<template>
    <div :class="['subject-item px-4 my-3', subject.inscripto ? 'subject-active' : subject.optativa ? 'subject-optative' : '']">
        <v-row>
            <v-col class="d-flex align-center py-2" cols="12" sm="9">
                <v-icon class="pl-3 pr-6" :class="{'visibility-hidden': !subject.inscripto}" color="green" :style="[subject.inscripto ? 'visibility: hidden' : '']">mdi-check-circle</v-icon>
                <div class="subject-item__content">
                    <h6 class="caption primary--text ma-0">{{ `${$t(`modules.portalalumno.academic_charge.subjects.ordinal.${subject.anioMateria}`)} año ${subject.optativa ? ` - ${$t('modules.portalalumno.academic_charge.subjects.optative')}` : ''}` }}</h6>
                    <h5 v-if="!subject.matElectivas" class="body-1 primary--text ma-0 text-uppercase">{{ (subject.selecMatElect) ? subject.selecMatElect : subject.materia }}</h5>
                    <v-select
                        v-else
                        class="my-2"
                        v-model="subject.selecMatElectId"
                        :items="subject.matElectivas"
                        :label="subject.materia"
                        item-value="id"
                        item-text="description"
                        outlined
                        :error-messages="subject.selected && !subject.selecMatElectId ? $t('modules.portalalumno.academic_charge.subjects.elective_required') : ''"
                        hide-details
                    ></v-select>
                    <h6 class="caption primary--text ma-0">{{ `${$t('modules.portalalumno.academic_charge.subjects.start')}: ${subject.fechaDesde} - ${$t('modules.portalalumno.academic_charge.subjects.end')}: ${subject.fechaHasta}` }}</h6>
                </div>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex justify-end align-center py-2">
                <v-switch v-if="subject.inscripto && (canDelete || $isTeclab) && subject.tipoMateria !== 127" :disabled="subject.canDisenroll" color="primary" v-model="subject.selected" @click.stop="" @click="changeSelection"></v-switch>
                <v-switch v-else-if="!subject.inscripto && subject.tipoMateria !== 127" color="primary" v-model="subject.selected" @change="changeSelection"></v-switch>
                <v-btn v-if="subject && subject.tipoMateria === 127" class="ma-2 bg-white rounded-xl" :to="'/professionalizing-practice'">Ir Al Formulario</v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'SubjectItem',
        props: {
            subject: Object,
        },
        computed: {
            canDelete() {
                return this.$permissions.portalalumno.academic_charge.subjects.DELETE;
            },
        },
        methods: {
            changeSelection() {
                if (this.subject.inscripto) this.$emit('remove')
            },
        },
    };
</script>

<style lang="sass" scoped>
    .subject-item
        background: white
        border: 1px solid #d9d9d9
        border-radius: 5px
        transition: all 300ms ease
        .visibility-hidden
            visibility: hidden
        &.subject-active
            background-color: #e9f2ff
            box-shadow: 2px 2px 4px 0 rgba(0, 44, 142, 0.15)
        &.subject-optative
            background-color: #fbead0
        &:hover
            // cursor: pointer
            border-color: #6087e1
            box-shadow: 4px 8px 14px 0 rgba(0, 44, 142, 0.15)
        &__content
            display: flex
            flex-direction: column
            justify-content: space-between
            padding: 0.5rem 0

</style>
