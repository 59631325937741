
const alumnosDatosGenerales = {
    title: 'Datos Generales Alumnos',
    description: 'Este reporte retorna un listado de alumnos con información general de los mismos.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosDatosGenerales.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span><span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'General' },
        { label: 'Criterios', value: 'Actualmente trae estudiantes pre matriculados, cursando o sean baja.' },
    ],
    example: {
        header: [
            'Id usuario',
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Legajo',
            'Email',
            'Fecha Nac',
            'Carrera',
            'Ceo',
            'Localidad',
            'Provincia',
            'Periodo Inicio',
            'Aprobadas',
            'Regulares',
            'Pedidos exámen',
            'PPs',
            'Teléfono',
            'Usuario',
            'Canvas id',
            'Último per abonado',
            'Genero',
            'Zona comercial',
            'Dirección',
            'Número',
            'CP',
            'Estado Carrera',
            'Plan de Estudio'
        ],
        data: [
            [
                '352404',
                'VICTORIA',
                'CORTEZ',
                '95969580',
                '20212AGHOT0008',
                'victoriacortez94@gmail.com',
                '28/07/2021',
                'Gestión Hotelera',
                'CEO VIRTUAL',
                'CABALLITO',
                'BUENOS AIRES',
                '2A-2021',
                '2',
                '10',
                'null',
                'null',
                '+541158883856',
                '95969580',
                '13471',
                '2B 2022',
                'Femenino',
                'Zona A',
                'Victor Martínez 12',
                '268',
                '1424',
                'Cursando',
                '2022',
            ],
            [
                '352600',
                'Gabriel',
                'Göring',
                '43357946',
                '20212AGAGR0001',
                'gabygoring2000@gmail.com',
                '13/06/2001',
                'Gestión de la Empresa Agraria',
                'CEO VIRTUAL',
                'SAN PEDRO',
                'MISIONES',
                '2A-2021',
                'null',
                'null',
                'null',
                'null',
                '+54 3751 66-6141',
                '43357946',
                'null',
                'null',
                'Femenino',
                'Zona G',
                'RUTA PROV 17',
                '1',
                '3334',
                'Baja</t',
                '2021',
            ],
        ],
    },
}

// documentations[this.reports.service][this.reports.url]
const admissions = {
    title: 'Admisión / Admisiones',
    description: 'Este reporte muestra los nuevos ingresantes para cada período.',
    detail: [
        { label: 'Archivo', value: 'Reporte Admisión.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estado del estudiante debe indicar "Cursando"' },
        { label: 'Criterios', value: 'Este reporte actualmente muestra pre-inscripciones.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'dni',
            'Mail',
            'Localidad',
            'Provincia',
            'Carrera',
            'Código agente',
            'Localidad agente',
            'Prov. agente',
            'Fecha PreIns',
            'Per. PreIns',
            'Usr PreIns',
            'Fecha Matri',
            'Per. Matri',
            'Usuario Tomo Matri',
            'Fecha Pago Arancel',
            'Per. Arancel',
            'Usuario Tomo Aran',
            'Legajo',
            'Celular',
            'Fecha pre Real',
            'Contacto Hubspot',
        ],
        data: [
            [
                'Abigail Lizbeth ',
                'Salazar León',
                '94671717',
                'abigail_salazar99@hotmail.com',
                'PARQUE AVELLANEDA',
                'BUENOS AIRES',
                'Marketing Digital',
                'AER03',
                'CIUDAD AUTONOMA DE BUENOS AIRES',
                'BUENOS AIRES',
                '18/10/2022',
                '2B 2022',
                'paula.paturzo@teclab.edu.ar',
                '18/10/2022',
                '2B 2022',
                'paula.paturzo@teclab.edu.ar',
                '18/10/2022',
                '2B 2022',
                'paula.paturzo@teclab.edu.ar',
                '20222BTSMD0119',
                '+54 11 6593-5436',
                '18/10/2022',
                '158258101',  
            ],
            [
                'Adrian ',
                'Lopez',
                '26176228',
                'adrianlop2000@gmail.com',
                'ESCOBAR',
                'BUENOS AIRES',
                'Seguridad Informática',
                'SMI02',
                'ESCOBAR',
                'BUENOS AIRES',
                '29/09/2022',
                '2B 2022',
                'leticia.garcia@teclab.edu.ar',
                '04/10/2022',
                '2B 2022',
                'leticia.garcia@teclab.edu.ar',
                '04/10/2022',
                '2B 2022',
                'leticia.garcia@teclab.edu.ar',
                '20222BTSSI0140',
                '+54 11 2179-5752',
                '29/09/2022',
                '157404201',
            ],
        ],
    },
}

const performance = {
    title: 'Reinscriptos',
    description: 'Este reporte muestra a los estudiantes que cumplen la condición de ser nuevos inscriptos (NI) para determinado período.',
    detail: [
        { label: 'Archivo', value: 'Reporte Admisión.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'No deben haber realizado un pago de arancel por un período anterior, independientemente si fue en la carrera actual u otra.' },
        { label: 'Criterios', value: 'Se consideran NI a los estudiantes que habiendo pagado matrícula pagan por primera vez un arancel.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'dni',
            'Mail',
            'Localidad',
            'Provincia',
            'Carrera',
            'Código agente',
            'Localidad agente',
            'Prov. agente',
            'Fecha PreIns',
            'Per. PreIns',
            'Usr PreIns',
            'Fecha Matri',
            'Per. Matri',
            'Usuario Tomo Matri',
            'Fecha Pago Arancel',
            'Per. Arancel',
            'Usuario Tomo Aran',
            'Legajo',
            'Celular',
            'Fecha pre Real',
            'Contacto Hubspot',          
        ],
        data: [
            [
                'Abel Alfonso',
                'Mendez',
                '39899229',
                'Metallicaandsoad@outlook.es',
                'SANTIAGO DEL ESTERO',
                'SANTIAGO DEL ESTERO',
                'Programación',
                'SDE02',
                'SANTIAGO DEL ESTERO',
                'SANTIAGO DEL ESTERO',
                '07/01/2023',
                '1A 2023',
                'sofia.fassi@teclab.edu.ar',
                '06/01/2023',
                '1A 2023',
                '39899229',
                '09/01/2023',
                '1A 2023',
                '39899229',
                '20231APNET1140',
                '+54 385 506-3505',
                '07/01/2023',
                '185466351',
            ],
            [
                'Abel dario',
                'skobronek',
                '35006501',
                'darioskobronek@gmail.com',
                'GDOR ROCA',
                'MISIONES',
                'Marketing Digital',
                'POS05',
                'GDOR ROCA',
                'MISIONES',
                '19/01/2023',
                '1A 2023',
                'agustina.martinez@teclab.edu.ar',
                'null',
                'null',
                'null',
                '23/01/2023',
                '1B 2023',
                'null',
                '20231ATSMD0365',
                '+54 376 423-5087',
                '19/01/2023',
                '32510451',
            ],
        ],
    },
}

const performanceRi = {
    title: 'Reinscriptos',
    description: 'Este reporte muestra a los estudiantes que cumplen la condición de ser RI para determinado período.',
    detail: [
        { label: 'Archivo', value: 'Reporte Reinscriptos.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Deben haber realizado un pago por un período anterior, independientemente si fue en la carrera actual u otra.' },
        { label: 'Criterios', value: 'No se consideran como RI los alumnos que habiendose egresado de una carrera comienzan otra.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Email',
            'Legajo',
            'Carrera',
            'Código Agente',
            'Localidad Agente',
            'Prov. Agente',
            'Fecha Matri',
            'Hora Matri',
            'Per. Matri',
            'Fecha Pago Arancel A',
            'Hora Pago Arancel A',
            'Per. Arancel A',
            'Fecha Pago Arancel B',
            'Hora Pago Arancel B',
            'Per. Arancel B',
        ],
        data: [
            [
                'MARLENE NICOLINA',
                'MARIN PRADO LIMA',
                '34478351',
                'pradolimamarlene@gmail.com',
                'PYNT00666',
                'Periodismo y Nuevas Tecnologías',
                'DIR03',
                'RESISTENCIA',
                'CHACO',
                '44859',
                '2022-10-25 08:47:58.792',
                '2B 2022',
                'null',
                'null',
                'null',
                '44859',
                '2022-10-25 08:47:58.794',
                '2B 2022',   
            ],
            [
                'Carina Andrea',
                'Scheffer',
                '29682974',
                'carinascheffer201666@gmail.com',
                'TSEG00435',
                'Seguros',
                'STT02',
                'NECOCHEA',
                'BUENOS AIRES',
                '44858',
                '2022-10-24 00:00:00.0',
                '2B 2022',
                'null',
                'null',
                'null',
                '44858',
                '2022-10-24 00:00:00.0',
                '2B 2022',
            ],
        ],
    },
}

const graduate = {
    title: 'Graduados y Titulados',
    description: 'Este reporte muestra a los estudiantes que se hayan graduado en IPP y si dieron el examen de titulación. Con datos de fechas y notas.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateGraduateAndTitleReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estudiante debe tener fecha de graduación o graduación intermedia o acta final o intermedia.' },
        { label: 'Criterios', value: 'Los titulados deben contar con acta de titulación.' },
    ],
    example: {
        header: [
            'RUT',
            'Nombre',
            'Apellido',
            'Correo',
            'Celular',
            'Género',
            'Nacionalidad',
            'Región',
            'Ciudad',
            'Dirección',
            'Carrera',
            'Legajo',
            'Estado Carrera',
            'Fecha Inicio Carrera',
            'Fecha Egreso Intermedio',
            'Fecha Egreso Final',
            'Cant. Actas',
            'Acta Tit. Intermedio',
            'Título Intermedio',
            'Fecha Tit. Intermedio',
            'Nota Examen Tit. Intermedio',
            'Acta Tit. Final',
            'Título Final',
            'Fecha Tit. Final',
            'Nota Examen Tit. Final',
            'Promedio Asignaturas',
            'Nota Final',
        ],
        data: [
            [
                '13603232',
                'ENRIQUE EDUARDO',
                'FALCÓN FERNANDEZ',
                'efernandez@hotmail.com',
                '62564124',
                'Masculino',
                'Chilena',
                'REGION DE ÑUBLE',
                'CHILLAN',
                'VILLA DOÑA ROSA, PASAJE CONAI 3688',
                'INGENIERÍA EN CONTROL DE GESTIÓN',
                '20202A0350PE0003',
                'Egresado',
                '11/08/2020',
                '08/10/2022',
                '01/09/2022',
                '1',
                '301032',
                'CONTADOR GENERAL',
                '19/07/2022',
                '4.5',
                '305254',
                'ASISTENTE SOCIAL',
                '20/07/2022',
                '4.0',
                '5.7',
                '5.8', 
            ],
            [
                '17587411',
                'MARIELA GISSELE',
                'VIDAL CHANO',
                'mvidal@gmail.com',
                '87005680',
                'Femenino',
                'Chilena',
                'REGION DE LOS LAGOS',
                'CASTRO',
                'MANUEL OJEDA 1189',
                'SERVICIO SOCIAL',
                '20171B0610P020',
                'Titulado',
                '01/01/2017',
                '08/10/2022',
                '26/07/2022',
                '1',
                '305707',
                'TÉCNICO DE NIVEL SUPERIOR EN INFORMÁTICA',
                '27/10/2020',
                '4.5',
                '305736',
                'ASISTENTE SOCIAL',
                '20/07/2022',
                '4.0',
                '5.4',
                '5.1',
            ],
        ],
    },
}

const alumnosDatosEgresados = {
    title: 'Egresados',
    description: 'Este reporte muestra el listado total de los estudiantes que egresaron con los datos de contacto.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosDatosEgresados.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'El estado de la carrera del estudiante debe ser egresado o debe tener cargada una fecha de egreso.' },
        { label: 'Criterios', value: 'En este reporte se agregaron los campos de cantidad de materias aprobadas y el estado de la documentación obligatoria para realizar un chequeo de cada estudiante.' },
    ],
    example: {
        header: [
            'Id Estudiante',
            'Nro Doc',
            'Nombre',
            'Apellido',
            'Fecha inicio',
            'Legajo',
            'Periodo',
            'Carrera',
            'Provincia',
            'Código carrera',
            'Tipo',
            'Teléfono',
            'Mail',
            'Fecha Egresado',
            'Localidad',
            'Fecha último examen',
            'Estado documentación obligatoria',
            'Cantidad materias aprobadas',
        ],
        data: [
            [
                '340411',
                '28364170',
                'ALEJANDRO',
                'GONZALEZ',
                '2019-07-23 00:00:00.0',
                'PNET02351',
                '2A/2021',
                'Técnico Superior  en Programación ',
                'BUENOS AIRES',
                'PNET',
                'EGRESADO',
                '',
                'alejandrogonzalez@gmail.com',
                '09-07-2021',
                'CIUDAD AUTONOMA DE BUENOS AIRES',
                '31-08-2021',
                'Aprobado',
                '14',
            ],
            [
                '343761',
                '26565351',
                'MARIA',
                'GONZALEZ',
                '2020-03-20 00:00:00.0',
                'TSEG01296',
                '1A/2022',
                'Técnico Superior en Seguros',
                'BUENOS AIRES',
                'TSEG',
                'EGRESADO',
                '+542267520959',
                'natiliz2008@hotmail.com',
                '11-12-2021',
                'VILLA GESELL',
                '16-11-2021',
                'Aprobado',
                '16',
            ],
        ],
    },
}

const curricularAdvancement = {
    title: 'Avance curricular SIES',
    description: 'Se utiliza para informar al Servicio de Información de Educación Superior: sus siglas “SIES” a mitad de año un reporte de avance académico semestral correspondiente al año anterior de la solicitud.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateCurricularAdvancementReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Todos los estudiantes.' },
        { label: 'Criterios', value: 'Las columnas importantes además de los datos personales, legajo, rut y carrera, son el número de asignaturas cursadas, aprobadas, lo ideal es que incluyera columnas con información semestral, porque así lo solicita la entidad.' },
    ],
    example: {
        header: [
            'LEGAJO',
            'RUT',
            'DIGITO VERIFICADOR',
            'APELLIDO MATERNO',
            'APELLIDO PATERNO',
            'NOMBRES',
            'SEXO',
            'FECHA NACIMIENTO',
            'PERÍODO INGRESO',
            'CÓDIGO CARRERA',
            'NOMBRE CARRERA',
            'CÓDIGO PLAN ESTUDIO',
            'ESTADO CARRERA',
            'ÚLTIMA MATRÍCULA',
            'CURSADAS PRIMER SEMESTRE',
            'CURSADAS SEGUNDO SEMESTRE',
            'CURSADAS',
            'APROBADAS',
            'CURSADAS CARRERA',
            'APROBADAS CARRERA',
            'ASIGNATURAS RESTANTES EGRESO',
            'ASIGNATURAS RESTANTES TÍTULO',
        ],
        data: [
            [
                '20212B0313PE0003',
                '10000955',
                '2',
                'MOLINA',
                'MARTINEZ',
                'PAMELA ELISABETH',
                'Femenino',
                '22/05/1977',
                '2B/2021',
                '0313PE',
                'INGENIERÍA EN ADMINISTRACIÓN DE EMPRESAS MENCIÓN RECURSOS HUMANOS (PLAN ESPECIAL)',
                '0313PE/2019-1',
                'Cursando',
                '2B/2022',
                '0',
                '0',
                '0',
                '0',
                '16',
                '11',
                '12',
                '13',
            ],
            [
                '20191A0630P058',
                '10000566',
                '2',
                'RIFFO',
                'GALLARDO',
                'EVARISTO CÉSAR',
                'Masculino',
                '20/04/1971',
                '1A/2019',
                '0630P',
                'TÉCNICO JURÍDICO EN GESTIÓN JUDICIAL ',
                '0630P/2015',
                'Baja',
                '1A/2019',
                '0',
                '0',
                '0',
                '0',
                '3',
                '0',
                '26',
                '26',
            ],
        ],
    },
}

const academic = {
    title: 'Carga académica',
    description: 'Este reporte muestra la cantidad de asignaturas cargadas para cada período por carrera y estudiante.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateCurricularAdvancementReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estudiante debe tener cargada al menos una asignatura.' },
        { label: 'Criterios', value: 'No se consideran las asignaturas deshabilitadas, anuladas ni los exámenes de título.' },
    ],
    example: {
        header: [
            'LEGAJO',
            'RUT',
            'DIGITO VERIFICADOR',
            'ESTUDIANTE',
            'SEDE',
            'CARRERA',
            'PLAN DE ESTUDIO',
            'ESTADO_CARRERA',
            'INGRESO',
            'ULTIMA REMATRICULA',
            'PERIODO',
            'CANTIDAD DE ASIGNATURAS',
        ],
        data: [
            [
                '20231A0271P0003',
                '100490',
                '5',
                'JUAN ESTEBAN  GRISALES OSORIO',
                '546 - CENTRO VIRTUAL',
                '0271P - TÉCNICO EN ANIMACIÓN DIGITAL',
                '0271P/2018',
                '54 - Cursando',
                '433 - 1A/2023',
                '433 - 1A/2023',
                '1A/2023',
                '2',
            ],
            [
                '20231A0610P0004',
                '10060019',
                '6',
                'MARÍA CECILIA  CASTILLO ÓRDENES',
                '546 - CENTRO VIRTUAL',
                '0610P - SERVICIO SOCIAL',
                '0610P/2015',
                '54 - Cursando',
                '433 - 1A/2023',
                '433 - 1A/2023',
                '1A/2023',
                '3',
            ],
        ],
    },
}

const inscriptions = {
    title: 'Inscripciones',
    description: 'Este reporte muestra las inscripciones de cada estudiante a cada asignatura.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateInscriptionsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estado de la asignatura para el estudiante debe ser Aprobada, Regular, En curso o Libre.' },
        { label: 'Criterios', value: 'Se muestra el avance de cada estudiante en cada asignatura, mostrando las notas y fechas de los módulos.' },
    ],
    example: {
        header: [
            'LEGAJO',
            'RUT',
            'DV',
            'NOMBRE',
            'ESTADO ESTUDIANTE',
            'REGLAMENTO',
            'SEDE',
            'CARRERA',
            'PLAN DE ESTUDIO',
            'ESTADO CARRERA',
            'INGRESO',
            'ULTIMA MATRICULA',
            'CODIGO MATERIA',
            'MATERIA',
            'CODIGO SECCION',
            'SECCION',
            'DURACION MATERIA',
            'ESTADO MATERIA',
            'FECHA ESTADO MATERIA',
            'LMS ID',
            'TIPO EXAMEN',
            'OBSERVACION',
            'MOD1',
            'MOD2',
            'MOD3',
            'MOD4E1',
            'MOD4E2',
            'MOD4E3',
            'INTENTOS MOD1',
            'INTENTOS MOD2',
            'INTENTOS MOD3',
            'INTENTOS MOD4E1',
            'INTENTOS MOD4E2',
            'INTENTOS MOD4E3',
            'FECHA MOD1',
            'FECHA MOD2',
            'FECHA MOD3',
            'FECHA MOD4E1',
            'FECHA MOD4E2',
            'FECHA MOD4E3',
            'NOTA EXAMEN',
            'NOTA FINAL',
            'RUT PROFESOR',
            'NOMBRE PROFESOR',
        ],
        data: [
            [
                '20222B0211P0001',
                '25498947',
                '9',
                'JHOSEPH SAUL RUEDA TORTOLERO',
                '21 Vigente',
                'R20',
                '546 - CENTRO VIRTUAL',
                '0211P - TÉCNICO EN DISEÑO GRÁFICO DIGITAL',
                '0211P/2019',
                '54 - Cursando',
                '315 - 2B/2022',
                '315 - 2B/2022',
                'TRA009',
                'HABILIDADES PARA LA COMUNICACIÓN ORAL Y ESCRITA',
                '7240',
                'TRA009-HABILIDADES PARA LA COMUNICACIÓN ORAL Y ESCRITA 1',
                'null',
                '86 - En curso',
                '2022-08-31',
                '513637',
                'Sin Examen',
                'null',
                '6.88',
                '6.34',
                'null',
                'null',
                'null',
                'null',
                '1',
                '1',
                'null',
                'null',
                'null',
                'null',
                '2022-11-21 15:00:25.0',
                '2022-12-07 12:27:27.0',
                'null',
                'null',
                'null',
                'null',
                'null',
                'null',
                '15964668-8',
                'CAROLINA EVELYN LEIVA RIVERA',
            ],
            [
                '20222B0121P0001',
                '18413341',
                '5',
                'CATALINA ANDREA VARGAS GARAI',
                '21 Vigente',
                'R20',
                '546 - CENTRO VIRTUAL',
                '0121P - TÉCNICO EN EDUCACIÓN DIFERENCIAL',
                '0121P/2018',
                '54 - Cursando',
                '315 - 2B/2022',
                '315 - 2B/2022',
                'TRA014',
                'RECURSOS TECNOLÓGICOS I',
                '7247',
                'TRA014-RECURSOS TECNOLÓGICOS I 1',
                'null',
                '86 - En curso',
                '2022-09-01',
                '515152',
                'Sin Examen',
                'null',
                '7.0',
                '1.0',
                'null',
                'null',
                'null',
                'null',
                '1',
                'null',
                'null',
                'null',
                'null',
                'null',
                '2022-11-12 19:05:09.0',
                '2022-11-23 14:33:17.0',
                'null',
                'null',
                'null',
                'null',
                'null',
                'null',
                '55555555-5',
                'LUIS ALBERTO CAMUS GONZALEZ',
            ],
        ],
    },
}

const confirmedSubjects = {
    title: 'Asignaturas confirmadas',
    description: 'Este reporte entrega un listado de estudiantes con las materias que tiene confirmadas para determinado período.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateConfirmedSubjectsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Se muestran seminarios y trabajos de título, inglés y prácticas profesional, laboral y laboral educativa. ' },
        { label: 'Criterios', value: 'Las prácticas profesional, laboral y laboral educativa deben estar "en curso" para el estudiante. Las asignaturas de inglés son todos los períodos y los seminarios y trabajos de títulos del período selecionado.' },
    ],
    example: {
        header: [
            'NOMBRES',
            'APELLIDOS',
            'RUT',
            'LEGAJO',
            'EMAIL',
            'TELEFONO',
            'CARRERA',
            'ASIGNATURA',
            'FECHA CONFIRMACIÓN',
            'PERÍODO',
        ],
        data: [
            [
                'ABIGAIL NINOSKA ',
                'SILVA  CASTILLO',
                '17800197-3',
                '20222B0410P0036',
                'abigail.n.silva.c@gmail.com',
                '+56 9 3900 4584',
                '0131P-TÉCNICO EN EDUCACIÓN PARVULARIA Y PRIMER CICLO BÁSICO',
                '0131P51A-PRÁCTICA LABORAL EDUCATIVA',
                '2022-08-24',
                '2A/2022',
            ],
            [
                'ABIGAIL NINOSKA ',
                'SILVA  CASTILLO',
                '17800197-3',
                '20211A0346P0113',
                'abigail.n.silva.c@gmail.com',
                '+56 9 3900 4584',
                '0131P-TÉCNICO EN EDUCACIÓN PARVULARIA Y PRIMER CICLO BÁSICO',
                '0131P51A-PRÁCTICA LABORAL EDUCATIVA',
                '2022-08-24',
                '2A/2022',
            ],
        ],
    },
}

const historialAlumnosMateriasStatus = {
    title: 'Historial materias por alumno',
    description: 'Este reporte muestra un listado de todas las materias aprobadas o regulares de cada estudiante.',
    detail: [
        { label: 'Archivo', value: 'Reporte historialAlumnosMateriasStatus.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'La asignatura debe estar aprobada o regularizada.' },
        { label: 'Criterios', value: 'Se muestran todas las asignaturas históricas.' },
    ],
    example: {
        header: [
            'Periodo',
            'Periodo inicio',
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Teléfono',
            'Email',
            'Carrera',
            'Materia',
            'Estado',
            'Zona Comercial',
        ],
        data: [
            [
                '1B-2021',
                '2B-2020',
                'MARIA VERONICA',
                'POZO',
                '22521114',
                '+543487666564',
                'mvpozo@hotmail.com',
                'Seguros',
                'SEGUROS ESPECÍFICOS 1',
                'Regular',
                'Zona C',
            ],
            [
                '2A-2021',
                '2A-2021',
                'LAUTARO',
                'MUIÑA',
                '42909447',
                '+542657264844',
                'muinalautaro@gmail.com',
                'Programación',
                'ORGANIZACIÓN DEL TIEMPO Y DEL TRABAJO',
                'Aprobada',
                'Zona D',
            ],
        ],
    },
}

const english = {
    title: 'Inglés',
    description: 'Este reporte muestra los datos de los estudiantes que se encuentran cursando alguna de las asignaturas correspondientes a Inglés.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateEnglishReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Sólo se muestran las asignaturas que se encuentran en curso.' },
        { label: 'Criterios', value: 'Al mostrar las asignaturas en curso este reporte sólo sirve para el período actual.' },
    ],
    example: {
        header: [
            'NOMBRES',
            'APELLIDOS',
            'RUT',
            'EMAIL',
            'TELEFONO',
            'CARRERA',
            'ASIGNATURA',
            'FECHA CONFIRMACIÓN',
        ],
        data: [
            [
                'SERGIO HERNÁN',
                'ALVARADO BARRIENTOS',
                '15832620',
                'sergio.alvarado@ipp.cl',
                '+56 9 6213 1222',
                '0410P-INGENIERÍA EN INFORMÁTICA',
                'ING01-INGLÉS I',
                '2022-12-20',
            ],
            [
                'JAVIER IGNACIO ',
                'ASTORGA CHAMORRO',
                '17456872',
                'javierignacioastorga@gmail.com',
                '+56934366926',
                '0344P-TÉCNICO EN COMERCIO EXTERIOR',
                'ING05-INGLÉS TÉCNICO PARA COMERCIO EXTERIOR',
                '2022-12-20',
            ],
        ],
    },
}

const alumnosInscripcionPps = {
    title: 'Inscripción a prácticas',
    description: 'Reporte con datos de los alumnos inscriptos en prácticas y los datos de la organización y el referente.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateLaborPracticeReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Las prácticas profesionalizantes corresponden al período seleccionado.' },
        { label: 'Criterios', value: 'Se muestran todos los registros.' },
    ],
    example: {
        header: [
            'IDENTIFICACION',
            'NOMBRE Y APELLIDO',
            'CARRERA',
            'CODIGO ASIGNATURA',
            'ASIGNATURA',
            'EMPRESA',
            'DIRECCION EMPRESA',
            'RUT EMPRESA',
            'REFERENTE',
            'CARGO REFERENTE',
            'EMAIL',
            'TELEFONO',
            'ESTADO',
        ],
        data: [
            [
                '17179288',
                'LILIAN ISABEL CARRILLO PALMA',
                'TÉCNICO DE NIVEL SUPERIOR EN ADMINISTRACIÓN DE EMPRESAS ',
                '6276',
                'PRÁCTICA LABORAL',
                'Banco Santander',
                'Lautaro 399',
                '97036000-k',
                'Patricio del Pino ',
                'Agente',
                'patricio.delpino@santander.cl',
                '+56 9 9826 2714',
                'Inicializado',
            ],
            [
                '16779858',
                'ÁLVARO IGNACIO HUAIQUILEF TRUJILLO',
                'TÉCNICO EN ADMINISTRACIÓN DE EMPRESAS',
                '6277',
                'PRÁCTICA LABORAL',
                'Preunic S.A.',
                'Illapel 10',
                '91635000-7',
                'Carolina Andrea Maldonado Soto',
                'Jefa de Tienda',
                'pu027@sb.cl',
                '+56 9 4410 8208',
                'Firmado',
            ],
        ],
    },
}

const alumnosPpsInscriptos = {
    title: 'Prácticas inscriptas',
    description: 'Reporte con datos de los alumnos inscriptos en prácticas, el estado y los datos de la organización y el referente.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosPpsInscriptos.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Las prácticas profesionalizantes corresponden al período seleccionado.' },
        { label: 'Criterios', value: 'Se muestran todos los registros.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'DNI',
            'Legajo',
            'email',
            'Telefono',
            'Carrera',
            'Asignatura',
            'Nombre Empresa',
            'CUIT Empresa',
            'DIRECCION Empresa',
            'Ciudad Empresa',
            'Referente Empresa',
            'Cargo Referente',
            'email Referente',
            'Telefono Referente',
            'Estado Practica',
            'Validado Practica',
            'Dias en el estado',            
        ],
        data: [
            [
                'Mateo',
                'Ferreira',
                '42219439',
                '20212BRTEL0011',
                'ferreiramateo1@gmail.com',
                '+54 358 425-0953',
                'Redes Informáticas',
                'PRÁCTICA PROFESIONALIZANTE I',
                'Prodeman S.A.',
                '33-66908849-9',
                'Ruta Nacional Nº 158 Km 230 ½',
                'GENERAL CABRERA',
                'Karin Goldman',
                'Analista de Telecomunicaciones',
                'kgoldman@prodeman.com.ar',
                '+54 358 514-9135',
                'Firmado',
                'no',
                '16 día/s',
            ],
            [
                'DIEGO NICOLAS',
                'ZARZA',
                '33654744',
                'PNET06690',
                'diesan.dz@gmail.com',
                '3464550664',
                'Programación',
                'PRÁCTICA PROFESIONALIZANTE I',
                'Master Solutions',
                '30-69566428-8',
                'Hipólito Yrigoyen 2767',
                'CASILDA',
                'Jose Lobos',
                'Cobranzas',
                'JoseLuis.lobos@msci.com.ar',
                '+54 3464 42-7452',
                'Inicializado',
                'no',
                '0 día/s',
            ],
        ],
    },
}

const practice = {
    title: 'Prácticas',
    description: 'Reporte con datos de los alumnos anotados en prácticas y la fecha de confirmación.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateLaborPracticeReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'La práctica debe estar en curso.' },
        { label: 'Criterios', value: 'Debe seleccionarse un período del tipo PP.' },
    ],
    example: {
        header: [
            'NOMBRES',
            'APELLIDOS',
            'RUT',
            'EMAIL',
            'TELEFONO',
            'CARRERA',
            'ASIGNATURA',
            'FECHA CONFIRMACIÓN',
        ],
        data: [
            [
                'MARJORIE ELIZABETH',
                'VALDEZ MOLINA',
                '17676645',
                'marjorievaldesm@gmail.com',
                '+56 9 7200 9095',
                '0313PE-INGENIERÍA EN ADMINISTRACIÓN DE EMPRESAS MENCIÓN RECURSOS HUMANOS (PLAN ESPECIAL)',
                '0310P85R-PRÁCTICA PROFESIONAL',
                '2022-11-29',
            ],
            [
                'EGON EDUARDO',
                'TOLG NUÑEZ',
                '15528414',
                'egontolg7@gmail.com',
                '+56 9 5674 0202',
                '0630P-TÉCNICO JURÍDICO EN GESTIÓN JUDICIAL ',
                '0630P52-PRÁCTICA LABORAL',
                '2022-12-15',
            ],
        ],
    },
}

const alumnosPpsAProbadas = {
    title: 'PPs aprobadas',
    description: 'Reporte con datos de los alumnos con prácticas aprobadas.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosPpsAProbadas.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Se muestran las prácticas profesionalizantes 1 y 2 que estén aprobadas.' },
        { label: 'Criterios', value: '' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Legajo',
            'Email',
            'Carrera',
            'Materia',
            'Perído',
        ],
        data: [
            [
                'YAMILA BELEN',
                'BLANCO',
                '39710633',
                'RLAB01269',
                'yamila1996blanco@gmail.com',
                'Relaciones Laborales',
                'PRÁCTICA PROFESIONALIZANTE I',
                'PP-Período Verano 2022',
            ],
            [
                'NADIA IRENE',
                'GROSSO',
                '28695622',
                'TGCO01964',
                'nadia.grosso@am.jll.com',
                'Gestión Contable',
                'PRÁCTICA PROFESIONALIZANTE II',
                '2A-2021',
            ],
        ],
    },
}

const alumnosEstadosPps = {
    title: 'Estados PP',
    description: 'Este reporte muestra el estado de aprobación de cada Práctica Profecionalizante de cada alumno, con los datos del mismo y el período en el que la llevó a cabo.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosEstadosPps.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'El estudiante debe haber cursado la práctica profecionalizante 1 o 2.' },
        { label: 'Criterios', value: 'Muestra todos los estudiantes que hayan pasado por la Práctica Profecionalizante.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Legajo',
            'Email',
            'Carrera',
            'Materia',
            'Estado',
            'Periodo',
        ],
        data: [
            [
                'JESSICA GABRIELA',
                'PEDREIRA',
                '37442921',
                'TGCO01070',
                'jgpedreira93@gmail.com',
                'Gestión Contable',
                'PRÁCTICA PROFESIONALIZANTE I',
                'Aprobada',
                '1B-2020',
            ],
            [
                'NANCY NOEMI',
                'GUNDIN',
                '25598551',
                'TSEG00626',
                'nangundin@hotmail.com',
                'Seguros',
                'PRÁCTICA PROFESIONALIZANTE I',
                'Desaprobada',
                '1A-2020',
            ],
        ],
    },
}

const alumnosPps = {
    title: 'Detalle habilitaciones PP',
    description: 'Este reporte muestra si cada estudiante se encuentra habilitado para realizar la Práctica Profecionalizante o no y el estado de aprobación de cada una.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosPps.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Sólo se muestran los estudiantes que se encuentran cursando.' },
        { label: 'Criterios', value: 'Si tiene 4 finales aprobados y no aproba la PP1, entonces puede hacerla. Si tiene 4 finales de primer año, 4 finales de segundo y la PP1 aprobada, puede hace la PP2.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Carrera',
            'Periodo Ingreso',
            'Último Período Abonado',
            'Total aprobadas',
            'Cant ex ap Primer año',
            'Cant ex ap Segundo año',
            'PP1 aprobada',
            'PP2 aprobada',
            'Puede cursar PP1',
            'Puede cursar PP2',
            'Fecha Nac',
        ],
        data: [
            [
                'FRANCISCO MARTIN',
                'PEREZ DE LA SIERRA',
                '41455920',
                'Gestión Hotelera',
                '1A-2021',
                '2B 2022',
                '14',
                '8',
                '6',
                'Si',
                'No',
                'No - Aprobada',
                'Si',
                '11-01-1998',
            ],
            [
                'Leonardo',
                'Raponi',
                '30399913',
                'Gestión Contable',
                '1A-2021',
                '2B 2022',
                '9',
                '8',
                '1',
                'Si',
                'No',
                'No - Aprobada',
                'No',
                '21-06-1983',
            ],
        ],
    },
}

const seminar = {
    title: 'Seminarios',
    description: 'Este reporte muestra los datos de los estudiantes que se encuentran cursando los seminarios o trabajos de título.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateSeminarReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Sólo se muestran los seminarios y trabajos que se encuentran en curso.' },
        { label: 'Criterios', value: 'Al mostrar los seminarios y trabajos en curso este reporte sólo sirve para el período actual.' },
    ],
    example: {
        header: [
            'NOMBRES',
            'APELLIDOS',
            'RUT',
            'EMAIL',
            'TELEFONO',
            'CARRERA',
            'ASIGNATURA',
            'FECHA CONFIRMACIÓN',
        ],
        data: [
            [
                'ERIKA JANET',
                'CASTRO ACUÑA ',
                '12579302',
                'ejcastro74@gmail.com',
                '+56 9 8674 1586',
                '0340P-TÉCNICO EN ADMINISTRACIÓN DE EMPRESAS',
                '0340P53R-TRABAJO DE TÍTULO',
                '2022-12-21',
            ],
            [
                'FERNANDO FACUNDO',
                'BRACAMONTE',
                '24925681',
                'facundobracamonte64@gmail.com',
                '+56950428607',
                '0340P-TÉCNICO EN ADMINISTRACIÓN DE EMPRESAS',
                '0340P53R-TRABAJO DE TÍTULO',
                '2022-12-20',
            ],
        ],
    },
}

const levels = {
    title: 'Niveles',
    description: 'Este reporte muestra el nivel de cada estudiante y la cantidad de asignaturas del plan de estudio, de aprobadas, en curso y regulares.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateLevelsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Se muestran sólo las asignaturas obligatorias.' },
        { label: 'Criterios', value: 'El nivel se obtiene de analizar la cantidad de asignaturas semestrales aprobadas del plan de estudio.' },
    ],
    example: {
        header: [
            'LEGAJO',
            'RUT',
            'NOMBRE',
            'APELLIDO',
            'CARRERA',
            'PLAN ESTUDIO',
            'PERIODO INGRESO',
            'ESTADO ESTUDIANTE',
            'ESTADO CARRERA',
            'RAMOS PLAN',
            'APROBADAS',
            'EN CURSO',
            'REGULARES',
            'NIVEL',
        ],
        data: [
            [
                '201114611G008',
                '20918340',
                'EDUARDO ANTONIO',
                'TOLMO CUBILLOS',
                '0610P - SERVICIO SOCIAL',
                '0610P/20151A',
                '2B/2014',
                '25 - Titulado',
                '145 - Titulado',
                '44',
                '44',
                '0',
                '0',
                '8',
            ],
            [
                '20121B0313G012',
                '16584367',
                'KARLA FLORENCIA',
                'SANTANA GUTIÉRREZ',
                '0310P - INGENIERÍA EN ADMINISTRACIÓN DE EMPRESAS',
                '0310PR/20151A',
                '1A/2014',
                '21 - Vigente',
                '54 - Cursando',
                '48',
                '33',
                '0',
                '3',
                '3',
            ],
        ],
    },
}

const nps = {
    title: 'NPS',
    description: 'Este reporte muestra los datos generales del estudiante y datos particulares para utilizar en los pulsos de NPS.',
    detail: [
        { label: 'Archivo', value: 'Reporte nps.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estudiante debe estar en estado "cursando", debe tener contrato válido para el período solicitado con fecha de firma y las asignaturas deben estar en estado "cursando", "aprobada", "regular" o "libre".' },
        { label: 'Criterios', value: 'Si bien es el mismo reporte para los dos países, presenta algunas diferencias como si cursa o no cierta asignatura.' },
    ],
    example: {
        header: [
            'EMAIL',
            'NOMBRE',
            'APELLIDO',
            'TELÉFONO',
            'LEGAJO',
            'IDENTIFICACION',
            'FECHA_NACIMIENTO',
            'CARRERA',
            'CEO',
            'COMUNA',
            'CIUDAD / LOCALIDAD',
            'REGION / PROVINCIA',
            'PERIODO_INGRESO',
            'EXAMENES_INSCRIPTOS',
            'EXAMENES_RENDIDOS',
            'EXAMENES_APROBADOS',
            'MATERIAS_PRÁCTICAS',
            'REALIZO_TEST_ONB',
            'AGENDO_ONB',
            'REALIZO_ONB',
            'APROBO_TEST_ONB',
            'PERIODO_ONB',
            'MVP BASE DE DATOS',
            'MVP FUNDAMENTOS DE CONTABILIDAD',
            'MVP LOGICA DE PROGRAMACION',
        ],
        data: [
            [
                'carinascheffer201666@gmail.com',
                'Carina Andrea',
                'Scheffer',
                '+54 2262 57-0099',
                'TSEG00435',
                '29682974',
                '06/03/1983',
                'Seguros',
                'CEO VIRTUAL',
                '',
                'NECOCHEA',
                'BUENOS AIRES',
                '1A/2019',
                '0',
                '0',
                '0',
                '0',
                'No',
                'No',
                'No',
                'No',
                '1A/2019',
                'No',
                'No',
                'No',
            ],
            [
                'jorgedelmastro18@gmail.com',
                'Jorge Francisco',
                'Del Mastro',
                '+54 9 2241 58-0596',
                '20221ATSMD0016',
                '35078112',
                '18/02/1990',
                'Marketing Digital',
                'CEO VIRTUAL',
                '',
                'RANCHOS',
                'BUENOS AIRES',
                '1B/2022',
                '6',
                '6',
                '6',
                '0',
                'Si',
                'Si',
                'Si',
                'Si',
                '1B/2022',
                'No',
                'No',
                'No',
            ],
        ],
    },
}

const processings = {
    title: 'Trámites',
    description: 'Este reporte muestra los trámites y el estado en el que se encuentran para cada estudiante en un determinado período.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateProcessingsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span><span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'No se muestran los trámites de cambio de sede ni certificados.' },
        { label: 'Criterios', value: 'Para los trámites de documentación obligatoria se considera que el período de inicio del mismo es el ingreso del estudiante a la carrera.' },
    ],
    example: {
        header: [
            'N° trámite',
            'RUT',
            'Dígito verificador',
            'Nombre',
            'Legajo',
            'Carrera',
            'Trámite',
            'Fecha creación',
            'Fecha actualización',
            'Estado',
            'Periodo inicio',
            'Última matrícula',
            'Email',
            'Celular',
            'Razón',
            'Comentario',
            'Fecha pago',
            'Monto',
            'Categoria',
            'Usuario solicito',
        ],
        data: [
            [
                '250905',
                '14146706',
                '9',
                'MARÍA LUZ PINCHEIRA CATALÁN',
                '20182A0250P013',
                'PUBLICIDAD EN MARKETING DIGITAL',
                '21 - Trámite de exámen de título',
                '44868',
                'null',
                '92 - Denegado',
                '206 - 2A/2018',
                '308 - 2A/2018',
                'mlpincheira@gmail.com',
                '90358455',
                'null',
                'null',
                '2022-11-07 13:09:37.596',
                '355000.0',
                'null',
                '14146706',
            ],
            [
                '250938',
                '19215910',
                '5',
                'BÁRBARA MARÍA ORELLANA CONTRERAS',
                '20222A0371P0036',
                'TÉCNICO EN ADMINISTRACIÓN PÚBLICA',
                '18 - Trámite de retiro',
                '44868',
                '44908',
                '93 - Aprobado',
                '314 - 2A/2022',
                '314 - 2A/2022',
                'Barbaraorellana018@gmail.com',
                '+56 9 7419 4894',
                'Problemas económicos',
                'null',
                'null',
                'null',
                'Problemas económicos',
                '19215910',
            ],
        ],
    },
}

const mandatoryDocumentation = {
    title: 'Documentación obligatoria',
    description: 'Este reporte muestra el estado del trámite de documentación obligatoria para cada estudiante y los preinscriptos del período seleccionado.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateMandatoryDocumentationReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span><span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'La carrera del estudiante debe encontrarse en condición Cursando, Egresado, Titulado o Egresado sin opción a Título (o Baja para Chile). O puede ser una preinscripción correspondiente al período seleccionado.'},
        { label: 'Criterios', value: 'Para que un trámite de documentación obligatoria sea aprobado debe contener al menos un archivo de DNI y uno de analítico aprobado.' },
    ],
    example: {
        header: [
            'DNI','Nombre','Email','Celular','Carrera','Estado carrera','Legajo',
            'Inicio legajo','Última matrícula','Último período abonado','Número de trámite',
            'Estado trámite','Materias aprobadas','Creación trámite','Modificación trámite',
            'Usuario','Estado DNI','Estado Analítico'
        ],
        data: [
            [
                '32991041','BRUNO OSCAR  CANO','bruno_cano87@hotmail.com','+54 351 333-1111',
                'Programación','Egresado','PNET03498','2020-04-02 00:00:00.0','1A/2020',
                '2B/2021','4839','Aprobado','16','2020-08-20 14:22:44.0','2020-08-20 14:22:45.0',
                '343989','Aprobado','Aprobado'
            ],
            [
                '10696485','Luis Ernesto Prieto Chocala','prietoluis693@gmail.com',
                '+541166085794','Programación','Cursando','PNET04342','2020-10-12 20:20:20.0',
                '2B/2020','2B/2020','5318','Observado','null','2020-10-27 17:10:49.0',
                '2020-10-27 17:10:49.0','346307','Vencido','Aprobado'
            ],
        ],
    },
}

const oldModality = {
    title: 'Modalidad 2019',
    description: 'Este reporte muestra un listado de todos los estudiantes registrados con la modalidad 2019 que todavía están cursando.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateOldModalityReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'La carrera del estudiante debe estar en estado "cursando".' },
        { label: 'Criterios', value: '' },
    ],
    example: {
        header: [
            'RUT',
            'NOMBRE',
            'APELLIDO',
            'FECHA NACIMIENTO',
            'ESTADO',
            'TELÉFONO 1',
            'TELÉFONO 2',
            'CORREO',
            'REGION',
            'CARRERA',
            
        ],
        data: [
            [
                '18482150',
                'XIMENA DEL CARMEN',
                'COCA GODOY',
                '1993-05-06',
                'Cursando',
                '+56 9 5678 8255',
                '+56 9 5678 8255',
                'ximenac.lda@gmail.com',
                'CALAMA',
                '0380P/2018 - INGENIERÍA INDUSTRIAL',
                  
            ],
            [
                '16066272',
                'DANIEL EDUARDO',
                'RUIZ MUÑOZ',
                '1985-10-16',
                'Cursando',
                '+56 9 6694 8351',
                '+56 9 6694 8351',
                'danielruizmz1985@gmail.com',
                'CALBUCO',
                '0640P/2015 - TÉCNICO EN SERVICIO SOCIAL',
                
            ],
        ],
    },
}

const enrollments = {
    title: 'Registro de matrículas',
    description: 'Reporte que muestra los datos de los estudiantes matriculados.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateEnrollmentsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Se muestran todos los registros.' },
        { label: 'Criterios', value: 'Se duplican algunes estudiantes que tienen más de un legajo.' },
    ],
    example: {
        header: [
            'STUDENT_ID',
            'LEGAJO',
            'RUT',
            'NOMBRES ESTUDIANTE',
            'APELLIDOS ESTUDIANTE',
            'INGRESO',
            'ULTIMA MATRÍCULA',
            'Periodo Contrato',
            'Fecha Contrato',
            'ESTADO ESTUDIANTE',
            'TIPO ESTUDIANTE',
            'CARRERA',
            'CEO',
            'ESTADO CARRERA',
            'FECHA ESTADO CARRERA',
            'CONFIRMACIÓN CARGA',
            'MATERIAS CARRERA',
            'MATERIAS CARRERA APROBADAS',
            'TELEFONO',
            'CELULAR',
            'EMAIL',
            'PLAN ESTUDIO',
        ],
        data: [
            [
                '130133',
                '20191B0210P002',
                '17571473',
                'ANGELA ANDREA',
                'ARANEDA RAMÍREZ',
                '1B/2019',
                '2B/2022',
                '1B/2019',
                '2019-03-20',
                'Vigente',
                'Reingresante',
                '0210P - DISEÑO GRÁFICO DIGITAL',
                '529 - CENTRO CONCEPCIÓN ',
                '54 - Cursando',
                '2020-03-10 01:13:06.593',
                'SI',
                '46',
                '43',
                '99306742',
                '+56 9 9930 6742',
                'aaraneda3@gmail.com',
                '0210P/2019',
            ],
            [
                '126515',
                '20181B0610P093',
                '15686761',
                'GLORIA NILDA',
                'TERRAZAS SALFATE',
                '1B/2018',
                '2B/2021',
                '2B/2019',
                '2019-10-19',
                'Titulado',
                'Reingresante',
                '0610P - SERVICIO SOCIAL',
                '532 - CENTRO IQUIQUE',
                '145 - Titulado',
                '2020-03-10 01:12:51.731',
                'SI',
                '48',
                '45',
                '88359635',
                '+56 9 8835 9635',
                'salfate78@gmail.com',
                '0610P/2015',   
            ],
        ],
    },
}

const reenrollments = {
    title: 'Rematrículas',
    description: 'Reporte que muestra los datos de los estudiantes rematriculados.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateReenrollmentsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Sólo se pueden mostrar períodos que no hayan iniciado todavía.' },
        { label: 'Criterios', value: 'Se muestran datos de contacto, financieros y académicos.' },
    ],
    example: {
        header: [
            'STUDENT_ID',
            'LEGAJO',
            'RUT',
            'NOMBRES ESTUDIANTE',
            'APELLIDOS ESTUDIANTE',
            'INGRESO',
            'ULTIMA MATRÍCULA',
            'ESTADO ESTUDIANTE',
            'TIPO ESTUDIANTE',
            'CARRERA',
            'CEO',
            'ESTADO CARRERA',
            'FECHA ESTADO CARRERA',
            'CUOTA',
            'FECHA PAGO CUOTA',
            'ARANCEL',
            'FECHA CONTRATO',
            'BECA',
            'CONFIRMACIÓN CARGA',
            'MATERIAS CARRERA',
            'MATERIAS CARRERA APROBADAS',
            'CUOTAS VENCIDAS(1 DIA O MAS)',
            'MONTO VENDIO(1 DIA O MAS)',
            'REPACTACIÓN',
            'FECHA REPACTACIÓN',
            'DEUDA REPACTACION',
            'TELEFONO',
            'CELULAR',
            'EMAIL',
            'PLAN ESTUDIO',
            'CONVENIO',
            'FECHA NACIMIENTO',
            'SEXO',
            'NACIONALIDAD',
        ],
        data: [
            [
                '120955',
                '20211B0320PE0001',
                '16450560-K',
                'DANIELA DE LOURDES',
                'GONZÁLEZ CARMONA',
                '1B/2021',
                '2B/2022',
                '21 - Vigente',
                '8 - Reingresante',
                '0320PE - CONTADOR AUDITOR (PLAN ESPECIAL)',
                '522 - CEAT VALLENAR',
                '54-Cursando',
                '2021-05-13',
                'null',
                'null',
                'null',
                'null',
                'NO',
                '2022-11-26',
                '24',
                '18',
                '4',
                '409198.421875',
                'SI',
                '2022-10-27',
                '279452.65625',
                'null',
                '41201636',
                'daniela.gcarmona30@gmail.com',
                '0320PE/20191A',
                'null',
                '1986-11-07',
                'Femenino',
                'Chilena',
            ],
            [
                '120961',
                '20141A0610G059',
                '14059465-2',
                'ROSSANA PAUILINA',
                'TAPIA ESTUARDO',
                '1A/2014',
                '1A/2023',
                '21 - Vigente',
                '8 - Reingresante',
                '0610P - SERVICIO SOCIAL',
                '529 - CENTRO CONCEPCIÓN', 
                '54-Cursando',
                '2020-03-10',
                '136000',
                '2023-02-12',
                '172156.40625',
                '2023-02-13',
                'NO',
                '2023-02-13',
                '45',
                '32',
                'null',
                'null',
                'NO',
                'null',
                'null',
                'null',
                '65587761',
                'rossana.t.e@hotmail.com',
                '0610P/20151A',
                'null',
                '1981-09-03',
                'Femenino',
                'Chilena',
            ],
        ],
    },
}

export default {alumnosPpsInscriptos, performance, enrollments, reenrollments, oldModality, mandatoryDocumentation, processings, nps, levels, seminar, alumnosPps, alumnosPpsAProbadas, alumnosEstadosPps, inscriptions, alumnosDatosGenerales, admissions, performanceRi, graduate, alumnosDatosEgresados, academic, historialAlumnosMateriasStatus, english, confirmedSubjects, curricularAdvancement, alumnosInscripcionPps, practice,};

