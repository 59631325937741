<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!sendFileStatus">
      <Breadcrumbs
        :title="$t('modules.messaging.whatsapp.breadcrumbs.title')"
        :description="$t('modules.messaging.whatsapp.breadcrumbs.description')"
        >
        <Button
          primary
          :text="$t('modules.messaging.whatsapp.breadcrumbs.buttom.template')"
          class="mr-4"
          @clicked="openDrawer"
        ></Button>
      </Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="14">
          <OutlinedCard
            :title="$t('modules.messaging.whatsapp.template_card.title')"
            class="mb-6"
          >
            <v-row>
                <v-col sm="6" class="py-0">
                    <v-select
                        v-model="form.selectedArea"
                        :items="areas"
                        return-object
                        item-text="name"
                        :label="$t('modules.messaging.whatsapp.template_card.label_area')"
                        :error-messages="($v.form.selectedArea.$dirty && $v.form.selectedArea.$invalid) ? $t('modules.messaging.whatsapp.template_card.areaRequired') : ''"
                        @blur="$v.form.selectedArea.$touch()"
                        class="pt-2"
                        outlined
                    ></v-select>
                </v-col>
                <v-col sm="6" class="py-0">
                    <v-select
                        v-model="form.selectedNumber"
                        :disabled="!form.selectedArea"
                        :items="numbers"
                        return-object
                        item-text="name"
                        :label="$t('modules.messaging.whatsapp.template_card.numero_de_area')"
                        :error-messages="($v.form.selectedNumber.$dirty && $v.form.selectedNumber.$invalid) ? $t('modules.messaging.whatsapp.template_card.numberRequired') : ''"
                        @blur="$v.form.selectedNumber.$touch()"
                        class="pt-2"
                        outlined
                    ></v-select>
                </v-col>
                <v-col sm="12" cols="10" class="pt-4">
                    <v-autocomplete
                        v-model="form.selectedTemplate"
                        :disabled="!form.selectedArea"
                        :items="templates"
                        return-object
                        item-text="elementName"
                        :label="$t('modules.messaging.whatsapp.template_card.campaña')"
                        :error-messages="($v.form.selectedTemplate.$dirty && $v.form.selectedTemplate.$invalid) ? $t('modules.messaging.whatsapp.template_card.templateRequired') : ''"
                        @blur="$v.form.selectedTemplate.$touch()"
                       class="mt-0 "
                        outlined
                    ></v-autocomplete>
                </v-col>
            </v-row>
          
          <section class="mt-6">
            <div class="d-flex">
              <h3 class="primary--text mr-2">{{$t('modules.messaging.whatsapp.template_card.subtitle')}}</h3>
              <h3 class="text--secondary">{{setTitle}}</h3>
            </div>
            <div v-if="form.selectedTemplate" class="px-8 py-6 mt-4 background-campaign text-center">
              <p class="text--secondary mb-0">{{form.selectedTemplate ? form.selectedTemplate.template : ''}}</p>
            </div>
          </section>
              <h5 class="mt-4">{{$t('modules.messaging.whatsapp.fileUpload.subtitle')}}</h5>
             
            <div class="mt-16">
              <h3>{{$t('modules.messaging.whatsapp.fileUpload.description')}}</h3>
               <p class="text--secondary">{{$t('modules.messaging.whatsapp.fileUpload.title')}}</p>
            </div>

            <DropFiles
                v-model='form.file'
                :extension="'.xls,.xlsx,.xlt'"
                type-file="excel"
                limitSizeBytes="2000000"
                :showTitle="true"
                :showSubtitle="true"
                :showDashed="true"
                :required="$v.form.file.$anyError"     
                @update:fileInput="setFileProgram($event)"
            >
             </DropFiles>
          </OutlinedCard>
          
        <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.replace('/')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="sendButton.loading"
              :success="sendButton.success"
              :error="sendButton.error"
              :text="$t('actions.send')"
              :successText="$t('actions.sent')"
              :errorText="$t('actions.error')"
              :disabled="!canCreate"
              @end="resetButtonValues"
              @clicked="sendFile"
            ></Button>
        </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :subtitle="successMessage.subtitle"
      :actionPrimary="successMessage.actionPrimary"
    />
     <TemporaryRightDrawer
        @tmpDrawerOpen="drawerOpen = false"
        :open="drawerOpen"
        :title="$t('modules.messaging.whatsapp.cargarTemplate.title')"
        :description="$t('modules.messaging.whatsapp.cargarTemplate.subtitle')"
      >
      <UploadTemplate
        :areasArray="areas"
        @updateTemplate="getArea"
        @closeRightDrawer="drawerOpen = false"
      ></UploadTemplate>
      </TemporaryRightDrawer>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import DropFiles from '@/components/DropFiles/DropFiles';
import UploadTemplate from '../Components/UploadTemplate.vue';
import {$areas,$messagePhone} from '../Services'; 
import {required} from 'vuelidate/lib/validators';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'

export default {
  name: 'WhatsappView',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
    DropFiles,
    UploadTemplate,
    TemporaryRightDrawer,
  },
  data() {
    return {
      areas: [],
      template: null,
      form: {
        selectedArea: null,
        selectedTemplate: null,
        selectedNumber: null,
        file: null,
      },
      sendFileStatus: null,
      nameCampaign: null,
      sendButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        subtitle: null,
        actionPrimary: null,
      },
      drawerOpen: false,
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.messaging.whatsapp.READ;
    },
    numbers() {
      for (let index = 0; index < this.areas.length; index++) {
        if(this.form.selectedArea?.name == this.areas[index].name) {
          return this.areas[index].telefonos.map((e)=>{
          return{
            name: `${e.waId} - ${e.name}`,
            id: e.id
          }
        })
        }
       }
       return[]
    },
    templates() {
      for (let index = 0; index < this.areas.length; index++) {
        if(this.form.selectedArea?.name == this.areas[index].name) {
          return this.areas[index].templates
          }
        }
        return[]
       },
    setTitle() {
      return this.form.selectedTemplate ? this.form.selectedTemplate.elementName: ''
    },
    },
  mounted() {
    this.getArea()
  },
  methods: {
    openDrawer() {
        this.drawerOpen = true
      },
    openModal(){
      this.open = true;
    },
    async getArea() {
      try{
        const areas = await $areas.find()
        this.areas = areas.data
      } catch(error){
        return false
      }
    },
    resetButtonValues() {
       this.sendButton.loading = false;
       this.sendButton.success = false;
       this.sendButton.error = false;
     },
     resetValues () {
       this.form.selectedArea = null;
       this.form.selectedNumber = null;
       this.form.selectedTemplate = null;
       this.form.file = null;
     },
    async sendFile() {
      if(this.$v.form.$invalid) this.$v.form.$touch()
      try{
        this.sendButton.loading = true;
        const formData = new FormData()
        formData.append('parametros', this.form.file)
        const response = await $messagePhone.formData(formData,`template/${this.form.selectedTemplate.id}`, this.form.selectedNumber.id)
        const data = JSON.parse(await response.text())
        if (data) {
          this.sendFileStatus = true
          this.sendButton.success = true;
          this.successMessage.type = 'success'
          this.successMessage.title = this.$t('modules.messaging.whatsapp.successMessage.title')
          this.successMessage.subtitle = this.$t('modules.messaging.whatsapp.successMessage.subtitle')
          this.successMessage.actionPrimary = {text:this.$t('actions.close'), callback: () =>{
            this.resetValues()
            this.$v.$reset()
            this.sendFileStatus = false
          }}
          if (data.data) {
            const mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.data}`
            const link = document.createElement('a');
            link.href = mediaType;
            link.download = 'envio-mensajeria';
            link.click();
          }
        }

      } catch(error){         
          this.sendButton.error = true;
          this.sendFileStatus = true;
          this.successMessage.type = 'error'
          this.successMessage.title = error?.message ?? this.$t('modules.messaging.whatsapp.successMessage.errorMessage')
          this.successMessage.subtitle = null
          this.successMessage.actionPrimary =  {text:this.$t('actions.close'), callback: () =>{
            this.sendFileStatus = false
          }}
          throw error
      } finally {
        this.sendButton.loading = false;
      }
    },
    setFileProgram(file) {
      this.form.file = file;
    },
  },
  validations: {
    form: {
      selectedArea: { required },
      selectedNumber: { required },
      selectedTemplate: { required },
      file: { required }
    }
  },
};
</script>

<style lang="sass" scoped>
  .background-campaign
   background-color: rgba(216,218,231,.1)
</style>