import { render, staticRenderFns } from "./MultipleCustomCardsChips.vue?vue&type=template&id=fa65f250&scoped=true&"
import script from "./MultipleCustomCardsChips.vue?vue&type=script&lang=js&"
export * from "./MultipleCustomCardsChips.vue?vue&type=script&lang=js&"
import style0 from "./MultipleCustomCardsChips.vue?vue&type=style&index=0&id=fa65f250&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa65f250",
  null
  
)

export default component.exports