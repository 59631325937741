<template>
    <v-container fluid class="superTable pa-0">
        <v-row no-gutters>
            <v-col sm="4">
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="$t('actions.search')"
                    outlined
                    clearable
                    class="superTable__search"
                    @keyup="keyup"
                    @keyup.esc="search = ''"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    :show-select="false"
                    :headers="headers"
                    :items="data"
                    :search="search"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    item-key="id"
                    class="superTable__table"
                    style="table-layout: fixed"
                >
                    <template v-slot:[`item.status`]="{ item }">
                        <v-icon size="12" :color="(item.status) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
                    </template>

                    <template  v-slot:[`item.action`]="{ item }">
                        <v-menu bottom left >
                            <template 
                            v-if="permissions.CREATE"
                            v-slot:activator="{ on }"
                            >
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item v-if="item.status">
                                    <v-list-item-title @click= "$emit('confirm-action',item)">
                                        <v-icon>mdi-currency-usd</v-icon> {{ $t('modules.subject0.table.actions.popUpDeshabilitar') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-else>
                                    <v-list-item-title @click= "$emit('confirm-action',item)">
                                        <v-icon>mdi-currency-usd</v-icon> {{ $t('modules.subject0.table.actions.popUpHabilitar') }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
        <v-row no-gutters align="center">
            <v-col sm="3">
                <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
                <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageAvailable"
                    :placeholder="''+itemsPerPage"
                    outlined
                    class="superTable__paginationSelect d-inline-block ml-4"
                ></v-select>
            </v-col>
            <v-col sm="6" class="text-center">
                <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
            </v-col>
            <v-col sm="3" align="right">
                <v-text-field
                    v-model="page"
                    max="pageCount"
                    outlined
                    class="superTable__paginationInput d-inline-block mr-4"
                ></v-text-field>
                <span class="caption d-inline-block">{{ $t('modules.communities.table.goToPage') }}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    export default {
        name: 'SuperTable',
        props: {
            data: Array,
            headers: Array,
            permissions: Object
        },
        data() {
            return {
                search: '',
                timeout: null,
                page: 1,
                itemsPerPage: 20,
                itemsPerPageAvailable: [20, 50, 100, 200],
            }
        },
        methods: {
            keyup () {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.searchAction()
                    clearTimeout(this.timeout);
                },1000)
            },
            searchAction() {
                this.$emit('search-action', this.search)
            }
        },
        watch:{
            page(val) {
                if(val > this.pageCount) this.page = this.pageCount;
                if(val <= 1) this.page = 1;
            },
        },
        computed: {
            pageCount () {
                let counter = this.data.length / this.itemsPerPage;
                if (counter < 1) counter = 1
                return Math.ceil(counter)
            },
            itemsCount () {
                const counter = {
                    totalItems: this.data.length,
                    startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) +  1,
                    endItem: this.page * this.itemsPerPage
                }
                if ( counter.endItem > this.data.length ) counter.endItem = this.data.length
                return counter
            }
        }
    };
</script>