<template>
    <div>
        <v-container class="pa-0 pb-8" fluid>
            <v-container class="container-custom px-6" v-if="!updateStatus">
                <v-row justify="center">
                    <v-col sm="12">
                        <Breadcrumbs 
                            :description="$t('modules.profile.breadcrumbs.description')"
                            :title="$t('modules.profile.breadcrumbs.title')">
                            <Button
                            :text="$t('modules.profile.changePassword2.label')"
                            outlined
                            v-show="show"
                            :disabled="disableButtonChangePassword"
                            @clicked="openModal"
                            class="mr-2"
                            ></Button>
                        </Breadcrumbs>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col class="viewSpaces" sm="12">
                        <OutlinedCard
                            :title="$t('modules.profile.sectionData.title')"
                            class="mb-6" >
                            <v-container class="pa-0" fluid>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionData.avatar') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="8">
                                        <div class="d-flex align-center">
                                            <AvatarUpload
                                                rounded
                                                :image="imagePreview"
                                                :model="userData.avatar"
                                                @save="createImage"
                                            ></AvatarUpload>
                                            <span class="caption ml-4 grey--text">{{ $t('modules.profile.sectionData.avatarDescription') }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionData.name') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="4">
                                        <v-text-field
                                            v-model="$v.userData.name.$model"
                                            :error-messages="($v.userData.name.$dirty && $v.userData.name.$invalid) ? $t('modules.profile.sectionData.validations.nameRequired') : ''"
                                            :label="`${$t('modules.profile.sectionData.name')} *`"
                                            @input="$v.userData.name.$touch()"
                                            @blur="$v.userData.name.$touch()"
                                            outlined
                                            type="text">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionData.lastname') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="4">
                                        <v-text-field
                                            v-model="$v.userData.lastname.$model"
                                            :error-messages="($v.userData.lastname.$dirty && $v.userData.lastname.$invalid) ? $t('modules.profile.sectionData.validations.lastnameRequired') : ''"
                                            :label="`${$t('modules.profile.sectionData.lastname')} *`"
                                            @input="$v.userData.lastname.$touch()"
                                            @blur="$v.userData.lastname.$touch()"
                                            outlined
                                            type="text">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionData.birthDate') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="2">
                                        <BirthdayDatePicker
                                            v-model="userData.birthDate"
                                            :label="$t('modules.profile.sectionData.birthDate')">
                                        </BirthdayDatePicker>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionData.gender') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="2">
                                        <v-select
                                            v-model="userData.gender"
                                            :items="listGender"
                                            :label="$t('modules.profile.sectionData.gender')"
                                            @blur="$v.userData.$touch()"
                                            outlined
                                            return-object
                                            item-text="meaning">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionData.identificationType') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="2">
                                        <v-select
                                            v-model="userData.identificationType"
                                            :items="listTypeIdentify"
                                            :label="$t('modules.profile.sectionData.identificationType')"
                                            @blur="$v.userData.$touch()"
                                            outlined
                                            return-object
                                            item-text="meaning">
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionData.identification') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="4">
                                        <span>{{ userData.identification }}</span>
                                        <!-- <v-text-field
                                            v-model="$v.userData.identification.$model"
                                            :error-messages="($v.userData.identification.$dirty && $v.userData.identification.$invalid) ? $t('modules.profile.sectionData.validations.identificationRequired') : ''"
                                            :label="`${$t('modules.profile.sectionData.identification')} *`"
                                            v-mask="(userData.identificationType.meaning == 'RUT') ? ['#######-#', '########-#'] : 'XXXXXXXXXXXXXXX'"
                                            @input="$v.userData.identification.$touch()"
                                            @blur="$v.userData.identification.$touch()"
                                            outlined
                                            type="text">
                                        </v-text-field> -->
                                    </v-col>
                                </v-row>
                            </v-container>
                        </OutlinedCard>
                        <OutlinedCard
                            :title="$t('modules.profile.sectionContact.title')"
                            class="mb-6"
                        >
                            <v-container class="pa-0" fluid>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionContact.email') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="4">
                                        <v-text-field
                                            v-model="$v.userData.email.$model"
                                            :error-messages="($v.userData.email.$dirty && $v.userData.email.$invalid) ? (($v.userData.email.required) ? $t('modules.profile.sectionContact.validations.emailEmail') : $t('modules.profile.sectionContact.validations.emailRequired')) : ''"
                                            :label="`${$t('modules.profile.sectionContact.email')} *`"
                                            @input="$v.userData.email.$touch()"
                                            @blur="$v.userData.email.$touch()"
                                            outlined
                                            type="text">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionContact.street') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="4">
                                        <v-text-field
                                            v-model="userData.address.street"
                                            :error-messages="($v.userData.address.street.$dirty && $v.userData.address.street.$invalid) ? $t('modules.profile.sectionContact.validations.street') : ''"
                                            :label="$t('modules.profile.sectionContact.street')"
                                            @blur="$v.userData.address.street.$touch()"
                                            outlined
                                            type="text">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionContact.number') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="2">
                                        <v-text-field
                                            v-model="userData.address.number"
                                            :label="$t('modules.profile.sectionContact.number')"
                                            @keypress="$validateIntegerInputNumber($event, userData.address.number, null, 6)"
                                            :error-messages="($v.userData.address.number.$dirty && $v.userData.address.number.$invalid) ? $t('modules.profile.sectionContact.validations.number') : ''"
                                            @blur="$v.userData.address.number.$touch()"
                                            outlined
                                            type="text">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionContact.floor') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="2">
                                        <v-text-field
                                            v-model="userData.address.floor"
                                            :label="$t('modules.profile.sectionContact.floor')"
                                            @keypress="$validateIntegerInputNumber($event, userData.address.floor, 200)"
                                            @blur="$v.userData.$touch()"
                                            outlined
                                            type="text">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionContact.apartment') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="2">
                                        <v-text-field
                                            v-model="userData.address.apartment"
                                            :label="$t('modules.profile.sectionContact.apartment')"
                                            @keypress="$validateLengthInput($event, 10)"
                                            @blur="$v.userData.$touch()"
                                            outlined
                                            type="text">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionContact.zipCode') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="2">
                                        <v-text-field
                                            v-model="userData.address.zipCode"
                                            :label="$t('modules.profile.sectionContact.zipCode')"
                                            @keypress="$validateLengthInput($event, 6)"
                                            @blur="$v.userData.$touch()"
                                            outlined
                                            type="text">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionContact.cityWithState') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="4">
                                        <AutocompleteInput
                                            :value.sync="$v.userData.address.cityWithState.$model"
                                            :errorMessages="$t('modules.profile.sectionContact.validations.city')"
                                            :label="$t('modules.profile.sectionContact.cityWithState')"
                                            :disable-buttons="true"
                                            :required="$v.userData.address.cityWithState.$invalid"
                                            @save="$v.userData.address.cityWithState.$model = $event">
                                        </AutocompleteInput>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionContact.phone') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="4">
                                        <TelephoneInput
                                            :value.sync="userData.phone"
                                            :placeholder="$t('modules.profile.sectionContact.phonePlaceholder')"
                                            @blur="$v.userData.$touch()"
                                        ></TelephoneInput>
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionContact.cellphone') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="4">
                                        <TelephoneInput
                                            :value.sync="$v.userData.cellphone.$model"
                                            :required="true"
                                            :placeholder="$t('modules.profile.sectionContact.cellphonePlaceholder') + ' *'"
                                            :error="$v.userData.cellphone.$dirty && $v.userData.cellphone.$invalid"
                                            @blur="$v.userData.cellphone.$touch()"
                                        ></TelephoneInput>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </OutlinedCard>
                        <!-- <OutlinedCard
                            :title="$t('modules.profile.sectionAcademic.title')"
                            class="mb-6"
                        >
                            <v-container class="pa-0" fluid>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionAcademic.fileNumber') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="8">
                                        <!- <IndependentInput
                                            :disabled="true"
                                            :input="userData.fileNumber"
                                            @save="(value)=>saveValue('userData.fileNumber', value)"
                                        ></IndependentInput> ->
                                    </v-col>
                                </v-row>
                                <v-row class="py-2" no-gutters>
                                    <v-col class="d-flex align-center" sm="4">
                                        <span class="caption text-uppercase">{{ $t('modules.profile.sectionAcademic.userProfile') }}</span>
                                    </v-col>
                                    <v-col class="align-center" sm="8">
                                        <!- <IndependentInput
                                            :disabled="true"
                                            :input="userData.userProfile"
                                            @save="(value)=>saveValue('userData.userProfile', value)"
                                        ></IndependentInput> ->
                                    </v-col>
                                </v-row>
                            </v-container>
                        </OutlinedCard> -->
                        <div class="d-flex justify-end">
                            <Button
                                white
                                :text="$t('actions.cancel')"
                                @clicked="$router.replace('/')"
                                @end="resetButtonValues()"
                                class="mr-4"
                            ></Button>

                            <Button
                                :loading="saveButton.loading"
                                :success="saveButton.success"
                                :error="saveButton.error"
                                :text="$t('actions.save')"
                                :successText="$t('actions.saved')"
                                :errorText="$t('actions.error')"
                                @clicked="updateProfilePopup"
                                @end="resetButtonValues()"
                                :disabled="$v.userData.$invalid"
                            ></Button>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <SuccessMessage
                v-else
                :type="successMessage.type"
                :title="successMessage.title"
                :actionPrimary="successMessage.actionPrimary"
            />
        </v-container>
    </div>
</template>

<style scoped lang="sass">
    .no-shadow
        border-top: 1px solid rgba(0, 0, 0, 0.12)
        border-bottom: 1px solid rgba(0, 0, 0, 0.12)

        &:before
            box-shadow: unset !important

        .wrap-outlinecard
            .row
                min-height: 82px
</style>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import Button from '@/components/Button/Button';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import AvatarUpload from '@/components/AvatarUpload/AvatarUpload';
    import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
    import BirthdayDatePicker from '@/components/BirthdayDatePicker/BirthdayDatePicker'
    import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
    import {mapGetters} from 'vuex';
    import { $users, $types } from '../Services';
    import {required, email} from 'vuelidate/lib/validators'
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';

    export default {
        components: {
            Button,
            Breadcrumbs,
            OutlinedCard,
            AvatarUpload,
            AutocompleteInput,
            BirthdayDatePicker,
            TelephoneInput,
            SuccessMessage,
        },
        data() {
            return {
                disableButtonChangePassword:true,
                imagePreview: null,
                updateStatus: false,
                saveButton: {
                    loading: false,
                    success: false,
                    error: false,
                },
                successMessage: {},
                avatar: '',
                originalAvatar: '',
                originalEmail: null,
                userData: {
                    // sectionData
                    id: '',
                    name: '',
                    lastname: '',
                    birthDate: null,
                    gender: [],
                    password: '',
                    identificationType: [],
                    identification: '',
                    // sectionContact
                    email: '',
                    address: {
                        number: null,
                        floor: null,
                        apartment: null,
                        zipCode: null,
                        street: null,
                        cityWithState: null,
                    },
                    phone: '',
                    cellphone: '',
                    // sectionAcademic
                    fileNumber: '',
                    userProfile: ''
                },
                listGender: [],
                listTypeIdentify: [],
                changePasswordStatus: {
                    loading: false,
                    success: null,
                    error: null
                },
                passInputs: {
                    currentPass: '',
                    newPass: '',
                    newPassRepeated: ''
                },
                show: false,
            }
        },
        computed: {
            ...mapGetters([
                'commons/getUserData',
                'commons/getUserID',
                'commons/getAvatarImageUrl',
            ]),
        },
        created() {
            this.imagePreview = this['commons/getAvatarImageUrl']
        },
        async mounted() {
            if(localStorage.getItem('is_cognito_user')==='true'){
                    this.disableButtonChangePassword=true;
                }else{
                    this.disableButtonChangePassword=false;
                }
            try {
                this.listGender = await this.getDataUser('types', 'GENDER_TYPE');
                this.listTypeIdentify = await this.getDataUser('types', 'IDENTIFICATION_TYPE');
                this.fetchData()
               
                
                
                // TODO Update next line, when back-end return the array
                // this.userData.fileNumber = this.userData.fileNumber.filter(active => active.status = true)[0].number?;
            } catch {
                this.listGender = []
            }
        },
    methods: {
            openModal(){
               this.$store.dispatch('commons/openPasswordPopUp')
            },
            closeModal(){
                this.$store.dispatch('commons/closePasswordPopUp')
            },
            createImage (file) {
                try {
                    this.avatar = file;
                    const reader = new FileReader();

                    reader.onload = (e) => {
                        this.setImagePreview(e.target.result);
                    };
                    reader.onerror = () => {
                        this.setImagePreview(this.originalAvatar);
                        reader.abort();
                    };
                    reader.readAsDataURL(this.avatar);
                } catch (err) {
                    this.setImagePreview(this.originalAvatar);
                }
            },
            setImagePreview(image) {
                this.imagePreview = image;
            },
            async fetchData() {
                const data = await this['commons/getUserData'];
                this.imagePreview = data.avatar
                this.avatar = data.avatar,
                this.originalAvatar = data.avatar,
                this.originalEmail = data.email,
                this.show = true
                this.userData = {
                    id: data.userId,
                    name: data.name,
                    lastname: data.lastname,
                    birthDate: data.birthDate,
                    gender: data.gender,
                    password: '',
                    identificationType: data.identificationType,
                    identification: data.identification,
                    // sectionContact
                    email: data.email,
                    address: {
                        number: data.address.number,
                        floor: data.address.floor,
                        apartment: data.address.apartment,
                        zipCode: data.address.zipCode,
                        street: data.address.street,
                        cityWithState: data.address.cityWithState,
                    },
                    phone: data.phone,
                    cellphone: data.cellphone,
                    // sectionAcademic
                    fileNumber: '',
                    userProfile: ''
                }
            },
            updateProfilePopup() {
                if (this.originalEmail !== this.userData.email) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('alert'),
                        content: [{ value: this.$t('modules.profile.success_message.logout_message') }],
                        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.updateProfile() },
                        actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                    });
                } else {
                    this.updateProfile()
                }
            },
            async updateProfile() {
                try {
                    this.saveButton.loading = true
                    const user = {
                        id: this.userData.id,
                        userData: this.userData,
                    }

                    await $users.update(user.id, user)

                    if (this.avatar !== this.originalAvatar) {
                        const formData = new FormData();
                        formData.append('file', this.avatar);
                        await $users.formData(formData, 'avatar', this.userData.id)
                        this.$store.dispatch('commons/updateAvatar');
                    }

                    if (this.originalEmail !== this.userData.email) {
                        this.successMessage.actionPrimary = {text: this.$t('modules.profile.success_message.actions.primary_text'), callback: () => {this.$auth.logout()}}
                    } else {
                        await this.$store.dispatch('commons/initData')
                        this.successMessage.actionPrimary = {text: this.$t('modules.profile.success_message.actions.primary_text'), callback: () => {this.$router.push('/')}}
                    }

                    this.saveButton.success = true;
                    this.successMessage.type = 'success';
                    this.successMessage.title = this.$t('modules.profile.success_message.title');
                } catch (err) {
                    this.saveButton.error = true;
                    this.successMessage.type = 'error';
                    this.successMessage.title = (err.codeMeaning == '') ? this.$t('modules.profile.error_message.title') : err.codeMeaning
                    this.successMessage.actionPrimary = {text: this.$t('modules.profile.success_message.actions.back'), callback: () => {this.updateStatus = false}}
                } finally {
                    this.updateStatus = true
                    this.resetButtonValues()
                }
            },
            resetButtonValues () {
                this.saveButton.success = false;
                this.saveButton.error = false;
                this.saveButton.loading = false;
            },
            resetButtonValuesStatus(){
                this.changePasswordStatus.success = false
                this.changePasswordStatus.error = false
                this.changePasswordStatus.loading = false
            },
            async getDataUser(endpoint, typeData) {
                let listData = [];
                listData = await $types.find(null, null, {params: {type: typeData}});
                return listData.data
            },
            async changePassword() {
                this.changePasswordStatus.loading = true;
                const prevPass = this.passInputs.currentPass;
                const {newPass} = this.passInputs;
                const user = this['commons/getUserData'].userId;

                await this.$users.changePassword(user, prevPass, newPass)
                    .then(() => {
                        this.changePasswordStatus.success = true
                        setTimeout(() => {
                            this.$refs.pass.$v.$reset()
                            this.passInputs.currentPass = null
                            this.passInputs.newPass = null
                            this.passInputs.newPassRepeated = null
                        }, 2000)
                    })
                    .catch(() => {
                        this.changePasswordStatus.error = true;
                    })
                    this.resetButtonValuesStatus()
            },
            //GET NESTED PROPERTY BY ARRAY REDUCE
            getNestedProperty(path) {
                path = path.split('.');
                return path.reduce((obj, key) => (obj && obj[key]) ? obj[key] : null, this);
            },
            async saveValue(fieldModel, value, type = null) {
                if (type === 'avatarUpload') {
                    const formData = new FormData();
                    formData.append('file', value);
                    const userId = this['commons/getUserID'];
                    this.$users.sendAvatar(formData, userId).then(() => {
                        this.$store.dispatch('commons/updateAvatar');
                    })
                } else {
                    const user = this.createAssociativeArray(fieldModel, value);
                    user.id = this['commons/getUserID'];
                    const updateUser = await this.$users.updateUser(user);
                    if (updateUser) {
                        const dataUser = await this.$users.getUserData();
                        await this.$store.dispatch('commons/setUserData', dataUser);
                        this.userData = await this['commons/getUserData'];
                    }
                }
            },
            createAssociativeArray(string, object) {
                const parts = string.split('.');
                const last = parts[parts.length - 1];
                const tree = {};
                const node = parts.slice(0, -1).reduce(function (memo, current) {
                    return (memo[current] = {});
                }, tree);
                node[last] = object;
                return tree;
            },
            enableEdit () {
                this.disabled = !this.disabled;
                this.show = false
                },
            disableEdit () {
                this.disabled = !this.disabled;
                this.show = true
                },
        },
        validations: {
            userData: {
                name: { required },
                lastname: { required },
                identification: { required },
                email: { required, email },
                cellphone: { required },
                address: {
                    street: {required},
                    number: {required},
                    cityWithState: {required}
                },
            }
        }
    }
</script>
