<template>
  <v-row class="transferList">
    <v-col class="transferList__list-left py-0">
      <h4 class="title mb-4">{{firstListTitle}}</h4>
      <v-card flat outlined>
        <v-data-iterator :items="availableItems" :search="search[0]" hide-default-footer>
          <template v-slot:header>
            <v-text-field
              v-model="search[0]"
              :loading="loading"
              clearable
              flat
              solo
              hide-details
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('components.transferlistTranslations.search')"
              class="transferList__list__search"
              @keyup="searchLeft"
              @click:clear="searchLeft"
            ></v-text-field>
          </template>

          <template v-slot:default>
            <v-list class="py-0">
              <v-subheader class="transferList__list__header d-flex justify-space-between">
                <div class="d-flex align-center">
                  <v-checkbox
                    ref="allLeftCheckbox"
                    color="primary"
                    class="ma-0 pt-4"
                    v-model="available"
                    :indeterminate="leftIndeterminate"
                    @change="($event) => moveAllToTheOtherSide($event)"
                  ></v-checkbox>
                  {{ $t('components.transferlistTranslations.select') }}
                </div>
                <div>
                  <span class="caption">{{`${selecteds[0].length + selectedsOnSearch.length} ${$t('components.transferlistTranslations.selecteds')}`}}</span>
                </div>
              </v-subheader>

              <v-list-item-group v-model="left" multiple class="transferList__list__content" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-throttle-delay="700">
                <draggable v-model="leftItems" group="items" @start="drag=true" @end="endDrag">
                  <template v-for="(item, i) in leftItems">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                    <v-list-item
                      v-else
                      :key="`item-${item.id}`"
                      :value="item"
                      :disabled="!drag && disabledItem(item)"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-icon class="mr-2">
                          <v-icon>mdi-drag-vertical</v-icon>
                        </v-list-item-icon>
                        <v-list-item-action class="mr-2">
                          <v-checkbox
                            color="primary"
                            :input-value="active"
                            :true-value="item"
                            :disabled="!drag && disabledItem(item)"
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-avatar v-if="avatar" size="32" class="mr-4">
                          <img :src="item.avatar" />
                        </v-avatar>
                        <v-list-item-content class="d-flex">
                          <span class="body-2" v-text="textItem(item)"></span>
                          <v-chip
                            v-if="chips"
                            x-small
                            :color="!item[valueDisable] ? 'success' : 'grey lighten-2'"
                            :dark="!!item[valueDisable]"
                          >{{ !item[valueDisable] ? 'Disponible' : item[valueDisable][showInChip] }}</v-chip>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </draggable>
              </v-list-item-group>
            </v-list>
          </template>

          <template v-slot:no-data>
            <draggable v-model="leftItems" group="items" @start="drag=true" @end="endDrag">
              <p
                class="overline pt-4 pl-4 grey--text"
              >{{ $t('components.transferlistTranslations.empty_list') }}</p>
            </draggable>
          </template>
          <template v-slot:no-results>
            <draggable v-model="leftItems" group="items" @start="drag=true" @end="endDrag">
              <p
                class="overline pt-4 pl-4 grey--text"
              >{{ $t('components.transferlistTranslations.no_records') }}</p>
            </draggable>
          </template>
        </v-data-iterator>
      </v-card>
    </v-col>
    <v-col class="transferList__actions">
      <v-btn
        :disabled="addButtonDisable"
        @click="() => addOrRemoveItem()"
        class="transferList__actions__btn"
      >
        <v-icon size="20" color="grey darken-1">mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn
        :disabled="removeButtonDisable"
        @click="() => addOrRemoveItem(false)"
        class="transferList__actions__btn"
      >
        <v-icon size="20" color="grey darken-1">mdi-arrow-left</v-icon>
      </v-btn>
    </v-col>
    <v-col class="transferList__list-right py-0">
      <h4 class="title mb-4">{{secondListTitle}}</h4>
      <v-card flat style="border: 1px dotted #ebebeb;">
        <v-data-iterator :items="addedItems" :search="search[1]" hide-default-footer>
          <template v-slot:header>
            <v-text-field
              v-model="search[1]"
              clearable
              flat
              solo
              hide-details
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('components.transferlistTranslations.search')"
              class="transferList__list__search"
              @keyup="searchRight"
              @click:clear="searchRight"
            ></v-text-field>
          </template>

          <template v-slot:default>
            <v-list class="py-0">
              <v-subheader class="transferList__list__header d-flex justify-space-between">
                <div class="d-flex align-center">
                  <v-checkbox
                    ref="allRightCheckbox"
                    color="primary"
                    class="ma-0 pt-4"
                    v-model="added"
                    :indeterminate="rightIndeterminate"
                    @change="($event) => moveAllToTheOtherSide($event, false)"
                  ></v-checkbox>
                  {{ $t('components.transferlistTranslations.select') }}
                </div>
                <div>
                  <span class="caption">{{`${selecteds[1].length + selectedsOnSearch.length} ${$t('components.transferlistTranslations.selecteds')}`}}</span>
                </div>
              </v-subheader>
              <v-list-item-group v-model="right" multiple class="transferList__list__content">
                <draggable
                  v-model="rightItems"
                  group="items"
                  @start="drag=true"
                  @end="endDrag($event, true)"
                >
                  <template v-for="(item, i) in rightItems">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                    <v-list-item
                      v-else
                      :key="`item-${item.id}`"
                      :value="item"
                      :disabled="disabledItemRight(item)"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-icon class="mr-2">
                          <v-icon>mdi-drag-vertical</v-icon>
                        </v-list-item-icon>
                        <v-list-item-action class="mr-2">
                          <v-checkbox
                            color="primary"
                            :input-value="active"
                            :true-value="item"
                            :disabled="disabledItemRight(item)"
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-avatar v-if="avatar" size="32" class="mr-4">
                          <img :src="item.avatar" />
                        </v-avatar>
                        <v-list-item-content>
                          <span class="body-2" v-text="textItem(item)"></span>
                          <v-chip
                            v-if="chips"
                            x-small
                            :color="!item[valueDisable] ? 'success' : 'grey lighten-2'"
                            :dark="!!item[valueDisable]"
                          >{{ !item[valueDisable] ? 'Disponible' : item[valueDisable][showInChip] }}</v-chip>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </draggable>
              </v-list-item-group>
            </v-list>
          </template>

          <template v-slot:no-data>
            <draggable
              v-model="rightItems"
              group="items"
              @start="drag=true"
              @end="endDrag($event, true)"
            >
              <p
                class="overline pt-4 pl-4 grey--text"
              >{{ $t('components.transferlistTranslations.empty_list') }}</p>
            </draggable>
          </template>
          <template v-slot:no-results>
            <draggable
              v-model="rightItems"
              group="items"
              @start="drag=true"
              @end="endDrag($event, true)"
            >
              <p
                class="overline pt-4 pl-4 grey--text"
              >{{ $t('components.transferlistTranslations.no_records') }}</p>
            </draggable>
          </template>
        </v-data-iterator>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep, isEqual, sortBy, differenceWith, uniqBy, intersection } from 'lodash';
import draggable from 'vuedraggable';

export default {
  name: 'TransferList',
  props: {
    alwaysSearchToBackend: {type: Boolean, default: false},
    firstListTitle: { type: String, default: '' },
    secondListTitle: { type: String, default: '' },
    availableItems: {
      type: Array,
      default: () => []
    },
    addedItems: {
      type: Array,
      default: () => []
    },
    areEquals: {
      type: String
    },
    itemText: {
      type: String,
      default: 'name'
    },
    chips: Boolean,
    valueDisable: String,
    showInChip: String,
    loading: Boolean,
    avatar: Boolean,
    searchParameter: String,
    disableRightCondition: Boolean,
    valueRightDisable: String,
    textValue: {
       type: Array,
      default: () => []
    },
  },
  components: {
    draggable
  },
  data: () => ({
    available: false,
    added: false,
    drag: false,
    leftIndeterminate: false,
    rightIndeterminate: false,
    left: [],
    right: [],
    search: ['', ''],
    originals: [[], []],
    leftItems: [],
    rightItems: [],
    selecteds: [[], []],
    selectedsOnSearch: [],
    isSearchToBackend: false,
  }),
  computed: {
    addButtonDisable() {
      if (this.search[0]) {
        return !!this.right.length || !this.selectedsOnSearch.length;
      }

      return (!this.selecteds[0].length && !this.left.length) || (!!this.right.length && !this.selecteds[0].length);
    },
    removeButtonDisable() {
      if (this.search[1]) {
        return !!this.left.length || !this.selectedsOnSearch.length;
      }

      return (!this.selecteds[1].length && !this.right.length) || (!!this.left.length && !this.selecteds[1].length);
    },
  },
  methods: {
    searchLeft () { },
    searchRight () { },
    duplicateGuard (startList, endList, itemsToAdd) {
      const alreadyAdded = endList.filter(item =>
        itemsToAdd.find(
          itemToAdd => item[this.areEquals] === itemToAdd[this.areEquals]
        )
      );

      this.drag = false;

      if (alreadyAdded.length) {
        return true;
      }

      return false;
    },
    endDrag ($event, left = false) {
      
      if (left) {
        this.leftItems = uniqBy([...this.leftItems], this.areEquals);
      } else {
        this.rightItems = uniqBy([...this.rightItems], this.areEquals);
      }

      this.setIndeterminateState(left);

      this.$emit('update:availableItems', [...this.leftItems]);
      // TODO: (Diego) The next line must be removed after a global refactor of transfer list implementation
      this.$emit('removeItems', [...this.leftItems]);
      this.$emit('update:addedItems', [...this.rightItems]);
      // TODO: (Diego) The next line must be removed after a global refactor of transfer list implementation
      this.$emit('addItems', [...this.rightItems]);

      this.drag = false;
    },
    delay (callback, isLeft, ms = 500) {
      let timer = null;
      return (...params) => {
        const self = this;

        if (timer) {
          window.clearTimeout(timer);
          timer = null;
        }

        timer = window.setTimeout(() => {
          callback.apply(self, [isLeft, ...params]);
        }, ms);
      };
    },
    searchItems (isLeft = true) {
      const parameter = this.searchParameter
      const regEx = new RegExp(this.search[isLeft ? 0 : 1], 'i');
      const onSearch = isLeft ? 'availableItems' : 'addedItems';
      const inverseOnSearch = isLeft ? 'addedItems' : 'availableItems';
      const side = isLeft ? 'left' : 'right';
      const backendSearch = isLeft ? 'searchItemLeftOnBackend' : 'searchItemRightOnBackend';
      const arraySide = isLeft ? 0 : 1;
      const propUpdate = `update:${isLeft ? 'availableItems' : 'addedItems'}`;
      const oldEvents = `remove${isLeft ? 'Items' : 'ItemsAdded'}`;

      if (this.search[arraySide]) {
        if (!this.originals[arraySide]?.length || this.originals[arraySide]?.length < this[onSearch]?.length) {
          this.originals[arraySide] = [...this[onSearch]];
        }

        const finded = this.originals[arraySide].filter(item => regEx.test(parameter ? item[parameter] : item.name));


        if (this.selecteds[arraySide].length) {
          const diffOnSearch = differenceWith(
            this[side],
            this.selecteds[arraySide],
            isEqual
          );

          this.selecteds[arraySide] = this.selecteds[arraySide].concat(diffOnSearch);
        } else {
          this.selecteds[arraySide] = [...this[side]];
        }
        if (!finded?.length || this.alwaysSearchToBackend) {
          this[side].length = 0;
          this.selectedsOnSearch.length = 0;
          this.isSearchToBackend = true;
          this.$emit(backendSearch, this.search[arraySide]);
        } else {
          // this.selecteds[arraySide] = [...this[side]];

          if (this.selecteds[arraySide]?.length) {
            this[side] = intersection(finded, this.selecteds[arraySide]);
            this.selectedsOnSearch.length = 0;
          }

          this.$emit(propUpdate, [...finded]);
          // TODO: (Diego) The next line must be removed after a global refactor of transfer list implementation
          this.$emit(oldEvents, [...finded]);

        }
      } else if (this.originals[arraySide]?.length > this[onSearch]?.length || this.isSearchToBackend) {
        const diffOnSearch = differenceWith(
          this.selectedsOnSearch,
          this.selecteds[arraySide],
          isEqual
        );

        this[side] = [...this.selecteds[arraySide], ...diffOnSearch];
        this.selectedsOnSearch.length = 0;

        const diff = differenceWith(
          this.originals[arraySide],
          this[inverseOnSearch],
          isEqual
        );

        this.$emit(propUpdate, [...diff]);
        // TODO: (Diego) The next line must be removed after a global refactor of transfer list implementation
        this.$emit(oldEvents, [...diff]);
      } else {
        if (this.selecteds[arraySide]?.length || this.selectedsOnSearch.length) {
          const diff = differenceWith(
            this.selectedsOnSearch,
            this.selecteds[arraySide],
            isEqual
          );

          this[side] = [...this.selecteds[arraySide], ...diff];
          this.selectedsOnSearch.length = 0;
        }
      }
    },
    disabledItem (item) {
      if (this.chips) {
        return !!item[this.valueDisable];
      } else {
        return false;
      }
    },
    disabledItemRight (item) {
      if (this.disableRightCondition) {
        if (item[this.valueRightDisable] === this.disableRightCondition) {
          return true
        } else {
          return false
        }
      } else {
        return false;
      }
    },
    textItem (item) {
      if (item.code) {
        return `${item.code} ${item.description}`;
      } else if(this.textValue.length  > 0 ) {
         let text = ''
          this.textValue.forEach( (key) =>   text += ` ${item[key]}`)
         return text;
      } else  {
        return item.name;
      }
    },
    addOrRemoveItem (isAdd = true) {
      const side = isAdd ? 'left' : 'right';
      const searchSide = `search${isAdd ? 'Left' : 'Right'}`;
      const propItems = isAdd ? 'addedItems' : 'availableItems';
      const inversePropItems = isAdd ? 'availableItems' : 'addedItems';
      const operation = isAdd ? 'addItems' : 'removeItems';
      const inverseOperation = isAdd ? 'removeItems' : 'addItems';
      const operationState = isAdd ? 'added' : 'available';
      const arraySide = isAdd ? 0 : 1;

      let toTransfer = [];
      let params = [this.availableItems, this.addedItems];

      if (!isAdd) {
        params = params.reverse();
      }

      params.push(this.selecteds[arraySide]);

      const diffOnSearch = differenceWith(
        this.selectedsOnSearch,
        this.selecteds[arraySide],
        isEqual
      );

      const searchMixed = [...this.selecteds[arraySide], ...diffOnSearch];
      this.selectedsOnSearch.length = 0;

      if (!this.duplicateGuard(...params)) {
        toTransfer = differenceWith(
          searchMixed,
          this[propItems],
          isEqual
        );
      }


      this.$emit(
        `update:${propItems}`,
        [...this[propItems], ...toTransfer]
      );

      // TODO: (Diego) The next line must be removed after a global refactor of transfer list implementation
      this.$emit(
        operation,
        [...this[propItems], ...toTransfer]
      );

      const toRemove = differenceWith(
        this[inversePropItems],
        searchMixed,
        isEqual
      );

      this.$emit(`update:${inversePropItems}`, [...toRemove]);
      // TODO: (Diego) The next line must be removed after a global refactor of transfer list implementation
      this.$emit(inverseOperation, [...toRemove]);

      this[side].length = 0;
      this[operationState] = false;
      this.selecteds = [[], []];
      this.search[arraySide] = '';
      this[searchSide]();
    },
    moveAllToTheOtherSide (event, toRight = true) {
      const side = toRight ? 'left' : 'right';
      const items = toRight ? 'availableItems' : 'addedItems';

      if (!event) {
        this.selecteds = [[], []];
      }

      if (event && !isEqual(sortBy(this[side]), sortBy(this[items])) && this[side].length !== this[items].length) {
        this[side] = toRight
          ? this[items].filter(item => !this.chips || !item[this.valueDisable])
          : this[items];
      } else {
        this[side] = [];
      }
    },
    setIndeterminateState (isLeft = true) {
      const allCheckbox = `${isLeft ? 'allLeft' : 'allRight'}Checkbox`;

      if (isLeft) {
        this.leftIndeterminate = !!this.left?.length && this.left?.length < this.leftItems?.length;

        if (this.left.length === this.leftItems.length) {
          this.available = true;
        } else {
          if (this.$refs[allCheckbox]) {
            this.$refs[allCheckbox].lazyValue = false;
          }

          this.available = false;
        }
      } else {
        this.rightIndeterminate = !!this.right?.length && this.right?.length < this.rightItems?.length;

        if (this.right.length === this.rightItems.length) {
          this.added = true;
        } else {
          if (this.$refs[allCheckbox]) {
            this.$refs[allCheckbox].lazyValue = false;
          }

          this.added = false;
        }
      }
    },
    setSelecteds (isLeft = true) {
      const side = isLeft ? 'left' : 'right';
      const arraySide = isLeft ? 0 : 1;
      const propItems = isLeft ? 'availableItems' : 'addedItems';

      const toAdd = differenceWith(
        this[side],
        this.search[arraySide] ? this.selectedsOnSearch : this.selecteds[arraySide],
        isEqual
      );
      const toRemove = differenceWith(
        this.search[arraySide] ? this.selectedsOnSearch : this.selecteds[arraySide],
        this[side],
        isEqual
      );

      if (this.search[arraySide]) {
        if (
          toAdd.length
          && (differenceWith(toAdd, this.selecteds[arraySide]))?.length
        ) {
          this.selectedsOnSearch = this.selectedsOnSearch.concat(differenceWith(toAdd, this.selecteds[arraySide]));
        } else if (toRemove.length) {
          this.selectedsOnSearch = differenceWith(this.selectedsOnSearch, toRemove, isEqual);
        }
      } else {
        if (
          toAdd.length
          && (differenceWith(toAdd, this.selecteds[arraySide]))?.length
        ) {
          this.selecteds[arraySide] = this.selecteds[arraySide].concat(toAdd);
        } else if (toRemove.length) {
          this.selecteds[arraySide] = differenceWith(this.selecteds[arraySide], toRemove, isEqual);
        }
      }


      const diff2 = differenceWith(
        this[propItems],
        this.originals[arraySide],
        isEqual
      );

      if (this.originals[arraySide]?.length) {
        this.originals[arraySide] = this.originals[arraySide].concat(diff2);
      } else {
        this.originals[arraySide] = [...diff2];
      }
    },
    loadMore () {},
    availableAddedItemsWatchers (isLeft = true) {
      const internalItems = isLeft ? 'leftItems' : 'rightItems';
      const propItems = isLeft ? 'availableItems' : 'addedItems'

      this[internalItems] = [...this[propItems]];
      this.added = false
      this.available = false
    },
    leftRightWatchers (value, isLeft = true) {
      const side = isLeft ? 'right' : 'left';
      const stateOperation = isLeft ? 'added' : 'available'

      if (value.length && this[side].length) {
        this[side] = [];

        if (this[stateOperation]) {
          this[stateOperation] = false
        }
      }

      this.setSelecteds(isLeft);
      this.setIndeterminateState(isLeft);
    }
  },
  watch: {
    availableItems () {
      this.availableAddedItemsWatchers();
    },
    addedItems () {
      this.availableAddedItemsWatchers(false);
    },
    left (value) {
      this.leftRightWatchers(value);
    },
    right (value) {
      this.leftRightWatchers(value, false);
    },
  },
  mounted () {
    this.leftItems = [...this.availableItems];
    this.rightItems = [...this.addedItems];
    this.originals = [cloneDeep(this.leftItems), cloneDeep(this.rightItems)];
    this.searchLeft = this.delay(this.searchItems, true);
    this.searchRight = this.delay(this.searchItems, false);
  },
};
</script>

<style lang="sass">

.transferList
  .v-card
    overflow: hidden
  &__list
    &-right, &-left
      flex-grow: 4
    &__search
      border-bottom: 1px solid #ccc
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
      .v-input__control
        min-height: 0 !important
    &__header
      font-size: 14px
      background-color: #fafafa
      border-bottom: 1px solid #ccc
      .v-input
        &--checkbox
          opacity: 0.2
          transition: opacity 300ms ease
          margin-top: 22px
          &.v-input--indeterminate, &.v-input--is-label-active
            opacity: 1
        &__slot
          margin-bottom: 0 !important
      &:hover
        .v-input--checkbox
          opacity: 1
    &__content
      max-height: 300px
      overflow: auto
      .v-list-item
        min-height: unset !important
        &__content
          justify-content: space-between
          *
            flex: initial
        &__icon, &__action
          transition: opacity 300ms ease
          opacity: 0.2
        &__icon
          margin: 8px 0
        &__action
          margin: 8px 0
        &__content
          padding: 8px 0
        &:hover
          .v-list-item__icon, .v-list-item__action
            opacity: 1
        &--active
          &:before
            opacity: 0.05
          .v-list-item__icon, .v-list-item__action
            opacity: 1
  &__actions
    display: flex
    flex-direction: column
    flex-grow: 1
    justify-content: center
    align-items: center
    &__btn
      padding: 0 !important
      min-width: unset !important
      width: 36px
      height: 36px
      border-radius: 50%
      margin: 0.5rem
      transition: all 300ms ease
      &:hover, &:active, &:focus
        background-color: var(--v-primary-base) !important
        .v-icon, .v-icon:before
          color: #FFFFFF !important
</style>
