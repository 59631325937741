<template>
	<div>
		<v-container fluid class="pa-0 pb-8">
			<v-container class="container-custom" v-if="!newCareersSuccess">
				<Breadcrumbs
					:title="$t('modules.careers.update.breadcrumbs.title')"
					:description="$t('modules.careers.update.breadcrumbs.description')"
				></Breadcrumbs>
				<template>
					<v-row justify="center">
						<v-col class="viewSpaces" sm="12">
							<OutlineCard
								:title="$t('modules.careers.update.sectionData.title')"
								:subtitle="$t('modules.careers.update.sectionData.subtitle')"
								:switch-label="changeStatuslabel"
								switcher
								@switchChange="changeStatus"
								:switchValue="status"
								class="mb-6"
							>
								<v-row>
									<v-col sm="6">
										<v-text-field
											:error="$v.newCareers.description.$anyError"
											:error-messages="descriptionErrors"
											outlined
											:label="`${$t('modules.careers.update.sectionData.items.description')} *`"
											v-model.trim="$v.newCareers.description.$model"
											@blur="$v.newCareers.description.$touch()"
										/>
									</v-col>
									<v-col sm="4">
										<v-text-field
											:error="$v.newCareers.code.$anyError"
											:error-messages="codeErrors"
											outlined
											:label="`${$t('modules.careers.update.sectionData.items.code')} *`"
											required
											v-model.trim="$v.newCareers.code.$model"
											@blur="$v.newCareers.code.$touch()"
										/>
									</v-col>
								</v-row>
								<v-row>
								<v-col sm="6">
									<v-select
										:error="$v.newCareers.careerType.$anyError"
										:error-messages="careerTypeErrors"
										outlined
										:items="careerTypes"
										item-text="meaning"
										return-object=""
										:label="`${$t('modules.careers.update.sectionData.items.careerType')} *`"
										v-model="$v.newCareers.careerType.$model"
										@blur="$v.newCareers.careerType.$touch()"
									></v-select>
								</v-col>
								</v-row>
								<v-row>
									<v-col sm="6">
										<v-text-field
											:error="$v.newCareers.legalDescription.$anyError"
											:error-messages="legalDescriptionErrors"
											outlined
											:label="`${$t('modules.careers.update.sectionData.items.legalDescription')}`"
											v-model.trim="$v.newCareers.legalDescription.$model"
											@blur="$v.newCareers.legalDescription.$touch()"
										/>
									</v-col>
									<v-col sm="6">
										<v-text-field
											outlined
											:label="`${$t('modules.careers.update.sectionData.items.fistYearCertified')}`"
											@blur="$v.newCareers.fistYearCertified.$touch()"
											v-model.trim="$v.newCareers.fistYearCertified.$model"
										/>
									</v-col>
									<v-col sm="2">
										<v-text-field
											:error="$v.newCareers.book.book.$anyError"
											:error-messages="bookErrors"
											outlined
											:label="`${$t('modules.careers.update.sectionData.items.book')}`"
											v-model.number="$v.newCareers.book.book.$model"
											@blur="$v.newCareers.book.book.$touch()"
											@keypress="$validateIntegerInputNumber($event, $v.newCareers.book.book.$model)"
											type="number"
											min="1"
										/>
									</v-col>
									<v-col sm="2">
										<v-text-field
											:error="$v.newCareers.book.invoice.$anyError"
											:error-messages="invoiceErrors"
											outlined
											:label="`${$t('modules.careers.update.sectionData.items.invoice')}`"
											v-model.number="$v.newCareers.book.invoice.$model"
											@blur="$v.newCareers.book.invoice.$touch()"
											@keypress="$validateIntegerInputNumber($event, $v.newCareers.book.invoice.$model)"
											type="number"
											min="1"
										/>
									</v-col>
								</v-row>
								<v-row>
									<v-col sm="2">
										<v-text-field
											:error="$v.newCareers.credits.$anyError"
											:error-messages="creditsErrors"
											outlined
											:label="`${$t('modules.careers.update.sectionData.items.credits')} *`"
											v-model.trim="$v.newCareers.credits.$model"
											@keypress="$validateIntegerInputNumber($event, $v.newCareers.credits.$model, 999)"
											@blur="$v.newCareers.credits.$touch()"
											type="number"
											min="1"
										/>
									</v-col>
								</v-row>
							</OutlineCard>
							<OutlineCard
								:title="`${$t('modules.careers.update.studyPlans.title')} *`"
								:subtitle="$t('modules.careers.update.studyPlans.subtitle')"
								class="mb-6"
							>
								<v-row>
									<v-col></v-col>
								</v-row>
								<v-divider class="mb-4"></v-divider>
								<v-row v-if="newCareers.studyPlans.length !== 0" justify="center">
									<v-col sm="12">
										<StudyPlansTable
											:headers="headers"
											:items="studyPlansTmp"
											:clear-selected="clearSelected"
											:permissions="{read: canRead, update: canUpdate}"
											@delete="deleteStudyPlanPopUp"
											@changeStudyPlanState="$v.$touch()"
											@update="(id, index) => {open(index)}"
										/>
									</v-col>
								</v-row>
								<div
									v-if="canUpdate"
									:class="studyPlansTmp.length === 0 ? 'd-flex justify-start' : 'd-flex justify-end'"
								>
								<Button
									outlined
									:text="$t('modules.careers.update.studyPlans.button')"
									@clicked="open"
									@end="resetButtonValues"
									class="mr-4"
								></Button>
								</div>
								<br />
							</OutlineCard>
							<div class="d-flex justify-end">
								<Button
									white
									:text="$t('actions.cancel')"
									@clicked="$router.replace('/careers')"
									@end="resetButtonValues"
									class="mr-4"
								></Button>

								<Button
									:loading="createButton.loading"
									:success="createButton.success"
									:error="createButton.error"
									:text="$t('actions.save')"
									:disabled="!canUpdate || $v.$invalid || !$v.$anyDirty"
									:successText="$t('actions.saved')"
									:errorText="'Error'"
									@end="resetButtonValues"
									@clicked="checkDisableAndUpdate"
								></Button>
							</div>
						</v-col>
					</v-row>
				</template>
			</v-container>
			<SuccessMessage
				v-else
				:type="successMessage.type"
				:title="successMessage.title"
				:actionPrimary="successMessage.actionPrimary"
			/>
			<TemporaryRightDrawer
				v-if="canUpdate || canRead"
				@tmpDrawerOpen="closeDrawerAlert"
				:open="tmpDrawer.open"
				:title="!pickedStudyPlan ? $t('modules.careers.study_plan.drawer.create.title') : $t('modules.careers.study_plan.drawer.edit.title')"
				:description="!pickedStudyPlan ? $t('modules.careers.study_plan.drawer.create.description') : $t('modules.careers.study_plan.drawer.edit.description')"
				:width="100"
				:scroll="false"
			>
				<StudyPlansCreate
					:permissions="{update: canUpdate}"
					:studyPlan.sync="pickedStudyPlan"
					@changeStudyPlan="changeStudyPlan = true"
					@cancel="closeDrawerAlert"
					@close='saveStudyPlan'
					:closed="!tmpDrawer.open"
					:isUpdate='tmpDrawer.isUpdate'
					v-if="tmpDrawer.open"
				/>
			</TemporaryRightDrawer>
		</v-container>
	</div>
</template>

<script>
	import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
	import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
	import Button from '@/components/Button/Button';
	import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
	import StudyPlansTable from '../Components/StudyPlansTable/StudyPlansTable';
	import StudyPlansCreate from './StudyPlanCreate'
	import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
	import { $careers, $types } from '../Services';
	import { mapGetters } from 'vuex'
	import CareerMixin from '../Mixins'

	export default {
		name: 'CareersUpdate',
		mixins: [CareerMixin],
		components: {
			Breadcrumbs,
			OutlineCard,
			Button,
			SuccessMessage,
			StudyPlansTable,
			TemporaryRightDrawer,
			StudyPlansCreate
		},
		data() {
			return {
				create: false,
				saving: false,
				changeStudyPlan: false,
				careerTypes: [],
				selectedCareerFilter: {},
				clearSelected: '',
				studyPlansTmp: [],

				newCareers: {
					fistYearCertified: null,
					legalDescription: null,
					status: true,
					description: null,
					code: null,
					credits: null,
					careerType: [],
					studyPlans: [],
					book: {
						book: null,
						invoice: null,
						date: null,
						career:null
					},
					userId: null,
				},
				status: true,
				groups: [
					{
						zone: null,
						price: 0
					}
				],
				createButton: {
					loading: false,
					success: false,
					error: false
				},

				newCareersSuccess: null,
				tmpDrawer: {
					open: false,
					isUpdate: false,
				},
				pickedStudyPlanIndex: 1000000,
				originalCode: null,
			};
		},
		async mounted() {
			this.fetchData(this.$route.params.id);
			this.getCareerTypes();
		},
		computed: {
			...mapGetters({
			userId: 'commons/getUserID'
			}),
			pickedStudyPlan:{
				set(value){
					this.studyPlansTmp.splice(this.pickedStudyPlanIndex, 1, value)
					this.close()
				},
				get(){
					return this.studyPlansTmp[this.pickedStudyPlanIndex];
				}
			},
			canUpdate () {
				return this.$permissions.portaladministrativo.academic.careers.UPDATE;
			},
			canRead () {
				return this.$permissions.portaladministrativo.academic.careers.READ;
			}
		},
		methods: {
			async checkDisableAndUpdate(){
				if(!this.newCareers.status)
					this.$store.dispatch('commons/openPopUp', {
					title: this.$t('modules.careers.update.disableCareerPopUp'),
					content: null,
					actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
					actionPrimary: { text: this.$t('actions.save'), callback: this.updateCareer },
					icon: { color: 'warning', name: 'mdi-alert' },
					color: 'primary'
					});
				else
					this.updateCareer()
			},
			closeDrawerAlert(){
				if (this.changeStudyPlan) {
					this.$store.dispatch('commons/openPopUp', {
					title: `Estás por cerrar sin guardar los cambios`,
					content: null,
					actionPrimary: { text: 'Cerrar', callback: this.close },
					actionSecondary: { text: 'Cancelar', callback() {} },
					icon: { color: 'warning', name: 'mdi-eye-off-outline' },
					color: 'primary'
					});
				} else {
					this.close()
				}
			},
			editStudyPlan(index){
				this.pickedStudyPlanIndex = index;
				this.open();
			},
			async saveStudyPlan(){
				this.tmpDrawer.open = false;
				this.tmpDrawer.isUpdate = false;
				await this.fetchData(this.$route.params.id)
				this.$v.$touch();
			},
			close(){
				this.pickedStudyPlanIndex = this.studyPlansTmp.length;
				this.tmpDrawer.open = false;
				this.$v.$touch();
			},
			open(val = undefined){
				if(val == undefined){
					this.pickedStudyPlanIndex  = this.studyPlansTmp.length;
				} else {
					this.tmpDrawer.isUpdate = true
					this.pickedStudyPlanIndex = val;
				}
				
				this.tmpDrawer.open = true;
			},
			changeStatus() {
				this.$v.$touch();
				this.newCareers.status = !this.newCareers.status;
			},
			removeItems(removedItems) {
				removedItems.forEach(removedItem => {
					const index = this.selectedItems.indexOf(removedItem);
					this.selectedItems.splice(index, 1);
				});
			},
			deleteStudyPlanPopUp(id) {
				this.studyPlansTmp.splice(id, 1);
			},
			async fetchData(id) {
				try {
					const [careers] = await Promise.all([$careers.findById(id, null, null)]);
					this.originalCode = careers.data.code;
					this.newCareers = careers.data;
					if (this.newCareers.studyPlans === null) this.newCareers.studyPlans = []
					if (this.newCareers.book === null) this.newCareers.book = {book: null, invoice: null, date: null, carrer: null}
						this.studyPlansTmp = careers.data.studyPlans ?? [];
						this.status = careers.data.status;
				} catch {
					this.newCareers = []
				}
			},
			async getCareerTypes() {
				try {
					this.careerTypes = await $types.find(null, null, {params: { type: 'CAREER_TYPE' }});
					this.careerTypes = this.careerTypes.data
				} catch (error) {
					this.careerTypes = []
					throw error;
				}
			},
			async updateCareer() {
				if (!this.canUpdate) return false
					this.createButton.loading = true;
					this.newCareers.studyPlans = this.studyPlansTmp;
					this.newCareers.userId = this.userId ? this.userId : null
				try {
					await $careers.update(this.$route.params.id, this.newCareers);
					this.successMessage.type = 'success';
					this.successMessage.title = this.$t('modules.careers.update.success_message.title');
					if (!this.insideDrawer) {
						this.successMessage.actionPrimary = { text: this.$t('modules.careers.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/careers') } };
					} else {
						this.successMessage.actionPrimary = { text: this.$t('modules.careers.update.success_message.actions.primary_text'), callback: () => { this.$emit('closeDrawer'); } }
					}
					this.createButton.success = true;

				} catch (error) {

					this.successMessage.type = 'error';
					this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.careers.update.error_message.title');
					this.successMessage.actionPrimary = { text: this.$t('modules.careers.update.success_message.actions.back'), callback: () => { this.newCareersSuccess = false } }
					this.createButton.error = true;
				} finally {
					this.newCareersSuccess = true;
					this.resetButtonValues()
				}
			},
			resetButtonValues() {
				this.createButton.loading = false;
				this.createButton.success = false;
				this.createButton.error = false;
			}
		},
		watch:{
			'StudyPlansTable': {
				handler() {
					this.studyPlansTable.$forceUpdate();
				},
			deep: true
			}
		}
	};
</script>
