import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('identity');

export class Cognito extends Api {
  constructor(http, config = {}) {
    super(http, url, 'cognito', config);
  }

  async getChallengeType(parameters, config = {}) {
    config = { ...this._config, ...config }
    const uri = this._uriConstructor(null, 'getChallengeType', null)
    return this._http.get(uri, parameters, config)
  }

  
}