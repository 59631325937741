<template>
    <v-row class="p-0 ">
        <v-col cols="12 py-0 " >
            <div class="banner d-flex align-center" style="height:106px" :class="[typeClass]">
                <div class="d-flex flex-column justify-center iconContainer ma-4" :class="[typeClass]">
                    <v-icon v-if="type === 'success'" color="success" size="30px">mdi-check-circle</v-icon>
                    <v-icon v-else-if="type === 'info'" color="primary lighten-2" size="30px">mdi-information-outline</v-icon>
                    <v-icon v-else-if="type === 'warning'" color="#FB9616" size="30px">mdi-alert-outline</v-icon>
                </div>
                <div class="banner__title py-3 body-1 font-weight-bold flex d-flex align-center">
                    <span v-if="title" class="flex">{{ title }}</span>
                    <slot name="action"></slot>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'Banner',
        props: {
            type: { type: String, default: 'success' },
            title: { type: String, default: null },
        },
        computed: {
            typeClass () {
                return `type-${this.type}`;
            }
        }
    }
</script>

<style lang="sass" scoped>
    .banner
        border-radius: 8px

        &.type-success
            background-color: rgba(59, 192, 0, 0.2)
            .banner__title
                color: #21510b

        &.type-info
            background-color: var(--v-primary-lighten5)
            .banner__title
                color: var(--v-primary-darken4)

        &.type-warning
            background-color: #FFF1E1
            .banner__title
                color: #995200

        .iconContainer
            min-width: 50px
            width: 50px
            height: 50px
            border-radius: 50%
            &.type-success
                background-color: rgba(59, 192, 0, 0.2) !important
            &.type-info
                background-color: var(--v-primary-lighten4) !important
            &.type-warning
                background-color: rgba(251, 150, 22, 0.2) !important
</style>
