<template>
  <v-container fluid class='pa-0 pb-8'>
    <v-container class='container-custom'>
      <Breadcrumbs :title="$t('modules.portalalumno.payments.checkingAccount.account.breadcrumb.title')"></Breadcrumbs>
      <v-row v-if="!loading" justify='center'>
        <v-col class='viewSpaces' cols='12'>
          <v-row>
          <v-col cols="12" sm="8">
            <Banner v-if="!hasDebts" :title="$t('modules.portalalumno.payments.checkingAccount.account.noDebts')" type='info'/>
            <!-- <Banner v-else :title="$tc('modules.portalalumno.payments.checkingAccount.account.debts', calcDebts ,{calcDebts} )" type='warning'/> -->
          </v-col>
          <v-col cols="12"  sm="2"  class="d-flex justify-center d-sm-block" v-if="false">
              <div class="card-creditTeclab mr-10">
                <div class="img-creditTeclab">
                  <img class="img-creditTeclab" src="@/assets/images/portalalumno/button-creditTeclab.png" alt="">
                </div>
                <div class="column-credit">
                  <p>Consultá tus creditos Teclab </p>
                  <div class="button">
                    <Button size="sm" text="Ver detalle" @clicked="goToCredits"/>
                  </div>
                </div>
              </div>
          </v-col>
          </v-row>
          <v-row v-if="isNotEmpty">
            <v-col cols='12' sm="10" class="p-0">
              <PaymentInfo @click='clickDetails' :receipts="data.receipts"/>
            </v-col>
          </v-row>
          <v-row v-if="!isNotEmpty && !loading">
              <v-col class='viewSpaces' cols='10'>
                <v-row class="justify-center mt-7">
                <img v-if="!$country === 'mexico'" src="@/assets/images/portalalumno/cuenta_corriente.png" alt="empty-state">
                </v-row>
                <v-row class="justify-center mt-10">
                 <div class="text-center">
                  <h3>{{$t('modules.portalalumno.payments.checkingAccount.account.empty_state.title')}}</h3>
                  <h5>{{$t('modules.portalalumno.payments.checkingAccount.account.empty_state.subtitle')}}</h5>
                 </div>
                </v-row>
              </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-container>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue';
import PaymentInfo from '../Components/PaymentInfoBanner';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { $receipt } from '../Services';
import { mapGetters } from 'vuex';
export default {
    name: 'account',
    components: {
      Banner,
      PaymentInfo,
      Breadcrumbs,
      Button
    },
    data() {
      return{
        data: {
          receipts: [],
        },
        hasDebts: false,
        loading: false,
        balance:0
      }
    },
    created(){
      this.getPaymentDetails();
    },
    methods: {
       async getPaymentDetails() {
         this.loading = true
        try{
          const { data } = await $receipt.find('payment-history', null,{params: {
            studentId: this.getStudentId
          }
          });
          this.balance = data.balance
          // Coeficientes fecha - [0,1] = dd / [3,4] = mm / [6,7,8,9] = yyyy
          const index = [ 6, 7, 8, 9, 3, 4, 0, 1 ]
          this.data.receipts = data.receipts.sort((a, b) => {
            let r = 0
            index.find(i => r = b.receiptDetail?.date.charCodeAt(i) - a.receiptDetail?.date.charCodeAt(i))
            return r
          });
          this.checkDebts(data.balance)
        }catch{
          this.data.receipts = [];
        }
        this.loading = false;
      },
      clickDetails(data) {
        this.$router.push({path: `checking-account/details/${data.receiptId}`, query: {
          body: data,
        }})
      },
      checkDebts(balance) {
        this.hasDebts = balance < 0 ? true : false;
      },
      goToCredits() {
        this.$router.push('checking-account/teclab-credits');
      }
    },
    computed: {
      ...mapGetters(['commons/getStudentId']),
      getStudentId() {
        return this['commons/getStudentId'];
      },
      calcDebts() {
         return Math.abs(this.balance);
      },
      isNotEmpty() {
        return this.data.receipts.length
      }
    }
}
</script>

<style lang="sass" scoped>
.card-creditTeclab
  width: 271px
  height: 106px
  display: flex
  flex-direction: row
  justify-content: space-between
  border-radius: 7px
  border: solid 1px #dfeef1
  background-color: #ffffff
.img-creditTeclab
  width: 100px
  height: 106px
.column-credit
  width: 112px
  margin-right: 10px
.button
  width: 96px
  height: 27px
</style>
