<template>
  <div>
    <v-navigation-drawer right permanent width="100%" height="100%" style="flex: 1">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title font-weight-bold primary--text">
            {{ $t('modules.portalalumno.payments.paymentsStudents.view.summary.title') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mx-5"></v-divider>

      <div class="insider pl-3 pr-3 pb-3">
        <v-list dense>
          <template v-for="key in summaryObj">
            <v-list-item :key="resumen.items[key].id" v-if="key == 'subtotal' || resumen.items[key].monto != 0" link>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="payment-section-title pr-1">
                    <h6 class="black--text mb-2">
                      {{ resumen.items[key].title }}
                      <span v-if="resumen.items[key].title === 'Subtotal'" class="text-right float-right currency-span title-currency color-subtotal">{{ resumen.items[key].monto | $currency }}</span>
                      <span v-else class="text-right float-right currency-span title-currency color-title">{{ resumen.items[key].monto | $currency }}</span>
                    </h6>
                  </div>
                </v-list-item-title>
                <ul class="ma-0 pa-0">
                  <li v-for="(item, i) in resumen.items[key].items" :key="`${i}-${item.id}-off`" :class="{'d-none': key != 'subtotal' && item.monto == 0}">
                    <div v-if="item.reenrollment?.billingProduct != 80 && $isMexico">
                      <p class="subitem-title gray--text">{{ item.title }}</p>
                      <p v-if="resumen.items[key].title === 'Subtotal'" class="subitem-monto color-subtotal">{{ item.monto | $currency }}</p>
                      <p v-else class="subitem-monto">{{ item.monto | $currency }}</p>
                    </div>
                    
                  </li>
                </ul>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <div class="total-wrapper">
          <v-alert outlined color="primary lighten-2">
            <div class="total">{{ $t('modules.portalalumno.payments.paymentsStudents.view.summary.total') }}</div>
            <div class="total-currency">{{ resumen.total | $currency }}</div>
          </v-alert>
        </div>

        <div class="d-flex justify-end mb-12">
          <Button
            v-if="summaryButtons.tertiary.visible && this.systemConfig?.repayment"
            :disabled="summaryButtons.tertiary.disabled"
            :text="summaryButtons.tertiary.text"
            @clicked="summaryButtons.tertiary.callback()"
            @end="resetButtonValues"
            outlined
            class="mr-4"
          ></Button>
          <Button
            v-if="summaryButtons.secondary.visible"
            :disabled="summaryButtons.secondary.disabled"
            :text="summaryButtons.secondary.text"
            @clicked="summaryButtons.secondary.callback()"
            @end="resetButtonValues"
            white
            class="mr-4"
          ></Button>
          <Button
            v-if="summaryButtons.primary.visible"
            :disabled="summaryButtons.primary.disabled"
            :loading="summaryButtons.primary.loading"
            :success="summaryButtons.primary.success"
            :error="summaryButtons.primary.error"
            :text="summaryButtons.primary.text"
            :successText="$t('actions.success')"
            :errorText="$t('actions.error')"
            @end="resetButtonValues"
            @clicked="primaryClicked"
          ></Button>
        </div>
      </div>
    </v-navigation-drawer>
    <v-sheet
      v-if="creditCards.length"
      outlined
      rounded
      dark
      color="#67C1B8"
      class="pa-4 text-center text-body-2 line-break"
    >
      <template v-for="(card, index) in creditCards">
        <div :key="`card-${index + 1}`">
          <v-divider v-if="index > 0" class="ma-2"></v-divider>
          <span>{{ $t('modules.portalalumno.payments.paymentsStudents.view.summary.dues', { cardName: card.name, installments: card.installments, installment_amount: $options.filters.$currency(card.installment_amount) }) }}</span>
        </div>
      </template>
    </v-sheet>
  </div>
</template>

<script>
  import Button from '@/components/Button/Button';

  export default {
    props: {
      summaryButtons: Object,
      resumen: Object,
      creditCards: { type: Array, default: () => [] }
    },
    components: {
      Button,
    },
    data() {
      return {
        summaryObj: ['subtotal', 'discounts', 'benefits', 'scholarships', 'adminExpenses'],
        systemConfig: null
      }
    },
    async mounted() {
            this.systemConfig = (await this.$getSystemConfig())?.data
        },
    methods: {
      primaryClicked() {
        if (!this.summaryButtons.primary.loading) this.summaryButtons.primary.callback()
      },
      resetButtonValues () {
        this.summaryButtons.primary.loading = false
        this.summaryButtons.primary.success = false
        this.summaryButtons.primary.error = false
      },
    },
    watch: {
      
    }
  };
</script>

<style scoped lang="sass">

.insider

  li
    list-style: none
    width: 100%
    float: none
    clear: both

  h6
    font-family: 'DM Sans'
    font-size: 18px
    line-height: 1.33
    letter-spacing: -0.54px

  .subitem-title
    font-family: 'Roboto'
    font-size: 14px
    line-height: 1.86
    letter-spacing: -0.42px
    float: left
    margin-bottom: 4px

  .subitem-monto
    font-family: 'Roboto'
    font-size: 14px
    line-height: 1.86
    letter-spacing: -0.42px
    text-align: right
    float: right
    margin-bottom: 4px
    color: #2d9200

  .color-subtotal
    color: var(--v-primary-darken4)  

.total-wrapper
  border-radius: 5px
  background-color: var(--v-primary-lighten5)
  font-family: 'DM Sans'
  font-weight: bold
  line-height: 1.3
  letter-spacing: -0.6px

  .total
    float: left
    font-size: 20px
    color: var(--v-primary-darken4)  

  .total-currency
    float: right
    font-size: 24px
    color: var(--v-primary-darken4)  
.color-title
  color: #2d9200
</style>
