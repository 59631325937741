<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs :description="$t('modules.subjects_sync.table.breadcrumbs.description')"
                     :title="$t('modules.subjects_sync.table.breadcrumbs.title')"></Breadcrumbs>
        <v-row>
          <v-col sm="5" class="pt-5 pb-0 pl-13">
            <v-autocomplete
              v-model="selectedTerm"
              :items="terms"
              :label="$t('modules.subjects_sync.table.labels.term')"
              item-text="description"
              item-value="id"
              outlined
              @change="fetchData"
            >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item" ></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.description"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.termType.meaning"></v-list-item-subtitle>
                </v-list-item-content> 
              </template>
            </template>
          </v-autocomplete>
          </v-col>
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :re-render="reRender"
              :switch-disable="loading"
              :headers="headers"
              :items="subjects"
              :pageCount="pagination.pagesAmount"
              :page="pagination.page"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              :totalItems="totalItems"
              :permissions="{ update: canUpdate }"
              :clear-selected="clearSelected"
              :sync-title="$t('modules.subjects_sync.table.sync')"
              :desync-title="$t('modules.subjects_sync.table.desync')"
              @bulk-update="bulkUpdatePopUp"
              @switch-change="updateSubjectPopUp"
              @searchAction="searchAction"
              @input-pagination="onInputPagination"
              @length-pagination-change="onLengthPaginationChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import {$sectionLms, $terms} from '../Services';
import { mapGetters } from 'vuex'

export default {
  name: 'SubjectsSyncTable',
  components: {
    SuperTable,
    Breadcrumbs
  },
  data() {
    return {
      reRender: '',
      loading: false,
      terms: [],
      selectedTerm: null,
      subjects: [],
      totalItems: 0,
      search: null,
      sort: {
        sortBy: 'subject.code',
        sortDesc: false,
      },
      clearSelected: '',
      pagination: {
        limit: 20,
        page: 1,
        pagesAmount: 1
      },
      headers: [
        {
          text: this.$t('modules.subjects_sync.table.headers.code'),
          value: 'subject.code',
        },
        {
          text: this.$t('modules.subjects_sync.table.headers.description'),
          value: 'subject.description',
        },
        {
          text: this.$t('modules.subjects_sync.table.headers.termCode'),
          value: 'term.code',
          sortable: false
        },
        {
          text: this.$t('modules.subjects_sync.table.headers.oldModality'),
          value: 'oldModality',
          align: 'center'
        },
        {
          text: this.$t('modules.subjects_sync.table.headers.synchronize'),
          value: 'synchronize',
          align: 'center'
        }
      ],
    };
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.subjects_sync.UPDATE
    },
    ...mapGetters({
      userId: 'commons/getUserID'
    }),
  },
  async mounted() {
    await this.getTerms();
    this.fetchData();
  },
  methods: {
    async getTerms() {
      try {
        const params = {page: 0, length: 20, orderBy: 'classStartDate', orientation: 'desc'}
        const terms = await $terms.find(null, null, { params });
        const cursado = [{ divider: true } , { header: this.$t('modules.subjects_sync.header.type.course') }, ...terms.data.content?.filter(e => e.termType.value == 193)]
        const onBording = [{ divider: true } , { header: this.$t('modules.subjects_sync.header.type.onBoarding') }, ...terms.data.content?.filter(e => e.termType.value == 194)]
        const nivelacion = [{ divider: true } , { header: this.$t('modules.subjects_sync.header.type.leveling')},...terms.data.content?.filter(e => e.termType.value == 195)]
        const practica = [{ divider: true } , { header: this.$t('modules.subjects_sync.header.type.practice')},...terms.data.content?.filter(e => e.termType.value == 281)]
        this.terms = [ ...cursado, ...onBording, ...nivelacion, ...practica]
        if (this.terms.length) this.selectedTerm = this.terms[2].id;
      } catch (error) {
        this.terms = [];
        throw error;
      }
    },
    async fetchData() {
      if (this.loading) return;
      this.loading = true;
      this.clearSelected = new Date().getMilliseconds().toString();
      try {
        const params = {
          page: this.pagination.page - 1,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc',
          search: this.search,
          term: this.selectedTerm
        };
        const resp = await $sectionLms.find(null, null, {params});
        this.subjects = resp.data.content.map(item => {
          item.key = `${item.subject.id}${item.term.id}${item.oldModality ? '0' : '1'}`;
          return item;
        });
        this.totalItems = resp.data.totalElements;
        this.pagination.pagesAmount = resp.data.totalPages;
      } catch (error) {
        this.subjects = [];
        this.pagination.page = 1;
        this.pagination.pagesAmount = 1;
        this.totalItems = 0;
        throw error;
      } finally {
        this.loading = false;
      }
    },
    searchAction(data) {
      this.pagination.page = 1;
      this.search = data;
      this.fetchData();
    },
    updateSubjectPopUp(item, sync) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.subjects_sync.table.popupTitle', {
          action: sync
            ? this.$t('modules.subjects_sync.table.sync').toLowerCase()
            : this.$t('modules.subjects_sync.table.desync').toLowerCase()
        }),
        content: [{value: item.subject.description}],
        actionPrimary: {text: this.$t('actions.accept'), callback: () => this.updateSubject(item, sync)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback: () => this.reRender = new Date().getMilliseconds().toString()
        },
        icon: {color: 'info', name: sync ? 'mdi-sync' : 'mdi-sync-off'},
        color: 'primary',
      });
    },
    async updateSubject(item, sync) {
      this.loading = true;
      try {
        const body = item.id
          ? {
            id: item.id,
            synchronize: sync
          }
          : {
            subject: { id: item.subject.id },
            term: { id: item.term.id },
            oldModality: item.oldModality,
            synchronize: sync
          };
          body.userId = this.userId ? this.userId : null
        await $sectionLms.create(body);
      } catch (err) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{
            value: this.$t('modules.subjects_sync.table.updateError', {
              action: sync
                ? this.$t('modules.subjects_sync.table.sync').toLowerCase()
                : this.$t('modules.subjects_sync.table.desync').toLowerCase()
            })
          }],
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        this.reRender = new Date().getMilliseconds().toString();
        throw err;
      } finally {
        this.loading = false;
        this.fetchData();
      }
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        pagesAmount: 1
      };
      this.fetchData();
    },
    bulkUpdatePopUp(items, sync) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.subjects_sync.table.popupTitle', {
          action: sync
            ? this.$t('modules.subjects_sync.table.sync').toLowerCase()
            : this.$t('modules.subjects_sync.table.desync').toLowerCase()
        }),
        content: items.map(item => ({ value: item.subject.description })),
        actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkUpdate(items, sync)},
        actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
        icon: {color: 'info', name: sync ? 'mdi-sync' : 'mdi-sync-off'},
        color: 'primary',
      });
    },
    async bulkUpdate(items, sync) {
      this.loading = true;
      try {
        const itemsForUpdate = items.filter(item =>
          item.synchronize !== sync).map(item => {
          return item.id
            ? {
              id: item.id,
              synchronize: sync
            }
            : {
              subject: { id: item.subject.id },
              term: { id: item.term.id },
              oldModality: item.oldModality,
              synchronize: sync
            };
        });
        await $sectionLms.create(itemsForUpdate, {}, 'bulk');
        this.clearSelected = new Date().getMilliseconds().toString();
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{
            value: this.$t('modules.subjects_sync.table.bulkUpdateError', {
              action: sync
                ? this.$t('modules.subjects_sync.table.sync').toLowerCase()
                : this.$t('modules.subjects_sync.table.desync').toLowerCase()
            })
          }],
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw error;
      } finally {
        this.loading = false;
        this.fetchData();
      }
    }
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
};
</script>

