<template>
  <v-container fluid class="superTable">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('actions.search')"
          outlined
          clearable
          class="superTable__search"
          @click:clear="search = '';keyup()"
          @keyup="keyup"
          @keyup.esc="search = ''"
        ></v-text-field>
      </v-col>
      <v-col sm="8" align="end" v-if="canAssign">
        <v-btn
          large
          rounded
          :disabled="!status"
          color="primary"
          @click="$emit('add')"
        >{{ $t('modules.scholarships.details.table.addNew') }}</v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="users"
          :totalItems="totalItems"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="id"
          class="superTable__table Scholarships-supertable"
          style="table-layout: fixed"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
          v-model="selectedItems"
          :show-select="true"
          :search="search"
          :server-items-length="totalItems"
          must-sort
        >
          <template v-slot:top>
            <div :class="['superTable__bulkActions', 'py-2',areItemsSelected ? 'show' : 'hide']">
              <v-divider></v-divider>
              <div class="py-3" v-if="canUnassign">
                <span
                  class="caption mx-4 cursor-pointer hover-7-inverted"
                  @click="itemsToDelete"
                >
                  <v-icon size="22" class="mr-2">mdi-cancel</v-icon>
                  {{$t('modules.scholarships.details.table.bulkDelete')}}
                </span>
              </div>
              <v-divider></v-divider>
            </div>
          </template>

          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.name`]="{item}">
            <div class="superTable__table__item__name">
              <v-avatar size="32" class="mr-4">
                <img :src="item.avatar" />
              </v-avatar>
              <div>{{ item.lastname }} {{ item.name }}</div>
            </div>
          </template>

          <template v-slot:[`item.action`]="{item}">
            <v-menu bottom left v-if="canUnassign">
              <template v-slot:activator="{ on: menu }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                      v-on="{ ...tooltip, ...menu }"
                      class="superTable__table__item__action"
                    >mdi-dots-vertical</v-icon>
                  </template>
                  <span>{{ $t('modules.scholarships.table.message.actions') }}</span>
                </v-tooltip>
              </template>
              <v-list class="pa-0">
                <v-list-item @click="$emit('remove', item.studentId)">
                  <v-list-item-title>
                    <v-icon>mdi-cancel</v-icon>
                    {{$t('modules.scholarships.details.table.delete')}}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-0 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span class="caption d-inline-block">
          {{ `${itemsCount.startItem} - ${itemsCount.endItem} de ${itemsCount.totalItems}` }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          @change="$emit('length-pagination-change', itemsPerPage)"
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination
          :value="page"
          :length="pageCount"
          @next="$emit('next-pagination', page)"
          @previous="$emit('previous-pagination', page)"
          @input="$emit('input-pagination', $event)"
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          max="pageCount"
          outlined
          @blur="changePage"
          v-model.number="goToPage"
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{$t('modules.scholarships.table.search.gotopage')}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Constants from '@/plugins/constants'

export default {
  name: 'SuperTable',
  props: {
    users: Array,
    headers: Array,
    clearSelected: String,
    canCreate: { type: Boolean, default: false },
    sortBy: String,
    sortDesc: Boolean,
    pageCount: { type: Number, default: 1 },
    page: { type: Number, default: 1 },
    totalItems: Number,
    canAssign: { type: Boolean, default: false },
    canUnassign: {type: Boolean, default: false},
    allowItemDeletion: { type: Boolean, default: true },
    status: Boolean,
  },
  data() {
    return {
      selectedItems: [],
      search: '',
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      markedUsers: [],
      goToPage: 1,
    };
  },
  methods: {
    keyup () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchAction()
        clearTimeout(this.timeout);
      },1000)
    },

    searchAction() {
      this.$emit('searchAction', this.search)
    },
    getAvatar(id) {
      return `${Constants.URL_DATA_SERVER}/users/${id}/avatar`;
    },
    itemsToDelete() {
      this.$emit('bulk-delete', [...this.selectedItems]);
      this.selectedItems = [];
    },
    openDetails(id) {
      this.$router.push(`scholarships/details/${id}`);
    },
    changePage() {
      if (this.goToPage > this.pageCount) this.goToPage = this.page;
      else this.$emit('input-pagination', this.goToPage)
    },
  },
  watch: {
    page (val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val < 1) this.page = 1;
      if (this.page !== this.goToPage) this.goToPage = this.page;
    },
    clearSelected() {
      this.selectedItems = [];
    },
  },
  computed: {
    areItemsSelected() {
      return !!this.selectedItems.length;
    },
    itemsCount() {
      const counter = {
        totalItems: this.totalItems,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };
      if (counter.endItem > this.totalItems) counter.endItem = this.totalItems
      return counter
    }
  }
};
</script>

<style lang="sass">
  .Scholarships-supertable
    table tr
      // cursor: pointer !important
</style>
