import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('payment');

export class Payments extends Api {
  constructor(http, config = {}) {
    super(http, url, 'payments', config);
  }

  async updatePayment(id, apiMethod, data={}) {
    const uri = this._uriConstructor(id, apiMethod);
    return this._http.post(uri, {...this._config}, {params: data});
  }
}