<template>
  <div class="loginFormCard fullHeightViewport">
    <div class="py-6 d-flex flex-column">
      <div class="d-flex justify-start">
        <v-img
          max-height="50px"
          max-width="150px"
          class="loginFormCard__logo"
          contain
          :src="logoPath"
        ></v-img>
      </div>
      <div>
        <h1 class="secondary--text">{{ recoveredData.title }}</h1>
      </div>
    </div>
    <v-alert text dense color="error" :value="recoveredData.authError" v-if="recoveredData.authError">
      {{ recoveredData.authErrorMessage }}
    </v-alert>
    <form @keyup.enter="recoveredAction" @submit.prevent="recoveredAction">
      <slot></slot>
      <div class="d-flex justify-space-between">
        <v-btn @click="goBack" class="px-6" text rounded large>Atrás</v-btn>
        <Button @clicked="recoveredAction" color="primary" class="px-6" :loading="loading" :error="errorAuth" :error-text="$t('actions-error')" text="Crear" @end="$emit('update:errorAuth',false)" rounded large></Button>
      </div>
    </form>
  </div>
</template>
<script>
import Button from '@/components/Button/Button'
export default {
  name: 'RecoveredForm',
  props: {
    loading: Boolean,
    logoPath: String,
    recoveredData: Object,
    errorAuth: Boolean,
  },
  components:{
      Button
    },
  methods: {
    recoveredAction () {
      this.$emit('recoveredAction');
    },
    goBack () {
      this.$router.go(-1)
    }
  }
};
</script>
