import bannersTranslations from './Banners/Translations';
import templateTranslations from './Template/Translations/index'
import whatsappTranslations from './Whatsapp/Translations/index'

const messagingTranslations = {
    es_CL: {
        template: { ...templateTranslations.es_CL },
    },
    es_MX: {
        whatsapp: { ...whatsappTranslations.es_MX },
    },
    es: {
        banners: { ...bannersTranslations.es },
        whatsapp: { ...whatsappTranslations.es },
        template: { ...templateTranslations.es },
    },
    en: {
        banners: { ...bannersTranslations.en },
        whatsapp: { ...whatsappTranslations.en },
        template: { ...templateTranslations.en },
    }
};

export default messagingTranslations
