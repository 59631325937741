import SyncStudent from '../Views/SyncStudentView';
import SyncSubjects from '../Views/SyncSubjectsView';

const SyncStudentRoute = [
    {
        path: '',
        component: SyncStudent,
        meta: { name: 'sync_student', group: 'portaladministrativo.canvas', path: 'sync_student', permission: 'READ' }
    },{
        path: 'sync-subjects/:id',
        component: SyncSubjects,
        meta: { name: 'sync_subjects', group: 'portaladministrativo.canvas', path: 'sync_student', permission: 'READ' }
    }
]

export default SyncStudentRoute