<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="name"
          hide-default-footer
          style="table-layout: fixed"
          @click:row="selectRow"
        >
          <template v-slot:[`item.status`]="{item}">
            <v-icon :color="STATUS[item.status].color" size="20px">{{ STATUS[item.status].icon }}</v-icon>
          </template>

          <template v-slot:[`item.correction`]="{item}">
            <v-icon
              v-if="item.optionalUrl && item.status !== 'OK'"
              color="gray" size="20px">
              mdi-chevron-right
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ModuleTable',
  props: {
    items: { type: Array, validator:(items)=> Array.isArray(items) },
    headers: { type:Array, validator:(headers)=> Array.isArray(headers) },
  },
  computed: {
    STATUS() {
      return {
        ERROR:{ color:'red', icon:'mdi-close-circle' },
        OK:{ color:'green', icon:'mdi-check-circle' },
        PENDING:{ color:'default', icon:'mdi-clock-outline' },
        WARNING:{ color:'orange', icon:'mdi-alert' },
      }
    }
  },
  methods:{
    selectRow(item){
      if(!item.optionalUrl || item.status === 'OK') return
      this.$emit('click', item)
    }
  },
}
</script>

<style lang="sass" scoped>
  ::v-deep table tr
    cursor: pointer
</style>