<template>
    <v-container fluid class="pa-0 scrollableContent">
        <v-container class="container-custom px-6" ref="sheetPdf">
            <Breadcrumbs
                :title="career ? career : ''"
                :description="career ? career : ''"
                >
            </Breadcrumbs>
            <v-row no-gutters class="row pl-6 pb-2 pt-4 mt-4">
                <v-col sm="4">
                    <v-text-field
                        prepend-inner-icon="mdi-magnify"
                        :placeholder="$t('modules.academic.gradebook.table.searchInput')"
                        outlined
                        clearable
                        class="superTable__search"
                        v-model="search"
                        @keyup.enter="searchAction"
                        @click:clear="clearAction"
                        @keyup.esc="clearAction"
                    ></v-text-field>
                </v-col>
                <v-col sm="4">
                    <Button
                        class="ml-4"
                        @clicked="searchAction"
                        outlined
                        depressed
                        :text="$t('actions.search')"
                    ></Button>
                </v-col>
            </v-row>
            <h3 class="mx-4 pl-6 pt-4"> {{`${$t('modules.academic.gradebook.bookSheet')} ${$route.params.sheetNumber}`}}</h3>
            <h5 class="mx-4 pl-6 pb-2 mb-6"> {{`${totalSheets} ${$t('modules.academic.gradebook.sheets')}`}}</h5>
            <SuperTable
                class="ml-6"
                :headers="headers"
                :items="sheets"
                :pageCount="pagination.pagesAmount"
                :page="pagination.page"
                :totalItems="totalSheets"
                @input-pagination="onInputPagination"
                @length-pagination-change="onLengthPaginationChange"
                ref="tabla"
                />
        </v-container>
    </v-container>

</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import {$getStudentsByBookAndCareer} from '../Services';
import SuperTable from '../Components/SuperTable';

    export default {
        name: 'Sheet',
        components: { 
            Breadcrumbs,
            Button,
            SuperTable,
        },
        data() {
            return {
                sheets: [],
                search: null,
                totalSheets: 0,
                career: '',
                numero: '',
                pagination: {
                        limit: 20,
                        page: 1,
                        pagesAmount: 1
                    },
                items: [],
            headers: [
                { text: this.$t('modules.academic.gradebook.headers.nameStudent'), value: 'nombreAlumno' },
                { text: this.$t('modules.academic.gradebook.headers.documentation'), value: 'dniAlumno', align: 'center' },
                { text: this.$t('modules.academic.gradebook.headers.sheet'), value: 'folio' },
                { text: this.$t('modules.academic.gradebook.headers.book'), value: 'libro' },
                { text: this.$t('modules.academic.gradebook.headers.career'), value: 'carrera' },
                { text: this.$t('modules.academic.gradebook.headers.legajo'), value: 'legajo' },
                { text: this.$t('modules.academic.gradebook.headers.status'), value: 'status', align: 'center' },
                ],
            }
        },
        
        mounted() {
            this.fetchData();
        },
        methods: {
        async fetchData(){
            try{
                const params = {
                    page:  this.pagination.page ?  this.pagination.page - 1 : 1,
                    length: this.pagination.limit,
                }
                const filterParams = {
                    book: this.$route.params.sheetNumber,
                    careerId: this.$route.params.id,
                    search: this.search,
                    code: this.$route.params.planNumber
                }
                const respuesta = await $getStudentsByBookAndCareer.filter(filterParams, {params});
                this.sheets = respuesta.data.content;
                this.totalSheets = respuesta.data.totalElements;
                this.career = respuesta.data.content[0]?.carrera
                this.pagination.pagesAmount = respuesta.data.totalPages;
            }
        catch(e){
            this.sheets = [];
            return e;
        } finally{
            this.loading = false;
        }
    },
        clearAction() {
            this.search = null
            this.$emit('search', '');
        },
        searchAction() {
            this.pagination.page = 1
            this.fetchData();
        },
        onInputPagination(event) {
            this.pagination.page = event;
            this.fetchData();
        },
        onLengthPaginationChange(event) {
            this.pagination = {
                page: 1,
                limit: event,
                pagesAmount: 1
            };
            this.fetchData();
            },
    },
};
</script>