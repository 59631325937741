<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0" v-if="!syncSubjectStatus">
            <v-container class="container-custom px-6">
                <Breadcrumbs :description="$t('modules.canvas.syncStudent.breadcrumbs.description2')"
                            :title="$t('modules.canvas.syncStudent.breadcrumbs.title2')">
                </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            show-expand
                            @goToNotes="goToNotes"
                            :headers="headers"
                            :data="subjects"
                            :isPagination="false"
                            :isSearching="false"
                            :permissions="{ create: canCreate }"
                            @syncUpSubject="(data)=>syncUpSubjectPopUp(data)"
                        ></SuperTable>
                    </v-col>
                </v-row>
            </v-container>
            <TemporaryRightDrawer
                :open="tmpDrawer.open"
                @tmpDrawerOpen="tmpDrawerOpen"
                :title="tmpDrawer.title"
                :description="tmpDrawer.description">
                <NotesView
                    @closeDrawer="()=>{this.tmpDrawer.open = false}"
                    @newUpdate="tmpDrawer.open" 
                    :notas="notas"
                    :studentSubjectId="this.studentSubjectId"
                    :fetchNotes="fetchNotes"
                    >
                </NotesView>
            </TemporaryRightDrawer>
        </v-container>
        <SuccessMessage
        v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import SuperTable from '../Components/SuperTable';
import NotesView from './NotesView.vue'
import { $materias, $users, $studentCanvas } from '../Services';
import moment from 'moment'; 

export default {
    name: 'SyncSubjectsView',
    components: {
        Breadcrumbs,
        SuperTable,
        TemporaryRightDrawer,
        SuccessMessage,
        NotesView
    },
    data () {
        return {
            syncSubjectStatus:false,
            subjects: [],
            subjectsCanvas: [],
            notas: [],
            headers: [
                {
                    text: this.$t('modules.canvas.syncStudent.subject.table.headers.code'),
                    value: 'code',
                },
                {
                    text: this.$t('modules.canvas.syncStudent.subject.table.headers.name'),
                    value: 'description',
                },
                {
                    text: this.$t('modules.canvas.syncStudent.subject.table.headers.termCode'),
                    value: 'termCode',
                    sortable: false
                },
                {
                    text: this.$t('modules.canvas.syncStudent.subject.table.headers.synchronization'),
                    value: 'lmsEnrollmentId',
                    align: 'center',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncStudent.subject.table.headers.edit'),
                    value: 'actionSyncSubject',
                    align: 'center',
                    sortable: false
                } 
            ],
            tmpDrawer: {
                open: false,
                title: this.$t('modules.canvas.syncStudent.drawer.title'),
                description: this.$t('modules.canvas.syncStudent.drawer.description')
            },
            successMessage:{},
            idSubjectCanvas:'',
            studentSubjectId: null,
        }
    },
    methods: {
        async goToNotes({studentSubjectId}){
            this.fetchNotes(studentSubjectId)
            this.tmpDrawerOpen()
        },
        async fetchNotes(studentSubjectId){
            try{
                const {data} = await $materias.findById('practical-grades',null,{params: {studentSubjectId}});
                this.notas = data.map(nota => {
                    const grade = nota.grade ?  nota.grade.toFixed(1)??0 : ''
                    const examTypeMeaning = nota.examTypeMeaning ? nota.examTypeMeaning : ''
                    const practicalDate = nota.practicalDate ? moment(nota.practicalDate).format('DD-MM-YYYY') : ''
                    const examType = nota.examType ? nota.examType : ''
                    const gradeId = nota.gradeId ? nota.gradeId : ''
                    return {...nota, grade, examTypeMeaning, practicalDate, examType, gradeId}
                })
                this.studentSubjectId = studentSubjectId
                this.lmsUserId = this.$route.query.lmsUserId
            }catch(error){
                this.notas = []
            }
        },
        async fetchSubjects() {
            if(this.$route.params.id != null){
                try{
                    const {data} = await $materias.findById('attending',null,{params: {studentCareerId:this.$route.params.id}});                                       
                    await this.fetchSubjectsCanvas()
                    this.subjects = data.map(subject =>{
                        const subjectCanvas = this.subjectsCanvas.filter(subjectCanvas => {
                            return subject?.lmsCourseId === subjectCanvas?.lmsCourseId
                        })
                        return {...subject, subjectCanvas}
                    })
                
                    
                }catch(error){
                    this.subjects = []
                }
            }else{
                    this.$router.go(-1)
            }
        },
        async fetchSubjectsCanvas() {
            try{
                const {data} = await $users.findById( `${this.$route.query.lmsUserId}/enrollments?isStudent=true`,null, null);
                this.subjectsCanvas = data
            }catch(error){
                this.subjectsCanvas = []
            }
        },
        syncUpSubjectPopUp(data){
            if(data.lmsCourseId !== null){
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.canvas.syncStudent.message.syncUpSubject'),
                    content: [
                        {
                            value: data.description
                        }
                    ],
                    actionPrimary: {
                        text: this.$t('modules.canvas.syncStudent.message.syncAccept'),
                        callback: () => this.syncUpSubject(data)
                    },
                    actionSecondary: {
                        text: this.$t('modules.canvas.syncStudent.message.syncCancel'),
                        callback() {}
                    },
                    icon: { color: 'warning', name: 'mdi-sync' },
                    color: 'primary'
                })
            }
            else{
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.canvas.syncStudent.message.errorLmsCourseNull'),
                    content: [
                        {
                            value: data.description
                        }
                    ],
                    actionPrimary: {
                        text: this.$t('modules.canvas.syncStudent.message.syncCancel'),
                        callback() {}
                    },
                    icon: { color: 'error', name: 'mdi-close' },
                    color: 'primary'
                })
            }
            
        },
        async syncUpSubject(data){
            const bodySync = {
                studentSubjectId: data.studentSubjectId,
                lmsEnrollmentId: data.subjectCanvas[0].lmsEnrollmentId
            }
            try {
                await $studentCanvas.update('sync-subject',bodySync)
                this.fetchSubjects()
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.success'),
                    content: [{ value: this.$t('modules.canvas.syncStudent.message.syncUpSubjectSuccess') }],
                    actionPrimary: { text: this.$t('actions.close'), callback () {} },
                    icon: {color: 'success', name: 'mdi-check'},
                    color: 'primary',
                })
            } catch (error) {
                this.successMessage.type = 'error';
                this.successMessage.title = this.$t('modules.canvas.syncStudent.message.syncUpSubjectError');
                this.successMessage.actionPrimary = {
                    text: this.$t('modules.canvas.syncStudent.actions.close'), callback: () => {
                        this.$router.push('/canvas/sync-student')
                    } 
                }
                this.successMessage.actionSecondary = null;
            } finally {
                this.syncSubjectStatus = false;
            }
        },
        tmpDrawerOpen() {
            this.tmpDrawer.open = !this.tmpDrawer.open
            if (this.tmpDrawer.open);
        }
    },
    computed: {
        canCreate() {
            return this.$permissions.portaladministrativo.canvas.sync_student.UPDATE
        },
    },
    mounted() {
        if(this.$route.params.id == null){
            this.$router.go(-1)
        }
        this.fetchSubjects();
    },
}
</script>