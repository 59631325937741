<template>
    <v-container fluid>
    <v-row>
        <v-col cols="12">
        <v-card outlined class="py-8 px-10">
            <h3>{{ $t('modules.academic.gradebook.download.drawer.title') }}</h3><br>
            <p>{{ $t('modules.academic.gradebook.download.drawer.description') }}</p>
            <SuperTableBooks
                :headers="headers"
                :items="lastBooks"
                @downloadBook="downloadBook"
            ></SuperTableBooks>
        </v-card>
        </v-col>
        <v-col class="pb-8 d-flex justify-contentn-end justify-end">
        <Button
            white
            :text="$t('actions.cancel')"
            @clicked="$emit('closeDrawer');"
            class="mr-4"
        ></Button>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
import SuperTableBooks from '#/portaladministrativo/Academic/Gradebook/Components/SuperTableBooks';
import Button from '@/components/Button/Button';
import download from 'downloadjs';
import { $reports } from '../Services';
import { mapGetters } from 'vuex';

export default {
    name: 'BooksDownload',
    components: {
        SuperTableBooks,
        Button
    },
    props: {
        lastBooks: Array
    },
    data() {
        return {
            headers: [
            {
                text: this.$t('modules.academic.gradebook.download.headers.book'),
                value: 'name'
            },
            {
                text: this.$t('modules.academic.gradebook.download.headers.date'),
                sortable: false,
                value: 'date'
            },
            {
                text: this.$t('modules.academic.gradebook.download.headers.status'),
                sortable: false,
                value: 'status'
            },
            {
                text: this.$t('modules.academic.gradebook.download.headers.download'),
                sortable: false,
                value: 'download'
            }
            ]
        }
    },
    methods: {
    async downloadBook(item) {
        try {
            const {headers, data} = await $reports.academic.find('getPdfFile', null, { params: { fileName: `${item.name}`, userId: this.userId } })
            download(data, item.name, { fileName: `${item.name}.pdf` }, headers.get('content-type'))
        } catch (error) {
            this.$store.dispatch('commons/openPopUp', {
                title: this.$t('actions.error'),
                content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.reports.download.error')}],
                actionPrimary: { text: this.$t('actions.accept'), callback() { }},
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary'
        });
        throw error
        }
    }
    },
    computed: {
    ...mapGetters({
        userId: 'commons/getUserID',
    })
    }
};
</script>
