<template>
    <v-container fluid class="superTable pa-0">

      <v-card
        class="mx-auto"
        width="1000"
      >
        <v-list>
          <!-- <v-list-item>
            <v-list-item-title>Reglamento (completar con traducciones)</v-list-item-title>
          </v-list-item> -->

          <v-list-group
            v-for="(item, index) in Regulation"
            :key="index"
            :value="true"
            prepend-icon="mdi-circle-medium"
          >
          <!-- titulo del capitulo-->
            <template v-slot:activator>
              <v-list-item-title v-if="editing != item.name" class="font-weight-bold">{{item.name}}</v-list-item-title>

              <template v-if="editing == item.name">
                <v-list-item-content>
                  <v-row>
                    <v-text-field @click.stop.prevent class="mx-5" :rules="[validationRules.required, validationRules.invalid, validationRules.invalidName, validationRules.nameExist]" v-model="editingValue" solo required></v-text-field>
                  </v-row>

                  <v-row class="d-flex justify-end mr-2">
                    <Button
                      white
                      :text="$t('actions.cancel')"
                      @clicked="cancelEdit"
                      class="mr-4"
                    ></Button>
                    <Button
                      :loading="saveButton.loading"
                      :success="saveButton.success"
                      :error="saveButton.error"
                      :text="$t('actions.save')"
                      :successText="$t('actions.saved')"
                      :errorText="$t('actions.error')"
                      :disabled="!validInput || !validChapterName"
                      @clicked="updateChapter(item.id, 'chapName')"
                    ></Button>
                  </v-row>
                </v-list-item-content>
              </template>

              <!-- actions btn -->
              <v-menu bottom left v-if="editing != item.name">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                </template>
                <v-list class="pa-0">
                  <v-list-item @click="selectToEdit(item.name), id = item.id" v-if="permissions.rules.update">
                    <v-list-item-title>
                      <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteChapter(item.id)" v-if="permissions.rules.delete">
                    <v-list-item-title>
                      <v-icon>mdi-delete</v-icon> {{ $t('modules.regulation.table.actions.deleteChapter') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="addDescription(item.id)" v-if="permissions.rules.update && (item.content == '' || item.content == null)">
                    <v-list-item-title>
                      <v-icon>mdi-plus</v-icon> {{ $t('modules.regulation.table.actions.addDescription') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="createNewSubchapter(item.id)" v-if="permissions.rules.update">
                    <v-list-item-title>
                      <v-icon>mdi-plus</v-icon> {{ $t('modules.regulation.table.actions.addSubchapter') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </template>

            <!-- cap descripcion -->
            <v-list-item
              v-if="item.content && item.content!=null && item.content!=''"
              >
                <v-list-item-content>

                  <div v-if="editing != item.content || id != item.id" class="body-1" v-html="item.content"></div>

                  <template v-if="editing == item.content && id == item.id">
                    <!-- <v-row>
                      <v-textarea class="mx-2" v-model="editingValue" solo></v-textarea>
                    </v-row> -->
                    <v-row>
                      <v-col>
                        <div contenteditable="true" :class="{'editorDanger': !validInput}" class="rich-text-editor elevation-2 mx-2 body-1" @input="updateContent" v-html="item.content"></div>
                        <transition name="fade">
                          <div v-if="!validInput" class="error--text caption ml-3">
                            {{ $t('modules.regulation.table.messages.editedValidationError') }}
                          </div>
                        </transition>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-end mr-2">
                      <Button
                        white
                        :text="$t('actions.cancel')"
                        @clicked="cancelEdit"
                        class="mr-4"
                      ></Button>
                      <Button
                        :loading="saveButton.loading"
                        :success="saveButton.success"
                        :error="saveButton.error"
                        :text="$t('actions.save')"
                        :successText="$t('actions.saved')"
                        :errorText="$t('actions.error')"
                        :disabled="!validInput"
                        @clicked="updateChapter(item.id, 'chapContent')"
                      ></Button>
                    </v-row>
                  </template>

                </v-list-item-content>

                 <!-- actions btn -->
                <v-menu v-if="editing != item.content || id != item.id" bottom left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item @click="selectToEdit(item.content), id = item.id" v-if="permissions.rules.update">
                      <v-list-item-title>
                        <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteDescription(item.id, 'chapter')" v-if="permissions.rules.delete">
                      <v-list-item-title>
                        <v-icon>mdi-delete</v-icon> {{ $t('actions.delete') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

            </v-list-item>

            <v-list-group
              v-for="(SubChapter, i) in item.subchapter"
              :key="i"
              :value="true"
              no-action
              sub-group
            >
            <!-- subchapter nombre -->
              <template v-slot:activator >
                <v-list-item-content>
                  <v-list-item-title v-if="editing != SubChapter.name || id != SubChapter.id" class="font-weight-medium">{{ SubChapter.name }}</v-list-item-title>

                  <template v-if="editing == SubChapter.name && id == SubChapter.id">
                    <v-row>
                      <v-text-field @click.stop.prevent class="mx-5" :rules="[validationRules.required, validationRules.invalid]" v-model="editingValue" solo required></v-text-field>
                    </v-row>
                    <v-row class="d-flex justify-end mr-5">
                      <Button
                        white
                        :text="$t('actions.cancel')"
                        @clicked="cancelEdit"
                        class="mr-4"
                      ></Button>
                      <Button
                        :loading="saveButton.loading"
                        :success="saveButton.success"
                        :error="saveButton.error"
                        :text="$t('actions.save')"
                        :successText="$t('actions.saved')"
                        :errorText="$t('actions.error')"
                        :disabled="!validInput"
                        @clicked="updateChapter(SubChapter.id, 'subchapName', SubChapter.chapterId)"
                      ></Button>
                    </v-row>
                  </template>

                </v-list-item-content>
                 <!-- actions btn -->
                 <v-menu v-if="editing != SubChapter.name || id != SubChapter.id" bottom left>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item @click="selectToEdit(SubChapter.name), id = SubChapter.id" v-if="permissions.rules.update">
                        <v-list-item-title>
                          <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="addDescriptionSubchapter(SubChapter.id)" v-if="permissions.rules.update && (SubChapter.content == '' || SubChapter.content == null)">
                        <v-list-item-title>
                          <v-icon>mdi-plus</v-icon> {{ $t('modules.regulation.table.actions.addDescription') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="deleteSubChapter(SubChapter.id)" v-if="permissions.rules.delete">
                        <v-list-item-title>
                          <v-icon>mdi-delete</v-icon> {{ $t('modules.regulation.table.actions.deleteSubChapter') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
              </template>

              <!-- subchapter content -->
              <v-list-item v-if="SubChapter.content != '' && SubChapter.content != null"
              >
                <v-list-item-content v-if="SubChapter.content != '' && SubChapter.content != null && (editing != SubChapter.content || id != SubChapter.id)">
                  <div class="body-1" v-html="SubChapter.content"></div>
                </v-list-item-content>

                <template v-if="editing == SubChapter.content && id == SubChapter.id">
                  <v-list-item-content>
                    <!-- <v-row>
                      <v-textarea class="mx-5" v-model="editingValue" solo></v-textarea>
                    </v-row> -->
                    <v-row>
                      <v-col>
                        <div contenteditable="true" :class="{'editorDanger': !validInput}" class="rich-text-editor elevation-2 mx-2 body-1" @input="updateContent" v-html="SubChapter.content"></div>
                        <transition name="fade">
                          <div v-if="!validInput" class="error--text caption ml-2">
                            {{ $t('modules.regulation.table.messages.editedValidationError') }}
                          </div>
                        </transition>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-end mr-5">
                      <Button
                        white
                        :text="$t('actions.cancel')"
                        @clicked="cancelEdit"
                        class="mr-4"
                      ></Button>
                      <Button
                        :loading="saveButton.loading"
                        :success="saveButton.success"
                        :error="saveButton.error"
                        :text="$t('actions.save')"
                        :successText="$t('actions.saved')"
                        :errorText="$t('actions.error')"
                        :disabled="!validInput"
                        @clicked="updateChapter(SubChapter.id, 'subchapContent', SubChapter.chapterId)"
                      ></Button>
                    </v-row>
                  </v-list-item-content>

                </template>

                 <!-- actions btn -->
                <v-menu v-if="editing != SubChapter.content || id != SubChapter.id" bottom left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item @click="selectToEdit(SubChapter.content), id = SubChapter.id" v-if="permissions.rules.update">
                      <v-list-item-title>
                        <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteDescription(SubChapter.id, 'subchapter', SubChapter.chapterId)" v-if="permissions.rules.delete">
                      <v-list-item-title>
                        <v-icon>mdi-delete</v-icon> {{ $t('actions.delete') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </v-list-item>
            </v-list-group>

          </v-list-group>
        </v-list>
      </v-card>
    </v-container>
  </template>

  <script>
  import Button from '@/components/Button/Button';

  export default {
    name: 'SuperTable',
    components: {
      Button,
    },
    props: {
      headers: Array,
      permissions: {
        rules: {update: false, delete: false},
      },
      Regulation: [],
    },
    data () {
      return {
        saveButton: {
          loading: false,
          success: false,
          error: false,
        },
        id:'',
        editing: '',
        editingValue: '',
        chapter: {},
        newSubchapter : {
          content : 'Edite el contenido del subcapítulo.',
          chapterId : '',
          name : 'Edite el nombre del subcapítulo.',
        },
        validationRules: {
          required: value => !!value || this.$t('modules.regulation.table.messages.editedNameValidationError'),
          // invalid: value => !!value.replace(/(&nbsp;|<[^>]+>)/g, '').trim() != '' || this.$t('modules.regulation.table.messages.editedNameErrorInvalid'),
          // invalidName: value => !! (/^CAPÍTULO \d+ \| [A-ZÁÉÍÓÚ]+/u.test(value) ==true && this.$isTeclab) || (/^TÍTULO [A-Z]+ \| [A-ZÁÉÍÓÚ]+/u.test(value) ==true && this.$isIPP) || this.$t('modules.regulation.table.messages.editedNameErrorInvalid'),
          nameExist: value => !!this.compareNames(value) != false || this.$t('modules.regulation.table.messages.nameExist'),
        }
      }
    },
    computed: {
      'validInput' : function() {
        if (this.editingValue.replace(/(&nbsp;|<[^>]+>)/g, '').trim() != '') {
          return true;
        } else {
          return false;
        }
      },
      'validChapterName' : function() {
        const splitedName = this.editingValue.split('|');
        for (const e of this.Regulation) {
          const splitedAux = e.name.split('|');
          if (this.$isTeclab && splitedAux[0].trim() === splitedName[0].trim() && this.id != e.id) {
            return false;
          }
          if (this.$isIPP && splitedAux[0].trim() === splitedName[0].trim() && this.id != e.id) {
            return false;
          }
        }
        if (this.$isTeclab && !/^CAPÍTULO \d+/u.test(this.editingValue)) {
          return false;
        }
        if (this.$isIPP && !/^TÍTULO [A-Z]+/u.test(this.editingValue)) {
          return false;
        }
        return true;
      }
    },
    methods: {
      compareNames(name) {
        const splitedName = name.split('|');
        for (const e of this.Regulation) {
          const splitedAux = e.name.split('|');
          if (splitedAux[0].trim() === splitedName[0].trim() && this.id != e.id) {
            return false;
          }
        }
        return true;
      },
      updateContent(event) {
        this.editingValue = event.target.innerHTML;
      },
      createNewSubchapter(id) {
        this.newSubchapter.chapterId = id;
        this.$emit('newSubchapter', this.newSubchapter)
      },
      addDescription(id) {
        const chapter = {
          name: '',
          content: '',
          order: ''
        };
        const aux = [...this.Regulation.filter(e => e.id == id)];
        if(aux[0].content == undefined || aux[0].content == '' || aux[0].content == null) {
          chapter.name =  aux[0].name;
          chapter.content = 'Edite el contenido del capítulo.';
          chapter.order  = aux[0].order;
        this.$emit('updateChapter', id, chapter);
        }
      },
      addDescriptionSubchapter(id) {
        const subchapter = {
          content: 'Edite el contenido del subcapítulo.'
        }
        this.$emit('updateSubchapter', id, subchapter);
      },
      deleteDescription(id, str, chapterId) {
        let aux = '';
        const chapter = {
          name: '',
          content: '',
          order: ''
        };
        const subchapter = {
          name: '',
          content: '',
          chapterId: ''
        };
        let eventToEmit = '';
        let itemToEdit = {};
        switch(str) {
          case'chapter':
            aux = [...this.Regulation.filter(e => e.id == id)];
            chapter.name =  aux[0].name;
            chapter.content = '';
            chapter.order  = aux[0].order;
            itemToEdit = chapter;
            eventToEmit = 'deleteChapterDescriptionPopUp';
            break;
          case 'subchapter':
            aux = [...[...this.Regulation.filter(e => e.id == chapterId)][0].subchapter.filter(e => e.id == id)]
            subchapter.name = aux[0].name;
            subchapter.content = '';
            subchapter.chapterId = aux[0].chapterId;
            itemToEdit = subchapter;
            eventToEmit = 'deleteSubchapterDescriptionPopUp';
            break;
        }
        this.$emit(eventToEmit, id, itemToEdit)
      },
      selectToEdit(str) {
        this.editing = str;
        this.editingValue = str;
      },
      cancelEdit() {
        event.stopPropagation();
        this.id = '';
        this.editing = '';
        this.editingValue = '';
      },
      updateChapter (id, str, chapterId) {
        event.stopPropagation();
        let itemToEdit = {};
        let aux = {};
        let eventToEmit = '';
        const chapter = {
          name: '',
          content: '',
          order: ''
        };
        const subchapter = {
          subchapterName: '',
          subchapterContent: '',
          chapterId: ''
        };
        switch (str) {
          case 'chapName':
            aux = [...this.Regulation.filter(e => e.id == id)];
            chapter.name = this.editingValue;
            chapter.content = aux[0].content;
            chapter.order  = aux[0].order;

            itemToEdit = chapter;
            eventToEmit = 'updateChapter';
            break;
          case'chapContent':
            aux = [...this.Regulation.filter(e => e.id == id)];
            chapter.name = aux[0].name;
            chapter.content = this.editingValue;
            chapter.order  = aux[0].order;

            itemToEdit = chapter;
            eventToEmit = 'updateChapter';
            break;
          case 'subchapName':
            aux = [...[...this.Regulation.filter(e => e.id == chapterId)][0].subchapter.filter(e => e.id == id)]

            subchapter.name = this.editingValue;
            subchapter.content = aux[0].content;
            subchapter.chapterId = aux[0].chapterId;

            itemToEdit = subchapter;
            eventToEmit = 'updateSubchapter';
            break;
          case 'subchapContent':
            aux = [...[...this.Regulation.filter(e => e.id == chapterId)][0].subchapter.filter(e => e.id == id)]

            subchapter.name = aux[0].name;
            subchapter.content = this.editingValue;
            subchapter.chapterId = aux[0].chapterId;

            itemToEdit = subchapter;
            eventToEmit = 'updateSubchapter';
            break;
        }
        this.id = '';
        this.$emit(eventToEmit, id, itemToEdit);
        this.editing = '';
        this.editingValue = '';
      },
      deleteChapter (id) {
        this.$emit('deleteChapter', id);
      },
      deleteSubChapter (id) {
        this.$emit('deleteSubChapter', id);
      },
    },
  }
  </script>

  <style>
  .rich-text-editor {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    min-height: 100px;
    font-family: Arial, sans-serif;
  }

  .rich-text-editor:hover {
    cursor: text;
  }

  .rich-text-editor:focus {
    outline: none;
  }
  .rich-text-editor table {
  border-collapse: collapse;
  width: 100%;
  }

  .rich-text-editor th,
  .rich-text-editor td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  .rich-text-editor th {
    background-color: #f2f2f2;
  }
  /* .editorDanger{
    border: 1px solid #fa5252;
  } */

  .fade-enter-active, .fade-leave-active {
    transition: all 0.3s;
  }

  .fade-enter, .fade-leave-to {
    transform: translateY(-5px);
    opacity: 0;
}

  /* table {
  border-collapse: collapse;
  width: 100%;
  }

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  } */

  </style>
