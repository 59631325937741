import Canvas from './Canvas.vue';
import SyncCourses from './SyncCourses/SyncCourses'
import SyncCoursesRoute from './SyncCourses/Routes';
import SyncStudent from './SyncStudent/SyncStudent'
import SyncStudentRoute from './SyncStudent/Routes';
import SyncTeacher from './SyncTeacher/SyncTeacher'
import SyncTeacherRoute from './SyncTeacher/Routes';

const CanvasRoute = {
    path: '/canvas',
    meta: {
        name: {
            en:'Canvas',
            es:'Canvas',
        }
    }, 
    component: Canvas,
    children: [
        {
            path: 'sync-courses',
            component: SyncCourses,
            children: SyncCoursesRoute,
            meta: { name: {es:'Sincronización de cursos', en:'Cynchronization of courses'}}
        },
        {
            path: 'sync-student',
            component: SyncStudent,
            children: SyncStudentRoute,
            meta: { name: {es:'Sincronización de alumnos', en:'synchronization of students'}}
        },
        {
            path: 'sync-teacher',
            component: SyncTeacher,
            children: SyncTeacherRoute,
            meta: { name: {es:'Sincronización de profesores', en:'synchronization of teachers'}}
        },
    ]
}

export default CanvasRoute