/**
 * This file  contains the initial configuration
 * in order to construct and export the base url
 * used along the application
 *
 * @environment string - This should be 'development', 'production', etc
 * @server Object - The keys of this object should be one of each environments
 *  @environment Object - This should be development, production, etc
 *    @https boolean - This indicates if should use a https protocol
 *    @port number - This is the number of the port that should be used
 *    @sufix string - A sufix between the domain and resource name like '/v1'
 *    @domain string - This is the final base url that should be used
 */

import Configuration from './configuration';

export const baseConf = {
  env: Configuration.value('env') || 'development',
  server: {
    aws: {
      https: true,
      port: 0,
      domain: `${Configuration.value('subDomain')}.${Configuration.value('url')}`,
      sufix: `${Configuration.value('gateway')}`
    },
    local: {
      https: false,
      port: 0,
      domain: `localhost:8000`,
      sufix: 'gw',
    },
    development: {
      https: true,
      port: 0,
      domain: `serviciosdev.${Configuration.value('url')}`,
      sufix: 'gw',
    },
    staging: {
      https: true,
      port: 0,
      domain: `serviciospre.${Configuration.value('url')}`,
      sufix: 'gw',
    },
    production: {
      https: true,
      port: 0,
      domain: `api.${Configuration.value('url')}`,
      sufix: 'gw',
    },
    test: {
      https: false,
      sufix: 'gw',
      port: 0,
      domain: '',
    }
  },
};

// eslint-disable-next-line func-names
export default (() => {
  let baseUrl = baseConf.server[baseConf.env].https ? 'https://' : 'http://';
  baseUrl += baseConf.server[baseConf.env].domain;
  if (baseConf.server[baseConf.env].port) baseUrl += `:${baseConf.server[baseConf.env].port}`;
  if (baseConf.server[baseConf.env].sufix) baseUrl += `/${baseConf.server[baseConf.env].sufix}`;
  return baseUrl;
})();
