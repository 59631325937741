const syncCoursesTranslations = {
    es : {
            breadcrumbs: {
                title: 'Sincronización de Cursos',
                description: 'Administración de sincronización de Cursos',
            },
            table:{
                headers:{
                    identification: 'DNI',
                    name: 'Nombre',
                    lastname: 'Apellido',
                    career: 'Carrera',
                    career_status: 'Estado de carrera',
                    term: 'Periodo',
                    synchronization: 'Sincronización',
                    edit:'',
                }
            },
            subject:{
                table:{
                    headers:{
                        code: 'Código',
                        name: 'Nombre',
                        subjectStatus: 'Estado de la materia',
                        termCode: 'Periodo',
                        synchronization: 'Sincronizar',
                        sisSectionId: 'SisSectionId',
                        edit:'',
                        modality: 'R19'
                    }
                },
            },
            actions:{
                sync_up: 'Sincronizar',
                viewSections: ' Ver secciones',
            },
            message:{
                syncUpCouse: 'Estas seguro que deseas sincronizar',
                syncUpCourseSuccess: 'Curso sincronizado correctamente',
                syncUpCoursesSectionSuccess: 'Sección sincronizado correctamente',
                syncUpCoursesSectionError: 'Error al sincronizar la sección',
                syncUpCoursesError: 'Error al sincronizar el curso',
                syncUpSubject: 'Estas seguro que deseas sincronizar la materia',
                syncUpSubjectSuccess: 'Materia sincronizado correctamente',
                syncUpSubjectError: 'Error al sincronizar la materia',
                syncAccept: 'Si, sincronizar',
                syncCancel: 'Cancelar'
            },
            drawer:{
                title: 'Notas',
                description: 'Lista de las notas en los módulos',
                table:{
                    title: 'Actualización de notas',
                    description: 'Aquí podrás actualizar las notas del sistema con las notas de canvas.',
                    headers:{
                        modules:'Módulos',
                        canvas:'Canvas',
                        sis:'SIS'
                    }
                }
            }
    },
}

export default syncCoursesTranslations