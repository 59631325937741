<template>
    <v-container fluid class="superTable">
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    :headers="headers"
                    :items="data"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    item-key="id"
                    sort-by="service"
                    class="superTable__table"
                    style="table-layout: fixed"
                >

                    <template v-slot:[`item.status`]="{item}">
                        <v-icon :color="$t(`modules.statusconnection.table.icon.${item.status}.color`)">
                            {{$t(`modules.statusconnection.table.icon.${item.status}.description`)}}
                        </v-icon>
                    </template>

                    <template v-slot:[`item.service`]="{item}">
                        <span class="font-weight-bold">{{ item.service }}</span>
                    </template>

                    <template v-slot:[`item.version`]="{item}">
                        <span v-if="item.name || item.version">{{ `${item.name ? item.name : ''} ${item.version ? item.version : ''}` }}</span>
                        <span v-else>-</span>
                    </template>

                    <template v-slot:[`item.action`]="{item}">
                        <v-icon class="icon" @click="$emit('check', item)">mdi-refresh</v-icon>
                    </template>

                    <template v-slot:[`item.action123`]="{item}">
                        <v-menu bottom left v-if="item.status === 'Preinscripto' && (permissions.read || permissions.update || permissions.delete)">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item v-if="permissions.read" @click="openDetails(item.id)">
                                    <v-list-item-title>
                                        <v-icon>mdi-eye</v-icon>
                                        {{ $t('modules.scholarships.table.search.details') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="permissions.update" @click="editItem(item.id)">
                                    <v-list-item-title>
                                        <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="permissions.delete" @click="deleteItem(item.id)">
                                    <v-list-item-title>
                                        <v-icon>mdi-delete</v-icon> {{ $t('modules.preRegistration.table.rowActions.unsubscribe') }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
        <v-row no-gutters align="center">
            <v-col sm="3">
                <span class="caption d-inline-block">{{ `${itemsCount.startItem} - ${itemsCount.endItem} ${$t('modules.preRegistration.table.of')} ${itemsCount.totalItems}` }}</span>
                <v-select
                    :value="itemsPerPage"
                    :items="itemsPerPageAvailable"
                    :placeholder="itemsPerPage.toString()"
                    outlined
                    class="superTable__paginationSelect d-inline-block ml-4"
                ></v-select>
                    <!-- @change="$emit('itemsPerPageChange', $event)" -->
            </v-col>
            <v-col sm="6" class="text-center">
                <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
            </v-col>
            <v-col sm="3" align="right">
                <v-text-field
                    :value="page"
                    :max="pageCount"
                    outlined
                    class="superTable__paginationInput d-inline-block mr-4"
                ></v-text-field>
                <span class="caption d-inline-block">{{$t('actions.goToPage')}}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'SuperTable',
        props: {
            data: Array,
            headers: Array,
            permissions: {read: false, update: false, delete: false},
        },
        data() {
            return {
                search: null,
                page: 1,
                itemsPerPage: 50,
                itemsPerPageAvailable: [ 20, 50, 100, 200 ],
            }
        },
        computed: {
            pageCount () {
                let counter = this.data.length / this.itemsPerPage
                if ( counter < 1 ) counter = 1

                return Math.ceil(counter)
            },
            itemsCount () {
                const counter = {
                    totalItems: this.data.length,
                    startItem: ( ( this.page * this.itemsPerPage ) - this.itemsPerPage ) +  1,
                    endItem: this.page * this.itemsPerPage
                };

                if ( counter.endItem > this.data.length ) {
                    counter.endItem = this.data.length
                }

                return counter
            }
        },
        methods:{
            // clearAction() {
            //     this.search = null
            //     this.searchAction()
            // },
            // searchAction() {
            //     this.$emit('searchAction', this.search);
            // },
            // editItem(id) {
            //     this.$emit('update', id);
            // },
            // deleteItem(id) {
            //     this.$emit('delete', id);
            // },
            // openDetails(id) {
            //     if (this.permissions.read) this.$router.push(`preregistration/detail/${id}`);
            // }
        },
    };
</script>