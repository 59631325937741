import trae from 'trae';
// import jwtConfig from '../helpers/before-middlewares';
import afterMiddlewares from '../helpers/after-middlewares'

export function configHttpClient(vue) {
  const fin = () => {
    vue.$store.dispatch('commons/decreaseLoading')
  }
  const jwtConfig = (config) => {
    const token = localStorage.getItem('access_token')
    ? JSON.parse(localStorage.getItem('access_token'))
    : '';
    
    vue.$store.dispatch('commons/increaseLoading')
    
    config.headers.Authorization = config.authorization ? 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0' : `Bearer ${token}`;
    config.headers['Content-Type'] = 'application/json';
    config.cache = 'no-cache';
    config.mode = 'cors';

    return config;
  }

  trae.before(jwtConfig);
  trae.after(...afterMiddlewares);
  trae.finally(fin);
}

export default trae;
