<template>
	<div>
		<v-container fluid class="d-flex pa-0 fullHeight">
			<v-container fluid class="px-0">

				<h4 class="primary--text mt-0">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.creditcardPaid') }}</h4>

          <v-row class="center mb-0 pl-3 pr-3">
            <v-col  class="d-flex align-center py-0" cols="2" lg="1">
              <v-checkbox v-if="!isAdminPayment?.selected === true"  color="primary" v-model="selectedall" 
              :disabled="hasAdaptationInsurance || generatePaymentIds" @change="selectAll " ></v-checkbox>
            </v-col>
            <v-col class="d-flex align-center py-0" cols="5" lg="5">
              <label class="payments-label color-pending text-left">
                {{$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentsPending')}}
              </label>
            </v-col>
            <v-col class="d-flex align-center hidden-md-and-down py-0" lg="3">
              <label class="payments-label color-pending text-center">
                {{$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentsExpiration')}}
              </label>
            </v-col>
            <v-col class="d-flex align-center text-right py-0" cols="5" lg="3">
              <label class="payments-label color-pending text-right" style="margin-right: 12px;">
                {{$t('modules.portalalumno.payments.paymentsStudents.view.labels.amount')}}
              </label>
            </v-col>
          </v-row>

          <v-row v-if="payments.length == 0">
            <v-col class="caption color-text py-0 text-center">
              <b>{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.no_pending_payments') }}</b>
            </v-col>
          </v-row>

          <div v-for="(payment, index) in payments" :key="payment.id">
            <PaymentComplex
              v-if="payment.billingProduct != 80 && $isMexico"
              :systemConfig="systemConfig"
              :canReprogram="canReprogram"
              :payment="payment"
              :prepaids="prepaids"
              :productWithPricePrepaids="productWithPricePrepaids"
              :seleccionDisabled="generatePaymentIds  || index !== 0 && showAllPayments || index === 0 && blockAdmin || index === 0 && blockAdminNext"
              @updatepaymentmethod="updatepaymentmethod($event, index)"
            ></PaymentComplex>
          </div>

				<v-row>
					<v-col class="subtitle-1 primary--text mt-2 mb-2">
						<b>{{$t('modules.portalalumno.payments.paymentsStudents.view.labels.nextMonthsPayments')}}</b>
					</v-col>
				</v-row>

				<v-row v-if="paymentsNext.length == 0">
					<v-col class="caption py-0 text-center">
						<b>{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.no_pending_payments') }}</b>
					</v-col>
				</v-row>

          <div v-for="(payment, index) in paymentsNext" :key="payment.id">
            <PaymentComplex
              v-if="payment.billingProduct != 80 && $isMexico"
              :payment="payment"
              :prepaids="prepaids"
              :productWithPricePrepaids="productWithPricePrepaids"
              :seleccionDisabled="generatePaymentIds || showAllPayments"
              @updatepaymentmethod="updatepaymentmethodNext($event, index)"
            ></PaymentComplex>
          </div>

				<Button
					v-if="$isIPP"
					class="mt-14"
					:text="$t('modules.portalalumno.payments.paymentsStudents.view.labels.seeMyPayments')"
					@clicked="goToHistory"
					outlined
				></Button>
			</v-container>
		</v-container>
	</div>
</template>

<script>
import PaymentComplex from '../Components/PaymentComplex/PaymentComplex';
import Button from '@/components/Button/Button';

  export default {
    props: {
      prepaids: Array,
      productWithPricePrepaids: Array,
      payments: Array,
      paymentsNext: Array,
      repayment: Object,
      systemConfig: Object,
      generatePaymentIds: { type: Boolean, default: false },

    },
    components: {
      PaymentComplex,
      Button,
    },
    data() {
      return {
        selectedall: false,
        blockAdminNext:false,
        blockAdmin:false,
        showAllPayments:false
      };
    },
    computed: {
      isAdminPayment() {
        return this.payments.find(item => item.billingProduct === this.$sisConstants('modules.products.gasto_administrativo'))
      },
      anySelected(){
        return this.payments.filter(item => item?.selected === true)
      },
      anyNextSelected(){
        return this.paymentsNext.filter(item => item?.selected === true)
      },
      canReprogram() {
        return this.repayment.available && this.payments.filter(payment => payment.repaymentAvailable === true).length >= this.repayment.installments
      },
      hasAdaptationInsurance() {
        return [...this.payments, ...this.paymentsNext].some(payment => payment.options.adaptationInsurance) && ![...this.payments, ...this.paymentsNext].every(payment => payment.options.adaptationInsurance)
      }
    },
    methods: {
      selectAll (event) {
        this.payments.forEach(payment => {
          if(payment.billingProduct !== this.$sisConstants('modules.products.gasto_administrativo') )
          payment.selected = event
        })
        this.paymentsNext.forEach(nextPayment => {
          
            nextPayment.selected = event
          
        })
        this.emitSelectedPayments()
      },
      emitSelectedPayments () {
        this.checkAllSelected()
         if(this.isAdminPayment && this.isAdminPayment?.selected === true) {
           this.showAllPayments = true
         } else {
           this.showAllPayments = false
         }
         
         if(this.anySelected.length >= 1 &&  !this.isAdminPayment?.selected === true){
         this.blockAdmin = true
         } else {
         this.blockAdmin = false
         } 
         if(this.anyNextSelected.length >= 1 &&  !this.isAdminPayment?.selected === true){
         this.blockAdminNext = true
         } else {
         this.blockAdminNext = false
         } 

        const selectedPayments = [...this.payments.filter(payment => payment.selected), ...this.paymentsNext.filter(payment => payment.selected)]
        const allPayments = [...this.payments, ...this.paymentsNext]
        this.$emit('billingchange', {payments: allPayments, itemsToUpdate: 'all'})
        this.$emit('billingchange', {payments: selectedPayments})
      },
      updatepaymentmethod(value, index) {
        if(!this.isAdminPayment){
        for ( const [i,p] of this.payments.entries() ) {
          if ( new Date().toISOString().split('T')[0] > p.options.dueDate && this.payments[index].options.dueDate > p.options.dueDate )
            this.payments[i].selected = true;
        }
      }
        if (this.payments[index].independent) {
          this.payments[index].selected = value
        } else {
          if (value === true) {
            for (let i = index; i >= 0; i--) {
              if (!this.payments[i].independent && this.payments[i].groupId == this.payments[index].groupId) this.payments[i].selected = true
            }
          } else {
            for (let i = index; i <= this.payments.length-1; i++) {
              if (!this.payments[i].independent && this.payments[i].groupId == this.payments[index].groupId) this.payments[i].selected = false
            }
            this.paymentsNext.forEach(payment => {if (!payment.independent) payment.selected = false})
          }
        }
        this.emitSelectedPayments()
      },
      updatepaymentmethodNext (value, index) {
        for ( const [i,p] of this.payments.entries() ) {
          if ( new Date().toISOString().split('T')[0] > p.options.dueDate && this.paymentsNext[index].options.dueDate > p.options.dueDate )
            this.payments[i].selected = true;
        }
        for ( const [i,p] of this.paymentsNext.entries() ) {
          if ( new Date().toISOString().split('T')[0] > p.options.dueDate && this.paymentsNext[index].options.dueDate > p.options.dueDate )
          this.paymentsNext[i].selected = true;
        }
        if (this.paymentsNext[index].independent) {
          this.paymentsNext[index].selected = value
        } else {
          if (value === true) {
            for (let i = index; i >= 0; i--) {
              if (!this.paymentsNext[i].independent && this.paymentsNext[i].groupId == this.paymentsNext[index].groupId) this.paymentsNext[i].selected = true
            }
            this.payments.forEach(payment => { if (!payment.independent && payment.groupId == this.paymentsNext[index].groupId) payment.selected = true })
          } else {
            for (let i = index; i <= this.paymentsNext.length-1; i++) {
              if (!this.paymentsNext[i].independent && this.paymentsNext[i].groupId == this.paymentsNext[index].groupId) this.paymentsNext[i].selected = false
            }
          }
        }
        this.emitSelectedPayments()
      },
      checkAllSelected() {
        let selectedall = true
        let extraOffCount = 0
        let pendingCount = 0
        
        this.payments.forEach(payment => {
          if (!payment.selected) selectedall = false
          if (payment.options.benefitExtra || payment.options.reenrollmentExtraOff) {
          if (((payment.options.reenrollmentExtraOff || payment.groupId == 4) && this.$isTeclab) || this.$isIPP) pendingCount++
          if (payment.selected) extraOffCount++   // Grupo 1 = arancel
          }
        })
        this.paymentsNext.forEach(payment => {
          if (!payment.selected) selectedall = false
          if (payment.options.benefitExtra || payment.options.reenrollmentExtraOff) {
            if (((payment.options.reenrollmentExtraOff || payment.groupId == 4) && this.$isTeclab) || this.$isIPP) pendingCount++
            if (payment.selected) extraOffCount++   // Grupo 1 = arancel
          }
        })        

        // Se define porcentaje extra de descuento solo para el pago de mas de 4 cuotas del arancel correspondiente
        this.payments.map(payment => {
          if (payment.options.reenrollmentExtraOff) {
            payment.options.addedExtraOff = (extraOffCount >= 2 /*&& extraOffCount == pendingCount*/) ? payment.options.reenrollmentExtraOff : 0
          } else {
            payment.options.extraOff = (extraOffCount >= 5 && extraOffCount == pendingCount ) ? payment.options.benefitExtra : 0
          }
          return payment
        })
        this.paymentsNext.map(payment => {
          if (payment.options.reenrollmentExtraOff) {
            payment.options.addedExtraOff = (extraOffCount >= 2 /*&& extraOffCount == pendingCount*/) ? payment.options.reenrollmentExtraOff : 0
          } else {
            payment.options.extraOff = (extraOffCount >= 5 && extraOffCount == pendingCount ) ? payment.options.benefitExtra : 0
          }
          return payment
        })

        this.selectedall = selectedall
      }
    },
    watch: {
      generatePaymentIds(val) {
        if (val) {
          this.$nextTick(() => {
            this.selectedall = true
            this.selectAll(true)
          })
        }
      }
    },
  };
</script>

<style scoped lang="sass">

  .color-pending
    color: var(--v-primary-lighten2)
  .color-text
    color: var(--v-primary-lighten3)
  .payments-label
    font-size: 11px
    opacity: 0.8
    width: 100%

</style>
