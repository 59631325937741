const ValidateEmailPhonePopUpTranslations = {
    es: {
        title: 'Verificación de datos requerida',
        subtittle: 'Por favor verifique sus datos para continuar',
        emailSendVerification:{
            title: 'Por favor verifique su mail para continuar',
            buttonText: 'Enviar mail de verificación',
            sendButtonSuccessText: 'Código enviado',
            validateButtonSuccessText: 'Código validado',
            buttonErrorText: 'Error'
        },
        emailCodeVerification:{
            textFieldLabel: 'Código de verificación',
            buttonText: 'Verificar Mail'
        },
        phoneSendVerification:{
            title: 'Por favor verifique su teléfono para continuar',
            buttonText: 'Enviar SMS de verificación'
        },
        phoneCodeVerification:{
            textFieldLabel: 'Código de verificación',
            buttonText: 'Verificar Teléfono'  
        },
        mailVerified: 'Mail verificado',
        phoneVerified: 'Teléfono verificado',
    },
    en: {

    }
};

export default ValidateEmailPhonePopUpTranslations