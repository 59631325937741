<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom" v-if="!confirmedStatus">
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">

                    <OutlinedCard title="Seleccionadas" class="mb-6">
                        <v-row no-gutters class="mt-2" v-for="subject in $v.subjects.$each.$iter" :key="subject.idSubject">
                            <v-col sm="7" offset-sm="1" class="d-flex flex-column align-start pb-0 pr-3" :class="subject.$model.electiveSelected ? 'pt-2' : 'pt-3'">
                                <span class="caption text-uppercase">{{ `${subject.$model.code} - ${subject.$model.descripcion}` }}</span>
                                <span class="caption primary--text font-weight-bold text-uppercase" v-if="subject.$model.electiveSelected">{{ `${subject.$model.electiveSelected.code} - ${subject.$model.electiveSelected.description}` }}</span>
                            </v-col>
                            <v-col sm="3" class="align-start pt-1 pb-3">
                                <v-text-field
                                    v-model="subject.score.$model"
                                    :error-messages="(subject.score.$dirty && subject.score.$anyError) ? $t('modules.examTitleNotes.update.validation_errors.calificacion') : ''"
                                    outlined
                                    :label="`${$t('modules.examTitleNotes.update.labels.calificacion')} *`"
                                    type="text"
                                    @blur="subject.score.$touch()"
                                    @keypress="$validateDecimalInputNumber($event, subject.score.$model, $countryConstants.maxAverage)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </OutlinedCard>
                    <div class="d-flex justify-end">
                        <Button
                            white
                            :text="$t('actions.cancel')"
                            @clicked="$emit('close-drawer')"
                            class="mr-4"
                        ></Button>
                        <Button
                            :text="$t('actions.save')"
                            :loading="homologarButton.loading"
                            :success="homologarButton.success"
                            :error="homologarButton.error"
                            :successText="$t('actions.success')"
                            :errorText="$t('actions.error')"
                            :disabled="!canSave"
                            @clicked="confirmPopup"
                            @end="resetButton"
                        ></Button>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <SuccessMessage
            v-else
            :type="successText.type"
            :title="successText.title"
            :actionPrimary="successText.actionPrimary"
        />
    </v-container>
</template>

<script>
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import { required, minValue, maxValue } from 'vuelidate/lib/validators'
    import Button from '@/components/Button/Button';
    import { $materias } from '../Services';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import { mapGetters } from 'vuex';

    export default {
        name: 'HomologarSubjects',
        props: {
            subjects: Array,
            studentCareer: String,
            isOpen: Boolean,
            periodSelected: Object,
        },
        components: {
            OutlinedCard,
            Button,
            SuccessMessage,
        },
        data() {
            return {
                confirmedStatus: false,
                homologarButton: {
                    loading: false,
                    success: false,
                    error: false,
                },
                successText: {
                    type: '',
                    title: '',
                    actionPrimary: '',
                },
            }
        },
        computed: {
            canSave() {
                return this.$v.subjects.$anyDirty && !this.$v.subjects.$anyError
            },
            ...mapGetters([
                'commons/getUserID'
            ]),
        },
        methods: {
            resetButton() {
                this.homologarButton.loading = false
                this.homologarButton.success = false
                this.homologarButton.error = false
            },
            async save() {
                this.homologarButton.loading = true
                this.subjects.map(subject => {
                    subject.score = parseFloat(subject.score)
                    return subject
                })
                const data = { studentCareer: this.$route.params.id, termId: this.periodSelected.id, subjects: this.subjects, user: this['commons/getUserID']}

                try{
                    await $materias.create(data, null, 'homologacion')
                    this.$emit('save')
                    this.successText.type = 'success';
                    this.successText.title = this.$t('modules.studentSubjects.enrollment.success_message.title')
                    this.successText.actionPrimary = {text: this.$t('actions.accept'), callback: () => {this.$emit('close-drawer')}}
                    this.confirmedStatus = false;
                    this.homologarButton.loading = false;
                    this.homologarButton.success = true;
                } catch {
                    this.successText.type = 'error';
                    this.successText.title = this.$t('modules.studentSubjects.enrollment.error_message');
                    this.successText.actionPrimary = {text: this.$t('modules.studentSubjects.enrollment.success_message.actions.back'), callback: () => { this.confirmedStatus = false }}
                    this.homologarButton.error = true;
                    this.homologarButton.loading = false;
                    this.confirmedStatus = true;
                }
            },
            confirmPopup() {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t(`actions.warning`),
                    content: [{value: '¿Estás seguro de realizar esta acción?'}],
                    actionPrimary: { text: this.$t('actions.accept'), callback: () => { this.save() } },
                    actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
                    icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                    color: 'primary',
                })
            },
            clearData(){
                this.$v.$reset();
            }
        },
        watch: {
            isOpen(value) {
                if(!value) this.clearData();
            }
        },
        validations() {
            return{
                subjects: {
                    $each: {
                        score: {
                            required,
                            minValue: minValue(this.$countryConstants.minAverage),
                            maxValue: maxValue(this.$countryConstants.maxAverage),
                        }
                    }
                }
            }
        }
    }
</script>
