import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('student');

export class Titulations extends Api {
  constructor(http, config = {}) {
    super(http, url, 'examenes/titulaciones', config);
  }

  filterExams(filter, config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(null, 'filtros', null);

    return this._http.post(uri, filter, config);
  }
}
