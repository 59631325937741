<template>
    <v-dialog v-model="modalOpen" width="550px" height="auto" @click:outside="closeDialog" > <!--content-class="modal-content"-->
        <v-card > <!--class="px-5 py-6"-->
            <v-card-title class="headline">{{ this.$t('modules.pricing.billing.cancelPopUpMessage.titlepopup') }}</v-card-title>
            <v-card-subtitle class="mt-0 text-capitalize">{{ `${selectedNoteCredit?.receiptType.toLowerCase()} número: ${selectedNoteCredit?.receiptNumber}` }}</v-card-subtitle>
            <v-card-title class="headline primary--text text-center d-block"> {{ this.$t('modules.pricing.billing.cancelPopUpMessage.title')}} </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="$v.reason.$model"
                            :items="reasonDetails"
                            :disabled="saveButton.loading"
                            :label="$t('modules.pricing.billing.cancelPopUpMessage.placeholder')"
                            item-text="description"
                            :error="$v.reason.$error || $v.reason.$invalid"
                            :rules="[v => !!v || $t('modules.pricing.billing.cancelPopUpMessage.required')]"
                            @blur="$v.reason.$touch()"
                            return-object
                            outlined 
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions >
                <v-spacer></v-spacer>
                <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="closeDialog"
                    class="mr-4"
                ></Button>
                <Button
                    :error="saveButton.error"
                    :text="$t('actions.accept')"
                    :loading="saveButton.loading"
                    :success="saveButton.success"
                    :successText="$t('actions.success')"
                    :errorText="$t('actions.error')"
                    :disabled="!$v.reason.$anyDirty || $v.reason.$invalid"
                    @clicked="cancelBill"
                ></Button> 
                <!-- :error="saveButton.error"
                    :disabled="!$v.payment.$anyDirty || $v.payment.$invalid"
                    :successText="$t('actions.success')"
                    :errorText="$t('actions.error')"
                    @clicked="confirmPopup"
                    @end="resetButton" -->
            </v-card-actions>
        </v-card>
        <!-- <v-card class="px-5 py-6">
            <v-card-text class="pa-0">
                <SuccessMessage
                    full-width
                    :type="successMessage.type"
                    :title="successMessage.title"
                    :actionPrimary="successMessage.actionPrimary"
                    :actionSecondary="successMessage.actionSecondary"
                />
            </v-card-text>
        </v-card> -->
    </v-dialog>
</template>

<script>
    import Button from '@/components/Button/Button'
    import { required } from 'vuelidate/lib/validators'
    import { $cancel } from '../../Services/index';
    import {mapGetters} from 'vuex'

    export default {
        components:{
            Button,
        },
        name:'ReasonCancelPopup',
        props:{
            selectedNoteCredit: Object,
            paymentStatus: Array,
            modalOpen: Boolean,
        },
        data() {
            return {
                reasonDetails:[
                    {
                        id: '02', description: 'Comprobante emitido con errores sin relación'
                    },
                    {
                        id: '03', description: 'No se llevó a cabo la operación'
                    },
                    {
                        id: '04', description: 'Operación nominativa relacionada en una factura global'
                    },
                ],
                saveButton: {
                    loading: false,
                    success: false,
                    error: false,
                },
                successMessage: {
                    type: null,
                    title: null,
                    actionPrimary: null,
                    actionSecondary: null,
                },
                reason: null,
                time: null
            }
        },
        computed: {
            ...mapGetters({
                userId: 'commons/getUserID'
            }),
        },
        methods:{
            resetButton() {
                this.saveButton.loading = false
                this.saveButton.success = false
                this.saveButton.error = false
            },
            closeDialog() {
                this.reason = null
                this.$emit('close')
            },
            async cancelBill() {
                try {
                    const params = {
                        receiptId: Number(this.selectedNoteCredit.receiptId),
                        cancelMotiveId: this.reason.id
                    }
                    await $cancel.create(null, {params})
                    
                    this.closeDialog()
                    setTimeout(() => {
                        this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.pricing.billing.cancelPopUpMessage.successMessage'),
                        actionPrimary: {
                            text: this.$t('actions.close'), callback: () => { this.$emit('fetchReceipts')}
                        },
                        icon: {color: 'success', name: 'mdi-check'},
                        color: 'primary',
                    });  
                    },300)
                } catch (error) {
                    this.closeDialog()
                    setTimeout(() => {
                        this.$store.dispatch('commons/openPopUp', {
                            title: this.$t('modules.pricing.billing.cancelPopUpMessage.errorMessage'),
                            actionPrimary: {
                                text: this.$t('actions.close'), callback: () => { this.$emit('fetchReceipts')}
                            },
                            icon: {color: 'error', name: 'mdi-close'},
                            color: 'primary',
                        });
                    },300)
                } finally {
                    this.saveButton.loading = false;
                }
            },
        },
        validations: {
            reason:{required}
        }
    }
</script>