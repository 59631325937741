import Subject0 from '../Subject0.vue'
import Subject0View from '../View/Subject0View.vue'

const Subject0Route = {
    path: '/subject0',
        meta: { 
            name: {
              en: 'Subject 0',
              es: 'Materia 0',
              }
            },
            component: Subject0,
            children:[
                {
                    path: '/',
                    component: Subject0View,
                    meta: { name: 'Index', group: 'portaladministrativo.academic', path: '', permission: 'READ' }
                }]
            }

export default Subject0Route