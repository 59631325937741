<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0" v-if="!syncTeacherStatus">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :description="$t('modules.canvas.syncTeacher.breadcrumbs.description')"
                    :title="$t('modules.canvas.syncTeacher.breadcrumbs.title')">
                </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            @goToSubjects="goToSubjects"
                            :synchronizedTeacher="synchronizedTeacher"
                            show-expand
                            :headers="headers"
                            :data="user" 
                            :userCanvas="userCanvas" 
                            :isPagination="false"
                            :isSearching="true"
                            :permissions="{ create: canCreate }"
                            @searchAction="(userIdentification)=>{search(userIdentification)}"
                            @syncUpTeacher="(data)=>syncUpTeacherPopUp(data)"
                        ></SuperTable>
                    </v-col>
                </v-row>
            </v-container>
        <TemporaryRightDrawer
            :open="tmpDrawer.open"
            @tmpDrawerOpen="tmpDrawerOpen"
            :title="tmpDrawer.title"
            :description="tmpDrawer.description"
        >
            <SyncSubjectsView
                @closeDrawer="onCloseDrawer"
                :subjects="subjects"
                :periods="periods"
                :user="user"
                :userCanvas="userCanvas" 
                :subjectsCanvas="subjectsCanvas"
                :sectionsCanvas="sectionsCanvas"
                @selectedPeriod="getSubjectCanvas "
                @syncUpSection="(sectionsCanvas)=>syncUpSectionPopUp(sectionsCanvas)"
                >
            </SyncSubjectsView>
        </TemporaryRightDrawer>
        </v-container>
        <SuccessMessage
        v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import SuperTable from '../Components/SuperTable';
import SyncSubjectsView from './SyncSubjectsView.vue'
import { $users, $terms } from '../Services';

export default {
    name: 'SyncTeacherView',
    components: {
    Breadcrumbs,
    SuperTable,
    SuccessMessage,
    TemporaryRightDrawer,
    SyncSubjectsView
    },
    data () {
        return {
            sectionIdSISNumbers: '',
            sectionIdCanvasNumbers: '',
            periods:[],
            syncTeacherStatus:false,
            user: [],
            subjectsCanvas: [],
            sectionsCanvas: [],
            subjects:[],
            userCanvas: [],
            headers: [
                {
                    text: this.$t('modules.canvas.syncTeacher.table.headers.nameAndLastname'),
                    value: 'fullName',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncTeacher.table.headers.identification'),
                    value: 'identification',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncTeacher.table.headers.synchronization'),
                    value: 'synchronizationTeacher',
                    align: 'center',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncTeacher.table.headers.edit'),
                    value: 'action',
                    align: 'center',
                    sortable: false
                } 
            ],
            successMessage:{},
            tmpDrawer: {
                open: false,
                title: this.$t('modules.canvas.syncTeacher.drawer.title'),
                description: this.$t('modules.canvas.syncTeacher.drawer.description')
            },
            synchronizedTeacher: false,
        }
    },
    async mounted(){
        await this.getPeriodsData()
    },
    methods: {
        onCloseDrawer() {
                this.tmpDrawer.open = false
                this.sectionsCanvas = [];
            },
        syncUpTeacherPopUp(data){
            this.$store.dispatch('commons/openPopUp', {
                title: this.$t('modules.canvas.syncTeacher.message.syncUpTeacher'),
                content: [
                    { value: data.fullName }
                ],
                actionPrimary: {
                    text: this.$t('modules.canvas.syncTeacher.message.syncAccept'),
                    callback: () => this.syncUpTeacher(data)
                },
                actionSecondary: {
                    text: this.$t('modules.canvas.syncTeacher.message.syncCancel'),
                    callback() {}
                },
                icon: { color: 'warning', name: 'mdi-sync' },
                color: 'primary'
            })
        },
        async syncUpTeacher(data){
            const bodySync = {
                teacherId: data.teacherId,
                lmsUserId: data.userCanvasId
            }
            try {
                await $users.update(`syncTeacher`,bodySync)
                this.search(data.identification)
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.success'),
                    content: [{ value: this.$t('modules.canvas.syncTeacher.message.syncUpTeacherSuccess') }],
                    actionPrimary: { text: this.$t('actions.close'), callback () {} },
                    icon: {color: 'success', name: 'mdi-check'},
                    color: 'primary',
                })
            } catch (error) {
                this.successMessage.type = 'error';
                this.successMessage.title = this.$t('modules.canvas.syncTeacher.message.syncUpTeacherError');
                this.successMessage.actionSecondary = null;
                this.successMessage.actionPrimary = { 
                    text: this.$t('modules.canvas.syncStudent.actions.close'), callback: () => { 
                        this.syncTeacherStatus = false
                    } 
                }
            } finally {
                this.syncTeacherStatus = false
            }
        },
        async getPeriodsData() {
            try {
                const params = {page: 0, length: 20, orderBy: 'classStartDate', orientation: 'desc'}
                const periodsData = await $terms.find(null,null,{params});
                const cursado = [{ divider: true } , { header: 'Cursado' }, ...periodsData.data.content?.filter(e => e.termType.meaning == 'Cursado')]
                const onBording = [{ divider: true } , { header: 'On Boarding' }, ...periodsData.data.content?.filter(e => e.termType.meaning == 'On Boarding')]
                const nivelacion = [{ divider: true } , { header: 'Nivelacion'},...periodsData.data.content?.filter(e => e.termType.meaning == 'Nivelacion')]
                const practica = [{ divider: true } , { header: 'Prácticas Profesionales'},...periodsData.data.content?.filter(e => e.termType.meaning == 'Practicas Profesionales')]
                this.periods = [ ...cursado, ...onBording, ...nivelacion, ...practica]
            } catch (error) {
                this.periods = []
                throw error
            }
        },
        goToSubjects(id){
            this.tmpDrawerOpen(id)
        },
        async search(userIdentification) {
            this.sectionsCanvas = [];
            this.subjectsCanvas = [];
            try {
                const {data} = await  $users.find(`teacher/${userIdentification}`, null, null)
                await this.getTeacherCanvas(data.teacherId) 
                this.user = [{...data,  synchronizationTeacher: false, userCanvasId: this.userCanvas[0].id}]
                
                if(this.user[0].lmsUserId != +this.userCanvas[0].id){
                    this.user[0].synchronizationTeacher = false;
                }
                else{
                    this.user[0].synchronizationTeacher = true;
                }
            } catch (error) {
                this.user = [];
            }
        },
        async getTeacherCanvas(teacherId) {
            try {
                const {data} = await  $users.find(`lmsUser/${teacherId}`, null, {params: {isStudent: false}})
                this.userCanvas = [{...data}]
            } catch (error) {
                this.user = [];
            }
        },
        async getSubjectCanvas(termId, sisTermId){
            try {
                const {data} = await  $users.find(`${this.userCanvas[0].id}/enrollments`, null, {params: {isStudent: false, lmsTermId: termId}})
                this.subjectsCanvas = [{...data}]
                this.getSectionCanvas(sisTermId, this.user[0].teacherId, data)
                
            } catch (error) {
                this.subjectsCanvas = [];
            }
        },
        async getSectionCanvas(sisTermId, teacherId, subjectsCanvas) {
        try {
            const { data } = await $users.find(`teacher/section?teacherId=${teacherId}&termId=${sisTermId}`, null, null);
            this.sectionsCanvas = data.map(sectionCanvas => {
                const [aux] = subjectsCanvas.filter(subjectCanvas => {
                    const sectionIdCanvas = subjectCanvas?.sectionId;
                    const sectionIdSIS = `${sectionCanvas?.sectionId}-${sisTermId}`;

                    const regex = /[0-9]/g;
                    this.sectionIdCanvasNumbers = sectionIdCanvas?.match(regex)?.join('') || '';
                    this.sectionIdSISNumbers = sectionIdSIS?.match(regex)?.join('') || '';
                    return  this.sectionIdCanvasNumbers ===  this.sectionIdSISNumbers;
                });
                return { ...sectionCanvas, synchronizationSection: !!sectionCanvas.lmsTeacherEnrollmentId, idCanvas: aux.lmsEnrollmentId };
            });
        } catch (error) {
            this.sectionsCanvas = [];
        }},
        tmpDrawerOpen() {
            this.tmpDrawer.open = !this.tmpDrawer.open
        },
    },
    computed: {
        canCreate() {
            return this.$permissions.portaladministrativo.canvas.sync_teacher.UPDATE
        },
    },
}
</script>