<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <!-- <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed"></AsideBar> -->
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.subjects.table.breadcrumbs.title')"
          :description="$t('modules.subjects.table.breadcrumbs.description')"
        >
          <Button v-if="canCreate" @clicked="newSubject" icon="mdi-plus" :text="$t('actions.new_female')" depressed></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :users="subjects"
              :clear-selected="clearSelected"
              :pageCount="asidebar.pagination.pagesAmount"
              :page="asidebar.pagination.currentPage"
              :totalItems="totalItems"
              :permissions="{update: canUpdate, delete: canDelete}"
              @inputPagination="onInputPagination"
              @lengthPaginationChange="onLengthPaginationChange"
              @bulk-delete="(subjects)=>{bulkDeletePopUp(subjects)}"
              @deleteUser="(id)=>deleteSubjectPopUp(id)"
              @updateUser="(id)=>{updateSubject(id)}"
              @searchAction="(data)=>{search(data)}"
              @searchAll="searchAllValues"
            />
            <!-- SuperTableBackendPagination
            :pageCount="asidebar.pagination.pagesAmount"
            :page="asidebar.pagination.currentPage"
            :totalItems="totalItems"-->
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { $subjects } from '../Services';
import { mapGetters } from 'vuex';

export default {
  name: 'SubjectsTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button
  },
  data () {
    return {
      totalItems: 20,
      clearSelected: '',
      subjects: [],
      asidebar: {
        title: 'Refinar búsqueda',
        pagination: {
          limit: 20,
          page: 1,
          currentPage: 1,
          pagesAmount: 1
        },
        items: [
          {
            name: 'Tipo',
            filters: [
              {
                name: 'Generales',
                type: 'check',
                value: true,
                dataFiltered: 123
              },
              {
                name: 'Disciplinarias',
                type: 'check',
                value: true,
                dataFiltered: 123
              },
              {
                name: 'Profesionales',
                type: 'check',
                value: true,
                dataFiltered: 123
              },
              {
                name: 'Complementarias',
                type: 'check',
                value: true,
                dataFiltered: 123
              },
              {
                name: 'Operativas',
                type: 'check',
                value: true,
                dataFiltered: 123
              },
              {
                name: 'Titulación',
                type: 'check',
                value: true,
                dataFiltered: 123
              }
            ]
          },
          {
            name: 'Estado',
            filters: [
              {
                name: 'Activo',
                type: 'check',
                value: true
              },
              {
                name: 'Inactivo',
                type: 'check',
                value: true
              }
            ]
          },
          {
            name: 'Créditos',
            filters: [
              {
                type: 'range',
                name: 'Filtered',
                label: 'Hasta',
                value: 20,
                min: 0,
                max: 100,
                dataFiltered: 123
              }
            ]
          },
          {
            name: 'Horas',
            filters: [
              {
                name: 'Activo',
                type: 'check',
                value: true
              },
              {
                name: 'Inactivo',
                type: 'check',
                value: true
              }
            ]
          },
          {
            name: 'Carreras',
            filters: [
              {
                name: 'Activo',
                type: 'check',
                value: true
              },
              {
                name: 'Inactivo',
                type: 'check',
                value: true
              }
            ]
          }
        ]
      },
      headers: [
        {
          text: this.$t('modules.subjects.table.headers.code'),
          value: 'code'
        },
        {
          text: this.$t('modules.subjects.table.headers.description'),
          value: 'description'
        },
        {
          text: this.$t('modules.subjects.table.headers.type'),
          value: 'type.meaning'
        },
        {
          text: this.$t('modules.subjects.table.headers.status'),
          value: 'status'
        },
        {
          text: this.$t('modules.subjects.table.headers.credits'),
          value: 'credits'
        },
        {
          text: this.$t('modules.subjects.table.headers.hours'),
          value: 'hours'
        },
        {
          text: this.$t('modules.subjects.table.headers.countCareers'),
          value: 'countCareers'
        },
        {
          text: this.$t('modules.subjects.table.headers.action'),
          value: 'action'
        }
      ],
      modalityTypes: []
    };
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.subjects.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.subjects.READ || this.$permissions.portaladministrativo.academic.subjects.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.academic.subjects.DELETE
    },
    ...mapGetters({
      userId: 'commons/getUserID'
    }),
  },
  async mounted () {
    this.fetchData();
  },
  methods: {
    async onInputPagination (event) {
      this.asidebar.pagination.page = event;
      if (!this.subjects[(event - 1) * this.asidebar.pagination.limit]) {
        const fetchedSubjects = await $subjects.find(null, null, {
          params: {
            page: this.asidebar.pagination.page - 1,
            length: this.asidebar.pagination.limit,
            orderBy: 'id',
            orientation: 'desc'
          }
        });
        fetchedSubjects.data.content.forEach((element, index) => {
          this.subjects[
            index + (event - 1) * this.asidebar.pagination.limit
          ] = this.formatSubjects(element);
        });
        this.subjects = Array.from(this.subjects);
      }
      this.asidebar.pagination.currentPage = event;
    },
    changed (parent, filter, value) {
      this.asidebar.pagination.page = 0;
      this.asidebar.items[parent].filters[filter].value = value;
    },
    onLengthPaginationChange (event) {
      this.asidebar.pagination = {
        page: 1,
        limit: event,
        currentPage: 1,
        pagesAmount: 1
      };
      this.fetchData();
    },
    deleteSubjectPopUp (id) {
      const subject = this.subjects.find(subject => subject.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.subjects.table.message.title'),
        content: [
          {
            value: subject.description
          }
        ],
        actionPrimary: {
          text: this.$t('modules.subjects.table.message.deleteAction'),
          callback: async () => {
            try {
              await this.deleteSubject(subject.id);
            } catch {
              this.$store.dispatch('commons/openPopUp', {
                title: this.$t(
                  'modules.subjects.table.error_message.delete_message'
                ),
                content: [
                  {
                    value: subject.name
                  }
                ],
                actionPrimary: { text: 'Aceptar', callback () { } },
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary'
              });
            }
          }
        },
        actionSecondary: {
          text: this.$t('modules.subjects.table.message.cancelAction'),
          callback () { }
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary'
      });
    },
    async fetchData() {
      const subjects = await $subjects.find(null, null, {
        params: {
          page: this.asidebar.pagination.page - 1,
          length: this.asidebar.pagination.limit,
          orderBy: 'id',
          orientation: 'desc'
        }
      });
      this.totalItems = subjects.data.totalElements;
      this.asidebar.pagination.pagesAmount = subjects.data.totalPages;
      this.subjects = subjects.data.content.map(subject => {
        return {
          id: subject.id,
          code: subject.code,
          description: subject.description,
          type: subject.type,
          status: subject.status,
          credits: subject.credits,
          hours: subject.hours,
          countCareers: subject.countCareers
        };
      });
    },
    formatSubjects (subjects) {
      if (subjects.length) {
        return subjects.map(subject => {
          return {
            id: subject.id,
            code: subject.code,
            description: subject.description,
            type: subject.type,
            status: subject.status,
            credits: subject.credits,
            hours: subject.hours,
            countCareers: subject.countCareers
          };
        });
      } else {
        return {
          id: subjects.id,
          code: subjects.code,
          description: subjects.description,
          type: subjects.type,
          status: subjects.status,
          credits: subjects.credits,
          hours: subjects.hours,
          countCareers: subjects.countCareers
        };
      }
    },
    bulkDeletePopUp (subjects) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.subjects.table.message.title'),
        content: subjects.map(subject => ({
          value: subject.description
        })),
        actionPrimary: {
          text: this.$t('modules.subjects.table.message.deleteAction'),
          callback: async () => {
            try {
              await this.bulkDelete(subjects);
            } catch {
              this.$store.dispatch('commons/openPopUp', {
                title: this.$t(
                  'modules.subjects.table.error_message.bulk_delete_message'
                ),
                content: subjects.map(subject => ({
                  value: subject.description
                })),
                actionPrimary: { text: 'Aceptar', callback () { } },
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary'
              });
            }
          }
        },
        actionSecondary: {
          text: this.$t('modules.subjects.table.message.cancelAction'),
          callback () { }
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary'
      });
    },
    async bulkDelete (subjects) {
      const itemsForDelete = subjects.map(subject => {
        return { id: subject.id };
      });
      try {
        await $subjects.bulkDelete(itemsForDelete, { params: { userId: this.userId  } });
      } finally {
        this.fetchData();
      }
    },
    newSubject () {
      if (this.canCreate) this.$router.push('/subjects/create');
    },
    updateSubject (id) {
      if (this.canUpdate) this.$router.push(`/subjects/update/${id}`);
    },
    searchAllValues () {
      this.asidebar.pagination.page = 1;
      this.asidebar.pagination.currentPage = 1;
      this.fetchData();
    },
    async search (data) {
      if (data) {
        data = data.toUpperCase()
        this.subject = await $subjects.find(null, null, {
          params: { name: data, code: data,  length: this.asidebar.pagination.limit, orderBy: 'id', orientation: 'desc' }
        });
        this.totalItems = this.subject.data.totalElements;
        if (this.totalItems <= this.asidebar.pagination.limit) {
          this.asidebar.pagination.pagesAmount = 1;
          this.asidebar.pagination.currentPage = 1;
        } else {
          this.asidebar.pagination.pagesAmount = Math.ceil(
            this.totalItems / this.asidebar.pagination.limit
          );
          this.asidebar.pagination.currentPage = 1;
        }
        this.subjects = this.subject.data.content.map(subject => {
          return {
            id: subject.id,
            code: subject.code,
            description: subject.description,
            type: subject.type,
            status: subject.status,
            credits: subject.credits,
            hours: subject.hours,
            countCareers: subject.countCareers
          };
        });
      } else if (data === null) {
        this.fetchData();
      }
    },
    async deleteSubject (id) {
      await $subjects.delete(id, { params: { userId: this.userId  } });
      this.subjects = this.subjects.filter(subject => subject.id !== id);
    }
  }
};
</script>

<style scoped>
</style>
