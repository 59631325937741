<template>
    <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
        <v-col sm="12">
            <v-data-table
                @click:row="goToSheet"
                :headers="headers"
                :items="items"
                :totalItems="totalItems"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                item-key="id"
                class="superTable__table"
                style="table-layout: fixed"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
            >
              <template v-slot:[`item.status`]="{item}"> 
                <v-chip  :class="setColor(item.status)" class="mx-2 white--text" >{{item.status}}</v-chip>
              </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
        
        
    </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span class="caption d-inline-block">{{ `${itemsCount.startItem} - ${itemsCount.endItem} de ${itemsCount.totalItems}` }}</span>
        <v-select
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          class="superTable__paginationSelect d-inline-block ml-4"
          outlined
          v-model="itemsPerPage"
          @change="$emit('length-pagination-change', itemsPerPage)"
        ></v-select>
      </v-col>

      <v-col class="text-center" sm="6">
        <v-pagination
          :value="page"
          :length="pageCount"
          @next="$emit('next-pagination', page)"
          @previous="$emit('previous-pagination', page)"
          @input="$emit('input-pagination', $event)"
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col align="right" sm="3">
        <v-text-field
          class="superTable__paginationInput d-inline-block mr-4"
          max="pageCount"
          outlined
          v-model.number="goToPage"
          @blur="changePage"
        ></v-text-field>
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SuperTable',
  props: {
    items: Array,
    headers: Array,
    sortBy: String,
    sortDesc: Boolean,
    pageCount: { type: Number, default: 1 },
    page: { type: Number, default: 1 },
    totalItems: Number,
  },
  data () {
    return {
      selectedItems: [],
      timeout: null,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      goToPage: 1,
    }
  },
  methods: {
    goToSheet(item){
      this.$router.push({path: `/academic/gradebook/${item.carreraId}/student-sheet/${item.libro}/invoice/${item.folio}`});
    },
    changePage() {
      if (this.goToPage > this.pageCount) this.goToPage = this.page;
      else this.$emit('input-pagination', this.goToPage)
    },
    setColor(status){
            let color = null
              switch(status) {
                case 'Cursando':
                    color = 'green'
                    break;
                case 'Baja':
                    color = 'red'
                    break
                case 'Egresado':
                    color = 'gray'
                    break
                case 'Anulado':
                    color = 'gray'
                    break       
            }
            return color
    },

  },
  watch: {
    page (val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val < 1) this.page = 1;
      if (this.page !== this.goToPage) this.goToPage = this.page;
    },
    clearSelected() {
      this.selectedItems = []
    },
  },
  computed: {
    itemsCount() {
      const counter = {
        totalItems: this.totalItems,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };
      if (counter.endItem > this.totalItems) counter.endItem = this.totalItems
      return counter
    },
        
  },
};
</script>
