import api from '@/api';

const { $student, $academic, $practica } = api;


export const { $students, $materias,  } = $student;
export const { $professionalPracticeTerms, $setting} = $academic;



export const { $enrollmentByException } = $practica;