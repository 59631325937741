<template>
  
  <div class="mt-0">
    <div class="mb-6">
      <span class="caption">{{ translatedDescription }}</span>
    </div>
    <v-text-field
      v-model="passInputs.newPass"
      :error-messages="newPassErrors"
      :hide-details="$v.passInputs.newPass.required"
      :label="$t('modules.login.recovered.password.form.labels.newPass')"
      outlined
      required
      minlength="8"
      maxlength="20"
      :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
      :type="hidePass ? 'text' : 'password'"
      @click:append="hidePass = !hidePass"
      @input="$v.passInputs.newPass.$touch"
      @blur="$v.passInputs.newPass.$touch"
      class="mb-4"
    ></v-text-field>
    <v-text-field
      v-model="passInputs.newPassRepeated"
      :error-messages="repeatedPassErrors"
      :label="$t('modules.login.recovered.password.form.labels.repeatPass')"
      outlined
      required
      minlength="8"
      maxlength="20"
      :append-icon="hidePassRepeated ? 'mdi-eye' : 'mdi-eye-off'"
      :type="hidePassRepeated ? 'text' : 'password'"
      @click:append="hidePassRepeated = !hidePassRepeated"
      @input="$v.passInputs.newPassRepeated.$touch"
      @blur="$v.passInputs.newPassRepeated.$touch"
      class="mb-4"
    ></v-text-field>
    <div class="d-flex flex-column align-items-start mb-4">
      <div class="d-flex align-center mb-1">
        <v-icon class="mr-2" :color="$v.passInputs.newPass.minLength && this.passInputs.newPass.length > 0 ? 'green' : 'red'">{{ $v.passInputs.newPass.minLength && this.passInputs.newPass.length > 0 ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        <span class="caption">{{ translatedPassLength }}</span>
      </div>
      <div class="d-flex align-center mb-1">
        <v-icon class="mr-2" :color="$v.passInputs.newPass.strongLevel ? 'green' : 'red'">{{ $v.passInputs.newPass.strongLevel ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        <span class="caption">{{ translatedSpecial }}</span>
      </div>
      <div class="d-flex align-center mb-1">
        <v-icon class="mr-2" :color="$v.passInputs.newPass.mediumLevel ? 'green' : 'red'">{{ $v.passInputs.newPass.mediumLevel ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        <span class="caption">{{translatedCapital}}</span>
      </div>
      <div class="d-flex align-center mb-1">
        <v-icon class="mr-2" :color="$v.passInputs.newPass.hasNumber ? 'green' : 'red'">{{ $v.passInputs.newPass.hasNumber ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        <span class="caption">{{translatedNumber}}</span>
      </div>
    </div>
  </div>
</template>


<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'

export default {
  name: 'RecoveryPassForm',
  props: {
    passInputs: Object,
  },
  data () {    return {
      hidePass: false,
      hidePassRepeated: false,
    }  },
  methods: {
    validatePassSecurity() {
      let val = 0;
      if (this.$v.passInputs.newPass.required) val++;
      if (this.$v.passInputs.newPass.minLength) val++;
      if (this.$v.passInputs.newPass.strongLevel) val++;
      if (this.$v.passInputs.newPass.mediumLevel) val++;
      if (this.$v.passInputs.newPass.mediumLevel) val++;
      this.progressBarValue = (val / 5) * 100;
    },
  },
  computed: {

    translatedNumber() {
      return this.$t('modules.login.recovered.password.form.validations.number');
    },
    translatedPassLength() {
      return this.$t('modules.login.recovered.password.form.validations.passLength');
    },
    translatedSpecial() {
      return this.$t('modules.login.recovered.password.form.validations.special_character');
    },
    translatedCapital() {
      return this.$t('modules.login.recovered.password.form.validations.capital_letter');
    },
    translatedDescription() {
      return this.$t('modules.login.recovered.password.form.validations.minRequired');
    },
    newPassErrors () {
      const errors = [];
      if(!this.$v.passInputs.newPass.$anyError) return errors;
      if (!this.$v.passInputs.newPass.required) errors.push(this.$t('modules.login.recovered.password.form.validations.passRequired'));
      if (!this.$v.passInputs.newPass.minLength) errors.push(this.$t('modules.login.recovered.password.form.validations.passLength'));
      if (!this.$v.passInputs.newPass.maxLength) errors.push(this.$t('modules.login.recovered.password.form.validations.passwordMax'));
      if (!this.$v.passInputs.newPass.strongLevel) errors.push(this.$t('modules.login.recovered.password.form.validations.minRequired')); 
      return errors;
    },
    repeatedPassErrors () {
      const errors = [];

      if(!this.$v.passInputs.newPassRepeated.$anyError) return errors;
      if (!this.$v.passInputs.newPassRepeated.required) errors.push(this.$t('modules.login.recovered.password.form.validations.passRequired'));
      if (!this.$v.passInputs.newPassRepeated.sameAsNewPass) errors.push(this.$t('modules.login.recovered.password.form.validations.passEqual'));
      return errors;
    }

  },
  watch: {
    'passInputs.newPass': function () {
      this.validatePassSecurity();
    },
  },
  validations: {
    passInputs: {
      newPass: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
        strongLevel (val) {
          /* eslint-disable */
            const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@\\#$\\/%^~,;¿¡:"&*{}()|\\[\\]\\-_+=.])[A-Za-z\\d@\\-¡¿",;.:_$#\\/^+!%*?{}()&~\\[\\]\\=]{8,20}$');
          /* eslint-enable */
          return strongRegex.test(val)
        },
        mediumLevel (val) {
          /* eslint-disable */
          const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
          /* eslint-enable */
          return mediumRegex.test(val);
        },
        hasNumber(val) {
        const numberRegex = /\d/;
        return numberRegex.test(val);
      },
      },
      newPassRepeated: {
        required,
        sameAsNewPass (value) { return value === this.passInputs.newPass }
      }
    }
  }
};
</script>
