<template>
	<v-dialog :value="open" @input="closeDialog" max-width="600px">
		<v-card style="padding:40px; height:400px">
			<h1 class="primary--text headline" style="font-size:22px;color:rgba(0, 0, 0, 0.6); margin-bottom:10px">Por favor ingrese un motivo: </h1>
			<v-select
				v-model="optionDeleteExam"
				:items="deleteExamData"
				item-text="meaning"
				@blur="$v.optionDeleteExam.$touch()"
				return-object
				@change="getOption($event)"
				label='Motivo'
				outlined
				:error-messages="$v.optionDeleteExam.$anyError && !$v.optionDeleteExam.required ? $t('modules.academic.periods.create.errors.required') : ''"
			>
			</v-select>
			<v-textarea
				outlined
				v-model="comments"
				name="input-7-4"
				label="Explicación"
				:error-messages="$v.comments.$anyError ? $t('modules.academic.periods.create.errors.required') : ''"
			></v-textarea>
			<v-row class="justify-center py-4">
				<Button
					class="mx-8"
					:text="$t('actions.close')"
					white
					@clicked="$emit('close')"
				></Button>
				<Button
					class="mx-8"
					:text="$t('actions.next')"
					style="background-color:#106ca7;border-radius:38px"
					@clicked="setWhyCloseExam"
				></Button>
			</v-row>
		</v-card>
	</v-dialog>
</template>

<script>
import { $types } from '../Services';
import Button from '@/components/Button/Button';
import { required } from 'vuelidate/lib/validators'
	export default {
		name: 'ExamDelete',
		props: {
			open: { type: Boolean, default: false },
		},
		components: {
			Button
		},
		data(){
			return {
				deleteExamData: [],
				optionDeleteExam: null,
				comments: null,
				otherOption: null
			}
		},
		mounted(){
			this.typesOfMotives()
		},
		methods: {
			closeDialog () {
				if (this.open) {
					this.$emit('close');
				}
			},
			async typesOfMotives(){
				const { data } = await  $types.find('', null, {params:{type:'CANCEL_EXAM_TYPE'}})
				this.deleteExamData = data;
			},
			getOption(option){
				if(option.value == 409 ){
					this.otherOption = true 
				} else {
					this.otherOption = false
					this.$v.$reset()
				}
			},
			setWhyCloseExam(){
				this.$trackEvent('Retirar examen', 'Clk_BtnEnvSolBaja_Exam', 'Retirar examen') //Google Analytics - Aceptar eliminar examen
				const body = {
					option: this.optionDeleteExam,
					comments: this.comments
				}
				if(this.$v.optionDeleteExam.$invalid){
					this.$v.optionDeleteExam.$touch()
				} else {
					if(!this.otherOption){
						this.$v.$touch()
						this.$emit('validateCloseExam', body)
					} else {
						this.$v.comments.$touch()
						if(!this.$v.comments.$anyError){
							this.$emit('validateCloseExam', body)
						}
					}
				}
			},
		},
		validations: {
			optionDeleteExam:{ required },
			comments: {required }
		}
	}
</script>

<style lang="sass" scoped>
	.dialog
		width: 400px
		height: 300px

	.headline
		font-size: 1.5rem !important
		font-weight: 400
		line-height: 2rem
		letter-spacing: normal !important
		font-family: "Roboto", sans-serif !important
</style>
