
const studentsTranslations = {
  es: {
    asideBar:{
      title: 'Refinar Busqueda',
      agent: 'Agente',
      career: 'Carrera',
      type: 'Tipo',
      state: 'Estado de Pago',
    },
    table: {
      breadcrumbs: {
        title: 'Estudiantes',
        description: 'Administración de pagos de Estudiantes Ni / Ri',
      },
      headers: {
        identification: 'DNI',
        name: 'Nombre Completo',
        phone: 'Teléfono',
        email: 'E-mail',
        career: 'Carrera',
        periods: 'Último periodo pago',
      }
    }
  },
  en: {
    tooltips: {
      select_image: 'Select an image',
      actions: {
        add: 'Assign',
        remove: 'Remove',
        title: 'Accions'
      }
    }
  }
};

export default studentsTranslations
