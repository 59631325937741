<template>
    <v-card>
      <v-card-title  v-if="!isSuccess" class="primary--text">Seguro quieres Cambiar el estado a {{   selectedStudent ? selectedStudent.nombreAlumno : '' }} ?</v-card-title>
      <v-card-actions  v-if="!isSuccess" class="justify-center mx-4 mb-4">
        <Button color="primary" :text="$t('actions.confirm')" @clicked="confirmAction"></Button>
        <Button color="secondary" class="mx-4" :text="$t('actions.close')" @clicked="$emit('close')"></Button>
      </v-card-actions>
      <v-alert v-if="isSuccess" type="success" class="my-4 mx-4">
      ¡Operación exitosa! El estado ha sido cambiado.
    </v-alert>
     <v-alert v-if="isError" type="error" class="my-4 mx-4">
      Error Al conectar al servidor. intente nuevamente.
  </v-alert>
    </v-card>
   
  </template>
  
  <script>
  import Button from '@/components/Button/Button'
  

  export default {
    name: 'ConfirmModal',
    props: {
      selectedStudent: Object,
      selectedNewStatus:Number,
      isSuccess: Boolean,
      isError: Boolean
    },
    components: {
      Button
    },
    methods: {
      confirmAction() {
        this.$emit('confirm', {
          item: this.selectedStudent,
          newStatus: this.selectedNewStatus
        });
      }
    }
  }
  </script>