<template>
    <div>
        <v-container fluid class="pa-0 pb-8">
            <template v-if="!insideDrawer">
                <v-container class="container-custom">
                    <Breadcrumbs 
                        :title="$t('modules.repayment.create.breadcrumbs.title')" 
                        :name="$t('modules.repayment.create.breadcrumbs.description')">
                    </Breadcrumbs>
                </v-container>
            </template>
            <v-container class="container-custom px-8">
                <template v-if="!createNewRepaymentStatus">
                    <v-row justify="center">
                        <v-col class="py-0 pl-7" sm="12">
                            <OutlinedCard
                                :title="$t('modules.repayment.create.title')"
                                :subtitle="$t('modules.repayment.create.subtitle')"
                                :switch-value="status"
                                @switchChange="(val) => {this.status = val;}"
                                :switch-label="(status) ? $t('enabled') : $t('disabled')"
                                switcher
                                class="mb-6"
                            >
                                <v-row>
                                    <v-col sm="9">
                                        <v-text-field
                                            counter="75"
                                            outlined
                                            :label="`${$t('modules.repayment.create.labels.description')} *`"
                                            @blur="$v.description.$touch()"
                                            v-model="$v.description.$model"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="9">
                                        <v-text-field
                                            counter="75"
                                            outlined
                                            :label="`${$t('modules.repayment.create.labels.installments')} *`"
                                            v-mask="'##'"
                                            v-model="$v.installments.$model"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col sm="9">
                                        <v-text-field
                                            counter="75"
                                            outlined
                                            :label="`${$t('modules.repayment.create.labels.basicPay')} *`"
                                            v-mask="'###'"
                                            @blur="$v.basicPay.$touch()"
                                            v-model="$v.basicPay.$model"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col sm="9">
                                        <v-text-field
                                            counter="75"
                                            outlined
                                            :label="`${$t('modules.repayment.create.labels.discountPercentage')}`"
                                            :error-messages="$v.discountPercentage.$error ? $t('modules.repayment.inputErrors.required') : ''"
                                            @blur="$v.discountPercentage.$touch()"
                                            v-model="$v.discountPercentage.$model"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </OutlinedCard>

                            <div class="d-flex justify-end">
                                <Button
                                    white
                                    :text="$t('actions.cancel')"
                                    @clicked="cancelCreation"
                                    @end="resetButtonValues()"
                                    class="mr-4"
                                >
                                </Button>

                                <Button
                                    :loading="createButton.loading"
                                    :success="createButton.success"
                                    :error="createButton.error"
                                    :text="$t('actions.create')"
                                    :successText="$t('actions.created')"
                                    :errorText="'Error'"
                                    :disabled="!canCreate"
                                    @end="resetButtonValues()"
                                    @clicked="createRepayment"
                                >
                                </Button>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <SuccessMessage v-else
                    :type="successMessage.type"
                    :title="successMessage.title"
                    :actionPrimary="successMessage.actionPrimary"
                    :actionSecondary="successMessage.actionSecondary"
                />
            </v-container>
        </v-container>
    </div>
</template>

<script>
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
    import Button from '@/components/Button/Button'
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import { $repayments } from '../Services';
    import { required } from 'vuelidate/lib/validators';

    export default {
        name: 'RepaymentCreate',
        props: {
            insideDrawer: {type: Boolean, default: false}
        },
        components: {
            OutlinedCard,
            Breadcrumbs,
            Button,
            SuccessMessage
        },
        data() {
            return {
                createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                createNewRepaymentStatus: false,
                successMessage: {},
                status: true,
                basicPay: null,
                description: null,
                discountPercentage: null,
                installments: null,

            }
        },
        validations: {
            description: {
                required
            },
            basicPay: {
                required
            },
            installments: {
                required
            },
            discountPercentage: {},
        },
        computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.prices.repayments.CREATE && this.$v.$anyDirty && !this.$v.$anyError;
            },
        },
        methods:{
            cancelCreation() {
                if(this.insideDrawer) {      
                    this.$emit('closeDrawer');
                    this.clearData();
                }else{
                    this.$router.replace('/repayments');
                }
            },

            async createRepayment() {
                if (!this.status) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.repayment.create.alert_message.title'),
                        content: '',
                        actionPrimary: {
                            text: this.$t('modules.repayment.create.alert_message.actions.primary_text'),
                            callback: () => {
                            this.triggerRepaymentCreation()
                            }
                        },
                        actionSecondary: {
                            text: this.$t('modules.repayment.create.alert_message.actions.secondary_text'),
                            callback: () => {
                                this.resetButtonValues()
                            }
                        },
                        icon: { 
                            color: 'warning', 
                            name: 'mdi-eye-off-outline' 
                        },
                        color: 'primary',
                    })
                } else {
                    this.triggerRepaymentCreation()
                }
            },
            async triggerRepaymentCreation () {

                try {
                    const repaymentToCreate = {
                        status: true,
                        basicPay: this.basicPay,
                        description: this.description,
                        discountPercentage: this.discountPercentage === null ? '0' : this.discountPercentage ,
                        installments: this.installments,
                    }

                    await $repayments.create(repaymentToCreate)
                    this.successMessage.type = 'success';
                    this.successMessage.title = `Nueva repactación ${this.description} creada`;
                    if (!this.insideDrawer) {
                        this.successMessage.actionPrimary = {
                            text: this.$t('modules.repayment.create.success_message.actions.primary_text'), 
                            callback: ()=>{this.$router.push('/repayments')}
                        };
                        this.successMessage.actionSecondary = {
                            text: this.$t('modules.repayment.create.success_message.actions.secondary_text'), 
                            callback: ()=>{this.createNewRepaymentStatus = false}
                        };
                    } else {
                        this.successMessage.actionPrimary = {
                            text: this.$t('modules.repayment.create.success_message.actions.primary_text'),
                            callback: ()=>{
                                this.createNewRepaymentStatus = false;
                                this.$emit('closeDrawer');
                            }
                        }
                        this.successMessage.actionSecondary = null;
                    }
                    this.createNewRepaymentStatus = true;
                    this.createButton.loading = false;
                    this.createButton.success = true;
                    this.clearData();
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = this.$t('modules.repayment.create.error_message.title');
                    this.successMessage.actionSecondary = null;
                    this.successMessage.actionPrimary = {
                        text: this.$t('modules.repayment.create.error_message.actions.go_back_text'), 
                        callback: ()=>{
                            this.createNewRepaymentStatus = false
                        }
                    }
                    this.createNewRepaymentStatus = true;
                    this.createButton.loading = false;
                    this.createButton.error = true;
                }
            },
            isDisabled (prop) {
                let status = false
                if (!prop.status){
                    prop.value = ''
                    status =  true
                } else{
                    status =  false
                }
                return status
            },
            resetButtonValues () {
                this.createButton.loading = false;
                this.createButton.success = false;
                this.createButton.error = false;
            },
            clearData() {
                this.resetButtonValues();
                this.$v.$reset();
                this.status = true;
                this.basicPay = null;
                this.description = null;
                this.discountPercentage = null;
                this.installments = null;
            },
            async fetchData() {
                const repaymentList = await $repayments.find(null, null, { params: { page: 0, length: 2000 } })
                this.repaymentsRelated = repaymentList.data.content;
            },
        },
        mounted(){
            this.fetchData();
        },
    }
</script>

<style scoped>
.linear-progess-code {
    top: -10px;
}
</style>