const changePasswordAlertTranslations = {
    es: {
        changePassword: {
            label: 'Cambiar contraseña',
            description: 'Por politicas de seguridad se requiere renovar la contraseña.<br><br>Para poder cambiar su contraseña actual debe contener entre 8 y 20 caracteres, al menos una mayúscula, minúscula, caracter especiales y un número.',
            description2: 'Para poder cambiar su contraseña actual debe contener entre 8 y 20 caracteres, al menos una mayúscula, minúscula, caracter especiales y un número.',
          },
        create:{
            passswordOld:'Ingrese contraseña actual *',
            passswordNew: 'Ingrese nueva contraseña *',
            passswordConfirmation: 'Confirme su nueva contraseña *',
            successMessage: 'Contraseña editada correctamente',
            errorMessage: 'Se produjo un error al editar la contraseña',
          },
        sectionData: {
            title: 'Datos personales',
            avatar: 'Avatar',
            avatarDescription: 'Puedes personalizar tu cuenta con una foto',
            name: 'Nombre',
            lastname: 'Apellido',
            birthDate: 'Fecha de nacimiento',
            gender: 'Sexo',
            email: 'Usuario',
            password: 'Contraseña',
            passwordDescription: 'Cambiar contraseña',
            identificationType: 'Tipo de identificación',
            identification: 'Número de identificación',
            about: 'Acerca de vos',
            socialMedia: 'Redes Sociales',
              validations: {
                minRequired: 'La contraseña debe contener al menos una mayúscula, minúscula, caracter especiales y un número.',
                nameRequired: 'Debes ingresar tu nombre',
                lastnameRequired: 'Ingresa tu/s apellido/s',
                emailRequired: 'Debes ingresar un email',
                emailEmail: 'Ingresa un email válido. Ej.: micorreo@ejemplo.com',
                identificationRequired: 'Ingresa tu número de identificación',
                passwordOldRequired: 'Debes ingresar tu contraseña actual',
                passwordNewRequired: 'Debes ingresar una nueva contraseña',
                passwordConfirmationRequired: 'Debes confirmar tu nueva contraseña',
                passwordMin: 'Mínimo 8 caracteres',
                passwordMax: 'Máximo 20 caracteres',
                passwordNewRepeat: 'Su contraseña nueva debe ser diferente a la antigua contraseña',
                sameAsNewPass: 'Debe ingresar la misma contraseña',
                passwordOldInconrrect: 'La contraseña actual ingresada es incorrecta'
              }
        },
    },
  
    en: {
     
    }
  };
  
  export default changePasswordAlertTranslations