
export default {
    provincias: [
        { 'descripcion': 'Buenos Aires' },
        { 'descripcion': 'Catamarca' },
        { 'descripcion': 'Chaco' },
        { 'descripcion': 'Chubut' },
        { 'descripcion': 'Ciudad Autónoma de Buenos Aires' },
        { 'descripcion': 'Córdoba' },
        { 'descripcion': 'Corrientes' },
        { 'descripcion': 'Entre Ríos' },
        { 'descripcion': 'Formosa' },
        { 'descripcion': 'Jujuy' },
        { 'descripcion': 'La Pampa' },
        { 'descripcion': 'La Rioja' },
        { 'descripcion': 'Mendoza' },
        { 'descripcion': 'Misiones' },
        { 'descripcion': 'Neuquén' },
        { 'descripcion': 'No informada' },
        { 'descripcion': 'Río Negro' },
        { 'descripcion': 'Salta' },
        { 'descripcion': 'San Juan' },
        { 'descripcion': 'San Luis' },
        { 'descripcion': 'Santa Cruz' },
        { 'descripcion': 'Santa Fe' },
        { 'descripcion': 'Santiago del Estero' },
        { 'descripcion': 'Tierra del Fuego' },
        { 'descripcion': 'Tucumán' },
    ],
    localidades: [
        { 'descripcion': '12 DE AGOSTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': '12 DE OCTUBRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': '16 DE JULIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': '17 DE AGOSTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': '20 DE JUNIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': '20 DE JUNIO ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': '25 DE MAYO', 'provincia': 'Buenos Aires' },
        { 'descripcion': '30 DE AGOSTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': '9 DE ABRIL', 'provincia': 'Buenos Aires' },
        { 'descripcion': '9 DE JULIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'A.A.FERNANDEZ ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'A.F.ORMA ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ABASTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ABBOT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ABBOTT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ABEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ABRA DE HINOJO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ABRA MAYO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ACASSUSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ACASSUSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ACEILAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ACEVEDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ACHUPALLAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ADELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ADELA CORTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ADELA SAENZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ADOLFO ALSINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ADOLFO GONZALES CHAVES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ADOLFO GONZÁLES CHAVES , Est. Chaves', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AERÓDROMO CAMPO DE MAYO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AERÓDROMO INTERNACIONAL DON TORCUATO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AERÓDROMO MATANZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AERÓDROMO MERLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AERÓDROMO MORÓN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AERÓDROMO SAN FERNANDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AERÓDROMO SAN JUSTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AEROPUERTO EZEIZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AGOTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AGUARA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AGUARA ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AGUAS CORRIENTES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AGUAS VERDES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AGUIRREZABALA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AGUSTIN FERRARI ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AGUSTIN MOSCONI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AGUSTIN ROCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AGUSTINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALAGON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALAMOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALASTUEY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALBARIÑO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALBERDI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALBERDI VIEJO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALBERTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALBERTI , Est. Vaccarezza', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALBERTI ,EST. ANDRES VACCAREZZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALDEA ROMANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALDEA SAN ANDRES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALDECON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALDO BONZI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALEGRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALEJANDRO KORN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALEJANDRO PETION', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALEJANDRO PETION ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALFA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALFALAD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALFEREZ SAN MARTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALFREDO DEMARCHI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALFREDO DEMARCHI , Est. Quiroga', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALGARROBO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALMACEN CASTRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALMACEN EL CRUCE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALMACEN EL DESCANSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALMACEN LA COLINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALMACEN PIATTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALMIRANTE BROWN , Est. AdroguÉ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALMIRANTE IRIZAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALMIRANTE SOLIER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALSINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALSINA , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALTA VISTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALTAMIRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALTAMIRANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALTIMPERGHER ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALTONA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALVAREZ DE TOLEDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALVAREZ JONTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALVARO BARROS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ALZAGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AMALIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AMBROSIO P LEZICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AMEGHINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AMERICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AMERICA UNIDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANASAGASTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANCON ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANDANT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANDAT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANDERSON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANDRES VACCOREZZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANEGADA , bahía', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANEQUE GRANDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANGEL ECHEVERRY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANTONIO CARBONI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ANTONIO DE LOS HEROS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'APARICIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARAUJO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARBOLEDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARBOLEDAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARBOLITO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARBUCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARENALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARENALES ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARENAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARENAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AREVALO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARGERICH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARIEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARISTOBULO DEL VALLE ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARQUEDAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARRECIFES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARRIBEÑOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO AGUAS BLANCAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO AGUILA NEGRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO ALELI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO BOTIJA FALSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO BURGOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO CANELON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO CARABELITAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO CHICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO CORTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO DE LA CRUZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO DE LOS HUESOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO DE LUNA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO DEL MEDIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO DEL PESCADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO DULCE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO EL AHOGADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO EL CHINGOLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO GRANDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO LA MAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO LAS CRUCES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO LAS ROSAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO LOS HUESOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO LOS TIGRES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO NACURUTU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO NACURUTU CHICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO NEGRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO PAREJA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO PESQUERIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO TAJIBER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO VENADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARROYO ZANJON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARTURO SEGUI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARTURO VATTEONE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ARTURO VATTEONE ,EST', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ASAMBLEA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ASCENCION', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ASCENSIÓN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ASTURIAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ASUNCIÓN , punta', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ATAHUALPA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ATALAYA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ATILIO PESSAGNO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ATUCHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AVELLANEDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AVESTRUZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AYACUCHO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AZCUENAGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AZOPARDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AZUCENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AZUL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'AZUL , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'B LOS AROMOS SAN PATRICIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'B NUESTRA SENORA DE LA PAZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'B SARMIENTO DON ROLANDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BACACAY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BACCAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BADANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BAGUES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BAHíA BLANCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BAHíA SAN BLAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BAIGORRITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BAJO HONDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALCARCE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO ATLANTIDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO CAMET NORTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO CHAPALCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO CLAROMECO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO FRENTE MAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO LA BALIZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO LA CALETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO LOS ANGELES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO MAR CHIQUITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO MAR DE COBO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO OCEANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO ORENSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO ORIENTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO PARADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO PEHUEN-CO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO PLAYA DORADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO RETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO SAN ANTONIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO SANTA ELENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALNEARIO SAUCE GRANDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BALSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BANCALARI ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BANDERALÓ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BANFIELD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARADERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARCA GRANDE , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARISSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARKER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIENTOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO 1 DE MAYO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO ALMAFUERTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO AVELLANEDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO BATAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO CAISAMAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO CAROSIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO CHAPADMALAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO COLONIA ALEGRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO EL CAZADOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO EL HUECO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO EL PORTENO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO EL RETAZO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO EMIR RAMON JUAREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO GARIN NORTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO GASTRONOMICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO GENERAL ROCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO GENERAL SAN MARTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO INDIO TROMPA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO JOSE M ESTRADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO JUAN B JUSTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO JUAREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO JULIO DE VEDIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO JURAMENTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO LA DOLLY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO LA FALDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO LA LUISA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO LA PERLA CASCO URBANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO LAS MANDARINAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO LOMA PARAGUAYA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO LOS ANDES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO NOROESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO OBRERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO OESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO PARQUE BRISTOL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO PARQUE LAMBARE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO PARQUE LELOIR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO PARQUE PATAGONIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO PEDRO RICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO PEDRO ROCCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO PINARES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO PRIMERA JUNTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO PUEBLO NUEVO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO ROSARIO SUD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SAN ALEJO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SAN BLAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SAN CARLOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SAN CAYETANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SAN JACINTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SAN JOSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SAN MARTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SAN PABLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SAN PATRICIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SAN ROQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO SANTA MAGDALENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO TIERRA DE ORO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO TIRO FEDERAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO TROCHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO U O C R A', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO VILLA MUNIZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO VILLA ORTEGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO VILLA SALADILLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARRIO VISTA ALEGRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARROW', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARTOLOME BAVIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BARTOLOME MITRE ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BASABILBASO , Est. Sta. Eleodora', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BASE AÉREA MILITAR EL PALOMAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BASE AERONAVAL CMTE ESPORA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BASE AERONAVAL PUNTA INDIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BASE NAVAL AZOPARDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BASE NAVAL RIO SANTIAGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BATÁN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BATERIAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BATHURST', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BATHURST ESTACION', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BAUDRIX', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BAYAUCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BECCAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BELEN DE ESCOBAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BELÉN DE ESCOBAR , Est. Escobar', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BELLA VISTA ,EST.TTE.GRL.RICCHIERI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BELLACA , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BELLOCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BELLOCQ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BENAVíDEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BENITEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BENITO JUAREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BENITO JUÁREZ , Est. JuÁrez', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BERAZATEGUI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BERDIER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BERISSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BERMUDEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BERNAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BERNAL ESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BERNAL OESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BERNARDO VERA Y PINTADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BERRAONDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BERUTTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BILLINGHURST', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BLANCA , bahía', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BLANCA GRANDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BLANCAGRANDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BLANDENGUES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BLAQUIER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BLAS DURAÑONA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BLONDEAU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BME BAVIO GRAL MANSILLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BO STA CATALINA HORNERO LA L', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BOCA DEL TORO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BOCAYUBA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BOCAYUVA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BOLIVAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BONIFACIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BONNEMENT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BONNEMENT ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BORDENAVE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BORDEU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BORGES ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BOSCH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BOSQUES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BOULOGNE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BRAGADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BRANDSEN ,EST.CNL.BRANDSEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BRAVO DEL DOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BUCHANAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'BURZACO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CABILDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CABO SAN FERMIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CACHARí', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CADRET', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAILOMUTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAIOMUTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CALDERON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CALERA AVELLANEDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CALFUCURA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CALVO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMAOTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMARON CHICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMBACERES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMET', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMINERA AZUL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMINERA GENERAL LOPEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMINERA JUAREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMINERA LUJAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMINERA NAPALEOFU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMINERA SAMBOROMBON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMINO CENTENARIO KM 11500', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPAMENTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO ARISTIMUNO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO BUENA VISTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO COLIQUEO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO CRISOL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO DE MAYO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO DEL NORTE AMERICANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO FUNKE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO LA ELISA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO LA LIMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO LA NENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO LA PLATA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO LA TRIBU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO LA ZULEMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO LEITE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO LOPE SECO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO LOS AROMOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO PELAEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO PENA LOPEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO ROJAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO SABATE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPO SAN JUAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPODONICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAMPOMAR VINEDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAN, ROBERTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANADA DE ARIAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANADA MARIANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAÑADA MARIANO ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANADA MARTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANADA RICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAÑADA SECA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANAL 15 CERRO DE LA GLORIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANAL GOBERNADOR ARIAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANAL GOBERNADOR DE LA SERNA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANAL LEANDRO N. ALEM 1ra. SECCIÓN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANAL LEANDRO N. ALEM 2da. SECCIÓN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANAL MARTIN IRIGOYEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANAL N', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANAL N ALEM 1A SEC', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANAL N ALEM 2A SEC', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANAL SAN FERNANDO ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANCHA DEL POLLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANGALLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANNING', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANONIGO GORRITI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANTERA AGUIRRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANTERA ALBION', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANTERA LA AURORA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANTERA LA FEDERACION', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANTERA LA MOVEDIZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANTERA MONTE CRISTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANTERA SAN LUIS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANTERA VILLALONGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CANTERAS DE GREGORINI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAÑUELAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAPDEPONT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAPILLA DEL SENOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAPILLA DEL SEÑOR , Est. Capilla', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAPITÁN , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAPITAN CASTRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAPITÁN SARMIENTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARABELAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARABELAS GRANDE , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARAPACHAY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARBONI, ANTONIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARDENAL CAGLIERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARHUÉ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARI LARQUEA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARILO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARLOS BEGUERIÉ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARLOS CASARES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARLOS KEEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARLOS LEMEE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARLOS MARIA NAON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARLOS SALAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARLOS SPEGAZZINI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARLOS TEJEDOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARMEN DE ARECO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CARMEN DE PATAGONES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASALINS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASANOVA, ISIDRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASARES, CARLOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASARES, VICENTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASAS, JOSE B.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASBAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASCADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASCALLARES, MICAELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASEROS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASEY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASTELAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASTELLI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASTILLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CASTILLO, RAFAEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CAZON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CENTRO AGRICOLA EL PATO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CENTRO AGRICOLA EL PATO ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CENTRO GUERRERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CERRI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CERRITO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CERRO AGUILA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CERRO DE LA GLORIA CANAL 15', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CERRO DE LOS LEONES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CERRO SOTUYO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHACABUCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHACRA EXPERIMENTAL INTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHALA QUILCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHANCAY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHAPADMALAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHAPALEOUFU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHAPALEUFU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHAPAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHAPARRO , cerro', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHAPI TALO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHASCOMÚS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHASCOMÚS , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHASICÓ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHASICÓ , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHASICÓ , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHELFORÓ , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHENAUT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHICLANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHILLAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHIVILCOY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHOIQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CHURRUCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CIRCUNVALACION ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CITY BELL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CIUDAD DE BUENOS AIRES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CIUDAD DE EDUARDO MADERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CIUDAD DE LIBERTADOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CIUDAD EVITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CIUDAD GRL.MARTIN MIGUEL DE GUEMES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CIUDAD JARDIN DEL PALOMAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CIUDADELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CIUDADELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CLARAZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CLAROMECÓ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CLAUDIO C MOLINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CLAVERIE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CLAYPOLE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CNA NACIONAL DE ALIENADOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CNEL RODOLFO BUNGE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COBO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COBO, MANUEL J.,EST.LEZAMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COCHRANE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COGHLAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLEGIO SAN PABLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLIQUEO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLMAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLMAN, MARTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLÓN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA ALBERDI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA BARGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA BARON HIRSCH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA BEETHOVEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA BELLA VISTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA CASAL , Est. CaÑada Seca', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA CUARENTA Y TRES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA DE VAC CHAPADMALAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA DR GDOR UDAONDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA EL BALDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA EL GUANACO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA EL PINCEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA FERRARI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA HINOJO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA HIPOLITO YRIGOYEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA HOGAR R GUTIERREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA HOGAR RICARDO GUTIERREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA INCHAUSTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA BEBA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA CATALINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA CELINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA ESPERANZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA ESTRELLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA GRACIELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA INVERNADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA MERCED', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA NENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA NORIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA REINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA VANGUARDIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LA VASCONGADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LABORDEROY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LAPIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LAS YESCAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LEVEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LOS ALAMOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LOS ALFALFARES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LOS BOSQUES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LOS HORNOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LOS HUESOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LOS TOLDOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA LOS TRES USARIS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA MAURICIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA MIGUEL ESTEVERENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA MONTE LA PLATA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA MURATURE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA NACIONAL DE MENORES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA NAVIERA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA NIEVES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA OCAMPO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA PALANTELEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA PHILLIPSON N 1', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA PUEBLO RUSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA RUSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA SAN EDUARDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA SAN ENRIQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA SAN FRANCISCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA SAN MIGUEL ARCANGEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA SAN RAMON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA SAN RICARDO , Est. Iriarte', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA SANTA MARIANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA SERÉ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA STEGMAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA TAPATTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA VELAZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA VELEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COLONIA ZAMBUNGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COMAHUE OESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COMANDANTE GIRIBONE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COMANDANTE NICANOR OTAMENDI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COMODORO PY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COMPLEJO FERROVIAL UNIÓN NACIONAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CONDARCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CONESA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COOPER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COPETONAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORACEROS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORACEROS ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORAZZI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORBETT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONADO, MARTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL BOERR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL BRANDSEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL CHARLONE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL DORREGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL FALCON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL GRANADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL ISLENOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL MALDONADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL MARCELINO FREYRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL MARCELINO FRYRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL MARTINEZ DE HOZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL MARTINIANO CHILAVERT ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL MOM', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL MON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL PRINGLES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL PRINGLES , Est. Pringles', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL RODOLFO BUNGE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL SEGUI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL SUÁREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORONEL VIDAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORREAS, IGNACIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CORTINES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COSTA AZUL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COSTA BONITA BALNEARIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COSTA BRAVA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COSTA, EDUARDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'COVELLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CRISTIANO MUERTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CRISTINO BENAVIDEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CROTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CROTTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CUARTEL 2', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CUARTEL 6', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CUARTEL 8', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CUARTEL CUATRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CUARTEL V', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CUATREROS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CUATRO DE NOVIEMBRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CUCHA CUCHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CUCULLU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CUENCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CURA MALAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CURAMALAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CURAPALIG', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'CURARÚ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'D ORBIGNY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DAIREAUX', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DARREGUEIRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DE BARY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DE BRUYN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'de CURA MALAL , sierra', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DE ELIA, AGUSTIN ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DE FERRARI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DE LA CANAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DE LA GARMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'de LA MATANZA , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DE LA PLAZA, VICTORINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'de LA RECONQUISTA , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DE LA RIESTRA, NORBERTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'de LA VENTANA , sierra', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'de LA VIDRIERA , salitral', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'de LOS HUESOS , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DE LUCA, ESTEBAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'de PILLAHUINCÓ , sierra', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DE VICTORIA, FRANCISCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DEFFERRARI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'del AZUL , sierras', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DEL CARRIL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'del MONTE , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'del PALO , isla', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'del PORTUGUÉS , isla', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'del TANDIL , sierra', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'del TIGRE , cordillera', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DEL VALLE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DEL VALLE,ARISTOBULO ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DEL VISO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DELFíN HUERGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DELGADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DELTA ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DENNEHY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DESCALZI, NICOLAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DESPENADEROS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DESTILERIA FISCAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DESVIO AGUIRRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DESVIO EL CHINGOLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DESVIO GARBARINI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DESVIO SAN ALEJO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DESVIO SANDRINI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DIEGO GAYNOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DIONISIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DIQUE LUJÁN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DIVISADERO BAYO , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOCE DE AGOSTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOCK CENTRAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOCK SUD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOCTOR DOMINGO CABRED', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOCTOR DOMINGO HAROSTEGUY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOCTOR RICARDO LEVENE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOCTOR RICARDO LEVENE ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOLORES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOMINGO FAUSTINO SARMIENTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOMSELAAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DON BOSCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DON CIPRIANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DON TORCUATO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DON VICENTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOS HERMANOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOS NACIONES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOYHENARD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DOYLE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DRABBLE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DRYSDALE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DUCOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DUDIGNAC', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DUFAUR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DUGGAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DUHAU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DULCE , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DURAÑONA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DUSSAUD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'DUSSAUD ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EDMUNDO PERKINS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EDUARDO COSTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EGAÑA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL 60', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL ALBA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL ARAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL ARBOLITO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL ARBOLITO PERGAMINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL BAGUAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL BOMBERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL BOQUERON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CARBON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CARMEN DE LANGUEYU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CARPINCHO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CARRETERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CENTINELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CHAJA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CHALAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CHEIQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CHUMBIAO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CORTAPIE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL CRISTIANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL DESCANSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL DESTINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL DIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL DIVISORIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL DORADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL DURAZNO , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL EUCALIPTUS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL FENIX', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL GALLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL GUALICHO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL HERVIDERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL JABALI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL JAGUEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL JUNCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL JUPITER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL LENGUARAZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL LIBERTADOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL LUCERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL LUCHADOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL MANGRULLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL MARQUESADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL MORO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL NACIONAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL NILO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL PALOMAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL PARAISO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL PARCHE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL PELADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL PENSAMIENTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL PERDIDO , Est. JosÉ A. Guisasola', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL PEREGRINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL PINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL PITO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL POTRILLO , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL POTRO , cerro', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL RECADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL REFUGIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL RETIRO PDO GRAL VIAMONTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL RINCON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL SANTIAGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL SIASGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL SILENCIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL SOCORRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL SOLDADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL TALAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL TATU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL TEJAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL TREBANON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL TRIANGULO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL TRIGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL TRIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL TRIUNFO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL VERANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL VIGILANTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL VOLANTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EL ZORRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ELIAS ROMERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ELIZALDE, RUFINO DE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ELORDI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ELVIRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMBALSE PASO DE LAS PIEDRAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMBALSE PIEDRA DEL ÁGUILA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMILIANO REYNOSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMILIO AYARZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMILIO BUNGE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMILIO LAMARCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMILIO V. BUNGE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMPALME', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMPALME CERRO CHATO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMPALME LOBOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMPALME MAGDALENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMPALME PIEDRA ECHADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EMPALME QUERANDIES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ENCINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ENERGíA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ENRIQUE FYNN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ENRIQUE LAVALLE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ENSENADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EPECUÉN , lago', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EPUMER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EPUMER ,EST.YUTUYACO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EREZCANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ERIZE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ERNESTINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESC NAV MILITAR RIO SANT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESCALADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESCALADA, REMEDIOS DE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESCOBAR , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESCOBAR, BELEN DE ,EST.ESCOBAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESCRIBANO P NICOLAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESCRIBANO, PEDRO N.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESPADANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESPARTILLAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESPIGAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESPINAZO DEL ZORRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESPORA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESQUINA DE CROTTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESQUINA NEGRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EST SAN FRANCISCO BELLOQ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTABLECIMIENTO SAN MIGUEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION ASCENSION', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION BARADERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION BARROW', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION CAIOMUTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION CORONEL PRINGLES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACIÓN DELTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION GENERAL ARENALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACIÓN GENERAL LEMOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION GOMEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACIÓN INGENIERO R. OTAMENDI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION LAGO EPECUEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION LAZZARINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION LINCOLN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION MITIKILI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION MORENO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACION PROVINCIAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACIÓN PUENTE ALSINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTACIÓN RíO LUJÁN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTANCIA CHAPAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTANCIA LAS GAMAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTANCIA LAS ISLETAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTANCIA SAN ANTONIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTANCIA SAN CLAUDIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTANCIA SAN RAFAEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTANCIA SANTA CATALINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTANCIA VIEJA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTANCIAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTEBAN A GASCON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTEBAN AGUSTíN GASCÓN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTEBAN DE LUCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTEBAN DIAZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'Esteban Echeverria', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTHER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTOMBA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTRELLA NACIENTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ESTRUGAMOU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ETCHEGOYEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ETCHEVERRY, ANGEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EXALTACION DE LA CRUZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EZEIZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EZEIZA, JOSE MARIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EZPELETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EZPELETA ESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'EZPELETA OESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FAIR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FARO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FARO QUERANDI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FARO SAN ANTONIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FARO SEGUNDA BARRANCOSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FATIMA ESTACION EMPALME', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FATRALO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FAUZON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FELIPE SOLÁ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FERNANDEZ, A.A.,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FERNANDEZ, JUAN N.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FERNANDO MARTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FERNANDO MARTí , Est. Cnl. Charlone', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FERRARI, AGUSTIN ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FERRARI, JOSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FERRÉ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FIORITO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FLAMENCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FLORENCIO VARELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FLORENTINO AMEGHINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FLORIDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FLORIDA OESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FONTEZUELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTABAT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTABAT, ALFREDO ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTIN ACHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTIN CHACO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTIN IRENE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTIN MERCEDES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTIN NECOCHEA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTIN OLAVARRIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTIN PAUNERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTIN TIBURCIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTIN VIEJO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FORTIN VIGILANCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANCISCO ÁLVAREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANCISCO AYERZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANCISCO BERRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANCISCO CASAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANCISCO DE VITORIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANCISCO J MEEKS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANCISCO MADERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANCISCO MADERO , Est. Salazar', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANCISCO MAGNANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANCISCO MURATURE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRANKLIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRENCH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRIGORIFICO ARMOUR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FRIGORIFICO LAS PALMAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FUERTE ARGENTINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FUERTE BARRAGAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FULTON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'FUNKE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GAHAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GALERA DE TORRES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GALO LLORENTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GALVAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GAMBIER ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GAMEN, PEDRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GANDARA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GARCIA DEL RIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GARCIA, MANUEL J.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GARDEY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GARíN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GARRÉ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GARRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GASCON, ESTEBAN AGUSTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL ALVEAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL ARENALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL ARENALES , Est. Arenales', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL BELGRANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL CONESA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL DANIEL CERRI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL DANIEL CERRI , Est. Grl. Cerri', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL GUIDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL HORNOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL JUAN MADARIAGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL JUAN MADARIAGA ,EST.G.MADARIAGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL LA MADRID', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL LAMADRID', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL LAS HERAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL LAS HERAS , Est. Las Heras', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL LAVALLE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL MADARIAGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL MANSILLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL MANSILLA , Est. BartolomÉ Bavio', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL O BRIEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL PACHECO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL PINTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL PIRÁN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL RIVAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL RODRíGUEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL ROJO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL RONDEAU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL SAN MARTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL VALDEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL VIAMONTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL VIAMONTE ,EST.LOS TOLDOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL VILLEGAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GENERAL VILLEGAS , Est. Villegas', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GERENTE CILLEY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GERLI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GERMANIA , Est. Mayor Orellano', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GIL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GIRODIAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GIRONDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GLEW', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GLORIALDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GNECCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBERNADOR ANDONAEGHI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBERNADOR ARIAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBERNADOR CASTRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBERNADOR EDUARDO ARANA ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBERNADOR LUIS GARCIA ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBERNADOR MONTEVERDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBERNADOR OBLIGADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBERNADOR ORTIZ DE ROSAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBERNADOR UDAONDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBERNADOR UGARTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOBOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOLDNEY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOMEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOMEZ DE LA VEGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOMEZ, VALENTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GONDRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GONI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GONNET, MANUEL B.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GONZÁLEZ CATÁN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GONZALEZ CHAVES, ADOLFO ,EST.G.CHAVES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GONZÁLEZ MORENO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GONZALEZ RISOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GORCHS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GORINA, JOAQUIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GORNATTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOROSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOROSTIAGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOUIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOWLAND', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOYENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOYENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GOYENECHE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GRACIARENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'Granadero Baigorria', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GRAND BOURG', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GRAND BOURG', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GRAND DOCK', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GRANDE , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GRAVIÑA, RICARDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GREGORIO DE LA FERRERE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GREGORIO VILLAFANE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GRISOLIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GRUNBEIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GRUNBEIN ,EST.EMP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUAMINí', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUANACO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUARDIA DEL MONTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUERNICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUERRERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUERRICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUIDO SPANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUILLERMO E HUDSON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUILLERMO E. HUDSON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUILLON, LUIS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUIRONDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUISASOLA,JOSE A.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUNTHER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'GUTIERREZ, JUAN M.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HAEDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HAEDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HALCEY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HALE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HAM', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS 1 DE MAYO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS CHACABUCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS CHAPADMALAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS EL CARMEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS EL CATORCE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS EL CENTINELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS EL CISNE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS EL MORO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS EL OMBU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS EL SALASO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS LA ELVIRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS LA LULA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS LOS CARDALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS NACIONAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS OJO DEL AGUA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS R DE LA PARVA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS SAN IGNACIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS SAN JACINTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HARAS TRUJUI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HEAVY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HENDERSON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HENRY BELL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HEREFORD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HERNANDEZ, JOSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HERRERA VEGAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HILARIO ASCASUBI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HINOJALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HINOJO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HIRSCH, MAURICIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HOGAR MARIANO ORTIZ BASUALDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HORNOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HORTENSIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HOSPITAL INTERZONAL DR DOMINGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HOSPITAL NECOCHEA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HOSPITAL SAN ANTONIO DE LA LLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HUANGUELÉN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HUDSON, GUILLERMO E.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HUERGO,DELFIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HUESO CLAVADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HUETEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HUNTER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HURLINGHAM', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'HUSARES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'IBAÑEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'IBARRA,JUAN F.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'IGARZABAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'IGNACIO CORREAS ARANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INDACOCHEA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INDART, INES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INDIA MUERTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INDIO RICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INDIO RICO , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INES INDART', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO ADOLFO SOURDEAUX', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO ALLAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO ANDRES VILLANUEVA ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO BALBIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO BEAUGEY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO BUNGE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO DE MADRID', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO JUAN ALLAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO MASCHWITZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO MONETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO PABLO NOGUES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO SANTIAGO BRIAN ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO SILVEYRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO THOMPSON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO WHITE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO WIHTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INGENIERO WILLIAMS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INOCENCIO SOSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'INVERNADAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'IRALA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'IRAOLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'IRENE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'IRENEO PORTELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'IRIARTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ISIDRO CASANOVA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ISLA CATARELLI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ISLA LOS LAURELES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ISLA MARTIN GARCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ISLA PAULINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ISLA SANTIAGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ISLAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ISONDU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ITURREGUI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ITUZAINGÓ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'J M MICHEO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JARRILLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JAUREGUI JOSE MARIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JEPPENER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOAQUíN GORINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOFRE, TOMAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSE A GUISASOLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSE B CASAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSÉ B. CASAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSÉ C. PAZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSE CLEMENTE PAZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSE FERRARI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSE INGENIEROS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSÉ LEÓN SUÁREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSE MARIA BLANCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSÉ MARíA EZEIZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSE MARIA GUTIERREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSÉ MÁRMOL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JOSE SOJO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN A PRADERE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN A. PRADERE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN ATUCHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN B JUSTO ING WHITE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN BAUTISTA ALBERDI , Est. Alberdi', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN BLAQUIER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN COUSTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN COUSTÉ , Est. Algarrobo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN E BARRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN E. BARRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN F IBARRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN F. IBARRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN G PUJOL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN JOSE ALMEYRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN JOSÉ PASO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN M. GUTIÉRREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN N FERNANDEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN N. FERNÁNDEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN TRONCONI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN V CILLEY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN VELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAN VUCETICH EX DR R LEVENE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUANA A DE LA PENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUANCHO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUAREZ, BENITO ,EST.JUAREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JULIO ARDITI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUNíN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUSTO VILLEGAS ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'JUSTO, JUAN B. ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KENNY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMERO 8 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 12 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 17 ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 19 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 27 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 34 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 36 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 40 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 45 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 48 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 53 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 54 ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 55 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 641 ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KILOMETRO 9 ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KORN, ALEJANDRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'KRABBÉ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ADELAIDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ALAMEDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ALCIRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA AMALIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA AMISTAD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA AMORILLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ANGELITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ARMONIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA AURORA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA AZOTEA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA AZOTEA GRANDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA AZUCENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA BALANDRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA BALLENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA BALLENERA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA BARRANCOSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA BEBA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA BLANCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA BLANQUEADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA BOLSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA BUANA MOZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CALETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CALIFORNIA ARGENTINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CAMPANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CARRETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CELIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CELINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CENTRAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CHINA , cerro', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CHOZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CHUMBEADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA COLINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA COLMENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA COLORADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA COLORADA CHICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CONSTANCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CONSTANCIA ,AP.KILOMETRO 360', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA COPETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA COPETA ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CORA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CORINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CORINCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA COTORRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA CUMBRE ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA DELFINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA DELIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA DESPIERTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA DORITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA DORMILONA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA DULCE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ELMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA EMILIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA EMILIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ESPERANZA GRAL MADARIAGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ESPERANZA PDO LAS FLORES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA EVA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA FELICIANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA FRATERNIDAD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA GARITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA GAVIOTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA GLEVA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA GRACIELITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA GREGORIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA HERMINIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA HORQUETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA HUAYQUERIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA INVENCIBLE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ISABEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA JUANITA , cerro', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LARGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LARGA NUEVA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LATA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LIMPIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LOMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LUCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LUCILA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LUCILA DEL MAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LUISA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LUNA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA LUZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA MADRECITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA MANTEQUERIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA MANUELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA MARGARITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA MARTINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA MASCOTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA MASCOTA ,EST.MASCOTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA MATILDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA NACION', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA NARCISA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA NAVARRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA NEGRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA NEVADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA NIÑA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA NORIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA NUMANCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA NUTRIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ORIENTAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PALA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PALMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PALMIRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PASTORA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PAZ CHICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PEREGRINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PERLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PESQUERIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PIEDRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PINTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'La Plata, Centro', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'La Plata, Norte', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA POCHOLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PORTEÑA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA POSADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PRADERA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PRIMAVERA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PRIMITIVA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PROTECCION', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PROTEGIDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA PROVIDENCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA RABIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA RAZON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA REFORMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA REJA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA RICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ROSADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ROSALIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA SALADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA SALADA GRANDE , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA SARA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA SARITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA SAUDADE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA SOBERANIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA SOFIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA SOMBRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA SORTIJA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA SUIZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA TALINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA TOBIANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA TOMASA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA TORRECITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA TRIBU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA VALEROSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA VASCONGADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA VICTORIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA VICTORIA DESVIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA VIOLETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA VIRGINIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA VITICOLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA YESCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ZANJA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LA ZARATENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LABARDEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAFERRERE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGO EPECUEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA ALSINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA ALSINA , Est. Bonifacio', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA CHASICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA DE GOMEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA DE LOBOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA DE LOS PADRES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA DEL CURA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA DEL SOLDADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA LAS MULITAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA MEDINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAGUNA REDONDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAMARCA, EMILIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LANGUEYU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LANGUEYÚ , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LANUS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LANUS ESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LANUS OESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAPLACETE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAPLACETTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAPRIDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LARGA , sierra', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LARRAMENDY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LARRE, SANTIAGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LARREA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LARTIGAU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS ACHIRAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS ARMAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS BAHAMAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS BARRANCAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS BARRANCAS , salina', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS BRUSCAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS CARABELAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS CHACRAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS CHILCAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS CORTADERAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS CUATRO HERMANAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS CUATRO PUERTAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS ESCOBAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS FLORES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS FLORES , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS GUASQUITAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS HERMANAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS JUANITAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS MALVINAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS MARIANAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS MARTINETAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS MULAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS NEGRAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS NIEVES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS NUTRIAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS OSCURAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS PALMAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS PARVAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS PIEDRTAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS SUIZAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS SULTANAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS TAHONAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS TONINAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS TOSCAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS TRES FLORES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAS VAQUERIAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LASALLE, PEDRO P.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LASTRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAVALLE, ENRIQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAVALLOL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAVAYÉN , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LAZZARINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LEANDRO N ALEM', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LEGARISTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LERTORA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LEUBUCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LEVALLE, NICOLAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LEZAMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LEZICA Y TORREZURI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LíBANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LIBERTAD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LIBRES DEL SUD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LICENCIADO MATIENZO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LIERRA ADJEMIRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LIMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LIN CALEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LINCOLN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LISANDRO OLMOS ETCHEVERRY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LLAVALLOL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LLORENTE, GALO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOBERíA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOBOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOMA DE SALOMON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOMA DEL INDIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOMA HERMOSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOMA NEGRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOMA PARAGUAYA ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOMA PARTIDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOMA VERDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOMAS DE ZAMORA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOMAS DEL MIRADOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LONGCHAMPS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LÓPEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOPEZ CAMELO ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOPEZ LECUBE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOPEZ MOLINARI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOPEZ, VICENTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS ACANTILADOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS ANGELES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS ARCOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS AROMOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS BOSQUES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS CALDENES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS CARDALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS CERROS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS COLONIALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS CUATRO CAMINOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS EUCALIPTOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS EUCALIPTUS CASCO URBANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS GAUCHOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS GAUCHOS ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS HUESOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS INDIOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS LAURELES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS LEONES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS MERINOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS ORTIZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS PATOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS PINOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS POLVORINES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS POZOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS SANTOS VIEJOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS TALAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOS TOLDOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOUGE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOURDES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LOZANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LUCAS MONTEVERDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LUIS CHICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LUIS GUILLÓN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LUJÁN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LUJÁN , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LUMB', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LURO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'LURO, PEDRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MACEDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MACHITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MADERO, FRANCISCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAGALLANES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAGDALA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAGDALENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAGNANO, FRANCISCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAGUIRRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAIPU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAIPÚ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MALABIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MALAVER ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MALECON GARDELIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MALVINAS ARGENTINAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAMAGUITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MANANTIALES GRANDES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MANUEL ALBERTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MANUEL B GONNET', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MANUEL B. GONNET , Est. French', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MANUEL J. COBO , Est. Lezama', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MANUEL JOSE GARCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MANUEL OCAMPO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MANZANARES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MANZO Y NINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MANZONE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAORI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAPIS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAQUINISTA F SAVIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAQUINISTA SAVIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAR AZUL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAR CHIQUITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAR CHIQUITA , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAR DE AJÓ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAR DE COBO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAR DE LAS PAMPAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAR DEL PLATA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAR DEL SUD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAR DEL SUR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAR DEL TUYÚ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARCELINO UGARTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARCOS PAZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARCOS PAZ B BERNASCONI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARCOS PAZ B EL MARTILLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARCOS PAZ B EL MORO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARCOS PAZ B EL ZORZAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARCOS PAZ B LA LONJA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARCOS PAZ B LA MILAGROSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARCOS PAZ B MARTIN FIERRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARCOS PAZ B URIOSTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARI LAUQUEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARIA IGNACIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARíA IGNACIA , Est. Vela', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARIA IGNACIA ,EST.VEIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARIA LUCILA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARIA P MORENO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARIANO ACOSTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARIANO BENITEZ PERGAMINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARIANO H ALFONZO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARIANO MORENO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARIANO ROLDAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARIANO UNZUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARISCAL SUCRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARMOL, JOSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARTIN BERRAONDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARTIN CORONADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARTIN FIERRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARTíN GARCíA , isla', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARTíNEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MARUCHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MASSEY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MASUREL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MASUREL ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MATHEU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAURAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAURICIO HIRSCH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAXIMO FERNANDEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MÁXIMO PAZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAYOR BURATOVICH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAYOR JOSE ORELLANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MECHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MECHITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MECHONGUÉ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MEDALAND', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MEDANO BLANCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MÉDANOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MELCHOR ROMERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MEMBRILLAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MERCADO CENTRAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MERCADO DE VICTORIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MERCEDES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MERIDIANO VO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MERLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MERLO GOMEZ ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MICAELA CASCALLARES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MICHEO, JOSE M.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MIGUELETE ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MINISTRO RIVADAVIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MIRA PAMPA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MIRAMAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MIRAMONTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MIRANDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MITRE, BARTOLOME ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MOCTEZUMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MOLINA, CLAUDIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MOLINO GALILEO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MOLL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONASTERIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONES CAZÓN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONROE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONSALVO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONTE CHINGOLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONTE CRESPO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONTE FIORE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONTE GRANDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONTE HERMOSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONTE VELOZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MONTES DE OCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MOORES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MOQUEHUA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MORALES , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MOREA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'Moreno', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MORENO, MARIANO ,EST.MORENO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MORÓN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MORRIS, WILIAM C.,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MORSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MOSCONI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MOURAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MOURAS ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MULCAHY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MUÑIZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MUÑOZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MUNRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MURATURE, FRANCISCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'MUTTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NAHUEL RUCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NAON, CARLOS M.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NAPALEOFU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NAPALEUFÚ o CHICO , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NAPOSTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NAVARRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NECOCHEA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NECOL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NECOL ESTACION FCGM', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NEGRA , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NEGRA , punta', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NEILD, RAMON J.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NEWTON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NICANOR OLIVERA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NICANOR OLIVERA , Est. La Dulce', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NICOLAS DESCALZI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NICOLAS LEVALLE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NIEVES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NORBERTO DE LA RIESTRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NORUMBEGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NUEVA ATLANTIS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NUEVA HERMOSURA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NUEVA PLATA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NUEVA ROMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NUEVA SUIZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'NUEVA SUIZA ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'O HIGGINS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OBLIGADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OBLIGADO, RAFAEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OCAMPO, MANUEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OCHANDIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ODORQUI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'O"HIGGINS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OLASCOAGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OLAVARRíA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OLIDEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OLIVERA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OLIVERA, NICANOR ,EST.LA DULCE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OLIVIERA CESAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OLIVOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OLMOS, LISANDRO ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OMBU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OMBUCTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ONCE DE SETIEMBRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OPEN DOOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ORDOQUI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ORENSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ORIENTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ORLANDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ORMA, ADOLFO F. ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ORTIZ BASUALDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ORTIZ DE ROSAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OSTENDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OTAMENDI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OTONO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OTOÑO ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'Otra', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'Otra', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'Otra', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'OYARBIDE , isla', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PABLO ACOSTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PABLO NOGUES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PABLO PODESTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PACHAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PADILLA, MIGUEL MANUEL ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PALANTELEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PALEMON HUERGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PANAME', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PANCHO DIAZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PAPIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARADA LOS ROBLES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARADA TATAY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARAGUIL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARAJE LA AURORA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARAJE LA VASCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARAJE SANTA ROSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARAJE STARACHE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARANÁ DE LAS PALMAS , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARANÁ GUAZÚ , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARANÁ MINí , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARISH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARQUE CARILO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARQUE MUNOZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARQUE SAN MARTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARQUE TAILLADE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARRAVICHINI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PARRAVICINI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PASCO ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PASMAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PASO ALSINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PASO CRAMER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PASO DEL MEDANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'Paso del Rey', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PASO DEL REY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PASO MAYOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PASO, JUAN JOSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PASOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PASTEUR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PATAGONES, CARMEN DE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PATRICIOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PATRIMONIO MUNDIAL DE LA HUMANIDAD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PAULA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PAYRO R', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PAYRO, ROBERTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PAZ, JOSE C.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PAZ, MARCOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PAZ, MAXIMO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PAZOS KANKI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEARSON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEDERNALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEDRO GAMEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEDRO LASALLE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEDRO LURO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEDRO M MORENO , Est. Ingeniero Thompson', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEDRO NICOLAS ESCRIBANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEHUAJÓ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEHUELCHES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEHUEN CÓ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEHUEN-CO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PELICURA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PELLEGRINI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PENAFLOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PERALTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEREDA, VICENTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEREYRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEREYRA , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEREYRA IRAOLA PARQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PÉREZ MILLÁN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PEREZ, ROQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PERGAMINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PESSAGNO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PESSAGNO, ATILIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PETION, ALEJANDRO ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PICHINCHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIEDRA ANCHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIEDRA ECHADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIEDRITAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIERES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIERINI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIG', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIGUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIGUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PILA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PILAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PILLAHUINCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PINAMAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PINAZO , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PINEIRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIÑERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIÑEYRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PINZON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIPINAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIROVANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PIRUCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PLÁ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PLA Y RAGNONI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PLATANOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PLAYA CHAPADMALAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PLAYA LAS MARGARITAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PLAYA SERENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PLAZA MONTERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PLOMER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PLUMACHO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'POBLET', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'POLVAREDAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PONTAUT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PONTEVEDRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PORTELA, IRINEO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PORVENIR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'POURTALE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PRADERE JUAN A', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PRADERE, JUAN A.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PRESA DE EMBALSE INGENIERO F. C. ROGGERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PRESIDENCIA DE LA PLAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PRESIDENTE DERQUI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PRESIDENTE QUINTANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PRIMERA JUNTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUÁN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUEBLITOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUEBLO BALNEARIO RETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUEBLO MARTINEZ DE HOZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUEBLO NUEVO DTO JUNIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUEBLO OTERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUEBLO SAN ESTEBAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUEBLO SAN JOSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUEBLO SANTA MARIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUENTE BATALLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUENTE CANETE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUENTE CASCALLARES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUENTE CASTEX', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUENTE EL OCHENTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO BAHIA BLANCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO BELGRANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO COLOMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO CUATREROS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO DE ESCOBAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO GALVAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO LA PLATA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO NECOCHEA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO ROSALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO TRES BONETES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUERTO WASSERMANN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUESTO DEL MEDIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUJOL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUNTA ALTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUNTA ALTA ,EST.ALMIRANTE SOLIER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUNTA CHICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUNTA DE CANAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUNTA INDIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUNTA LARA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUNTA LARA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'PUNTA MEDANOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUENUMÁ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUEQUÉN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUEQUÉN CHICO , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUEQUÉN GRANDE , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUEQUÉN SALADO , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUERANDI ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUERANDIES ,EMP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUILCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUILMES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUILMES OESTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUIÑIHUAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUINIHUAL ESTACION', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUIRNO COSTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'QUIROGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAFAEL CALZADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAFAEL CASTILLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAFAEL OBLIGADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAMALLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAMON BIAUS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAMON J NEILD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAMÓN SANTAMARINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAMOS MEJíA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAMOS OTERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RANCAGUA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RANCHOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RANELAGH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RASA , punta', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAUCH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAULET', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RAWSON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'REAL AUDIENCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RECALDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RECTIFICACIÓN DEL RIACHUELO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'REDONDA , punta', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'REGINALDO J NEILD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'REMEDIOS DE ESCALADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'REQUENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RESERVA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RESERVA NATURAL ESTRICTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RESERVA NATURAL ESTRICTA OTAMENDI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RETIRO SAN PABLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RIACHUELO , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RICARDO CANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RICARDO GAVINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RICARDO ROJAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RINCON DE BAUDRIX', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RINCON DE MILBERG', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RINCON DE NOARIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RINCON DE VIVOT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RINCON NORTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RINGUELET', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RIO LUJAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RIO LUJAN ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RIO SALADO ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RIO TALA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RIVADAVIA , Est. AmÉrica', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RIVADEO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RIVAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RIVERA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROBERTO PAYRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROBERTS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROCA, AGUSTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROCHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROJAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROLDAN, MARIANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROLITO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROLITO ESTACION FCGB', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROMAN BAEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROMERO, ELIAS ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROMERO, MELCHOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROOSEVELT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROOSEVELT PARTIDO RIVADAVIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROQUE PÉREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROSAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ROVIRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RUBEN DARIO ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RUIZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RUIZ SOLIS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'RUTA 26 MAQUINISTA F SAVIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAAVEDRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAENZ PEÑA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAFORCADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALADA CHICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALADA GRANDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALADILLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALADILLO NORTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALADO , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALAS, CARLOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALAZAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALDUNGARAY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALINA DE PIEDRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALINAS CHICAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALINERA ,DV.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALLIQUELÓ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SALVADOR MARIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAMBOROMBON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAMBOROMBÓN , bahía', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAMBOROMBÓN , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ADOLFO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN AGUSTíN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ALBERTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ANDRES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ANDRÉS DE GILES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ANDRES DE GILES ,EST.GILES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ANDRES DE TAPALQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ANTONIO , cabo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ANTONIO DE ARECO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ANTONIO DE PADUA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN BERNARDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN BERNARDO , Est. Guanaco', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN BERNARDO DEL TUYU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN CALA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN CARLOS DE BOLíVAR , Est. Bolívar', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN CAYETANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN CLEMENTE DEL TUYÚ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN CORNELIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN DANIEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN EDUARDO DEL MAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ELADIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN EMILIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ENRIQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN FEDERICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN FERMIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN FERNANDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN FRANCISCO DE BELLOCQ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN FRANCISCO SOLANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN FRANCISCO SOLANO ,PDA.KILOMETRO 26', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN GERMÁN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN GERVACIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN IGNACIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ISIDRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN JACINTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN JORGE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN JOSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN JOSE DE GALI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN JOSE DE LOS QUINTEROS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN JOSE DE OTAMENDI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN JUAN DE NELSON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN JULIAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN JUSTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN LAUREANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MANUEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MARTÍN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MARTIN DE TOURS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MAURICIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MAYOL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MIGUEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MIGUEL ,EST.GENERAL SARMIENTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MIGUEL ARCANGEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MIGUEL DEL MONTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MIGUEL DEL MONTE ,EST.MONTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN MIGUEL DEL MORO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN NICOLÁS DE LOS ARROYOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN NICOLAS DE LOS ARROYOS,EST.S.NICOLAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN PASCUAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN PATRICIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN PEDRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN QUILCO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN RAMON DE ANCHORENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN ROMAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN SIMON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN VALENTIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN VICENTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAN VICENTE , laguna', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANDRINI ,DV.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANSINENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA ALICIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA CATALINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA CECILIA CENTRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA CECILIA NORTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA CECILIA SUD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA CLARA DEL MAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA CLEMENTINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA COLOMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA ELENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA ELEODORA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA FELICIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA INES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA IRENE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA LUCíA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA LUISA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA MARíA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA MARIA BELLOQ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA REGINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA RITA PDO GUAMINI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA ROSA DE MINELLONO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA TERESITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA TERESITA PERGAMINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTA TRINIDAD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTAMARINA, RAMON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTIAGO GARBARINI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTIAGO LARRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTO DOMINGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTO TOMAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTO TOMAS CHICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTOS AREVALO, JOSE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTOS LUGARES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SANTOS UNZUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SARANDí', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SARASA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SATURNO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAUCE CHICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAUCE CHICO , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAUCE CORTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAUCE GRANDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAUCE GRANDE , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SAUZALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SEGUI, ARTURO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SEGUROLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SEMINARIO PIO XII', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SEVIGNE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SHAW', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SIEMPRE VERDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SIERRA CHICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SIERRA DE LA VENTANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SIERRA DE LOS PADRES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SIERRAS BAYAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SMITH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SOLA, FELIPE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SOLALE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SOLANET', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SOLANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SOLIS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SOLíS , islas', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SOSA, INOCENCIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SOURDEAUX, ADOLFO ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SOURIGUES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SOURIGUES, CARLOS ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SPANO, GUIDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SPEGAZZINI, CARLOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SPERATTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SPERONI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SPURR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SPURR ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'STEGMANN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'STROEDER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SUAREZ, JOSE LEON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SUAREZ, TRISTAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SUCRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SUIPACHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'SUNDBLAD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TABLADA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TACUARI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TAMANGUEYÚ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TAMBO NUEVO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TANDIL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TAPALQUÉ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TAPALQUÉ , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TAPIALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TATAY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TEDíN URIBURU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TEJEDOR, CARLOS ,EST.TEJEDOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TEJO GALETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TEMPERLEY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TENIENTE CORONEL MIÑANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TENIENTE ORIGONE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TERMAS LOS GAUCHOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'THAMES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TIGRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TIMOTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TIMOTE ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TIO DOMINGO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TODD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TOLDOS VIEJOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TOLOSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TOMAS JOFRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TORDILLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TORNQUIST', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TORO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TORRES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TORTUGUITAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRENQUE LAUQUEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES ALGARROBOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES ALGARROBOS , Est. Cuenca', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES ARROYOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES CUERVOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES DE FEBRERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES LAGUNAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES LEGUAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES LOMAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES PICOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES PICOS , cerro', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRES SARGENTOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRIGALES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRINIDAD , isla', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRISTÁN SUÁREZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRIUNVIRATO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRONCONI, JUAN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRONCOS DEL TALAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRONGE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TROPEZON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TRUJUI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TURDERA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'TUYUTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'UBALLES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'UBALLES, EUFEMIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'UDAQUIOLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'UNIDAD TURISTICA CHAPADMALAL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'UNION FERROVIARIA ,PDA.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'UNZUE, MARIANO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'URDAMPILLETA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'URIBELARREA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'URIBURU, TEDIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'URIÓN , río', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'URQUIZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'V-', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VAGUES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VALDES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VALDEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VALENTÍN ALSINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VALENTIN GOMEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VALENZUELA ANTON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VALERIA DEL MAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VALLIMANCA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VALLIMANCA , arroyo', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VARELA, FLORENCIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VASQUEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VATTEONE, ARTURO ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VECINO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VEDIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VELLOSO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VENANCIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VERDE , península', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VERGARA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VERÓNICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VIBORAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VICEALMIRANTE E.MONTES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VICENTE CASARES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VICENTE LÓPEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VICENTE PEREDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VICTORIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VICTORINO DE LA PLAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VIDELA DORNA, ZENON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VIEYTES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VIGELENCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'Villa Adelina', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ADRIANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA AGUEDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ALDEANITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ALFREDO FORTABAT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ANGELICA ,EST,EL SOCORRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ANGUS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ARCADIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ASTOLFI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA BALLESTER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA BARILARI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA BELGRANO DTO JUNIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA BOSCH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA BOSCH ,PDA.JUAN MARIA BOSCH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA BRANDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA BROWN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA BUENOS AIRES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA BUIDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA BURGOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CACIQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CACIQUE , Est. Alfredo Fortabat', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CAPDEPONT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CARAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CAROLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CARUCHA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CASTELAR , Est. Erize', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CASTELAR EST ERIZE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CELINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CENTENARIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CERRITO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CLELIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA COPACABANA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA CRISTOBAL COLON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DA FONTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DE MAYO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DEL MAR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DELFINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DEPIETRI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DIAMANTE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DIAMANTINA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DIAZ VELEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DOMINGO PRONSATO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DOMINICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DUFAU', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA DURCUDOY , Est. 17 de Agosto', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA EL CACIQUE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ELENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ELISA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA EPUMER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ESPAÑA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ESPIL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA FLANDRIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA FLORESTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA FLORIDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA FORTABAT', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA FOX', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA FRANCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GALICIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GARIBALDI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GENERAL ARIAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GENERAL ARIAS ,EST.KILOMETRO 638', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GENERAL SAVIO EST.SANCHEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GESEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GESELL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GIAMBRUNO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GOBERNADOR UDAONDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GODOY', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA GRAL SAVIO EX SANCHEZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA HARDING GREEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA HERMINIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA IGOLLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA INSUPERABLE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA IRIS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ITALIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LA CHECHELA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LA FLORIDA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LAURA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LEANDRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LEZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LIBRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LOMA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LORETO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LUZURIAGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LYNCH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LYNCH ,EST.CNL.FRANCISCO LYNCH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA LYNCH PUEYRREDON', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MADERO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MAIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MARTELLI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MASSONI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MAYOR', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MAZA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MITRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MOLL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MOLL , Est. Moll', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MONICA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MOQUEHUA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA MOSCONI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA NOCITO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA NUMANCIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA OBRERA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA OLGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA OLGA GRUMBEIN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ORTEGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ORTIZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA PENOTTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA PRECEPTOR M ROBLES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA PRECEPTOR MANUEL CRUZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA PROGRESO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA PRONSATO ,EST.CORONEL MALDONADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA PUEBLO NUEVO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA PUERTO QUEQUEN', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA RAFFO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA RAMALLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA RAMALLO ESTACION FFCC', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA RIO CHICO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ROCH', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA RODRíGUEZ , Est. Barrow', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ROSA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA ROSAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA RUIZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SABOYA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SAENZ PENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SAN ALBERTO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SAN PEDRO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SANCHEZ ELIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SANGUINETTI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SANTA MARIA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SANTOS TESEI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SANZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SARITA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SAUCE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SAURI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SAUZE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SERRA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA SOLDATI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA TALLERES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA TERESA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA TESEI', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA TRIANGULO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA VALLIER', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA VATTEONE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA VERDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA VIGNOLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLA YORK', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLAFANE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLAIGRILLO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLALONGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLANUEVA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VILLARS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VIÑA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'Virrey del Pino', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VIRREYES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VIRREYES ,EST.FACUNDO QUIROGA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VITEL', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VIVORATÁ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VOLTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VOLUNTAD', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VUCETICH, JUAN ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VUELTA DE OBLIGADO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'VUELTA DE ZAPATA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'WARNES', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'WILDE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'YERBAS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'YRAIZOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'YRAIZOZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'YRAZOZ', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'YUTUYACO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZAMUDIO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZAMUDIO ,AP.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZAPIOLA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZÁRATE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZAVALíA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZEBALLOS', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZEBALLOS, ESTANISLAO SEVERO ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZELAYA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZENON VIDELA DORNA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZENTENA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZENTENA ,EST.', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZOILO PERALTA', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZONA DELTA SAN FERNANDO', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ZUBIAURRE', 'provincia': 'Buenos Aires' },
        { 'descripcion': 'ABAUCÁN , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'ACHALCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ACONQUIJA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ACOSTILLA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ADOLFO E CARRANZA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ADOLFO E. CARRANZA', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA AMARILLA LA HOYADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA AMARILLA PTA DE BALASTO', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA COLORADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA DE DIONISIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA DE LAS PALOMAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA DEL CAMPO', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA DEL SIMBOL', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA GRANDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA LOS MATOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA SALADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUA VERDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'AGUAS BLANCAS, cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'ALBIGASTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ALIJILAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'ALMIGAUCHO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ALTA GRACIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ALTO BELLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ALTO DE LA ESQUINA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ALTO DE LA JUNTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ALTO DEL ROSARIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ALTO EL BOLSON', 'provincia': 'Catamarca' },
        { 'descripcion': 'ALUMBRERA', 'provincia': 'Catamarca' },
        { 'descripcion': 'AMADORES', 'provincia': 'Catamarca' },
        { 'descripcion': 'AMANA', 'provincia': 'Catamarca' },
        { 'descripcion': 'AMANAO', 'provincia': 'Catamarca' },
        { 'descripcion': 'AMANCALA', 'provincia': 'Catamarca' },
        { 'descripcion': 'AMBATO', 'provincia': 'Catamarca' },
        { 'descripcion': 'AMPAJANGO', 'provincia': 'Catamarca' },
        { 'descripcion': 'AMPOLLA', 'provincia': 'Catamarca' },
        { 'descripcion': 'AMPUJACO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANCASTI', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANCASTILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANCHOCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANDALGALÁ', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANDALHUALA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANDALUCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANDALUCIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANGELINA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANJULI', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANQUINCILA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANTAPOCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANTINACO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANTOFAGASTA DE LA SIERRA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ANTOFALLA , volcÁn', 'provincia': 'Catamarca' },
        { 'descripcion': 'APOCANGO', 'provincia': 'Catamarca' },
        { 'descripcion': 'APOYACO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ARIMA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ASAMPAY', 'provincia': 'Catamarca' },
        { 'descripcion': 'ASERRADERO EL PILCIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'AYAPASO', 'provincia': 'Catamarca' },
        { 'descripcion': 'AZAMPAY', 'provincia': 'Catamarca' },
        { 'descripcion': 'BALCOSNA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BALCOSNA DE AFUERA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BALDE LA PUNTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BALDE NUEVO', 'provincia': 'Catamarca' },
        { 'descripcion': 'BALUNGASTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BANADO DE DIVISADERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'BAÑADO DE OVANTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BANDA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BANDA DE LUCERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'BANDA DE VARELA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BANDA VARELA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BANOS TERMALES', 'provincia': 'Catamarca' },
        { 'descripcion': 'BARRANCA LARGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BARRANQUITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'BARRO NEGRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'BASTIDOR', 'provincia': 'Catamarca' },
        { 'descripcion': 'BAVIANO', 'provincia': 'Catamarca' },
        { 'descripcion': 'BEBIDA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BELÉN', 'provincia': 'Catamarca' },
        { 'descripcion': 'BELÉN , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'BELÉN , sierra', 'provincia': 'Catamarca' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BISCOTAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'BREA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BREA CHIMPANA', 'provincia': 'Catamarca' },
        { 'descripcion': 'BUEN RETIRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CABALLA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CABRERA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CACHIJAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'CACHUAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'CALACIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CALERA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CALERA LA NORMA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CAMPITOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'CAMPO BLANCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CANADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CANADA DE IPIZCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CANADA DE PAEZ', 'provincia': 'Catamarca' },
        { 'descripcion': 'CANADA LARGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CANTERA ROTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CAPAYÁN', 'provincia': 'Catamarca' },
        { 'descripcion': 'CAPILLITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'CARA CIENAGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CARAPUNCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CARIDAD', 'provincia': 'Catamarca' },
        { 'descripcion': 'CARRANZA, ADOLFO E.', 'provincia': 'Catamarca' },
        { 'descripcion': 'CARRIZAL DE ABAJO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CARRIZAL DE LA COSTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CASA ARMADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CASA DE ALTO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CASA DE LA CUMBRE', 'provincia': 'Catamarca' },
        { 'descripcion': 'CASA VIEJA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CASA VIEJAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'CASPINCHANGO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CASTANAR', 'provincia': 'Catamarca' },
        { 'descripcion': 'CATITA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CERDAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'CERRILLADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CERRO NEGRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CERVINO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHACRITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHAFINAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHAMORRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHAÑAR LAGUNA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHANAR PUNCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHANARCITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHAÑARITOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHANARYACO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHANERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHAQUIAGO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHASCHUIL o GUANCHíN , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHAVERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHICHAGASTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHIFLON', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHILCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHIQUERITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHUCHUCARUANA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHUCOLAY', 'provincia': 'Catamarca' },
        { 'descripcion': 'CHUMBICHA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CIENAGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CIENAGUITA ,AP.', 'provincia': 'Catamarca' },
        { 'descripcion': 'COLLAGASTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'COLONIA DEL VALLE', 'provincia': 'Catamarca' },
        { 'descripcion': 'COLONIA NUEVA CONETA', 'provincia': 'Catamarca' },
        { 'descripcion': 'COLORADO , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'COLORADO , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'COLORADOS , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'COLPES', 'provincia': 'Catamarca' },
        { 'descripcion': 'CONCEPCION', 'provincia': 'Catamarca' },
        { 'descripcion': 'CONDOR HUASI', 'provincia': 'Catamarca' },
        { 'descripcion': 'CONDOR HUASI DE BELEN', 'provincia': 'Catamarca' },
        { 'descripcion': 'CONETA', 'provincia': 'Catamarca' },
        { 'descripcion': 'COPACABANA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CORDOBITA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CORRAL DE PIEDRA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CORRAL QUEMADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CORRAL VIEJO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CORRALITA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CORTADERAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'COSTA DE REYES', 'provincia': 'Catamarca' },
        { 'descripcion': 'COTAGUA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CUEVA BLANCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'CULAMPAJÁ', 'provincia': 'Catamarca' },
        { 'descripcion': 'CULAMPAJÁ , sierra', 'provincia': 'Catamarca' },
        { 'descripcion': 'CUMBRE DEL LAUDO', 'provincia': 'Catamarca' },
        { 'descripcion': 'CUMBRE DEL LAUDO , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'de ANCASTI o EL ALTO , sierra', 'provincia': 'Catamarca' },
        { 'descripcion': 'de CUROTO , cerros', 'provincia': 'Catamarca' },
        { 'descripcion': 'de INCAHUASI , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'de LA LAGUNA VERDE , salina', 'provincia': 'Catamarca' },
        { 'descripcion': 'de LAS CUEVAS , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'de LAS DOS LAGUNAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'de PIPANACO , salar', 'provincia': 'Catamarca' },
        { 'descripcion': 'de SAN BUENAVENTURA , Cordillera', 'provincia': 'Catamarca' },
        { 'descripcion': 'de SAN FRANCISCO , paso', 'provincia': 'Catamarca' },
        { 'descripcion': 'de ZAPATA , sierra', 'provincia': 'Catamarca' },
        { 'descripcion': 'de ZENTA , sierra', 'provincia': 'Catamarca' },
        { 'descripcion': 'de ZURIARA , serranía', 'provincia': 'Catamarca' },
        { 'descripcion': 'del AGUA DE LA FALDA , portezuelo', 'provincia': 'Catamarca' },
        { 'descripcion': 'del POZO , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'DEL VALLE , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'DESRUMBE', 'provincia': 'Catamarca' },
        { 'descripcion': 'DISTRITO ESPINILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'DIVISADERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'DOS POCITOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'DOS TRONCOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'DURAZNILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL ABRA', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL ALAMITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL ALTO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL ARBOL SOLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL ARENAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL ARROYO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL ATOYAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL AYBAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL BALDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL BAÑADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL BARRIAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL BARRIALITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL BASTIDOR', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL BAVIANO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL BELLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL BISCOTE', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL BOLSÓN', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CACHIYUYO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CACHO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CAJON', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CALCHAQUI', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CAMPILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CARRIZAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CENTENARIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CERCADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CEVIL', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CEVILARCITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CHAMICO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CHORRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CIENEGO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CIFLON', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL COLEGIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CÓNDOR , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL CONTADOR', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL DIVISADERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL DURAZNILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL EJE', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL GACHO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL GARABATO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL HUECO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL JUMEAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL LINDERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL MANCHADO , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL MEDANITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL MEDANO ,AP.KILOMETRO 99', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL MEDIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL MISTOLITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL MOLINITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL MUERTO , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL NOGAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL PAJONAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL PAJONAL ,EST.POMAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL PENON', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL PEÑÓN , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL PIE DE LA CUESTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL PORTEZUELO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL POTRERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL POTRERO DE LOS CORDOBA', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL POZO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL PUEBLITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL PUESTO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL QUEBRACHITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL QUIMILO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL REALITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL RECREO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL RODEITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL RODEO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL RODEO GRANDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL SALTITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL SALTO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL SAUCE IPIZCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL SAUCECITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL SIMBOL', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL TABIQUE', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL TACO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL TAMBILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL TESORO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL TOLAR', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL VALLE', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL VALLECITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EL ZARZO', 'provincia': 'Catamarca' },
        { 'descripcion': 'EMPALME SAN CARLOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'ENTRE RIOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'ESQUIÚ', 'provincia': 'Catamarca' },
        { 'descripcion': 'ESTABLECIMIENTO MINERO CERRO B', 'provincia': 'Catamarca' },
        { 'descripcion': 'ESTACION POMAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'ESTANCIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ESTANCIA VIEJA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ESTANCITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ESTANQUE', 'provincia': 'Catamarca' },
        { 'descripcion': 'ESTANZUELA', 'provincia': 'Catamarca' },
        { 'descripcion': 'FALDEO', 'provincia': 'Catamarca' },
        { 'descripcion': 'FAMABALASTRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'FAMATANCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'FARALLON NEGRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'FIAMBALÁ', 'provincia': 'Catamarca' },
        { 'descripcion': 'FUERTE QUEMADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'GALÁN , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'GALPON', 'provincia': 'Catamarca' },
        { 'descripcion': 'GARAY', 'provincia': 'Catamarca' },
        { 'descripcion': 'GARZON', 'provincia': 'Catamarca' },
        { 'descripcion': 'GENTILE', 'provincia': 'Catamarca' },
        { 'descripcion': 'GUANCHICITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'GUANCHIN', 'provincia': 'Catamarca' },
        { 'descripcion': 'GUAYAMBA', 'provincia': 'Catamarca' },
        { 'descripcion': 'GUINCHO', 'provincia': 'Catamarca' },
        { 'descripcion': 'HIGUERA DEL ALUMBRE', 'provincia': 'Catamarca' },
        { 'descripcion': 'HOMBRE MUERTO , sierra', 'provincia': 'Catamarca' },
        { 'descripcion': 'HUACRA', 'provincia': 'Catamarca' },
        { 'descripcion': 'HUALFIN', 'provincia': 'Catamarca' },
        { 'descripcion': 'HUASAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'HUASAYACO', 'provincia': 'Catamarca' },
        { 'descripcion': 'HUASCHASCHI', 'provincia': 'Catamarca' },
        { 'descripcion': 'HUASI CIENAGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'HUAYCAMA', 'provincia': 'Catamarca' },
        { 'descripcion': 'HUILLAPIMA', 'provincia': 'Catamarca' },
        { 'descripcion': 'HUILLAPINA', 'provincia': 'Catamarca' },
        { 'descripcion': 'HUMAYA', 'provincia': 'Catamarca' },
        { 'descripcion': 'IAPES', 'provincia': 'Catamarca' },
        { 'descripcion': 'ICAÑO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ILOGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'INACILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'INCAHUASI', 'provincia': 'Catamarca' },
        { 'descripcion': 'INFANZON', 'provincia': 'Catamarca' },
        { 'descripcion': 'IPIZCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ISLA LARGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'JACIPUNCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'JOYANGO', 'provincia': 'Catamarca' },
        { 'descripcion': 'JOYANGUITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'JULIPAO', 'provincia': 'Catamarca' },
        { 'descripcion': 'JUMEAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'JUNTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'KILOMETR0 62 ,EST.', 'provincia': 'Catamarca' },
        { 'descripcion': 'KILOMETRO 1017', 'provincia': 'Catamarca' },
        { 'descripcion': 'KILOMETRO 38 ,EMB.', 'provincia': 'Catamarca' },
        { 'descripcion': 'KILOMETRO 969 ,EMB.', 'provincia': 'Catamarca' },
        { 'descripcion': 'KILOMETRO 997', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA AGUADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA AGUADA GRANDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA AGUITA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA ANTIGUA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA BAJADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA BARRANCA LARGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA BARROSA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA BREA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA BUENA ESTRELLA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA CALERA DEL SAUCE', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA CAMPANA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA CANADA LARGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA CAPELLANIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA CARRERA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA CHACARITA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA CHACARITA DE LOS PADRES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA CHILCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA CIENAGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA CIENAGA DE LOS ZONDONES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA COLONIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA DORADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA ESTANCITA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA ESTANZUELA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA FALDA DE SAN ANTONIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA GUARDIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA HIGUERITA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA HORQUETA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA HOYADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA LOMA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA MAJADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA MARAVILLA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA MERCED', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA MESADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA MONTOSA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA OLLADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA OVEJERIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA PALCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA PARADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA PARAGUAYA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA PENA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA PIEDRA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA PUERTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA PUERTA DE SAN JOSE', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA PUNTILLA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA PUNTILLA DE SAN JOSE', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA QUEBRADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA QUINTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA RAMADITA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA RENOVACION', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA SALVIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA SOLEDAD', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA TERCENA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA TOTORA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA VALENTINA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA VICTORIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA VIÑA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA VINA DE ABAJO', 'provincia': 'Catamarca' },
        { 'descripcion': 'LA YEGUA MUERTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAGUNA BLANCA , sierra', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAGUNA COLORADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAJA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAMPASILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAMPASO', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS AGUITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS BARRANCAS CASA ARMADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS BARRAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS BREAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS BURRAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS CAÑAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS CASITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS CAYAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS CHACRITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS CIENAGAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS CORTADERAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS CORTADERITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS CUCHILLAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS CUCHILLAS DEL AYBAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS CUEVAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS ESQUINAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS ESTANCIAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS HIGUERITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS HUERTAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS IGUANAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS JUNTAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS JUSTAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS LAJAS , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS LATILLAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS LOSAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS MANZAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS MINAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS MOJARRAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS PAMPAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS PAMPITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS PAPAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS PELADAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS PIEDRAS BLANCAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS PIRQUITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS PUERTAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS RETAMAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS TEJAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS TEJAS DE VALLE VIEJO', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS TRANCAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS TRANQUITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS TRILLAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS VARITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAS ZANJAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LAZARETO', 'provincia': 'Catamarca' },
        { 'descripcion': 'LEÓN MUERTO, portezuelo', 'provincia': 'Catamarca' },
        { 'descripcion': 'LIEBRE', 'provincia': 'Catamarca' },
        { 'descripcion': 'LINDERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOCONTE', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOMA CORTADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOMA GRANDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOMA SOLA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LONDRES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LONDRES ESTE', 'provincia': 'Catamarca' },
        { 'descripcion': 'LONDRES OESTE', 'provincia': 'Catamarca' },
        { 'descripcion': 'LORO HUASI', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS BALVERDIS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS BASTIDORES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS BAZAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS BULACIOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS CAJONES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS CASTILLOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS CAUDILLOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS CHANAMPA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS CISTERNAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS CORDOBESES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS CORRALES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS ESTANTES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS GALPONES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS GONZALES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS GUAYTIMAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS GUINDOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS HUAYCOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS LOROS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS MORTEROS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS NACIMIENTOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS NARVAEZ', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS NAVARROS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS ORTICES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS OVEJEROS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS PEDRAZAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS PINTADOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS PIQUILLINES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS POCITOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS POTRERILLOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS POZUELOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS QUINTEROS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS RASTROJOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS ROBLEDOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS SALTOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS SUNCHOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS TALAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS TRONCOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS VALDEZ', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS VALVEROS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS VARELA', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS VARELAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'LOS ZANJONES', 'provincia': 'Catamarca' },
        { 'descripcion': 'LUNA AGUADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'MAJADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'MALCASCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MALLIN 1', 'provincia': 'Catamarca' },
        { 'descripcion': 'MALLIN 2', 'provincia': 'Catamarca' },
        { 'descripcion': 'MANANTIALES', 'provincia': 'Catamarca' },
        { 'descripcion': 'MARENGO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MARIA DORA', 'provincia': 'Catamarca' },
        { 'descripcion': 'MARIA ELENA', 'provincia': 'Catamarca' },
        { 'descripcion': 'MATAMBRE', 'provincia': 'Catamarca' },
        { 'descripcion': 'MEDANITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MEDANITOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'MEDANO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MESADA DE LOS ZARATE', 'provincia': 'Catamarca' },
        { 'descripcion': 'MESADA GRANDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'MINA CAPILLITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'MINA DAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'MINA INCA HUASI', 'provincia': 'Catamarca' },
        { 'descripcion': 'MINAS AGUA TAPADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'MIRAFLORES', 'provincia': 'Catamarca' },
        { 'descripcion': 'MISCHANGO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MISTOL ANCHO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MOLLE QUEMADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MOLLECITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MOLLEGASTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'MONTE POTRERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MONTE REDONDO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MONTEGASTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'MOTA BOTELLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'MUTQUIN', 'provincia': 'Catamarca' },
        { 'descripcion': 'NACIMIENTO', 'provincia': 'Catamarca' },
        { 'descripcion': 'NACIMIENTOS DE ABAJO', 'provincia': 'Catamarca' },
        { 'descripcion': 'NACIMIENTOS DE ARRIBA', 'provincia': 'Catamarca' },
        { 'descripcion': 'NACIMIENTOS DE SAN ANTONIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'NACIMIENTOS DEL BOLSON', 'provincia': 'Catamarca' },
        { 'descripcion': 'NAIPA', 'provincia': 'Catamarca' },
        { 'descripcion': 'NAVAGUIN', 'provincia': 'Catamarca' },
        { 'descripcion': 'NAVIGAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'NEVADA , sierra', 'provincia': 'Catamarca' },
        { 'descripcion': 'NEVADO DEL CANDADO , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'NOGALITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'o de LOS CÓNDORES , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'OCHO VADOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'OJO DE AGUA', 'provincia': 'Catamarca' },
        { 'descripcion': 'OJO DE LA CORTADERA', 'provincia': 'Catamarca' },
        { 'descripcion': 'OJOS DEL SALADO ,cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'OLLITA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ORELLANO', 'provincia': 'Catamarca' },
        { 'descripcion': 'Otra', 'provincia': 'Catamarca' },
        { 'descripcion': 'OVANTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'OVEJERIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'OYOLA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PAJANGUILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PALO BLANCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PALO CRUZ', 'provincia': 'Catamarca' },
        { 'descripcion': 'PALO LABRADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PALO PARADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PALO SECO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PALOMA YACO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PAMPA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PAMPA CHACRA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PAMPA CIENAGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PAN DE AZUCAR', 'provincia': 'Catamarca' },
        { 'descripcion': 'PANTANOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'PAPA CHACRA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PARAJE LOS CHANARITOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'PASO SAN FRANCISCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PASTOS AMARILLOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'PAYABUAYCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PENAFLOR', 'provincia': 'Catamarca' },
        { 'descripcion': 'PENAS BLANCAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'PENON', 'provincia': 'Catamarca' },
        { 'descripcion': 'PIE DEL MEDANO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PIEDRA LARGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PILCIAO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PILLAHUASI', 'provincia': 'Catamarca' },
        { 'descripcion': 'PLANCHADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PLAZA DE SAN PEDRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PLUMERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'POCITOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'POLCOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'POMAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'POMANCILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PORTEZUELO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PORTILLO CHICO', 'provincia': 'Catamarca' },
        { 'descripcion': 'POSTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'POTRERITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'POZANCONES', 'provincia': 'Catamarca' },
        { 'descripcion': 'POZO DEL ALGARROBO', 'provincia': 'Catamarca' },
        { 'descripcion': 'POZO DEL CAMPO', 'provincia': 'Catamarca' },
        { 'descripcion': 'POZO DEL MISTOL', 'provincia': 'Catamarca' },
        { 'descripcion': 'POZO GRANDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'POZOS CAVADOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'PTO ESPINAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUCARA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUEBLITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUERTA DE CORRAL QUEMADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUERTA GRANDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUERTO BLANCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUERTO CHIPI', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUERTO DE LA PAMPA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUERTO POTRERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUESTO DE FADEL O DE LOBO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUESTO DE LA VIUDA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUESTO DE LOS MORALES', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUESTO DE VERA', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUESTO DEL MEDIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUESTO LOS GOMEZ', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUESTO SABATTE', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUNILLA , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUNTA DE BALASTO', 'provincia': 'Catamarca' },
        { 'descripcion': 'PUNTA DEL POZO', 'provincia': 'Catamarca' },
        { 'descripcion': 'QUEBRACHAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'QUEBRACHO', 'provincia': 'Catamarca' },
        { 'descripcion': 'QUEBRACHOS BLANCOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'QUEBRADA HONDA', 'provincia': 'Catamarca' },
        { 'descripcion': 'QUEMADITA', 'provincia': 'Catamarca' },
        { 'descripcion': 'QUEMADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'QUIMILPA', 'provincia': 'Catamarca' },
        { 'descripcion': 'QUIQUERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'QUIRÓS', 'provincia': 'Catamarca' },
        { 'descripcion': 'QUSTO', 'provincia': 'Catamarca' },
        { 'descripcion': 'RAFAEL CASTILLO', 'provincia': 'Catamarca' },
        { 'descripcion': 'RAMBLONES', 'provincia': 'Catamarca' },
        { 'descripcion': 'RECREO', 'provincia': 'Catamarca' },
        { 'descripcion': 'RETIRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'RETIRO DE COLANA', 'provincia': 'Catamarca' },
        { 'descripcion': 'RINCON', 'provincia': 'Catamarca' },
        { 'descripcion': 'RINCON GRANDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO ABAJO', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO CHICO', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO COLORADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO DE AVILA', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO DE BAZANES', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO DE DON DIEGO', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO DE LA DORADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO DE LA PLATA', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO DE LOS INDIOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO DEL TALA', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO LOS MOLINOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'RIO POTRERO', 'provincia': 'Catamarca' },
        { 'descripcion': 'RODEO GERVAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'RODEO GRANDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'ROSARIO DE COLANA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ROSARIO DEL SUMALAO', 'provincia': 'Catamarca' },
        { 'descripcion': 'RUMIMONION', 'provincia': 'Catamarca' },
        { 'descripcion': 'SALADILLO , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'SALADO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SALADO , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'SALAUCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SALCEDO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN ANTONIO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN ANTONIO , Est. San Antonio de la Paz', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN ANTONIO ,EST.SAN A.DE LA PAZ', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN ANTONIO DE LA PAZ', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN ANTONIO DE P BLANCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN ANTONIO DE PANCLIN', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN ANTONIO DEL CAJON', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN ANTONIO FRAY M ESQUIU', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN ANTONIO VIEJO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN FDO DEL VALLE DE CATAMARCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN FDO.DEL VALLE DE CATAMARCA ,EST.', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN FERNANDO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN FERNANDO DEL VALLE DE CATAMARCA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN ISIDRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN JERONIMO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN JOSÉ', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN JOSÉ', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN JOSE BANDA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN JOSE DE TINOGASTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN JOSE NORTE', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN MARTíN', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN MIGUEL', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAN PEDRO CAPAYAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'SANCHO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SANTA CRUZ', 'provincia': 'Catamarca' },
        { 'descripcion': 'SANTA GERTRUDIS', 'provincia': 'Catamarca' },
        { 'descripcion': 'SANTA LUCIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SANTA MARíA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SANTA MARíA , río', 'provincia': 'Catamarca' },
        { 'descripcion': 'SANTA ROSA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SANTO TOMAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAUCE DE LOS CEJAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAUCE HUACHO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAUCE MAYO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAUJIL', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAUJIL', 'provincia': 'Catamarca' },
        { 'descripcion': 'SAUJIL DE TINOGASTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SEBILA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SHINCAL', 'provincia': 'Catamarca' },
        { 'descripcion': 'SICHA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SIJÁN', 'provincia': 'Catamarca' },
        { 'descripcion': 'SILVERIO , laguna', 'provincia': 'Catamarca' },
        { 'descripcion': 'SINGUIL', 'provincia': 'Catamarca' },
        { 'descripcion': 'SISIGUASI', 'provincia': 'Catamarca' },
        { 'descripcion': 'SISIHUASI', 'provincia': 'Catamarca' },
        { 'descripcion': 'SUCUMA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SUMALAO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SUMAMPA', 'provincia': 'Catamarca' },
        { 'descripcion': 'SUNCHO', 'provincia': 'Catamarca' },
        { 'descripcion': 'SUPERI', 'provincia': 'Catamarca' },
        { 'descripcion': 'SURUIPIANA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TACAHUASI', 'provincia': 'Catamarca' },
        { 'descripcion': 'TACANA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TACO', 'provincia': 'Catamarca' },
        { 'descripcion': 'TACO DE ABAJO', 'provincia': 'Catamarca' },
        { 'descripcion': 'TACOPAMPA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TAJAMARES', 'provincia': 'Catamarca' },
        { 'descripcion': 'TALA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TALA ZAPATA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TALAGUADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TALAMAYO', 'provincia': 'Catamarca' },
        { 'descripcion': 'TALAR', 'provincia': 'Catamarca' },
        { 'descripcion': 'TALEGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TAMBERIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TAMBU', 'provincia': 'Catamarca' },
        { 'descripcion': 'TAPSO', 'provincia': 'Catamarca' },
        { 'descripcion': 'TATON', 'provincia': 'Catamarca' },
        { 'descripcion': 'TELARITOS', 'provincia': 'Catamarca' },
        { 'descripcion': 'TERMAS VILLA VIL', 'provincia': 'Catamarca' },
        { 'descripcion': 'TIERRA VERDE', 'provincia': 'Catamarca' },
        { 'descripcion': 'TINAJERA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TINOGASTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TINTIGASTA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TIORCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'TIPAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'TORO MUERTO', 'provincia': 'Catamarca' },
        { 'descripcion': 'TORO YACO', 'provincia': 'Catamarca' },
        { 'descripcion': 'TOROYACO', 'provincia': 'Catamarca' },
        { 'descripcion': 'TOTORA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TOTORILLA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TRAMPASACHA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TRES CRUCES , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'TRES PUENTES', 'provincia': 'Catamarca' },
        { 'descripcion': 'TRES QUEBRADOS o TORO MUERTO , paso', 'provincia': 'Catamarca' },
        { 'descripcion': 'TRIDENTE , cerro', 'provincia': 'Catamarca' },
        { 'descripcion': 'TROYA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TULA', 'provincia': 'Catamarca' },
        { 'descripcion': 'TUSCUMAYO', 'provincia': 'Catamarca' },
        { 'descripcion': 'VEGA', 'provincia': 'Catamarca' },
        { 'descripcion': 'VEGA CURUTU', 'provincia': 'Catamarca' },
        { 'descripcion': 'VEGA TAMBERIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'VICUNA PAMPA', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILISMÁN', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA COLLANTES', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA CORONEL ARROYO', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA CUBAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA DOLORES', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA GIL', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA LAS PIRQUITAS', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA MACEDO', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA OFELIA', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA PARQUE CHACABUCO', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA QUINTIN AHUMADA', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA SAN ROQUE', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA SELEME', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLA SOTOMAYOR', 'provincia': 'Catamarca' },
        { 'descripcion': 'VILLAVIL', 'provincia': 'Catamarca' },
        { 'descripcion': 'VINA DEL CERRO', 'provincia': 'Catamarca' },
        { 'descripcion': 'VINQUIS', 'provincia': 'Catamarca' },
        { 'descripcion': 'VISCOTE', 'provincia': 'Catamarca' },
        { 'descripcion': 'YACOCHUYO', 'provincia': 'Catamarca' },
        { 'descripcion': 'YAPES', 'provincia': 'Catamarca' },
        { 'descripcion': 'YAQUICHO', 'provincia': 'Catamarca' },
        { 'descripcion': 'YERBA BUENA', 'provincia': 'Catamarca' },
        { 'descripcion': 'YOCAN', 'provincia': 'Catamarca' },
        { 'descripcion': 'ZANJA', 'provincia': 'Catamarca' },
        { 'descripcion': 'ZARCITO', 'provincia': 'Catamarca' },
        { 'descripcion': 'ZARZA', 'provincia': 'Catamarca' },
        { 'descripcion': '2 DE MAYO', 'provincia': 'Chaco' },
        { 'descripcion': 'AGUA BUENA', 'provincia': 'Chaco' },
        { 'descripcion': 'ALDEA FORESTAL', 'provincia': 'Chaco' },
        { 'descripcion': 'ALELOY', 'provincia': 'Chaco' },
        { 'descripcion': 'AMAMBAY', 'provincia': 'Chaco' },
        { 'descripcion': 'ARROYO QUINTANA', 'provincia': 'Chaco' },
        { 'descripcion': 'AVANZADA', 'provincia': 'Chaco' },
        { 'descripcion': 'AVIA TERAI', 'provincia': 'Chaco' },
        { 'descripcion': 'BAJO VERDE', 'provincia': 'Chaco' },
        { 'descripcion': 'BARRANQUERAS', 'provincia': 'Chaco' },
        { 'descripcion': 'BARRIO GRAL JOSE DE SAN MARTIN', 'provincia': 'Chaco' },
        { 'descripcion': 'BARRIO SARMIENTO', 'provincia': 'Chaco' },
        { 'descripcion': 'BASAIL', 'provincia': 'Chaco' },
        { 'descripcion': 'BENITEZ', 'provincia': 'Chaco' },
        { 'descripcion': 'BERLIN', 'provincia': 'Chaco' },
        { 'descripcion': 'BOCAS', 'provincia': 'Chaco' },
        { 'descripcion': 'BOLSA GRANDE', 'provincia': 'Chaco' },
        { 'descripcion': 'BOTIJA', 'provincia': 'Chaco' },
        { 'descripcion': 'CABANARO PASAJE', 'provincia': 'Chaco' },
        { 'descripcion': 'CABEZA DE TIGRE', 'provincia': 'Chaco' },
        { 'descripcion': 'CABRAL', 'provincia': 'Chaco' },
        { 'descripcion': 'CABRAL CUE', 'provincia': 'Chaco' },
        { 'descripcion': 'CACUI', 'provincia': 'Chaco' },
        { 'descripcion': 'CACUI ,EST', 'provincia': 'Chaco' },
        { 'descripcion': 'CALIFORNIA', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO DE GALNASI', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO DE LA CHOZA', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO ECHEGARAY', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO EL AIBAL', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO EL BERMEJO', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO EL JACARANDA', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO EL ONZA', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO FELDMAN', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO FERRANDO', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO LARGO', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO LAS PUERTAS', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO MORENO', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO NUEVO', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO OVEROS', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO WINTER', 'provincia': 'Chaco' },
        { 'descripcion': 'CAMPO ZAPA', 'provincia': 'Chaco' },
        { 'descripcion': 'CANCHA LARGA', 'provincia': 'Chaco' },
        { 'descripcion': 'CANGUI GRANDE , arroyo', 'provincia': 'Chaco' },
        { 'descripcion': 'CAPITÁN SOLARI', 'provincia': 'Chaco' },
        { 'descripcion': 'CASTELLI ,EST.COLONIA J.J.CASTELLI', 'provincia': 'Chaco' },
        { 'descripcion': 'CASTELLIN', 'provincia': 'Chaco' },
        { 'descripcion': 'CHARADAI', 'provincia': 'Chaco' },
        { 'descripcion': 'CHARATA', 'provincia': 'Chaco' },
        { 'descripcion': 'CHOROTIS', 'provincia': 'Chaco' },
        { 'descripcion': 'CIERVO PETISO', 'provincia': 'Chaco' },
        { 'descripcion': 'CNIA AGRICOLA PAMPA NAPENAY', 'provincia': 'Chaco' },
        { 'descripcion': 'COLOMBIA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA ABATE', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA ABORIGEN', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA ABORIGEN CHACO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA AGRICOLA ABORIGEN CHACO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA ALELAY', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA BAJO HONDO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA BARANDA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA BARRERA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA BENITEZ', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA BENíTEZ', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA BERMEJO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA BERNARDINO RIVADAVIA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA BLAS PARERA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA BRAVO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA CABEZA DE BUEY', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA CABEZA DE TIGRE', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA CABRAL', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA CERVEZA HELADA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA CODUTTI', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA CORONEL BRANDSEN', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA CORONEL DORREGO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA CUERO QUEMADO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA DRYDALE', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA ECHEGARAY', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA ECONOMIA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA EL AGUARA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA EL ALAZAN', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA EL CIERVO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA EL CURUPI', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA EL FISCAL', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA EL PARAISAL', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA EL PILAR', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA EL TIGRE', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA EL TRIANGULO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA ELISA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA ESPERANZA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA FLORENCIA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA FORTUNI', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA GENERAL NECOCHEA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA GUALTIERI', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA HAMBURGUESA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA HERRERA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA HIPOLITO VIEYTES', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA INDIGENA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA JOSÉ MÁRMOL', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA JUAN JOSE CASTELLI', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA JUAN JOSE PASO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA JUAN LARREA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA JUAN LAVALLE', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA JUAN PENCO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LA AVANZADA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LA FILOMENA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LA FLORIDA CHICA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LA FLORIDA GRANDE', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LA LOLA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LA MARIA LUISA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LA TAMBORA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LA TOTA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LAS AVISPAS', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LOS GANZOS', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA LUCINDA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA MALGRATTI', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA MARIANO SARRATEA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA MATHEU', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA MIXTA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA MONTE QUEMADO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA NECOCHEA SUD', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA PALMIRA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA POPULAR', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA PUENTE PHILIPON', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA PUENTE URIBURU', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA RIO DE ORO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA RODRIGUEZ PENA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA SABINA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA SAN ANTONIO', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA SANTA ELENA', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA SCHMIDT', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA SIETE ARBOLES', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA TACUARI', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA TRES LAGUNAS', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA VELAZ', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA VELEZ SARSFIELD', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIA WELHERS', 'provincia': 'Chaco' },
        { 'descripcion': 'COLONIAS UNIDAS', 'provincia': 'Chaco' },
        { 'descripcion': 'COLORADA', 'provincia': 'Chaco' },
        { 'descripcion': 'COMANDANCIA FRIAS', 'provincia': 'Chaco' },
        { 'descripcion': 'CONCEPCIÓN DEL BERMEJO', 'provincia': 'Chaco' },
        { 'descripcion': 'CORONEL AVALOS', 'provincia': 'Chaco' },
        { 'descripcion': 'CORONEL DU GRATY', 'provincia': 'Chaco' },
        { 'descripcion': 'CORZUELA', 'provincia': 'Chaco' },
        { 'descripcion': 'COSTA INE', 'provincia': 'Chaco' },
        { 'descripcion': 'COTE LAI', 'provincia': 'Chaco' },
        { 'descripcion': 'COTE-LAI', 'provincia': 'Chaco' },
        { 'descripcion': 'CUATRO ARBOLES', 'provincia': 'Chaco' },
        { 'descripcion': 'CURANDU', 'provincia': 'Chaco' },
        { 'descripcion': 'CURVA DE NOVOA', 'provincia': 'Chaco' },
        { 'descripcion': 'de ORO , río', 'provincia': 'Chaco' },
        { 'descripcion': 'del CERRITO , isla', 'provincia': 'Chaco' },
        { 'descripcion': 'del COBRE , sierra', 'provincia': 'Chaco' },
        { 'descripcion': 'DIEZ DE MAYO', 'provincia': 'Chaco' },
        { 'descripcion': 'DONA PAULA', 'provincia': 'Chaco' },
        { 'descripcion': 'DOS BOLICHES', 'provincia': 'Chaco' },
        { 'descripcion': 'EL 14', 'provincia': 'Chaco' },
        { 'descripcion': 'EL 15', 'provincia': 'Chaco' },
        { 'descripcion': 'EL ASUSTADO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL ASUSTADO , arroyo', 'provincia': 'Chaco' },
        { 'descripcion': 'EL BOQUERON', 'provincia': 'Chaco' },
        { 'descripcion': 'EL CAMPAMENTO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL CANON', 'provincia': 'Chaco' },
        { 'descripcion': 'EL CATORCE', 'provincia': 'Chaco' },
        { 'descripcion': 'EL CUARENTA Y SEIS', 'provincia': 'Chaco' },
        { 'descripcion': 'EL CURUNDU', 'provincia': 'Chaco' },
        { 'descripcion': 'EL DESIERTO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL DESTIERRO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL ESPINILLO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL ESQUINERO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL ESTERO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL GUANACO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL INDIO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL LAPACHO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL NANDUBAY', 'provincia': 'Chaco' },
        { 'descripcion': 'EL OBRAJE', 'provincia': 'Chaco' },
        { 'descripcion': 'EL ORO BLANCO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL PALMAR', 'provincia': 'Chaco' },
        { 'descripcion': 'EL PALMAR TRES ISLETAS', 'provincia': 'Chaco' },
        { 'descripcion': 'EL PERDIDO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL PICASO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL PINTADO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL PORONGAL', 'provincia': 'Chaco' },
        { 'descripcion': 'EL PUCA', 'provincia': 'Chaco' },
        { 'descripcion': 'EL PUMA', 'provincia': 'Chaco' },
        { 'descripcion': 'EL RAIGONAL', 'provincia': 'Chaco' },
        { 'descripcion': 'EL RECOVECO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL RECOVO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL RECREO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL SAUZAL', 'provincia': 'Chaco' },
        { 'descripcion': 'EL SAUZALITO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL SIMBOLAR', 'provincia': 'Chaco' },
        { 'descripcion': 'EL TACURUZAL', 'provincia': 'Chaco' },
        { 'descripcion': 'EL TRAGADERO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL TREINTA Y SEIS', 'provincia': 'Chaco' },
        { 'descripcion': 'EL TRIANGULO', 'provincia': 'Chaco' },
        { 'descripcion': 'EL VALLA', 'provincia': 'Chaco' },
        { 'descripcion': 'EL VISCACHERAL', 'provincia': 'Chaco' },
        { 'descripcion': 'ENRIQUE URIEN', 'provincia': 'Chaco' },
        { 'descripcion': 'ESTANCIA EL SABALO', 'provincia': 'Chaco' },
        { 'descripcion': 'ESTANCIA LA AURORA', 'provincia': 'Chaco' },
        { 'descripcion': 'ESTANCIA LOMA ALTA', 'provincia': 'Chaco' },
        { 'descripcion': 'ESTERO REDONDO', 'provincia': 'Chaco' },
        { 'descripcion': 'EX FORTIN ARENALES', 'provincia': 'Chaco' },
        { 'descripcion': 'EX FORTIN COMANDANTE FRIAS', 'provincia': 'Chaco' },
        { 'descripcion': 'EX FORTIN LAVALLE', 'provincia': 'Chaco' },
        { 'descripcion': 'EX FORTIN PEREZ MILLAN', 'provincia': 'Chaco' },
        { 'descripcion': 'EX FORTIN WILDE', 'provincia': 'Chaco' },
        { 'descripcion': 'EX FORTIN ZELAVA', 'provincia': 'Chaco' },
        { 'descripcion': 'EX FORTIN ZELAYA', 'provincia': 'Chaco' },
        { 'descripcion': 'FLORADORA', 'provincia': 'Chaco' },
        { 'descripcion': 'FONTANA', 'provincia': 'Chaco' },
        { 'descripcion': 'FORTIN AGUILAR', 'provincia': 'Chaco' },
        { 'descripcion': 'FORTIN CARDOSO', 'provincia': 'Chaco' },
        { 'descripcion': 'FORTIN CHAJA', 'provincia': 'Chaco' },
        { 'descripcion': 'FORTIN LAS CHUNAS', 'provincia': 'Chaco' },
        { 'descripcion': 'FORTíN LAVALLE', 'provincia': 'Chaco' },
        { 'descripcion': 'FORTIN POTRERO', 'provincia': 'Chaco' },
        { 'descripcion': 'FORTIN TOTORALITA LUGAR HISTOR', 'provincia': 'Chaco' },
        { 'descripcion': 'FUERTE ESPERANZA', 'provincia': 'Chaco' },
        { 'descripcion': 'GANCEDO', 'provincia': 'Chaco' },
        { 'descripcion': 'GANDOLFI', 'provincia': 'Chaco' },
        { 'descripcion': 'GENERAL CAPDEVILA', 'provincia': 'Chaco' },
        { 'descripcion': 'GENERAL CAPDEVILLA', 'provincia': 'Chaco' },
        { 'descripcion': 'GENERAL DONOVAN', 'provincia': 'Chaco' },
        { 'descripcion': 'GENERAL JOSE DE SAN MARTIN', 'provincia': 'Chaco' },
        { 'descripcion': 'GENERAL JOSÉ DE SAN MARTíN , Est. Zapallar', 'provincia': 'Chaco' },
        { 'descripcion': 'GENERAL NECOCHEA', 'provincia': 'Chaco' },
        { 'descripcion': 'GENERAL OBLIGADO', 'provincia': 'Chaco' },
        { 'descripcion': 'GENERAL PINEDO', 'provincia': 'Chaco' },
        { 'descripcion': 'GENERAL VEDIA', 'provincia': 'Chaco' },
        { 'descripcion': 'GIRASOL', 'provincia': 'Chaco' },
        { 'descripcion': 'GIRASOL ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'GUAYAIBI', 'provincia': 'Chaco' },
        { 'descripcion': 'GUAYCURÚ , arroyo', 'provincia': 'Chaco' },
        { 'descripcion': 'GUAYCURÚ , riacho', 'provincia': 'Chaco' },
        { 'descripcion': 'HAUMONíA', 'provincia': 'Chaco' },
        { 'descripcion': 'HERMOSO CAMPO', 'provincia': 'Chaco' },
        { 'descripcion': 'HIVONNAIT', 'provincia': 'Chaco' },
        { 'descripcion': 'HORQUILLA', 'provincia': 'Chaco' },
        { 'descripcion': 'INDIA MUERTA', 'provincia': 'Chaco' },
        { 'descripcion': 'INGENIERO BARBET', 'provincia': 'Chaco' },
        { 'descripcion': 'INVERNADA', 'provincia': 'Chaco' },
        { 'descripcion': 'IPORA GUAZU', 'provincia': 'Chaco' },
        { 'descripcion': 'ISLA ANTEQUERA', 'provincia': 'Chaco' },
        { 'descripcion': 'ISLA DEL CERRITO', 'provincia': 'Chaco' },
        { 'descripcion': 'ITíN', 'provincia': 'Chaco' },
        { 'descripcion': 'JUAN JOSE CASTELLI', 'provincia': 'Chaco' },
        { 'descripcion': 'JUAN JOSÉ CASTELLI , Est. Cnia. J. J. Castelli', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETR0 501 ,AP.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 22 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 29 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 34 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 39 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 42 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 443 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 474 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 48 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 518 ,DV.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 520 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 522 ,DV.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 53 ,AP.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 59 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 596 ,DV.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 602 ,AP.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 75', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 841 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 855 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'KILOMETRO 884 ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'LA ARMONIA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA CHACO', 'provincia': 'Chaco' },
        { 'descripcion': 'LA CHINA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA CHIQUITA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA CHIQUITA ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'LA CHOZA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA CLOTILDE', 'provincia': 'Chaco' },
        { 'descripcion': 'LA COLONIA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA COSTOSA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA CUCHILLA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA DIFICULTAD', 'provincia': 'Chaco' },
        { 'descripcion': 'LA ECONOMIA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA EDUVIGES', 'provincia': 'Chaco' },
        { 'descripcion': 'LA EDUVIGIS', 'provincia': 'Chaco' },
        { 'descripcion': 'LA ELABORADORA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA ENTRADA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA ESCONDIDA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA ESCONDIDA ,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'LA ESTACION', 'provincia': 'Chaco' },
        { 'descripcion': 'LA EVANGELICA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA FIDELIDAD', 'provincia': 'Chaco' },
        { 'descripcion': 'LA FLECHA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA FLOJERA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA GANADERA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA GRINGA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA ILUSION', 'provincia': 'Chaco' },
        { 'descripcion': 'LA LEONESA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA LIBERTAD', 'provincia': 'Chaco' },
        { 'descripcion': 'LA LIGURIA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA LOMA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA LUCINDA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA MAGDALENA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA MASCOTA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA MATANZA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA MEDIA LUNA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA NATA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA NUEVA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA OFELIA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA PALOMA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA PALOMETA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA PASTORIL', 'provincia': 'Chaco' },
        { 'descripcion': 'LA PASTORIL ,DV.', 'provincia': 'Chaco' },
        { 'descripcion': 'LA PILAR', 'provincia': 'Chaco' },
        { 'descripcion': 'LA PINTA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA POBLADORA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA RAQUEL', 'provincia': 'Chaco' },
        { 'descripcion': 'LA SABANA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA SALTARINA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA SARA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA SOLEDAD', 'provincia': 'Chaco' },
        { 'descripcion': 'LA SUIZA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA TAMBORA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA TAPERA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA TIGRA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA TRANQUILIDAD', 'provincia': 'Chaco' },
        { 'descripcion': 'LA VERDE', 'provincia': 'Chaco' },
        { 'descripcion': 'LA VICUÑA', 'provincia': 'Chaco' },
        { 'descripcion': 'LA VIRUELA', 'provincia': 'Chaco' },
        { 'descripcion': 'LAGUNA BELIGAY', 'provincia': 'Chaco' },
        { 'descripcion': 'LAGUNA BLANCA', 'provincia': 'Chaco' },
        { 'descripcion': 'LAGUNA ESCONDIDA', 'provincia': 'Chaco' },
        { 'descripcion': 'LAGUNA LIMPIA', 'provincia': 'Chaco' },
        { 'descripcion': 'LAGUNA PATOS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAPACHITO', 'provincia': 'Chaco' },
        { 'descripcion': 'LAPACHO', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS BANDERAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS BLANCAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS BREÑAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS CUATRO BOCAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS CUCHILLAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS CUCHILLAS CNIA J MARMOL', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS GARCITAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS GOLONDRINAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS GOLONDRINAS SUR', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS HACHERAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS LEONAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS MARAVILLAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS MORERAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS PALMAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LAS VIBORAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LIVA', 'provincia': 'Chaco' },
        { 'descripcion': 'LOMA FLORIDA', 'provincia': 'Chaco' },
        { 'descripcion': 'LORENA', 'provincia': 'Chaco' },
        { 'descripcion': 'LORO BLANCO', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS ALGARROBOS', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS BARRILES', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS CERRITOS', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS CHINACOS', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS FORTINES', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS FRENTONES', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS GANSOS', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS GUALCOS', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS MAGOS', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS PALMARES', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS POZOS', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS QUEBRACHITOS', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS TOBAS', 'provincia': 'Chaco' },
        { 'descripcion': 'LOS TUNALES', 'provincia': 'Chaco' },
        { 'descripcion': 'MACHAGAI', 'provincia': 'Chaco' },
        { 'descripcion': 'MACOMITAS', 'provincia': 'Chaco' },
        { 'descripcion': 'MADRE DE DIOS', 'provincia': 'Chaco' },
        { 'descripcion': 'MAKALLÉ', 'provincia': 'Chaco' },
        { 'descripcion': 'MALBALAES', 'provincia': 'Chaco' },
        { 'descripcion': 'MANANTIALES', 'provincia': 'Chaco' },
        { 'descripcion': 'MANUELíN CUÉ , isla', 'provincia': 'Chaco' },
        { 'descripcion': 'MARGARITA BELÉN', 'provincia': 'Chaco' },
        { 'descripcion': 'MARIA SARA', 'provincia': 'Chaco' },
        { 'descripcion': 'MARTINEZ DE HOZ', 'provincia': 'Chaco' },
        { 'descripcion': 'MESON DE FIERRO', 'provincia': 'Chaco' },
        { 'descripcion': 'MIERES', 'provincia': 'Chaco' },
        { 'descripcion': 'MINISTRO RAMON GOMEZ', 'provincia': 'Chaco' },
        { 'descripcion': 'MIRAFLORES', 'provincia': 'Chaco' },
        { 'descripcion': 'MISION ANGELICANA', 'provincia': 'Chaco' },
        { 'descripcion': 'MISION NUEVA POMPEYA', 'provincia': 'Chaco' },
        { 'descripcion': 'MOLLE MARCADO', 'provincia': 'Chaco' },
        { 'descripcion': 'MUERTO , río', 'provincia': 'Chaco' },
        { 'descripcion': 'NAPALPI', 'provincia': 'Chaco' },
        { 'descripcion': 'NAPENAY', 'provincia': 'Chaco' },
        { 'descripcion': 'NEGRO , río', 'provincia': 'Chaco' },
        { 'descripcion': 'NEGRO DE LA LAGUNA VERDE , cerro', 'provincia': 'Chaco' },
        { 'descripcion': 'NEGROARA , cerro', 'provincia': 'Chaco' },
        { 'descripcion': 'NOGUEIRA , riacho', 'provincia': 'Chaco' },
        { 'descripcion': 'NTRA SENORA DE LA CONCEPCION', 'provincia': 'Chaco' },
        { 'descripcion': 'NUEVA POBLACION', 'provincia': 'Chaco' },
        { 'descripcion': 'NUEVA POMPEYA', 'provincia': 'Chaco' },
        { 'descripcion': 'NUEVA UNION', 'provincia': 'Chaco' },
        { 'descripcion': 'NUEVA YORK', 'provincia': 'Chaco' },
        { 'descripcion': 'OBRAJE LA VICUNA', 'provincia': 'Chaco' },
        { 'descripcion': 'Otra', 'provincia': 'Chaco' },
        { 'descripcion': 'PALMAR CENTRAL', 'provincia': 'Chaco' },
        { 'descripcion': 'PALMAR NORTE', 'provincia': 'Chaco' },
        { 'descripcion': 'PALO MARCADO', 'provincia': 'Chaco' },
        { 'descripcion': 'PALOMETA , río', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA AGUADO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA AGUARA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA ALEGRIA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA ALELAI', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA ALMIRÓN', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA ALSINA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA AVILA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA BANDERA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA BARRERA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA BOLSA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA BORRACHO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA BRUGNOLI', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA CABÁ NARÓ', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA CABRERA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA CABURE', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA CASTRO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA CEJAS', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA CHICA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA CUVALO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA DE LAS FLORES', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA DE LOS LOCOS', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA DEL CIELO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA DEL HUEVO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA DEL INDIO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA DEL INFIERNO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA DEL REGIMIENTO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA DEL TORDILLO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA DEL ZORRO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA DOROTIER', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA EL 11', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA EL 12', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA EL FOSFORITO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA EL MANGRULLO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA EL MOLLAR', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA EL SILENCIO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA FLORES', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA FLORIDA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA GALPON', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA GAMBA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA GUANACO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA HERMOSA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA IPORA GUAZU', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA JUANITA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA LA PELIGROSA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA LANDRIEL', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA LARGA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA LEGUA CUATRO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA LOCA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA LOS BEDOGNI', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA MACHETE', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA MITRE', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA OCULTA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA PELADO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA PEREYRA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA QUIMILI', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA RALERA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA SAN MARTIN', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA SOMMER', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA TOLOSA CHICA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA TOLOSA GRANDE', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA VARGAS', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA VERDE', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA VILLORDO', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA VIRGEN', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPA ZANATA', 'provincia': 'Chaco' },
        { 'descripcion': 'PAMPINI', 'provincia': 'Chaco' },
        { 'descripcion': 'PARAJE EL COLCHON', 'provincia': 'Chaco' },
        { 'descripcion': 'PARAJE EL COLORADO', 'provincia': 'Chaco' },
        { 'descripcion': 'PARAJE INDEPENDENCIA', 'provincia': 'Chaco' },
        { 'descripcion': 'PARAJE LAS TABLAS', 'provincia': 'Chaco' },
        { 'descripcion': 'PARAJE OJO DE AGUA', 'provincia': 'Chaco' },
        { 'descripcion': 'PARAJE SANTA CRUZ', 'provincia': 'Chaco' },
        { 'descripcion': 'PARALELO 28', 'provincia': 'Chaco' },
        { 'descripcion': 'PARQUE NACIONAL CHACO', 'provincia': 'Chaco' },
        { 'descripcion': 'PASO DEL OSO', 'provincia': 'Chaco' },
        { 'descripcion': 'PICADITAS', 'provincia': 'Chaco' },
        { 'descripcion': 'PINDO', 'provincia': 'Chaco' },
        { 'descripcion': 'PINEDO CENTRAL', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DE LA LINEA', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DE LA MULA', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DE LA PAVA', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DE LA TUNA', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DE LAS GARZAS', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DE LOS SURIS', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DEL CINCUENTA', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DEL GATO', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DEL GRIS', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DEL INDIO ,DV.', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DEL INDIO ESTACION FCGB', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DEL NEGRO', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO DEL TORO', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO EL CHANAR', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO HONDO', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO LA BREA', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO LA OSCA', 'provincia': 'Chaco' },
        { 'descripcion': 'POZO NAVAGAN', 'provincia': 'Chaco' },
        { 'descripcion': 'PRESIDENCIA DE LA PLAZA', 'provincia': 'Chaco' },
        { 'descripcion': 'PRESIDENCIA ROCA', 'provincia': 'Chaco' },
        { 'descripcion': 'PRESIDENCIA ROQUE SAENZ PEÑA', 'provincia': 'Chaco' },
        { 'descripcion': 'PUEBLO CLODOMIRO DIAZ', 'provincia': 'Chaco' },
        { 'descripcion': 'PUEBLO PUCA', 'provincia': 'Chaco' },
        { 'descripcion': 'PUENTE INE', 'provincia': 'Chaco' },
        { 'descripcion': 'PUENTE PALOMETA', 'provincia': 'Chaco' },
        { 'descripcion': 'PUENTE PHILIPPON', 'provincia': 'Chaco' },
        { 'descripcion': 'PUENTE SVRITZ', 'provincia': 'Chaco' },
        { 'descripcion': 'PUERTA DE LEON', 'provincia': 'Chaco' },
        { 'descripcion': 'PUERTO ANTEQUERA', 'provincia': 'Chaco' },
        { 'descripcion': 'PUERTO BASTIANI', 'provincia': 'Chaco' },
        { 'descripcion': 'PUERTO BERMEJO', 'provincia': 'Chaco' },
        { 'descripcion': 'PUERTO LAS PALMAS', 'provincia': 'Chaco' },
        { 'descripcion': 'PUERTO TIROL', 'provincia': 'Chaco' },
        { 'descripcion': 'PUERTO URQUIZA', 'provincia': 'Chaco' },
        { 'descripcion': 'PUERTO VICENTINI', 'provincia': 'Chaco' },
        { 'descripcion': 'PUERTO VILELAS', 'provincia': 'Chaco' },
        { 'descripcion': 'PUERTO ZAPALLAR', 'provincia': 'Chaco' },
        { 'descripcion': 'PUESTO CARRIZO', 'provincia': 'Chaco' },
        { 'descripcion': 'PUESTO COCHERI', 'provincia': 'Chaco' },
        { 'descripcion': 'PUESTO MENDIZABAL', 'provincia': 'Chaco' },
        { 'descripcion': 'PUNTA DE RIELES', 'provincia': 'Chaco' },
        { 'descripcion': 'PUNTA NUEVA', 'provincia': 'Chaco' },
        { 'descripcion': 'PUNTA RIELES', 'provincia': 'Chaco' },
        { 'descripcion': 'QUIA', 'provincia': 'Chaco' },
        { 'descripcion': 'QUITILIPI', 'provincia': 'Chaco' },
        { 'descripcion': 'RANCHOS VIEJOS', 'provincia': 'Chaco' },
        { 'descripcion': 'REDUCCION DE LA CANGAYE LUGAR', 'provincia': 'Chaco' },
        { 'descripcion': 'REDUCCION DE SAN BERNARDO EL V', 'provincia': 'Chaco' },
        { 'descripcion': 'REDUCCION NAPALPI', 'provincia': 'Chaco' },
        { 'descripcion': 'RESISTENCIA', 'provincia': 'Chaco' },
        { 'descripcion': 'RINCON DEL ZORRO', 'provincia': 'Chaco' },
        { 'descripcion': 'RIO ARAZA', 'provincia': 'Chaco' },
        { 'descripcion': 'RIO BERMEJO', 'provincia': 'Chaco' },
        { 'descripcion': 'RIO DE ORO', 'provincia': 'Chaco' },
        { 'descripcion': 'RíO MUERTO', 'provincia': 'Chaco' },
        { 'descripcion': 'RIO TAPENAGA', 'provincia': 'Chaco' },
        { 'descripcion': 'SALADO , río', 'provincia': 'Chaco' },
        { 'descripcion': 'SALTO DE LA VIEJA', 'provincia': 'Chaco' },
        { 'descripcion': 'SAMUHÚ', 'provincia': 'Chaco' },
        { 'descripcion': 'SAN BERNARDO', 'provincia': 'Chaco' },
        { 'descripcion': 'SAN JUANCITO', 'provincia': 'Chaco' },
        { 'descripcion': 'SAN TELMO', 'provincia': 'Chaco' },
        { 'descripcion': 'SANTA AGUEDA', 'provincia': 'Chaco' },
        { 'descripcion': 'SANTA CRUZ', 'provincia': 'Chaco' },
        { 'descripcion': 'SANTA ELVIRA', 'provincia': 'Chaco' },
        { 'descripcion': 'SANTA MARTA', 'provincia': 'Chaco' },
        { 'descripcion': 'SANTA SYLVINA', 'provincia': 'Chaco' },
        { 'descripcion': 'SANTA TERESA DE CARBALLO', 'provincia': 'Chaco' },
        { 'descripcion': 'SELVAS DEL RíO DE ORO', 'provincia': 'Chaco' },
        { 'descripcion': 'SIMBOLAR', 'provincia': 'Chaco' },
        { 'descripcion': 'SOLALINDE', 'provincia': 'Chaco' },
        { 'descripcion': 'TACO POZO', 'provincia': 'Chaco' },
        { 'descripcion': 'TAPENAGÁ , río', 'provincia': 'Chaco' },
        { 'descripcion': 'TERMAS DEL CERRITO', 'provincia': 'Chaco' },
        { 'descripcion': 'TIMBO', 'provincia': 'Chaco' },
        { 'descripcion': 'TOLDERIAS', 'provincia': 'Chaco' },
        { 'descripcion': 'TRES BOLICHES', 'provincia': 'Chaco' },
        { 'descripcion': 'TRES ESTACAS', 'provincia': 'Chaco' },
        { 'descripcion': 'TRES HORQUETAS', 'provincia': 'Chaco' },
        { 'descripcion': 'TRES ISLETAS', 'provincia': 'Chaco' },
        { 'descripcion': 'TRES MOJONES', 'provincia': 'Chaco' },
        { 'descripcion': 'TRES MONJES', 'provincia': 'Chaco' },
        { 'descripcion': 'TRES NACIONES', 'provincia': 'Chaco' },
        { 'descripcion': 'TRES PALMAS', 'provincia': 'Chaco' },
        { 'descripcion': 'TROPEZON', 'provincia': 'Chaco' },
        { 'descripcion': 'TUCA , arroyo', 'provincia': 'Chaco' },
        { 'descripcion': 'TUCURU', 'provincia': 'Chaco' },
        { 'descripcion': 'TUNALES', 'provincia': 'Chaco' },
        { 'descripcion': 'URIEN, ENRIQUE', 'provincia': 'Chaco' },
        { 'descripcion': 'VELAZ', 'provincia': 'Chaco' },
        { 'descripcion': 'VENADOS GRANDES', 'provincia': 'Chaco' },
        { 'descripcion': 'VENADOS GRANDES,EMB.', 'provincia': 'Chaco' },
        { 'descripcion': 'VENEZUELA', 'provincia': 'Chaco' },
        { 'descripcion': 'VIBORAS', 'provincia': 'Chaco' },
        { 'descripcion': 'VICENTINI', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA ALTA', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA ANGELA', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA BARBERAN', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA BERTHET', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA DOS', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA EL DORADO', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA EL PALMAR', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA FORESTACION', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA JALON', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA JUAN DE GARAY', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA LIBERTAD', 'provincia': 'Chaco' },
        { 'descripcion': 'VILLA RíO BERMEJITO', 'provincia': 'Chaco' },
        { 'descripcion': 'WELHERS', 'provincia': 'Chaco' },
        { 'descripcion': 'WICHI', 'provincia': 'Chaco' },
        { 'descripcion': 'YATAY', 'provincia': 'Chaco' },
        { 'descripcion': 'ZAPARINQUI', 'provincia': 'Chaco' },
        { 'descripcion': 'ZUBERBUHLER', 'provincia': 'Chaco' },
        { 'descripcion': '28 DE JULIO', 'provincia': 'Chubut' },
        { 'descripcion': 'AGUADA DE LAS TEJAS', 'provincia': 'Chubut' },
        { 'descripcion': 'AGUADA DEL PITO', 'provincia': 'Chubut' },
        { 'descripcion': 'AGUJA SUR , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'ALDEA', 'provincia': 'Chubut' },
        { 'descripcion': 'ALDEA APELEG', 'provincia': 'Chubut' },
        { 'descripcion': 'ALDEA BELEIRO', 'provincia': 'Chubut' },
        { 'descripcion': 'ALDEA ESCOLAR', 'provincia': 'Chubut' },
        { 'descripcion': 'ALTO DE LAS PLUMAS', 'provincia': 'Chubut' },
        { 'descripcion': 'ALTO RíO MAYO', 'provincia': 'Chubut' },
        { 'descripcion': 'ALTO RIO PICO', 'provincia': 'Chubut' },
        { 'descripcion': 'ALTO RíO SENGUER', 'provincia': 'Chubut' },
        { 'descripcion': 'ALTO RIO SENGUERR', 'provincia': 'Chubut' },
        { 'descripcion': 'ALVAREZ , caÑadÓn', 'provincia': 'Chubut' },
        { 'descripcion': 'AMEGHINO , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'ANGOSTURA SEGUNDA', 'provincia': 'Chubut' },
        { 'descripcion': 'APELEG', 'provincia': 'Chubut' },
        { 'descripcion': 'APELEG , arroyo', 'provincia': 'Chubut' },
        { 'descripcion': 'ARCE , isla', 'provincia': 'Chubut' },
        { 'descripcion': 'ARCHIBARCA, cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'ARENOSO', 'provincia': 'Chubut' },
        { 'descripcion': 'ARISTIZÁBAL , cabo', 'provincia': 'Chubut' },
        { 'descripcion': 'ARROYO CHALIA', 'provincia': 'Chubut' },
        { 'descripcion': 'ARROYO GATO', 'provincia': 'Chubut' },
        { 'descripcion': 'ARROYO GUILAIA', 'provincia': 'Chubut' },
        { 'descripcion': 'ARROYO PERCY', 'provincia': 'Chubut' },
        { 'descripcion': 'ARROYO PESCADO', 'provincia': 'Chubut' },
        { 'descripcion': 'ARROYO QUILLA', 'provincia': 'Chubut' },
        { 'descripcion': 'ASTRA', 'provincia': 'Chubut' },
        { 'descripcion': 'ATLAS , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'AVANZADO , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'AZUL , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'BAHíA BUSTAMANTE', 'provincia': 'Chubut' },
        { 'descripcion': 'BAHIA CRACHER', 'provincia': 'Chubut' },
        { 'descripcion': 'BAHIA SOLANO', 'provincia': 'Chubut' },
        { 'descripcion': 'BAJADA DEL DIABLO', 'provincia': 'Chubut' },
        { 'descripcion': 'BAJADA MORENO', 'provincia': 'Chubut' },
        { 'descripcion': 'BAJO BARTOLO', 'provincia': 'Chubut' },
        { 'descripcion': 'BAJO DE LOS HUESOS', 'provincia': 'Chubut' },
        { 'descripcion': 'BAJO DEL GUALICHO', 'provincia': 'Chubut' },
        { 'descripcion': 'BAJO LA CANCHA', 'provincia': 'Chubut' },
        { 'descripcion': 'BAJO LAS DAMAJUANAS', 'provincia': 'Chubut' },
        { 'descripcion': 'BARRIO ASTRA', 'provincia': 'Chubut' },
        { 'descripcion': 'BASE AERONAVAL ALMIRANTE ZAR', 'provincia': 'Chubut' },
        { 'descripcion': 'BAYO , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'BETESTA', 'provincia': 'Chubut' },
        { 'descripcion': 'BLANCAS , islas', 'provincia': 'Chubut' },
        { 'descripcion': 'BLANCO , lago', 'provincia': 'Chubut' },
        { 'descripcion': 'BOCA DE LA ZANJA', 'provincia': 'Chubut' },
        { 'descripcion': 'BOCA ZANJA SUD', 'provincia': 'Chubut' },
        { 'descripcion': 'BOTELLA OESTE , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'BRYN BROWN', 'provincia': 'Chubut' },
        { 'descripcion': 'BRYN GWYN', 'provincia': 'Chubut' },
        { 'descripcion': 'BUEN PASTO', 'provincia': 'Chubut' },
        { 'descripcion': 'BUENOS AIRES , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'BUENOS AIRES CHICO', 'provincia': 'Chubut' },
        { 'descripcion': 'BUSTAMANTE , bahía', 'provincia': 'Chubut' },
        { 'descripcion': 'CABANA DEL VALLE', 'provincia': 'Chubut' },
        { 'descripcion': 'CABO RASO', 'provincia': 'Chubut' },
        { 'descripcion': 'CACHEL', 'provincia': 'Chubut' },
        { 'descripcion': 'CACIQUE , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'CAJON DE GINEBRA CHICO', 'provincia': 'Chubut' },
        { 'descripcion': 'CAJON DE GINEBRA GRANDE', 'provincia': 'Chubut' },
        { 'descripcion': 'CALCATAPUL , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'CALETA CORDOBA', 'provincia': 'Chubut' },
        { 'descripcion': 'CALETA CORDOVA', 'provincia': 'Chubut' },
        { 'descripcion': 'CALETA VALDEZ', 'provincia': 'Chubut' },
        { 'descripcion': 'CAMARONES', 'provincia': 'Chubut' },
        { 'descripcion': 'CAMARONES , bahía', 'provincia': 'Chubut' },
        { 'descripcion': 'CAMPAMENTO I', 'provincia': 'Chubut' },
        { 'descripcion': 'CAMPAMENTO VILLEGAS', 'provincia': 'Chubut' },
        { 'descripcion': 'CAMPAMENTO VILLEGAS ,EST.', 'provincia': 'Chubut' },
        { 'descripcion': 'CAMPO ESCALANTE ,AP.', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADA BAGUAL', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON BAGUAL', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON BLANCO', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON CALIENTE', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON CARRIL', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON CHACAY', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON CHILENO', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON FERRAIS', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON GRANDE', 'provincia': 'Chubut' },
        { 'descripcion': 'CAÑADÓN GRANDE , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'CAÑADÓN IGLESIAS', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON LA MADERA', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON LAGARTO', 'provincia': 'Chubut' },
        { 'descripcion': 'CAÑADON LAGARTO ,EMB.', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON LOPEZ', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON PEDRO EX VALLE HERMOSO', 'provincia': 'Chubut' },
        { 'descripcion': 'CANADON TACHO', 'provincia': 'Chubut' },
        { 'descripcion': 'CANQUEL', 'provincia': 'Chubut' },
        { 'descripcion': 'CARHUE NIYEO', 'provincia': 'Chubut' },
        { 'descripcion': 'CARLOS AMEGHINO , istmo', 'provincia': 'Chubut' },
        { 'descripcion': 'CARRENLEUFU', 'provincia': 'Chubut' },
        { 'descripcion': 'CARRENLEUFÚ o CORCOVADO , río', 'provincia': 'Chubut' },
        { 'descripcion': 'CASA BLANCA', 'provincia': 'Chubut' },
        { 'descripcion': 'CASTRO , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'CATAICO', 'provincia': 'Chubut' },
        { 'descripcion': 'CATEDRAL , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'CERO , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'CERRO CENTINELA', 'provincia': 'Chubut' },
        { 'descripcion': 'CERRO CONDOR', 'provincia': 'Chubut' },
        { 'descripcion': 'CERRO DRAGÓN', 'provincia': 'Chubut' },
        { 'descripcion': 'CERRO FOFOCAHUEL', 'provincia': 'Chubut' },
        { 'descripcion': 'CERRO LONCO TRAPIAL', 'provincia': 'Chubut' },
        { 'descripcion': 'CERRO MALLACO', 'provincia': 'Chubut' },
        { 'descripcion': 'CERRO PICHALAO', 'provincia': 'Chubut' },
        { 'descripcion': 'CERRO RADAL', 'provincia': 'Chubut' },
        { 'descripcion': 'CERRO SANTA ANA', 'provincia': 'Chubut' },
        { 'descripcion': 'CHACAY ESTE', 'provincia': 'Chubut' },
        { 'descripcion': 'CHACAY OESTE', 'provincia': 'Chubut' },
        { 'descripcion': 'CHACRA DE AUSTIN', 'provincia': 'Chubut' },
        { 'descripcion': 'CHAIRA , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'CHALíA , arroyo', 'provincia': 'Chubut' },
        { 'descripcion': 'CHARQUE CHICO', 'provincia': 'Chubut' },
        { 'descripcion': 'CHASICO', 'provincia': 'Chubut' },
        { 'descripcion': 'CHATA , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'CHATO , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'CHENQUE , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'CHICO , río', 'provincia': 'Chubut' },
        { 'descripcion': 'CHICO , río', 'provincia': 'Chubut' },
        { 'descripcion': 'CHOLILA', 'provincia': 'Chubut' },
        { 'descripcion': 'CHUBUT , río', 'provincia': 'Chubut' },
        { 'descripcion': 'CLARA , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'COLAN CONHUE', 'provincia': 'Chubut' },
        { 'descripcion': 'COLANCONHUE', 'provincia': 'Chubut' },
        { 'descripcion': 'COLANG', 'provincia': 'Chubut' },
        { 'descripcion': 'COLELACHE', 'provincia': 'Chubut' },
        { 'descripcion': 'COLHUE HUAPI', 'provincia': 'Chubut' },
        { 'descripcion': 'COLHUÉ HUAPI , lago', 'provincia': 'Chubut' },
        { 'descripcion': 'COLONIA 16 DE OCTUBRE', 'provincia': 'Chubut' },
        { 'descripcion': 'COLONIA CUSHAMEN', 'provincia': 'Chubut' },
        { 'descripcion': 'COLONIA EPULIEF', 'provincia': 'Chubut' },
        { 'descripcion': 'COLONIA GERMANIA', 'provincia': 'Chubut' },
        { 'descripcion': 'COLORADA , laguna', 'provincia': 'Chubut' },
        { 'descripcion': 'COLORADO , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'COLORADO , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'COMODORO RIVADAVIA', 'provincia': 'Chubut' },
        { 'descripcion': 'COMPLEJO HIDROELÉCTRICO FUTALEUFÚ', 'provincia': 'Chubut' },
        { 'descripcion': 'CÓNICO , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'CONSCRIPTOS , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'CORCOVADO', 'provincia': 'Chubut' },
        { 'descripcion': 'COSTA CHUBUT', 'provincia': 'Chubut' },
        { 'descripcion': 'COSTA DEL LEPA', 'provincia': 'Chubut' },
        { 'descripcion': 'COSTA RIO CHICO', 'provincia': 'Chubut' },
        { 'descripcion': 'CRUZ , bahía', 'provincia': 'Chubut' },
        { 'descripcion': 'CUADRADA , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'CUCHE , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'CUSHAMEN', 'provincia': 'Chubut' },
        { 'descripcion': 'de AGNIA , laguna', 'provincia': 'Chubut' },
        { 'descripcion': 'de AMBARGASTA , salinas', 'provincia': 'Chubut' },
        { 'descripcion': 'de AMBARGASTA , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'de CHALíA , pampa', 'provincia': 'Chubut' },
        { 'descripcion': 'de CHAÑI , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'de ESQUEL , cordÓn', 'provincia': 'Chubut' },
        { 'descripcion': 'de GASTRE , pampa', 'provincia': 'Chubut' },
        { 'descripcion': 'de HUANCACHE , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'de LA TIERRA COLORADA , bajo', 'provincia': 'Chubut' },
        { 'descripcion': 'de LA VIRGEN , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'de LAS RUINAS , valle', 'provincia': 'Chubut' },
        { 'descripcion': 'de LONCO TRAPIAL , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'de LOS CHACAYS , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'de LOS GUANACOS , pampa', 'provincia': 'Chubut' },
        { 'descripcion': 'de MONTEMAYOR , meseta', 'provincia': 'Chubut' },
        { 'descripcion': 'de OLTE , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'de SACANANA , pampa', 'provincia': 'Chubut' },
        { 'descripcion': 'de SALAMANCA , pampa', 'provincia': 'Chubut' },
        { 'descripcion': 'de TALAGAPA , pampa', 'provincia': 'Chubut' },
        { 'descripcion': 'de TECKA , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'del CARMEN , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'del CASTILLO , pampa', 'provincia': 'Chubut' },
        { 'descripcion': 'del CHERQUE , cordÓn', 'provincia': 'Chubut' },
        { 'descripcion': 'del GUALICHO , bajo', 'provincia': 'Chubut' },
        { 'descripcion': 'del MOLLE , salina', 'provincia': 'Chubut' },
        { 'descripcion': 'del PITO , salina', 'provincia': 'Chubut' },
        { 'descripcion': 'DELFíN , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'DIADEMA ARGENTINA', 'provincia': 'Chubut' },
        { 'descripcion': 'DIQUE FLORENTINO AMEGHINO', 'provincia': 'Chubut' },
        { 'descripcion': 'DOCTOR RICARDO ROJAS', 'provincia': 'Chubut' },
        { 'descripcion': 'DOLAVON', 'provincia': 'Chubut' },
        { 'descripcion': 'DON BOSCO', 'provincia': 'Chubut' },
        { 'descripcion': 'DOS BAHíAS , cabo', 'provincia': 'Chubut' },
        { 'descripcion': 'DOS POZOS', 'provincia': 'Chubut' },
        { 'descripcion': 'EBENECER', 'provincia': 'Chubut' },
        { 'descripcion': 'EL ALAMO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL ARGENTINO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL BOQUETE', 'provincia': 'Chubut' },
        { 'descripcion': 'EL CANQUEL', 'provincia': 'Chubut' },
        { 'descripcion': 'EL CHACAY DPTO GASTRE', 'provincia': 'Chubut' },
        { 'descripcion': 'EL CHALET', 'provincia': 'Chubut' },
        { 'descripcion': 'EL CHERQUE', 'provincia': 'Chubut' },
        { 'descripcion': 'EL COIHUE', 'provincia': 'Chubut' },
        { 'descripcion': 'EL CORCOVADO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL COYTE', 'provincia': 'Chubut' },
        { 'descripcion': 'EL CRONOMETRO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL CUCHE', 'provincia': 'Chubut' },
        { 'descripcion': 'EL DESEMPENO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL ESCORIAL', 'provincia': 'Chubut' },
        { 'descripcion': 'EL JAGUEL', 'provincia': 'Chubut' },
        { 'descripcion': 'EL KAQUEL', 'provincia': 'Chubut' },
        { 'descripcion': 'EL MAITÉN', 'provincia': 'Chubut' },
        { 'descripcion': 'EL MAYOCO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL MIRASOL', 'provincia': 'Chubut' },
        { 'descripcion': 'EL MOLLE', 'provincia': 'Chubut' },
        { 'descripcion': 'EL PAJARITO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL PASTIZAL', 'provincia': 'Chubut' },
        { 'descripcion': 'EL PIQUILLIN', 'provincia': 'Chubut' },
        { 'descripcion': 'EL POYO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL QUILIMUAY', 'provincia': 'Chubut' },
        { 'descripcion': 'EL RUANO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL SALITRAL', 'provincia': 'Chubut' },
        { 'descripcion': 'EL SHAMAN', 'provincia': 'Chubut' },
        { 'descripcion': 'EL SOMBRERO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL TORDILLO', 'provincia': 'Chubut' },
        { 'descripcion': 'EL TRIANA', 'provincia': 'Chubut' },
        { 'descripcion': 'EL TROPEZON', 'provincia': 'Chubut' },
        { 'descripcion': 'EMBALSE FLORENTINO AMEGHINO', 'provincia': 'Chubut' },
        { 'descripcion': 'EMPALME A ASTRA', 'provincia': 'Chubut' },
        { 'descripcion': 'EMPALME PUERTO LOBOS', 'provincia': 'Chubut' },
        { 'descripcion': 'ENGAÑO , bahía', 'provincia': 'Chubut' },
        { 'descripcion': 'ENRIQUE HERMITTE', 'provincia': 'Chubut' },
        { 'descripcion': 'EPUYÉN', 'provincia': 'Chubut' },
        { 'descripcion': 'EPUYÉN , lago', 'provincia': 'Chubut' },
        { 'descripcion': 'ESCALANTE', 'provincia': 'Chubut' },
        { 'descripcion': 'ESQUEL', 'provincia': 'Chubut' },
        { 'descripcion': 'ESTANCIA EL MORO', 'provincia': 'Chubut' },
        { 'descripcion': 'ESTANCIA LA MIMOSA', 'provincia': 'Chubut' },
        { 'descripcion': 'ESTANCIA PAMPA CHICA', 'provincia': 'Chubut' },
        { 'descripcion': 'ESTE , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'FACUNDO', 'provincia': 'Chubut' },
        { 'descripcion': 'FITHEN VERIN', 'provincia': 'Chubut' },
        { 'descripcion': 'FITIRHUIN', 'provincia': 'Chubut' },
        { 'descripcion': 'FLORENTINO AMEGHINO', 'provincia': 'Chubut' },
        { 'descripcion': 'FONTANA , lago', 'provincia': 'Chubut' },
        { 'descripcion': 'FOTOCAHUEL', 'provincia': 'Chubut' },
        { 'descripcion': 'FRONTERA DE RIO PICO', 'provincia': 'Chubut' },
        { 'descripcion': 'FUTALAUFQUEN , lago', 'provincia': 'Chubut' },
        { 'descripcion': 'FUTALEUFU', 'provincia': 'Chubut' },
        { 'descripcion': 'GAIMAN', 'provincia': 'Chubut' },
        { 'descripcion': 'GAN GAN', 'provincia': 'Chubut' },
        { 'descripcion': 'GARAYALDE', 'provincia': 'Chubut' },
        { 'descripcion': 'GASTRE', 'provincia': 'Chubut' },
        { 'descripcion': 'GENERAL MOSCONI', 'provincia': 'Chubut' },
        { 'descripcion': 'GENERAL RACEDO , valle', 'provincia': 'Chubut' },
        { 'descripcion': 'GENERAL VINTTER , lago', 'provincia': 'Chubut' },
        { 'descripcion': 'GENOA , arroyo', 'provincia': 'Chubut' },
        { 'descripcion': 'GLASFRYN', 'provincia': 'Chubut' },
        { 'descripcion': 'GOBERNADOR COSTA', 'provincia': 'Chubut' },
        { 'descripcion': 'GRAN LAGUNA SALADA , laguna', 'provincia': 'Chubut' },
        { 'descripcion': 'GRAVINA , península', 'provincia': 'Chubut' },
        { 'descripcion': 'GUALJAINA', 'provincia': 'Chubut' },
        { 'descripcion': 'GUALJAINA , río', 'provincia': 'Chubut' },
        { 'descripcion': 'HÉRCULES , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'HERMITTE, ENRIQUE ,AP.', 'provincia': 'Chubut' },
        { 'descripcion': 'HERMOSO , valle', 'provincia': 'Chubut' },
        { 'descripcion': 'HITO 43', 'provincia': 'Chubut' },
        { 'descripcion': 'HITO 45', 'provincia': 'Chubut' },
        { 'descripcion': 'HITO 50', 'provincia': 'Chubut' },
        { 'descripcion': 'HOLDICH', 'provincia': 'Chubut' },
        { 'descripcion': 'HOYO DE EPUYEN', 'provincia': 'Chubut' },
        { 'descripcion': 'ING BRUNO J THOMAE', 'provincia': 'Chubut' },
        { 'descripcion': 'INGENIERO BRUNO J.THOMAE ,AP.', 'provincia': 'Chubut' },
        { 'descripcion': 'JOSÉ DE SAN MARTíN', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETR0 81 ,AP.', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETRO 11', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETRO 141', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETRO 161', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETRO 191 ,AP.', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETRO 192', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETRO 35', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETRO 78 ,AP.', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETRO 82 ,AP.', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETRO 87 ,AP.', 'provincia': 'Chubut' },
        { 'descripcion': 'KILOMETRO 95 ,AP.', 'provincia': 'Chubut' },
        { 'descripcion': 'LA BOMBILLA', 'provincia': 'Chubut' },
        { 'descripcion': 'LA CANCHA', 'provincia': 'Chubut' },
        { 'descripcion': 'LA CASTELLANA', 'provincia': 'Chubut' },
        { 'descripcion': 'LA CORONA', 'provincia': 'Chubut' },
        { 'descripcion': 'LA LANCHA', 'provincia': 'Chubut' },
        { 'descripcion': 'LA LAURITA', 'provincia': 'Chubut' },
        { 'descripcion': 'LA NICOLASA', 'provincia': 'Chubut' },
        { 'descripcion': 'LA PEPITA', 'provincia': 'Chubut' },
        { 'descripcion': 'LA PLATA , lago', 'provincia': 'Chubut' },
        { 'descripcion': 'LA ROSILLA', 'provincia': 'Chubut' },
        { 'descripcion': 'LA SALAMANCA', 'provincia': 'Chubut' },
        { 'descripcion': 'LA SIBERIA', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO AMUTUI QUIMEI', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO BLANCO', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO CARLOS PELLEGRINI', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO FONTANA', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO LEZAMA', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO MUSTERS', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO PAZ', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO PUELO', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO RIVADAVIA', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO ROSARIO', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO VERDE', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGO VINTTER', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGUNA DE VACAS', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGUNA DEL MATE', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGUNA FRIA', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGUNA GRANDE ,EMB.', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGUNA PALACIO', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGUNA RINCON DEL MORO', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGUNA TERRAPLEN', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGUNA VERDE', 'provincia': 'Chubut' },
        { 'descripcion': 'LAGUNITA SALADA', 'provincia': 'Chubut' },
        { 'descripcion': 'LANGUIÑEO', 'provincia': 'Chubut' },
        { 'descripcion': 'LARRALDE', 'provincia': 'Chubut' },
        { 'descripcion': 'LAS CHAPAS', 'provincia': 'Chubut' },
        { 'descripcion': 'LAS CORTADERAS', 'provincia': 'Chubut' },
        { 'descripcion': 'LAS GOLONDRINAS', 'provincia': 'Chubut' },
        { 'descripcion': 'LAS HORQUETAS', 'provincia': 'Chubut' },
        { 'descripcion': 'LAS MULAS', 'provincia': 'Chubut' },
        { 'descripcion': 'LAS PAMPAS', 'provincia': 'Chubut' },
        { 'descripcion': 'LAS PLUMAS', 'provincia': 'Chubut' },
        { 'descripcion': 'LAS PULGAS', 'provincia': 'Chubut' },
        { 'descripcion': 'LAS SALINAS', 'provincia': 'Chubut' },
        { 'descripcion': 'LEGUA 24', 'provincia': 'Chubut' },
        { 'descripcion': 'LELEQUE', 'provincia': 'Chubut' },
        { 'descripcion': 'LELEQUE , cordÓn', 'provincia': 'Chubut' },
        { 'descripcion': 'LENZANILLEO', 'provincia': 'Chubut' },
        { 'descripcion': 'LEONES , isla', 'provincia': 'Chubut' },
        { 'descripcion': 'LEPA', 'provincia': 'Chubut' },
        { 'descripcion': 'LOBOS , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'LOMA REDONDA', 'provincia': 'Chubut' },
        { 'descripcion': 'LONCO TRAPIAL , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'LOS ALTARES', 'provincia': 'Chubut' },
        { 'descripcion': 'LOS CIPRESES', 'provincia': 'Chubut' },
        { 'descripcion': 'LOS CORRALITOS', 'provincia': 'Chubut' },
        { 'descripcion': 'LOS TAMARISCOS', 'provincia': 'Chubut' },
        { 'descripcion': 'MAESTEG', 'provincia': 'Chubut' },
        { 'descripcion': 'MALASPINA', 'provincia': 'Chubut' },
        { 'descripcion': 'MALLIN BLANCO', 'provincia': 'Chubut' },
        { 'descripcion': 'MALLIN GRANDE', 'provincia': 'Chubut' },
        { 'descripcion': 'MALLIN GRANDE CORCOVADO', 'provincia': 'Chubut' },
        { 'descripcion': 'MANANTIAL GRANDE', 'provincia': 'Chubut' },
        { 'descripcion': 'MANANTIALES BERH', 'provincia': 'Chubut' },
        { 'descripcion': 'MATUCANA', 'provincia': 'Chubut' },
        { 'descripcion': 'MAYO , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'MAYO , río', 'provincia': 'Chubut' },
        { 'descripcion': 'MAYOCO', 'provincia': 'Chubut' },
        { 'descripcion': 'MENÉNDEZ , lago', 'provincia': 'Chubut' },
        { 'descripcion': 'MENTIRA , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'MINA CAMPAMENTO VILLEGAS', 'provincia': 'Chubut' },
        { 'descripcion': 'MOJÓN , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'MUSTERS , lago', 'provincia': 'Chubut' },
        { 'descripcion': 'NAHUEL PAN ESTACION FCGR', 'provincia': 'Chubut' },
        { 'descripcion': 'NEGRA , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'NEGRA , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'NEGRA , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'NEGRA , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'NEGRO , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'NEGRO , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'NEGRO , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'NEVADA , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'NINFAS , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'NIRIGUAO', 'provincia': 'Chubut' },
        { 'descripcion': 'NIRIGUCE PAMPA', 'provincia': 'Chubut' },
        { 'descripcion': 'NORQUINCO SUD', 'provincia': 'Chubut' },
        { 'descripcion': 'NORTE , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'NOVALES , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'NUEVA LUBECKA', 'provincia': 'Chubut' },
        { 'descripcion': 'NUEVO , golfo', 'provincia': 'Chubut' },
        { 'descripcion': 'ONETO , pico', 'provincia': 'Chubut' },
        { 'descripcion': 'Otra', 'provincia': 'Chubut' },
        { 'descripcion': 'OYARZÚN , boquete', 'provincia': 'Chubut' },
        { 'descripcion': 'PAINALUF', 'provincia': 'Chubut' },
        { 'descripcion': 'PALACIO , laguna', 'provincia': 'Chubut' },
        { 'descripcion': 'PAMPA DE AGNIA', 'provincia': 'Chubut' },
        { 'descripcion': 'PAMPA DE LAS LATAS o BERMEJO , salina', 'provincia': 'Chubut' },
        { 'descripcion': 'PAMPA DE LAS SALINAS , salina', 'provincia': 'Chubut' },
        { 'descripcion': 'PAMPA DEL CASTILLO', 'provincia': 'Chubut' },
        { 'descripcion': 'PAMPA PELADA', 'provincia': 'Chubut' },
        { 'descripcion': 'PAMPA SALAMANCA', 'provincia': 'Chubut' },
        { 'descripcion': 'PAMPA TEPUEL', 'provincia': 'Chubut' },
        { 'descripcion': 'PARAJE SACANANA', 'provincia': 'Chubut' },
        { 'descripcion': 'PARQUE NACIONAL LAGO PUELO', 'provincia': 'Chubut' },
        { 'descripcion': 'PARQUE NACIONAL LOS ALERCES', 'provincia': 'Chubut' },
        { 'descripcion': 'PASO DE INDIOS', 'provincia': 'Chubut' },
        { 'descripcion': 'PASO DE TORRES', 'provincia': 'Chubut' },
        { 'descripcion': 'PASO DEL SAPO', 'provincia': 'Chubut' },
        { 'descripcion': 'PASO MORENO', 'provincia': 'Chubut' },
        { 'descripcion': 'PASTOS BLANCOS', 'provincia': 'Chubut' },
        { 'descripcion': 'PATRIA , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'PELADA , pampa', 'provincia': 'Chubut' },
        { 'descripcion': 'PERDIDO , arroyo', 'provincia': 'Chubut' },
        { 'descripcion': 'PETROQUIMICA ,EST.COMFERPET', 'provincia': 'Chubut' },
        { 'descripcion': 'PICO , río', 'provincia': 'Chubut' },
        { 'descripcion': 'PICO DE SALAMANCA', 'provincia': 'Chubut' },
        { 'descripcion': 'PICO SALAMANCA', 'provincia': 'Chubut' },
        { 'descripcion': 'PIEDRA , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'PIEDRA PARADA', 'provincia': 'Chubut' },
        { 'descripcion': 'PIEDRA SHOTEL', 'provincia': 'Chubut' },
        { 'descripcion': 'PIRRE MAHUIDA', 'provincia': 'Chubut' },
        { 'descripcion': 'PLANCUNTRE', 'provincia': 'Chubut' },
        { 'descripcion': 'PLAYA UNIÓN', 'provincia': 'Chubut' },
        { 'descripcion': 'POCITOS DE QUICHAURA', 'provincia': 'Chubut' },
        { 'descripcion': 'PUELO , lago', 'provincia': 'Chubut' },
        { 'descripcion': 'PUENTE EL ENCUENTRO', 'provincia': 'Chubut' },
        { 'descripcion': 'PUENTE HENDRE', 'provincia': 'Chubut' },
        { 'descripcion': 'PUENTE INTERNACIONAL', 'provincia': 'Chubut' },
        { 'descripcion': 'PUERTO LOBOS', 'provincia': 'Chubut' },
        { 'descripcion': 'PUERTO MADRYN', 'provincia': 'Chubut' },
        { 'descripcion': 'PUERTO PIRÁMIDE', 'provincia': 'Chubut' },
        { 'descripcion': 'PUERTO PIRAMIDES', 'provincia': 'Chubut' },
        { 'descripcion': 'PUERTO RAWSON', 'provincia': 'Chubut' },
        { 'descripcion': 'PUERTO SAN ROMAN', 'provincia': 'Chubut' },
        { 'descripcion': 'PUERTO VISSER', 'provincia': 'Chubut' },
        { 'descripcion': 'PUNTA BAJOS', 'provincia': 'Chubut' },
        { 'descripcion': 'PUNTA DELGADA', 'provincia': 'Chubut' },
        { 'descripcion': 'PUNTA NINFAS', 'provincia': 'Chubut' },
        { 'descripcion': 'PUNTA NORTE', 'provincia': 'Chubut' },
        { 'descripcion': 'PUNTA QUIROGA', 'provincia': 'Chubut' },
        { 'descripcion': 'PUTRACHOIQUE', 'provincia': 'Chubut' },
        { 'descripcion': 'QUICHAURA o LANGUIÑEO , arroyo', 'provincia': 'Chubut' },
        { 'descripcion': 'QUIROGA , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'RADA TILLY', 'provincia': 'Chubut' },
        { 'descripcion': 'RANQUIL HUAO', 'provincia': 'Chubut' },
        { 'descripcion': 'RASO , cabo', 'provincia': 'Chubut' },
        { 'descripcion': 'RATONES , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'RAWSON', 'provincia': 'Chubut' },
        { 'descripcion': 'RIO CORINTO', 'provincia': 'Chubut' },
        { 'descripcion': 'RIO FRIAS', 'provincia': 'Chubut' },
        { 'descripcion': 'RíO MAYO', 'provincia': 'Chubut' },
        { 'descripcion': 'RíO PICO', 'provincia': 'Chubut' },
        { 'descripcion': 'ROJA , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'SACANANA', 'provincia': 'Chubut' },
        { 'descripcion': 'SALADO , caÑadÓn', 'provincia': 'Chubut' },
        { 'descripcion': 'SALAMANCA , pico', 'provincia': 'Chubut' },
        { 'descripcion': 'SALINA GRANDE , salina', 'provincia': 'Chubut' },
        { 'descripcion': 'SALINAS CHICAS', 'provincia': 'Chubut' },
        { 'descripcion': 'SALINAS GRANDES', 'provincia': 'Chubut' },
        { 'descripcion': 'SALINAS GRANDES , salinas', 'provincia': 'Chubut' },
        { 'descripcion': 'SAN BERNARDO , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'SAN JORGE , cabo', 'provincia': 'Chubut' },
        { 'descripcion': 'SAN JORGE , golfo', 'provincia': 'Chubut' },
        { 'descripcion': 'SAN JOSÉ , cabo', 'provincia': 'Chubut' },
        { 'descripcion': 'SAN JOSÉ , golfo', 'provincia': 'Chubut' },
        { 'descripcion': 'SAN MARTIN, JOSE DE', 'provincia': 'Chubut' },
        { 'descripcion': 'SANTA ANA , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'SANTA ELENA , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'SARMIENTO', 'provincia': 'Chubut' },
        { 'descripcion': 'SECA , laguna', 'provincia': 'Chubut' },
        { 'descripcion': 'SENGUER , río', 'provincia': 'Chubut' },
        { 'descripcion': 'SEPRUCAL', 'provincia': 'Chubut' },
        { 'descripcion': 'SHAMAN , arroyo', 'provincia': 'Chubut' },
        { 'descripcion': 'SIEMPRE VIVA', 'provincia': 'Chubut' },
        { 'descripcion': 'SIERRA CHATA', 'provincia': 'Chubut' },
        { 'descripcion': 'SIERRA CORRIENTES', 'provincia': 'Chubut' },
        { 'descripcion': 'SIERRA CUADRADA', 'provincia': 'Chubut' },
        { 'descripcion': 'SIERRA DE TECKA', 'provincia': 'Chubut' },
        { 'descripcion': 'SIERRA NEVADA', 'provincia': 'Chubut' },
        { 'descripcion': 'SIERRA NEVADA BUEN PASTO', 'provincia': 'Chubut' },
        { 'descripcion': 'SIERRA NEVADA PASO DE INDIOS', 'provincia': 'Chubut' },
        { 'descripcion': 'SIERRA OVERA CHICAS Y GRANDES', 'provincia': 'Chubut' },
        { 'descripcion': 'SIERRA ROSADA', 'provincia': 'Chubut' },
        { 'descripcion': 'SIERRA VICTORIA', 'provincia': 'Chubut' },
        { 'descripcion': 'SOLANO , bahía', 'provincia': 'Chubut' },
        { 'descripcion': 'STEFFEN , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'SUNICA', 'provincia': 'Chubut' },
        { 'descripcion': 'TALAGAPA', 'provincia': 'Chubut' },
        { 'descripcion': 'TAQUETREN', 'provincia': 'Chubut' },
        { 'descripcion': 'TAQUETRÉN , sierra', 'provincia': 'Chubut' },
        { 'descripcion': 'TATUEN', 'provincia': 'Chubut' },
        { 'descripcion': 'TECKA', 'provincia': 'Chubut' },
        { 'descripcion': 'TECKA , río', 'provincia': 'Chubut' },
        { 'descripcion': 'TELSEN', 'provincia': 'Chubut' },
        { 'descripcion': 'TOMA DE LOS CANALES', 'provincia': 'Chubut' },
        { 'descripcion': 'TOMBO , punta', 'provincia': 'Chubut' },
        { 'descripcion': 'TORO HOSCO', 'provincia': 'Chubut' },
        { 'descripcion': 'TOVA , isla', 'provincia': 'Chubut' },
        { 'descripcion': 'TRELEW', 'provincia': 'Chubut' },
        { 'descripcion': 'TREORKI', 'provincia': 'Chubut' },
        { 'descripcion': 'TRES PICOS , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'TREVELíN', 'provincia': 'Chubut' },
        { 'descripcion': 'TRISTE , monte', 'provincia': 'Chubut' },
        { 'descripcion': 'UZCUDUM', 'provincia': 'Chubut' },
        { 'descripcion': 'UZCUDÚN', 'provincia': 'Chubut' },
        { 'descripcion': 'VALDÉS , península', 'provincia': 'Chubut' },
        { 'descripcion': 'VALLE DEL RIO CHUBUT', 'provincia': 'Chubut' },
        { 'descripcion': 'VALLE DEL TECKA', 'provincia': 'Chubut' },
        { 'descripcion': 'VALLE FRIO', 'provincia': 'Chubut' },
        { 'descripcion': 'VALLE GARIN', 'provincia': 'Chubut' },
        { 'descripcion': 'VALLE HONDO', 'provincia': 'Chubut' },
        { 'descripcion': 'VALLE HUEMULES', 'provincia': 'Chubut' },
        { 'descripcion': 'VALLE LOS MARTIRES', 'provincia': 'Chubut' },
        { 'descripcion': 'VENTISQUERO SUR , cerro', 'provincia': 'Chubut' },
        { 'descripcion': 'VERA , bahía', 'provincia': 'Chubut' },
        { 'descripcion': 'VERDE , laguna', 'provincia': 'Chubut' },
        { 'descripcion': 'VILLA FUTALAUFQUEN', 'provincia': 'Chubut' },
        { 'descripcion': 'VILLA INES', 'provincia': 'Chubut' },
        { 'descripcion': 'CABA', 'provincia': 'Ciudad Autónoma de Buenos Aires' },
        { 'descripcion': 'COSTANERA SUR', 'provincia': 'Ciudad Autónoma de Buenos Aires' },
        { 'descripcion': 'JORGE NEWBERY AEROPARQUE', 'provincia': 'Ciudad Autónoma de Buenos Aires' },
        { 'descripcion': 'Otra', 'provincia': 'Ciudad Autónoma de Buenos Aires' },
        { 'descripcion': 'ABBURRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ACHIRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ACOLLARADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ACOSTILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ADELA MARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ADELIA MARíA', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUA DE CRESPIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUA DE LAS PIEDRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUA DE ORO', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUA DE TALA', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUA DEL TALA', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUA HEDIONDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUA PINTADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUA SACHA', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUADA DEL MONTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUAS DE RAMON', 'provincia': 'Córdoba' },
        { 'descripcion': 'AGUILA BLANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALCIRA , Est. Gigena', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALCIRA ESTACION GIGENA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALEJANDRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALEJANDRO ROCA , Est. Alejandro', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALEJANDRO ROCA ,EST. ALEJANDRO.', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALEJO LEDESMA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALGARROBO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALICIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALMAFUERTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALPA CORRAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALPAPUCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTA CORDOBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTA CORDOBA ,EST.', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTA GRACIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTAUTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO ALEGRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO CASTRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO DE CASTILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO DE FIERRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO DE LAS MULAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO DE LOS QUEBRACHOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO DE SAN PEDRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO DEL DURAZNO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO DEL TALA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO FRESCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO RESBALOSO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTO SAN PEDRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ALTOS DE CHIPIÓN', 'provincia': 'Córdoba' },
        { 'descripcion': 'AMBOY', 'provincia': 'Córdoba' },
        { 'descripcion': 'AMBUL', 'provincia': 'Córdoba' },
        { 'descripcion': 'ANA ZUMARAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'ANI MI', 'provincia': 'Córdoba' },
        { 'descripcion': 'ANTONIO CATALANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARBOL BLANCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARBOL CHATO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARBOLES BLANCOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARCOYO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARGUELLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'AROMITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYO ALGODÓN', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYO CABRAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYO DE ALVAREZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYO DEL PINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYO LA HIGUERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYO LOS PATOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYO SAN ANTONIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYO SANTA CATALINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYO SANTANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARROYO TOLEDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ARSENAL JOSE MARIA ROJAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ASCOCHINGA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ASCONCHINGA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ÁSPERO , cerro', 'provincia': 'Córdoba' },
        { 'descripcion': 'ASSUNTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ATAHONA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ATOS PAMPA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ATUMI PAMPA', 'provincia': 'Córdoba' },
        { 'descripcion': 'AUGUSTO VANDERSANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'AUSONIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'AVELLANEDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'B LUGONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAJO CHICO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAJO CHICO BAJO GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAJO DE FERNANDEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAJO DE GOMEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAJO DE OLMOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAJO DEL BURRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAJO DEL CARMEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAJO EL MOLINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAJO GALINDEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAJO LINDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BALBUENA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BALDE DE LA MORA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BALDE DE LA ORILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BALDE LINDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BALLESTEROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'BALLESTEROS SUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'BALLESTEROS SUR', 'provincia': 'Córdoba' },
        { 'descripcion': 'BALNEARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BALNEARIO GUGLIERI', 'provincia': 'Córdoba' },
        { 'descripcion': 'BANADO DE PAJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BAÑADO DE SOTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BANADO DEL FUERTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRANCA YACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRETO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIALITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO AMEGHINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO BELGRANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO DEAN FUNES', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO DEL LIBERTADOR', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO EMPALME', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO FLORES ,EMB.', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO LA FERIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO LA FORTUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO LOZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO MULLER', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO SAN LORENZO SUR', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO TTE BENJAMIN MATIE', 'provincia': 'Córdoba' },
        { 'descripcion': 'BARRIO VILLA UNION', 'provincia': 'Córdoba' },
        { 'descripcion': 'BELL VILLE', 'provincia': 'Córdoba' },
        { 'descripcion': 'BENGOLEA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BENJAMíN GOULD', 'provincia': 'Córdoba' },
        { 'descripcion': 'BERROTARÁN', 'provincia': 'Córdoba' },
        { 'descripcion': 'BEUCE', 'provincia': 'Córdoba' },
        { 'descripcion': 'BIALET MASSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'BLAS DE ROSALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'BO FLORES GUARNICION AEREA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BO OFICIAL Y SUBOFICIAL GE', 'provincia': 'Córdoba' },
        { 'descripcion': 'BOCA DEL RIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BOCA DEL TIGRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'BORDO DE LOS ESPINOSA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BOSQUE ALEGRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'BOUWER', 'provincia': 'Córdoba' },
        { 'descripcion': 'BRINKMANN', 'provincia': 'Córdoba' },
        { 'descripcion': 'BUCHARDO ,EST.HIPOLITO BOUCHARD', 'provincia': 'Córdoba' },
        { 'descripcion': 'BUEN RETIRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BUEN RETIRO ,AP.EL VADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BUENA VISTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'BUEY MUERTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'BULNES', 'provincia': 'Córdoba' },
        { 'descripcion': 'BURMEISTER', 'provincia': 'Córdoba' },
        { 'descripcion': 'CABALANGO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CABANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CABINDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CACHI YACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CACHIYULLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CACHIYUYO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CADETE CHAVEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAJON DEL RIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CALABALUMBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CALASUYA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CALCHIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'CALCHIN OESTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CALERA CENTRAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'CALMAYO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMILO ALDAO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMINIAGA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMINO A PUNTA DEL AGUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMOATI', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPAMENTO MINNETTI', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO ALVAREZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO AMBROGGIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO BANDILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO BEIRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO BOERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO BOURDICHON', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO CALVO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO COYUNDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO DE LA TORRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO DE LAS PIEDRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO GENERAL PAZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO LA LUISA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO LA PIEDRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO RAMALLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO ROSSIANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO SAN ANTONIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO SAN JUAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAMPO SOL DE MAYO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANA CRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA ANCHA SANTA ROSA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAÑADA DE ALVAREZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DE CORIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DE CUEVAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAÑADA DE JUME', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DE LAS CHACRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DE LAS GATIADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAÑADA DE LUQUE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAÑADA DE MACHADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DE MACHADO SUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DE MATEO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DE MAYO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DE POCHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAÑADA DE RIO PINTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAÑADA DE SALAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAÑADA DEL CORO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DEL DURAZNO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DEL PUERTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DEL SAUCE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DEL SIMBOL', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA DEL TALA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAÑADA HONDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA LARGA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA SAN ANTONIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADA VERDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADAS HONDAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANADON DE LOS MOGOTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANALS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANDELARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANDELARIA NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANDELARIA SUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANDONGA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANTERA LOS VIERAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANTERAS ALTA GRACIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANTERAS DE QUILPO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANTERAS EL MANZANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANTERAS EL SAUCE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANTERAS EL SAUCE ,EST.EMP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANTERAS IGUAZU', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANTERAS LA CALERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANTERAS LOS MORALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'CANTERAS QUILPO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAP GRAL BERNARDO O HIGINS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA DE COSME', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA DE DOLORES', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA DE LA SAGRADA FAMILIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA DE LOS REMEDIOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA DE ROMERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA DE SAN ANTONIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA DE SITON', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA DE TEGUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA DEL CARMEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA DEL MONTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA LA CANDELARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA LA ESPERANZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA SAN ANTONIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA SAN ANTONIO DE YUCAT', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPILLA SANTA ROSA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAPITAN GENERAL BERNARDO O HIGGINS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CARCANO, RAMON J.', 'provincia': 'Córdoba' },
        { 'descripcion': 'CARLOMAGNO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CARNERILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CARNERO YACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAROLINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAROYA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CARRILOBO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CARTABEROL', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASA BAMBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASA BLANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASA GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASA GRANDE ', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASA NUEVA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASA SERRANA HUERTA GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASAS VEJAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASAS VIEJAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASCADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASERIO LA LONJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASEROS ESTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASPICHUMA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASPICUCHANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASSAFFOUSTH ESTACION FCGB', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASSAFOUSTH', 'provincia': 'Córdoba' },
        { 'descripcion': 'CASTRO URDIALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAVALANGO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAVANAGH', 'provincia': 'Córdoba' },
        { 'descripcion': 'CAYUQUEO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CERRITOS DE ANIZACATE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CERRO BLANCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CERRO BOLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CERRO COLORADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CERRO DE LA CRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'CERRO DE ROSAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CERRO SAN LORENZO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CERROS ASPEROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHACHA DEL REY', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHACRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHACRAS DEL POTRERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHACRAS DEL SAUCE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHACRAS VIEJAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHAJÁN', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHALACEA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHALACEA CENTRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHAMICO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHAMPAQUí , cerro', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHAÑAR VIEJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHANARIACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHAÑARITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHANCANí', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHAQUINCHUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHARACATO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHARBONIER', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHARCAS NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHARRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHAZÓN', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHICA , sierra', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHILE CORRAL AL AGAUDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHILIBROSTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHILLI CORRAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHIPITIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHUCHIRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHUCUL', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHUÑA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHUÑA HUASI', 'provincia': 'Córdoba' },
        { 'descripcion': 'CHURQUI CAÑADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CIENAGA DE ALLENDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CIENAGA DE BRITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CIENAGA DEL CORO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CINTRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CNIA HOGAR VELEZ SARSFIELD', 'provincia': 'Córdoba' },
        { 'descripcion': 'CNIA VACACIONES DE EMPLEADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLAZO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA 10 DE JULIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA 25 LOS SURGENTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA ALEMANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA ALMADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA ANGELITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA ANITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA ARROYO DE ALVAREZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA BALLESTEROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA BANCO PCIA BS AS', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA BARGE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA BEIRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA BISMARCK', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA BOERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA BOTTURI', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA BREMEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA CALCHAQUI', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA CANADON', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA CAROYA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA CEFERINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA CORTADERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA COSME SUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA COYUNDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA CRISTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA DEAN FUNES', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA DEL BANCO NACION', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA DOROTEA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA DOS HERMANOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA EL CARMEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA EL CARMEN PARAJE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA EL CHAJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA EL FORTIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA EL MILAGRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA EL TRABAJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA EUGENIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA GARZON', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA GENERAL DEHEZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA GORCHS', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA HAMBURGO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA HOLANDESA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA ITALIANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA ITURRASPE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA ARGENTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA CALLE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA CARMENSITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA CELESTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA LEONCITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA LOLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA MAGDALENA DE ORO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA MOROCHA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA MURIUCHA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA PALESTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA PIEDRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA PRIMAVERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA PROVIDENCIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA TORDILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LA TRINCHERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LAS CUATRO ESQUINAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LAVARELLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LEDESMA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LOS VASCOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA LUQUE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA MACKINLAY', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA MAIPU', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA MALBERTINA ,EMB.KILOMETRO 531', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA MARINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA MASCHI', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA MAUNIER', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA MILESSI', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA MONTES NEGROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA NUEVO PIAMONTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA ORCOVI', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA PALO LABRADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA PASO CARRIL', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA PRODAMONTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA PROSPERIDAD', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA RIO CHICO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SAGRADA FAMILIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SAN BARTOLOME', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SAN IGANCIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SAN IGNACIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SAN ISIDRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SAN PEDRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SAN RAFAEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SANTA ANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SANTA CATALINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SANTA MARGARITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SANTA MARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SANTA PAULA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SANTA RITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA SILVIO PELLICO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA TIROLESA ,EST.', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA TIROLEZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA TORO PUJIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA UDINE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA VALLE GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA VALTELINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA VEINTICINCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA VICENTE AGUERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA VIDELA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA VIGNAUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA WALTELINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA YARETA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIA YUCAT SUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLONIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'COLUMBO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COME TIERRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COMECHINGONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'CONLARA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CONSTITUCION', 'provincia': 'Córdoba' },
        { 'descripcion': 'COPACABANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'COPINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORDOBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'Córdoba', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORIMAYO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORONEL BAIGORRIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORONEL MOLDES', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORONEL OLMEDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRAL DE BARRANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRAL DE BUSTOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRAL DE CEBALLOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRAL DE GOMEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRAL DE GUARDIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRAL DE MULAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRAL DEL BAJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRAL DEL REY', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRAL VIEJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRALITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORRALITO SAN JAVIER', 'provincia': 'Córdoba' },
        { 'descripcion': 'CORTES, JERONIMO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COSME', 'provincia': 'Córdoba' },
        { 'descripcion': 'COSQUíN', 'provincia': 'Córdoba' },
        { 'descripcion': 'COSTA ALEGRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COSTA DEL CASTANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COSTA DEL RIO QUINTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COSTA DEL TAMBO', 'provincia': 'Córdoba' },
        { 'descripcion': 'COSTA SACATE', 'provincia': 'Córdoba' },
        { 'descripcion': 'COTAGAITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CRAIK, JAMES', 'provincia': 'Córdoba' },
        { 'descripcion': 'CRISTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CRUZ ALTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CRUZ CHICA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CRUZ DE CAÑA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CRUZ DEL EJE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CRUZ DEL EJE ,EST.NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CRUZ DEL QUEMADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CRUZ GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'CRUZ MOJADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CUATRO CAMINOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CUATRO VIENTOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CUCHI CORRAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'CUCHILLA NEVADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CUCHILLO YACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'CUESTA BLANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'CUEVA DE INDIOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CUEVA DE LOS PAJARITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'CUMBRE DE ACHALA , cerro', 'provincia': 'Córdoba' },
        { 'descripcion': 'CUMBRE DE GASPAR , cerro', 'provincia': 'Córdoba' },
        { 'descripcion': 'CURAPALIGUE', 'provincia': 'Córdoba' },
        { 'descripcion': 'DALMACIO V. SARSFIELD', 'provincia': 'Córdoba' },
        { 'descripcion': 'DALMACIO VELEZ SARSFIELD', 'provincia': 'Córdoba' },
        { 'descripcion': 'de ACHALA , pampa', 'provincia': 'Córdoba' },
        { 'descripcion': 'de GUASAPAMPA , sierra', 'provincia': 'Córdoba' },
        { 'descripcion': 'de LA AMARGA , BaÑados', 'provincia': 'Córdoba' },
        { 'descripcion': 'de LA CRUZ , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'DE LA QUINTANA, JOSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'DE LA SERNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'de LAS BARRANCAS , arroyo', 'provincia': 'Córdoba' },
        { 'descripcion': 'de LAS BURRAS , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'de LOS ÁVALOS , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'de LOS PATOS , laguna', 'provincia': 'Córdoba' },
        { 'descripcion': 'de LOS REARTES , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'de LOS SAUCES , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'de LOS SAUCES , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'de LOS SAUCES o del INFIERNILLO , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'de POCHO , sierra', 'provincia': 'Córdoba' },
        { 'descripcion': 'de TREGUA , arroyo', 'provincia': 'Córdoba' },
        { 'descripcion': 'DEÁN FUNES', 'provincia': 'Córdoba' },
        { 'descripcion': 'DEL CAMPILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'DEMARCHI', 'provincia': 'Córdoba' },
        { 'descripcion': 'DESPENADEROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'DESPEÑADEROS , Est. Dr. Lucas A. de Olmos', 'provincia': 'Córdoba' },
        { 'descripcion': 'DESVIO CHALACEA', 'provincia': 'Córdoba' },
        { 'descripcion': 'DEVOTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'DIEGO DE ROJAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'DIEZ RIOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'DIQUE ARROYITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'DIQUE LA VINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'DIQUE LAS VAQUERIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'DIQUE LOS MOLINOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'DIQUE SAN ROQUE', 'provincia': 'Córdoba' },
        { 'descripcion': 'DOCTOR NICASIO SALAS OROÑO', 'provincia': 'Córdoba' },
        { 'descripcion': 'DOLORES', 'provincia': 'Córdoba' },
        { 'descripcion': 'DOLORES NUNEZ DEL PRADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'DOLORES SAN ESTEBAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'DOMINGO FUNES', 'provincia': 'Córdoba' },
        { 'descripcion': 'DOS LAGUNAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'DOS RIOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'DOS ROSAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'DUARTE QUIROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'DUMESNIL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL AGUILA BLANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ALCALDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ALGADOBAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ALGARROBAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ALGODONAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ARAÑADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ARBOL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL BAGUAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL BAJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL BALDECITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL BAÑADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL BARRIAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL BRETE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CADILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CALLEJON', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CARACOL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CARMEN GUINAZU', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CARRILITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CARRIZAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CARRIZAL CHUNAGUASI', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CHACHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CHANCHITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CHINGOLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CHIQUILLAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CORO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CRESTON DE PIEDRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL CRISPíN', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL DESCANSO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL DIQUECITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL DIQUECITO ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL DURAZNO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ESPINAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ESPINILLAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ESTANQUE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL FARO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL FLORENTINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL FLORIDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL FORTíN', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL FRANCES', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL FUERTECITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL GABINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL GALLEGO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL GATEADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL GUAICO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL GUANACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL GUINDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL IALITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL JORDAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL JUME', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL JUMIAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL MANANTIAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL MANGRULLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL MANZANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL MIRADOR', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL MOJONCITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL MOYANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL NOY', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL OCHENTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL OJO DE AGUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL OVERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PAMPERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PANAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PANTANILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PANTANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PARADOR DE LA MONTANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PASO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PASO DE LA PAMPA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PASTOR', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PAYADOR', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PAYADOR ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PEDACITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PERCHEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PERUEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PILCADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PONIENTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PORTENO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PORTEZUELO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PORTILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL POTOSI', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL POTRERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL POZO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PRADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PROGRESO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PUEBLITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PUENTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL PUESTO LOS CABRERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL QUEBRACHITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL QUEBRACHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL QUICHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL RANCHITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL RASTREADOR', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL RETIRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL REYMUNDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL RINCON', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL RIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL RODEITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ROSARIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL SALTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL SALTO NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL SAUCE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL SAUZAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL SEBIL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL SILVERIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL SIMBOL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL SIMBOLAR', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL SUNCHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TAJAMAR', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TALITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TALITA VILLA GRAL MITRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TAMBERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TAMBO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TíO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TOMILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TORREON', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TOSTADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TRABAJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TRIANGULO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TULE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL TUSCAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL VADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL VALLECITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL VALLESITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL VEINTICINCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL VENCE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL VISMAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ZAINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ZAINO ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'EL ZAPATO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ELCANO, SEBASTIAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'ELENA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EMBALSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EMBALSE CRUZ DEL EJE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EMBALSE DEL RíO TERCERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'EMBALSE INGENIERO A. MEDINA ALLENDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'EMBALSE LOS MOLINOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'EMBALSE MINISTRO JUAN PISTARINI', 'provincia': 'Córdoba' },
        { 'descripcion': 'EMPALME BARRIO FLORES', 'provincia': 'Córdoba' },
        { 'descripcion': 'ENCRUCIJADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ENFERMERA KELLY', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESCALANTE, WENCESLAO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESCOBAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESCUELA PARACAIDISTAS ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESPINILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESPINILLO NUNEZ DEL PRADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESQUINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESQUINA DEL ALAMBRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'Est. Villa. Santa Rosa', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTACIÓN ACHIRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTACION BELL VILLE', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTACION CALCHIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTACION CAROYA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTACION COLONIA TIROLESA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTACION GENERAL PAZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTACION PUNTA DE AGUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTACION SOTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA BOTTARO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA DE GUADALUPE', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA DOS RIOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA EL CARMEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA EL CHANAR', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA EL NACIONAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA EL TACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA GOROSITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA LA CHIQUITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA LA MOROCHA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA LA PUNTA DEL AGUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA LAS CANAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA LAS MARGARITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA LAS MERCEDES', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA LAS ROSAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA LOS MATORRALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'ESTANCIA PATINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ETRURIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'EUFRASIO LOZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'FABRICA MILITAR', 'provincia': 'Córdoba' },
        { 'descripcion': 'FABRICA MILITAR RIO TERCERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'FALDA DE LOS REARTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'FALDA DEL CARMEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'FERREYRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'FLORA', 'provincia': 'Córdoba' },
        { 'descripcion': 'FRAGUEIRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'FRAGUEYRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'FRAY CAYETANO RODRIGUEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'FREYRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'FUNES, DOMINGO ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'FUNES, PEDRO E.', 'provincia': 'Córdoba' },
        { 'descripcion': 'GALPON CHICO', 'provincia': 'Córdoba' },
        { 'descripcion': 'GARCIA, RAFAEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'GAVILAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'GAVILAN ,EST.', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL BALDISSERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL CABRERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL DEHEZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL FOTHERINGHAM', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL LAS HERAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL LEVALLE', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL ORTIZ DE OCAMPO', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL ORTIZ DE OCAMPO ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL PAZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL PUEYRREDON', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL ROCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL SOLER', 'provincia': 'Córdoba' },
        { 'descripcion': 'GENERAL VIAMONTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'GIGENA ALCIRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'GIGENA ESTACION', 'provincia': 'Córdoba' },
        { 'descripcion': 'GLORIALDO FERNANDEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'GOLPE DE AGUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'GOULD, BENJAMIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'GRACIELA', 'provincia': 'Córdoba' },
        { 'descripcion': 'GRANDE , sierra', 'provincia': 'Córdoba' },
        { 'descripcion': 'GRUTA DE SAN ANTONIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'GUALLASCATE', 'provincia': 'Córdoba' },
        { 'descripcion': 'GUANACO BOLEADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'GUANACO MUERTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'GUARDIA VIEJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'GUASAPAMPA', 'provincia': 'Córdoba' },
        { 'descripcion': 'GUASTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'GUATIMOZíN', 'provincia': 'Córdoba' },
        { 'descripcion': 'GUIÑAZU', 'provincia': 'Córdoba' },
        { 'descripcion': 'GUINDAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'GUTEMBERG', 'provincia': 'Córdoba' },
        { 'descripcion': 'HARAS SAN ANTONIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'HARAS SANTA MARTHA', 'provincia': 'Córdoba' },
        { 'descripcion': 'HERNANDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'HIGUERIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'HIGUERILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'HIPÓLITO BOUCHARD', 'provincia': 'Córdoba' },
        { 'descripcion': 'HOLMBERG', 'provincia': 'Córdoba' },
        { 'descripcion': 'HOSPITAL FLIA DOMINGO FUNES', 'provincia': 'Córdoba' },
        { 'descripcion': 'HUANCHILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'HUANCHILLA SUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'HUANCHILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'HUASCHA', 'provincia': 'Córdoba' },
        { 'descripcion': 'HUASTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'HUCLE', 'provincia': 'Córdoba' },
        { 'descripcion': 'HUERTA GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'HUINCA RENANCÓ', 'provincia': 'Córdoba' },
        { 'descripcion': 'ICHO CRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'IDIAZÁBAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'IGLESIA VIEJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'IMPIRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'INDIA MUERTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'INGENIERO BERTINI', 'provincia': 'Córdoba' },
        { 'descripcion': 'INGENIERO MALMEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'INRIVILLE', 'provincia': 'Córdoba' },
        { 'descripcion': 'INVERNADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ISCHILIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'ISCHILIN VIEJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ISLA DE SAN ANTONIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ISLA DEL CERRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ISLA LARGA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ISLA VERDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'ISLETA NEGRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ITALÓ', 'provincia': 'Córdoba' },
        { 'descripcion': 'ITI HUASI', 'provincia': 'Córdoba' },
        { 'descripcion': 'JAIME PETER', 'provincia': 'Córdoba' },
        { 'descripcion': 'JAMES CRAIK', 'provincia': 'Córdoba' },
        { 'descripcion': 'JARILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'JEANMAIRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'JERONIMO CORTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'JESÚS MARíA', 'provincia': 'Córdoba' },
        { 'descripcion': 'JOSÉ DE LA QUINTANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'JOVITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'JUAN GARCIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'JUAREZ CELMAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'JUAREZ, MARCOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'JULIO ARGENTINO ROCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'JUME', 'provincia': 'Córdoba' },
        { 'descripcion': 'JUMÉ , laguna', 'provincia': 'Córdoba' },
        { 'descripcion': 'JUSTINIANO POSSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILEGRUMAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 1,5 AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 136 ,EMP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 16', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 182 ,DV.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 23', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 25', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 271 ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 316 ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 394 ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 466 ,DV.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 480 ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 541 ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 563 ,DV.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 57 ,EST.EMB.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 579 ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 581 ,EMB.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 608 ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 618 ,DV.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 636 ,DV.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 651 ,DV.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 679', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 691 ,DV.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 7', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 730 ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 745', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 859 ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 865 ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 881 ,EMB.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 9', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 907 ,EST.', 'provincia': 'Córdoba' },
        { 'descripcion': 'KILOMETRO 931', 'provincia': 'Córdoba' },
        { 'descripcion': 'L A PROVIDENCIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ABRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA AGUADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ARABIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ARGENTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA AURA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA BARRANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA BARRANQUITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA BATALLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA BATEA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA BETANIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA BISMUTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA BOTIJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA BRIANZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA BUENA PARADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA BUENA PARADA ,EST.COMECHINGONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CAJUELA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CALERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CALERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CAÑADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CANADA ANGOSTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CANADA GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CANADA SANTA CRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CANTERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CARBONADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CARBONERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CARLOTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CASCADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CAUTIVA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CELINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CESIRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CHACRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CHICHARRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CHOZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA COLONIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA COLORADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA COMPASION', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CONCEPCION', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CORTADERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA COTITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CUESTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CUMBRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CUMBRECITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA CURVA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA DORA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ESPERANZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ESQUINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ESTACADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ESTANCIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ESTANCITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA FALDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA FALDA DEL CARMEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA FRANCIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA FRONDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA FUENTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA GILDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA GRAMILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA GRANADILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA GRANJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA HERRADURA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA HIGUERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA HIGUERITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ISABELA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ISOLINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ITALIANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA JUANITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA LAGUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA LAGUNILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA LILIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA LINEA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA LUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA MAJADILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA MAZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA MERCANTIL', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA MESADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA MESILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA MILKA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA MOSTAZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA MUDANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA NACIONAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA OSCURIDAD', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PAISANITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PALESTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PALMA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PALMERINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PAMPA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PAQUITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PAQUITA ,EST.PRESIDENTE F.ALCORTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PARA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PATRIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PAZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PENCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PERLITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PIEDRA BLANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PIEDRA MOVEDIZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PINTADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PLAYA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PLAYOSA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PLAZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA POBLACION', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA POBLADORA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PORFIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA POSTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA POSTA CHUNAGUAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PUERTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PUERTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA PUERTA VILLA DE SOTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA QUEBRADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA QUINTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA QUINTANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA RAMADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA RAMONCITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA REDENCION', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA REINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA REPRESA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA RINCONADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA RINCONADA CANDELARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ROSARINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA RUDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA SELVA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA SERRANITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA SIENA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA SIERRITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA SOLEDAD', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA TABLADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA TABLADA ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA TIGRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA TOMA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA TORDILLA NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA TOTORILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA TRAVESIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA TRINCHERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA TUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA TUNA TINOCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA UDINE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA USINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA VENTANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA VERONICA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA VICENTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA VICTORIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA VIRGINIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ZARA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LA ZARITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LABORDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LABOULAYE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LADERA YACUS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAGUNA CLARA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAGUNA DE GOMEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAGUNA DEL MONTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAGUNA DEL SUNCHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAGUNA LARGA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAGUNA LARGA SUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAGUNA OSCURA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAGUNA SECA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAGUNILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAGUNILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LARSEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS ABAHACAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS ACACIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS ACEQUIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS AGUADITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS ALERAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS AROMAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS ARRIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS ASTILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS AVERIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS BAJADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS BANDURRIAS NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CABRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CALECITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CALERAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CALLES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CANADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CAÑITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CANTERAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CARDAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CASITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CEBOLLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CHACRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CINCO CUADRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CONANITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CORTADERAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CRUCECITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CUATRO ESQUINAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS CUSENADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS ENCADENADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS ENCRUCIJADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS ENSENADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS GAMAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS GEMELAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS GRAMILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS GUINDAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS HIGUERAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS HIGUERILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS HIGUERITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS HILERAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS HORQUETAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS ISLETILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS JARILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS JUNTURAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS LAGUNITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS LATAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS MANZANAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS MASITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS MERCEDITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS MOJARRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS MORAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS MOSTAZAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS OSCURAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS OVERIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PALMAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PALOMITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PAMPILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PEÑAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PENAS NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PENAS SUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PENCAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PERDICES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PICHANAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PIEDRAS ANCHAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PIGUAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PLAYAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS PLAYAS LOZADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS QUINTAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS RABONAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS SALADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS SESENTA CUADRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS SIERRITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS TAPIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS TINAJERAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS TOSCAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS TOTORITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS TRANCAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS TRES PIEDRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS TUSCAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS VARAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS VARILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS VERTIENTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LAS VERTIENTES DE LA GRANJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LASPIUR, SATURNINO M.', 'provincia': 'Córdoba' },
        { 'descripcion': 'LATAN HALL', 'provincia': 'Córdoba' },
        { 'descripcion': 'LECUEDER', 'provincia': 'Córdoba' },
        { 'descripcion': 'LEDESMA, ALEJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'LEGUIZAMÓN', 'provincia': 'Córdoba' },
        { 'descripcion': 'LEONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LO MACHADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOA ALGARROBOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOBERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOMA BOLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOMA DE PIEDRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOMA REDONDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOMAS DE SAN JOSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOMAS DEL TROZO', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOMITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS ALAMOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS ALFALFARES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS ALGARROBITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS ALGARROBOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS ALTOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS ALVAREZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS AVILES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS BARRIALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS BORDOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS BOULEVARES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS BRINZES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CADILLOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CAJONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CALLEJONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CASTANOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CEDROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CEJAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CERRILLOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CERROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CERROS NEGROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CHAÑARES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CHAÑARITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CIGARRALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CISNES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS COCOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS COMETIERRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS CÓNDORES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS COQUITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS DESAGUES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS DOS POZOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS DOS RIOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS DURAZNOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS ESLABONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS GAUCHOS DE GUEMES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS GIGANTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS GIGANTES , cerro', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS GUEVARA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS GUINDOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS HORMIGUEROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS HORNILLOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS HOYOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS HUESOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS JAGUELES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS JUSTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MANANTIALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MANGUITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MANSILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MEDANITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MEDANOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MIGUELITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MIQUILES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MISTOLES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MISTOLES , laguna', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MOLINOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MOLLES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS MORTERITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS OJOS DE AGUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS OLIVARES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS PANTALLES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS PANTANILLOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS PARAISOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS PAREDONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS PEDERNALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS PIQUILLINES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS POCITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS PORONGOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS POTREROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS POZOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS POZOS ,AP.KILOMETRO 827', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS PUENTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS PUESTITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS QUEBRACHITOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS QUEBRACHOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS REARTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS REYUNOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS RUICES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS SAUCES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS SOCABONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS SURGENTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS TAJAMARES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS TARTAGOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS TARTAROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS TASIS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS TERRONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS TRES POZOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS TRONCOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS UCLES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS VALDES', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS VAZQUEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOS ZORROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'LOZADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LUCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LUCIO V MANSILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LUCIO V. MANSILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'LUGONES, LEOPOLDO ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'LUIS SAUZE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LUQUE', 'provincia': 'Córdoba' },
        { 'descripcion': 'LUTTI', 'provincia': 'Córdoba' },
        { 'descripcion': 'LUXARDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'LUYABA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MACHA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MAGIGASTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MAJADA DE SANTIAGO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MAJADILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MALAGUENO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MALENA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MALLIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'MALVINAS ARGENTINAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'MALVINAS ARGENTINAS ,EST.KILOMETRO 711', 'provincia': 'Córdoba' },
        { 'descripcion': 'MANANTIALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'MANDALA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MANFREDI', 'provincia': 'Córdoba' },
        { 'descripcion': 'MANGUITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'MANSILLA, LUCIO V.', 'provincia': 'Córdoba' },
        { 'descripcion': 'MAQUINISTA GALLINI', 'provincia': 'Córdoba' },
        { 'descripcion': 'MAR AZUL', 'provincia': 'Córdoba' },
        { 'descripcion': 'MARCOS JUÁREZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'MARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MARIA LASTENIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MARULL', 'provincia': 'Córdoba' },
        { 'descripcion': 'MATACOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'MATORRALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'MATTALDI', 'provincia': 'Córdoba' },
        { 'descripcion': 'MAUNIER', 'provincia': 'Córdoba' },
        { 'descripcion': 'MAYU SUMAJ', 'provincia': 'Córdoba' },
        { 'descripcion': 'MEDIA LUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MEDIA NARANJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MEDIO NARANJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MELIDEO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MELO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MENDIOLAZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MENDIOLAZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MESA DE MARIANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MESILLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'MI GRANJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MI VALLE', 'provincia': 'Córdoba' },
        { 'descripcion': 'MIGUEL SALAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'MIGUELITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MINA ARAUJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MINA CLAVERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MINA LA BISMUTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MIQUILOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'MIRAMAR', 'provincia': 'Córdoba' },
        { 'descripcion': 'MODESTINO PIZARRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MODESTO ACUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MOGIGASTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MOGOTE VERDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'MOLINARI', 'provincia': 'Córdoba' },
        { 'descripcion': 'MOLINARI', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE BUEY', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE CASTILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE CRISTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE DE LOS GAUCHOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE DE LOS LAZOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE DE TORO PUJIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE DEL FRAYLE', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE DEL ROSARIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE GRANDE RAFAEL GARCIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE LA INVERNADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE LEÑA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE MAíZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE RALO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE REDONDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTE REDONDO ,EST.', 'provincia': 'Córdoba' },
        { 'descripcion': 'MONTECRISTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MORRISON', 'provincia': 'Córdoba' },
        { 'descripcion': 'MORTEROS', 'provincia': 'Córdoba' },
        { 'descripcion': 'MOVADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'MULA MUERTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'MUSSI', 'provincia': 'Córdoba' },
        { 'descripcion': 'NARVAJA, TRISTAN ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'NAZCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'NEGRO HUASI', 'provincia': 'Córdoba' },
        { 'descripcion': 'NICHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'NICOLÁS BRUZONE', 'provincia': 'Córdoba' },
        { 'descripcion': 'NIDO DEL AGUILA', 'provincia': 'Córdoba' },
        { 'descripcion': 'NIKES , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'NINA PAULA', 'provincia': 'Córdoba' },
        { 'descripcion': 'NINALQUIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'NINTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'NOETINGER', 'provincia': 'Córdoba' },
        { 'descripcion': 'NONO', 'provincia': 'Córdoba' },
        { 'descripcion': 'NU PORA', 'provincia': 'Córdoba' },
        { 'descripcion': 'NUEVA ALDALUCIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'NUEVA ANDALUCIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'NUÑEZ DEL ORADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'NUÑEZ DEL PRADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'OBISPO TREJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'OBREGON', 'provincia': 'Córdoba' },
        { 'descripcion': 'OJO DE AGUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'OLAEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'OLAETA', 'provincia': 'Córdoba' },
        { 'descripcion': 'OLIVA', 'provincia': 'Córdoba' },
        { 'descripcion': 'OLIVARES SAN NICOLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'OLMOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'ONAGOITY', 'provincia': 'Córdoba' },
        { 'descripcion': 'ONCATIVO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ONGAMIRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ORATORIO DE PERALTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ORCOSUNI', 'provincia': 'Córdoba' },
        { 'descripcion': 'ORDOÑEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'ORO GRUESO', 'provincia': 'Córdoba' },
        { 'descripcion': 'Otra', 'provincia': 'Córdoba' },
        { 'descripcion': 'OVERA NEGRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PACHANGO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PACHECO DE MELO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PAJAS BLANCAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PAJONAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'PALO CORTADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PALO PARADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PALO QUEMADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PALOMA POZO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PAMPA DE ACHALA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PAMPA DE OLAEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'PAMPAYASTA NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'PAMPAYASTA SUR', 'provincia': 'Córdoba' },
        { 'descripcion': 'PAN DE AZUCAR ,AP.KILOMETRO 592', 'provincia': 'Córdoba' },
        { 'descripcion': 'PANAHOLMA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PARQUE SIQUIMAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASCANAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO CABRAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO CASTELLANOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO DE LOS GALLEGOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO DE MONTOYA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO DE VELEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO DEL DURAZNO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO DEL RIO SECO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO DEL SAUCE', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO DEL SILVERIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO LAS TROPAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO SANDIALITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASO VIEJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PASTOS ALTOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PAUNERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PAVIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'PEDRO E FUNES', 'provincia': 'Córdoba' },
        { 'descripcion': 'PEDRO E VIVAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PEGASANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PELLICO, SILVIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PERMANENTES', 'provincia': 'Córdoba' },
        { 'descripcion': 'PETER, JAIME', 'provincia': 'Córdoba' },
        { 'descripcion': 'PICHANAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRA BLANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRA GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRA MOVEDIZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRA PINTADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRAS AMONTONADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRAS ANCHAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRAS BLANCAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRAS BLANCAS , arroyo', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRAS GRANDES', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRITA BLANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIEDRITAS ROSADAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PILAR', 'provincia': 'Córdoba' },
        { 'descripcion': 'PINAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PINCEN', 'provincia': 'Córdoba' },
        { 'descripcion': 'PINTOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PINTOS , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIQUILLIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'PISCO HUASI', 'provincia': 'Córdoba' },
        { 'descripcion': 'PITOA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PIZARRO, MODESTINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLAYA GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLAZA BRUNO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLAZA COLAZO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLAZA DE MERCEDES', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLAZA DEHEZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLAZA LUXARDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLAZA MINETTI', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLAZA RODRIGUEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLAZA SAN FRANCISCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLAZA VIDELA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PLUJUNTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'POCHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'POCITO DEL CAMPO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PORTEÑA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PORTEÑA', 'provincia': 'Córdoba' },
        { 'descripcion': 'POSSE, JUSTINIANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'POTRERO DE GARAY', 'provincia': 'Córdoba' },
        { 'descripcion': 'POTRERO DE LUJAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'POTRERO DE MARQUES', 'provincia': 'Córdoba' },
        { 'descripcion': 'POTRERO DE TUTZER', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO CONCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO CORREA', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DE JUANCHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DE LA LOMA', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DE LA PAMPA', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DE LAS OLLAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DE LAS YEGUAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DE LOS ARBOLES', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DE LOS TRONCOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DE MOLINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DEL AVESTRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DEL BARRIAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DEL CHAJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DEL CHAÑAR', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DEL MOLLE', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DEL MORO', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DEL SIMBOL', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO DEL TIGRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO LA PIEDRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO SECO', 'provincia': 'Córdoba' },
        { 'descripcion': 'POZO SOLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PRETOT FREYRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'PRIMAVERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUEBLO ALBERDI', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUEBLO ARGENTINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUEBLO CARLOS SAUVERAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUEBLO GAMBANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUEBLO ITALIANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUEBLO PIANELLI', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUEBLO RIO TERCERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUEBLO SARMIENTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUEBLO VIEJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUENTE LOS MOLLES', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUENTE RIO PLUJUNTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUERTA COLORADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUERTA DE LA QUEBRADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE AFUERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE AFUERA ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE ARRIBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE BATALLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE CASTRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE CERRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE FIERRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE LA OVEJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE LOS ALAMOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE LOS RODRIGUEZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE LUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE PUCHETA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DE VERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DEL GALLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DEL MEDIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO DEL ROSARIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO GUZMAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO MULITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO NUEVO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO PUCHETA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO SAN JOSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUESTO VIEJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUNILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUNILLA ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUNTA DEL AGUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUNTA DEL MONTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'PUSISUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUEBRACHO HERRADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUEBRACHO LADEADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUEBRACHO SOLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUEBRACHOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUEBRADA DE LOS POZOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUEBRADA DE LUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUEBRADA DE NONA', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUEBRADA DEL HORNO', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUILINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUILLINZO , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUISCASACATE', 'provincia': 'Córdoba' },
        { 'descripcion': 'QUISQUIZACATE ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'RACEDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RAFAEL GARCíA', 'provincia': 'Córdoba' },
        { 'descripcion': 'RAMIREZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'RAMON J CARCANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RAMÓN J. CÁRCANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RANGEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'RANQUELES', 'provincia': 'Córdoba' },
        { 'descripcion': 'RARA FORTUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'RAYO CORTADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RECREO VICTORIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'REDUCCION', 'provincia': 'Córdoba' },
        { 'descripcion': 'REPRESA DE MORALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'RINCON', 'provincia': 'Córdoba' },
        { 'descripcion': 'RINCON CASA GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'RINCON DE LUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO ARRIBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO BAMBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'RíO CEBALLOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'RíO CUARTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO CUARTO NORTE ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO DE JAIME', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO DE LA POBLACION', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO DE LAS MANZANAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO DE LOS MOLINOS ,PDA.', 'provincia': 'Córdoba' },
        { 'descripcion': 'RíO DE LOS SAUCES', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO DE LOS TALAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO DEL DURAZNO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO DULCE', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO GRANDE AMBOY', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO HONDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO LOS MOLINOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO PEDRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO PINTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RíO PRIMERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO SAN MIGUEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'RíO SEGUNDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RíO TERCERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIO VIEJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RIVERA INDARTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'ROCA, ALEJANDRO ,EST.ALEJANDRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RODEITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RODEO DE LOS CABALLOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'RODEO DE PIEDRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'RODEO GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'RODEO LAS YEGUAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'RODEO VIEJO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RODRIGUEZ DEL BUSTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ROJAS, DIEGO DE', 'provincia': 'Córdoba' },
        { 'descripcion': 'ROSALES', 'provincia': 'Córdoba' },
        { 'descripcion': 'ROSALES, BLAS DE', 'provincia': 'Córdoba' },
        { 'descripcion': 'RUIZ DíAZ DE GUZMÁN', 'provincia': 'Córdoba' },
        { 'descripcion': 'RUMIACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'RUMIGUASI', 'provincia': 'Córdoba' },
        { 'descripcion': 'RUMIYACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SACANTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAGRADA FAMILIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAGUION', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAIRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAJON', 'provincia': 'Córdoba' },
        { 'descripcion': 'SALADILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SALADILLO , baÑado del río', 'provincia': 'Córdoba' },
        { 'descripcion': 'SALADILLO , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'SALAS, MIGUEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'SALDAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'SALDAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'SALGUERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SALSACATE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SALSIPUEDES', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAMPACHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN AGUSTíN', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN AMBROSIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ANTINIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ANTONIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ANTONIO DE ARREDONDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ANTONIO DE BELLA VISTA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ANTONIO DE LITíN', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ANTONIO DE YUCAT', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ANTONIO NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN BARTOLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN BARTOLOME', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN BASILIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN BUENAVENTURA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN BUENAVENTURA ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN CARLOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN CARLOS MINAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN CLEMENTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ESTEBAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN EUSEBIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN FERNANDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN FRANCISCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN FRANCISCO , cerro', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN FRANCISCO , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN FRANCISCO , río', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN FRANCISCO DEL CHAÑAR', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN GERONIMO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN IGNACIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN JAVIER', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN JERONIMO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN JOAQUIN', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN JOSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN JOSÉ DE LA DORMIDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN JOSÉ DE LAS SALINAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN JOSE DE LAS SALINAS ,EST.SAN JOSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN JOSE DEL SALTEÑO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN LORENZO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN LUCAS NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN LUIS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN MARCOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN MARCOS SIERRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN MARCOS SUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN MELITON', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN MIGUEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN MIGUEL CHANCANI', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN MIGUEL SAN VICENTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN PEDRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN PEDRO DE TOYOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN PEDRO NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN PELLEGRINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN RAFAEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ROQUE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ROQUE , lago', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN ROQUE LAS ARRIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN SEVERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAN VICENTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANABRIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA ANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA CATALINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA CATALINA , Est. Holmberg', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA CECILIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA CRISTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA CRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA EUFEMIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA ISABEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA LUCíA o de LAS MOJARRAS , laguna', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA MAGDALENA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA MAGDALENA , Est. Jovita', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA MARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA MARIA DE PUNILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA MARIA DE PUNILLA ,EST.SANTA MARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA MARIA DE SOBREMONTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA MONICA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA ROSA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA ROSA DE CALAMUCHITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA ROSA DE RIO PRIMERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA ROSA DE RIO PRIMERO ,EST.V.S.ROSA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA ROSA DEL CONLARA ,', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA ROSA HUERTA GRANDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA SABINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA TERESA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTA VICTORIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTANILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTIAGO TEMPLE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SANTO TOMAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAPANSOTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SARLACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SARMICA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SARMIENTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SATURNINO M LASPIUR', 'provincia': 'Córdoba' },
        { 'descripcion': 'SATURNINO M. LASPIUR', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAUCE ARRIBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAUCE CHIQUITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAUCE DE LOS QUEVEDOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SAUCE PUNCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SEBASTIÁN EL CANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SEEBER', 'provincia': 'Córdoba' },
        { 'descripcion': 'SEGUNDA USINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SENDAS GRANDES', 'provincia': 'Córdoba' },
        { 'descripcion': 'SERRANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SERREZUELA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SEVILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SIERRA BLANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SIERRA DE ABREGU', 'provincia': 'Córdoba' },
        { 'descripcion': 'SIERRA DE LAS PAREDES', 'provincia': 'Córdoba' },
        { 'descripcion': 'SIERRAS MORENAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SIMBOLAR', 'provincia': 'Córdoba' },
        { 'descripcion': 'SINSACATE', 'provincia': 'Córdoba' },
        { 'descripcion': 'SOCONCHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SOCORRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SOLAR LOS MOLINOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'SOLARES DE YCHO CRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'SORIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'SOTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SUCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SUNCHO HUICO', 'provincia': 'Córdoba' },
        { 'descripcion': 'SUQUIA ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'SVILLA NUEVA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TABANILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TABAQUILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TACUREL', 'provincia': 'Córdoba' },
        { 'descripcion': 'TAJAMARES', 'provincia': 'Córdoba' },
        { 'descripcion': 'TALA CAÑADA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TALA CRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'TALA DEL RIO SECO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TALA HUASI', 'provincia': 'Córdoba' },
        { 'descripcion': 'TALA NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'TALA NORTE , Est. El Alcalde', 'provincia': 'Córdoba' },
        { 'descripcion': 'TALA SUD', 'provincia': 'Córdoba' },
        { 'descripcion': 'TALAINI', 'provincia': 'Córdoba' },
        { 'descripcion': 'TANCACHA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TANTI', 'provincia': 'Córdoba' },
        { 'descripcion': 'TANTI LOMAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'TANTI NUEVO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TASACUNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TASMA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TEGUA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TEJEDA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TEMPLE, SANTIAGO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TERCER CUERPO DEL EJERCITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TERCERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'THEA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TICINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TIGRE MUERTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TILQUICHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TILQUINCHO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TINOCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TINTIZACO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TíO PUJIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TODOS LOS SANTOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'TOLEDO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TOMAS ECHENIQUE', 'provincia': 'Córdoba' },
        { 'descripcion': 'TORDILLA NORTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'TORO MUERTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TORO PUJIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TOSNO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TOSQUITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TOTORA GUASI', 'provincia': 'Córdoba' },
        { 'descripcion': 'TOTORALEJOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'TOTORITAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'TOTRILLA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TRÁNSITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TRAVESIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TRES ARBOLES', 'provincia': 'Córdoba' },
        { 'descripcion': 'TRES CHANARES', 'provincia': 'Córdoba' },
        { 'descripcion': 'TRINCHERA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TRISTAN NARVAJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TRONCO POZO', 'provincia': 'Córdoba' },
        { 'descripcion': 'TUCLAME', 'provincia': 'Córdoba' },
        { 'descripcion': 'TULUMBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'TUSCAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'UCACHA', 'provincia': 'Córdoba' },
        { 'descripcion': 'UNIDAD TURISTICA EMBALSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'UNQUILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'UNQUILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'URITORCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'URITORCO , cerro', 'provincia': 'Córdoba' },
        { 'descripcion': 'USINA NUCLEAR EMBALSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VACAS BLANCAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'VALLE DEL SOL', 'provincia': 'Córdoba' },
        { 'descripcion': 'VALLE DORADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VALLE HERMOSO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VALLE VERDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VANGUARDIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VELEZ SARSFIELD, DALMACIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VELEZ SARSFIELD, DALMACIO ,AP.', 'provincia': 'Córdoba' },
        { 'descripcion': 'VIAMONTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VICUÑA MACKENNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA AGUADA DE LOS REYES', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA AHORA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA AIZACATE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ALBERTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ALICIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ALLENDE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ALPINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA AMANCAY', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ANGELICA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ANISACATE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ANIZACATE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ASCASUBI', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA AURORA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA BUSTOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CAEIRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CANDELARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CARLOS PAZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CARLOS PELLEGRINI', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CERRO AZUL', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CERRO NEGRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CIUDAD DE AMERICA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CLODOMIRA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA COLANCHANGA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA COLIMBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA COLONIA ITALIANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CONCEPCIÓN DEL TíO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CORAZON DE MARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA COSTA AZUL', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CUESTA BLANCA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA CURA BROCHERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DE LAS ROSAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DE LAS ROSAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DE MARíA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DE SOTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DEL DIQUE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DEL LAGO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DEL PARQUE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DEL PRADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DEL ROSARIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DEL TALA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DEL TOTORAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DEL TRANSITO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DIAZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA DOLORES', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA EL CHACAY', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA EL CORCOVADO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA EL DESCANSO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA EL TORREON', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ELISA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA EMILIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ESQUIU', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA FLOR SERRANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA FONTANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA GARCIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA GENERAL BELGRANO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA GENERAL G', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA GENERAL MITRE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA GIARDINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA GIARDINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA GRACIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA GUTIÉRREZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA GUTIERREZ ,EMB. KM. 807', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA HERNANDARIAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA HUIDOBRO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA HUIDOBRO , Est. CaÑada Verde', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ICHO CRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA INDEPENDENCIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA LA BOLSA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA LA COBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA LA RANCHERITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA LAGO AZUL', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA LAS MERCEDES', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA LEONOR', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA LOS ALTOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA LOS AROMOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA LOS LEONES', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA LOS MOLINOS', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA MAR CHIQUITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA MARíA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA MIREA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA MODERNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA NATURALEZA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ÑU PORA', 'provincia': 'Córdoba' },
        { 'descripcion': 'Villa Nueva', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA PARQUE SANTA ANA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA PARQUE SIQUIMAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA POSSE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA QUILINO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA QUILLINZO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA RAFAEL BENEGAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA REDUCCION', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA RIO YCHO CRUZ', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ROSARIO DEL SALADILLO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA ROSSI', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA RUMIPAL', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA SAN ANTONIO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA SAN ESTEBAN', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA SAN MIGUEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA SANTA CRUZ DEL LAGO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA SANTA ISABEL', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA SANTA MARIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA SARMIENTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA SATITE', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA SIERRAS DE LAGO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA SUIZA ARGENTINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA TANINGA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA TORTOSA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA TULUMBA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA VALERIA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA VAUDAGNA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA VIEJA', 'provincia': 'Córdoba' },
        { 'descripcion': 'VILLA VISO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VISO', 'provincia': 'Córdoba' },
        { 'descripcion': 'VIVAS, PEDRO E. ,EMB.KILOMETRO 658', 'provincia': 'Córdoba' },
        { 'descripcion': 'VIVERO', 'provincia': 'Córdoba' },
        { 'descripcion': 'WASHINGTON', 'provincia': 'Córdoba' },
        { 'descripcion': 'WATT', 'provincia': 'Córdoba' },
        { 'descripcion': 'WATT , Est. Melideo', 'provincia': 'Córdoba' },
        { 'descripcion': 'WENCESLAO ESCALANTE', 'provincia': 'Córdoba' },
        { 'descripcion': 'YACANTO', 'provincia': 'Córdoba' },
        { 'descripcion': 'YACANTO CALAMUCHITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'YACANTO DE CALAMUCHITA', 'provincia': 'Córdoba' },
        { 'descripcion': 'YANACATO', 'provincia': 'Córdoba' },
        { 'descripcion': 'YCHO CRUZ SIERRAS', 'provincia': 'Córdoba' },
        { 'descripcion': 'YOCSINA', 'provincia': 'Córdoba' },
        { 'descripcion': 'ZAPOLOCO', 'provincia': 'Córdoba' },
        { 'descripcion': 'ZUMARAN, ANA', 'provincia': 'Córdoba' },
        { 'descripcion': '2 DE JULIO', 'provincia': 'Corrientes' },
        { 'descripcion': '8 DE DICIEMBRE', 'provincia': 'Corrientes' },
        { 'descripcion': '9 DE JULIO , Est. Pueblo De Julio', 'provincia': 'Corrientes' },
        { 'descripcion': 'ABALO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ABELI', 'provincia': 'Corrientes' },
        { 'descripcion': 'ABO NEZU', 'provincia': 'Corrientes' },
        { 'descripcion': 'ABRA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ABRA GUAZU', 'provincia': 'Corrientes' },
        { 'descripcion': 'ACUÑA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ACUNA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'AGUAPEY', 'provincia': 'Corrientes' },
        { 'descripcion': 'AGUAPEY , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'AGUARA CUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'AGUAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'AGUIRRE CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'AGUIRRE LOMAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALAMO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALBARDONES', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALEJANDRIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALEM CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALEN CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALFONSO LOMA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALFONSO LOMAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALGARROBAL PUISOYE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALGARROBALES', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALGARROBO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALGARROBO PARAJE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALTA MORA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALTAMORA PARADA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ALVEAR', 'provincia': 'Corrientes' },
        { 'descripcion': 'AMBROSIO , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'ANGUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'APIPE', 'provincia': 'Corrientes' },
        { 'descripcion': 'APIPÉ , isla', 'provincia': 'Corrientes' },
        { 'descripcion': 'APIPÉ CHICO , isla', 'provincia': 'Corrientes' },
        { 'descripcion': 'APIPE GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARANITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARERUNGUÁ', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARISTIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO AMBROSIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO AMBROSIO ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO BALMACEDA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO CARANCHO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO CASCO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO CASTILLO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO GARAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO GONZALEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO HORQUETA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO MANGANGA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO MENDEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO PAISO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO PELON', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO PONTON', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO SAN JUAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO SARANDI', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO SATURNO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO SOLIS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO SORO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO TIMBOY', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO TOTORAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ARROYO VEGA', 'provincia': 'Corrientes' },
        { 'descripcion': 'AVALOS , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'AYALA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'AYUí GRANDE , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'BAIBIENE', 'provincia': 'Corrientes' },
        { 'descripcion': 'BAJO GUAZU', 'provincia': 'Corrientes' },
        { 'descripcion': 'BALENGO', 'provincia': 'Corrientes' },
        { 'descripcion': 'BANADO NORTE', 'provincia': 'Corrientes' },
        { 'descripcion': 'BANADO SAN ANTONIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'BAÑADO SUR', 'provincia': 'Corrientes' },
        { 'descripcion': 'BARGONE', 'provincia': 'Corrientes' },
        { 'descripcion': 'BARRANCAS , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'BARRANCAS , río', 'provincia': 'Corrientes' },
        { 'descripcion': 'BARRIO ALGARROBO', 'provincia': 'Corrientes' },
        { 'descripcion': 'BARRIO VILLA CORDOBA', 'provincia': 'Corrientes' },
        { 'descripcion': 'BARTOLOME MITRE', 'provincia': 'Corrientes' },
        { 'descripcion': 'BASTIDORES', 'provincia': 'Corrientes' },
        { 'descripcion': 'BASUALDO', 'provincia': 'Corrientes' },
        { 'descripcion': 'BATAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'BATARA', 'provincia': 'Corrientes' },
        { 'descripcion': 'BATAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'BATEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'BATEL , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'BATELITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'BAYGORRIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'BEDOYA', 'provincia': 'Corrientes' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'BERNACHEA', 'provincia': 'Corrientes' },
        { 'descripcion': 'BERÓN DE ASTRADA', 'provincia': 'Corrientes' },
        { 'descripcion': 'BLANCO CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'BOLICHE LATA', 'provincia': 'Corrientes' },
        { 'descripcion': 'BOMPLAND', 'provincia': 'Corrientes' },
        { 'descripcion': 'BONETE', 'provincia': 'Corrientes' },
        { 'descripcion': 'BONPLAND', 'provincia': 'Corrientes' },
        { 'descripcion': 'BOQUERON', 'provincia': 'Corrientes' },
        { 'descripcion': 'BORANZA', 'provincia': 'Corrientes' },
        { 'descripcion': 'BREGAIN CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'BRIGANIS', 'provincia': 'Corrientes' },
        { 'descripcion': 'BROJA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'BUEN RETIRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'BUENA VENTURA', 'provincia': 'Corrientes' },
        { 'descripcion': 'BUENA VISTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAA CARAI', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAA CATI', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAA GARAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAA GARAY GDOR VALENTIN', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAA GUAZU', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAA GUAZU ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAABI POI', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAABY POY', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAAYOBAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'CABRAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'CABRED', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAFARRENO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAIMAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'CALLEJON', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMBA CUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMBAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMBARA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMBIRETA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO ARAUJO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO BORDON', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO CAFFERATA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO CARDOZO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO DE CARLOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO ESCALADA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO FERNANDEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO MAIDANA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO MORATO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO POY', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO ROMERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAMPO SAN JACINTO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CANADA BURGOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CANADA GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'CANADA MALA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CANADA QUIROZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAÑADITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAPI VARI', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAPIGUARI', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAPILLA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAPILLITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAPILLITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAPIRARI', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAPITA MINI', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAPITÁN JOAQUíN MADARIAGA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARABAJAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARABAJAL ESTE', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARABI POY', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARAMBOLA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARANDAITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARAYA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARDOZO PHI', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARMAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARRETA PASO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARRIZAL NORTE', 'provincia': 'Corrientes' },
        { 'descripcion': 'CARUSO APEAERO FCGU', 'provincia': 'Corrientes' },
        { 'descripcion': 'CASILLAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CASUALIDAD', 'provincia': 'Corrientes' },
        { 'descripcion': 'CASUARINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CASUARINAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CATALAN CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAU GARAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAVI POY', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAVIA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAZA PAVA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CAZADORES CORRENTINOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CEBOLLAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CENTINELA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CERRITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CERRUDO CUÉ', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHACRAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHACRAS 1A SECCION', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHACRAS 2A SECCION', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHACRAS 3A SECCION', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHACRAS 4A SECCION', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHACRAS NORTE', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHACRAS SECCION EJIDO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHACRAS SUD', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHAMORRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHAÑAR , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHAQUITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHAVARRíA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CHIRCAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'CNIA OFICIAL JUAN BAUTISTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA 3 DE ABRIL', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA ACUNA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA ALVAREZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA AMADEI', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA AROCENA INA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA ARROCERA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA BARRIENTES', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA BARRIENTOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA BASUALDO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA BERON DE ASTRADA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA BRANCHI', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA BROUGNES', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA BUEN RETIRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA CAIMAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA CARLOS PELLEGRINI', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA CAROLINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA CAROLINA ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA CECILIO ECHEVERRIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA CHIRCAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA DANUZZO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA DORA ELENA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA DURAZNO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA FLORENCIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA GAIMAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA GARABí', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA GENERAL FERRE', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA GENERAL URIBURU', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA GOBERNADOR RUIZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA ISABEL VICTORIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA JACOBO FINH', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA JOSE R GOMEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA JUAN PUJOL', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA LA CARMEN', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA LA HABANA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA LA UNION', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA LIBERTAD', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA LIEBIG', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA LIEBIG S', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA LLANO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA LUCERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA LUJAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA M ABERASTURY', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA MADARIAGA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA MARIA ESTHER', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA MATILDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA MENDEZ BAR', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA MERCEDES COSSIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA NUEVA VALENCIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA PAIRIRI', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA PANDO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA PORVENIR', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA PROGRESO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA PUCHETA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA ROLON COSSIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA ROMERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA SAN ANTONIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA SAN EUGENIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA SAN MARTIN', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA SAN MATEO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA SARGENTO JUAN B. CABRAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA SAUCE', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA TACUARALITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA TATACUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA VEDOYA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COLONIA YATAYTI CALLE', 'provincia': 'Corrientes' },
        { 'descripcion': 'COMPLEJO HIDROELÉCTRICO YACYRETÁ - APIPÉ', 'provincia': 'Corrientes' },
        { 'descripcion': 'CONCEPCIÓN', 'provincia': 'Corrientes' },
        { 'descripcion': 'CONI, EMILIO R.', 'provincia': 'Corrientes' },
        { 'descripcion': 'CORONA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CORONEL ABRAHAM SCHWEIZER', 'provincia': 'Corrientes' },
        { 'descripcion': 'CORONEL DESIDERIO SOSA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CORRIENTES', 'provincia': 'Corrientes' },
        { 'descripcion': 'CORRIENTES , río', 'provincia': 'Corrientes' },
        { 'descripcion': 'CORSA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA ARROYO GARAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA BATEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA BRAVA , isla', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA DE ARROYO SAN LORENZO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA DE EMPEDRADO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA DEL BATEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA GUAVIRAVI', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA GUAZU', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA RIO PARANA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA SAN LORENZO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA SANTA LUCIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTA TOLEDO', 'provincia': 'Corrientes' },
        { 'descripcion': 'COSTAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CRUCECITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CRUCECITAS SANTA LUCIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CRUCESITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CRUZ DE LOS MILAGROS', 'provincia': 'Corrientes' },
        { 'descripcion': 'CUARTA SECCION ENSENADA GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'CUAY CHICO', 'provincia': 'Corrientes' },
        { 'descripcion': 'CUAY GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'CUCARACHA , cerro', 'provincia': 'Corrientes' },
        { 'descripcion': 'CUNA SUEGRA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CURUPAYTI', 'provincia': 'Corrientes' },
        { 'descripcion': 'CURUZU', 'provincia': 'Corrientes' },
        { 'descripcion': 'CURUZÚ CUATíA', 'provincia': 'Corrientes' },
        { 'descripcion': 'CURUZÚ CUATIÁ , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'CURUZU LAUREL', 'provincia': 'Corrientes' },
        { 'descripcion': 'de BATELITO , esteros', 'provincia': 'Corrientes' },
        { 'descripcion': 'de LUNA , laguna', 'provincia': 'Corrientes' },
        { 'descripcion': 'del BATEL , esteros', 'provincia': 'Corrientes' },
        { 'descripcion': 'del IBERÁ , esteros', 'provincia': 'Corrientes' },
        { 'descripcion': 'del MIRIÑAY , esteros', 'provincia': 'Corrientes' },
        { 'descripcion': 'del SANTA LUCíA , esteros', 'provincia': 'Corrientes' },
        { 'descripcion': 'DERQUI, MANUEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'DESMOCHADO', 'provincia': 'Corrientes' },
        { 'descripcion': 'DIAZ COLODRERO,PEDRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'DOCTOR FELIX MARIA GOMEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'DOCTOR FELIX MARIA GOMEZ ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'DON MAXIMO', 'provincia': 'Corrientes' },
        { 'descripcion': 'DOS HERMANAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'DOS OMBUES', 'provincia': 'Corrientes' },
        { 'descripcion': 'EJERCITO ARGENTINO ,DV.', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL BUEN RETIRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL CARRIZAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL CEIBO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL CENTINELA', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL CHIRCAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL COQUITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL LOTO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL NANDUBAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL PAGO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL PALMAR', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL PELON', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL PILAR', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL PLATA', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL POLLO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL PONTON', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL PORVENIR COLONIA LIBERTAD', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL PROGRESO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL REMANSO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL SALVADOR', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL SOCORRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL SOMBRERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL SOMBRERO ,EMB.', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL TATARE', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL TESORO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL TORO PI', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL TRANSITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL VASCO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL YAPU', 'provincia': 'Corrientes' },
        { 'descripcion': 'EL YUQUERI', 'provincia': 'Corrientes' },
        { 'descripcion': 'EMILIO R CONI', 'provincia': 'Corrientes' },
        { 'descripcion': 'EMILIO R. CONI', 'provincia': 'Corrientes' },
        { 'descripcion': 'EMPEDRADO', 'provincia': 'Corrientes' },
        { 'descripcion': 'EMPEDRADO , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'EMPEDRADO LIMPIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ENSENADA GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ENSENADITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ENTRE RíOS , isla', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESFADAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESPINILLAR', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESQUINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESQUIVEL CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTABLECIMIENTO LA MERCED', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTACION AGRONOMICA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTACION LIBERTAD', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTACION SALADAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA AGUACEROS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA BUENA VISTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA CAFFERATTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA CASURINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA CERRO VERDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA DEL MEDIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA DURRUTI', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA EL CARMEN', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA EL CHANAR', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA EL OMBU', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA EL PORVENIR', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA GONZALEZ CRUZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA ITA CAABO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LA ARBOLEDA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LA CALERA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LA CARMENCHA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LA CAROLINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LA LOMA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LA LOMA ALTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LA MARIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LAGUNA LIMPIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LAS MAGNOLAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LAS SALINAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LAS TUNAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LOMATORA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LOS MILAGROS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA LOS PARAISOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA MANDURE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA MARQUEZ LUI', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA MBOTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA POZO CUADRADO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA RINCON GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA ROSARIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SAN ANTONIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SAN JAVIER', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SAN JUAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SAN JULIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SAN MATEO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SAN MIGUEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SAN ROBERTO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SAN SOLANO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SANTA CRUZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SANTA MARIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA SOLEDAD', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTANCIA TUNAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTE ARGENTINO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTERO GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTERO PIRU', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTERO SAUCE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTERO YATAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTINGANA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ESTRELLA', 'provincia': 'Corrientes' },
        { 'descripcion': 'FANEGAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'FELIPE YOFRÉ', 'provincia': 'Corrientes' },
        { 'descripcion': 'FERNÁNDEZ , laguna', 'provincia': 'Corrientes' },
        { 'descripcion': 'FERNANDEZ, PEDRO R.,EST.M.F.MANSILLA', 'provincia': 'Corrientes' },
        { 'descripcion': 'FERRET', 'provincia': 'Corrientes' },
        { 'descripcion': 'FERRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'FRANCISCO GAUNA', 'provincia': 'Corrientes' },
        { 'descripcion': 'G', 'provincia': 'Corrientes' },
        { 'descripcion': 'GALARZA', 'provincia': 'Corrientes' },
        { 'descripcion': 'GALARZA , laguna', 'provincia': 'Corrientes' },
        { 'descripcion': 'GALARZA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'GARABATA', 'provincia': 'Corrientes' },
        { 'descripcion': 'GARABí , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'GARCITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'GARRIDO', 'provincia': 'Corrientes' },
        { 'descripcion': 'GARRIDO CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'GARRUCHOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'GDOR ING VALENTIN VIRASO', 'provincia': 'Corrientes' },
        { 'descripcion': 'GDOR JUAN EUSEBIO TORREN', 'provincia': 'Corrientes' },
        { 'descripcion': 'GDOR.IGR.VALENTIN VIRASORO.GDOR.VIRASORO', 'provincia': 'Corrientes' },
        { 'descripcion': 'GENERAL URIBURU', 'provincia': 'Corrientes' },
        { 'descripcion': 'GOBERNADOR AGR. VALENTíN VIRASORO', 'provincia': 'Corrientes' },
        { 'descripcion': 'GOBERNADOR JUAN E MARTINEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'GOBERNADOR JUAN E. MARTíNEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'GOBERNADOR RUIZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'GOMEZ CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'GOYA', 'provincia': 'Corrientes' },
        { 'descripcion': 'GRANJA AMELIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'GUAVIRAVí', 'provincia': 'Corrientes' },
        { 'descripcion': 'GUAVIRAVI ,EST.25 DE FEBRERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'GUAY GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'GUAYAGUAS , cerro', 'provincia': 'Corrientes' },
        { 'descripcion': 'GUAYQUIRARÓ', 'provincia': 'Corrientes' },
        { 'descripcion': 'GUAYQUIRARÓ , río', 'provincia': 'Corrientes' },
        { 'descripcion': 'GUAYU', 'provincia': 'Corrientes' },
        { 'descripcion': 'GUAZU CORA', 'provincia': 'Corrientes' },
        { 'descripcion': 'GUENGUEL , río', 'provincia': 'Corrientes' },
        { 'descripcion': 'HERLITKZA', 'provincia': 'Corrientes' },
        { 'descripcion': 'HERLITZKA', 'provincia': 'Corrientes' },
        { 'descripcion': 'HORMIGUERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'IBAHAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'IBAVIYU', 'provincia': 'Corrientes' },
        { 'descripcion': 'IBERA', 'provincia': 'Corrientes' },
        { 'descripcion': 'IBERÁ , laguna', 'provincia': 'Corrientes' },
        { 'descripcion': 'IBIRA PITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'IBIRITANGAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'Icho Cruz', 'provincia': 'Corrientes' },
        { 'descripcion': 'IFRAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'IGUATE PORA', 'provincia': 'Corrientes' },
        { 'descripcion': 'INFANTE', 'provincia': 'Corrientes' },
        { 'descripcion': 'INGA', 'provincia': 'Corrientes' },
        { 'descripcion': 'INGENIO PRIMER CORRENTINO', 'provincia': 'Corrientes' },
        { 'descripcion': 'INVERNADA', 'provincia': 'Corrientes' },
        { 'descripcion': 'IPACARAPA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ISABEL VICTORIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ISLA ALTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ISLA APIPE CHICO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ISLA GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ISLA IBATAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'ISLA IBATE', 'provincia': 'Corrientes' },
        { 'descripcion': 'ISLA SAN MATEO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ISLA SOLA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ISLA TACUARA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ISOQUI', 'provincia': 'Corrientes' },
        { 'descripcion': 'ITA CORA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ITA CUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ITA CURUBI', 'provincia': 'Corrientes' },
        { 'descripcion': 'ITÁ IBATÉ', 'provincia': 'Corrientes' },
        { 'descripcion': 'ITA PUCU', 'provincia': 'Corrientes' },
        { 'descripcion': 'ITATí', 'provincia': 'Corrientes' },
        { 'descripcion': 'ITATí , laguna', 'provincia': 'Corrientes' },
        { 'descripcion': 'ITATI RINCON', 'provincia': 'Corrientes' },
        { 'descripcion': 'ITUZAINGÓ', 'provincia': 'Corrientes' },
        { 'descripcion': 'JARDIN FLORIDO', 'provincia': 'Corrientes' },
        { 'descripcion': 'JUAN PUJOL', 'provincia': 'Corrientes' },
        { 'descripcion': 'JUAN RAMON VIDAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'JUSTINO SOLARI', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETR 402 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETR0 167 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETR0 301 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 104 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 120 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 161 ,PDA.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 173 ,EMB', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 182 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 204 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 374 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 382 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 387 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 394 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 396 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 405 .AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 406 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 410 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 416 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 431 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 442 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 451 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 459 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 470 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 476 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 479 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 485 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 489 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 492 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 501 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 504 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 506 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 512 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 516 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KILOMETRO 517 ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'KM 425', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA AGRIPINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA AMELIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA AMISTAD', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA ANGELA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA ARMONIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA BELERMINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA BLANCA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA BLANQUEADA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA BOLSA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA CARLINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA CASUALIDAD', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA CELESTE', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA CELIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA CELINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA CHIQUITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA COLORADA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA CONCEPCION', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA CRUZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA DELICIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA DIANA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA ELENA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA ELOISA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA ELSA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA ELVA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA ELVIRA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA FE', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA FLECHA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA FLOR', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA FLORENTINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA FLORESTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA FORTUNA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA GARCIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA HAYDEE', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA HERMINIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA HILEORICA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA ISABEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA JAULA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA LEONOR', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA LEONTINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA LOLITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA LOMA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA LOMA TORRENT', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA MAGNOLIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA MATILDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA MOROCHA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA PACHINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA PALMA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA PALMERA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA PALMIRA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA PASTORIL', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA PEPITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA PUPI', 'provincia': 'Corrientes' },
        { 'descripcion': 'LA PUPII', 'provincia': 'Corrientes' },
        { 'descripcion': 'LABORY', 'provincia': 'Corrientes' },
        { 'descripcion': 'LABOUGLE', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAGO ARIAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAGUNA ALFONSO', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAGUNA AVALOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAGUNA BRAVA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAGUNA PUCU', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAGUNA SIRENA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAGUNA SOTO', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAPACHO', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS ANIMAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS CUCHILLAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS ELINAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS LAGUNAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS LOMAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS MATRERAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS PALMIRAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS PALMITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS RATAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS TAPERAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAS VIOLETAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAUREL', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAURETTI', 'provincia': 'Corrientes' },
        { 'descripcion': 'LAVALLE', 'provincia': 'Corrientes' },
        { 'descripcion': 'LEON CUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LIBERTAD', 'provincia': 'Corrientes' },
        { 'descripcion': 'LIBERTAD ,EST.', 'provincia': 'Corrientes' },
        { 'descripcion': 'LIBERTADOR', 'provincia': 'Corrientes' },
        { 'descripcion': 'LIMAS CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'LINDA VISTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOBORY', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMA POY', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMA VILLANUEVA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS DE AGUIRRE', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS DE EMPEDRADO', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS DE GALARZA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS DE GONZALEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS DE VALLEJOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS DE VERGARA', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS ESQUIVEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS ESTE', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS FLORIDAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS RAMIREZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS REDONDAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS SALADAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS SAN CAYETANO', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS SAN JUAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOMAS VAZQUEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'LORETO', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS ALGARROBOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS ANGELES DEL BATEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS BRETES', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS CEIBOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS EUCALIPTOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS FLOTADORES', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS GEMELOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS LAURELES', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS LIRIOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS MEDIOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS PARAISOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS TRES AMIGOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS TRES CERROS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS TRES HERMANOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOS VENCES', 'provincia': 'Corrientes' },
        { 'descripcion': 'LOZA, MARIANO I. ,EST.JUSTINO SOLARI', 'provincia': 'Corrientes' },
        { 'descripcion': 'LUIS GOMEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'LUJAMBIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'MADARIAGA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MALEZAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'MALOYA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MALOYAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'MALOYITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MALVINAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'MALVINAS CENTRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'MALVINAS NORTE', 'provincia': 'Corrientes' },
        { 'descripcion': 'MALVINAS SUR', 'provincia': 'Corrientes' },
        { 'descripcion': 'MANANTIALES', 'provincia': 'Corrientes' },
        { 'descripcion': 'MANCHITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MANDINGA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MANSION DE INVIERNO', 'provincia': 'Corrientes' },
        { 'descripcion': 'MANUEL DERQUI', 'provincia': 'Corrientes' },
        { 'descripcion': 'MANUEL FLORENCIO MANTILLA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARIA DEL CARMEN', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARíA GRANDE , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARIA IDALINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARIANO I LOSAS EST SOLAR', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARIANO l . LOZA , Est. J. Solari', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARTIN', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARTIN GARCIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARTINEZ CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARUCHAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'MARUCHITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'MATADERO SANTA CATALINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MATRERA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MBALGUIAPU', 'provincia': 'Corrientes' },
        { 'descripcion': 'MBARIGUI', 'provincia': 'Corrientes' },
        { 'descripcion': 'MBOI CUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MBURUCUYÁ', 'provincia': 'Corrientes' },
        { 'descripcion': 'MEDINA , laguna', 'provincia': 'Corrientes' },
        { 'descripcion': 'MEDIODIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MERCEDES', 'provincia': 'Corrientes' },
        { 'descripcion': 'MINUANES', 'provincia': 'Corrientes' },
        { 'descripcion': 'MINUANES ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'MIRA FLORES', 'provincia': 'Corrientes' },
        { 'descripcion': 'MIRADOR', 'provincia': 'Corrientes' },
        { 'descripcion': 'MIRIÑAY , río', 'provincia': 'Corrientes' },
        { 'descripcion': 'MIRUNGA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MOCHO , cerro', 'provincia': 'Corrientes' },
        { 'descripcion': 'MOCORETÁ', 'provincia': 'Corrientes' },
        { 'descripcion': 'MOCORETÁ , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'MOJON', 'provincia': 'Corrientes' },
        { 'descripcion': 'MONTANA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MONTE CASEROS', 'provincia': 'Corrientes' },
        { 'descripcion': 'MONTE FLORIDO', 'provincia': 'Corrientes' },
        { 'descripcion': 'MONTE GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'MONTEVIDEO', 'provincia': 'Corrientes' },
        { 'descripcion': 'MORA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MORICA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MOTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'MOTA PIEDRITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'MUCHAS ISLAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'NAEMBE', 'provincia': 'Corrientes' },
        { 'descripcion': 'NARANJATY', 'provincia': 'Corrientes' },
        { 'descripcion': 'NARANJITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'NARANJITO SAN ROQUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'NATIU', 'provincia': 'Corrientes' },
        { 'descripcion': 'NAZARENO , cerro', 'provincia': 'Corrientes' },
        { 'descripcion': 'NINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'NTRA SRA DEL ROSARIO DE CAA', 'provincia': 'Corrientes' },
        { 'descripcion': 'NUESTRA SEÑORA DEL ROSARIO DE CAÁ CATí', 'provincia': 'Corrientes' },
        { 'descripcion': 'NUEVA GRANADA', 'provincia': 'Corrientes' },
        { 'descripcion': 'NUEVA PALMIRA', 'provincia': 'Corrientes' },
        { 'descripcion': 'NUEVO PARAISO', 'provincia': 'Corrientes' },
        { 'descripcion': 'NUEVO PORVENIR', 'provincia': 'Corrientes' },
        { 'descripcion': 'NURUGUAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'OBRAJE CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'OBRAJE DEL VASCO', 'provincia': 'Corrientes' },
        { 'descripcion': 'OCANTO CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'OMBU LOMAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'OMBU SOLO', 'provincia': 'Corrientes' },
        { 'descripcion': 'OMBUCITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ONAGOYTI', 'provincia': 'Corrientes' },
        { 'descripcion': 'OSCURO', 'provincia': 'Corrientes' },
        { 'descripcion': 'Otra', 'provincia': 'Corrientes' },
        { 'descripcion': 'PAGO ALEGRE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PAGO ARIAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PAGO DE LOS DESEOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PAGO LARGO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PAGO POI', 'provincia': 'Corrientes' },
        { 'descripcion': 'PAGO REDONDO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PAIMBRE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PALMAR ARERUNGUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PALMAR GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PALMERA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PALMITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PALMITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PAMPIN', 'provincia': 'Corrientes' },
        { 'descripcion': 'PANCHO CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARADA LABOUGLE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARADA PUCHETA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARAISO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARAJE AUGUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARAJE BARRANQUITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARAJE EL CARMEN', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARAJE FLORIDA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARAJE IRIBU CUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARAJE PORTILLO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARAJE POTON', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARAJE SAN ISIDRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARANÁ MINí , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARQUE NACIONAL MBURUCUYÁ', 'provincia': 'Corrientes' },
        { 'descripcion': 'PARQUE SAN MARTIN', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASAJE SANTA JUANA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO AGUIRRE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO ALGARROBO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO ANCHO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO BANDERA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO BERMUDEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO CEJAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO CHANARAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO CONCEPCION', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO CORONEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO DE LA PATRIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO DE LAS PIEDRAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO DE LOS LIBRES', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO DE MULA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO ESTERITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO FLORENTIN', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO GALLEGO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO IRIBU CUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO ITU', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO LEDESMA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO LOPEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO LOS ANGELES', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO LOVERA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO LUCERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO MESA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO NARANJITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO PESOA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO POTRERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO PUCHETA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO ROSARIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO RUBIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO SALDANA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO SAN JUAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO SANTA ROSA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO TIRANTE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASO VALLEJOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PASTORES', 'provincia': 'Corrientes' },
        { 'descripcion': 'PAY UBRE CHICO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PEDRO DíAZ COLODRERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PEDRO R FERNANDEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'PEDRO R. FERNÁNDEZ , Est. M. F. Mantilla', 'provincia': 'Corrientes' },
        { 'descripcion': 'PEHUAHO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PELADO , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'PERUGORRíA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PIEDRA ITA PUCU', 'provincia': 'Corrientes' },
        { 'descripcion': 'PIEDRITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PILINCHO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PINDO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PINDONCITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PIRACU', 'provincia': 'Corrientes' },
        { 'descripcion': 'PIRAYU', 'provincia': 'Corrientes' },
        { 'descripcion': 'PIRRA PUY', 'provincia': 'Corrientes' },
        { 'descripcion': 'PLAYADITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'POTRERO GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUCHETA ,PDA.', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUEBLITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUEBLITO ESPINOSA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUEBLITO SAN JUAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUEBLO DE JULIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUEBLO LIBERTADOR', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUENTE AVALOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUENTE BATEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUENTE MACHUCA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTA IFRAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO ARAZA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO EMPEDRADO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO GONZALEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO GOYA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO HORMIGUERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO JUAN DE DIOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO LAS LAJAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO LAS TACUARITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO LAVALLE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO PIEDRA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO UBAJAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUERTO VALLE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUESTO DE ISLA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUESTO LATA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUISOYÉ', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUJOL BEDOYA', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUJOL, JUAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUNTA GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUNTA IFRAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUNTA MERCEDES', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUNTAS DE FRANCISCO GOMEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'PUNTAS DEL TIGRE', 'provincia': 'Corrientes' },
        { 'descripcion': 'QUINTA SECCION OMBUCITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'QUINTA TERESA', 'provincia': 'Corrientes' },
        { 'descripcion': 'QUIYATI', 'provincia': 'Corrientes' },
        { 'descripcion': 'RALERA SUD', 'provincia': 'Corrientes' },
        { 'descripcion': 'RAMADA PASO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RAMONES', 'provincia': 'Corrientes' },
        { 'descripcion': 'RANEGAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'REAL CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'REDUCCION', 'provincia': 'Corrientes' },
        { 'descripcion': 'REMANSO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RIACHUELITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RIACHUELO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RIACHUELO BARDECI', 'provincia': 'Corrientes' },
        { 'descripcion': 'RIACHUELO SUD', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON CHICO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE AMBROSIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE ANIMAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE GOMEZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE LAS MERCEDES', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE MERCEDES', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE PAGO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE SAN LORENZO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE SARANDY', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE SOTO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE TUNAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE VENCES', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DE YAGUARY', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DEL ROSARIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DEL SOMBRERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON DEL TIGRE', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON ITAEMBE', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON MERCEDES ESTANCIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON SAN PEDRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON TRANQUERA GENERAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'RINCON ZALAZAR', 'provincia': 'Corrientes' },
        { 'descripcion': 'RODEITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ROLON JACINTO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ROMERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ROMERO CUAZU', 'provincia': 'Corrientes' },
        { 'descripcion': 'ROSADITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'ROSADO GRANDE', 'provincia': 'Corrientes' },
        { 'descripcion': 'RUIZ CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAENZ VALIENTE', 'provincia': 'Corrientes' },
        { 'descripcion': 'SALADAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'SALADERO SAN ANTONIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SALDAÑA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SALDANA 9 DE JULIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SALINAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'SALINAS GANDES', 'provincia': 'Corrientes' },
        { 'descripcion': 'SALTO ITA JHASE', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN ALEJO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN ALONSO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN ANTONIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN ANTONIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN ANTONIO DEL CAIMAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN BORJITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN CARLOS', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN CELESTINO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN COSME', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN DIEGO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN DIONISIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN FRANCISCO CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN FRANCISCO GUAVIRARI', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN GABRIEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN ISIDRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN JACINTO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN JERONIMO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN JOSE CAACATI', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN JOSE EST LIBERTAD DP', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN JULIAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN LORENZO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN LORENZO , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN LUIS CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN LUIS DEL PALMAR', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN LUIS EST LIBERTAD DP', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN MIGUEL', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN MIGUEL ESTACION LIBERTAD', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN NICANOR', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN PALADIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN ROQUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN ROQUITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAN SALVADOR', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANGARA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA ANA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA CECILIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA ELISA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA IRENE', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA JUANA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA LEA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA LIBRADA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA LUCíA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA LUCíA , río', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA LUCIA 9 DE JULIO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA MAGDALENA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA MARTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA RITA PARADA PUCHETA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA ROSA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA SINFOROSA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTA TECLA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTIAGO ALCORTA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTILLAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTO TOMAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'SANTO TOMÉ', 'provincia': 'Corrientes' },
        { 'descripcion': 'SARANDí , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAUCE', 'provincia': 'Corrientes' },
        { 'descripcion': 'SAUCESITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SECCION PRIMERA SAN JUAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'SERIANO CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'SILVERO CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'SOCORRO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SOLARI MARIANO I LOZA', 'provincia': 'Corrientes' },
        { 'descripcion': 'SOMBRERO', 'provincia': 'Corrientes' },
        { 'descripcion': 'SOSA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'TABAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'TACUABE', 'provincia': 'Corrientes' },
        { 'descripcion': 'TACUARACARENDY', 'provincia': 'Corrientes' },
        { 'descripcion': 'TACUARAL', 'provincia': 'Corrientes' },
        { 'descripcion': 'TACUAREMBO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TACUARITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'TACURAL MERCEDES', 'provincia': 'Corrientes' },
        { 'descripcion': 'TAJIBO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TALA , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'TALA CORA', 'provincia': 'Corrientes' },
        { 'descripcion': 'TALA PASO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TALATY', 'provincia': 'Corrientes' },
        { 'descripcion': 'TALITA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'TALLERES', 'provincia': 'Corrientes' },
        { 'descripcion': 'TAMBO NUEVO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TAPE RATI', 'provincia': 'Corrientes' },
        { 'descripcion': 'TAPEBICUÁ', 'provincia': 'Corrientes' },
        { 'descripcion': 'TARANGULLO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TAREIRI', 'provincia': 'Corrientes' },
        { 'descripcion': 'TARTAGUITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TARTARIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'TATACUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'TATARE', 'provincia': 'Corrientes' },
        { 'descripcion': 'TEBLENARI', 'provincia': 'Corrientes' },
        { 'descripcion': 'TIERRA COLORADA', 'provincia': 'Corrientes' },
        { 'descripcion': 'TILITA', 'provincia': 'Corrientes' },
        { 'descripcion': 'TIMBO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TIMBO CORA', 'provincia': 'Corrientes' },
        { 'descripcion': 'TIMBO PASO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TIMBOCITO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TIMBOY', 'provincia': 'Corrientes' },
        { 'descripcion': 'TINGUI', 'provincia': 'Corrientes' },
        { 'descripcion': 'TIQUINO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TOLATU', 'provincia': 'Corrientes' },
        { 'descripcion': 'TOPADOR', 'provincia': 'Corrientes' },
        { 'descripcion': 'TORO CHIPAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'TORO I', 'provincia': 'Corrientes' },
        { 'descripcion': 'TORO PICHAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'TOROS CORA', 'provincia': 'Corrientes' },
        { 'descripcion': 'TORRENT', 'provincia': 'Corrientes' },
        { 'descripcion': 'TRES BOCAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'TRES HERMANAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'TRES HOJAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'TRES TAPERAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'TRíN , laguna', 'provincia': 'Corrientes' },
        { 'descripcion': 'TRIPOLI', 'provincia': 'Corrientes' },
        { 'descripcion': 'TRISTAN CHICO', 'provincia': 'Corrientes' },
        { 'descripcion': 'TUNITAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'UGUAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'ULAJAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'URUGUAY', 'provincia': 'Corrientes' },
        { 'descripcion': 'VACA CUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'VALENCIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'VALLEJOS , laguna', 'provincia': 'Corrientes' },
        { 'descripcion': 'VECINDAD', 'provincia': 'Corrientes' },
        { 'descripcion': 'VEDOYA', 'provincia': 'Corrientes' },
        { 'descripcion': 'VELOSO', 'provincia': 'Corrientes' },
        { 'descripcion': 'VERGARA LOMAS', 'provincia': 'Corrientes' },
        { 'descripcion': 'VERON CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA AQUINO', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA CORDOBA', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA CRISTIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA EL DORADO', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA JUAN DE VERA', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA LA FLORIDA', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA OLIVARI', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA ORTIZ', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA P ARGENTINA', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA ROLLET', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA ROLON', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA SAN JUAN', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA SAN RAMON', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA SOLARI', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA SOTO', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLA TESARO', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLAGA CUE', 'provincia': 'Corrientes' },
        { 'descripcion': 'VILLANUEVA , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'VIRGEN MARIA', 'provincia': 'Corrientes' },
        { 'descripcion': 'VIZCAINO', 'provincia': 'Corrientes' },
        { 'descripcion': 'VUELTA DEL OMBU', 'provincia': 'Corrientes' },
        { 'descripcion': 'YACARE', 'provincia': 'Corrientes' },
        { 'descripcion': 'YACAREY', 'provincia': 'Corrientes' },
        { 'descripcion': 'YAGUA RINCON', 'provincia': 'Corrientes' },
        { 'descripcion': 'YAGUA ROCAU', 'provincia': 'Corrientes' },
        { 'descripcion': 'YAGUARí , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'YAGUARU', 'provincia': 'Corrientes' },
        { 'descripcion': 'YAGUARY', 'provincia': 'Corrientes' },
        { 'descripcion': 'YAHAPE', 'provincia': 'Corrientes' },
        { 'descripcion': 'YAPEYÚ', 'provincia': 'Corrientes' },
        { 'descripcion': 'YAPEYU ,AP.', 'provincia': 'Corrientes' },
        { 'descripcion': 'YAPUCA', 'provincia': 'Corrientes' },
        { 'descripcion': 'YATAITI SATA', 'provincia': 'Corrientes' },
        { 'descripcion': 'YATAITY CALLE', 'provincia': 'Corrientes' },
        { 'descripcion': 'YATAITY POI', 'provincia': 'Corrientes' },
        { 'descripcion': 'YATAY CORA', 'provincia': 'Corrientes' },
        { 'descripcion': 'YATAYTI CALLE', 'provincia': 'Corrientes' },
        { 'descripcion': 'YAZUCA', 'provincia': 'Corrientes' },
        { 'descripcion': 'YOFRE, FELIPE', 'provincia': 'Corrientes' },
        { 'descripcion': 'YTA PASO', 'provincia': 'Corrientes' },
        { 'descripcion': 'YUQUERí', 'provincia': 'Corrientes' },
        { 'descripcion': 'YUQUERí , arroyo', 'provincia': 'Corrientes' },
        { 'descripcion': 'YURUCUA', 'provincia': 'Corrientes' },
        { 'descripcion': 'ZAPALLAR', 'provincia': 'Corrientes' },
        { 'descripcion': 'ZAPALLOS', 'provincia': 'Corrientes' },
        { 'descripcion': '1 DE MAYO', 'provincia': 'Entre Ríos' },
        { 'descripcion': '1° DE MAYO', 'provincia': 'Entre Ríos' },
        { 'descripcion': '20 DE SEPTIEMBRE', 'provincia': 'Entre Ríos' },
        { 'descripcion': '20 DE SETIEMBRE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ACHAGUE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'AERO CLUB CANAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'AGUILA RIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALARCON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALBERTO GERCHUNOF ,PDA.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALBERTO GERCHUNOFF', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALCARACITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALCARAZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALCARAZ 1RO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALCARAZ 2DO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALCARAZ NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALCARAZ PUEBLO ARRUA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALCARAZ SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALCETE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA ASUNCIÓN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA BRASILERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA CHALECO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA CUESTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA EIGENFELD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA MARIA LUISA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA MEROU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA PROTESTANTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SALTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SAN ANTONIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SAN FRANCISCO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SAN GREGORIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SAN JORGE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SAN JOSE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SAN JUAN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SAN MIGUEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SAN RAFAEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SANTA CELIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SANTA MARíA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SANTA ROSA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SANTAFECINA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA SPATZENKUTTER', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALDEA VALLE MARíA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALGARROBITO 1RO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALGARROBITOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALMACEN CRISTIAN SCHUBERT', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALMIRANTE IGLESIAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALTAMIRANO NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALTAMIRANO SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ALTAMIRANO SUR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ANAHI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ANTELO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ANTONIO TOMAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ANTONIO TOMAS SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARANGUREN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BALTAZAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BALTAZAR CHICO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BARÚ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BICHO FEO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BOCA FALSA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BRASILERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BRAVO GUTIERREZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BRAZO CHICO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BRAZO DE LA TINTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BUEN PASTOR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO BURGOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CABALLO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CARABALLO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CARBON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CARBONCITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CARPINCHO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CEIBITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CEIBO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CLE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CLE DESVIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CONCEPCION', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CORRENTOSO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CUCHARAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO CUZCO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO DE LA ROSA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO DEL CURA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO DEL MEDIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO DESAGUADERO DEL GUTIERR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO DESAGUADERO DEL SAUCE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO EL MOCHO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO GARCETE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO GUTIERREZ CHICO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO HONDO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO IBICUYCITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO JACINTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO LA PACIENCIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO LA TINTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO LA VIRGEN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO LARA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO LAS ANIMAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO LAS TUNAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO LLORONES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO LOS PLATOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO MALAMBO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO MANZANO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO MARIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO MARTINEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO MERLO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO MOLINO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO MOREIRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO MOSQUITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO NANCAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO NEGRO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO OBISPO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PACIENCIA CHICO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PACIENCIA GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PALMAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PALMAR ,EST.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PANCHO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PELADO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PERDIDO CEIBO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PERDIDO MOSQUITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PEREYRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PIEDRAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PLATITOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO PRINCIPAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO SAGASTUME CHICO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO SAGASTUME GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO SALADO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO SANCHEZ CHICO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO SANCHEZ GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO SANTOS CHICO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO SANTOS GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO TIROLES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO URQUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO VENERATO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ARROYO ZAPALLO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ATENCIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'AVENIDA EJERCITO PARANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'AYUí', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'AYUI PARADA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BAJADA GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BALNEARIO PELAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BANADERO OFICIAL BURGOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BANADERO OFICIAL LAS GALARZAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BANDERAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BARON HIRSCH', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BARRANCAS COLORADAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BASAVILBASO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BELEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BELGRANO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BELLA UNION PARAJE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BENITO LEGEREN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BENITO LEGEREN ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BERDUC', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BERDUC ,EST.MARTINIANO LEGUIZAMON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BERISSO ,EMB.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BERISSO DESVIO FCGU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BETBEDER', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BIZCOCHO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BOCA DEL TIGRE ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BOCA DEL TIGRE APEADERO FCGU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BOCA GUALEGUAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BONALDI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BOVRIL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BOVRIL DTO NRO 12', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BRAZO LARGO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BRITOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'BUENA VISTA PARAJE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CABI MONDA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CALABACILLA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CALABACILLAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CALANDRIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CALERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CAMBA PASO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CAMINO A DIAMANTE KM 1', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CAMPO DE VILLAMIL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CAMPO DOMINGUEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CAMPO ESCALES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CAMPO MORENO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CAMPO RIQUELME', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CAMPS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CANADA DE LAS OVEJAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CANADA GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CANAL NUEVO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CANAL PRINCIPAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CANAL SAN MARTIN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CANTERA LA CONSTANCIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CARAGUATA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CARBO, ENRIQUE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CARPINCHORIS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CASEROS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CATALOTTI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CEIBAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CEIBAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CENTELLA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CENTENARIO LA PAZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CENTENARIO PARANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CENTRO COMUNITARIO CNIA NUE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CERRITO , Est. Gobernador Racedo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CERRITO ,EST.COLONIA CERRITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CHACRAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CHAJARí', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CHAÑAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CHANAR FELICIANO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CHANAR MARIA GRANDE PRIMERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CHAVIYU COLONIA FLORES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CHAVIYU PARADA FCGU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CHILCAS SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CHIQUERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CHIRCALITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CLARA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CLÉ , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CLODOMIRO LEDESMA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CNIA JUSTO JOSE DE URQUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLEGIO ADVENTISTA DEL PLATA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLÓN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA 1 DE MAYO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA 5 ENSANCHE DE MAYO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ACHIRAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ADELA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ALCARCITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ALEMANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ALGARRABITOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA AMBIS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ANGELA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ARGENTINA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ARROYO URQUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ARRUABARRENA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA AVIGDOR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA AYUI GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA BAILINA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA BARON HIRSCH', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA BELEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA BELGA AMERICANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA BERRO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA BIZCOCHO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA BUENA VISTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CAMPOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CARABALLO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CARLOS CALVO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CARMEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CARMELO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CARRASCO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CASEROS ,EST.CASEROS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CELINA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CENTENARIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CENTENARIO ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CRESPO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CRUCESITAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CUPALEN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA CURBELO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA DELTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA DON BOSCO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA DUPORTAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA EGIDO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA EL CARMEN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA EL POTRERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA EL SAUCE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ELíA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA EMILIO GOUCHON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ENSANCHE MAYO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ENSANCHE SAUCE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ENSAYO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ESPINDOLA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA F SILLEN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA FALCO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA FEIMBERG', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA FLORES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA FONTANINI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA FRAZER', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA FREITAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA GDOR BASAVILBASO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA GENERAL ROCA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA GRAL URQUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA GRAPSCHENTAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA GUALEGUAYCITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA GUIBURG', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA HAMBIS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA HEBREA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA HERNANDARIAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA HIGUERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA HOCKER', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA HUGHES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA IDA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA ITALIANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA JORGE FINK', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA ARGENTINA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA ARMONIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA BLANQUITA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA DELIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA ESPERANZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA GAMA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA GLORIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA LLAVE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA MATILDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA MATILDE SANTA ANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA MORA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA MORENITA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA PAMPA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA PAZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA PROVIDENCIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA QUINTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LA ROSADA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LAMARCA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LAS GAMAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LAS PEPAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LEVEN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LOMA NEGRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LOPEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LOS SAUCES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LUCA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LUCIENVILLE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA LUCRECIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA MABRAGANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA MARIA LUISA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA MAXIMO CASTRO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA MIGUEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA N 1', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA N 2', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA N 3', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA N 4', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA NAVARRO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA NUEVA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA NUEVA AL SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA NUEVA ALEMANIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA NUEVA MONTEVIDEO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA NUEVA SAN MIGUEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA OFICIAL N 1 LA FLORI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA OFICIAL N 11', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA OFICIAL N 13', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA OFICIAL N 14', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA OFICIAL N 3', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA OFICIAL N 4', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA OFICIAL N 6', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA OFICIAL NRO 5', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA OUGRIE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA PALMAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA PEREIRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA PERFECCION', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA PERLIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA REFFINO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA RIVADAVIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA RIVAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAENZ VALIENTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAGASTUME', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAN ANTONIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAN BONIFACIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAN CIPRIANO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAN ERNESTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAN FRANCISCO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAN IGNACIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAN MANUEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAN MIGUEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAN RAMON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAN VICENTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SANDOVAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SANTA ANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SANTA ELENA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SANTA ELOISA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SANTA ELVIRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SANTA JUANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SANTA LUISA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SANTA TERESITA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SAUCE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA SONENFELD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA STAUWER', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA TRES DE FEBRERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA UBAJAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA VAZQUEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA VELEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA VILLA LIBERTAD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA VILLAGUAYCITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA VIRARO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COLONIA YERUA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CONCEPCIÓN DEL URUGUAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CONCORDIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CONSCRIPTO BERNARDI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COOPERATIVA BRAZO LARGO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COOPERATIVA GRAL SAN MARTIN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CORRALES NUEVOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COSTA DEL NOGOYA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COSTA DEL PAYTICU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COSTA DEL URUGUAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COSTA GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COSTA GRANDE DOLL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'COSTAS DE SAN ANTONIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CRESPO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CRESPO NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CRUCECITAS 3A SECCION', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CRUCECITAS 7A SECCION', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CRUCECITAS 8A SECCION', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CRUCECITAS URQUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CUARTO DISTRITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CUATRO BOCAS PARAJE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CUATRO MANOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CUCHILLA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CUCHILLA DE MONTIEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CUCHILLA GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CUCHILLA REDONDA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CUEVA DEL TIGRE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CUPALEN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CURTIEMBRE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CURUPI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CURUZU CHALI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'CURUZÚ CHALí , isla', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'de LAS LECHIGUANAS , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'de LAS LECHIGUANAS , islas', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'de LAS MULAS , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DEL PARAGUAYO , isla', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DESPARRAMADOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DESTACAMENTO GENERAL GUEMES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DIAMANTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DISTRITO EL SAUCE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DISTRITO ESPINILLO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DISTRITO TALA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DOCTOR EUGENIO MUNOZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DOCTOR GARCIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DON CRISTÓBAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DON CRISTOBAL 1RA SECCION', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DON CRISTOBAL 2DA SECCION', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DON GONZALO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DON GONZALO , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DON GONZALO ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DON ROBERTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DOS HERMANAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'DURAZNO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EBEN HOROSCHA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ECHAGUE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EJIDO COLON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EJIDO DIAMANTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EJIDO SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL AVESTRUZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL BRILLANTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL CARMEN , Est. General Racedo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL CHAJA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL CIMARRÓN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL DIECISIETE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL DURAZNAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL EMBALSADO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL EMPALME PARAJE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL GATO , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL GAUCHO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL GRAMILLAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL GUALEGUAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL GUALEGUAY ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL MOCHO ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL NUEVO RINCON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL PAGO ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL PAGO APEADERO FCGU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL PALENQUE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL PALENQUE ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL PINGO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL POTRERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL PUEBLITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL RAMBLON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL REDOMÓN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL REFUGIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL REMANCE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL ROMANCE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL SARANDI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL SOLAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL TALLER', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL TIGRE , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EL TROPEZON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EMBARCADERO FERRARI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EMPALME HOLT', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'EMPALME NEILD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ENRIQUE BERDUC', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ENRIQUE CARBÓ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESCRIÑA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO EL CARMEN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO EL CIMARRON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO EL TALA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO LA CALERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO LA ESPERANZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO LAS MARGARITAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO LOS MONIGOTES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO PUNTA ALTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO SAN EDUARDO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO SAN EUSEBIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO SAN FRANCISCO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTABLECIMIENTO SAN MARTIN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTACAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTACAS , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTACION ALCARAZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTACION GENERAL RACEDO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTACIÓN LAZO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTACION SANTA ANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTACION SOLA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTACION URQUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTACION URUGUAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTACION YERUA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA BELLA VISTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA CNIA LA PRIMAVERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA CNIA SANTA ELENA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA CNIA STA TERESA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA COLONIA EL OMBU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA COLONIA EL TOROPI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA COLONIA LA TAPERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA COLONIA PERIBEBUY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA COLONIA SAN PEDRO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA COLONIA SANTA ELOISA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA COLONIA SANTA JUANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA EL TOROPI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA LA FLORESTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA LA GAMA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA LOS VASCOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA SALINAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA SAN JOSE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ESTANCIA SAN JUAN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FABRICA COLON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FAUSTINO M PARERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FAUSTINO M. PARERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FEBRE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FEDERACIÓN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FEDERAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FELICIANO , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FERNANDEZ ,EMB.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FILOMENA GRANDE , isla', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FLORESTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FORTUNA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FRIGORIFICO YUQUERI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'FRONTERAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GARAT, JUAN ,EST.GARAT', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENÁ , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENACITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL ALMADA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL ALVEAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL CAMPOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL G', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL G', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL GALARZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL GUEMES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL JOSÉ GERVASIO ARTIGAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL RACEDO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL RAMíREZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GENERAL RAMIREZ ,EST.RAMIREZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GERIBEBUY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GILBERT', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GOBERNADOR BASAVILBASO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GOBERNADOR ECHAGUE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GOBERNADOR FEBRE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GOBERNADOR MANSILLA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GOBERNADOR SOLÁ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GOBERNADOR URQUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GONZALEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GONZÁLEZ CALDERÓN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GONZALEZ, LUCAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GRANDE o del PEDERNAL , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GRANDE o del QUEBRACHITO , laguna', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GRUPO ACHIRAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GRUPO PARRERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GUALEGUAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GUALEGUAY , río', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GUALEGUAYCHÚ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GUALEGUAYCHÚ , río', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GUALEGUAYCITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GUALEYAN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GUALEYÁN , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GUARDAMONTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'GUAYAQUIL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HAMBIS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HASENKAMP', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HERNANDARIAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HERNANDARIAS , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HERNÁNDEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HERRERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HERVIDERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HINOJAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HIPODROMO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HOJAS ANCHAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HOLT', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'HOLT ,EST', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'IBICUY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'IBICUY , río', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ING MIGUEL SAJAROFF', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'INGENIERO MIGUEL SAJAROFF', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'IRAZUSTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'IRAZUSTA ESTACION', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'IRIGOYEN , caleta', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ISLA CURUZU CHALI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ISLA DEL IBICUY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ISLA EL DORADO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ISLA EL PILLO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ISLA LA PAZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ISLA LYNCH', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ISLA SAN JOSE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ISLAS ALCARAZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ISLAS DE LAS LECHIGUANAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ISTHILART', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'JORGE FINK', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'JUAN B MONTI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'JUAN GARAT', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'JUAN JORGE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'JUAN V. MORÁN , Est. Arroyo ClÉ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'JUBILEO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILMETRO 165', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 160 ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 180 ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 183,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 200 ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 208 ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 213 ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 306 ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 325 ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 33 ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 340 ,PDA.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 361 ,EMB.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 389 ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'KILOMETRO 45 ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA ALICIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA AMIGUITA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA ARGENTINA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA BALSA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA BALSA PARANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA BARRACA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA BLANQUEADA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA CALANDRIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA CHICA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA CHILENA CANAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA CHILENA ZANJA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA CLARITA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA COLMENA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA COLORADA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA CORVINA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA CRIOLLA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA CUADRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA DILIGENCIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA ENCIERRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA ESMERALDA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA FAVORITA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA GOYA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA GRANJA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA HIERRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA ILUSION', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA JOYA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA JULIANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA LATA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA LLAVE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA LOMA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA MARIA LUISA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA MARUJA A', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA NOBLEZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA ODILIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA OLLITA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA PAZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA PERLA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA PICADA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA PICADA NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA QUERENCIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA QUINTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA ROSADA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA S DIEZ CASAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA SELVA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA SESTEADA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA SOLEDAD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA SUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA TIGRESA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA VERBENA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA VIRGEN ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA VIRGINIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LA ZELMIRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAGUNA DEL PESCADO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAGUNA LARGA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAMARCA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LARROQUE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS ACHIRAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS BATEAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS CATORCE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS COLAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS CUEVAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS DELICIAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS GARZAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS GAUCHAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS LAGUNAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS MASITAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS MERCEDES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS MOCHAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS MOSCAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS MULITAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAS PAJITAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAURENCENA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LAZO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LEDESMA, CLODOMIRO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LEGUIZAMON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LESCA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LíBAROS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LIBERTADOR GENERAL SAN MARTíN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LIBERTADOR GRAL SAN MARTIN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LIBERTADOR SAN MARTIN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LIEBIG', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LINEA 19', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LINEA 20', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LINEA 24', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LINEA 25', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LIONEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOBOS ARROYO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOS AMIGOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOS BRILLANTES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOS BURGOS APEADERO FCGU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOS CHARRÚAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOS CONQUISTADORES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOS CORRALES , cerro', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOS GANSOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOS OMBUES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOS PARAISOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LOS SAUER', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LUCAS , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LUCAS GONZÁLEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LUCAS NORESTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LUCAS NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LUCAS SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LUCIENVILLE 1', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LUCIENVILLE 2', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LUCIENVILLE 3', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'LUCIENVILLE 4', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MAC DOUGALL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MAC KELLER', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MACIÁ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MAGNASCO, OSVALDO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MANATIALES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MANDISOVí', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MANDOLEG', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MANGRULLO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MARíA GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MARIA GRANDE PRIMERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MARIA GRANDE SEGUNDA SUR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MARTINETTI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MARTINIANO LEGUIZAMON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MAURICIO RIBOLE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MAZARUCA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MÉDANOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MESA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MIGUEL J PERLIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MIÑONES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MIRA MONTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MOJONES NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MOJONES SUD PRIMERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MOJONES SUR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MOJONES SUR SEGUNDO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MOLINO BOB', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MOLINO DOLL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MONTE CHICO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MONTE VERDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MONTIEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MONTOYA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MONTOYA , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MONTOYA VICTORIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MOREIRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MOREYRA , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'MORO , cerro', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'NANDUBAYSAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'NICOLAS HERRERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'NOGOYÁ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'NOGOYÁ , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'NOVIBUCO PRIMERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'NUEVA ESCOCIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'NUEVA VIZCAYA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'OLEGARIO VICTOR ANDRADE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'OMBUES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ORO VERDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'OSVALDO MAGNASCO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'Otra', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PAJA BRAVA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PAJONAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PALACIO SAN JOSE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PALAVECINO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PALMAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PALMAR YATAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PALO A PIQUE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARACAO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARADA YUQUERI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARAJE GUAYABO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARAJE PALAVEROI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANÁ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANÁ , río', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANÁ , río', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANA BRAVO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANÁ BRAVO , río', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANÁ PAVÓN , río', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANACITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANACITO , Est. Libertador General San Martín', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANACITO , río', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANACITO ,EST.LIB.GRL.SAN MARTIN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARANACITO RIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARERA, FAUSTINO M.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARERA, RAMONA A. ,EMB.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARQUE NACIONAL DIAMANTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PARQUE NACIONAL EL PALMAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASAJE AL AGUILA RIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASAJE TALAVERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO DE LA ARENA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO DE LA BALZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO DE LA LAGUNA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO DE LA LEGUA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO DE LAS PIEDRAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO DEL ABRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO DEL CISNERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO DEL GALLO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO DEL MOLINO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO GARIBALDI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO MARGARINOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO MEDINA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO POTRILLO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO PUERTO AUGUSTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO SOCIEDAD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASO TELEGRAFO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PASTOR BRITOS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PEDERMAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PEDERNAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PEHUAJO NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PEHUAJO SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PERDICES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PERUCHO VERNA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PESQUERIA DIAMANTINO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PIEDRAS BLANCAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PILOTO ÁVILA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'POS POS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PRESIDENTE AVELLANEDA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PRIMER CONGRESO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PRIMER CONGRESO ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PRIMER CUARTEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PRIMER DISTRITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PRONUNCIAMIENTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLITO NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO ARRUA ,EST.ALCARAZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO BELLOCQ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO BELLOCQ , Est. Las Garzas', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO BRUGO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO CAZES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO COLORADO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO DOMINGUEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO ELLISON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO FERRÉ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO GENERAL BELGRANO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO GENERAL PAZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO GENERAL. PAZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO LEGUIZAMÓN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO LIEBIG', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO LIEBIG S', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUEBLO MORENO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE CARMONA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE DE GUALEGUAYCHU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE DE LAS PENCAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE DE LUCAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE DEL CHANAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE DEL DOLL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE INTERNACIONAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE INTERNACIONAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE INTERNACIONAL SALTO GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE NANCAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE OBISPO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE PARANACITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE PELLEGRINI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUENTE VICTORIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTA DE CRESPO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO ALGARROBO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO ALMIRON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO BARRILES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO CADENAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO CAMPINCHUELO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO COLORADO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO CONSTANZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO CUPALEN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO CURTIEMBRE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO DIAMANTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO ESQUINA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO IBICUY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO LA ESMERALDA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO LAS CUEVAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO LOPEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO MARQUEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO PERAZZO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO RUIZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO SAN JUAN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO UNZUÉ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO VIBORAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO VIEJO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO VILLARRUEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO YERUÁ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUERTO YUNQUE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUIGGARI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUNTA DEL MONTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUNTAS DE MOREIRA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUNTAS DEL GUALEGUAYCHU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'PUNTAS DEL PALMAR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'QUEBRACHITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'QUEBRACHO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'QUINTAS AL SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'QUINTO CUARTEL VICTORIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'QUINTO DISTRITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RACEDO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RACHEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RAíCES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RAíCES , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RAICES AL ESTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RAICES AL NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RAICES OESTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RAMIREZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RAMON A PARERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RAÚL URANGA-CARLOS SYLVESTRE BEGNIS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RINCON DE CINTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RINCON DE LAS GUACHAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RINCON DE MOJONES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RINCÓN DE NOGOYÁ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RINCON DEL DOLL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RINCON DEL GATO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RINCON DEL NOGOYA SUR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RINCON LUCAS NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RINCON LUCAS SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RIO ALFEREZ NELSON PAGE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RIO CEIBO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RIO PARANA GUAZU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RIO SAUCE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RIO TALAVERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ROCAMORA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ROSARIO DE TALA ,EST.TALA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ROSARIO DEL TALA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ROSPINA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'RUTA 14 KM 443', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAGASTUME', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SALADERO ALZUA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SALADERO CONCORDIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SALADERO SAN JOSE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN ANSELMO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN BENITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN CIPRIANO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN ERNESTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN GUSTAVO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN JAIME', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN JAIME DE LA FRONTERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN JORGE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN JOSÉ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN JOSÉ DE FELICIANO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN JUAN LA QUERENCIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN JULIAN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN JUSTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN LORENZO , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN LUIS SAN JOSE FELICIANO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN MIGUEL NRO 2', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN RAMIREZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN RAMÓN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN SALVADOR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAN VICTOR', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SANATORIO ADVENTISTA DEL PLATA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SANATORIO APEADERO FCGU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SANTA ANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SANTA ANITA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SANTA ELENA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SANTA JUANA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SANTA MARTA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SANTA SARA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SARANDI CORA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAUCE DE LUNA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAUCE MONTRULL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAUCE NORTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAUCE PINTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAUCE RIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAUCE SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SAXTO DISTRITO COLON', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SECCION URQUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SEGUí', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SEGUNDA SECCION LUCAS AL SUD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SEGUNDO CUARTEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SEPTIMO DISTRITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SEXTO DISTRITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SIR LEONARD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SOLA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SOSA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SPINDOLA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'STROBEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SUBCENTRAL SANTA MARIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'SURST', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TABLADA NORTE CONCORDIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TABLADA OESTE CONCORDIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TABOSSI', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TACUARAS YACARE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TALITA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TALITAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TALITAS ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TALITAS GUALEGUAYCHU', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TASES', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TENIENTE 1° BRIGIDO CAINZO ,AP.', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TEZANOS PINTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TIRO FEDERAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TOMAS ROCAMORA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TRES ALDEAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TRES BOCAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'TTE PRIMERO BRIGIO CAINZO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'UBAJAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'URDINARRAIN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'URQUIZA , arroyo', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VALLE MARIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VIALE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VIBORAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VICTORIA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VICTORIA , río', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA AIDA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA ANGELICA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA ANTONY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA BOREILO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA CLARA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA DEL CERRO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA DEL ROSARIO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA DOMINGUEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA DOMINGUEZ ,EST.DOMINGUEZ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA ELEONORA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA ELISA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA FAUSTINO M PARERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA GOB LUIS ETCHEVEHERE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA GOBERNADOR ECHEVERRíA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA HERNANDARIAS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA LIBERTAD', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA LIBERTADOR SAN MARTíN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA LILA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA MANTERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA MARIA GRANDE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA NUEVA MONTEVIDEO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA PARANACITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA PERPER', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA PORTENA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA ROMERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA SAN JOSE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA SAN JUSTO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA SAN MARCIAL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA SAN MARCIAL , Est. Gobernador Urquiza', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA SAN MARCIAL ,EST.GDR.URQUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA SAN MIGUEL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA TRES DE FEBRERO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA UDINE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA URANGA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA URQUIZA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLA ZORRAQUíN', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLAGUAY', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLAGUAY ESTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLAGUAYCITO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VILLAMIL', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VIRANO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'VIZCACHERA', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'WALTER MOSS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'YACARE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'YAROS', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'YERUÁ', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'YESO', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'YESO OESTE', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'YUQUERí', 'provincia': 'Entre Ríos' },
        { 'descripcion': 'ZENON ROCA', 'provincia': 'Entre Ríos' },
        { 'descripcion': '19 DE MARZO', 'provincia': 'Formosa' },
        { 'descripcion': 'AGENTE ARGENTINO ALEGRE', 'provincia': 'Formosa' },
        { 'descripcion': 'AGENTE FELIPE SANTIAGO IBANEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'AGUA VERDE', 'provincia': 'Formosa' },
        { 'descripcion': 'AGUAS NEGRAS', 'provincia': 'Formosa' },
        { 'descripcion': 'ALFONSINA STORNI', 'provincia': 'Formosa' },
        { 'descripcion': 'ALOLAGUE', 'provincia': 'Formosa' },
        { 'descripcion': 'ANDRADE OLEGARIO VICTOR', 'provincia': 'Formosa' },
        { 'descripcion': 'ANDRES FLORES', 'provincia': 'Formosa' },
        { 'descripcion': 'APAYEREY', 'provincia': 'Formosa' },
        { 'descripcion': 'APROVECHAMIENTO MÚLTIPLE Río TEUCO', 'provincia': 'Formosa' },
        { 'descripcion': 'AYUDANTE PAREDES', 'provincia': 'Formosa' },
        { 'descripcion': 'BAHIA NEGRA', 'provincia': 'Formosa' },
        { 'descripcion': 'BAJO VERDE', 'provincia': 'Formosa' },
        { 'descripcion': 'BALLON', 'provincia': 'Formosa' },
        { 'descripcion': 'BANADEROS', 'provincia': 'Formosa' },
        { 'descripcion': 'BANCO PAYAGUA', 'provincia': 'Formosa' },
        { 'descripcion': 'BARRIO SAN JOSE OBRERO', 'provincia': 'Formosa' },
        { 'descripcion': 'BARRIO SAN MARTIN', 'provincia': 'Formosa' },
        { 'descripcion': 'BARRIO SUD AMERICA', 'provincia': 'Formosa' },
        { 'descripcion': 'BARTOLOMÉ DE LAS CASAS', 'provincia': 'Formosa' },
        { 'descripcion': 'BOCA DEL RIACHO DE PILAGA', 'provincia': 'Formosa' },
        { 'descripcion': 'BOCARIN', 'provincia': 'Formosa' },
        { 'descripcion': 'BOLSA DE PALOMO', 'provincia': 'Formosa' },
        { 'descripcion': 'BOUVIER', 'provincia': 'Formosa' },
        { 'descripcion': 'BRIGADIER GENERAL PUEYRREDON', 'provincia': 'Formosa' },
        { 'descripcion': 'BRUCHARD', 'provincia': 'Formosa' },
        { 'descripcion': 'BUEN LUGAR', 'provincia': 'Formosa' },
        { 'descripcion': 'BUENA VISTA', 'provincia': 'Formosa' },
        { 'descripcion': 'CABALLO MUERTO', 'provincia': 'Formosa' },
        { 'descripcion': 'CABO 1RO CASIMIRO BENITEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'CABO 1RO. LUGONES', 'provincia': 'Formosa' },
        { 'descripcion': 'CABO ADRIANO AYALA', 'provincia': 'Formosa' },
        { 'descripcion': 'CABO NORONA', 'provincia': 'Formosa' },
        { 'descripcion': 'CABO PRIMERO CHAVEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'CAMPO ALEGRE', 'provincia': 'Formosa' },
        { 'descripcion': 'CAMPO AZCURRA', 'provincia': 'Formosa' },
        { 'descripcion': 'CAMPO EL SURI', 'provincia': 'Formosa' },
        { 'descripcion': 'CAMPO GORETA', 'provincia': 'Formosa' },
        { 'descripcion': 'CAMPO HARDY', 'provincia': 'Formosa' },
        { 'descripcion': 'CAMPO OSWALD', 'provincia': 'Formosa' },
        { 'descripcion': 'CAMPO RIGONATO', 'provincia': 'Formosa' },
        { 'descripcion': 'CANADA DOCE', 'provincia': 'Formosa' },
        { 'descripcion': 'CANADA SAN PEDRO', 'provincia': 'Formosa' },
        { 'descripcion': 'CAPILLA SAN ANTONIO', 'provincia': 'Formosa' },
        { 'descripcion': 'CAPITAN JUAN SOLA', 'provincia': 'Formosa' },
        { 'descripcion': 'CARLOS PELEGRINI', 'provincia': 'Formosa' },
        { 'descripcion': 'CARLOS SAAVEDRA LAMAS', 'provincia': 'Formosa' },
        { 'descripcion': 'CASCO CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'CATANEO CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'CEIBO TRECE', 'provincia': 'Formosa' },
        { 'descripcion': 'CENTRO FRONTERIZO CLORINDA', 'provincia': 'Formosa' },
        { 'descripcion': 'CHAGADAY', 'provincia': 'Formosa' },
        { 'descripcion': 'CHIROCHILAS', 'provincia': 'Formosa' },
        { 'descripcion': 'CHURQUI CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'CLORINDA', 'provincia': 'Formosa' },
        { 'descripcion': 'CMTE PRINCIPAL ISMAEL ST', 'provincia': 'Formosa' },
        { 'descripcion': 'CNEL MIGUEL MARTINEZ DE HOZ', 'provincia': 'Formosa' },
        { 'descripcion': 'CNIA ABORIGEN B BARTOLOME D', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA 5 DE OCTUBRE', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA 8 DE SETIEMBRE', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA ALFONSO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA ALTO ALEGRE', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA ALTO TIGRE', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA AQUINO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA BOUVIER', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA BUENA VISTA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA CAMPO VILLAFAÑE', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA CANO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA CORONEL DORREGO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA DALMACIA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA EL ALBA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA EL CATORCE', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA EL OLVIDO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA EL SILENCIO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA EL ZAPALLITO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA FRANCISCO J MUNIZ', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA GUILLERMINA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA ISLA ALVAREZ', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA ISLA DE ORO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA ISLA SOLA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA JUAN B ALBERDI', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA JUANITA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA KM. 503', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA LA BRAVA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA LA DISCIPLINA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA LA SOCIEDAD', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA LOS TRES REYES', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA PALMAR GRANDE', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA PASTORIL', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA PERIN', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA PUENTE PUCU', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA PUENTE URIBURU', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA RECONQUISTA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA SABINA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA SAN BERNARDO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA SAN ISIDRO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA SAN PABLO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA SANTA CATALINA', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA SANTORO', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA SIETE QUEBRADOS', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA TATANE', 'provincia': 'Formosa' },
        { 'descripcion': 'COLONIA VILLA RICA', 'provincia': 'Formosa' },
        { 'descripcion': 'COMANDANTE FONTANA', 'provincia': 'Formosa' },
        { 'descripcion': 'COMISARIA PTE YRIGOYEN', 'provincia': 'Formosa' },
        { 'descripcion': 'CORONEL ARGENTINO LARRABURE', 'provincia': 'Formosa' },
        { 'descripcion': 'CORONEL ENRIQUE ROSTAGNO', 'provincia': 'Formosa' },
        { 'descripcion': 'CORONEL FELIX BOGADO', 'provincia': 'Formosa' },
        { 'descripcion': 'CORONEL JOSE I WARNES', 'provincia': 'Formosa' },
        { 'descripcion': 'COSTA DEL LINDO', 'provincia': 'Formosa' },
        { 'descripcion': 'COSTA DEL PILCOMAYO', 'provincia': 'Formosa' },
        { 'descripcion': 'COSTA RIO NEGRO', 'provincia': 'Formosa' },
        { 'descripcion': 'COSTA SALADO', 'provincia': 'Formosa' },
        { 'descripcion': 'CURTIEMBRE CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'CURUPAY', 'provincia': 'Formosa' },
        { 'descripcion': 'DE LAS CASAS, BARTOLOME', 'provincia': 'Formosa' },
        { 'descripcion': 'DEL CAMPO, ESTANISLAO', 'provincia': 'Formosa' },
        { 'descripcion': 'DESVIO LOS MATACOS', 'provincia': 'Formosa' },
        { 'descripcion': 'DOCTOR CARLOS MONTAG', 'provincia': 'Formosa' },
        { 'descripcion': 'DOCTOR E.RAMOS MEJIAS ,EST.CHIRIGUANOS', 'provincia': 'Formosa' },
        { 'descripcion': 'DOCTOR GUMERSINDO SAYAGO', 'provincia': 'Formosa' },
        { 'descripcion': 'DOCTOR JOSE DE GASPRI', 'provincia': 'Formosa' },
        { 'descripcion': 'DOCTOR LUIS AGOTE', 'provincia': 'Formosa' },
        { 'descripcion': 'DOMINGO F SARMIENTO', 'provincia': 'Formosa' },
        { 'descripcion': 'DR E RAMOS MEJIA CHIRIGUANOS', 'provincia': 'Formosa' },
        { 'descripcion': 'EL ACHERAL', 'provincia': 'Formosa' },
        { 'descripcion': 'EL AIBALITO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL ALAMBRADO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL ANGELITO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL ARBOL SOLO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL AZOTADO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL BORDO SANTO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL BRAGADO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL CANON', 'provincia': 'Formosa' },
        { 'descripcion': 'EL CAVADO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL CHAÑARAL', 'provincia': 'Formosa' },
        { 'descripcion': 'EL COATI', 'provincia': 'Formosa' },
        { 'descripcion': 'EL COGOIK', 'provincia': 'Formosa' },
        { 'descripcion': 'EL COLORADO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL CORRALITO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL CORREDERO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL DESCANSO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL DESMONTE', 'provincia': 'Formosa' },
        { 'descripcion': 'EL GATO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL GUAJHO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL MARCADO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL MISTOLAR', 'provincia': 'Formosa' },
        { 'descripcion': 'EL NANDU', 'provincia': 'Formosa' },
        { 'descripcion': 'EL OCULTO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL OLVIDO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL OMBU', 'provincia': 'Formosa' },
        { 'descripcion': 'EL PALMAR', 'provincia': 'Formosa' },
        { 'descripcion': 'EL PALO SANTO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL PERDIDO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL PILON', 'provincia': 'Formosa' },
        { 'descripcion': 'EL PIMPIN', 'provincia': 'Formosa' },
        { 'descripcion': 'EL PINDO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL POI', 'provincia': 'Formosa' },
        { 'descripcion': 'EL POMBERO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL PORTENITO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL PORTENO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL POTRERITO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL QUEBRANTO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL RECREO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL REMANSO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL ROSADO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL SILENCIO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL SIMBOLAR', 'provincia': 'Formosa' },
        { 'descripcion': 'EL SOMBRERO NEGRO', 'provincia': 'Formosa' },
        { 'descripcion': 'EL SURR', 'provincia': 'Formosa' },
        { 'descripcion': 'EL TACURUZAL', 'provincia': 'Formosa' },
        { 'descripcion': 'EL TASTAS', 'provincia': 'Formosa' },
        { 'descripcion': 'EL TOTORAL', 'provincia': 'Formosa' },
        { 'descripcion': 'EL YACARE', 'provincia': 'Formosa' },
        { 'descripcion': 'EL YUCHAN', 'provincia': 'Formosa' },
        { 'descripcion': 'EL YULO', 'provincia': 'Formosa' },
        { 'descripcion': 'ESPINILLO', 'provincia': 'Formosa' },
        { 'descripcion': 'ESQUINITAS', 'provincia': 'Formosa' },
        { 'descripcion': 'ESTANCIA EL CIERVO', 'provincia': 'Formosa' },
        { 'descripcion': 'ESTANCIA LAS HORQUETAS', 'provincia': 'Formosa' },
        { 'descripcion': 'ESTANISLAO DEL CAMPO', 'provincia': 'Formosa' },
        { 'descripcion': 'ESTERITO', 'provincia': 'Formosa' },
        { 'descripcion': 'ESTERO GRANDE', 'provincia': 'Formosa' },
        { 'descripcion': 'ESTERO PATINO', 'provincia': 'Formosa' },
        { 'descripcion': 'ESTERO PATIÑO ,AP.', 'provincia': 'Formosa' },
        { 'descripcion': 'EX FORTIN SOLA', 'provincia': 'Formosa' },
        { 'descripcion': 'EX POSTA GENERAL LAVALLE', 'provincia': 'Formosa' },
        { 'descripcion': 'FLORENCIO SANCHEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'FORMOSA', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTíN CABO 1', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTIN CABO 1 LUGONES', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTIN CABO 1RO CHAVES', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTIN CABO 1RO CHAVEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTIN FONTANA', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTIN GALPON', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTIN GUEMES', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTIN LA SOLEDAD', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTIN MEDIA LUNA', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTíN PILCOMAYO', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTíN SARGENTO 1', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTIN SARGENTO 1º LEYES', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTIN SARGENTO 1RO LEYES', 'provincia': 'Formosa' },
        { 'descripcion': 'FORTíN SOLEDAD', 'provincia': 'Formosa' },
        { 'descripcion': 'FRANCISCO NARCISO DE LAPRIDA', 'provincia': 'Formosa' },
        { 'descripcion': 'FRAY MAMERTO ESQUIU', 'provincia': 'Formosa' },
        { 'descripcion': 'GABRIELA MISTRAL', 'provincia': 'Formosa' },
        { 'descripcion': 'GARCETE CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'GENDARME VIVIANO GARCETE', 'provincia': 'Formosa' },
        { 'descripcion': 'GENERAL ENRIQUE MOSCONI', 'provincia': 'Formosa' },
        { 'descripcion': 'GENERAL FRANCISCO B BOSCH', 'provincia': 'Formosa' },
        { 'descripcion': 'GENERAL FRANCISCO BASILIANO BOSCH', 'provincia': 'Formosa' },
        { 'descripcion': 'GENERAL JULIO DE VEDIA', 'provincia': 'Formosa' },
        { 'descripcion': 'GENERAL LUCIO V. MANSILLA', 'provincia': 'Formosa' },
        { 'descripcion': 'GENERAL LUCIO VICTORIO MANSILL', 'provincia': 'Formosa' },
        { 'descripcion': 'GENERAL MANUEL BELGRANO', 'provincia': 'Formosa' },
        { 'descripcion': 'GENERAL PABLO RICCHIERI', 'provincia': 'Formosa' },
        { 'descripcion': 'GOBERNADOR LUNA OLMOS', 'provincia': 'Formosa' },
        { 'descripcion': 'GOBERNADOR YALUR', 'provincia': 'Formosa' },
        { 'descripcion': 'GRAL IGNACIO H FOTHERINGHAM', 'provincia': 'Formosa' },
        { 'descripcion': 'GRAN GUARDIA', 'provincia': 'Formosa' },
        { 'descripcion': 'GUADALCAZAR', 'provincia': 'Formosa' },
        { 'descripcion': 'GUAYCOLEC', 'provincia': 'Formosa' },
        { 'descripcion': 'HERMINDO BONAS', 'provincia': 'Formosa' },
        { 'descripcion': 'HERRADURA', 'provincia': 'Formosa' },
        { 'descripcion': 'HIPOLITO VIEYTES', 'provincia': 'Formosa' },
        { 'descripcion': 'HOSPITAL RURAL', 'provincia': 'Formosa' },
        { 'descripcion': 'IBARRETA', 'provincia': 'Formosa' },
        { 'descripcion': 'ING GUILLERMO N JUAREZ', 'provincia': 'Formosa' },
        { 'descripcion': 'INGENIERO ENRIQUE H FAURE', 'provincia': 'Formosa' },
        { 'descripcion': 'INGENIERO ENRIQUE H.FAURE', 'provincia': 'Formosa' },
        { 'descripcion': 'INGENIERO GUILLERMO N. JUÁREZ', 'provincia': 'Formosa' },
        { 'descripcion': 'ISLA 9 DE JULIO', 'provincia': 'Formosa' },
        { 'descripcion': 'ISLA APANGO', 'provincia': 'Formosa' },
        { 'descripcion': 'ISLA CARAYA', 'provincia': 'Formosa' },
        { 'descripcion': 'ISLA DE PUEN', 'provincia': 'Formosa' },
        { 'descripcion': 'ISLA OCA', 'provincia': 'Formosa' },
        { 'descripcion': 'ISLA PAYAGUA', 'provincia': 'Formosa' },
        { 'descripcion': 'ISLA TOLDO', 'provincia': 'Formosa' },
        { 'descripcion': 'ISLETA', 'provincia': 'Formosa' },
        { 'descripcion': 'JOAQUIN V GONZALEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'JOSE CANCIO', 'provincia': 'Formosa' },
        { 'descripcion': 'JOSE HERNANDEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'JOSE MANUEL ESTRADA', 'provincia': 'Formosa' },
        { 'descripcion': 'JUAN G BAZAN', 'provincia': 'Formosa' },
        { 'descripcion': 'JUAN G. BAZÁN', 'provincia': 'Formosa' },
        { 'descripcion': 'JULIO CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'KILOMETRO 1769', 'provincia': 'Formosa' },
        { 'descripcion': 'KILOMETRO 1895 ,AP.', 'provincia': 'Formosa' },
        { 'descripcion': 'KILOMETRO 213', 'provincia': 'Formosa' },
        { 'descripcion': 'LA BLANCA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA CHINA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA COLONIA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA ESPERANZA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA FLORENCIA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA FRONTERA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA INMACULADA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA ISLETA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA JUNTA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA LIBERTAD', 'provincia': 'Formosa' },
        { 'descripcion': 'LA LOMA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA LUCRECIA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA MANIJA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA MEDIA LUNA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA NOBLEZA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA PALMA SOLA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA PALMITA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA PALOMA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA PASION', 'provincia': 'Formosa' },
        { 'descripcion': 'LA PICADITA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA PRIMAVERA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA SIRENA', 'provincia': 'Formosa' },
        { 'descripcion': 'LA SOLEDAD', 'provincia': 'Formosa' },
        { 'descripcion': 'LA URBANA', 'provincia': 'Formosa' },
        { 'descripcion': 'LAGUNA BLANCA', 'provincia': 'Formosa' },
        { 'descripcion': 'LAGUNA GALLO', 'provincia': 'Formosa' },
        { 'descripcion': 'LAGUNA INES', 'provincia': 'Formosa' },
        { 'descripcion': 'LAGUNA MURUA', 'provincia': 'Formosa' },
        { 'descripcion': 'LAGUNA NAICK NECK', 'provincia': 'Formosa' },
        { 'descripcion': 'LAGUNA NAICK-NECK', 'provincia': 'Formosa' },
        { 'descripcion': 'LAGUNA YEMA', 'provincia': 'Formosa' },
        { 'descripcion': 'LAMADRID', 'provincia': 'Formosa' },
        { 'descripcion': 'LAPRIDA, FRANCISCO NARCISO DE', 'provincia': 'Formosa' },
        { 'descripcion': 'LAS BOLIVIANAS', 'provincia': 'Formosa' },
        { 'descripcion': 'LAS CHOYAS', 'provincia': 'Formosa' },
        { 'descripcion': 'LAS LOLAS', 'provincia': 'Formosa' },
        { 'descripcion': 'LAS LOMITAS', 'provincia': 'Formosa' },
        { 'descripcion': 'LAS MOCHAS', 'provincia': 'Formosa' },
        { 'descripcion': 'LAZO QUEMADO', 'provincia': 'Formosa' },
        { 'descripcion': 'LEGUA A', 'provincia': 'Formosa' },
        { 'descripcion': 'LOA MATACOS', 'provincia': 'Formosa' },
        { 'descripcion': 'LOMA CLAVEL', 'provincia': 'Formosa' },
        { 'descripcion': 'LOMA SENE', 'provincia': 'Formosa' },
        { 'descripcion': 'LOMA ZAPATU', 'provincia': 'Formosa' },
        { 'descripcion': 'LORO CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS CHAGUANCOS', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS CHIRIGUANOS', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS CLAVELES', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS ESTEROS', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS GALPONES', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS INMIGRANTES', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS MATACOS', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS NIDOS', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS PILAGAS', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS PILAGAS ,EMB.', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS POCITOS', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS SUSPIROS', 'provincia': 'Formosa' },
        { 'descripcion': 'LOS TRES REYES', 'provincia': 'Formosa' },
        { 'descripcion': 'LUCERO CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'MAESTRA BLANCA GOMEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'MAESTRO FERMIN BAEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'MALVINAS , riacho', 'provincia': 'Formosa' },
        { 'descripcion': 'MARCA M', 'provincia': 'Formosa' },
        { 'descripcion': 'MARIA CRISTINA', 'provincia': 'Formosa' },
        { 'descripcion': 'MARIANO BOEDO', 'provincia': 'Formosa' },
        { 'descripcion': 'MATIAS GULACSI', 'provincia': 'Formosa' },
        { 'descripcion': 'MAYOR MARCELO T ROJAS', 'provincia': 'Formosa' },
        { 'descripcion': 'MAYOR VICENTE E VILLAFANE', 'provincia': 'Formosa' },
        { 'descripcion': 'MEDIA LUNA', 'provincia': 'Formosa' },
        { 'descripcion': 'MERCEDES CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'MISION EL CARMEN', 'provincia': 'Formosa' },
        { 'descripcion': 'MISION EVANGELICA LAGUNA DE YA', 'provincia': 'Formosa' },
        { 'descripcion': 'MISIÓN TACAAGLÉ', 'provincia': 'Formosa' },
        { 'descripcion': 'MISION YACARE', 'provincia': 'Formosa' },
        { 'descripcion': 'MISTOL MARCADO', 'provincia': 'Formosa' },
        { 'descripcion': 'MITRE , península', 'provincia': 'Formosa' },
        { 'descripcion': 'MOJON DE FIERRO', 'provincia': 'Formosa' },
        { 'descripcion': 'MONSENOR DE ANDREA', 'provincia': 'Formosa' },
        { 'descripcion': 'MONTE AGUDO', 'provincia': 'Formosa' },
        { 'descripcion': 'MONTE CLARO', 'provincia': 'Formosa' },
        { 'descripcion': 'MONTE LINDO', 'provincia': 'Formosa' },
        { 'descripcion': 'MONTE LINDO , arroyo', 'provincia': 'Formosa' },
        { 'descripcion': 'MONTE LINDO , riacho', 'provincia': 'Formosa' },
        { 'descripcion': 'MONTE LINDO CHICO', 'provincia': 'Formosa' },
        { 'descripcion': 'MONTE LINDO CHICO , arroyo', 'provincia': 'Formosa' },
        { 'descripcion': 'MONTE LINDO CNIA PASTORIL', 'provincia': 'Formosa' },
        { 'descripcion': 'MONTE LINDO GRANDE , riacho', 'provincia': 'Formosa' },
        { 'descripcion': 'NICORA', 'provincia': 'Formosa' },
        { 'descripcion': 'NUEVO PILCOMAYO', 'provincia': 'Formosa' },
        { 'descripcion': 'Otra', 'provincia': 'Formosa' },
        { 'descripcion': 'PALMA SOLA', 'provincia': 'Formosa' },
        { 'descripcion': 'PALMAR CHICO', 'provincia': 'Formosa' },
        { 'descripcion': 'PALMAR LARGO', 'provincia': 'Formosa' },
        { 'descripcion': 'PALMARCITO', 'provincia': 'Formosa' },
        { 'descripcion': 'PALO SANTO', 'provincia': 'Formosa' },
        { 'descripcion': 'PARA TODO', 'provincia': 'Formosa' },
        { 'descripcion': 'PARQUE BOTANICO FORESTAL IGR L', 'provincia': 'Formosa' },
        { 'descripcion': 'PARQUE NACIONAL', 'provincia': 'Formosa' },
        { 'descripcion': 'PARQUE NACIONAL RíO PILCOMAYO', 'provincia': 'Formosa' },
        { 'descripcion': 'PASO DE LOS TOBAS', 'provincia': 'Formosa' },
        { 'descripcion': 'PASO DE NAITE', 'provincia': 'Formosa' },
        { 'descripcion': 'PASO LA CRUZ', 'provincia': 'Formosa' },
        { 'descripcion': 'PASO NALTE', 'provincia': 'Formosa' },
        { 'descripcion': 'PASO, JUAN JOSE', 'provincia': 'Formosa' },
        { 'descripcion': 'PATO MARCADO', 'provincia': 'Formosa' },
        { 'descripcion': 'PAVAO', 'provincia': 'Formosa' },
        { 'descripcion': 'PIGO', 'provincia': 'Formosa' },
        { 'descripcion': 'PILAGÁ , riacho', 'provincia': 'Formosa' },
        { 'descripcion': 'PILANGA III', 'provincia': 'Formosa' },
        { 'descripcion': 'PIRANÉ', 'provincia': 'Formosa' },
        { 'descripcion': 'PONCHO QUEMADO', 'provincia': 'Formosa' },
        { 'descripcion': 'PORTEÑO VIEJO', 'provincia': 'Formosa' },
        { 'descripcion': 'PORTON NEGRO', 'provincia': 'Formosa' },
        { 'descripcion': 'POSTA CAMBIO a ZALAZAR', 'provincia': 'Formosa' },
        { 'descripcion': 'POSTA KILOMETRO 45', 'provincia': 'Formosa' },
        { 'descripcion': 'POSTA LENCINA', 'provincia': 'Formosa' },
        { 'descripcion': 'POSTA SAN MARTIN 1', 'provincia': 'Formosa' },
        { 'descripcion': 'POSTA SAN MARTIN 2', 'provincia': 'Formosa' },
        { 'descripcion': 'POSTA SARGENTO CABRAL', 'provincia': 'Formosa' },
        { 'descripcion': 'POTRERO DE LOS CABALLOS', 'provincia': 'Formosa' },
        { 'descripcion': 'POTRERO NORTE', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DE LA YEGUA', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DE LAS BOTIJAS', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DE LAS GARZAS', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DE LOS CHANCHOS', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DE MARA', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DE MAZA', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DE NAVAGAN', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DEL CUCHILLO', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DEL LEON', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DEL MAZA', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DEL MORTERO', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO DEL TIGRE', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO EL LECHERON', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO LA CHINA', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO LA NEGRA', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO VERDE', 'provincia': 'Formosa' },
        { 'descripcion': 'POZO VERDE INGENIERO G N JU', 'provincia': 'Formosa' },
        { 'descripcion': 'PRESIDENTE AVELLANEDA', 'provincia': 'Formosa' },
        { 'descripcion': 'PRESIDENTE YRIGOYEN', 'provincia': 'Formosa' },
        { 'descripcion': 'PRIMAVERA', 'provincia': 'Formosa' },
        { 'descripcion': 'PUENTE INTERNACIONAL', 'provincia': 'Formosa' },
        { 'descripcion': 'PUERTO CABO IRIGOYEN', 'provincia': 'Formosa' },
        { 'descripcion': 'PUERTO DALMACIA', 'provincia': 'Formosa' },
        { 'descripcion': 'PUERTO IRIGOYEN', 'provincia': 'Formosa' },
        { 'descripcion': 'PUERTO PILCOMAYO', 'provincia': 'Formosa' },
        { 'descripcion': 'PUERTO RAMONA', 'provincia': 'Formosa' },
        { 'descripcion': 'PUERTO VELAZ', 'provincia': 'Formosa' },
        { 'descripcion': 'PUESTO AGUARA', 'provincia': 'Formosa' },
        { 'descripcion': 'PUNTA GUIA', 'provincia': 'Formosa' },
        { 'descripcion': 'PUNTA PORÁ', 'provincia': 'Formosa' },
        { 'descripcion': 'QUEBRACHO MARCADO', 'provincia': 'Formosa' },
        { 'descripcion': 'RACEDO ESCOBAR', 'provincia': 'Formosa' },
        { 'descripcion': 'RANERO CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'REDUCCION CACIQUE COQUENA', 'provincia': 'Formosa' },
        { 'descripcion': 'RESERVA NATURAL FORMOSA', 'provincia': 'Formosa' },
        { 'descripcion': 'RIACHO HÉ - HÉ', 'provincia': 'Formosa' },
        { 'descripcion': 'RIACHO HE HE', 'provincia': 'Formosa' },
        { 'descripcion': 'RIACHO LINDO', 'provincia': 'Formosa' },
        { 'descripcion': 'RIACHO NEGRO', 'provincia': 'Formosa' },
        { 'descripcion': 'RIACHO RAMIREZ', 'provincia': 'Formosa' },
        { 'descripcion': 'RICARDO GUIRALDES', 'provincia': 'Formosa' },
        { 'descripcion': 'RINCON FLORIDO', 'provincia': 'Formosa' },
        { 'descripcion': 'RINCON NANDU', 'provincia': 'Formosa' },
        { 'descripcion': 'RINCON NARO', 'provincia': 'Formosa' },
        { 'descripcion': 'RIO CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'RODEO TAPITI', 'provincia': 'Formosa' },
        { 'descripcion': 'ROZADITO', 'provincia': 'Formosa' },
        { 'descripcion': 'SALADO , arroyo', 'provincia': 'Formosa' },
        { 'descripcion': 'SALADO , riacho', 'provincia': 'Formosa' },
        { 'descripcion': 'SALADO , río', 'provincia': 'Formosa' },
        { 'descripcion': 'SALVACION', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN CAMILO', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN FRANCISCO DE LAISHI', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN HILARIO', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN IGNACIO DE LOYOLA', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN ISIDRO', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN JACINTO', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN MARTIN 1', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN MARTíN 2', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN MARTIN II', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN RAMON', 'provincia': 'Formosa' },
        { 'descripcion': 'SAN SIMON', 'provincia': 'Formosa' },
        { 'descripcion': 'SARGENTO AGRAMONTE', 'provincia': 'Formosa' },
        { 'descripcion': 'SARGENTO AYUDANTE VICTOR SANABRIA', 'provincia': 'Formosa' },
        { 'descripcion': 'SATURNINO SEGUROLA', 'provincia': 'Formosa' },
        { 'descripcion': 'SEGUNDA PUNTA', 'provincia': 'Formosa' },
        { 'descripcion': 'SELVA MARIA', 'provincia': 'Formosa' },
        { 'descripcion': 'SIETE PALMAS', 'provincia': 'Formosa' },
        { 'descripcion': 'SOLDADO ALBERTO VILLALBA', 'provincia': 'Formosa' },
        { 'descripcion': 'SOLDADO DANTE SALVATIERRA', 'provincia': 'Formosa' },
        { 'descripcion': 'SOLDADO EDMUNDO SOSA', 'provincia': 'Formosa' },
        { 'descripcion': 'SOLDADO ERMINDO LUNA', 'provincia': 'Formosa' },
        { 'descripcion': 'SOLDADO HERIBERTO AVALOS', 'provincia': 'Formosa' },
        { 'descripcion': 'SOLDADO ISMAEL SANCHEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'SOLDADO MARCELINO TORALES', 'provincia': 'Formosa' },
        { 'descripcion': 'SOLDADO RAMON A ARRIETA', 'provincia': 'Formosa' },
        { 'descripcion': 'SOLDADO TOMAS SANCHEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'SOMBRERO NEGRO', 'provincia': 'Formosa' },
        { 'descripcion': 'STO AYUDANTE V SANABRIA', 'provincia': 'Formosa' },
        { 'descripcion': 'STO MAYOR BERNARDO AGUILA', 'provincia': 'Formosa' },
        { 'descripcion': 'SUBTENIENTE PERíN', 'provincia': 'Formosa' },
        { 'descripcion': 'SUBTTE RICARDO E MASAFERRO', 'provincia': 'Formosa' },
        { 'descripcion': 'SUMAYEN', 'provincia': 'Formosa' },
        { 'descripcion': 'TATANE', 'provincia': 'Formosa' },
        { 'descripcion': 'TATU PIRE', 'provincia': 'Formosa' },
        { 'descripcion': 'TATÚ PIRÉ , arroyo', 'provincia': 'Formosa' },
        { 'descripcion': 'TENIENTE BROWN', 'provincia': 'Formosa' },
        { 'descripcion': 'TENIENTE GENERAL JUAN CARLOS SANCHEZ', 'provincia': 'Formosa' },
        { 'descripcion': 'TENIENTE GENERAL ROSENDO M FRAGA', 'provincia': 'Formosa' },
        { 'descripcion': 'TENIENTE GENERAL ROSENDO M. FRAGA', 'provincia': 'Formosa' },
        { 'descripcion': 'TIMBO PORA', 'provincia': 'Formosa' },
        { 'descripcion': 'TOMAS GODOY CRUZ', 'provincia': 'Formosa' },
        { 'descripcion': 'TORO PASO', 'provincia': 'Formosa' },
        { 'descripcion': 'TRANSITO CUE', 'provincia': 'Formosa' },
        { 'descripcion': 'TRES LAGUNAS', 'provincia': 'Formosa' },
        { 'descripcion': 'TRES LAGUNAS HERRADURA', 'provincia': 'Formosa' },
        { 'descripcion': 'TRES LAGUNAS PILCOMAYO', 'provincia': 'Formosa' },
        { 'descripcion': 'TRES MARIAS', 'provincia': 'Formosa' },
        { 'descripcion': 'TRES POCITOS', 'provincia': 'Formosa' },
        { 'descripcion': 'TTE CNEL GASPAR CAMPOS', 'provincia': 'Formosa' },
        { 'descripcion': 'TTE GRAL JUAN C SANCHE', 'provincia': 'Formosa' },
        { 'descripcion': 'TTE GRAL ROSENDO N FRA', 'provincia': 'Formosa' },
        { 'descripcion': 'URBANA VIEJA', 'provincia': 'Formosa' },
        { 'descripcion': 'VACA PERDIDA', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA ADELAIDA', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA DOS TRECE', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA EMILIA', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA ESCOLAR', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA GENERAL GUEMES', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA GENERAL M. BELGRANO', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA GENERAL URQUIZA', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA GRAL MANUEL BELGRANO', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA HERMOSA', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA LUCERO', 'provincia': 'Formosa' },
        { 'descripcion': 'VILLA REAL', 'provincia': 'Formosa' },
        { 'descripcion': 'VIRASOL', 'provincia': 'Formosa' },
        { 'descripcion': 'YEMA , laguna', 'provincia': 'Formosa' },
        { 'descripcion': 'YUNCA', 'provincia': 'Formosa' },
        { 'descripcion': 'ZORRILLA CUE', 'provincia': 'Formosa' },
        { 'descripcion': '23 DE AGOSTO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ABDÓN CASTRO TOLAY', 'provincia': 'Jujuy' },
        { 'descripcion': 'ABRA DE PENAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'ABRA DE PIVES', 'provincia': 'Jujuy' },
        { 'descripcion': 'ABRA DEL TRIGO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ABRA LAITE', 'provincia': 'Jujuy' },
        { 'descripcion': 'ABRA PAMPA', 'provincia': 'Jujuy' },
        { 'descripcion': 'ABRALAITE', 'provincia': 'Jujuy' },
        { 'descripcion': 'ACHACAMAYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'AGUA BENDITA', 'provincia': 'Jujuy' },
        { 'descripcion': 'AGUA CALIENTE', 'provincia': 'Jujuy' },
        { 'descripcion': 'AGUA CALIENTE DE LA PUNA', 'provincia': 'Jujuy' },
        { 'descripcion': 'AGUA CHICA', 'provincia': 'Jujuy' },
        { 'descripcion': 'AGUA DE CASTILLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'AGUA NEGRA', 'provincia': 'Jujuy' },
        { 'descripcion': 'AGUA PALOMAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'AGUADITA', 'provincia': 'Jujuy' },
        { 'descripcion': 'AGUAS CALIENTES', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALEGRIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALFARCITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALISOS , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALISOS DE ABAJO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALISOS DE ARRIBA', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTA , sierra', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO CALILEGUA', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO COMEDERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO DE CASA', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO DE LOZANO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO DE MOJON', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO DEL ANGOSTO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO DEL SALADILLO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO HUANCAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO LA TORRE', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO LA VIÑA', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO MINERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO POTRERILLO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO QUEMADO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ALTO QUIRQUINCHO', 'provincia': 'Jujuy' },
        { 'descripcion': 'AMANCAYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'AMARGURAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'ANGOSTO DEL PERCHEL', 'provincia': 'Jujuy' },
        { 'descripcion': 'ANIMAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'ANTIGUO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ANTIGUYOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'ANTUMPA', 'provincia': 'Jujuy' },
        { 'descripcion': 'APAREJO', 'provincia': 'Jujuy' },
        { 'descripcion': 'APARZO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ARBOLITO NUEVO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ARENAL BARROSO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ARRAYANAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'ARROYO COLORADO', 'provincia': 'Jujuy' },
        { 'descripcion': 'AZUL PAMPA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BAJADA ALTA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BALIAZO', 'provincia': 'Jujuy' },
        { 'descripcion': 'BALLIAZO', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARCENA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO 9 DE JULIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO ALBERDI', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO ALTO LA LOMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO ALTO LA VINA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO BAJO LA VINA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO CHIJRA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO CUYAYA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO LA PROVIDENCIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO LA UNION', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO LUJAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO PARQUE 19 DE ABRIL', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIO SANTA RITA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRIOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'BARRO NEGRO', 'provincia': 'Jujuy' },
        { 'descripcion': 'BATEAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'BOLETERIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BOMBA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BORDO LA ISLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'BRANQUI , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'CABRERIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CACHI PUNCO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CACHIHUAICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CACHO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CADILLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CAIMANCITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CAJAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'CALAHOYO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CALETE', 'provincia': 'Jujuy' },
        { 'descripcion': 'CALILEGUA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CAMPO BAJO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CAMPO COLORADO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CAMPO LA TUNA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CAMPO OCULTO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CANAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'CANCHAHUASI', 'provincia': 'Jujuy' },
        { 'descripcion': 'CANCHUELA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CANGREJILLOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'CANGREJOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'CAPACHACRA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CAPLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CARACARA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CARAHUASI', 'provincia': 'Jujuy' },
        { 'descripcion': 'CARAHUNCO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CARAYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'CARCEL', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASA COLORADA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASA NEGRA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASA VIEJA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASABINDO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASAYOCK', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASILLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASILLAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASIRA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASPALÁ', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASTI', 'provincia': 'Jujuy' },
        { 'descripcion': 'CASTRO TOLAY, ABDON', 'provincia': 'Jujuy' },
        { 'descripcion': 'CATAMONTANA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CATARI', 'provincia': 'Jujuy' },
        { 'descripcion': 'CATÚA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CAUCHARI', 'provincia': 'Jujuy' },
        { 'descripcion': 'CEIBAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'CEIBAL , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'CENTRO FORESTAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'CERRILLOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'CERRO CHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CERRO REDONDO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CERROS ZAPLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CEVILAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHALICAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHAMICAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHANAR SOLO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHANARAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHANARCITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHANI', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHAÑI ,AP.', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHANI CHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHAUPI RODERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHILCAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHILCAYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHOCOITE', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHORCAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHOROJRA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHORRILLO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHORRO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHUCALEZNA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHULIN O INCA NUEVA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHUQUINA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CHURQUI', 'provincia': 'Jujuy' },
        { 'descripcion': 'CIANZO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CIENAGA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CIENAGA GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'CIENAGUILLAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'CIENEGO GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'CIENEGUILLAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'CINCEL', 'provincia': 'Jujuy' },
        { 'descripcion': 'CINCEL , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'CIUDAD DE PERICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'COCHAGATE', 'provincia': 'Jujuy' },
        { 'descripcion': 'COCHINOCA', 'provincia': 'Jujuy' },
        { 'descripcion': 'COCTACA', 'provincia': 'Jujuy' },
        { 'descripcion': 'COIRURO', 'provincia': 'Jujuy' },
        { 'descripcion': 'COLONIA 8 DE SEPTIEMBRE', 'provincia': 'Jujuy' },
        { 'descripcion': 'COLONIA LOS LAPACHOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'COLORADO , arroyo', 'provincia': 'Jujuy' },
        { 'descripcion': 'COLORADO , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'COLORADOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'CONDOR', 'provincia': 'Jujuy' },
        { 'descripcion': 'CÓNDOR , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'CÓNDOR , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'CORANZULí', 'provincia': 'Jujuy' },
        { 'descripcion': 'CORANZULLI', 'provincia': 'Jujuy' },
        { 'descripcion': 'CORAYA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CORDILLERA ORIENTAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'CORONEL ARIAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'CORRAL BLANCO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CORRAL DE PIEDRAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'COSTILLAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'COYAGAIMA , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'COYAGUAIMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'CRUZ NIDO', 'provincia': 'Jujuy' },
        { 'descripcion': 'CUSI CUSI', 'provincia': 'Jujuy' },
        { 'descripcion': 'de CALILEGUA , serranía', 'provincia': 'Jujuy' },
        { 'descripcion': 'de CAUCHARI , salar', 'provincia': 'Jujuy' },
        { 'descripcion': 'de COCHINOCA , sierra', 'provincia': 'Jujuy' },
        { 'descripcion': 'de COLANG', 'provincia': 'Jujuy' },
        { 'descripcion': 'de COMECHINGONES , sierra', 'provincia': 'Jujuy' },
        { 'descripcion': 'de COPACABANA , sierra', 'provincia': 'Jujuy' },
        { 'descripcion': 'de ESCOBAR , quebrada', 'provincia': 'Jujuy' },
        { 'descripcion': 'de GUAYATAYOC , laguna', 'provincia': 'Jujuy' },
        { 'descripcion': 'de HUANACACHE , lagunas', 'provincia': 'Jujuy' },
        { 'descripcion': 'de HUMAHUACA , quebrada', 'provincia': 'Jujuy' },
        { 'descripcion': 'de INCAHUASI , sierra', 'provincia': 'Jujuy' },
        { 'descripcion': 'de JAMA , paso', 'provincia': 'Jujuy' },
        { 'descripcion': 'de JAMA ,salar', 'provincia': 'Jujuy' },
        { 'descripcion': 'de LECHO , quebrada', 'provincia': 'Jujuy' },
        { 'descripcion': 'de LOS POZUELOS , laguna', 'provincia': 'Jujuy' },
        { 'descripcion': 'de OLAROZ , salar', 'provincia': 'Jujuy' },
        { 'descripcion': 'de QUICHAGUA , sierra', 'provincia': 'Jujuy' },
        { 'descripcion': 'de QUILMES o del CAJÓN , sierra', 'provincia': 'Jujuy' },
        { 'descripcion': 'de QUISTO , arroyo', 'provincia': 'Jujuy' },
        { 'descripcion': 'de VILAMA , laguna', 'provincia': 'Jujuy' },
        { 'descripcion': 'de YAVí o de CASTI , quebrada', 'provincia': 'Jujuy' },
        { 'descripcion': 'de ZAPALERI , serranías', 'provincia': 'Jujuy' },
        { 'descripcion': 'del AGUILAR , sierra', 'provincia': 'Jujuy' },
        { 'descripcion': 'del CENTINELA , sierra', 'provincia': 'Jujuy' },
        { 'descripcion': 'DOGLONZO', 'provincia': 'Jujuy' },
        { 'descripcion': 'DON JORGE', 'provincia': 'Jujuy' },
        { 'descripcion': 'DONCELLAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'DURAZNAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL ACHERAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL AGUILAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL ALGARROBAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL AMANCAY', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL ANGOSTO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL ARENAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL BANANAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL BRETE', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL CABRAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL CALLEJON', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL CARDONAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL CARMEN', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL CARMEN PERICO DE', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL CARRIL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL CAULARIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL CEIBAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL CHUCUPAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL CUCHO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL FUERTE', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL MANANTIAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL MISTOL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL MONUMENTO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL MORENO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL MORRO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL OLLERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL OLVIDO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL PALMAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL PALMAR DE SAN FRANCISCO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL PARQUE', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL PIQUETE', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL PONGO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL PORVENIR', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL POTRERO DE LA PUNA', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL QUEMADO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL REMATE', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL RIO NEGRO', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL SUNCHAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL TIPAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL TOBA', 'provincia': 'Jujuy' },
        { 'descripcion': 'EL TORO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ENTRE RIOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'ESCAYA', 'provincia': 'Jujuy' },
        { 'descripcion': 'ESQUINA DE HUANCAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'ESQUINA GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'ESQUINAS BLANCAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'ESTACIÓN OLACAPATO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ESTACION PERICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ESTACION ZOOTECNICA', 'provincia': 'Jujuy' },
        { 'descripcion': 'ESTANCIA GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'FALDA DEL QUEBRACHAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'FALDA MOJON', 'provincia': 'Jujuy' },
        { 'descripcion': 'FALDA MONTOSA', 'provincia': 'Jujuy' },
        { 'descripcion': 'FARILLON', 'provincia': 'Jujuy' },
        { 'descripcion': 'FINCA AGUA SALADA', 'provincia': 'Jujuy' },
        { 'descripcion': 'FINCA AGUA TAPADA', 'provincia': 'Jujuy' },
        { 'descripcion': 'FINCA LA LUCRECIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'FINCA LA REALIDAD', 'provincia': 'Jujuy' },
        { 'descripcion': 'FINCA LEACH', 'provincia': 'Jujuy' },
        { 'descripcion': 'FINCA SANTA CORNELIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'FRAILE PINTADO', 'provincia': 'Jujuy' },
        { 'descripcion': 'FUNDICIONES', 'provincia': 'Jujuy' },
        { 'descripcion': 'GALAN, JUAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'GALETA', 'provincia': 'Jujuy' },
        { 'descripcion': 'GENERAL MANUEL SAVIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'GENERAL SAN MARTíN , Est. Ledesma', 'provincia': 'Jujuy' },
        { 'descripcion': 'GOBERNADOR OVEJERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'GOBERNADOR TELLO', 'provincia': 'Jujuy' },
        { 'descripcion': 'GRAL MANUEL SAVIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'GRANADA II , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'GRANADAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'GRANADAS , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'GRANDE , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'GRANDE DE SAN JUAN , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'GUACHAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'GUAYATAYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'GUEMES', 'provincia': 'Jujuy' },
        { 'descripcion': 'GUERRERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'GUERREROS', 'provincia': 'Jujuy' },
        { 'descripcion': 'HIGUERITAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'HIPÓLITO YRIGOYEN , Est. Iturbe', 'provincia': 'Jujuy' },
        { 'descripcion': 'HIPOLITO YRIGOYEN EST ITURBE', 'provincia': 'Jujuy' },
        { 'descripcion': 'HORNADITAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'HORNILLOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'HUACALERA', 'provincia': 'Jujuy' },
        { 'descripcion': 'HUACHICHOCANA', 'provincia': 'Jujuy' },
        { 'descripcion': 'HUAICO CHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'HUALLATAYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'HUANCAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'HUICHAIRA', 'provincia': 'Jujuy' },
        { 'descripcion': 'HUMAHUACA', 'provincia': 'Jujuy' },
        { 'descripcion': 'INCA HUASI', 'provincia': 'Jujuy' },
        { 'descripcion': 'INGENIO LA ESPERANZA', 'provincia': 'Jujuy' },
        { 'descripcion': 'INGENIO LEDESMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'INTI CANCHA', 'provincia': 'Jujuy' },
        { 'descripcion': 'INTICANCHO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ISLA CHICA', 'provincia': 'Jujuy' },
        { 'descripcion': 'ISLA GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'ITUAICOCHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ITURBE', 'provincia': 'Jujuy' },
        { 'descripcion': 'JUAN GALAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'JUAN JOSE CASTELLI', 'provincia': 'Jujuy' },
        { 'descripcion': 'JUAN MANUEL DE ROSAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'JUELLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1129 ,DV.', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1137 ,EST.', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1149 ,AP.', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1183 ,AP.', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1210 ,DV.', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1243 ,AP.', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1247', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1278', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1289 ,AP.', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1321 ,DV.', 'provincia': 'Jujuy' },
        { 'descripcion': 'KILOMETRO 1373 ,AP.', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA ALMONA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA CABANA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA CAPILLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA CHINACA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA CUEVA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA INTERMEDIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA MENDIETA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA OLLADA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA OVEJERIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA QUIACA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA QUINTA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA RAMADA , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA RONDA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA SANGA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA TOMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA VERTIENTE', 'provincia': 'Jujuy' },
        { 'descripcion': 'LA VETA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAGUNA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAGUNA DE LOS POZUELOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAGUNA SAN MIGUEL', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAGUNA TOTORILLAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAGUNAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAGUNAS DE YALA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAGUNAS DEYALA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAGUNILLA EL CARMEN', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAGUNILLA LEDESMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAGUNILLAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAPACHAL LEDESMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAPACHAL SANTA BARBARA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAS CANADAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAS CHICAPENAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAS CUEVAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAS ESCALERAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAS HIGUERILLAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAS HIGUERITAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAS PAMPITAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAS PICHANAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAS PIRCAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAS QUINTAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LAVAYEN', 'provincia': 'Jujuy' },
        { 'descripcion': 'LEACHS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LECHERIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LEDESMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LEÓN', 'provincia': 'Jujuy' },
        { 'descripcion': 'LIB GRAL SAN MARTIN', 'provincia': 'Jujuy' },
        { 'descripcion': 'LIBERTADOR GRL.SAN MARTIN, EST.LEDESMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LINA , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'LINDERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'LIPAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'LLAMERIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LLULLUCHAYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOMA DEL MEDIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOMA PELADA', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS ALISOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS BANOS TERMALES', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS BAYOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS BLANCOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS CATRES', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS CEDROS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS CHANCHILLOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS LAPACHOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS LAPACHOS ,EST.MAQUINISTA VERON', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS MATOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOS PERALES', 'provincia': 'Jujuy' },
        { 'descripcion': 'LOZANO', 'provincia': 'Jujuy' },
        { 'descripcion': 'LUMARA', 'provincia': 'Jujuy' },
        { 'descripcion': 'MADREJON', 'provincia': 'Jujuy' },
        { 'descripcion': 'MAIMARÁ', 'provincia': 'Jujuy' },
        { 'descripcion': 'MAQUINISTA VERON', 'provincia': 'Jujuy' },
        { 'descripcion': 'MARTA', 'provincia': 'Jujuy' },
        { 'descripcion': 'MAYILTE', 'provincia': 'Jujuy' },
        { 'descripcion': 'MAYINTE', 'provincia': 'Jujuy' },
        { 'descripcion': 'MEDANITOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'MERCO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MILAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'MINA 9 DE OCTUBRE', 'provincia': 'Jujuy' },
        { 'descripcion': 'MINA AJEDREZ', 'provincia': 'Jujuy' },
        { 'descripcion': 'MINA BELGICA', 'provincia': 'Jujuy' },
        { 'descripcion': 'MINA PIRQUITAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'MINA PULPERA', 'provincia': 'Jujuy' },
        { 'descripcion': 'MINA SAN FRANCISCO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MINA SOL DE MAYO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MINAS AZULES', 'provincia': 'Jujuy' },
        { 'descripcion': 'MINAS DE BORATO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MINIAIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MIRA FLORES', 'provincia': 'Jujuy' },
        { 'descripcion': 'MIRAFLORES DE LA CANDELARIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'MIRAFLORES o de ABRA PAMPA , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'MIRES', 'provincia': 'Jujuy' },
        { 'descripcion': 'MIYUYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'MOCORAITE', 'provincia': 'Jujuy' },
        { 'descripcion': 'MOJON AZUCENA', 'provincia': 'Jujuy' },
        { 'descripcion': 'MOLLI PUNCO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MOLULAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'MOLULO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MONTE RICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MONTE RICO ,AP.', 'provincia': 'Jujuy' },
        { 'descripcion': 'MONTERRICO , apeadero', 'provincia': 'Jujuy' },
        { 'descripcion': 'MORALITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MORENO CHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MORRITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MORRO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MULLI PUNCO', 'provincia': 'Jujuy' },
        { 'descripcion': 'MUNAYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'NEGRO , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'NEGRO , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'NOGAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'NOGALES', 'provincia': 'Jujuy' },
        { 'descripcion': 'NOGALITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'NORMENTA', 'provincia': 'Jujuy' },
        { 'descripcion': 'OBRAJE SAN JOSE', 'provincia': 'Jujuy' },
        { 'descripcion': 'OCLOYAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'OCULTO', 'provincia': 'Jujuy' },
        { 'descripcion': 'OCUMAZO', 'provincia': 'Jujuy' },
        { 'descripcion': 'OLACAPATO', 'provincia': 'Jujuy' },
        { 'descripcion': 'OLACAPATO ,EST.', 'provincia': 'Jujuy' },
        { 'descripcion': 'OLAROZ CHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'OLAROZ GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'ORATORIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ORNILLO', 'provincia': 'Jujuy' },
        { 'descripcion': 'OROS SAN JUAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'OROSMAYO', 'provincia': 'Jujuy' },
        { 'descripcion': 'OROSMAYO , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'Otra', 'provincia': 'Jujuy' },
        { 'descripcion': 'OVARA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PAICONE', 'provincia': 'Jujuy' },
        { 'descripcion': 'PAIRIQUE CHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PAIRIQUE GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'PALAR , laguna', 'provincia': 'Jujuy' },
        { 'descripcion': 'PALCA DE APARZO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PALCA DE ARPAZO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PALMA SOLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PALPALÁ', 'provincia': 'Jujuy' },
        { 'descripcion': 'PALPALA ,EST.GRL.MANUEL N.SAVIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PAMPA BLANCA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PAMPA LARGA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PAMPICHUELA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PAN DE AZUCAR MINA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PANIZOS , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'PANO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PARQUE NACIONAL CALILEGUA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PASAJES', 'provincia': 'Jujuy' },
        { 'descripcion': 'PASTO PAMPA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PASTOS CHICOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'PASTOS CHICOS , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'PAULETE', 'provincia': 'Jujuy' },
        { 'descripcion': 'PAULINA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PAYO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PENA COLORADA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PENAS BLANCAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'PERICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PERICO , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'PERICO DEL CARMEN', 'provincia': 'Jujuy' },
        { 'descripcion': 'PERICO SAN JUAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'PICACHO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PIE DE LA CUESTA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PIEDRA CHOTA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PIEDRAS AMONTONADAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'PISCUNO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PISUNGO', 'provincia': 'Jujuy' },
        { 'descripcion': 'POSTA DE HORNILLOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'POSTA GUARDAPARQUE NACIONAL CA', 'provincia': 'Jujuy' },
        { 'descripcion': 'POTRERILLO', 'provincia': 'Jujuy' },
        { 'descripcion': 'POTRERO ALEGRE', 'provincia': 'Jujuy' },
        { 'descripcion': 'POTRERO DE LA PUNA', 'provincia': 'Jujuy' },
        { 'descripcion': 'POZO CABADO', 'provincia': 'Jujuy' },
        { 'descripcion': 'POZO COLORADO', 'provincia': 'Jujuy' },
        { 'descripcion': 'POZO DE LAS AVISPAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'POZO VERDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'POZUELO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PREDILIANA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUEBLO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUEBLO LEDESMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUEBLO PLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUEBLO VIEJO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUERTA DE COLORADOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUERTA DE LIPAN', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUERTA DE SALAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUERTA PATACAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUERTA POTRERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUERTA VIEJA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUERTO TOLAVA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUESTO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUESTO CHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUESTO DEL MARQUÉS', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUESTO DEL MARQUEZ', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUESTO GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUESTO NUEVO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUESTO VIEJO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PULULOS , laguna', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUMAHUASI', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUMAHUASI , Est. Cara Cara', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUNA DE JUJUY', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUNTA CANAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUNTA CORRAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUNTA DE AGUA', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUNTA DEL CAMPO', 'provincia': 'Jujuy' },
        { 'descripcion': 'PUNTAS DE COLORADOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'PURMAMARCA', 'provincia': 'Jujuy' },
        { 'descripcion': 'QUEBRADA HUASAMAYO', 'provincia': 'Jujuy' },
        { 'descripcion': 'QUEBRALENA', 'provincia': 'Jujuy' },
        { 'descripcion': 'QUENOAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'QUEÑOAL , quebrada', 'provincia': 'Jujuy' },
        { 'descripcion': 'QUENTI TACO', 'provincia': 'Jujuy' },
        { 'descripcion': 'QUERA', 'provincia': 'Jujuy' },
        { 'descripcion': 'QUETA', 'provincia': 'Jujuy' },
        { 'descripcion': 'QUICHAGUA', 'provincia': 'Jujuy' },
        { 'descripcion': 'QUIMAZO', 'provincia': 'Jujuy' },
        { 'descripcion': 'QUISQUINE', 'provincia': 'Jujuy' },
        { 'descripcion': 'RACHAITE', 'provincia': 'Jujuy' },
        { 'descripcion': 'RAMADA', 'provincia': 'Jujuy' },
        { 'descripcion': 'RASTROJOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'REAL DE LOS TOROS', 'provincia': 'Jujuy' },
        { 'descripcion': 'RECEPTORIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'REDONDA', 'provincia': 'Jujuy' },
        { 'descripcion': 'REYES', 'provincia': 'Jujuy' },
        { 'descripcion': 'REYES VILLA JARDIN', 'provincia': 'Jujuy' },
        { 'descripcion': 'RINCONADA', 'provincia': 'Jujuy' },
        { 'descripcion': 'RINCONADILLAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'RIO BLANCO', 'provincia': 'Jujuy' },
        { 'descripcion': 'RIO BLANCO ,AP.', 'provincia': 'Jujuy' },
        { 'descripcion': 'RIO GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'RIO NEGRO', 'provincia': 'Jujuy' },
        { 'descripcion': 'RIVERITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'RODEITOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'RODEO CHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'RODERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'RONQUE', 'provincia': 'Jujuy' },
        { 'descripcion': 'RONTUYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'ROSARIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ROSARIO , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'RUMI CRUZ', 'provincia': 'Jujuy' },
        { 'descripcion': 'SALA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SALADILLO LEDESMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SALADILLO SAN PEDRO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SALITRE', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN ANTONIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN ANTONIO PERICO DE', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN BERNARDO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN FRANCISCO DE ALFARCITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JAVIER', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JOSE DE LA RINCONADA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JOSE DE MIRAFLORES', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JOSE DEL BORDO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JOSE DEL CHANI', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JUAN DE DIOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JUAN DE ORO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JUAN DE OROS', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JUAN DE PALCA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JUAN DE QUILLAGUES', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JUAN MAYO , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN JUANCITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN LEON', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN LUCAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN PABLO DE REYES', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN PEDRITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN PEDRO , Est. San Pedro de Jujuy', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN PEDRO DE IRUYA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN SALVADOR DE JUJUY', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAN SALVADOR DE JUJUY ,EST.JUJUY', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANSANA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTA ANA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTA ANA DE LA PUNA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTA BARBARA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTA BÁRBARA , sierra', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTA CATALINA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTA CATALINA , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTA CLARA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTA RITA , arroyo', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTA ROSA TUMBAYA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTA ROSA VALLE GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTILLO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTO DOMINGO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTUARIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SANTUYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAUZAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAUZALITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SAYATE', 'provincia': 'Jujuy' },
        { 'descripcion': 'SENADOR PEREZ', 'provincia': 'Jujuy' },
        { 'descripcion': 'SEPULTURA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SEY', 'provincia': 'Jujuy' },
        { 'descripcion': 'SIBERIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'SIETE AGUAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'SIJES', 'provincia': 'Jujuy' },
        { 'descripcion': 'SOCABON', 'provincia': 'Jujuy' },
        { 'descripcion': 'SOL DE MAYO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SORCUYO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SOYSOLAYTE', 'provincia': 'Jujuy' },
        { 'descripcion': 'SURIPUGIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SURIPUJIO', 'provincia': 'Jujuy' },
        { 'descripcion': 'SUSQUES', 'provincia': 'Jujuy' },
        { 'descripcion': 'SUSUYACO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TABLADITAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'TABLON', 'provincia': 'Jujuy' },
        { 'descripcion': 'TABLÓN , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'TACTA', 'provincia': 'Jujuy' },
        { 'descripcion': 'TAFNA', 'provincia': 'Jujuy' },
        { 'descripcion': 'TALA GRUSA', 'provincia': 'Jujuy' },
        { 'descripcion': 'TALAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'TAMBILLOS', 'provincia': 'Jujuy' },
        { 'descripcion': 'TANQUES', 'provincia': 'Jujuy' },
        { 'descripcion': 'TARIJITA', 'provincia': 'Jujuy' },
        { 'descripcion': 'TEJADAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'TERMAS DE REYES', 'provincia': 'Jujuy' },
        { 'descripcion': 'TESORERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TEUCO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TILCARA', 'provincia': 'Jujuy' },
        { 'descripcion': 'TILQUIZA', 'provincia': 'Jujuy' },
        { 'descripcion': 'TIMÓN CRUZ', 'provincia': 'Jujuy' },
        { 'descripcion': 'TINTE , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'TIO MAYO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TIOMAYO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TIONSO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TIRAXI', 'provincia': 'Jujuy' },
        { 'descripcion': 'TIRAXI CHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TOCOL', 'provincia': 'Jujuy' },
        { 'descripcion': 'TOQUERO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TOQUILLERA', 'provincia': 'Jujuy' },
        { 'descripcion': 'TORO MUERTO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TORONAO , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'TOTORITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TRANCAS , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'TREMENTINAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'TRES CRUCES', 'provincia': 'Jujuy' },
        { 'descripcion': 'TRES MORROS', 'provincia': 'Jujuy' },
        { 'descripcion': 'TUCUMANCITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TUITE', 'provincia': 'Jujuy' },
        { 'descripcion': 'TUMBAYA', 'provincia': 'Jujuy' },
        { 'descripcion': 'TUMBAYA GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'TUNALITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'TUNAS , arroyo', 'provincia': 'Jujuy' },
        { 'descripcion': 'TÚNEL SUBFLUVIAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'TURILARI', 'provincia': 'Jujuy' },
        { 'descripcion': 'TURU TARI', 'provincia': 'Jujuy' },
        { 'descripcion': 'TUSAQUILLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'TUSAQUILLAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'UQUIA', 'provincia': 'Jujuy' },
        { 'descripcion': 'UQUíA , Est. Senador PÉrez', 'provincia': 'Jujuy' },
        { 'descripcion': 'VALLE COLORADO', 'provincia': 'Jujuy' },
        { 'descripcion': 'VALLE GRANDE', 'provincia': 'Jujuy' },
        { 'descripcion': 'VARAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'VENECIAS ARGENTINAS', 'provincia': 'Jujuy' },
        { 'descripcion': 'VETA MINA AGUILAR', 'provincia': 'Jujuy' },
        { 'descripcion': 'VICUÑA HUASI , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'VICUNAYOC', 'provincia': 'Jujuy' },
        { 'descripcion': 'VILAMA , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'VILLA ACHAVAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'VILLA ARGENTINA', 'provincia': 'Jujuy' },
        { 'descripcion': 'VILLA CIUDAD DE NIEVA', 'provincia': 'Jujuy' },
        { 'descripcion': 'VILLA DEL PERCHEL', 'provincia': 'Jujuy' },
        { 'descripcion': 'VILLA GORRITI', 'provincia': 'Jujuy' },
        { 'descripcion': 'VINALITO', 'provincia': 'Jujuy' },
        { 'descripcion': 'VINDICACIÓN , isla', 'provincia': 'Jujuy' },
        { 'descripcion': 'VISCACHANI', 'provincia': 'Jujuy' },
        { 'descripcion': 'VIZACACHAL', 'provincia': 'Jujuy' },
        { 'descripcion': 'VOLCÁN', 'provincia': 'Jujuy' },
        { 'descripcion': 'VOLCAN HIGUERA', 'provincia': 'Jujuy' },
        { 'descripcion': 'YACORAITE', 'provincia': 'Jujuy' },
        { 'descripcion': 'YACORAITE , río', 'provincia': 'Jujuy' },
        { 'descripcion': 'YALA', 'provincia': 'Jujuy' },
        { 'descripcion': 'YALA DE MONTE CARMELO', 'provincia': 'Jujuy' },
        { 'descripcion': 'YAVí', 'provincia': 'Jujuy' },
        { 'descripcion': 'YAVI CHICO', 'provincia': 'Jujuy' },
        { 'descripcion': 'YERBA BUENA LEDESMA', 'provincia': 'Jujuy' },
        { 'descripcion': 'YERBA BUENA TILCARA', 'provincia': 'Jujuy' },
        { 'descripcion': 'YOSCABA', 'provincia': 'Jujuy' },
        { 'descripcion': 'YUTO', 'provincia': 'Jujuy' },
        { 'descripcion': 'ZAPALERI , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': 'ZAPLA', 'provincia': 'Jujuy' },
        { 'descripcion': 'ZUCHO , cerro', 'provincia': 'Jujuy' },
        { 'descripcion': '25 DE MAYO', 'provincia': 'La Pampa' },
        { 'descripcion': 'ABRAMO', 'provincia': 'La Pampa' },
        { 'descripcion': 'ADOLFO VAN PRAET', 'provincia': 'La Pampa' },
        { 'descripcion': 'AGUA DE TORRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'AGUAS BUENAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'AGUSTONI', 'provincia': 'La Pampa' },
        { 'descripcion': 'ALFREDO PEÑA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ALGARROBO DEL ÁGUILA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ALPACHIRI', 'provincia': 'La Pampa' },
        { 'descripcion': 'ALTA ITALIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ANGUIL', 'provincia': 'La Pampa' },
        { 'descripcion': 'ANZOATEGUI', 'provincia': 'La Pampa' },
        { 'descripcion': 'APUYACO', 'provincia': 'La Pampa' },
        { 'descripcion': 'ARATA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ARBOL DE LA ESPERANZA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ARGENTINA BELVEDERE', 'provincia': 'La Pampa' },
        { 'descripcion': 'ARTURO ALMARAZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'ATALIVA ROCA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ATREUCÓ', 'provincia': 'La Pampa' },
        { 'descripcion': 'AZTEAZU', 'provincia': 'La Pampa' },
        { 'descripcion': 'BAJO DE LAS PALOMAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'BALO LOS MORROS', 'provincia': 'La Pampa' },
        { 'descripcion': 'BARRANCAS COLORADAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'BARRIO EL MOLINO', 'provincia': 'La Pampa' },
        { 'descripcion': 'BERNARDO LARROUDÉ', 'provincia': 'La Pampa' },
        { 'descripcion': 'BERNASCONI', 'provincia': 'La Pampa' },
        { 'descripcion': 'BOEUF', 'provincia': 'La Pampa' },
        { 'descripcion': 'BOLICHE LA ARANA', 'provincia': 'La Pampa' },
        { 'descripcion': 'BUTA RANQUIL', 'provincia': 'La Pampa' },
        { 'descripcion': 'BUTALO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CACHIRULO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAICHUE', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAIMI', 'provincia': 'La Pampa' },
        { 'descripcion': 'CALCHAHUE', 'provincia': 'La Pampa' },
        { 'descripcion': 'CALEU CALEU', 'provincia': 'La Pampa' },
        { 'descripcion': 'CALEUFÚ', 'provincia': 'La Pampa' },
        { 'descripcion': 'CALLAQUEO , laguna', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO CARETTO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO CICARE', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO DE LOS TOROS', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO DE SALAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO LA FLORIDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO LUDUENA', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO MOISES SECCION 1A', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO NICHOLSON', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO PICO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO SALUSSO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAMPO URDANIZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'CANE, MIGUEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'CARAMAN', 'provincia': 'La Pampa' },
        { 'descripcion': 'CARAPACHA CHICA , sierras', 'provincia': 'La Pampa' },
        { 'descripcion': 'CARAPACHA GRANDE , sierra', 'provincia': 'La Pampa' },
        { 'descripcion': 'CARLOS BERG', 'provincia': 'La Pampa' },
        { 'descripcion': 'CARRO QUEMADO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CASA DE PIEDRA', 'provincia': 'La Pampa' },
        { 'descripcion': 'CASTEX, EDUARDO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CATRILÓ', 'provincia': 'La Pampa' },
        { 'descripcion': 'CAYUPAN', 'provincia': 'La Pampa' },
        { 'descripcion': 'CEBALLOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'CEREALES', 'provincia': 'La Pampa' },
        { 'descripcion': 'CERRO BAYO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CERRO DEL AIGRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'CERRO LA BOTA', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHACHARRAMENDI', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHACRA 16', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHACRA LA CASILDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHACRA LA MAGDALENA', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHACRAS DE VICTORICA', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHACU', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHADILEUVÚ , río', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHAMAICÓ', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHANILAO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHAPALCO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHICA , sierra', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHICAL CO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHICALCO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CHOIQUE MAHUIDA , sierra', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA 17 DE AGOSTO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA 25 DE MAYO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA AGUIRRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA ANASAGASTI', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA BARÓN', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA BEATRIZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA BEAUFORT', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA CAZAUX', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA CHICA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA DENEVI', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA DEVOTO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA ECHETA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA EL DESTINO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA EL PORVENIR', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA EL SAUZAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA EL TIGRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA EMILIO MITRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA ESPANA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA ESPIGA DE ORO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA FERRARO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA GIUSTI', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA GOBERNADOR AYALA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA GUIBURG N 2', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA HELVECIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA INES Y CARLOTA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA ABUNDANCIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA AMARGA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA CARLOTA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA CHISPA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA ESPERANZA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA GAVIOTA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA INDIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA MARGARITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA MUTUA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA ORACION', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA PASTORIL', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA PAZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LA SARA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LAGOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LAS MERCEDES', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LAS PIEDRITAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LAS TRES PIEDRAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LAS VIZCACHERAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LIA Y ALLENDE', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LOS PIOJOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LOS TOROS', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA LUNA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA MARIA LUISA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA MEDANO COLORADO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA MIGLIORI', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA MINISTRO LOBOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA RAMON QUINTAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA ROCA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SAN BASILIO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SAN FELIPE', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SAN IGNACIO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SAN JOSE', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SAN LORENZO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SAN ROSARIO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SAN VICTORIO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SANTA ANA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SANTA CECILIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SANTA CLARA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SANTA ELENA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SANTA ELVIRA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SANTA TERESA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA SOBADELL', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA TORELLO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA TRENQUENDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA TREQUEN', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA VASCONGADA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIA VILLA ALBA', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIAS DRYSDALE', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLONIAS MURRAY', 'provincia': 'La Pampa' },
        { 'descripcion': 'COLORADA GRANDE , salina', 'provincia': 'La Pampa' },
        { 'descripcion': 'CONA LAUQUEN', 'provincia': 'La Pampa' },
        { 'descripcion': 'CONHELO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CORONEL HILARIO LAGOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'CORONEL HILARIOS LAGOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'COSTA DEL SALADO', 'provincia': 'La Pampa' },
        { 'descripcion': 'COTITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'CUCHILLO CO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CUCHILLO-CO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CURILCO', 'provincia': 'La Pampa' },
        { 'descripcion': 'CURRU MAHUIDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'DAMIÁN MAISONAVE', 'provincia': 'La Pampa' },
        { 'descripcion': 'DAZA , valle', 'provincia': 'La Pampa' },
        { 'descripcion': 'de LA ESCALERA , bajo', 'provincia': 'La Pampa' },
        { 'descripcion': 'de LA PERRA , salitral', 'provincia': 'La Pampa' },
        { 'descripcion': 'de LIHUEL CALEL , sierras', 'provincia': 'La Pampa' },
        { 'descripcion': 'de OLGUíN , lomas', 'provincia': 'La Pampa' },
        { 'descripcion': 'de UTRACÁN , valle', 'provincia': 'La Pampa' },
        { 'descripcion': 'DEL 6 Y 10 , salitral', 'provincia': 'La Pampa' },
        { 'descripcion': 'DOBLAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'DORILA', 'provincia': 'La Pampa' },
        { 'descripcion': 'DOS AMIGOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'DOS CHANARES', 'provincia': 'La Pampa' },
        { 'descripcion': 'DOS DE IPINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'DOS VIOLETAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'EDUARDO CASTEX', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL ANTOJO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL BELGICA', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL BOQUERON', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL CARANCHO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL CENTENARIO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL CENTINELA', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL CHILLEN', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL CINCO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL DESCANSO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL DESCANSO LONQUIMAY', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL DESLINDE', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL DESTINO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL DESTINO ROLON', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL DIEZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL DIEZ Y SIETE', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL DURAZNO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL ESCABEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL EUCALIPTO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL EUCALIPTO CARRO QUEMADO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL FURLONG', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL GUAICURU', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL GUANACO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL GUANACO WINIFREDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL HUITRU', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL LUCERO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL MADRIGAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL MALACATE', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL MATE', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL MIRADOR DE JUAREZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL NUEVE', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL OASIS', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL ODRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL OLIVO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL PELUDO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL PIMIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL PUMA', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL RECREO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL REFUGIO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL RUBI', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL SALITRAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL SILENCIO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL TAJAMAR', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL TARTAGAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL TRECE', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL UNO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL VASQUITO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL VERANEO', 'provincia': 'La Pampa' },
        { 'descripcion': 'EL VOLANTE', 'provincia': 'La Pampa' },
        { 'descripcion': 'EMBAJADOR MARTINI', 'provincia': 'La Pampa' },
        { 'descripcion': 'EMILIO MITRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'EPU PEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'EPU-PEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'ESTABLECIMIENTO EL CENTINELA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ESTACIÓN SIMSON', 'provincia': 'La Pampa' },
        { 'descripcion': 'ESTANCIA LA LUCHA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ESTANCIA LA PAMPEANA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ESTANCIA LA VOLUNTAD', 'provincia': 'La Pampa' },
        { 'descripcion': 'EUSKADI', 'provincia': 'La Pampa' },
        { 'descripcion': 'FALUCHO', 'provincia': 'La Pampa' },
        { 'descripcion': 'GALLINAO', 'provincia': 'La Pampa' },
        { 'descripcion': 'GAMAY', 'provincia': 'La Pampa' },
        { 'descripcion': 'GAVIOTAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'GENERAL ACHA', 'provincia': 'La Pampa' },
        { 'descripcion': 'GENERAL ACHA , valle', 'provincia': 'La Pampa' },
        { 'descripcion': 'GENERAL MANUEL CAMPOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'GENERAL PICO', 'provincia': 'La Pampa' },
        { 'descripcion': 'GENERAL SAN MARTíN', 'provincia': 'La Pampa' },
        { 'descripcion': 'GERVASIO ORTIZ DE ROSAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'GOBERNADOR AYALA', 'provincia': 'La Pampa' },
        { 'descripcion': 'GOBERNADOR DUVAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'GRAL MANUEL CAMPOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'GUADALOZA', 'provincia': 'La Pampa' },
        { 'descripcion': 'GUARACO', 'provincia': 'La Pampa' },
        { 'descripcion': 'GUATRACHE', 'provincia': 'La Pampa' },
        { 'descripcion': 'HIDALGO', 'provincia': 'La Pampa' },
        { 'descripcion': 'HIPOLITO YRIGOYEN', 'provincia': 'La Pampa' },
        { 'descripcion': 'HUCAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'HUELEN', 'provincia': 'La Pampa' },
        { 'descripcion': 'INES Y CARLOTA', 'provincia': 'La Pampa' },
        { 'descripcion': 'INGENIERO FOSTER', 'provincia': 'La Pampa' },
        { 'descripcion': 'INGENIERO LUIGGI', 'provincia': 'La Pampa' },
        { 'descripcion': 'INTENDENTE ALVEAR', 'provincia': 'La Pampa' },
        { 'descripcion': 'IPINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'IVANOWSKY', 'provincia': 'La Pampa' },
        { 'descripcion': 'JACINTO ARAUZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'JAGUEL DEL ESQUINERO', 'provincia': 'La Pampa' },
        { 'descripcion': 'JAGUEL DEL MONTE', 'provincia': 'La Pampa' },
        { 'descripcion': 'JARDON', 'provincia': 'La Pampa' },
        { 'descripcion': 'JULIAN A MANSILLA', 'provincia': 'La Pampa' },
        { 'descripcion': 'JUZGADO VIEJO', 'provincia': 'La Pampa' },
        { 'descripcion': 'KILOMETRO 755 ,DV.', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ADELA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ADMINISTRACION', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA AMARGA , laguna', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA AMARGA , laguna', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ANTONIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ARANA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ASTURIANA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ATALAYA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA AVANZADA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA BANDERITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA BAYA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA BAYA MUERTA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA BILBAINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA BLANCA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA BLANCA GRANDE , laguna', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA BOLSA , cerro', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA BOTA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CARMEN', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CAROLA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CASILDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CATALINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CATALINITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CATITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CELIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CELINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CELMIRA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CHAPELLE', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CHIRLANDIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CHITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CHITA PUELCHES', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CLELIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA COLORADA CHICA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA COLORADA GRANDE', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA CONSTANCIA ANGUIL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA COPELINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA DELICIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA DOLORES', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ELENITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ELIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ELINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ELSA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ELVA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ELVIRA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ENERGIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ENRIQUETA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ESMERALDA MACACHIN', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ESPERANZA ANGUIL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ESPERANZA HIDALGO', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ESPERANZA MACACHIN', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ESPERANZA VERTIZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ESTHER', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ESTRELLA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ESTRELLA DEL SUD', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA EULOGIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA FE', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA FORTUNA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA GAVENITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA GAVIOTA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA GLORIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA GUADALOSA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA GUENITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA HUMADA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA IMARRA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ISABEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA JAPONESA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA JOSEFINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA LAURENTINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA LENA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA LONJA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA LUCHA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA LUCHA LA REFORMA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA LUZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MAGDALENA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MALVINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MANUELITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MARCELA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MARGARITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MARIA ELENA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MARIA ELISA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MARIA ROSA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MARIA VERTIZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MARIANITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MARUJA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MATILDE', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MODERNA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA MOROCHA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA NILDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA NUEVA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA NUEVA PROVINCIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA OLLA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ORACION', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PALOMA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PAMPEANA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PAMPITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PAMPITA HIDALGO', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PASTORIL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PAULINA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PENCOSA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PERLA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PERLITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PIEDAD', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA POMONA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PRADERA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PRIMAVERA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PRIMAVERA CHAMAICO', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PRIMAVERA MIGUEL RIGLOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PRIMAVERA SANTA ROSA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PRIMERA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PUMA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PUNA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA PUNALADA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA RAZON', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA RAZON SANTA ISABEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA REBECA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA REFORMA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA REFORMA VIEJA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA RESERVA ANGUIL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA RESERVA IVANOWSKY', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA RICA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ROSA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA SARITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA SEGUNDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA SIN NOMBRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA SOLEDAD', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA SORPRESA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA SUERTE', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA TERESITA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA TINAJERA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA TORERA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA TOSCA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA UNIDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA VEINTITRES', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA VERDE ANGUIL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA VICTORIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA VOLUNTAD', 'provincia': 'La Pampa' },
        { 'descripcion': 'LA ZOTA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LABAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LARROUDE, BERNARDO', 'provincia': 'La Pampa' },
        { 'descripcion': 'LAS ACACIAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LAS DOS NACIONES', 'provincia': 'La Pampa' },
        { 'descripcion': 'LAS FELICITAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LAS GAVIOTAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LAS QUINTAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LAVALLE, RICARDO', 'provincia': 'La Pampa' },
        { 'descripcion': 'LEGASA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LEONA REDONDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LIHUE CALEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LIHUEL CALEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LIMAY MAHUIDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LINDO VER', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOMA REDONDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOMAS DE GATICA', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOMAS OMBU', 'provincia': 'La Pampa' },
        { 'descripcion': 'LONQUIMAY', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOO CO', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOS ALGARROBOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOS DOS HERMANOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOS MORROS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOS OLIVOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOS PIRINEOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOS QUINIENTOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOS TAJAMARES', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOS TOROS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOS TRES POZOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOS TURCOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOVENTUÉ', 'provincia': 'La Pampa' },
        { 'descripcion': 'LOVENTUEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'LUAN TORO', 'provincia': 'La Pampa' },
        { 'descripcion': 'LUNA', 'provincia': 'La Pampa' },
        { 'descripcion': 'MACACHíN', 'provincia': 'La Pampa' },
        { 'descripcion': 'MAISONAVE, DAMIAN ,EST.SIMSON', 'provincia': 'La Pampa' },
        { 'descripcion': 'MAISONNAVE', 'provincia': 'La Pampa' },
        { 'descripcion': 'MARACO', 'provincia': 'La Pampa' },
        { 'descripcion': 'MARACO CHICO', 'provincia': 'La Pampa' },
        { 'descripcion': 'MARI MARI', 'provincia': 'La Pampa' },
        { 'descripcion': 'MARIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'MARIANO MIRO', 'provincia': 'La Pampa' },
        { 'descripcion': 'MAURICIO MAYER', 'provincia': 'La Pampa' },
        { 'descripcion': 'MAYACO', 'provincia': 'La Pampa' },
        { 'descripcion': 'MAYER, MAURICIO', 'provincia': 'La Pampa' },
        { 'descripcion': 'MEDANO BLANCO', 'provincia': 'La Pampa' },
        { 'descripcion': 'MEDANO COLORADO', 'provincia': 'La Pampa' },
        { 'descripcion': 'MEDANOS NEGROS', 'provincia': 'La Pampa' },
        { 'descripcion': 'METILEO', 'provincia': 'La Pampa' },
        { 'descripcion': 'MIGUEL CANÉ', 'provincia': 'La Pampa' },
        { 'descripcion': 'MIGUEL RIGLOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'MINAS DE SAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'MINERALES DE LA PAMPA', 'provincia': 'La Pampa' },
        { 'descripcion': 'MINISTRO ORLANDO', 'provincia': 'La Pampa' },
        { 'descripcion': 'MONTE NIEVAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'NAHUEL NAPA', 'provincia': 'La Pampa' },
        { 'descripcion': 'NAICÓ', 'provincia': 'La Pampa' },
        { 'descripcion': 'NANQUEL HUITRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'NARCISO LEVEN', 'provincia': 'La Pampa' },
        { 'descripcion': 'NEGRO , cerro', 'provincia': 'La Pampa' },
        { 'descripcion': 'NEGRO , cerro', 'provincia': 'La Pampa' },
        { 'descripcion': 'NERECÓ , valle', 'provincia': 'La Pampa' },
        { 'descripcion': 'NERRE CO', 'provincia': 'La Pampa' },
        { 'descripcion': 'NICOLAS VERA', 'provincia': 'La Pampa' },
        { 'descripcion': 'OFICIAL E SEGURA', 'provincia': 'La Pampa' },
        { 'descripcion': 'OJEDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'OLONIA SANTA MARIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'Otra', 'provincia': 'La Pampa' },
        { 'descripcion': 'PARERA', 'provincia': 'La Pampa' },
        { 'descripcion': 'PARQUE LURO', 'provincia': 'La Pampa' },
        { 'descripcion': 'PARQUE NACIONAL LIHUE CALEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'PASO DE LOS ALGARROBOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'PASO DE LOS PUNTANOS', 'provincia': 'La Pampa' },
        { 'descripcion': 'PASO LA BALSA', 'provincia': 'La Pampa' },
        { 'descripcion': 'PASO LA RAZON', 'provincia': 'La Pampa' },
        { 'descripcion': 'PEÑA, ALFREDO', 'provincia': 'La Pampa' },
        { 'descripcion': 'PERÚ', 'provincia': 'La Pampa' },
        { 'descripcion': 'PICHE CONA LAUQUEN', 'provincia': 'La Pampa' },
        { 'descripcion': 'PICHI HUILCO', 'provincia': 'La Pampa' },
        { 'descripcion': 'PICHI HUINCA', 'provincia': 'La Pampa' },
        { 'descripcion': 'PICHI MAHUIDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'PICHI MAHUIDA , cerro', 'provincia': 'La Pampa' },
        { 'descripcion': 'PICHI MERICO', 'provincia': 'La Pampa' },
        { 'descripcion': 'POITAGUE', 'provincia': 'La Pampa' },
        { 'descripcion': 'PUEBLO ALASSA', 'provincia': 'La Pampa' },
        { 'descripcion': 'PUEBLO QUINTANA', 'provincia': 'La Pampa' },
        { 'descripcion': 'PUELCHES', 'provincia': 'La Pampa' },
        { 'descripcion': 'PUELÉN', 'provincia': 'La Pampa' },
        { 'descripcion': 'QUEHUÉ', 'provincia': 'La Pampa' },
        { 'descripcion': 'QUEMÚ QUEMÚ', 'provincia': 'La Pampa' },
        { 'descripcion': 'QUETREQUEN', 'provincia': 'La Pampa' },
        { 'descripcion': 'QUINI MALAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'QUINTANA', 'provincia': 'La Pampa' },
        { 'descripcion': 'RAMON QUINTAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'RAMON SEGUNDO', 'provincia': 'La Pampa' },
        { 'descripcion': 'RANCUL', 'provincia': 'La Pampa' },
        { 'descripcion': 'REALICÓ', 'provincia': 'La Pampa' },
        { 'descripcion': 'RELMO', 'provincia': 'La Pampa' },
        { 'descripcion': 'REMECÓ', 'provincia': 'La Pampa' },
        { 'descripcion': 'RICARDO LAVALLE', 'provincia': 'La Pampa' },
        { 'descripcion': 'RIGLOS, MIGUEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'ROCA, ATALIVA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ROLÓN', 'provincia': 'La Pampa' },
        { 'descripcion': 'RUCAHUE', 'provincia': 'La Pampa' },
        { 'descripcion': 'RUCANELO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SALADO , río', 'provincia': 'La Pampa' },
        { 'descripcion': 'SALADO o CURACÓ , río', 'provincia': 'La Pampa' },
        { 'descripcion': 'SALINA GRANDE O GRAN SALITRAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'SALINAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'SALINAS GRANDES ANZOATEGUI', 'provincia': 'La Pampa' },
        { 'descripcion': 'SALINAS GRANDES HIDALGO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SALINAS MARI MANUEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN ALBERTO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN AQUILINO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN DIEGO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN FRANCISCO DE LA RAMADA', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN HUMBERTO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN ILDEFONSO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN JOAQUIN METILEO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN JOSE ANGUIL', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN JUAN SIMSON', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN MARCELO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN PEDRO ROLON', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN ROBERTO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN SIMON', 'provincia': 'La Pampa' },
        { 'descripcion': 'SAN URBANO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SANTA AURELIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'SANTA ELVIRA', 'provincia': 'La Pampa' },
        { 'descripcion': 'SANTA FELICITAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'SANTA GRACIA', 'provincia': 'La Pampa' },
        { 'descripcion': 'SANTA ISABEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'SANTA ROSA', 'provincia': 'La Pampa' },
        { 'descripcion': 'SANTA STELLA', 'provincia': 'La Pampa' },
        { 'descripcion': 'SANTIAGO ORELLANO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SANTO TOMAS', 'provincia': 'La Pampa' },
        { 'descripcion': 'SARAH', 'provincia': 'La Pampa' },
        { 'descripcion': 'SECCION PRIMERA CONHELLO', 'provincia': 'La Pampa' },
        { 'descripcion': 'SIERRAS DE LIHUEL CALEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'SPELUZZI', 'provincia': 'La Pampa' },
        { 'descripcion': 'TA HUILCO', 'provincia': 'La Pampa' },
        { 'descripcion': 'TELÉN', 'provincia': 'La Pampa' },
        { 'descripcion': 'TENIENTE GENERAL EMILIO MITRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'TOAY', 'provincia': 'La Pampa' },
        { 'descripcion': 'TOMAS M DE ANCHORENA', 'provincia': 'La Pampa' },
        { 'descripcion': 'TOMÁS M. ANCHORENA', 'provincia': 'La Pampa' },
        { 'descripcion': 'TRAICO', 'provincia': 'La Pampa' },
        { 'descripcion': 'TRAICO GRANDE', 'provincia': 'La Pampa' },
        { 'descripcion': 'TREBOLARES', 'provincia': 'La Pampa' },
        { 'descripcion': 'TRENEL', 'provincia': 'La Pampa' },
        { 'descripcion': 'TRES BOTONES', 'provincia': 'La Pampa' },
        { 'descripcion': 'TRES HERMANOS MACACHIN', 'provincia': 'La Pampa' },
        { 'descripcion': 'TRES HERMANOS QUETREQUEN', 'provincia': 'La Pampa' },
        { 'descripcion': 'TRES NACIONES', 'provincia': 'La Pampa' },
        { 'descripcion': 'TRIBULUCI', 'provincia': 'La Pampa' },
        { 'descripcion': 'TRILI', 'provincia': 'La Pampa' },
        { 'descripcion': 'TRUBULUCO', 'provincia': 'La Pampa' },
        { 'descripcion': 'TTE GRAL EMILIO MITRE', 'provincia': 'La Pampa' },
        { 'descripcion': 'UNANJE', 'provincia': 'La Pampa' },
        { 'descripcion': 'UNANUÉ', 'provincia': 'La Pampa' },
        { 'descripcion': 'URIBURU', 'provincia': 'La Pampa' },
        { 'descripcion': 'URRE LAUQUEN , laguna', 'provincia': 'La Pampa' },
        { 'descripcion': 'URUEÑA , río', 'provincia': 'La Pampa' },
        { 'descripcion': 'UTRACÁN', 'provincia': 'La Pampa' },
        { 'descripcion': 'VALLE ARGENTINO', 'provincia': 'La Pampa' },
        { 'descripcion': 'VALLE DAZA', 'provincia': 'La Pampa' },
        { 'descripcion': 'VAN PRAET, ADOLFO', 'provincia': 'La Pampa' },
        { 'descripcion': 'VÉRTIZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'VICTORICA', 'provincia': 'La Pampa' },
        { 'descripcion': 'VILLA ALBA', 'provincia': 'La Pampa' },
        { 'descripcion': 'VILLA MENCUELLE', 'provincia': 'La Pampa' },
        { 'descripcion': 'VILLA MIRASOL', 'provincia': 'La Pampa' },
        { 'descripcion': 'WINIFREDA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ZONA RURAL', 'provincia': 'La Pampa' },
        { 'descripcion': 'ZONA RURAL DE MIRASOL', 'provincia': 'La Pampa' },
        { 'descripcion': 'ZONA RURAL DE VERTIZ', 'provincia': 'La Pampa' },
        { 'descripcion': 'ZONA RURAL DORILA', 'provincia': 'La Pampa' },
        { 'descripcion': 'ZONA RURAL METILEO', 'provincia': 'La Pampa' },
        { 'descripcion': 'ZONA URBANA NORTE', 'provincia': 'La Pampa' },
        { 'descripcion': 'ABRA VERDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'ADOLFO E CARRANZA', 'provincia': 'La Rioja' },
        { 'descripcion': 'AGUA BLANCA', 'provincia': 'La Rioja' },
        { 'descripcion': 'AGUA COLORADA', 'provincia': 'La Rioja' },
        { 'descripcion': 'AGUA DE LA PIEDRA', 'provincia': 'La Rioja' },
        { 'descripcion': 'AGUA DE PIEDRA', 'provincia': 'La Rioja' },
        { 'descripcion': 'AGUADA', 'provincia': 'La Rioja' },
        { 'descripcion': 'AGUAYO', 'provincia': 'La Rioja' },
        { 'descripcion': 'AICUÑA', 'provincia': 'La Rioja' },
        { 'descripcion': 'AIMOGASTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'ALCÁZAR', 'provincia': 'La Rioja' },
        { 'descripcion': 'ALGARROBO GRANDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'ALPASINCHE', 'provincia': 'La Rioja' },
        { 'descripcion': 'ALTILLO DEL MEDIO', 'provincia': 'La Rioja' },
        { 'descripcion': 'ALTO BAYO', 'provincia': 'La Rioja' },
        { 'descripcion': 'ALTO CARRIZAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'ALTO JAGUEL', 'provincia': 'La Rioja' },
        { 'descripcion': 'AMANA', 'provincia': 'La Rioja' },
        { 'descripcion': 'AMBIL', 'provincia': 'La Rioja' },
        { 'descripcion': 'AMILGANCHO', 'provincia': 'La Rioja' },
        { 'descripcion': 'AMINGA', 'provincia': 'La Rioja' },
        { 'descripcion': 'AMUSCHINA', 'provincia': 'La Rioja' },
        { 'descripcion': 'ANCHICO', 'provincia': 'La Rioja' },
        { 'descripcion': 'ANCHUMBIL', 'provincia': 'La Rioja' },
        { 'descripcion': 'ANDALUCAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'ANDOLUCAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'ANGUINAN', 'provincia': 'La Rioja' },
        { 'descripcion': 'ÁNGULOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'ANILLACO', 'provincia': 'La Rioja' },
        { 'descripcion': 'ANIMAN', 'provincia': 'La Rioja' },
        { 'descripcion': 'ANJULLÓN', 'provincia': 'La Rioja' },
        { 'descripcion': 'ANTINACO', 'provincia': 'La Rioja' },
        { 'descripcion': 'ARAUCO', 'provincia': 'La Rioja' },
        { 'descripcion': 'ATILES', 'provincia': 'La Rioja' },
        { 'descripcion': 'BAJO CORRAL DE ISAAC', 'provincia': 'La Rioja' },
        { 'descripcion': 'BAJO DE GALLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'BAJO DEL GALLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'BAJO GRANDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'BAJO HONDO', 'provincia': 'La Rioja' },
        { 'descripcion': 'BAJO JAGUEL', 'provincia': 'La Rioja' },
        { 'descripcion': 'BALDE DEL QUEBRACHO', 'provincia': 'La Rioja' },
        { 'descripcion': 'BALDE SALADO', 'provincia': 'La Rioja' },
        { 'descripcion': 'BALDE SAN ISIDRO', 'provincia': 'La Rioja' },
        { 'descripcion': 'BALDES DE PACHECO', 'provincia': 'La Rioja' },
        { 'descripcion': 'BANADOS DEL PANTANO', 'provincia': 'La Rioja' },
        { 'descripcion': 'BANDA FLORIDA', 'provincia': 'La Rioja' },
        { 'descripcion': 'BARRANQUITAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'BARRIO DE GALLI', 'provincia': 'La Rioja' },
        { 'descripcion': 'BAYO MUERTO', 'provincia': 'La Rioja' },
        { 'descripcion': 'BAZÁN', 'provincia': 'La Rioja' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'BERMEJO o VINCHINA , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'BLANCO , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'BOLA , cerro', 'provincia': 'La Rioja' },
        { 'descripcion': 'BONETE , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'BONETE CHICO , cerro', 'provincia': 'La Rioja' },
        { 'descripcion': 'BONETE GRANDE , cerro', 'provincia': 'La Rioja' },
        { 'descripcion': 'BRAVA , laguna', 'provincia': 'La Rioja' },
        { 'descripcion': 'BRAVA , sierra', 'provincia': 'La Rioja' },
        { 'descripcion': 'BRAZO SUR DEL RíO COIG', 'provincia': 'La Rioja' },
        { 'descripcion': 'CABOLLAR', 'provincia': 'La Rioja' },
        { 'descripcion': 'CACHIYUYAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'CAMPANAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'CAMPO TRES POZOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'CANADA VERDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'CAPIHUAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'CARRIZAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'CARRIZAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'CARRIZAL , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'CARRIZAL ESTACION FCGB', 'provincia': 'La Rioja' },
        { 'descripcion': 'CARRIZAL TAMA', 'provincia': 'La Rioja' },
        { 'descripcion': 'CARRIZALILLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'CASA PINTADA', 'provincia': 'La Rioja' },
        { 'descripcion': 'CASAGATE', 'provincia': 'La Rioja' },
        { 'descripcion': 'CASANGATE', 'provincia': 'La Rioja' },
        { 'descripcion': 'CASAS VIEJAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'CASTRO BARROS', 'provincia': 'La Rioja' },
        { 'descripcion': 'CATINZACO', 'provincia': 'La Rioja' },
        { 'descripcion': 'CATINZACO EMBARCADERO FCGB', 'provincia': 'La Rioja' },
        { 'descripcion': 'CATUNA', 'provincia': 'La Rioja' },
        { 'descripcion': 'CEBOLLAR', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHAMICAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHAMICAL , Est. Gobernador Gordillo', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHAÑAR', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHAÑARMUYO', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHAUPIHUASI', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHELCOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHEPES', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHEPES VIEJO', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHEPES VIEJOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHILA', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHILECITO', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHIMENEA', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHULO', 'provincia': 'La Rioja' },
        { 'descripcion': 'CHUQUIS', 'provincia': 'La Rioja' },
        { 'descripcion': 'CISCO', 'provincia': 'La Rioja' },
        { 'descripcion': 'COCHANGASTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'COLONIA ALFREDO', 'provincia': 'La Rioja' },
        { 'descripcion': 'COLONIA ORTIZ DE OCAMPO', 'provincia': 'La Rioja' },
        { 'descripcion': 'COLORADO , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'COMANDANTE LEAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'CORDOBITA', 'provincia': 'La Rioja' },
        { 'descripcion': 'CORRAL DE ISAAC', 'provincia': 'La Rioja' },
        { 'descripcion': 'CORTADERAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'CORTADERAS EMBARCADERO FCGB', 'provincia': 'La Rioja' },
        { 'descripcion': 'CUATRO ESQUINAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'CUEVA DEL CHACHO', 'provincia': 'La Rioja' },
        { 'descripcion': 'CUIPAN', 'provincia': 'La Rioja' },
        { 'descripcion': 'de CHEPES , sierra', 'provincia': 'La Rioja' },
        { 'descripcion': 'de FAMATINA , sierra', 'provincia': 'La Rioja' },
        { 'descripcion': 'de LA PEÑA NEGRA , paso', 'provincia': 'La Rioja' },
        { 'descripcion': 'de LAS MINAS , sierra', 'provincia': 'La Rioja' },
        { 'descripcion': 'de MALANZÁN , sierra', 'provincia': 'La Rioja' },
        { 'descripcion': 'de PAGANZO , sierra', 'provincia': 'La Rioja' },
        { 'descripcion': 'de PAIMÁN , cadena', 'provincia': 'La Rioja' },
        { 'descripcion': 'de PIRCAS NEGRAS , paso', 'provincia': 'La Rioja' },
        { 'descripcion': 'de SAÑOGASTA , sierra', 'provincia': 'La Rioja' },
        { 'descripcion': 'de UMANGO , sierra', 'provincia': 'La Rioja' },
        { 'descripcion': 'de VELASCO , sierra', 'provincia': 'La Rioja' },
        { 'descripcion': 'del ALTO , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'del ALTO RíO SECO', 'provincia': 'La Rioja' },
        { 'descripcion': 'del PEÑÓN , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'del TORO MUERTO , sierra', 'provincia': 'La Rioja' },
        { 'descripcion': 'DESIDERIO TELLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'DIQUE DE ANZULON', 'provincia': 'La Rioja' },
        { 'descripcion': 'DIQUE LOS SAUCES', 'provincia': 'La Rioja' },
        { 'descripcion': 'DISTRITO PUEBLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL ABRA', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL ALTILLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL ALTO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL BALDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL BARRANCO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL BARREAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL BARRIAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL BAYITO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CALDEN', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CANTADERO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CARRIZAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CATORCE', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CERCO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CHIFLON', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CHOCOY', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CHUSCHIN', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CHUSCO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CIENAGO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CINCUENTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CONDADO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL CONSUELO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL DURAZNILLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL ESCONDIDO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL FRAILE', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL FUERTE', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL GARABATO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL HORNO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL HUACO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL MEDANITO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL MEDANO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL MOLLAR', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL PEDREGAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL PORTEZUELO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL POTRERILLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL POTRERILLO GENERAL ROCA', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL POTRERO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL PUEBLITO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL QUEBRACHO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL RODEO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL SUNCHAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL TALA', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL VALDECITO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL VALLE', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL VALLECITO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL VERDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'EL VILGO', 'provincia': 'La Rioja' },
        { 'descripcion': 'EMBALSE ANZULÓN', 'provincia': 'La Rioja' },
        { 'descripcion': 'EMBALSE EL PORTEZUELO', 'provincia': 'La Rioja' },
        { 'descripcion': 'ESPERANZA DE LOS CERRILLOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'ESQUINA DEL NORTE', 'provincia': 'La Rioja' },
        { 'descripcion': 'ESQUINA DEL SUD', 'provincia': 'La Rioja' },
        { 'descripcion': 'ESQUINA GRANDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'ESTACION 69', 'provincia': 'La Rioja' },
        { 'descripcion': 'ESTACIÓN COMANDANTE LEAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'ESTACIÓN MAZÁN', 'provincia': 'La Rioja' },
        { 'descripcion': 'ESTACIÓN TALAMUYUNA', 'provincia': 'La Rioja' },
        { 'descripcion': 'ESTANCIA DE MAIZ', 'provincia': 'La Rioja' },
        { 'descripcion': 'ESTANCIA LA REPRESA', 'provincia': 'La Rioja' },
        { 'descripcion': 'FALDA DE CITAN', 'provincia': 'La Rioja' },
        { 'descripcion': 'FAMATINA', 'provincia': 'La Rioja' },
        { 'descripcion': 'FLAMENCO', 'provincia': 'La Rioja' },
        { 'descripcion': 'FRANCISCO ORTIZ DE OCAMPO', 'provincia': 'La Rioja' },
        { 'descripcion': 'GOBERNADOR GORDILLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'GRANDE , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'GRANDE DE VALLE HERMOSO , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'GUACHIN', 'provincia': 'La Rioja' },
        { 'descripcion': 'GUANDACOL', 'provincia': 'La Rioja' },
        { 'descripcion': 'GUAYAPA', 'provincia': 'La Rioja' },
        { 'descripcion': 'HUAJA', 'provincia': 'La Rioja' },
        { 'descripcion': 'HUNQUILLAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'ILIAR', 'provincia': 'La Rioja' },
        { 'descripcion': 'ILISCA', 'provincia': 'La Rioja' },
        { 'descripcion': 'ILISCO', 'provincia': 'La Rioja' },
        { 'descripcion': 'ILIZCA', 'provincia': 'La Rioja' },
        { 'descripcion': 'ILLISCA', 'provincia': 'La Rioja' },
        { 'descripcion': 'IMÁN , cerro', 'provincia': 'La Rioja' },
        { 'descripcion': 'INCAHUASI , cerro', 'provincia': 'La Rioja' },
        { 'descripcion': 'ISMIANGO', 'provincia': 'La Rioja' },
        { 'descripcion': 'JAGUE', 'provincia': 'La Rioja' },
        { 'descripcion': 'KILOMETRO 875 ,DV.', 'provincia': 'La Rioja' },
        { 'descripcion': 'KILOMETRO 891 ,AP.', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA AGUADITA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA AGUADITA DE LOS BRIZUELA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA AMERICA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA ANTIGUA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA ANTIGUA , salina', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA ARMONIA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA BANDA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA BUENA ESTRELLA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA BUENA SUERTE', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA CALERA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA CALLANA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA CARRIZANA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA CHILCA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA CHIMENEA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA CIENAGA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA CUADRA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA DORA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA ENVIDIA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA FALDA DE CITAN', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA FLOR', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA IGUALDAD', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA ISLA ,EST.', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA JARILLA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA LANCHA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA LATA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA LIBERTAD', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA LOMITA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA MARAVILLA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA MERCED', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA PALOMA , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA PERLITA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA PERSEGUIDA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA PINTADA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA PIRGUA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA PLAZA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA PUERTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA REPRESA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA RIOJA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA ROSILLA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA SERENA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA TORDILLA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA TORRE', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA TROYA , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'LA YESERA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS AGUADITAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS BOMBAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS CATAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS CLOACAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS COLORADAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS GREDAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS HIGUERILLAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS PADERCITAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS SALINAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS SIERRAS BRAVAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS TALAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS TOSCAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS TUCUMANESAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LAS TUSCAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOMA BLANCA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOMA LARGA', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOROHUASI', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS AGUIRRES', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS ALANICES', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS ALGARROBOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS BALDES', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS BARRIACITOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS BARRIALITOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS BORDOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS COLORADOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS CORIAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS CORRALES', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS FRANCES', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS MOGOTES', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS MOGOTES COLORADOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS MOLINOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS NACIMIENTOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS OLIVARES', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS OLMOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS OROS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS PALACIOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS ROBLES', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS SARMIENTOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'LOS TAMBILLOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'MACHIGASTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'MAJADITA', 'provincia': 'La Rioja' },
        { 'descripcion': 'MALANZÁN', 'provincia': 'La Rioja' },
        { 'descripcion': 'MALARG', 'provincia': 'La Rioja' },
        { 'descripcion': 'MALARG', 'provincia': 'La Rioja' },
        { 'descripcion': 'MALLIGASTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'MASCASíN', 'provincia': 'La Rioja' },
        { 'descripcion': 'MAZAN ,EST.', 'provincia': 'La Rioja' },
        { 'descripcion': 'MEDANO', 'provincia': 'La Rioja' },
        { 'descripcion': 'MESILLAS BLANCAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'MILAGRO', 'provincia': 'La Rioja' },
        { 'descripcion': 'MINA LA MEJICANA', 'provincia': 'La Rioja' },
        { 'descripcion': 'MIRANDA', 'provincia': 'La Rioja' },
        { 'descripcion': 'MOLLACO', 'provincia': 'La Rioja' },
        { 'descripcion': 'MORADITAS , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'NACATE', 'provincia': 'La Rioja' },
        { 'descripcion': 'NEPES', 'provincia': 'La Rioja' },
        { 'descripcion': 'NONOGASTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'ÑOQUEBE', 'provincia': 'La Rioja' },
        { 'descripcion': 'NOQUEVES', 'provincia': 'La Rioja' },
        { 'descripcion': 'OBRAJE DE TOSCANO', 'provincia': 'La Rioja' },
        { 'descripcion': 'OLPAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'OLTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'Otra', 'provincia': 'La Rioja' },
        { 'descripcion': 'PACATALA', 'provincia': 'La Rioja' },
        { 'descripcion': 'PADERCITAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'PAGANCILLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'PAGANZO', 'provincia': 'La Rioja' },
        { 'descripcion': 'PAGANZO , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'PARAJE MONTE GRANDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'PARECITAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'PASO SAN ISIDRO', 'provincia': 'La Rioja' },
        { 'descripcion': 'PASTOS LARGOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'PATQUIA', 'provincia': 'La Rioja' },
        { 'descripcion': 'PENAS BLANCAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'PIEDRA BLANCA , cerro', 'provincia': 'La Rioja' },
        { 'descripcion': 'PIEDRA DE TALAMPAYA', 'provincia': 'La Rioja' },
        { 'descripcion': 'PIEDRA PINTADA', 'provincia': 'La Rioja' },
        { 'descripcion': 'PINCHAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'PITUIL', 'provincia': 'La Rioja' },
        { 'descripcion': 'PLAZA NUEVA', 'provincia': 'La Rioja' },
        { 'descripcion': 'PLAZA VIEJA', 'provincia': 'La Rioja' },
        { 'descripcion': 'POLCO', 'provincia': 'La Rioja' },
        { 'descripcion': 'PORTEZUELO DE ARCE', 'provincia': 'La Rioja' },
        { 'descripcion': 'PORTEZUELO DE LOS ARCE', 'provincia': 'La Rioja' },
        { 'descripcion': 'POTRERO GRANDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'POZO BLANCO', 'provincia': 'La Rioja' },
        { 'descripcion': 'POZO DE AVILA', 'provincia': 'La Rioja' },
        { 'descripcion': 'POZO DE LA ORILLA', 'provincia': 'La Rioja' },
        { 'descripcion': 'POZO DE LA PIEDRA', 'provincia': 'La Rioja' },
        { 'descripcion': 'POZO DE LA YEGUA', 'provincia': 'La Rioja' },
        { 'descripcion': 'POZO DE PIEDRA', 'provincia': 'La Rioja' },
        { 'descripcion': 'POZO DEL MEDIO', 'provincia': 'La Rioja' },
        { 'descripcion': 'POZO ESCONDIDO', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUERTA DE LA QUEBRADA', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUERTO ALEGRE', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUERTO DEL VALLE', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUESTO DE CARRIZO', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUESTO DE LOS SANCHEZ', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUESTO DICHOSO', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUESTO TALITA', 'provincia': 'La Rioja' },
        { 'descripcion': 'PULUCHAN', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUNILLAS , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUNTA DE AGUA', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUNTA DE LOS LLANOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUNTA DEL CERRO', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUNTA DEL MÉDANO , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'PUNTA DEL NEGRO', 'provincia': 'La Rioja' },
        { 'descripcion': 'QUEBRACHAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'QUEBRADA DEL VALLECITO', 'provincia': 'La Rioja' },
        { 'descripcion': 'REAL DEL CADILLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'REAL DEL CADILLO ,DV.', 'provincia': 'La Rioja' },
        { 'descripcion': 'REPRESA DE LA PUNTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'REPRESA DEL MONTE', 'provincia': 'La Rioja' },
        { 'descripcion': 'RETAMAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'RIO DE LAS CANAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'RIOS DE LAS MESADAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'RIOS DE LOS COLCOLES', 'provincia': 'La Rioja' },
        { 'descripcion': 'RIVADAVIA', 'provincia': 'La Rioja' },
        { 'descripcion': 'SALADILLO', 'provincia': 'La Rioja' },
        { 'descripcion': 'SALADO , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'SALADO , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'SALANA', 'provincia': 'La Rioja' },
        { 'descripcion': 'SALICAS  SAN BLAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'SALINAS DE BUSTOS', 'provincia': 'La Rioja' },
        { 'descripcion': 'SALINAS DEL LEONCITO', 'provincia': 'La Rioja' },
        { 'descripcion': 'SAMAY HUASI', 'provincia': 'La Rioja' },
        { 'descripcion': 'SAN ANTONIO', 'provincia': 'La Rioja' },
        { 'descripcion': 'SAN BLAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'SAN ISIDRO', 'provincia': 'La Rioja' },
        { 'descripcion': 'SAN JUAN', 'provincia': 'La Rioja' },
        { 'descripcion': 'SAN PEDRO', 'provincia': 'La Rioja' },
        { 'descripcion': 'SAN RAMON', 'provincia': 'La Rioja' },
        { 'descripcion': 'SAN ROQUE', 'provincia': 'La Rioja' },
        { 'descripcion': 'SAN SOLANO', 'provincia': 'La Rioja' },
        { 'descripcion': 'SAÑOGASTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'SANTA CLARA', 'provincia': 'La Rioja' },
        { 'descripcion': 'SANTA CRUZ', 'provincia': 'La Rioja' },
        { 'descripcion': 'SANTA CRUZ ,DV.', 'provincia': 'La Rioja' },
        { 'descripcion': 'SANTA FLORENTINA', 'provincia': 'La Rioja' },
        { 'descripcion': 'SANTA RITA DE CATUNA', 'provincia': 'La Rioja' },
        { 'descripcion': 'SANTA VERA CRUZ', 'provincia': 'La Rioja' },
        { 'descripcion': 'SANTO DOMINGO', 'provincia': 'La Rioja' },
        { 'descripcion': 'SANTO DOMINGO DEPARTAMENTO FAM', 'provincia': 'La Rioja' },
        { 'descripcion': 'SCHAQUI', 'provincia': 'La Rioja' },
        { 'descripcion': 'SENOR DE LA PENA', 'provincia': 'La Rioja' },
        { 'descripcion': 'SIEMPRE VERDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'SIERRA BRAVA', 'provincia': 'La Rioja' },
        { 'descripcion': 'SOLCA', 'provincia': 'La Rioja' },
        { 'descripcion': 'SURIYACO', 'provincia': 'La Rioja' },
        { 'descripcion': 'TALA VERDE', 'provincia': 'La Rioja' },
        { 'descripcion': 'TALAMPAYA', 'provincia': 'La Rioja' },
        { 'descripcion': 'TALAMUYUNA', 'provincia': 'La Rioja' },
        { 'descripcion': 'TALVA', 'provincia': 'La Rioja' },
        { 'descripcion': 'TAMA', 'provincia': 'La Rioja' },
        { 'descripcion': 'TASQUIN', 'provincia': 'La Rioja' },
        { 'descripcion': 'TELLO, DESIDERIO', 'provincia': 'La Rioja' },
        { 'descripcion': 'TENDAL , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'TERMAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'TERMAS DE SANTA TERESITA', 'provincia': 'La Rioja' },
        { 'descripcion': 'TERMAS SANTA TERESITA', 'provincia': 'La Rioja' },
        { 'descripcion': 'TILIMUQUI', 'provincia': 'La Rioja' },
        { 'descripcion': 'TINOCAN', 'provincia': 'La Rioja' },
        { 'descripcion': 'TORRECITAS', 'provincia': 'La Rioja' },
        { 'descripcion': 'TOTORAL', 'provincia': 'La Rioja' },
        { 'descripcion': 'TRAMPA DEL TIGRE', 'provincia': 'La Rioja' },
        { 'descripcion': 'TUANI', 'provincia': 'La Rioja' },
        { 'descripcion': 'TUIZON', 'provincia': 'La Rioja' },
        { 'descripcion': 'TUYUBIL', 'provincia': 'La Rioja' },
        { 'descripcion': 'UDPINANGO', 'provincia': 'La Rioja' },
        { 'descripcion': 'ULAPES', 'provincia': 'La Rioja' },
        { 'descripcion': 'UMANGO , río', 'provincia': 'La Rioja' },
        { 'descripcion': 'VERDE OLIVO', 'provincia': 'La Rioja' },
        { 'descripcion': 'VICHIGASTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'VIDAL GORMAZ , cerro', 'provincia': 'La Rioja' },
        { 'descripcion': 'VILLA CASANA', 'provincia': 'La Rioja' },
        { 'descripcion': 'VILLA CASTELLI', 'provincia': 'La Rioja' },
        { 'descripcion': 'VILLA CHEPES', 'provincia': 'La Rioja' },
        { 'descripcion': 'VILLA MAZÁN', 'provincia': 'La Rioja' },
        { 'descripcion': 'VILLA NIDIA', 'provincia': 'La Rioja' },
        { 'descripcion': 'VILLA SAN JOSÉ DE VINCHINA', 'provincia': 'La Rioja' },
        { 'descripcion': 'VILLA SANAGASTA', 'provincia': 'La Rioja' },
        { 'descripcion': 'VILLA SANTA RITA', 'provincia': 'La Rioja' },
        { 'descripcion': 'VILLA UNIÓN', 'provincia': 'La Rioja' },
        { 'descripcion': 'VINCHINA', 'provincia': 'La Rioja' },
        { 'descripcion': '25 DE MAYO', 'provincia': 'Mendoza' },
        { 'descripcion': '3 DE MAYO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ACONCAGUA , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'ADRIAN MATURANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGRELO', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA AMARGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA BOTADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA DE CABRERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA DE DIAZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA DE LA MULA', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA DE LOS MANANTIALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA DEL CHANCHO', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA ESCONDIDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA NUEVA', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA NUEVA ,EMB.CAÑADA AMARILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUA RICA', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUADA DE TORO', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUADA PENEPE', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUADA PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'AGUADA PUESTO LA TOTORA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALBERTO FLORES', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALDEA LA PRIMAVERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALEJANDRO PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALFREDO LUCERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALGARROBAL ABAJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALGARROBAL ARRIBA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALGARROBITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALGARROBO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALGARROBO DE SORTUE', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALGARROBO GRANDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALPATACAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO AMARILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO CON ZAMPA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO DE LAS ARANAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO DE LOS MANANTIALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO DE LOS PERROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO DE LOS SAPOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO DEL OLVIDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO DEL PAYÚN , bordo', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO DEL PLOMO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO DEL SALVADOR', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO GRANDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO TRES COMPADRES', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTO VERDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALTOS DEL OLVIDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ALVAREZ CONDARCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ANA DE DONAIRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'ANACLETA VIUDA DE PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'ANCHORIS', 'provincia': 'Mendoza' },
        { 'descripcion': 'ANCON', 'provincia': 'Mendoza' },
        { 'descripcion': 'ANDRADE', 'provincia': 'Mendoza' },
        { 'descripcion': 'ANDRES PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'ANTONIO SOSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ARAGANITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ARANCIBIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ARANZABAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'ARBOLEDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ARENALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'ARíSTIDES VILLANUEVA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ARROYITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ARROYO CLARO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ARROYO LOS SAUCES', 'provincia': 'Mendoza' },
        { 'descripcion': 'ASUNCION', 'provincia': 'Mendoza' },
        { 'descripcion': 'ATUEL , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'ATUEL SUD', 'provincia': 'Mendoza' },
        { 'descripcion': 'AZCURRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'AZUL , sierra', 'provincia': 'Mendoza' },
        { 'descripcion': 'AZUL CASA , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'B DE ARAYA', 'provincia': 'Mendoza' },
        { 'descripcion': 'B DE QUEBRADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'B ELENA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJADA ARAUJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJADA DE LA SALADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJADA DE LOS GAUCHOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJADA DE LOS PAPAGAYOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJADA DE YAUCHA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJADA DEL AGUA ESCONDIDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJADA DEL FUERTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJADA DEL PERRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJADA DEL SAUCE', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJO DEL PELUDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJO DEL YUYO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAJO LUNLUNTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE DE LA JARILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE DE LOS GAUCHOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE DE PIEDRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE E AQUERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE EL SOSNEADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE JOFRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE LA PICHANA VIEJA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE LAS CARPAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE LAS CATITAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE LAS LAGUNITAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE NUEVO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BALDE SAN AGUSTIN', 'provincia': 'Mendoza' },
        { 'descripcion': 'BANADO VERDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'BANDERITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BANOS DE AZUFRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'BANOS DE CAPIZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'BANOS LA SALADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BAÑOS LUNLUNTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARCALA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARDAS BLANCAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARRANCAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARRAQUERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARREAL COLORADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARREAL DE LA MESILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARREAL DE LA PAMPA SECA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARREAL JOSE LUIS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARREAL PAJARO MUERTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARRIALES LOS RANCHOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARRIO FERRI', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARRIO JARDIN LUZURIAGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARRIO LENCINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARRIO SAN EDUARDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BARRIO VILLA ADELA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BECERRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BELLE VILLE', 'provincia': 'Mendoza' },
        { 'descripcion': 'BERMEJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BLANCO ENCALADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BLAS PANELO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BOLICHE', 'provincia': 'Mendoza' },
        { 'descripcion': 'BOLICHE LOS BARREALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'BORDE DE LA LINEA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BORDO AMARILLO DE LA CRUZ PIED', 'provincia': 'Mendoza' },
        { 'descripcion': 'BORDO EL ALGARROBO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BORDO LECHUZO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BORDOS DEL PLUMERILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'BOWEN', 'provincia': 'Mendoza' },
        { 'descripcion': 'BOYEROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'BUEN ORDEN', 'provincia': 'Mendoza' },
        { 'descripcion': 'BUENA NUEVA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BUITRERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'BUTA BILLON', 'provincia': 'Mendoza' },
        { 'descripcion': 'C GONZALES VIDELA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CA DEL DIABLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CABEZA DE VACA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CACHEUTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CADETES DE CHILE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAJON DE MAYO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAJON GRANDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CALLE LARGA VIEJA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CALLE TERRADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CALMUCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMP VIZCACHERAS YPF', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMPAMENTO CACHEUTA YPF', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMPAMENTO CARAPACHO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMPAMENTO RANQUILCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMPAMENTOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMPANARIO , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMPO DE LOS ANDES', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMPO EL ALAMO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMPO EL TORO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMPO LOS ANDES', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAMPOS, GASPAR', 'provincia': 'Mendoza' },
        { 'descripcion': 'CANADA AMARILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CANADA ANCHA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CANADA COLORADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAÑADA SECA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CANADITA ALEGRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CANADON DE BONILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CANAL PESCARA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CANALEJAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CANCHA DE ESQUI', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAPACHO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAPDEVILLE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAPILLA DEL COVADITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAPILLA DEL ROSARIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAPILLA SAN JOSE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAPITAN MONTOYA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CAPIZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'CARACOLES', 'provincia': 'Mendoza' },
        { 'descripcion': 'CARBOMETAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'CARLOS SUBERCASEUX', 'provincia': 'Mendoza' },
        { 'descripcion': 'CARMENSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CARRIL NORTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CARRIZAL DE ABAJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CARRIZAL DE ARRIBA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CARRODILLA LA PUNTILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CARTELLONE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CASA DE ADOBE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CASA DE LAS PENAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CASAS VIEJAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CASILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CATITAS VIEJAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CENTRAL HIDROELECTRICA 1', 'provincia': 'Mendoza' },
        { 'descripcion': 'CENTRAL HIDROELECTRICA 2', 'provincia': 'Mendoza' },
        { 'descripcion': 'CEPILLO VIEJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRILLOS AL NORTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRILLOS AL SUD', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRILLOS NEGROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRITO MORO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO ACONCAGUA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO AGUA NEGRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO AGUA SALADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO AGUADITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO ALOJAMIENTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO ALQUITRAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO ALTO DE LAS PENAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO ALVARADO CENTRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO ANGOSTURA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO ARROYO HONDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO ASPERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO BALEADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO BARAUCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO BARBARAN O TRES PICOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO BAY', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO BLANCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO BONETE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO BRAVO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO CATEDRAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO CHATO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO CHIQUERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO CIELO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO CIENAGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO CLEMENTINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO COLOR', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO COLORADO DE LAS LAGUNILL', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO CORTADERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO CUERNO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO CUPULA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DE LA BANDEROLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DE LAS LENAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DE LOS BURROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DE LOS DEDOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DE LOS LEONES', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DE LOS POTRERILLOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DEL CHACAY', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DEL MEDIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DEL POZO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DEL RINCON BAYO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DEL ZORRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DIVISADERO DE LA CIENEGU', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO DURAZNO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO EL GUANACO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO FIERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO FUNDICION', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO GASPAR', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO GRANDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO GUADALOSO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO HORQUETA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO INVERNADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO JUAN POBRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO L CORRALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO LA INVERNADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO LA MANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO LAGANOSO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO LAS PIEDRAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO LOS BAJOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO LOS BARROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO LOS DIENTITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO MANANTIAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO MASILLAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO MELOCOTON', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO MEXICO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO MONTURA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO NEGROS DE LAS MESILLAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO NEVADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PAMPA SECA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PAN DE AZUCAR', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PANTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PELADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PIEDRA COLORADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PLOMO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PONDERADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO POTRERILLOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO POZO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PUNTA DE AGUA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PUNTILLA NEGRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PUNTUDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO PUQUIOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO RIQUITIPANCHE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO SAN LORENZO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO SANTA MARIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO SAPO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO TIGRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO TOLOSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO TUNDUQUERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO YALGUARAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERRO YARETA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CERROS COLORADOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CESPEDES', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHACHAO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHACHARALEN', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHACHAUÉN , sierra', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHACHINGO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHACRAS DE CORIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHACRAS DE CORIA ,EST.PASO DE LOS ANDES', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHACRAS DE LIMA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHAMUSCAO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHANARAL REDONDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHAPANAY', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHARCO VACAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHILECITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHILOTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CHIMBA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CIENAGA DEL ALTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CIRCUNVALACION', 'provincia': 'Mendoza' },
        { 'descripcion': 'CIRILO MAHONA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CIUDAD DE JUNIN', 'provincia': 'Mendoza' },
        { 'descripcion': 'CIUDAD OESTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CLARENTINO ROLDAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'CLODOMIRO RETA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COCHICO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COEHUE-CO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COIHUECO NORTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLA MORA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLON SANDALHO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA 3 DE MAYO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ALAZANES', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ALEMANA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ALVEAR', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ALVEAR OESTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ANDRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ASPERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ATUEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ATUEL NORTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA BARRAQUERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA BOMBAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA BOMBAL Y TABANERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA BOUQUET', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA CANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA CHALET', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA CHATO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA COLINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA COLOMER', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA CURNINAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA DE LAS MULAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA DE LOS GUANAQUEROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA DEL CARMEN', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA DEL DIABLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA DEL LEON', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA DELFINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA DIVISADERO DEL CARDAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA DIVISADERO NEGRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA DURAZNO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA EL CAMPANARIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA EL REGADIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ELENA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ESPANOLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ESTRELLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA FARO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA FUNES', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA GUADAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA GUANACO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA ITALIANA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA JARA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA JAUREGUI', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA LA ESCONDIDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA LA LLAVE', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA LA TORRECILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA LAMBARE', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA LAS ROSAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA LOLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA LOPEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA LOS HUEVOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA LOS OSCUROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA LOS TAPONES', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA MIRADOR', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA MONTE CASEROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA MURALLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA NACIONAL DE LOS INDIOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA NEGRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA OSAMENTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA PAPAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA PASCUAL IACARINI', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA PEDERNALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA PENCAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA PICO COLORADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA REINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA RUSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA SAN AGUSTIN', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA SAN FRANCISCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA SEGOVIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA SOITUE', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA SUR', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA TABANERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA TORRECILLAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA TORRECITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COLONIA TRES ALTITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COM NAC DE ENERGIA ATOMICA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COMANDANTE SALAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COMISION NAC DE EMERGENCIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'COMPUERTAS NEGRAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CONCHA SUBERCASEAUX', 'provincia': 'Mendoza' },
        { 'descripcion': 'CONTROL YPF', 'provincia': 'Mendoza' },
        { 'descripcion': 'COQUIMBITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CORDON DE PLATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CORONEL BELTRAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'CORONEL DORREGO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CORRAL DE CUERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CORRAL DE LORCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CORRAL DEL MOLLE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CORRALITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'COSTA DE ARAUJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'COSTA DEL DIAMANTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CRISTO REDENTOR', 'provincia': 'Mendoza' },
        { 'descripcion': 'CRISTOBAL LOPEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'CRUZ BLANCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CRUZ DE PIEDRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CRUZ DE PIEDRA PTO GENDARMERIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CRUZ DEL YUGO', 'provincia': 'Mendoza' },
        { 'descripcion': 'CRUZ GIMENEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'CRUZ LEDESMA', 'provincia': 'Mendoza' },
        { 'descripcion': 'CTO DEL TIGRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'CUADRO BENEGAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CUADRO BOMBAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'CUADRO NACIONAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'CUESTA DE LOS TERNEROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'CUPILES', 'provincia': 'Mendoza' },
        { 'descripcion': 'D LOPEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'DALMIRO ZAPATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'DANIEL LUCERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'DANIEL MORGAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'de LA CUMBRE , paso', 'provincia': 'Mendoza' },
        { 'descripcion': 'de LA POLLERA , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'de LA PUNILLA , sierra', 'provincia': 'Mendoza' },
        { 'descripcion': 'de LA TRISTEZA , cuchilla', 'provincia': 'Mendoza' },
        { 'descripcion': 'de LA VARITA , pampa', 'provincia': 'Mendoza' },
        { 'descripcion': 'de LAS TUNAS , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'de LOS PAPAGAYOS , arroyo', 'provincia': 'Mendoza' },
        { 'descripcion': 'de LOS PIUQUENES , portezuelo', 'provincia': 'Mendoza' },
        { 'descripcion': 'de MARY , cordÓn', 'provincia': 'Mendoza' },
        { 'descripcion': 'de MASCASíN , salinas', 'provincia': 'Mendoza' },
        { 'descripcion': 'de RANQUILCÓ , salitral', 'provincia': 'Mendoza' },
        { 'descripcion': 'de URETA , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'de USPALLATA , sierra', 'provincia': 'Mendoza' },
        { 'descripcion': 'del DIMANTE , pampa', 'provincia': 'Mendoza' },
        { 'descripcion': 'del MAIPO , paso', 'provincia': 'Mendoza' },
        { 'descripcion': 'del MAIZ GORDO , sierra', 'provincia': 'Mendoza' },
        { 'descripcion': 'del MEDIO , arroyo', 'provincia': 'Mendoza' },
        { 'descripcion': 'del NEVADO , sierra', 'provincia': 'Mendoza' },
        { 'descripcion': 'del PAYÚN , altiplanicie', 'provincia': 'Mendoza' },
        { 'descripcion': 'del PLANCHÓN , portezuelo', 'provincia': 'Mendoza' },
        { 'descripcion': 'del PLATA , cordÓn', 'provincia': 'Mendoza' },
        { 'descripcion': 'del TUNUYÁN , travesía', 'provincia': 'Mendoza' },
        { 'descripcion': 'DELGADILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'DESAGUADERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'DIAMANTE , laguna', 'provincia': 'Mendoza' },
        { 'descripcion': 'DIAMANTE , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'DIONISIO ORDONEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'DIONISIO ORTUBIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'DIQUE DEL VALLE DE UCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'DIQUE RIO MENDOZA', 'provincia': 'Mendoza' },
        { 'descripcion': 'DISTRITO COMPUERTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'DIVISADERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'DIVISADERO COLORADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'DIVISADERO NEGRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'DOCTOR ANTONIO SOOMAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'DOMINGO GIMENEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'DOMINGO LARA', 'provincia': 'Mendoza' },
        { 'descripcion': 'DOMINGO OGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'DOMINGO REAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'DON MARTIN', 'provincia': 'Mendoza' },
        { 'descripcion': 'DONATO OJEDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'DOROTEO ORDONEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'DULCE', 'provincia': 'Mendoza' },
        { 'descripcion': 'E ROSALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL 15', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ALAMBRADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ALAMO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ALGARROBAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ALGARROBO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ALPERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ALPERO ,EMB.', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ALTILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ALTO SALVADOR', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL AZUFRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL BALSADERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL BANDERON', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL BONITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL BORBOLLON', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL BUEN PASTOR', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CABAO VIEJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CALVADITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CAMPAMENTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CANITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CAPACHO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CARANCHITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CARBALINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CARRIZAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CARRIZAL ABAJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CARRIZAL DE ABAJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CARRIZAL DE ARRIBA', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CAVADITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CAVADO CHICO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CEIBO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CENIZO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CENTRAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CEPILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CERCADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CHACALLAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CHACAY', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CHACAY , arroyo', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CHALET', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CHALLAO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CHILCAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CHIRCAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CHOIQUE', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CIENAGO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CIPRES', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL COLON', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CONSUELO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL CORBALNO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL DESVIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ESCONDIDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL FORZADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL GIGANTILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL GONZANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL GORGINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL GUADAL DE CAMPOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL GUANACO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL GUERRINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL INFIERNILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL INFIERNO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL JILGUERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL JUME', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL JUNCALITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL LECHUCITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL LEMINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL LUQUINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL MANZANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL MANZANO HISTORICO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL MARCADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL MAURINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL MIRADOR', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL MOYANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL NANGO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL NEVADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL NIHUIL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL NIHUIL ,EST.', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PARAISO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PARRAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PASCAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PASTAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PAYEN', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PERAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PERINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PICONA', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PLATEADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PLUMERILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PLUMERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PORTILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PUMA', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL PUNTIAGUDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL REFUGIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL REGADIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL RESGUARDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL RETAMO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL RETAMOSO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL RINCON', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL SALTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL SAUCE', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL SOSNEADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL TAMARINDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL TAPON', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL TOPON', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL TORDILLO , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL TOSCAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL TROPEZON', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL USILLAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL VALLE', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL VAQUERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL VATRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL VENTARRON', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL VERGEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL VILLEGUINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL VILTEGUINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ZAMPAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ZAPATINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EL ZORZAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'ELOY FUNES', 'provincia': 'Mendoza' },
        { 'descripcion': 'EMBALSE AGUA DEL TORO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EMBALSE EL CARRIZAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EMBALSE EL NIHUIL', 'provincia': 'Mendoza' },
        { 'descripcion': 'EMBALSE LOS REYUNOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'EMBALSE VALLE GRANDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'EMILIANO LUCERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EMILIO NIETA', 'provincia': 'Mendoza' },
        { 'descripcion': 'EMPALME FRONTERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'EMPALME RESGUARDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EPIFANIO FERNANDEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'ERNESTO ALCARAZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'ERNESTO LOPEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESCUDERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESPEJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESPEJO RESGUARDADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESPINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESQUINA PESCADERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTACIÓN USPALLATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA AGUANDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA ARROYO HONDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA AVEIRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA BELLA VISTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA CASA DE PIEDRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA CASAS VIEJAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA CHACAICO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA CORREA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA CUEVA DEL TORO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA EL BALDERON', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA EL BONITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA EL CAMPAMENTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA EL CARRIZAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA EL CARRIZALITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA GIL', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA GUINAZU', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA JOCOLI', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA ARGENTINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA CHUNA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA CORTADERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA PAMPA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA PUMA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA ROSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA SALCEDINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA SARITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA TRINTRICA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA VARITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LA VIZCACHERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LAS CHILCAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LAS HIGUERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LAS VIZCACHERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LOS CHACAYES', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LOS HUAICOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA LOS LEONES', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA MALLEA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA SAN MARTIN', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA SILVA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA SOFIA RAQUEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA TIERRAS BLANCAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA USPALLATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA VILLAVICENCIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA VILUCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA YALGUARAZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANCIA YAUCHA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ESTANISLAO ORDONEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'EUGENIO BUSTOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'EUSEBIA VIUDA DE GOMEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'EVARISTO ACEVEDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'EVARISTO SALAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'EX FORTIN MALARGUE', 'provincia': 'Mendoza' },
        { 'descripcion': 'FABRICIANO ROJAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'FELIPE GARRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'FELIPE PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'FERMIN PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'FINCA EL ARROZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'FINCA LOPEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'FINCA LOS ALAMOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'FLORENCIO GARRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'FLORENCIO MOLINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'FLORENCIO ORDONEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'FLORINDO FLORES', 'provincia': 'Mendoza' },
        { 'descripcion': 'FORZUDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'FRANCISCO MOLINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'FRANCISCO ROJAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'FRAY LUIS BELTRAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'FRUCTUOSO DIAZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'GARGANTA DEL LEON', 'provincia': 'Mendoza' },
        { 'descripcion': 'GASPAR CAMPOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'GENERAL ALVEAR', 'provincia': 'Mendoza' },
        { 'descripcion': 'GENERAL AMIGORENA ,EMB.', 'provincia': 'Mendoza' },
        { 'descripcion': 'GENERAL GUTIERREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'GENERAL LAVALLE', 'provincia': 'Mendoza' },
        { 'descripcion': 'GENERAL ORTEGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'GERMAN MATURANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'GERMANIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'GERTRUDIS DE OJEDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'GILBERTO PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'GLACIARES DEL RIO BLANCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'GOBERNADOR BENEGAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'GOBERNADOR CIVIT', 'provincia': 'Mendoza' },
        { 'descripcion': 'GOBERNADOR LUIS MOLINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'GODOY CRUZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'GOICO', 'provincia': 'Mendoza' },
        { 'descripcion': 'GOUDGE', 'provincia': 'Mendoza' },
        { 'descripcion': 'GREGORIO ZAPATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'GUADAL DE LOS PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'GUADALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'GUALTALLARY', 'provincia': 'Mendoza' },
        { 'descripcion': 'GUAYMALLÉN', 'provincia': 'Mendoza' },
        { 'descripcion': 'GUAYQUERIA COLORADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'GUIDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'GUILLERMO DONAIRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'GURRUCHAGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'H GARZALA', 'provincia': 'Mendoza' },
        { 'descripcion': 'HERMENEGILDO DIAZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'HONORIO ZAPATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'HORNITO DEL GRINGO', 'provincia': 'Mendoza' },
        { 'descripcion': 'HORNOS DE MOYANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'HOTEL PORTEZUELO DEL VIENTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'HOTEL TERMAS DEL AZUFRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'HOTEL TERMAS EL SOSNEADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'HUAICOS DE RUFINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'HUAIQUERIA DE LA HORQUETA', 'provincia': 'Mendoza' },
        { 'descripcion': 'HUAIQUERIA DE LOS BURROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'HUECOS DE LOS TORDILLOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'IGNACIO VILLEGAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'IGUAZU', 'provincia': 'Mendoza' },
        { 'descripcion': 'INGENIERO BALLOFFET', 'provincia': 'Mendoza' },
        { 'descripcion': 'INGENIERO GIAGNONI', 'provincia': 'Mendoza' },
        { 'descripcion': 'INGENIERO GUSTAVO ANDRÉ', 'provincia': 'Mendoza' },
        { 'descripcion': 'IRINEO ZAPATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ISABEL FLORES', 'provincia': 'Mendoza' },
        { 'descripcion': 'ISELIN, RODOLFO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ISLA CHICA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ISLA DEL CUCHILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ISLA GRANDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'ISLA RETAMITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'J CAMPOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'J ORTUBIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'J VERON', 'provincia': 'Mendoza' },
        { 'descripcion': 'JAGUEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'JAGUEL AMARILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'JAGUEL DE LAS CHILCAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'JAGUEL DE ROSAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'JAGUEL DEL CATALAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'JAGUEL DEL GAUCHO', 'provincia': 'Mendoza' },
        { 'descripcion': 'JAGUEL DEL GOBIERNO', 'provincia': 'Mendoza' },
        { 'descripcion': 'JAIME PRATS', 'provincia': 'Mendoza' },
        { 'descripcion': 'JARILLOSO', 'provincia': 'Mendoza' },
        { 'descripcion': 'JAUCHA', 'provincia': 'Mendoza' },
        { 'descripcion': 'JESUS NAZARENO', 'provincia': 'Mendoza' },
        { 'descripcion': 'JOCOLí', 'provincia': 'Mendoza' },
        { 'descripcion': 'JOCOLI VIEJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'JOFRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'JORGE NEWBERY', 'provincia': 'Mendoza' },
        { 'descripcion': 'JOSE CAMPOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'JOSE DIAZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'JOSE FERNANDEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'JOSE LUCERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'JOSE R MOLINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'JOSE SOSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'JOSE SUAREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'JUAN B DUFAU', 'provincia': 'Mendoza' },
        { 'descripcion': 'JUAN H ORTIZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'JUAN MILLAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'JUAN ZAPATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'JULIO COMEGLIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'JUNCAL , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'JUNíN', 'provincia': 'Mendoza' },
        { 'descripcion': 'JUNTA DE LA VAINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'JUNTA DE LOS RIOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'KILOMETRO 976 ,EST.', 'provincia': 'Mendoza' },
        { 'descripcion': 'L DEL AGUERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'L PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'L PRADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA ADELINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA ARBOLEDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA BANDERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA BARDA CORTADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA BATRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA BOVEDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CALDENADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CARRERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CARRODILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CARUSINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CASA DEL TIGRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CAUTIVA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CELIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CENTRAL RIVADAVIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CHAPINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CHILCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CHIMBA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CHUNA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CIENAGUITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA COLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA COLONIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA COLONIA SUD', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CONSULTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA CORONA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA COSTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA DIVISORIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA DORMIDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA ESCANDINAVA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA ESTACADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA ESTRECHURA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA EXCAVACION', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA FAVORITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA FORTUNA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA FUNDICION', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA GUEVARINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA HOLANDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA HORQUETA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA HULLERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA ISLA CHICA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA ISLA GRANDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA JACINTITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA JACINTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA JAULA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA JUNTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA LAGUNITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA LATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA LEONA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA LIBERTAD', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA LLAVE NUEVA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA LLAVE VIEJA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA LUNINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA MARZOLINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA MINA DEL PECENO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA MONTILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA MORA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA NEGRITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA PALMERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA PALOMA , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA PASTORAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA PAZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA PEGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA PICARONA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA PICASA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA PICHANA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA PIRATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA POMONA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA QUEBRADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA SALINILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA SOMBRILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA TOSCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA UNION', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA VALENCIANA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA VARITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA VASCONIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LA YESERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LADISLAO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LADISLAO CAMPOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAGUNA DE GUANACACHE', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAGUNA DEL ROSARIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAGUNA NEGRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAGUNA SALADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAGUNITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS ARABIAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS CANTERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS CARDITAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS CATITAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS CATITAS , Est. JosÉ NÉstor Lencinas', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS CHACRAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS CHACRITAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS COLONIAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS COMPUERTAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS COMPUERTAS NEGRAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS CORTADERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS CRUCES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS CUEVAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS GATEADAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS GATITAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS HERAS , Est. Cabina Tamarindos', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS LEÑAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS LOICAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS MALVINAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS MINAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS PAREDES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS PAREDES ,EST.CAPITAN MONTOYA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS PINTADAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS RAJADURAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS TAGUAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS TORRECITAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS TOTORITAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS VAYAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS VEGAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS VIOLETAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS VISCACHERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS VISTAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS VIZCACHAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAS YEGUAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LAVALLE VILLA TULUMAYA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LECHUZO , arroyo', 'provincia': 'Mendoza' },
        { 'descripcion': 'LEZCANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LIBERTADOR GRAL SAN MARTIN', 'provincia': 'Mendoza' },
        { 'descripcion': 'LIMON', 'provincia': 'Mendoza' },
        { 'descripcion': 'LINO PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'LIRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LISANDRO ESCUDERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LLANCANELO , laguna', 'provincia': 'Mendoza' },
        { 'descripcion': 'LLANO BLANCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOA ALAMITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA CHATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA CHATA , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA COLORADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA DE LOS BURROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA DEL CERRO ASPERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA DEL CHANAR', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA DEL MEDIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA EXTENDIDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA JAGUEL DEL GAUCHO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA NEGRA GRANDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA PELADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMA SOLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMAS ALTAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMAS BAYAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMAS BAYAS , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMAS BLANCAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMAS CHICAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMAS COLORADAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMITA LARGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOMITA MORADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LONCO VACAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS AHUMADOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS ALGARROBOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS ALGODONES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS ALTAMIQUES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS AMARILLOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS ARBOLES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS ARBOLES DE VILLEGAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS ARROYOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS BARRIALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS BRITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS BURGOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS CAMPAMENTOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS CARRIZALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS CHACAYES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS CLAVELES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS COLADOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS COMETIERRAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS COMPARTOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS CORRALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS CORRALITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS CORREDORES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS EMBARQUES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS EUCALIPTOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS FILTROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS HORCONCITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS HUARPES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS HUARPES ,AP.', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS MEDANOS NEGROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS MOLLES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS MOROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS OLMOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS OTOYANES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS PARAMILLOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS PARLAMENTOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS PATOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS PEJECITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS PEJES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS PENITENTES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS POZOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS RAMBLONES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS REPUNTES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS REYUNOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS ROSETI', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS SAUCES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS SAUCES LAVALLE', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS TAMARINDOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS TERNEROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS TERNEROS ,DV.', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS TOLDITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS TORDILLOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS TOSCALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS VERDES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS VILLEGAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS YAULLINES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LOS YOLES', 'provincia': 'Mendoza' },
        { 'descripcion': 'LUANCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LUCAS DONAIRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'LUIS MARQUEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'LUJÁN DE CUYO', 'provincia': 'Mendoza' },
        { 'descripcion': 'LUNLUNTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'LUZURIAGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'M ESCUDERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'M QUIROGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MAIPO , volcÁn', 'provincia': 'Mendoza' },
        { 'descripcion': 'MAIPÚ', 'provincia': 'Mendoza' },
        { 'descripcion': 'MALAL DEL MEDIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MALARGUE', 'provincia': 'Mendoza' },
        { 'descripcion': 'MALLIN QUEMADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MAQUINISTA LEVET', 'provincia': 'Mendoza' },
        { 'descripcion': 'MARAVILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MARIA GARCIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MARIA VIUDA DE DONAIRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'MARIO OLGUIN', 'provincia': 'Mendoza' },
        { 'descripcion': 'MASA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MATANCILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MATHIEU NORTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'MATIAS GARRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MATONCILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MATURANA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MAULE o PEHUENCHE , paso', 'provincia': 'Mendoza' },
        { 'descripcion': 'MAURICIO CALDERON', 'provincia': 'Mendoza' },
        { 'descripcion': 'MAYOR DRUMMOND', 'provincia': 'Mendoza' },
        { 'descripcion': 'MECHANQUIL', 'provincia': 'Mendoza' },
        { 'descripcion': 'MECHENGUIL', 'provincia': 'Mendoza' },
        { 'descripcion': 'MEDANOS COLORADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MEDARDO MIRANDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MEDIA LUNA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MEDRANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MELITON CAMPOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'MELOCOTON', 'provincia': 'Mendoza' },
        { 'descripcion': 'MENDOZA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MENDOZA , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'MESETA COLORADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MIGUEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINA ARGENTINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINA ETHEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINA HUEMUL', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINA SANTA CRUZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINA VOLCAN OVERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINACAR', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINAS DE PETROLEO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINAS DEL NEVADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINAS EL SOSNEADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINAS SALAGASTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'MINELLI', 'provincia': 'Mendoza' },
        { 'descripcion': 'MOJON OCHO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MOLINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MOLUCHES', 'provincia': 'Mendoza' },
        { 'descripcion': 'MONTE BAYO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MONTE COMAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'MONUMENTO AL EJERCITO DE LOS A', 'provincia': 'Mendoza' },
        { 'descripcion': 'MORON CHICO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MORON VIEJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MORRO DEL CUERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MOYANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'MOYANO, FEDERICO J.', 'provincia': 'Mendoza' },
        { 'descripcion': 'N ZAPATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ÑACUÑÁN', 'provincia': 'Mendoza' },
        { 'descripcion': 'NANCUNAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'NATALIA DONAIRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'NECTO SOSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'NEGRO QUEMADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'NESTOR AGUILERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'NEVADO , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'NEWBERY, JORGE', 'provincia': 'Mendoza' },
        { 'descripcion': 'NICOLAS ORDONEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'NIHUIL', 'provincia': 'Mendoza' },
        { 'descripcion': 'NIRE CO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ÑIRE-CO', 'provincia': 'Mendoza' },
        { 'descripcion': 'NUEVA CALIFORNIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'NUEVA CALIFORNIA ,EST.MOLUCHES', 'provincia': 'Mendoza' },
        { 'descripcion': 'NUEVA CIUDAD', 'provincia': 'Mendoza' },
        { 'descripcion': 'OCHENTA Y CUATRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ONOTRE PUEBLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'Otra', 'provincia': 'Mendoza' },
        { 'descripcion': 'OVEJERíA', 'provincia': 'Mendoza' },
        { 'descripcion': 'P PLANCHON', 'provincia': 'Mendoza' },
        { 'descripcion': 'P ROSALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'P SAN IGNACIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PALERMO CHICO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PALMIRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAMPA AMARILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAMPA DE LAS YARETAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAMPA DE LOS BAYOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAMPA DEL SALADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAMPA DEL TIGRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAMPA YALGUARAZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAMPITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAMPITA EMBARCADERO FCGSM', 'provincia': 'Mendoza' },
        { 'descripcion': 'PANQUEUA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PARADERO LA SUPERIORA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PARAMILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PARAMILLO ,AP.', 'provincia': 'Mendoza' },
        { 'descripcion': 'PARAMILLO DE LAS VACAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAREDITAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PASCUAL SOSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PASO DE LAS CANOAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PASO DE LAS CARRETAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PASO DE LOS ANDES', 'provincia': 'Mendoza' },
        { 'descripcion': 'PASO DE LOS GAUCHOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PASO DEL CISNE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PASO HONDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PASO LOS PALOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PATA MORA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PATIMALAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAULINO MATURA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAYUN', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAYÚN , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'PAYÚN MATRÚ , volcÁn', 'provincia': 'Mendoza' },
        { 'descripcion': 'PEDREGAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PEDRO CASTELU', 'provincia': 'Mendoza' },
        { 'descripcion': 'PEDRO PABLO PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'PEDRO VARGAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PENA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PERDRIEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PETEROA , volcÁn', 'provincia': 'Mendoza' },
        { 'descripcion': 'PETROLEO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PHILIPPS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PICHI CIEGO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PICHI CIEGO ESTACION FCGSM', 'provincia': 'Mendoza' },
        { 'descripcion': 'PICOS BAYOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PIEDRA DE AFILAR', 'provincia': 'Mendoza' },
        { 'descripcion': 'PIRCAS DE OSORIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PIRQUITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PIRQUITA EMBARCADERO FCGSM', 'provincia': 'Mendoza' },
        { 'descripcion': 'PLAZA DE MULAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO ALVARADO NORTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO ALVARADO SUR', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO AMARILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO DE CONTRABANDISTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO DE LA CUMBRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO DE LA QUEBRADA HONDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO DE LOS ESCALONES', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO DEL HUANACO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO DEL RUBIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO MAIPU', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO MALLAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO PEHUENCHE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PO VALLE HERMOSO', 'provincia': 'Mendoza' },
        { 'descripcion': 'POLVAREDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'POLVAREDAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PONCE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PONÓN TREHUE , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTEZUELO CHOIQUE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO AGUA DE TORO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO CANALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO CRUZ DE PIEDRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO D QUEMADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DE COLINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DE INDIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DE LA G DEL CAMINO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DE LA LAGRIMA VIVA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DE LA YESERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DE LAS CABEZAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DE LAS VACAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DE LOMAS COLORADAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DE PINQUENES', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DEL DIABLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DEL MEDIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DEL NORTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DEL PAPAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DEL TIGRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO DEL VIENTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO LA PAMPA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO OCCIDENTAL DEL BAYO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PORTILLO PEDERNALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'POSTA DE HIERRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'POSTE DE FIERRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'POTRERILLOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'POTRERO SAN PABLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PRATS, JAIME', 'provincia': 'Mendoza' },
        { 'descripcion': 'PRIMAVERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PTA DEL AGUA VIEJA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PTO LOS AMARILLOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUEBLO DIAMANTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUEBLO ECHEVARRIETA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUEBLO LUNA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUEBLO NUEVO (KM.ONCE)', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUEBLO SOTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUENTE DEL INCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUENTE VIEJO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUERTA DE LA ISLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUERTO HORTENSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUERTO RINCON ESCALONA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTA EL CAVADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO A MARTINEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO AGUA AMARGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO AGUA DE LA LIEBRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO AGUA DE LA MERINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO AGUA DE LA ZORRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO AGUA DE ZANJON', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO AGUA DEL MEDANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO ALFARFA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO ALGARROBO GRANDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO ATAMISQUI', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO CANALES', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO CARRIZALITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO CHAMBON', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DE GARRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DE LA CORONA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DE LA SALADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DE LAS CARRETAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DE LAS TROPAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DE OLGUIN', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DE OROZCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DE PETRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DE SOSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DEL BUEN PASTOR', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO DEL CHANACAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO EL CARRIZALITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO EL JAGUAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO EL JILGUERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO EL MANZANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO EL PERAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO EL PICHON', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO EL RETAMITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO EL TOTORAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO EL TRUENO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO ESCONDIDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO F TELLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO GARCIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO GENDARMERIA NACIONAL PO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO GENTILE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO GUAMPARITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO HORQUETA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO ISLA CHANAR', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO J ALVAREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO J CASTRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA CACHACA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA CALDENADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA COSTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA FLORIDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA GRUTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA HORTENSIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA INVERNADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA JARILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA JERILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA MOJADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA NEGRITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA NIEBLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA PORTENA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA SENA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA SUIZA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA TOSCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LA VENTANA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LAS AGUADAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LAS CORTADERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LAS HIGUERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LAS JUNTITAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LAS PICHANAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LAS PUNTANAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LAS VIBORAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LORCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LORETO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LOS ALOJAMIENTOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LOS CAUSES', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LOS CHANARES', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LOS GAUCHOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LOS PAJARITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LOS RAMBLONES', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LUFFI', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LUNA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO LUNINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO MALLIN', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO MALO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO MANGA DE ARRIBA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO MANZANITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO MARFIL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO MIRONDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO NIEVES NEGRAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO NUERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO OJO DE AGUA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO P MIRANDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO P MONTRIEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO PUNTA DEL AGUA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO QUEMADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO QUIROGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO RANCHO DE LA PAMPA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO RINCON DE LA PAMPA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO RINCON DEL SAUCE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO RINCON ESCALONA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO RIQUITIPANCHE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO S PEREZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO SAN MIGUEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO SAN VICENTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO SANTA CLARA DE ARRIBA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO SANTA MARIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO SECO', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO SOSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO ULTIMA AGUADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO VEGA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO VIUDA DE ESTRELLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO VUELTA DEL ZANJON', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUESTO ZAMPAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUNTA DE VACA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUNTA DE VACAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUNTA DEL AGUA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUNTA DEL CANAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUNTILLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'PUNTOS DE AGUA', 'provincia': 'Mendoza' },
        { 'descripcion': 'QUIRCACO', 'provincia': 'Mendoza' },
        { 'descripcion': 'R BARRI', 'provincia': 'Mendoza' },
        { 'descripcion': 'R BEBEDERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'R BUSTOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'RAMA CAíDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'RAMBLON DE LA PAMPA', 'provincia': 'Mendoza' },
        { 'descripcion': 'RAMBLON DE LOS CHILENOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'RAMBLON GRANDE', 'provincia': 'Mendoza' },
        { 'descripcion': 'RAMON DONAIRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'RAMON GIMENEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'RANCHITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'RANQUIL DEL NORTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'RANQUIL NORTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'RANQUILCO POZOS PETROLIFEROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL BAYO', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL DE MOYANO', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL DEL COLORADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL DEL LEON', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL DEL PADRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL DEL PELAMBRE', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL ESCONDIDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL LOMA BLANCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL PIEDRA HORADADA', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL PRIMER RIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'REAL RINCON DE LAS OVEJAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'RECOARO', 'provincia': 'Mendoza' },
        { 'descripcion': 'REDUCCION', 'provincia': 'Mendoza' },
        { 'descripcion': 'REFUGIO LA FAJA', 'provincia': 'Mendoza' },
        { 'descripcion': 'REFUGIO MILITAR GRAL ALVARADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'REFUGIO VIALIDAD', 'provincia': 'Mendoza' },
        { 'descripcion': 'REGINO OJEDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'REINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'RESOLANA', 'provincia': 'Mendoza' },
        { 'descripcion': 'RESURRECCION', 'provincia': 'Mendoza' },
        { 'descripcion': 'RETAMO', 'provincia': 'Mendoza' },
        { 'descripcion': 'RETAMO PARTIDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'RICARDO VIDELA', 'provincia': 'Mendoza' },
        { 'descripcion': 'RINCON CHICO', 'provincia': 'Mendoza' },
        { 'descripcion': 'RINCON DE CORREA', 'provincia': 'Mendoza' },
        { 'descripcion': 'RINCON DE LA RAMADA CHATO', 'provincia': 'Mendoza' },
        { 'descripcion': 'RINCON DEL ATUEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'RINCÓN DEL ATUEL , Est. Ing. Balloffet', 'provincia': 'Mendoza' },
        { 'descripcion': 'RINCON ESCONDIDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'RINCON HUAIQUERIAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'RIO BARRANCAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'RIO BLANCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'RISQUERA , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'RIVADAVIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'RODEO DE LA CRUZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'RODEO DEL MEDIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'RODOLFO ISELIN', 'provincia': 'Mendoza' },
        { 'descripcion': 'RODRIGUEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'RODRIGUEZ PEÑA', 'provincia': 'Mendoza' },
        { 'descripcion': 'ROSARIO GATICA', 'provincia': 'Mendoza' },
        { 'descripcion': 'RUFINO GOMEZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'RUIZ HUIDOBRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'RUSSELL', 'provincia': 'Mendoza' },
        { 'descripcion': 'S CORTIS', 'provincia': 'Mendoza' },
        { 'descripcion': 'S ESTRELLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'SALINAS DEL DIAMANTE ,EST.', 'provincia': 'Mendoza' },
        { 'descripcion': 'SALINAS EL DIAMANTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'SALTO DE LAS ROSAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN ALBERTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN CARLOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN FRANCISCO DEL MONTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN JOSÉ', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN JOSE DE GUAYMALLEN', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN JOSE DE TUPUNGATO', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN MARTÍN', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN MARTíN , Est. Lib. Grl. San Martín', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN MIGUEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN PEDRO DE ATUEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN RAFAEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'SAN ROQUE', 'provincia': 'Mendoza' },
        { 'descripcion': 'SANCHEZ DE BUSTAMANTE', 'provincia': 'Mendoza' },
        { 'descripcion': 'SANTA BLANCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'SANTA MARíA , volcÁn', 'provincia': 'Mendoza' },
        { 'descripcion': 'SANTA MARIA DE ORO', 'provincia': 'Mendoza' },
        { 'descripcion': 'SANTA MARTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'SANTA ROSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'SANTIAGO ROMERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'SATURNINO ROMERO', 'provincia': 'Mendoza' },
        { 'descripcion': 'SECO DE LAS PEÑAS , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'SECO HONDO , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'SERVILIANO OJEDA', 'provincia': 'Mendoza' },
        { 'descripcion': 'SIERRA ANSILTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'SIERRA DE LAS HIGUERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'SIERRA DEL TONTAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'SIXTO LEDESMA', 'provincia': 'Mendoza' },
        { 'descripcion': 'SOITUÉ', 'provincia': 'Mendoza' },
        { 'descripcion': 'SOPANTA', 'provincia': 'Mendoza' },
        { 'descripcion': 'SOSNEADO , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'T OROZCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'TABANERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'TALQUENCA', 'provincia': 'Mendoza' },
        { 'descripcion': 'TAMBITO', 'provincia': 'Mendoza' },
        { 'descripcion': 'TAPERA DE LOS VIEJOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'TAPERA NEGRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'TAPON', 'provincia': 'Mendoza' },
        { 'descripcion': 'TEODORO GARRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'TEODORO VILLARUEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'TEOFILA ACEVEDO', 'provincia': 'Mendoza' },
        { 'descripcion': 'TEOFILO RUBEN', 'provincia': 'Mendoza' },
        { 'descripcion': 'TEOFILO ZAPATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'TERESA B DE TITTARELLI', 'provincia': 'Mendoza' },
        { 'descripcion': 'TERMAS VILLAVICENCIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'TEUCO , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'TIERRAS BLANCAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'TILA', 'provincia': 'Mendoza' },
        { 'descripcion': 'TILATIRÚ , arroyo', 'provincia': 'Mendoza' },
        { 'descripcion': 'TILIO ALCARAZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'TOMAS MERCADO', 'provincia': 'Mendoza' },
        { 'descripcion': 'TOSCAL DEL TORO', 'provincia': 'Mendoza' },
        { 'descripcion': 'TRES ACEQUIAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'TRES BANDERAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'TRES ESQUINAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'TRES PORTEÑAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'TRINO ROSALESO', 'provincia': 'Mendoza' },
        { 'descripcion': 'TROPERO SOSA', 'provincia': 'Mendoza' },
        { 'descripcion': 'TROPERO SOSA ,EMP.', 'provincia': 'Mendoza' },
        { 'descripcion': 'TTE BENJAMIN MATIENZO', 'provincia': 'Mendoza' },
        { 'descripcion': 'TULUMAYA', 'provincia': 'Mendoza' },
        { 'descripcion': 'TUNUYÁN', 'provincia': 'Mendoza' },
        { 'descripcion': 'TUNUYÁN , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'TUNUYÁN NUEVO , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'TUNUYÁN VIEJO , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'TUPUNGATO', 'provincia': 'Mendoza' },
        { 'descripcion': 'TUPUNGATO , cerro', 'provincia': 'Mendoza' },
        { 'descripcion': 'TUPUNGATO , río', 'provincia': 'Mendoza' },
        { 'descripcion': 'UGARTECHE', 'provincia': 'Mendoza' },
        { 'descripcion': 'URISA', 'provincia': 'Mendoza' },
        { 'descripcion': 'USPALLATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'USPALLATA ,EST.', 'provincia': 'Mendoza' },
        { 'descripcion': 'V HIPODROMO', 'provincia': 'Mendoza' },
        { 'descripcion': 'V N DE COCHIQUITA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VALLE DE LAS LENAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'VALLE DE USPALLATA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VARGAS, PEDRO', 'provincia': 'Mendoza' },
        { 'descripcion': 'VEGA DE LOS BURROS', 'provincia': 'Mendoza' },
        { 'descripcion': 'VEGA DE PRASO', 'provincia': 'Mendoza' },
        { 'descripcion': 'VEGA FERRAINA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VEGAS DE LAS OVEJAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'VEGAS DE LOS CORRALES DE ARAYA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VERGEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'VICENTE MUNOZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'VICENTE PELETAY', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA ANTIGUA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA ATUEL', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA BASTIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA BASTIAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA CATALA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA CENTENARIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA COMPARTO', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA EDELMIRA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA GAVIOLA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA HIPODROMO', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA LA PAZ', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA LOS CORRALITOS', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA MOLINO ORFILA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA NUEVA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA NUEVA DE GUAYMALLEN', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA PRIMAVERA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA RIVADAVIA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA SECA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA SECA DE TUNUYAN', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA SUAVA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA TULUMAYA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLA VIEJA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLANUEVA, ARISTIDES', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLAS UNIDAS 25 DE MAYO', 'provincia': 'Mendoza' },
        { 'descripcion': 'VILLAVICENCIO', 'provincia': 'Mendoza' },
        { 'descripcion': 'VISTA FLORES', 'provincia': 'Mendoza' },
        { 'descripcion': 'VISTALBA', 'provincia': 'Mendoza' },
        { 'descripcion': 'VIUDA DE OROZCO', 'provincia': 'Mendoza' },
        { 'descripcion': 'VIUDA DE SATELO', 'provincia': 'Mendoza' },
        { 'descripcion': 'VOLCAN MAIPU', 'provincia': 'Mendoza' },
        { 'descripcion': 'VRA DE LAS VACAS', 'provincia': 'Mendoza' },
        { 'descripcion': 'VUELTA DEL ZANJON', 'provincia': 'Mendoza' },
        { 'descripcion': 'ZANJON AMARILLO', 'provincia': 'Mendoza' },
        { 'descripcion': 'ZANON CANAL', 'provincia': 'Mendoza' },
        { 'descripcion': 'ZAPATA', 'provincia': 'Mendoza' },
        { 'descripcion': '2 DE MAYO', 'provincia': 'Misiones' },
        { 'descripcion': '22 DE DICIEMBRE', 'provincia': 'Misiones' },
        { 'descripcion': '25 DE MAYO', 'provincia': 'Misiones' },
        { 'descripcion': '3 DE MAYO', 'provincia': 'Misiones' },
        { 'descripcion': '9 DE JULIO', 'provincia': 'Misiones' },
        { 'descripcion': 'ACARAGUA', 'provincia': 'Misiones' },
        { 'descripcion': 'ACARAGUÁ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'AGUARAY GUAZÚ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'ALBA POSE', 'provincia': 'Misiones' },
        { 'descripcion': 'ALBA POSSE', 'provincia': 'Misiones' },
        { 'descripcion': 'ALEGRE , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'ALMAFUERTE', 'provincia': 'Misiones' },
        { 'descripcion': 'ALMIRANTE BROWN', 'provincia': 'Misiones' },
        { 'descripcion': 'ALTA UNION', 'provincia': 'Misiones' },
        { 'descripcion': 'APARICIO CUE', 'provincia': 'Misiones' },
        { 'descripcion': 'APÓSTOLES', 'provincia': 'Misiones' },
        { 'descripcion': 'ARISTÓBULO DEL VALLE', 'provincia': 'Misiones' },
        { 'descripcion': 'ARRECHEA', 'provincia': 'Misiones' },
        { 'descripcion': 'ARROYO DEL MEDIO', 'provincia': 'Misiones' },
        { 'descripcion': 'ARROYO FEDOR', 'provincia': 'Misiones' },
        { 'descripcion': 'ARROYO ISABEL', 'provincia': 'Misiones' },
        { 'descripcion': 'ARROYO MAGDALENA', 'provincia': 'Misiones' },
        { 'descripcion': 'ARROYO PASTORA', 'provincia': 'Misiones' },
        { 'descripcion': 'ARROYO PERSIGUERO', 'provincia': 'Misiones' },
        { 'descripcion': 'ARROYO SANTA MARIA', 'provincia': 'Misiones' },
        { 'descripcion': 'ARROYO TOMAS', 'provincia': 'Misiones' },
        { 'descripcion': 'ARROYO TUNITAS', 'provincia': 'Misiones' },
        { 'descripcion': 'ARROYO YABEBIRI', 'provincia': 'Misiones' },
        { 'descripcion': 'ASERRADERO ECHEVERRIA', 'provincia': 'Misiones' },
        { 'descripcion': 'ASERRADERO PINALITO', 'provincia': 'Misiones' },
        { 'descripcion': 'AZARA', 'provincia': 'Misiones' },
        { 'descripcion': 'AZUFRE o LASTARRíA , volcÁn', 'provincia': 'Misiones' },
        { 'descripcion': 'BANADO GRANDE', 'provincia': 'Misiones' },
        { 'descripcion': 'BARRA BONITA', 'provincia': 'Misiones' },
        { 'descripcion': 'BARRA CONCEPCION', 'provincia': 'Misiones' },
        { 'descripcion': 'BARRACON', 'provincia': 'Misiones' },
        { 'descripcion': 'BARRANCON', 'provincia': 'Misiones' },
        { 'descripcion': 'BARRANCON SAN PEDRO', 'provincia': 'Misiones' },
        { 'descripcion': 'BARRIO DON SANTIAGO', 'provincia': 'Misiones' },
        { 'descripcion': 'BARTOLITO', 'provincia': 'Misiones' },
        { 'descripcion': 'BAYO TRONCHO', 'provincia': 'Misiones' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'Misiones' },
        { 'descripcion': 'BERNARDINO RIVADAVIA', 'provincia': 'Misiones' },
        { 'descripcion': 'BERNARDO DE IRIGOYEN', 'provincia': 'Misiones' },
        { 'descripcion': 'BONPLAND', 'provincia': 'Misiones' },
        { 'descripcion': 'BONPLAND NORTE', 'provincia': 'Misiones' },
        { 'descripcion': 'BRAZO DEL TACUARUZU', 'provincia': 'Misiones' },
        { 'descripcion': 'BRETES MARTIRES', 'provincia': 'Misiones' },
        { 'descripcion': 'CAA YARI', 'provincia': 'Misiones' },
        { 'descripcion': 'CAAPORA', 'provincia': 'Misiones' },
        { 'descripcion': 'CABURE', 'provincia': 'Misiones' },
        { 'descripcion': 'CABURÉ-í', 'provincia': 'Misiones' },
        { 'descripcion': 'CAINGUAS', 'provincia': 'Misiones' },
        { 'descripcion': 'CAMPANA', 'provincia': 'Misiones' },
        { 'descripcion': 'CAMPINA DE BONPLAND', 'provincia': 'Misiones' },
        { 'descripcion': 'CAMPINA GRANDE', 'provincia': 'Misiones' },
        { 'descripcion': 'CAMPIÑAS DE AMERICA', 'provincia': 'Misiones' },
        { 'descripcion': 'CAMPO GRANDE', 'provincia': 'Misiones' },
        { 'descripcion': 'CAMPO RAMÓN', 'provincia': 'Misiones' },
        { 'descripcion': 'CAMPO RICHARDSON', 'provincia': 'Misiones' },
        { 'descripcion': 'CAMPO TORNQUINST', 'provincia': 'Misiones' },
        { 'descripcion': 'CAMPO VIERA', 'provincia': 'Misiones' },
        { 'descripcion': 'CAMPOS SALLES', 'provincia': 'Misiones' },
        { 'descripcion': 'CANDELARIA', 'provincia': 'Misiones' },
        { 'descripcion': 'CAPIOVí', 'provincia': 'Misiones' },
        { 'descripcion': 'CAPIOVICINO', 'provincia': 'Misiones' },
        { 'descripcion': 'CAPIOVISINO', 'provincia': 'Misiones' },
        { 'descripcion': 'CAPITAN ANTONIO MORALES', 'provincia': 'Misiones' },
        { 'descripcion': 'CAPIVU', 'provincia': 'Misiones' },
        { 'descripcion': 'CAPUERON', 'provincia': 'Misiones' },
        { 'descripcion': 'CARAGUATAY', 'provincia': 'Misiones' },
        { 'descripcion': 'CARRILLO VIEJO', 'provincia': 'Misiones' },
        { 'descripcion': 'CATARATAS DEL IGUAZÚ', 'provincia': 'Misiones' },
        { 'descripcion': 'CENTINELA', 'provincia': 'Misiones' },
        { 'descripcion': 'CERRO AZUL', 'provincia': 'Misiones' },
        { 'descripcion': 'CERRO CORÁ', 'provincia': 'Misiones' },
        { 'descripcion': 'CHATARIZ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'CHEROGUITA', 'provincia': 'Misiones' },
        { 'descripcion': 'CHIRIMAY', 'provincia': 'Misiones' },
        { 'descripcion': 'CITRUS', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA ALBERDI', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA ALEMANA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA ALICIA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA ALMAFUERTE', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA APOSTOLES', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA ARISTOBULO DEL VALLE', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA AURORA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA AZARA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA CAA GUAZU', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA CAPON BONITO', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA CHAFARIZ', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA CHAPA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA CUMANDAY', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA CUNCI', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA DELICIA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA DOMINGO SAVIO', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA DURAN', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA EL DORADILLO', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA EL PESADO', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA EL PROGRESO', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA FINLANDESA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA FLORIDA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA FORTALEZA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA GRAMADO', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA GUARANI', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA JAPONESA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA JUANITA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA LA CHILLITA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA LA GRUTA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA LA OTILIA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA LA POLACA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA LEIVA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA MANUEL BELGRANO', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA MARTIR SANTA MARIA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA MARTIRES', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA MBOPICUA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA MONDORI', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA NACANGAZU', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA NARANJITO', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA ORO VERDE', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA PADUAN', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA PALMERA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA POLACA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA POLANA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA PRIMAVERA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA PROFUNDIDAD', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA PUERTO ROSALES', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA ROCA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA ROCA CHICA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA SAN IGNACIO', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA SAN JAVIER', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA SANTA TERESA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA SEGUI', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA SIETE ESTRELLAS', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA TARANCO', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA TRES MARIAS', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA VICTORIA', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA YABEBIRI', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA YABEBIRY', 'provincia': 'Misiones' },
        { 'descripcion': 'COLONIA YACUTINGA', 'provincia': 'Misiones' },
        { 'descripcion': 'COMANDANTE ANDRESITO', 'provincia': 'Misiones' },
        { 'descripcion': 'CONCEPCIÓN DE LA SIERRA', 'provincia': 'Misiones' },
        { 'descripcion': 'CORPUS', 'provincia': 'Misiones' },
        { 'descripcion': 'COSTA PORTERA', 'provincia': 'Misiones' },
        { 'descripcion': 'CRUCE CABALLERO', 'provincia': 'Misiones' },
        { 'descripcion': 'CRUCE LONDERO', 'provincia': 'Misiones' },
        { 'descripcion': 'CUNA PIRU', 'provincia': 'Misiones' },
        { 'descripcion': 'CUNA PORA', 'provincia': 'Misiones' },
        { 'descripcion': 'CUÑAPIRÚ', 'provincia': 'Misiones' },
        { 'descripcion': 'CUÑAPIRÚ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'DAMUS', 'provincia': 'Misiones' },
        { 'descripcion': 'de LA VICTORIA , sierra', 'provincia': 'Misiones' },
        { 'descripcion': 'de MISIONES , sierra', 'provincia': 'Misiones' },
        { 'descripcion': 'del IGUAZÚ , cataratas', 'provincia': 'Misiones' },
        { 'descripcion': 'DEL VALLE, ARISTOBULO', 'provincia': 'Misiones' },
        { 'descripcion': 'DESEADO', 'provincia': 'Misiones' },
        { 'descripcion': 'DESPLAYADA', 'provincia': 'Misiones' },
        { 'descripcion': 'DESTACAMENTO BOSQUES', 'provincia': 'Misiones' },
        { 'descripcion': 'DOMINGO BARTHE', 'provincia': 'Misiones' },
        { 'descripcion': 'DON HORACIO', 'provincia': 'Misiones' },
        { 'descripcion': 'DONA MARIA', 'provincia': 'Misiones' },
        { 'descripcion': 'DOS ARROYOS', 'provincia': 'Misiones' },
        { 'descripcion': 'DOS DE MAYO', 'provincia': 'Misiones' },
        { 'descripcion': 'DOS HERMANAS', 'provincia': 'Misiones' },
        { 'descripcion': 'EL 26', 'provincia': 'Misiones' },
        { 'descripcion': 'EL ALCÁZAR', 'provincia': 'Misiones' },
        { 'descripcion': 'EL CHATON', 'provincia': 'Misiones' },
        { 'descripcion': 'EL DESTIERRO', 'provincia': 'Misiones' },
        { 'descripcion': 'EL MACACO', 'provincia': 'Misiones' },
        { 'descripcion': 'EL PARAISO', 'provincia': 'Misiones' },
        { 'descripcion': 'EL PERSIGUERO', 'provincia': 'Misiones' },
        { 'descripcion': 'EL RANCHO', 'provincia': 'Misiones' },
        { 'descripcion': 'EL REPOSO', 'provincia': 'Misiones' },
        { 'descripcion': 'EL SALTINO', 'provincia': 'Misiones' },
        { 'descripcion': 'EL SALTITO', 'provincia': 'Misiones' },
        { 'descripcion': 'EL SALTITO , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'EL SOBERBIO', 'provincia': 'Misiones' },
        { 'descripcion': 'EL SOCORRO', 'provincia': 'Misiones' },
        { 'descripcion': 'EL TIGRE', 'provincia': 'Misiones' },
        { 'descripcion': 'EL TROPEZON', 'provincia': 'Misiones' },
        { 'descripcion': 'ELDORADO', 'provincia': 'Misiones' },
        { 'descripcion': 'EMBALSE URUGUA -í', 'provincia': 'Misiones' },
        { 'descripcion': 'ENSANCHE ESTE', 'provincia': 'Misiones' },
        { 'descripcion': 'ENSANCHE NORTE', 'provincia': 'Misiones' },
        { 'descripcion': 'ESPERANZA', 'provincia': 'Misiones' },
        { 'descripcion': 'ESTACIÓN APÓSTOLES', 'provincia': 'Misiones' },
        { 'descripcion': 'ESTACION EXPERIMENTAL DE LORET', 'provincia': 'Misiones' },
        { 'descripcion': 'ESTANCIA ITAEMBRE', 'provincia': 'Misiones' },
        { 'descripcion': 'ESTANCIA SANTA RITA', 'provincia': 'Misiones' },
        { 'descripcion': 'FACHINAL', 'provincia': 'Misiones' },
        { 'descripcion': 'FILEMON POSE', 'provincia': 'Misiones' },
        { 'descripcion': 'FRACRAN', 'provincia': 'Misiones' },
        { 'descripcion': 'FRANCES', 'provincia': 'Misiones' },
        { 'descripcion': 'FRONTERAS', 'provincia': 'Misiones' },
        { 'descripcion': 'GARUHAPÉ', 'provincia': 'Misiones' },
        { 'descripcion': 'GARUHAPÉ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'GARUPÁ', 'provincia': 'Misiones' },
        { 'descripcion': 'GARUPA NORTE', 'provincia': 'Misiones' },
        { 'descripcion': 'GARUPÉ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'GENERAL ALVEAR', 'provincia': 'Misiones' },
        { 'descripcion': 'GENERAL GUEMES', 'provincia': 'Misiones' },
        { 'descripcion': 'GOBERNADOR JUAN J. LANUSSE', 'provincia': 'Misiones' },
        { 'descripcion': 'GOBERNADOR LANUSSE', 'provincia': 'Misiones' },
        { 'descripcion': 'GOBERNADOR LÓPEZ', 'provincia': 'Misiones' },
        { 'descripcion': 'GOBERNADOR ROCA', 'provincia': 'Misiones' },
        { 'descripcion': 'GRAL GUEMES', 'provincia': 'Misiones' },
        { 'descripcion': 'GRAN SALTO DE MOCONÁ , salto', 'provincia': 'Misiones' },
        { 'descripcion': 'GRANDE o GRAN SALTO MOCORÁ , salto', 'provincia': 'Misiones' },
        { 'descripcion': 'GRUPO DE LA ISLA GRANDE , islas', 'provincia': 'Misiones' },
        { 'descripcion': 'GUAIBICHU', 'provincia': 'Misiones' },
        { 'descripcion': 'GUARANí', 'provincia': 'Misiones' },
        { 'descripcion': 'GUARAYPO', 'provincia': 'Misiones' },
        { 'descripcion': 'GUAYABERA', 'provincia': 'Misiones' },
        { 'descripcion': 'HEKENAN', 'provincia': 'Misiones' },
        { 'descripcion': 'HIPOLITO YRIGOYEN', 'provincia': 'Misiones' },
        { 'descripcion': 'IGUAZÚ , río', 'provincia': 'Misiones' },
        { 'descripcion': 'INTEGRACION', 'provincia': 'Misiones' },
        { 'descripcion': 'INVERNADA CHICA', 'provincia': 'Misiones' },
        { 'descripcion': 'INVERNADA DE ITACARUARE', 'provincia': 'Misiones' },
        { 'descripcion': 'INVERNADA GRANDE', 'provincia': 'Misiones' },
        { 'descripcion': 'INVERNADA SAN IGNACIO', 'provincia': 'Misiones' },
        { 'descripcion': 'IRIGOYEN, BERNARDO DE', 'provincia': 'Misiones' },
        { 'descripcion': 'ISLA ARGENTINA', 'provincia': 'Misiones' },
        { 'descripcion': 'ISLA SAN LUCAS', 'provincia': 'Misiones' },
        { 'descripcion': 'ITA CURUZU', 'provincia': 'Misiones' },
        { 'descripcion': 'ITACARUARÉ', 'provincia': 'Misiones' },
        { 'descripcion': 'ITACARUARÉ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'ITAEMBÉ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'ITAEMBRE MINI', 'provincia': 'Misiones' },
        { 'descripcion': 'JARDíN AMÉRICA', 'provincia': 'Misiones' },
        { 'descripcion': 'KILOMETRO 18', 'provincia': 'Misiones' },
        { 'descripcion': 'KILOMETRO 538 ,AP.', 'provincia': 'Misiones' },
        { 'descripcion': 'KILOMETRO 546 ,AP.', 'provincia': 'Misiones' },
        { 'descripcion': 'KILOMETRO 577', 'provincia': 'Misiones' },
        { 'descripcion': 'LA GRUTA', 'provincia': 'Misiones' },
        { 'descripcion': 'LA HORQUETA', 'provincia': 'Misiones' },
        { 'descripcion': 'LA MILAGROSA', 'provincia': 'Misiones' },
        { 'descripcion': 'LA MISIONERA', 'provincia': 'Misiones' },
        { 'descripcion': 'LA OTILIA', 'provincia': 'Misiones' },
        { 'descripcion': 'LA PLANTADORA', 'provincia': 'Misiones' },
        { 'descripcion': 'LA ROTONDA', 'provincia': 'Misiones' },
        { 'descripcion': 'LAHARRAGE', 'provincia': 'Misiones' },
        { 'descripcion': 'LANUS, MIGUEL', 'provincia': 'Misiones' },
        { 'descripcion': 'LARRAQUE', 'provincia': 'Misiones' },
        { 'descripcion': 'LAS ANTAS , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'LAS QUEMADAS', 'provincia': 'Misiones' },
        { 'descripcion': 'LAS TUNAS', 'provincia': 'Misiones' },
        { 'descripcion': 'LEANDRO N ALEM', 'provincia': 'Misiones' },
        { 'descripcion': 'LEANDRO N. ALEM', 'provincia': 'Misiones' },
        { 'descripcion': 'LEIS ,PDA.', 'provincia': 'Misiones' },
        { 'descripcion': 'LIBERTAD', 'provincia': 'Misiones' },
        { 'descripcion': 'LINEA CUCHILLA', 'provincia': 'Misiones' },
        { 'descripcion': 'LINEA DE PERAY', 'provincia': 'Misiones' },
        { 'descripcion': 'LORETO', 'provincia': 'Misiones' },
        { 'descripcion': 'LOS GALPONES', 'provincia': 'Misiones' },
        { 'descripcion': 'LOS HELECHOS', 'provincia': 'Misiones' },
        { 'descripcion': 'LOS TEALES', 'provincia': 'Misiones' },
        { 'descripcion': 'MACACA', 'provincia': 'Misiones' },
        { 'descripcion': 'MACACO', 'provincia': 'Misiones' },
        { 'descripcion': 'MACHADINO', 'provincia': 'Misiones' },
        { 'descripcion': 'MAI BAO', 'provincia': 'Misiones' },
        { 'descripcion': 'MANIS', 'provincia': 'Misiones' },
        { 'descripcion': 'MARIA ANTONIA', 'provincia': 'Misiones' },
        { 'descripcion': 'MARíA MAGDALENA', 'provincia': 'Misiones' },
        { 'descripcion': 'MÁRTIRES', 'provincia': 'Misiones' },
        { 'descripcion': 'MBOPICUA', 'provincia': 'Misiones' },
        { 'descripcion': 'MECKING', 'provincia': 'Misiones' },
        { 'descripcion': 'MESA REDONDA', 'provincia': 'Misiones' },
        { 'descripcion': 'MIGUEL GUEMES', 'provincia': 'Misiones' },
        { 'descripcion': 'MIGUEL LANÚS', 'provincia': 'Misiones' },
        { 'descripcion': 'MOCONA', 'provincia': 'Misiones' },
        { 'descripcion': 'MOJON GRANDE', 'provincia': 'Misiones' },
        { 'descripcion': 'MONTEAGUDO', 'provincia': 'Misiones' },
        { 'descripcion': 'MONTECARLO', 'provincia': 'Misiones' },
        { 'descripcion': 'MORALES', 'provincia': 'Misiones' },
        { 'descripcion': 'ÑACANGUAZU', 'provincia': 'Misiones' },
        { 'descripcion': 'ÑACANGUAZÚ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'NACIENTES DEL ISABEL', 'provincia': 'Misiones' },
        { 'descripcion': 'NACIENTES DEL TUNAR', 'provincia': 'Misiones' },
        { 'descripcion': 'NUEVA VALENCIA', 'provincia': 'Misiones' },
        { 'descripcion': 'NUEVE DE JULIO', 'provincia': 'Misiones' },
        { 'descripcion': 'OASIS', 'provincia': 'Misiones' },
        { 'descripcion': 'OBERÁ', 'provincia': 'Misiones' },
        { 'descripcion': 'OLEGARIO VICTOR ANDRADE', 'provincia': 'Misiones' },
        { 'descripcion': 'ONCE VUELTAS', 'provincia': 'Misiones' },
        { 'descripcion': 'OTILIA', 'provincia': 'Misiones' },
        { 'descripcion': 'Otra', 'provincia': 'Misiones' },
        { 'descripcion': 'PANAMBI', 'provincia': 'Misiones' },
        { 'descripcion': 'PARADA LEIS', 'provincia': 'Misiones' },
        { 'descripcion': 'PARAGUAY , río', 'provincia': 'Misiones' },
        { 'descripcion': 'PARAISO', 'provincia': 'Misiones' },
        { 'descripcion': 'PARAJE AZOPARDO', 'provincia': 'Misiones' },
        { 'descripcion': 'PARAJE DOS HERMANAS', 'provincia': 'Misiones' },
        { 'descripcion': 'PARAJE ESTELINA', 'provincia': 'Misiones' },
        { 'descripcion': 'PARAJE GRANADO', 'provincia': 'Misiones' },
        { 'descripcion': 'PARAJE INTEGRACIÓN', 'provincia': 'Misiones' },
        { 'descripcion': 'PARAJE INTERCONTINENTAL', 'provincia': 'Misiones' },
        { 'descripcion': 'PARAJE LUCERO', 'provincia': 'Misiones' },
        { 'descripcion': 'PARAJE VILLA UNION', 'provincia': 'Misiones' },
        { 'descripcion': 'PARANAY', 'provincia': 'Misiones' },
        { 'descripcion': 'PARANAY - GUAZÚ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'PARQUE NACIONAL IGUAZÚ', 'provincia': 'Misiones' },
        { 'descripcion': 'PASO DEL ARROYO PERSIGUERO', 'provincia': 'Misiones' },
        { 'descripcion': 'PASO PORTENO', 'provincia': 'Misiones' },
        { 'descripcion': 'PASTOREO', 'provincia': 'Misiones' },
        { 'descripcion': 'PATI CUA', 'provincia': 'Misiones' },
        { 'descripcion': 'PATRIMONIO MUNDIAL DE LA HUMANIDAD', 'provincia': 'Misiones' },
        { 'descripcion': 'PEDRO NUNEZ', 'provincia': 'Misiones' },
        { 'descripcion': 'PEPERí GUAZÚ , río', 'provincia': 'Misiones' },
        { 'descripcion': 'PEPIRí MINí o YABOTí GUAZÚ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'PERSIGUERO', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA BELGRANO', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA BONPLAND', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA ESPANOLA', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA FINLANDESA', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA GOBERNADOR LOPEZ', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA IGLESIA', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA LIBERTAD', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA POLACA', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA PORTUGUESA', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA POZO FEO', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA RUSA', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA SAN JAVIER', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA SAN MARTIN', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA SUECA', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA SUR MECKING', 'provincia': 'Misiones' },
        { 'descripcion': 'PICADA YAPEYU', 'provincia': 'Misiones' },
        { 'descripcion': 'PIÑALITO', 'provincia': 'Misiones' },
        { 'descripcion': 'PINALITO NORTE', 'provincia': 'Misiones' },
        { 'descripcion': 'PINALITO SUR', 'provincia': 'Misiones' },
        { 'descripcion': 'PINDAITI', 'provincia': 'Misiones' },
        { 'descripcion': 'PINDAPOY', 'provincia': 'Misiones' },
        { 'descripcion': 'PINDAPOY ,EST.', 'provincia': 'Misiones' },
        { 'descripcion': 'PINDAPOY GRANDE , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'PINDAYTI', 'provincia': 'Misiones' },
        { 'descripcion': 'PIRAY', 'provincia': 'Misiones' },
        { 'descripcion': 'PIRAY GUAZÚ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'PIRAY MINI', 'provincia': 'Misiones' },
        { 'descripcion': 'PIRAY MIRí , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'PLANCHADA BANDERITA', 'provincia': 'Misiones' },
        { 'descripcion': 'POSADAS', 'provincia': 'Misiones' },
        { 'descripcion': 'PRESIDENTE TANCREDO NEVES', 'provincia': 'Misiones' },
        { 'descripcion': 'PROFUNDIDAD', 'provincia': 'Misiones' },
        { 'descripcion': 'PUEBLO SALTO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUEBLO TARUMA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUENTE NACIONAL', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO 3 DE MAYO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO AGUIRRE', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO ALCAZAR', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO ALICIA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO AURORA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO AVELLANEDA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO AZARA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO BEMBERG', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO BOSSETTI', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO CANOAS', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO CARAGUATAY', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO CAROLINA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO CAZADOR', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO CHUNO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO CONCEPCION', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO DELICIA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO DOCE', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO EL DORADO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO ELDORADO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO ELVECIA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO ERRECABORDE', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO ESPAÑA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO ESPERANZA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO GARUHAPE', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO GISELA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO HARDELASTE', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO IGUAZÚ', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO INGENIERO MORANDI', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO INSUA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO LA MINA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO LAHARRAGUE', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO LEONI', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO LIBERTAD', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO LONDERO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO LUJAN', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO MADO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO MBOPICUA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO MENOCCHIO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO MENOCHIO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO MINERAL', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO NARANJITO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO NUEVO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO OASIS', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO ORO VERDE', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO PANAMBí', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO PARAíSO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO PARANAY', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO PATICAA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO PAULITO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO PENINSULA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO PINARES', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO PIRAY', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO RICO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO ROSARIO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO RUBEN', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO SALTINO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO SAN ALBERTO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO SAN ANTONIO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO SAN IGNACIO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO SAN ISIDRO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO SAN LUCAS', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO SAN MARTIN', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO SEGUNDO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO TABAY', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO TIGRE', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO URUGUAY', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO VICTORIA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO VIEJO', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO WANDA', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO YABEBIRI', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO YACUY', 'provincia': 'Misiones' },
        { 'descripcion': 'PUERTO YRIGOYEN', 'provincia': 'Misiones' },
        { 'descripcion': 'PURTO MINERAL', 'provincia': 'Misiones' },
        { 'descripcion': 'RINCON DE BONPLAND', 'provincia': 'Misiones' },
        { 'descripcion': 'RINCON DE BUGRES', 'provincia': 'Misiones' },
        { 'descripcion': 'RINCON DE CHIMTRAY', 'provincia': 'Misiones' },
        { 'descripcion': 'RINCON DE LOPEZ', 'provincia': 'Misiones' },
        { 'descripcion': 'RINCON DEL GUERRERO', 'provincia': 'Misiones' },
        { 'descripcion': 'RIO YABOTAY', 'provincia': 'Misiones' },
        { 'descripcion': 'ROCA CHICA', 'provincia': 'Misiones' },
        { 'descripcion': 'RUINAS DE LORETO', 'provincia': 'Misiones' },
        { 'descripcion': 'RUINAS JESUíTICAS', 'provincia': 'Misiones' },
        { 'descripcion': 'RUIZ DE MONTOYA', 'provincia': 'Misiones' },
        { 'descripcion': 'SALTITO CHICO , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'SALTO', 'provincia': 'Misiones' },
        { 'descripcion': 'SALTO ENCANTADO', 'provincia': 'Misiones' },
        { 'descripcion': 'SAMAMBAYA', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN ALBERTO', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN ANTONIO', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN ANTONIO , río', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN FRANCISCO DE ASIS', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN GOTARDO', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN IGNACIO', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN JAVIER', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN JOSÉ', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN JUAN DE LA SIERRA', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN LUCAS', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN LUCAS GRANDE , isla', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN PEDRO', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN ROQUE GONZÁLEZ DE LA SANTA CRUZ', 'provincia': 'Misiones' },
        { 'descripcion': 'SAN VICENTE', 'provincia': 'Misiones' },
        { 'descripcion': 'SANTA ANA', 'provincia': 'Misiones' },
        { 'descripcion': 'SANTA MARíA', 'provincia': 'Misiones' },
        { 'descripcion': 'SANTA MARIA LA MAYOR', 'provincia': 'Misiones' },
        { 'descripcion': 'SANTA MARIA MARTIR', 'provincia': 'Misiones' },
        { 'descripcion': 'SANTA RITA', 'provincia': 'Misiones' },
        { 'descripcion': 'SANTIAGO DE LINIERS', 'provincia': 'Misiones' },
        { 'descripcion': 'SANTO PIPÓ', 'provincia': 'Misiones' },
        { 'descripcion': 'SEGUNDA ZONA', 'provincia': 'Misiones' },
        { 'descripcion': 'SIERRA DE ORO', 'provincia': 'Misiones' },
        { 'descripcion': 'SIERRA DE SAN JOSE', 'provincia': 'Misiones' },
        { 'descripcion': 'SIERRAS SAN JUAN', 'provincia': 'Misiones' },
        { 'descripcion': 'SOBERBIO o GUARAMBOCA , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'TABAY , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'TACUARUZU', 'provincia': 'Misiones' },
        { 'descripcion': 'TARUMA', 'provincia': 'Misiones' },
        { 'descripcion': 'TARUMÁ , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'TEYUGUARE', 'provincia': 'Misiones' },
        { 'descripcion': 'TIMBAUBA', 'provincia': 'Misiones' },
        { 'descripcion': 'TIRICA', 'provincia': 'Misiones' },
        { 'descripcion': 'TOBUNA', 'provincia': 'Misiones' },
        { 'descripcion': 'TOBUNAS', 'provincia': 'Misiones' },
        { 'descripcion': 'TOCOMAR , río', 'provincia': 'Misiones' },
        { 'descripcion': 'TORORO', 'provincia': 'Misiones' },
        { 'descripcion': 'TORTA QUEMADA', 'provincia': 'Misiones' },
        { 'descripcion': 'TRATADO DE PAZ', 'provincia': 'Misiones' },
        { 'descripcion': 'TRES CAPONES', 'provincia': 'Misiones' },
        { 'descripcion': 'URUGUA -í , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'URUGUAY , río', 'provincia': 'Misiones' },
        { 'descripcion': 'VICTORIA', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA ARMONIA', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA BLANQUITA', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA BONITA', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA DON BOSCO', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA EMILIA', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA ERRECABORDE', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA FLOR', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA LANUS', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA LIBERTAD', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA LONCA', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA ORTIZ PEREIRA', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA ROULET', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA SARUBBI', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA SVEA', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA VENECIA', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLA VILMA', 'provincia': 'Misiones' },
        { 'descripcion': 'VILLAFANE', 'provincia': 'Misiones' },
        { 'descripcion': 'WANDA', 'provincia': 'Misiones' },
        { 'descripcion': 'YABEBIRI', 'provincia': 'Misiones' },
        { 'descripcion': 'YABEBIRY , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'YABOTí MINí , arroyo', 'provincia': 'Misiones' },
        { 'descripcion': 'YACUTINGA', 'provincia': 'Misiones' },
        { 'descripcion': 'YAPEYU CENTRO', 'provincia': 'Misiones' },
        { 'descripcion': 'YERBAL MAMBORETA', 'provincia': 'Misiones' },
        { 'descripcion': 'YRIGOYEN, HIPOLITO', 'provincia': 'Misiones' },
        { 'descripcion': 'ACHICO', 'provincia': 'Neuquén' },
        { 'descripcion': 'AGRIO , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'AGRIO , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'AGRIO BALSA', 'provincia': 'Neuquén' },
        { 'descripcion': 'AGUADA CHACAY CO', 'provincia': 'Neuquén' },
        { 'descripcion': 'AGUADA FLORENCIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'AGUAS DE LAS MULAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'ALIANZA', 'provincia': 'Neuquén' },
        { 'descripcion': 'ALICURA', 'provincia': 'Neuquén' },
        { 'descripcion': 'ALUMINÉ', 'provincia': 'Neuquén' },
        { 'descripcion': 'ALUMINÉ , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'ALUMINÉ , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'ANDACOLLO', 'provincia': 'Neuquén' },
        { 'descripcion': 'AÑELO', 'provincia': 'Neuquén' },
        { 'descripcion': 'ANQUINCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'ARROYITO CHALLACO', 'provincia': 'Neuquén' },
        { 'descripcion': 'ARROYO BLANCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'ARROYO CAHUNCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'ARROYO QUILLEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'ARROYO RANQUILCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'ASTRA', 'provincia': 'Neuquén' },
        { 'descripcion': 'AUCA MAHUIDA', 'provincia': 'Neuquén' },
        { 'descripcion': 'AUCA MAHUIDA , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'AUCA MAHUIDA , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'AUCA PAN', 'provincia': 'Neuquén' },
        { 'descripcion': 'AUQUINCO , laguna', 'provincia': 'Neuquén' },
        { 'descripcion': 'AZUL , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'BAJADA COLORADA', 'provincia': 'Neuquén' },
        { 'descripcion': 'BAJADA DE LOS MOLLES', 'provincia': 'Neuquén' },
        { 'descripcion': 'BAJADA DEL AGRIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'BAJADA DEL MARUCHO', 'provincia': 'Neuquén' },
        { 'descripcion': 'BALNEARIO DEL RIO AGRIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'BALSA DEL RIO AGRIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'BALSA SENILLOSA', 'provincia': 'Neuquén' },
        { 'descripcion': 'BAÑOS LAS MAQUINITAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'BARDA ANGUIL', 'provincia': 'Neuquén' },
        { 'descripcion': 'BARDAS NEGRAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'BARRANCAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'BARRIO PELIGROSO', 'provincia': 'Neuquén' },
        { 'descripcion': 'BARROSA , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'BATRE LAUQUEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'BAYO MESA , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'Neuquén' },
        { 'descripcion': 'BLANCA , laguna', 'provincia': 'Neuquén' },
        { 'descripcion': 'BONETE , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'BOTE', 'provincia': 'Neuquén' },
        { 'descripcion': 'BUTA CO', 'provincia': 'Neuquén' },
        { 'descripcion': 'BUTA MALLIN', 'provincia': 'Neuquén' },
        { 'descripcion': 'BUTA RANQUIL', 'provincia': 'Neuquén' },
        { 'descripcion': 'BUTACO', 'provincia': 'Neuquén' },
        { 'descripcion': 'BUTALON', 'provincia': 'Neuquén' },
        { 'descripcion': 'BUTALÓN , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAEPE MALAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAICHIHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAJON DE ALMANZA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAJON DE ALMAZA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAJON DE CURI LEUVU', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAJON DE LOS PATOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAJON DE MANZANO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAJON DEL MANZANO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAJON DEL TORO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAJON GRANDE', 'provincia': 'Neuquén' },
        { 'descripcion': 'CALEOFU', 'provincia': 'Neuquén' },
        { 'descripcion': 'CALEUFÚ , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'CALIFORNIA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CALIHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAMALEONES', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAMINERA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAMINERA TRAFUL', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAMPAMENTO SOL', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAMPANA MAHUIDA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAMPO GRANDE', 'provincia': 'Neuquén' },
        { 'descripcion': 'CANADON DE LOS INDIOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'CANADON DEL INDIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CANCHA HUIGANCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CARDENAL ANTONIO SAMORÉ , paso', 'provincia': 'Neuquén' },
        { 'descripcion': 'CARILAUQUÉN GRANDE , laguna', 'provincia': 'Neuquén' },
        { 'descripcion': 'CARRAN CARA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CARRERI', 'provincia': 'Neuquén' },
        { 'descripcion': 'CARRI LAUQUEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'CARRI LIL', 'provincia': 'Neuquén' },
        { 'descripcion': 'CASA DE PIEDRA , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'CASTRO, RAMON M.', 'provincia': 'Neuquén' },
        { 'descripcion': 'CATAN LIL', 'provincia': 'Neuquén' },
        { 'descripcion': 'CATÁN LIL , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAVIAHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'CAYANTA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CENTENARIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CENTINELA , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'CERRO DE LA GRASA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CERRO DE LA PARVA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CERRO DE LOS PINOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'CERRO DEL LEON', 'provincia': 'Neuquén' },
        { 'descripcion': 'CERRO GATO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CERRO NEGRO CHAPUA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CERRO NEGRO TRICAO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHACAICO SUR', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHACAY', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHACAY CO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHACAY MELEHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHACAYAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHACAYCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHACHIL , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHALLACÓ', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHAPELCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHAPUA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHAPUA ABAJO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHARAHUILLA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHATO , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHENQUECURA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHICHIGUAY', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHIHUIDO SUR , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHIMEHUIN', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHIMEHUIN , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHIMIRAY , arroyo', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHINA MUERTA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHINA MUERTA , arroyo', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHINA MUERTA , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHINCHINA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHIQUERO , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHIQUILLIHUIN', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHOCHOY MALLIN', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHOS MALAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'CHURRIACA', 'provincia': 'Neuquén' },
        { 'descripcion': 'COCHICO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CODIHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'CODIHUE , arroyo', 'provincia': 'Neuquén' },
        { 'descripcion': 'CODIHUE o ÑIRECO , portezuelo', 'provincia': 'Neuquén' },
        { 'descripcion': 'COIG', 'provincia': 'Neuquén' },
        { 'descripcion': 'COIHUECO', 'provincia': 'Neuquén' },
        { 'descripcion': 'COLI MALAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'COLIPILI', 'provincia': 'Neuquén' },
        { 'descripcion': 'COLIPILLI', 'provincia': 'Neuquén' },
        { 'descripcion': 'COLIPILLI , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'COLLÓN CURÁ', 'provincia': 'Neuquén' },
        { 'descripcion': 'COLLON-CO', 'provincia': 'Neuquén' },
        { 'descripcion': 'COLLUN CO', 'provincia': 'Neuquén' },
        { 'descripcion': 'COLONIA VALENTINA', 'provincia': 'Neuquén' },
        { 'descripcion': 'COLONIA VALENTINA SUR', 'provincia': 'Neuquén' },
        { 'descripcion': 'CONFLUENCIA DEL AGUIJON', 'provincia': 'Neuquén' },
        { 'descripcion': 'COPAHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'COPAHUE , volcÁn', 'provincia': 'Neuquén' },
        { 'descripcion': 'CORRAL DE PIEDRA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CORRENTOSO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CORTADERA', 'provincia': 'Neuquén' },
        { 'descripcion': 'COSTA DEL ARROYO SALADO', 'provincia': 'Neuquén' },
        { 'descripcion': 'COSTA LIMAY', 'provincia': 'Neuquén' },
        { 'descripcion': 'COVUNCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'COVUNCO , arroyo', 'provincia': 'Neuquén' },
        { 'descripcion': 'COVUNCO ABAJO', 'provincia': 'Neuquén' },
        { 'descripcion': 'COVUNCO ARRIBA', 'provincia': 'Neuquén' },
        { 'descripcion': 'COVUNCO CENTRO', 'provincia': 'Neuquén' },
        { 'descripcion': 'COYUCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CUCHILLO CURA', 'provincia': 'Neuquén' },
        { 'descripcion': 'CULLIN MANZANO', 'provincia': 'Neuquén' },
        { 'descripcion': 'CURRHUÉ , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'CURU LEUVU', 'provincia': 'Neuquén' },
        { 'descripcion': 'CUTRAL - CÓ', 'provincia': 'Neuquén' },
        { 'descripcion': 'CUTRAL CO', 'provincia': 'Neuquén' },
        { 'descripcion': 'de BUTA MALLíN , paso', 'provincia': 'Neuquén' },
        { 'descripcion': 'de CATÁN LIL , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'de COCHICÓ , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'de COPAHUE , paso', 'provincia': 'Neuquén' },
        { 'descripcion': 'de HUANTRAICÓ , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'de LAGO HERMOSO , paso', 'provincia': 'Neuquén' },
        { 'descripcion': 'DE LAGUNAS EPULAFQUEN o ALICÓ , paso', 'provincia': 'Neuquén' },
        { 'descripcion': 'de LOS CÁRMENES , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'de LOS OLIVOS , loma', 'provincia': 'Neuquén' },
        { 'descripcion': 'de MAMUIL MALAL , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'de MAR CHIQUITA', 'provincia': 'Neuquén' },
        { 'descripcion': 'de PINO HACHADO , paso', 'provincia': 'Neuquén' },
        { 'descripcion': 'de REIGOLIL , paso', 'provincia': 'Neuquén' },
        { 'descripcion': 'del AÑELO , cuenca', 'provincia': 'Neuquén' },
        { 'descripcion': 'del CHACHIL , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'del SALADO , cordillera', 'provincia': 'Neuquén' },
        { 'descripcion': 'del VIENTO , cordillera', 'provincia': 'Neuquén' },
        { 'descripcion': 'DIQUE CERROS COLORADOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'DOMUYO , volcÁn', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL ALAMITO', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL ATRAVESADO', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL CHINGUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL CHOLAR', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL CURILEO', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL DORMIDO', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL ESCORIAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL GATO', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL HUECÚ', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL MACHETE', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL MARUCHO', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL OASIS', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL OVERO', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL PALAO', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL PERALITO', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL PINO ANDINO', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL PORTON', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL SALITRAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL TROMEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'EL TROPEZON', 'provincia': 'Neuquén' },
        { 'descripcion': 'EMBALSE LOS BARREALES', 'provincia': 'Neuquén' },
        { 'descripcion': 'EMBALSE MARI MENUCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'ESPEJO , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'ESPINAZO DEL ZORRO', 'provincia': 'Neuquén' },
        { 'descripcion': 'ESTANCIA LA PRIMAVERA', 'provincia': 'Neuquén' },
        { 'descripcion': 'ESTANCIA NEWBERY', 'provincia': 'Neuquén' },
        { 'descripcion': 'ESTANCIA TEQUEL MALAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'FALKNER , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'FEA , laguna', 'provincia': 'Neuquén' },
        { 'descripcion': 'FILMATUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'FILO HUA - HUM , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'FILO HUA HUM', 'provincia': 'Neuquén' },
        { 'descripcion': 'FLORES', 'provincia': 'Neuquén' },
        { 'descripcion': 'FORTIN 1 DE MAYO', 'provincia': 'Neuquén' },
        { 'descripcion': 'FORTIN 1º DE MAYO', 'provincia': 'Neuquén' },
        { 'descripcion': 'FORTIN GUANACOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'FRANHUCURA', 'provincia': 'Neuquén' },
        { 'descripcion': 'GENTE GRANDE', 'provincia': 'Neuquén' },
        { 'descripcion': 'GONI CO', 'provincia': 'Neuquén' },
        { 'descripcion': 'GUANACOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'HAICHOL', 'provincia': 'Neuquén' },
        { 'descripcion': 'HARAS PATRIA', 'provincia': 'Neuquén' },
        { 'descripcion': 'HAYCU', 'provincia': 'Neuquén' },
        { 'descripcion': 'HERMOSO , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUA HUM', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUALCUPEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUALCUPÉN , arroyo', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUANTRAICÓ , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUARACO', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUARINCHENQUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUARINCHENQUE , arroyo', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUECHAHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUECHULAFQUEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUECHULAFQUEN , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUILLILON', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUINCA LU', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUINGANCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUITRIN', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUMIGAMIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'HUNCAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'INVERNADA VIEJA', 'provincia': 'Neuquén' },
        { 'descripcion': 'ISLA VICTORIA', 'provincia': 'Neuquén' },
        { 'descripcion': 'JECANASCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'JUARANCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'JUNíN DE LOS ANDES', 'provincia': 'Neuquén' },
        { 'descripcion': 'KILCA CASA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA ANGOSTURA DE ICALMA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA ARAUCARIA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA ATALAYA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA BUITRERA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA CIENEGUITA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA CRUZADA , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA ESTACADA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA FORTUNA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA FRIA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA ISABEL', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA LIPELA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA NEGRA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA OFELIA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA PATAGONIA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA PICAZA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA PINTADA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA POCHOLA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA SUSANA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA TERESA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA TERESA PIEDRA DEL AGUILA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LA VERDAD', 'provincia': 'Neuquén' },
        { 'descripcion': 'LACAR , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAGO LOG LOG', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAGOTERA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAGUNA MIRANDA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAJA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LANíN , volcÁn', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAPA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAPACHAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAS ABEJAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAS BARDITAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAS COLORADAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAS CORTADERAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAS LAGUNAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAS LAJAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAS LAJAS , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAS OVEJAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAS PERLAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LAS TRES LAGUNAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LASCAR', 'provincia': 'Neuquén' },
        { 'descripcion': 'LEUTO CABALLO', 'provincia': 'Neuquén' },
        { 'descripcion': 'LILEO', 'provincia': 'Neuquén' },
        { 'descripcion': 'LIMAY CENTRO', 'provincia': 'Neuquén' },
        { 'descripcion': 'LITRAN', 'provincia': 'Neuquén' },
        { 'descripcion': 'LIU CULLIN', 'provincia': 'Neuquén' },
        { 'descripcion': 'LLAMUCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOG LOG', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOLOG , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOMA DE LA LATA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LONCO LUAN', 'provincia': 'Neuquén' },
        { 'descripcion': 'LONCO MULA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LONCOPUÉ', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS BOLILLOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS CARRIZOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS CATUTOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS CHACALES', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS CHIHUIDOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS CHINITOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS ENTIERROS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS GALPONES', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS HELECHOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS MICHES', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS MOLLES', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS MUCHACHOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS RODILLOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LOS TRES CHORROS', 'provincia': 'Neuquén' },
        { 'descripcion': 'LUBECA', 'provincia': 'Neuquén' },
        { 'descripcion': 'LUICOCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'LUIN COCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MACHICO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALAICO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLEO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLEO , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLIN BLANCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLIN CHILENO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLíN DE ICALMA , paso', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLIN DE LA CUEVA', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLIN DE LAS YEGUAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLIN DE MENA', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLIN DEL RUBIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLIN DEL TORO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MALLIN QUEMADO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MAMUL MALAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'MANZANO AMARGO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MARI MENUCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MARIANO MORENO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MAYAN MAHUIDA', 'provincia': 'Neuquén' },
        { 'descripcion': 'MELIQUINA', 'provincia': 'Neuquén' },
        { 'descripcion': 'MELIQUINA , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'MESA DE LOS OVEROS , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'MILLA', 'provincia': 'Neuquén' },
        { 'descripcion': 'MINA CARRASCOSA', 'provincia': 'Neuquén' },
        { 'descripcion': 'MINA LILEO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MOQUEHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'MOQUEHUE , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'MORADA , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'MORENO, MARIANO', 'provincia': 'Neuquén' },
        { 'descripcion': 'MULICHINCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'NAHUEL HUAPI', 'provincia': 'Neuquén' },
        { 'descripcion': 'NAHUEL HUAPI , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'NAHUEL MAPE', 'provincia': 'Neuquén' },
        { 'descripcion': 'NAHUEVE', 'provincia': 'Neuquén' },
        { 'descripcion': 'NALAY CULLIN', 'provincia': 'Neuquén' },
        { 'descripcion': 'NAU NAUCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'NAUNAUCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'NEGRA , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'NEGRO , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'NEGRO ZANDONI , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'NERECO NORTE', 'provincia': 'Neuquén' },
        { 'descripcion': 'NEUQUÉN', 'provincia': 'Neuquén' },
        { 'descripcion': 'NEUQUÉN , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'NEVADO , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'NIRECO', 'provincia': 'Neuquén' },
        { 'descripcion': 'NIRECO SUD', 'provincia': 'Neuquén' },
        { 'descripcion': 'NOGUEIRA', 'provincia': 'Neuquén' },
        { 'descripcion': 'ÑORQUIN', 'provincia': 'Neuquén' },
        { 'descripcion': 'ÑORQUINCO , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'Otra', 'provincia': 'Neuquén' },
        { 'descripcion': 'PALAO , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'PALAU', 'provincia': 'Neuquén' },
        { 'descripcion': 'PALITUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'PAMPA COLLON CURA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PAMPA DE LONCO LUAN', 'provincia': 'Neuquén' },
        { 'descripcion': 'PAMPA DE TRIL', 'provincia': 'Neuquén' },
        { 'descripcion': 'PAMPA DEL MALLEO', 'provincia': 'Neuquén' },
        { 'descripcion': 'PAMPA DEL SALADO', 'provincia': 'Neuquén' },
        { 'descripcion': 'PAMPA DEL UNCO , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'PAMPA FERREIRA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PANTANITOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'PARQUE NACIONAL LAGUNA BLANCA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PARQUE NACIONAL LANíN', 'provincia': 'Neuquén' },
        { 'descripcion': 'PARQUE NACIONAL LOS ARRAYANES', 'provincia': 'Neuquén' },
        { 'descripcion': 'PASO AGUERRE', 'provincia': 'Neuquén' },
        { 'descripcion': 'PASO ANCHO', 'provincia': 'Neuquén' },
        { 'descripcion': 'PASO BARDA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PASO CATA TUN', 'provincia': 'Neuquén' },
        { 'descripcion': 'PASO COIHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'PASO DE LOS INDIOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'PASO DE SAN IGNACIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'PENA COLORADA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PENINSULA HUEMUL', 'provincia': 'Neuquén' },
        { 'descripcion': 'PICHAIHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'PICHE PONON', 'provincia': 'Neuquén' },
        { 'descripcion': 'PICHI NEUQUEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'PICÚN LEUFÚ', 'provincia': 'Neuquén' },
        { 'descripcion': 'PICÚN LEUFÚ , arroyo', 'provincia': 'Neuquén' },
        { 'descripcion': 'PICÚN LEUFÚ , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'PIEDRA DEL ÁGUILA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PIEDRA MALA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PIEDRA PINTADA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PIEDRA SOLA , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'PIEDRAS BAYAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'PILMATUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'PILO LIL', 'provincia': 'Neuquén' },
        { 'descripcion': 'PILOLIL', 'provincia': 'Neuquén' },
        { 'descripcion': 'PINO HACHADO', 'provincia': 'Neuquén' },
        { 'descripcion': 'PINO SOLO', 'provincia': 'Neuquén' },
        { 'descripcion': 'PIUQUENES , paso', 'provincia': 'Neuquén' },
        { 'descripcion': 'PLANICIE BANDERITA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PLAZA HUINCUL', 'provincia': 'Neuquén' },
        { 'descripcion': 'PLOTTIER', 'provincia': 'Neuquén' },
        { 'descripcion': 'PORTADA COVUNCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'PORTEZUELO GRANDE', 'provincia': 'Neuquén' },
        { 'descripcion': 'POZO HUALICHES', 'provincia': 'Neuquén' },
        { 'descripcion': 'PRIMEROS PINOS', 'provincia': 'Neuquén' },
        { 'descripcion': 'PUENTE PICUN LEUFU', 'provincia': 'Neuquén' },
        { 'descripcion': 'PUERTO ANCHORENA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PUERTO HUA-HUM', 'provincia': 'Neuquén' },
        { 'descripcion': 'PUERTO HUEMUL', 'provincia': 'Neuquén' },
        { 'descripcion': 'PUERTO MANZANO', 'provincia': 'Neuquén' },
        { 'descripcion': 'PUESTO HERNANDEZ BATERIAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'PUNTA DE SIERRA', 'provincia': 'Neuquén' },
        { 'descripcion': 'PUNTA SIERRA', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUEBRADA HONDA', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUEMPU LEUFU', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUENTRENQUEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUILA CHANQUIL', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUILA QUEHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUILA QUINA', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUILACHANQUIL', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUILCA', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUILI MALAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUILLEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUILLÉN , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUILQUIHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUILQUIHUE , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUININELIU', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUINQUIMITREO', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUINTUCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'QUITA QUINA', 'provincia': 'Neuquén' },
        { 'descripcion': 'RAHUÉ', 'provincia': 'Neuquén' },
        { 'descripcion': 'RAMICHAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'RAMÓN M CASTRO', 'provincia': 'Neuquén' },
        { 'descripcion': 'RANQUIL VEGA', 'provincia': 'Neuquén' },
        { 'descripcion': 'RANQUILCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'RANQUILON', 'provincia': 'Neuquén' },
        { 'descripcion': 'RENILEO', 'provincia': 'Neuquén' },
        { 'descripcion': 'REÑILEUVÚ , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'RICHOIQUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'RINCON CHICO', 'provincia': 'Neuquén' },
        { 'descripcion': 'RINCON DE EMILIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'RINCÓN DE LOS SAUCES', 'provincia': 'Neuquén' },
        { 'descripcion': 'RINCON GRANDE', 'provincia': 'Neuquén' },
        { 'descripcion': 'RIO AGRIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'RIO BARRANCAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'RUCA CHORROY ARRIBA', 'provincia': 'Neuquén' },
        { 'descripcion': 'SAINUCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'SALQUICO', 'provincia': 'Neuquén' },
        { 'descripcion': 'SAN DEMETRIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'SAN IGNACIO', 'provincia': 'Neuquén' },
        { 'descripcion': 'SAN JUAN JUNIN DE LOS ANDES', 'provincia': 'Neuquén' },
        { 'descripcion': 'SAN JUAN RAHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'SAN MARTíN DE LOS ANDES', 'provincia': 'Neuquén' },
        { 'descripcion': 'SAN MATíAS , golfo', 'provincia': 'Neuquén' },
        { 'descripcion': 'SAN PATRICIO DEL CHAÑAR', 'provincia': 'Neuquén' },
        { 'descripcion': 'SAÑICÓ', 'provincia': 'Neuquén' },
        { 'descripcion': 'SANTO TOMAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'SAUZAL BONITO', 'provincia': 'Neuquén' },
        { 'descripcion': 'SENILLOSA', 'provincia': 'Neuquén' },
        { 'descripcion': 'SENILLOSA , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'TAQUI NILEU', 'provincia': 'Neuquén' },
        { 'descripcion': 'TAQUIMILAN', 'provincia': 'Neuquén' },
        { 'descripcion': 'TAQUIMILLAN ABAJO', 'provincia': 'Neuquén' },
        { 'descripcion': 'TIERRAS BLANCAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'TIHUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'TIPILIUKE', 'provincia': 'Neuquén' },
        { 'descripcion': 'TRAFUL', 'provincia': 'Neuquén' },
        { 'descripcion': 'TRAFUL , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'TRAHUNCURA', 'provincia': 'Neuquén' },
        { 'descripcion': 'TRALATUE', 'provincia': 'Neuquén' },
        { 'descripcion': 'TRATAYEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'TRES CHORROS', 'provincia': 'Neuquén' },
        { 'descripcion': 'TRES PIEDRAS', 'provincia': 'Neuquén' },
        { 'descripcion': 'TRICAO MALAL', 'provincia': 'Neuquén' },
        { 'descripcion': 'TROCOMÁN , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'TROCOMÁN , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'TROLÓN , sierra', 'provincia': 'Neuquén' },
        { 'descripcion': 'TROMEN', 'provincia': 'Neuquén' },
        { 'descripcion': 'TROMEN , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'TROMEN , volcÁn', 'provincia': 'Neuquén' },
        { 'descripcion': 'TROMEN o MAMUIL MALAL , paso', 'provincia': 'Neuquén' },
        { 'descripcion': 'TROMPUL', 'provincia': 'Neuquén' },
        { 'descripcion': 'TROPEZÓN , cerro', 'provincia': 'Neuquén' },
        { 'descripcion': 'VACA MUERTA', 'provincia': 'Neuquén' },
        { 'descripcion': 'VARVARCI', 'provincia': 'Neuquén' },
        { 'descripcion': 'VARVARCO', 'provincia': 'Neuquén' },
        { 'descripcion': 'VARVARCO , río', 'provincia': 'Neuquén' },
        { 'descripcion': 'VARVARCO CAMPOS , laguna', 'provincia': 'Neuquén' },
        { 'descripcion': 'VICTORIA , isla', 'provincia': 'Neuquén' },
        { 'descripcion': 'VILLA EL CHOCÓN', 'provincia': 'Neuquén' },
        { 'descripcion': 'VILLA LA ANGOSTURA', 'provincia': 'Neuquén' },
        { 'descripcion': 'VILLA MALLIN', 'provincia': 'Neuquén' },
        { 'descripcion': 'VILLA PEHUENIA', 'provincia': 'Neuquén' },
        { 'descripcion': 'VILLA RAUR', 'provincia': 'Neuquén' },
        { 'descripcion': 'VILLA TRAFUL', 'provincia': 'Neuquén' },
        { 'descripcion': 'VILLARINO , lago', 'provincia': 'Neuquén' },
        { 'descripcion': 'VISTA ALEGRE', 'provincia': 'Neuquén' },
        { 'descripcion': 'VISTA ALEGRE NORTE', 'provincia': 'Neuquén' },
        { 'descripcion': 'VISTA ALEGRE SUR', 'provincia': 'Neuquén' },
        { 'descripcion': 'ZAINA YEGUA', 'provincia': 'Neuquén' },
        { 'descripcion': 'ZAPALA', 'provincia': 'Neuquén' },
        { 'descripcion': 'ZINGONE Y CIA M', 'provincia': 'Neuquén' },
        { 'descripcion': 'ZULEMITA', 'provincia': 'Neuquén' },
        { 'descripcion': '--', 'provincia': 'No informada' },
        { 'descripcion': 'Otra', 'provincia': 'No informada' },
        { 'descripcion': '6 DE OCTUBRE', 'provincia': 'Río Negro' },
        { 'descripcion': 'AGUADA CECILIO', 'provincia': 'Río Negro' },
        { 'descripcion': 'AGUADA DE GUERRA', 'provincia': 'Río Negro' },
        { 'descripcion': 'AGUADA DE GUZMÁN', 'provincia': 'Río Negro' },
        { 'descripcion': 'AGUADA DE LOS PAJARITOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'AGUADA DE PIEDRA', 'provincia': 'Río Negro' },
        { 'descripcion': 'AGUADA DEL LORO', 'provincia': 'Río Negro' },
        { 'descripcion': 'AGUADA GUZMAN', 'provincia': 'Río Negro' },
        { 'descripcion': 'AGUADA TRONCOSO', 'provincia': 'Río Negro' },
        { 'descripcion': 'AGUARA', 'provincia': 'Río Negro' },
        { 'descripcion': 'ALANITOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'ALLEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'ANECON CHICO', 'provincia': 'Río Negro' },
        { 'descripcion': 'ANECON GRANDE', 'provincia': 'Río Negro' },
        { 'descripcion': 'ANECÓN GRANDE , cerro', 'provincia': 'Río Negro' },
        { 'descripcion': 'ARROYO CHACAY', 'provincia': 'Río Negro' },
        { 'descripcion': 'ARROYO DE LA VENTANA', 'provincia': 'Río Negro' },
        { 'descripcion': 'ARROYO LA VENTANA', 'provincia': 'Río Negro' },
        { 'descripcion': 'ARROYO LAS MINAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'ARROYO LOS BERROS', 'provincia': 'Río Negro' },
        { 'descripcion': 'ARROYO SALADO', 'provincia': 'Río Negro' },
        { 'descripcion': 'ARROYO TEMBRADO', 'provincia': 'Río Negro' },
        { 'descripcion': 'ARROYO VERDE', 'provincia': 'Río Negro' },
        { 'descripcion': 'ATRAICO', 'provincia': 'Río Negro' },
        { 'descripcion': 'BAJO DEL GUALICHO', 'provincia': 'Río Negro' },
        { 'descripcion': 'BAJO RICO', 'provincia': 'Río Negro' },
        { 'descripcion': 'BALNEARIO EL CÓNDOR', 'provincia': 'Río Negro' },
        { 'descripcion': 'BALNEARIO LAS GRUTAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'BARDA COLORADA', 'provincia': 'Río Negro' },
        { 'descripcion': 'BARDA DEL MEDIO', 'provincia': 'Río Negro' },
        { 'descripcion': 'BARDA DEL MEDIO , Est. Km. 1218', 'provincia': 'Río Negro' },
        { 'descripcion': 'BARDA DEL MEDIO ,EST.KM 1218', 'provincia': 'Río Negro' },
        { 'descripcion': 'BARILOCHE', 'provincia': 'Río Negro' },
        { 'descripcion': 'BARRIL NIYEO', 'provincia': 'Río Negro' },
        { 'descripcion': 'BARRIO LAGUNA', 'provincia': 'Río Negro' },
        { 'descripcion': 'BARRIO NORTE', 'provincia': 'Río Negro' },
        { 'descripcion': 'BELLIDO , sierra', 'provincia': 'Río Negro' },
        { 'descripcion': 'BENJAMíN ZORRILLA', 'provincia': 'Río Negro' },
        { 'descripcion': 'BERMEJA , punta', 'provincia': 'Río Negro' },
        { 'descripcion': 'BERMEJITO', 'provincia': 'Río Negro' },
        { 'descripcion': 'BLANCAS , sierras', 'provincia': 'Río Negro' },
        { 'descripcion': 'BOCA DE LA TRAVESíA', 'provincia': 'Río Negro' },
        { 'descripcion': 'BUENA PARADA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CALTRAUNA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CANADON CAMALLO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CANADON CHILENO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CANADON DEL CORRAL', 'provincia': 'Río Negro' },
        { 'descripcion': 'CANAL POMONA - SAN ANTONIO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CANAL SAN ANTONIO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CANLLEQUIN', 'provincia': 'Río Negro' },
        { 'descripcion': 'CARI - LAUFQUEN GRANDE , laguna', 'provincia': 'Río Negro' },
        { 'descripcion': 'CARI LAUQUEN , laguna', 'provincia': 'Río Negro' },
        { 'descripcion': 'CARRI YEGUA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CARRILAUFQUÉN , laguna', 'provincia': 'Río Negro' },
        { 'descripcion': 'CARRILAUQUEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'CASA QUEMADA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CASCADA LOS CANTAROS', 'provincia': 'Río Negro' },
        { 'descripcion': 'CATRIEL', 'provincia': 'Río Negro' },
        { 'descripcion': 'CERRO ABANICO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CERRO ABANICO ,AP.', 'provincia': 'Río Negro' },
        { 'descripcion': 'CERRO ALTO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CERRO DE POLICIA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CERRO MESA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CERRO POLICIA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CERVANTES', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHACALHUA RUCA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHACAY HUARRUCA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHACRAS DE ALLEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHAIFUL', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHASICÓ', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHAUCHAIÑEU , sierra', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHELFORÓ', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHENQUENIYEU', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHICHIHUAO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHICHINALES', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHIMPAY', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHINA MUERTA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHIPAUQUIL', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHOCORI', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHOELE CHOEL', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHOELE CHOEL GRANDE , isla', 'provincia': 'Río Negro' },
        { 'descripcion': 'CHURQUINEO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CINCO CHAÑARES', 'provincia': 'Río Negro' },
        { 'descripcion': 'CINCO SALTOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'CIPOLLETTI', 'provincia': 'Río Negro' },
        { 'descripcion': 'CLEMENTE ONELLI', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLI TORO', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLONIA ALTE GUERRICO', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLONIA EL MANZANO', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLONIA GENERAL FRIAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLONIA JOSEFA', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLONIA JULIÁ Y ECHARREN', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLONIA LA LUISA', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLONIA REGINA', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLONIA RUSA', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLORADO , cerro', 'provincia': 'Río Negro' },
        { 'descripcion': 'COLORADOS , cerros', 'provincia': 'Río Negro' },
        { 'descripcion': 'COMALLO', 'provincia': 'Río Negro' },
        { 'descripcion': 'COMALLO , arroyo', 'provincia': 'Río Negro' },
        { 'descripcion': 'COMALLO ABAJO', 'provincia': 'Río Negro' },
        { 'descripcion': 'COMI C', 'provincia': 'Río Negro' },
        { 'descripcion': 'CONA NIYEU', 'provincia': 'Río Negro' },
        { 'descripcion': 'CONTRALMIRANTE CORDERO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CONTRALMIRANTE M GUERRICO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CONTRALMIRANTE M. GUERRICO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CONTRALMIRANTE MARTIN GUERRICO', 'provincia': 'Río Negro' },
        { 'descripcion': 'COQUELEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'CORONEL BELISLE', 'provincia': 'Río Negro' },
        { 'descripcion': 'CORONEL E. DEL BUSTO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CORONEL EUGENIO DEL BUSTO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CORONEL FRANCISCO SOSA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CORONEL JUAN J. GÓMEZ', 'provincia': 'Río Negro' },
        { 'descripcion': 'CORONEL JUAN JOSE GOMEZ', 'provincia': 'Río Negro' },
        { 'descripcion': 'CORRAL DE LOS PINOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'COS ZAURES', 'provincia': 'Río Negro' },
        { 'descripcion': 'COSTA DEL RIO AZUL', 'provincia': 'Río Negro' },
        { 'descripcion': 'COSTAS DEL PICHI LEUFU', 'provincia': 'Río Negro' },
        { 'descripcion': 'CUBANEA', 'provincia': 'Río Negro' },
        { 'descripcion': 'CUENCA VIDAL', 'provincia': 'Río Negro' },
        { 'descripcion': 'CUESTA DEL TERNERO', 'provincia': 'Río Negro' },
        { 'descripcion': 'CURA LAUQUEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'CURICÓ , lagua', 'provincia': 'Río Negro' },
        { 'descripcion': 'DARWIN', 'provincia': 'Río Negro' },
        { 'descripcion': 'de BASTIÓN , portezuelo', 'provincia': 'Río Negro' },
        { 'descripcion': 'de LA LAGUNA ESCONDIDA , bajo', 'provincia': 'Río Negro' },
        { 'descripcion': 'de LOS AVESTRUCES , bajo', 'provincia': 'Río Negro' },
        { 'descripcion': 'de LOS MENUCOS , bajo', 'provincia': 'Río Negro' },
        { 'descripcion': 'de PÉREZ ROSALES , paso', 'provincia': 'Río Negro' },
        { 'descripcion': 'de QUEIPUNIYEO , sierra', 'provincia': 'Río Negro' },
        { 'descripcion': 'de SOMUNCURÁ , meseta', 'provincia': 'Río Negro' },
        { 'descripcion': 'de SOMUNCURÁ , sierra', 'provincia': 'Río Negro' },
        { 'descripcion': 'de SUMAMPA , sierra', 'provincia': 'Río Negro' },
        { 'descripcion': 'de TRAPALCÓ , salinas', 'provincia': 'Río Negro' },
        { 'descripcion': 'de VALCHETA , bajo', 'provincia': 'Río Negro' },
        { 'descripcion': 'del GUALICHO , salina', 'provincia': 'Río Negro' },
        { 'descripcion': 'del PICHI , laguna', 'provincia': 'Río Negro' },
        { 'descripcion': 'DINA HUAPI', 'provincia': 'Río Negro' },
        { 'descripcion': 'DOCTOR ROGELIO CORTIZO ,EMP.', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL BOLSÓN', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL CACIQUE', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL CAíN', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL CAMARURO', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL CHEIFUL', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL CONDOR ESTANCIA', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL CUY', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL DIQUE', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL FOYEL', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL GAUCHO POBRE', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL JARDINERO', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL MANSO', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL MOLIGUE', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL PANTANOSO', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL PORVENIR ,EMB.', 'provincia': 'Río Negro' },
        { 'descripcion': 'EL PUNTUDO , cerro', 'provincia': 'Río Negro' },
        { 'descripcion': 'ENCAYAPAU , cerro', 'provincia': 'Río Negro' },
        { 'descripcion': 'ENCÓN', 'provincia': 'Río Negro' },
        { 'descripcion': 'ESTACIÓN ÑIRIHUAU', 'provincia': 'Río Negro' },
        { 'descripcion': 'ESTANCIA LAS JULIAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'FALCKNER', 'provincia': 'Río Negro' },
        { 'descripcion': 'FALKNER', 'provincia': 'Río Negro' },
        { 'descripcion': 'FERRI', 'provincia': 'Río Negro' },
        { 'descripcion': 'FITALANCAO', 'provincia': 'Río Negro' },
        { 'descripcion': 'FITAMICHE', 'provincia': 'Río Negro' },
        { 'descripcion': 'FITATIMEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'FORTíN UNO', 'provincia': 'Río Negro' },
        { 'descripcion': 'FRAY LUIS BELTRAN', 'provincia': 'Río Negro' },
        { 'descripcion': 'FUTA RUIN', 'provincia': 'Río Negro' },
        { 'descripcion': 'GANZU LAUQUEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'GANZU LAUQUEN ,AP.', 'provincia': 'Río Negro' },
        { 'descripcion': 'GARAY, JUAN DE', 'provincia': 'Río Negro' },
        { 'descripcion': 'GENERAL CONESA', 'provincia': 'Río Negro' },
        { 'descripcion': 'GENERAL E. GODOY', 'provincia': 'Río Negro' },
        { 'descripcion': 'GENERAL ENRIQUE GODOY', 'provincia': 'Río Negro' },
        { 'descripcion': 'GENERAL FERNÁNDEZ ORO', 'provincia': 'Río Negro' },
        { 'descripcion': 'GENERAL LIBORIO BERNAL', 'provincia': 'Río Negro' },
        { 'descripcion': 'GENERAL LORENZO VINTER', 'provincia': 'Río Negro' },
        { 'descripcion': 'GENERAL LORENZO VINTTER', 'provincia': 'Río Negro' },
        { 'descripcion': 'GENERAL NICOLÁS H. PALACIO', 'provincia': 'Río Negro' },
        { 'descripcion': 'GENERAL NICOLAS H.PALACIOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'GENERAL ROCA', 'provincia': 'Río Negro' },
        { 'descripcion': 'GRAN BAJO DEL GUALICHO , depresiÓn', 'provincia': 'Río Negro' },
        { 'descripcion': 'GRANDE , sierra', 'provincia': 'Río Negro' },
        { 'descripcion': 'GUARDIA MITRE', 'provincia': 'Río Negro' },
        { 'descripcion': 'HONDO , bajo', 'provincia': 'Río Negro' },
        { 'descripcion': 'HONDOS , bajos', 'provincia': 'Río Negro' },
        { 'descripcion': 'HUA MICHE', 'provincia': 'Río Negro' },
        { 'descripcion': 'HUAN LUAN', 'provincia': 'Río Negro' },
        { 'descripcion': 'INGENIERO HUERGO', 'provincia': 'Río Negro' },
        { 'descripcion': 'INGENIERO JACOBACCI', 'provincia': 'Río Negro' },
        { 'descripcion': 'INGENIERO JULIAN ROMERO ,EST', 'provincia': 'Río Negro' },
        { 'descripcion': 'INGENIERO LUIS A. HUERGO', 'provincia': 'Río Negro' },
        { 'descripcion': 'INGENIERO OTTO KRAUSE', 'provincia': 'Río Negro' },
        { 'descripcion': 'INGENIERO ZIMMERMANN RESTA', 'provincia': 'Río Negro' },
        { 'descripcion': 'INGENIERO ZIMMERMANN RESTA ,AP.', 'provincia': 'Río Negro' },
        { 'descripcion': 'INGENIO SAN LORENZO', 'provincia': 'Río Negro' },
        { 'descripcion': 'IRIS', 'provincia': 'Río Negro' },
        { 'descripcion': 'ISLA CHICA', 'provincia': 'Río Negro' },
        { 'descripcion': 'ISLA GRANDE', 'provincia': 'Río Negro' },
        { 'descripcion': 'JAGUEL CAMPO MONTE', 'provincia': 'Río Negro' },
        { 'descripcion': 'JANINUE', 'provincia': 'Río Negro' },
        { 'descripcion': 'JITA RUSIA', 'provincia': 'Río Negro' },
        { 'descripcion': 'JUAN DE GARAY', 'provincia': 'Río Negro' },
        { 'descripcion': 'KAKEL HUINCUL , cerro', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILI MALAL', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 1013', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 1040', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 1071', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 21', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 38', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 39', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 57', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 648', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 829', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 839', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 867', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 875', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 899', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 966', 'provincia': 'Río Negro' },
        { 'descripcion': 'KILOMETRO 995', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA ALIANZA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA BOMBILLA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA CAROLINA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA CHILENA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA CRIOLLITA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA ELVIRA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA ENSENADA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA ESPERANZA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA ESTANCIA VIEJA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA EXCURRA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA FLECHA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA JULIA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA LOBERíA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA LUCINDA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA MARIA INES', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA MESETA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA MIMOSA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA QUEBRADA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA SARA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LA VENCEDORA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAGO PELLEGRINI', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAGUNA BLANCA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAGUNA CORTES', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAGUNA DE LA PRUEBA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAGUNA DEL BARRO', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAGUNA FRIAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAGUNA LOS JUNCOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAMARQUE', 'provincia': 'Río Negro' },
        { 'descripcion': 'LANQUINEO', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAS BAYAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAS DOS HERMANAS , cerro', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAS MAQUINAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAS MELLIZAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LAS PIEDRAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LENZANIYEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'LLAO LLAO', 'provincia': 'Río Negro' },
        { 'descripcion': 'LONCO VACA', 'provincia': 'Río Negro' },
        { 'descripcion': 'LOS COSTEROS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LOS JUNCOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LOS MENUCOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LOS PIRINEOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LOS QUEBRACHOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LOS REPOLLOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'LUIS BELTRÁN', 'provincia': 'Río Negro' },
        { 'descripcion': 'LUIS M ZAGAGLIA', 'provincia': 'Río Negro' },
        { 'descripcion': 'MAINQUE', 'provincia': 'Río Negro' },
        { 'descripcion': 'MALLíN AHOGADO', 'provincia': 'Río Negro' },
        { 'descripcion': 'MAMUEL CHOIQUE', 'provincia': 'Río Negro' },
        { 'descripcion': 'MANCHA BLANCA', 'provincia': 'Río Negro' },
        { 'descripcion': 'MANCHA BLANCA ,EST.', 'provincia': 'Río Negro' },
        { 'descripcion': 'MANCULLIQUE', 'provincia': 'Río Negro' },
        { 'descripcion': 'MAQUINCHAO', 'provincia': 'Río Negro' },
        { 'descripcion': 'MAQUINCHAO , río', 'provincia': 'Río Negro' },
        { 'descripcion': 'MARIA CRISTINA', 'provincia': 'Río Negro' },
        { 'descripcion': 'MASCARDI , lago', 'provincia': 'Río Negro' },
        { 'descripcion': 'MATA NEGRA', 'provincia': 'Río Negro' },
        { 'descripcion': 'MEDANOS NEGROS', 'provincia': 'Río Negro' },
        { 'descripcion': 'MEJILLÓN , punta', 'provincia': 'Río Negro' },
        { 'descripcion': 'MENCUÉ', 'provincia': 'Río Negro' },
        { 'descripcion': 'MENUCO VACA MUERTA', 'provincia': 'Río Negro' },
        { 'descripcion': 'MENUCOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'MICHI HONOCA', 'provincia': 'Río Negro' },
        { 'descripcion': 'MICHIHUAO', 'provincia': 'Río Negro' },
        { 'descripcion': 'MINISTRO RAMOS MEXíA', 'provincia': 'Río Negro' },
        { 'descripcion': 'MULANILLO', 'provincia': 'Río Negro' },
        { 'descripcion': 'MUSTERS', 'provincia': 'Río Negro' },
        { 'descripcion': 'MUSTERS ,EST.', 'provincia': 'Río Negro' },
        { 'descripcion': 'NAHUEL NIYEU', 'provincia': 'Río Negro' },
        { 'descripcion': 'NAUPA HUEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'NEGRA , loma', 'provincia': 'Río Negro' },
        { 'descripcion': 'NEGRO MUERTO', 'provincia': 'Río Negro' },
        { 'descripcion': 'NEGRO MUERTO , laguna', 'provincia': 'Río Negro' },
        { 'descripcion': 'NENEO RUCÁ', 'provincia': 'Río Negro' },
        { 'descripcion': 'NILUAN', 'provincia': 'Río Negro' },
        { 'descripcion': 'NIRIHUAO ESTACION FCGR', 'provincia': 'Río Negro' },
        { 'descripcion': 'ÑIRIHUAU', 'provincia': 'Río Negro' },
        { 'descripcion': 'ÑORQUINCO', 'provincia': 'Río Negro' },
        { 'descripcion': 'NUEVA CAROLINA', 'provincia': 'Río Negro' },
        { 'descripcion': 'NUEVO LEON', 'provincia': 'Río Negro' },
        { 'descripcion': 'OJO DE AGUA EMBARCADERO FCGB', 'provincia': 'Río Negro' },
        { 'descripcion': 'OJOS DE AGUA', 'provincia': 'Río Negro' },
        { 'descripcion': 'ONELLI, CLEMENTE', 'provincia': 'Río Negro' },
        { 'descripcion': 'Otra', 'provincia': 'Río Negro' },
        { 'descripcion': 'PADRE A. STEFFENELLI', 'provincia': 'Río Negro' },
        { 'descripcion': 'PADRE ALEJANDRO STEFANELLI', 'provincia': 'Río Negro' },
        { 'descripcion': 'PADRE ALEJANDRO STEFENELLI', 'provincia': 'Río Negro' },
        { 'descripcion': 'PAJALTA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PALENQUE NIYEU', 'provincia': 'Río Negro' },
        { 'descripcion': 'PANQUEHUAO', 'provincia': 'Río Negro' },
        { 'descripcion': 'PASO CHACABUCO', 'provincia': 'Río Negro' },
        { 'descripcion': 'PASO CORDOVA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PASO DE LOS MOLLES', 'provincia': 'Río Negro' },
        { 'descripcion': 'PASO DEL LIMAY', 'provincia': 'Río Negro' },
        { 'descripcion': 'PASO FLORES', 'provincia': 'Río Negro' },
        { 'descripcion': 'PASO LEZCANO', 'provincia': 'Río Negro' },
        { 'descripcion': 'PASO LIMAY', 'provincia': 'Río Negro' },
        { 'descripcion': 'PASO MIRANDA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PASO PIEDRA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PELLEGRINI , lago', 'provincia': 'Río Negro' },
        { 'descripcion': 'PENAS BLANCAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'PERCY H SCOTT', 'provincia': 'Río Negro' },
        { 'descripcion': 'PERITO MORENO', 'provincia': 'Río Negro' },
        { 'descripcion': 'PERITO MORENO ,EST.', 'provincia': 'Río Negro' },
        { 'descripcion': 'PERITO MORENO ESTACION FCGR', 'provincia': 'Río Negro' },
        { 'descripcion': 'PICHI LEUFU', 'provincia': 'Río Negro' },
        { 'descripcion': 'PICHI LEUFU ABAJO', 'provincia': 'Río Negro' },
        { 'descripcion': 'PICHI LEUFU ARRIBA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PICHI MAHUIDA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PILAHUE', 'provincia': 'Río Negro' },
        { 'descripcion': 'PILCANIYEU', 'provincia': 'Río Negro' },
        { 'descripcion': 'PILCANIYEU VIEJO', 'provincia': 'Río Negro' },
        { 'descripcion': 'PILCOMAYO', 'provincia': 'Río Negro' },
        { 'descripcion': 'PILCOMAYO , río', 'provincia': 'Río Negro' },
        { 'descripcion': 'PILQUI NIYEU', 'provincia': 'Río Negro' },
        { 'descripcion': 'PLANICIE DE JAGUELITO', 'provincia': 'Río Negro' },
        { 'descripcion': 'PLAYA BONITA', 'provincia': 'Río Negro' },
        { 'descripcion': 'POLICíA , cerro', 'provincia': 'Río Negro' },
        { 'descripcion': 'POMONA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PÓRFIDO , punta', 'provincia': 'Río Negro' },
        { 'descripcion': 'PORTEZUELO', 'provincia': 'Río Negro' },
        { 'descripcion': 'POZO MORO', 'provincia': 'Río Negro' },
        { 'descripcion': 'POZO SALADO', 'provincia': 'Río Negro' },
        { 'descripcion': 'PRAHUANIYEU', 'provincia': 'Río Negro' },
        { 'descripcion': 'PRIMERA ANGOSTURA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PUERTO BLEST', 'provincia': 'Río Negro' },
        { 'descripcion': 'PUERTO OJO DE AGUA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PUERTO SAN ANTONIO ESTE', 'provincia': 'Río Negro' },
        { 'descripcion': 'PUERTO TIGRE ISLA VICTORIA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PUESTO FARIA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PUESTO GAVINA', 'provincia': 'Río Negro' },
        { 'descripcion': 'PUNTA DE AGUA', 'provincia': 'Río Negro' },
        { 'descripcion': 'QUEMPU NIYEU', 'provincia': 'Río Negro' },
        { 'descripcion': 'QUETREQUILE', 'provincia': 'Río Negro' },
        { 'descripcion': 'QUINTA PANAL', 'provincia': 'Río Negro' },
        { 'descripcion': 'RAYHUAO', 'provincia': 'Río Negro' },
        { 'descripcion': 'REPOLLOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'RINCON DE GASTRE', 'provincia': 'Río Negro' },
        { 'descripcion': 'RíO CHICO , Est. Cerro Mesa', 'provincia': 'Río Negro' },
        { 'descripcion': 'RíO COLORADO', 'provincia': 'Río Negro' },
        { 'descripcion': 'RíO DE LA PLATA , río', 'provincia': 'Río Negro' },
        { 'descripcion': 'RIO SALADO', 'provincia': 'Río Negro' },
        { 'descripcion': 'RíO VILLEGAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'ROSAS , bahía', 'provincia': 'Río Negro' },
        { 'descripcion': 'RUCU LUAN', 'provincia': 'Río Negro' },
        { 'descripcion': 'SACO VIEJO', 'provincia': 'Río Negro' },
        { 'descripcion': 'SALADO , arroyo', 'provincia': 'Río Negro' },
        { 'descripcion': 'SALADO , arroyo', 'provincia': 'Río Negro' },
        { 'descripcion': 'SALITRAL NEGRO', 'provincia': 'Río Negro' },
        { 'descripcion': 'SAN ANTONIO OESTE', 'provincia': 'Río Negro' },
        { 'descripcion': 'SAN CARLOS DE BARILOCHE', 'provincia': 'Río Negro' },
        { 'descripcion': 'SAN LEON', 'provincia': 'Río Negro' },
        { 'descripcion': 'SAN LORENZO', 'provincia': 'Río Negro' },
        { 'descripcion': 'SAN SIMON', 'provincia': 'Río Negro' },
        { 'descripcion': 'SANTA GENOVEVA', 'provincia': 'Río Negro' },
        { 'descripcion': 'SANTA GREGORIA', 'provincia': 'Río Negro' },
        { 'descripcion': 'SANTA NICOLASA', 'provincia': 'Río Negro' },
        { 'descripcion': 'SANTA ROSA', 'provincia': 'Río Negro' },
        { 'descripcion': 'SARGENTO VIDAL', 'provincia': 'Río Negro' },
        { 'descripcion': 'SAUCE BLANCO', 'provincia': 'Río Negro' },
        { 'descripcion': 'SCOTT, PERCY H. ,AP.', 'provincia': 'Río Negro' },
        { 'descripcion': 'SECA , laguna', 'provincia': 'Río Negro' },
        { 'descripcion': 'SECA , laguna', 'provincia': 'Río Negro' },
        { 'descripcion': 'SEGUNDA ANGOSTURA', 'provincia': 'Río Negro' },
        { 'descripcion': 'SIERRA , punta', 'provincia': 'Río Negro' },
        { 'descripcion': 'SIERRA COLORADA', 'provincia': 'Río Negro' },
        { 'descripcion': 'SIERRA GRANDE', 'provincia': 'Río Negro' },
        { 'descripcion': 'SIERRA PAILEMAN', 'provincia': 'Río Negro' },
        { 'descripcion': 'SOSA , cerro', 'provincia': 'Río Negro' },
        { 'descripcion': 'TALCAHUALA ,AP.', 'provincia': 'Río Negro' },
        { 'descripcion': 'TENIENTE MAZA ,EST.', 'provincia': 'Río Negro' },
        { 'descripcion': 'TENIENTE MAZA ESTACION FCGR', 'provincia': 'Río Negro' },
        { 'descripcion': 'TERCERA ZONA', 'provincia': 'Río Negro' },
        { 'descripcion': 'TRAPALCÓ', 'provincia': 'Río Negro' },
        { 'descripcion': 'TRAVESIA CASTRO', 'provincia': 'Río Negro' },
        { 'descripcion': 'TRENETA', 'provincia': 'Río Negro' },
        { 'descripcion': 'TRENETA , arroyo', 'provincia': 'Río Negro' },
        { 'descripcion': 'TRES OJOS DE AGUAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'TRES PICOS , laguna', 'provincia': 'Río Negro' },
        { 'descripcion': 'TRICA CÓ', 'provincia': 'Río Negro' },
        { 'descripcion': 'TRICACO', 'provincia': 'Río Negro' },
        { 'descripcion': 'TROMENIYEU', 'provincia': 'Río Negro' },
        { 'descripcion': 'TRONADOR , monte', 'provincia': 'Río Negro' },
        { 'descripcion': 'TTE GRAL EUSTOQUIO FRIAS', 'provincia': 'Río Negro' },
        { 'descripcion': 'TUNQUELEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'VACA LAUQUEN', 'provincia': 'Río Negro' },
        { 'descripcion': 'VALCHETA', 'provincia': 'Río Negro' },
        { 'descripcion': 'VALCHETA , arroyo', 'provincia': 'Río Negro' },
        { 'descripcion': 'VALLE AZUL', 'provincia': 'Río Negro' },
        { 'descripcion': 'VALLE DE LOS ALAMOS', 'provincia': 'Río Negro' },
        { 'descripcion': 'VICEALMIRANTE EDUARDO O"CONNOR', 'provincia': 'Río Negro' },
        { 'descripcion': 'VIEDMA', 'provincia': 'Río Negro' },
        { 'descripcion': 'VIEDMA ,EST.', 'provincia': 'Río Negro' },
        { 'descripcion': 'VILLA ALBERDI', 'provincia': 'Río Negro' },
        { 'descripcion': 'VILLA LLANQUIN', 'provincia': 'Río Negro' },
        { 'descripcion': 'VILLA LLAO LLAO', 'provincia': 'Río Negro' },
        { 'descripcion': 'VILLA MANZANO', 'provincia': 'Río Negro' },
        { 'descripcion': 'VILLA MASCARDI', 'provincia': 'Río Negro' },
        { 'descripcion': 'VILLA REGINA', 'provincia': 'Río Negro' },
        { 'descripcion': 'VILLA TURISMO', 'provincia': 'Río Negro' },
        { 'descripcion': 'VILLARINO , punta', 'provincia': 'Río Negro' },
        { 'descripcion': 'YUQUICHE , cerro', 'provincia': 'Río Negro' },
        { 'descripcion': 'YUQUINCHE', 'provincia': 'Río Negro' },
        { 'descripcion': 'ZANJÓN DE OYUELA', 'provincia': 'Río Negro' },
        { 'descripcion': 'ZORRILLA, BENJAMIN', 'provincia': 'Río Negro' },
        { 'descripcion': '20 DE FEBRERO', 'provincia': 'Salta' },
        { 'descripcion': 'ABLOME', 'provincia': 'Salta' },
        { 'descripcion': 'ABRA DEL GALLO', 'provincia': 'Salta' },
        { 'descripcion': 'ACAMBUCO', 'provincia': 'Salta' },
        { 'descripcion': 'ACAZOQUE', 'provincia': 'Salta' },
        { 'descripcion': 'ACHARAS', 'provincia': 'Salta' },
        { 'descripcion': 'ACOSTA', 'provincia': 'Salta' },
        { 'descripcion': 'ACOYTE', 'provincia': 'Salta' },
        { 'descripcion': 'AGUA BLANCA', 'provincia': 'Salta' },
        { 'descripcion': 'AGUA NEGRA', 'provincia': 'Salta' },
        { 'descripcion': 'AGUA SUCIA', 'provincia': 'Salta' },
        { 'descripcion': 'AGUA VERDE', 'provincia': 'Salta' },
        { 'descripcion': 'AGUARAY', 'provincia': 'Salta' },
        { 'descripcion': 'AGUARAY ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'AGUAS BLANCAS', 'provincia': 'Salta' },
        { 'descripcion': 'AGUAS MUERTAS', 'provincia': 'Salta' },
        { 'descripcion': 'ALEJO DE ALBERRO', 'provincia': 'Salta' },
        { 'descripcion': 'ALEM', 'provincia': 'Salta' },
        { 'descripcion': 'ALEMANíA', 'provincia': 'Salta' },
        { 'descripcion': 'ALEMANIA CAMPOS DE', 'provincia': 'Salta' },
        { 'descripcion': 'ALFARCITO', 'provincia': 'Salta' },
        { 'descripcion': 'ALGARROBAL', 'provincia': 'Salta' },
        { 'descripcion': 'ALMIRANTE BROWN', 'provincia': 'Salta' },
        { 'descripcion': 'ALMONA', 'provincia': 'Salta' },
        { 'descripcion': 'ALTO DE FLORES', 'provincia': 'Salta' },
        { 'descripcion': 'ALTO DE LA SIERRA', 'provincia': 'Salta' },
        { 'descripcion': 'ALTO DEL MISTOL', 'provincia': 'Salta' },
        { 'descripcion': 'ALTOS HORNOS GUEMES', 'provincia': 'Salta' },
        { 'descripcion': 'AMAICHA', 'provincia': 'Salta' },
        { 'descripcion': 'AMBLAYO', 'provincia': 'Salta' },
        { 'descripcion': 'AMPASCACHI', 'provincia': 'Salta' },
        { 'descripcion': 'ANGASTACO', 'provincia': 'Salta' },
        { 'descripcion': 'ANGEL PEREDO', 'provincia': 'Salta' },
        { 'descripcion': 'ANGOSTO PESCADO', 'provincia': 'Salta' },
        { 'descripcion': 'ANGOSTURA', 'provincia': 'Salta' },
        { 'descripcion': 'ANIMANÁ', 'provincia': 'Salta' },
        { 'descripcion': 'ANTA', 'provincia': 'Salta' },
        { 'descripcion': 'ANTILLA', 'provincia': 'Salta' },
        { 'descripcion': 'ANTONIO ALICE', 'provincia': 'Salta' },
        { 'descripcion': 'ANTONIO QUIJARRO', 'provincia': 'Salta' },
        { 'descripcion': 'APOLINARIO SARAVIA', 'provincia': 'Salta' },
        { 'descripcion': 'ARACAR , volcÁn', 'provincia': 'Salta' },
        { 'descripcion': 'ARENAL', 'provincia': 'Salta' },
        { 'descripcion': 'ARENALES', 'provincia': 'Salta' },
        { 'descripcion': 'ARITA', 'provincia': 'Salta' },
        { 'descripcion': 'ARIZARO , cerro', 'provincia': 'Salta' },
        { 'descripcion': 'ARJUNTAS', 'provincia': 'Salta' },
        { 'descripcion': 'ARROCERO ITALIANO', 'provincia': 'Salta' },
        { 'descripcion': 'ASTILLERO', 'provincia': 'Salta' },
        { 'descripcion': 'ATOCHA', 'provincia': 'Salta' },
        { 'descripcion': 'ATUDILLO', 'provincia': 'Salta' },
        { 'descripcion': 'AZUL CUESTA', 'provincia': 'Salta' },
        { 'descripcion': 'BACOYA', 'provincia': 'Salta' },
        { 'descripcion': 'BAJA DE ORÁN , sierra', 'provincia': 'Salta' },
        { 'descripcion': 'BAJADA', 'provincia': 'Salta' },
        { 'descripcion': 'BAJADA BLANCA', 'provincia': 'Salta' },
        { 'descripcion': 'BAJADA DE GAVI', 'provincia': 'Salta' },
        { 'descripcion': 'BAJO GRANDE', 'provincia': 'Salta' },
        { 'descripcion': 'BAJO GRANDE DESVIO FCGB', 'provincia': 'Salta' },
        { 'descripcion': 'BALBOA', 'provincia': 'Salta' },
        { 'descripcion': 'BALBUENA', 'provincia': 'Salta' },
        { 'descripcion': 'BALLENAL', 'provincia': 'Salta' },
        { 'descripcion': 'BANDA GRANDE', 'provincia': 'Salta' },
        { 'descripcion': 'BANOS TERMALES', 'provincia': 'Salta' },
        { 'descripcion': 'BARBAYASCO', 'provincia': 'Salta' },
        { 'descripcion': 'BARITU', 'provincia': 'Salta' },
        { 'descripcion': 'BARREALITO', 'provincia': 'Salta' },
        { 'descripcion': 'BARRIAL', 'provincia': 'Salta' },
        { 'descripcion': 'BARRIO LA LOMA', 'provincia': 'Salta' },
        { 'descripcion': 'BARRIO PARABOLICA', 'provincia': 'Salta' },
        { 'descripcion': 'BARRIO SAN ANTONIO', 'provincia': 'Salta' },
        { 'descripcion': 'BARRIO SAN CAYETANO', 'provincia': 'Salta' },
        { 'descripcion': 'BARRO NEGRO', 'provincia': 'Salta' },
        { 'descripcion': 'BELGRANO FORTIN 2', 'provincia': 'Salta' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'Salta' },
        { 'descripcion': 'BETANIA', 'provincia': 'Salta' },
        { 'descripcion': 'BODEGUITA', 'provincia': 'Salta' },
        { 'descripcion': 'BREALITO', 'provincia': 'Salta' },
        { 'descripcion': 'BUEN LUGAR', 'provincia': 'Salta' },
        { 'descripcion': 'BUENA FE', 'provincia': 'Salta' },
        { 'descripcion': 'BURRO YACO', 'provincia': 'Salta' },
        { 'descripcion': 'CABEZA DE ANTA', 'provincia': 'Salta' },
        { 'descripcion': 'CABEZA DE BUEY', 'provincia': 'Salta' },
        { 'descripcion': 'CABRA CORRAL DIQUE', 'provincia': 'Salta' },
        { 'descripcion': 'CACHI', 'provincia': 'Salta' },
        { 'descripcion': 'CACHI ADENTRO', 'provincia': 'Salta' },
        { 'descripcion': 'CACHIÑAL', 'provincia': 'Salta' },
        { 'descripcion': 'CACHIPAMPA', 'provincia': 'Salta' },
        { 'descripcion': 'CACHO MOLINO', 'provincia': 'Salta' },
        { 'descripcion': 'CAFAYATE', 'provincia': 'Salta' },
        { 'descripcion': 'CAIPE', 'provincia': 'Salta' },
        { 'descripcion': 'CAIPE ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'CALCHAQUí , río', 'provincia': 'Salta' },
        { 'descripcion': 'CALCHAQUíES , valles', 'provincia': 'Salta' },
        { 'descripcion': 'CALDERA', 'provincia': 'Salta' },
        { 'descripcion': 'CALDERILLA', 'provincia': 'Salta' },
        { 'descripcion': 'CALVIMONTE', 'provincia': 'Salta' },
        { 'descripcion': 'CAMARA', 'provincia': 'Salta' },
        { 'descripcion': 'CAMINERA SAN PEDRITO', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPAMENTO TABLILLA', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPAMENTO VESPUCIO', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPICHUELO', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPO ALEGRE', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPO ARGENTINO', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPO AZUL', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPO CASEROS', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPO DURÁN', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPO GRANDE', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPO LIBRE', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPO QUIJANO', 'provincia': 'Salta' },
        { 'descripcion': 'CAMPO SANTO', 'provincia': 'Salta' },
        { 'descripcion': 'CANADA DE LA JUNTA', 'provincia': 'Salta' },
        { 'descripcion': 'CANADON DE LAS JUNTAS', 'provincia': 'Salta' },
        { 'descripcion': 'CANCILLAR', 'provincia': 'Salta' },
        { 'descripcion': 'CANTERA DEL SAUCE', 'provincia': 'Salta' },
        { 'descripcion': 'CAPIAZUTTI', 'provincia': 'Salta' },
        { 'descripcion': 'CAPILLA FUERTE', 'provincia': 'Salta' },
        { 'descripcion': 'CAPITÁN JUAN PAGÉ', 'provincia': 'Salta' },
        { 'descripcion': 'CARABAJAL', 'provincia': 'Salta' },
        { 'descripcion': 'CARAPARI', 'provincia': 'Salta' },
        { 'descripcion': 'CASTANARES', 'provincia': 'Salta' },
        { 'descripcion': 'CASTAÑARES ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'CAUCHARI', 'provincia': 'Salta' },
        { 'descripcion': 'CEBADOS', 'provincia': 'Salta' },
        { 'descripcion': 'CEIBAL', 'provincia': 'Salta' },
        { 'descripcion': 'CEIBALITO', 'provincia': 'Salta' },
        { 'descripcion': 'CENTENARIO , salar', 'provincia': 'Salta' },
        { 'descripcion': 'CERRILLOS', 'provincia': 'Salta' },
        { 'descripcion': 'CERRO ALEMANIA', 'provincia': 'Salta' },
        { 'descripcion': 'CERRO BAYO', 'provincia': 'Salta' },
        { 'descripcion': 'CERRO BRAVO', 'provincia': 'Salta' },
        { 'descripcion': 'CERRO DE LA ZORRA VIEJA', 'provincia': 'Salta' },
        { 'descripcion': 'CERRO DEL AGUA DE LA FALDA', 'provincia': 'Salta' },
        { 'descripcion': 'CERRO MAL CANTO', 'provincia': 'Salta' },
        { 'descripcion': 'CEVILAR', 'provincia': 'Salta' },
        { 'descripcion': 'CHACAR', 'provincia': 'Salta' },
        { 'descripcion': 'CHACHAPOYAS', 'provincia': 'Salta' },
        { 'descripcion': 'CHACHAS', 'provincia': 'Salta' },
        { 'descripcion': 'CHACRA EXPERIMENTAL', 'provincia': 'Salta' },
        { 'descripcion': 'CHAGUARAL', 'provincia': 'Salta' },
        { 'descripcion': 'CHAMICAL', 'provincia': 'Salta' },
        { 'descripcion': 'CHANAR AGUADA', 'provincia': 'Salta' },
        { 'descripcion': 'CHANAR MUYO', 'provincia': 'Salta' },
        { 'descripcion': 'CHAÑAR MUYO ,EST.CNL.OLLEROS', 'provincia': 'Salta' },
        { 'descripcion': 'CHICOANA', 'provincia': 'Salta' },
        { 'descripcion': 'CHICOANA ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'CHIRETE', 'provincia': 'Salta' },
        { 'descripcion': 'CHIVILME', 'provincia': 'Salta' },
        { 'descripcion': 'CHIYAYOC', 'provincia': 'Salta' },
        { 'descripcion': 'CHORRILLITOS', 'provincia': 'Salta' },
        { 'descripcion': 'CHORRILLOS', 'provincia': 'Salta' },
        { 'descripcion': 'CHORRO BLANCO', 'provincia': 'Salta' },
        { 'descripcion': 'CHORROARíN', 'provincia': 'Salta' },
        { 'descripcion': 'CHUCULAQUI', 'provincia': 'Salta' },
        { 'descripcion': 'CHUCULAQUI ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'CIERVO CANSADO', 'provincia': 'Salta' },
        { 'descripcion': 'COBA', 'provincia': 'Salta' },
        { 'descripcion': 'COBAS', 'provincia': 'Salta' },
        { 'descripcion': 'COBOS', 'provincia': 'Salta' },
        { 'descripcion': 'COBRES', 'provincia': 'Salta' },
        { 'descripcion': 'COCHABAMBA ,AP.', 'provincia': 'Salta' },
        { 'descripcion': 'COLANZULI', 'provincia': 'Salta' },
        { 'descripcion': 'COLGADAS', 'provincia': 'Salta' },
        { 'descripcion': 'COLMENAR', 'provincia': 'Salta' },
        { 'descripcion': 'COLOME', 'provincia': 'Salta' },
        { 'descripcion': 'COLONIA A', 'provincia': 'Salta' },
        { 'descripcion': 'COLONIA AGRICOLA SAN AGUSTIN', 'provincia': 'Salta' },
        { 'descripcion': 'COLONIA BUENAVENTURA', 'provincia': 'Salta' },
        { 'descripcion': 'COLONIA D', 'provincia': 'Salta' },
        { 'descripcion': 'COLONIA EL FUERTE', 'provincia': 'Salta' },
        { 'descripcion': 'COLONIA HURLINGHAM', 'provincia': 'Salta' },
        { 'descripcion': 'COLONIA OTOMANA', 'provincia': 'Salta' },
        { 'descripcion': 'COLONIA SANTA ROSA', 'provincia': 'Salta' },
        { 'descripcion': 'COLONIA SANTA ROSA DE LIMA', 'provincia': 'Salta' },
        { 'descripcion': 'COLONIA ZANJA DEL TIGRE', 'provincia': 'Salta' },
        { 'descripcion': 'COLORADO', 'provincia': 'Salta' },
        { 'descripcion': 'COLTE', 'provincia': 'Salta' },
        { 'descripcion': 'CONCHAS', 'provincia': 'Salta' },
        { 'descripcion': 'CONDADO', 'provincia': 'Salta' },
        { 'descripcion': 'COPO QUILE', 'provincia': 'Salta' },
        { 'descripcion': 'CORONEL CORNEJO', 'provincia': 'Salta' },
        { 'descripcion': 'CORONEL JUAN SOLA', 'provincia': 'Salta' },
        { 'descripcion': 'CORONEL JUAN SOLÁ , Est. Morillo', 'provincia': 'Salta' },
        { 'descripcion': 'CORONEL MOLDES', 'provincia': 'Salta' },
        { 'descripcion': 'CORONEL MOLLINEDO', 'provincia': 'Salta' },
        { 'descripcion': 'CORONEL OLLEROS', 'provincia': 'Salta' },
        { 'descripcion': 'CORONEL VIDT', 'provincia': 'Salta' },
        { 'descripcion': 'COROPAMPA', 'provincia': 'Salta' },
        { 'descripcion': 'CORRAL QUEMADO', 'provincia': 'Salta' },
        { 'descripcion': 'CORRALITO', 'provincia': 'Salta' },
        { 'descripcion': 'CORRIDA DE CORI', 'provincia': 'Salta' },
        { 'descripcion': 'CRUZ QUEMADA', 'provincia': 'Salta' },
        { 'descripcion': 'CUCHIYACO', 'provincia': 'Salta' },
        { 'descripcion': 'CUESTA AZUL', 'provincia': 'Salta' },
        { 'descripcion': 'CUESTA CHICA', 'provincia': 'Salta' },
        { 'descripcion': 'CUESTA DEL OBISPO', 'provincia': 'Salta' },
        { 'descripcion': 'CURUZU', 'provincia': 'Salta' },
        { 'descripcion': 'DAMIAN N TORINO', 'provincia': 'Salta' },
        { 'descripcion': 'DE ALMAGRO, DIEGO', 'provincia': 'Salta' },
        { 'descripcion': 'de ARCHIBARCA , sierra', 'provincia': 'Salta' },
        { 'descripcion': 'de ARIZARO , salar', 'provincia': 'Salta' },
        { 'descripcion': 'de CACHI , sierra', 'provincia': 'Salta' },
        { 'descripcion': 'de CALALASTE , sierra', 'provincia': 'Salta' },
        { 'descripcion': 'de INCAHUASI , salar', 'provincia': 'Salta' },
        { 'descripcion': 'de LA CANDELARIA o de CASTILLEJOS , sierra', 'provincia': 'Salta' },
        { 'descripcion': 'de LA CRESTA DEL GALLO , cerros', 'provincia': 'Salta' },
        { 'descripcion': 'de LA MESADA , serranía', 'provincia': 'Salta' },
        { 'descripcion': 'de LAS CONCHAS , río', 'provincia': 'Salta' },
        { 'descripcion': 'de LOS PASTOS GRANDES , sierra', 'provincia': 'Salta' },
        { 'descripcion': 'del ANTA , río', 'provincia': 'Salta' },
        { 'descripcion': 'del LIBERTADOR GENERAL SAN MARTíN , cumbre', 'provincia': 'Salta' },
        { 'descripcion': 'del QUEMADO , mogote', 'provincia': 'Salta' },
        { 'descripcion': 'del RINCÓN , salina', 'provincia': 'Salta' },
        { 'descripcion': 'del TORO , quebrada', 'provincia': 'Salta' },
        { 'descripcion': 'DIEGO DE ALMAGRO', 'provincia': 'Salta' },
        { 'descripcion': 'DIQUE EMBALSE CAMPO ALEGRE', 'provincia': 'Salta' },
        { 'descripcion': 'DIQUE ITIRUYO', 'provincia': 'Salta' },
        { 'descripcion': 'DIVISADERO', 'provincia': 'Salta' },
        { 'descripcion': 'DOCTOR FACUNDO ZUVIRIA', 'provincia': 'Salta' },
        { 'descripcion': 'DOS YUCHANES', 'provincia': 'Salta' },
        { 'descripcion': 'DRAGONES', 'provincia': 'Salta' },
        { 'descripcion': 'DURAZNITO', 'provincia': 'Salta' },
        { 'descripcion': 'EBRO', 'provincia': 'Salta' },
        { 'descripcion': 'EL ACHERAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL AGUAY', 'provincia': 'Salta' },
        { 'descripcion': 'EL AIBAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL ALFREDITO', 'provincia': 'Salta' },
        { 'descripcion': 'EL ALGARROBAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL ALISAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL ARENAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL BORDE DE SAN MIGUEL', 'provincia': 'Salta' },
        { 'descripcion': 'EL BORDO', 'provincia': 'Salta' },
        { 'descripcion': 'EL BORDO CAMPO SANTO', 'provincia': 'Salta' },
        { 'descripcion': 'EL BOTIN', 'provincia': 'Salta' },
        { 'descripcion': 'EL BREAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL BREALITO', 'provincia': 'Salta' },
        { 'descripcion': 'EL CANDADO', 'provincia': 'Salta' },
        { 'descripcion': 'EL CARANCHO', 'provincia': 'Salta' },
        { 'descripcion': 'EL CARRIL', 'provincia': 'Salta' },
        { 'descripcion': 'EL CARRIZAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL CEBILLAR', 'provincia': 'Salta' },
        { 'descripcion': 'EL CEIBALITO', 'provincia': 'Salta' },
        { 'descripcion': 'EL CHORRO', 'provincia': 'Salta' },
        { 'descripcion': 'EL CHURCAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL CIENEGO', 'provincia': 'Salta' },
        { 'descripcion': 'EL COLEGIO', 'provincia': 'Salta' },
        { 'descripcion': 'EL COLGADO', 'provincia': 'Salta' },
        { 'descripcion': 'EL CORRAL VIEJO', 'provincia': 'Salta' },
        { 'descripcion': 'EL CORRALITO', 'provincia': 'Salta' },
        { 'descripcion': 'EL CRESTON', 'provincia': 'Salta' },
        { 'descripcion': 'EL CUCHILLO', 'provincia': 'Salta' },
        { 'descripcion': 'EL CUIBAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL CUINCO POZO DEL', 'provincia': 'Salta' },
        { 'descripcion': 'EL DESTIERRO', 'provincia': 'Salta' },
        { 'descripcion': 'EL DURAZNO', 'provincia': 'Salta' },
        { 'descripcion': 'EL ENCON', 'provincia': 'Salta' },
        { 'descripcion': 'EL ESTANQUE', 'provincia': 'Salta' },
        { 'descripcion': 'EL FRAILE', 'provincia': 'Salta' },
        { 'descripcion': 'EL GALLINATO', 'provincia': 'Salta' },
        { 'descripcion': 'EL GALPÓN , Est. Foguista Jorge F. SuÁrez', 'provincia': 'Salta' },
        { 'descripcion': 'EL GALPON ,EST.FOGUISTA JORGE.F.SUAREZ', 'provincia': 'Salta' },
        { 'descripcion': 'EL GALPON EST FOGUISTA J F', 'provincia': 'Salta' },
        { 'descripcion': 'EL GOLGOTA', 'provincia': 'Salta' },
        { 'descripcion': 'EL GUANACO', 'provincia': 'Salta' },
        { 'descripcion': 'EL HUAICO', 'provincia': 'Salta' },
        { 'descripcion': 'EL JARAVI', 'provincia': 'Salta' },
        { 'descripcion': 'EL LEONCITO', 'provincia': 'Salta' },
        { 'descripcion': 'EL LIBANO', 'provincia': 'Salta' },
        { 'descripcion': 'EL MANANTIAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL MARAY', 'provincia': 'Salta' },
        { 'descripcion': 'EL MISTOL', 'provincia': 'Salta' },
        { 'descripcion': 'EL MORANILLO', 'provincia': 'Salta' },
        { 'descripcion': 'EL MOYAR', 'provincia': 'Salta' },
        { 'descripcion': 'EL NARANJO', 'provincia': 'Salta' },
        { 'descripcion': 'EL NATO', 'provincia': 'Salta' },
        { 'descripcion': 'EL NOGALAR', 'provincia': 'Salta' },
        { 'descripcion': 'EL OBELISCO', 'provincia': 'Salta' },
        { 'descripcion': 'EL OJITO', 'provincia': 'Salta' },
        { 'descripcion': 'EL OSO', 'provincia': 'Salta' },
        { 'descripcion': 'EL PACARA', 'provincia': 'Salta' },
        { 'descripcion': 'EL PAJEAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL PERICOTE', 'provincia': 'Salta' },
        { 'descripcion': 'EL PIQUETE', 'provincia': 'Salta' },
        { 'descripcion': 'EL POTRERO', 'provincia': 'Salta' },
        { 'descripcion': 'EL POTRERO DE DIAZ', 'provincia': 'Salta' },
        { 'descripcion': 'EL PRADO', 'provincia': 'Salta' },
        { 'descripcion': 'EL PUCARA', 'provincia': 'Salta' },
        { 'descripcion': 'EL PUYIL', 'provincia': 'Salta' },
        { 'descripcion': 'EL QUEBRACHAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL QUIMILAR CARBONCITO', 'provincia': 'Salta' },
        { 'descripcion': 'EL RECREO', 'provincia': 'Salta' },
        { 'descripcion': 'EL REY', 'provincia': 'Salta' },
        { 'descripcion': 'EL ROSAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL SALADILLO', 'provincia': 'Salta' },
        { 'descripcion': 'EL SALTO', 'provincia': 'Salta' },
        { 'descripcion': 'EL SAPO', 'provincia': 'Salta' },
        { 'descripcion': 'EL SAUZAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL SIMBOLAR', 'provincia': 'Salta' },
        { 'descripcion': 'EL SOLDADITO', 'provincia': 'Salta' },
        { 'descripcion': 'EL TABACAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL TALA , Est. Ruiz de los Llanos', 'provincia': 'Salta' },
        { 'descripcion': 'EL TALA EST R DE LOS LLANOS', 'provincia': 'Salta' },
        { 'descripcion': 'EL TANDIL', 'provincia': 'Salta' },
        { 'descripcion': 'EL TAPIAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL TARTAGAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL TIMBO', 'provincia': 'Salta' },
        { 'descripcion': 'EL TIPAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL TORO', 'provincia': 'Salta' },
        { 'descripcion': 'EL TRIGAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL TUNAL', 'provincia': 'Salta' },
        { 'descripcion': 'EL TUNALITO', 'provincia': 'Salta' },
        { 'descripcion': 'EL VALLECITO', 'provincia': 'Salta' },
        { 'descripcion': 'EL VENCIDO', 'provincia': 'Salta' },
        { 'descripcion': 'EL YACON', 'provincia': 'Salta' },
        { 'descripcion': 'EL YESO', 'provincia': 'Salta' },
        { 'descripcion': 'EL ZAPALLO', 'provincia': 'Salta' },
        { 'descripcion': 'ELTUNAL', 'provincia': 'Salta' },
        { 'descripcion': 'EMBALSE CABRA CORRAL', 'provincia': 'Salta' },
        { 'descripcion': 'EMBALSE CASA DE PIEDRA', 'provincia': 'Salta' },
        { 'descripcion': 'EMBALSE EL TUNAL', 'provincia': 'Salta' },
        { 'descripcion': 'EMBALSE EZEQUIEL RAMOS MEXíA', 'provincia': 'Salta' },
        { 'descripcion': 'EMBALSE ITIYURO', 'provincia': 'Salta' },
        { 'descripcion': 'EMBARCACIÓN', 'provincia': 'Salta' },
        { 'descripcion': 'EMBOSCADA', 'provincia': 'Salta' },
        { 'descripcion': 'ENCRUCIJADA DE TASTIL', 'provincia': 'Salta' },
        { 'descripcion': 'ENTRE RIOS', 'provincia': 'Salta' },
        { 'descripcion': 'ESCALCHI', 'provincia': 'Salta' },
        { 'descripcion': 'ESCOIPE', 'provincia': 'Salta' },
        { 'descripcion': 'ESQUINA DE QUISTO', 'provincia': 'Salta' },
        { 'descripcion': 'ESTACION CACHINAL', 'provincia': 'Salta' },
        { 'descripcion': 'ESTACION ZUVIRIA', 'provincia': 'Salta' },
        { 'descripcion': 'ESTANCIA VIEJA', 'provincia': 'Salta' },
        { 'descripcion': 'ESTEBAN DE URIZAR', 'provincia': 'Salta' },
        { 'descripcion': 'ESTECO', 'provincia': 'Salta' },
        { 'descripcion': 'ESTECO EMBARCADERO FCGB', 'provincia': 'Salta' },
        { 'descripcion': 'ESTOLA', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA ARMONIA', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA BELGRANO', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA CAMINO A COLON', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA CAMINO VALLISIOS', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA COLON', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA EL CARMEN', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA EL COLEGIO', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA LA CHINA', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA LA ROSA', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA LA TOMA', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA MISION ZENTA', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA ROCCA', 'provincia': 'Salta' },
        { 'descripcion': 'FINCA SANTIAGO', 'provincia': 'Salta' },
        { 'descripcion': 'FLORESTA', 'provincia': 'Salta' },
        { 'descripcion': 'FOGUISTA J F JUAREZ', 'provincia': 'Salta' },
        { 'descripcion': 'FORTIN BELGRANO', 'provincia': 'Salta' },
        { 'descripcion': 'FORTIN FRIAS', 'provincia': 'Salta' },
        { 'descripcion': 'FRONTERA 4', 'provincia': 'Salta' },
        { 'descripcion': 'FRONTERA 5', 'provincia': 'Salta' },
        { 'descripcion': 'FRONTERA TRES', 'provincia': 'Salta' },
        { 'descripcion': 'FUERTE ALTO', 'provincia': 'Salta' },
        { 'descripcion': 'FUERTE EL PITO', 'provincia': 'Salta' },
        { 'descripcion': 'GALLINATO', 'provincia': 'Salta' },
        { 'descripcion': 'GAONA', 'provincia': 'Salta' },
        { 'descripcion': 'GENERAL ALVARADO', 'provincia': 'Salta' },
        { 'descripcion': 'GENERAL BALLIVIAN', 'provincia': 'Salta' },
        { 'descripcion': 'GENERAL GUEMES ,EST.GUEMES', 'provincia': 'Salta' },
        { 'descripcion': 'GENERAL MOSCONI , Est. Vespucio', 'provincia': 'Salta' },
        { 'descripcion': 'GENERAL PIZARRO', 'provincia': 'Salta' },
        { 'descripcion': 'GOBERNADOR MANUEL SOLÁ', 'provincia': 'Salta' },
        { 'descripcion': 'GOBERNADOR SARAVIA', 'provincia': 'Salta' },
        { 'descripcion': 'GONZALEZ', 'provincia': 'Salta' },
        { 'descripcion': 'GONZALEZ, JOAQUIN V.', 'provincia': 'Salta' },
        { 'descripcion': 'GRANDE DE TARIJA , río', 'provincia': 'Salta' },
        { 'descripcion': 'GRANDES PASTOS', 'provincia': 'Salta' },
        { 'descripcion': 'GUACHIPAS', 'provincia': 'Salta' },
        { 'descripcion': 'GUALFIN', 'provincia': 'Salta' },
        { 'descripcion': 'GUALIANA', 'provincia': 'Salta' },
        { 'descripcion': 'GUAMACHI', 'provincia': 'Salta' },
        { 'descripcion': 'GUAYACAN', 'provincia': 'Salta' },
        { 'descripcion': 'HICKMAN', 'provincia': 'Salta' },
        { 'descripcion': 'HICKMANN', 'provincia': 'Salta' },
        { 'descripcion': 'HIGUERAS', 'provincia': 'Salta' },
        { 'descripcion': 'HIPOLITO YRIGOYEN', 'provincia': 'Salta' },
        { 'descripcion': 'HIPÓLITO YRIGOYEN , Est. Tabacal', 'provincia': 'Salta' },
        { 'descripcion': 'HITO 1', 'provincia': 'Salta' },
        { 'descripcion': 'HITO ESMERALDA', 'provincia': 'Salta' },
        { 'descripcion': 'HOMBRE MUERTO SALAR DE', 'provincia': 'Salta' },
        { 'descripcion': 'HORCONES', 'provincia': 'Salta' },
        { 'descripcion': 'HORCONES , río', 'provincia': 'Salta' },
        { 'descripcion': 'HOSTERIA JURAMENTO', 'provincia': 'Salta' },
        { 'descripcion': 'HUAICONDO', 'provincia': 'Salta' },
        { 'descripcion': 'HUAYRA HUASY', 'provincia': 'Salta' },
        { 'descripcion': 'HUAYTIQUINA , Port.', 'provincia': 'Salta' },
        { 'descripcion': 'HUERTA', 'provincia': 'Salta' },
        { 'descripcion': 'HUGUERILLAS', 'provincia': 'Salta' },
        { 'descripcion': 'HUMAITA', 'provincia': 'Salta' },
        { 'descripcion': 'HUMANAS', 'provincia': 'Salta' },
        { 'descripcion': 'INCACHULI', 'provincia': 'Salta' },
        { 'descripcion': 'INCAHUASI', 'provincia': 'Salta' },
        { 'descripcion': 'INCAMAYO', 'provincia': 'Salta' },
        { 'descripcion': 'INGENIERO MAURY', 'provincia': 'Salta' },
        { 'descripcion': 'INGENIO SAN ISIDRO', 'provincia': 'Salta' },
        { 'descripcion': 'INGENIO SAN MARTIN', 'provincia': 'Salta' },
        { 'descripcion': 'IRUYA', 'provincia': 'Salta' },
        { 'descripcion': 'IRUYA, río', 'provincia': 'Salta' },
        { 'descripcion': 'ISLA DE CANAS', 'provincia': 'Salta' },
        { 'descripcion': 'ISLA DE LA CANDELARIA', 'provincia': 'Salta' },
        { 'descripcion': 'ISONZA', 'provincia': 'Salta' },
        { 'descripcion': 'ITAÚ ,río', 'provincia': 'Salta' },
        { 'descripcion': 'ITUYURO', 'provincia': 'Salta' },
        { 'descripcion': 'ITUYURO , arroyo', 'provincia': 'Salta' },
        { 'descripcion': 'JACIMANA', 'provincia': 'Salta' },
        { 'descripcion': 'JASIMANA', 'provincia': 'Salta' },
        { 'descripcion': 'JERÓNIMO MATORRAS', 'provincia': 'Salta' },
        { 'descripcion': 'JOAQUIN V GONZALEZ', 'provincia': 'Salta' },
        { 'descripcion': 'JOAQUíN V. GONZÁLEZ', 'provincia': 'Salta' },
        { 'descripcion': 'JUNCALITO', 'provincia': 'Salta' },
        { 'descripcion': 'JUNTAS DE SAN ANTONIO', 'provincia': 'Salta' },
        { 'descripcion': 'JURAMENTO', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1088 ,DV.', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1104 ,DV.', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1125 ,DV.', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1129 ,DV.', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1152 ,DV.', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1156 ,AP.', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1291', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1291 ,DV.', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1299', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1305 ,DV.', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1357', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1424', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1448', 'provincia': 'Salta' },
        { 'descripcion': 'KILOMETRO 1506', 'provincia': 'Salta' },
        { 'descripcion': 'LA ARCADIA', 'provincia': 'Salta' },
        { 'descripcion': 'LA ARMONIA', 'provincia': 'Salta' },
        { 'descripcion': 'LA ASUNCION', 'provincia': 'Salta' },
        { 'descripcion': 'LA BODEGA', 'provincia': 'Salta' },
        { 'descripcion': 'LA BODEGUITA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CALAVERA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CALDERA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CANCHA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CANDELARIA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CARRETERA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CASUALIDAD MINA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CHINA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CIENEGUITA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CORZUELA', 'provincia': 'Salta' },
        { 'descripcion': 'LA COSTOSA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CUESTITA ANTA', 'provincia': 'Salta' },
        { 'descripcion': 'LA CUESTITA METAN', 'provincia': 'Salta' },
        { 'descripcion': 'LA CURVA', 'provincia': 'Salta' },
        { 'descripcion': 'LA DEFENSA', 'provincia': 'Salta' },
        { 'descripcion': 'LA ENTRADA', 'provincia': 'Salta' },
        { 'descripcion': 'LA ESQUINITA', 'provincia': 'Salta' },
        { 'descripcion': 'LA ESTELA', 'provincia': 'Salta' },
        { 'descripcion': 'LA ESTRELLA', 'provincia': 'Salta' },
        { 'descripcion': 'LA FAMA', 'provincia': 'Salta' },
        { 'descripcion': 'LA FLORIDA', 'provincia': 'Salta' },
        { 'descripcion': 'LA FORTUNA', 'provincia': 'Salta' },
        { 'descripcion': 'LA ISLA', 'provincia': 'Salta' },
        { 'descripcion': 'LA LAGUNILLA', 'provincia': 'Salta' },
        { 'descripcion': 'LA LOMITA', 'provincia': 'Salta' },
        { 'descripcion': 'LA MANGA', 'provincia': 'Salta' },
        { 'descripcion': 'LA MARAVILLA', 'provincia': 'Salta' },
        { 'descripcion': 'LA MARGARITA', 'provincia': 'Salta' },
        { 'descripcion': 'LA MATILDE', 'provincia': 'Salta' },
        { 'descripcion': 'LA MERCED', 'provincia': 'Salta' },
        { 'descripcion': 'LA MONTANA', 'provincia': 'Salta' },
        { 'descripcion': 'LA OFELIA', 'provincia': 'Salta' },
        { 'descripcion': 'LA OLIVA', 'provincia': 'Salta' },
        { 'descripcion': 'LA PAJITA', 'provincia': 'Salta' },
        { 'descripcion': 'LA PALATA', 'provincia': 'Salta' },
        { 'descripcion': 'LA PAYA', 'provincia': 'Salta' },
        { 'descripcion': 'LA PEDRERA', 'provincia': 'Salta' },
        { 'descripcion': 'LA POMA', 'provincia': 'Salta' },
        { 'descripcion': 'LA PORCELANA', 'provincia': 'Salta' },
        { 'descripcion': 'LA QUENA', 'provincia': 'Salta' },
        { 'descripcion': 'LA QUESERA', 'provincia': 'Salta' },
        { 'descripcion': 'LA ROSITA', 'provincia': 'Salta' },
        { 'descripcion': 'LA SILLETA', 'provincia': 'Salta' },
        { 'descripcion': 'LA SOLEDAD', 'provincia': 'Salta' },
        { 'descripcion': 'LA TRAMPA', 'provincia': 'Salta' },
        { 'descripcion': 'LA TROJA', 'provincia': 'Salta' },
        { 'descripcion': 'LA UNION', 'provincia': 'Salta' },
        { 'descripcion': 'LA VIÑA', 'provincia': 'Salta' },
        { 'descripcion': 'LA YESERA', 'provincia': 'Salta' },
        { 'descripcion': 'LAGUNA SECA', 'provincia': 'Salta' },
        { 'descripcion': 'LAGUNA VERDE', 'provincia': 'Salta' },
        { 'descripcion': 'LAGUNITA NUEVA POBLACION', 'provincia': 'Salta' },
        { 'descripcion': 'LAS ACHERAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS ANIMAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS ARCAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS ARENAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS BATEAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS BLANCAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS BOLSAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS CAPILLAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS CATITAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS CEBADAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS CONCHAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS CORTADERAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS CORTDERAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS COSTAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS CUESTITAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS CURTIEMBRES', 'provincia': 'Salta' },
        { 'descripcion': 'LAS FLACAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS FLECHAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS HECHERAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS HORQUETAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS JUNTAS DE ALEMANIA', 'provincia': 'Salta' },
        { 'descripcion': 'LAS LAJITAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS LECHUZAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS LLAVES', 'provincia': 'Salta' },
        { 'descripcion': 'LAS MARAVILLAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS MERCEDES', 'provincia': 'Salta' },
        { 'descripcion': 'LAS MESADAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS MESITAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS MESITAS ,DV.', 'provincia': 'Salta' },
        { 'descripcion': 'LAS MOJARRITAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS MORAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS PAILAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS PALMAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS PIRCAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS SALADAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS TIENDITAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS TORTUGAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS TRANCAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS TRES MARIAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS TUNILLAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS VATEAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS VENTANAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS VERTIENTES SANTA RITA DE', 'provincia': 'Salta' },
        { 'descripcion': 'LAS VIBORAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS VINAS', 'provincia': 'Salta' },
        { 'descripcion': 'LAS ZANJAS', 'provincia': 'Salta' },
        { 'descripcion': 'LECHIGUANA', 'provincia': 'Salta' },
        { 'descripcion': 'LESSER', 'provincia': 'Salta' },
        { 'descripcion': 'LIMACHE', 'provincia': 'Salta' },
        { 'descripcion': 'LIMONCITO', 'provincia': 'Salta' },
        { 'descripcion': 'LIPEO', 'provincia': 'Salta' },
        { 'descripcion': 'LIPEO , río', 'provincia': 'Salta' },
        { 'descripcion': 'LIZOITE', 'provincia': 'Salta' },
        { 'descripcion': 'LLUCHA', 'provincia': 'Salta' },
        { 'descripcion': 'LLULLAILLACO , cerro', 'provincia': 'Salta' },
        { 'descripcion': 'LOMA DE BURRO', 'provincia': 'Salta' },
        { 'descripcion': 'LOMAS DE OLMEDO', 'provincia': 'Salta' },
        { 'descripcion': 'LORO HUASI', 'provincia': 'Salta' },
        { 'descripcion': 'LOROHUASI', 'provincia': 'Salta' },
        { 'descripcion': 'LOS BANADOS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS BAÑOS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS BLANCOS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS CANTEROS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS CASTILLOS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS CHIFLES', 'provincia': 'Salta' },
        { 'descripcion': 'LOS CHURQUIS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS CORRALES', 'provincia': 'Salta' },
        { 'descripcion': 'LOS LAURELES', 'provincia': 'Salta' },
        { 'descripcion': 'LOS LOS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS MERCADOS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS MOGOTES', 'provincia': 'Salta' },
        { 'descripcion': 'LOS MOLLINEDOS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS NOGALES', 'provincia': 'Salta' },
        { 'descripcion': 'LOS NOQUES', 'provincia': 'Salta' },
        { 'descripcion': 'LOS PATOS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS PATOS ,AP.', 'provincia': 'Salta' },
        { 'descripcion': 'LOS PENONES', 'provincia': 'Salta' },
        { 'descripcion': 'LOS POCITOS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS RANCHILLOS', 'provincia': 'Salta' },
        { 'descripcion': 'LOS ROSALES', 'provincia': 'Salta' },
        { 'descripcion': 'LOS SAUCES', 'provincia': 'Salta' },
        { 'descripcion': 'LOS ZANJONES', 'provincia': 'Salta' },
        { 'descripcion': 'LUIS BURELA', 'provincia': 'Salta' },
        { 'descripcion': 'LUMBRERA', 'provincia': 'Salta' },
        { 'descripcion': 'LUMBRERAS', 'provincia': 'Salta' },
        { 'descripcion': 'LUNA MUERTA', 'provincia': 'Salta' },
        { 'descripcion': 'LURACATAO', 'provincia': 'Salta' },
        { 'descripcion': 'LURACATAO , río', 'provincia': 'Salta' },
        { 'descripcion': 'MACAPILLO', 'provincia': 'Salta' },
        { 'descripcion': 'MACHO RASTROJO', 'provincia': 'Salta' },
        { 'descripcion': 'MACUETA', 'provincia': 'Salta' },
        { 'descripcion': 'MADARIAGA', 'provincia': 'Salta' },
        { 'descripcion': 'MADRE VIEJA', 'provincia': 'Salta' },
        { 'descripcion': 'MAL CANTE', 'provincia': 'Salta' },
        { 'descripcion': 'MANGA VIEJA', 'provincia': 'Salta' },
        { 'descripcion': 'MANUEL ELORDI', 'provincia': 'Salta' },
        { 'descripcion': 'MANUELA PEDRAZA', 'provincia': 'Salta' },
        { 'descripcion': 'MARIA JOSE', 'provincia': 'Salta' },
        { 'descripcion': 'MARTIN GARCIA', 'provincia': 'Salta' },
        { 'descripcion': 'MARTINEZ DEL TINCO', 'provincia': 'Salta' },
        { 'descripcion': 'MARTíNEZ DEL TINEO', 'provincia': 'Salta' },
        { 'descripcion': 'MATANSILLAS', 'provincia': 'Salta' },
        { 'descripcion': 'MATORRAS, JERONIMO', 'provincia': 'Salta' },
        { 'descripcion': 'MAYO TORITO', 'provincia': 'Salta' },
        { 'descripcion': 'MECOYA , cerro', 'provincia': 'Salta' },
        { 'descripcion': 'MECOYITA', 'provincia': 'Salta' },
        { 'descripcion': 'MERCED DE ARRIBA', 'provincia': 'Salta' },
        { 'descripcion': 'MESETA', 'provincia': 'Salta' },
        { 'descripcion': 'MESON', 'provincia': 'Salta' },
        { 'descripcion': 'METAN', 'provincia': 'Salta' },
        { 'descripcion': 'METAN VIEJO', 'provincia': 'Salta' },
        { 'descripcion': 'MINA CAROLINA', 'provincia': 'Salta' },
        { 'descripcion': 'MINA CONCORDIA ,EMB.', 'provincia': 'Salta' },
        { 'descripcion': 'MINA DON OTTO', 'provincia': 'Salta' },
        { 'descripcion': 'MINA JULIO', 'provincia': 'Salta' },
        { 'descripcion': 'MINA LA CASUALIDAD', 'provincia': 'Salta' },
        { 'descripcion': 'MINA SAN ESTEBAN', 'provincia': 'Salta' },
        { 'descripcion': 'MINA SAN GUILLERMO', 'provincia': 'Salta' },
        { 'descripcion': 'MINA SAN WALTERIO', 'provincia': 'Salta' },
        { 'descripcion': 'MINA TINCALAYA', 'provincia': 'Salta' },
        { 'descripcion': 'MINAS VICTORIA', 'provincia': 'Salta' },
        { 'descripcion': 'MINAS YPF', 'provincia': 'Salta' },
        { 'descripcion': 'MIRAFLORES M', 'provincia': 'Salta' },
        { 'descripcion': 'MISION CHAQUENA', 'provincia': 'Salta' },
        { 'descripcion': 'MISION FRANCISCANA', 'provincia': 'Salta' },
        { 'descripcion': 'MISION LA PAZ', 'provincia': 'Salta' },
        { 'descripcion': 'MISION SANTA LUCIA', 'provincia': 'Salta' },
        { 'descripcion': 'MISIONES', 'provincia': 'Salta' },
        { 'descripcion': 'MISTOL MAREADO', 'provincia': 'Salta' },
        { 'descripcion': 'MISTOLAR', 'provincia': 'Salta' },
        { 'descripcion': 'MOJOTORO', 'provincia': 'Salta' },
        { 'descripcion': 'MOLINO', 'provincia': 'Salta' },
        { 'descripcion': 'MOLINO DE GONGORA', 'provincia': 'Salta' },
        { 'descripcion': 'MOLINOS', 'provincia': 'Salta' },
        { 'descripcion': 'MOLLAR', 'provincia': 'Salta' },
        { 'descripcion': 'MOLLE POZO', 'provincia': 'Salta' },
        { 'descripcion': 'MOLLINEDO', 'provincia': 'Salta' },
        { 'descripcion': 'MONASTERIOS', 'provincia': 'Salta' },
        { 'descripcion': 'MONTE', 'provincia': 'Salta' },
        { 'descripcion': 'MONTE CARMELO', 'provincia': 'Salta' },
        { 'descripcion': 'MONTE VIEJO', 'provincia': 'Salta' },
        { 'descripcion': 'MONTEVERDE', 'provincia': 'Salta' },
        { 'descripcion': 'MORALES', 'provincia': 'Salta' },
        { 'descripcion': 'MORENILLO', 'provincia': 'Salta' },
        { 'descripcion': 'MORILLO', 'provincia': 'Salta' },
        { 'descripcion': 'MUÑANO', 'provincia': 'Salta' },
        { 'descripcion': 'NAZARENO', 'provincia': 'Salta' },
        { 'descripcion': 'NAZARENO , río', 'provincia': 'Salta' },
        { 'descripcion': 'NEVADO DE ACAY , cerro', 'provincia': 'Salta' },
        { 'descripcion': 'NEVADO DE CATREAL , sierras', 'provincia': 'Salta' },
        { 'descripcion': 'NEVADO DE CHAÑI , cerro', 'provincia': 'Salta' },
        { 'descripcion': 'NEVADO DEL ACONQUIJA , sierras', 'provincia': 'Salta' },
        { 'descripcion': 'NEVADOS DE CACHI , sierras', 'provincia': 'Salta' },
        { 'descripcion': 'NOGALITO', 'provincia': 'Salta' },
        { 'descripcion': 'NUESTRA SEÑORA DE TALAVERA', 'provincia': 'Salta' },
        { 'descripcion': 'NUEVO PORVENIR', 'provincia': 'Salta' },
        { 'descripcion': 'o CUMBRE DE SAN ANTONIO , sierra', 'provincia': 'Salta' },
        { 'descripcion': 'OLACAPANTO CHICO', 'provincia': 'Salta' },
        { 'descripcion': 'OLACAPANTO GRANDE', 'provincia': 'Salta' },
        { 'descripcion': 'OSMA', 'provincia': 'Salta' },
        { 'descripcion': 'OSMA ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'OTOMANA', 'provincia': 'Salta' },
        { 'descripcion': 'Otra', 'provincia': 'Salta' },
        { 'descripcion': 'OVANDO', 'provincia': 'Salta' },
        { 'descripcion': 'OVEJERO', 'provincia': 'Salta' },
        { 'descripcion': 'P SALVADOR MAZZA EST POCITO', 'provincia': 'Salta' },
        { 'descripcion': 'PADRE LOZANO', 'provincia': 'Salta' },
        { 'descripcion': 'PAL TOLCO', 'provincia': 'Salta' },
        { 'descripcion': 'PALERMO', 'provincia': 'Salta' },
        { 'descripcion': 'PALERMO OESTE', 'provincia': 'Salta' },
        { 'descripcion': 'PALO PINTADO', 'provincia': 'Salta' },
        { 'descripcion': 'PALOMAR', 'provincia': 'Salta' },
        { 'descripcion': 'PALOMITAS', 'provincia': 'Salta' },
        { 'descripcion': 'PAMPA GRANDE', 'provincia': 'Salta' },
        { 'descripcion': 'PAPA CHACRA', 'provincia': 'Salta' },
        { 'descripcion': 'PARAISO', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE CAMPO LARGO', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE CERRO NEGRO', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE COBRES', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE CORTADERAS', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE ESQUINA DE GUARDIA', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE LAS CUEVAS', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE MINA CONCORDIA', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE MORRO COLORADO', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE NACIMIENTOS', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE OLACAPATO', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE PASTOS GRANDES', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE PIRCAS', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE PIZCUNO', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE UNCURU', 'provincia': 'Salta' },
        { 'descripcion': 'PARAJE ZANJON', 'provincia': 'Salta' },
        { 'descripcion': 'PARAMAMAYA', 'provincia': 'Salta' },
        { 'descripcion': 'PARANI', 'provincia': 'Salta' },
        { 'descripcion': 'PAREDES', 'provincia': 'Salta' },
        { 'descripcion': 'PARQUE NACIONAL BARITÚ', 'provincia': 'Salta' },
        { 'descripcion': 'PARQUE NACIONAL EL REY', 'provincia': 'Salta' },
        { 'descripcion': 'PARQUE NACIONAL LOS CARDONES', 'provincia': 'Salta' },
        { 'descripcion': 'PASAJE o JURAMENTO , río', 'provincia': 'Salta' },
        { 'descripcion': 'PASCALLA', 'provincia': 'Salta' },
        { 'descripcion': 'PASCHA', 'provincia': 'Salta' },
        { 'descripcion': 'PASO DE BADERRANA', 'provincia': 'Salta' },
        { 'descripcion': 'PASO DE LA CRUZ', 'provincia': 'Salta' },
        { 'descripcion': 'PASO DE LAS CARRETAS', 'provincia': 'Salta' },
        { 'descripcion': 'PASO DEL DURAZNO', 'provincia': 'Salta' },
        { 'descripcion': 'PASO DEL RIO', 'provincia': 'Salta' },
        { 'descripcion': 'PASO EL MILAGRO SAN ANICETO', 'provincia': 'Salta' },
        { 'descripcion': 'PASO LA CRUZ', 'provincia': 'Salta' },
        { 'descripcion': 'PASO VERDE', 'provincia': 'Salta' },
        { 'descripcion': 'PASTEADERO', 'provincia': 'Salta' },
        { 'descripcion': 'PASTOR SEVILLOSA', 'provincia': 'Salta' },
        { 'descripcion': 'PATRON COSTAS, LUIS', 'provincia': 'Salta' },
        { 'descripcion': 'PAYOGASTA', 'provincia': 'Salta' },
        { 'descripcion': 'PAYOGASTILLA', 'provincia': 'Salta' },
        { 'descripcion': 'PEDRO LOZANO', 'provincia': 'Salta' },
        { 'descripcion': 'PENAFLOR', 'provincia': 'Salta' },
        { 'descripcion': 'PENALVA', 'provincia': 'Salta' },
        { 'descripcion': 'PENAS AZULES', 'provincia': 'Salta' },
        { 'descripcion': 'PENAS BLANCAS', 'provincia': 'Salta' },
        { 'descripcion': 'PICHANAL', 'provincia': 'Salta' },
        { 'descripcion': 'PIE DE LA CUESTA', 'provincia': 'Salta' },
        { 'descripcion': 'PIEDEMONTE', 'provincia': 'Salta' },
        { 'descripcion': 'PIEDRA DEL MOLINO', 'provincia': 'Salta' },
        { 'descripcion': 'PIEDRA DEL POTRILLO', 'provincia': 'Salta' },
        { 'descripcion': 'PIEDRAS MORADAS', 'provincia': 'Salta' },
        { 'descripcion': 'PINAL', 'provincia': 'Salta' },
        { 'descripcion': 'PIQUETE CABADO', 'provincia': 'Salta' },
        { 'descripcion': 'PIQUETE DE ANTA', 'provincia': 'Salta' },
        { 'descripcion': 'PIQUIRENDA', 'provincia': 'Salta' },
        { 'descripcion': 'PIUL', 'provincia': 'Salta' },
        { 'descripcion': 'PIZARRO', 'provincia': 'Salta' },
        { 'descripcion': 'PLUMA DEL PATO', 'provincia': 'Salta' },
        { 'descripcion': 'POBLACION', 'provincia': 'Salta' },
        { 'descripcion': 'POBLACION DE ORTEGA', 'provincia': 'Salta' },
        { 'descripcion': 'POCITOS', 'provincia': 'Salta' },
        { 'descripcion': 'POCITOS o QUIRÓN , salar', 'provincia': 'Salta' },
        { 'descripcion': 'POCOY', 'provincia': 'Salta' },
        { 'descripcion': 'POMPEYA', 'provincia': 'Salta' },
        { 'descripcion': 'PORONGAL', 'provincia': 'Salta' },
        { 'descripcion': 'POSCAYA', 'provincia': 'Salta' },
        { 'descripcion': 'POSO DE ALGARROBO', 'provincia': 'Salta' },
        { 'descripcion': 'POTRERILLO', 'provincia': 'Salta' },
        { 'descripcion': 'POTRERILLOS', 'provincia': 'Salta' },
        { 'descripcion': 'POTRERO', 'provincia': 'Salta' },
        { 'descripcion': 'POTRERO DE CASTILLA', 'provincia': 'Salta' },
        { 'descripcion': 'POTRERO DE DIAZ', 'provincia': 'Salta' },
        { 'descripcion': 'POTRERO DE LINARES', 'provincia': 'Salta' },
        { 'descripcion': 'POTRERO DE POYOGASTA', 'provincia': 'Salta' },
        { 'descripcion': 'POTRERO DE URIBURU', 'provincia': 'Salta' },
        { 'descripcion': 'POZO AZUL', 'provincia': 'Salta' },
        { 'descripcion': 'POZO BERMEJO', 'provincia': 'Salta' },
        { 'descripcion': 'POZO BLANCO', 'provincia': 'Salta' },
        { 'descripcion': 'POZO BRAVO', 'provincia': 'Salta' },
        { 'descripcion': 'POZO CANTADO', 'provincia': 'Salta' },
        { 'descripcion': 'POZO CERCADO', 'provincia': 'Salta' },
        { 'descripcion': 'POZO CERRADO', 'provincia': 'Salta' },
        { 'descripcion': 'POZO DE LA ESQUINA', 'provincia': 'Salta' },
        { 'descripcion': 'POZO DE LA PIEDRA', 'provincia': 'Salta' },
        { 'descripcion': 'POZO DEL CUICO', 'provincia': 'Salta' },
        { 'descripcion': 'POZO DEL GREAL', 'provincia': 'Salta' },
        { 'descripcion': 'POZO DEL PATO', 'provincia': 'Salta' },
        { 'descripcion': 'POZO DEL SAUCE', 'provincia': 'Salta' },
        { 'descripcion': 'POZO DEL ZORRO', 'provincia': 'Salta' },
        { 'descripcion': 'POZO EL ALGARROBO', 'provincia': 'Salta' },
        { 'descripcion': 'POZO GRANDE', 'provincia': 'Salta' },
        { 'descripcion': 'POZO PRINGLES', 'provincia': 'Salta' },
        { 'descripcion': 'POZO VERDE', 'provincia': 'Salta' },
        { 'descripcion': 'POZOS LARGOS', 'provincia': 'Salta' },
        { 'descripcion': 'PRINGLES', 'provincia': 'Salta' },
        { 'descripcion': 'PROFESOR SALVADOR MAZZA , Est. Pocitos', 'provincia': 'Salta' },
        { 'descripcion': 'PUCARA', 'provincia': 'Salta' },
        { 'descripcion': 'PUEBLO VIEJO', 'provincia': 'Salta' },
        { 'descripcion': 'PUENTE DE DIAZ', 'provincia': 'Salta' },
        { 'descripcion': 'PUENTE DE PLATA', 'provincia': 'Salta' },
        { 'descripcion': 'PUENTE INTERNACIONAL', 'provincia': 'Salta' },
        { 'descripcion': 'PUENTE INTERNACIONAL AGUAS BLANCAS', 'provincia': 'Salta' },
        { 'descripcion': 'PUERTA BLANCA', 'provincia': 'Salta' },
        { 'descripcion': 'PUERTA DE DIAZ', 'provincia': 'Salta' },
        { 'descripcion': 'PUERTA DE LA PAYA', 'provincia': 'Salta' },
        { 'descripcion': 'PUERTA DE TASTIL', 'provincia': 'Salta' },
        { 'descripcion': 'PUERTA TASTIL', 'provincia': 'Salta' },
        { 'descripcion': 'PUERTO BAULES', 'provincia': 'Salta' },
        { 'descripcion': 'PUERTO DE DIAZ', 'provincia': 'Salta' },
        { 'descripcion': 'PUERTO LA PAZ', 'provincia': 'Salta' },
        { 'descripcion': 'PUESTO', 'provincia': 'Salta' },
        { 'descripcion': 'PUESTO DE LA VIUDA', 'provincia': 'Salta' },
        { 'descripcion': 'PUESTO DE MOTIJO', 'provincia': 'Salta' },
        { 'descripcion': 'PUESTO DEL MEDIO', 'provincia': 'Salta' },
        { 'descripcion': 'PUESTO DEL PANUELO', 'provincia': 'Salta' },
        { 'descripcion': 'PUESTO VIEJO', 'provincia': 'Salta' },
        { 'descripcion': 'PUIL', 'provincia': 'Salta' },
        { 'descripcion': 'PULARES', 'provincia': 'Salta' },
        { 'descripcion': 'PULI', 'provincia': 'Salta' },
        { 'descripcion': 'PUNCA VISCANA', 'provincia': 'Salta' },
        { 'descripcion': 'PUNCO VISCANA', 'provincia': 'Salta' },
        { 'descripcion': 'PUNTA DE AGUA', 'provincia': 'Salta' },
        { 'descripcion': 'PUNTA DEL AGUA', 'provincia': 'Salta' },
        { 'descripcion': 'QUEBRACHAL', 'provincia': 'Salta' },
        { 'descripcion': 'QUEBRADA DE ESCOIPE', 'provincia': 'Salta' },
        { 'descripcion': 'QUEBRADA DEL AGUA', 'provincia': 'Salta' },
        { 'descripcion': 'QUEBRADA DEL AGUA ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'QUEBRADA DEL TORO', 'provincia': 'Salta' },
        { 'descripcion': 'QUEBRADA MUNANO', 'provincia': 'Salta' },
        { 'descripcion': 'QUENUA', 'provincia': 'Salta' },
        { 'descripcion': 'QUESERA', 'provincia': 'Salta' },
        { 'descripcion': 'QUIJARRO, ANTONIO', 'provincia': 'Salta' },
        { 'descripcion': 'QUISCA GRANDE', 'provincia': 'Salta' },
        { 'descripcion': 'QUISCA LORO', 'provincia': 'Salta' },
        { 'descripcion': 'QUISTO', 'provincia': 'Salta' },
        { 'descripcion': 'RECAREDO', 'provincia': 'Salta' },
        { 'descripcion': 'REDONDO', 'provincia': 'Salta' },
        { 'descripcion': 'RETIRO', 'provincia': 'Salta' },
        { 'descripcion': 'RINCÓN , cerro', 'provincia': 'Salta' },
        { 'descripcion': 'RIO ALEMANIA', 'provincia': 'Salta' },
        { 'descripcion': 'RIO ANCHO', 'provincia': 'Salta' },
        { 'descripcion': 'RIO ANCHO ,AP.', 'provincia': 'Salta' },
        { 'descripcion': 'RIO BLANCO', 'provincia': 'Salta' },
        { 'descripcion': 'RIO CARAPAN', 'provincia': 'Salta' },
        { 'descripcion': 'RIO DE LAS PIEDRAS', 'provincia': 'Salta' },
        { 'descripcion': 'RíO DEL VALLE', 'provincia': 'Salta' },
        { 'descripcion': 'RIO LAVALLEN', 'provincia': 'Salta' },
        { 'descripcion': 'RíO PESCADO', 'provincia': 'Salta' },
        { 'descripcion': 'RíO PIEDRAS', 'provincia': 'Salta' },
        { 'descripcion': 'RIO SECO', 'provincia': 'Salta' },
        { 'descripcion': 'RIO TORO', 'provincia': 'Salta' },
        { 'descripcion': 'RIO URUEÑA', 'provincia': 'Salta' },
        { 'descripcion': 'RIVADAVIA', 'provincia': 'Salta' },
        { 'descripcion': 'ROCA', 'provincia': 'Salta' },
        { 'descripcion': 'RODEO COLORADO', 'provincia': 'Salta' },
        { 'descripcion': 'RODEO PAMPA', 'provincia': 'Salta' },
        { 'descripcion': 'RODEOS', 'provincia': 'Salta' },
        { 'descripcion': 'ROSARIO , río', 'provincia': 'Salta' },
        { 'descripcion': 'ROSARIO DE LA FRONTERA', 'provincia': 'Salta' },
        { 'descripcion': 'ROSARIO DE LERMA', 'provincia': 'Salta' },
        { 'descripcion': 'ROSARIO DEL DORADO', 'provincia': 'Salta' },
        { 'descripcion': 'ROSARIO FUNCA', 'provincia': 'Salta' },
        { 'descripcion': 'RUIZ DE LOS LLANOS', 'provincia': 'Salta' },
        { 'descripcion': 'RUMIHUASI', 'provincia': 'Salta' },
        { 'descripcion': 'RUMIUARCO', 'provincia': 'Salta' },
        { 'descripcion': 'S JOLLIN', 'provincia': 'Salta' },
        { 'descripcion': 'SACHA PERA', 'provincia': 'Salta' },
        { 'descripcion': 'SALADILLO DE JUAREZ', 'provincia': 'Salta' },
        { 'descripcion': 'SALADILLO DE OSMA', 'provincia': 'Salta' },
        { 'descripcion': 'SALADO , río', 'provincia': 'Salta' },
        { 'descripcion': 'SALADO o COLORADO , río', 'provincia': 'Salta' },
        { 'descripcion': 'SALAR DE HOMBRE MUERTO', 'provincia': 'Salta' },
        { 'descripcion': 'SALAR DE POCITOS', 'provincia': 'Salta' },
        { 'descripcion': 'SALAR DE POCITOS ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'SALTA', 'provincia': 'Salta' },
        { 'descripcion': 'SAN AGUSTIN', 'provincia': 'Salta' },
        { 'descripcion': 'SAN ALEJO', 'provincia': 'Salta' },
        { 'descripcion': 'SAN ANTONIO CHICOANA', 'provincia': 'Salta' },
        { 'descripcion': 'SAN ANTONIO DE IRUYA', 'provincia': 'Salta' },
        { 'descripcion': 'SAN ANTONIO DE LOS COBRES', 'provincia': 'Salta' },
        { 'descripcion': 'SAN ANTONIO DE LOS COBRES , río', 'provincia': 'Salta' },
        { 'descripcion': 'SAN ANTONIO LA VINA', 'provincia': 'Salta' },
        { 'descripcion': 'SAN CARLOS', 'provincia': 'Salta' },
        { 'descripcion': 'SAN FERNANDO DE ESCOIPE', 'provincia': 'Salta' },
        { 'descripcion': 'SAN ISIDRO DE IRUYA', 'provincia': 'Salta' },
        { 'descripcion': 'SAN JOSE DE COLTE', 'provincia': 'Salta' },
        { 'descripcion': 'SAN JOSE DE ESCALCHI', 'provincia': 'Salta' },
        { 'descripcion': 'SAN JOSE DE LA VINA', 'provincia': 'Salta' },
        { 'descripcion': 'SAN JOSE DE ORQUERAS', 'provincia': 'Salta' },
        { 'descripcion': 'SAN LAURENCIO', 'provincia': 'Salta' },
        { 'descripcion': 'SAN LEON', 'provincia': 'Salta' },
        { 'descripcion': 'SAN LORENZO', 'provincia': 'Salta' },
        { 'descripcion': 'SAN LUCAS', 'provincia': 'Salta' },
        { 'descripcion': 'SAN MARTIN LA CUESTA', 'provincia': 'Salta' },
        { 'descripcion': 'SAN NICOLAS', 'provincia': 'Salta' },
        { 'descripcion': 'SAN PEDRO', 'provincia': 'Salta' },
        { 'descripcion': 'SAN PEDRO DE ARANDA', 'provincia': 'Salta' },
        { 'descripcion': 'SAN PEDRO DE IRUYA', 'provincia': 'Salta' },
        { 'descripcion': 'SAN PEDRO DE LOS CORRALES', 'provincia': 'Salta' },
        { 'descripcion': 'SAN PEDRO DE SAN ALBERTO', 'provincia': 'Salta' },
        { 'descripcion': 'SAN RAMÓN DE LA NUEVA ORÁN', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA GERTRUDIS', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA MARíA', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA MARíA , río', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA MARINA', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA ROSA DE ANTA', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA ROSA DE LOS PASTOS GRANDES', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA ROSA DE LOS PATOS GRANDE', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA ROSA DE RíO PRIMERO,', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA ROSA DE TASTIL', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA RUFINA', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA VICTORIA', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA VICTORIA', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA VICTORIA , sierra', 'provincia': 'Salta' },
        { 'descripcion': 'SANTA VICTORIA ESTE', 'provincia': 'Salta' },
        { 'descripcion': 'SANTO DOMINGO', 'provincia': 'Salta' },
        { 'descripcion': 'SANTO DOMINGO ANTA', 'provincia': 'Salta' },
        { 'descripcion': 'SANTO DOMINGO SANTA VICTORIA', 'provincia': 'Salta' },
        { 'descripcion': 'SAPO QUEMADO', 'provincia': 'Salta' },
        { 'descripcion': 'SARAVIA, APOLINARIO', 'provincia': 'Salta' },
        { 'descripcion': 'SARGENTO CRISTOBAL', 'provincia': 'Salta' },
        { 'descripcion': 'SAUCE ALEGRE', 'provincia': 'Salta' },
        { 'descripcion': 'SAUCE BAJADA', 'provincia': 'Salta' },
        { 'descripcion': 'SAUCE REDONDO', 'provincia': 'Salta' },
        { 'descripcion': 'SAUCE SOLO', 'provincia': 'Salta' },
        { 'descripcion': 'SAUCELITO', 'provincia': 'Salta' },
        { 'descripcion': 'SAUSALITO', 'provincia': 'Salta' },
        { 'descripcion': 'SAUZAL', 'provincia': 'Salta' },
        { 'descripcion': 'SCHNEIDEWIND', 'provincia': 'Salta' },
        { 'descripcion': 'SECLANTA ADENTRO', 'provincia': 'Salta' },
        { 'descripcion': 'SECLANTÁS', 'provincia': 'Salta' },
        { 'descripcion': 'SECO , río', 'provincia': 'Salta' },
        { 'descripcion': 'SENDA HACHADA', 'provincia': 'Salta' },
        { 'descripcion': 'SENDA HACHADA ESTACION FCGB', 'provincia': 'Salta' },
        { 'descripcion': 'SENILLOSA', 'provincia': 'Salta' },
        { 'descripcion': 'SEVILLAR', 'provincia': 'Salta' },
        { 'descripcion': 'SICO , paso', 'provincia': 'Salta' },
        { 'descripcion': 'SILLA , cerro', 'provincia': 'Salta' },
        { 'descripcion': 'SIMBOL YACO', 'provincia': 'Salta' },
        { 'descripcion': 'SIMBOLITO', 'provincia': 'Salta' },
        { 'descripcion': 'SINUNTO', 'provincia': 'Salta' },
        { 'descripcion': 'SOCOMPA', 'provincia': 'Salta' },
        { 'descripcion': 'SOCOMPA , paso', 'provincia': 'Salta' },
        { 'descripcion': 'SOCOMPA , volcÁn', 'provincia': 'Salta' },
        { 'descripcion': 'SOCOMPA ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'SOLAZUTI', 'provincia': 'Salta' },
        { 'descripcion': 'SOLEDANI', 'provincia': 'Salta' },
        { 'descripcion': 'SUNCHAL', 'provincia': 'Salta' },
        { 'descripcion': 'SUNCHALITO', 'provincia': 'Salta' },
        { 'descripcion': 'SURI MICUNA', 'provincia': 'Salta' },
        { 'descripcion': 'SURI PINTADO', 'provincia': 'Salta' },
        { 'descripcion': 'TABACAL', 'provincia': 'Salta' },
        { 'descripcion': 'TABACAL INGENIO', 'provincia': 'Salta' },
        { 'descripcion': 'TABACO CIMARRON', 'provincia': 'Salta' },
        { 'descripcion': 'TACA TACA ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'TACA TACA ESTACION FCGB', 'provincia': 'Salta' },
        { 'descripcion': 'TACO PAMPA', 'provincia': 'Salta' },
        { 'descripcion': 'TACUARA', 'provincia': 'Salta' },
        { 'descripcion': 'TACUIL', 'provincia': 'Salta' },
        { 'descripcion': 'TACUY', 'provincia': 'Salta' },
        { 'descripcion': 'TALA', 'provincia': 'Salta' },
        { 'descripcion': 'TALA MUYO', 'provincia': 'Salta' },
        { 'descripcion': 'TALA YACO', 'provincia': 'Salta' },
        { 'descripcion': 'TALAPAMPA', 'provincia': 'Salta' },
        { 'descripcion': 'TALAS', 'provincia': 'Salta' },
        { 'descripcion': 'TALAVERA', 'provincia': 'Salta' },
        { 'descripcion': 'TAMAS CORTADAS', 'provincia': 'Salta' },
        { 'descripcion': 'TARTAGAL', 'provincia': 'Salta' },
        { 'descripcion': 'TEDIN, VIRGILIO', 'provincia': 'Salta' },
        { 'descripcion': 'TEUCO , río', 'provincia': 'Salta' },
        { 'descripcion': 'TEUQUITO , arroyo', 'provincia': 'Salta' },
        { 'descripcion': 'TILIAN', 'provincia': 'Salta' },
        { 'descripcion': 'TIPA SOLA', 'provincia': 'Salta' },
        { 'descripcion': 'TIPAYOC', 'provincia': 'Salta' },
        { 'descripcion': 'TITICOITE', 'provincia': 'Salta' },
        { 'descripcion': 'TOBANTIRENDA', 'provincia': 'Salta' },
        { 'descripcion': 'TOLAR CHICO', 'provincia': 'Salta' },
        { 'descripcion': 'TOLAR GRANDE', 'provincia': 'Salta' },
        { 'descripcion': 'TOLLOCHE', 'provincia': 'Salta' },
        { 'descripcion': 'TOLOMBÓN', 'provincia': 'Salta' },
        { 'descripcion': 'TOMUCO', 'provincia': 'Salta' },
        { 'descripcion': 'TONCO', 'provincia': 'Salta' },
        { 'descripcion': 'TONONO', 'provincia': 'Salta' },
        { 'descripcion': 'TORO', 'provincia': 'Salta' },
        { 'descripcion': 'TORO PAMPA', 'provincia': 'Salta' },
        { 'descripcion': 'TOROYOC', 'provincia': 'Salta' },
        { 'descripcion': 'TORZALITO', 'provincia': 'Salta' },
        { 'descripcion': 'TRANQUITAS', 'provincia': 'Salta' },
        { 'descripcion': 'TRES ACEQUIAS', 'provincia': 'Salta' },
        { 'descripcion': 'TRES YUCHANES', 'provincia': 'Salta' },
        { 'descripcion': 'TRIGAL', 'provincia': 'Salta' },
        { 'descripcion': 'TRIGO HUAYCO', 'provincia': 'Salta' },
        { 'descripcion': 'TRUSUCA', 'provincia': 'Salta' },
        { 'descripcion': 'TUCTUCA', 'provincia': 'Salta' },
        { 'descripcion': 'TUNALITO', 'provincia': 'Salta' },
        { 'descripcion': 'TUYUNTI', 'provincia': 'Salta' },
        { 'descripcion': 'UCHOGOL', 'provincia': 'Salta' },
        { 'descripcion': 'UCHUYOC', 'provincia': 'Salta' },
        { 'descripcion': 'UNQUILLAL ,EMB.', 'provincia': 'Salta' },
        { 'descripcion': 'UNQUILLAL EMBARCADERO FCGB', 'provincia': 'Salta' },
        { 'descripcion': 'URIZAR, ESTEBAN DE', 'provincia': 'Salta' },
        { 'descripcion': 'URUNDEL', 'provincia': 'Salta' },
        { 'descripcion': 'VADO HONDO', 'provincia': 'Salta' },
        { 'descripcion': 'VALLE DELGADO', 'provincia': 'Salta' },
        { 'descripcion': 'VALLE ENCANTADO', 'provincia': 'Salta' },
        { 'descripcion': 'VAQUERIA', 'provincia': 'Salta' },
        { 'descripcion': 'VAQUERIA LOS SAUCES', 'provincia': 'Salta' },
        { 'descripcion': 'VAQUEROS', 'provincia': 'Salta' },
        { 'descripcion': 'VEGA DE ARIZARO', 'provincia': 'Salta' },
        { 'descripcion': 'VEGA DE ARZARO ,EST.', 'provincia': 'Salta' },
        { 'descripcion': 'VELARDES', 'provincia': 'Salta' },
        { 'descripcion': 'VENCIDA', 'provincia': 'Salta' },
        { 'descripcion': 'VERA CRUZ', 'provincia': 'Salta' },
        { 'descripcion': 'VESPUCIO', 'provincia': 'Salta' },
        { 'descripcion': 'VIADUCTO EL MUNAL', 'provincia': 'Salta' },
        { 'descripcion': 'VIEJA POZO', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA ALEM', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA AURELIA', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA CORTA', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA FANNY', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA GENERAL GUEMES', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA LOS TARCOS', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA MAYOR ZABALETA', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA PETRONA', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA SAAVEDRA', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA SAN LORENZO', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA SARMIENTO', 'provincia': 'Salta' },
        { 'descripcion': 'VILLA SOLA', 'provincia': 'Salta' },
        { 'descripcion': 'VILLITAS', 'provincia': 'Salta' },
        { 'descripcion': 'VINACO', 'provincia': 'Salta' },
        { 'descripcion': 'VINAL POZO', 'provincia': 'Salta' },
        { 'descripcion': 'VIRGILIO TEDIN', 'provincia': 'Salta' },
        { 'descripcion': 'VIRREY TOLEDO', 'provincia': 'Salta' },
        { 'descripcion': 'VISCACHANI', 'provincia': 'Salta' },
        { 'descripcion': 'VIZCACHERAL', 'provincia': 'Salta' },
        { 'descripcion': 'VIZCARRA', 'provincia': 'Salta' },
        { 'descripcion': 'VOLCAN AZUFRE', 'provincia': 'Salta' },
        { 'descripcion': 'VOLCAN HIGUERAS', 'provincia': 'Salta' },
        { 'descripcion': 'VUELTA DE LAS TOBAS', 'provincia': 'Salta' },
        { 'descripcion': 'VUELTA DE LOS TOBAS', 'provincia': 'Salta' },
        { 'descripcion': 'WEISBURG', 'provincia': 'Salta' },
        { 'descripcion': 'YACARA', 'provincia': 'Salta' },
        { 'descripcion': 'YACAY', 'provincia': 'Salta' },
        { 'descripcion': 'YACERA', 'provincia': 'Salta' },
        { 'descripcion': 'YACIMIENTO TONONO', 'provincia': 'Salta' },
        { 'descripcion': 'YACOCHUYA', 'provincia': 'Salta' },
        { 'descripcion': 'YACONES', 'provincia': 'Salta' },
        { 'descripcion': 'YACUY', 'provincia': 'Salta' },
        { 'descripcion': 'YANCHUYA', 'provincia': 'Salta' },
        { 'descripcion': 'YARIGUARENDA', 'provincia': 'Salta' },
        { 'descripcion': 'YASQUIASME', 'provincia': 'Salta' },
        { 'descripcion': 'YATASTO', 'provincia': 'Salta' },
        { 'descripcion': 'YUCHAN', 'provincia': 'Salta' },
        { 'descripcion': 'ZANJA HONDA', 'provincia': 'Salta' },
        { 'descripcion': 'ZANJON', 'provincia': 'Salta' },
        { 'descripcion': 'ZAPALLITO', 'provincia': 'Salta' },
        { 'descripcion': '9 DE JULIO', 'provincia': 'San Juan' },
        { 'descripcion': 'ACEQUIÓN , río', 'provincia': 'San Juan' },
        { 'descripcion': 'ACERILLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'ADAN QUIROGA', 'provincia': 'San Juan' },
        { 'descripcion': 'AEROPUERTO SAN JUAN', 'provincia': 'San Juan' },
        { 'descripcion': 'AGUA CERCADA', 'provincia': 'San Juan' },
        { 'descripcion': 'AGUA DE LA ZANJA', 'provincia': 'San Juan' },
        { 'descripcion': 'AGUA DE LA ZORRA', 'provincia': 'San Juan' },
        { 'descripcion': 'AGUA DE LOS CABALLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'AGUA Y ENERGIA', 'provincia': 'San Juan' },
        { 'descripcion': 'AGUADA DE LA PENA', 'provincia': 'San Juan' },
        { 'descripcion': 'AGUADITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'AGUADITAS DEL RIO JACHAL', 'provincia': 'San Juan' },
        { 'descripcion': 'AGUANGO', 'provincia': 'San Juan' },
        { 'descripcion': 'AGUAS DEL PAJARO', 'provincia': 'San Juan' },
        { 'descripcion': 'ALAMITO', 'provincia': 'San Juan' },
        { 'descripcion': 'ALBARDON', 'provincia': 'San Juan' },
        { 'descripcion': 'ALBARRACIN', 'provincia': 'San Juan' },
        { 'descripcion': 'ALCAUCHA', 'provincia': 'San Juan' },
        { 'descripcion': 'ALGARROBO DEL CURA', 'provincia': 'San Juan' },
        { 'descripcion': 'ALGARROBO GRANDE', 'provincia': 'San Juan' },
        { 'descripcion': 'ALGARROBO VERDE', 'provincia': 'San Juan' },
        { 'descripcion': 'ALTO DE SIERRA', 'provincia': 'San Juan' },
        { 'descripcion': 'ALTO HUACO', 'provincia': 'San Juan' },
        { 'descripcion': 'AMARFIL', 'provincia': 'San Juan' },
        { 'descripcion': 'AMBAS PUNTILLAS', 'provincia': 'San Juan' },
        { 'descripcion': 'AMPACAMA', 'provincia': 'San Juan' },
        { 'descripcion': 'ANGACO NORTE', 'provincia': 'San Juan' },
        { 'descripcion': 'ANGACO NORTE ,EST', 'provincia': 'San Juan' },
        { 'descripcion': 'ANGACO SUD', 'provincia': 'San Juan' },
        { 'descripcion': 'ANGUALASTO', 'provincia': 'San Juan' },
        { 'descripcion': 'APEADERO GUANACACHE', 'provincia': 'San Juan' },
        { 'descripcion': 'APEADERO LAS CHIMBAS', 'provincia': 'San Juan' },
        { 'descripcion': 'APEADERO QUIROGA', 'provincia': 'San Juan' },
        { 'descripcion': 'ARREQUINTIN', 'provincia': 'San Juan' },
        { 'descripcion': 'ASCHICHUSCA', 'provincia': 'San Juan' },
        { 'descripcion': 'ASTICA', 'provincia': 'San Juan' },
        { 'descripcion': 'ATUTIA , río', 'provincia': 'San Juan' },
        { 'descripcion': 'AURORA', 'provincia': 'San Juan' },
        { 'descripcion': 'AZUCARERA DE CUYO', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDE DE LEYES', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDE DEL LUCERO', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDE DEL NORTE', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDE DEL ROSARIO', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDE PLUMERITO', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDE SAN CARLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDECITO', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDECITO DEL MORADO', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDES DE LA CHILCA', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDES DEL SUD', 'provincia': 'San Juan' },
        { 'descripcion': 'BALDES DEL TARABAY', 'provincia': 'San Juan' },
        { 'descripcion': 'BAÑO CENTENARIO', 'provincia': 'San Juan' },
        { 'descripcion': 'BANOS CENTENARIO', 'provincia': 'San Juan' },
        { 'descripcion': 'BANOS DE LA LAJA', 'provincia': 'San Juan' },
        { 'descripcion': 'BANOS DE LOS DESPOBLADOS', 'provincia': 'San Juan' },
        { 'descripcion': 'BANOS DE SAN CRISPIN', 'provincia': 'San Juan' },
        { 'descripcion': 'BANOS DEL CERRO', 'provincia': 'San Juan' },
        { 'descripcion': 'BANOS DEL SALADO', 'provincia': 'San Juan' },
        { 'descripcion': 'BANOS PISMANTA', 'provincia': 'San Juan' },
        { 'descripcion': 'BARRANCA DE LOS LOROS', 'provincia': 'San Juan' },
        { 'descripcion': 'BARRANCAS BLANCAS', 'provincia': 'San Juan' },
        { 'descripcion': 'BARREAL', 'provincia': 'San Juan' },
        { 'descripcion': 'BARREALES', 'provincia': 'San Juan' },
        { 'descripcion': 'BARREALITO', 'provincia': 'San Juan' },
        { 'descripcion': 'BARRIALITO', 'provincia': 'San Juan' },
        { 'descripcion': 'BARRIALITOS', 'provincia': 'San Juan' },
        { 'descripcion': 'BARRIO AGUA Y ENERGIA', 'provincia': 'San Juan' },
        { 'descripcion': 'BARRIO COLON', 'provincia': 'San Juan' },
        { 'descripcion': 'BARRIO GRAFFIGNA', 'provincia': 'San Juan' },
        { 'descripcion': 'BARRIO RAWSON', 'provincia': 'San Juan' },
        { 'descripcion': 'BARRIO RIVADAVIA', 'provincia': 'San Juan' },
        { 'descripcion': 'BARRIO SANTA BARBARA', 'provincia': 'San Juan' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'San Juan' },
        { 'descripcion': 'BERMEJO', 'provincia': 'San Juan' },
        { 'descripcion': 'BERMEJO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'BLANCO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'BLANCO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'BOCA DE LA QUEBRADA', 'provincia': 'San Juan' },
        { 'descripcion': 'BODEGA GRAFFIGNA', 'provincia': 'San Juan' },
        { 'descripcion': 'BODEGA SAN ANTONIO', 'provincia': 'San Juan' },
        { 'descripcion': 'BRAMADERO , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'BUENA ESPERANZA', 'provincia': 'San Juan' },
        { 'descripcion': 'CABECERA DEL BARRIAL', 'provincia': 'San Juan' },
        { 'descripcion': 'CABEZA DEL TORO', 'provincia': 'San Juan' },
        { 'descripcion': 'CACHO ANCHO', 'provincia': 'San Juan' },
        { 'descripcion': 'CAJON DE LOS TAMBILLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'CALIBAR', 'provincia': 'San Juan' },
        { 'descripcion': 'CALINGASTA', 'provincia': 'San Juan' },
        { 'descripcion': 'CALINGASTA , río', 'provincia': 'San Juan' },
        { 'descripcion': 'CALLE AGUILERAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CALLE LARGA', 'provincia': 'San Juan' },
        { 'descripcion': 'CALLE NACIONAL', 'provincia': 'San Juan' },
        { 'descripcion': 'CALLECITA', 'provincia': 'San Juan' },
        { 'descripcion': 'CAMP D P V LA CIENAGA', 'provincia': 'San Juan' },
        { 'descripcion': 'CAMPANARIO NUEVO', 'provincia': 'San Juan' },
        { 'descripcion': 'CAMPO AFUERA', 'provincia': 'San Juan' },
        { 'descripcion': 'CAMPO DE BATALLA', 'provincia': 'San Juan' },
        { 'descripcion': 'CAMPO DEL LEONCITO', 'provincia': 'San Juan' },
        { 'descripcion': 'CAMPO LAS LIEBRES', 'provincia': 'San Juan' },
        { 'descripcion': 'CAMPO LOS POZOS', 'provincia': 'San Juan' },
        { 'descripcion': 'CANADA', 'provincia': 'San Juan' },
        { 'descripcion': 'CANADA DE LAGUNA', 'provincia': 'San Juan' },
        { 'descripcion': 'CANADA DEL POZO', 'provincia': 'San Juan' },
        { 'descripcion': 'CAÑADA HONDA', 'provincia': 'San Juan' },
        { 'descripcion': 'CAPITAN LAZO', 'provincia': 'San Juan' },
        { 'descripcion': 'CARBOMETAL', 'provincia': 'San Juan' },
        { 'descripcion': 'CARPINTERíA', 'provincia': 'San Juan' },
        { 'descripcion': 'CARRIZALITO', 'provincia': 'San Juan' },
        { 'descripcion': 'CASA DE JAVIER', 'provincia': 'San Juan' },
        { 'descripcion': 'CASA DE PIEDRA , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'CASA VIEJA', 'provincia': 'San Juan' },
        { 'descripcion': 'CASTANO', 'provincia': 'San Juan' },
        { 'descripcion': 'CASTAÑO NUEVO', 'provincia': 'San Juan' },
        { 'descripcion': 'CASTAÑO VIEJO', 'provincia': 'San Juan' },
        { 'descripcion': 'CASTAÑO VIEJO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'CAUCETE', 'provincia': 'San Juan' },
        { 'descripcion': 'CENTRO AVIACION CIVIL SAN JUAN', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO AGUADITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO AGUILA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO ALTO DEL DESCUBRIMIENTO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO AMARILLO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO ASILAN', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO ASPERO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO BAYO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO BLANCO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO BOLA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO BOLEADORA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO BONETE', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO BRAMADERO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO BRAVO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO CABALLO ANCA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO CABALLO BAYO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO CASA DE PIEDRA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO CHIQUERO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO CORTADERA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO D L BANITOS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DE CONCONTA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DE DOLORES', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DE LA CUESTA DEL VIENTO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DE LA SEPULTURA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DE LAS VACAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DE LOS BURROS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DE LOS CABALLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DE LOS POZOS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DEL AGUA DE LAS VACAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DEL ALUMBRE', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DEL CACHIYUYAL', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DEL COQUIMBITO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DEL GUANACO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DEL MEDIO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DEL SALADO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DEL TOME', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO DIVOSADERO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO EL BRONCE', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO EL CEPO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO EL DURAZNO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO EL FRANCES', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO ESPANTAJO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO GRANDE', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO GUANAQUERO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO HEDIONDO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO HORNITO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO IMAN', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO INFIERNILLO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO JAGUEL', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO JOAQUIN', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LA BOLSA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LA CANADA AMARILLA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LA CIENAGA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LA COLORADA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LA FLECHA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LA FORTUNA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LA JARILLA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LA ORTIGA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LA RINCONADA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LA VENTANITA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LAGUNITA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LAJITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LAS BARRANCAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LAS MULAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LAS MULITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LAS PLACETAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LAS RAICES', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LAS YEGUAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LAVADEROS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LOS MOGOTES', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LOS PATOS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO LOS POZOS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO MERCEDARIO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO MUDADERO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO NEGRO DE CHITA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO NEGRO DEL CORRAL', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO NICO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO OCUCAROS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO PACHACO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO PANTEON', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO PATA DE INDIO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO PICHEREGUAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO PINTADO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO PIRCAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO POTRERITO DE AGUA BLANCO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO POTRERO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO PUNTUDO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO RIQUILIPONCHE', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO SANTA ROSA', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO SASITO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO SENDA AZUL', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO SILVIO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO SILVO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO TAMBOLAR', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO TIGRE', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO TRES MOGOTES', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO TRES PUNTAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO VILLA LONCITO', 'provincia': 'San Juan' },
        { 'descripcion': 'CERRO VILLICUN', 'provincia': 'San Juan' },
        { 'descripcion': 'CHAMPONES', 'provincia': 'San Juan' },
        { 'descripcion': 'CHANAR PINTADO', 'provincia': 'San Juan' },
        { 'descripcion': 'CHANAR SECO', 'provincia': 'San Juan' },
        { 'descripcion': 'CHANCHOS', 'provincia': 'San Juan' },
        { 'descripcion': 'CHAPARRO', 'provincia': 'San Juan' },
        { 'descripcion': 'CHEPICAL', 'provincia': 'San Juan' },
        { 'descripcion': 'CHICA NEGRA', 'provincia': 'San Juan' },
        { 'descripcion': 'CHIGUA', 'provincia': 'San Juan' },
        { 'descripcion': 'CHIGUA DE ABAJO', 'provincia': 'San Juan' },
        { 'descripcion': 'CHIMBAS', 'provincia': 'San Juan' },
        { 'descripcion': 'CHISNASCO', 'provincia': 'San Juan' },
        { 'descripcion': 'CHUCUMA', 'provincia': 'San Juan' },
        { 'descripcion': 'CIENAGA', 'provincia': 'San Juan' },
        { 'descripcion': 'CIENAGUILLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'CIENAGUITA', 'provincia': 'San Juan' },
        { 'descripcion': 'COCHAGUAL', 'provincia': 'San Juan' },
        { 'descripcion': 'COLANGUIL', 'provincia': 'San Juan' },
        { 'descripcion': 'COLANQUI', 'provincia': 'San Juan' },
        { 'descripcion': 'COLL', 'provincia': 'San Juan' },
        { 'descripcion': 'COLOLA', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA CANTONI', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA CASTRO PADIN', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA CENTENARIO', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA EL MOLINO', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA FIORITO', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA FISCAL SARMIENTO', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA FLORIDA', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA GUTIERREZ', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA JUAN SOLARI', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA MOYA', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA RICHET', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA ROCA', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA RODAS', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA RODRIGUEZ ZAVALLA', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA SAN ANTONIO', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA YORNER', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA ZABALA', 'provincia': 'San Juan' },
        { 'descripcion': 'COLONIA ZAPATA', 'provincia': 'San Juan' },
        { 'descripcion': 'COLORADO , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'COLORADO , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'COLORADO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'CONCOTA', 'provincia': 'San Juan' },
        { 'descripcion': 'CONDOR MUERTO', 'provincia': 'San Juan' },
        { 'descripcion': 'CONTEGRAND', 'provincia': 'San Juan' },
        { 'descripcion': 'CORRAL DE PIRCA', 'provincia': 'San Juan' },
        { 'descripcion': 'CORRALITOS , mogote', 'provincia': 'San Juan' },
        { 'descripcion': 'COYON', 'provincia': 'San Juan' },
        { 'descripcion': 'CRUZ DE SAN PEDRO', 'provincia': 'San Juan' },
        { 'descripcion': 'CUCHILLAZO', 'provincia': 'San Juan' },
        { 'descripcion': 'CUESTA VIEJO', 'provincia': 'San Juan' },
        { 'descripcion': 'CULEBRA', 'provincia': 'San Juan' },
        { 'descripcion': 'CUMIYANGO', 'provincia': 'San Juan' },
        { 'descripcion': 'CUYO', 'provincia': 'San Juan' },
        { 'descripcion': 'CUYO ,EST.', 'provincia': 'San Juan' },
        { 'descripcion': 'de ANSILTA , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de ANSILTA , río', 'provincia': 'San Juan' },
        { 'descripcion': 'de ANTOFALLA , salar', 'provincia': 'San Juan' },
        { 'descripcion': 'de ANTOFALLA , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'de CALINGASTA , valle', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA BREA , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA HUERTA , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA INVERNADA , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA LAGUNA , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA LUNA o ISCHIGUALASTO , valle', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA ORTIGA , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA PALCA , río', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA RAMADA , cordÓn', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA SAL , río', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA TOTORA , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA TOTORA , cordÓn', 'provincia': 'San Juan' },
        { 'descripcion': 'de LA VARILLA , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de LAS CARACHAS , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de LAS LAGUNAS , río', 'provincia': 'San Juan' },
        { 'descripcion': 'de LAS LAJITAS o del INFIERNILLO , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'de LAS MULAS , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'de LAS OJOTAS , paso', 'provincia': 'San Juan' },
        { 'descripcion': 'de LAS OVEJAS , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'de LAS PAVAS , río', 'provincia': 'San Juan' },
        { 'descripcion': 'de LAS PIEDRAS , río', 'provincia': 'San Juan' },
        { 'descripcion': 'de LAS PUNTAS NEGRAS , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de LAS TAGUAS , río', 'provincia': 'San Juan' },
        { 'descripcion': 'de LOS BAÑITOS , paso', 'provincia': 'San Juan' },
        { 'descripcion': 'de LOS PATOS , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'de LOS PATOS , rio', 'provincia': 'San Juan' },
        { 'descripcion': 'de LOS PIUQUENES , paso', 'provincia': 'San Juan' },
        { 'descripcion': 'de MOGNA , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'de OLIVARES , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de POTRERILLO , paso', 'provincia': 'San Juan' },
        { 'descripcion': 'de SAN GUILLERMO , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de SANTA CRUZ , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de SANTA ROSA , cordillera', 'provincia': 'San Juan' },
        { 'descripcion': 'de TALACASTO , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'de VALERIANO o de LA COIPA , paso', 'provincia': 'San Juan' },
        { 'descripcion': 'de VILLICUM , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'del AGUA NEGRA , paso', 'provincia': 'San Juan' },
        { 'descripcion': 'del DIABLO , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'del INCA , paso', 'provincia': 'San Juan' },
        { 'descripcion': 'del MACHO MUERTO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'del RíO BERMEJO , valle', 'provincia': 'San Juan' },
        { 'descripcion': 'del TIGRE , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'del TONTAL , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'del VALLE FÉRTIL , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'DEL VENTILLO , Portezuelo', 'provincia': 'San Juan' },
        { 'descripcion': 'del VOLCÁN , paso', 'provincia': 'San Juan' },
        { 'descripcion': 'del VOLCÁN , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'DESAMPARADOS', 'provincia': 'San Juan' },
        { 'descripcion': 'DIAZ VELEZ', 'provincia': 'San Juan' },
        { 'descripcion': 'DIFUNTA CORREA', 'provincia': 'San Juan' },
        { 'descripcion': 'DIQUE CAUQUENES', 'provincia': 'San Juan' },
        { 'descripcion': 'DIQUE LAS CRUCECITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'DIQUE LOS CAUQUENES', 'provincia': 'San Juan' },
        { 'descripcion': 'DIQUE SOLDANO', 'provincia': 'San Juan' },
        { 'descripcion': 'DIQUE TOMA', 'provincia': 'San Juan' },
        { 'descripcion': 'DIVISADERO', 'provincia': 'San Juan' },
        { 'descripcion': 'DIVISADERO DE LA MUJER HELADA', 'provincia': 'San Juan' },
        { 'descripcion': 'DIVISORIA', 'provincia': 'San Juan' },
        { 'descripcion': 'DOMINGO DE ORO', 'provincia': 'San Juan' },
        { 'descripcion': 'DOS ACEQUIAS', 'provincia': 'San Juan' },
        { 'descripcion': 'DOS MOJONES', 'provincia': 'San Juan' },
        { 'descripcion': 'DOS PUENTES', 'provincia': 'San Juan' },
        { 'descripcion': 'ECHAGUE, PEDRO ,EST.', 'provincia': 'San Juan' },
        { 'descripcion': 'EL ABANICO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL BALDE', 'provincia': 'San Juan' },
        { 'descripcion': 'EL BARRIALITO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL BOSQUE', 'provincia': 'San Juan' },
        { 'descripcion': 'EL BUEN RETIRO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL CARRIZAL', 'provincia': 'San Juan' },
        { 'descripcion': 'EL CHACRERO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL CHAMIZUDO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL CHILOTE', 'provincia': 'San Juan' },
        { 'descripcion': 'EL CHINGUILLO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL CHUPINO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL CORRALITO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL FICAL', 'provincia': 'San Juan' },
        { 'descripcion': 'EL FUERTE', 'provincia': 'San Juan' },
        { 'descripcion': 'EL GIGANTILLO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL INFIERNO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL JABONCITO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL LECHUZO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL LEONCITO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL MEDANITO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL MEDANO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL MOGOTE CHIMBAS', 'provincia': 'San Juan' },
        { 'descripcion': 'EL PLUMERITO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL POZO DEL 20', 'provincia': 'San Juan' },
        { 'descripcion': 'EL PUERTO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL RINCON', 'provincia': 'San Juan' },
        { 'descripcion': 'EL SALADO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL SALITRE', 'provincia': 'San Juan' },
        { 'descripcion': 'EL SALTO', 'provincia': 'San Juan' },
        { 'descripcion': 'EL TAPON', 'provincia': 'San Juan' },
        { 'descripcion': 'EL TREINTA', 'provincia': 'San Juan' },
        { 'descripcion': 'EMBALSE DE ULLUM', 'provincia': 'San Juan' },
        { 'descripcion': 'ENCON', 'provincia': 'San Juan' },
        { 'descripcion': 'ENTRE RIOS', 'provincia': 'San Juan' },
        { 'descripcion': 'EST ALBARDON', 'provincia': 'San Juan' },
        { 'descripcion': 'EST DE HERRERA VEGAS', 'provincia': 'San Juan' },
        { 'descripcion': 'EST LA CIENAGA DE GUALILA', 'provincia': 'San Juan' },
        { 'descripcion': 'EST MARAYES', 'provincia': 'San Juan' },
        { 'descripcion': 'EST NIQUIVIL', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTACION LA RINCONADA', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTACIÓN POCITO', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA ACEQUION', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA BAJO DE LAS TUMANAS', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA CASA RIO BLANCO', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA EL CHANAR NUEVO', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA EL DURAZNO', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA EL JUMEAL', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA EL MOLINO', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA EL POLEAR', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA EL TOTORAL', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA ELIZONDO', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA LA ESCALERA', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA LA FLORIDA', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA LA LATA', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA LA POSTA', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA LA PUNTILLA', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA LEONCITO', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA MARADONA', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA QUIROGA', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA RIO VERDE', 'provincia': 'San Juan' },
        { 'descripcion': 'ESTANCIA SAN ROQUE', 'provincia': 'San Juan' },
        { 'descripcion': 'FIERRO', 'provincia': 'San Juan' },
        { 'descripcion': 'FIERRO NUEVO', 'provincia': 'San Juan' },
        { 'descripcion': 'FIERRO VIEJO', 'provincia': 'San Juan' },
        { 'descripcion': 'FILO DEL MOCHO', 'provincia': 'San Juan' },
        { 'descripcion': 'FILO DEL MOCHO , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'FINCA DE IZASA', 'provincia': 'San Juan' },
        { 'descripcion': 'FINCA DEL JAPONES', 'provincia': 'San Juan' },
        { 'descripcion': 'FINCA EL MOLINO', 'provincia': 'San Juan' },
        { 'descripcion': 'FINCA EL TORO', 'provincia': 'San Juan' },
        { 'descripcion': 'FINCA ZAPATA', 'provincia': 'San Juan' },
        { 'descripcion': 'GENDARMERIA NACIONAL', 'provincia': 'San Juan' },
        { 'descripcion': 'GRAN CHINA', 'provincia': 'San Juan' },
        { 'descripcion': 'GUACHI , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'GUACHIPAMPA', 'provincia': 'San Juan' },
        { 'descripcion': 'GUAJA', 'provincia': 'San Juan' },
        { 'descripcion': 'GUANACACHE', 'provincia': 'San Juan' },
        { 'descripcion': 'GUAYAGUAS', 'provincia': 'San Juan' },
        { 'descripcion': 'GUAYAMAS', 'provincia': 'San Juan' },
        { 'descripcion': 'HACHANGO', 'provincia': 'San Juan' },
        { 'descripcion': 'HILARIO', 'provincia': 'San Juan' },
        { 'descripcion': 'HOSTERIA EL BALDE', 'provincia': 'San Juan' },
        { 'descripcion': 'HUACO', 'provincia': 'San Juan' },
        { 'descripcion': 'HUACO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'HUAICOS', 'provincia': 'San Juan' },
        { 'descripcion': 'HUANIZUIL', 'provincia': 'San Juan' },
        { 'descripcion': 'HUERTA DE GUACHI', 'provincia': 'San Juan' },
        { 'descripcion': 'HUERTA DE HUACHI', 'provincia': 'San Juan' },
        { 'descripcion': 'HUESO QUEBRADO', 'provincia': 'San Juan' },
        { 'descripcion': 'ICHIGUALASTO', 'provincia': 'San Juan' },
        { 'descripcion': 'IGLESIA', 'provincia': 'San Juan' },
        { 'descripcion': 'IGLESIA , arroyo', 'provincia': 'San Juan' },
        { 'descripcion': 'IMSA', 'provincia': 'San Juan' },
        { 'descripcion': 'INDIO MUERTO', 'provincia': 'San Juan' },
        { 'descripcion': 'INGENIERO MATIAS G SANCHEZ', 'provincia': 'San Juan' },
        { 'descripcion': 'INGENIERO MATIAS G.SANCHEZ ,EST.', 'provincia': 'San Juan' },
        { 'descripcion': 'ISCHIGUALASTO', 'provincia': 'San Juan' },
        { 'descripcion': 'ISLA DEL SAUCE', 'provincia': 'San Juan' },
        { 'descripcion': 'JACHAL', 'provincia': 'San Juan' },
        { 'descripcion': 'JÁCHAL , río', 'provincia': 'San Juan' },
        { 'descripcion': 'JAG', 'provincia': 'San Juan' },
        { 'descripcion': 'JAG', 'provincia': 'San Juan' },
        { 'descripcion': 'JAGUELITO', 'provincia': 'San Juan' },
        { 'descripcion': 'JARILLA CHICA', 'provincia': 'San Juan' },
        { 'descripcion': 'JARILLITO', 'provincia': 'San Juan' },
        { 'descripcion': 'JOSE MARTI', 'provincia': 'San Juan' },
        { 'descripcion': 'JUAN CELANI', 'provincia': 'San Juan' },
        { 'descripcion': 'JUNTA DE SANTA ROSA', 'provincia': 'San Juan' },
        { 'descripcion': 'JUNTAS DE LA JARILLA', 'provincia': 'San Juan' },
        { 'descripcion': 'JUNTAS DE LA SAL', 'provincia': 'San Juan' },
        { 'descripcion': 'JUNTAS DEL FRIO', 'provincia': 'San Juan' },
        { 'descripcion': 'JUNTAS DEL GUANDACOL', 'provincia': 'San Juan' },
        { 'descripcion': 'KILOMETRO 810', 'provincia': 'San Juan' },
        { 'descripcion': 'KILOMETRO 936 ,EMB.', 'provincia': 'San Juan' },
        { 'descripcion': 'LA ALUMBRERA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA BEBIDA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA CALLECITA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA CAÑADA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA CARPA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA CERCADA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA CHIGUA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA CHILCA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA CHIMBERA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA CIENAGA DE CUMILLANGO', 'provincia': 'San Juan' },
        { 'descripcion': 'LA CIENEGUITA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA COLONIA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA COSECHERA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA ESTACA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA ESTRECHURA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA ESTRELLA , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'LA GERMANIA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA HUERTA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA ISLA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA LAJA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA LEGUA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA MAJADITA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA MARAL', 'provincia': 'San Juan' },
        { 'descripcion': 'LA MESADA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA MORAL', 'provincia': 'San Juan' },
        { 'descripcion': 'LA ORILLA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA ORQUETA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA ORTIGA , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'LA OVERA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA RINCONADA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA RIPIERA', 'provincia': 'San Juan' },
        { 'descripcion': 'LA SAL', 'provincia': 'San Juan' },
        { 'descripcion': 'LA VALENTINA', 'provincia': 'San Juan' },
        { 'descripcion': 'LAGUNA DEL ROSARIO', 'provincia': 'San Juan' },
        { 'descripcion': 'LAGUNA SECA', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS AGUADITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS CASITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS CASUARINAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS CHACRITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS CHIMBAS ,AP.', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS CIENAGAS VERDES', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS DELICIAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS ESPINAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS FLORES', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS HIGUERITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS HORNILLAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS JUNTAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS LAGUNAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS LIEBRES', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS LOMITAS , Est. AlbardÓn', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS PENITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS PUESTAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS RAMADITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS SALINAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS SALINAS ,DV.', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS TAPIAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS TÓRTOLAS , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS TUMANAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LAS YEGUAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LLOVERAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOMA ANCHA', 'provincia': 'San Juan' },
        { 'descripcion': 'LOMA DE COCHO', 'provincia': 'San Juan' },
        { 'descripcion': 'LOMA LEONES', 'provincia': 'San Juan' },
        { 'descripcion': 'LOMAS DEL AGUADITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS ANGACOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS BALDECITOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS BALDES DE ASTICA', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS BARRIALES', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS BERROS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS CHAVES', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS COGOTES', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS COMPARTOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS CORREDORES', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS DIAGUITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS HORNOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS LAGARES', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS LOROS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS MEDANOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS MELLIZOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS MOGOTES , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS PAPAGAYOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS PARAMILLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS PENITENTES', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS QUILLAY', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS QUIMBALETES', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS RANCHOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS RINCONES', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS SANCHEZ', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS SAPITOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS SOMBREROS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS TERREMOTOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS VINEDOS', 'provincia': 'San Juan' },
        { 'descripcion': 'LOS VIÑEDOS ,AP.', 'provincia': 'San Juan' },
        { 'descripcion': 'LUZ DEL MUNDO', 'provincia': 'San Juan' },
        { 'descripcion': 'MACATACLASTO', 'provincia': 'San Juan' },
        { 'descripcion': 'MACLACASTO', 'provincia': 'San Juan' },
        { 'descripcion': 'MAIPIRINQUI', 'provincia': 'San Juan' },
        { 'descripcion': 'MALIMAN', 'provincia': 'San Juan' },
        { 'descripcion': 'MALIMAN ARRIBA', 'provincia': 'San Juan' },
        { 'descripcion': 'MALIMAN DE ABAJO', 'provincia': 'San Juan' },
        { 'descripcion': 'MARAYES', 'provincia': 'San Juan' },
        { 'descripcion': 'MARQUESADO', 'provincia': 'San Juan' },
        { 'descripcion': 'MATAGUSANOS', 'provincia': 'San Juan' },
        { 'descripcion': 'MEDANO COLORADO', 'provincia': 'San Juan' },
        { 'descripcion': 'MEDANO DE ORO', 'provincia': 'San Juan' },
        { 'descripcion': 'MEDIA AGUA', 'provincia': 'San Juan' },
        { 'descripcion': 'MERCEDARIO , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'MESADA AGUADA', 'provincia': 'San Juan' },
        { 'descripcion': 'MICA', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA DE GUACHI', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA DE LAS CARACHAS', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA EL ALGARROBO', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA EL PESCADO', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA ESCONDIDA', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA GENERAL BELGRANO', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA GUALILAN', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA LA ABUNDANCIA', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA LA DELFINA', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA LA ESPERANZA', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA LA SALAMANTA', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA LOS CABALLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA MONTOSA', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA SAN ANTONIO', 'provincia': 'San Juan' },
        { 'descripcion': 'MINA SAN JORGE', 'provincia': 'San Juan' },
        { 'descripcion': 'MOGNA', 'provincia': 'San Juan' },
        { 'descripcion': 'MONDACA', 'provincia': 'San Juan' },
        { 'descripcion': 'MORTERITO', 'provincia': 'San Juan' },
        { 'descripcion': 'NAQUERA', 'provincia': 'San Juan' },
        { 'descripcion': 'NEGRA , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'NEGRO , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'NEGRO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'NIKISANGA', 'provincia': 'San Juan' },
        { 'descripcion': 'NIQUIVIL', 'provincia': 'San Juan' },
        { 'descripcion': 'NIQUIVIL NUEVO', 'provincia': 'San Juan' },
        { 'descripcion': 'NIQUIVIL VIEJO', 'provincia': 'San Juan' },
        { 'descripcion': 'NUEVA CASTILLA', 'provincia': 'San Juan' },
        { 'descripcion': 'NUEVA CASTILLA ,DV.', 'provincia': 'San Juan' },
        { 'descripcion': 'ORO, DOMINGO DE ,EST.', 'provincia': 'San Juan' },
        { 'descripcion': 'Otra', 'provincia': 'San Juan' },
        { 'descripcion': 'OTRA BANDA', 'provincia': 'San Juan' },
        { 'descripcion': 'PACHACO', 'provincia': 'San Juan' },
        { 'descripcion': 'PACHIMOCO', 'provincia': 'San Juan' },
        { 'descripcion': 'PAMPA DE LOS CABALLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'PAMPA DEL CHAÑAR', 'provincia': 'San Juan' },
        { 'descripcion': 'PAMPA VIEJA', 'provincia': 'San Juan' },
        { 'descripcion': 'PAMPITA', 'provincia': 'San Juan' },
        { 'descripcion': 'PANACAN', 'provincia': 'San Juan' },
        { 'descripcion': 'PAQUITA', 'provincia': 'San Juan' },
        { 'descripcion': 'PARAJE BEBIDA', 'provincia': 'San Juan' },
        { 'descripcion': 'PARAMILLO , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'PASLEAM', 'provincia': 'San Juan' },
        { 'descripcion': 'PASO DE FERREIRA', 'provincia': 'San Juan' },
        { 'descripcion': 'PASO DE LAMAS', 'provincia': 'San Juan' },
        { 'descripcion': 'PASO DE OTAROLA', 'provincia': 'San Juan' },
        { 'descripcion': 'PASO DEL AGUA NEGRA', 'provincia': 'San Juan' },
        { 'descripcion': 'PASO DEL LAMAR', 'provincia': 'San Juan' },
        { 'descripcion': 'PEDERNAL', 'provincia': 'San Juan' },
        { 'descripcion': 'PEDRO ECHAGUE', 'provincia': 'San Juan' },
        { 'descripcion': 'PENASCO COLORADO', 'provincia': 'San Juan' },
        { 'descripcion': 'PENASQUITO', 'provincia': 'San Juan' },
        { 'descripcion': 'PICHAGUAL', 'provincia': 'San Juan' },
        { 'descripcion': 'PIE DE PALO', 'provincia': 'San Juan' },
        { 'descripcion': 'PIE DE PALO , sierra', 'provincia': 'San Juan' },
        { 'descripcion': 'PIEDRA COLORADA', 'provincia': 'San Juan' },
        { 'descripcion': 'PIEDRA RAJADA', 'provincia': 'San Juan' },
        { 'descripcion': 'PILA DE MACHO', 'provincia': 'San Juan' },
        { 'descripcion': 'PIMPA', 'provincia': 'San Juan' },
        { 'descripcion': 'PIRCAS , cerro', 'provincia': 'San Juan' },
        { 'descripcion': 'PIRCAS BLANCAS', 'provincia': 'San Juan' },
        { 'descripcion': 'PIRCAS NEGRAS', 'provincia': 'San Juan' },
        { 'descripcion': 'PISMANIA', 'provincia': 'San Juan' },
        { 'descripcion': 'PISMANTA', 'provincia': 'San Juan' },
        { 'descripcion': 'PISSIS , monte', 'provincia': 'San Juan' },
        { 'descripcion': 'PLUMERILLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'PO CAJON DE LA BREA', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DE BARAHONA', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DE LA GUARDIA', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DE LAS LLARETAS', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DE LAS OJOTAS', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DE LOS PIUQUENES', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DE LOS TEATINOS', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DE USNO', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DEL CHOLLAY', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DEL INCA', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DEL PORTILLO', 'provincia': 'San Juan' },
        { 'descripcion': 'PO DEL TONTAL', 'provincia': 'San Juan' },
        { 'descripcion': 'PO LAS TORTOLAS', 'provincia': 'San Juan' },
        { 'descripcion': 'POCITO', 'provincia': 'San Juan' },
        { 'descripcion': 'PORT DE LA PUNILLA', 'provincia': 'San Juan' },
        { 'descripcion': 'PORT DE LONGOMICHE', 'provincia': 'San Juan' },
        { 'descripcion': 'PORT DE LOS SOMBREROS', 'provincia': 'San Juan' },
        { 'descripcion': 'PORT DEL COLORADO', 'provincia': 'San Juan' },
        { 'descripcion': 'PORT LAS CARACACHAS', 'provincia': 'San Juan' },
        { 'descripcion': 'PORT LAS CHILCAS', 'provincia': 'San Juan' },
        { 'descripcion': 'PORT LAS FRANCAS', 'provincia': 'San Juan' },
        { 'descripcion': 'PORT SAN GUILLERMO', 'provincia': 'San Juan' },
        { 'descripcion': 'PORT SANTA ROSA', 'provincia': 'San Juan' },
        { 'descripcion': 'PORTON GRANDE', 'provincia': 'San Juan' },
        { 'descripcion': 'POTRANCA', 'provincia': 'San Juan' },
        { 'descripcion': 'POTREROS LOS AMADORES', 'provincia': 'San Juan' },
        { 'descripcion': 'POZO DE AGUADITA', 'provincia': 'San Juan' },
        { 'descripcion': 'POZO DE LOS ALGARROBOS', 'provincia': 'San Juan' },
        { 'descripcion': 'POZO SALADO', 'provincia': 'San Juan' },
        { 'descripcion': 'PRESBITERO FRANCISCO PEREZ HER', 'provincia': 'San Juan' },
        { 'descripcion': 'PRIMER CUARTEL', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO AG DEL BURRO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO AGUADITA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO AGUADITA DE ABAJO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO ANJULIO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO CHANQUIA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO CHAVEZ', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO CORDOVA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO CUMILLANGO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO DEL AGUA DE PINTO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO DURAZNO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO EL ARBOL LIGUDO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO EL MOLLE', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO EL SARCO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO EL TORO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO FIGUEROA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO GEN', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO GORDILLO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO HUASI', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LA CHILCA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LA CORTADERA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LA ESPINA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LA REPRESA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LA TUNA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LA VIRGENCITA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LAS CUEVAS', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LIMA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LOS ALAMOS', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LOS PAPAGALLOS', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO LOS POZOS', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO MAJADITA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO PAJARITO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO PANTANITO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO PERICO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO PESCADO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO PIMPA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO PORTEZUELO HONDO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO POTRERILLO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO PUNILLA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO RECREO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO ROMERO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO SABATO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO SAN ISIDRO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO SANTA ROSA DE ABAJO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO SEGOVIA', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO TRAPICHE', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO VALLECITO', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO VAREJON', 'provincia': 'San Juan' },
        { 'descripcion': 'PTO VEGA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUCHUZÚN', 'provincia': 'San Juan' },
        { 'descripcion': 'PUENTE NACIONAL', 'provincia': 'San Juan' },
        { 'descripcion': 'PUENTE NACIONAL ,AP.', 'provincia': 'San Juan' },
        { 'descripcion': 'PUENTE RIO SAN JUAN', 'provincia': 'San Juan' },
        { 'descripcion': 'PUENTE RUFINO', 'provincia': 'San Juan' },
        { 'descripcion': 'PUERTA DE LA CHILCA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUERTA DEL INFIERNILLO', 'provincia': 'San Juan' },
        { 'descripcion': 'PUERTO TAPONES DE MAYA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUESTO ANGOSTURA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUESTO DE ARRIBA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUESTO LA CHILCA DE ABAJO', 'provincia': 'San Juan' },
        { 'descripcion': 'PUESTO OLGUIN', 'provincia': 'San Juan' },
        { 'descripcion': 'PUESTO RETIRO', 'provincia': 'San Juan' },
        { 'descripcion': 'PUESTO SANTA ROSA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUNTA BLANCA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUNTA DE AGUA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUNTA DE LAGUNA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUNTA DEL AGUA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUNTA DEL MEDANO', 'provincia': 'San Juan' },
        { 'descripcion': 'PUNTA DEL MONTE', 'provincia': 'San Juan' },
        { 'descripcion': 'PUNTA NORTE', 'provincia': 'San Juan' },
        { 'descripcion': 'PUNTILLA BLANCA', 'provincia': 'San Juan' },
        { 'descripcion': 'PUNTILLA NEGRA', 'provincia': 'San Juan' },
        { 'descripcion': 'QUILINQUIL', 'provincia': 'San Juan' },
        { 'descripcion': 'QUINTO CUARTEL', 'provincia': 'San Juan' },
        { 'descripcion': 'QUIROGA, ADAN', 'provincia': 'San Juan' },
        { 'descripcion': 'RAMBLÓN', 'provincia': 'San Juan' },
        { 'descripcion': 'RANCHOS DE FAMACOA', 'provincia': 'San Juan' },
        { 'descripcion': 'RAWSON', 'provincia': 'San Juan' },
        { 'descripcion': 'REFUGIO', 'provincia': 'San Juan' },
        { 'descripcion': 'REFUGIO D P V', 'provincia': 'San Juan' },
        { 'descripcion': 'REFUGIO LOS GAUCHOS', 'provincia': 'San Juan' },
        { 'descripcion': 'RETAMITO', 'provincia': 'San Juan' },
        { 'descripcion': 'RICHARD', 'provincia': 'San Juan' },
        { 'descripcion': 'RINCON CHICO', 'provincia': 'San Juan' },
        { 'descripcion': 'RINCON COLORADO', 'provincia': 'San Juan' },
        { 'descripcion': 'RINCON DE LA BREA', 'provincia': 'San Juan' },
        { 'descripcion': 'RINCON DE LA OLLITA', 'provincia': 'San Juan' },
        { 'descripcion': 'RINCON DE LOS CHINCHILLEROS', 'provincia': 'San Juan' },
        { 'descripcion': 'RINCON DEL GATO', 'provincia': 'San Juan' },
        { 'descripcion': 'RINCON GRANDE', 'provincia': 'San Juan' },
        { 'descripcion': 'RINCONES', 'provincia': 'San Juan' },
        { 'descripcion': 'RIO PALO', 'provincia': 'San Juan' },
        { 'descripcion': 'RIO SASO', 'provincia': 'San Juan' },
        { 'descripcion': 'RIVADAVIA', 'provincia': 'San Juan' },
        { 'descripcion': 'RODEO', 'provincia': 'San Juan' },
        { 'descripcion': 'RUINAS INDIGENAS', 'provincia': 'San Juan' },
        { 'descripcion': 'SAN AGUSTIN DEL VALLE FERTIL', 'provincia': 'San Juan' },
        { 'descripcion': 'SAN FRANCISCO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'SAN GUILLERMO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'SAN ISIDRO', 'provincia': 'San Juan' },
        { 'descripcion': 'SAN JOSÉ DE JÁCHAL', 'provincia': 'San Juan' },
        { 'descripcion': 'SAN JUAN', 'provincia': 'San Juan' },
        { 'descripcion': 'SAN JUAN BAUTISTA USNO', 'provincia': 'San Juan' },
        { 'descripcion': 'SAN MARTIN ,EST.ANGACO SUD', 'provincia': 'San Juan' },
        { 'descripcion': 'SAN ROQUE , Est. Los Diaguitas', 'provincia': 'San Juan' },
        { 'descripcion': 'SANCHEZ DE LORIA', 'provincia': 'San Juan' },
        { 'descripcion': 'SANJUANINO', 'provincia': 'San Juan' },
        { 'descripcion': 'SANTA LUCíA', 'provincia': 'San Juan' },
        { 'descripcion': 'SANTA MARIA DEL ROSARIO', 'provincia': 'San Juan' },
        { 'descripcion': 'SANTO DOMINGO', 'provincia': 'San Juan' },
        { 'descripcion': 'SEGUNDO CUARTEL', 'provincia': 'San Juan' },
        { 'descripcion': 'SIERRA BILLICUM', 'provincia': 'San Juan' },
        { 'descripcion': 'SIERRA DE CHAVES', 'provincia': 'San Juan' },
        { 'descripcion': 'SIERRA DE ELIZONDO', 'provincia': 'San Juan' },
        { 'descripcion': 'SIERRA DE RIVERO', 'provincia': 'San Juan' },
        { 'descripcion': 'SLA CIENAGA', 'provincia': 'San Juan' },
        { 'descripcion': 'SOROCAYENSE', 'provincia': 'San Juan' },
        { 'descripcion': 'TALACASTO', 'provincia': 'San Juan' },
        { 'descripcion': 'TAMBERíAS', 'provincia': 'San Juan' },
        { 'descripcion': 'TAP GALLARDO', 'provincia': 'San Juan' },
        { 'descripcion': 'TERMA LA LAJA', 'provincia': 'San Juan' },
        { 'descripcion': 'TERMA PISMANTA', 'provincia': 'San Juan' },
        { 'descripcion': 'TERMAS AGUA HEDIONDA', 'provincia': 'San Juan' },
        { 'descripcion': 'TERMAS CENTENARIO', 'provincia': 'San Juan' },
        { 'descripcion': 'TERMAS DE AGUA NEGRA', 'provincia': 'San Juan' },
        { 'descripcion': 'TIERRA ADENTRO', 'provincia': 'San Juan' },
        { 'descripcion': 'TIRA LARGA', 'provincia': 'San Juan' },
        { 'descripcion': 'TOCOTA', 'provincia': 'San Juan' },
        { 'descripcion': 'TOCOTA , arroyo', 'provincia': 'San Juan' },
        { 'descripcion': 'TONTAL', 'provincia': 'San Juan' },
        { 'descripcion': 'TOTORALITO', 'provincia': 'San Juan' },
        { 'descripcion': 'TRAVESIA DE MOGNA', 'provincia': 'San Juan' },
        { 'descripcion': 'TRES QUEBRADITAS', 'provincia': 'San Juan' },
        { 'descripcion': 'TRINIDAD', 'provincia': 'San Juan' },
        { 'descripcion': 'TUCUNUCO', 'provincia': 'San Juan' },
        { 'descripcion': 'TUCUNUCO ,EMB.', 'provincia': 'San Juan' },
        { 'descripcion': 'TUDCUM', 'provincia': 'San Juan' },
        { 'descripcion': 'TUMANAS', 'provincia': 'San Juan' },
        { 'descripcion': 'TUMINICO', 'provincia': 'San Juan' },
        { 'descripcion': 'TUPELI', 'provincia': 'San Juan' },
        { 'descripcion': 'TUTIANCA', 'provincia': 'San Juan' },
        { 'descripcion': 'ULLUM', 'provincia': 'San Juan' },
        { 'descripcion': 'ULLUN', 'provincia': 'San Juan' },
        { 'descripcion': 'USINA', 'provincia': 'San Juan' },
        { 'descripcion': 'USNO', 'provincia': 'San Juan' },
        { 'descripcion': 'VALLE DEL CURA', 'provincia': 'San Juan' },
        { 'descripcion': 'VALLE DEL CURA , río', 'provincia': 'San Juan' },
        { 'descripcion': 'VALLE FERTIL', 'provincia': 'San Juan' },
        { 'descripcion': 'VALLECITO', 'provincia': 'San Juan' },
        { 'descripcion': 'VALLECITO , río', 'provincia': 'San Juan' },
        { 'descripcion': 'VENILLO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA 20 DE JUNIO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA ABERASTAIN', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA ALEM', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA BARBOZA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA BASILIO NIEVAS', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA BERMEJITO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA BORJAS', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA CARLOTA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA CAROLINA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA COLON', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA CORRAL', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA DEL SALVADOR', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA DOMINGUITO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA ESTEVEZ', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA FLEURY', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA FRANCA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA GENERAL ACHA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA GENERAL LAS HERAS', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA GENERAL SAN MARTíN', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA GENERAL SARMIENTO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA GOBERNADOR CHAVEZ', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA HUASIHUL', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA IBAÑEZ', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA INDEPENDENCIA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA J C SARMIENTO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA JUAN XXIII', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA KRAUSE', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA LAPRIDA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA LERGA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA LUGANO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA LUZ DEL MUNDO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA MARINI', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA MEDIA AGUA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA MERCEDES', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA MORRONES', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA MUNOZ', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA N LARRAIN', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA NACUSI', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA NUEVA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA OBRERA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA P A D SARMIENTO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA PALERMO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA PATRICIAS SANJUANINAS', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA PAULA ALBARRACIN DE SARMIENTO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA PITUIL', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA PUEYRREDON', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA RACHEL', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA RIZZO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA RUFINO GOMEZ', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA SAN AGUSTíN', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA SAN ISIDRO', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA SANTA ANITA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA SANTA PAULA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA SANTA ROSA', 'provincia': 'San Juan' },
        { 'descripcion': 'VILLA SARGENTO CABRAL', 'provincia': 'San Juan' },
        { 'descripcion': 'YACIMIENTO DE COBRE EL PACHON', 'provincia': 'San Juan' },
        { 'descripcion': 'YOCA', 'provincia': 'San Juan' },
        { 'descripcion': 'ZONDA', 'provincia': 'San Juan' },
        { 'descripcion': '3 ESQUINAS', 'provincia': 'San Luis' },
        { 'descripcion': '6 DE SEPTIEMBRE', 'provincia': 'San Luis' },
        { 'descripcion': 'ACASAPE', 'provincia': 'San Luis' },
        { 'descripcion': 'ADOLFO RODRIGUEZ SAA', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUA AMARGA', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUA DEL PORTEZUELO', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUA FRIA', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUA HEDIONDA', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUA HEDIONDA , cerro', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUA LINDA', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUA SALADA', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUA SEBALLE', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUADA', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUADITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'AGUAS DE PIEDRAS', 'provincia': 'San Luis' },
        { 'descripcion': 'ALANICES', 'provincia': 'San Luis' },
        { 'descripcion': 'ALAZANAS', 'provincia': 'San Luis' },
        { 'descripcion': 'ALEGRIA', 'provincia': 'San Luis' },
        { 'descripcion': 'ALFALAND', 'provincia': 'San Luis' },
        { 'descripcion': 'ALGARROBO', 'provincia': 'San Luis' },
        { 'descripcion': 'ALGARROBOS GRANDES', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTILLO', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO BLANCO', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO DE LA LENA', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO DEL LEON', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO DEL MOLLE', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO DEL VALLE', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO GRANDE', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO LINDO', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO NEGRO', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO PELADO', 'provincia': 'San Luis' },
        { 'descripcion': 'ALTO PENCOSO', 'provincia': 'San Luis' },
        { 'descripcion': 'ANCAMILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'ANCHORENA', 'provincia': 'San Luis' },
        { 'descripcion': 'ANGELITA', 'provincia': 'San Luis' },
        { 'descripcion': 'ANTIHUASI', 'provincia': 'San Luis' },
        { 'descripcion': 'ARBOL SOLO', 'provincia': 'San Luis' },
        { 'descripcion': 'ARBOL VERDE', 'provincia': 'San Luis' },
        { 'descripcion': 'ARBOLEDA', 'provincia': 'San Luis' },
        { 'descripcion': 'ARBOLES BLANCOS', 'provincia': 'San Luis' },
        { 'descripcion': 'ARENILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'ARIZONA', 'provincia': 'San Luis' },
        { 'descripcion': 'ARROYO DE VILCHES', 'provincia': 'San Luis' },
        { 'descripcion': 'ARROYO LA CAL', 'provincia': 'San Luis' },
        { 'descripcion': 'AVANZADA', 'provincia': 'San Luis' },
        { 'descripcion': 'AVANZADA ,EST.', 'provincia': 'San Luis' },
        { 'descripcion': 'AVIADOR ORIGONE', 'provincia': 'San Luis' },
        { 'descripcion': 'BAGUAL', 'provincia': 'San Luis' },
        { 'descripcion': 'BAJADA', 'provincia': 'San Luis' },
        { 'descripcion': 'BAJADA NUEVA', 'provincia': 'San Luis' },
        { 'descripcion': 'BAJO DE CONLARA', 'provincia': 'San Luis' },
        { 'descripcion': 'BAJO DE LA CRUZ', 'provincia': 'San Luis' },
        { 'descripcion': 'BAJO LA LAGUNA', 'provincia': 'San Luis' },
        { 'descripcion': 'BAJOS HONDOS', 'provincia': 'San Luis' },
        { 'descripcion': 'BALCARCE', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDA', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE AHUMADA', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE AMIRA', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE ARRIBA', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE AZCURRA', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE ESCUDERO', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE GARCIA', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE GUARDIA', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE GUINAZU', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE LA ISLA', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE LA LINEA', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE LEDESMA', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE LOS TORRES', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE MONTE', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE NUEVO', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE PUERTAS', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE QUINES', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DE TORRES', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE DEL ESCUDERO', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE EL CARRIL', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE HONDO', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE RETAMO', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDE VIEJO', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDECITO', 'provincia': 'San Luis' },
        { 'descripcion': 'BALDECITO LA PAMPA', 'provincia': 'San Luis' },
        { 'descripcion': 'BANADITO', 'provincia': 'San Luis' },
        { 'descripcion': 'BANADITO VIEJO', 'provincia': 'San Luis' },
        { 'descripcion': 'BANADO', 'provincia': 'San Luis' },
        { 'descripcion': 'BANADO DE CAUTANA', 'provincia': 'San Luis' },
        { 'descripcion': 'BANADO LINDO', 'provincia': 'San Luis' },
        { 'descripcion': 'BANDA SUD', 'provincia': 'San Luis' },
        { 'descripcion': 'BANOS ZAPALLAR', 'provincia': 'San Luis' },
        { 'descripcion': 'BARRANCA COLORADA', 'provincia': 'San Luis' },
        { 'descripcion': 'BARRANQUITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'BARRIAL', 'provincia': 'San Luis' },
        { 'descripcion': 'BARRIALES', 'provincia': 'San Luis' },
        { 'descripcion': 'BARRIO BLANCO', 'provincia': 'San Luis' },
        { 'descripcion': 'BARZOLA', 'provincia': 'San Luis' },
        { 'descripcion': 'BATAVIA', 'provincia': 'San Luis' },
        { 'descripcion': 'BEAZLEY', 'provincia': 'San Luis' },
        { 'descripcion': 'BEBEDERO', 'provincia': 'San Luis' },
        { 'descripcion': 'BEBIDA', 'provincia': 'San Luis' },
        { 'descripcion': 'BECERRA', 'provincia': 'San Luis' },
        { 'descripcion': 'BELLA ESTANCIA', 'provincia': 'San Luis' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'San Luis' },
        { 'descripcion': 'BILLIKEN', 'provincia': 'San Luis' },
        { 'descripcion': 'BLANCO , cerro', 'provincia': 'San Luis' },
        { 'descripcion': 'BOCA DEL RIO', 'provincia': 'San Luis' },
        { 'descripcion': 'BOTIJAS', 'provincia': 'San Luis' },
        { 'descripcion': 'BUENA ESPERANZA', 'provincia': 'San Luis' },
        { 'descripcion': 'BUENA VENTURA', 'provincia': 'San Luis' },
        { 'descripcion': 'CABEZA DE NOVILLO', 'provincia': 'San Luis' },
        { 'descripcion': 'CACHI CORRAL', 'provincia': 'San Luis' },
        { 'descripcion': 'CAIN DE LOS TIGRES', 'provincia': 'San Luis' },
        { 'descripcion': 'CALDENADAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CALERA ARGENTINA', 'provincia': 'San Luis' },
        { 'descripcion': 'CALERAS CANADA GRANDE', 'provincia': 'San Luis' },
        { 'descripcion': 'CAMPANARIO', 'provincia': 'San Luis' },
        { 'descripcion': 'CAMPO DE SAN PEDRO', 'provincia': 'San Luis' },
        { 'descripcion': 'CANA LARGA', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA', 'provincia': 'San Luis' },
        { 'descripcion': 'CAÑADA , zanjÓn', 'provincia': 'San Luis' },
        { 'descripcion': 'CAÑADA ANGOSTA', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA BLANCA', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA DE ATRAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA DE LA NEGRA', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA DE LAS LAGUNAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA DE LOS TIGRES', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA DE VILAN', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA DEL PASTO', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA DEL PUESTITO', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA GRANDE', 'provincia': 'San Luis' },
        { 'descripcion': 'CAÑADA HONDA', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA HONDA DE GUZMAN', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA LA NEGRA', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA LA TIENDA', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA QUEMADA', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA SAN ANTONIO', 'provincia': 'San Luis' },
        { 'descripcion': 'CANADA VERDE', 'provincia': 'San Luis' },
        { 'descripcion': 'CANDELARIA', 'provincia': 'San Luis' },
        { 'descripcion': 'CANDELARIA ,EST.LA CANDELARIA', 'provincia': 'San Luis' },
        { 'descripcion': 'CANITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CANTANTAL', 'provincia': 'San Luis' },
        { 'descripcion': 'CANTERAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CANTERAS SANTA ISABEL', 'provincia': 'San Luis' },
        { 'descripcion': 'CAPELEN', 'provincia': 'San Luis' },
        { 'descripcion': 'CARMELO', 'provincia': 'San Luis' },
        { 'descripcion': 'CAROLINA', 'provincia': 'San Luis' },
        { 'descripcion': 'CARPINTERíA', 'provincia': 'San Luis' },
        { 'descripcion': 'CASA DE CONDOR', 'provincia': 'San Luis' },
        { 'descripcion': 'CASAS VIEJAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CASIMIRO GÓMEZ', 'provincia': 'San Luis' },
        { 'descripcion': 'CAZADOR', 'provincia': 'San Luis' },
        { 'descripcion': 'CERRITO BLANCO', 'provincia': 'San Luis' },
        { 'descripcion': 'CERRITO NEGRO', 'provincia': 'San Luis' },
        { 'descripcion': 'CERRO BAYO', 'provincia': 'San Luis' },
        { 'descripcion': 'CERRO BLANCO', 'provincia': 'San Luis' },
        { 'descripcion': 'CERRO DE LA PILA', 'provincia': 'San Luis' },
        { 'descripcion': 'CERRO DE ORO', 'provincia': 'San Luis' },
        { 'descripcion': 'CERRO DE PIEDRA', 'provincia': 'San Luis' },
        { 'descripcion': 'CERRO VARELA', 'provincia': 'San Luis' },
        { 'descripcion': 'CERRO VERDE', 'provincia': 'San Luis' },
        { 'descripcion': 'CERRO VIEJO', 'provincia': 'San Luis' },
        { 'descripcion': 'CERROS LARGOS', 'provincia': 'San Luis' },
        { 'descripcion': 'CHACARITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CHACRA LA PRIMAVERA', 'provincia': 'San Luis' },
        { 'descripcion': 'CHACRAS DEL CANTARO', 'provincia': 'San Luis' },
        { 'descripcion': 'CHACRAS VIEJAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CHALANTA', 'provincia': 'San Luis' },
        { 'descripcion': 'CHANAR DE LA LEGUA', 'provincia': 'San Luis' },
        { 'descripcion': 'CHANCARITA', 'provincia': 'San Luis' },
        { 'descripcion': 'CHARLONE', 'provincia': 'San Luis' },
        { 'descripcion': 'CHARLONES', 'provincia': 'San Luis' },
        { 'descripcion': 'CHIMBORAZO', 'provincia': 'San Luis' },
        { 'descripcion': 'CHIPICAL', 'provincia': 'San Luis' },
        { 'descripcion': 'CHIPISCU', 'provincia': 'San Luis' },
        { 'descripcion': 'CHISCHACA', 'provincia': 'San Luis' },
        { 'descripcion': 'CHISCHACA ,DV.', 'provincia': 'San Luis' },
        { 'descripcion': 'CHISCHAQUITA', 'provincia': 'San Luis' },
        { 'descripcion': 'CHORRILLOS , río', 'provincia': 'San Luis' },
        { 'descripcion': 'CHOSMES', 'provincia': 'San Luis' },
        { 'descripcion': 'CHUTUSA', 'provincia': 'San Luis' },
        { 'descripcion': 'CIUDAD JARDIN DE SAN LUIS', 'provincia': 'San Luis' },
        { 'descripcion': 'COCHENELOS', 'provincia': 'San Luis' },
        { 'descripcion': 'COCHEQUINGAN', 'provincia': 'San Luis' },
        { 'descripcion': 'COLONIA BELLA VISTA', 'provincia': 'San Luis' },
        { 'descripcion': 'COLONIA CALZADA', 'provincia': 'San Luis' },
        { 'descripcion': 'COLONIA EL CAMPAMENTO', 'provincia': 'San Luis' },
        { 'descripcion': 'COLONIA LA FLORIDA', 'provincia': 'San Luis' },
        { 'descripcion': 'COLONIA LUNA', 'provincia': 'San Luis' },
        { 'descripcion': 'COLONIA SANTA VIRGINIA', 'provincia': 'San Luis' },
        { 'descripcion': 'COLONIA URDANIZ', 'provincia': 'San Luis' },
        { 'descripcion': 'COMANDANTE GRANVILLE', 'provincia': 'San Luis' },
        { 'descripcion': 'CONCARÁN', 'provincia': 'San Luis' },
        { 'descripcion': 'CONSUELO', 'provincia': 'San Luis' },
        { 'descripcion': 'CONSULTA', 'provincia': 'San Luis' },
        { 'descripcion': 'CORONEL ALZOGARAY', 'provincia': 'San Luis' },
        { 'descripcion': 'CORONEL SEGOVIA', 'provincia': 'San Luis' },
        { 'descripcion': 'CORRAL DE PIEDRA', 'provincia': 'San Luis' },
        { 'descripcion': 'CORRAL DE TORRES', 'provincia': 'San Luis' },
        { 'descripcion': 'CORRAL DEL TALA', 'provincia': 'San Luis' },
        { 'descripcion': 'CORRALES', 'provincia': 'San Luis' },
        { 'descripcion': 'CORTADERAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CRAMER', 'provincia': 'San Luis' },
        { 'descripcion': 'CRUCECITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CRUZ BRILLANTE', 'provincia': 'San Luis' },
        { 'descripcion': 'CRUZ DE CAÑA', 'provincia': 'San Luis' },
        { 'descripcion': 'CUATRO ESQUINAS', 'provincia': 'San Luis' },
        { 'descripcion': 'CUEVA DE TIGRE', 'provincia': 'San Luis' },
        { 'descripcion': 'DANIEL DONOVAN', 'provincia': 'San Luis' },
        { 'descripcion': 'DARACT, JUSTO', 'provincia': 'San Luis' },
        { 'descripcion': 'de CANTANTAL , sierra', 'provincia': 'San Luis' },
        { 'descripcion': 'de GUAYAGUAS , sierra', 'provincia': 'San Luis' },
        { 'descripcion': 'de LA ESTANZUELA , sierra', 'provincia': 'San Luis' },
        { 'descripcion': 'de LAS QUIJADAS , sierra', 'provincia': 'San Luis' },
        { 'descripcion': 'de LAS SALINAS , pampa', 'provincia': 'San Luis' },
        { 'descripcion': 'de SAN LUIS , sierra', 'provincia': 'San Luis' },
        { 'descripcion': 'de SOCOSCORA , sierra', 'provincia': 'San Luis' },
        { 'descripcion': 'del BAJO RETAMO , río', 'provincia': 'San Luis' },
        { 'descripcion': 'del BEBEDERO , salina', 'provincia': 'San Luis' },
        { 'descripcion': 'del BOLSÓN , cerro', 'provincia': 'San Luis' },
        { 'descripcion': 'del CAJÓN DE LA BREA , cordillera', 'provincia': 'San Luis' },
        { 'descripcion': 'del GIGANTE , sierra', 'provincia': 'San Luis' },
        { 'descripcion': 'DIQUE LA FLORIDA', 'provincia': 'San Luis' },
        { 'descripcion': 'DIQUE LA HUERTITA', 'provincia': 'San Luis' },
        { 'descripcion': 'DIVISADERO', 'provincia': 'San Luis' },
        { 'descripcion': 'DIXONVILLE ,EST.FORTIN EL PATRIA', 'provincia': 'San Luis' },
        { 'descripcion': 'DOMINGUEZ', 'provincia': 'San Luis' },
        { 'descripcion': 'DONADO', 'provincia': 'San Luis' },
        { 'descripcion': 'DONOVAN, DANIEL', 'provincia': 'San Luis' },
        { 'descripcion': 'DORMIDA', 'provincia': 'San Luis' },
        { 'descripcion': 'DURAZNITO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL AGUILA', 'provincia': 'San Luis' },
        { 'descripcion': 'EL ALGARROBAL', 'provincia': 'San Luis' },
        { 'descripcion': 'EL AMPARO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL ARENAL', 'provincia': 'San Luis' },
        { 'descripcion': 'EL ARROYO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL BAGUAL', 'provincia': 'San Luis' },
        { 'descripcion': 'EL BAJO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL BALDE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL BALDECITO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL BARRIAL', 'provincia': 'San Luis' },
        { 'descripcion': 'EL BLANCO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL BURRITO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL CAMPAMENTO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL CARDAL', 'provincia': 'San Luis' },
        { 'descripcion': 'EL CARMEN', 'provincia': 'San Luis' },
        { 'descripcion': 'EL CAVADO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL CAZADOR', 'provincia': 'San Luis' },
        { 'descripcion': 'EL CHARABON', 'provincia': 'San Luis' },
        { 'descripcion': 'EL CHORRILLO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL CINCO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL CORO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL DICHOSO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL DIQUE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL DURAZNO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL HORMIGUERO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL HORNITO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL INJERTO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL JARILLAL', 'provincia': 'San Luis' },
        { 'descripcion': 'EL LECHUZO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL MANANTIAL', 'provincia': 'San Luis' },
        { 'descripcion': 'EL MANANTIAL ESCONDIDO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL MANGRULLO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL MARTILLO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL MATACO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL MILAGRO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL MOLLARCITO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL MOLLE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL MORRO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL NASAO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL NEGRO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL OASIS', 'provincia': 'San Luis' },
        { 'descripcion': 'EL OLMO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PAJARETE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PANTANILLO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PANTANO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PARAGUAY', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PASAJERO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PAYERO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PEDERNAL', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PEJE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PICHE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PIGUE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PIMPOLLO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PLATEADO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PLUMERITO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL POCITO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL POLEO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PORTEZUELO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PORVENIR', 'provincia': 'San Luis' },
        { 'descripcion': 'EL POTRERO DE LEYES', 'provincia': 'San Luis' },
        { 'descripcion': 'EL POZO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PROGRESO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PUEBLITO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL PUERTO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL QUINGUAL', 'provincia': 'San Luis' },
        { 'descripcion': 'EL RAMBLON', 'provincia': 'San Luis' },
        { 'descripcion': 'EL RECUERDO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL RETAMO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL RIECITO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL RINCON', 'provincia': 'San Luis' },
        { 'descripcion': 'EL RIO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL RODEO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL SALADO DE AMAYA', 'provincia': 'San Luis' },
        { 'descripcion': 'EL SALTO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL SALVADOR', 'provincia': 'San Luis' },
        { 'descripcion': 'EL SARCO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL SAUCE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL SEMBRADO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL SOCORRO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL TALITA', 'provincia': 'San Luis' },
        { 'descripcion': 'EL TEMBLEQUE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL TORCIDO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL TORO MUERTO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL TRAPICHE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL VALLE', 'provincia': 'San Luis' },
        { 'descripcion': 'EL VALLECITO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL VERANO', 'provincia': 'San Luis' },
        { 'descripcion': 'EL VOLCAN', 'provincia': 'San Luis' },
        { 'descripcion': 'EL YACATAN', 'provincia': 'San Luis' },
        { 'descripcion': 'EL ZAMPAL', 'provincia': 'San Luis' },
        { 'descripcion': 'ELEODORO LOBOS', 'provincia': 'San Luis' },
        { 'descripcion': 'EMBALSE CRUZ DE PIEDRA', 'provincia': 'San Luis' },
        { 'descripcion': 'EMBALSE LA FLORIDA', 'provincia': 'San Luis' },
        { 'descripcion': 'EMBALSE POTRERO DE LOS FUNES', 'provincia': 'San Luis' },
        { 'descripcion': 'EMBALSE RíO HONDO', 'provincia': 'San Luis' },
        { 'descripcion': 'EMBALSE SALTO GRANDE', 'provincia': 'San Luis' },
        { 'descripcion': 'EMBALSE SAN FELIPE', 'provincia': 'San Luis' },
        { 'descripcion': 'ENTRE RIOS', 'provincia': 'San Luis' },
        { 'descripcion': 'Est. Adolfo Rodríguez SÁa', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTABLECIMIENTO LAS FLORES', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTACION ZANJITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA 30 DE OCTUBRE', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA DON ARTURO', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA EL CHAMICO', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA EL DIVISADERO', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA EL MEDANO', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA EL QUEBRACHAL', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA EL SAUCECITO', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA GRANDE', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LA BLANCA', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LA GUARDIA', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LA GUILLERMINA', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LA MORENA', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LA RESERVA', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LA UNION', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LA ZULEMITA', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LAS BEBIDAS', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LOS CISNES', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LOS HERMANOS', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA LOS NOGALES', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA RETAMO', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA RIVADAVIA', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA SAN ALBERTO', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA SAN ANTONIO', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA SAN FRANCISCO', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA SAN MARTíN DEL ALTO NEGRO', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA SAN NICOLÁS', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA SAN ROQUE', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANCIA TRES ARBOLES', 'provincia': 'San Luis' },
        { 'descripcion': 'ESTANZUELA', 'provincia': 'San Luis' },
        { 'descripcion': 'FAVELLI', 'provincia': 'San Luis' },
        { 'descripcion': 'FENOGLIO', 'provincia': 'San Luis' },
        { 'descripcion': 'FORTíN EL PATRIA', 'provincia': 'San Luis' },
        { 'descripcion': 'FORTIN SALTO', 'provincia': 'San Luis' },
        { 'descripcion': 'FORTUNA', 'provincia': 'San Luis' },
        { 'descripcion': 'FORTUNA DE SAN JUAN', 'provincia': 'San Luis' },
        { 'descripcion': 'FRAGA', 'provincia': 'San Luis' },
        { 'descripcion': 'FRAILE , cerro', 'provincia': 'San Luis' },
        { 'descripcion': 'FRISIA', 'provincia': 'San Luis' },
        { 'descripcion': 'GENERAL PEDERNERA', 'provincia': 'San Luis' },
        { 'descripcion': 'GENERAL URQUIZA', 'provincia': 'San Luis' },
        { 'descripcion': 'GEZ,JUAN W.', 'provincia': 'San Luis' },
        { 'descripcion': 'GIGANTE', 'provincia': 'San Luis' },
        { 'descripcion': 'GLORIA A DIOS', 'provincia': 'San Luis' },
        { 'descripcion': 'GOMEZ, CASIMIRO', 'provincia': 'San Luis' },
        { 'descripcion': 'GORGONTA', 'provincia': 'San Luis' },
        { 'descripcion': 'GRUTA DE INTIHUASI', 'provincia': 'San Luis' },
        { 'descripcion': 'GUALTARAN', 'provincia': 'San Luis' },
        { 'descripcion': 'GUANACO PAMPA', 'provincia': 'San Luis' },
        { 'descripcion': 'GUANIZUL', 'provincia': 'San Luis' },
        { 'descripcion': 'GUASQUITA', 'provincia': 'San Luis' },
        { 'descripcion': 'HINOJITO', 'provincia': 'San Luis' },
        { 'descripcion': 'HINOJOS', 'provincia': 'San Luis' },
        { 'descripcion': 'HIPOLITO YRIGOYEN', 'provincia': 'San Luis' },
        { 'descripcion': 'HORNITO', 'provincia': 'San Luis' },
        { 'descripcion': 'HUALTARAN', 'provincia': 'San Luis' },
        { 'descripcion': 'HUCHISSON', 'provincia': 'San Luis' },
        { 'descripcion': 'HUEJEDA', 'provincia': 'San Luis' },
        { 'descripcion': 'HUERTAS', 'provincia': 'San Luis' },
        { 'descripcion': 'INTIHUASI', 'provincia': 'San Luis' },
        { 'descripcion': 'INVERNADA', 'provincia': 'San Luis' },
        { 'descripcion': 'ISLA', 'provincia': 'San Luis' },
        { 'descripcion': 'ISLITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'ISONDU', 'provincia': 'San Luis' },
        { 'descripcion': 'JARILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'JORBA, JUAN', 'provincia': 'San Luis' },
        { 'descripcion': 'JUAN JORBA', 'provincia': 'San Luis' },
        { 'descripcion': 'JUAN LLERENA', 'provincia': 'San Luis' },
        { 'descripcion': 'JUAN W GEZ', 'provincia': 'San Luis' },
        { 'descripcion': 'JUAN W. GEZ', 'provincia': 'San Luis' },
        { 'descripcion': 'JUANA KOSLAY', 'provincia': 'San Luis' },
        { 'descripcion': 'JUANTE', 'provincia': 'San Luis' },
        { 'descripcion': 'JUSTO DARACT', 'provincia': 'San Luis' },
        { 'descripcion': 'KILOMETRO 14 ,DIV.', 'provincia': 'San Luis' },
        { 'descripcion': 'KILOMETRO 731', 'provincia': 'San Luis' },
        { 'descripcion': 'KILOMETRO 757', 'provincia': 'San Luis' },
        { 'descripcion': 'LA AGUA NUEVA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA AGUADA DE LAS ANIMAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LA AGUEDA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ALAMEDA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ALCORTENA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ALEGRIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ALIANZA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA AMALIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA AMARGA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ANGELINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ARMONIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA AROMA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ATALAYA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA BAJADA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA BAVA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA BAVARIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA BERTITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA BOLIVIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA BONITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA BREA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CABRA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CALAGUALA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CALERA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CARMEN', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CARMENCITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CELIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CHANARIENTA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CHERINDU', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CHILCA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CHILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA COCHA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA COLONIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CORA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CORINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA COSTA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CRISTINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA CRUCECITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA DELIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA DONOSTIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA DORA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA DUDA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA DULCE', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ELENA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ELENITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ELIDA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ELISA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ELVIRA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA EMMA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA EMPAJADA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ERNESTINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ESPESURA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ESQUINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ESQUINA DEL RIO', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ESTANZUELA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ESTRELLA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ETHEL', 'provincia': 'San Luis' },
        { 'descripcion': 'LA EULOGIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA FELISA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA FINCA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA FLECHA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA FLORIDA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA GAMA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA GARRAPATA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA GARZA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA GAVIOTA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA GERMANIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA GITANA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA GRAMILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA HERMOSURA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA HIGUERITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA HORTENSIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA HUERTA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA HUERTITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA IBERIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA IRENE', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ISABEL', 'provincia': 'San Luis' },
        { 'descripcion': 'LA JAVIERA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA JERGA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA JOSEFA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA JOSEFINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA JUANA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA JULIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA JUSTA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA LAURA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA LECHUGA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA LEGUA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA LINDA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA LINEA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA LOMA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MAGDALENA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MARAVILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MARGARITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MARGARITA CARLOTA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MARIA ESTHER', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MARIA LUISA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MAROMA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MASCOTA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MEDIA LEGUA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MEDULA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MELINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MESILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA MODERNA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA NEGRITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA NELIDA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA NUTRIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA PALMIRA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA PAMPA GRANDE', 'provincia': 'San Luis' },
        { 'descripcion': 'LA PEREGRINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA PETRA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA PORFIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA PORTADA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA PUNILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA QUEBRADA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA REALIDAD', 'provincia': 'San Luis' },
        { 'descripcion': 'LA REINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA REPRESITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA RESISTENCIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA RIOJITA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ROSADA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ROSALIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ROSINA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA SALA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA SALUD', 'provincia': 'San Luis' },
        { 'descripcion': 'LA SALVADORA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA SANDIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA SEGUNDA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA SELVA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA SEÑA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA SERRANA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA SILESIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA SUIZA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA TOMA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA TOSCA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA TOTORA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA TRANCA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA TRAVESIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA TULA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA TUSCA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA ULBARA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA URUGUAYA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA VACA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA VENECIA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA VERDE', 'provincia': 'San Luis' },
        { 'descripcion': 'LA VERTIENTE', 'provincia': 'San Luis' },
        { 'descripcion': 'LA YERBA BUENA', 'provincia': 'San Luis' },
        { 'descripcion': 'LA YESERA', 'provincia': 'San Luis' },
        { 'descripcion': 'LAFINUR', 'provincia': 'San Luis' },
        { 'descripcion': 'LAGUNA CAPELEN', 'provincia': 'San Luis' },
        { 'descripcion': 'LAGUNA DE LA CANADA', 'provincia': 'San Luis' },
        { 'descripcion': 'LAGUNA DE LOS PATOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAGUNA DE PATOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAGUNA SAYAPE', 'provincia': 'San Luis' },
        { 'descripcion': 'LAGUNA SECA', 'provincia': 'San Luis' },
        { 'descripcion': 'LAGUNAS LARGAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS AGUADAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS AROMAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS BARRANCAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS BARRANQUITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CABRAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CANTERAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CARITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CAROLINAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CARRETAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CHACRAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CHACRAS DE SAN MARTIN', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CHACRITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CHILCAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CHIMBAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CLARITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS COLONIAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS CORTADERAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS ENCADENADAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS GALERAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS GAMAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS GITANAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS ISLETAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS ISLITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS LAGUNAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS LAGUNITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS LOMITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS MANGAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS MARTINETAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS MELADAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS MESIAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS MESTIZAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS NIEVES', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS PALOMAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS PAMPITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS PLAYAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS PLAYAS ARGENTINAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS PRADERAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS PUERTAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS RAICES', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS ROSADAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS SALINAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS TIGRAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS TOTORITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS TRES CANADAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS VERTIENTES', 'provincia': 'San Luis' },
        { 'descripcion': 'LAS VISCACHERAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LAURA ELISA', 'provincia': 'San Luis' },
        { 'descripcion': 'LAVAISSE', 'provincia': 'San Luis' },
        { 'descripcion': 'LEANDRO N ALEM', 'provincia': 'San Luis' },
        { 'descripcion': 'LEANDRO N. ALEM', 'provincia': 'San Luis' },
        { 'descripcion': 'LIBERTADOR GENERAL SAN MARTíN', 'provincia': 'San Luis' },
        { 'descripcion': 'LIBORIO LUNA', 'provincia': 'San Luis' },
        { 'descripcion': 'LINCE', 'provincia': 'San Luis' },
        { 'descripcion': 'LINDO', 'provincia': 'San Luis' },
        { 'descripcion': 'LLERENA, JUAN', 'provincia': 'San Luis' },
        { 'descripcion': 'LOBOS, ELEODORO', 'provincia': 'San Luis' },
        { 'descripcion': 'LOMA DEL MEDIO', 'provincia': 'San Luis' },
        { 'descripcion': 'LOMAS BLANCAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOMAS BLANCAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOMITAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LONGARI', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS AGUADOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS ALGARROBITOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS ALGARROBOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS ALMACIGOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS ARADITOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS ARCES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS ARGUELLOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS ARROYOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS BARRIALES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CAJONES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CARRICITOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CERRILLOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CERRITOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CESARES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CHANCAROS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CHENAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CLAVELES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS COMEDEROS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS COMEDORES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS COROS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CORRALES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CORRALITOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS CUADROS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS DOS RIOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS DUEROS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS DURAZNITOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS DURAZNOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS ESPINILLOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS ESQUINEROS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS HINOJOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS HUAYCOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS LECHUZONES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS LOBOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS MEDANOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS MENBRILLOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS MENDOCINOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS MOLLECITOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS MOLLES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS MONTES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS NOQUES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS OSCUROS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS PASITOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS PEJES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS PEROS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS POLEOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS POZOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS PUQUIOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS QUEBRACHOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS RAMBLONES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS ROLDANES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS TALAS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS TAMARINOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS TAPIALES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS TELARIOS', 'provincia': 'San Luis' },
        { 'descripcion': 'LOS VALLES', 'provincia': 'San Luis' },
        { 'descripcion': 'LOYOLA, MARTIN DE', 'provincia': 'San Luis' },
        { 'descripcion': 'LUJÁN', 'provincia': 'San Luis' },
        { 'descripcion': 'LUJÁN , río', 'provincia': 'San Luis' },
        { 'descripcion': 'LULUARA , arroyo', 'provincia': 'San Luis' },
        { 'descripcion': 'LUNA, LIBORIO', 'provincia': 'San Luis' },
        { 'descripcion': 'MACHAO', 'provincia': 'San Luis' },
        { 'descripcion': 'MANANTIAL BLANCO', 'provincia': 'San Luis' },
        { 'descripcion': 'MANANTIAL DE FLORES', 'provincia': 'San Luis' },
        { 'descripcion': 'MANANTIAL DE RENCA', 'provincia': 'San Luis' },
        { 'descripcion': 'MANANTIAL GRANDE', 'provincia': 'San Luis' },
        { 'descripcion': 'MANANTIAL LINDO', 'provincia': 'San Luis' },
        { 'descripcion': 'MANANTIALES', 'provincia': 'San Luis' },
        { 'descripcion': 'MANTILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'MARAVILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'MARAY', 'provincia': 'San Luis' },
        { 'descripcion': 'MARLITO', 'provincia': 'San Luis' },
        { 'descripcion': 'MARMOL VERDE', 'provincia': 'San Luis' },
        { 'descripcion': 'MAROMA DE PÁEZ', 'provincia': 'San Luis' },
        { 'descripcion': 'MAROMA FALLATA', 'provincia': 'San Luis' },
        { 'descripcion': 'MARTíN DE LOYOLA', 'provincia': 'San Luis' },
        { 'descripcion': 'MATACO', 'provincia': 'San Luis' },
        { 'descripcion': 'MEDANO BALLO', 'provincia': 'San Luis' },
        { 'descripcion': 'MEDANO CHICO', 'provincia': 'San Luis' },
        { 'descripcion': 'MEDANO GRANDE', 'provincia': 'San Luis' },
        { 'descripcion': 'MERCEDES ,EST.VILLA MERCEDES', 'provincia': 'San Luis' },
        { 'descripcion': 'MERLO', 'provincia': 'San Luis' },
        { 'descripcion': 'MINA CAROLINA', 'provincia': 'San Luis' },
        { 'descripcion': 'MINA LOS CONDORES', 'provincia': 'San Luis' },
        { 'descripcion': 'MINA SANTO DOMINGO', 'provincia': 'San Luis' },
        { 'descripcion': 'MOLLECITO', 'provincia': 'San Luis' },
        { 'descripcion': 'MONTE CARMELO', 'provincia': 'San Luis' },
        { 'descripcion': 'MONTE CHIQUITO', 'provincia': 'San Luis' },
        { 'descripcion': 'MONTE COCHEQUINGAN', 'provincia': 'San Luis' },
        { 'descripcion': 'MONTE VERDE', 'provincia': 'San Luis' },
        { 'descripcion': 'MOSMOTA', 'provincia': 'San Luis' },
        { 'descripcion': 'MOYAR', 'provincia': 'San Luis' },
        { 'descripcion': 'MOYARCITO', 'provincia': 'San Luis' },
        { 'descripcion': 'NAHUEL MAPA', 'provincia': 'San Luis' },
        { 'descripcion': 'NARANJO', 'provincia': 'San Luis' },
        { 'descripcion': 'NASCHEL', 'provincia': 'San Luis' },
        { 'descripcion': 'NAVIA', 'provincia': 'San Luis' },
        { 'descripcion': 'NEVADO , cerro', 'provincia': 'San Luis' },
        { 'descripcion': 'NIKIZANGA ,DV.', 'provincia': 'San Luis' },
        { 'descripcion': 'NILINAST', 'provincia': 'San Luis' },
        { 'descripcion': 'NO ES MIA', 'provincia': 'San Luis' },
        { 'descripcion': 'NOGOLí', 'provincia': 'San Luis' },
        { 'descripcion': 'NOGOLí , río', 'provincia': 'San Luis' },
        { 'descripcion': 'NOSSAR', 'provincia': 'San Luis' },
        { 'descripcion': 'NUEVA CONSTITUCIÓN', 'provincia': 'San Luis' },
        { 'descripcion': 'NUEVA ESCOCIA', 'provincia': 'San Luis' },
        { 'descripcion': 'NUEVA GALIA', 'provincia': 'San Luis' },
        { 'descripcion': 'NURILAY', 'provincia': 'San Luis' },
        { 'descripcion': 'OJO DEL RIO', 'provincia': 'San Luis' },
        { 'descripcion': 'ONCE DE MAYO', 'provincia': 'San Luis' },
        { 'descripcion': 'Otra', 'provincia': 'San Luis' },
        { 'descripcion': 'OTRA BANDA', 'provincia': 'San Luis' },
        { 'descripcion': 'PAINES', 'provincia': 'San Luis' },
        { 'descripcion': 'PAJE', 'provincia': 'San Luis' },
        { 'descripcion': 'PALIGUANTA', 'provincia': 'San Luis' },
        { 'descripcion': 'PALOMAR', 'provincia': 'San Luis' },
        { 'descripcion': 'PAMPA', 'provincia': 'San Luis' },
        { 'descripcion': 'PAMPA DE LOS GOBERNADORES', 'provincia': 'San Luis' },
        { 'descripcion': 'PAMPA DEL BAJO', 'provincia': 'San Luis' },
        { 'descripcion': 'PAMPA DEL TAMBORERO', 'provincia': 'San Luis' },
        { 'descripcion': 'PAMPA INVERNADA', 'provincia': 'San Luis' },
        { 'descripcion': 'PANTANILLO', 'provincia': 'San Luis' },
        { 'descripcion': 'PANTANILLOS', 'provincia': 'San Luis' },
        { 'descripcion': 'PAPAGAYO', 'provincia': 'San Luis' },
        { 'descripcion': 'PAPAGAYOS', 'provincia': 'San Luis' },
        { 'descripcion': 'PARAISO', 'provincia': 'San Luis' },
        { 'descripcion': 'PARQUE NACIONAL SIERRA DE LAS QUIJADAS', 'provincia': 'San Luis' },
        { 'descripcion': 'PARQUE NATURAL Y ZONA DE RESERVA ECOLÓGICA', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO ANCHO', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE CUERO', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE LA CRUZ', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE LA TIERRA', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE LAS CARRETAS', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE LAS SALINAS', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE LAS SIERRAS', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE LAS TOSCAS', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE LAS VACAS', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE LOS BAYOS', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE LOS GAUCHOS', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DE PIEDRA', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DEL MEDIO', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO DEL REY', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO GRANDE', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO JUAN GOMEZ', 'provincia': 'San Luis' },
        { 'descripcion': 'PASO LOS ALGARROBOS', 'provincia': 'San Luis' },
        { 'descripcion': 'PASTAL', 'provincia': 'San Luis' },
        { 'descripcion': 'PATIO LIMPIO', 'provincia': 'San Luis' },
        { 'descripcion': 'PEDERNERA', 'provincia': 'San Luis' },
        { 'descripcion': 'PENICE', 'provincia': 'San Luis' },
        { 'descripcion': 'PENON COLORADO', 'provincia': 'San Luis' },
        { 'descripcion': 'PESCADORES', 'provincia': 'San Luis' },
        { 'descripcion': 'PICOS YACU', 'provincia': 'San Luis' },
        { 'descripcion': 'PIE DE LA CUESTA', 'provincia': 'San Luis' },
        { 'descripcion': 'PIEDRA BOLA', 'provincia': 'San Luis' },
        { 'descripcion': 'PIEDRA ROSADA', 'provincia': 'San Luis' },
        { 'descripcion': 'PIEDRA SOLA', 'provincia': 'San Luis' },
        { 'descripcion': 'PIEDRAS CHATAS', 'provincia': 'San Luis' },
        { 'descripcion': 'PIQUILLINES', 'provincia': 'San Luis' },
        { 'descripcion': 'PISCOYACO', 'provincia': 'San Luis' },
        { 'descripcion': 'PIZARRAS BAJO VELEZ', 'provincia': 'San Luis' },
        { 'descripcion': 'PLACILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'PLANTA DE SANDIA', 'provincia': 'San Luis' },
        { 'descripcion': 'PLUMERITO', 'provincia': 'San Luis' },
        { 'descripcion': 'POCITOS', 'provincia': 'San Luis' },
        { 'descripcion': 'POLLEDO', 'provincia': 'San Luis' },
        { 'descripcion': 'PORTADA DEL SAUCE', 'provincia': 'San Luis' },
        { 'descripcion': 'PORTEZUELO', 'provincia': 'San Luis' },
        { 'descripcion': 'POSTA DE FIERRO', 'provincia': 'San Luis' },
        { 'descripcion': 'POSTA DEL PORTEZUELO', 'provincia': 'San Luis' },
        { 'descripcion': 'POTRERILLO', 'provincia': 'San Luis' },
        { 'descripcion': 'POTRERO DE FUNES', 'provincia': 'San Luis' },
        { 'descripcion': 'POTRERO DE LOS FUNES', 'provincia': 'San Luis' },
        { 'descripcion': 'POTRILLO , cerro', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO CAVADO', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO CERCADO', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO DE LAS RAICES', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO DE LOS RAYOS', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO DEL CARRIL', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO DEL ESPINILLO', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO DEL MEDIO', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO DEL TALA', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO ESCONDIDO', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO FRIO', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO SANTIAGO', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO SECO', 'provincia': 'San Luis' },
        { 'descripcion': 'POZO SIMON', 'provincia': 'San Luis' },
        { 'descripcion': 'PRIMER AGUA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUENTE HIERRO', 'provincia': 'San Luis' },
        { 'descripcion': 'PUENTE LA ORQUETA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUERTA DE LA ISLA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUERTA DE PALO', 'provincia': 'San Luis' },
        { 'descripcion': 'PUESTITO', 'provincia': 'San Luis' },
        { 'descripcion': 'PUESTO BELLA VISTA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUESTO DE LOS JUMES', 'provincia': 'San Luis' },
        { 'descripcion': 'PUESTO DE TABARES', 'provincia': 'San Luis' },
        { 'descripcion': 'PUESTO EL TALA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUESTO PAMPA INVERNADA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUESTO QUEBRADA CAL', 'provincia': 'San Luis' },
        { 'descripcion': 'PUESTO ROBERTO', 'provincia': 'San Luis' },
        { 'descripcion': 'PUESTO TALAR', 'provincia': 'San Luis' },
        { 'descripcion': 'PUNILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUNTA DE AGUA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUNTA DE LA LOMA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUNTA DE LA SIERRA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUNTA DEL ALTO', 'provincia': 'San Luis' },
        { 'descripcion': 'PUNTA DEL CERRO', 'provincia': 'San Luis' },
        { 'descripcion': 'PUNTOS DE AGUA', 'provincia': 'San Luis' },
        { 'descripcion': 'PUNTOS DE LA LINEA', 'provincia': 'San Luis' },
        { 'descripcion': 'QUEBRACHITO', 'provincia': 'San Luis' },
        { 'descripcion': 'QUEBRADA DE LA BURRA', 'provincia': 'San Luis' },
        { 'descripcion': 'QUEBRADA DE LA MORA', 'provincia': 'San Luis' },
        { 'descripcion': 'QUEBRADA DE LOS BARROSOS', 'provincia': 'San Luis' },
        { 'descripcion': 'QUEBRADA DE LOS CONDORES', 'provincia': 'San Luis' },
        { 'descripcion': 'QUEBRADA DE SAN VICENTE', 'provincia': 'San Luis' },
        { 'descripcion': 'QUEBRADA DEL TIGRE', 'provincia': 'San Luis' },
        { 'descripcion': 'QUEBRADA HONDA', 'provincia': 'San Luis' },
        { 'descripcion': 'QUINES', 'provincia': 'San Luis' },
        { 'descripcion': 'QUINES , río', 'provincia': 'San Luis' },
        { 'descripcion': 'QUINTO', 'provincia': 'San Luis' },
        { 'descripcion': 'QUINTO , río', 'provincia': 'San Luis' },
        { 'descripcion': 'RAMADITA', 'provincia': 'San Luis' },
        { 'descripcion': 'RANQUELCO', 'provincia': 'San Luis' },
        { 'descripcion': 'REAL', 'provincia': 'San Luis' },
        { 'descripcion': 'REFORMA CHICA', 'provincia': 'San Luis' },
        { 'descripcion': 'RENCA', 'provincia': 'San Luis' },
        { 'descripcion': 'REPRESA DEL CARMEN', 'provincia': 'San Luis' },
        { 'descripcion': 'REPRESA DEL CHANAR', 'provincia': 'San Luis' },
        { 'descripcion': 'REPRESA DEL MONTE', 'provincia': 'San Luis' },
        { 'descripcion': 'RETAMO', 'provincia': 'San Luis' },
        { 'descripcion': 'RETAZO DEL MONTE', 'provincia': 'San Luis' },
        { 'descripcion': 'RETIRO', 'provincia': 'San Luis' },
        { 'descripcion': 'RIECITO', 'provincia': 'San Luis' },
        { 'descripcion': 'RINCON DEL CARMEN', 'provincia': 'San Luis' },
        { 'descripcion': 'RINCON DEL ESTE', 'provincia': 'San Luis' },
        { 'descripcion': 'RIO JUAN GOMEZ', 'provincia': 'San Luis' },
        { 'descripcion': 'RIO QUINTO', 'provincia': 'San Luis' },
        { 'descripcion': 'RIOJITA', 'provincia': 'San Luis' },
        { 'descripcion': 'RODEO CADENAS', 'provincia': 'San Luis' },
        { 'descripcion': 'ROMANCE', 'provincia': 'San Luis' },
        { 'descripcion': 'ROSARIO', 'provincia': 'San Luis' },
        { 'descripcion': 'ROSARIO , río', 'provincia': 'San Luis' },
        { 'descripcion': 'RUMIGUASI', 'provincia': 'San Luis' },
        { 'descripcion': 'SALADILLO', 'provincia': 'San Luis' },
        { 'descripcion': 'SALADO DE AMAYA', 'provincia': 'San Luis' },
        { 'descripcion': 'SALINAS', 'provincia': 'San Luis' },
        { 'descripcion': 'SALINAS DEL BEBEDERO', 'provincia': 'San Luis' },
        { 'descripcion': 'SALINAS GRANDES , salinas', 'provincia': 'San Luis' },
        { 'descripcion': 'SALINAS GRANDES , salinas', 'provincia': 'San Luis' },
        { 'descripcion': 'SALITRAL', 'provincia': 'San Luis' },
        { 'descripcion': 'SALTO CHICO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN ALBERTO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN ALEJANDRO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN AMBROSIO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN ANTONIO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN CELESTINO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN EDUARDO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN FCO DEL MONTE DE ORO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN FELIPE', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN FRANCISCO DEL MONTE DE ORO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN GERÓNIMO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN ISIDRO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN JOSE DE LOS CHANARES', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN JOSE DEL DURAZNO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN JOSÉ DEL MORRO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN JUAN DE TASTU', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN LUIS', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN MARTIN DEL ALTO NEGRO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN MIGUEL', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN NICOLAS PUNILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN PABLO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN PEDRO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN RAIMUNDO', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN RAMON SUD', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN ROQUE', 'provincia': 'San Luis' },
        { 'descripcion': 'SAN RUFINO', 'provincia': 'San Luis' },
        { 'descripcion': 'SANT ANA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA CECILIA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA DIONISIA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA FELISA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA LUCINDA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA MARTINA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA ROSA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA ROSA ,EST.ADOLFO RODRIGUEZ ZAA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA ROSA DE CONLARA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA ROSA DEL GIGANTE', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA RUFINA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA SIMONA', 'provincia': 'San Luis' },
        { 'descripcion': 'SANTA TERESA', 'provincia': 'San Luis' },
        { 'descripcion': 'SAUCESITO', 'provincia': 'San Luis' },
        { 'descripcion': 'SELCI', 'provincia': 'San Luis' },
        { 'descripcion': 'SERAFINA', 'provincia': 'San Luis' },
        { 'descripcion': 'SOCOSCORA', 'provincia': 'San Luis' },
        { 'descripcion': 'SOL DE ABRIL', 'provincia': 'San Luis' },
        { 'descripcion': 'SOL DE ABRIL DPTO SAN MARTIN', 'provincia': 'San Luis' },
        { 'descripcion': 'SOLA , loma', 'provincia': 'San Luis' },
        { 'descripcion': 'SOLOBASTA', 'provincia': 'San Luis' },
        { 'descripcion': 'SOLOLOSTA', 'provincia': 'San Luis' },
        { 'descripcion': 'SOVEN', 'provincia': 'San Luis' },
        { 'descripcion': 'SUYUQUE', 'provincia': 'San Luis' },
        { 'descripcion': 'SUYUQUE NUEVO', 'provincia': 'San Luis' },
        { 'descripcion': 'TALA VERDE', 'provincia': 'San Luis' },
        { 'descripcion': 'TALARCITO', 'provincia': 'San Luis' },
        { 'descripcion': 'TALITA', 'provincia': 'San Luis' },
        { 'descripcion': 'TAMASCANES', 'provincia': 'San Luis' },
        { 'descripcion': 'TAMBOREO', 'provincia': 'San Luis' },
        { 'descripcion': 'TASTO', 'provincia': 'San Luis' },
        { 'descripcion': 'TAZA BLANCA', 'provincia': 'San Luis' },
        { 'descripcion': 'TEMERARIA', 'provincia': 'San Luis' },
        { 'descripcion': 'TILISARAO', 'provincia': 'San Luis' },
        { 'descripcion': 'TINAJA , cerro', 'provincia': 'San Luis' },
        { 'descripcion': 'TINTITACO', 'provincia': 'San Luis' },
        { 'descripcion': 'TOIGUS', 'provincia': 'San Luis' },
        { 'descripcion': 'TOINGUA', 'provincia': 'San Luis' },
        { 'descripcion': 'TORO BAYO', 'provincia': 'San Luis' },
        { 'descripcion': 'TORO NEGRO', 'provincia': 'San Luis' },
        { 'descripcion': 'TOSCAL', 'provincia': 'San Luis' },
        { 'descripcion': 'TOTORILLA', 'provincia': 'San Luis' },
        { 'descripcion': 'TRANSVAL', 'provincia': 'San Luis' },
        { 'descripcion': 'TRAPICHE', 'provincia': 'San Luis' },
        { 'descripcion': 'TRAVESIA', 'provincia': 'San Luis' },
        { 'descripcion': 'TRECE DE ENERO', 'provincia': 'San Luis' },
        { 'descripcion': 'TREINTA DE OCTUBRE', 'provincia': 'San Luis' },
        { 'descripcion': 'TRES AMIGOS', 'provincia': 'San Luis' },
        { 'descripcion': 'TRES CANADAS', 'provincia': 'San Luis' },
        { 'descripcion': 'TRES MARIAS', 'provincia': 'San Luis' },
        { 'descripcion': 'TRES PUERTAS', 'provincia': 'San Luis' },
        { 'descripcion': 'TUKIROS', 'provincia': 'San Luis' },
        { 'descripcion': 'UCHAIMA', 'provincia': 'San Luis' },
        { 'descripcion': 'UNIÓN', 'provincia': 'San Luis' },
        { 'descripcion': 'USIYAL', 'provincia': 'San Luis' },
        { 'descripcion': 'USPARA', 'provincia': 'San Luis' },
        { 'descripcion': 'VACAS MUERTAS', 'provincia': 'San Luis' },
        { 'descripcion': 'VALLE DE LA PANCANTA', 'provincia': 'San Luis' },
        { 'descripcion': 'VALLE SAN AGUSTIN', 'provincia': 'San Luis' },
        { 'descripcion': 'VALLE SAN JOSE', 'provincia': 'San Luis' },
        { 'descripcion': 'VARELA', 'provincia': 'San Luis' },
        { 'descripcion': 'VENTA DE LOS RIOS', 'provincia': 'San Luis' },
        { 'descripcion': 'VIEJA ESTANCIA', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA DE LA QUEBRADA', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA DE PRAGA', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA DEL CARMEN', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA ELENA', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA GENERAL ROCA', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA LA QUEBRADA', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA LARCA', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA LUISA', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA MERCEDES', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA REYNOLDS', 'provincia': 'San Luis' },
        { 'descripcion': 'VILLA SANTIAGO', 'provincia': 'San Luis' },
        { 'descripcion': 'VIRARCO', 'provincia': 'San Luis' },
        { 'descripcion': 'VISCACHERAS', 'provincia': 'San Luis' },
        { 'descripcion': 'VISTA HERMOSA', 'provincia': 'San Luis' },
        { 'descripcion': 'VIVA LA PATRIA', 'provincia': 'San Luis' },
        { 'descripcion': 'VIZCACHERAS', 'provincia': 'San Luis' },
        { 'descripcion': 'VOLCAN ESTANZUELA', 'provincia': 'San Luis' },
        { 'descripcion': 'YACORO', 'provincia': 'San Luis' },
        { 'descripcion': 'YRIGOYEN, HIPOLITO', 'provincia': 'San Luis' },
        { 'descripcion': 'ZAMPAL', 'provincia': 'San Luis' },
        { 'descripcion': 'ZANJITAS', 'provincia': 'San Luis' },
        { 'descripcion': '28 DE NOVIEMBRE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'AGASSIZ , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'AGUADA A PIQUE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'AGUADA ALEGRE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'AGUADA ESCONDIDA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'AGUADA GRANDE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'AGUADA LA OVEJA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ALMA GAUCHA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ALMA GRANDE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ALQUINTA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ALTO , cordÓn', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ALTO , cordÓn', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'AN AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'AÑAPI , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ANTONIO DE BIEDMA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'AP IWAN , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ARGENTINO , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ARISTIZABAL', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ASADOR o GUITARRA , laguna', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ASTILLADO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'AYMOND , monte', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BAHIA LANGARA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BAHíA LAURA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BAHIA OSO MARINO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BAHIA TRANQUILA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BAJO CARACOLES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BAJO DE LOS CARACOLES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BAJO FUEGO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BAJO PIEDRA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BAUZÁ , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BAYO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BELGRANO , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BELGRANO , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BELLA VISTA ,EST.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BLANCO , cabo', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BLANCO , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BOSQUES PETRIFICADOS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BUEN TIEMPO , cabo', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BUENOS AIRES , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BURMEISTER , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'BUSTAMANTE , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CABO BLANCO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CABO BUEN TIEMPO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CABO DE LAS VIRGENES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CABO TRES PUNTAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CABO VIRGENES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CALAFATE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CALETA OLIVIA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CAMERON', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CAMPAMENTO DOROTEA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CAMPORRO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CAMUZU AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANADA DE LAS VACAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CAÑADON 11 DE SEPTIEMBRE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANADON 11 DE SETIEMBRE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANADON BOTELLO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANADON DE LAS VACAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANADON DEL RANCHO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANADON DEL TORO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANADON FABRE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANADON LEON', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANADON MOLINARI', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANADON PLUMA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CAÑADON SECO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANCHA CARRERA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CANCHA CARRERAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CAP', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CAPITAN EYROA ,EST.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CARA MALA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CARACOLES , bajo', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CARDIEL , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CAROLINA , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CASAMAYOR , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CAZADOR , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CERRO ALTO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CERRO BLANCO ,EMB.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CERRO LA SETENTA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CERRO MANGRULLO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CERRO MORO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CERRO PALIQUE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CERRO PUNTUDO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CERRO REDONDO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CERRO RENZEL', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CERRO SILVA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CHALíA o SHEHUEN , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CHARLES FUHR', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CHICO , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CHIMEN AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CHONQUE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CIUDADELA , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'COCODRILO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'COJUDO BLANCO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'COLONIA LEANDRO N ALEM', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'COLONIA PERITO MORENO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'COLORADO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'COMANDANTE LUIS PIEDRA BUENA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'COMANDANTE LUIS PIEDRA BUENA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'COMANDANTE LUIS PIEDRABUENA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CONDOR CLIF', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CONO GRANDE , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CORFEN , arroyo', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CORONA , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CORONEL GUARUMBA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CORONEL MARTIN IRIGOYEN', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'COY AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CUEVA DE LAS MANOS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'CURIOSO , cabo', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'DAÑOSO , cabo', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'DE BIEDMA, ANTONIO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'de CABO CURIOSO , salitral', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'de INTEGRACIÓN AUSTRAL , paso', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'de LAS VACAS , sierra', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'de LAS VIZCACHAS , meseta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'de LOS CISNES , laguna', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'de LOS FRAILES , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'de PIEDRA , morro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'de SANGRA , sierra', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del ASADOR , pampa', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del CARBÓN , laguna', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del DESIERTO , laguna', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del GUENGUEL , meseta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del HOMBRE MUERTO , salar', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del LAGO BUENOS AIRES , meseta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del PUESTO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del RíO DESEADO , valle', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del SELLO , laguna', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del SETENTA , pampa', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'del TALA , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'DESAMPARADOS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'DESEADO , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'DESENGAÑO , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'DESVELOS , bahía', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'DOCE GRANDE , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'DUFOUR, JULIA ,EST.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'DUNGENESS, punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ECHAG', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ECKER , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL BAILE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL BARBUCHO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL CALAFATE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL CERRITO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL CHALTÉN', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL CHARA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL CONDOR', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL GUADAL', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL HUECO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL LORO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL PAN DE AZUCAR', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL PASO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL PEDRERO , meseta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL PLUMA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL PLUMA , cordÓn', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL POLVORIN', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL PORTEZUELO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL PORVENIR , laguna', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL SALADO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL TURBIO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL TURBIO , Est. Gobernador Mayer', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL TURBIO ,EST.GOBERNADOR MEYER', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL VOLCAN', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL ZURDO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'EL ZURDO ,EST.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ENTRADA , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESPERANZA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTACIÓN CAPITÁN EYROA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTACIÓN EL ZURDO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTACIÓN GOBERNADOR MOYANO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTACION ING ATILIO CAPPA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTACIÓN JULIA DUFOUR', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA CAÑADÓN 11 DE SETIEMBRE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA CAÑADÓN DE LAS VACAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA EL CÓNDOR', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA EL SALADO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA FUENTES DEL COYLE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA LA ARAGONESA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA LA FEDERICA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA LA JERÓNIMA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA LA LEONESA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA MATA GRANDE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ESTANCIA MONTE DINERO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FARO CABO GUARDIAN', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FARO CAMPANA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FÉNIX GRANDE , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FITZ ROY', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FITZ ROY , monte', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FLECHA NEGRA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FLORADORA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FLORIDA NEGRA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FOCA , península', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FORTALEZA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FRíAS , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FUENTES DE COYLE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FUENTES DEL COYLE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'FUHR, CHARLES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GALLEGOS , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GARMINUE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GAYPON', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GENDARME BARRETO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GHíO , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GLACIAR PERITO MORENO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GLENCROSS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GOBERNADOR GREGORES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GOBERNADOR LISTA ,EST.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GOBERNADOR MAYER', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GOBERNADOR MOYANO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GOBERNADOR MOYANO ,EST.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GRAN ALTIPLANICIE CENTRAL , meseta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GRAN BAJO DE SAN JULIÁN , depresiÓn', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GRANDE , bahía', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GRANDE , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GRANDE , laguna', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GRANDE DEL RíO CHICO , isla', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GUANACO , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GUARDAPARQUE FITZ ROY', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GUARDIÁN , cabo', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GUER AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'GUZMÁN , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'HATCHER , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'HILL STATION', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'HOTEL LAS HORQUETAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'HUEMUL , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'IGLESIAS , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'INDIA MUERTA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'INFANTE , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'INGENIERO ATILIO CAPPA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'INGENIERO PALLAVICINI', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ISLAS DEL ATLANTICO SUR', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'JARAMILLO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'JELAINA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'JULIA DUFOUR', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'KENSEL , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'KILOMETRO 199 ,DV.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'KOLUEL KAIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'KOLUEL KAYKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA ANTONIA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA ARAGONESA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA BARRETA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA CENTRAL', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA CRIOLLA , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA ESTELA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA ESTHER', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA FECUNDIDAD', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA FEDERICA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA JULIA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA LEONA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA LEONA , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA MADRUGADA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA MANCHURIA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA MARGARITA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA MARIA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA MATA , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA PENINSULA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA PIGMEA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA PROTEGIDA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA ROSA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA ROSADA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LA VICTORIA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGO ARGENTINO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGO BUENOS AIRES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGO CARDIEL', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGO POSADAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGO PUEYRREDON', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGO ROCA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGO SAN MARTIN', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGO STROBEL', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGO TAR', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGO VIEDMA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGUNA COLORADA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAGUNA GRANDE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAI AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAS HERAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAS HERAS ,EST.COLONIA LAS HERAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAS HORQUETAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAS MASITAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAS PIRAMIDES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAS SIERRAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAS TRES HERMANAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LAURA , bahía', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LEANDRO NICEFORO ALEM', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LISTA, RAMON ,EST.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LÓPEZ , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LOS ANTIGUOS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LOS MONOS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'LOYOLA , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MATA AMARILLA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MATA GRANDE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MATA MAGALLANES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MAZAREDO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MAZARREDO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MEDANOSA , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MESETA GUENGUE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MIÉ , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MINA 3', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MINERALES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MINERALES ,EST.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MINIK AIKE , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MONTE AYMOND', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MONTE CEBALLOS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MONTE DINERO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MONTE LEON', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MONTE VERDE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MONTES , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MONTEVIDEO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MONUMENTO NATURAL', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MORRO CHICO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'MURALLÓN , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'NACIMIENTOS DEL PLUMA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'NANSEN , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'NEGRO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'NORTE , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'OLNIE , laguna', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'OLNIE u OLíN , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'Otra', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'OVERO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PALERMO AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PALI AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PAMPA ALTA ,EMB.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PAMPA VERDUM', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PANA , cerrro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PARQUE NACIONAL FRANCISCO P. MORENO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PARQUE NACIONAL LOS GLACIARES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PARQUE NACIONAL NAHUEL HUAPI', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PASO CHARLES FHUR', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PASO DEL AGUILA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PASO DEL MEDIO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PASO DEL RIO SANTA CRUZ', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PASO GREGORES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PASO IBANEZ', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PASO RIO LA LEONA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PASO ROBALLO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PASO RODOLFO ROBALLOS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PATRIMONIO MUNDIAL DE LA HUMANIDAD', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PELQUE , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PENINSULA MAIPU', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PERITO MORENO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PICO TRUNCADO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PICUDO , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PIEDRA CLAVADA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PIEDRA CLAVADA ,EST.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PINTURAS , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'POIVRE , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'POLAR , meseta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUENTE BLANCO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUERTO BANDERA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUERTO COIG', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUERTO DESEADO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUERTO SAN JULIÁN', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUERTO SANTA CRUZ', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUEYRREDÓN , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUNTA BANDERA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUNTA DEL LAGO VIEDMA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUNTA DEL MONTE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUNTA GRUESA , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUNTA LOYOLA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'PUNTA MERCEDES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'QUIEN SABE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'QUILLA , punta', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'QUIROGA , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'RINCON', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'RINCON DE LOS MORROS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'RIO BOTE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'RIO CALAFATE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'RIO CHICO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'RIO FENIX', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'RíO GALLEGOS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'RIO MITRE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'RIO TURBIO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'RODOLFO ROBALLOS , paso', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ROSPENTEK', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ROSPENTEK AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'SALITROSO , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'SAN JULIÁN , bahía', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'SAN LORENZO , monte', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'SAN MARTíN , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'SANTA CRUZ , río', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'SARAI', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'SIRVEN , laguna', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'STOKES , monte', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'STROBEL , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TAMEL AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TAPI AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TAR , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TAUEL AIKE', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TEHUELCHES', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TEHUELCHES ,AP.', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TELLIER', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TRES CERROS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TRES LAGOS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TRES PICOS , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TRES PUNTAS', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TRES PUNTAS , cabo', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'TUCU TUCU', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'VEGA MALA , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'VENTANA , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'VENTISQUERO MORENO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'VIEDMA , lago', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'VIGíA , cabo', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'VíRGENES , cabo', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'VOLCÁN , cerro', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'YACIMIENTO CERRO VANGUARDIA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'YACIMIENTO RíO TURBIO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'YEGUA MUERTA', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ZANJON DEL PESCADO', 'provincia': 'Santa Cruz' },
        { 'descripcion': 'ZEBALLOS , monte', 'provincia': 'Santa Cruz' },
        { 'descripcion': '22 DE MAYO', 'provincia': 'Santa Fe' },
        { 'descripcion': '29', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AARON CASTELLANOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ABIPONES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ACEBAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ACHAVAL RODRIGUEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ADELA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ADOLFO ALSINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AERO CLUB ARGENTINO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AERO CLUB ROSARIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AGUARA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AGUARA GRANDE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ALBARELLOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ALCORTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ALDAO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ALDAO , Est. Casablanca', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ALEJANDRA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ALLENDE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ALTO VERDE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ALVAREZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ALVEAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AMBROSETTI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AMENABAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AMORES , río', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ANDINO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ANGEL GALLARDO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ANGELICA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ANTARTIDA ARGENTINA ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ANTONIO PINI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AREQUITO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARIJON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARIJON ,DV.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARMINDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARMSTRONG', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AROCENA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AROMOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AROMOS ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARON CASTELLANOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARRASCAETA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARRASCAETA ,AP.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARROYO AGUIAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARROYO AGUIAR ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARROYO CEIBAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARROYO DEL REY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARROYO LEYES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARROYO SECO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARRUFO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARSENAL DE GUERRA SAN LORENZO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ARTEAGA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ASCOCHINGAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ASCOCHINGAS ,DV.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ASUNCION MARIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ATALIVA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AURELIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AURELIA NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AURELIA SUD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AURELIA SUR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AVELLANEDA , Est. Ewald', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AVENA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'AVICHUNCHO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BAJO LAS TUNAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BARABEVÚ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BARLETT', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BARRANCAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BARRANQUITAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BARRIO BELGRANO ORTIZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BARRIO CAIMA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BAUER Y SIGEL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BEALISTOCK', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BELLA ITALIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BERABEVU', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BERABEVU 1', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BERNA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BERNARD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BERNARDO DE IRIGOYEN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BERRETTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BIGAND', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BOCA P 25', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BOMBAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BORGHI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BOUQUET', 'provincia': 'Santa Fe' },
        { 'descripcion': 'BUSTINZA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CABAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CABANA EL CISNE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CABEZA DE CHANCHO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CACIQUE ARIACAIQUIN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAFFERATA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CALCHAQUí', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CALCHINES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CALCHINES o LAS AVES , laguna', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMINERA GENERAL LOPEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMINO MONTE FLORES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO ANDINO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO BERRAZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO BOTTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO BRARDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO CALVO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO CARIGNANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO CASTRO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO CHARO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO CLUCELLAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO COUBERT', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO CRENNA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO CRESPO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO DARATTI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO DEL MEDIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO EL ARAZA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO EL MATACO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO FAGGIANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO FURRER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO GALLOSO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO GARABATO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO GARAY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO GARCIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO GENERO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO GIMBATTI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO GIMENEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO GOLA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO HARDY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO HORQUESCO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO HUBER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO ITURRASPE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO LA AMISTAD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO LA PAZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO LA RIVIERE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO LEHMAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO MAGNIN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO MEDINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO MONTE LA VIRUELA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO MOURE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO NUEVO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO PALETTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO PESOA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO QUINONES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO QUIRNO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO RAFFO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO RAMSEYER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO REDONDO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO RODRIGUEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO ROMERO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO RUEDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO SAN JOSE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO SANTA ISABEL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO SANTO DOMINGO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO SIETE PROVINCIAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO TORQUINSTON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO UBAJO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO URDANIZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO VERGE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO YAGUARETE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO ZAVALLA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAMPO ZURBRIGGEN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAÑADA DE GÓMEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAÑADA DEL UCLE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAÑADA OMBÚ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CANADA RICA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAÑADA RICA ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAÑADA ROSQUíN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CANADITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CANDIOTI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAPILLA GUADALUPE NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAPILLA SAN JOSE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAPITÁN BERMUDEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAPIVARA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARAGUATAY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARCARAÑÁ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARCARAÑÁ , río', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARCEL MODELO CORONDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARLOS DOSE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARLOS PELLEGRINI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARMEN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARMEN DEL SAUCE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARRERAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARRIZALES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CARRIZALES ,EST.CLARKE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CASABLANCA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CASABLANCA ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CASALEGNO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CASAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CASILDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CASTELAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CASTELLANOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CASTELLANOS, AARON ,EST.CASTELLANOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAVOUR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAYASTÁ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CAYASTACITO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CENTENO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CEPEDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CERANA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CERES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CERRITOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CHABÁS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CHAÑAR LADEADO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CHAPUY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CHATEAUBRIAND', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CHATEAUBRIAND ,EST.MURPHY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CHOVET', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CHRISTOPHERSEN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CICARELLI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CLARKE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CLASON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CLUCELLAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CLUCELLAS ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLASTINE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLASTINE NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLMENA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA ADOLFO ALSINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA ALDAO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA ALTHUAUS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA ANA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA ANGELONI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA BELGRANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA BELLA ITALIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA BERLIN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA BICHA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA BIGAND', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA BOSSI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA CALIFORNIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA CAMPO BOTTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA CANDELARIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA CASTELAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA CASTELLANOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA CELLO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA CLARA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA CLODOMIRA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA CORONDINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA DOLORES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA DOS ROSAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA DURAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA EL OCHENTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA EL SIMBOL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA EL TOBA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA EL VEINTICINCO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA ELLA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA ESCRIBANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA FERNANDEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA FIDELA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA FRANCESA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA GOMEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA HANSEN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA INDEPENDENCIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA JOSEFINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA BLANCA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA CAMILA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA CATALANA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA COSTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA MARIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA MORA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA NEGRA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA NICOLASA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA NUEVA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA PALENCIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA PELLEGRINI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA PENCA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LA YERBA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LAGO DI COMO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA LOS ZAPALLOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA MALHMAN SUD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA MANUEL MENCHACA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA MARGARITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA MASCIAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA MATILDE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA MAUA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA MEDICI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA MONTEFIORE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA MORGAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA NUEVA NARCISO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA ORTIZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA PIAGGIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA PIAMONTESA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA PUJOL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA RAQUEL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA REINA MARGARITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA RIPAMONTI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA ROSA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SAGER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SAN ANTONIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SAN FRANCISCO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SAN JOAQUIN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SAN MANUEL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SAN ROQUE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SANTA ANITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SANTA CATALINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SANTA LUCIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SANTA NATALIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SILVA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SILVA ,EST.ABIPONES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA SOL DE MAYO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA TACURALES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA TERESA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA TOSCANA PRIMERA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA TOSCANA SEGUNDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA TRES MARIAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA TRES REYES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA VALDEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA VALENCIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COLONIA YAGUARETE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CONSTANZA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CONSTITUYENTES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORONDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORONDA , laguna', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORONEL AGUIRRE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORONEL ARNOLD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORONEL BOGADO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORONEL DOMINGUEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORONEL FRAGA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORONEL RODRIGUEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORONEL ROSETI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORONEL ROSETTI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CORREA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'COSTA DEL TOBA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CRESTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CRISPI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CRISTOLIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CUATRO CASAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CUATRO DE FEBRERO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CULLEN ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CULULU', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CULULÚ , arroyo', 'provincia': 'Santa Fe' },
        { 'descripcion': 'CURUPAITY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'de LA CUEVA DEL TIGRE o de LAS PENCAS , laguna', 'provincia': 'Santa Fe' },
        { 'descripcion': 'del CRISTAL , laguna', 'provincia': 'Santa Fe' },
        { 'descripcion': 'del CUERNO , portezuelo', 'provincia': 'Santa Fe' },
        { 'descripcion': 'del PALMAR , laguna', 'provincia': 'Santa Fe' },
        { 'descripcion': 'del REY , arroyo', 'provincia': 'Santa Fe' },
        { 'descripcion': 'del TORO , laguna', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DENIS, GREGORIA PEREZ DE ,EST.EL NOCHERO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DESVIO ARAUZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DESVIO ARIJON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DESVIO BOERO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DESVIO DR BARROS PAZOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DHO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DíAZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DIEGO DE ALVEAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DISTRITO 3 ISLETAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DOCE CASAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DOCTOR BARROS PAZOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DURHAM', 'provincia': 'Santa Fe' },
        { 'descripcion': 'DURHAM ,AP.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ECHEVERRIA, VICENTE A.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EDISON, TOMAS A.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EGUSQUIZA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL 38', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL 44', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL AGUARA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL ALBERDON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL AMARGO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL ARAZA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL BAGUAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL BAYO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL BONETE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL CANTOR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL CANTOR ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL CARAMELO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL CARMEN DE AVELLANEDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL CEIBO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL CINCUENTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL DIECISIETE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL GALPON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL GUSANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL JARDIN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL LAUREL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL MARIANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL NOCHERO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL PAJARO BLANCO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL PARA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL POZO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL RABON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL REFUGIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL RICARDITO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL SOMBRERERO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL SOMBRERITO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL SOMBRERITO ,EST.PAUL GROUSSAC', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL TAJAMAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL TAPIALITO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL TIMBO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL TOBA , arroyo', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL TOBIANO , laguna', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL TRANSITO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL TREBOL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL TRIANGULO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EL TROPEZON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ELISA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ELORTONDO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EMILIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EMPALME SAN CARLOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EMPALME VILLA CONSTITUCION', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ENCADENADAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESCALADA, MARCELINO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESCALADA, MIGUEL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESMERALDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESMERALDITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESPERANZA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESPIADERO , cerro', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESPIN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESQUINA GRANDE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTACION CHRISTOPHERSEN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTACION CLUCELLAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTACION ERASTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTACION JOSEFINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTACION MARIA JUANA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTACION MATILDE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTACION SAGUIER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTACION TEODELINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTACION VILLA CONSTITUCION', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTANCIA ACHALA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTANCIA LA CIGUENA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTANCIA LA CONSTANCIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTANCIA LA MARIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTANCIA LAS GAMAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTANCIA LOS PALMARES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTANCIA PAVENHAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTANCIA PRUSIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTANCIA SAN ANTONIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTANCIA SAN FRANCISCO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTEBAN RAMS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTHER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTRADA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ESTRADA, JOSE M.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EUSEBIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EUSTOLIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'EWALD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FABRICA MILITAR SAN LORENZO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FASSI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FELICIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FIGHIERA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FIRMAT', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FIVES LILLE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FLOR DE ORO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FLORENCIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FLORIDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTIN ALERTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTIN ALMAGRO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTIN ARGENTINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTIN ATAHUALPA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTIN CACIQUE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTIN CHARRUA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTIN CHILCAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTíN OLMOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTIN SEIS DE CABALLERIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTIN TACURU', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FORTIN TOSTADO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FRANCISCO PAZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FRANCK', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FRAY LUIS BELTRÁN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FRAY LUIS BELTRAN ,EST.TTE.CNL.L.BELTRAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FRONTERA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FUENTES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FUNES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'FUNES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GABOTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GALISTEO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GALLARDO, ANGEL ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GÁLVEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GARABATO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GARIBALDI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GATO COLORADO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GAVIOTAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GENERAL GELLY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GENERAL LAGOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GESSLER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GOBERNADOR CANDIOTI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GOBERNADOR CRESPO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GODEKEN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GODOY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GODOY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GOLONDRINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GOMEZ CELLO, PEDRO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GRANADERO B BARGAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GRANADERO BAIGORRIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GRANADERO BLAS BARGAS ,AP.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GRANADERO BRASILIO BUSTOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GRANADEROS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GRANADEROS ,PDA.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GRANEROS ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GRANJA SAN MANUEL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GREGORIA PEREZ DE DENIS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GREGORIA PÉREZ DE DENIS , Est. El Nochero', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GRUTLY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GRUTLY NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GUADALUPE ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GUADALUPE NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GUADALUPE NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GUARANIES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GUASUNCHO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'GUAYCURU', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HANSEN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HELVECIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HERSILIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HIPATIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HUANQUEROS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HUGENTOBLER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HUGES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HUGHES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HUMBERTO PRIMERO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HUMBOLDT', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HUMBOLDT CHICO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'HUME ,EST.EL GAUCHO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'IBARLUCEA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'INDEPENDENCIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'INGENIERO BOASI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'INGENIERO CHANOURDIE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'INGENIERO GARMENDIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'INGENIERO GERMANIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'INTIYACO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'IRIGOYEN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'IRIGOYEN, BERNARDO DE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'IRIONDO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ISLA DEL PORTENO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ISLA TIGRE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ITURRASPE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'JOSE MACIAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'JOSE MANUEL ESTRADA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'JOSEFINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'JUAN B MOLINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'JUAN ORTIZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'JUNCAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETR 465 ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 17 ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 187 ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 213 ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 320', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 392 ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 403 ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 405 ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 408 ,AP.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 41 ,AP.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 41 ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 443 ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 468 ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 483 ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 501 ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 85 ,AP.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'KILOMETRO 9 ,AP.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA ADELAIDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA BLANCA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA BLANCA CENTRAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA BOMBILLA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA BRAVA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA BUENA YERBA , cerro', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CABRAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CALIFORNIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CALMA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CAMILA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CAROLINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CATALINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CELIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CERAMICA Y CUYO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CHISPA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CIGUENA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CLARA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CLORINDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA CRIOLLA , Est. CaÑadita', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA DIAMELA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA ELSA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA GALLARETA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA GAMA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA GUAMPITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA GUARDIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA HOSCA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA INES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA INGLESITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA JOSEFINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA JULIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA LATA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA LOCA , laguna', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA LOLA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA LOMA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA LUCILA ,EST.LUCILA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA MOROCHA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA ORILLA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA OTHILIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA PELADA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA PENCA Y CARAGUATA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA PICASA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA POLVAREDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA POTASA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA RESERVA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA ROSA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA RUBIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA SARITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA SARNOSA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA SELVA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA SEMENTERA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA VANGUARDIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA VIUDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LA ZULEMA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LABORDEBOY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAGUNA PAIVA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAGUNA VERDE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LANDETA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LANTERI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LARGUIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LARRECHEA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS ANINTAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS ARENAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS AVISPAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS BANDURRIAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS CAÑAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS CATALINAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS CHUNAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS DOS ANGELITAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS ENCADENADAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS FLORES ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS GAMAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS GARSITAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS GARZAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS GRAZAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS HIGUERITAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS LIEBRES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS PALMERAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS PAREJAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS PETACAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS QUINTAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS ROSAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS SIETE PROVINCIAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS TOSCAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS TROJAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS TROJAS ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAS TUNAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LASSAGA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LAZZARINO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LEHMANN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LEIVA, LUCIANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LICEO AERONAUTICO MILITAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LINKS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LLAMBI CAMPBELL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOGROÑO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOMA ALTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LÓPEZ , Est. San Martín de Tours', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOPEZ, LUCIO V.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS AMORES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS AMORES , arroyo', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS ARCOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS CARDENALES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS CARDOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS CERRILLOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS CHARABONES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS CHARABONES ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS CLAROS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS CORRALITOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS CUERVOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS GALPONES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS HORNOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS LAPACHOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS LAURELES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS LEONES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS MOLINOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS MUCHACHOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS NOGALES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS OLIVOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS OSOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS QUIRQUINCHOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS SALADILLOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS SEMBRADOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS TABANOS ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LOS TABANOS DESVIO KM 366', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LUCIANO LEIVA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LUCIO V LOPEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LUDUEÑA ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LUIS PALACIOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'LUIS PALACIOS , Est. La Salada', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MACIEL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MAGGIOLO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MAIZALES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MALABRIGO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MANGORE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MANUCHO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MARCELINO ESCALADA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MARGARITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MARIA EUGENIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MARIA JUANA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MARIA LUISA CORREA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MARíA SUSANA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MARíA TERESA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MARIANO SAAVEDRA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MASCIAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MATILDE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MAUA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MÁXIMO PAZ , Est. Paz', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MELINCUE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MELINCUÉ , Est. San Urbano', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MELINCUÉ , laguna', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MERCEDITAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MIGUEL ESCALADA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MOCOVI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MOISÉS VILLE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MOLINA, JUAN B.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MOLINAS, NICANOR E.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MONIGOTES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MONJE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MONTE FLORES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MONTE OBSCURIDAD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MONTE VERA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MONTEFIORE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MONTES DE OCA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MOUSSY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MURPHY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'MUTCHNIK', 'provincia': 'Santa Fe' },
        { 'descripcion': 'NANDU', 'provincia': 'Santa Fe' },
        { 'descripcion': 'NANDUBAY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ÑANDUCITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'NARE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'NELSON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'NICANOR E MOLINAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'NUEVA ITALIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'NUEVA LEHMANN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'NUEVA UKRANIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'NUEVO ALBERDI ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'NUEVO TORINO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'OBRAJE INDIO MUERTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'OBRAJE SAN JUAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'OGILIVE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'OGILVIE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'OLIVEROS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'OMBU', 'provincia': 'Santa Fe' },
        { 'descripcion': 'OMBU NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ORATORIO MORANTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'OROÑO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'Otra', 'provincia': 'Santa Fe' },
        { 'descripcion': 'OTTO BEMBERG', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PADRE PEDRO ITURRALDE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PAGANINI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PAIKIN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PALACIOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PALACIOS, LUIS ,EST.LA SALADA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PARAJE TRAGNAGHI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PARANÁ MINí , río', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PASO VINAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PAUL GROUSSAC', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PAVENHAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PAVON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PAVON ARRIBA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PAZ, FRANCISCO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PAZ, MAXIMO ,EST.PAZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PEDRO GOMEZ CELLO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PELLEGRINI, CARLOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PEREYRA LUCENA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PEREYRA, ZENON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PÉREZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PERICOTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PETRONILA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PEYRANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PIAMONTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PILAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PINI, ANTONIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PINO DE SAN LORENZO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PINO DE SAN LORENZO ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PIQUETE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PLAZA CLUCELLAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'POMPEYA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'POMPEYA ,AP.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PORTALIS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PORTUGALETE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PORTUGALETTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'POTRERO GUASUNCHO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'POZO BORRADO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'POZO DE LOS INDIOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PRESIDENTE ROCA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PROGRESO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PROVIDENCIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO ABC', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO ALCORTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO ANDINO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO AREQUITO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO CANDIOTI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO ESTHER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO GOLONDRINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO KIRSTON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO MARIA JUANA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO MARINI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO MIGUEL TORRES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO MUNOZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO MUÑOZ ,EST.BERNARD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO NUEVO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO RAMONA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO SAN ANTONIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO SAN BERNARDO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUEBLO TERRAGNI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUENTE COLASTINE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUERTO ARAGON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUERTO DE SAN LORENZO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUERTO GABOTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUERTO GENERAL SAN MARTíN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUERTO GRAL SAN MARTIN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUERTO OCAMPO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUERTO PIRACUA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUERTO PIRACUACITO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUERTO RECONQUISTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUERTO SAN LORENZO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUJATO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'PUJATO NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RABIOLA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RAFAELA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RAMAYON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RAMONA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RAMS, ESTEBAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RAQUEL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RASTREADOR FOURNIER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RASTREADOR FOURNIER ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RECONQUISTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RECONQUISTA DEST AER MILITAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RECREO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RECREO SUR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'REINA MARGARITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'REYNALDO CULLEN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RICARDONE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RIGBY', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RINCON DE GRONDONA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RINCON DE SAN ANTONIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RINCON DE TACURALES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RINCON DEL PINTADO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RINCON DEL POTRERO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RINCON DEL QUEBRACHO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RINCON DEL QUEBRACHO ,EMB.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RINCON NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RINCON POTREROS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RINLON DE AVILA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RIO SALADO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RODOLFO ALCORTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ROLDÁN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ROMANG', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ROSARIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RUEDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RUEDA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RUFINO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RUINAS SANTA FE LA VIEJA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'RUNCIMAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SA PEREYRA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAAVEDRA, MARIANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAGUIER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SALADERO M CABAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SALADERO M. CABAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SALADILLO , arroyo', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SALADILLO AMARGO , arroyo', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SALADILLO DULCE , arroyo', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SALTO GRANDE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN ALBERTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN ANTONIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN ANTONIO , arroyo', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN ANTONIO DE OBLIGADO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN BERNARDO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN CARLOS ,EMP.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN CARLOS CENTRO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN CARLOS NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN CARLOS SUD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN CARLOS SUR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN CRISTÓBAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN EDUARDO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN ESTANISLAO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN EUGENIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN FABIAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN FRANCISCO DE SANTA FE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN GENARO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN GENARO NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN GREGORIO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN GUILLERMO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JAVIER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JAVIER , río', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JERONIMO DEL SAUCE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JERONIMO NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JERONIMO SUD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JERONIMO SUR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JORGE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JOSÉ DE LA ESQUINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JOSE DEL RINCON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JOSE FRONTERA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN JUSTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN LORENZO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN MARCELO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN MARCOS DE VENADO TUERTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN MARIANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN MARTIN DE LAS ESCOBAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN MARTIN DE TOURS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN MARTIN NORTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN PEDRO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN PEDRO SUR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN RICARDO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN URBANO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAN VICENTE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANCTI SPIRITU', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANFORD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA CLARA DE BUENA VISTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA CLARA DE SAGUIER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA EMILIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA FE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA FELICIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA ISABEL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA LUCIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA MARGARITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA NATALIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA PAULA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA ROSA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA ROSA DE CALCHINES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTA TERESA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTO DOMINGO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTO TOMÉ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SANTURCE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SARGENTO CABRAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SARMIENTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SASTRE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SAUCE VIEJO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SCHIFFNER', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SEMINO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SERODINO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SETUBAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SIERRA PEREYRA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SIN PEREZA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SOLDINI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SOLEDAD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SORRENTO ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SOUTO MAYOR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SOUTOMAYOR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'STEPHENSON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'STEPHENSON ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SUARDI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SUNCHALES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'SUSANA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TACUARENDI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TACUARENDI ,EMB.KILOMETRO 421', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TACURAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TAIS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TALLERES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TARRAGONA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TARTAGAL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TARTAGAL ,EST.EL TAJAMAR', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TEODELINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TEODELINA ,EST.', 'provincia': 'Santa Fe' },
        { 'descripcion': 'THEOBALD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TIMBUES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TIMBUES JOSE MARIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TOBA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TOMAS ALVA EDISON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TORRES, MIGUEL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TORTUGAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TOSTADO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TOTORAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TRAILL', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TRES COLONIAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'TTE HIPOLITO BOUCHARD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'UMBERTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'UMBERTO I', 'provincia': 'Santa Fe' },
        { 'descripcion': 'URANGA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VEINTICUATRO CASAS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VELAZQUEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VENADO TUERTO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VERA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VERA , Est. Gobernador Vera', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VERA MUJICA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VERA Y PINTADO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VERA Y PINTADO , Est. Guaraníes', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VERDE , arroyo', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VICENTE ECHEVERRIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VICTOR MANUEL SEGUNDO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VIDELA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA AMELIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA AMERICA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA ANA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA BIOTA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA CAÑÁS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA CASSINI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA CONSTITUCIÓN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA DIEGO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA DIVISA DE MAYO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA DON BOSCO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA ELOíSA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA ESTELA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA FREDICKSON', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA GOBERNADOR GÁLVEZ', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA GUASTALLA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA GUILLERMINA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA LA RIBERA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA LASTENIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA LYLY TALLERES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA MARGARITA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA MARIA SELVA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA MINETTI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA MUGUETA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA OCAMPO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA PORUCCI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA REGULES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA ROSELLO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA SAN DIEGO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA SARALEGUI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA TRINIDAD', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA VIVEROS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLA YAPEYU', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLADA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLANI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VILLE, MOISES', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VIRGINIA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VUELTA DEL PARAGUAYO', 'provincia': 'Santa Fe' },
        { 'descripcion': 'VUELTA DEL PIRATA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'WALVELBERG', 'provincia': 'Santa Fe' },
        { 'descripcion': 'WHEELWRIGHT', 'provincia': 'Santa Fe' },
        { 'descripcion': 'WILDERMUTH', 'provincia': 'Santa Fe' },
        { 'descripcion': 'WILDERMUTH ,EST.GRANADERO B.BUSTOS', 'provincia': 'Santa Fe' },
        { 'descripcion': 'YAGUARETE', 'provincia': 'Santa Fe' },
        { 'descripcion': 'YAMANDU', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ZADOCKHAN', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ZAMPONI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ZANETTI', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ZAVALLA', 'provincia': 'Santa Fe' },
        { 'descripcion': 'ZENÓN PEREYRA', 'provincia': 'Santa Fe' },
        { 'descripcion': '25 DE MAYO DE BARNEGAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': '25 DE MAYO SUD', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ABRA DE LA CRUZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ABRA DE QUIMIL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ABRA GRANDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ABRAS DEL MARTIRIZADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ABRAS DEL MEDIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ABRITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ABRITA CHICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ABRITA GRANDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ACOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ACOSTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AEROLITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AGUA AMARGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AGUA AZUL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AGUA SALADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AGUA TURBIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AGUADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AGUAS COLORADAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AGUJEREADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AGUSTINA LIBARONA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AHI VEREMOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AHI VEREMOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AIBAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AIBALITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALARCON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALBARDON CHUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALBERDI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALEJITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALGARROBAL VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALGARROBALES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALGARROBO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALHUAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALPA PUCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALPAPUCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALTO BELLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALTO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ALZA NUEVA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AMAMÁ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AMAPOLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AMBARGASTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AMICHA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AMIMAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AMOLADERAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AÑATUYA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANCAJÁN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANCASMAYO , arroyo', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANCHANGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANCHILO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANCHORIGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANCOCHA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANCOCHE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANIL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANIMAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANIMAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANTAJE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANTILO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANTONIO E. TALBOT ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ANTUCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARAGONES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARBOL BLANCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARBOL NEGRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARBOLITOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARDILES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARDILES DE LA COSTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AREAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARENALES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARGENTINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARMONIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARRAGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ARROYO TALA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ASPA SINCHI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ATAHUALPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ATAMISQUI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ATOJ POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AVE MARIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AVERíAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AYUNCHA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'AZOGASTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BABILONIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BAEZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BAGUAL MUERTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BAHOMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BAJADITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BAJO GRANDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BAJO LAS PIEDRAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BAJO VERDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BALBUENA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BALDE POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BANDERA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BANDERA BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BANEGAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BARRANCAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BARRIAL ALTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BARRIALITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BARRIO ESTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BARRIO JARDIN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BARRIO LA LENERA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BARRIO OBRERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BARRIO VILLA COHESA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BARRIO VILLA FERNANDEZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BAUMAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BAYO MUERTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BEBIDAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BEJAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BELGICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BELGRANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BELTRÁN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BELTRAN LORETO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BELTRANLORETO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BINAL ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BLANCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BLANCA POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BLANCO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BOBADAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BOQUERON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BORDO PAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BOTIJA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BRACHO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BRANDAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BREA POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BREA POZO VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BREA PUNUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BREALOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BUEN LUGAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BUENOS AIRES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BUEY RODEO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BURRA HUANUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'BURRO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CABRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CACHICO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAJON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CALERAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CALLEJON BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CALOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO AMARILLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO BELGRANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO DE AMOR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO DEL AGUILA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO DEL CIELO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO DEL CISNE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO DEL INFIERNO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO EL ROSARIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO GALLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO GRANDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO LA ANGELITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO LIMPIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO NUEVO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO RAMON LAPLACE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO RICO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAMPO VERDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANADA DE LA COSTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANADA DE LA CRUZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANADA DEL MEDIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAÑADA ESCOBAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANADA LIMPIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANADA RICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANADA SAN RAMON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANADA TALA POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANAL CAMPO GALLO - GOBERNADOR GATICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANAL DE DIOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANAL DE LA PATRIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANAL DEL DESIERTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANAL ENCAUZADOR INGENIERO. A. TINI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANAL SUR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANAL VIRGEN DEL CARMEN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANARIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANAS PASO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANCINOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANEINOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANTAMAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CANTEROS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAPILLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CARANCHI YACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CARBON POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CARDAJAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CARDON ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CARDOZOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CARRERA VIEJA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CARRETERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CARTAVIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CASA ALTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CASA DE DIOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CASA VERDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CASARES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CASHICO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CASILLA DEL MEDIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CASPI CORRAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CATORCE QUEBRACHOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAVADITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAVADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CAZADORES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CEJA POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CEJAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CEJOLAO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CELESTINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CENTRAL DOLORES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CERRILLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CERRILLOS DE SAN ISIDRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CERRO RICO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHACRAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHAGUAR PUNCU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHAINIMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANAR AGUADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANAR BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANAR ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANAR POCITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANAR POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANAR POZO DE ABAJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANAR PUJIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANAR SUNICHAJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANAR YACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANARES ALTOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHANCHILLOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHARCO VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHARQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHAUCHILLAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHAUPI POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHAVES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILCA ALBARDON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILCA JULIANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILCA LA LOMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILCAS LA LOMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILENO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILLIMO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILPA MACHO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILPA MAYO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILQUITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILQUITA I', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHILQUITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHIRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHOYA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHUCHI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHUIQUI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHUNA ALBARDON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHUNA PALMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CHURQUI ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CIUDAD DE LORETO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CLAVEL BLANCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CLEVELAND', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CLODOMIRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CODILLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CODO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CODO BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CODO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CODO VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLLERA HUIRCUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLLERA HUIRI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLLERA HURCUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLLUJLIOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLLUN LIOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLOMBIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA ALPINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA ALSINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA ARBOL BLANCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA DORA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA EL PELIGRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA EL SIMBOLAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA ERMELINDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA ESPANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA GERALDINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA ISLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA LA VICTORIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA MARIA LUISA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA MEDIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA MERCEDES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA PAULA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA PAZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA PINTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA SAN JUAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA SANTA ROSA AGUIRRE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA SIEGEL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA TINCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIA TOTORILLAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COLONIAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CONCHAYOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CONSULNOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CONTRERAS ESTABLECIMIENTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CONZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COPO VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORASPINO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORO ABRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORONEL BARROS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORONEL FERNANDEZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORONEL MANUEL L. RICO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORONEL MANUEL LEONCIO RICO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COROPAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORRAL DE CARCOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORRAL DEL REY', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORRAL GRANDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORRAL QUEMADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CORVALANES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COSTA RICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'COSTA VIEJA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CRUZ BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CRUZ CHULA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CRUZ LOMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CRUZ POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CUATRO BOCAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CUCHI CORRAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CUICHICANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CUQUENOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CUQUERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'CUYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'de GUASAYÁN , sierra', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'de LA GUARDIA , río', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'de MAILLíN , arroyo', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'de SAN BERNARDO , salinas', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DESVIO POZO DULCE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DIASPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DIENTE DEL ARADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DIQUE CHICO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DIQUE FIGUEROA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DIQUE LOS QUIROGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DIVISADERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DOBLE TERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DOLORES CENTRAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DON BARTOLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DON PIETRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DONA LORENZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DOÑA LUISA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DONADEU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DORMIDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DOS ARBOLES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DOS EULACIAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DOS EULALIAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DOS HERMANAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DOS REPRESAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'DOS VARONES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EA LA VERDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL 21', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL ABRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL AEROLITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL AGRICULTOR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL AIBALITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL AIBE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL ALAMBRADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL ANIL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL ARBOL DE PIEDRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL ASPIRANTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL BAGUAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL BAJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL BALDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL BALDECITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL BANADERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL BARRIAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL BOBADAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL BORDITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL BRAGADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL BRAVO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CABURÉ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CACHI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CAMBIADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CANAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CANDELERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CANON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CEBOLLIIN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CEBOLLIN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CEIBAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CERCADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CERRITO MONTE QUEMADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CERRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CHARABON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CHARCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CHINCHILLAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CHURQUI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CINCUENTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL COLMENAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL COLORADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CORRIDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CRECE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CRUCERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CUADRADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CUARENTA Y NUEVE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL CUELLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL DEAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL DESCANSO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL DESTINO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL DIABLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL DIAMANTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL EMPACHADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL ESCONDIDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL FAVORITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL FISCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL FISCO DE FATIMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL FLORIDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL FRAILE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL FUERTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL GALPON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL GRAMILLAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL GUAYACAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL HOYO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL HUAICO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL JUME', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL JUNCAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL MALACARA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL MANANTIAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL MARNE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL MATACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL MISTOL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL MOJÓN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL MOLAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL MULATO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL NEGRITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL NERIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL NOVENTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL OJO DE AGUA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL OLIVAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL ONCE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL OSCURO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL OSO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PACARA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PALMAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PARANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PERAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PERSEGUIDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PERTIGO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PERU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PILAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PINTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PIRUCHO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL POLEAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PORVENIR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL POTRERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PRADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PUEBLITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PUENTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL PUESTITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL QUILLIN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL RAJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL REAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL RECREO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL REMANSO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL RINCON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL SALVADOR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL SEGUNDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL SENUELO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL SETENTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL SILENCIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL SIMBOL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL SIMBOLAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL SOLITARIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL TACIAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL TANQUE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL TOBIANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL TOBIANO ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL TRASLADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL TRECE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL UCLE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL UNCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL URUNDAY', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VALLE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VALLE DE ORIENTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VEINTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VEINTICINCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VEINTICINCO DE MAYO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VEINTICINCO SUMAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VEINTISIETE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VENTICINCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VINALAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VINALITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL VIZCACHERAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EL ZANJON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EMBALSE CHUCHI POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EMBALSE DE ALICURÁ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EMBALSE DEL KM. 0', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EMBALSE LOS FIGUEROA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ENVIDIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESCALERA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESPINAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTABLECIMIENTO 14 DE SETIEMBR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTACION ATAMISQUI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTACION PAMPA MUYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTADOS UNIDOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTANCIA EL CARMEN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTANCIA LA AGUSTINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTANCIA LA ELSITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTANCIA LA INVERNADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTANCIA NUEVA ESPERANZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTANCIA VIEJA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ESTEROS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'EZCURRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FAROL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FAVORINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FAVORITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FERNÁNDEZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FIERRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FIVIALTOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FLORESTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FORRES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FORTíN INCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FORTIN LA VIUDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'FRíAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GALEANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GALLEGOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GARCEANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GARZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GASPAR SUAREZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GENOVEVA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GIBIALTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GIRARDET', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GRAMILLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GRAMILLAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GRANADERO GATICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUAIPI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUALAMBA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUAMPACHA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUAMPACHO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUANACO SOMBRIANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUANACUYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUANAGASTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUARCAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUARDIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUARDIA DE LA ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUARDIA DEL NORTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUARDIA ESCOLTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUATANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUAYPE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUERRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'GUINAO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HAASE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HAHUANCUYOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HAZAN VILLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HERNAN MEJIA MIRAVAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HERRERA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HIGUERA CHAQUI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HIGUERILLAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HILUMAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HORCOS TUCUCUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HOYO CERCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HOYO CON AGUA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HOYON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUACANITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUACHANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUAICO HONDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUAJIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUALO CANCANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUASCAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUASCHO PATILLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUCHUPAYANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUILLA CATINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUMAITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUNAJCITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HURITU HUASI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUTCU CHACRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUTURUNGO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'HUYAMAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ICAÑO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ICHAGON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ILIAGES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'INDUSTRIA NUEVA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'INGENIERO CARLOS CHRISTIERNSON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'INGENIERO CARLOS CHRISTIERNSON ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'INGENIERO EZCURRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'INGENIERO FORRES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'INGENIERO FORRES , Est. Chaguar Punco', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'INTI HUASI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ISCA YACU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ISCA YACU SEMAUL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ISLA BAJA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ISLA DE ARAGONES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ISLA DE LOS CASTILLOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ISLA DE LOS SOTELOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ISLA MOTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'IUCHAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JACIMAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JANTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JARDIN DE LAS DELICIAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JIMENEZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUANILLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUME', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUME ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUMEAL O JUMIAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUMI POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUMI VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUMIAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUMIAL GRANDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUMIALITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUNALITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'JUNCAL GRANDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KENTI TACKO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KENTI TACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETR0 80 ,DV.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETR0699 ,EMP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 101 ,PDA.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 1362 ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 1380 ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 1391 ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 153 ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 18 ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 390 ,DV.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 433 ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 436 ,DV.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 454 ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 473 ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 494', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 499 ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 546 ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 55 ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 606 ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 613 ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 645 ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 659 ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KILOMETRO 969 ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KISKA HURMANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'KISKA LORO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA ABRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA ABRITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA AIDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA ALEMANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA ALOJA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA ALOJA ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA AMERICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA ARMONIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA AURORA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA BALANZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA BANDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA BARROSA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA BELLA CRIOLLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA BLANCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA BOTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA BREA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CAÑADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CAROLINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CASIMIRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CENTELLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CHEJCHILLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CHILCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CLEMIRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CODICIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA COLONIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CONCEPCION', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA COSTOSA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CUARTEADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA CURVA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA DARSENA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA DEFENSA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA DELIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA DOLORES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA DONOSA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA DORA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA ENCALADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA EULALIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA FELICIANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA FIRMEZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA FORTUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA FRAGUA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA FRANCISCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA GERALDINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA GERMANIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA GOLONDRINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA GRAMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA GRANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA GRANADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA GRINGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA GRITERIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA HIEDRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA INVERNADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA ISLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA JULIANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA LAURA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA LIBIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA LOMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA LOMADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA LUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA MAGDALENA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA MANGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA MARAVILLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA MARTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA MELADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA MESADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA NENA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA OVERA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PACIENCIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PALIZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PALMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PALOMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PALOMA ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PANCHITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PERLITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PETRONILA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PINTADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA POLVAREDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PORFIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA POTOCHA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PRIMITIVA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PROTEGIDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PUERTA DEL MONTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA PUNTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA RECOMPENSA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA RECONQUISTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA RESBALOSA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA REVANCHA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA RIVERA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA ROMELIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA ROSILLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA SANTAFECINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA SARITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA SELVA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA SIMONA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA SOLEDAD', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA SUSANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA TALA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA TAPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA TERESA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA TOTORILLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA TUSCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA VICTORIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA VIRTUD', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA VIUDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA VUELTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LA YERBA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAGO MUYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAGUNA BAYA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAGUNITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAPRIDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS ABRAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS ABRAS DE SAN ANTONIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS AGUILAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS ALMAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS CANTINAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS CARPAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS CHILCAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS CIENAGAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS COLINAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS COLONIAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS CRUCES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS DOS FLORES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS FLORES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS HORQUETAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS ISLAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS LOMITAS BLANCAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS MARAVILLAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS MOCHAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS ORELLANAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS PALOMITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS PERFORACIONES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS PORTENAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS PUERTAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS QUEBRADAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS RAMADITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS RANDAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS SALINAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS TALAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS TERESAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS TIGRERAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS TINAJAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS TRINCHERAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS VIBORITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAS ZANJAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LASPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAURELES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LAVALLE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LECHUZAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LEDESMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LEIVA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LESCANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LEZCANOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LIBANESA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LIBARONA, AGUSTINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LIBERTAD', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LILO VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LIMACHE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LIMAY , río', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LINCHO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LINTON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LLAJTA MAUCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LLAMA PAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOJLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOMA COLORADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOMA DE YESO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOMA DEL MEDIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOMA GRANDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOMITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOMITAS BLANCAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS ALDERETE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS ALDERETES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS ALGARROBOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS ARBOLITOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS ARIAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS CANOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS CARRIZOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS CASTILLOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS CERROS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS COBRES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS CORREAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS CRUCES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS DECIMAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS DIAZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS DOCE QUEBRACHOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS ENCANTOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS FIERROS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS GALLARDOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS GATOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS GUERREROS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS HERRERAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS HERREROS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS JURíES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS LATERALES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS LINARES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS MARCOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS MILAGROS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS MOLLARES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS MOYAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS NARANJOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS NUÑEZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS PARAISOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS PAREDONES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS PECARIEL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS PENSAMIENTOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS PEREYRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS PIRPINTOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS POCITOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS PORTENOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS POZOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS PUENTES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS PUNTOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS QUEBRACHOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS QUIROGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS REMANSOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS ROBLES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS TABLEROS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS TELARES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LOS TIGRES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'LUGONES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MACO YANDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MADERAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAGUITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAIDANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAILIN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAJADAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAJADAS SUD', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAJANCITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAL PASO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MALBRÁN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MALLIN VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MALOTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MANCHIN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MANFLOA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MANGA BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MANGRULLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MANOGASTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MANSUPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAQUIJATA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAQUITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAQUITIS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MAQUITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MARAVILLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MARIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MARIA DELICIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MARTIN PASO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MASUMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MATARÁ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MATE PAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MEDELLíN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MEDIA FLOR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MEDIA LUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MEDIO MUNDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MELERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MIEL DE PALO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MILI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MINERVA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MIRANDAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MIRAVAL, HERNAN M.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MIRCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MISTOL LOMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MISTOL MUYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MISTOL PAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MISTOL PAMPA ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MISTOL POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MISTOLES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MISTOLITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MOCONZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MOJON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MOJONCITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MOLLE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MOLLE POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MOLLEOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MOLLES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MONTE ALTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MONTE ALTO ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MONTE CRECIDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MONTE QUEMADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MONTE REDONDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MONTE VERDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MONTESINO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MONTEVIDEO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MORADITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MORALES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MORAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MORAYOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MORCILLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'MORELLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NAMBY , río', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NANDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NAQUITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NARANJITOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NASALO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NEGRA MUERTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NOGALES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NOGE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NORQUEOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NOVILLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVA ALZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVA ANTAJE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVA AURORA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVA CERES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVA COLONIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVA ESPERANZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVA FRANCIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVA GRANADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVA INDUSTRIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVE MISTOLES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVO LIBANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVO LUJAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'NUEVO SIMBOLAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'OBRAJE IRIONDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'OBRAJE LOS TIGRES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'OBRAJE MAILIN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'OBRAJE MARIA ANGELICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'OCTAVIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ONCAJAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ONCAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ORO PAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'Otra', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'OTUMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'OVEJEROS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAAJ MUYO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAAJ POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAAJ RODEO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PACIENCIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PADUA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAJARO BLANCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALIZAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALMA FLOR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALMA LARGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALMA POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALMA REDONDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALMARES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALMAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALO LINDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALO NEGRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALO PARADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALOMAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PALOS QUEMADOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAMPA ATUN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAMPA DE LOS GUANACOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAMPA MAYO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAMPA MUYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAMPA MUYOJ ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAMPA POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PAMPALLAJTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PARAJE EL PRADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PARAJE GAUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PARAJE LA PAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PARAJE LILO VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PARAJE MILAGRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PARAJE OBRAJE MARIA ANGELICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PARAJE VILLA YOLANDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PASO DE LOS OSCARES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PASO DE OSCARES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PASO DEL SALADILLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PASO MOSOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PASO REDUCIDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PATAY', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PATILLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PELUDO WARCUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PENAL PROVINCIAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PERCAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PERCHIL BAJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PEREZ DE ZURITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PICOS DE AMOR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PICOS DE ARROZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PIEDRA BUENA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PIEDRAS BLANCAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PINEDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PINEDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PINTO  VILLA GENERAL MITRE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PIRHUAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PIRUITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PLATERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POCITO DE LA LOMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POCITOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POLEO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POLVAREDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PORONGAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PORONGOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PORTALIS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PORTEZUELO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POSTA SANITARIA POCITOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POTRERO BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZANCON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZANCONES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO BETBEDER', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO CABADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO CASTANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO CERCADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO CIEGO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO COLORADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DE LA PUERTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DEL ALGARROBO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DEL ARBOLITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DEL CAMPO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DEL CASTAÑO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DEL GARABATO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DEL MACHO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DEL MONTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DEL SIMBOL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DEL TOBA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO DULCE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO ESCONDIDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO GRANDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO HERRERA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO HONDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO HUASCHO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO LERDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO LIMPIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO LINDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO MARCADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO MORO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO MOSOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO MUERTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO NUEVO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO REDONDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO SALADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO VERDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZO VIL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'POZUELOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PRIMAVERA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PROGRESO DE JUME', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PROVIDENCIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PROVIRU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUEBLITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUEDA SER', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUENTE BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUENTE DEL SALADILLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUENTE DEL SALADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUENTE NEGRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUENTE RIO SALADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUERTA CHIQUITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUERTA DE LAS PIEDRAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUERTA DE LOS RIOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUERTA DEL CIELO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUERTA DEL MONTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUERTA GRANDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTITO DE SAN ANTONIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO CORDOBA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO DE ARRIBA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO DE DIAZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO DE JUANES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO DE MENA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO DE VIEYRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO DEL MEDIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO DEL RETIRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO DEL SIMBOL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO LOS MARCOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUESTO NUEVO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUMITAYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNI TAJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNI TAJO ,DV.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNITA NORTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNITA SUD', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNTA CORRAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNTA DE RIELES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNTA DEL GARABATO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNTA DEL MONTE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNTA POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'PUNTA RIELES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUEBRACHAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUEBRACHITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUEBRACHITOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUEBRACHO COTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUEBRACHO PINTADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUEBRACHO YACU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUEBRACHOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUEBRADA ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUEMADITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUENTI TACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUERA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUILUMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUIMILí', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUIMILI PASO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUIMILIOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUIMILLOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUISHCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUISNA LORO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'QUITA PUNCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RAMA PASO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RAMADITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RAMADITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RAMI YACU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RAMIREZ DE VELAZCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RANCHITOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RAPELLI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'REAL SAYANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'REMANSITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'REMANSO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'REMANSOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'REMES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'REPARO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'REPECHO DIAZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'REPECHO MONTENEGRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'REPRESA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RETIRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'REY VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RINCON DE LA ESPERANZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RINCON ESPERANZA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RIO DE GALLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RIO NAMBI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RIO SALADILLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RIO VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ROBLES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RODEITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RODEO BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RODEO DE SORIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RODEO DE VALDEZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ROMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ROMANOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ROSADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ROSIYULLOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ROVERSI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUBIA MORENO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUMI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUMI ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUMI HUASI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUMI JACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUMIARCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUMIOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUTA NACIONAL 34', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUTA PROVINCIAL 11', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUTA PROVINCIAL 130', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUTA PROVINCIAL 17', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUTA PROVINCIAL 40', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUTA PROVINCIAL 5', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'RUTA PROVINCIAL 8', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SABAGASTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SACHAYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAINQUEN PUNCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SALADILLO , río', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SALAVINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SALDIVAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SALINAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SALVIAIOJ GAITAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN ALBERTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN ANTONIO DE COPO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN ANTONIO DE LAS FLORES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN ANTONIO DE LOS CACERES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN DELFIN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN DIONISIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN FELIX', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN FRANCISCO LAVALLE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN GREGORIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN HORACIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN JERONIMO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN JOSE DE FLORES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN JOSÉ DEL BOQUERÓN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN JOSE DTO FIGUEROA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN MATEO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN MIGUEL DEL MATARA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN PABLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN PASCUAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN PASTOR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN PEDRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN PEDRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN RAMON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN RAMON QUEBRACHOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN ROMANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN RUFINO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAN SIMON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANAVIRONES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANDIA HUAJCHU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANSIOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA BÁRBARA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA BARBARA FERREIRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA BRIGIDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA CATALINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA CRUZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA FELISA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA JUSTINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA MARIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA MARIA ,EST.INGENIERO EZCURRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA MARIA DE LAS CHACRAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA MARIA DTO FIGUEROA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA MARIA SALOME', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA PAULA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA ROSA ARRAGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA ROSA COPO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA ROSA DE CORONEL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTA ROSA DE VITERVO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTIAGO DEL ESTERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTO DOMINGO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTO DOMINGO CHICO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTO DOMINGO PELLEGRINI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTO DOMINGO ROBLES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SANTOS LUGARES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAUCE BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAUCE ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAUCE SOLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAUCEN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAUCES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAUCIOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAUZAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SAYACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SEGUNDO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SELVA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SELVA BLANCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SEÑORA PUJIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SEPULTURA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SEPULTURAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SERRANO MUERTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SESTEADERO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SHISHI POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SIEMPRE VERDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SIETE ARBOLES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SILIPICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SIMBOL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SIMBOL BAJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SIMBOL BAJO ,EMB.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SIMBOL CANADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SIMBOL HUASI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SIMBOL POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SIMBOLAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SIN DESCANSO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SINCHI CANA  ESTABLECIMIENTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SINQUEN PUNCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SOL DE JULIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SOL DE MAYO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SORIA BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SOTELILLOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SOTELOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'STAYLE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SUMAMAO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SUMAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SUMAMPA VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SUNCHITUYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SUNCHO CORRAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SUNCHO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SUNCHO PUJIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SUPERINTENDENTE LEDESMA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SURI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SURI POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SURIHUAYA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'SURIHUAYA ,AP.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TABEANITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TABIANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TABLADA DEL BOQUERON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TABLEADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TABOADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TABOADA ESTACION', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACAMAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACANAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACAÑITAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACIOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO ATUN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO FURA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO HUACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO ISLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO MISQUI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO PUJIO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO PUNCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO QUINKA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO SUYO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACO TOTARAYOL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACON ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TACOYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TAGAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TAJAMAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TALA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TALA ATUN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TALA YACU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TAPERAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TAQUELLO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TAQUETUYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TARPUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TARUY', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TENENE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TENTI TACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TERMAS DE RíO HONDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TIBILAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TIESTITUYOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TIGRE MUERTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TINAJERALOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TINAP JERAYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TINCO COLONIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TINTINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TIO ALTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TIO CHACRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TIO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TIPIRO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TIUN PUNCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TOBAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TOLOZAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TOMÁS YOUNG', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TOME Y TRAIGA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TONTOLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TONZU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TORO CHARQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TORO HUMAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TORO PAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TORO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TOROPAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TOTORA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TOTORA PAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TOTORILLA NUEVO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRAMO 16', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRAMO 20', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRAMO VEINTISEIS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRASLADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES BAJADAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES BAJOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES CHANARES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES DE MARZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES FLORES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES HERMANAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES JAZMINES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES MOJONES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES POZOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES QUEBRACHOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRES VARONES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRONCAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRONCO BLANCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRONCO JURAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRONCO QUEMADO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TRONCOS QUEMADOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TROZO POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TULUM', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TULUN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TUNAS PUNCO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TURENA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TUSCA BAJADA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TUSCA POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'TUSCAYOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'UCLAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'UPIANITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'URUTAÚ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'UTRUNJOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VACA HUAÑUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VACA HUMAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VALDIVIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VARAS CUCHUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VEINTIOCHO DE MARZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VENTURA PAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VERON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILA ISLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILELAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA ABREGU', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA ADELA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA ATAMISQUI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA BALNEARIA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA BRANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA COINOR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA CONSTANTINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA DE LA BARRACA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA ELENA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA ELVIRA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA ESQUINA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA FANNY', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA FIGUEROA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA GENERAL MITRE , Est. Pinto', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA GRIMANESA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA GUANUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA GUASAYAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA HIPOLITA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA ISLA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA JIMENEZ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA LA PUNTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA MAILíN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA MATARA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA MATILDE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA MATOQUE', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA MERCEDES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA OJO DE AGUA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA PALMAR', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA QUEBRACHOS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA RíO HONDO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA ROBLES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA SAN MARTIN ,EST.LORETO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA SILIPICA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA TOLOJNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA UNIÓN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA YOLANDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLA ZANJON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILLARES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VILMER', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VINAL MACHO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VINAL POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VINAL VIEJO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VINARA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VITEACA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VIVA MERCEDES', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VIZCACHERAL', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'VUELTA DE LA BARRANCA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'WEISBURD', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'WINANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YACANO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YACASNIOJ', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YACO MISQUI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YACU HICHACUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YACU HURMANA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YACUCHIRI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YALAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YANDA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YANTA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YASO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YESO ALTO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YLUMAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YOLOHUASI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YOUNG, TOMAS', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YUCHAN', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YUCHANCITO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YULU HUASI', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YUMAMPA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YUNTA POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YUTO YACA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'YUTU YACO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ZANJA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ZANJON', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ZANJON ,EST.', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ZAPI POZO', 'provincia': 'Santiago del Estero' },
        { 'descripcion': 'ZORRO HUARCUNA', 'provincia': 'Santiago del Estero' },
        { 'descripcion': '25 DE MAYO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': '9 DE JULIO , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'AGRADABLE , puerto', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'AGUDA , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ÁGUILA , canal', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ÁGUILA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ÁGUILA , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'AGUIRRE , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ALBERTI . cerro', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ALEJANDRO I , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ALMIRANTE BROWN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ALTO , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ALTURAS RIVADAVIA , montes', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ALVEAR , sierra', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'AMBERES , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ANDRÉS JACKSON , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ANNENKOV , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ANTÁRTICA , península', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ARMADA ARGENTINA , macizo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ASERRADERO ARROYO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BAHIA FOX', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BAHIA LAPATAIA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BAHIA THETIS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BAJA , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BAJA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BARRIO ALMIRANTE BROWN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE A.C.E. ALFEREZ DE NAVIO SOBRAL', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE AEREA TENIENTE MATIENZO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE AEREA TTE. BENJAMIN MATIENZO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE AEREA VICECOMOD MARAMBIO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE BELGRANO II', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE BELGRANO III', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE BROWN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE CÁMARA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE DE EJERCITO ESPERANZA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE DE EJERCITO GRL.BELGRANO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE DE EJERCITO GRL.SAN MARTIN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE DECEPCIÓN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE EJERCITO ESPERANZA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE EJERCITO GRAL BELGRANO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE EJERCITO GRAL BELGRANO 3', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE EJERCITO GRAL SAN MARTIN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE EJERCITO PRIMAVERA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE EJERCITO SOBRAL', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE ESPERANZA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE JUBANY', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE MARAMBIO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE MATIENZO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE MELCHIOR', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE ORCADAS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE PETREL', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE PRIMAVERA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE SAN MARTíN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASE SOBRAL', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BASUALDO , arroyo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BEAGLE , canal', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BEAUCH', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BELGRANO , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BELGRANO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BELINDA , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BELLINGSHAUSEN , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BERKNER , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BISCOE , archipiÉlago', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BLACKBURN , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BLANCA , roca', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BLANCO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BOUGAINVILLE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BOWMAN , península', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BRABANTE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BUEN SUCESO , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BUENOS AIRES , glaciar', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'BULLER , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CABALLO , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CABANA RUBY', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CABO SAN PABLO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CALISTA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CAMILLA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CAMPAMENTO CENTRAL Y P F', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CAMPAMENTO LOS CHORRILLOS Y P', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CANDELARIA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CANDELARIA , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CANDELARIA , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CANTERA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CARLOTA , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CARMEN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CELEBROÑA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CELEBROÑA , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CENIZO , cerro', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CHENEN , cerro', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CHEPELMUT , lago', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CHIRIGUANO , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CHOISEUL , seno', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CISNE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CLARENCE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'COLÓN , canal', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'COMAN , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'COMISARIA RADMAN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CONEJO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CÓNICO , cerro', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CONLARA , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'COOK , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'COOPER , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CORMORÁN , rocas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CORNÚ , cerro', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CORONACIÓN , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CORRIENTES , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CUARTA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CUARTO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CULEBRA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CULLEN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'CUMBERLAND , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DARLEY , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DARWIN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DARWIN , puerto', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de APEN , sierra', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de ARENAS , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de BORBÓN , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de COATS , tierra', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de DRAKE , pasaje', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de EDITH RONNE , tierra', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de FREYCINET , península', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de GOICOECHEA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LA ANUNCIACIÓN , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LA BOLSA ,cerro', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LA CRUZADA , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LA SUERTE , laguna', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LA TURBA , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LE MAIRE , estrecho', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LOS ABRIGOS , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LOS ARRECIFES , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LOS DESVELOS , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LOS ESTADOS , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LOS LEONES MARINOS , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de LUISA , ensenada', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de PALMER , archipiÉlago', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de PASTO VENTURA , portezuelo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de RUIZ PUENTE , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de SAN CARLOS , estrecho', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de SAN LUIS , península', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de SAN MARTIN , tierra', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de VALDIVIESO , sierra', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'de WEDDELL , mar', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DECEPCIÓN , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del ACEITE , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del ACONQUIJA , sierra', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del ESTE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del ESTE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del GANSO , prado', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del LABERINTO , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del MEDIO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del PASAJE , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del RíO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del ROSARIO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del SCOTIA , mar', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'del TORO , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DEMIDOV , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESAG', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESAGUADERO , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESCUBRIMIENTO , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESENGAÑO , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESTACAMENTO MELCHIOR', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESTACAMENTO NAVAL CORBETA URUGUAY', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESTACAMENTO NAVAL DECEPCION', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESTACAMENTO NAVAL ESPERANZA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESTACAMENTO NAVAL MELCHIOR', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESTACAMENTO NAVAL ORCADAS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DESTACAMENTO NAVAL TENIENTE CAMARA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DIAMANTE , cordillera', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DIVISORIA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DOLLEMAN , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DOS LOMAS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DRYGALSKI , fiordo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DUNDEE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'DYKE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'EDGARDO , puerto', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'EL PARAMO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'EL PÁRAMO , península', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ELEFANTE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ELEFANTE MARINO , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ELEFANTE MARINO , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ENRIQUETA , puerto', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESCARPADA , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESCONDIDA , laguna', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESPíRITU SANTO , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTABLECIMIENTO FITZROY', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTABLECIMIENTO PUERTO JOHNSON', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTABLECIMIENTO PUERTO SAN LUIS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTABLECIMIENTO RINCÓN GRANDE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTABLECIMIENTO SALVADOR', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTABLECIMIENTO SAN CARLOS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTACION AERONAVAL', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTACION AERONAVAL PETRIEL', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTACION CIENTIFICA ALMIRANTE BROWN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTACION CIENTIFICA ALTE BROWN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTACION CIENTIFICA ELLSWORTH', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTACION DE APOYO Nº 1 FUERZA AEREA ARG.', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTACION OSN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA AURELIA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA BUENOS AIRES', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA CARMEN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA CAUCHICO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA COSTANCIA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA CULLEN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA DESPEDIDA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA DOS HEMANAS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA EL ROBLE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA EL RODEO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA EL SALVADOR', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA GUAZU CUE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA HARBERTON', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA HERMINITA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA INES', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA JOSE MENENDEZ', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LA CRIOLLA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LA FUEGUINA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LA INDIANA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LA MARINA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LA PORTENA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LAS HIJAS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LAS VIOLETAS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LAURA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LIBERTAD', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LOS CERROS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA LOS FLAMENCOS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA MARIA BEHETY', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA MARIA CRISTINA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA MARíA LUISA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA MARINA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA MIRAMONTE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA PIRINAICA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA POLICARPO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA RIO CLARO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA RIO EWAN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA RIO IRIGOYEN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA RIVADAVIA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA ROLITO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA ROSITA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA RUBY', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA SAN JOSE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA SAN JULIO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA SAN JUSTO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA SAN MARTIN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA SAN PABLO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA SANTA ANA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA SARA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA TEPI', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA VIAMONTE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ESTANCIA VIEJA CARMEN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'FAGNANO , lago', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'FILCHNER , barrera de hielos', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'FITZROY , puerto', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'FITZROY , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'FOCA , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'FOSTER , pasaje', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'FOX , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'FRANCESES , islotes', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'FRIGORIFICO CAP', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'FUEGO , lago', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GABINETE , ensenada', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GABLE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GARDNER , ensenada', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GENERAL PAZ , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GEORGIAS DEL SUR , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GOBIERNO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GRAN MALVINA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GRANDE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GRANDE , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GRANDE DE TIERRA DEL FUEGO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GRUPO TULE DEL SUR , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'GRYTVIKEN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'HALL , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'HARMER , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'HEARST , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'HILTON , ensenada', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'HODSON , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'HOLLICK KENYON , península', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'HORNBY , montes', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'HORQUETA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'HOSTERIA KAIKEN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'INDEPENDENCIA , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'IRIGOYEN , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ISLA , laguna', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ISLA DE LOS ESTADOS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ISLA GRAN MALVINA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ISLA JOINVILLE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ISLA SHETLAND DEL SUR', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ISLA SOLEDAD', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ISLAS GEORGIAS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ISLAS ORCADAS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'JASON , península', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'JOINVILLE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'JORGE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'JORGE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'KAIKEN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'KEMP , península', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'KENT , cerro', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LA MARINA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LAGO KHAMI', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LÁINEZ , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LAPATAIA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LARGA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LARSEN , barrera de hielos', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LAS LLAVES , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LAS SEIS COLINAS , montes', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LATADY , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LAURIE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LEAL , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LEÓN MARINO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LEÓN MARINO ESTE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LESKOV , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LÓPEZ , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LORENZO , laguna', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LOS HERMANOS , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LOS SALVAJES , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LUCAS , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LUCIO LÓPEZ , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'LUCIO LÓPEZ , sierra', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MAR DE LA FLOTA , mar', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MARGARITA , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MARíA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MARíA , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MARíA , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MARIA LUISA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MARIQUITA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MARSOPA , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MAYOR , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MIGUEL , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MISIÓN SALESIANA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MISION SALESIANA MNOR FAGNANO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MOAT , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MONETA , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MONTíCULO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MONTURA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MONUMENTO NATURAL', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MORENO , puerto', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'MORRELL , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'NEGRA , roca', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'NOGUERA , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'NOGUERA , sierra', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'NOMBRE , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'NORDESTE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'NORTE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'NORTE , puerto', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'NUÑEZ , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'OBSERVATORIO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'OESTE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'OESTE , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ONA , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ORCADAS DEL SUR , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'Otra', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PAGET , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PÁJARO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PÁJARO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PÁJAROS , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PALOMA , laguna', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PAN DE AZÚCAR , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PARQUE NACIONAL DE TIERRA DEL FUEGO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PEDRO , arroyo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PELADA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PEÑAS , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PICKERSGILL , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'POLICARPO , caleta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'POPPER , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PORTILLO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PRESIDENTE SARMIENTO , canal', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PRIMERO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PRINCIPAL , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PRINCIPAL , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PUERTO ARGENTINO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PUERTO HARBERTON', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PUERTO LEITH', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PUERTO LUIS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PUERTO RANCHO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PUERTO REMOLINO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PUERTO SAN CARLOS', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'PUNTA MARIA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'QUIJADA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'RANCHO HAMBRE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'RASA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'RASA DEL OESTE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'REMOLINOS , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'REMOLINOS , roca', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'RíO GRANDE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'RIVADAVIA , cerro', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ROBERTSON , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ROBINSON , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ROCA BLANCA , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'RONNE , barrera de hielos', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ROSALíA , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ROSS , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'RUISEÑOR ,monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN ANDRÉS , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN BARTOLOMÉ , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN CARLOS , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN DIEGO , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN FELIPE , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN FRANCISCO DE PAULA , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN JOSÉ , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN JUAN , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN JUAN , río', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN JULIÁN , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN JULIÁN , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN JULIÁN , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN JULIO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN JUSTO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN PABLO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN PABLO , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN PEDRO , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN PíO , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN RAFAEL , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN SEBASTIÁN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN SEBASTIÁN , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN SEBASTIÁN , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAN VICENTE , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SANDWICH DEL SUR , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SANTA EUFEMIA , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SARA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SARMIENTO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SARMIENTO , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SAUNDERS , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SEBALDES , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SECCION AVILES ESTANCIA SAN J', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SEGUNDA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SEGUNDO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SHETLAND DEL SUR , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SIMÓN , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SLOGGETT , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SOLEDAD , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SOMUNCURÁ , banco', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'STEELE , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SUCIA , bahía', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SUECIA , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SULLIVAN , lago', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SULLIVAN , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'SUSSUEK , puerto', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'TERCER CORRAL', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'TERCERA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'TERCERO', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'TOLHUIN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'TRAVERSE , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'TRINIDAD , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'TRINIDAD , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'TRISTE , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'TUSSAC , islas', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'USHUAIA', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'VENTANA , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'VERNET , cerro', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'VIAMONTE', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'VIAMONTE , cabo', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'VIEJA CARMEN', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'VIGíA , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'VISOKOI , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'VOLUNTARIO , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'VUELTA , punta', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'WILKINS , estrecho', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'YEHUíN , lago', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'YOUNG , monte', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': 'ZAVODOVSKI , isla', 'provincia': 'Tierra del Fuego' },
        { 'descripcion': '7 DE ABRIL', 'provincia': 'Tucumán' },
        { 'descripcion': 'ABRA BAYA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ABRA DE LA PICAZA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ABRA DE YARETA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ABRA DEL INFIERNILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ABRA DEL TAFI', 'provincia': 'Tucumán' },
        { 'descripcion': 'ABRA EL CANDADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ABRA RICA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ACEQUIONES', 'provincia': 'Tucumán' },
        { 'descripcion': 'ACEQUIONES , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'ACHERAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'ACONQUIJA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ACOSTILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUA AZUL', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUA CALIENTE , cerro', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUA COLORADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUA DULCE', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUA DULCE ,EMP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUA EL SIMBO', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUA NEGRA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUA ROSADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUA SALADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUADA DE JORGE', 'provincia': 'Tucumán' },
        { 'descripcion': 'AGUILARES', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALABAMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALABAMA NUEVA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALDERETES', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALISOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALIZAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALONGO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALPACHIRI', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTA GRACIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTA GRACIA DE VILLA BURRUYACU', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO CAZADERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO DE ANFAMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO DE LA ANGOSTURA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO DE LEIVA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO DE LOS GIMENEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO DE LOS REALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO DE MEDINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO DEL HUASCHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO DEL LAMPAZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO DEL PUSTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO EL PUESTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO LA TOTORA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO LAS FLORES', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO LAS LECHUZAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO LOS CARDONES', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO NUESTRA SENORA DEL VALLE', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALTO VERDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'ALURRALDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'AMAICHA DEL LLANO', 'provincia': 'Tucumán' },
        { 'descripcion': 'AMAICHA DEL VALLE', 'provincia': 'Tucumán' },
        { 'descripcion': 'AMBERES', 'provincia': 'Tucumán' },
        { 'descripcion': 'AMIMPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AMPATA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AMPATILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AMPIMPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AMUNPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANCAJULI', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANCAJULLI', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANCHILLOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANDRES FERREYRA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANEGADOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANFAMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANFANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANIMAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANJUANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANTA CHICA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANTAMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANTIGUO QUILMES', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANTILLAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'ANTU MAPU', 'provincia': 'Tucumán' },
        { 'descripcion': 'APARADERO MILITAR GRAL MUNOZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARAGON', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARAN', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARANILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARAOZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARBOLES GRANDES', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARBOLES VERDES', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARCADIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARENILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AROCAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARROYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARROYO ATAHONA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARROYO BARRIENTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ARROYO MAL PASO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ASERRADERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ASNA YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ATAHONA', 'provincia': 'Tucumán' },
        { 'descripcion': 'AVESTILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'B ZORRILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'BAJASTINE', 'provincia': 'Tucumán' },
        { 'descripcion': 'BAJO DE LOS SUELDOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'BAJO DE RACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'BALDERRAMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'BALDERRAMA SUR', 'provincia': 'Tucumán' },
        { 'descripcion': 'BANADO DEL VALLE', 'provincia': 'Tucumán' },
        { 'descripcion': 'BANDA', 'provincia': 'Tucumán' },
        { 'descripcion': 'BANDA DEL RíO SALI', 'provincia': 'Tucumán' },
        { 'descripcion': 'BARBORIN', 'provincia': 'Tucumán' },
        { 'descripcion': 'BARRANCAS COLORADAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'BARREALITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'BARRIO BELGRANO', 'provincia': 'Tucumán' },
        { 'descripcion': 'BARRIO CASINO', 'provincia': 'Tucumán' },
        { 'descripcion': 'BARRIO DIAGONAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'BARRIO TEXTIL', 'provincia': 'Tucumán' },
        { 'descripcion': 'BARROSA', 'provincia': 'Tucumán' },
        { 'descripcion': 'BATIRUANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'BELICHA HUAICO', 'provincia': 'Tucumán' },
        { 'descripcion': 'BELLA VISTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'BELLO HORIZONTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'BENJAMíN ARAOZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'BENJAMíN PAZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'BILCA POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'BLANCO POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'BOBA YACU', 'provincia': 'Tucumán' },
        { 'descripcion': 'BOLSÓN , cerro', 'provincia': 'Tucumán' },
        { 'descripcion': 'BRACHO VIEJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'BUEN RETIRO', 'provincia': 'Tucumán' },
        { 'descripcion': 'BUENA VISTA OESTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'BUENA YERBA', 'provincia': 'Tucumán' },
        { 'descripcion': 'BURRUYACU', 'provincia': 'Tucumán' },
        { 'descripcion': 'BUSTAMANTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'BUSTAMANTE ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'CACHI HUASI', 'provincia': 'Tucumán' },
        { 'descripcion': 'CACHI HUASI ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'CACHI YACO ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'CACHI YACO APEADERO FCGB', 'provincia': 'Tucumán' },
        { 'descripcion': 'CACHIYACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAJAS VIEJAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CALCHAQUíES , cumbres', 'provincia': 'Tucumán' },
        { 'descripcion': 'CALERA ACONQUIJA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CALERA DE CHIRIMAYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CALIMAYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CALIMONTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMAS AMONTONADAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMINO DEL PERU', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO AZUL', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO BELLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO BLANCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO DE LAS GALLINAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO DE LOS CARDONES', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO DE LOS CHANARES', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO DE TALAMAYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO EL LUISITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO EL MOLLAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO HERRERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO LA CRUZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO LA FLOR', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO LA FLOR LOS RALOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO SOLCO LOS COCHAMOLLES', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO VOLANTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAMPO ZAUZAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADA ALEGRE', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADA DE ALZOGARAY', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADA DE LA CRUZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADA DE LOS NEGROS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADA DE VICLOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADA DEL ARENAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADA EL ARENAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADA LARGA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADA YERBA BUENA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANADAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CANDELILLAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAÑETE', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAPILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAPITÁN CÁCERES', 'provincia': 'Tucumán' },
        { 'descripcion': 'CARANCHO POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CARAPUNCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CARBON POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CAROLINAS BAJAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CARRETA QUEMADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CARRICHANGO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASA DE CAMPO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASA DE PIEDRAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASA DE ZINC', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASA DEL ALTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASA ROSADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASA SANTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASA VIEJA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASAS VIEJAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASPICHANGO VIEJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASPINCHANGO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASPINCHANGO , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'CASTILLAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CEJA POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CEJAS DE AROCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CEJAS DE BENACHILLOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CERCO CUATRO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CERVALITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CEVIL GRANDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'CEVIL POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CEVIL REDONDO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CEVIL SOLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CEVILARCITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHABELA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHALCHACITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHANAR MUYA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHANAR PAGO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHANAR POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHANAR TAQUENO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHANAR VIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHAÑARITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHASQUIVIL', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHAVARRíA , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHICLIGASTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHICO , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHILCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHILCAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHILCAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHILCAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHOROMORO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHORRILLOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHORRILOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHUANTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHULCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHULCA , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'CHUSCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CIUDACITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CIUDAD HOSPITAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'CIUDAD UNIVERSITARIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COCHAMOLLE', 'provincia': 'Tucumán' },
        { 'descripcion': 'COCHUNA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'COHIGAC', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLALAO DEL VALLE', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLMENA LOLITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLOMBRES', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA 6', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA ACEVEDO', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA AGRICOLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA BASCARY', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA EL PUESTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA EL SUNCHAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA EL TARCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA FARA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA FELIPE', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA HUMAITA PRIMERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA JUAN JOSE IRAMAIN', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA LA BONARIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA LA ORTIZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA LA ROCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA LOLITA NORTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA LOS CHASALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA LOS HILLS', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA MARIA ELENA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA MARULL', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA MERCEDES', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA MISTOL', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA MONTEROS', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA NASCHI', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA NRO 2', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA NUEVA TRINIDAD', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA PACARA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA PEDRO LEON CORNET', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SAN JORGE', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SAN JOSE', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SAN LUIS', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SAN RAMON', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SANTA CATALINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SANTA CLARA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SANTA LUCIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SANTA MARINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SANTA RITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SARMIENTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA SOBRECASA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COLONIA TACAPUNCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'COMUNA LA ESPERANZA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CONCEPCIÓN', 'provincia': 'Tucumán' },
        { 'descripcion': 'CONSIMO', 'provincia': 'Tucumán' },
        { 'descripcion': 'COOPERATIVA AGRONOMICA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COROMAMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CORONA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CORRAL GRANDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'CORRAL VIEJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CORTADERAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'COSSIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'COSTA ARROYO ESQUINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'COSTA DEL RIO SECO', 'provincia': 'Tucumán' },
        { 'descripcion': 'COSTILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CRIOLLAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CRUZ DE ABAJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'CRUZ DEL NORTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'CRUZ DEL NORTE ESTACION FCGM', 'provincia': 'Tucumán' },
        { 'descripcion': 'CUATRO GATOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CUATRO SAUCES', 'provincia': 'Tucumán' },
        { 'descripcion': 'CUCHILLAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'CUESTA DE CHILCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CUESTA DE LA CHILCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'CUMBRE DE NARVAÉZ , sierras', 'provincia': 'Tucumán' },
        { 'descripcion': 'CUMBRE DEL POTRERILLO , sierras', 'provincia': 'Tucumán' },
        { 'descripcion': 'CUMBRES DE SANTA BÁRBARA , sierras', 'provincia': 'Tucumán' },
        { 'descripcion': 'CURVA DE LOS VEGAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'de LAS CAÑAS , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'de LOS REALES , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'de LOS SOSA , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'de MEDINA , sierra', 'provincia': 'Tucumán' },
        { 'descripcion': 'de TAFí , valle', 'provincia': 'Tucumán' },
        { 'descripcion': 'del CAJÓN o TAJAMAR , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'del TORO , abra', 'provincia': 'Tucumán' },
        { 'descripcion': 'DELFíN GALLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'DESCANSO', 'provincia': 'Tucumán' },
        { 'descripcion': 'DESMONTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'DIQUE EL CADILLAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'DIQUE EL MOLLAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'DIQUE ESCABA', 'provincia': 'Tucumán' },
        { 'descripcion': 'DOMINGO MILLAN', 'provincia': 'Tucumán' },
        { 'descripcion': 'DONATO ALVAREZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'DOS POZOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'DURAZNOS BLANCOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ALPIZAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ANTIGAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ARBOLAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ARENAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ARQUEAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ASERRADERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ATACAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ATACAT', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL AZUL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL BACHI', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL BAGUAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL BAJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL BAÑADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL BARCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL BARRANQUERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL BARRIALITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL BATIRUANO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL BOYERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL BRACHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CADILLAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CAMPO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CARBON', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CARDENAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CARMEN PUENTE ALTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CARRIZAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CASIAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CASIALITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CASTORAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CATORCE', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CEBIL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CEDRO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CERCADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CEVILAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CEVILAR COLOMBRES', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CHAÑAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CHILCAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CHORRO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CHURQUI', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CHURQUIS', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL COCHUCHAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL COLCOLAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL COLMENAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL COLMENAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CORRALITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CORTADERAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CORTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CRUCE', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CUADRO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL CUARTEADERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL DURAZNILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL DURAZNITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ESPINAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ESTABLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL FRASQUILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL GUARDAMONTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL GUAYACAN', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL HUAICO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL INDIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL INFIERNILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL INTERES', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL JARDIN', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL JUNQUILLAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL LAMEDERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL LAMPARAZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MANANTIAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MATADERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MATAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MELON', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MISTOL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MISTOLAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MOLINO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MOLLAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MOLLE VIEJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MORADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MOYAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL MUTUL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL NARANJAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL NARANJITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL NARANJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL NIAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL NOGAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL NOGALAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL NOGALITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL OBRAJE', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL OJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ONCE', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PABELLON', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PAJAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PALANCHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PALCARA', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PASO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PAVON', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PAYANAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PELADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PELADO DE PARANILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PILA', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PORVENIR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL POTRERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PRADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PUERTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL PUESTO DEL MEDIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL QUEBRACHITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL QUIMIL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL RINCON', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL SAUZAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL SESTEADERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL SIAMBON', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL SINQUIAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL SUNCAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL SUNCHAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL TAJAMAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL TIMBO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL TIPAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL TIRO ARGENTINO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL TOBAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL TORO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL TROPEZON', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL VALLECITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ZAPALLAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'EL ZAUZAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EMBALSE DE ESCABA', 'provincia': 'Tucumán' },
        { 'descripcion': 'EMBALSE EL CADILLAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'EMBALSE LA ANGOSTURA', 'provincia': 'Tucumán' },
        { 'descripcion': 'EMPALME AGUA DULCE', 'provincia': 'Tucumán' },
        { 'descripcion': 'ENCRUCIJADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ENTRE RIOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESCABA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESCABA ABAJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESCABA ARRIBA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESCABA DE ABAJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESCABA DE ARRIBA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESCOBAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESPIADERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESQUINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESQUINA DEL LLANO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESQUINA DEL VALLE', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESQUINA NORTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESTABLECIMIENTO ESQUINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESTABLECIMIENTO LAS COLONIAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESTACION ARAOZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESTACION DE ZOOTECNIA B', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESTACION EXPERIMENTAL AGRICOLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESTACION SUPERIOR AGRICOLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESTANCIA EL DIAMANTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESTANCIA LA PRINCESA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESTANCIA SURI YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ESTANQUE', 'provincia': 'Tucumán' },
        { 'descripcion': 'Esteban Echeverria', 'provincia': 'Tucumán' },
        { 'descripcion': 'FAGSA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FALDA DE ARCADIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FAMAILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FAVORINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FIN DEL MUNDO', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA ANCHORENA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA ARAOZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA CRISTINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA EL CEIBO', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA ELISA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA ENTRE RIOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA LEILA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA LOPEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA LOS LLANOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA MAYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA PACARA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA PEREYRA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA PIEDRA BLANCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA SAN LUIS', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA TINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA TINTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'FINCA TULIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'FRONTERITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'FUERTE ALTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'G ANSELMO ROJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'GALLO, DELFIN', 'provincia': 'Tucumán' },
        { 'descripcion': 'GARCIA FERNANDEZ, MANUEL', 'provincia': 'Tucumán' },
        { 'descripcion': 'GARMENDIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'GASTONA', 'provincia': 'Tucumán' },
        { 'descripcion': 'GASTONA , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'GASTONILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'GOBERNADOR GARMENDIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'GOBERNADOR NOUGES', 'provincia': 'Tucumán' },
        { 'descripcion': 'GOBERNADOR NOUGUES', 'provincia': 'Tucumán' },
        { 'descripcion': 'GOBERNADOR PIEDRABUENA', 'provincia': 'Tucumán' },
        { 'descripcion': 'GOMEZ CHICO', 'provincia': 'Tucumán' },
        { 'descripcion': 'GONZALO', 'provincia': 'Tucumán' },
        { 'descripcion': 'GRAMAJOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'GRANEROS', 'provincia': 'Tucumán' },
        { 'descripcion': 'GRANJA MODELO', 'provincia': 'Tucumán' },
        { 'descripcion': 'GUEMES', 'provincia': 'Tucumán' },
        { 'descripcion': 'GUZMAN', 'provincia': 'Tucumán' },
        { 'descripcion': 'GUZMAN ESTACION FCGB', 'provincia': 'Tucumán' },
        { 'descripcion': 'HIGUERITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'HITACHI', 'provincia': 'Tucumán' },
        { 'descripcion': 'HORCO MOLLE', 'provincia': 'Tucumán' },
        { 'descripcion': 'HOYADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUACRA', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUALINCHAY', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUASA PAMPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUASA PAMPA NORTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUASA PAMPA NORTE ,EST.HUASA PAMPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUASA PAMPA SUR', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUASA RINCON', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUASAMAYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUASAMAYO SUD', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUMAITA 1', 'provincia': 'Tucumán' },
        { 'descripcion': 'HUMAITA 2', 'provincia': 'Tucumán' },
        { 'descripcion': 'IBATIN', 'provincia': 'Tucumán' },
        { 'descripcion': 'ICHIPUCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'IGLESIAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'IGUANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ILTICO', 'provincia': 'Tucumán' },
        { 'descripcion': 'INDIA MUERTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ING MERCEDES', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIERO NUNORCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIERO SANTA LUCIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO AMALIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO BELLA VISTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO CONCEPCION', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO CRUZ ALTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO ESPERANZA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LA CORONA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LA ESPERANZA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LA FLORIDA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LA FRONTERITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LA PROVIDENCIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LA TRINIDAD', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LASTENIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LEALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LOS RALOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LULES', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO LULES ,EST.', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO MARAPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO SAN JOSE', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO SAN JUAN', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO SAN MIGUEL', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO SAN PABLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO SANTA ANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO SANTA BARBARA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO SANTA LUCIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INGENIO SANTA ROSA', 'provincia': 'Tucumán' },
        { 'descripcion': 'INVERNADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ISCHILLON', 'provincia': 'Tucumán' },
        { 'descripcion': 'ISLA SAN JOSE', 'provincia': 'Tucumán' },
        { 'descripcion': 'ISLA SAN JOSE SUD', 'provincia': 'Tucumán' },
        { 'descripcion': 'JAGUEL', 'provincia': 'Tucumán' },
        { 'descripcion': 'JAVA', 'provincia': 'Tucumán' },
        { 'descripcion': 'JAYA', 'provincia': 'Tucumán' },
        { 'descripcion': 'JUAN BAUTISTA ALBERDI', 'provincia': 'Tucumán' },
        { 'descripcion': 'JUAN POSSE', 'provincia': 'Tucumán' },
        { 'descripcion': 'JULIANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'JULIPAO', 'provincia': 'Tucumán' },
        { 'descripcion': 'JUNTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'JUSCO POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 10 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 102 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 1185 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 1194 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 1207 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 1213 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 1235 ,DV.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 1248 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 1254 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 1256 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 29 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 36 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 46 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 5 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 55 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 770 ,DV.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 781 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 784 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 794 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 808 ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 810 ,DV.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 825', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 847 ,DV.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KILOMETRO 99,DV.', 'provincia': 'Tucumán' },
        { 'descripcion': 'KM 12', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA AGUITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA ANGOSTURA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA BANDERITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA BOLSA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA BOMBA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA BRAMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA CAÑADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA CANADA PARADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA CANTINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA CAVERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA CHILCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA COCHA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA COLONIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA COMBADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA CONCEPCION', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA CORNELIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA COSTA PALAMPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA CRUZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA CRUZ DE ARRIBA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA EMPATADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA ENCANTADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA ERCILIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA FALDA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA FAVORITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA FLOR', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA FORTUNA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA FRONTERITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA GRAMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA GUILLERMINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA HELADERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA HIGUERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA IGUANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA INVERNADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA JUNTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA LAGUNILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA LAGUNITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA LIBERTAD', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA LOMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA MADRID', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA MANGA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA MARAVILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA MARTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA MEDIA AGUA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA MESADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA PINTA Y LA CUARENTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA PLANTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA POLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA POSTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA PRINCESA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA PUERTA DE LUCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA PUERTA DE MARAPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA QUEBRADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA QUESERIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA QUINTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA RAMADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA RAMADA DE ABAJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA REDUCCIÓN', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA REINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA RINCONADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA RINCONADA PARADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA RUDA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA SALA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA SALAMANCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA SILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA SOLEDAD', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA TALA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA TAPIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA TIPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA TRINIDAD', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA TUNA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LA VINITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LACAVERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LACHICO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAGARTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAGUNA DE ROBLES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAMADRID', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAMPARCITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAPACHITOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LARA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS ACOSTILLAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS ANIMAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS ARCAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS BANDERITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS BOLSAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS BOTIJAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS BRISAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS BURRAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS CANADAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS CARRERAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS CEJAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS CELAYAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS CIENAGAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS COLAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS COLONIAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS CRIOLLAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS CUATRO ESQUINAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS ENCRUCIJADAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS FALDAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS GUCHAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS GUCHAS LOS GUCHEA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS HIGUERILLAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS HIGUERITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS LEGUAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS LENGUAS LAS LEGUAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS MELLIZAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS MESADAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS MORERAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS MORITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS PALOMITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS PAMPITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS PARRITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS PAVAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS PECHOSAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS PIEDRITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS PIRCAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS PIRVAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS RATAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS SALINAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS TABLAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS TABLITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS TACANAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS TALAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS TALITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS TIPAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS TIPAS DE COLALAO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS TRANCAS TRANQUITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS TRES FLORES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS TUSCAS TUSCAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS ZANJAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS ZANJITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAS ZORRAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LASTENIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAUREL YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAURELES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAURELES NORTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAURELES SUR', 'provincia': 'Tucumán' },
        { 'descripcion': 'LAZARTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'LEALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LEO HUASI', 'provincia': 'Tucumán' },
        { 'descripcion': 'LEOCADIO PAZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LEÓN ROUGES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LESCANO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LILLO, MIGUEL', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOA DIAZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOLITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOLITA NUEVA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOMA COLORADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOMA DEL MEDIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOMA GRANDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOMA REDONDA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOMA VERDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOMAS DE IMBAUD', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOPEZ DOMINGUEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOPEZ DOMINGUEZ ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS AGUDOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS AGUEROS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS AGUIRRE', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ALAMITOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ALCARACES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ARRIETAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ARROYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS BAJOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS BATEONES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS BORDOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS BRITOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS BULACIOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS CAMPEROS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS CERCOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS CHAMICOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS CHAÑARES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS CHORRILLOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS CORDOBA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS CORDONES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS CORPITOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS CRESPO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS CUARTOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS DECIMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS DIAZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ESTANQUES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS EUCALIPTOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GALPONES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GODOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GODOY', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GOMEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GONZALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GONZALEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GRAMAJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GRAMAJOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GUAYACANES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GUCHEA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS GUTIERREZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS HARDOY', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS HERRERAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS HILOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS JUAREZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS LESCANOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS LUNAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS MENDOZAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS MOYES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS OCHO CUARTOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS PANCHILLOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS PARAISOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS PEDRAZA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS PEREYRA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS PEREZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS PINOS B', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS PIZARRO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS PLANCHONES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS POCITOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS PORCELES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS PUESTOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS QUEMADOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS RALOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS REYES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS RIOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS RIZOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ROBLES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS RODRIGUEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ROJOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ROMANOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS RUIZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS SARACHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS SARMIENTOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS SIFONES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS SORAIRE', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS SOSA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS SOTELO', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS SUELDOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS TIMBOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS TIMBRES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS TREJOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS TRES BAJOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS VALDES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS VALLISTOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS VAZQUEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS VEGA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS VILLAGRA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS VILLEGAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ZARAGOZA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ZAZOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOS ZELAYAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LOVAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'LUISIANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'LUISIANA ESTACION FCGM', 'provincia': 'Tucumán' },
        { 'descripcion': 'LULES', 'provincia': 'Tucumán' },
        { 'descripcion': 'LULES , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'LUNAREJOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'LUZ Y FUERZA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MACHO HUANUSCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MACIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MACIO SUR', 'provincia': 'Tucumán' },
        { 'descripcion': 'MACOMITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MALVINAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'MANAGUA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MANANTIAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'MANANTIAL DE OVANTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MANCHALA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MANCOPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MANCOPA CHICO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MANUEL GARCíA FERNÁNDEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'MANUELA PEDRAZA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MARAPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MARAPA , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'MARIA BLANCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MARIA ELENA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MARIÑO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MARTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MASCIO PILCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MATAZAMBI , arroyo', 'provincia': 'Tucumán' },
        { 'descripcion': 'MATE DE LUNA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MATO YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MATUL', 'provincia': 'Tucumán' },
        { 'descripcion': 'MAYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MEDINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MEDINA , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'MEDINA , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'MEDINAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'MEMBRILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MENDEZ, PEDRO G.', 'provincia': 'Tucumán' },
        { 'descripcion': 'MESADA DE ENCIMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MIGUEL LILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MILLAN, DOMINGO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MIMILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MIRAFLORES', 'provincia': 'Tucumán' },
        { 'descripcion': 'MISKY', 'provincia': 'Tucumán' },
        { 'descripcion': 'MISTOL', 'provincia': 'Tucumán' },
        { 'descripcion': 'MIXTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MOJON', 'provincia': 'Tucumán' },
        { 'descripcion': 'MOLLE CHATO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MOLLE DE ABAJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MOLLE POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MOLLE YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MOLLES', 'provincia': 'Tucumán' },
        { 'descripcion': 'MONTE BELLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MONTE GRANDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'MONTE LARGO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MONTE REDOMON', 'provincia': 'Tucumán' },
        { 'descripcion': 'MONTE REDONDO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MONTEAGUDO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MONTEROS', 'provincia': 'Tucumán' },
        { 'descripcion': 'MONTEROS VIEJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MONTUOSO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MORAS MINUCAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'MOTHE', 'provincia': 'Tucumán' },
        { 'descripcion': 'MOYA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MOYAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'MUJER MUERTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'MULTIFLORES', 'provincia': 'Tucumán' },
        { 'descripcion': 'MUNDO NUEVO', 'provincia': 'Tucumán' },
        { 'descripcion': 'MUÑECAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'MUYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'NARANJITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'NARANJO ESQUINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'NASCHE', 'provincia': 'Tucumán' },
        { 'descripcion': 'NEGRO POTRERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'NEVADO , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'NIO EL PUESTITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'NIO VILLA PADRE MONTI', 'provincia': 'Tucumán' },
        { 'descripcion': 'NIOGASTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'NOARIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'NOGALITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'NOGALITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'NORCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'NOREO', 'provincia': 'Tucumán' },
        { 'descripcion': 'NUEVA BAVIERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'NUEVA ESPAÑA', 'provincia': 'Tucumán' },
        { 'descripcion': 'NUEVA ESQUINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'NUEVA ROSA', 'provincia': 'Tucumán' },
        { 'descripcion': 'NUEVA TRINIDAD', 'provincia': 'Tucumán' },
        { 'descripcion': 'NUEVO PUEBLO LA FLORIDA', 'provincia': 'Tucumán' },
        { 'descripcion': 'NUEVOS MATADEROS', 'provincia': 'Tucumán' },
        { 'descripcion': 'NUÑORCO GRANDE , cerro', 'provincia': 'Tucumán' },
        { 'descripcion': 'OBRAJE', 'provincia': 'Tucumán' },
        { 'descripcion': 'OJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ORAN', 'provincia': 'Tucumán' },
        { 'descripcion': 'Otra', 'provincia': 'Tucumán' },
        { 'descripcion': 'OVERO POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'P G MENDEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'PACARA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PACARA MARCADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PACARA PINTADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PADILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAJA BLANCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAJA COLORADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PALA PALA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PALMAS REDONDAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PALO GACHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PALO SECO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PALOMAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PALOMINOS BANDA DE', 'provincia': 'Tucumán' },
        { 'descripcion': 'PALOS QUEMADOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAMPA LARGA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAMPA MAYO NOROESTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAMPA MUYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAMPA POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAMPA ROSA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PANTANILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PARADA DE OHUANTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PARAISO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PASO DE LAS LANZAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PASO DE LOS NIEVAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PASTEUR LUIS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAZ, BENJAMIN', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAZ, LEOCADIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PAZ, MARCOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PEDRAZA, MANUELA ,AP.', 'provincia': 'Tucumán' },
        { 'descripcion': 'PEDREGOSO , arroyo', 'provincia': 'Tucumán' },
        { 'descripcion': 'PENA OVERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PENA PICAZA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PEREYRA NORTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'PEREYRA SUR', 'provincia': 'Tucumán' },
        { 'descripcion': 'PERUCHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PICHAO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PIE DE LA CUESTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PIE DEL ACONQUIJA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PIEDRA GRANDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'PIEDRA TENDIDA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PIEDRAS COLORADAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PILCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PINGOLLAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'PIRHUAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PLANTA COMPRESORA YPF', 'provincia': 'Tucumán' },
        { 'descripcion': 'PLANTA DE BOMBEO DE YPF', 'provincia': 'Tucumán' },
        { 'descripcion': 'PLAYA LARGA', 'provincia': 'Tucumán' },
        { 'descripcion': 'POLIAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'POLITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POLITO ,DV.', 'provincia': 'Tucumán' },
        { 'descripcion': 'PONZACON', 'provincia': 'Tucumán' },
        { 'descripcion': 'PORT DE LAS ANIMAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PORT DE TOMAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PORTEZUELO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POSSE DESVIO PARTICULAR FCGM', 'provincia': 'Tucumán' },
        { 'descripcion': 'POSTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'POSTA VIEJA', 'provincia': 'Tucumán' },
        { 'descripcion': 'POTRERILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POTRERO DE LAS CABRAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'POTRERO DE LAS TABLAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'POTRERO DE LOS ALAMOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'POTRERO GRANDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'POTRO YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POZO ALTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POZO CAVADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POZO DEL ALGARROBO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POZO DEL ALTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POZO EL QUEBRACHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POZO GRANDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'POZO LAPACHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POZO LARGO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POZO SUNCHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'POZO VERDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'PRADERA ALEGRE', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUEBLO INDEPENDENCIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUEBLO OBRERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUEBLO VIEJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUEBLO VIEJO , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUENTE EL MANANTIAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUENTE RIO SALI', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUERTA ALEGRE', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUERTA DE JULIPAO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUERTA DE PALAVECINO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUERTA DE SAN JAVIER', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUERTA GRANDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUERTA QUEMADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUERTA SAN JAVIER', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUERTA VIEJA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUERTAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUERTAS GRANDES', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTITO DE ARRIBA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO 9 DE JULIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO BELEN', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO CEVIL CON AGUA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO CHICO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO CIENAGA AMARILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO COCHUCHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO DE ALUMBRE', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO DE AVILA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO DE ENCALILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO DE GALVANES', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO DE JULIPAO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO DE LOS VALDES', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO DE UNCOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO DE ZARZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO DEL MEDIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO LA RAMADITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO LOS AVILAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO LOS BARRAZA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO LOS GALVEZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO LOS PEREZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO LOS ROBLES', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO NUEVO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO VARELA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO VIEJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUESTO VILLAGRA', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUMA POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUNTA DE RIELES', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUNTA DEL MONTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'PUNTA RIELES', 'provincia': 'Tucumán' },
        { 'descripcion': 'QUEBRACHITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'QUEBRADA DE LULES', 'provincia': 'Tucumán' },
        { 'descripcion': 'QUINTEROS 1', 'provincia': 'Tucumán' },
        { 'descripcion': 'QUINTEROS 2', 'provincia': 'Tucumán' },
        { 'descripcion': 'QUISCA CHICA', 'provincia': 'Tucumán' },
        { 'descripcion': 'RACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RAFAELA POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RAMADITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'RAMOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'RANCHILLOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'RANCHILLOS VIEJOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'RANCHO DE CASCADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'REARTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'REQUELME', 'provincia': 'Tucumán' },
        { 'descripcion': 'RESCATE', 'provincia': 'Tucumán' },
        { 'descripcion': 'RETIRO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RIARTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'RIEGASTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'RINCON DE BALDERRAMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'RINCON DE LAS TACANAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'RINCON DE QUILMES', 'provincia': 'Tucumán' },
        { 'descripcion': 'RINCON GRANDE', 'provincia': 'Tucumán' },
        { 'descripcion': 'RINCON HUASA', 'provincia': 'Tucumán' },
        { 'descripcion': 'RIO BLANCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RíO CHICO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RíO COLORADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RíO DEL NIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RIO HONDITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RIO LORO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RIO LULES', 'provincia': 'Tucumán' },
        { 'descripcion': 'RíO SECO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RIO VIPOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'RODEO DEL ALGARROBO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RODEO TORO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ROMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'ROMERA POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ROMERELLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ROMERO POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ROSARIO OESTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'ROUGES, LEON', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUMI COCHA', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUMI PUNCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUMI YURA', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA NACIONAL 157', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA NACIONAL 38', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA NACIONAL 64', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA NACIONAL 9', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA NACIONAL38', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 301', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 302', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 303', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 304', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 306', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 308', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 319', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 320', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 322', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 323', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 333', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 334', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 335', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 338', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 366', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 374', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 375', 'provincia': 'Tucumán' },
        { 'descripcion': 'RUTA PROVINCIAL 380', 'provincia': 'Tucumán' },
        { 'descripcion': 'SACRIFICIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SALA VIEJA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SALAMANCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SALAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'SALí , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'SALINAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN AGUSTIN', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN ALBERTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN ANDRÉS', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN ANTONIO DE QUISCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN ARTURO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN BERNARDO B', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN CARLITOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN EUSEBIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN FEDERICO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN FELIPE', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN GABRIEL DEL MONTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN IGNACIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JAVIER', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JENARO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JOSE', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JOSE DE BUENA VISTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JOSE DE CHASQUIVIL', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JOSE DE FLORES', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JOSÉ DE LA COCHA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JOSE DE LEALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JOSE DE LULES', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JOSE DE MACOMITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JOSE DE SAN MARTIN', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN JULIAN YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN LUIS DE LAS CASAS VIEJAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN MIGUEL', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN MIGUEL DE TUCUMÁN', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN MIGUEL DE TUCUMAN ,EST.TUCUMAN', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN MIGUELITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN PABLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN PATRICIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN PEDRO DE COLALAO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN PEDRO MARTIR', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN PEREYRA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN RAFAEL', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN RAMON CHICLIGASTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAN VICENTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANDIS', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANDOVALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANTA ANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANTA BARBARA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANTA CLARA SUD', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANTA CRUZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANTA FELISA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANTA LUCíA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANTA MARíA , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANTA MONICA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SANTA ROSA DE LEALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'SARGENTO MOYA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAUCE GAUCHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAUCE HUACHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAUCE PARTIDO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAUCE SECO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAUCE YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAUCE YACU', 'provincia': 'Tucumán' },
        { 'descripcion': 'SAUZAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'SECO , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'SEPULTURA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SESTEADERO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SIAMBON', 'provincia': 'Tucumán' },
        { 'descripcion': 'SILLETA DE ESCABA , sierra', 'provincia': 'Tucumán' },
        { 'descripcion': 'SIMOCA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SINQUEAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'SINQUIAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'SINQUIEAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'SOL DE MAYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SOLCO , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'SOLDADO MALDONADO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SORAIRE', 'provincia': 'Tucumán' },
        { 'descripcion': 'SUD DE LAZARTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'SUD DE SANDOVALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'SUD DE TREJOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'SUELDO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SUELDOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'SUNCHO PUNTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SUPERINTENDENTE LEDESMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'SURIYACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'SURIYACU', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACANAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACANAS , Est. Superintendente Ledesma', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACANAS , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACO LLANO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACO PALTA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACO PUNCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACO RALO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACO RODEO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACO YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TACO YANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TAFí DEL VALLE', 'provincia': 'Tucumán' },
        { 'descripcion': 'TAFí VIEJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TAFICILLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALA BAJADA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALA CAIDA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALA PAMPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALA PASO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALA POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALA SACHA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALA YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALAMUYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALILAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALITA POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'TALLERES NACIONALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'TAMBOR DE TACUARI', 'provincia': 'Tucumán' },
        { 'descripcion': 'TAPIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TAQUELLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TARUCA PAMPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TATA YACU', 'provincia': 'Tucumán' },
        { 'descripcion': 'TECOTEX', 'provincia': 'Tucumán' },
        { 'descripcion': 'TENIENTE BERDINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TICUCHO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TIERRAS BLANCAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'TIMBO NUEVO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TIMBO VIEJO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TINAJEROS', 'provincia': 'Tucumán' },
        { 'descripcion': 'TIO FRANCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TIO PUNCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TIPA MAYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TIPAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'TOCO LLANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TOQUELLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TORO LOCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TORO MUERTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TOTORILLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TOTORITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'TRANCAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'TRANQUITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'TREJOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'TRES ALMACENES', 'provincia': 'Tucumán' },
        { 'descripcion': 'TRES POZOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'TUNA SOLA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TUNALITO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TUSCA PAMPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'TUSCA POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TUSCAL', 'provincia': 'Tucumán' },
        { 'descripcion': 'TUSCAL REDONDO', 'provincia': 'Tucumán' },
        { 'descripcion': 'TUSQUITAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'UCUCHACRA', 'provincia': 'Tucumán' },
        { 'descripcion': 'URIZAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'UTURUNCO', 'provincia': 'Tucumán' },
        { 'descripcion': 'UTURUNGU', 'provincia': 'Tucumán' },
        { 'descripcion': 'UTURUNO', 'provincia': 'Tucumán' },
        { 'descripcion': 'VA RECASTE', 'provincia': 'Tucumán' },
        { 'descripcion': 'VACAHUASI', 'provincia': 'Tucumán' },
        { 'descripcion': 'VALENZUELA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VESUBIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'VIADUCTOS DEL TORO', 'provincia': 'Tucumán' },
        { 'descripcion': 'VICLOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'VIELOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILCA POZO', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA ALBERDI', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA ALBERDI ESTACION', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA ALVEAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA ANGELINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA BELGRANO', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA BENJAMIN ARAOZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA BRAVA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA BURRUYACU', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA CARMELA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA CAROLINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA CLODOMIRO HILERET', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA COLMENA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA DE LOS BRITOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA DESIERTO DE LUZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA DEVOTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA EL BACHE', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA EL RETIRO', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA FIAD', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA GLORIA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA LA COLMENA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA LA SOLEDAD', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA LA TRINIDAD', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA LA TUNA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA LEALES', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA LUJAN', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA MARCOS PAZ', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA MITRE', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA MONTE CRISTO', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA MUNECAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA NOUGUES', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA NUEVA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA NUEVA AGUILARES', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA PADRE MONTI', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA PUJIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA QUINTEROS', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA RESCATE', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA RITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA SAN JAVIER', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA SANTA ROSA DE NUEVA TRIN', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA TERCERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA TRANCAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA VIEJA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA VIEJA SANTA ANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILLA ZENON SANTILLAN', 'provincia': 'Tucumán' },
        { 'descripcion': 'VILTRAN', 'provincia': 'Tucumán' },
        { 'descripcion': 'VIPOS', 'provincia': 'Tucumán' },
        { 'descripcion': 'VIPOS , río', 'provincia': 'Tucumán' },
        { 'descripcion': 'VIZCACHERA', 'provincia': 'Tucumán' },
        { 'descripcion': 'YACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'YACUCHINA', 'provincia': 'Tucumán' },
        { 'descripcion': 'YACUCHIRI', 'provincia': 'Tucumán' },
        { 'descripcion': 'YALAPA', 'provincia': 'Tucumán' },
        { 'descripcion': 'YAMINAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'YANA MAYO , arroyo', 'provincia': 'Tucumán' },
        { 'descripcion': 'YANGALLO', 'provincia': 'Tucumán' },
        { 'descripcion': 'YANIMAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'YAPACHIN', 'provincia': 'Tucumán' },
        { 'descripcion': 'YAQUILO', 'provincia': 'Tucumán' },
        { 'descripcion': 'YARAMI', 'provincia': 'Tucumán' },
        { 'descripcion': 'YASYAMAYO', 'provincia': 'Tucumán' },
        { 'descripcion': 'YATAPAYANA', 'provincia': 'Tucumán' },
        { 'descripcion': 'YERBA BUENA', 'provincia': 'Tucumán' },
        { 'descripcion': 'YERBA HUASI', 'provincia': 'Tucumán' },
        { 'descripcion': 'YMPAS', 'provincia': 'Tucumán' },
        { 'descripcion': 'YONOPONGO', 'provincia': 'Tucumán' },
        { 'descripcion': 'YUCHACO', 'provincia': 'Tucumán' },
        { 'descripcion': 'YUCUMANITA', 'provincia': 'Tucumán' },
        { 'descripcion': 'YUMILLURA', 'provincia': 'Tucumán' },
        { 'descripcion': 'YUNCA SUMA', 'provincia': 'Tucumán' },
        { 'descripcion': 'YUNOPONGO SUD', 'provincia': 'Tucumán' },
        { 'descripcion': 'ZANJON MASCIO', 'provincia': 'Tucumán' },
        { 'descripcion': 'ZAPALLAR', 'provincia': 'Tucumán' },
        { 'descripcion': 'ZURITA', 'provincia': 'Tucumán' },
    ],
}