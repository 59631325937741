<template>
  <v-container>
    <v-row>
      <form style="display: none" method="post" :action="paymentUrl">
        <input type="text" name="Comercio" id="Comercio" :value="macroKey" /><br />
        <input type="text" name="Monto" id="Monto" :value="encriptedData.monto" />
        <br />

          <input type="text" name="CallbackSuccess" id="CallbackSuccess" :value="encriptedData.success" />
          <br />

        <input type="text" name="CallbackCancel" id="CallbackCancel" :value="encriptedData.cancel" />
        <br />

        <input type="text" name="SucursalComercio" id="SucursalComercio" :value="encriptedData.comercio" />
        <br />

        <input type="text" name="Producto[0]" id="Producto[0]" :value="description" />
        <br />

        <input type="text" name="TransaccionComercioId" id="TransaccionComercioId" :value="resumen.paymentIntent" />
        <br />

        <input type="text" name="Informacion" id="Informacion" :value="encriptedData.informacion" />

        <button type="submit" id="submitMacroClick">Enviar!</button>
      </form>
      <PaymentBillingDataTeclab
        v-if="$isTeclab"
        ref="billingData"
        @update="billingData = $event"
        @validate="billingDataValid = $event"
      ></PaymentBillingDataTeclab>
    </v-row>
  </v-container>
</template>

<script>
import AESEncrypter from 'pluspagos-aes-encryption/dist/AESEncrypter';
import PaymentBillingDataTeclab from '../PaymentBillingData/PaymentBillingDataTeclab';
import { mapGetters } from 'vuex'
import { $payments } from '../../Services';
import commons from '../../Mixins/commons';
import Configuration from '@/constants/configuration'

export default {
  name: 'GatewayMacro',
  mixins: [ commons ],
  props: {
    resumen: Object,
    selectedAccount: { type: Object },
    subscription: { type: Object, default: null },
    summaryButtons: Object,
  },
  components: {
    PaymentBillingDataTeclab
  },
  created() {
    this.getTokenMacro()
  },
  data() {
    return {
      deviceId: null,
      billingDataValid: false,
      publicApiKey: null,
    }
  },
  computed: {
    ...mapGetters({
      studentRegister: 'commons/getStudentActualRegister',
      studentUserData: 'commons/getStudentUserData',
      loggedUsername: 'commons/getUserName',
    }),
    encriptedData() {
      const CallbackSuccess = `${this.redirectUrl}?status=approved`
      return {
        monto: this.publicApiKey ? AESEncrypter.encryptString(this.resumen.total.toFixed(2), this.publicApiKey) : null,
        success: this.publicApiKey ? AESEncrypter.encryptString(CallbackSuccess, this.publicApiKey) : null,
        cancel: this.publicApiKey ? AESEncrypter.encryptString(this.redirectUrl, this.publicApiKey) : null,
        comercio: this.publicApiKey ? AESEncrypter.encryptString('', this.publicApiKey) : null,
        informacion: this.publicApiKey ? AESEncrypter.encryptString(`${this.studentData.academicRegister}-${this.description}`, this.publicApiKey) : null,
      }
    },
    description() {
      let description = ''
      this.resumen.items.subtotal.items.forEach((concepto, index) => {
        if (index === 0) description = concepto.title
        else description  += ` - ${concepto.title}`
      })
      return description
    },
    redirectUrl() {
      let environment = Configuration.value('app')
      if (Configuration.value('env') == 'development') environment += 'dev'
      else if (Configuration.value('env') == 'staging') environment += 'pre'

      return `${environment}.teclab.edu.ar/pagos`
    },
    paymentUrl() {
      if (Configuration.value('env') === 'production') {
        return 'https://botonpp.macroclickpago.com.ar/'
      } else {
        return 'https://sandboxpp.macroclickpago.com.ar/'
      }
    },
    macroKey() {
      return Configuration.value('macroKey')
    }
  },
  methods: {
    getTokenMacro(){
      this.publicApiKey = this.$getAccountKey(this.selectedAccount?.account_id)
    },
    async saveBillingData() {
      try {
        this.summaryButtons.primary.loading = true
        await this.$refs.billingData.save()
        this.createPayment()
      } catch (error) {
        this.summaryButtons.primary.loading = false
        this.summaryButtons.primary.error = true

        throw error;
      }
    },
    async createPayment() {
      try {
        const paymentData = {
          'legajo': this.studentRegister,
          'amount': this.$encrypter(parseFloat(this.resumen.total).toFixed(2)),
          'description': this.subscription ? 'GASTO_ADMINISTRATIVO' : this.description,
          'payment_method_id': this.selectedAccount.id,
          'token': null,
          'installments': this.selectedInstallment?.installments || 1,
          'payer_email': this.studentUserData.email,
          'issuer_id': null,
          'usr_portal': this.loggedUsername,
          'discount_intent': this.resumen.paymentIntent,
          'intencion_de_pago_id': this.$paymentsIdToArray(this.resumen.items.subtotal.items),
          'user_type': this.$isGestor ? 'ADVISOR' : 'STUDENT',
          'doc_number': this.selectedAccount?.cardId,
          'doc_type': 'DNI',
          'device_id': this.deviceId,
          'firstName': this.studentData.student.user.userData.name,
          'lastName': this.studentData.student.user.userData.lastname,
          'capture': true,
          'pago_una_tarjeta': true,
          'credit_card_fee': this.selectedInstallment?.credit_card_fee ?? null,
          'secondAmount': null,
          'percentage': null,
          'idsPending': this.unselectedReenrollmentsAccountIds,
          'bin': null,
          'accountId': this.selectedAccount?.account_id,
          'city': this.$isTeclab ? this.billingData.city : this.billingData.city.cityState,
          'phone': this.$isTeclab ? this.billingData.phone : '',
          'postal_code': this.$isTeclab ? this.billingData.zipCode : '',
          'state': this.$isTeclab ? this.billingData.state : '',
          'address': `${this.billingData.street}-${this.billingData.number}`,
          'device_unique_identifier': 0
        }

        const response = await $payments.create(paymentData)
        if (response && response.status === 200) {
          document.getElementById('submitMacroClick').click()
        } else {
          this.summaryButtons.primary.loading = false
          this.summaryButtons.primary.error = true
        }
      } catch (error) {
        this.summaryButtons.primary.loading = false
        this.summaryButtons.primary.error = true
        throw error
      }
    },
  },
  watch: {
    billingDataValid(value) {
      this.summaryButtons.primary.disabled = !value
    }
  }
}
</script>