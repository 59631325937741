<template>
  <v-container :key="key" fluid class="superTable pa-0">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" :placeholder="$t('actions.search')" outlined
          clearable class="superTable__search" @keyup="keyup" @click:clear="clearData" @keyup.enter="searchAction"
          @keyup.esc="() => { this.search = ''; this.clearData() }"></v-text-field>
      </v-col>
      <v-col sm="4">
        <Button class="ml-4" @clicked="searchAction" outlined depressed :text="$t('actions.search')"></Button>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table :headers="headers" :items="data" :totalItems="totalItems" :page.sync="page" :sort-by="sortBy"
          :sort-desc="sortDesc" @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
          :items-per-page="itemsPerPage" hide-default-footer item-key="table_id" class="superTable__table"
          style="table-layout: fixed">

          <template v-slot:[`item.grade`]="{ item }">
            <div v-if="item.grade != null && item.grade != '' && regulation.value === 'PREV'">{{ item.grade }}</div>
            <div v-else-if="!permissions.create && regulation.value === 'PREV'">-</div>
            <v-btn v-else-if="showDegree(item)" @click="$emit('createOpen', item)" outlined rounded color="primary">{{
              $t('modules.examTitleNotes.table.regulations.button') }}</v-btn>
          </template>

          <template v-slot:[`item.action`]="{ item }" v-if="permissions.update">
            <v-menu bottom left>
              <template v-slot:activator="{ on }" v-if="showUpdate(item)">
                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item @click="$emit('updateOpen', item)" v-if="permissions.update">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span class="caption d-inline-block">{{ `${itemsCount.startItem} - ${itemsCount.endItem} de
                  ${itemsCount.totalItems}` }}</span>
        <v-select v-model="itemsPerPage" :items="itemsPerPageAvailable" :placeholder="itemsPerPage.toString()" outlined
          @change="$emit('lengthPaginationChange', itemsPerPage)"
          class="superTable__paginationSelect d-inline-block ml-4"></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination :value="page" :length="pageCount" @next="$emit('nextPagination', page)"
          @previous="$emit('previousPagination', page)" @input="$emit('inputPagination', $event)"
          class="superTable__pagination"></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field v-model.number="goToPage" max="pageCount" outlined @blur="changePage"
          class="superTable__paginationInput d-inline-block mr-4"></v-text-field>
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass">
    .superTable
        &__table
            &__item
                *
                    font-size: 14px !important
                &__checkbox
                    .v-icon
                        opacity: 0.1
                        transition: opacity 200ms ease
                        font-size: 20px !important
                        &.mdi-checkbox-marked
                            opacity: 1
                &__name
                    display: flex
                    align-items: center
                    font-weight: 500 !important
                &__action
                    opacity: 0.1
                    transition: opacity 200ms ease
                    font-size: 20px !important
                    &.mdi-vertical-dots-marked
                        opacity: 1
                    & + .v-menu__content
                        border-radius: 2px
                        border: 1px solid red
            tbody tr:hover
                .superTable__table__item__checkbox .v-icon, .superTable__table__item__action
                    opacity: 1
            .v-data-table-header
                .superTable__table__item__checkbox:hover
                    .v-icon
                        opacity: 1
            .v-menu.v-menu--inline
                display: none !important
        &__search
            .v-input
                &__slot
                    border-radius: 24px !important
                    padding: 0 24px !important
                &__prepend-inner
                    margin-top: 10px !important
            .v-text-field__details
                display: none !important
        &__pagination
            .v-pagination__navigation, .v-pagination__item
                border-radius: 50%
                outline: 0
                box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 1px 5px 0px rgba(0, 0, 0, 0.1)
        &__paginationSelect
            min-width: 20px
            width: min-content
            .v-input
                &__slot
                    min-height: 30px !important
                    padding: 0 0 0 10px !important
                    margin-bottom: 0 !important
                &__append-inner
                    padding: 0 !important
            .v-select
                &__selections
                    padding: 0 !important
            .v-text-field__details
                display: none
        &__paginationInput
            min-width: 45px
            width: min-content
            .v-input
                &__slot
                    min-height: 30px !important
                    margin-bottom: 0 !important
            .v-text-field__details
                display: none
        &__bulkActions
            &.show
                animation-fill-mode: forwards
                animation-name: smoothShow
                animation-duration: 1s
                animation-timing-function: ease-in
            &.hide
                animation-fill-mode: forwards
                animation-name: smoothHide
                animation-duration: 1s
                animation-timing-function: ease-in
                div
                    transform: scale(0)
                    transition: transform 1s step-end

    @keyframes smoothHide
        0%
            opacity: 1
            height: 60px
        15%
            opacity: 0
        50%
            height: 0
        100%
            height: 0
            opacity: 0

    @keyframes smoothShow
        0%
            opacity: 0
            height: 0
        15%
            opacity: 0
        50%
            height: 60px
        100%
            height: 60px
            opacity: 1
</style>

<script>
import moment from 'moment';
import { customAlphabet } from 'nanoid'
import Button from '@/components/Button/Button';

export default {
  name: 'SuperTable',
  components: {
    Button
  },
  props: {
    data: Array,
    headers: Array,
    permissions: { create: false, update: false },
    pageCount: { type: Number, default: 1 },
    page: { type: Number, default: 1 },
    sortBy: String,
    sortDesc: Boolean,
    totalItems: Number,
    regulation: Object
  },
  data() {
    return {
      key: '',
      search: '',
      timeout: null,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      goToPage: 1,
    }
  },
  methods: {
    keyup() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchAction();
        clearTimeout(this.timeout);
      }, 1000)
    },
    editItem(id) {
      this.$emit('updateOpen', id);
    },
    searchAction() {
      if (this.search || this.search === '') this.$emit('searchAction', this.search);
    },
    clearData() {
      this.$emit('searchAction', '');
    },
    changePage() {
      if (this.goToPage > this.pageCount) {
        this.goToPage = this.page;
      } else {
        this.$emit('inputPagination', this.goToPage)
      }
    },
    showDegree(item) {
      const validDate = moment(item.date).isValid()
      const validGrade = typeof item.grade === 'number'
      const isValidR23 = this.regulation.value === 'R23' && !validDate
      const isValidPREV = this.regulation.value === 'PREV' && !(validDate && validGrade)
      return isValidPREV || isValidR23
    },
    showUpdate(item) {
      const validDate = moment(item.date).isValid()
      const validGrade = typeof item.grade === 'number'
      const isValidR23 = this.regulation.value === 'R23' && validDate
      const isValidPREV = this.regulation.value === 'PREV' && validDate && validGrade
      return isValidPREV || isValidR23
    },
    generateKey() {
      const nanoid = customAlphabet('1234567890abcdef', 10)
      return nanoid()
    }
  },
  watch: {
    page(val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val < 1) this.page = 1;
      if (this.page !== this.goToPage) this.goToPage = this.page;
    },
    data() {
      this.key = this.generateKey()
    }
  },
  computed: {
    itemsCount() {
      const counter = {
        totalItems: this.totalItems,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };

      if (counter.endItem > this.totalItems) {
        counter.endItem = this.totalItems
      }

      return counter;
    }
  },
};
</script>
