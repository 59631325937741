<template>
    <v-container fluid class="fullHeight scrollableContent">
        <v-container>
            <v-row>
                <v-col cols="12" sm="8" lg="6" class="offset-sm-2 offset-lg-3">
            
                    <div class="py-6 d-flex flex-column mb-8">
                        <div class="d-flex justify-start">
                            <v-img
                                max-width="100px" height="auto" contain
                                :src="logoPath"
                            ></v-img>
                        </div>
                    </div>
            
                    <SuccessMessage
                        v-if="successMessage.type != null"
                        :type="successMessage.type"
                        :title="successMessage.title"
                        :subtitle="successMessage.subtitle"
                        :actionPrimary="successMessage.actionPrimary"
                    />

                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import Constants from '@/plugins/constants'
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
    import { mapGetters } from 'vuex';
    //import baseUrl from '@/constants/uri';
    export default {
        name: 'MercadoPagoDebit',
        components:{
            SuccessMessage
        },
        data () {
            return {
                url_data_server: Constants.URL_DATA_SERVER,
                payment_id: null,
                payment_status: null,
                successMessage: {
                    type: null,
                    title: null,
                    subtitle: null,
                    actionPrimary: {text: 'Ir al Portal', callback: ()=>{this.$router.replace('/')}},
                },
            }
        },
        async mounted() {
            if (this.$route.query.payment_id) this.payment_id = this.$route.query.payment_id
            if (this.$route.query.status) this.payment_status = this.$route.query.status
            // await fetch(`${baseUrl}/payment/notification/MP1?source_news=webhooks`, {
            //     method: 'POST',
            //     mode: 'no-cors'
            // })
                if (this.payment_status === 'approved') {
                this.successMessage.type = 'success'
                this.successMessage.title = 'El pago fue acreditado correctamente'
            } else {
                this.successMessage.type = 'error'
                this.successMessage.title = 'No pudimos validar el pago'
            }
        },
        computed: {
            ...mapGetters({
                logoPath: 'commons/getLogoImageUrl',
            }),
        },
    }
</script>