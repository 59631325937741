<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
              :title="$t('modules.portalalumno.reglamento.breadcrumbs.title')"
              :description="$t('modules.regulation.RegulationCardsView.breadcrumbs.description')"
              :callback="() => { this.backHome() }"
      ></Breadcrumbs>
          <v-row justify="center" class="mt-15">
            <h2 class="text-center" > {{ $t('modules.regulation.table.headers.title') }} </h2>
          </v-row>
          <v-row
            max-height="1000px"
            justify="center"
            class="mt-15"
          >
          <div class="cards">
            <div class="card" v-for="card in cards" :key="card.id">
              <div
                @click="
                  $router.push({
                    path: 'regulation/'.concat(card.path),
                    params: { regulation: card.path },
                  })"
              >
                <Reglamento-card :card="card" style="width: 230px; height: 230px;"></Reglamento-card>
              </div>
            </div>
          </div>
          </v-row>
    </v-container>
  </v-container>
</template>

<script>
import ReglamentoCard from '#/portalalumno/Reglamento/Components/ReglamentoCard';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Constants from '@/plugins/constants';
import { $rules } from '../Services';
import { mapGetters } from 'vuex';
export default {
  components: {
    ReglamentoCard,
    Breadcrumbs
  },
  name: 'RegulationCardsView',
  data() {
    return {
      autocompleteItems: [],
      capituloNews: [],
      selectedEntryId: null,
      searchArt: '',
      searchText: null,
      cards: [],
      selectedCapitulo: null,
    };
  },
  computed: {
    token() {
      return this.$isTeclab
        ? '9e705928b960e3c338102ab3233e1e'
        : '9b840d8b42fa7c01c06df4e95913d0';
    },
    uriBase() {
      return `https://${Constants.DOMAIN}`;
    },
    ...mapGetters({
      rulesImage: 'commons/getRulesImage',
    }),
  },
  methods: {
    backHome() {
      this.selectedCapitulo = null;
      this.selectedEntryId = null;
      this.searchText = '';
      this.autocompleteItems = this.capituloNews;
    },
    async getFetchCard() {
      //Revisar para unificar con argentina y chile antes de unificar países
      try {
        //Revisar para unificar con argentina y chile antes de unificar países
        const { data } = await $rules.find('rulesType');
        for (const [name, id] of Object.entries(data)) {
          const path = name;
          const img = ''.concat(this.rulesImage, name, '.svg') ;
          const title = name.charAt(0).toUpperCase().concat(name.slice(1));
          this.cards.push({ title, id, path, img });
        }
      } catch (error) {
        this.cards = [];
        throw error;
      }
    },
  },
  async mounted() {
    await this.getFetchCard();
  },
};
</script>

<style lang='sass' scoped>
.cards
    display: grid
    grid-template-columns: auto auto
    gap: 40px
.bg
    background-color: #ecedf0
.v-menu__content
    background-color: red !important
</style>
