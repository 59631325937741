<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed"></AsideBar>
      <v-container fluid class="scrollableContent pa-0">
        <v-container class="container-custom px-6">
          <Breadcrumbs :description="breadcrumbs.description" :title="breadcrumbs.title">
            <Button
              @clicked="newPeriod"
              :icon="'mdi-plus'"
              :text="$t('actions.new')"
              depressed
              :disabled="!canCreate"
            ></Button>
          </Breadcrumbs>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <SuperTable
                :headers="headers"
                :users="periods"
                :clear-selected="clearSelected"
                :permissions="{ update: canUpdate, delete: canDelete, closeBianual: canCloseSubjectBianual, closePractical: canCloseSubjectPractical }"
                @inputPagination="onInputPagination"
                @lengthPaginationChange="onLengthPaginationChange"
                @bulk-delete="(periods)=>{bulkDeletePopUp(periods)}"
                @deleteUser="(id)=>deletePeriodPopUp(id)"
                @updateUser="(id)=>{updatePeriod(id)}"
                @closePractical="(id)=>{closePracticalSubject(id)}"
                @closeBianual="(id)=>{closeBianualSubject(id)}"
              />
              <!-- SuperTableBackendPagination
              :pageCount="asidebar.pagination.pagesAmount"
              :page="asidebar.pagination.currentPage"
              :totalItems="totalItems"-->
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import AsideBar from '@/components/AsideBar/AsideBar'
import Button from '@/components/Button/Button';
import { $terms, $types, $tools} from '../services';
import moment from 'moment'
import { mapGetters } from 'vuex';

export default {
  name: 'PeriodsList',
  components: {
    SuperTable,
    Breadcrumbs,
    AsideBar,
    Button
  },
  data () {
    return {
      totalItems: 10,
      clearSelected: '',
      breadcrumbs: {
        title: this.$t('modules.academic.periods.list.breadcrumbs.title'),
        description: this.$t('modules.academic.periods.list.breadcrumbs.description'),
        button: {
          name: 'Nuevo',
          icon: 'mdi-plus',
          color: 'primary'
        }
      },
      availableYears: [],
      asidebar: {
        title: 'Refinar búsqueda',
        pagination: {
          limit: 500,
          page: 1,
          currentPage: 1,
          pagesAmount: 1,
        },
        items: [
          {
            name: 'Inicio de cursado',
            field: 'classStartDate',
            filters: [
              {
                type: 'autocomplete',
                name: 'Inicio de cursado',
                field: 'classStartDate',
                condition: 'gt',
                items: [],
                value: null,
                clearable: true,
                isCalendarOpen: false,
                dataFiltered: 123
              },
            ]
          },
          {
            name: 'Inicio de venta',
            field: 'saleDateFrom',
            filters: [
              {
                type: 'autocomplete',
                name: 'Inicio de venta',
                field: 'saleDateFrom',
                items: [],
                condition: 'gt',
                value: null,
                clearable: true,
                isCalendarOpen: false,
                dataFiltered: 123
              },
            ]
          },
          {
            name: this.$t('modules.academic.periods.list.period'),
            field: 'saleDateFrom',
            filters: null
          }
        ]
      },
      headers: [
        {
          text: this.$t('modules.academic.periods.list.code'),
          value: 'code',
        },
        {
          text: this.$t('modules.academic.periods.list.name'),
          value: 'name',
        },
        {
          text: this.$t('modules.academic.periods.list.status'),
          value: 'status',
        },
        {
          text: this.$t('modules.academic.periods.list.start'),
          value: 'classStartDate',
        },
        {
          text: this.$t('modules.academic.periods.list.end'),
          value: 'classEndDate',
        },
        {
          text: this.$t('modules.academic.periods.list.period'),
          value: 'termType',
        },
        {
          text: '',
          value: 'action'
        }
      ],
      rawPeriods: [],
      periods: [],
      modalityTypes: []
    }
  },
  async mounted () {
    await this.getPeriods()
    const generatedYears = [];
    for (let index = -1; index <= 5; index++) {
      const year = moment().subtract(index, 'year').startOf('year').format('YYYY')
      generatedYears.push({ text: year, value: year })
    }
    this.asidebar.items[0].filters[0].items = generatedYears
    this.asidebar.items[1].filters[0].items = generatedYears
    this.fetchData();
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.periods.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.periods.READ || this.$permissions.portaladministrativo.academic.periods.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.academic.periods.DELETE
    },
    canCloseSubjectBianual() {
      return this.$permissions.portaladministrativo.academic.periods.FINISH_SUBJECTS_BIANUAL
    },
    canCloseSubjectPractical() {
      return this.$permissions.portaladministrativo.academic.periods.FINISH_SUBJECTS_PRACTICAL
    },
    currentFormattedFilters () {
      const filterObject = {};
      if (this.asidebar.items[0].filters[0].value) filterObject.classStartDate = this.asidebar.items[0].filters[0].value;
      if (this.asidebar.items[1].filters[0].value) filterObject.saleDateFrom = this.asidebar.items[1].filters[0].value;
      if (this.asidebar.items[2] && this.asidebar.items[2].filters.some(filter => filter.value === false)) filterObject.modalityType = this.asidebar.items[2].filters.map(filter => {
        if (filter.value === true) return filter.filterValue
      }).filter(value => value);
      return filterObject
    },
    ...mapGetters({
      configurationValues: 'settings/getAllCurrentValues',
      configurationOptions: 'settings/getAllOptions',
      userId: 'commons/getUserID'
    })
  },
  methods: {
    async getPeriods() {
        const fetchedPeriods = await $types.find(null, null, { params: { type: 'PERIOD_TYPE' } })
        this.asidebar.items[2].filters = fetchedPeriods.data.map(item => {
          return {
            id: item.value,
            name: item.meaning,
            type: 'check',
            items: [],
            value: true,
            dataFiltered: '',
            mandatory: true
          }
        });        
      },
    async onInputPagination () { // event param
      // this.asidebar.pagination.page = event
      // if (!this.periods[(event-1)*this.asidebar.pagination.limit]) {
      //   let fetchedPeriods = await $terms.find(null, null, {params: {page: this.asidebar.pagination.page-1, length: this.asidebar.pagination.limit}})
      //   fetchedPeriods.data.content.forEach((element, index) => {
      //     this.periods[index+(event-1)*this.asidebar.pagination.limit] = this.formatPeriods(element)
      //   })
      //   this.periods = Array.from(this.periods)
      // }
      // this.asidebar.pagination.currentPage = event
    },
    async changed () { // parent, filter and value parameters
      let filteredItems = this.rawPeriods
      this.asidebar.items.forEach(element => {
        if (element.name === 'Inicio de cursado' && element.filters[0].value) {
          if (element.filters[0].value !== 'clear') {
            filteredItems = filteredItems.filter(item => +moment(item.classStartDate, this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()).year() === +element.filters[0].value)
          }
        } else if (element.name === 'Inicio de venta' && element.filters[0].value) {
          if (element.filters[0].value !== 'clear') {
            filteredItems = filteredItems.filter(item => +moment(item.saleDateFrom, this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()).year() === +element.filters[0].value)
          }
        }
      });
      this.periods = filteredItems
      // this.asidebar.pagination.page = 0
      // this.asidebar.items[parent].filters[filter].value = value
      // let fetchedPeriods = await $terms.findWithFilter(this.currentFormattedFilters, {params: {page: this.asidebar.pagination.page, length: this.asidebar.pagination.limit}})
      // this.periods = this.formatPeriods(fetchedPeriods.data)
      this.fetchData();
    },
    onLengthPaginationChange () {
      // this.asidebar.pagination = {
      //   page: 1,
      //   limit: event,
      //   currentPage: 1,
      //   pagesAmount: 1,
      // };
      // this.fetchData()
    },
    async closeBianualSubject (id) { //id param
      const period = this.periods.find(period => period && period.id === id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('alert'),
        content: [{ value: this.$t('modules.academic.periods.closeBianualSubject.title', {periodo: period.name})}],
        actionPrimary: {    
          text: this.$t('actions.accept'), callback: async () => {
            try {
              await $tools.find(`finishBianualSubject?term=${id}`)
            } catch {
              this.$store.dispatch('commons/openPopUp', {
                title: this.$t('alert'),
                content: [{ value: this.$t('modules.academic.periods.closeBianualSubject.error_message.title', {periodo: period.name})}],
                actionPrimary: {                  
                  text: this.$t('actions.accept'), callback () {
                }
                },
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary',
              })
            }
          }
        },
        actionSecondary: {
          text: this.$t('actions.cancel'), callback () {
          }
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      })
    },
    async closePracticalSubject (id) { //id param
      const period = this.periods.find(period => period && period.id === id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('alert'),
        content: [{ value: this.$t('modules.academic.periods.closePracticalSubject.title', {periodo: period.name})}],
        actionPrimary: {    
          text: this.$t('actions.accept'), callback: async () => {
            try {
              await $tools.find(`finishPracticalSubject?term=${id}`)

            } catch {
              this.$store.dispatch('commons/openPopUp', {
                title: this.$t('alert'),
                content:[{ value: this.$t('modules.academic.periods.closePracticalSubject.error_message.title', {periodo: period.name})}],
                actionPrimary: {                  
                  text: this.$t('actions.accept'), callback () {
                }
                },
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary',
              })
            }
          }
        },
        actionSecondary: {
          text: this.$t('actions.cancel'), callback () {
          }
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      })
    },
    async deletePeriodPopUp (id) { //id param
      const period = this.periods.find(period => period && period.id === id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{
          value: period.name
        }],
        actionPrimary: {          text: this.$t('actions.accept'), callback: async () => {
            try {
              await this.deletePeriod(period.id)
            } catch {
              this.$store.dispatch('commons/openPopUp', {
                title: this.$t('modules.academic.periods.list.error_message.delete'),
                content: [{
                  value: period.name
                }],
                actionPrimary: {                  text: this.$t('actions.accept'), callback () {
                  }
                },
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary',
              })
            }
          }
        },
        actionSecondary: {
          text: this.$t('actions.cancel'), callback () {

          }
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      })
    },
    async fetchData () {
      const params = { 
        page: this.asidebar.pagination.page - 1, 
        length: this.asidebar.pagination.limit, 
        orderBy: 'classStartDate',
        orientation: 'desc'
      }
      this.clearSelected = new Date().getMilliseconds().toString();
      let periods;
      try {
        const filterParams = {
          classStartDate: new Date(),
          saleDateFrom: new Date(),
          termTypeValues: [],
        }            
        filterParams.classStartDate = this.asidebar.items[0].filters[0].value ? new Date(this.asidebar.items[0].filters[0].value) : null;
        filterParams.saleDateFrom = this.asidebar.items[1].filters[0].value ? new Date(this.asidebar.items[1].filters[0].value) : null;
        
        this.asidebar.items[2].filters.forEach( termTypeValues => {
          if (termTypeValues.value) filterParams.termTypeValues.push(termTypeValues.id);
        })
        periods = await $terms.filter(filterParams, { params });
        this.totalItems = periods.data.totalElements;
        this.periods = this.formatPeriods(periods.data.content);
        this.rawPeriods = Array.from(this.periods);
        this.asidebar.pagination.pagesAmount = periods.data.totalPages
      } catch (error) {
        this.totalItems = 0
        this.periods = []
        this.asidebar.pagination.pagesAmount = 0
        throw error
      }
    },
    formatPeriods (periods) {
      if (periods.length) {
        return periods.map(period => {
          return {
            id: period.id,
            code: period.code,
            name: period.description,
            status: period.status,
            classStartDate: this.configurationValues.dateFormat ? moment(period.classStartDate).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) : moment(period.classStartDate).format('YYYY-MM-DD'),
            classEndDate: this.configurationValues.dateFormat ? moment(period.classEndDate).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) : moment(period.classEndDate).format('YYYY-MM-DD'),
            termType: period.termType.meaning,
            studyType: period.studyType ? period.studyType.meaning : '',
            canDelete: period.canDelete,
            saleDateFrom: this.configurationValues.dateFormat ? moment(period.saleDateFrom).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) : moment(period.saleDateFrom).format('YYYY-MM-DD'),
          }
        });
      } else {
        return {
          id: periods.id,
          code: periods.code,
          name: periods.description,
          status: periods.status,
          classStartDate: this.configurationValues.dateFormat ? moment(periods.classStartDate).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) : moment(periods.classStartDate).format('YYYY/MM/DD'),
          classEndDate: this.configurationValues.dateFormat ? moment(periods.classEndDate).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) : moment(periods.classEndDate).format('YYYY/MM/DD'),
          modalityType: periods.modalityType.meaning,
          studyType: periods.studyType ? periods.studyType.meaning : '',
          canDelete: periods.canDelete,
          saleDateFrom: this.configurationValues.dateFormat ? moment(periods.saleDateFrom).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) : moment(periods.saleDateFrom).format('YYYY-MM-DD'),
        }
      }
    },
    generateModalityFilter (modalities) {
      const formattedModalityFilter = { name: 'Modalidades' };
      formattedModalityFilter.filters = modalities.map(modality => (
        {
          type: 'check',
          field: 'modalityType',
          name: modality.meaning,
          filterValue: modality.value,
          value: true,
          dataFiltered: ''
        }
      ));
      return formattedModalityFilter
    },
    bulkDeletePopUp (periods) { //periods param
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: periods.map(user => ({
          value: user.name
        })),
        actionPrimary: {          text: this.$t('actions.accept'), callback: async () => {
            try {
              await this.bulkDelete(periods)
            } catch {
              this.$store.dispatch('commons/openPopUp', {
                title: this.$t('modules.academic.periods.list.error_message.bulk_delete'),
                content: periods.map(rol => ({
                  value: rol.name
                })),
                actionPrimary: { text: this.$t('actions.accept'), callback () { } },
                actionSecondary: null,
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary'
              })
            }
          }
        },
        actionSecondary: {
          text: this.$t('actions.cancel'), callback () {
          }
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      })
    },
    async bulkDelete (periods) { //periods param
      const itemsForDelete = periods.map((period) => ({ id: period.id }));
      await $terms.bulkDelete(itemsForDelete);
      await this.fetchData()
    },
    newPeriod () {
      if (this.canCreate) this.$router.push('/academic/periods/create')
    },
    updatePeriod (id) {
      if (this.canUpdate) this.$router.push(`/academic/periods/update/${id}`)
    },
    async deletePeriod (id) { //id param
      await $terms.delete(`${id}?userId=${this.userId}`);
      this.periods = this.periods.filter(period => period.id !== id);
    }
  }
}
</script>
