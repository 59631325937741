<template>
  <v-container fluid>
    <v-row v-if="!syncNotesStatus">
      <v-col cols="12">
        <v-card outlined class="py-8 px-10">
          <h3>{{ $t('modules.canvas.syncStudent.drawer.table.title') }}</h3><br>
          <p>{{ $t('modules.canvas.syncStudent.drawer.table.description') }}</p>
          <SuperTable
            :headers="headers"
            :data="notas"
            :isPagination="false"
            :isSearching="false"
          ></SuperTable>
        </v-card>
      </v-col>
      <v-col class="pb-8 d-flex justify-contentn-end justify-end">
        <Button
          :disabled="false"
          :text="$t('modules.canvas.syncStudent.actions.update')"
          @clicked="syncUpNotestPopUp"
          class="mr-4"
        ></Button>
      </v-col>
    </v-row>
    <SuccessMessage
        v-else
          :type="successMessage.type"
          :title="successMessage.title"
          :actionPrimary="successMessage.actionPrimary"
          :actionSecondary="successMessage.actionSecondary"
        />
  </v-container>
</template>

<script>
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import SuperTable from '../Components/SuperTable';
import { $grades } from '../Services';

export default {
  name: 'NotesView',
  components: {
    SuperTable,
    Button,
    SuccessMessage
  },
  props: {
    notas: Array,
    studentSubjectId: Number,
    lmsUserId: Number,
    fetchNotes: Function,
  },
  data() {
    return {
      syncNotesStatus:false,
      headers: [
        {
          text: this.$t('modules.canvas.syncStudent.drawer.table.headers.modules'),
          sortable: false,
          value: 'examTypeMeaning'
        },
        {
          text: this.$t('modules.canvas.syncStudent.drawer.table.headers.notas'),
          sortable: false,
          value: 'grade',
          align: 'center',
        },
        {
          text: this.$t('modules.canvas.syncStudent.drawer.table.headers.practicalDate'),
          sortable: false,
          value: 'practicalDate',
          align: 'center',
        }
      ],
      successMessage:{}
    }
  },
  methods: {
    syncUpNotestPopUp(){
          this.$store.dispatch('commons/openPopUp', {
              title: this.$t('modules.canvas.syncStudent.message.syncUpNotes'),
              actionPrimary: {
                  text: this.$t('modules.canvas.syncStudent.message.syncAccept'),
                  callback: () => this.syncUpNotes()
              },
              actionSecondary: {
                  text: this.$t('modules.canvas.syncStudent.message.syncCancel'),
                  callback() {}
              },
              icon: { color: 'warning', name: 'mdi-sync' },
              color: 'primary'
          })
        },
    async syncUpNotes(){
        try {
            const body = {
              studentSubjectId: this.studentSubjectId,
              lmsUserId: this.$route.query.lmsUserId
            }
            await $grades.create(body)
            await this.fetchNotes(this.studentSubjectId)
            this.successMessage.type = 'success';
            this.successMessage.title = this.$t('modules.canvas.syncStudent.message.syncUpNotesSuccess');
            this.successMessage.actionPrimary = { text: this.$t('modules.canvas.syncStudent.actions.close'), callback: () => { this.$emit('newUpdate') 
            this.syncNotesStatus = false}  }
            this.successMessage.actionSecondary = null;
        } catch (error) {
          this.successMessage.type = 'error';
          this.successMessage.title = this.$t('modules.canvas.syncStudent.message.syncUpNoteError');
          this.successMessage.actionSecondary = null;
          this.successMessage.actionPrimary = { text: this.$t('modules.canvas.syncStudent.actions.close'), 
          callback: () => { 
            this.$emit('newUpdate') 
            this.syncNotesStatus = false } 
          }
        } finally {
          this.syncNotesStatus = true;
        }
    },
    newUpdate() {
      this.$emit('newUpdate' )
      setTimeout(() => {
        this.file = null
        this.dropFilesKey++
        this.syncNotesStatus = false
        this.resetButtonValues()
      }, 625)
    },
  },
};
</script>
