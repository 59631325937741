<template>
    <v-dialog :value="open" @input="closeDialog" max-width="660px">
        <v-card v-if="!response" style="padding:40px;">
        <div class="alertPopup mx-auto mb-5">
            <div class="circle text-center mx-auto">
                <v-icon color="white" size="60" class="icon">mdi-eye-off-outline</v-icon>
            </div>
        </div>
            <h1 class="primary--text headline text-center">¿Desea descargar el libro "{{book ? book : "?"}}" {{resolucion ? `(resolucion  ${resolucion})?`  : ""}}</h1>
            <v-row class="justify-center pt-8">
                <Button
                    class="mx-8"
                    :text="$t('actions.close')"
                    white
                    @clicked="closeDialog"
                ></Button>
                <Button
                    class="mx-8"
                    :text="$t('actions.confirm')"
                    :loading="createButton.loading"
                    :success="createButton.success"
                    :error="createButton.error"
                    :successText="$t('actions.created')"
                    :errorText="'Error'"
                    @end="resetButtonValues"
                    @clicked="saveConfirm"
                ></Button>
            </v-row>
        </v-card>
        <SuccessMessage 
            v-else
            class="white px-10"
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
            :fullWidth="true"
            >
            <h2 class="text-center font-weight-regular">{{successMessage.text}}</h2>
        </SuccessMessage>
    </v-dialog>
</template>

    <script>
    import Button from '@/components/Button/Button';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    export default {
    name: 'ConfirmDownload',
    props: {
        open: { type: Boolean, default: false },
        successMessage: {type: Object},
        response: {type: Boolean, default: false},
        createButton: {type:Object},
        book: {type:Number},
        resolucion: {type:String},
    },
    components: {
        Button,
        SuccessMessage
    },
    data(){
        return {
        }
    },
    
    methods: {
        closeDialog () {
            if (this.open) {
                    this.$emit('close');
                }
        },
        resetButtonValues () {
            this.createButton.success = false;
            this.createButton.error = false;
            
        },
        saveConfirm(){
            this.$emit('requestBook', 'test')
            this.resetButtonValues()
        },
    },
    }
    </script>
    
    <style lang="sass" scoped>
    .dialog
        width: 400px
        height: 300px
    
    .headline
        font-size: 1.5rem !important
        font-weight: 400
        line-height: 2rem
        letter-spacing: normal !important
        font-family: "Roboto", sans-serif !important
    
    .alertPopup
        width: 160px
    
    .circle
        border-radius: 50% !important
        background-color: #ffff9e
    
    .icon
        background-color: #fb8c00
        margin: 35px
        padding: 15px
        position: relative
        border-radius: 50%
    </style>