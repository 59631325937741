
const sectionsTranslations = {
    es: {
        validation_errors: {
            name: 'Ingresa el nombre',
            modality: 'Selecciona la modalidad',
            period: 'Selecciona el período',
            lms: 'Selecciona el LMS',
            subject: 'Selecciona un departamento para elegir la asignatura',
            size: 'Define un cupo',
            limitSize: 'Limite de cupos 32767',
            teacher: 'Selecciona un docente',
        },
        table: {
            breadcrumbs: {
                title: 'Secciones',
                description: 'Administración de secciones'
            },
            periods: 'Periodos',
            new_section: 'Nueva',
            bulk_delete: 'Eliminar secciones',
            delete_error: 'No se pudo eliminar la sección',
            bulk_delete_error: 'No se pudieron eliminar las secciones',
            headers: {
                name: 'Nombre',
                subjects: 'Asignaturas',
                teacher: 'Docente',
                term: 'Período',
                lms_type: 'Tipo de LMS',
                size: 'Cupo',
                registered: 'Inscritos',
                status: 'Estado',
                reglamento: 'R19',
            }
        },
        create: {
            title: 'Datos principales',
            subtitle: 'Define los datos básicos necesarios para la creación de una nueva sección. Podrás asignarle el tipo de LMS que utilizará, como así también el cupo de estudiantes y la asignatura que incluirá.',
            labels: {
                name: 'Nombre',
                modality: 'Modalidad',
                term: 'Período',
                lms_type: 'Tipo LMS',
                department: 'Departamento',
                subject: 'Asignatura',
                sections: 'Secciones',
                size: 'Cupo',
                teacher: 'Docente',
                enabled: 'Habilitado',
                disabled: 'Deshabilitado',
            },
            breadcrumbs: {
                title: 'Nueva sección',
                description: 'Crea una nueva sección'
            },
            warning_popup: {
                title: 'Se está creando la sección deshabilitada',
                content: '',
                create: 'Crear',
                cancel: 'Cancelar'
            },
            success_message: {
                title: 'Sección creada correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otro',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'Se produjo un error al crear la sección',
                duplicated:'El registro ya existe en el sistema.',
            },
            headers: {
                header1: 'Cursando',
                header2: 'On Boarding',
                header3: 'Nivelación'
            },
            tutors: {
                title: 'Tutores',
                subtitle: 'Seleccione los usuarios asignados como tutores de la Seccion',
                transferList: {
                    firstTitle: 'Disponibles',
                    secondTitle: 'Seleccionados',
                }
            },
            review: {
                title: 'Correctores',
                subtitle: 'Seleccione los usuarios asignados como correctores de la Seccion',
                transferList: {
                    firstTitle: 'Disponibles',
                    secondTitle: 'Seleccionados',
                }
            }
        },
        update: {
            title: 'Datos principales',
            subtitle: '',
            studenttitle: 'Listados de estudiantes',
            studentsubtitle: '',
            studentrut: 'DNI',
            career: 'Carrera',
            name: 'Alumno',
            labels: {
                name: 'Nombre',
                modality: 'Modalidad',
                term: 'Período',
                lms_type: 'Tipo LMS',
                department: 'Departamento',
                subject: 'Asignatura',
                sections: 'Secciones',
                size: 'Cupo',
                teacher: 'Docente',
                enabled: 'Habilitado',
                disabled: 'Deshabilitado',
            },
            breadcrumbs: {
                title: 'Editar sección',
                description: 'Modifica la sección'
            },
            warning_popup: {
                title: 'Se está guardando la sección deshabilitada',
                content: '',
                update: 'Guardar',
                cancel: 'Cancelar'
            },
            success_message: {
                title: 'Sección modificada correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'Se produjo un error al modificar la sección',
            },
            tutors: {
                title: 'Tutores',
                subtitle: 'Seleccione los usuarios asignados como tutores de la Seccion',
                transferList: {
                    firstTitle: 'Disponibles',
                    secondTitle: 'Seleccionados',
                }
            },
            review: {
                title: 'Correctores',
                subtitle: 'Seleccione los usuarios asignados como correctores de la Seccion',
                transferList: {
                    firstTitle: 'Disponibles',
                    secondTitle: 'Seleccionados',
                }
            }
        },
    },
    es_Cl: {
        update:{
            studentrut: 'RUT',
        }
    },
    es_PE: {
        validation_errors: {
            subject: 'Selecciona un departamento para elegir el curso',
            teacher: 'Selecciona un profesor',
        },
        table: {
            headers: {
                subjects: 'Cursos',
                teacher: 'Profesor',
            }
        },
        create: {
            labels: {
                subject: 'Curso',
                teacher: 'Profesor'
            }
        },
        update: {
            name: 'Estudiante',
            labels: {
                subject: 'Curso',
                teacher: 'Profesor'
            },
        },
    },
    es_MX: {
        validation_errors: {
            subject: 'Selecciona un departamento para elegir la materia',
            teacher: 'Selecciona un profesor',
        },
        table: {
            headers: {
                subjects: 'Materias',
                teacher: 'Profesor',
            }
        },
        create: {
            labels: {
                subject: 'Materia',
                teacher: 'Profesor'
            }
        },
        update: {
            name: 'Estudiante',
            labels: {
                subject: 'Materia',
                teacher: 'Profesor'
            },
        },
    }
};

export default sectionsTranslations
