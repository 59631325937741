
const cartera = {
    title: 'Cartera',
    description: 'Este reporte muestra los pagos faltantes para determinado período sin contar las anulaciones.',
    detail: [
        { label: 'Archivo', value: 'Reporte cartera.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El pago no debe estar ni cancelado ni anulado. El pago debe corresponder a un arancel, una repactación de deuda o un cobro administrativo por retiro.' },
        { label: 'Criterios', value: 'Muestra el porcentaje de pronto pago en el caso de que corresponda.' },
    ],
    example: {
        header: [
            'Año Deuda',
            'Año Deuda Descripción',
            'RUT',
            'Dig',
            'Apellido',
            'Nombre',
            'Per. Ingreso',
            'Per. Ingreso Descripción',
            'Fecha Ultimo Contrato (ex FEC_MAT)',
            'SCE',
            'SCE Descripción',
            'Código Carrera',
            'Nombre Carrera',
            'Estado Académico',
            'Estado Académico Descripción',
            'Estado Carrera',
            'Estado Carrera Descripción',
            'N Documento',
            'N Cuota',
            'Monto',
            'Estado Cuota',
            'Estado Cuota Descripción',
            'Fecha vencimiento',
            'diasvencido',
            'Porcentaje Beca',
            'Numero de Becas',
            'TERM_ID',
            'TERM_VALID_FROM',
            'Uso de ProntoPago',
            'Porcentaje de ProntoPago',
            'Legajo',
            'CELULAR',
            'TELEFONO',
            'EMAIL',
            'Dirección',
            'Ciudad',
            'Region',
        ],
        data: [
            [
                '308',
                'PRIMER BIMESTRE ACADÉMICO 2022',
                '13643429',
                '2',
                'AGUERO HERNANDEZ',
                'RODRIGO ANDRÉS',
                '210',
                'TERCER BIMESTRE ACADÉMICO 2017',
                '44651',
                '542',
                'CENTRO VALDIVIA',
                '686',
                'INGENIERÍA EN ADMINISTRACIÓN DE EMPRESAS',
                '99',
                'Congelado',
                '57',
                'Anulado',
                '1518347',
                '2 de 5',
                '153201',
                '117',
                'Vencido',
                '44681',
                '180.0',
                '20',
                '1',
                '210',
                '308',
                'Vencido',
                '0',
                '20171B0310P003',
                '994260255',
                '994260255',
                'raguero.inf@gmail.com',
                'FELIX GARCIA  938',
                'Santiago',
                'Santiago',   
            ],
            [
                '270',
                'TERCER BIMESTRE ACADÉMICO 2021',
                '13937174',
                '7',
                'CABRERA HEWITT',
                'ROBERTO ENRIQUE',
                '209',
                'SEGUNDO BIMESTRE ACADÉMICO 2017',
                '44411',
                '525',
                'CENTRO SANTIAGO SANTA ISABEL',
                '695',
                'TÉCNICO EN ADMINISTRACIÓN PÚBLICA',
                '21',
                'Vigente',
                '182',
                'Eliminado por no matricula',
                '1389874',
                '4 de 5',
                '132008',
                '117',
                'Vencido',
                '44560',
                '301.0',
                '',
                '1',
                '209',
                '270',
                'Vencido',
                '0',
                '20172A0371P008',
                '+56 9 3758 5705',
                '',
                'rech.jakaz@gmail.com',
                'La Grossal  2352',
                '13401 - SAN BERNARDO',
                '13053 - LO HERRERA',
            ],
        ],
    },
}

const historialSubscripcionesCuotasEstados = {
    title: 'Detalle Pagos Suscriptores',
    description: 'Este reporte muestra los pagos realizados en cuotas.',
    detail: [
        { label: 'Archivo', value: 'Reporte Suscripciones.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Pagos registrados como suscripción.' },
        { label: 'Criterios', value: 'Muestra gastos administrativos y pagos desdoblados.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Telefono',
            'Email',
            'Legajo',
            'Id Suscripcion',
            'Vencimiento',
            'Tipo cuota',
            'Estado cuota',
            'Monto cuota',
            'Cant cuotas',
            'Numero factura',
            'Forma de pago',
            'Tipo',
            'Cant Tickets',
            'Periodo',
            'Zona comercial',
            'Estado alumno',
            'Estado Suscripcion',
        ],
        data: [
            [
                'Luis',
                'Medina',
                '31376070',
                '+54 11 5466-0156',
                'toluispo@hotmail.com',
                '20212ATSSI0041',
                '13926',
                '2022-09-08',
                'cuota 1',
                'Cancelado',
                '9256.26953125',
                '6',
                '64748',
                'pagofacil',
                'Reingresante',
                '2',
                '2A 2022',
                'Zona A',
                'Cursando',
                'Activo',
            ],
            [
                'Jeremias Lautaro',
                'Royon Debrito',
                '43412918',
                '+54 297 426-9715',
                'lautarodebrito@outlook.com.ar',
                '20212APNET0070',
                '14084',
                '44812',
                'cuota 1',
                'Cancelado',
                '12474330078125',
                '6',
                '65169',
                'master',
                'Reingresante',
                '2',
                '2A 2022',
                'Zona B',
                'Cursando',
                'Activo',   
            ],
        ],
    },
}

const pagosSinSubscripcion = {
    title: 'Detalle Pagos Sin Suscripción',
    description: 'Este reporte muestra los pagos realizados sin cuotas.',
    detail: [
        { label: 'Archivo', value: 'Reporte pagossinSubscripciones.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Pagos registrados sin cuotas.' },
        { label: 'Criterios', value: 'Muestra medios de pago y fecha de aprobación.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Teléfono',
            'Email',
            'Legajo',
            'Monto',
            'Descuento',
            'Periodo',
            'Tipo',
            'Numero factura',
            'Forma de pago',
            'Articulo',
            'Cant tickets',
            'Tipo comprobante',
            'Zona comercial',
            'Fecha aprobacion',
        ],
        data: [
            [
                'AARON',
                'PRIETO',
                '43590127',
                '541168797904',
                'aaronprieto06@gmail.com',
                'GHOT00721',
                '35254.0',
                '3525.4',
                '2B 2022',
                'RI',
                '60197',
                'amex',
                'Arancel ',
                '1',
                'FB',
                'Zona C',
                '2022-07-22 14:32:26.0',  
            ],
            [
                'Abril',
                'Bivona',
                '44455813',
                '+54 11 5600-7374',
                'marcemar210170@gmail.com',
                '20222ATSMD0021',
                '35254.0',
                '7050.8',
                '2B 2022',
                'RI',
                '56651',
                'visa',
                'Arancel ',
                '1',
                'FB',
                'Zona C',
                '2022-06-23 18:54:35.0',
            ],
        ],
    },
}

const alumnoHistorialPagoMP = {
    title: 'Historial pagos Mercado Pago',
    description: 'Este reporte muestra el estado de cada pago realizado vía Mercado Pago.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosHistorialPagoMP.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'La fecha de pago en Mercado Pago no debe tener más de 5 semanas de antiguedad.' },
        { label: 'Criterios', value: 'Muestra detalle de estado de pago, método de pago y monto.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Legajo',
            'Email',
            'Estado',
            'Estado detalle',
            'Fecha',
            'Método de pago',
            'Monto',
        ],
        data: [
            [
                'Iván',
                'valencia',
                '36566656',
                '20221APNET2902',
                'Ivan06valencia@gmail.com',
                'pending',
                'pending_waiting_payment',
                '2022-11-10 00:52:53.881',
                'pagofacil',
                '105623.2', 
            ],
            [
                'LAURA',
                'maldonado',
                '27551362',
                '20222BTSEG0033',
                'lauamici@gmail.com',
                'approved',
                'accredited',
                '2022-11-09 20:45:16.371',
                'master',
                '12506.78',
            ],
        ],
    },
}

const dueAccounts = {
    title: 'Oficina Cobranzas',
    description: 'Este reporte muestra un listado de estudiantes que tienen mora por algún concepto para la Oficina de Cobranzas.',
    detail: [
        { label: 'Archivo', value: 'Reporte ganereteDueAccountsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Este reporte detalla los datos de los estudiantes en mora para cada período seleccionado.' },
        { label: 'Criterios', value: 'Muestra detalle de estado de pago, método de pago y monto.' },
    ],
    example: {
        header: [
            'RemoteId',
            'NOMBRE',
            'NationalId',
            'Email',
            'Celular',
            'ID',
            'Descripcion',
            'Moneda',
            'Remote-Id',
            'Descripcion',
            'Monto Capital',
            'Interes mora',
            'Dias de mora',
            'Detalle mora',
            'Vencimiento',
        ],
        data: [
            [
                '16412133-K',
                'YORKA NATALIA CODELIA LARA',
                '16412133-K',
                'yonalara.c@gmail.com',
                '+56 9 3244 0746',
                '1616833',
                'Matricula ',
                'CLP',
                '16412133-K',
                'Cuota 1',
                '136000',
                '0',
                '46',
                '',
                '2022-09-30',
            ],
            [
                '18119721-8',
                'JORGE GUIDO URRA SÁEZ',
                '18119721-8',
                'eljorgee92@gmail.com',
                '+56 9 5090 5855',
                '1613171',
                'Examen de Título',
                'CLP',
                '18119721-8',
                'Cuota 1',
                '355000',
                '0',
                '65',
                '',
                '2022-09-11',
            ],
        ],
    },
}

const facturacion = {
    title: 'Facturación',
    description: 'Este reporte muestra todas las facturas realizadas en un semestre. Incluye matrículas, aranceles, exámenes, etc.',
    detail: [
        { label: 'Archivo', value: 'Reporte facturacion.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span><span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El período debe ser de cursada y el pago no debe estar anulado.' },
        { label: 'Criterios', value: 'Si bien este reporte solicita un período al ejecutarse, busca los dos períodos del correspondiente cuatrimestre. La zona de facturación es actual, puede no corresponder a la que tenía el estudiante a la hora de matricularse.' },
    ],
    example: {
        header: [
            'DNI',
            'Tipo de alumno',
            'legajo',
            'Periodo Ingreso',
            'Zona Fact.',
            'carrera',
            'R19',
            'Facha de Pago',
            'Estado de Pago',
            'Fecha Venc Pago',
            'Cant cuotas',
            'N Cuota',
            'Periodo de Pago',
            'Articulo',
            'Monto Bruto',
            'Monto_Beca',
            'Monto Beneficio',
            'Factura',
            'Tipo factura',
            'Metodo de Pago',
        ],
        data: [
            [
                '44378390',
                'RI',
                '20212BRLAB0084',
                '1A 2022',
                'Zona D',
                'Relaciones Laborales',
                '',
                '2022-11-14 18:12:06.583',
                'Cancelado',
                '44873',
                '6',
                '4',
                '2A 2022',
                'Cuota Suscripción',
                '12300.75',
                '0.0',
                '0.0',
                '68500',
                'FB',
                'pagofacil',
            ],
            [
                '37376813',
                'NI',
                '20222BPNET0648',
                '2B 2022',
                'Zona D',
                'Programación',
                '',
                '2022-11-15 17:41:48.673',
                'Cancelado',
                '44903',
                '3',
                '2',
                '2B 2022',
                'Cuota Suscripción',
                '11959.5',
                '0.0',
                '0.0',
                '68547',
                'FB',
                'visa',
            ],
        ],
    },
}

const discounts = {
    title: 'Descuentos',
    description: 'Este reporte muestra todas las becas y los beneficios aplicados en este período',
    detail: [
        { label: 'Archivo', value: 'Reporte generateDiscountsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'El estado del pago debe ser "cancelado".' },
        { label: 'Criterios', value: 'No se muestra el tipo de beca o beneficio aplicado.' },
    ],
    example: {
        header: [
            'Legajo',
            'Nombre',
            'Apellido',
            'DNI',
            'email',
            'Producto',
            'Periodo de pago',
            'Estado de pago',
            'Fecha de pago',
            'Monto a pagar',
            'Monto descuentos',
            'Monto Beca',
            'Monto Beneficio',
            'N de doc de pago',  
        ],
        data: [
            [
                '20231APNET0572',
                'Adhemar Alfredo',
                'Borges Nogueira',
                '10710427',
                'aahborgesnogueira@gmail.com',
                'Arancel ',
                '1A 2023',
                'Cancelado',
                '30/11/2022',
                '43185.0',
                '6477.75',
                '0.0',
                '4318.5',
                '617556',
            ],
            [
                '20231ARTEL0043',
                'Jose Antonio',
                'Lopez Castaño',
                '17693325',
                'jamajit@hotmail.com',
                'Arancel ',
                '1A 2023',
                'Cancelado',
                '28/11/2022',
                '47838.0',
                '14351.4',
                '4783.8',
                '4783.8',
                '617556',
            ],
        ],
    },
}

const cancellations = {
    title: 'Anulaciones',
    description: 'Este reporte muestra los pagos faltantes anulados.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateCancellationsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estado del pago debe ser "anulado".' },
        { label: 'Criterios', value: 'Muestra el porcentaje de pronto pago en el caso de que corresponda.' },
    ],
    example: {
        header: [
            'Año Deuda',
            'Año Deuda Descripción',
            'RUT',
            'Dig',
            'Apellido',
            'Nombre',
            'Per. Ingreso',
            'Per. Ingreso Descripción',
            'Fecha Ultimo Contrato (ex FEC_MAT)',
            'SCE',
            'SCE Descripción',
            'Código Carrera',
            'Nombre Carrera',
            'Estado Académico',
            'Estado Académico Descripción',
            'Estado Carrera',
            'Estado Carrera Descripción',
            'N Documento de pago',
            'producto',
            'N Cuota',
            'Monto',
            'Estado Cuota',
            'Estado Cuota Descripción',
            'Fecha vencimiento',
            'diasvencido',
            'Porcentaje Beca',
            'Numero de Becas',
            'Uso de ProntoPago',
            'Porcentaje de ProntoPago',
            'Legajo',
            'CELULAR',
            'TELEFONO',
            'EMAIL',
            'Dirección',
            'Ciudad',
            'Region',
        ],
        data: [
            [
                '195',
                'PRIMER BIMESTRE ACADÉMICO 2020',
                '10592159',
                '4',
                'GUERRERO  GUERRERO ',
                'SANDRA SOLEDAD',
                '201',
                'TERCER BIMESTRE ACADÉMICO 2019',
                '23/1/2020',
                '527',
                'CENTRO ANTOFAGASTA',
                '688',
                'CONTADOR GENERAL',
                '102',
                'Anulado',
                '57',
                'Anulado',
                '1013511',
                'Arancel ',
                '1 de 1',
                '541538',
                '116',
                'Anulado',
                '23/1/2020',
                '1135',
                '25',
                '1',
                '',
                '0',
                '20192A0330P021',
                '76989767',
                '',
                'sandra5_hermanitas@hotmail.com',
                '',
                '',
                '                ',
            ],
            [
                '195',
                'PRIMER BIMESTRE ACADÉMICO 2020',
                '10773392',
                '2',
                'DÍAZ MORENO',
                'BRAULIO ARMANDO',
                '195',
                'PRIMER BIMESTRE ACADÉMICO 2020',
                '1/12/2022',
                '525',
                'CENTRO SANTIAGO SANTA ISABEL',
                '696',
                'INGENIERÍA EN INFORMÁTICA',
                '21',
                'Vigente',
                '54',
                'Cursando',
                '1223255',
                '',
                '1 de 1',
                '389665',
                '116',
                'Anulado',
                '20/3/2020',
                '1078',
                '',
                '1',
                'No puede utilizarlo',
                '0',
                '20201A0410P507',
                '99164956',
                '',
                'BADIAZM@GMAIL.COM',
                '',
                '',
                '                ',
            ],
        ],
    },
}

const payments = {
    title: 'Pagos completo',
    description: 'Este reporte muestra todos los pagos aprobados.',
    detail: [
        { label: 'Archivo', value: 'Reporte generatePaymentsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'El estado del pago debe ser "cancelado" y el estado de Mercado Pago debe ser "Aprobado".' },
        { label: 'Criterios', value: 'El tipo de alumno (RI/NI) depende si es el primer pago realizado o no.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'DNI',
            'Legajo',
            'Tipo',
            'Intento de pago',
            'Periodo de pago',
            'Método de pago',
            'Tipo de Pago',
            'Mercado Pago ID',
            'Fecha de aprobación',
            'Mercado Pago Monto',
            'Monto de Cuenta',
            'Monto de Beca',
            'Monto de Beneficio',
            'Producto',
            'Monto de pago',
            'Fecha de pago',
            'Número de cuota',
            'Cantidad de cuotas',
            'N de doc de pago',
        ],
        data: [
            [
                'Liliana ',
                'Di Lanzo',
                '33993926',
                '20212BTSCP0002',
                'RI',
                '325094',
                '1B 2022',
                'amex',
                'credit_card',
                '18501984187',
                '30/11/2021 12:54',
                '56962',
                '5933,6',
                '0',
                '0',
                'Arancel ',
                '29668',
                '30/11/2021 12:53',
                '1',
                '1',
                '307559',
            ],
            [
                'JORGE LUIS',
                'DANNI',
                '32346534',
                'TGCO02835',
                'RI',
                '210',
                '1A-2021',
                'visa',
                'credit_card',
                '3299378267',
                '29/12/2017 18:55',
                '8,25',
                '0',
                '',
                '',
                'Cuota',
                '5290,98',
                '5/8/2021 15:24',
                '2',
                '6',
                '392207',
            ],
        ],
    },
}

const tne = {
    title: 'TNE',
    description: 'Este reporte retorna un listado de alumnos que pagaron la Tarjeta Nacional de Estudiante (TNE) para presentar en la Junta Nacional de Auxilio Escolar y Becas JUNAEB.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateTneReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Todos los estudiantes que hayan pagado la TNE.' },
        { label: 'Criterios', value: 'Las primeras columnas de este reporte coinciden con el formato requerido por la JUNAEB en Chile para el envío de las TNE.' },
    ],
    example: {
        header: [
            'REGION',
            'SEDE',
            'CARRERA',
            'JORNADA',
            'RBD',
            'RUN',
            'DGV_RUN',
            'AP_PATERNO',
            'AP_MATERNO',
            'PRIMER NOMBRE',
            'SEGUNDO NOMBRE',
            'DIA_NACIMIENTO',
            'MES_NACIMIENTO',
            'ANO_NACIMIENTO',
            'EMAIL',
            'COD_AREA',
            'TELEFONO',
            'LEGAJO',
            'ESTADO_CARRERA',
            'MONTO',
            'FECHA_PAGO',
        ],
        data: [
            [
                '13',
                'CASA CENTRAL',
                'TÉCNICO JURÍDICO EN GESTIÓN JUDICIAL ',
                'VESPERTINA',
                '106',
                '10041531',
                '3',
                'CERDA',
                'FIGUEROA',
                'ALEJANDRO',
                'ALFREDO',
                '5',
                '11',
                '1966',
                'alecerdaf@hotmail.com',
                '',
                '+56965777016',
                '20202A0630P0038',
                'Cursando',
                '1100',
                '09/02/2021',
            ],
            [
                '13',
                'CASA CENTRAL',
                'TÉCNICO EN LOGÍSTICA',
                'VESPERTINA',
                '106',
                '10057780',
                '1',
                'MALDONADO',
                'ESPINOZA',
                'MAURICIO',
                'ESTEBAN',
                '22',
                '3',
                '1968',
                'mauricio.m4ldo@gmail.com',
                '',
                '+56 9 7516 2551',
                '20222A0346P0074',
                'Cursando',
                '2700',
                '09/12/2018',
            ],
        ],
    },
}

const studentAccount = {
    title: 'Pagos',
    description: 'Este reporte muestra los pagos vigentes de aranceles y exámenes de título de cada estudiante.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateStudentAccountReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El pago no tiene que estar ni "cancelado" ni "anulado" y el producto no debe ser ni Matricula ni TNE.' },
        { label: 'Criterios', value: 'El período es el que figura en el pago.' },
    ],
    example: {
        header: [
            'PERIODO DEUDA',
            'PERIODO COD DEUDA',
            'RUT',
            'DIG',
            'APELLIDOS',
            'NOMBRES',
            'PERIODO INGRESO',
            'PERIODO COD INGRESO',
            'FECHA MAT',
            'SCE',
            'SCE NOMBRE',
            'COD CARR',
            'NOMBRE CARRERA',
            'ESTADO ACADEMICO',
            'EST ACADEMICO',
            'ESTADO CARRERA',
            'EST CARRERA',
            'NUM_DOCUM',
            'NUM_CUOTA',
            'MONTO',
            'ESTADO CUOTA',
            'MEANING CUOTA',
            'FEC VENCIMIENTO',
            'DIAS VENCIDO',
            'PORCENTAJE BECA',
            'NUMERO BECAS',
            'TERM_ID',
            'TERM_VALID_FROM',
            'PRONTO_PAGO',
            'ID',
            'USER_DATA_CELLPHONE',
            'USER_DATA_PHONE',
            'USER_DATA_EMAIL',
            'ADDRESS',
            'CITY',
            'REGION',
        ],
        data: [
            [
                '433',
                'PRIMER BIMESTRE ACADÉMICO 2023',
                '17902597',
                '3',
                'CID YAÑEZ',
                'ANTIZA MACARENA ',
                '433',
                'PRIMER BIMESTRE ACADÉMICO 2023',
                '2022-12-26',
                '546',
                'CENTRO VIRTUAL',
                '688',
                'CONTADOR GENERAL',
                '21',
                'Vigente',
                '54',
                'Cursando',
                '1659816',
                '6',
                '132651.5',
                '115',
                'Vigente',
                '2023-08-30',
                '-193',
                'null',
                'null',
                '433',
                '433',
                'NO',
                '20231A0330P0095',
                '+56 9 5330 0661',
                '+56 9 5330 0661',
                'codemarcaldera1@gmail.com',
                'Los delfines  510',
                'null',
                '3003 - CALDERA',
            ],
            [
                '433',
                'PRIMER BIMESTRE ACADÉMICO 2023',
                '15087751',
                '2',
                'ACOSTA GONZÁLEZ',
                'LUIS GUILLERMO',
                '433',
                'PRIMER BIMESTRE ACADÉMICO 2023',
                '2023-01-09',
                '546',
                'CENTRO VIRTUAL',
                '779',
                'TÉCNICO EN CIBERSEGURIDAD',
                '21',
                'Vigente',
                '54',
                'Cursando',
                '1668886',
                '6',
                '134604.828125',
                '115',
                'Vigente',
                '2023-08-30',
                '-193',
                'null',
                'null',
                '433',
                '433',
                'NO',
                '20231A0441P0169',
                '+56964353470',
                'null',
                'luisguillermoacostagonzalez53@gmail.com',
                'Cantera de San Antonio  35',
                'null',
                '5046 - SAN ANTONIO',
            ],
        ],
    },
}

const scholarshipsAndBenefits = {
    title: 'Becas y Beneficios',
    description: 'Este reporte muestra las becas y los beneficios asignados a cada estudiante.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateScholarshipsAndBenefitsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Se muestran todas las becas y los beneficios asociados a cada alumno.' },
        { label: 'Criterios', value: 'Según si es beca o beneficio se muestran datos como fecha y período.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'DNI / RUT',
            'Descuento',
            'Descripción',
            'Tipo',
            'Código',
            'Fecha otorgada',
            'Válida hasta',
            'Período',
            'Estado',    
        ],
        data: [
            [
                'Aaron',
                'Aroni',
                '95694233',
                'beneficio',
                'Empresa Referida',
                'Empresa referida',
                'EMPREF',
                '29/10/2018',
                '',
                '',
                'Verdadero',         
            ],
            [
                'Abel Alfonso',
                'Mendez',
                '39899229',
                'beca',
                'One Shot 3',
                '',
                'ONESHOT1A20223',
                '04/01/2023',
                '21/05/2023',
                '1A 2023',
                'null',      
            ],
        ],
    },
}
// documentations[this.reports.service][this.reports.url]


export default { discounts, cartera, historialSubscripcionesCuotasEstados, pagosSinSubscripcion, alumnoHistorialPagoMP, dueAccounts, facturacion, cancellations, payments, tne, studentAccount, scholarshipsAndBenefits};
