<template>
  <v-container>
    <section class= "pa-3">
          <section style="width:80%; margin:0 auto">
              <v-row>
                <v-col sm="6" md="6" class= "py-0">
                    <v-select
                          v-model="form.selectedArea"
                          return-object
                          :items="areasArray"
                          item-text="name"
                          :label="`${$t('modules.messaging.whatsapp.cargarTemplate.area')}*`"
                          :error-messages="($v.form.selectedArea.$dirty && $v.form.selectedArea.$invalid) ? $t('modules.messaging.whatsapp.cargarTemplate.areaRequired') : ''"
                          @blur="$v.form.selectedArea.$touch()"
                          class="pt-2"
                          outlined
                      ></v-select>
                </v-col>
                <v-col sm="6" md="6" class= "py-0">
                      <v-text-field
                          v-model="form.templateName"
                          :label="`${$t('modules.messaging.whatsapp.cargarTemplate.nombre_del_template')}*`"
                          :error-messages="($v.form.templateName.$dirty && $v.form.templateName.$invalid) ? $t('modules.messaging.whatsapp.cargarTemplate.nameCampaignRequired') : ''"
                          @blur="$v.form.templateName.$touch()"
                          class="pt-2"
                          outlined
                      ></v-text-field>
                </v-col>
               <v-col sm="12" md="12" class="pt-4">
                    <v-textarea
                        v-model="form.templateText"
                        :label="`${$t('modules.messaging.whatsapp.cargarTemplate.detalle_del_template')}*`"
                        :error-messages="($v.form.templateText.$dirty && $v.form.templateText.$invalid) ? $t('modules.messaging.whatsapp.cargarTemplate.templateDetails') : ''"
                        @blur="$v.form.templateText.$touch()"
                        class="pt-2"
                        outlined
                    ></v-textarea>
                </v-col>
               </v-row>
              <div class="d-flex justify-end">
              <Button 
              white
              :text="$t('actions.cancel')" 
              @clicked="close"
              @end="resetButtonValues"
              class="mr-4"
              ></Button>
                <Button 
                    dark
                    :loading="saveButton.loading"
                    :success="saveButton.success"
                    :error="saveButton.error"
                    :text="$t('actions.save')"
                    :successText="$t('actions.saved')"
                    :errorText="$t('actions.error')"
                    @end="resetButtonValues"
                    @clicked="saveNewTemplate"
                    />
              </div>
          </section>
    </section>
  </v-container>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import {required} from 'vuelidate/lib/validators';
import {$templates} from '../Services';

export default {
  name: 'UploadTemplate',
  props: {
    title: {type: String},
    text: {type: String},
    showButton: {type: Boolean},
    areasArray: {type:Array},
  },
  components: {
    Button,
  },
  data() {
    return {
      loading: false,
      form: {
        selectedArea: null,
        templateName: null,
        templateText: null,
      },
      saveButton: {
        loading: false,
        success: false,
        error: false,
      },
    }
  },
  methods: {
    resetButtonValues() {
       this.saveButton.loading = false;
       this.saveButton.success = false;
       this.saveButton.error = false;
     },
    closeDialog () {
      if (this.open) {
        this.$emit('close');
        this.form.selectedArea=null
        this.$v.$reset()
      }
    },
    close() {
      this.form.selectedArea = null
      this.form.templateText = null
      this.form.templateName = null
      this.$v.$reset()
      this.$emit('closeRightDrawer')
    },
    async saveNewTemplate() {
      if(this.$v.form.$invalid) this.$v.form.$touch()
      else {
        try {
            this.saveButton.loading = true;
          const body = {
            areaId: this.form.selectedArea.id.toString(),
            template: this.form.templateText,
            elementName: this.form.templateName,
          }
          const res = await $templates.create(body)
            this.saveButton.success = true;
              this.close()
            this.$store.dispatch('commons/openPopUp', {
                  title: this.$t('modules.messaging.whatsapp.saveNewTemplate.createNewTemplate'),
                  actionPrimary: {
                  text: this.$t('actions.close'), callback() {},
                },
                  icon: {color: 'success', name: 'mdi-check'},
                  color: 'primary',
              });
            
            this.$emit('updateTemplate')
            return res
        } catch (error) {
          this.saveButton.error = true;
          throw error
        } finally {
          this.saveButton.loading = false;
        }
      }
    }
  },
 
  computed: {
    area() {
      return {...this.form.selectedArea}
   }
  },

  validations: {
      form: {
        selectedArea: { required },
        templateName: { required },
        templateText: { required },
      }
  }
};
</script>

<style lang="sass" scoped>
  .dialog
    width: 400px
    height: 250px
</style>