<template>
  <v-dialog
    v-model="renderPopUp"
    max-width="900px"
    :persistent="$store.state.commons.cognitoDataValidate"
    @click:outside="handleClickOutside"
    ref="validationpopup"
  >
    <OutlinedCard
        v-if="!statusOk"
        :title="$t('components.validateEmailPhoneAlert.title')" 
        :subtitle="$t('components.validateEmailPhoneAlert.subtittle')"
        class="border-validate-email-phone"    
    >
    <br>
    <v-row>
        <v-col cols="12" sm="6" v-if="!emailValidated">
            <OutlinedCard
              :title="$t('components.validateEmailPhoneAlert.emailSendVerification.title')"
            >
              <Button
              :text="$t('components.validateEmailPhoneAlert.emailSendVerification.buttonText')"
              @clicked="sendVerificationEmail"
              :error=sendEmailButton.error
              :loading=sendEmailButton.loading
              :success=sendEmailButton.success
              :errorText="$t('components.validateEmailPhoneAlert.emailSendVerification.buttonErrorText')"
              :successText="$t('components.validateEmailPhoneAlert.emailSendVerification.sendButtonSuccessText')"
              ></Button>
            </OutlinedCard>
            <OutlinedCard>
              <v-text-field
              v-model="emailValidationCode"
              error-messages=""
              :label="$t('components.validateEmailPhoneAlert.emailCodeVerification.textFieldLabel')"
              outlined
              >
              </v-text-field>
              <Button
              :text="$t('components.validateEmailPhoneAlert.emailCodeVerification.buttonText')"
              @clicked="validateEmail"
              :error=validateEmailButton.error
              :loading=validateEmailButton.loading
              :success=validateEmailButton.success
              :errorText="$t('components.validateEmailPhoneAlert.emailSendVerification.buttonErrorText')"
              :successText="$t('components.validateEmailPhoneAlert.emailSendVerification.validateButtonSuccessText')"
              ></Button>
            </OutlinedCard>
        </v-col>
        <v-col cols="12" sm="6" v-else>
          <div class="mx-auto d-flex flex-column justify-center">
            <h3>Mail verificado</h3>
            <v-icon color="success" size="100px">mdi-check-circle</v-icon>
          </div>
        </v-col>
        <v-col cols="12" sm="6" v-if="!phoneNumberValidated">
            <OutlinedCard
              :title="$t('components.validateEmailPhoneAlert.phoneSendVerification.title')"
              >
              <Button
              :text="$t('components.validateEmailPhoneAlert.phoneSendVerification.buttonText')"
              @clicked="sendVerificationSms"
              :error=sendSmsButton.error
              :loading=sendSmsButton.loading
              :success=sendSmsButton.success
              :errorText="$t('components.validateEmailPhoneAlert.emailSendVerification.buttonErrorText')"
              :successText="$t('components.validateEmailPhoneAlert.emailSendVerification.sendButtonSuccessText')"
              ></Button>
            </OutlinedCard>
            <OutlinedCard>
              <v-text-field
              v-model="phoneValidationCode"
              error-messages=""
              :label="$t('components.validateEmailPhoneAlert.phoneCodeVerification.textFieldLabel')"
              outlined
              >
              </v-text-field>
              <Button
              :text="$t('components.validateEmailPhoneAlert.phoneCodeVerification.buttonText')"
              @clicked="validatePhoneNumber"
              :error=validateSmsButton.error
              :loading=validateSmsButton.loading
              :success=validateSmsButton.success
              :errorText="$t('components.validateEmailPhoneAlert.emailSendVerification.buttonErrorText')"
              :successText="$t('components.validateEmailPhoneAlert.emailSendVerification.validateButtonSuccessText')"
              ></Button>
            </OutlinedCard>
        </v-col>
        <v-col cols="12" sm="6" v-else>
          <div class="mx-auto d-flex flex-column justify-center">
            <h3>Telefono verificado</h3>
            <v-icon color="success" size="100px">mdi-check-circle</v-icon>
          </div>
        </v-col>
    </v-row>
    </OutlinedCard>
  </v-dialog>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard.vue';
import Button from '@/components/Button/Button';
import api from '@/api'

const { $core } = api
const { $users } = $core

export default {
    name: 'ValidateEmailPhonePopUp',
    components: {
        OutlinedCard,
        Button,
  },
  data(){
    return {
      statusOk: null,
      phoneValidationCode: null,
      emailValidationCode: null,
      successMessage: {
          actionPrimary: null,
          title: null,
          type: null,
      },
      emailValidated: false,
      phoneNumberValidated: false,
      renderPopUp: false,
      renderPopUpTest: true,
      sendEmailButton: {
        loading: false,
        success: false,
        error: false,
      },
      sendSmsButton: {
        loading: false,
        success: false,
        error: false,
      },
      validateEmailButton: {
        loading: false,
        success: false,
        error: false,
      },
      validateSmsButton: {
        loading: false,
        success: false,
        error: false,
      }
    }
  },
  created() {

  },
  mounted() {
    setTimeout(() => {
      const isEmailVerified = JSON.parse(localStorage.getItem('cognito_email_verified'))
      const isPhoneVerified = JSON.parse(localStorage.getItem('cognito_phone_verified'))
      const isCognitoUser = JSON.parse(localStorage.getItem('is_cognito_user'))
      
      if(isCognitoUser){
        if(isPhoneVerified != null){
          if(isPhoneVerified){
            this.phoneNumberValidated = true
          }else{
            this.phoneNumberValidated = false
          }
        }else{
          this.phoneNumberValidated = true
        }
        if(isEmailVerified){
          this.emailValidated = true  
        }else{
          this.emailValidated = false
        }
        if(!this.phoneNumberValidated || !this.emailValidated){
          this.renderPopUp = true
        }
      }
    }, 3000)
      
  },
  computed: {
    
  },
  methods: {
    handleClickOutside() {

    },
    async sendVerificationEmail(){
      try{
        this.sendEmailButton.loading=true
        const responseEmailCode = await $users.sendCognitoVerificationCode({params: {attributeName: 'email'}})
        if(responseEmailCode.status===200){
          this.sendEmailButton.loading=false
          this.sendEmailButton.success=true
          this.sendEmailButton.error=false
        }else{
          this.sendEmailButton.loading=false
          this.sendEmailButton.success=false
          this.sendEmailButton.error=true
        }
        
      }catch(e){
        this.sendEmailButton.error=true
        this.sendEmailButton.success=false
        this.sendEmailButton.loading=false
        return e
      }
    },
    async sendVerificationSms(){
      try{
        this.sendSmsButton.loading=true
        const responseSmsCode = await $users.sendCognitoVerificationCode({params: {attributeName: 'phone_number'}})
        if(responseSmsCode.status===200){
          this.sendSmsButton.loading=false
          this.sendSmsButton.success=true
          this.sendSmsButton.error=false
        }else{
          this.sendSmsButton.loading=false
          this.sendSmsButton.success=false
          this.sendSmsButton.error=true
        }
      }catch(e){
        this.sendSmsButton.error=true
        this.sendSmsButton.success=false
        this.sendSmsButton.loading=false
        return e
      }
    },
    async validateEmail(){
      try{
        this.validateEmailButton.loading=true
        const responseValidateEmailButton = await $users.verifyCognitoCode({params: {attributeName: 'email', code: this.emailValidationCode}})
        if(responseValidateEmailButton.status===200){
          this.validateEmailButton.loading=false
          this.validateEmailButton.success=true
          this.validateEmailButton.error=false
          localStorage.setItem('cognito_email_verified', true)
          this.emailValidated = localStorage.getItem('cognito_email_verified')
        }else{
          this.validateEmailButton.loading=false
          this.validateEmailButton.success=false
          this.validateEmailButton.error=true
        }
      }catch(e){
        this.validateEmailButton.loading=false
        this.validateEmailButton.success=false
        this.validateEmailButton.error=true
        return e
      }
    },
    async validatePhoneNumber(){
      try{
        this.validateSmsButton.loading=true
        const responseValidateSmsButton = await $users.verifyCognitoCode({params: {attributeName: 'phone_number', code: this.phoneValidationCode}})
        if(responseValidateSmsButton.status===200){
          this.validateSmsButton.loading=false
          this.validateSmsButton.success=true
          this.validateSmsButton.error=false
          localStorage.setItem('cognito_phone_verified', true)
          this.phoneNumberValidated = localStorage.getItem('cognito_phone_verified')
        }else{
          this.validateSmsButton.loading=false
          this.validateSmsButton.success=false
          this.validateSmsButton.error=true
        }
      }catch(e){
        this.validateSmsButton.loading=false
        this.validateSmsButton.success=false
        this.validateSmsButton.error=true
        return e
      }
    },
  }
}
</script>

<style lang="sass" scoped>
.border-validate-email-phone
  margin-bottom: 0 !important
</style>