<template>
  <v-card class="px-4" outlined >
    <template v-slot:progress="">
      <v-row>
        <v-col cols="12" class="pa-6 text-center">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </template>
    <v-row v-if="selectedPeriods.length < 1">
      <v-col cols="12">
        <p class="mb-0 ContractPreviewPeriodsDetailsCard-title pa-6 text-center">
          {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.periodsDetails.noPeriodSelected') }}
        </p>
      </v-col>
    </v-row>
    <v-row v-else-if="payments.length < 1">
      <v-col cols="12">
        <p class="mb-0 ContractPreviewPeriodsDetailsCard-title pa-6 text-center">
          {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.periodsDetails.noInstallmentsForSeletedPeriods') }}
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showEnrollmentPayment">
      <v-col class="col-sm-12 col-md-3">
        <div class="d-flex fill-height align-center">
          {{ enrollmentTitle }}
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="3" v-for="(payment, index) in enrollmentPayments" :key="index">
        <PeriodCard 
            :payment="payment"
            :due="getInstallmentsTranslation(payment.options.paymentInstallment, payment.options.paymentInstallments)" 
            :price="payment.options.amount" 
            :expiration-date="payment.options.dueDate" 
            :is-mandatory="payment.mandatory"
        />
      </v-col>
    </v-row>
    <v-row v-for="({ period, payments }, idx) in tariffPaymentsMappedByPeriod" :key="`period-${idx}`">
      <v-col class="col-sm-12 col-md-3">
        <div class="d-flex fill-height align-center">{{ period.termDescription }}</div>
      </v-col>
      <v-col cols="12" sm="12" md="3" v-for="(payment, index) in payments" :key="`payment-${index}`">
        <PeriodCard 
            :payment="payment"
            :due="getInstallmentsTranslation(payment.options.paymentInstallment, payment.options.paymentInstallments)" 
            :price="payment.options.amount" 
            :expiration-date="payment.options.dueDate" 
            :is-mandatory="payment.mandatory"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ContractPreviewPeriodsDetailsPeriodCard  from './ContractPreviewPeriodsDetailsAccountCard.vue';
export default {
  name: 'PeriodsDetails',
  props: ['selectedPeriods', 'payments', 'loading'],
  data() {
    return {
      systemConfig: null
    }
  },
  computed: {
    selectedPayments() {
      const paymentsCopy = this.payments
      const orderedPayments = paymentsCopy.sort((a, b) => (a.idProduct - b.idProduct))
      const selectedPeriodsIds = this.selectedPeriods.map(period => period.id )
      return orderedPayments.filter(payment => selectedPeriodsIds.includes(payment.options.term.id))
    },
    selectedPeriodsDescription() {
      return this.selectedPeriods.map(period => period.description).join(' ')
    },
    selectedPeriodsCount() {
      return this.selectedPeriods.length
    },
    filteredPaymentsBySelectedPeriod() {
      const selectedPeriodsIds = this.selectedPeriods.map(period => period.id)
      return this.payments.filter(payment => selectedPeriodsIds.includes(payment.options.term.id))
    },
    enrollmentPayments() {
      const ENROLLMENT_PRODUCT_ID = this.$sisConstants('modules.products.matricula')
      return this.filteredPaymentsBySelectedPeriod?.filter((payment) => payment.idProduct === ENROLLMENT_PRODUCT_ID)
    },
    enrollmentTitle() {
      // Setea cómo fallback la traducción de matrícula de cada país
      return this.enrollmentPayments[0]?.title || this.$t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.periodsDetails.enrollment')
    },
    tariffPayments() {
      const TARIFF_PRODUCT_ID = this.$sisConstants('modules.products.arancel')
      return this.filteredPaymentsBySelectedPeriod?.filter((payment) => payment.idProduct === TARIFF_PRODUCT_ID)
    },
    tariffPaymentsMappedByPeriod() {
      const periods = []

      this.tariffPayments.forEach((payment) => {
        const periodIndexInPeriodsArray = periods.findIndex((e) => e.period.termId === payment.options.term.id)
        const isPeriodAlreadyIncludedInPeriodsArray = periodIndexInPeriodsArray !== -1
        if (!isPeriodAlreadyIncludedInPeriodsArray) {
          periods.push({
            period: {
              termId: payment.options.term.id,
              termDescription: payment.options.term.description
            },
            payments: [payment],
          })
        } else {
          const periodPayments = periods[periodIndexInPeriodsArray].payments
          periodPayments.push(payment)
        }
      })

      return periods
    },
    showEnrollmentPayment() {
      return this.$countryConstants.student_pays_enrollment && this.enrollmentPayments.length > 0;
    }
  },
  components: {
    PeriodCard: ContractPreviewPeriodsDetailsPeriodCard
  },
  methods: {
    getInstallmentsTranslation(currentInstallment, installments) {
      return this.$t(
        'modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.periodsDetails.installments',
        { currentInstallment, installments }  
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.ContractPreviewPeriodsDetailsCard
  &-title
    font-size: 13px
</style>
