export default {

    // DEFINE SIS DB CONSTANTS

    core:{
        rootRolesIds: [29]
    },
    modules:{
        products:{
            matricula: 80,
            arancel: 81,
            examen: 82,
            nueva_tne: 85,
            revalidacion_tne: 86,
            derecho_examen: 88,
            gasto_administrativo: 87
        },
        examBoards:{
            laboratory: 70,
            notebook: 71,
        },
        procedures:{
            exam: 8
        }
    }

}