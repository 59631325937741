import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('canvas');

export class Courses extends Api {
    constructor(http, config = {}) {
        super(http, url, 'courses', config);
    }
}
