const prepaidsTranslations = {
  es: {
    table: {
      breadcrumbs: {
        title: 'Configuración de pagos anticipados',
        description:'Administración de pagos anticipados',
      },
      headers: {
        description: 'Nombre',
        code: 'Código',
        status: 'Estado',
        dayRange: 'Rango de Días',
        percentage: 'Porcentaje',
        studentType: 'Tipo de Estudiante',
        scholarship: 'Beca',
        average: 'Promedio'
      },
      actions: {
        errorDelete: 'Error al eliminar',
        bulkDelete: 'Eliminar pagos anticipados',
      },
      messages: {
        bulkDeleteError: 'No se pudieron eliminar los pagos anticipados seleccionados',
        deleteError: 'No se pudo eliminar el pago anticipado seleccionado',
      }
    },
    create: {
      breadcrumbs: {
        title: 'Nuevo pago anticipado',
        description:'Crea un nuevo pago anticipado',
      },
      codeSection: {
        title: 'Datos del pago anticipado',
        subtitle: 'Asigna la información básica del pago anticipado que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          name: 'Nombre',
          code: 'Código'
        },
      },
      studentSection: {
        title: 'Tipo de estudiantes',
      },
      conditionSection: {
        title: 'Aplica por',
        labels: {
          scholarship: 'Beca',
          average: 'Promedio'
        }
      },
      termSection: {
        title: 'Periodos',
        subtitle: 'Selecciona en esta sección los periodos donde este pago anticipado puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      scholarship: {
        title: 'Becas' ,
        subtitle: 'Selecciona en esta sección las becas donde este pago anticipado puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      benefits: {
        title: 'Beneficios' ,
        subtitle: 'Selecciona en esta sección los beneficios donde este pago anticipado puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },

      dayRangeSection: {
        title: 'Días y porcentajes',
        labels: {
          percentage: 'Porcentaje',
          dayFrom: 'Día Inicio',
          dayTo: 'Día Fin'
        }
      },
      zoneSection: {
        title: 'Zonas'
      },
      careersSection: {
        title: 'Carreras',
        subtitle: 'Selecciona las carreras a las que quiere asignarles el pago anticipado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionadas'
        }
      },
      successMessage: {
        title: 'Pago anticipado creado correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al crear el pago anticipado',
      }
    },
    update: {
      breadcrumbs: {
        title: 'Editar pago anticipado',
        description:'Modifica el pago anticipado seleccionado',
      },
      codeSection: {
        title: 'Datos del pago anticipado',
        subtitle: 'Modifica la información básica del pago anticipado que estás modificando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          name: 'Nombre',
          code: 'Código'
        },
      },
      studentSection: {
        title: 'Tipo de estudiantes',
      },
      conditionSection: {
        title: 'Aplica por',
        labels: {
          scholarship: 'Beca',
          average: 'Promedio'
        }
      },
      termSection: {
        title: 'Periodos',
        subtitle: 'Selecciona en esta sección los periodos donde este pago anticipado puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      scholarship: {
        title: 'Becas' ,
        subtitle: 'Selecciona en esta sección las becas donde este pago anticipado puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      benefits: {
        title: 'Beneficios' ,
        subtitle: 'Selecciona en esta sección los beneficios donde este pago anticipado puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      dayRangeSection: {
        title: 'Días y porcentajes',
        labels: {
          percentage: 'Porcentaje',
          dayFrom: 'Día Inicio',
          dayTo: 'Día Fin'
        }
      },
      zoneSection: {
        title: 'Zonas'
      },
      careersSection: {
        title: 'Carreras',
        subtitle: 'Selecciona las carreras a las que quiere asignarles el pago anticipado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionadas'
        }
      },
      successMessage: {
        title: 'Pago anticipado modificado correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al modificar el pago anticipado',
      }
    },
    validations: {
      nameRequired: 'Ingresa el nombre del pago anticipado',
      nameAlphanumericWithSpaces: 'Solo alfanuméricos',
      nameMaxLength: 'Máximo 60 caracteres',
      codeRequired: 'Ingresa el código del pago anticipado',
      codeAlphanumeric: 'Solo alfanuméricos',
      codeMaxLength: 'Máximo 10 caracteres',
      codeUnique: 'Código ya existente',
      studentType: 'Debe seleccionar un tipo de estudiante',
      term: 'Seleccione un periodo',
      dayFromRequired: 'Debe ingresar un día de inicio',
      dayToRequired: 'Debe ingresar un día de fin',
      uniqueRange: 'Rango de días ya existente',
      validRange: 'Debe ingresar un rango de días válido',
      betweenValues: 'Debe ser entre 1 y 30',
      percentageRequired: 'Debe ingresar un porcentaje',
      percentageBetweenValues: 'Debe ser entre 1 y 100',
      zone: 'Debe seleccionar al menos una zona',
      noSelectedTerms: 'Debes seleccionar al menos un periodo',
      noSelectedCareers: 'Debes seleccionar al menos una carrera',
      noSelectedScholarships:'Debes seleccionar al menos una beca',
      noSelectedBenefits:'Debes seleccionar al menos un beneficio',
    },
  },
  en: {

  }
};


export default prepaidsTranslations
