<template>
  <div>
    <v-container fluid v-if="!finished">
      <v-row>
        <v-col cols="12">
          <v-card outlined class="py-8 px-10">
            <h3>{{ $t('modules.scholarships.details.drawer.students') }}</h3><br>
            <p>{{ $t('modules.scholarships.details.drawer.description') }}</p>

            <SuperTableAssign
              :headers="headers"
              :users="availableItems"
              :withAverage="withAverage"
              :minAverage="minAverage"
              :canSelect="canSelect"
              :pageCount="pagination.pagesAmount"
              :page="pagination.currentPage"
              :totalItems="pagination.totalItems"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              :loading="loading"
              @searchAction="searchAction"
              @inputPagination="onInputPagination"
              @lengthPaginationChange="onLengthPaginationChange"
              @toggleItem="toggleItem"
            ></SuperTableAssign>

          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex justify-end pb-8">
          <Button
            white
            :text="$t('actions.cancel')"
            @clicked="cancel"
            :disabled="sending"
            @end="resetButtonValues()"
            class="mr-4"
          ></Button>

          <Button
            :loading="sending"
            :success="success"
            :error="error"
            :text="$t('actions.add')"
            :successText="$t('actions.added')"
            :errorText="'Error'"
            :disabled="!addedItems.length || (availableSlots !== null && availableSlots <= 0)"
            @end="resetButtonValues()"
            @clicked="checkLimit"
          ></Button>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </div>
</template>
<script>
import SuperTableAssign from '../Components/SuperTable/SuperTableAssign'
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import Button from '@/components/Button/Button';
import { $studentsWithoutScholarship, $users, $scholarships } from '../services'
import { mapGetters } from 'vuex';

export default {
  name: 'ScholarshipsAssign',
  components: {
    SuperTableAssign,
    SuccessMessage,
    Button
  },
  props:{
    insideDrawer: Boolean,
    withAverage: Boolean,
    availableSlots: Number,
    limit: Number,
    minAverage: { type: Number, default: null },
    items: { type: Array, default: () => [] },
    products: { type: Array, default: () => [] },
    scholarshipId: Number,
  },
  data() {return {
    finished: false,
    loading: false,
    sending: false,
    success: false,
    error: false,
    search: '',
    addedItems: [],
    availableItems: [],
    successMessage: {
      type: '',
      title: '',
      actionPrimary: null,
      actionSecondary: null
    },
    pagination: {
      limit: 20,
      page: 1,
      currentPage: 1,
      pagesAmount: 1,
      totalItems: 0
    },
    sort: {
      sortBy: 'name',
      sortDesc: false,
    },
  }},
  computed: {
    headers() {
      return [ 
        { text: this.$t('modules.scholarships.details.drawer.headers.name'), value: 'name', show: true, sort: () => 0 }, 
        { text: this.$t('modules.scholarships.details.drawer.headers.average'), value: 'average', sortable: false, show: this.withAverage }
      ].filter(item => item.show);
    },
    canSelect() {
      return !this.limit || (this.availableSlots !== null && this.availableSlots > this.addedItems.length);
    },
    ...mapGetters({userId: 'commons/getUserID'})
  },
  methods: {
    cancel() {
      this.$emit('closeDrawer', false);
    },
    // clean(){
    //   setTimeout(() => {
    //     this.search == null ? this.search = '' : ''
    //     this.getAvailableStudents()
    //     },100)
    // },
    async getAvailableStudents() {
      this.loading = true;

      let sortCol = 'name';
      switch (this.sort.sortBy) {
        case 'name':
        default:
          sortCol = 'user.userData.lastname';
          break;
      }
      if(this.search == null) {
        this.search = ''
      }
      const params = {
        page: this.pagination.page - 1,
        length: this.pagination.limit,
        orderBy: sortCol,
        orientation: this.sort.sortDesc ? 'desc' : 'asc',
        search: this.search
      }

      const responseData = await $studentsWithoutScholarship.findPaginated(null, params);
      const auxStudents = responseData.data.content.map(item => {
        return { 
          id: item.id, 
          name: `${item.lastname} ${item.name}`, 
          avatar: `${$users.baseUrl}/users/${item.userId}/avatar`,
          average: null,
          isSelected: false
        };
      });

      this.pagination.pagesAmount = responseData.data.totalPages
      this.pagination.totalItems = responseData.data.totalElements
      
      
      auxStudents.forEach((student, index) => {
        const added = this.addedItems.find(el => el.id == student.id);
        if(added) auxStudents[index] = {...student, ...added};
      });

      this.availableItems = auxStudents;
      this.loading = false;
    },

    toggleItem(item){
      if(item.isSelected) {
        this.addedItems.push(item);
      }
      else {
        const index = this.addedItems.indexOf(item);
        if(index != -1) this.addedItems.splice(index, 1);
      }
    },

    resetTable() {
      this.addedItems = [];
      this.getAvailableStudents();
      this.finished = false;
      this.sending = false;
      this.resetButtonValues()
      this.successMessage = {
        type: '',
        title: '',
        actionPrimary: null,
        actionSecondary: null
      }
    },

    checkLimit() {
      if (this.availableSlots !== null && this.addedItems.length > this.availableSlots) {
        
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{value: this.$t('modules.scholarships.details.drawer.success.limitError')}],
          actionPrimary: {text: this.$t('modules.scholarships.details.drawer.success.actions.back'), callback() {}},
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary'
        });
      } else {
        this.save()
      }
    },

    async save() {
      this.sending = true;

      const payload = this.addedItems.map(row => ({id: row.id, average: row.average, userId: this.userId}));

      try {
        await $scholarships.assignStudents(this.scholarshipId, payload);
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.scholarships.details.drawer.success.title');
        this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { 
          this.$emit('closeDrawer', true);
          this.resetTable();
        }};
        this.successMessage.actionSecondary = { text: 'Seguir agregando', callback: () => { 
          this.resetTable();
          this.$emit('reloadScholarship');
        }};
        this.success = true
      }
      catch(e) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.scholarships.details.drawer.error');
        this.successMessage.actionPrimary = { text: this.$t('modules.scholarships.details.drawer.success.actions.back'), callback: () => this.finished = false };
        this.successMessage.actionSecondary = null;
        this.error = true
      }
      finally {
        this.finished = true;
        this.resetButtonValues()
      }
    },
    resetButtonValues () {
      this.sending = false;
      this.success = false;
      this.error = false;
    },
    async onInputPagination(event) {
      this.pagination.page = event;
      this.pagination.currentPage = event;
      this.getAvailableStudents();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        currentPage: 1,
        pagesAmount: 1
      };
      this.getAvailableStudents();
    },
    searchAction(data) {
      this.pagination.page = 1;
      this.search = data;
      this.getAvailableStudents();
    },
  },
  watch: {
    availableSlots() {
      this.resetTable();
    },
    sort: {
      handler(){
        this.getAvailableStudents();
      },
      deep: true
    }
    
  },
  mounted() {
    this.getAvailableStudents();
  }
}
</script>