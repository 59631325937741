
const ProfessionalizingPractice = {
    es: {
        breadcrumbs: {
            title: 'Alumnos Práctica Profesionalizante',
            description: 'Alumnos inscriptos a prácticas profesionalizante'
        },
        cards_data: {
            qualified_students: 'Alumnos habilitados',
            completed_procedure: 'Finalizados',
            initialized_procedures: 'Trámites iniciados',
            management_procedures: 'Gestión'
        },
        filters: {
                title: 'Refinar búsqueda',
                term: 'Periodo',
                sub1: 'Todos',
                condition: 'Estado',
                sub2: 'Todos',
            },
        table: {
            headers: {
                student: 'Alumno',
                dni: 'DNI',
                place: 'Dónde hara la PP',
                locality: 'Localidad',
                career: 'Carrera',
                status: 'Estado'
            },
            warning: 'No se encontro un alumno con ese DNI {documento}'
        },
        update: {
            breadcrumbs: {
                title: 'Estado Practica Profesionalizante de {name}',
                subtitle: 'Estado Practica Profesionalizante',
                agreement: 'Convenio N{convenio}'
            },
            observations: 'Observaciones',
            documents: {
                title: 'Documentos',
                presentation_letter: 'Carta de presentación',
                cv: 'CV del alumno',
                academic_record: 'Ficha academica',
                not_found: 'El documento no existe',
            },
            steppers: {
                header: {
                    start: {
                        title: 'Inicio',
                        companyPP: 'Dónde hará la PP',
                        subject: 'Materia',
                        drop_text_alumno: 'Datos del Alumno',
                        drop_company: {
                            title: 'Datos de la empresa',
                            form: {
                                reason: 'Razón Social',
                                cuit: 'CUIT',
                                address: 'Dirección',
                                referrer_name: 'Nombre del referente de la empresa',
                                referrer_position: 'Cargo del referente',
                                email: 'E-mail del contacto',
                                tel: 'Teléfono',
                                minLength: 'Minimo 9 digitos',
                                minLengthTel: 'Minimo 10 digitos',
                                emailRequired: 'El email es invalido'
                            }
                        },
                        drop_student: {
                            form: {
                                telefono: 'Teléfono fijo',
                                celular: 'Teléfono celular',
                                email: 'E-mail',
                                localidad: 'Localidad',
                                carrera: 'Carrera',
                                legajo: 'Legajo',
                                materia: 'Materia',
                                provincia: 'Provincia'
                            }
                        },
                    },
                    managment: 'Gestión',
                    finish: 'Finalizado',
                },
                manage: {
                    requirements: '¿Cumple con los requisitos requeridos?',
                    search: 'Buscar empresa',
                    update: 'Actualizar empresa',
                    company_process: '¿La empresa continúa con el proceso?',
                    documentation: 'Documentación',
                    validation: 'Validación',
                },
                reject: '¿Estas seguro de rechazar la Práctica Profesionalizante?',
                finish: {
                    startPP: 'Inicio de la PP',
                    endPP: 'Fin de la PP',
                    disapproved: 'Desaprobar',
                    approved: 'Aprobar',
                }
            },
            title: 'Práctica Profesionalizante',
        }
        
    },
    es_MX: {
        breadcrumbs: {
            title: 'Alumnos Práctica Profesionalizante',
            description: 'Alumnos inscriptos a prácticas profesionalizante'
        },
        cards_data: {
            qualified_students: 'Alumnos habilitados',
            completed_procedure: 'Finalizados',
            initialized_procedures: 'Trámites iniciados',
            management_procedures: 'Gestión'
        },
        filters: {
                title: 'Refinar búsqueda',
                term: 'Periodo',
                sub1: 'Todos',
                condition: 'Estado',
                sub2: 'Todos',
            },
        table: {
            headers: {
                student: 'Alumno',
                dni: 'CURP',
                place: 'Dónde hara la PP',
                locality: 'Distrito',
                career: 'Carrera',
                status: 'Estado'
            },
            warning: 'No se encontro un alumno con ese CURP {documento}'
        },
        update: {
            breadcrumbs: {
                title: 'Estado Practica Profesionalizante de {name}',
                subtitle: 'Estado Practica Profesionalizante',
                agreement: 'Convenio N{convenio}'
            },
            observations: 'Observaciones',
            documents: {
                title: 'Documentos',
                presentation_letter: 'Carta de presentación',
                cv: 'CV del alumno',
                academic_record: 'Ficha academica',
                not_found: 'El documento no existe',
            },
            steppers: {
                header: {
                    start: {
                        title: 'Inicio',
                        companyPP: 'Dónde hará la PP',
                        subject: 'Materia',
                        drop_text_alumno: 'Datos del Alumno',
                        drop_company: {
                            title: 'Datos de la empresa',
                            form: {
                                reason: 'Razón Social',
                                cuit: 'RFC',
                                address: 'Dirección',
                                referrer_name: 'Nombre del referente de la empresa',
                                referrer_position: 'Cargo del referente',
                                email: 'E-mail del contacto',
                                tel: 'Teléfono',
                                minLength: 'Minimo 9 digitos',
                                minLengthTel: 'Minimo 10 digitos',
                                emailRequired: 'El email es invalido'
                            }
                        },
                        drop_student: {
                            form: {
                                telefono: 'Teléfono fijo',
                                celular: 'Teléfono celular',
                                email: 'E-mail',
                                localidad: 'Distrito',
                                carrera: 'Carrera',
                                legajo: 'Legajo',
                                materia: 'Materia',
                                provincia: 'Provincia'
                            }
                        },
                    },
                    managment: 'Gestión',
                    finish: 'Finalizado',
                },
                manage: {
                    requirements: '¿Cumple con los requisitos requeridos?',
                    search: 'Buscar empresa',
                    update: 'Actualizar empresa',
                    company_process: '¿La empresa continúa con el proceso?',
                    documentation: 'Documentación',
                    validation: 'Validación',
                },
                reject: '¿Estas seguro de rechazar la Práctica Profesionalizante?',
                finish: {
                    startPP: 'Inicio de la PP',
                    endPP: 'Fin de la PP',
                    disapproved: 'Desaprobar',
                    approved: 'Aprobar',
                }
            },
            title: 'Práctica Profesionalizante',
        }
        
    },
    es_CL: {
        breadcrumbs: {
            title: 'Estudiantes Práctica Profesionalizante',
            description: 'Estudiantes inscriptos a prácticas profesionalizante'
        },
        update: {
            steppers: {
                header: {
                    start: {
                        drop_company: {
                            form: {
                                cuit: 'RUT'
                            }
                        },
                        drop_student: {
                            form: {
                                telefono: 'Teléfono fijo',
                                celular: 'Teléfono celular',
                                email: 'E-mail',
                                localidad: 'Comuna',
                                carrera: 'Carrera',
                                legajo: 'Legajo',
                                materia: 'Materia',
                                provincia: 'Región'
                            }
                        },
                    }
                },
                manage: {
                    search: 'Cambiar empresa',
                    update: 'Actualizar empresa'
                },
                finish: {
                    disapproved: 'Reprobar',
                    approved: 'Aprobar',
                }
            },
            title: 'Práctica Profesionalizante',
        },
        table: {
            warning: 'No se encontro un alumno con ese RUT {documento}',
            headers: {
                dni: 'RUT'
            }
        }
    },
    es_PE: {
        table: {
            headers: {
                locality: 'Distrito'
            }, 
        },
        update: {
            steppers: {
                header: {
                    start: {
                        subject: 'Curso',
                        drop_student: {
                            form: {
                                materia: 'Curso',
                                localidad: 'Distrito',
                                provincia: 'Departamento'
                            }
                        }
                    }
                },
            }
        }
    }
};

export default ProfessionalizingPractice