<template>
    <v-container fluid class="pa-0 scrollableContent">
        <v-container class="container-custom px-6">
            <Breadcrumbs
                :title="carrera"
                :description="carrera"
                :callback="goBack"
                class="pb-4"
                >
                <Button
                    @clicked="tmpDrawerOpen"
                    icon='mdi-download'
                    :text="$t('modules.academic.gradebook.drawer.title')"
                ></Button>
            </Breadcrumbs>
            <h3 class="mx-4  pl-6 pt-2" ></h3>
            <v-row no-gutters class="row pl-6 pb-6 pt-4">
                <v-col sm="4">
                    <v-text-field
                        prepend-inner-icon="mdi-magnify"
                        :placeholder="$t('modules.academic.gradebook.table.searchInput')"
                        outlined
                        clearable
                        class="superTable__search"
                        v-model="search"
                        @click:clear="clearAction"
                        @keyup.enter="searchAction"
                        @keyup.esc="clearAction"
                    ></v-text-field>
                </v-col>
                <v-col sm="4">
                    <Button
                        class="ml-4"
                        @clicked="searchAction"
                        outlined
                        depressed
                        :text="$t('actions.search')"
                    ></Button>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4" >
                    <v-select
                        v-if="!superTableView"
                        :items="order"
                        label="Ordenar"
                        outlined
                        @change="onChangeOrder($event)"
                    ></v-select>
                </v-col>
            </v-row>
        <v-row class="ml-4 pl-4" v-if="!superTableView">
            <v-col sm="4" v-for="(cardBook, index) in cardsBooks" :key="index">
                
                <CardBook 
                    :title="cardBook.numero.toString()"
                    :subtitle="`${cardBook.folios} de 200`"
                    :plan="cardBook.resolucion ? `Resolución ${cardBook.resolucion}` : 'Sin resolución'"
                    :planNumber="cardBook.plan"
                    :resolucion="cardBook.resolucion"
                    :separator="true"
                    :book="cardBook.numero"
                    :careerBook="careerBook"
                    @goToSheet="(id)=>{goToSheet(id)}"
                    :sheets="$route.params.id"
                    @requestConfirmation="requestConfirmation"
                    :bookConfirm="bookConfirm" 
                > 
                </CardBook>
            </v-col>
        </v-row>
        <v-row  v-else>
            <SuperTable
                class="ml-6"
                :headers="headers"
                ref="tabla"
                :items="sheets"
                :pageCount="pagination.pagesAmount"
                :page="pagination.page"
                :totalItems="totalSheets"
                @input-pagination="onInputPagination"
                @length-pagination-change="onLengthPaginationChange"
            />
        </v-row>
        </v-container>
        <TemporaryRightDrawer
            :open="tmpDrawer.open"
            @tmpDrawerOpen="tmpDrawerOpen"
            :title="tmpDrawer.title"
            :description="tmpDrawer.description"
        >
        <BooksDownload
            :lastBooks="lastBooks"
            @closeDrawer="()=>{this.tmpDrawer.open = false}"
        ></BooksDownload>
        </TemporaryRightDrawer>
        <ConfirmDownload 
            :open="openModal" 
            @close="closeModal" 
            @requestBook="requestBook" 
            :response="response"
            :successMessage="successMessage"
            :createButton="createButton"
            :book="bookNumber"
            :resolucion="resolucion"
            :plan="planNumber"
        />
    </v-container>
</template>
<script>

import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import CardBook from '../Components/CardBook';
import {$studentCareerDetails, $getStudentsByBookAndCareer, $reports} from '../Services';
import ConfirmDownload from '../Components/ConfirmDownload';
import SuperTable from '../Components/SuperTable';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import BooksDownload from '#/portaladministrativo/Academic/Gradebook/Views/BooksDownload';
import { mapGetters } from 'vuex';
import moment from 'moment';

    export default {
        name: 'CareersBook',
        components: { 
            ConfirmDownload,
            BooksDownload,
            TemporaryRightDrawer,
            Breadcrumbs,
            Button,
            CardBook,
            SuperTable,
        },
        data() {
            return {
                bookConfirm: false,
                response: false,
                cardsBooks: [],
                search: '',
                carrera: '',
                totalSheets: 0,
                planes: [],
                lastBooks: [],
                sheets: [],
                currentOrder: null,
                sortOrder: null,
                superTableView: false,
                headers: [
                    { text: this.$t('modules.academic.gradebook.headers.nameStudent'), value: 'nombreAlumno' },
                    { text: this.$t('modules.academic.gradebook.headers.documentation'), value: 'dniAlumno' },
                    { text: this.$t('modules.academic.gradebook.headers.sheet'), value: 'folio' },
                    { text: this.$t('modules.academic.gradebook.headers.career'), value: 'carrera' },
                    { text: this.$t('modules.academic.gradebook.headers.status'), value: 'status' },
                ],
                pagination: {
                    limit: 20,
                    page: 1,
                    pagesAmount: 1
                },
                items: [],
                order: ['Mayor', 'Menor'],
                careerBook: true,
                tmpDrawer: {
                    open: false,
                    title: this.$t('modules.academic.gradebook.drawer.title'),
                    description: this.$t('modules.academic.gradebook.drawer.description')
                },
                successMessage: {
                    actionPrimary: null,
                    title: null,
                    type: null,
                    text: null,
                },
                createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                openModal: false,
                bookNumber: null,
                planNumber: null,
                loading: false,
                resolucion: null,
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            async requestBook() {
                this.createButton.loading = true
                try {
                    this.loading = true
                    this.createButton.loading = true
                    
                    await $reports.academic.create({book: this.bookNumber, userId: this.userId, careerId: this.$route.params.id, resolution: this.resolucion}, null , 'generateInvoices'  )
                    this.createButton.loading = false
                    this.createButton.success = true
                    this.response = true
                    this.successMessage.title = this.$t('modules.academic.gradebook.download.request.successTitle');
                    this.successMessage.text = this.$t('modules.academic.gradebook.download.request.successContent');
                    this.successMessage.type = 'success';
                    this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback:() => { this.closeModal() }}
                } catch (error) {
                    this.response = true
                    this.createButton.loading = false
                    this.createButton.error = true
                    if(error.status == 217){
                        this.successMessage.title = this.$t('modules.academic.gradebook.download.request.successTitle');
                        this.successMessage.text = this.$t('modules.academic.gradebook.download.request.successContent');
                        this.successMessage.type = 'warning';
                        this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback:() => { this.closeModal() }}
                    } else {
                        this.successMessage.type = (error.status == 203) ? 'warning' : 'error'
                        this.successMessage.title = (error.status == 203) ? ' ' : this.$t('actions.error')
                        this.successMessage.text = (error.status == 203) ? this.$t('modules.academic.gradebook.download.request.errorTime',) : this.$t('modules.academic.gradebook.download.request.error')
                        this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.closeModal() }}
                        throw error
                    }
                } finally {
                    this.loading = false
                }
            },
            closeModal(){
                this.openModal = false
                this.response = false
            },
            requestConfirmation(object) {
                    this.openModal = true
                    this.bookConfirm = object.bookConfirm
                    this.bookNumber = object.book
                    this.planNumber = object.planNumber
                    this.resolucion = object.resolucion
            },
            tmpDrawerOpen () {
                this.tmpDrawer.open = !this.tmpDrawer.open
                if (this.tmpDrawer.open) this.festchLastBooks();
            },
            async festchLastBooks() {
                try {
                    const params = {
                        page:  this.pagination.page ?  this.pagination.page - 1 : 1,
                        size: this.pagination.limit,
                        sort: this.sortOrder,
                        userId: this.userId,
                    }
                    const {data} = await $reports.academic.find('getFilesNames', null, { params })
                    this.lastBooks = data.content.map(item =>{
                        item.date = moment(item.date).format('DD-MM-YYYY');
                        return item
                    })
                    const hasPendingReport = this.lastBooks.find(item => item.status.value == 282)
                    if(hasPendingReport){
                        setTimeout(()=>{
                            this.festchLastBooks()
                        },120000)
                    }
                } catch (error) {
                    this.lastBooks = []
                    throw error
                }
            },
            async fetchData(){
                try{
                    this.loading = true
                    const respuesta = await $studentCareerDetails.find(null, null, {params: {careerId: this.$route.params.id, order: this.sortOrder}});
                    this.cardsBooks = respuesta.data.libros;
                    this.planes = respuesta.data.libros.map(libro => libro.plan)
                    this.carrera = respuesta.data.carrera
                }
                catch(e){
                    this.cardsBooks = [];
                    return e;
                } finally{
                    this.loading = false;
                }
            },
            async fetchStudent(){
                try{
                    const params = {
                        page:  this.pagination.page ?  this.pagination.page - 1 : 1,
                        length: this.pagination.limit,
                    }
                    const filterParams = {
                        book: null,
                        careerId: this.$route.params.id,
                        search: this?.search ?? ''
                    }
                    this.loading = true
                    const respuesta = await $getStudentsByBookAndCareer.filter(filterParams, {params});
                    this.sheets = respuesta.data.content;
                    this.totalSheets = respuesta.data.totalElements;
                    this.career = respuesta.data.content[0]?.carrera
                    this.pagination.pagesAmount = respuesta.data.totalPages;
                }
                catch(e){
                    this.sheets = [];
                    return e;
                } finally{
                    this.loading = false;
                }
            },
            onChangeOrder(order){
                if(order === 'Mayor'){
                    this.sortOrder = 'desc'
                }else{
                    this.sortOrder = 'asc'
                }
                this.fetchData();
            },
            searchAction() {
                this.fetchStudent();
                this.superTableView = true
            },
            clearAction() {
                this.$emit('search', '');
            },
            goToSheet(id, sheetNumber){
                this.$router.push({path: `/academic/gradebook/${id}/sheet/${sheetNumber}`});
            },
            onInputPagination(event) {
                this.pagination.page = event;
                this.fetchStudent();
            },
            onLengthPaginationChange(event) {
                this.pagination = {
                    page: 1,
                    limit: event,
                    pagesAmount: 1
                };
                this.fetchStudent();
                },
            goBack(){
                if(this.superTableView){
                    this.superTableView = false;
                    this.search = ''
                    this.fetchData()
                }
                else{
                    this.$router.push('/academic/gradebook');
                }
            },
        },
        computed: {
            ...mapGetters({
                userId: 'commons/getUserID',
            })
        }
    };
</script>