<template>
  <div>
    <v-row class="px-3" v-if="!massImportStatus">
      <v-col cols="12">
        <div class="mx-5 my-3">
          <h4 class="subtitle-1 font-weight-medium">{{ $t('modules.users.table.massImportDrawer.title') }}</h4>
          <a :href="dataWork ? template.work : template.SIES" download>
            <Button
              outlined
              class="mt-4 mb-8"
              icon="mdi-download"
              :text="$t('actions.download_template')"
            ></Button>
          </a>
        </div>
        <DropFiles
          :key="dropFilesKey"
          v-model="file"
          class="mx-5 mt-5"
          extension=".xls,.xlsx"
          limitSizeBytes="2000000"
          type-file="excel"
        ></DropFiles>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <Button
          white
          class="my-6"
          :text="$t('actions.close')"
          :disabled="sendButton.loading"
          @clicked="closeConfirmation"
        ></Button>
        <Button
          class="mx-5 my-6"
          :loading="sendButton.loading"
          :success="sendButton.success"
          :error="sendButton.error"
          :text="$t('actions.load')"
          :successText="$t('actions.loaded')"
          :errorText="$t('actions.cancel')"
          :disabled="!canUpdate"
          @end="resetButtonValues"
          @clicked="confirmationPopup(dataWork)"
        ></Button>
      </v-col>
    </v-row>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
    />
  </div>
</template>

<script>
import DropFiles from '@/components/DropFiles/DropFiles';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $work, $siesMassive } from '../../services';
import { mapGetters } from 'vuex'

export default {
  name: 'MassImportWorkData',
  components: {
    DropFiles,
    Button,
    SuccessMessage,

  },
  props: {
    dataWork: Boolean,
  },
  data() {
    return {
      template: {
        work: '/templates/template-datos-laborales.xlsx',
        SIES: '/templates/template-changeBooleanSiesUser.xlsx',
      },
      massImportStatus: false,
      file: null,
      dropFilesKey: 0,
      sendButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: 'success',
        title: '',
        actionPrimary: { text: '', callback() {} },
      },
    }
  },
  computed: {
    ...mapGetters({
      userId: 'commons/getUserID',
    }),
    canUpdate() {
      return this.$permissions.portaladministrativo.users.UPDATE
    },
  },
  methods: {
    confirmationPopup(dataWork) {
      if (!this.file) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.users.table.massImportDrawer.messages.noFiles'),
          
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.users.table.massImportDrawer.messages.sendConfirmation'),
          content: [{ value: this.file.name }],
          actionPrimary: { text: this.$t('actions.send'), callback: () => this.sendFile(dataWork) },
          actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      }
    },
    async sendFile(dataWork) {
      if(dataWork){
        try {
          this.sendButton.loading = true;

          const formData = new FormData()
          formData.append('file', this.file);
          const response = await $work.formData(formData, 'massive')
          const data = JSON.parse(await response.text())

          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.users.table.massImportDrawer.messages.success', { quantity: data.errorDetail });
          this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.close() } }
          this.sendButton.success = true;
        } catch (error) {
          this.successMessage.type = 'error';
          this.successMessage.title = (error.codeMeaning && error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.users.table.massImportDrawer.messages.error');
          this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => this.close()}
          this.sendButton.error = true;

          throw error
        } finally {
          this.massImportStatus = true;
          this.sendButton.loading = false;
        }
      }else{
        this.studentSIES();
      }
    },
    async studentSIES(){
      try {
          this.sendButton.loading = true;

          const formData = new FormData()
          formData.append('file', this.file);
          await  $siesMassive.formData(formData, null)
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.users.table.massImportDrawer.messages.success');
          this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.close() } }
          this.sendButton.success = true;
        } catch (error) {
          this.successMessage.type = 'error';
          this.successMessage.title = (error.codeMeaning && error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.users.table.massImportDrawer.messages.error');
          this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => this.close()}
          this.sendButton.error = true;

          throw error
        } finally {
          this.massImportStatus = true;
          this.sendButton.loading = false;
        }
    },
    resetButtonValues() {
      this.sendButton.loading = false;
      this.sendButton.success = false;
      this.sendButton.error = false;
    },
    closeConfirmation() {
      if (this.file) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.users.table.massImportDrawer.messages.pendingFiles'),
          actionPrimary: { text: this.$t('actions.yes'), callback: () => this.close() },
          actionSecondary: { text: this.$t('actions.no'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.close()
      }
    },
    close() {
      this.$emit('close')
      setTimeout(() => {
        this.file = null
        this.dropFilesKey++
        this.massImportStatus = false
        this.resetButtonValues()
      }, 2000)
    },
  },
}
</script>
