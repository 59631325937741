<template>
  <div>
    <v-container fluid class="pa-0 pb-8 overflow-y-auto">
      <v-container class="container-custom" v-if="!createNewDiscountStatus">
        <template v-if="!insideDrawer">
          <Breadcrumbs
            :title="$t('modules.academic.periods.create.breadcrumbs.title')"
            :description="$t('modules.academic.periods.create.breadcrumbs.description')"
          ></Breadcrumbs>
        </template>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlineCard
                :title="$t('modules.academic.periods.create.cards.title')"
                :subtitle="$t('modules.academic.periods.create.cards.main_subtitle')"
                :switchLabel="formattedPeriodStatus"
                :switchValue="period.status"
                switcher
                @switchChange="onSwitchChange"
                class="mb-6"
              >
                <v-row>
                  <v-col sm="6" class="py-2">
                    <v-text-field
                      :label="`${$t('modules.academic.periods.create.cards.name')} *`"
                      outlined
                      v-model="period.description"
                      required
                      :error-messages="$v.period.description.$dirty && $v.period.description.$invalid ? $t('modules.academic.periods.create.errors.required') : ''"
                      @blur="onBlur('description')"
                      @keypress="$validateAlphanumeric($event, $v.period.description.$model,40, true)"
                      ref="description"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <v-text-field
                      :label="`${$t('modules.academic.periods.create.cards.code')} *`"
                      outlined
                      placeholder="XX/XXXX"
                      v-model="period.code"
                      ref="code"
                      v-mask="'#A/####'"
                      :error-messages="$v.period.code.$dirty && $v.period.code.$invalid ?
                      ((!$v.period.code.required) ? $t('modules.academic.periods.create.errors.required')
                      : !$v.period.code.minLength ? $t('modules.academic.periods.create.errors.minLength')
                      : $t('modules.academic.periods.create.errors.codeUnique')) : '' "
                      @blur="validateCode"
                    ></v-text-field>
                  </v-col>
                  <v-col  sm="6" class="py-2">
                    <v-select
                      v-model="period.termType"
                      ref="termType"
                      :items="terms"
                      item-text="meaning"
                      return-object
                      color="primary"
                      :label="$t('modules.academic.periods.create.cards.period')"
                      outlined
                      @blur="validateTermType"
                      @change="onChange"
                      :error-messages=" $v.period.termType.$dirty && !$v.period.termType.required ? $t('modules.academic.periods.create.errors.required') : ''"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-card-text
                  class="title font-weight-bold ma-0 mt-2 px-0 pt-5"
                >{{$t('modules.academic.periods.create.cards.date')}}</v-card-text>
                <template>
                  <v-row>
                    <v-col cols="10" sm="4" md="4">
                      <v-menu
                        ref="dateFromMenu"
                        v-model="dateFromMenu"
                        :close-on-content-click="false"
                        :return-value.sync="period.classStartDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="formatDateToCurrent(period.classStartDate)"
                            :label="`${$t('modules.academic.periods.create.cards.from')} *`"
                            readonly
                            v-on="on"
                            outlined
                            append-icon="mdi-calendar"
                            :error-messages="$v.period.classStartDate.$dirty && !$v.period.classStartDate.datePairValid ? $t('modules.academic.periods.create.errors.datePair') : ''"
                            @blur="onBlur('classStartDate')"
                          ></v-text-field>
                        </template>

                        <v-date-picker v-model="period.classStartDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="onCancelDatePickerClick('dateFromMenu')"
                          >{{ $t('actions.cancel') }}</v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="onOkDatePickerClick('dateFromMenu', period.classStartDate)"
                          >OK</v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="10" sm="4" md="4">
                      <v-menu
                        ref="dateToMenu"
                        v-model="dateToMenu"
                        :close-on-content-click="false"
                        :return-value.sync="period.classEndDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="formatDateToCurrent(period.classEndDate)"
                            :label="`${$t('modules.academic.periods.create.cards.to')} *`"
                            append-icon="mdi-calendar"
                            readonly
                            v-on="on"
                            outlined
                            :error-messages="$v.period.classEndDate.$dirty && !$v.period.classEndDate.datePairValid ? $t('modules.academic.periods.create.errors.datePair') : ''"
                            @blur="onBlur('classEndDate')"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="period.classEndDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="onCancelDatePickerClick('dateToMenu')"
                          >{{ $t('actions.cancel') }}</v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="onOkDatePickerClick('dateToMenu', period.classEndDate)"
                          >OK</v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </template>
                <div v-if="period.termType">
                  <v-divider class="mb-4"></v-divider>
                  <v-card-text
                    class="title font-weight-bold ma-0 px-0 pt-2"
                  >{{ $t('modules.academic.periods.create.cards.enable') }}</v-card-text>
                  <v-card-text
                    class="grey--text body-2 ma-0 px-0 pt-2"
                  >{{ $t('modules.academic.periods.create.cards.subtitle') }}</v-card-text>

                  <template v-for="(qualification, indexQualification) in qualificationsCurrent">
                    <v-row :key="qualification.refDateFrom">
                      <v-col cols="12" sm="8" md="4">
                        <v-checkbox
                          v-model="qualification.isChecked"
                          @change="onQualificationCheckboxClick($event, qualification.identifier)"
                          color="primary"
                          :label="$t(qualification.translation)"
                          class="mr-8 mt-0 no-message"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="2" md="4">
                        <v-menu
                          :ref="qualification.refDateFrom"
                          v-model="qualification.dateFromMenu"
                          :close-on-content-click="false"
                          :return-value.sync="period[qualification.dateFromFieldName]"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :label="$t('modules.academic.periods.create.cards.from')"
                              readonly
                              :value="formatDateToCurrent(period[qualification.dateFromFieldName])"
                              v-on="on"
                              outlined
                              class="v-input__slot"
                              v-show="qualification.isChecked"
                              :error-messages="$v.period[qualification.dateFromFieldName].$dirty && $v.period[qualification.dateFromFieldName].$invalid ?
                              ((!$v.period[qualification.dateFromFieldName].required) ? $t('modules.academic.periods.create.errors.required') : $t('modules.academic.periods.create.errors.datePair')) : ''"
                              @blur="onBlur(qualification.dateFromFieldName)"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="period[qualification.dateFromFieldName]"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="onQualificationCancelDatePickerClick(indexQualification, 'dateFromMenu')"
                            >Cancel</v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="onOkDatePickerClick(qualification.refDateFrom, period[qualification.dateFromFieldName])"
                            >OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="2" md="4">
                        <v-menu
                          :ref="qualification.refDateTo"
                          v-model="qualification.dateToMenu"
                          :close-on-content-click="false"
                          :return-value.sync="period[qualification.dateToFieldName]"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formatDateToCurrent(period[qualification.dateToFieldName])"
                              :label="$t('modules.academic.periods.create.cards.to')"
                              readonly
                              v-on="on"
                              outlined
                              class="v-input__slot"
                              v-show="qualification.isChecked"
                              :error-messages="$v.period[qualification.dateToFieldName].$dirty && $v.period[qualification.dateToFieldName].$invalid ?
                              ((!$v.period[qualification.dateToFieldName].required) ? $t('modules.academic.periods.create.errors.required') :
                              $t('modules.academic.periods.create.errors.datePair')) : ''"
                              @blur="onBlur(qualification.dateToFieldName)"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="period[qualification.dateToFieldName]"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="onQualificationCancelDatePickerClick(indexQualification, 'dateToMenu')"
                            >Cancel</v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="onOkDatePickerClick(qualification.refDateTo, period[qualification.dateToFieldName])"
                            >OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </template>
                </div>


              </OutlineCard>
              <OutlineCard
                :title="$t('modules.academic.periods.create.cards.modality')"
                :subtitle="$t('modules.academic.periods.create.cards.subtitle2')"
                class="mb-6"
              >
                <div class="d-flex">
                  <v-checkbox
                    v-for="modality in modalityTypes"
                    :key="modality.value"
                    v-model="period.modalityType"
                    ref="modalityType"
                    :value="modality.value"
                    color="primary"
                    :label="modality.meaning"
                    class="mr-8 mt-0 no-message"
                    :error-messages="$v.period.modalityType.$dirty && $v.period.modalityType.$anyError ?
                    $t('modules.academic.periods.update.errors.required') : ''"
                  ></v-checkbox>
                </div>
              </OutlineCard>
              <div v-if="isCursando">
                <OutlineCard
                  :title="$t('modules.academic.periods.create.cards.product')"
                  :subtitle="$t('modules.academic.periods.create.cards.subtitle3')"
                  class="mb-6"
                >
                  <TransferList
                    :avatar="false"
                    :loading="loadingSearchProducts"
                    :availableItems.sync="products"
                    :addedItems.sync="$v.period.selectedProducts.$model"
                    areEquals="id"
                    @searchItemLeftOnBackend="searchProducts"
                    @searchItemRightOnBackend="searchProductsAdded"
                    :firstListTitle="$t('modules.academic.periods.create.transferList.firstTitle')"
                    :secondListTitle="$t('modules.academic.periods.create.transferList.secondTitle')"
                    ref="selectedProducts"
                  ></TransferList>
                  <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-if="$v.period.selectedProducts.$dirty && $v.period.selectedProducts.$anyError"
                >{{ $t('modules.academic.periods.create.errors.requiredTransferList') }}</v-alert>
                </OutlineCard>
              </div>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="goBack"
                  @end="resetButtonValues"
                  class="mr-4"
                ></Button>
                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :disabled="!canCreate"
                  :text="$t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  @end="resetButtonValues"
                  @clicked="createPeriod"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      ></SuccessMessage>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import Button from '@/components/Button/Button';
import { $types, $terms, $products } from '../services';
import { required, minLength, helpers, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import moment from 'moment'

const datePairValid = (fieldNameStart, fieldNameEnd) => (value, vm) => !helpers.req(value) || moment(vm[fieldNameStart]).isBefore(moment(vm[fieldNameEnd]))

export default {
  name: 'PeriodsCreate',
  components: {
    Breadcrumbs,
    OutlineCard,
    TransferList,
    SuccessMessage,
    Button
  },
  props: {
    insideDrawer: Boolean
  },
  computed: {
    formattedPeriodStatus () {
      return this.period.status ? this.$t('modules.academic.periods.create.cards.switch.enable') : this.$t('modules.academic.periods.create.cards.switch.disable')
    },
    isCursando(){
      return this.period.termType?.meaning === 'Cursado'
    },
    isPractice(){
      return this.period.termType?.meaning === 'Practicas Profesionales'
    },
    canCreate () {
      return (
        this.$permissions.portaladministrativo.academic.periods.CREATE
      )
    },
    ...mapGetters({
      configurationValues: 'settings/getAllCurrentValues',
      configurationOptions: 'settings/getAllOptions',
      userId: 'commons/getUserID'
    })
  },
  data () {
    return {
      loadingCode: false,
      terms:[],
      paginationLimit: 150,
      loadingSearchProducts: false,
      errors: {
        required: 'El campo es obligatorio',
        datePair: 'Fecha inválida'
      },
      systemConfig : null,
      saving: false,
      periodError: '',
      period: {
        termType:null,
        description: '',
        code: '',
        studyType: null,
        selectedProducts: [],
        classStartDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        classEndDate: moment().format('YYYY-MM-DD'),
        modalityType: [],
        saleDateFrom: null,
        saleDateTo: null,
        saleRiDateFrom: null,
        saleRiDateTo: null,
        scholarshipDateFrom: null,
        scholarshipDateTo: null,
        refundDateFrom: null,
        refundDateTo: null,
        refundForceDateFrom: null,
        refundForceDateTo: null,
        repaymentRiDateFrom: null,
        repaymentRiDateTo: null,
        disenrollmentDateFrom: null,
        disenrollmentDateTo: null,
        changeDateFrom: null,
        changeDateTo: null,
        sectionDateFrom: null,
        sectionDateTo: null,
        penaltyRaicFrom: null,
        penaltyRaicTo: null,
        status: true,
        billingProducts: [],
        translationDesinscription: null,
      },
      successMessage: {
        type: 'success',
        title: this.$t('modules.academic.periods.create.success_message.title'),
        actionPrimary: { text: this.$t('modules.academic.periods.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/academic/periods') } },
        actionSecondary: {          text: this.$t('modules.academic.periods.create.success_message.actions.secondary_text'), callback: () => {
            this.createNewDiscountStatus = false
          }        }
      },
      periodValidationLoading: false,
      qualifications: [
        {
          identifier: 'Venta Ingresante',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'saleDateFromMenu',
          dateFromFieldName: 'saleDateFrom',
          dateToMenu: false,
          refDateTo: 'saleDateToMenu',
          dateToFieldName: 'saleDateTo',
          translation: 'modules.academic.periods.create.cards.incomingsale'
        },
        {
          identifier: 'Venta Re Ingresante',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'saleRiDateFromMenu',
          dateFromFieldName: 'saleRiDateFrom',
          dateToMenu: false,
          refDateTo: 'saleRiDateToMenu',
          dateToFieldName: 'saleRiDateTo',
          translation: 'modules.academic.periods.create.cards.resale'
        },
        {
          identifier: 'Aplicacion de beca',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'scholarshipDateFromMenu',
          dateFromFieldName: 'scholarshipDateFrom',
          dateToMenu: false,
          refDateTo: 'scholarshipDateToMenu',
          dateToFieldName: 'scholarshipDateTo',
          translation: 'modules.academic.periods.create.cards.application'
        },
        {
          identifier: 'Reembolso sin motivo',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'refundDateFromMenu',
          dateFromFieldName: 'refundDateFrom',
          dateToMenu: false,
          refDateTo: 'refundDateToMenu',
          dateToFieldName: 'refundDateTo',
          translation: 'modules.academic.periods.create.cards.refund'
        },
        {
          identifier: 'Reembolso fuerza mayor',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'refundForceDateFromMenu',
          dateFromFieldName: 'refundForceDateFrom',
          dateToMenu: false,
          refDateTo: 'refundForceDateToMenu',
          dateToFieldName: 'refundForceDateTo',
          translation: 'modules.academic.periods.create.cards.refund2'
        },
        {
          identifier: 'Devolucion reingresantes',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'repaymentRiDateFromMenu',
          dateFromFieldName: 'repaymentRiDateFrom',
          dateToMenu: false,
          refDateTo: 'repaymentRiDateToMenu',
          dateToFieldName: 'repaymentRiDateTo',
          translation: 'modules.academic.periods.create.cards.refund3'
        },
        {
          identifier: 'Desinscripcion a materias',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'disenrollmentDateFromMenu',
          dateFromFieldName: 'disenrollmentDateFrom',
          dateToMenu: false,
          refDateTo: 'disenrollmentDateToMenu',
          dateToFieldName: 'disenrollmentDateTo',
          translation: ''
        },
        {
          identifier: 'Pases',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'changeDateFromMenu',
          dateFromFieldName: 'changeDateFrom',
          dateToMenu: false,
          refDateTo: 'changeDateToMenu',
          dateToFieldName: 'changeDateTo',
          translation: 'modules.academic.periods.create.cards.pases'
        },
        {
          identifier: 'Catedra',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'sectionDateFromMenu',
          dateFromFieldName: 'sectionDateFrom',
          dateToMenu: false,
          refDateTo: 'sectionDateToMenu',
          dateToFieldName: 'sectionDateTo',
          translation: 'modules.academic.periods.create.cards.cathedra'
        },
      ],
      qualificationsCurrent: [],
      codeUnique: null,
      createNewDiscountStatus: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      dateFromMenu: false,
      dateToMenu: false,
      modalityTypes: [],
      products: [],
      rawProducts: [],
      // mockup
      radios: '1',
      input: 400,
      groups: [
        {
          'product': [1, 2, 3],
          'zone': [1, 2, 3],
          'from': '',
          'discountType': [1, 2, 3],
          'semester': '',
          'bimester': '',
          menu: false, //mockup
          date: new Date().toISOString().substr(0, 10), // mockup
        }
      ]
      // end mockup
    }
  },
  validations: {
    period: {
      termType:{
        required
      },
      description: {
        required
      },
      code: {
        required,
        minLength: minLength(7),
        unique() {
        return this.codeUnique != false;
        },
      },
      classStartDate: {
        required,
        datePairValid: datePairValid('classStartDate', 'classEndDate')
      },
      classEndDate: {
        required,
        datePairValid: datePairValid('classStartDate', 'classEndDate')
      },
      modalityType: {
        required,
        minLength: minLength(1)
      },
      saleDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Venta Ingresante')?.isChecked || value.saleDateFrom
        }),
        datePairValid: datePairValid('saleDateFrom', 'saleDateTo')
      },
      saleDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Venta Ingresante')?.isChecked || value.saleDateTo
        }),
        datePairValid: datePairValid('saleDateFrom', 'saleDateTo')
      },
      saleRiDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Venta Re Ingresante')?.isChecked || value.saleRiDateFrom
        }),
        datePairValid: datePairValid('saleRiDateFrom', 'saleRiDateTo')
      },
      saleRiDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Venta Re Ingresante')?.isChecked || value.saleRiDateTo
        }),
        datePairValid: datePairValid('saleRiDateFrom', 'saleRiDateTo')
      },
      scholarshipDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Aplicacion de beca')?.isChecked || value.scholarshipDateFrom
        }),
        datePairValid: datePairValid('scholarshipDateFrom', 'scholarshipDateTo')
      },
      scholarshipDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Aplicacion de beca')?.isChecked || value.scholarshipDateTo
        }),
        datePairValid: datePairValid('scholarshipDateFrom', 'scholarshipDateTo')
      },
      refundDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Reembolso sin motivo')?.isChecked || value.refundDateFrom
        }),
        datePairValid: datePairValid('refundDateFrom', 'refundDateTo')
      },
      refundDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Reembolso sin motivo')?.isChecked || value.refundDateTo
        }),
        datePairValid: datePairValid('refundDateFrom', 'refundDateTo')
      },
      refundForceDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Reembolso fuerza mayor')?.isChecked || value.refundForceDateFrom
        }),
        datePairValid: datePairValid('refundForceDateFrom', 'refundForceDateTo')
      },
      refundForceDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Reembolso fuerza mayor')?.isChecked || value.refundForceDateTo
        }),
        datePairValid: datePairValid('refundForceDateFrom', 'refundForceDateTo')
      },
      repaymentRiDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Devolucion reingresantes')?.isChecked || value.repaymentRiDateFrom
        }),
        datePairValid: datePairValid('repaymentRiDateFrom', 'repaymentRiDateTo')
      },
      repaymentRiDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Devolucion reingresantes')?.isChecked || value.repaymentRiDateTo
        }),
        datePairValid: datePairValid('repaymentRiDateFrom', 'repaymentRiDateTo')
      },
      disenrollmentDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Desinscripcion a materias')?.isChecked || value.disenrollmentDateFrom
        }),
        datePairValid: datePairValid('disenrollmentDateFrom', 'disenrollmentDateTo')
      },
      disenrollmentDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Desinscripcion a materias')?.isChecked || value.disenrollmentDateTo
        }),
        datePairValid: datePairValid('disenrollmentDateFrom', 'disenrollmentDateTo')
      },
      changeDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Pases')?.isChecked || value.changeDateFrom
        }),
        datePairValid: datePairValid('changeDateFrom', 'changeDateTo')
      },
      changeDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Pases')?.isChecked || value.changeDateTo
        }),
        datePairValid: datePairValid('changeDateFrom', 'changeDateTo')
      },
      sectionDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Catedra')?.isChecked || value.sectionDateFrom
        }),
        datePairValid: datePairValid('sectionDateFrom', 'sectionDateTo')
      },
      sectionDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Catedra')?.isChecked || value.sectionDateTo
        }),
        datePairValid: datePairValid('sectionDateFrom', 'sectionDateTo')
      },
      selectedProducts: {
        requiredSelectProducts(value) {
          return !this.isCursando || value.length
        }
      },
      penaltyRaicFrom: {
        datePairValid: datePairValid('penaltyRaicFrom', 'penaltyRaicTo')
      },
      penaltyRaicTo: {
        datePairValid: datePairValid('penaltyRaicFrom', 'penaltyRaicTo')
      },
      preSaleDateFrom: {
        datePairValid: datePairValid('preSaleDateFrom', 'preSaleDateTo')
      },
      preSaleDateTo: {
        datePairValid: datePairValid('preSaleDateFrom', 'preSaleDateTo')
      },
      status: {
        required
      },
    }
  },
  methods: {
    validateTermType(){
      this.onBlur('termType')
      this.validateCode()
    },
    async validateCode() {
      this.$v.period.code.$touch();
      if (this.$v.period.code.$model && this.$v.period.termType.$model) {
        this.codeUnique = null;
        this.loadingCode = true;
        try {
          const response = await $terms.find('exists', null, {params: {code: this.$v.period.code.$model, termType: this.$v.period.termType.$model.value}});
          this.codeUnique = !response.data.exists;
          this.loadingCode = false;
        } catch (error) {
          this.codeUnique = null;
          throw error;
        } finally {
          this.$v.period.code.$touch();
        }
      }
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    formatDateToCurrent (date) {
      return date ?
      // moment(date).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) 
      moment(date).format( 'DD/MM/YYYY' )
      : null
    },
    onQualificationCheckboxClick (event, identifier) {
      const selectedQualification = this.qualifications.find(qualification => qualification.identifier === identifier)
      this.$v.period[selectedQualification.dateFromFieldName].$reset()
      this.$v.period[selectedQualification.dateToFieldName].$reset()
      selectedQualification.isChecked = event
    },
    setQualificationOnPeriod (field1, value1, field2, value2) {
      this.period[field1] = value1
      this.period[field2] = value2
    },
    onCancelDatePickerClick (model) {
      this[model] = false
    },
    onQualificationCancelDatePickerClick (index, field) {
      this.qualifications[index][field] = false
    },

    validateSetting(){
      const preSale =
        {
          identifier: 'Pre Venta',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'fromPreSaleMenu',
          dateFromFieldName: 'preSaleDateFrom',
          dateToMenu: false,
          refDateTo: 'preSaleMenu',
          dateToFieldName: 'preSaleDateTo',
          translation: 'modules.academic.periods.create.cards.preSale'
        } 
      const raic =
      {
          identifier: 'Multa raic',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'fromRaicMenu',
          dateFromFieldName: 'penaltyRaicFrom',
          dateToMenu: false,
          refDateTo: 'foRaicMenu',
          dateToFieldName: 'penaltyRaicTo',
          translation: 'modules.academic.periods.create.cards.raic'
        }
        
        if(this.$country === 'chile'){
          this.qualifications.push(raic)
        }
        if(this.systemConfig.preSale){
          this.qualifications.unshift(preSale)
        }
    },

    // setQualifications () {
    //   const dateFrom = moment(this.period.classEndDate).subtract(15, 'days').format('YYYY-MM-DD')
    //   const dateTo = moment(this.period.classEndDate).add(15, 'days').format('YYYY-MM-DD')
    //   this.qualifications.forEach(qual => { qual.isChecked = true })
    //   this.period.saleDateFrom = dateFrom
    //   this.period.saleDateTo = dateTo
    //   this.period.saleRiDateFrom = dateFrom
    //   this.period.saleRiDateTo = dateTo
    //   this.period.scholarshipDateFrom = dateFrom
    //   this.period.scholarshipDateTo = dateTo
    //   this.period.refundDateFrom = dateFrom
    //   this.period.refundDateTo = dateTo
    //   this.period.refundForceDateFrom = dateFrom
    //   this.period.refundForceDateTo = dateTo
    //   this.period.repaymentRiDateFrom = dateFrom
    //   this.period.repaymentRiDateTo = dateTo
    //   this.period.disenrollmentDateFrom = dateFrom
    //   this.period.disenrollmentDateTo = dateTo
    //   this.period.changeDateFrom = dateFrom
    //   this.period.changeDateTo = dateTo
    //   this.period.sectionDateFrom = dateFrom
    //   this.period.sectionDateTo = dateTo
    // },
    onOkDatePickerClick (ref, date) {
      if (ref === 'dateToMenu') {
        this.$refs[ref].save(date)
      } else if (ref === 'dateFromMenu') {
        this.$refs[ref].save(date)
        //this.setQualifications()
      } else {
        this.$refs[ref][0].save(date)
      }
    },
    goBack () {
      if (window.history.length > 1) {
        const getCurrentRoute = this.$route.matched[this.$route.matched.length - 1]
        if (getCurrentRoute.meta.name !== 'Index') {
          this.$router.go(-1)
        } else {
          this.$router.push('/')
        }
      }
    },
    removeGroup (index) {
      this.groups.splice(index, 1)
    },
    addGroup () {
      this.groups.push({
        check: false,
      })
    },
    formatCodeMask (code) {
      return code.split('/').join('')
    },
    async createPeriod () {
      const periodToCreate = { ...this.period }
      periodToCreate.billingProducts = []
      periodToCreate.billingProducts = this.period.selectedProducts.map(item => ({ id: item.id }))
      if (!periodToCreate.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.academic.periods.create.warning_popup.title'),
          content: this.$t('modules.academic.periods.create.warning_popup.content'),
          actionPrimary: {
            text: this.$t('modules.academic.periods.create.buttons.create'),
            callback: async () => {
              await this.triggerPeriodCreation(periodToCreate)
            }
          },
          actionSecondary: {
            text: this.$t('modules.academic.periods.create.buttons.cancel'),
            callback: () => {
              this.resetButtonValues()
            }
          },
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary',
        })
      } else {
        await this.triggerPeriodCreation(periodToCreate)
      }
    },
    async triggerPeriodCreation (periodToCreate) {
      if (this.$v.period.$invalid || !this.codeUnique) {
          this.$v.period.$touch();
          if(!this.codeUnique) {
            this.$refs.code.$el.scrollIntoView({block: 'center', behavior: 'smooth'})
          } else {
            Object.keys(this.$v.period).some(input => {
              if (input.includes('$')) return false;
              if (this.$v.period[input].$error) {
                  this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
                return true
              }
            })
          }
          this.createButton.error = true;
          this.createButton.loading = false;
        } else{ if (!this.canCreate) return false
      this.createButton.loading = true;
      this.qualifications.forEach(qualification => {
        if (!qualification.isChecked) {
          periodToCreate[qualification.dateFromFieldName] = null
          periodToCreate[qualification.dateToFieldName] = null
        }
      })
      periodToCreate.modalityType = periodToCreate.modalityType.map(modality => ({ value: modality }))
      const periodsToCreate = []
      periodToCreate.modalityType.forEach(async (modality) => {
        const periodWithSingleModality = { ...periodToCreate, ...{ modalityType: modality }, userId: this.userId }
        periodsToCreate.push(periodWithSingleModality)
      })
      try {
        await $terms.create(periodsToCreate)
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.academic.periods.create.success_message.title');
        if (!this.insideDrawer) {
          this.successMessage.actionPrimary = { text: this.$t('modules.academic.periods.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/academic/periods') } };
          this.successMessage.actionSecondary = { text: this.$t('modules.academic.periods.create.success_message.actions.secondary_text'), callback: () => {
              this.period = {
                description: '',
                code: '',
                studyType: null,
                classStartDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                classEndDate: moment().format('YYYY-MM-DD'),
                modalityType: [],
                status: true,
                billingProducts: [],
                termType: []
              }
              //this.setQualifications(this.classEndDate)
              this.period.selectedProducts = []
              this.$v.$reset()
              this.createNewDiscountStatus = false
            }          };
        } else {
          this.successMessage.actionPrimary = { text: this.$t('modules.academic.periods.create.success_message.actions.primary_text'), callback: () => { this.$emit('closeDrawer'); } }
          this.successMessage.actionSecondary = null;
        }
        this.createNewDiscountStatus = true;
        this.createButton.success = true;
      } catch (err) {
        this.successMessage.type = 'error';
        this.successMessage.title = err.codeMeaning
        this.successMessage.actionSecondary = null;
        this.createNewDiscountStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        if (!this.insideDrawer) {
          this.successMessage.actionPrimary = {            text: this.$t('modules.academic.periods.create.buttons.back'), callback: () => {
              this.createNewDiscountStatus = false
            }          }
        } else {
          this.successMessage.actionPrimary = { text: this.$t('modules.academic.periods.create.success_message.actions.primary_text'), callback: () => { this.$emit('closeDrawer'); } }
        }
        this.products = this.rawProducts.filter(product => {
          return !this.period.selectedProducts.find(selectedProduct => selectedProduct.id === product.id)
        })
      } finally {
        this.createNewScholarship = true;
        this.createButton.loading = false;
        this.createButton.error = true;
      }
    }
    },
    onSwitchChange () {
      this.period.status = !this.period.status
    },
    async onBlur (field) {
      this.$v.period[field].$touch()
    },
    traductionDesincripcion(value){
      this.translationDesinscription = value === 281 || value === 193
      ? 'modules.academic.periods.create.cards.unsuscribe'
      : 'modules.academic.periods.create.cards.suscribe';
      const disenrollmentObject = this.qualifications.find(q => q.identifier === 'Desinscripcion a materias');
      if (disenrollmentObject) {
        disenrollmentObject.translation = this.translationDesinscription
      }

    },
    async onChange (field) {
      this.traductionDesincripcion(field.value)
      if(field.meaning === 'Cursado' ){
        this.qualificationsCurrent = this.qualifications
      }
      //practica
      else if(field.meaning === 'Practicas Profesionales' ){
        this.qualificationsCurrent = this.qualifications.filter(qualification => qualification.identifier === 'Desinscripcion a materias' || qualification.identifier === 'Catedra' )
      }
      //OnBoarding, Nivelacion.
      else{
        this.qualificationsCurrent = this.qualifications.filter(qualification => qualification.identifier === 'Desinscripcion a materias' || qualification.identifier === 'Catedra' )
      }
    },
    searchProducts (event) {
      this.getProducts(event);
    },
    searchProductsAdded (event) {
      this.period.selectedProducts = event
    },
    async getProducts (product) {
      this.loadingSearchProducts = true;
      let products = [];
      if (product) {
        products = await $products.find(null, null, {
          params: { name: product, length: this.paginationLimit }
        });
      } else {
        products = await $products.find(null, null, {
          params: { length: this.paginationLimit }
        });
      }
      if (products.data && products.data.content.length) {
        products = products.data.content.map(item => {
          return {
            id: item.id,
            name: item.name
          };
        });
        this.rawProducts = products
        this.products = products;
      }

      this.loadingSearchProducts = false;
    },
    async fetchTerms(){
      const terms = await  $types.find(null, null, { params: { type: 'PERIOD_TYPE' } })
      this.terms = terms.data
    }
  },
  async mounted () {
    // this.setQualifications(this.classEndDate)
    const fetchedModalityTypes = await $types.find(null, null, { params: { type: 'MODALITY_TYPE' } })
    this.modalityTypes = fetchedModalityTypes.data
    this.getProducts()
    this.fetchTerms()
    this.systemConfig = (await this.$getSystemConfig())?.data
    this.validateSetting()
    
    // modules.academic.periods.create.cards.unsuscribe
  }
}
</script>
