<template>
  <div>
    <v-container fluid class="d-flex pa-0 pb-8 fullHeight">
      <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom" v-if="!createNewSectionStatus">
        <template v-if="!insideDrawer">
          <Breadcrumbs
            :title="$t('modules.sections.create.breadcrumbs.title')"
            :description="$t('modules.sections.create.breadcrumbs.description')"
          ></Breadcrumbs>
        </template>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlineCard
                :title="$t('modules.sections.create.title')"
                :subtitle="$t('modules.sections.create.subtitle')"
                :switchValue="status"
                @switchChange="val => status = val"
                :switch-label="status? $t('modules.sections.create.labels.enabled') : $t('modules.sections.create.labels.disabled')"
                switcher
                class="mb-6"
              >
                <v-row>
                  <v-col sm="6" class="py-2">
                    <v-text-field
                      :error-messages="($v.description.$dirty && $v.description.$invalid) ? $t('modules.sections.validation_errors.name') : ''"
                      @blur="$v.description.$touch()"
                      v-model="$v.description.$model"
                      :label="`${$t('modules.sections.create.labels.name')} *`"
                      outlined
                      :counter="75"
                      @keypress="$validateAlphanumeric($event, $v.description.$model,40, true, true)"
                      ref="description"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="3" class="py-2">
                    <v-select
                      :error-messages="($v.selectedModalityType.$dirty && $v.selectedModalityType.$invalid) ? $t('modules.sections.validation_errors.modality') : ''"
                      :label="`${$t('modules.sections.create.labels.modality')} *`"
                      :items="modalityTypes"
                      item-text="meaning"
                      @blur="$v.selectedModalityType.$touch()"
                      outlined
                      return-object
                      v-model="$v.selectedModalityType.$model"
                      ref="selectedModalityType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-2">
                    <v-autocomplete
                      :error-messages="($v.selectedTerm.$dirty && $v.selectedTerm.$invalid) ? $t('modules.sections.validation_errors.period') : ''"
                      :label="`${$t('modules.sections.create.labels.term')} *`"
                      :items="terms"
                      item-text="description"
                      @blur="$v.selectedTerm.$touch()"
                      outlined
                      return-object
                      v-model="$v.selectedTerm.$model"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="3" class="py-2">
                    <v-select
                      :error-messages="($v.selectedLmsType.$dirty && $v.selectedLmsType.$invalid) ? $t('modules.sections.validation_errors.lms') : ''"
                      :label="`${$t('modules.sections.create.labels.lms_type')} *`"
                      :items="lmsTypes"
                      item-text="meaning"
                      @blur="$v.selectedLmsType.$touch()"
                      outlined
                      return-object
                      v-model="$v.selectedLmsType.$model"
                      ref="selectedLmsType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-2">
                    <v-select
                      :label="`${$t('modules.sections.create.labels.department')} *`"
                      :items="departments"
                      item-text="name"
                      @change="filterSubject"
                      outlined
                      return-object
                      class="no-message"
                      v-model="selectedDepartment"
                    ></v-select>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <v-select
                      :error-messages="($v.selectedSubject.$dirty && $v.selectedSubject.$invalid) ? $t('modules.sections.validation_errors.subject') : ''"
                      :label="`${$t('modules.sections.create.labels.subject')} *`"
                      :items="subjects"
                      item-text="description"
                      item-value="id"
                      outlined
                      return-object
                      @blur="$v.selectedSubject.$touch()"
                      v-model="$v.selectedSubject.$model"
                      ref="selectedSubject"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-2">
                    <v-text-field
                      :error-messages="($v.size.$invalid && $v.size.$dirty) ? 
                      ($v.size.maxValue ? $t('modules.sections.validation_errors.size') :
                      $t('modules.sections.validation_errors.limitSize')) : ''"
                      :label="`${$t('modules.sections.create.labels.size')} *`"
                      outlined
                      min="0"
                      max="32767"
                      v-model.number="$v.size.$model"
                      @keypress="$validateIntegerInputNumber($event, $v.size.$model, null, 5)"
                      type="number"
                      @blur="$v.size.$touch()"
                      ref="size"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <v-select
                      :error-messages="($v.selectedTeacher.$dirty && $v.selectedTeacher.$invalid) ? $t('modules.sections.validation_errors.teacher') : ''"
                      :label="`${$t('modules.sections.create.labels.teacher')} *`"
                      :items="teachers"
                      item-text="name"
                      @blur="$v.selectedTeacher.$touch()"
                      outlined
                      return-object
                      v-model="$v.selectedTeacher.$model"
                      ref="selectedTeacher"
                    ></v-select>
                  </v-col>
                </v-row>
              </OutlineCard>
              <OutlineCard
                :title="$t('modules.sections.create.tutors.title')"
                :subtitle="$t('modules.sections.create.tutors.subtitle')"
                class="mb-6"
              >
                <TransferList
                  ref="tutorsAdded"
                  :avatar="false"
                  :loading="loadingSearchTutors"
                  :firstListTitle="$t('modules.sections.create.tutors.transferList.firstTitle')"
                  :secondListTitle="$t('modules.sections.create.tutors.transferList.secondTitle')"
                  :availableItems.sync="tutors"
                  :added-items.sync="tutorsAdded"
                  areEquals="id"
                ></TransferList>
              </OutlineCard>
              <OutlineCard
                :title="$t('modules.sections.create.review.title')"
                :subtitle="$t('modules.sections.create.review.subtitle')"
                class="mb-6"
              ><br>
                <TransferList
                  ref="reviewersAdded"
                  :avatar="false"
                  :loading="loadingSearchReviewers"
                  :firstListTitle="$t('modules.sections.create.review.transferList.firstTitle')"
                  :secondListTitle="$t('modules.sections.create.review.transferList.secondTitle')"
                  :availableItems.sync="reviewers"
                  :added-items.sync="reviewersAdded"
                  areEquals="id"
                ></TransferList>
              </OutlineCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/sections')"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  @end="resetButtonValues()"
                  @clicked="checkStatus()"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $terms, $departments, $types, $subjects, $sections } from '../Services';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import TransferList from '@/components/TransferList/TransferList';
import { mapGetters } from 'vuex';

export default {
  name: 'SectionsCreate',
  components: {
    Breadcrumbs,
    OutlineCard,
    Button,
    SuccessMessage,
    TransferList
  },
  props: {
    insideDrawer: { type: Boolean, default: false }
  },
  data () {
    return {
      createNewSectionStatus: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      tmpDrawer: {
        open: false,
        title: this.$t('modules.sections.create.breadcrumbs.title'),
        description: this.$t('modules.sections.create.breadcrumbs.description')
      },
      successMessage: {
        type: 'success',
        title: this.$t('modules.sections.create.success_message.title'),
        actionPrimary: { text: this.$t('modules.sections.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/sections') } },
        actionSecondary: { text: this.$t('modules.sections.create.success_message.actions.secondary_text'), callback: () => { this.createNewSectionStatus = false } }
      },

      status: true,
      description: null,
      selectedModalityType: [],
      selectedTerm: [],
      selectedLmsType: [],
      selectedDepartment: [],
      selectedSubject: [],
      selectedTeacher: [],
      size: null,

      modalityTypes: [],
      terms: [],
      lmsTypes: [],
      departments: [],
      subjects: [],
      teachers: [],

      tutors: [],
      tutorsAdded: [],
      loadingSearchTutors: false,

      reviewers: [],
      reviewersAdded: [],
      loadingSearchReviewers: false,
    }
  },
  validations: {
    description: { required },
    selectedModalityType: { required },
    selectedTerm: { required },
    selectedLmsType: { required },
    selectedTeacher: { required },
    selectedSubject: { required },
    size: { required, minValue: minValue(0), maxValue: maxValue(32767)},
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.sections.CREATE
    },
    ...mapGetters({
      userId: 'commons/getUserID'
    })
  },
  methods: {
    async getTutors() {
      this.loadingSearchTutors = true;
      let tutorsFetch = [];
      try {
        tutorsFetch = await $sections.getTeachers('Tutor');
        this.tutors = tutorsFetch.data.content.map(item => {
          item.name = `${item.user.userData.name} ${item.user.userData.lastname}`;
          return item;
        });
      } catch (error) {
        this.tutors = [];
        throw error;
      } finally {
        this.loadingSearchTutors = false;
      }
    },
    async getReviewers() {
      this.loadingSearchReviewers = true;
      let reviewersFetch = [];
      try {
        reviewersFetch = await $sections.getTeachers('Corrector');
        this.reviewers = reviewersFetch.data.content.map(item => {
          item.name = `${item.user.userData.name} ${item.user.userData.lastname}`;
          return item;
        });
      } catch (error) {
        this.reviewers = [];
        throw error;
      } finally {
        this.loadingSearchReviewers = false;
      }
    },
    async getTerms() {
      try {
        const params = {page: 0, length: 20, orderBy: 'classStartDate', orientation: 'desc'}
        const terms = await $terms.find(null, null, { params });
        const cursado = [{header:this.$t('modules.sections.create.headers.header1')}, ...terms.data.content?.filter(e => e.termType.value == '193')]
        const onBording = [{ divider: true } , {header:this.$t('modules.sections.create.headers.header2')}, ...terms.data.content?.filter(e => e.termType.value == '194')]
        const nivelacion = [{ divider: true } , {header:this.$t('modules.sections.create.headers.header3')},...terms.data.content?.filter(e => e.termType.value == '195')]
        this.terms = [ ...cursado, ...onBording, ...nivelacion]
      } catch (error) {
        this.terms = [];
        throw error;
      }
    },
    tmpDrawerOpen () {
      this.tmpDrawer.open = !this.tmpDrawer.open;
    },
    checkStatus () {
      if (this.status) {
        this.createSection();
      } else {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.sections.create.warning_popup.title'),
          content: this.$t('modules.sections.create.warning_popup.content'),
          actionPrimary: {
            text: this.$t('modules.sections.create.warning_popup.create'),
            callback: this.createSection
          },
          actionSecondary: {
            text: this.$t('modules.sections.create.warning_popup.cancel'),
            callback () { }
          },
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary',
        })
      }
    },
    async createSection () {
      if (!this.canCreate) return false
      await this.$v.$touch();
      if (this.$v.$invalid) {
        Object.keys(this.$v).some(input => {
          if (input.includes('$')) return false;
          if (this.$v[input].$error) {
            this.$refs[input]?.$el.scrollIntoView({block: 'center', behavior: 'smooth'});
            return true
          }
        });
        return;
      }

      this.createButton.loading = true;

      const assignedTutors = this.tutorsAdded.map(t => {return {user: {id: t.user.id}}});

      const assignedReviewers = this.reviewersAdded.map(t => {return {user: {id: t.user.id}}});
      const assignedTeachers = this.selectedTeacher ? [{ user: { id: this.selectedTeacher.user.id } }] : [];

      const newSection = {
        status: this.status,
        name: this.description,
        modalityType: { value: this.selectedModalityType.value },
        term: { id: this.selectedTerm.id },
        lmsType: { value: this.selectedLmsType.value },
        subject: { id: this.selectedSubject.id },
        assignedTeachers,
        assignedTutors,
        assignedReviewers,
        size: this.size,
        userId: this.userId
      };

      try {
        await $sections.create(newSection);

        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.sections.create.success_message.title');
        if (!this.insideDrawer) {
          this.successMessage.actionPrimary = { text: this.$t('modules.sections.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/sections') } };
          this.successMessage.actionSecondary = { text: this.$t('modules.sections.create.success_message.actions.secondary_text'), callback: () => { this.createNewSectionStatus = false } };
        } else {
          this.successMessage.actionPrimary = { text: this.$t('modules.sections.create.success_message.actions.primary_text'), callback: () => { this.$emit('closeDrawer'); } }
          this.successMessage.actionSecondary = null;
        }
        this.createButton.success = true;
        this.clearData();

      } catch (err) {
        this.successMessage.type = 'error';
        this.successMessage.title = (err.codeMeaning == '') ? this.$t('modules.sections.create.error_message.title') :  this.$t('modules.sections.create.error_message.duplicated');
        this.successMessage.actionSecondary = null;
        this.successMessage.actionPrimary = { text: this.$t('modules.sections.create.success_message.actions.back'), callback: () => { this.createNewSectionStatus = false } }
        this.createButton.error = true;
      } finally {
        this.createNewSectionStatus = true;
        this.createButton.loading = false;
        this.resetButtonValues()
      }
    },
    clearData () {
      this.resetButtonValues();
      this.$v.$reset();
      this.status = true;
      this.description = null;
      this.selectedModalityType = [];
      this.selectedTerm = [];
      this.selectedLmsType = [];
      this.selectedDepartment = [];
      this.selectedSubject = [];
      this.selectedTeacher = [];
      this.size = null;
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    async filterSubject (department) {
      const subjects = await $subjects.filterByDepartment(department.id);
      this.subjects = subjects.data;
    },
    async fetchData () {
      const modalityTypes = await $types.find(null, null, { params: { type: 'MODALITY_TYPE' } });
      this.modalityTypes = modalityTypes.data;


      const lmsTypes = await $types.find(null, null, { params: { type: 'LMS_TYPE' } });
      this.lmsTypes = lmsTypes.data;

      const departments = await $departments.find();
      this.departments = departments.data.content;

      const teachers = await $sections.getTeachers('Docente');
      this.teachers = teachers.data.content.map(teacher => {
        teacher.name = `${teacher.user.userData.name} ${teacher.user.userData.lastname}`
        return teacher;
      });
    },
  },
  async mounted () {
    await this.getTerms();
    await this.getTutors();
    await this.getReviewers();
    this.fetchData();
  }
}
</script>
