<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.portalalumno.procedures.retirement.title')"
        :description="!createRetirement ? $t('modules.portalalumno.procedures.retirement.description') : ''"
        isDescriptionFullLength
        :callback="goBack"
      ></Breadcrumbs>
      <v-row v-if="!createRetirement" class="pl-10 pt-2 pb-8">
          <v-icon class="pr-2 noteIcon">mdi-alert-circle-outline</v-icon>
          <h5 class="pt-1 noteText" v-text="note"></h5>
      </v-row>
      <v-row v-if="!createRetirement" justify="center">
        <v-col class="viewSpaces" cols="10">
          <OutlinedCard
            :title="$t('modules.portalalumno.procedures.retirement.dataSection.title')"
            :subtitle="$t('modules.portalalumno.procedures.retirement.dataSection.subtitle')"
            class="mb-6">
            <v-row>
              <v-col sm="5">
                <v-autocomplete
                  outlined
                  :label="$t('modules.portalalumno.procedures.retirement.dataSection.reasonType')"
                  v-model="$v.retirement.processingsReasonType.value.$model"
                  :items="reasons"
                  :error="$v.retirement.processingsReasonType.value.$anyError"
                  @blur="$v.retirement.processingsReasonType.value.$touch()"
                  item-text="meaning"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col sm="12" v-if="showField">
                <v-textarea
                  outlined
                  :error="$v.retirement.reason.$anyError"
                  :label="$t('modules.portalalumno.procedures.retirement.dataSection.reason')"
                  :placeholder="$t('modules.portalalumno.procedures.retirement.dataSection.reasonPlaceholder')"
                  v-model.trim="$v.retirement.reason.$model"
                  @blur="$v.retirement.reason.$touch()"
                  required
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </OutlinedCard>
          <div class="d-flex justify-end mb-12 py-5">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="goBack"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :disabled="!$v.retirement.processingsReasonType.value.$anyDirty || $v.retirement.processingsReasonType.value.$anyError || (showField && (!$v.retirement.reason.$anyDirty || $v.retirement.reason.$anyError))"
              :text="$t('actions.apply')"
              :successText="$t('actions.created')"
              :errorText="$t('actions.error')"
              @end="resetButtonValues"
              @clicked="confirmAction"
            ></Button>
          </div>
          <ModalRulesPopup
            v-if="confirmationPopup"
            :modalOpen="confirmationPopup"
            :title="$t('modules.portalalumno.procedures.retirement.confirmation.title')"
            :subtitle="$t('modules.portalalumno.procedures.retirement.confirmation.subtitle')"
            :rules="[
              $t('modules.portalalumno.procedures.retirement.confirmation.rule_1'),
              $t('modules.portalalumno.procedures.retirement.confirmation.rule_2'),
              $t('modules.portalalumno.procedures.retirement.confirmation.rule_3'),
              $t('modules.portalalumno.procedures.retirement.confirmation.rule_4'),
            ]"
            @cancel="cancelPopUp"
            @confirm="onRetirementSend"
          ></ModalRulesPopup>
        </v-col>
      </v-row>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :noteSuccess="$t('modules.portalalumno.procedures.alert.noteSuccess')"
      />
    </v-container>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
  import { required } from 'vuelidate/lib/validators';
  import Button from '@/components/Button/Button';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import ModalRulesPopup from '../Components/ModalRulesPopup/ModalRulesPopup';
  import { $studentProcessing, $types } from '../services';
  import { mapGetters } from 'vuex'

  export default {
    name: 'Retirement',
    components: {
      Breadcrumbs,
      OutlinedCard,
      Button,
      SuccessMessage,
      ModalRulesPopup,
    },
    data () {
      return {
        confirmationPopup: false,
        retirement: {
          reason: null,
          processingsReasonType: {
            value: null
          }
        },
        note: this.$t('modules.portalalumno.procedures.alert.note'),
        reasons: [],
        createButton: {
          loading: false,
          success: false,
          error: false
        },
        createRetirement: false,
        successMessage: {
          title: null,
          type: null,
          actionPrimary: null
        }
      }
    },
    computed: {
      showField () {
        return this.retirement.processingsReasonType.value && this.retirement.processingsReasonType.value === 108
      },
      ...mapGetters({
        studentRegister: 'commons/getStudentActualRegister',
        studentUserData: 'commons/getStudentUserData',
        studentId: 'commons/getStudentId',
        userName: 'commons/getUserName',
        studentUserId: 'commons/getStudentUserID',
      }),
    },
    mounted () {
      this.getReasonTypes()
    },
    methods: {
      aceptSucces (){
        if(this.$isIPP)this.$trackEvent('Tramites', ' Clk_BtnSolciRetCread_Tram', 'Retiro definitivo') //Google Analytics - Aceptar SuccessMessage
      },
      cancelPopUp (){
        this.confirmationPopup = false
        if(this.$isIPP)this.$trackEvent('Tramites', 'Clk_BtnBackConfSolciRet_Tram', 'Retiro definitivo') //Google Analytics - Cancelar Retiro PopUp
      },
      confirmAction (){
        this.confirmationPopup = true
        if(this.$isIPP)this.$trackEvent('Tramites', 'Clk_BtnSolciRet_Tram', 'Retiro definitivo') //Google Analytics - Solicitar Retiro  - Motivo
      },
      async onRetirementSend () {
        if(this.$isIPP)this.$trackEvent('Tramites', 'Clk_BtnConfSolciRet_Tram', 'BajaTrans'); //Google Analytics - Confirmar Retiro
        this.confirmationPopup = false
        this.createButton.loading = true
        try {
          this.$trackEvent('Tramites', 'Solicitar', 'Retiro')
          await $studentProcessing.create({...this.retirement, ...{
            student: { id: this.studentId, user: {name: this.studentUserData.email} },
            studentRecord: this.studentRegister,
            processing: { key: 'RETIREMENT' },
            userName: this.userName,
            userUpdateId: this.studentUserId ? this.studentUserId : null
          }})
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.portalalumno.procedures.success_message.title');
          this.successMessage.actionPrimary = { text: this.$t('modules.portalalumno.procedures.success_message.actions.accept'), callback: () => { this.$router.push('/procedures'), this.aceptSucces() } }
          this.createButton.success = true
        } catch (error) {
          this.successMessage.type = 'error';
          this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : 'Error';
          this.successMessage.actionPrimary = { text: this.$t('modules.portalalumno.procedures.success_message.actions.back'), callback: () => { this.createRetirement = false } }
          this.createButton.error = true
        } finally {
          this.createRetirement = true
          this.createButton.loading = false
        }
      },
      resetButtonValues () {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      goBack () {
        this.$trackEvent('Tramites', 'Clk_BtnBackSolciRet_Tram', 'Retiro definitivo')  //Google Analytics - Cancelo Retiro - Motivo
        this.$router.push('/procedures')
      },
      async getReasonTypes() {
        const resp = await $types.find(null, null, {params: { type: 'PROCESSINGS_REASONS' }})
        resp.data.sort((a, b) => (a.meaning > b.meaning) ? 1 : -1)
        this.reasons = resp.data
      },
    },
    validations: {
      retirement: {
        reason: {required},
        processingsReasonType: { value: {required} }
      }
    },
  }
</script>

<style lang="sass" scoped>
  .noteIcon
    font-size: 20px
    color: v-bind('$vuetify.theme.defaults.light.primary.accent4')
  .noteText
    color: v-bind('$vuetify.theme.defaults.light.primary.accent4')
    font-weight: 410
</style>
