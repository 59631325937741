import api from '@/api'

const { $payment, $academic, $pricing, $billing } = api;
const $st = api.$student

export const { $studentAccount, $billingData, $discount, $repayments, $students, $studentProcessing } = $st;
export const { $paymentMethod, $payments, $config, $configurations, $accounts, $html } = $payment;
export const { $productsWithPricePeriods } = $pricing;
export const { $currentPeriod } = $academic
export const { $types, $suscriptions } = $pricing
export const { $provincias, $localidades, $process } = $billing
