<template>
    <v-navigation-drawer
        v-model="drawer"
        :absolute="$isMobile"
        :temporary="$isMobile"
        :permanent="!$isMobile"
        hide-overlay
        color="primary"
        :width="$isMobile ? '100%' : '100px'"
        class="MiniNavigationDrawer"
        :style="$isMobile ? 'height: calc(100vh - 64px) !important; top: 64px !important;' : ''"
        dark
        @input="updateDrawer"
    >
        <div class="fullHeight" style="background-color: var(--v-primary-base)">
            <v-list class="pa-0 fullHeight" color="primary" dark>
                <div style="height: 0">
                    <v-list-item-group :mandatory="$route.path !== '/profile'" max="1" v-model="selected" class="fullHeight d-flex flex-column">
                        <template v-if="!$isMobile">
                            <v-menu
                                content-class="MiniNavigationDrawer"
                                v-for="(item, i) in activeModules" :key="`module-${i}`"
                                :open-on-click="false"
                                open-on-hover
                                :min-width="item.children && item.children.length ? '200px' : '0'"
                                offset-x
                                @input="setCurrentSelected"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item
                                        class="px-0"
                                        :value="i"
                                        @click="linkClicked(item)"
                                        v-bind="attrs" v-on="on"
                                        :input-value="selected === i" >

                                        <v-list-item-content class="d-flex flex-column text-center">
                                            <v-icon v-if="item.imagePath" size="20">{{ item.imagePath }}</v-icon>
                                            <v-icon v-else size="20">mdi-heart</v-icon>
                                            <h4 class="caption px-2 mt-1" style="line-height: 1.4">{{ item.name }}</h4>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                                <v-list class="py-0" dense color="primary" dark v-if="item.children && item.children.length">
                                    <v-list-item-group :mandatory="selected == i && item.childSelected !== null" max="1" v-model="item.childSelected" class="d-flex flex-column">
                                        <v-list-item
                                            v-for="(child, j) in item.children"
                                            :key="`child-${j}`"
                                            :value="j" @click="linkClicked(child)"
                                            :input-value="item.childSelected === j"
                                        >
                                            <v-list-item-icon class="mr-2">
                                                <v-icon v-if="child.imagePath" size="20">{{ child.imagePath }}</v-icon>
                                                <v-icon v-else size="20">mdi-heart</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="caption">
                                                    {{ child.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </template>

                        <template v-else v-for="(item, i) in activeModules">
                            <v-list-item
                                v-if="!item.children || !item.children.length"
                                :key="`module-${i}`"
                                :value="i"
                                @click="linkClicked(item)"
                                :input-value="selected === i"
                            >
                                <v-list-item-icon>
                                    <v-icon v-if="item.imagePath">{{ item.imagePath }}</v-icon>
                                    <v-icon v-else>mdi-heart</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title class="body-2">
                                        {{ item.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-group
                                :key="`module-${i}`"
                                v-else
                                :value="selected == i && item.childSelected !== null"
                                :prepend-icon="item.imagePath ? item.imagePath : 'mdi-heart'"
                                no-action
                            >
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title class="body-2">
                                            {{ item.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>

                                <v-list-item-group :mandatory="selected == i && item.childSelected !== null" max="1" v-model="item.childSelected">
                                    <v-list-item
                                        class="pl-16"
                                        v-for="(child, j) in item.children"
                                        :key="`child-${j}`"
                                        :value="j" @click="linkClicked(child)"
                                        :input-value="item.childSelected === j"
                                    >

                                        <v-list-item-icon class="mr-2">
                                            <v-icon v-if="child.imagePath" size="20">{{ child.imagePath }}</v-icon>
                                            <v-icon v-else size="20">mdi-heart</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="caption font-weight-light">
                                                {{ child.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list-group>
                        </template>
                    </v-list-item-group>
                </div>
            </v-list>
        </div>
    </v-navigation-drawer>
</template>

<script>
    import { mapGetters } from 'vuex'
    import ListStudents from '../../../core/constants/listStudents';
    import AuthService from '../../plugins/auth.service';
    import Configuration from '../../constants/configuration';

    export default {
        name: 'MiniNavigationDrawer',
        props: {
            drawerOpen: {type: Boolean, default: false},
        },
        data() {
            return {
                drawer: false,
                selected: null,
                modules: [],
            }
        },
        computed: {
            activeModules() {
                return this.modules.filter(module => module.status)
            },
            ...mapGetters({
                apiModules: 'commons/getApplicationModules',
                permissions: 'commons/getPermissions',
                canvasId: 'commons/getCanvasId',
                studentData: 'commons/getStudentData',
            }),
        },
        created() {
            if (this.permissions && this.permissions.length) {
                this.setCurrentSelected();
            }
        },
        methods: {
            updateDrawer(isOpen) {
                if (this.$isMobile && this.drawerOpen != isOpen) this.$emit('close', isOpen)
            },
            async linkClicked(item) {
                //if (item.path === '#') return false

                if (item.code == 'portalalumno-canvas' || item.code == 'portalalumno-edusoft' || item.code == 'portalalumno-elibro' ||
                item.code == 'dashboard' || item.code == 'portalalumno-subjects' || item.code == 'portalalumno-study-plan' || item.code == 'portalalumno-exams'
                || item.code == 'portalalumno-payments-student' || item.code == 'portalalumno-checking-account' || item.code == 'portalalumno-procedures' ||
                item.code == 'portalalumno-pp-practice' || item.code == 'portalalumno-apps' || item.code == 'portalalumno-reglamento' || item.code == 'portalalumno-reglamento-general'
                || item.code == 'portalalumno-reglamento-honestidad' || item.code == 'portalalumno-reglamento-evaluaciones' || item.code == 'portalalumno-reglamento-practicas') {
                    this.redirect(item.code, item.path)
                    this.setCurrentSelected()
                } else if (this.$route.path !== item.path && item.path !== '#') {
                    this.$router.push(item.path)
                } else if (item.path === '#') return false
            },
            orderModules() {
                const orderSubModules = (item) => {
                    const availableItems = item.filter(child => child.status)
                    availableItems.sort((itemA, itemB) => itemA.order - itemB.order)
                    return availableItems
                }
                if (this.apiModules && this.apiModules.length > 0) {
                    // SORT modules and subModules order
                    this.modules = this.apiModules.sort((itemA, itemB) => itemA.order - itemB.order);
                    this.modules.forEach(item => {
                        if (item.children) item.children = orderSubModules(item.children)
                        item.childSelected = null
                        item.menu = false
                    });
                }

                this.modules = this.modules.filter(module => module.path != '#' || module.children.length)
            },
            setCurrentSelected() {
                let moduleSelected = false
                this.modules.forEach((item, i) => {
                    if (item.children && item.children.length > 0) {
                        const subitemSelected = item.children.find(children => children.path === this.$route.path || this.$route.matched.some(route => route.path === children.path));
                        if (subitemSelected) {
                            const subIndex = item.children.indexOf(subitemSelected);
                            this.selected = i
                            item.childSelected = subIndex
                            moduleSelected = true
                        } else item.childSelected = null
                    } else {
                        if (item.path === this.$route.path || this.$route.matched.some(route => route.path === item.path)) {
                            this.selected = i;
                            moduleSelected = true
                        }
                        item.childSelected = null
                    }
                });
                if (!moduleSelected) this.selected = null
            },
            async redirect(code, path) {
                // EduSoft ---> https://ed.engdis.com/ipp
                // E-Libro ---> https://elibro.net/es/lc/ipp/inicio
                // Canvas sin ID ---> https://ipp.instructure.com/courses/4506
                // Canvas con ID ---> clickToCanvas()
                const alumnoSeleccionado = JSON.parse(
                localStorage.getItem('alumnoSeleccionado')
                );
                const dniAlumnoBloqueado = Configuration.value('app') == 'portalalumno' && AuthService.isStudent() && alumnoSeleccionado ? ListStudents.find(
                dni => dni == alumnoSeleccionado.userData.username
                ) : null;
                if (!this.studentData.student) return false

                let uri = null;

                switch(code) {
                    case 'portalalumno-edusoft':
                        uri = 'https://ed.engdis.com/ipp';
                        this.$trackEvent('Menu', 'Clk_MenLat_EdSft', 'Estudia');    //Google Analytics
                        break;
                    case 'portalalumno-elibro':
                        this.$trackEvent('Menu', 'Clk_MenLat_EBook', 'E_Libro');
                        uri = 'https://elibro.net/es/lc/ipp/inicio';
                        break;
                    case 'portalalumno-canvas':
                        if (!dniAlumnoBloqueado){
                            this.$trackEvent('Menu', 'Clk_MenLat_Cnvs', 'Estudia');
                            this.$redirectToCanvas(this.canvasId, this.studentData.student.user.name)
                        }
                        break;
                    case 'dashboard':
                        this.$trackEvent('Menu', 'Clk_MenLat_Inicio', 'Inicio');
                        this.$router.push({path: '/dashboard', query: {pp: 'true'}});
                        break;
                    case 'portalalumno-subjects':
                        this.$trackEvent('Menu', 'Clk_MenLat_InscrAsig', 'Carga_academica');
                        break;
                    case 'portalalumno-study-plan':
                        this.$trackEvent('Menu', 'Clk_MenLat_PlnStdio', 'Carga_academica');
                        break;
                    case 'portalalumno-exams':
                        this.$trackEvent('Menu', 'Clk_MenLat_InscrExam', 'Examenes');
                        break;
                    case 'portalalumno-payments-student':
                        if(this.$isIPP)this.$trackEvent('Menu', 'Clk_MenLat_PagLinea', 'Pagos');   //Google Analytics - Pagos en linea IPP
                        if(this.$isTeclab)this.$trackEvent('Menu', 'Clk_MenLat_PagLinea', 'Pago');   //Google Analytics - Pagos en linea Teclab
                        break;
                    case 'portalalumno-checking-account':
                        if(this.$isTeclab)this.$trackEvent('Menu', 'Clk_MenLat_Cuencorr', 'Pago');   //Google Analytics - Cuenta corriente Teclab
                        break;
                    case 'portalalumno-procedures':
                        this.$trackEvent('Menu', 'Clk_MenLat_Tram', 'Tramites');
                        break;
                    case 'portalalumno-pp-practice':
                        this.$trackEvent('Menu', 'Clk_MenLat_Pract', 'Practicas');
                        break;
                    case 'portalalumno-apps':
                        this.$trackEvent('Menu', 'Clk_MenLat_App', 'APP');
                        break;
                    case 'portalalumno-reglamento':
                        this.$trackEvent('Menu', 'Clk_MenLat_Reglam', 'Reglamento');
                        break;
                }
                if (uri) {
                    const win = window.open(uri, '_blank')
                    if (!win) {
                        // Mostrar mensaje de bloqueo
                    }
                }
                else if (!uri && path !== '#' && code !== 'portalalumno-canvas'){
                    if(/(general|honestidad|evaluaciones|practicas)/.test(path)) {//solves reglamento and reglamento's children navigation issues
                        this.$router.push(path)
                        this.$router.go()
                    } else {
                        if(this.$isTeclab && path == '/reglamento') path += '/general'
                        this.$router.push(path)
                    }
                }
            },
        },
        watch:{
            drawerOpen(isOpen) {
                if (this.$isMobile && isOpen != this.drawer) this.drawer = isOpen
            },
            drawer(status) {
                if (this.$isMobile && !status) this.setCurrentSelected();
            },
            $route() {
                this.setCurrentSelected();
            },
            async permissions() {
                await this.orderModules()
                this.setCurrentSelected();
            },
        },
    }
</script>

<style lang="sass">

    .MiniNavigationDrawer
        min-width: 110px
        transition-duration: 0.3s !important
        transition-timing-function: ease-out !important

        .v-list-item
            -webkit-box-shadow: inset 0px 10px 10px -8px rgb(0 0 0 / 10%)
            -moz-box-shadow: inset 0px 10px 10px -8px rgba(0, 0, 0, 0.1)
            box-shadow: inset 0px 10px 10px -8px rgb(0 0 0 / 10%)
            padding: 3px 24px
            flex: 0
            transition: background 500ms ease
            &:hover, &:active, &:focus
                background: var(--v-primary-lighten1)
            &--active
                background: var(--v-info-base) !important
                color: white !important
            &:before
                z-index: -1

        .v-list-item__content
            .v-list-item__title
                .v-icon
                    position: absolute
                    right: 6px
</style>
