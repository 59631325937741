<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
      <v-col sm="4" class="mt-4 mb-2">
        <v-text-field
          :value="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('actions.search')"
          @input="$emit('update:search', $event)"
          outlined
          class="superTable__search"
          @keyup.enter="$emit('fetchData')"
          @clear="$emit('clear')"
        ></v-text-field>
      </v-col>
      <v-col sm="2" class="mt-4 mb-2">
        <Button
          class="ml-4"
          @clicked="$emit('fetchData')"
          outlined
          depressed
          :text="$t('actions.search')"
        ></Button>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="students"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          hide-default-footer
          item-key="id"
          class="superTable__table"
          style="table-layout: fixed"
        >
          <template v-slot:item.name="{item}">
            <div class="superTable__table__item__name">
              <v-avatar color="secondary" size="32" class="mr-4">
                <span class="white&#45;&#45;text">{{item.avatar}}</span>
              </v-avatar>
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.paid="{item}">
            <v-icon size="12" :color="(item.paid) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span
          class="caption d-inline-block"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          v-model="page"
          :max="pageCount"
          outlined
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import Button from '@/components/Button/Button'
  export default {
    name: 'SuperTable',
    components:{
      Button
    },
    props: {
      students: Array,
      headers: Array,
      page: Number,
      totalElements: Number,
      itemsPerPage: Number,
      search: String,
    },
    data () {
      return {
        itemsPerPageAvailable: [20, 50, 100, 200],
      }
    },
    watch: {
      async page(val) {
        if (val > this.pageCount) this.page = this.pageCount
        if (val <= 1) this.page = 1
        await this.$emit('update:page', val)
        this.$emit('fetchData')
      },
      async itemsPerPage(val) {
        await this.$emit('update:itemsPerPage', val)
        this.$emit('fetchData')
      },
    },
    computed: {
      pageCount () {
        let counter = this.totalElements / this.itemsPerPage
        if (counter < 1) counter = 1
        return Math.ceil(counter)
      },
      itemsCount () {
        const counter = {
          totalItems: this.totalElements,
          startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
          endItem: this.page * this.itemsPerPage
        };
        if (counter.endItem > this.totalElements) {
          counter.endItem = this.totalElements
        }
        return counter
      }
    },
  };
</script>
