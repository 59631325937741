<template>
  <div>
    <v-row class="px-3" v-if="!massImportStatus">
      <v-col cols="12">
        <div class="mx-5 my-3">
          <h4 class="subtitle-1 font-weight-medium">{{ $t('modules.changeStudyPlan.table.massImportDrawer.title') }}</h4>
          <a href="/templates/template-changeStudyPlan.xlsx" download>
            <Button outlined class="mt-4 mb-8" icon="mdi-download" :text="$t('actions.download_template')"></Button>
          </a>
        </div>
        <a hidden v-show="showDownloadFile" ref="urlToDownload">descargar</a>
        <div v-show="!sendButton.loading">
          <DropFiles :key="dropFilesKey" v-model="file" class="mx-5 mt-5" extension=".xls,.xlsx" limitSizeBytes="2000000"
            type-file="excel"></DropFiles>
        </div>
        <div class="bt-6" v-show="sendButton.loading">
          <h3 class="font-weight-medium text-center mt-6 mb-6 ">{{
            $t('modules.changeStudyPlan.table.massImportDrawer.loading') }}</h3>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <Button white class="my-6" :text="$t('actions.close')" :disabled="sendButton.loading"
          @clicked="closeConfirmation"></Button>
        <Button class="mx-5 my-6" :loading="sendButton.loading" :success="sendButton.success" :error="sendButton.error"
          :text="$t('actions.load')" :successText="$t('actions.loaded')" :errorText="$t('actions.cancel')"
          @end="resetButtonValues" @clicked="confirmationPopup"></Button>
      </v-col>
    </v-row>
    <SuccessMessage v-else :type="successMessage.type" :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary" :actionSecondary="successMessage.actionSecondary">
      <template #default="{ type }">
        <div v-if="type === 'warning'" class="d-flex align-center justify-center">
          <a ref="download" hidden>
            <v-icon>mdi-download</v-icon>Descargar
          </a>
        </div>
      </template>
    </SuccessMessage>
  </div>
</template>

<script>
import DropFiles from '@/components/DropFiles/DropFiles';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $studentCareer } from '../Services';

export default {
  name: 'MassImportWorkData',
  components: {
    DropFiles,
    Button,
    SuccessMessage,
  },
  data() {
    return {
      massImportStatus: false,
      file: null,
      dropFilesKey: 0,
      sendButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: 'success',
        title: '',
        actionPrimary: { text: '', callback() { } },
        actionSecondary: { text: '', callback() { } },
      },
      showDownloadFile: false
    }
  },
  methods: {
    confirmationPopup() {
      if (!this.file) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.changeStudyPlan.table.massImportDrawer.messages.noFiles'),
          actionPrimary: { text: this.$t('actions.accept'), callback() { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.changeStudyPlan.table.massImportDrawer.messages.sendConfirmation'),
          content: [{ value: this.file.name }],
          actionPrimary: { text: this.$t('actions.send'), callback: () => this.sendFile() },
          actionSecondary: { text: this.$t('actions.cancel'), callback() { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      }
    },
    feedbackMessage({ type, error }) {
      const MESSAGES = {
        success: 'modules.changeStudyPlan.table.massImportDrawer.messages.success',
        warning: 'modules.changeStudyPlan.table.massImportDrawer.messages.warning',
        errors: {
          204: 'modules.changeStudyPlan.table.massImportDrawer.messages.errorAny',
          210: 'modules.changeStudyPlan.table.massImportDrawer.messages.errorAll'
        }
      }

      this.successMessage.type = type

      this.successMessage.actionPrimary = {
        text: this.$t('modules.changeStudyPlan.table.massImportDrawer.messages.createAgain'), callback: this.newCreate
      }
      this.successMessage.actionSecondary = {
        text: this.$t('actions.close'),
        callback: this.close
      }

      if (!error) {
        this.successMessage.title = this.$t(MESSAGES[type]);
        this.sendButton.success = true;
        return
      }

      if (!error.errorCode) {
        this.successMessage.title = this.$t('modules.changeStudyPlan.table.massImportDrawer.messages.errorOffline');
        this.sendButton.error = true;
        return
      }

      this.successMessage.title = this.$t(MESSAGES.errors[error.errorCode]);
      this.sendButton.error = true;

    },
    convertBase64ToBlob(b64Data) {
      if (b64Data.startsWith('data:')) return fetch(b64Data).then(data => data.blob())

      const MIME = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const data = ''.concat('data:', MIME, ';base64,', b64Data)
      return fetch(data).then(data => data.blob())
    },
    createDownload(file) {
      setTimeout(() => {
        const url = URL.createObjectURL(file)
        this.$refs.download.href = url
        this.$refs.download.download = 'Descargar'
        this.$refs.download.click()
        setTimeout(() => this.$refs.download.hidden = false, 500)
      }, 1000)
    },
    async sendFile() {
      try {
        this.sendButton.loading = true;
        const formData = new FormData()
        formData.append('file', this.file);
        const response = await $studentCareer.formData(formData, 'batch')
        const { body } = await response.json()
        let file

        try {
          file = await this.convertBase64ToBlob(body?.data)
          // TODO: manejar excepcion
          // eslint-disable-next-line no-empty
        } catch {
        }

        this.createDownload(file)
        this.feedbackMessage({ type: 'warning' })
      } catch (error) {
        this.feedbackMessage({ type: 'error', error })
        throw error
      } finally {
        this.massImportStatus = true;
        this.sendButton.loading = false;
      }
    },
    resetButtonValues() {
      this.sendButton.loading = false;
      this.sendButton.success = false;
      this.sendButton.error = false;
    },
    closeConfirmation() {
      if (this.file) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.changeStudyPlan.table.massImportDrawer.messages.pendingFiles'),
          actionPrimary: { text: this.$t('actions.yes'), callback: () => this.close() },
          actionSecondary: { text: this.$t('actions.no'), callback() { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.close()
      }
    },
    close() {
      this.$emit('close')
      setTimeout(() => {
        this.file = null
        this.dropFilesKey++
        this.massImportStatus = false
        this.resetButtonValues()
      }, 1000)
    },
    newCreate() {
      this.$emit('newCreate')
      setTimeout(() => {
        this.file = null
        this.dropFilesKey++
        this.massImportStatus = false
        this.resetButtonValues()
      }, 625)
    },
  },
}
</script>
