<template>
  <div fluid :class="[{'v-card--disabled': payment.options.disabled}, 'pl-3 pr-3']" @click="selectPaymentMethod">
    <v-row class="payment-single" :class="{'active-payments': payment.selected , 'active-reprogram': activeReprogram }">
      <v-col :cols="(showPrepaid) ? 1 : 2" lg="1">
        <v-checkbox color="primary" :disabled="payment.options.disabled || seleccionDisabled" :input-value="payment.selected"></v-checkbox>
      </v-col>

      <v-col class="text-left description" cols="5" :sm="(showPrepaid) ? 3 : 5">
        <span>{{ payment.title }}</span>
        <span v-if = "!$isMexico" class="subdescription">{{ `${$t('modules.portalalumno.payments.paymentsStudents.view.labels.due')}  ${payment.options.paymentInstallment}/${payment.options.paymentInstallments}` }}</span>
        <span v-if = "$isMexico" class="subdescription">{{ `${payment.options.subdescription}` }}</span>
        <span class="subdescription hidden-md-and-up" :class="(showPrepaid) ? 'hidden' : ''" v-if="payment.options && (!payment.reenrollmentId || $isIPP)">{{ dueMessage }}</span>
        <small class="itemMandatory" v-if="payment.mandatory && studentData.studentType.value == 8 ">*Item obligatorio de pago para re-matriculacion</small>
        <small class="itemMandatory" v-if="payment.mandatory && studentData.studentType.value == 7">*Item obligatorio de pago para matriculacion</small>
        <span v-if="payment.billingProduct === 87" class="subdescription">{{ this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.term') }} {{ payment.subscription.termCodes }}</span>
        <span v-if="payment.billingProduct === 87" class="subdescription">{{
          this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.info', { installments: payment.subscription.installments, mount: payment.subscription.amount }) }}
        </span>
        <!-- <span class="subdescription" v-if="payment.description">{{ payment.description }}</span> -->
        <span class="subdescription hidden-lg-and-up" :class="(showPrepaid) ? 'hidden' : ''" v-if="payment.options && (!payment.reenrollmentId || $isIPP)">{{ dueMessage }}</span>
      </v-col>

      <v-col class="amount  d-none d-md-flex"  lg="3" :style="(showPrepaid) ? 'display: none !important' : ''">
        <div class="option-bkg" v-if="payment.options">
          <div class="text-center limit-date"
               v-if="systemConfig?.dues && payment.mandatory">
          </div>
          <div v-else class="text-center limit-date">{{ dueMessage }}</div>

        </div>
      </v-col>

      <v-col v-if="!showPrepaid" class="text-right amount " cols="5"  lg="3" md="3">
        <div class="option-payment pr-3" v-if="payment.options.off + payment.options.addedExtraOff > 0">
          <div class="old-price">{{ payment.options.amount | $currency }}</div>
          <div class="money-value">{{ savingAmount | $currency }}</div>
          <!-- <div class="discount-value">{{`${savingOff}% off`}} </div> -->
          <div class="discount-value">{{this.productWithPricePrepaids?.percentageWitchExtraOff ? this.productWithPricePrepaids[0]?.percentageWitchExtraOff : applyOff | $formatOff}} </div>
        </div>
        <div class="option-payment pr-3" v-else>
          <div class="money-value" :class="{'red-text': activeReprogram}">{{ payment.options.amount | $currency }}</div>
        </div>
      </v-col>

      <template v-if="showPrepaid">
        <template v-if="!payment.reenrollmentId && !payment.options.discounts.length">
          <v-col class="text-right amount prepaid py-0 px-0 my-3" :class="{'d-none': !prepaidValid}" cols="5" sm="2"  v-for="(prepaid, index) in prepaids" :key="`${payment.id}-${index}`">
            <div class="option-payment px-3" :class="{'active': prepaidsActive[index] === true}">
              <div class="text-date" v-if="!isFirstPayment">{{ `Hasta el ${prepaid.dayTo}/${payment.options.month}` }}</div>
              <div class="money-value">{{ payment.options.amount - (prepaid.percentage * payment.options.amount / 100) - (payment.options.offWithoutPrepaids * payment.options.amount / 100) | $currency }}</div>
              <div class="discount-value">{{`${payment.options.offWithoutPrepaids + prepaid.percentage }% off`}} </div>
            </div>
          </v-col>
        </template>
        <template v-else>
          <v-col class="text-right amount prepaid py-0 px-0 my-3" :class="{'d-none': !productWithPricePrepaidValid}" cols="5" sm="2"  v-for="(prepaid, index) in productWithPricePrepaids" :key="`${payment.id}-${index}`">
            <div v-if="!payment.options.discounts.length" class="option-payment px-3" :class="{'active': productWithPricePrepaidsActive[index] === true}">
              <div class="text-date" v-if="!payment.mandatory">{{ `Hasta el ${prepaid.dayTo}/${payment.options.month}` }}</div>
              <div class="money-value">{{ payment.options.amount - (prepaid.percentageWitchExtraOff  * payment.options.amount / 100) | $currency }}</div>
              <!-- <div class="discount-value">{{`${ prepaid.percentage + payment.options.offWithoutPrepaids }% off`}} </div> -->
              <!-- <div class="discount-value">{{`${ !payment.options.accumulativeDiscount  && payment.options.reenrollmentExtraOff ? prepaid.percentageWitchExtraOff : prepaid.percentage + payment.options.offWithoutPrepaids }% off`}} </div> -->
              <div class="discount-value">{{ `${ parseFloat(prepaid.percentageWitchExtraOff).toFixed(2) }% off`}} </div>
            </div>
            <div v-else class="option-payment px-3" :class="{'active': prepaidsActive[index] === true}">
              <div class="text-date" v-if="!payment.mandatory">{{ `Hasta el ${prepaid.dayTo}/${payment.options.month}` }}</div>
              <div class="money-value">{{ payment.options.amount - (prepaid.percentageWitchExtraOff * payment.options.amount / 100)  | $currency }}</div>
              <div class="discount-value">{{`${  parseFloat(prepaid.percentageWitchExtraOff).toFixed(2) }% off`}} </div>
            </div>
            <!-- - (payment.options.offWithoutPrepaids * payment.options.amount / 100)  -->
          </v-col>
        </template>
        <v-col v-if="!payment.reenrollmentId ? !prepaidValid : !productWithPricePrepaidValid" class="amount d-sm-none d-md-flex" cols="1" sm="5">
          <div class="option-bkg" v-if="payment.options && !payment.reenrollmentId ">
            <div class="text-center limit-date">{{ dueMessage }}</div>
          </div>
        </v-col>

        <v-col class="text-right amount prepaid"
        :cols="prepaidValid || productWithPricePrepaidValid ? 5 : 6"
        :sm="prepaidValid || productWithPricePrepaidValid ? 2 : 8"
        :md="prepaidValid || productWithPricePrepaidValid ? 2 : 3" >
          <div class="option-payment">
            <div v-if="payment.options.off + payment.options.addedExtraOff > 0" class="old-price">{{ payment.options.amount | $currency }}</div>
            <div v-if="payment.options.discounts.length" class="money-value" :class="{'red-text': activeReprogram}">{{ payment.options.amount - (this.payment.options.reenrollmentExtraOff * payment.options.amount / 100)  | $currency}}</div>
            <div v-else class="money-value" :class="{'red-text': activeReprogram}">{{ prepaidInactiveMoneyValue - ((this.payment.options?.offWithoutPrepaids?? this.payment.options.off )* prepaidInactiveMoneyValue / 100) | $currency }}</div>
            <div class="discount-value" v-if="this.payment.options?.offWithoutPrepaids?? this.payment.options.off > 0 " >{{this.payment.options?.offWithoutPrepaids?? applyOff | $formatOff}} </div>
            <div class="discount-value" v-if="this.payment.options.reenrollmentExtraOff > 0">{{`${this.payment.options.reenrollmentExtraOff}% off`}} </div>
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex'

export default {
  props: {
    systemConfig: Object,
    payment: Object,
    prepaids: Array,
    productWithPricePrepaids: Array,
    canReprogram: {type: Boolean, default: false},
    seleccionDisabled: {type: Boolean, default: false},
  },
  data() {
    return {
      now: moment(),
      dueDate: moment(this.payment.options.dueDate),
      prepaidsActive: [],
      productWithPricePrepaidsActive: [],
      prepaidValid: false,
      productWithPricePrepaidValid: false,
      filteredPrepaids: [],
    }
  },
  filters:{
      $formatOff(value){
        let off
        if(typeof value === 'number') off = parseFloat(value).toFixed(2)
        else if (typeof value === 'string') off = value
        else return

        if (/\.\d*00/.test(off)) {
          off = Math.round(value)
        }else if(/0$/.test(off)){
          off = parseFloat(value).toFixed(1)
        }
        if(!off) return ''
        return `${ off }`.concat('% off')
      }
    },
  mounted() {
    this.filteredPrepaids = this.prepaids.filter(prepaid => this.payment.options.term.id === prepaid.termId);
    this.setActivePrepaid()
    this.setActiveProductWithPricePrepaid()
    this.setPercentage()

  },
  computed: {
    prepaidInactiveMoneyValue() {
      if(this.payment.options?.offWithoutPrepaids) {
        return this.payment.options.amount - (this.payment.options.offWithoutPrepaids * this.payment.options.amount / 100)
      }
      return this.payment.options.amount
    },
    dueMessage() {
      return this.payment.status == 117 ? this.payment.options.expiration.replace('Hasta', 'Vencido') : this.payment.options.expiration
    },
    showPrepaid() {
      return this.payment.billingProduct === 81 && this.prepaids.length && !this.payment?.mandatory && !this.payment?.status === 117 || this.payment.billingProduct === 81 && !this.payment?.mandatory  //&& this.productWithPricePrepaids.length
    },
    isArancel() {
      return !!this.payment.options.term && this.payment.billingProduct === 81
    },
    isFirstPayment() {
      return (this.payment.options.paymentInstallment == 1 && !this.payment.options.inscriptionDate ) || (
              // Alumnos inscriptos al 1A/2024 entre el 12/10 y 31/10, se muestra pronto pago sin maximo descuento en duro
              this.payment.options.paymentInstallment == 1 && this.payment.length > 3 &&
              (this.payment.options.term?.id != 597 ||
              moment(this.payment.options.inscriptionDate) < moment('2023-10-12') ||
              moment(this.payment.options.inscriptionDate) > moment('2023-10-31'))
            ) || (
              // Alumnos de mexico inscriptos del 16 al 31 de diciembre, se otorga dos cuotas con maximo descuento
              this.$isMexico &&
              [1, 2].includes(this.payment.options.paymentInstallment) &&
              this.payment.options.paymentInstallments >= 4 &&
              moment(this.payment.options.inscriptionDate).date() >= 16 &&
              moment(this.payment.options.inscriptionDate).date() <= 31
            ) || (
              this.payment.options.inscriptionDate &&
              this.payment.options.term?.id == 498 &&
              [1, 2].includes(this.payment.options.paymentInstallment) &&
              moment(this.payment.options.inscriptionDate) >= moment('2023-08-08') &&
              moment(this.payment.options.inscriptionDate) <= moment('2023-08-24')
            )
    },
    activeReprogram() {
      return this.canReprogram && this.payment.selected && this.payment.repaymentAvailable
    },
    savingAmount() {
      return this.$isTeclab ? this.payment.options.amount - ((this.payment.options.off + this.payment.options.addedExtraOff) * this.payment.options.amount / 100) < 0 ? 0 : this.payment.options.amount - ((this.payment.options.off + this.payment.options.addedExtraOff) * this.payment.options.amount / 100)
        : this.payment.options.amount - ((this.payment.options.off + (this.payment.options.addedExtraOff * (100 - this.payment.options.off) / 100)) * this.payment.options.amount / 100)
    },
    savingOff() {
      return this.$isTeclab ? (this.payment.options.off + this.payment.options.addedExtraOff) > 100 ? 100 : this.payment.options.off + this.payment.options.addedExtraOff
        : this.payment.options.off + (this.payment.options.addedExtraOff * (100 - this.payment.options.off) / 100)
    },
    savingOffProgressive() {
      return this.payment.options.off
    },
    ...mapGetters({
          studentData: 'commons/getStudentData',
      }),
      applyOff() {
        if(this.payment.options.accumulativeDiscount){
          return this.payment.options.off + this.payment.options.addedExtraOff
        }else {
          let amountOff = 0
          const prepaidOff = this.payment.options.prepaidPercentage
          const scholarshipOff = this.payment.options.scholarshipAmount / this.payment.options.amount * 100
          const benefitOff = this.payment.options.benefitAmount / this.payment.options.amount * 100
          const {addedExtraOff} = this.payment.options
          if(prepaidOff){
            const prepaidAmount = (this.payment.options.amount / 100) * prepaidOff
            if(addedExtraOff){
              amountOff = ((this.payment.options.amount - prepaidAmount) / 100) * addedExtraOff
            }
            if(benefitOff){
              amountOff = ((this.payment.options.amount - prepaidAmount) / 100) * benefitOff
            }
          }else if(scholarshipOff){
            amountOff = ((this.payment.options.amount - this.payment.options.scholarshipAmount) / 100) * benefitOff
          }

          // const addedextraPercentage = (amount / this.payment.options.amount) * 100
          const percentageOff = (amountOff / this.payment.options.amount) * 100
          return (scholarshipOff || prepaidOff) + percentageOff || 0
        }
      },
  },
  methods: {
    setPercentage(){
      if(!this.payment.options.accumulativeDiscount && !this.payment.options.discounts.length){
        this.productWithPricePrepaids?.map(prepaid => {
          let aux = 0
          if(this.payment.options.reenrollmentExtraOff){
             aux = this.payment.options.amount - (this.payment.options.amount * this.payment.options.reenrollmentExtraOff / 100)
             const cal1 = aux  - (aux * prepaid.percentage / 100)
             prepaid.percentageWitchExtraOff = 100 - (cal1 * 100 / this.payment.options.amount)
          }else{
            prepaid.percentageWitchExtraOff = prepaid.percentage
          }
        })
      }else if(this.payment.options.discounts.length){
        this.productWithPricePrepaids?.map(prepaid => {
          prepaid.percentageWitchExtraOff = prepaid.percentage
        })

      }else{
        this.productWithPricePrepaids?.map(prepaid => {
          prepaid.percentageWitchExtraOff = prepaid.percentage + this.payment.options.reenrollmentExtraOff
        })
      }
    },
    setActivePrepaid() {
      if (this.prepaids.length) {
        let activeFound = false
        this.prepaids.forEach((prep, index) => {
          if (this.isFirstPayment) {
            if (index == 0 && this.isArancel && !this.payment?.mandatory ) {
              this.prepaidsActive.push(true)
              this.payment.options.offWithoutPrepaids = this.isArancel ? this.payment.options.off : 0
              this.payment.options.off = (this.isArancel && this.mandatory && (this.payment?.status != 117 || this.$isMexico)) ? prep.percentage : 0
              //this.payment.options.off = (this.isArancel && (this.payment?.status != 117 || this.$isMexico)) ? this.payment.options.off + prep.percentage : 0
              activeFound = true
            } else {
              this.prepaidsActive.push(false)
            }
          } else {
            const prepaidDate = this.dueDate.set('date', prep.dayTo + 1)
            const diff = prepaidDate.diff(this.now, 'seconds')

            if (diff > 0 && !activeFound && this.isArancel && !this.payment?.mandatory) {
              this.prepaidsActive.push(true)
              this.payment.options.offWithoutPrepaids = this.isArancel ? this.payment.options.off : 0
              this.payment.options.prepaidPercentage = this.isArancel ? prep.percentage : 0
              this.payment.options.off = (this.isArancel && !this.payment?.status=== 117) ? this.payment.options.off + prep.percentage : 0
              activeFound = true
            } else {
              this.prepaidsActive.push(false)
            }
          }
        })

        this.prepaidValid = activeFound && this.isArancel && !this.payment?.mandatory
      }
    },
    setActiveProductWithPricePrepaid() {
      if (this.productWithPricePrepaids?.length) {
        let activeFound = false
        this.productWithPricePrepaids?.forEach((prep, index) => {
          this.productWithPricePrepaidsActive[index] = false
          const prepaidDate = this.dueDate.set('date', prep.dayTo + 1)
          const diff = prepaidDate.diff(this.now, 'seconds')
          if(this.isFirstPayment && index === 0){
            activeFound = this.setPrepaidDiscount(prep, index);
          }else if (this.payment?.mandatory) {
            if (index === 0) {
              activeFound = this.setPrepaidDiscount(prep, index);
            }
          } else if (diff > 0) {
            if(!activeFound && this.isArancel){
              activeFound = this.setPrepaidDiscount(prep, index);
            }
          }
        })
        this.productWithPricePrepaidValid = activeFound && this.isArancel && !this.payment?.mandatory
      }
    },
    setPrepaidDiscount(prep, index) {
      this.productWithPricePrepaidsActive[index] = true
      this.payment.options.offWithoutPrepaids = this.isArancel && this.mandatory ? this.payment.options.off : 0
      this.payment.options.prepaidPercentage = this.isArancel ? prep.percentage : 0
      this.payment.options.off = this.isArancel ? this.payment.options.off + prep.percentage : 0
      return true
    },
    selectPaymentMethod() {
      if (!this.payment.options.disabled && !this.seleccionDisabled) {
        this.$emit('updatepaymentmethod', !this.payment.selected);
      }
    },
  }
};
</script>

<style scoped  lang="sass">

.payment-single
  font-family: 'Roboto'
  font-size: 12px
  font-weight: bold
  height: 90px
  border-radius: 5px
  box-shadow: 0 2px 2px 0 rgba(1, 33, 105, 0)
  border: solid 1px #d9d9d9
  background-color: white
  margin-bottom: 10px !important

  &.mandatory-payment
    border: solid 1px #var(--v-primary-darken3)
    .prepaid .active
      background-color: #d9e2fa
  &.active-reprogram
    border: solid 1px #ff5252

  &:hover
    box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)
    border: solid 1px var(--v-primary-darken3)
    cursor: pointer

  &.active-payments, &:hover

    .prepaid .active
      background-color: #d9e2fa

.description
  display: flex
  flex-direction: column
  justify-content: space-around
  span
    font-family: 'DM Sans'
    font-size: 16px
    line-height: 1.2
    letter-spacing: -0.48px
    text-align: left
    color: #212121
    margin-bottom: 0

    &.subdescription
      font-family: 'Roboto'
      font-size: 12px
      color: rgba(33, 33, 33, 0.5)
      line-height: 1.2
      letter-spacing: normal
      text-align: left
      margin-bottom: 0

.limit-date
  line-height: 1.33
  letter-spacing: -0.54px
  color: rgba(33, 33, 33, 0.5)
  width: 100%

.option-payment
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-around

.old-price
  line-height: 1.21
  color: rgba(33, 33, 33, 0.5)
  text-decoration: line-through

.money-value
  font-family: 'DM Sans'
  font-size: 18px
  line-height: 1.33
  letter-spacing: -0.54px
  color: var(--v-primary-darken4)

  &.red-text
    color: #f72b2b !important

.discount-value
  line-height: 1.17
  color: #2d9200

.option-bkg
  width: 100%
  height: 100%
  display: flex
  align-items: center


  .old-price
    line-height: 1.21
    color: rgba(33, 33, 33, 0.5)
    text-decoration: line-through

  .text-date
    color: rgba(33, 33, 33, 0.5)
    text-decoration: none

.text--dark
  color: #212121 !important


</style>
