const rulesTranslations = {
    es: {
      RegulationCardsView: {
        breadcrumbs: {
          title: 'Reglamento',
          description: 'Tipos de reglamentos institucionales.',
        },
      },
      table: {
        breadcrumbs: {
          title: 'Reglamento',
          description: 'Administración del reglamento {regulation}.',
        },
        headers: {
          title: 'Selecciona el tipo de reglamento',
          chapter: 'Capítulo',
        },
        messages: {
          delete: '¿Estás seguro de eliminar el capítulo?',
          deleteSubChapter: '¿Estás seguro de eliminar el subcapítulo?',
          deleteDescription:'¿Estás seguro de eliminar la descripción?',
          editedSuccess: 'Reglamento actualizado con éxito',
          editedError: 'No se pudo modificar el reglamento',
          editedValidationError: 'Ingrese un texto o elimine la descripción. No se permite guardar una descripción vacía.',
          editedNameValidationError: 'El nombre es requerido',
          editedNameErrorInvalid: 'Ingrese un nombre válido',
          nameExist:'El capítulo ya existe',
          newChapterSuccess: '¡Capítulo creado con éxito!',
          newSubchapterSuccess: '¡Subcapítulo creado con éxito!',
        },
        actions: {
          addSubchapter: 'Agregar Subcapitulo',
          addDescription: 'Agregar Descripción',
          deleteChapter: 'Eliminar Capítulo',
          deleteSubChapter: 'Eliminar Subcapítulo',
        }
      },
    },
    en: {

      tooltips: {
        enable: 'Enable',
        disable: 'Disable',
        help_group: '',
      },

    }
  };


  export default rulesTranslations
