var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 pb-8",attrs:{"fluid":""}},[(!(_vm.success || _vm.error))?_c('v-container',{staticClass:"container-custom"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"viewSpaces",attrs:{"sm":"12"}},[_c('OutlinedCard',{staticClass:"mb-6",attrs:{"title":"Seleccionada"}},[_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_vm._l((_vm.subjects),function({subject}){return _c('v-col',{key:subject?.id,staticClass:"d-flex flex-column align-start pb-0 pr-3",attrs:{"sm":"7","offset-sm":"1"}},[_c('span',{staticClass:"caption text-uppercase"},[_vm._v(_vm._s(`${subject?.code} - ${subject?.descripcion}`))])])}),_c('v-col',{staticClass:"align-start pt-1 pb-3",attrs:{"sm":"3"}},[_c('v-select',{staticClass:"d-inline-block ml-2",attrs:{"outlined":"","placeholder":"Seleccione","items":_vm.optionsItems,"item-text":"description","error-messages":(_vm.$v.selectedQualifyPP.$dirty && _vm.$v.selectedQualifyPP.$anyError) ? _vm.$t('modules.studentSubjects.ChangeAppovedPP.errorRequired') : '',"return-object":""},on:{"blur":function($event){return _vm.$v.selectedQualifyPP.$touch()},"keypress":function($event){return _vm.$validateDecimalInputNumber($event, _vm.$v.selectedQualifyPP.$model)}},model:{value:(_vm.$v.selectedQualifyPP.$model),callback:function ($$v) {_vm.$set(_vm.$v.selectedQualifyPP, "$model", $$v)},expression:"$v.selectedQualifyPP.$model"}})],1)],2)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Button',{staticClass:"mr-4",attrs:{"white":"","text":_vm.$t('actions.cancel')},on:{"clicked":_vm.clearDataQualifyPP}}),_c('Button',{attrs:{"text":_vm.$t('actions.save'),"loading":_vm.loading,"disabled":_vm.$v.$invalid && _vm.$v.$dirty},on:{"clicked":_vm.confirmPopup}})],1)],1)],1)],1):_vm._e(),(_vm.success)?_c('SuccessMessage',{attrs:{"type":"success","title":_vm.$t('modules.studentSubjects.ChangeAppovedPP.successQualifyPP'),"actionPrimary":{
      text: 'Cerrar',
      callback: _vm.clearDataQualifyPP
    }}}):_vm._e(),(_vm.error)?_c('SuccessMessage',{attrs:{"type":"error","title":_vm.$t('modules.studentSubjects.ChangeAppovedPP.errorQualifyPP'),"actionPrimary":{
      text: 'Cerrar',
      callback:_vm.clearDataQualifyPP
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }