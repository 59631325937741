
const careersTranslations = {
    es_PE: {
        create: {
            table: {
                headers: {
                    subjects: 'Cursos',
                }
            },
        },
        update: {
            table: {
                headers: {
                    subjects: 'Cursos'
                }
            },
        },
        study_plan:{
            drawer:{
                create:{
                    description: 'Agrega los cursos y asígnalo a una carrera',
                },
                edit:{
                    description: 'Agrega y quita cursos',
                },
                search:{
                    title: 'Buscar cursos',
                },
                periods_subjects:{
                    title: 'Períodos y cursos',
                }
            },
        }
    },
    es_MX: {
        create: {
            table: {
                headers: {
                    subjects: 'Materias',
                }
            },
        },
        update: {
            table: {
                headers: {
                    subjects: 'Materias'
                }
            },
        },
        study_plan:{
            drawer:{
                create:{
                    description: 'Agrega las materias y asígnalo a una carrera',
                },
                edit:{
                    description: 'Agrega y quita materias',
                },
                search:{
                    title: 'Buscar materias',
                },
                periods_subjects:{
                    title: 'Períodos y materias',
                }
            },
        }
    },
    es: {
        ordinal : ['primer', 'segundo', 'tercer', 'cuarto', 'quinto', 'sexto', 'septimo', 'octavo', 'noveno'],
        ordinal_2: ['decimo', 'vigesimo', 'trigesimo'],
        table: {
            newCareer: 'Nueva',

            breadcrumbs: {
                title: 'Carreras',
                description: 'Administración de carreras'
            },
            headers: {
                id: '#',
                code: 'Código',
                name: 'Nombre',
                type: 'Tipo',
                status: 'Estado',
                mentions: 'Menciones' 
            },
            message: {
                delete: '¿Estás seguro de eliminar?',
                title: 'Estás a punto de eliminar las siguientes carreras',
                deleteAction: 'Si, eliminar',
                cancelAction: 'cancelar'
            },
            search: {
                search: 'Buscar',
                gotopage: 'ir a página',
                edit: 'Editar',
                delete: 'Eliminar',
                bulkDelete: 'Eliminar carreras'
            }
        },
        create: {
            breadcrumbs: {
                title: 'Nueva carrera',
                description: 'Crea una nueva carrera.'
            },
            sectionData: {
                title: 'Datos de la carrera',
                subtitle: 'Agrega la información de la nueva carrera. Recuerda que estos datos deben ser lo más descriptivos posible, ya que serán utilizados para futuros filtros de búsqueda.',
                labelEnable: 'Habilitada',
                labelDisable: 'Deshabilitada',
                items: {
                'description': 'Nombre',
                'code': 'Código',
                'careerType': 'Tipo de carrera',
                'legalDescription': 'Descripción legal *',
                'fistYearCertified': 'Descripción certificado primer año',
                'book': 'Libro',
                'invoice': 'Folio',
                'credits': 'Créditos',
                'hours': 'Horas'
                }
            },
            studyPlans: {
                title: 'Planes de estudio/Menciones',
                subtitle: 'Aquí podrás habilitar, deshabilitar o crear un plan de estudio. Al asignarlo a la nueva carrera tomará la estructura curricular para su cursado.',
                button: 'Agregar plan',
            },
            success_message: {
                title: 'Carrera creada con éxito',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otra',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'La carrera no pudo crearse',
            },
            table: {
                headers: {
                    status: 'Habilitación',
                    name: 'Nombre',
                    quarter: 'Semestres',
                    subjects: 'Asignaturas',
                    duplicateCarousel: 'Duplica Carrusel',
                    studyPlanHours: 'Horas de Cursado'
                },
                text:{
                    apply:'Aplica',
                    noApply: 'No aplica'
                }
            },
            validations: {
                descriptionRequired: 'Nombre es requerido',
                codeRequired: 'Código es requerido',
                codeExisting: 'Ese código ya existe', 
                legalDescriptionRequired: 'Ingresa descripción legal',
                fistYearCertifiedRequired: 'Ingresa descripción certificado primer año',
                creditsRequired: 'Créditos es requerido',
                hoursRequired: 'Horas es requerido',
                invoiceRequired: 'Folio es requerido',
                bookRequired: 'Libro es requerido',
                careerTypeRequired: 'Debes seleccionar el tipo de carrera'
            },
            disableCareerPopUp: 'Estás creando una carrera deshabilitada' 
        },
        update: {
            disableCareerPopUpUpdate: 'Estás guardando una carrera deshabilitada',
            
            breadcrumbs: {
                title: 'Editar carrera',
                description: 'Edita una carrera.'
            },
            sectionData: {
                title: 'Datos de la carrera',
                subtitle: 'Información básica de la nueva carrera. Recuerda que estos datos deben ser lo más descriptivos posibles ya que serán utilizados para futuros filtros de búsqueda.',
                labelEnable: 'Habilitada',
                labelDisable: 'Deshabilitada',
                items: {
                'description': 'Nombre',
                'code': 'Código',
                'careerType': 'Tipo de carrera',
                'legalDescription': 'Descripción legal *',
                'fistYearCertified': 'Descripción certificado primer año',
                'book': 'Libro',
                'invoice': 'Folio',
                'credits': 'Créditos'
                }
            },
            studyPlans: {
                title: 'Planes de estudio/Menciones',
                subtitle: 'Aquí podrás habilitar, deshabilitar o crear un plan de estudio. Al asignarlo a la nueva carrera tomará la estructura curricular para su cursado.',
                button: 'Agregar plan'
            },
            success_message: {
                title: 'Carrera modificada con éxito',
                actions: {
                    primary_text: 'Cerrar',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'La carrera no pudo modificarse',
            },
            table: {
                headers: {
                    status: 'Habilitación',
                    name: 'Nombre',
                    quarter: 'Semestres',
                    subjects: 'Asignaturas'
                }
            },
            validations: {
                descriptionRequired: 'Nombre es requerido',
                codeRequired: 'Código es requerido',
                codeExisting: 'Ese Código ya existe', 
                legalDescriptionRequired: 'Ingresa descripción legal',
                creditsRequired: 'Créditos es requerido',
                hoursRequired: 'Horas es requerido',
                invoiceRequired: 'Folio es requerido',
                bookRequired: 'Libro es requerido'
            },
            disableCareerPopUp: 'Estás guardando una carrera deshabilitada'
        },
        warning: {
            no_selected_warning: 'Debes crear al menos un plan de estudio'
        },



        study_plan:{
            breadcrumbs:{
                title:'Crear Plan de estudio'
            },
            drawer:{
                create:{
                    title: 'Crea un Plan de estudio',
                    description: 'Agrega las asignaturas y asígnalo a una carrera',        
                },
                edit:{
                    title: 'Edición de Plan de estudio',
                    description: 'Agrega y quita asignaturas',
                },
                search:{
                    title: 'Buscar asignaturas',
                    placeholder: 'Escribe aquí'
                },
                basic_data:{
                    title: 'Datos básicos',
                    degree: 'Título',
                    plan:'Plan/Año',
                    resolution: 'Resolución',
                    modality: 'Modalidad',
                    hoursStudyPlan: 'Horas de Cursado'
                },
                periods_subjects:{
                    title: 'Períodos y asignaturas',
                    semester_amount: 'Total de semestres',
                }
            },
            validations:{
                degreeRequired:'Título es requerido',
                planYearRequired: 'Plan/Año es requerido',
                resolutionRequired: 'Resolución es requerida',
                modalityTypeRequired: 'Modalidad es requerida'

            },
            successUpdate: 'El plan de estudio se actualizo correctamente',
            errorUpdate: 'No se pudo actualizar el plan de estudio',
        }
    },
    en:  {
    }
};


export default careersTranslations
