<template>
  <v-item-group active-class="selected" v-model="selectedCardValue">
    <v-container>
      <v-row v-for="(item, index) in schedules" :key="index" cols="12" md="4">
        <v-col>
          <v-item v-slot="{ toggle }" :value="item.idCeoAgenda">
            <v-card class="d-flex justify-space-between align-center pa-4" @click="toggle" v-on:click="$emit('cardSelection', selectedCardValue, hasSupport(item), itemFirstHour(item))" style="background-color: #f5f5f5">
              <div>
                <div class="'body-2' font-weight-medium card-caption">{{ $t('modules.portalalumno.exams.scheduleStep.card.schedule') }}</div>
                <div class="card-availability font-weight-bold py-2">{{ item.horario }}</div>
              </div>
              <div>
                <div class="body-2 font-weight-medium card-caption">{{ $t('modules.portalalumno.exams.scheduleStep.card.availableSpots') }}
                  <span class="font-weight-bold card-availability">{{ item.lugaresDisponibles }}</span>
                </div>
                <div v-if="hasSupport(item)" class="text-caption text-center py-2 card-caption">
                  <v-icon color="#0088cc" size="20px">mdi-information-outline</v-icon>
                  {{ $t('modules.portalalumno.exams.scheduleStep.info_message.has') }}</div>
                <div v-else class="text-caption text-center py-2 card-caption">
                  <v-icon color="#FB9616" size="20px">mdi-information-outline</v-icon>
                  {{ $t('modules.portalalumno.exams.scheduleStep.info_message.hasnt') }}</div>
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'ExamsScheduleCardList',
    props: {
      schedules: Array,
      scheduleButton: Object,
      date: Date
    },
    data() {
      return {
        selectedCardValue: null
      };
    },
    watch: {
      scheduleButton: {
        handler(val) {
          if (val.success) this.selectedCardValue = null;
        },
        deep: true
      },
    },
    methods: {
      hasSupport(item) {


        if(this.$country === 'argentina'){
          const createSchedule = (schedule) =>({
            isSameScheduleStart: (date) => date.isSame(schedule.start),
            isSameScheduleEnd: (date) =>  date.isSame(schedule.end),
            isBetweenSchedule: (date) => date.isBetween(schedule.start, schedule.end)
          })
          let exam
          const weekday = moment(this.date).day()
          const [ start, end ] = item.horario
            .split(' - ')
            .map(time =>{
              const [ hour, minute ] = time.split(':')
              return moment(this.date)
                .set('hour', hour)
                .set('minute', minute)
            })


          if(weekday === sunday) return false
          if(weekday === saturday){
            exam = createSchedule({
              start: moment(this.date).set('hour', 9).set('minute', 0),
              end: moment(this.date).set('hour', 14).set('minute', 0)
            })
          }else{
            exam = createSchedule({
              start: moment(this.date).set('hour', 9).set('minute', 0),
              end: moment(this.date).set('hour', 22).set('minute', 0)
            })
          }

          const isStartEqualOrBetween = exam.isSameScheduleStart(start) || exam.isBetweenSchedule(start)
          const isEndEqualOrBetween = exam.isSameScheduleEnd(end) || exam.isBetweenSchedule(end)
          return  isStartEqualOrBetween && isEndEqualOrBetween
        }
        else if(this.$country === 'chile'){
          const day = item.dia;
          const [, hour] = item.horario.split(' - ');
          if ((day != 'sábado' && (moment(hour, 'HH:mm').isAfter(moment('20:00', 'HH:mm')) && moment(hour, 'HH:mm').isSameOrBefore(moment('23:00', 'HH:mm'))))
          || (day == 'sábado' && (moment(hour, 'HH:mm').isAfter(moment('9:00', 'HH:mm')) && moment(hour, 'HH:mm').isSameOrBefore(moment('11:00', 'HH:mm'))))) {
              return false;
          }

          return true;
        }
        else if(this.$country === 'mexico'){
          const day = item.dia;
          const [, hour] = item.horario.split(' - ');
          if ((day != 'sábado' && (moment(hour, 'HH:mm').isAfter(moment('12:00', 'HH:mm')) && moment(hour, 'HH:mm').isSameOrBefore(moment('22:00', 'HH:mm'))))
          || (day == 'sábado' && (moment(hour, 'HH:mm').isAfter(moment('12:00', 'HH:mm')) && moment(hour, 'HH:mm').isSameOrBefore(moment('22:00', 'HH:mm'))))) {
              return true;
          }

          return false;
        }
      },
      itemFirstHour(item) {
        return item.horario.split(' - ')[0];
      }
    }
  };
  const sunday = 0
  const saturday = 6
</script>

<style lang="sass" scoped>

$card-teal: #61BCCC

$primaryLighten2: var(--v-primary-lighten2)

.card-caption
  color: #B2B9B3

.card-availability
  color: $primaryLighten2

.selected
  background-color: $card-teal !important
  color: white

.selected .card-caption
  color: white

.selected .card-availability
  color: white

</style>
