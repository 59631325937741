
const Gradebook = {
    es: {
        drawer: {
            title: 'Mis libros descargados',
            description: 'Lista de los últimos libros descargados'
        },
        download: {
            headers: {
                book: 'Libro',
                date: 'Fecha',
                plan: 'Plan',
                download: 'Descargar',
                status: 'Estado'

            },
            drawer: {
                title: 'Libros',
                description: 'Aquí podrás descargar los libro solicitados. Ten en cuenta que solo estarán displonibles el día en que fueron generados y solo apareceran en la lista una vez que finalice su generación, esto puede llevar varios minutos.'
            },
            error: 'Ocurrió un error descargando el libro',
            request: {
                successTitle: 'El libro esta siendo generado',
                successContent: 'Puede descargarlo desde la opción "Mis libros descargados", puede existir una demora de hasta 10 minutos',
                error: 'Ocurrió un error generando el libro',
                errorTime: 'Se ha generado este libro en los últimos 10 minutos. \nPara generarlo nuevamente vuelva a intentar más tarde.'
            },
        },
        action:{
            export: 'Exportar',
            open: 'Abrir',
            download: 'Descargar',
            goToPage: 'Ir a página',
        },
        numberYear:{
            1: 'Primer ',
            2: 'Segundo',
            3: 'Tercer',
            4: 'Cuarto',
            5: 'Quinto',
            6: 'Sexto',
            7: 'Septimo',
        },
        breadcrumbs: {
            title: 'Libro Matriz',
            subtitle: 'Libro Matriz'
        },
        studentStatus: 'Estado del Alumno:',
        career: 'Carreras',
        bookSheet: 'Libro',
        year: 'año',
        sheets: 'Folios',
        sheet: 'Folio',
        grade: 'Curso',
        documentation: 'DNI:',
        paragraph1: 'Señor',
        paragraph2: 'nacido en',
        paragraph3: 'el',
        paragraph4: 'DNI',
        paragraph5: 'Ingresó con el certificado de expedido por.',
        paragraph6: 'Carrera',
        paragraph7: 'plan',
        paragraph8: 'ingresó en el año',
        paragraph9: 'Legajo N°:',
        paragraph10: 'Termino anual',
        observations: 'Observaciones', 
        card: {
            books: 'Libros'
        },
        table: {
            searchInput: 'Buscar',
        },
        headers: {
            nameStudent: 'Nombre y Apellido',
            documentation: 'DNI',
            sheet: 'Folio',
            career: 'Carreras',
            subject: 'Asignatura',
            book: 'Libro',
            inWord: 'En letra',
            inNumber: 'En numero',
            date: 'Fecha',
            status: 'Estado',
            legajo: 'Legajo'
        }
        
    },
    es_CL: {
        
    }
};

export default Gradebook