<template>
  <v-container class="container-custom">
    <div v-if="loading" class="d-flex align-center justify-center container">
      <v-progress-circular indeterminate class="primary--text"></v-progress-circular>
    </div>

      <v-container class="container-custom"  v-else>
      <Breadcrumbs
        :title="$t('modules.portalalumno.admission.documentation.title')"
        size="sm"
      ></Breadcrumbs>
      <v-row class="pl-12 pb-4">
          <v-icon class="pr-2 noteIcon ">mdi-alert-circle-outline</v-icon>
          <h5 class="pt-1 noteText ">{{ $t('modules.portalalumno.admission.alert.note') }}</h5>
      </v-row>
      <div class="container">
        <v-row>
          <v-col sm="11">
            <v-card outlined class="py-5 px-10 my-2 documentation-container">
              <v-row>
                <v-col sm="2" class="d-flex align-center caption">
                  {{ $t('modules.portalalumno.admission.documentation.status') }}
                </v-col>
                <v-col sm="10" class="d-flex align-center">
                  <div class="d-flex align-center current-status" :class="status.class">
                    <div class="icon-container mr-2">
                      <v-icon>{{ status.icon }}</v-icon>
                    </div>
                    <span class="black--text">{{ status.text }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="2" class="d-flex align-center caption">
                  {{ $t('modules.portalalumno.admission.documentation.observation') }}
                </v-col>
                <v-col sm="10" class="d-flex align-center">
                  <div class="d-flex align-center body-2 pr-10">{{ observationText }}</div>
                </v-col>
              </v-row>
            </v-card>
            <v-card v-if="isAprovedProcedure()" outlined class="py-5 px-10 my-2">
              <h1 class="body-1 font-weight-bold primary--text">{{$t('modules.portalalumno.admission.documentation.requiredDocumentation')}}</h1>
              <v-row class="d-flex align-center">
                <div class="d-flex align-center">
                  <v-icon style="font-size: 21px" class="pr-1 pb-2 primary--text">mdi-alert-circle</v-icon>
                  <div class="pl-2">
                    <h2 class="body-2 pt-2 pb-5">{{ $t('modules.portalalumno.admission.alert.noteDoc') }}</h2>
                  </div>
                </div>
              </v-row>
              <h2 class="body-2 mt-0 mb-10 pl-5">{{ $t('modules.portalalumno.admission.documentation.requiredFields') }}</h2>
              <div class="d-flex flex-wrap align-start justify-lg-space-between my-2">
                <v-card
                  outlined
                  v-for="(documentation,index) in normalDocumentationRequired"
                  class="pa-5 documentation-card ma-1"
                  :class="{'documentation-rejected': normalDocumentationRejected[index]}"
                  :elevation="normalDocumentationRejected[index] ? 2 : 0"
                  :key="`req-doc-${index}`">
                  <div class="d-block">
                    <h1 class="body-1 font-weight-bold ma-0">{{ `${documentation.name} ${!documentation.optional ? '*' : ''}` }}</h1>
                    <h2 class="body-2 mt-1 mb-3">{{ documentation.description }}</h2>
                    <template v-if="normalUploadedDocs[index].length > 0">
                    <v-divider/>
                    <v-list class="files-list">
                      <h2 class="caption my-1">{{$t('modules.portalalumno.admission.documentation.revisedDocumentation')}}</h2>
                      <v-list-item
                        v-for="(item, i) in normalUploadedDocs[index]"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{item.filename}}</v-list-item-title>
                        </v-list-item-content>
                        <div class="d-flex align-center caption font-weight-bold">
                          <v-icon class="mr-2" :class="item.statusClass" >{{item.statusIcon}}</v-icon>
                          {{ item.meaning }}
                          <v-icon class="ml-2 mr-2" color="primary" @click="downloadFile(item)" >mdi-cloud-download-outline</v-icon>
                        </div>
                      </v-list-item>
                    </v-list>
                    </template>
                    <v-divider/>
                    <FileUploader
                      formatsAccepted=".pdf,.jpg,.jpeg,.png"
                      :files.sync="normalDocumentation[index]"
                      :process="false"
                      :isMultiple="true"
                    />
                  </div>
                </v-card>
              </div>
            </v-card>
            <v-card outlined class="py-5 px-10 my-2" v-if="needScholarshipDocumentation">
              <h1 class="body-1 font-weight-bold primary--text mb-10">{{$t('modules.portalalumno.admission.documentation.scholarshipDocumentation')}}</h1>
              <div class="d-flex flex-wrap align-start justify-lg-space-between my-2">
                <v-card
                  outlined
                  v-for="(documentation,index) in scholarshipDocumentationRequired"
                  :class="['pa-5 documentation-card ma-1',scholarshipDocumentationRejected[index]? 'documentation-rejected' : '']"
                  :elevation="scholarshipDocumentationRejected[index] ? 2 : 0"
                  :key="`req-doc-${index}`">
                  <div class="d-block">
                    <h1 class="body-1 font-weight-bold ma-0">{{documentation.name}}</h1>
                    <h2 class="body-2 mt-1 mb-3">{{documentation.description}}</h2>
                    <template v-if="scholarshipUploadedDocs[index].length>0">
                    <v-divider/>
                    <v-list class="files-list">
                      <h2 class="caption my-1">{{$t('modules.portalalumno.admission.documentation.revisedDocumentation')}}</h2>
                      <v-list-item
                        v-for="(item, i) in scholarshipUploadedDocs"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{item.filename}}</v-list-item-title>
                        </v-list-item-content>
                        <div class="d-flex align-center caption font-weight-bold">
                          <v-icon class="mr-2" :class="item.statusClass">{{item.statusIcon}}</v-icon>
                          {{ item.statusText }}
                        </div>
                      </v-list-item>
                    </v-list>
                    </template>
                    <v-divider/>
                    <FileUploader
                      :isEnabled="documentation.status"
                      formatsAccepted=".pdf,.jpg,.jpeg,.png"
                      :files.sync="scholarshipDocumentation[index]"
                      :process="false"
                    />
                  </div>
                </v-card>
              </div>
            </v-card>
            <v-card outlined class="py-5 px-10 my-2" v-if="needBenefitDocumentation">
              <h1 class="body-1 font-weight-bold primary--text mb-10">{{$t('modules.portalalumno.admission.documentation.benefitDocumentation')}}</h1>
              <div class="d-flex flex-wrap align-start justify-lg-space-between my-2">
                <v-card
                  outlined
                  v-for="(documentation,index) in benefitDocumentationRequired"
                  :class="['pa-5 documentation-card ma-1',benefitDocumentationRejected[index]? 'documentation-rejected' : '']"
                  :elevation="benefitDocumentationRejected[index] ? 2 : 0"
                  :key="`req-doc-${index}`">
                  <div class="d-block">
                    <h1 class="body-1 font-weight-bold ma-0">{{documentation.name}}</h1>
                    <h2 class="body-2 mt-1 mb-3">{{documentation.description}}</h2>
                    <template v-if="benefitUploadedDocs[index].length>0">
                    <v-divider/>
                    <v-list class="files-list">
                      <h2 class="caption my-1">{{$t('modules.portalalumno.admission.documentation.revisedDocumentation')}}</h2>
                      <v-list-item
                        v-for="(item, i) in benefitUploadedDocs"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{item.filename}}</v-list-item-title>
                        </v-list-item-content>
                        <div class="d-flex align-center caption font-weight-bold">
                          <v-icon class="mr-2" :class="item.statusClass">{{item.statusIcon}}</v-icon>
                          {{ item.statusText }}
                        </div>
                      </v-list-item>
                    </v-list>
                    </template>
                    <v-divider/>
                    <FileUploader
                      :isEnabled="documentation.status"
                      formatsAccepted=".pdf,.jpg,.jpeg,.png"
                      :files.sync="benefitDocumentation[index]"
                      :process="false"
                    />
                  </div>
                </v-card>
              </div>
            </v-card>
            <div v-if="isAprovedProcedure()" class="container d-flex justify-end margin-bot">
              <Button
                primary
                :text="$t('modules.portalalumno.admission.documentation.sendDocumentation')"
                :disabled="disabledSave"
                :loading="sendButton.loading"
                :success="sendButton.success"
                :error="sendButton.error"
                :successText="$t('actions.sent')"
                :errorText="'Error'"
                @end="resetButtonValues"
                @clicked="sendDocumentation"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import { $studentProcessing, $processings } from '../services'
import Button from '@/components/Button/Button'
import { mapGetters } from 'vuex'


export default {
  name: 'DocumentationUpload',
  components: {
    Breadcrumbs,
    FileUploader,
    Button,
  },
  data() {
    return {

      loading: false,
      sendButton: {
        loading: false,
        success: false,
        error: false
      },
      observation: null,
      normalDocumentationRequired: [],
      scholarshipDocumentationRequired:[],
      benefitDocumentationRequired: [],
      documentationUploaded:[],
      normalDocumentation:[],
      scholarshipDocumentation:[],
      benefitDocumentation:[],
      toUpload:[],
      procedureStatus: null,
    }
  },
  computed: {
      ...mapGetters({
              studentRegister: 'commons/getStudentActualRegister',
              studentId: 'commons/getStudentId',
              studentData: 'commons/getStudentData',
              studentUserId: 'commons/getStudentUserID'
          }),

    needScholarshipDocumentation(){
      return this.scholarshipDocumentationRequired.length > 0;
    },
    needBenefitDocumentation(){
      return this.benefitDocumentationRequired.length > 0;
    },

    status(){
      if(this.$route.params.dataDocumentation?.description == 'Carga de documentacion obligatoria'){
        return{
          isNew: true,
          class: 'status-revision',
          icon:'mdi-plus',
          text: 'Nueva',
        }
      }else{
        switch(this.procedureStatus?.value){
          case 91:
            return {
              isNew: false,
              class: 'status-info',
              icon: 'mdi-alert-outline',
              text: this.procedureStatus?.meaning,
            }
          case 93:
              return{
                isNew: true,
                class: 'status-success',
                icon:' mdi-check-circle-outline',
                text: this.procedureStatus?.meaning,
              }
          case 113:
            return {
              isNew: false,
              class: 'status-warning',
              icon: 'mdi-alert-outline',
              text: this.procedureStatus?.meaning,
            }
          default:
            return 0
        }
      }
    },

    observationText(){
      return this.observation ? this.observation : 'Sin observación.'
    },

    normalUploadedDocs() {
  const normalDocumentations = []

  this.normalDocumentationRequired.forEach((docType) => {
    const normalDocsFiltered = this.documentationUploaded?.filter((doc) => {
      return docType.id === doc.documentation.id
    })?.map((docFiltered) => {
      return this.mapDocumentationState(docFiltered)
    }) || []

    normalDocumentations.push(normalDocsFiltered);
  })

  return normalDocumentations;
},


    normalDocumentationRejected(){
      const rejecteds = []

      this.normalUploadedDocs.forEach((docType,docTypeIndex)=>{
        rejecteds[docTypeIndex] = !!docType.find(doc=> (!doc.isApproved) ) && this.normalDocumentation[docTypeIndex].length<1;
      })

      return rejecteds
    },

    scholarshipUploadedDocs(){

      const scholarshipDocumentations = [];

      this.scholarshipDocumentationRequired.forEach(docType => {
        const scholarshipDocsFiltered = this.documentationUploaded.filter(doc=>{
          return docType.id === doc.documentation.id;
        }).map(docFiltered => {
          return this.mapDocumentationState(docFiltered);
        })
        scholarshipDocumentations.push(scholarshipDocsFiltered);
      });
      return scholarshipDocumentations

    },

    scholarshipDocumentationRejected(){
      const rejecteds = []

      this.scholarshipUploadedDocs.forEach((docType,docTypeIndex)=>{
        rejecteds[docTypeIndex] = !!docType.find(doc => !doc.isApproved) &&
        (this.scholarshipDocumentation[docTypeIndex] && this.scholarshipDocumentation[docTypeIndex]?.length < 1);
      })

      return rejecteds
    },

    benefitUploadedDocs(){

      const benefitDocumentations = [];

      this.benefitDocumentationRequired.forEach(docType => {
        const benefitDocsFiltered = this.documentationUploaded.filter(doc=>{
          return docType.id === doc.documentation.id;
        }).map(docFiltered => {
          return this.mapDocumentationState(docFiltered);
        })
        benefitDocumentations.push(benefitDocsFiltered);
      });
      return benefitDocumentations

    },

    benefitDocumentationRejected(){
      const rejecteds = []

      this.benefitUploadedDocs.forEach((docType,docTypeIndex)=>{
        rejecteds[docTypeIndex] = !!docType.find(doc=>!doc.isApproved) && this.benefitDocumentation[docTypeIndex].length < 1;
      })

      return rejecteds
    },
    disabledSave(){
      if(this.status.isNew && this.status.text != 'Pendiente' && !this.status.text == 'Aprobado'){
        return (!!this.normalDocumentation.find((doc, i) => doc.length < 1 && !this.normalDocumentationRequired[i].optional)
                || !!this.scholarshipDocumentation.find(doc => doc.length < 1)
                || !!this.benefitDocumentation.find(doc => doc.length < 1)
                );
      }else if( this.status.text == 'Pendiente' || this.status.text == 'Observado' || this.status.text == 'Aprobado' ){
        return   (!this.normalDocumentation.find((doc) => doc.length > 0))
      }else{
        return(this.benefitDocumentationRejected.find(status => status)
              || this.scholarshipDocumentationRejected.find(status => status)
              || this.normalDocumentationRejected.find(status => status)
              );
      }
    }
  },
  beforeRouteEnter(to,_,next){
    const params = Object.keys(to?.params ?? {}).length
    if(!params) next(vm => vm.$router.go(-1))
    next()
  },
  mounted() {
    this.initialize()
  },
  methods: {
    isTeclab(){
      return this.$isTeclab
    },
    isAprovedProcedure(){
      if(this.procedureStatus && this.procedureStatus.meaning === 'Aprobado' && this.$isTeclab){
        return false
      } else {
        return true
      }
    },
    resetButtonValues() {
      this.sendButton.loading = false;
      this.sendButton.success = false;
      this.sendButton.error = false;
    },

    async initialize(){

      this.loading = true;
      await this.getNormalDocumentationRequired();
      await this.getScholarshipDocumentationRequired();
      await this.getBenefitDocumentationsRequired();
      const resp = await this.getProcedureStatus();
      if(!resp) this.isUpload()
      this.loading = false;
    },

    async getNormalDocumentationRequired(){
      const response = (await $processings.find(`required-documentations`)).data
      response.documentations.forEach(doc=>{
        this.normalDocumentationRequired.push(doc);

        this.normalDocumentation.push([])
      })
    },

    getScholarshipDocumentationRequired(){
      this.studentData.scholarship?.scholarshipDocumentation?.forEach(doc => {
        this.scholarshipDocumentationRequired.push(doc.documentation);
        this.scholarshipDocumentation.push([]);
      })
    },

    getBenefitDocumentationsRequired(){
      this.studentData.benefit?.benefitDocumentation?.forEach(doc => {
        this.benefitDocumentationRequired.push(doc.documentation);
        this.benefitDocumentation.push([]);
      })
    },
  getDniName(){
  if(this.$isIPP){
    return 'Cédula de identidad'
  } else {
    return 'DNI'
  }
},
getAnalitycName(){
  if(this.$isIPP){
    return 'Certificado de educación media'
  } else {
    return 'ANALITICO'
  }
},
getAnalitycInputPosition(){
  if(this.$isIPP){
    return 2
  } else {
    return 1
  }
},
async getProcedureStatus() {
      if (this.studentData && ( this.$route.params.dataDocumentation.key != 'MANDATORY_DOCUMENTATION' && this.$route.params.dataDocumentation ))  {
        const procedureStatus = await $studentProcessing.find(this.$route.params.dataDocumentation.id);
        this.procedureStatus = procedureStatus.data.processingStatus;
        this.observation = procedureStatus?.data.commentary
        this.documentationUploaded = procedureStatus?.data.documentations
      }else{
        return true
      }
    },

    getDocumentationsPropsToSave(docs_array, docs_types_array){
      docs_array.forEach((docType,docTypeIndex)=>{
          if (docType.length > 0) {
            this.toUpload.push({
              idDocumentationType: docs_types_array[docTypeIndex].id,
              file: docType,
            });
          }
      });
    },

    makeSingleDocumentationArray(){
      this.toUpload = [];
      this.getDocumentationsPropsToSave(this.normalDocumentation,
      this.normalDocumentationRequired,
      this.normalUploadedDocs);

      this.getDocumentationsPropsToSave(this.scholarshipDocumentation,
      this.scholarshipDocumentationRequired,
      this.scholarshipUploadedDocs);

      this.getDocumentationsPropsToSave(this.benefitDocumentation,
      this.benefitDocumentationRequired,
      this.benefitUploadedDocs);
    },

    mapDocumentationState(documentation){
      const isApproved = documentation?.docStatus.value === 94;
      return {
        isApproved,
        id: documentation.id,
        filename: documentation.fileName,
        status: isApproved ? 'A' : 'R',
        statusIcon: isApproved ? 'mdi-check-circle-outline' : (documentation?.docStatus.value == 96 || documentation?.docStatus.value == 257) ? 'mdi-pencil-circle' : (documentation?.docStatus.value == 258) ? 'mdi-alert-outline' : 'mdi-close-circle-outline',
        statusClass: isApproved ? 'approved' : (documentation?.docStatus.value == 96 || documentation?.docStatus.value == 257) ? 'pending' : (documentation?.docStatus.value == 258) ? 'pending' : 'rejected',
        statusText: isApproved ? 'Aprobado' : 'Rechazado',
        meaning:documentation?.docStatus.meaning,
        upload: documentation.upload,
        statusDoc: documentation?.docStatus.value
      }
    },

    async sendDocumentation() {
          this.makeSingleDocumentationArray();
          this.sendButton.loading = true;
          try {
            for (let i = 0; i < this.toUpload.length; i++) {
              const doc = this.toUpload[i]
              const formData = new FormData()

              const model = {
                studentRecord: this.studentData.academicRegister,
                user: this.studentUserId ? this.studentUserId : null,
                student: {
                  id: this.studentData.student.id,
                  user: {
                    name: this.studentData.student.user.name,
                  }
                },
                idDocumentation: doc.idDocumentationType,
              };

              doc.file.forEach(file => {
                formData.append('documentation', file);
              });
              formData.append('model', JSON.stringify(model));
              formData.append('idDocumentation', doc.idDocumentationType);

              await $studentProcessing.formData(formData, 'required-documentation')

            }
            this.$trackEvent('Tramites', 'Clk_BtnEnvDocObl_Tram', 'DocuObligatoria');
            this.sendButton.success = true
            this.$router.push('/procedures')

          } catch (error) {
            this.saveButton.error = true
            throw error
          } finally {
            this.sendButton.loading = false
          }


    },

    isUpload(){
      function filterEnabled(doc) {

       const result =  doc.map(el => {
            if ( el.statusDoc === 95 || el.upload === true || el.statusDoc === 257 || el.statusDoc === 258 ) {
              return  true
            }else{
              return false
            }
          })
           if (doc.length == 0) return [true]

          return result
      }
      this.normalUploadedDocs.map((el,index) => {
          this.normalDocumentationRequired[index].status = filterEnabled(el,index).includes(true)
      })
    },
    async downloadFile(file){
      const fileDownload = (await $studentProcessing.find(`/documentation/${file.id}`)).data
      if (fileDownload) {
        const link = document.createElement('a');
        link.download = file.filename;
        link.href = fileDownload;
        link.click();
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.noteIcon
  font-size: 20px
  color: v-bind('$vuetify.theme.defaults.light.primary.accent4')
.noteText
  color: v-bind('$vuetify.theme.defaults.light.primary.accent4')
  font-weight: 410

.files-list
  .v-list-item
    min-height: 0
    font-size: 0.9em
    font-weight: bold
    color: #aaa !important

    .v-list-item__content
      padding-top: 10px !important
      padding-bottom: 10px !important

    .v-icon.uploaded
      color: #007eff !important

    .v-icon.aproved
      color: #39c139 !important

    .v-icon.rejected
      color: #ff0000 !important

    .v-icon.pending
      color: #fa6702  !important

.current-status
  font-size: .8em
  display: flex
  justify-content: flex-start
  align-items: center

  .icon-container
    border-radius: 50%
    padding: 5px
    float: left
    display: flex
    justify-content: center
    align-items: center
    background: grey

    .v-icon
      color: white
      font-size: 18px

  &.status-success
    .icon-container
      background: #008614

  &.status-revision
    .icon-container
      background: #3d6cd9

  &.status-warning
    .icon-container
      background: #f58c05

.observation
  font-size: .7em

.documentation-container
  width: 100%

.documentation-card
  width: 49%
  min-height: 300px
  transition: .5s

/*In small resolution the card breaks  */
@media screen and (max-width: 768px)
  .documentation-card
    width: auto

.documentation-rejected
  border: 1px solid #ff8686 !important
</style>
