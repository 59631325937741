const whatsappTranslations = {
  es: {
       breadcrumbs: {
          title: 'Mensajes de Whatsapp',
          description: 'Enviar vía Whatsapp',
          buttom: {
            template:'Nuevo template',
          }
       },
       template_card: {
         title: 'Template de campaña',
         label_area:'Área',
         numero_de_area:'Número de Área',
         campaña:'Campaña',
         areaRequired: 'Debe seleccionar un área',
         numberRequired: 'Debe seleccionar un número de área',
         templateRequired: 'Debe seleccionar una campaña',
         filerequired: 'Debe cargar un archivo excel',
         subtitle: 'Campaña seleccionada: ',
       },
      fileUpload:{ 
        description: 'Destinatarios',
        title: 'Cargar excel con los DNI de los destinatarios y los parámetros correspondiente al template de mensaje seleccionado.',
        subtitle: 'Previsualización del mensaje',
      },
       cargarTemplate: {
         title: 'Carga de template',
         subtitle: 'Agregar nuevo template',
         area:'Área',
         nombre_del_template: 'Nombre del template',
         detalle_del_template: 'Detalle del template',
         areaRequired: 'Debe seleccionar un área',
         nameCampaignRequired: 'Colocar nombre del template',
         templateDetails:'Colocar detalle del template',
       },
        successMessage: {
          title: 'Mensaje enviado correctamente',
          subtitle:'Se ha enviado el mensaje a la lista de destinatarios seleccionados',
          errorMessage: 'Su mensaje no ha sido enviado',
        },
        saveNewTemplate: { 
          createNewTemplate: 'Su nueva campaña ha sido guardada'
        }
    },
    es_MX:{
      fileUpload:{ 
        title: 'Cargar excel con los CURP de los destinatarios y los parámetros correspondiente al template de mensaje seleccionado.',
      },
    },
    en: {
  
    }
  };
  
  
  export default whatsappTranslations
  