const bannersTranslations = {
  es: {
    table: {
      breadcrumbs: {
        title: 'Configuración de banners',
        description:'Administración de banners',
      },
      headers: {
        code: 'Código',
        description: 'Nombre',
        dateFrom: 'Fecha desde',
        dateTo: 'Fecha hasta',
        order: 'Orden',
        status: 'Estado',
      },
      actions: {
        errorDelete: 'Error al eliminar',
        bulkDelete: 'Eliminar banners',
      },
      messages: {
        bulkDeleteError: 'No se pudieron eliminar los banners seleccionados',
        deleteError: 'No se pudo eliminar el banner seleccionado',
        duplicatePopUpMessage: '¿Estás seguro de duplicar?',
        duplicateError: 'No se pudo duplicar el banner seleccionado',
      }
    },
    create: {
      banner: {
        preview: 'Vista previa'
      },
      breadcrumbs: {
        title: 'Crear nuevo banner',
        description:'Agregar nuevo banner',
      },
      mainData: {
        title: 'Datos principales',
        subtitle: 'Asigna la información básica del banner que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          name: 'Nombre',
          code: 'Código',
          order: 'Orden',
          fromDate: 'Fecha desde',
          toDate: 'Fecha hasta',
        },
      },
      bannerDesign: {
        title: 'Diseño banner',
        bannerType: {
          label: 'Tipo de Banner',
          placeholder: 'Seleccionar tipo de banner',
          manual: 'Creador de banner manual',
          fromFile: 'Imagen GIF/PNG',
          
        },
        headers: {
          text: 'Texto',
          background: 'Fondo',
          images: 'Imagenes',
          buttons: 'Botones',
        },
        labels: {
          mainText: 'Texto principal',
          secondaryText: 'Texto secundario',
          backgroundColor: 'Elegir color de fondo',
          imagePosition: 'Ubicación de la imagen',
          left: 'Izquierda',
          right: 'Derecha',
          corner: 'Esquina',
          buttonPosition: 'Ubicación del botón',
          buttonTitle: 'Título botón',
          buttonLink: 'Link de botón',
          bannerLink: 'Link de banner',
          googleAnalitycs: {
            title: 'Google Analytics',
            action: 'Acción',
            category: 'Categoria',
            label: 'Etiqueta'
          }
        }
      },
      successMessage: {
        title: 'Banner creado correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al crear el banner',
      }
    },
    update: {
      banner: {
        preview: 'Vista previa'
      },
      breadcrumbs: {
        title: 'Editar banner',
        description:'Modifica el banner seleccionado',
      },
      mainData: {
        title: 'Datos principales',
        subtitle: 'Asigna la información básica del banner que estás editando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          name: 'Nombre',
          code: 'Código',
          order: 'Orden',
          fromDate: 'Fecha desde',
          toDate: 'Fecha hasta',
        },
      },
      bannerDesign: {
        title: 'Diseño banner',
        bannerType: {
          label: 'Tipo de Banner',
          placeholder: 'Seleccionar tipo de banner',
          manual: 'Creador de banner manual',
          fromFile: 'Imagen GIF/PNG',
        },
        headers: {
          text: 'Texto',
          background: 'Fondo',
          images: 'Imagenes',
          buttons: 'Botones',
        },
        labels: {
          mainText: 'Texto principal',
          secondaryText: 'Texto sedundario',
          backgroundColor: 'Elegir color de fondo',
          imagePosition: 'Ubicación de la imagen',
          left: 'Izquierda',
          right: 'Derecha',
          corner: 'Esquina',
          buttonPosition: 'Ubicación del botón',
          buttonTitle: 'Título botón',
          buttonLink: 'Link de botón',
          bannerLink: 'Link de banner',
        }
      },
      successMessage: {
        title: 'Banner modificado correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al modificar el banner',
      }
    },
    validations: {
      nameRequired: 'Ingrese el nombre del banner',
      nameAlphanumericWithSpaces: 'Solo alfanuméricos',
      nameMaxLength: 'Máximo 60 caracteres',
      codeRequired: 'Ingrese el código del banner',
      codeAlphanumeric: 'Solo alfanuméricos',
      codeMaxLength: 'Máximo 10 caracteres',
      codeUnique: 'Código ya existente',
      orderRequired: 'Ingrese el orden',
      orderNumeric: 'Solo números',
      orderMinValue: 'Ingrese un número mayor a 0',
      dateRequired: 'Debe seleccionar una fecha',
      bannerTypeRequired: 'Debe seleccionar un tipo de banner',
      titleRequired: 'Ingrese el texto principal',
      titleMaxLength: 'Máximo 65 caracteres',
      commentMaxLength: 'Máximo 120 caracteres',
      backgroundImage: 'Debe seleccionar una imagen',
      buttonTextRequired: 'Ingrese título del botón',
      buttonTextMaxLength: 'Máximo {maxLength} caracteres',
      buttonTextAlphanumericWithSpaces: 'Solo alfanuméricos',
      buttonLinkRequired: 'Ingrese link del botón',
      buttonLinkMaxLength: 'Máximo 120 caracteres',
      bannerLinkRequired: 'Ingrese link del banner',
      bannerLinkMaxLength: 'Máximo 120 caracteres',
      analitycsAInputMaxLength: 'Máximo 32 caracteres',
      analitycsCategoryRequired: 'Ingrese la categoría',
      analitycsActionRequired: 'Ingrese la acción',
      analitycsLabelRequired: 'Ingrese la etiqueta'
    },
  },
  en: {

  }
};

export default bannersTranslations
