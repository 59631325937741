<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom">
            <Breadcrumbs :title="$t('modules.portalalumno.apps.breadcrumbs.title')" :description="$t('modules.portalalumno.apps.breadcrumbs.description')"></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">
                    <div class="my-6">
                            <!-- CANVAS_STUDENT_APP -->

                            <div class="d-flex align-left ">
                                <h4 class="detail my-2"></h4>
                                <h4 class="section-title ml-2">{{ $t('modules.portalalumno.apps.label.canvas_studen_app') }}</h4>
                            </div>
                            <div>
                                <h6 class="section-subtitle ml-2 my-5">{{ $t('modules.portalalumno.apps.label.instrucciones_app_mobile') }}</h6>
                                <ul class="section-content ml-1">
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_mobile_1') }}</li>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_mobile_2') }}</li>
                                <ul>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_mobile_3') }}</li>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_mobile_4') }}</li>
                                </ul>
                                </ul>
                            </div>
                            <div class="section-content-secondary my-6 ml-5" >{{ $t('modules.portalalumno.apps.label.download') }}</div>       
                                <v-row class="d-flex align-left justify-left my-6">
                                    <a @click="$trackEvent('APP', 'Clk_CanvGoog_App', 'APP_Canvas')" class="apps-google mx-3" target="_blank" href='https://play.google.com/store/apps/details?id=com.instructure.candroid&hl=es&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                        <img alt='Disponible en Google Play' :src="require('@/assets/images/portalalumno/google_play.png')" style="width: 198px; height: 55px;" />
                                    </a>
                                    <a @click="$trackEvent('APP', 'Clk_CanvAppl_App', 'APP_Canvas')" class="apps-apple mx-3" target="_blank" href="https://itunes.apple.com/ar/app/canvas-student/id480883488?mt=8">
                                        <img :src="require('@/assets/images/portalalumno/app_store_ios.svg')" style="width: 189px; height: 55px;" />
                                    </a>
                                </v-row>

                            <!-- ZOOM -->
                            <br>
                            <div class="d-flex align-left ">
                                <h4 class="detail my-2"></h4>
                                <h4 class="section-title ml-2">{{ $t('modules.portalalumno.apps.label.zoom') }}</h4>
                            </div>
                            <div>
                                <h6 class="section-subtitle ml-2 my-5">{{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga') }}</h6>
                                <ul class="section-content ml-2">
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_zoom_1') }} <a @click="this.$trackEvent('APP', 'Clk_UrlZoom_App', 'Zoom')" target="_blank" :href="appUrl.zoom.sitio" style="text-decoration: underline">{{ $t('modules.portalalumno.apps.zoom.zoom_descarga') }}</a></li>
                                <ul>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga_2') }}</li>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga_3') }}</li>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga_4') }}</li>
                                </ul>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga_5') }}</li>
                                </ul>
                                <div class="section-content-secondary my-8" v-html="$t('modules.portalalumno.apps.label.instrucciones_app_descarga_6')"></div>
                                <div class="d-flex align-left ml-15">
                                    <v-col cols="12" class="d-flex align-left my-6 mx-15 justify-left">
                                        <a @click="$trackEvent('APP', 'Clk_DescZoom_App', 'Zoom')"  target="_blank" href='https://zoom.us/download'>
                                        <img alt='Disponible en Google Play' :src="require('@/assets/images/portalalumno/descarga_zoom.png')" style="width: 230px; height: 55px;"/>
                                        </a>
                                    </v-col>
                                </div> 
                            </div>
                            <br>
                            <!-- EXAMENES -->
                            <br>
                            <div class="d-flex align-left ">
                                <h4 class="detail my-2"></h4>
                                <h4 class="section-title ml-2">{{ $t('modules.portalalumno.apps.klarway.exams') }}</h4>
                            </div>
                            <v-row>
                                <v-col cols="12" class="section-subtitle ml-5 mt-5">
                                        {{ $t('modules.portalalumno.apps.klarway.klarway_que_es') }}
                                </v-col>
                                <div class="section-content ml-5">{{ $t('modules.portalalumno.apps.klarway.klarway_que_es_1') }}</div>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="section-subtitle ml-5">
                                        {{ $t('modules.portalalumno.apps.klarway.klarway_como_funciona') }}
                                </v-col>
                                <div class="section-content ml-5">{{ $t('modules.portalalumno.apps.klarway.klarway_como_funciona_1') }}<i>{{ $t('modules.portalalumno.apps.klarway.klarway_como_funciona_2') }}</i></div>
                            </v-row>
                            <v-row class="my-3">
                                <v-col cols="12" class="mt-5 mb-1">
                                        <img  :src="require('@/assets/images/portalalumno/klarway.png')" height="75%"/>
                                </v-col>
                            </v-row>
                            <!-- KLARWAY -->
                        <v-row>
                            <v-col cols="12" class="section-content pt-0">
                                <div>
                                    <h3 class="section-subtitle">{{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento')}}</h3>
                                    <div type="1" class="section-content mb-6 my-6">
                                        {{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento_description') }} <br> {{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento_remember') }}
                                    </div>
                            <!-- VIDEO -->
                            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '10' : '12'" align-self offset-md="1">
                                            <div class="d-flex justify-left align-left"><div class="video-container">
                                                <iframe class="responsive-iframe" allowfullscreen
                                                        :src="appUrl.klarway.video_empadronamiento"
                                                        frameborder="0"
                                                ></iframe>
                                            </div>
                                        </div>
                                    </v-col>
                            <!-- BOTON VER INSTRUCTIVO -->
                            <div class="section-content-secondary">{{ $t('modules.portalalumno.apps.klarway.download')}}</div>
                                    <v-row  class="d-flex align-center justify-left my-6">
                                            <!-- <a @click="clickAction('Windows')" href="" target="_blank"> -->
                                                <a @click="$trackEvent('APP', 'Clk_DescWinKl_App', 'Klarway')" :href="appUrl.klarway.appWindows" class="mx-0 mr-6" target="_blank">
                                                    <img  style="margin-bottom: 10px; width: 230px; height: 55px;" :src="require('@/assets/images/portalalumno/btn-macos.svg')"/>
                                                </a>
                                            <!-- <a @click="clickAction('Mac')" href="" target="_blank"> -->
                                                <a @click="$trackEvent('APP', 'Clk_DescApplKl_App', 'Klarway')" :href="appUrl.klarway.appIOSDesktop" class="mx-0 ml-6" target="_blank">
                                                    <img  style="margin-bottom: 10px; width: 230px; height: 55px;" :src="require('@/assets/images/portalalumno/btn-windows.svg')"/>
                                                </a>
                                    </v-row>
                                </div>
                                <p>{{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento_7')}}</p>
                            <!-- BOTON VER INSTRUCTIVO -->
                                <div class="d-flex align-center justify-left my-6">
                                    <a @click="$trackEvent('APP', 'Clk_BtnInstrucKl_App', 'Klarway')" class="instructivo primary" target="_blank" :href="appUrl.klarway.condiciones"> {{ $t('modules.portalalumno.apps.klarway.klarway_instalacion_4') }}</a>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- SUMADI -->
                        <v-row v-if="$country !== 'peru' || $country !== 'mexico'">
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12">
                                        <img :src="require('@/assets/images/portalalumno/sumadi.png')" height="100%"/>
                                    </v-col>
                                    <v-col cols="12" class="section-subtitle">
                                        <p class="mb-0"><strong>{{ $t('modules.portalalumno.apps.sumadi.sumadi_que_es') }}</strong></p>
                                        <p class="section-content mb-0">{{ $t('modules.portalalumno.apps.sumadi.sumadi_que_es_1') }}</p>
                                    </v-col>
                                    <v-col cols="12" class="section-subtitle">
                                        <p class="mb-0"><strong>{{ $t('modules.portalalumno.apps.sumadi.sumadi_como_funciona') }}</strong></p>
                                        <p class="section-content mb-0">{{ $t('modules.portalalumno.apps.sumadi.sumadi_como_funciona_1') }}</p>
                                    </v-col>
                                    <v-col cols="12" class="section-subtitle">
                                        <p class="mb-0"><strong>{{ $t('modules.portalalumno.apps.sumadi.sumadi_instalacion') }}</strong></p>
                                        <ul>
                                            <li class="section-content" type="1">{{ $t('modules.portalalumno.apps.sumadi.sumadi_instalacion_1') }}</li>
                                            <li class="section-content" type="1">{{ $t('modules.portalalumno.apps.sumadi.sumadi_instalacion_2') }}</li>
                                            <li class="section-content" type="1">{{ $t('modules.portalalumno.apps.sumadi.sumadi_instalacion_3') }}</li>
                                            <li class="section-content" type="1">{{ $t('modules.portalalumno.apps.sumadi.sumadi_instalacion_4') }}</li>
                                        </ul>
                                    </v-col>
                                </v-row>
                                <v-row class="my-6 d-flex align-center justify-left">
                                    <v-col cols="8" sm="6" md="4" lg="3" xl="3">
                                        <a  @click="$trackEvent('APP', 'Clk_DescWinSMD_App', 'SUMADI')" target="_blank" href="https://app-electron-latam.sumadi.net/download/app/us/global">
                                            <img style="margin-bottom: 10px;" :src="require('@/assets/images/portalalumno/btn-macos.svg')"  width="90%" height="100%" />
                                        </a>
                                    </v-col>
                                    <v-col cols="8" sm="6" md="4" lg="3" xl="3" >
                                        <a @click="$trackEvent('APP', 'Clk_DescApplSMD_App', 'SUMADI')" href="https://app-electron-latam.sumadi.net/download/app/us/global" target="_blank">
                                            <img style="margin-bottom: 10px;"  :src="require('@/assets/images/portalalumno/btn-windows.svg')" width="90%" height="100%" />
                                        </a>
                                    </v-col>
                                </v-row>
                                <template v-if="appUrl.sumadi?.instructivo">
                                    <hr class="my-6" :style="$vuetify.breakpoint.mdAndUp ? 'margin-right:15%;':''">
                                    <p>{{ $t('modules.portalalumno.apps.sumadi.sumadi_empadronamiento')}}</p>
                                    <!-- BOTON VER INSTRUCTIVO -->
                                    <div class="d-flex align-center justify-center my-6">
                                        <a @click="$trackEvent('APP', 'Clk_BtnInstrucSMD_App', 'SUMADI')" class="instructivo primary" target="_blank" :href="appUrl.sumadi.instructivo" > {{ $t('modules.portalalumno.apps.sumadi.sumadi_ver_instructivo') }}</a>
                                    </div>
                                </template>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    export default {
        name: 'AppsView',
        components: {
            Breadcrumbs,
        },
        methods: {
            clickAction(action) {
                this.$trackEvent('Apps', action, 'Descargas')
            },
        },
        computed:{
            appUrl(){
                if(this.$country === 'argentina'){
                    return {
                        klarway :{
                            video_empadronamiento: 'https://5880041.fs1.hubspotusercontent-na1.net/hubfs/5880041/Examinaci%C3%B3n/TECLAB%20Registro%20de%20identidad%20en%20Klarway.mp4',
                            instructivo: 'https://f.hubspotusercontent40.net/hubfs/5880041/EX%C3%81MENES%20FINALES%20KLARWAY/Instructivo%20Klarway-%20INSTALACI%C3%93N%20-Teclab.pdf',
                            revisar_instructivo: 'https://f.hubspotusercontent40.net/hubfs/5880041/EX%C3%81MENES%20FINALES%20KLARWAY/Instructivo%20Klarway-%20INSTALACI%C3%93N%20-Teclab.pdf',
                            condiciones: 'https://5880041.fs1.hubspotusercontent-na1.net/hubfs/5880041/Examinaci%C3%B3n/Instructivo%20Klarway%20registro%20nuevo.pdf',
                            appWindows:'https://downloads.klarway.com/',
                            appIOSDesktop:'https://downloads.klarway.com/',
                        },
                        zoom :{
                            sitioDescarga: 'https://zoom.us/download',
                            sitio: 'https://zoom.us/'
                        },
                    }
                }
                if(this.$country === 'mexico'){
                    return {
                        klarway :{
                            video_empadronamiento: 'https://24271670.fs1.hubspotusercontent-na1.net/hubfs/24271670/examenes-final-klarway.mp4',
                            instructivo: 'https://f.hubspotusercontent40.net/hubfs/5880041/EX%C3%81MENES%20FINALES%20KLARWAY/Instructivo%20Klarway-%20INSTALACI%C3%93N%20-Teclab.pdf',
                            revisar_instructivo: 'https://f.hubspotusercontent40.net/hubfs/5880041/EX%C3%81MENES%20FINALES%20KLARWAY/Instructivo%20Klarway-%20INSTALACI%C3%93N%20-Teclab.pdf',
                            condiciones: 'https://24271670.fs1.hubspotusercontent-na1.net/hubfs/24271670/instructivo-klarway.pdf',
                            appWindows:'https://downloads.klarway.com/',
                            appIOSDesktop:'https://downloads.klarway.com/',
                        },
                        zoom :{
                            sitioDescarga: 'https://zoom.us/download',
                            sitio: 'https://zoom.us/'
                        },
                    }
                }
                else{
                    return {
                        sumadi: {
                            instructivo: 'https://blog.ipp.cl/hubfs/Instructivo%20Sumadi-IPP%201.pdf',
                        },
                        klarway :{
                            video_empadronamiento: 'https://instituto.ipp.cl/hubfs/IPP%20Registro%20de%20identidad%20en%20Klarway-1.mp4',
                            instructivo: 'https://f.hubspotusercontent10.net/hubfs/6058217/EXAMENES%20FINALES%20KLARWAY/Instructivo%20Klarway-IPP%20-%20FINAL.pdf',
                            revisar_instructivo: 'https://f.hubspotusercontent10.net/hubfs/6058217/EXAMENES%20FINALES%20KLARWAY/Instructivo%20Klarway-IPP%20-%20FINAL.pdf',
                            condiciones: 'https://instituto.ipp.cl/hubfs/INSTRUCTIVO%20EX%C3%81MENES%20OBLIGATORIOS%20IPP.pdf',
                            appWindows:'https://downloads.klarway.com/',
                            appIOSDesktop:'https://downloads.klarway.com/',
                        },
                        zoom :{
                            sitio: 'https://zoom.us/download'
                        },
                    } 
                }
                
            }
        }
    }
</script>

<style scoped lang="sass">
    .container-custom
        font-family: "Roboto", sans-serif
        color: #828482

    .detail
        background-color: var(--v-primary-base)
        width: 20px
        height: 20px
        border-radius: 6px
    .section-title
        font-family: "Roboto-Bold", sans-serif
        color: #05080f
        font-weight: 700
        font-size: 24px
    .section-subtitle
        color: #353c49
        font-family: "Roboto-Medium", sans-serif
        font-weight: 400
        font-size: 20px
        padding: 14px 15px 0px 0px
    .section-content
        font-size: 16px
        font-family: "Roboto-Regular", sans-serif
    .section-content-secondary
        font-size: 14px
        font-family: "Poppins-Regular", sans-serif 
    .apps-google img 
        width: 170px
    .apps-apple img 
        width: 170px
    .apps-huawei img
        width: 170px
    .list-klarway
        list-style: none
    .instructivo 
        font-family: "DM Sans"
        font-size: 12px
        font-weight: 700
        text-decoration: none
        padding: 13px 34px 13px 34px
        border-radius: 23px
        color: white
        width: 178px
    .video-container
        position: relative
        overflow: hidden
        width: 90%
        padding-top: 56.25%
    .responsive-iframe
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0
        width: 100%
        height: 100%
</style>
