<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :title="$t('modules.reports.table.breadcrumbs.title')"
                    :description="$t('modules.reports.table.breadcrumbs.description')"
                >
                    <Button
                      @clicked="tmpDrawerOpen('report')"
                      icon='mdi-download'
                      :text="$t('modules.reports.table.drawer.title')"
                    ></Button>
                </Breadcrumbs>
                <v-row class="my-4">
                    <a :href="finalUrl" target="_blank" download ref="downloadButton" v-show="false"></a>
                </v-row>
                <v-row justify="center" class="mb-10">
                    <v-col cols="12" v-if="!availableReports.length">
                        <div class="text-center mt-6">
                            <v-icon x-large class="mb-4">mdi-file-chart-outline</v-icon>
                            <h3 class="mt-0 mb-6 text-muted">{{ $t(`modules.reports.table.no_report`) }}</h3>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4" v-for="report in availableReports" :key="report.id">
                        <v-card class="report" hover  :loading="report.loading">
                            <v-card-title  class="pb-0 justify-end"   >
                                <v-icon v-if="report.isInfo" class="icon" style="position:absolute;float:rigth;" @click="tmpDrawerOpen('info', report)">mdi-help-circle-outline</v-icon>
                            </v-card-title>
                            <div @click="requestConfirmation(report)">
                                <v-card-title class="pb-0 justify-center">
                                    <v-icon class="icon" x-large>{{ report.icon }}</v-icon>
                                </v-card-title>
                                <v-card-title class="justify-center text-center text-break">{{ report.name }}</v-card-title>
                                <v-card-text class="text-center mb-4 pb-0" style="min-height: 60px;">{{ report.description }}</v-card-text>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <TemporaryRightDrawer
          :open="tmpDrawer.open ? tmpDrawer.open : tmpDrawerInfo.open "
          @tmpDrawerOpen="tmpDrawerOpen"
          :title=" tmpDrawer.open ? tmpDrawer.title : tmpDrawerInfo.title  "
          :description=" tmpDrawer.open ? tmpDrawer.description : tmpDrawerInfo.description"
        >
        <div v-if="tmpDrawer.open">
          <ReportsDownload
            :lastReports="lastReports"
            @closeDrawer="()=>{this.tmpDrawer.open = false}"
          ></ReportsDownload>
        </div>
        <div v-if="tmpDrawerInfo.open">
          <ReportsInfo
           :reportInfo="reportInfo"
            @closeDrawer="()=>{this.tmpDrawerInfo.open = false}"
          ></ReportsInfo>
        </div>
        </TemporaryRightDrawer>
        <RequestReportPopUp
            :open="openModal"
            @close="closeModal"
            :periods="terms"
            :report="report"
            @requestReport="requestReport"
            :response="response"
            :successMessage="successMessage"
            :requiredTerms="requiredTerms"
            :createButton="createButton"
        />
    </v-container>
</template>

<style lang="sass" scoped>
    .report
        border: 1px solid white

        &:hover
            color: var(--v-primary-base)
            background-color: rgba(96, 135, 225, 0.2)
            border: 1px solid #6087e1

            .icon
                color: var(--v-primary-base)
</style>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import Button from '@/components/Button/Button';
    import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
    import ReportsDownload from '#/portaladministrativo/Reports/Views/ReportsDownload';
    import ReportsInfo from '#/portaladministrativo/Reports/Views/ReportsInfo';
    import RequestReportPopUp from '../Components/RequestReportPopUp.vue'
    import { $reports, $terms } from '../Services';
    import { mapGetters } from 'vuex';
    import moment from 'moment'
    import info from '../ReportsInfo/index.js'

    export default {
        name: 'ReportsTable',
        components: {
            Breadcrumbs,
            Button,
            TemporaryRightDrawer,
            ReportsDownload,
            ReportsInfo,
            RequestReportPopUp
        },
        data () {
            return {
                report: null,
                successMessage: {
                    actionPrimary: null,
                    title: null,
                    type: null,
                    text: null,
                },
                createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                response: false,
                openModal: false,
                requiredTerms: false,
                finalUrl: '',
                loading: false,
                selectedTerm: null,
                terms: [],
                reports: [],
                lastReports: [],
                reportInfo: [],
                tmpDrawer: {
                  open: false,
                  title: this.$t('modules.reports.table.drawer.title'),
                  description: this.$t('modules.reports.table.drawer.description')
                },
                tmpDrawerInfo: {
                    open: false,
                    title: this.$t('modules.reports.table.drawer_info.title'),
                    description: this.$t('modules.reports.table.drawer_info.description')
                }
            }
        },
        mounted() {
            this.fetchTerms()
            this.fetchReports()
        },
        methods: {
            tmpDrawerOpen (drawer, report) {
                let openDrawer;
                switch (drawer) {
                case 'info':
                    openDrawer = 'info';
                    this.reportInfo = report;
                    break;
                case 'report':
                    openDrawer = 'report';
                    this.fetchLastReports();
                    break;
                default:
                    openDrawer = null;
                }

                this.tmpDrawerInfo.open = openDrawer === 'info';
                this.tmpDrawer.open = openDrawer === 'report';
            },
            async fetchLastReports() {
              try {
                const {data} = await $reports.student.find('getFilesNames', null, { params: { userId: this.userId } })
                this.lastReports = data.map(item =>{
                    item.date= moment(item.date).locale('es').format('l LT');
                    return item
                })
                const hasPendingReport = this.lastReports.find(item => item.status.value == 282)
                if(hasPendingReport){
                    setTimeout(()=>{
                        this.fetchLastReports()
                    },120000)
                }
              } catch (error) {
                this.lastReports = []
                throw error
              }
            },
            requestConfirmation(report) {
                if (!this.loading) {
                    if(report.reportTerm){
                        this.openModal = true
                        this.report = report
                        this.requiredTerms = true
                    } else {
                        this.openModal = true
                        this.requiredTerms = false
                        this.report = report
                        }
                    }
            },
            async fetchReports() {
               const infoReports = Object.values(info).map(item => Object.keys(item)).flat()
                try {
                    const resp = await $reports.list.find(null, null, {params: { page: 0, length: 500 }});
                    this.reports = resp.data.map(report => {
                        report.isInfo = infoReports.find(item => item === report.url)
                        report.loading = false
                        return report
                    })
                    this.reports.sort((a, b) => (a.name > b.name) ? 1 : -1)
                } catch (error) {
                    this.reports = []
                    throw error
                }
            },
            async fetchTerms() {
              try {
                const params = {page: 0, length: 25, orderBy: 'classStartDate', orientation: 'desc'}
                const {data} = await $terms.find(null, null, { params });
                const cursado = [{header:this.$t('modules.reports.table.dropdown.headers.header1')}, ...data.content?.filter(e => e.termType.value == 193)]
                const practica = [{ divider: true } , { header: this.$t('modules.reports.table.dropdown.headers.header2')},...data.content?.filter(e => e.termType.value == 281)]
                const onBoarding = [{ divider: true } , {header:this.$t('modules.reports.table.dropdown.headers.header3')}, ...data.content?.filter(e => e.termType.value == 194)]
                const nivelacion = [{ divider: true } , {header:this.$t('modules.reports.table.dropdown.headers.header4')},...data.content?.filter(e => e.termType.value == 195)]
                this.terms = [ ...cursado, ...practica, ...onBoarding, ...nivelacion]
              } catch (error) {
                this.terms = [];
                throw error;
              }
            },
            async requestReport(body) {
                const report = { ...body.report }
                const term = { ...body.term }
                this.createButton.loading = true
                try {
                    this.loading = true
                    report.loading = true
                    await $reports[report.service].find(report.url, null, {responseType: 'blob', params: {termId: term.id, userId: this.userId}});
                    this.createButton.loading = false
                    this.createButton.success = true
                    this.response = true
                    this.successMessage.title = this.$t('modules.reports.table.request.successTitle');
                    this.successMessage.text = this.$t('modules.reports.table.request.successContent');
                    this.successMessage.type = 'success';
                    this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback:() => { this.closeModal() }}
                } catch (error) {
                    this.response = true
                    this.createButton.loading = false
                    this.createButton.error = true
                    const reportName = term.code ?  `${report.name} ${term.code}` : `${report.name}`
                    if(error.status == 217){
                        this.successMessage.title = this.$t('modules.reports.table.request.successTitle');
                        this.successMessage.text = this.$t('modules.reports.table.request.successContent');
                        this.successMessage.type = 'success';
                        this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback:() => { this.closeModal() }}
                    } else {
                        this.successMessage.type = (error.status == 203) ? 'warning' : 'error'
                        this.successMessage.title = (error.status == 203) ? ' ' : this.$t('actions.error')
                        this.successMessage.text = (error.status == 203) ? this.$t('modules.reports.table.request.errorTime', {report: reportName}) : this.$t('modules.reports.table.request.error')
                        this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.closeModal() }}
                        throw error
                    }
                } finally {
                    this.loading = false
                    report.loading = false
                }
            },
            closeModal(){
                this.openModal = false
                this.response = false
            }
        },
        computed: {
            availableReports() {
                const permissions = []
                Object.entries(this.$permissions.portaladministrativo.reports).forEach(([permission, status]) => { if (status && permission != 'NAME' && permission != 'STATUS' && permission != 'READ') permissions.push(permission) })
                return this.reports.filter(report => permissions.indexOf(report.permission) >= 0)
            },
            ...mapGetters({
                userId: 'commons/getUserID',
            })
        },
    }
</script>
