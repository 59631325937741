
<template>
  <div>
    <div v-if="loading" class="d-flex justify-center align-center mt-5">
      <v-progress-circular class="mx-auto" indeterminate color="primary"></v-progress-circular>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div v-for="(item, index) in items" :key="index">
            <v-hover v-slot:default="{ hover }">
              <v-card
                :class="['mx-auto card-outter', item.specialCard ? 'special-card' : 'transparent-card', hover ? 'color-selected' : 'color-unselected']"
                elevation="0"
                @click="$emit('chipClick', {index, item})"
              >
                <v-tooltip top color="rgba(0, 0, 0, 0.8)" v-if="item.description">
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" three-line>
                      <v-list-item-content class="resetMargenes">
                        <v-row>
                          <v-col :cols="showChips ? 7 : 10">
                            <v-list-item-title
                              class="primary--text text--lighten-1 pt-1"
                              :class="['header-title text-sm-left align-self-center align-self-sm-start', item.specialCard ? 'white--text' : '']"
                            >
                              <v-badge v-if="item.specialCard" color="red" content="1" class="ml-2 mr-6"></v-badge>
                              {{ item.name }}
                            </v-list-item-title>
                          </v-col>
                          <v-col v-if="showChips" cols="5">
                            <v-chip class="text-chip hidden-md-and-down" rounded :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
                          </v-col>
                          <v-col v-else cols="1" class="d-flex justify-end">
                            <v-icon :class="{'white--text': item.specialCard}">mdi-chevron-right</v-icon>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-list-item-subtitle
                              class="subtitle text-sm-left"
                              :class="item.specialCard ? 'white--text' : ''"
                            >
                              {{ item.description }}
                            </v-list-item-subtitle>
                            <v-chip v-if="showChips" class="text-chip hidden-lg-and-up" x-small rounded :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <div class="text-left">
                    <span>{{ item.description }}</span>
                  </div>
                </v-tooltip>
                <v-list-item v-else>
                  <v-list-item-content class="resetMargenes">
                    <v-row>
                      <v-col :cols="showChips ? 10 : 8" >
                        <v-list-item-title
                          class="primary--text text--lighten-1 pt-1"
                          :class="['header-title text-sm-left align-self-center align-self-sm-start', item.specialCard ? 'white--text':'']">
                          <v-badge v-if="item.specialCard" color="red" content="1" class="ml-2 mr-6"></v-badge>
                          {{ item.name }}
                        </v-list-item-title>
                      </v-col>
                      <v-col cols="3" class="d-flex justify-end text">
                        <v-chip v-if="showChips" class="text-chip " rounded :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
                        <v-icon v-else :class="{'white--text': item.specialCard}">mdi-chevron-right</v-icon>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-hover>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  props: {
    items: Array,
    showChips: Boolean,
    loading: Boolean,
  },
  methods: {
    getColor(status) {
      for (let i = 0; i < this.items.length; i++) {
        if (status === this.items[i].status) return this.items[i].value;
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.subtitle
  font-size: 13px
  height: auto
  white-space: normal
  -webkit-line-clamp: unset !important
.header-title
  font-size: 15px
  font-weight: bold
  text-align: left
  white-space: normal
.card-outter
  background-color: var(--v-primary-lighten5)
  border-radius: 10px !important
.transparent-card
  background-color: transparent
  margin: 10px
  border-radius: 10px
  &:hover
    background-color: white
.special-card
  background-color: var(--v-primary-lighten2)
  color: white
  margin: 10px
  border-radius: 10px
  &:hover
    background-color: var(--v-primary-lighten1)
.text-chip
  font-size: 12px
  // margin-top: 20px !important
.container-text
  width: 100%
.resetMargenes
  margin: 0
  padding: 0
</style>
