<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed($event)"></AsideBar>
      <v-container fluid class="scrollableContent pa-0">
        <v-container class="container-custom px-6">
          <Breadcrumbs
            :title="$t('modules.scholarships.table.breadcrumbs.title')"
            :description="$t('modules.scholarships.table.breadcrumbs.description')"
          >
            <Button v-if="canCreate" @clicked="newScholarship" icon="mdi-plus" :text="$t('actions.new_female')" depressed></Button>
          </Breadcrumbs>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <SuperTable
                :headers="headers"
                :users="scholarships"
                :clear-selected="clearSelected"
                :pageCount="pagination.pagesAmount"
                :page="pagination.page"
                :permissions="{read: canRead, update: canUpdate, delete: canDelete}"
                :total-items="totalItems"
                @searchAction="searchAction"
                :sortBy.sync="sort.sortBy"
                :sortDesc.sync="sort.sortDesc"
                @input-pagination="onInputPagination"
                @length-pagination-change="onLengthPaginationChange"
                @bulk-delete="(scholarships)=>{bulkDeletePopUp(scholarships)}"
                @deleteUser="(id)=>deleteScholarshipPopUp(id)"
                @updateUser="(id)=>{updateScholarship(id)}"
                @openDetails="(id)=>{openDetails(id)}"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import AsideBar from '@/components/AsideBar/AsideBar';
import Button from '@/components/Button/Button';
import { $products, $scholarships } from '../services';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'ScholarshipsTable',
  components: {
    SuperTable,
    Breadcrumbs,
    AsideBar,
    Button
  },
  data() {
    return {
      clearSelected: '',
      scholarships: [],
      billingProduct: [],
      rawScholarships: [],
      discounts: [],
      asidebar: {
        title: this.$t('modules.scholarships.table.filters.title'),
        items: [
          {
            name: this.$t('modules.scholarships.table.filters.product'),
            filters: [
              {
                name: 'Matricula',
                type: 'check',
                items: [],
                value: true,
                dataFiltered: ''
              },
              {
                name: 'Arancel',
                type: 'check',
                value: true,
                items: [],
                dataFiltered: ''
              }
            ]
          },
          {
            name: this.$t('modules.scholarships.table.filters.state'),
            filters: [
              {
                name: 'Activo',
                type: 'check',
                value: true,
                items: [],
                dataFiltered: ''
              },
              {
                name: 'Inactivo',
                type: 'check',
                value: true,
                items: [],
                dataFiltered: ''
              }
            ]
          },
          {
            name: this.$t('modules.scholarships.table.filters.discountType'),
            filters: [
              {
                name: 'Porcentual',
                type: 'check',
                items: [],
                value: true,
                dataFiltered: ''
              },
              {
                name: 'Monto',
                type: 'check',
                items: [],
                value: true,
                dataFiltered: ''
              }
            ]
          }
        ]
      },
      headers: [
        {
          text: this.$t('modules.scholarships.table.headers.code'),
          value: 'code'
        },
        {
          text: this.$t('modules.scholarships.table.headers.name'),
          value: 'description'
        },
        {
          text: this.$t('modules.scholarships.table.headers.status'),
          value: 'status'
        },
        {
          text: this.$t('modules.scholarships.table.headers.alumns'),
          value: 'alumns',
          sortable: false,

        },
        {
          text: this.$t('modules.scholarships.table.headers.discounts'),
          value: 'discountsTypes',
          sortable: false,
        },
        {
          text: this.$t('modules.scholarships.table.headers.from'),
          value: 'from'
        },
        {
          text: '',
          value: 'action',
          sortable: false,
        }
      ],
      totalItems: null,
      pagination: {
          limit: 20,
          page: 1,
          pageAmount: 1
      },
      sort: {
          sortBy: 'code',
          sortDesc: false,
      },
      loading:false,
    };
  },
  mounted() {
    this.getBillingProducts();
    
  },
  computed: {
    canRead() {
      return this.$permissions.portaladministrativo.prices.scholarships.READ
    },
    canCreate() {
      return this.$permissions.portaladministrativo.prices.scholarships.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.scholarships.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.scholarships.DELETE
    },
    ...mapGetters({
      configurationValues: 'settings/getAllCurrentValues',
      configurationOptions: 'settings/getAllOptions'
    })
  },
  methods: {
    async getBillingProducts() {
      try {
        this.billingProduct = await $products.find();
        this.billingProduct = this.billingProduct.data.content.map(product => {
          return {
            description: product.name,
            id: product.id,
            type: 'check',
            items: [],
            value: true,
            dataFiltered: ''
          };
        });
        this.asidebar.items[0].filters = this.billingProduct;
        this.fetchData();
      } catch (error) {
        this.billingProduct = []
        throw error;
      }
    },
      onInputPagination(event) {
        this.pagination.page = event;
        this.fetchData();
      },
      onLengthPaginationChange(event) {
        this.pagination = {
            page: 1,
            limit: event,
            pagesAmount: 1
        };
        this.fetchData();
      },
      searchAction(data) {
        this.pagination.page = 1;
        this.search = data;
        this.fetchData();
      },
    changed() {
      let filteredItems = this.rawScholarships;
      this.asidebar.items.forEach(element => {
        if (element.name === this.$t('modules.scholarships.table.filters_asidebar.product')) {
          filteredItems = filteredItems.filter(item => {
            return item;
          });
        }
        if (element.name === this.$t('modules.scholarships.table.filters_asidebar.state')) {
          filteredItems = filteredItems.filter(item => {
            if (
              element.filters[0].name === this.$t('modules.scholarships.table.filters_asidebar.active') &&
              element.filters[0].value === true
            ) {
              if (item.status === true) {
                return item;
              }
            }
            if (
              element.filters[1].name === this.$t('modules.scholarships.table.filters_asidebar.inactive')&&
              element.filters[1].value === true
            ) {
              if (item.status === false) {
                return item;
              }
            }
          });
        }
        if (element.name === this.$t('modules.scholarships.table.filters_asidebar.discountType')) {
          filteredItems = filteredItems.filter(item => {
            return item;
          });
        }

        return false;
      });
      this.scholarships = filteredItems;
      this.fetchData();
    },
      deleteScholarshipPopUp(id) {
      const scholarship = this.scholarships.find(
        scholarship => scholarship.id == id
      );
      this.$store.dispatch('commons/openPopUp', {
        title: '¿Esta seguro de eliminar?',
        content: [
          {
            value: scholarship.description
          }
        ],
        actionPrimary: {
          text: 'Aceptar',
          callback: () => this.deleteScholarship(id)
        },
        actionSecondary: {
          text: 'Cancelar',
          callback() {}
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary'
      });
    },
    async fetchData() {
        this.clearSelected = new Date().getMilliseconds().toString();
        this.loading=true;

        const params = { 
          id: this.$route.params.id,
          page: this.pagination.page == 0 ? this.pagination.page=0 : this.pagination.page - 1 ,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc',
          search: this.search,
      }
      try {
        const filterParams = {
            products: [],
            status: [],
            discountTypes: [],
        }
        this.asidebar.items[0].filters.forEach( items => {
          if (items.value) filterParams.products.push(items.id);
        })
        this.asidebar.items[1].filters.forEach( items => {
          if (items.value) filterParams.status.push(items.name === 'Activo');
        })
        const discountTypesDictionary = {
            Porcentual:'PERCENTAGE',
            Monto: 'AMOUNT',
          };
        this.asidebar.items[2].filters.forEach( items => {
          if (items.value) filterParams.discountTypes.push(discountTypesDictionary[items.name]);
        })
        const scholarships = await $scholarships.filter(filterParams, { params });
        this.totalItems = scholarships.data.totalElements;
        this.pagination.pagesAmount = scholarships.data.totalPages
        this.scholarships = this.formatScholarships(scholarships.data.content);
        this.rawScholarships = JSON.parse(JSON.stringify(this.scholarships));
      } catch (error) {
        this.totalItems = 0
        this.scholarships = []
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        throw error
      }
    },
    formatScholarships(scholarships) {
      return scholarships.map(scholarship => {
        // let count = 0
          const discountTypesDictionary = {
            PERCENTAGE:'Porcentual',
            AMOUNT: 'Monto',
          };
        return {
          id: scholarship.id,
          code: scholarship.code,
          description: scholarship.description,
          status: scholarship.status,
          alumns: scholarship.studentCount,
          discountsTypes: discountTypesDictionary[scholarship.discountType],
          from: this.configurationValues.dateFormat
            ? moment(scholarship.from).format(
                this.configurationOptions.dateFormat
                  .find(
                    config =>
                      config.value === this.configurationValues.dateFormat
                  )
                  .text.toUpperCase()
              )
            : moment(scholarship.from).format('YYYY-MM-DD')
        };
      });
    },
    bulkDeletePopUp(scholarships) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.scholarships.table.message.title'),
        content: scholarships.map(scholarship => ({
          value: scholarship.name
        })),
        actionPrimary: {
          text: this.$t('modules.scholarships.table.message.deleteAction'),
          callback: async () => {
            try {
              await this.bulkDelete(scholarships);
            } catch {
              this.$store.dispatch('commons/openPopUp', {
                title: this.$t(
                  'modules.scholarships.table.error_message.bulk_delete_message'
                ),
                content: scholarships.map(scholarship => ({
                  value: scholarship.name
                })),
                actionPrimary: { text: 'Aceptar', callback() {} },
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary'
              });
            }
          }
        },
        actionSecondary: {
          text: this.$t('modules.subjects.table.message.cancelAction'),
          callback() {}
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary'
      });
    },
    async bulkDelete(scholarships) {
      const itemsForDelete = scholarships.map(scolar => {
        return { id: scolar.id };
      });

      try {
        await $scholarships.bulkDelete(itemsForDelete);
      } finally {
        this.fetchData();
      }
    },
    newScholarship() {
      if (this.canCreate) this.$router.push('/scholarships/create');
    },
    updateScholarship(id) {
      if (this.canUpdate) this.$router.push(`scholarships/update/${id}`);
    },
    openDetails(id) {
      if (this.canRead) this.$router.push(`scholarships/details/${id}`);
    },
    async deleteScholarship(id) {
      try {
        await $scholarships.delete(id);
        this.scholarships = this.scholarships.filter(
          scholarship => scholarship.id !== id
        );
      } finally {
        this.fetchData();
      }
    }
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
};
</script>
