const examExceptionTranslations = {
    es_CL: {
        table: {
            headers: {
                identification: 'RUT',
            },
        }
    },
    es_PE: {
        create: {
            headers: {
                subject: 'Curso'
            },
            stepper: {
                justify:{
                    component:{
                        text: 'Se inscribirá por excepción al estudiante {student} en la siguiente mesa.',
                        headers:{
                            subject: 'Curso',
                        },
                    }
                },
                availableBoard:{
                    component: {
                        subtitle:'Selecciona el curso al cual deseas inscribir al estudiante',
                    }
                },
                confirmation:{
                    component: {
                        title:'Estudiante inscrito correctamente',
                        subtitle:'El estudiante seleccionado ya está habilitado para rendir',
                    }
                },
                subjectSelection:{
                    title:'Selección de curso',
                    component:{
                        title:'Cursos',
                        subtitle:'Selecciona el curso al cual deseas inscribir al estudiante.',
                        headers:{
                            subject: 'Curso'
                        },
                    },
                },
            }

        },
    },
    es_MX: {
        create: {
            headers: {
                subject: 'Materia'
            },
            stepper: {
                justify:{
                    component:{
                        text: 'Se inscribirá por excepción al estudiante {student} en la siguiente mesa.',
                        headers:{
                            subject: 'Materia',
                        },
                    }
                },
                availableBoard:{
                    component: {
                        subtitle:'Selecciona la materia al cual deseas inscribir al estudiante',
                    }
                },
                confirmation:{
                    component: {
                        title:'Estudiante inscrito correctamente',
                        subtitle:'El estudiante seleccionado ya está habilitado para rendir',
                    }
                },
                subjectSelection:{
                    title:'Selección de materia',
                    component:{
                        title:'Materias',
                        subtitle:'Selecciona la materia al cual deseas inscribir al estudiante.',
                        headers:{
                            subject: 'Materia'
                        },
                    },
                },
            }

        },
    },
    es: {
        errors: {
            required: 'Campo requerido',
            minLength: 'Debe seleccionar al menos uno',
            hourRequired: 'Selecciona una hora',
            datePair: 'Fecha inválida',
            dateRequired: 'Ingresa una fecha'
        },
        table: {
            breadcrumbs: {
                title: 'Inscripción por excepción',
                description: 'Administración de excepciones',
            },
            headers: {
                identification: 'DNI',
                name: 'Nombre',
                lastname: 'Apellido',
                code: 'Código',
                career: 'Carrera',
                ceo: 'CSE',
            }
        },
        create: {
            breadcrumbs: {
                title: 'Inscripción a examen por excepción',
                description: '{student} / {career}',
            },
            headers: {
                subject: 'Asignatura',
                date: 'Fecha pedido',
                exam_type: 'Tipo de exámen',
                lab_type: 'Tipo de mesa',
                exam_date: 'Fecha de exámen',
                ceo: 'CSE',
                status: 'Estado',
            },
            labels: {
                history: 'Ver historial de inscripciones',
            },
            stepper: {
                justify:{
                    title:'Justificación',
                    button:'',
                    component:{
                        text: 'Se inscribirá por excepción al alumno {student} en la siguiente mesa.',
                        headers:{
                            day: 'Día',
                            hour: 'Hora',
                            ceo: 'Centro',
                            subject: 'Asignatura',
                        },
                        selectReason: 'Selecciona un motivo para justificar esta excepción',
                        reason: 'Motivo',
                        selectBoardType: 'Selecciona un tipo de mesa',
                        boardType:'Tipo de mesa',
                        types: {
                            notebook: 'Notebook, cupos disponibles: {cupos}',
                            laboratory: 'Laboratorio, cupos disponibles: {cupos}'
                        }
                    }
                },
                availableBoard:{
                    title:'Mesas disponibles',
                    button:'',
                    component: {
                        title:'Mesas disponibles',
                        subtitle:'Selecciona la asignatura a la cual deseas inscribir al alumno',
                        view: 'Visualizar',
                    }
                },
                confirmation:{
                    title:'Confirmación',
                    button:'',
                    component: {
                        title:'Alumno inscrito correctamente',
                        subtitle:'El alumno seleccionado ya está habilitado para rendir',
                    }
                },
                subjectSelection:{
                    title:'Selección de asignatura',
                    button:'',
                    component:{
                        title:'Asignaturas',
                        subtitle:'Selecciona la asignatura a la cual deseas inscribir al alumno.',
                        headers:{
                            subject: 'Asignatura',
                            condition: 'Condición',
                            term: 'Periodo',
                            validity: 'Vigencia',
                            professor: 'Profesor',
                            count: 'Intentos',
                            state: 'Estado',
                        },
                        states: {
                            enabled: 'Habilitado para rendir.',
                            disabled: 'Inhabilitado para rendir, superó el límite de intentos.'
                        }
                    },
                    error: 'Ocurrió un error en la inscripción',
                },
            }

        },
    },
    en: {



    }
};

export default examExceptionTranslations
