
<template>
    <div class="banner" :style="`background-color: ${banner.backgroundColor}`">
        <img class="banner__image" :src="banner.image" alt="image-test">
        <img class="banner__image_flecha" v-if="isCountry" :src="require('@/assets/dashboard-assets/onmex/micelaneas.png')" alt="misceláneas"/>
        <div class="banner__content">
            <v-chip class="banner__content__chip"  label>
                <div v-if="!isCountry" class="banner__content__icon">
                    <span class="banner__content__icon__circle"></span>
                    <v-icon left>mdi-bell-ring</v-icon>
                </div>
                {{ $t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.classStart', { classStart: getClassStart }) }}
            </v-chip>
            <div class="banner__content__actions">
                <div class="banner__content__actions__caption" v-if="banner.text.caption" v-html="banner.text.caption"></div>
                <div class="banner__content__actions__title__subjectzero" v-if="banner.isSubjectZero" v-html="banner.text.title" :style="`color:${banner.text.textColor}`"></div>
                <div class="banner__content__actions__title" v-else v-html="banner.text.title" :style="`color:${banner.text.textColor}`"></div>
                <div class="banner__content__actions__comment_subjectzero" v-if="banner.isSubjectZero" v-html="banner.text.comment" :style="`color:${banner.text.textColor}`"></div>
                <div class="banner__content__actions__comment" v-else v-html="banner.text.comment" :style="`color:${banner.text.textColor}`"></div>
                <div class="banner__content__actions__buttons">
                    <v-btn
                        v-for="(button, index) in banner.buttons"
                        :key="index"
                        rounded
                        :small="button.size === 'sm'"
                        :large="button.size === 'lg'"
                        :x-large="button.size === 'x-lg'"
                        :depressed="button.outline"
                        :style="`color: ${button.textColor}`"
                        :class="`banner__content__actions__buttons__${banner.isSubjectZero ? `${button.outline ? 'outline_subjectzero' : 'solid_subjectzero'}` : `${button.outline ? 'outline' : 'solid'}` }`"
                        :color="button.color"
                        @click="buttonClick(button)"
                    >
                        <v-icon class="pr-2" v-if="button.icon && !isCountry">{{ button.icon }}</v-icon>
                        {{ button.text }}
                    </v-btn>
                </div>
            </div>
        </div>
        <v-dialog attach=".welcome-banner" content-class="banner-video" v-model="videoDialog">
            <iframe
                v-if="videoDialog" type="text/html"
                width="640" height="360"
                iv_load_policy="3" modestbranding="1" showinfo="0"
                :src="`${videoSrc}?rel=0`"
                frameborder="0"
                allowfullscreen
            ></iframe>
            <v-btn icon dark class="close-dialog" @click="videoDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-dialog>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'OnboardingMainPrimaryBanner',
        props: {
            banner: Object,
            classStart: String
        },
        data() {
            return {
                videoDialog: false,
                videoSrc: null
            }
        },
        computed: {
            getClassStart() {
                const classStartDate = moment(this.classStart?.substr(0, 10)).locale('es')
                return classStartDate.format('D [de] ') + this.$options.filters.$capitalize(classStartDate.format('MMMM'))
            },
            isCountry(){
                return this.$country === 'mexico' ? true : false
            }
        },
        methods: {
            buttonClick(button) {
                if (button.action.includes('youtube') || button.action.includes('.mp4')) {
                    this.videoDialog = true
                    this.videoSrc = button.action
                } else {
                    window.open(button.action)
                }
                this.$trackEvent(button.analytics.category, button.analytics.action, button.analytics.label)
            }
        }
    }
</script>

<style lang="sass" scoped>
    .banner
        width: 100%
        height: 200px
        border-radius: 5px
        position: relative
        padding: 20px
        display: flex
        font-family: 'DM Sans'
        &__image
            position: absolute
            bottom: 0
            right: 0
            height: 105%
            max-height: 131px
        &__image_flecha
            position: absolute
            top: 28px
            left: 32px
            height: 105%
            max-height: 11px
        &__content
            width: 100%
            display: flex
            flex-direction: column
            &__chip
                padding: 12px 24px 12px 24px
                font-weight: bold
                font-size: 0.85rem
                color: var(--v-secondary-darken1)
                width: fit-content
                margin: 0 auto 10px
            &__icon
                position: relative
                padding: 0 12px
                margin-bottom: 2px
                &__circle:before
                    position: absolute
                    content: ''
                    width: 11px
                    height: 11px
                    top: 3%
                    right: 35%
                    z-index: 1
                    border-radius: 50%
                    border: 2px solid white
                    background-color: #E64B4B
            &__actions
                margin: auto 0
                z-index: 1
                &__buttons
                    padding-top: 10px
                    display: flex
                    justify-content: center
                    &__solid
                        margin-right: 10px
                        box-shadow: 0 8px 12px #0F99E259
                        letter-spacing: 0
                        opacity: 1
                    &__solid_subjectzero
                        margin-right: 10px
                        box-shadow: 0 8px 12px #0F99E259
                        letter-spacing: 0
                        opacity: 1
                    &__outline
                        background-color:#42E6EB
                        font-weight: bold
                        margin-right: 10px
                        border: 1px solid var(--v-secondary-base)
                        border-color: var(--v-secondary-base) !important
                        letter-spacing: 0
                        opacity: 1
                    &__outline_subjectzero
                        background-color:#42E6EB
                        font-weight: bold
                        margin-right: 10px
                        border: 1px solid var(--v-secondary-base)
                        border-color: var(--v-secondary-base) !important
                        letter-spacing: 0
                        opacity: 1
                &__caption
                    font-size: small
                    font-weight: bold
                    letter-spacing: 0
                    padding-bottom: 4px
                &__title
                    font-size: 1.3rem
                    font-style: normal
                    font-weight: 600
                    text-align: center
                &__title__subjectzero 
                    font-size: 1.75rem
                    font-style: normal
                    font-weight: 600
                    text-align: center
                &__comment
                    letter-spacing: 0
                    padding-top: 5px
                    font-size: 0.85rem
                    font-style: normal
                    font-weight: 400
                    text-align: center
                &__comment_subjectzero
                    letter-spacing: 0
                    padding-top: 5px
                    padding-left: 60px 
                    padding-right: 60px
                    font-size: 0.84rem
                    font-style: normal
                    font-weight: 400
                    text-align: center
    
    .close-dialog
        position: absolute
        top: 10px
        right: 10px
    .theme--light.v-chip:not(.v-chip--active) 
        background: #fee13d
</style>

<style lang="sass">
    .welcome-banner
        > .v-dialog__content
            > .banner-video
                position: relative
                width: 640px
                height: 360px
                max-height: 100% !important
                line-height: 0 !important
</style>
