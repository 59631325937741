const scholarshipsTranslations = {
  es: {
    create: {
      breadcrumbs: {
        title: 'Nueva beca',
        description: 'Crea una nueva beca.'
      },
      sectionData: {
        title: 'Datos de la beca',
        subtitle: 'Agrega la información básica de la beca y su código. Recuerda que estos datos deben ser lo más descriptivos posibles ya que serán utilizados para futuros filtros de búsqueda.',
        labelEnable: 'Habilitada',
        labelDisable: 'Deshabilitada',
        items: {
          'name': 'Nombre',
          'code': 'Código',
          'relation': 'Aplica a',
          'typeDiscount': 'Tipo de descuento',
          'amount': 'Monto',
          'from': 'Aplica desde',
          'average': 'Promedio',
          'percent' : 'Porcentaje',
        },
        error: {
          'selectedBillingProducts' : 'Debes seleccionar al menos un producto',
          'invalidFormat' : 'Formato invalido'
        }
      },
      sectionRenewal: {
        title: 'Renovación',
        subtitle: 'Activa esta opción para la renovación automática de la beca.',
        items: {
          renewalCheck: 'Renovar automáticamente'
        }
      },
      sectionDocumentation: {
        title: 'Documentación obligatoria',
        subtitle: 'Selecciona en esta sección la documentación necesaria para que esta beca pueda ser aplicada.',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionada'
        }
      },
      sectionRestriction: {
        title: 'Restricciones',
        items: {
          radioFirst: 'Sin restricción',
          radioSecond: 'Límite de estudiantes'
        }
      },
      validations: {
        descriptionRequired: 'Ingresa nombre',
        codeRequired: 'Ingresa codigo',
        codeExist: 'Codigo ya existente',
        fromRequired: 'Ingresa fecha',
        typeRequired: 'Selecciona tipo de descuento',
        averageRequired: 'Ingresa promedio',
        averageInvalid: 'Incorrecto, ingresa del 0 al 10',
        percentRequired: 'Ingresa porcentaje',
        amountRequired: 'Ingresa monto',

      },
      warning : {
        no_selected_warning : 'Debes seleccionar al menos una documentación.',
        no_selected_product : 'Debes seleccionar al menos un producto',
        no_add_product : 'Debes agregar al menos un producto',
      },
      warning_status: {
        title: 'Estás creando una beca deshabilitada',
        actions: {
            primary_text: 'Crear',
            secondary_text: 'Cancelar',
        }
      },
      success_message: {
        title: 'Beca creada correctamente',
        description: 'creada',
        actions: {
            primary_text: 'Cerrar',
            secondary_text: 'Crear otro',
        },
      },
      error_message: {
          title: 'Se produjo un error al crear la beca',
      },
      errors: {
        popup: {
          btnPrimary: 'Aceptar',
          title: 'La beca no se pudo {action}.',
        },
        limit: 'Ingresa la cantidad límite de estudiantes',
      },
      discountType: {
        percentege: 'Porcentual',
        amount: 'Monto',
        percentege_lowercase: 'porcentual',
        amount_lowercase: 'monto'
      },
      multilpleFormGroup: {
        withAverage: 'Con promedio de :',
        apply: 'Aplica a :'
      }
    },
    update: {
      breadcrumbs: {
        title: 'Editar beca',
        description: 'Edita la beca seleccionada.'
      },
      sectionData: {
        title: 'Datos de la beca',
        subtitle: 'Agregue la información básica de la beca y su código. Recuerde que estos datos deben ser lo más descriptivos posibles ya que serán utilizados para futuros filtros de búsqueda.',
        labelEnable: 'Habilitada',
        labelDisable: 'Deshabilitada',
        items: {
          'name': 'Nombre',
          'code': 'Código',
          'relation': 'Aplica a',
          'typeDiscount': 'Tipo de descuento',
          'amount': 'Monto',
          'from': 'Aplica desde',
          'average': 'Promedio',
          'percent' : 'Porcentaje',
        },
        error: {
          'selectedBillingProducts' : 'Debes seleccionar al menos un producto',
          'invalidFormat' : 'Formato invalido'
        }
      },
      sectionRenewal: {
        title: 'Renovación',
        subtitle: 'Activa esta opción para la renovación automática de la beca.',
        items: {
          renewalCheck: 'Renovar automáticamente'
        }
      },
      sectionDocumentation: {
        title: 'Documentación obligatoria',
        subtitle: 'Selecciona en esta sección la documentación necesaria para que esta beca pueda ser aplicada.',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionada'
        }
      },
      sectionRestriction: {
        title: 'Restricciones',
        items: {
          radioFirst: 'Sin restricción',
          radioSecond: 'Límite de estudiantes'
        }
      },
      validations: {
        descriptionRequired: 'Ingresa nombre',
        codeRequired: 'Ingresa código',
        codeExist: 'Codigo ya existente',
        fromRequired: 'Ingresa fecha',
        averageRequired: 'Ingresa promedio',
        percentRequired: 'Ingresa porcentaje',
        amountRequired: 'Ingresa monto'
      },
      warning : {
        no_selected_warning : 'Debes seleccionar al menos una documentación',
        no_selected_product : 'Debes seleccionar al menos un producto',
        no_add_product : 'Debes agregar al menos un producto',
      },
      warning_status: {
        title: 'Estás modificando una beca a deshabilitada',
        actions: {
            primary_text: 'Guardar',
            secondary_text: 'Cancelar',
        }
      },
      success_message: {
        title: 'Beca modificada correctamente',
        description: 'modificada',
        actions: {
            primary_text: 'Cerrar',
            secondary_text: 'Crear otro',
        },
      },
      error_message: {
          title: 'Se produjo un error al modificar la beca',
          assigned: 'Beca asociada a un pronto pago'
      },
      errors: {
        popup: {
          btnPrimary: 'Aceptar',
          title: 'La beca no se pudo {action}.',
        },
        limit: 'Ingresa la cantidad límite de estudiantes',
      },
      discountType: {
        percentege: 'Porcentual',
        amount: 'Monto',
        percentege_lowercase: 'porcentual',
        amount_lowercase: 'monto'
      },
      multilpleFormGroup: {
        withAverage: 'Con promedio de :',
        apply: 'Aplica a :'
      }
    },
    table: {
      breadcrumbs: {
        title: 'Becas',
        description: 'Administración de becas.'
      },
      headers: {
        'code': 'Código',
        'name': 'Nombre',
        'product': 'Producto',
        'status': 'Estado',
        'discounts': 'Tipo de descuento',
        'quantity': 'Cantidad',
        'alumns': 'Estudiantes',
        'from': 'Desde',
      },
      filters: {
        title: 'Refinar búsqueda',
        product: 'Producto',
        state: 'Estado',
        discountType: 'Tipo de descuento'
      },
      message: {
        delete: '¿Estás seguro de eliminar?',
        title: 'Estás a punto de eliminar las siguientes becas',
        deleteAction: 'Si, eliminar',
        cancelAction: 'Cancelar',
        actions: 'Acciones'
      },
      search: {
          search: 'Buscar',
          gotopage: 'ir a página',
          edit: 'Editar',
          delete: 'Eliminar',
          details: 'Detalles',
          bulkDelete: 'Eliminar becas'
      },
      error_message: {
        delete_message: 'No se puede eliminar la beca',
        bulk_delete_message: 'No se pueden eliminar las becas'
      },
      filters_asidebar: {
        'product': 'Producto',
        'state': 'Estado',
        'discountType': 'Tipo de descuento',
        'active': 'Activo',
        'inactive' : 'Inactivo',
        'percentege' : 'Porcentaje',
        'amount' : 'amount'
      }
    },
    details: {
      breadcrumbs: {
        title: 'Beca rendimiento académico 50%',
        description: '',
        actions: 'Acciones'
      },
      resume: {
        productType: 'Tipo de descuento',
        discountType: 'Tipo de descuento',
        applySince: 'Aplica desde',
        average: 'Promedio Min./ Max.',
        status: 'Estado',
        renovation: 'Renovación',
        documentation: 'Documentación',
        scholarshipStudents: 'Estudiantes becados actualmente',
        availableSlots: 'Cupos disponibles',
        noAverage: 'No aplica',
        active: 'Activa',
        inactive: 'Inactiva',
        automatic: 'Automática',
        manual: 'Manual'
      },
      table: {
        title: 'Estudiantes becados',
        name: 'Nombre',
        career: 'Carrera',
        delete: 'Dar de baja',
        bulkDelete: 'Dar de baja',
        addNew: 'Agregar nuevo estudiante',
        identification: 'Identificación',
      },
      drawer: {
        title: 'Nuevo estudiante becado',
        subtitle: 'Agrega un nuevo estudiante a la beca.',
        students: 'Estudiantes',
        error: 'Ocurrió un error al asignar la beca.',
        description: 'Aquí podrás seleccionar a quienes asignarle una beca. Ten en cuenta que si el estudiante que buscas no está disponible en esta lista es posible que ya tenga una beca asignada o no cumpla los requisitos necesarios',
        headers: {
          name: 'Nombre',
          average: 'Promedio'
        },
        success: {
          title: 'Estudiantes agregados correctamente',
          limitError: 'La cantidad de estudiantes seleccionada supera el cupo disponible.',
          actions: {
            back: 'Volver',
          }
        }
      }
    }
  },
  en: {

  },
};

export { scholarshipsTranslations };
export default scholarshipsTranslations;
