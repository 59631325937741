<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container fluid>
            <Breadcrumbs :title="$t('modules.portalalumno.payments.paymentsStudents.breadcrumbs.title')"
                :description="$t('modules.portalalumno.payments.paymentsStudents.breadcrumbs.description')"></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" cols="12">
                    <v-row>
                        <v-col cols="12" :md="(step != 3) ? '7' : '12'" :lg="(step != 3) ? '8' : '12'">
                            <v-stepper v-model="step" class='stepper paymentStepper' :alt-labels="$isMobile">
                                <v-stepper-header class="stepper-header">
                                    <v-stepper-step class="stepper-step" :complete="step > 1"
                                        :color="step > 1 ? 'green' : 'primary'" step="1">
                                        {{ $t('modules.portalalumno.payments.paymentsStudents.view.stepper.selection') }}
                                    </v-stepper-step>
                                    <v-stepper-step class="stepper-step" :complete="step > 2"
                                        :color="step > 2 ? 'green' : 'primary'" step="2">
                                        {{ $t('modules.portalalumno.payments.paymentsStudents.view.stepper.payment') }}
                                    </v-stepper-step>
                                    <v-stepper-step step="3" class="stepper-step">
                                        {{ $t('modules.portalalumno.payments.paymentsStudents.view.stepper.confirmation') }}
                                    </v-stepper-step>
                                </v-stepper-header>
                                <v-stepper-items>
                                    <v-stepper-content step="1" class="px-0">

                                        <div v-show="!repayment.selected">
                                            <!-- <PaymentsTeclabBanner v-if="$isTeclab"></PaymentsTeclabBanner> -->
                                            <PaymentsBanner v-if="showBanner" :percentage="extraBenefitPercentage"></PaymentsBanner>

                                            <TeclabReenrollmentBanners v-if="teclabReenrollment"
                                                :reenrollment="teclabReenrollment" :hasDebt="hasDebt"
                                                @open-documentation="documentationDrawerOpen = true">
                                            </TeclabReenrollmentBanners>

                                            <PaymentsSelectionGridView
                                                ref="paymentSelectionGridView"
                                                :systemConfig="systemConfig"
                                                :prepaids="prepaids"
                                                :productWithPricePrepaids="productWithPricePrepaids"
                                                :payments="filteredPaymentsByCountryConstants"
                                                :paymentsNext="filteredPaymentsNextByCountryConstants"
                                                :repayment="repayment"
                                                :generatePaymentIds="generatePaymentIds"
                                                @billingchange="onBillingChange($event.payments, $event?.itemsToUpdate)"
                                            ></PaymentsSelectionGridView>
                                        </div>

                                        <Repayment
                                            ref="REP"
                                            v-if="repayment.selected"
                                            :resumen="resumen"
                                            :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                            :summaryButtons="summaryButtons"
                                            @continue="onPayClick"
                                        ></Repayment>
                                    </v-stepper-content>

                                    <v-stepper-content step="2">

                                        <PaymentsSelectionView
                                            ref="PSV"
                                            v-if="selectedMethod.id == null"
                                            :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                            @paymentSelected="paymentSelected"
                                            :isCheckoutPro="isCheckoutPro"
                                            :isGetnet = "isGetnet"
                                        ></PaymentsSelectionView>

                                        <PaymentsSingleCardView
                                            ref="TC1"
                                            v-if="selectedMethod.id == 'TC1'"
                                            :resumen="resumen"
                                            :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                            :summaryButtons="summaryButtons"
                                            :selectedMethod="selectedMethod"
                                            @changeStep="nextStepChange"
                                            @goBackPayment="goBackPayment"
                                            @setDue="setDue"
                                        ></PaymentsSingleCardView>

                                        <PaymentsMultipleView
                                            ref="TC2"
                                            v-if="selectedMethod.id == 'TC2'"
                                            :resumen="resumen"
                                            :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                            :summaryButtons="summaryButtons"
                                            :selectedMethod="selectedMethod"
                                            :isRepayment="isRepayment"
                                            :countAnalytics="countAnalytics"
                                            @incrementCountAnalytics="incrementCountAnalytics"
                                            @resetCountAnalytics="resetCountAnalytics"
                                            @changeStep="nextStepChange"
                                            @goBackPayment="goBackPayment"
                                            @setDue="setDue"
                                        ></PaymentsMultipleView>

                                        <PaymentsDebitView
                                            ref="TD"
                                            v-if="selectedMethod.id == 'TD'"
                                            :resumen="resumen"
                                            :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                            :summaryButtons="summaryButtons"
                                            :selectedMethod="selectedMethod"
                                            @goBackPayment="goBackPayment"
                                            @changeStep="nextStepChange"
                                        ></PaymentsDebitView>

                                        <PaymentsCheckoutPro
                                            ref="CHECK"
                                            v-if="selectedMethod.id == 'CHECK'"
                                            :resumen="resumen"
                                            :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                            :summaryButtons="summaryButtons"
                                            :selectedMethod="selectedMethod"
                                            @goBackPayment="goBackPayment"
                                            @changeStep="nextStepChange"
                                        ></PaymentsCheckoutPro>

                                        <PaymentsCheckoutGetnet ref="GETNET" v-if="selectedMethod.id == 'GETNET'"
                                            :resumen="resumen" :summaryButtons="summaryButtons"
                                            :selectedMethod.sync="selectedMethod" @goBackPayment="goBackPayment"
                                            @changeStep="nextStepChange"
                                        ></PaymentsCheckoutGetnet>

                                        <PaymentsCashView
                                            ref="FT"
                                            v-if="selectedMethod.id == 'FT' || selectedMethod.id == 'ATM'"
                                            :resumen="resumen"
                                            :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                            :coupon="coupon"
                                            :summaryButtons="summaryButtons"
                                            :selectedMethod="selectedMethod"
                                            @functionSelectPayFT="functionSelectPayFT"
                                            @goBackPayment="goBackPayment"
                                            @changeStep="nextStepChange"
                                            @updateCouponExternalInfo="updateCouponExternalInfo"
                                        ></PaymentsCashView>

                                        <PaymentsSubscriptionView
                                          ref="SUS"
                                          v-if="selectedMethod.id == 'SUS'"
                                          @goBackPayment="goBackPayment"
                                          @changeStep="nextStepChange"
                                          :subscriptions="filteredSubscriptions"
                                          :subscriptionPaymentTypes="filteredSubscriptionsTypes"
                                          :selectedMethod="selectedMethod"
                                          :reenrollment="teclabReenrollment"
                                          :coupon="coupon"
                                          :resumen="resumen"
                                          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                          :summaryButtons="summaryButtons"
                                          @setDue="setDue"
                                        ></PaymentsSubscriptionView>
                                    </v-stepper-content>

                                    <v-stepper-content step="3">
                                        <PaymentsCompletedView :resumen="resumen" :coupon="coupon" :status="successMessage">
                                        </PaymentsCompletedView>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </v-col>
                        <v-col cols="12" md="5" lg="4">
                            <v-card class="mx-6 mx-md-0 margin-bot">
                                <PaymentSummary v-if="step != 3" :summaryButtons="summaryButtons" :resumen="resumen"
                                    :credit-cards="creditCards"></PaymentSummary>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-container>

        <TemporaryRightDrawer v-if="teclabReenrollment" @tmpDrawerOpen="documentationDrawerOpen = false"
            :open="documentationDrawerOpen"
            :title="$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_title')"
            :description="$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_description')">
            <DocumentationUpload
                v-if="teclabReenrollment.benefit && teclabReenrollment.benefit.documentation && teclabReenrollment.benefit.documentation.length"
                :benefit="teclabReenrollment.benefit" @close="closeDocumentationUpload"></DocumentationUpload>
        </TemporaryRightDrawer>
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import PaymentsSelectionGridView from './PaymentsSelectionGridView'
import PaymentsSingleCardView from './PaymentsSingleCardView';
import PaymentsCompletedView from './PaymentsCompletedView';
import PaymentsCashView from './PaymentsCashView';
import PaymentsCheckoutPro from './PaymentsCheckoutPro';
import PaymentsDebitView from './PaymentsDebitView';
import PaymentsMultipleView from './PaymentsMultipleView';
import PaymentsSubscriptionView from './PaymentsSubscriptionView.vue';
import PaymentsCheckoutGetnet from './PaymentsCheckoutGetnet';
import PaymentsSelectionView from './PaymentsSelectionView';
import PaymentSummary from '../Components/PaymentSummary/PaymentSummary'
import Repayment from '../Components/Repayment/Repayment';
import PaymentsBanner from '../Components/PaymentBanner/PaymentBanner'
import TeclabReenrollmentBanners from '../Components/PaymentBanner/TeclabReenrollmentBanners'
import DocumentationUpload from '../Components/PaymentBanner/DocumentationUpload'
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import { $studentAccount, $discount, $productsWithPricePeriods, $types, $suscriptions, $accounts } from '../Services';
import { mapGetters } from 'vuex'


const PENDING_PAYMENT_STATUS = { type: null, value: 115, meaning: 'Vigente' }

export default {
    name: 'PaymentsView',
    components: {
        PaymentsSelectionView,
        PaymentsSelectionGridView,
        PaymentsSingleCardView,
        PaymentsCashView,
        PaymentsMultipleView,
        PaymentsSubscriptionView,
        PaymentsCompletedView,
        PaymentSummary,
        PaymentsBanner,
        TeclabReenrollmentBanners,
        DocumentationUpload,
        TemporaryRightDrawer,
        PaymentsDebitView,
        PaymentsCheckoutPro,
        PaymentsCheckoutGetnet,
        Repayment,
        Breadcrumbs,
    },
    data() {
        return {
            systemConfig: null,
            repayment: { selected: false, available: false, installments: 3 },
            showExtraBenefit: false,
            showBannerBenefitExtra: false,
            generatePaymentIds: false,
            selectedMethod: { id: null },
            step: 1,
            successMessage: null,
            prepaids: [],
            productWithPricePrepaids: [],
            summaryButtons: {
                primary: {
                    text: this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue'),
                    disabled: true,
                    visible: true,
                    callback: () => { this.onPayClick() },
                    loading: false,
                    success: false,
                    error: false,
                },
                secondary: {
                    text: this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.back'),
                    disabled: false,
                    visible: false,
                    callback: () => { this.previousStep() }
                },
                tertiary: {
                    text: this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.repayment'),
                    disabled: false,
                    visible: false,
                    callback: () => { this.goToRepayment() }
                },
            },
            resumen: {
                items: {
                    subtotal: { title: 'Subtotal', items: [], monto: 0 },
                    scholarships: { title: 'Becas', items: [], monto: 0 },
                    benefits: { title: 'Beneficios', items: [], monto: 0 },
                    discounts: { title: 'Descuentos', items: [], monto: 0 },
                    repayments: { title: 'Subtotal', items: [], monto: 0 },
                    adminExpenses: { title: 'Gastos Administrativos', items: [], monto: 0 },
                },
                cards: [
                    { paymentId: null, amount: null },
                    { paymentId: null, amount: null },
                ],
                total: 0,
                tea: '0,00%',
                cft: '0,00%',
                paymentIntent: 0
            },
            allItemsResumen: {
                items: {
                subtotal: { title: 'Subtotal', items: [], monto: 0 },
                scholarships: { title: 'Becas', items: [], monto: 0 },
                benefits: { title: 'Beneficios', items: [], monto: 0 },
                discounts: { title: 'Descuentos', items: [], monto: 0 },
                repayments: { title: 'Subtotal', items: [], monto: 0 },
                adminExpenses: { title: 'Gastos Administrativos', items: [], monto: 0 },
                },
                cards: [
                { paymentId: null, amount: null },
                { paymentId: null, amount: null },
                ],
                total: 0,
                tea: '0,00%',
                cft: '0,00%',
                paymentIntent: 0
            },
            coupon: {
                payment: null,
                number: null,
                manualOpNumber: null,
                creationDate: null,
                barcode: {
                    type: null,
                    codeNumber: null
                },
                externalResourceUrl: null,
                dateOfExpiration: null

            },
            payments: [],
            hiddenPayments: [],
            paymentsNext: [],
            subscriptionPaymentTypes: [],
            filteredSubscriptionsTypes: [],
            subscriptions: [],
            reenrollmentItems: [],
            allReenrollmentItems: [],
            filteredSubscriptions: [],
            paymentsSelected:[],
            paymentsSelectedAll:[],
            subscriptionMethod: {
                id: 'SUS',
                type: 'suscripcion',
                name: 'Suscripción',
                icon: 'mdi-account-cash-outline',
                off: 0,
            },
            productsToHide: [],
            documentationDrawerOpen: false,
            hasDebt: false,
            teclabReenrollment: null,
            ippReenrollment: null,
            adaptationInsuranceTerms: [],
            referedCredits: 0,
            creditCards: [],
            iv: 0,
            aux:'',
            isCheckoutPro: false,
            isGetnet:false
        };
    },
    computed: {
        ...mapGetters({
            studentRegister: 'commons/getStudentActualRegister',
            studentId: 'commons/getStudentId',
            studentUserId: 'commons/getStudentUserID',
            studentData: 'commons/getStudentData',
        }),
        unselectedReenrollmentsAccountIds() {
            const selectedReenrollmentsIds = this.resumen.items.subtotal.items.filter(item => item.reenrollmentId).map(item => item.reenrollmentId)
            const selectedAccountIds = this.resumen.items.subtotal.items.filter(item => item.id).map(item => item.id)
            if(selectedAccountIds) {
            const unselectedAccountIds = this.allItemsResumen.items.subtotal.items.filter(item => !selectedAccountIds.includes(item.id)).map(item => item.id)
            return unselectedAccountIds
            }
            const unselectedReenrollmentsAccountIds = this.allItemsResumen.items.subtotal.items.filter(item => !selectedReenrollmentsIds.includes(item.reenrollmentId)).map(item => item.id)
            return unselectedReenrollmentsAccountIds
        },
        enableRepayment() {
            return this.repayment.available && this.payments.filter(payment => payment.selected && payment.status == 117 && payment.groupId === 1).length >= this.repayment.installments
        },
        showBanner(){
            return !!(this.showBannerBenefitExtra && this.paymentsSelected.length > 0 && this.paymentsSelected.length >= 4 && this.paymentsSelectedAll.length === this.paymentsSelected.length && this.ippReenrollment.accounts.length === 0 || this.systemConfig?.contract && this.showExtraBenefit );
        },
        extraBenefitPercentage(){
            return this.showBannerBenefitExtra ? this.showBannerBenefitExtra.benefitExtra : this.ippReenrollment?.accounts[this.ippReenrollment.accounts.length - 1]?.benefitExtra
        },
        filteredPaymentsNextByCountryConstants() {
            // const ENROLLMENT_PRODUCT_ID = this.$sisConstants('modules.products.matricula')
            // if (!this.$countryConstants.student_pays_enrollment) {
            //   return this.paymentsNext.filter((p) => p.billingProduct !== ENROLLMENT_PRODUCT_ID)
            // }

            return this.paymentsNext
        },
        filteredPaymentsByCountryConstants() {
            // const ENROLLMENT_PRODUCT_ID = this.$sisConstants('modules.products.matricula')
            // if (!this.$countryConstants.student_pays_enrollment) {
            //   return this.payments.filter((p) => p.billingProduct !== ENROLLMENT_PRODUCT_ID)
            // }
            return this.payments
        },
        selectAllPaymentMexico(){
            return this.$nextTick(() => this.$refs.paymentSelectionGridView.selectAll(true))
        }
    },
    async mounted() {
        if (this.$isGestor) {
            this.getSubscriptionPaymentTypes()
            this.getSubscriptions()
        }
        this.systemConfig = (await this.$getSystemConfig())?.data
        await this.fetchData()
        this.specialPayFlow()
    },
    methods: {
        closeDocumentationUpload(processingId) {
            if (processingId) this.getProductsWithPrice()
            this.documentationDrawerOpen = false
        },
        async getProductsWithPrice() {
            const params = { studentCareer: this.studentRegister }
            if(this.$route.params?.termIds && this.$route.params?.termIds.length > 0) {
                params.termIds = this.$route.params.termIds
            }

            if(params.termIds || !this.systemConfig?.dues || this.$isMexico){
                try {
                    const {data} = await $productsWithPricePeriods?.find(null, null, {params})
                    if (data.products?.some(product => product.idProduct !== 80) || data.prepaids?.length > 0) {
                        this.productWithPricePrepaids = data.prepaids?.map((prepaid)=>{return {...prepaid, percentage: parseInt(this.$decryter(prepaid.percentage))}})
                        this.productWithPricePrepaids?.sort((a, b) => (a.dayFrom > b.dayFrom) ? 1 : -1)
                    }
                    data.benefit?.products.map(item => {item.amount = item.amount? parseInt(this.$decryter(item.amount)):null;
                                            item.percentage = item.percentage? parseInt(this.$decryter(item.percentage)):null});
                    data.products?.map(item =>{item.amount = item.amount ? parseInt(this.$decryter(item.amount)):null
                                            item.benefitExtra = item.benefitExtra ? parseFloat(this.$decryter(item.benefitExtra)): null});
                    data.discount?.details.map(item => {item.bianualAmount= item.bianualAmount? parseInt(this.$decryter(item.bianualAmount)):null
                                            item.bianualPercentage= item.bianualPercentage? parseInt(this.$decryter(item.bianualPercentage)):null
                                            item.bimesterAmount= item.bimesterAmount? parseInt(this.$decryter(item.bimesterAmount)):null
                                            item.bimesterPercentage= item.bimesterPercentage? parseInt(this.$decryter(item.bimesterPercentage)):null})
                    data.referedCredits = data.referedCredits? parseInt(this.$decryter(data.referedCredits)):null;
                    data.scholarship?.products.map(item => {item.amount = item.amount? parseInt(this.$decryter(item.amount)):null
                                                item.percentage = item.percentage? parseInt(this.$decryter(item.percentage)):null});
                    data.uniqueDiscount = data.uniqueDiscount? parseInt(this.$decryter(data.uniqueDiscount)):null;
                    this.teclabReenrollment = data
                    this.showBannerBenefitExtra = data?.products?.find(({benefitExtra}) =>  benefitExtra > 0)
                } catch (error) {
                    this.teclabReenrollment = null
                    throw error
                }
            }
        },
        async getAdaptationInsurance() {
            try {
                const { data } = await $studentAccount?.find('getAdaptationInsuranceTerms', null, { params: { studentId: this.studentId } })
                this.adaptationInsuranceTerms = data.termIdLst
            } catch (error) {
                this.adaptationInsuranceTerms = []
                throw error
            }
        },
        checkParam(param, array) {
            const getQueryParams = (params, url) => {
                const href = url;
                const reg = new RegExp(`[?&]${params}=([^&#]*)`, 'i');
                const queryString = reg.exec(href);
                return queryString ? queryString[1] : null;
            };
            const payParam = getQueryParams(param, this.$route.hash);
            return array.includes(payParam) ? payParam : null
        },
        specialPayFlow() {
            if (this.$route.params.billingProduct) {
                const specialPays = this.hiddenPayments.filter(payment => payment.billingProduct == this.$route.params.billingProduct)
                const payment = specialPays.slice(-1)
                this.onBillingChange(payment)
                this.onPayClick()
            }
        },
        dateFormat(dateString) {
            const date = new Date(dateString)
            return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear().toString().substr(-2)}`
        },
        async fetchData() {
                try {
                    this.productsToHide = [`${this.$sisConstants('modules.products.matricula')}`]

                    this.getConfig()
                    const response = await $studentAccount?.findById(this.studentId)
                    const payments = []
                    this.ippReenrollment = response.data
                    this.repayment.available = response.data.repaymentAvailable
                    this.repayment.installments = response.data.minInstallmentsRepayment

                    response.data.accounts.forEach((acc, i) => {
                        // Se excluyen pagos con descripcion arancel ya que se duplican en base
                        if ((this.systemConfig?.dues) || this.$isMexico || (!acc.accountDescription.toLowerCase().match(/.*arancel.*/))) {
                          if (acc.paymentStatus && acc.paymentStatus.value != 114) {
                                if (acc.paymentStatus.value == 117) this.hasDebt = true
                                if (!(acc.billingProduct && acc.billingProduct.id == 83 && acc.paymentInstallment === 0)) payments.push(acc)
                            } else {
                                const dateId = (new Date()).getTime()

                                acc.paymentStatus = PENDING_PAYMENT_STATUS
                                if (!acc.id) this.generatePaymentIds = true
                                acc.reenrollmentId = `${dateId}-${i}`,
                                payments.push(acc)
                            }
                        }
                    });

                    if(!this.$isTeclab) {
                        this.referedCredits =  response.data.benefit ? parseInt(this.$decryter(response.data.benefit?.referredAmount)) : 0
                        response.data.accounts.map(item =>{item.paymentAmount = parseInt(this.$decryter(item.paymentAmount))
                                                  item.benefitExtra = item.benefitExtra ? parseFloat(this.$decryter(item.benefitExtra)): null})
                        response.data.uniqueDiscount = response.data.uniqueDiscount? parseInt(this.$decryter(response.data.uniqueDiscount)):null
                        response.data.prepaids?.map(item => {item.percentage = parseInt(this.$decryter(item.percentage))})
                        response.data.benefit?.referredAmount ? response.data.benefit.referredAmount = parseInt(this.$decryter(response.data.benefit?.referredAmount)): null
                        response.data.scholarship?.amount && (response.data.scholarship.amount = parseInt(this.$decryter(response.data.scholarship.amount)))
                        response.data.scholarship?.percentage && (response.data.scholarship.percentage = parseInt(this.$decryter(response.data.scholarship.percentage)))

                    }
                    if (this.$isTeclab) {
                        response.data.accounts.map(item =>{item.paymentAmount = parseInt(this.$decryter(item.paymentAmount))
                                                  item.benefitExtra = item.benefitExtra ? parseFloat(this.$decryter(item.benefitExtra)): null})
                        response.data.uniqueDiscount = response.data.uniqueDiscount? parseFloat(this.$decryter(response.data.uniqueDiscount)):null
                        response.data.prepaids?.map(item => {item.percentage = parseInt(this.$decryter(item.percentage))})
                        this.productsToHide = []
                        await this.getProductsWithPrice()
                        if(this.systemConfig?.adaptationInsurance) {
                          await this.getAdaptationInsurance()
                        }
                        if (this.teclabReenrollment && this.teclabReenrollment.products) {
                            this.teclabReenrollment.products.forEach((prod, prodIndex) => {
                          let total = prod.amount
                          let off = 0
                          let extraOff = 0
                          let benefitAmount = 0
                          let scholarshipAmount = 0

                          const discount = this.teclabReenrollment.discount?.details?.find(disc => disc.billingProduct.id == prod.idProduct)
                          if (discount) {
                            if (prod.idProduct == this.$sisConstants('modules.products.matricula')) {
                              if (discount.bianualAmount) off += discount.bianualAmount / total * 100
                              else if (discount.bianualPercentage) off += discount.bianualPercentage
                            } else if (prod.idProduct == this.$sisConstants('modules.products.arancel')) {
                              let discountOff = 0
                              if (discount.bimesterAmount) discountOff = discount.bimesterAmount / total * 100
                              else if (discount.bimesterPercentage) discountOff = discount.bimesterPercentage
                              off += discountOff

                              let bianualOff = 0
                              if (discount.bianualAmount) bianualOff = discount.bianualAmount / total * 100
                              else if (discount.bianualPercentage) bianualOff = discount.bianualPercentage
                              if (bianualOff && bianualOff > discountOff) extraOff = bianualOff - discountOff
                            }
                          }

                          if (this.teclabReenrollment.benefit?.statusProcessing?.status.value == 93 || !this.teclabReenrollment.benefit?.documentation.length) {
                            const benefit = this.teclabReenrollment.benefit?.products?.find(b => b.billingProduct == prod.idProduct)
                            if (benefit && (this.$isTeclab || off < 100)) {
                              benefitAmount = benefit.percentage ? total * benefit.percentage / 100 : benefit.amount
                              if (benefitAmount > total) {
                                benefitAmount = total
                              }
                              if (this.$isIPP) {
                                total -= benefitAmount
                              }
                              off += benefitAmount / prod.amount * 100
                            }
                          }

                          const isAdaptationInsurance = (prod.idProduct == this.$sisConstants('modules.products.arancel')
                                  || prod.idProduct == this.$sisConstants('modules.products.matricula'))
                              && this.adaptationInsuranceTerms.includes(prod.idTerm)
                          if (isAdaptationInsurance) {
                            benefitAmount = prod.amount
                            off = 100
                          }

                          if (this.teclabReenrollment.referedCredits) {
                            this.referedCredits = this.teclabReenrollment.referedCredits
                            if (this.$isIPP) {
                              total -= this.referedCredits
                            }
                          }

                          if (!this.teclabReenrollment.scholarship?.renew || this.teclabReenrollment.scholarship?.renew.status?.value == 77) {
                            const scholarship = this.teclabReenrollment.scholarship?.products?.find(s => s.billingProduct == prod.idProduct)
                            if (scholarship && (this.$isTeclab || off < 100)) {
                              scholarshipAmount = scholarship.percentage ? total * scholarship.percentage / 100 : scholarship.amount
                              if (scholarshipAmount > total) {
                                scholarshipAmount = total
                              }
                              if (this.$isIPP) {
                                total -= scholarshipAmount
                              }
                              off += scholarship.percentage || scholarship.amount / total * 100
                            }
                          }
                          if (this.$isIPP && off > 100) off = 100
                          const dateId = (new Date()).getTime()
                                if(this.$isPeru || this.$isMexico ) {
                                  payments.push({
                                    accountDescription: prod.name,
                                    billingProduct: { id: prod.idProduct },
                                    paymentAmount: prod.amount,
                                    scholarshipAmount,
                                    benefitAmount,
                                    benefitExtra: this.showBannerBenefitExtra?.benefitExtra?? null,
                                    id: null,
                                    reenrollmentId: `${dateId}-${prodIndex}`,
                                    term: { id: prod.idTerm, description: prod.termCode},
                                    paymentDueDate: prod.dueDate,
                                    paymentInstallment: prod.installment,
                                    paymentInstallments: prod.installments,
                                    mandatory: prod.mandatory,
                                    paymentStatus: PENDING_PAYMENT_STATUS,
                                    paymentStudentRecord: this.studentRegister,
                                    off,
                                    reenrollmentExtraOff: extraOff,
                                    adaptationInsurance: isAdaptationInsurance,
                                    subdescription: prod.subdescription,

                                  })
                                }
                                else {
                                  payments.push({
                                    accountDescription: prod.name,
                                    billingProduct: { id: prod.idProduct },
                                    paymentAmount: prod.amount,
                                    scholarshipAmount,
                                    benefitAmount,
                                    benefitExtra: null,
                                    id: null,
                                    reenrollmentId: `${dateId}-${prodIndex}`,
                                    term: { id: prod.idTerm, description: prod.termCode},
                                    paymentDueDate: prod.dueDate,
                                    paymentInstallment: 1,
                                    paymentInstallments: 1,
                                    mandatory: prod.mandatory,
                                    paymentStatus: PENDING_PAYMENT_STATUS,
                                    paymentStudentRecord: this.studentRegister,
                                    off,
                                    reenrollmentExtraOff: extraOff,
                                    adaptationInsurance: isAdaptationInsurance,
                                  })
                                }
                            });
                        }

                    }
                    payments.sort((a, b) => {
                        if (b.billingProduct?.id == this.$sisConstants('modules.products.derecho_examen')) return 1
                        const dateA = new Date(a.paymentDueDate)
                        const dateB = new Date(b.paymentDueDate)
                        if (dateA > dateB) return 1
                        if (dateA < dateB) return -1
                        if (a.billingProduct?.id == this.$sisConstants('modules.products.arancel') && b.billingProduct?.id == this.$sisConstants('modules.products.matricula')) return 1
                        if (b.billingProduct?.id == this.$sisConstants('modules.products.arancel') && a.billingProduct?.id == this.$sisConstants('modules.products.matricula')) return -1
                        return 0
                    });

                    const adaptationInsuranceIndex = payments?.findIndex(payment => payment.adaptationInsurance)
                    if (adaptationInsuranceIndex !== -1) {
                        payments.map((payment, index) => {
                            payment.disabled = true
                            if (adaptationInsuranceIndex === 0) {
                              if (payment.adaptationInsurance && (index === 0 || payments[index - 1].adaptationInsurance)) {
                                  payment.disabled = false
                              }
                            }
                            if (index < adaptationInsuranceIndex) {
                                payment.disabled = false
                            }
                            return payment
                        })
                    }

                    const now = new Date()
                    const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
                    const groupsId = {
                        arancel: 1,
                        repactacion: 2,
                        independent: 3,
                        rematricula: 4,
                    }

                    payments.forEach(account => {

                        const description = account.accountDescription.toLowerCase()
                        const exp = new Date(account.paymentDueDate)
                        let arr = null
                        let title = null

                        if (description.match(/.*cuota.*/)) title = account.accountDescription
                        else if (account.billingProduct && account.billingProduct.id == 83) title = `Repactación cuota ${account.paymentInstallment}/${account.paymentInstallments}`
                        else title = account.accountDescription
                        let groupId = null

                        if (account.billingProduct && (!account.reenrollmentId || this.$isIPP))  {
                            switch (account.billingProduct.id) {
                                case 80:
                                case 81:
                                    groupId = groupsId.arancel
                                    break;
                                case 82:
                                case 85:
                                case 86:
                                    groupId = groupsId.independent
                                    break;
                                case 83:
                                    groupId = groupsId.repactacion
                                    break;
                                default:
                                    groupId = groupsId.repactacion
                                    break;
                            }
                        } else if (account.reenrollmentId) {
                            groupId = groupsId.rematricula;
                        } else {
                            groupId = groupsId.arancel;
                        }

                        if (account.term && account.benefitExtra > 0) {
                            this.showExtraBenefit = true
                        }

                        let off = 0
                        const discounts = []
                        let tmpTotal = account.paymentAmount
                        if (groupId == groupsId.independent || !this.systemConfig?.contract) {
                            if (account.scholarshipAmount && account.scholarship) {
                                tmpTotal -= account.scholarshipAmount
                                const fullScholarship = account.scholarshipAmount >= account.paymentAmount
                                off += !fullScholarship ? account.scholarshipAmount / account.paymentAmount * 100 : 100
                                discounts.push({
                                    description: account.scholarship.description,
                                    off: !fullScholarship ? account.scholarshipAmount / account.paymentAmount * 100 : 100,
                                    amount: !fullScholarship ? account.scholarshipAmount : account.paymentAmount
                                })
                            }

                            if ((account.discountAmount && account.discount) || (account.discountAmount && this.systemConfig?.dues)) {
                                const fullDiscount = account.discountAmount >= tmpTotal
                                if (!fullDiscount) off += (100 - off) * account.discountAmount / tmpTotal
                                else off = 100
                                discounts.push({
                                    description: account.discount?.description?? null,
                                    off: !fullDiscount ? (100 - off) * account.discountAmount / tmpTotal : 100 - off,
                                    amount: !fullDiscount ? account.discountAmount : tmpTotal
                                })
                            }
                        }
                        arr = {
                            id: account.id,
                            reenrollmentId: account.reenrollmentId || null,
                            title,
                            groupId,
                            independent: groupId == groupsId.independent,
                            selected: true,
                            mandatory: account.mandatory,
                            repaymentAvailable: this.repayment.available && account.paymentStatus.value == 117 && groupId == groupsId.arancel,
                            studentRecord: account.paymentStudentRecord,
                            status: account.paymentStatus.value,

                            // this is required for re-enrollment payment
                            isHiddenProduct: account.billingProduct && this.productsToHide.includes(`${account.billingProduct.id}`),
                            billingProduct: account.billingProduct ? account.billingProduct.id : null,
                            options: {
                                term: account.term,
                                dueDate: account.paymentDueDate,
                                expiration: `Hasta el ${this.dateFormat(account.paymentDueDate)}`,
                                inscriptionDate: response.data.inscriptionDate,
                                paymentInstallment: account.paymentInstallment,
                                paymentInstallments: account.paymentInstallments,
                                month: exp.getUTCMonth() + 1,
                                scholarshipAmount: account?.scholarshipAmount || 0,
                                benefitAmount: account?.benefitAmount || 0,
                                benefitExtra: this.teclabReenrollment?.products?.benefitExtra || account?.benefitExtra,
                                extraOff: 0,
                                reenrollmentExtraOff: account.reenrollmentExtraOff ? account.reenrollmentExtraOff : 0,
                                addedExtraOff: 0,
                                off: this.$options.filters.$roundNumber(account.off ? account.off : off),
                                discounts,
                                amount: account.paymentAmount,
                                adaptationInsurance: account.adaptationInsurance,
                                disabled: !!account?.disabled,
                                subdescription: account.subdescription,
                                accumulativeDiscount: this.teclabReenrollment?.accumulativeDiscount,
                            }
                        }
                        if (arr) {
                            if(arr.isHiddenProduct){
                                if(account.discount && account.discountAmount){
                                    arr.options.off = account.discountAmount * 100 / account.paymentAmount;
                                }
                                this.hiddenPayments.push(arr)
                            } else if (exp < nextMonth) {
                                this.payments.push(arr)
                            } else {
                                if (arr.billingProduct == this.$sisConstants('modules.products.derecho_examen')) {
                                  this.payments.push(arr)
                                } else {
                                  this.paymentsNext.push(arr)
                                  if(!this.systemConfig?.dues){
                                    this.paymentsNext.sort((a,b) => a.options.paymentInstallment - b.options.paymentInstallment).sort((a,b) => a.billingProduct - b.billingProduct)
                                  }
                                }
                            }
                        }
                    });
                    const allPayments = [...this.payments, ...this.paymentsNext]
                    this.onBillingChange(allPayments, 'all')
                    // this.$refs.paymentSelectionGridView.selectAll(true)
                    this.selectAllPaymentMexico
                    if (!response.data.prepaids) response.data.prepaids = []

                    this.prepaids = response.data.prepaids
                    this.prepaids.sort((a, b) => (a.dayFrom > b.dayFrom) ? 1 : -1)
                } catch (error) {
                    this.prepaids = []
                    this.payments = []
                    this.paymentsNext = []
                    throw error
                }
            },
            async getSubscriptionPaymentTypes() {
              try {
                const {data} = await $types?.find(null, null, { params: { type: 'SUSCRIPTION_PAYMENT_TYPE' } })
                this.subscriptionPaymentTypes = data
              } catch (err) {
                this.subscriptionPaymentTypes = []
                throw err
              }
            },
            async getSubscriptions() {
              try {
                const {data} = await $suscriptions?.find(null, null, { params: { active: true } })
                this.subscriptions = data
              } catch (err) {
                this.subscriptions = []
              }
            },
            async getConfig() {
                try {
                    const response = await $accounts?.find()
                    await this.$store.dispatch('commons/setMpConfig', response.data);
                    this.isCheckoutPro = !!response.data?.find((account) => !!account.checkout)
                    this.isGetnet = !!response.data?.find((account) => account.accountKey === 'GETNET' && account.status === true)
                } catch {
                    await this.$store.dispatch('commons/setMpConfig', null);
                }
            },
            goBackPayment() {
                if (!this.resumen.total) this.step--
                this.creditCards = []
                this.selectedMethod = {id: null};
                this.$nextTick(function () {
                  if (this.$isGestor && this.$isTeclab) {
                    this.resumen.items.adminExpenses.monto = 0
                    this.resumen.total = this.resumen.items.subtotal.monto - this.resumen.items.discounts.monto -this.resumen.items.scholarships.monto - this.resumen.items.benefits.monto
                    if (this.resumen.total < 0) this.resumen.total = 0
                    this.showSubscriptionPaymentMethod()
                  }
                })
                this.$trackEvent('Pagos', 'Volver', 'Método de pago 2')
            },
            goToRepayment() {
                this.isRepayment = true
                if(this.isRepayment & this.$isIPP) this.$trackEvent('Pago', 'Clk_BtnRepact_Repac', 'Pago repactación')  //Google Analytics - Repactacion 1
                this.repayment.selected = true
                this.summaryButtons.primary.disabled = true
                this.summaryButtons.secondary.visible = true
                this.summaryButtons.tertiary.visible = false

                this.resumen.items.subtotal.items = JSON.parse(JSON.stringify(this.resumen.items.repayments.items))
                this.resumen.items.subtotal.items = this.resumen.items.subtotal.items.map(item => {
                    item.title = `Arancel cuota ${item.title}`
                    return item
                })
                this.resumen.items.subtotal.monto = this.resumen.items.repayments.monto
                this.resumen.items.discounts.items = []
                this.resumen.items.discounts.monto = 0
                this.resumen.total = this.resumen.items.subtotal.monto

                this.$nextTick()
                this.definePrimaryCallback()
            },
            paymentSelected(payment) {

                if (payment.id != 'TC2' && payment.id != 'SUS') this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentLink')
                else this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
                this.selectedMethod = payment
            },
            functionSelectPayFT(pay){
                this.selectPayFT = pay
            },
            async onPayClick () {
                if(this.systemConfig?.dues) {
                    const isReenrollmentAvailable = this.showBanner && !this.systemConfig?.contract;
                    const itemsType = isReenrollmentAvailable ? 'reenrollmentItems' : 'allReenrollmentItems';
                    const summaryType = isReenrollmentAvailable ? 'resumen' : 'allItemsResumen';
                    if(this.$isIPP & !this.isRepayment) this.$trackEvent('Pago', 'CLK_BtnContPag_Pagos', 'Pago cuotas') // Común 1 Ipp
                    const reenrollmentStatus = await this.createReenrollmentItems(itemsType, summaryType);
                    if (!reenrollmentStatus) return false
                    if(isReenrollmentAvailable){
                        await this.saveDiscounts(summaryType);
                    }else{
                        this.$trackEvent('Pago', 'CLK_BtnContPag_Pagos', 'PagoArancel')
                        await this.saveDiscounts(summaryType);
                    }
                } else {
                  const reenrollmentStatus = await this.createReenrollmentItems('reenrollmentItems', 'resumen')
                  if (!reenrollmentStatus) return false
                  this.$trackEvent('Pago', 'CLK_BtnContPag_Pagos', 'Pago cuotas')
                  await this.saveDiscounts('resumen');
                }
                this.step++
                if (!this.resumen.total) {
                  this.selectedMethod.id = 'FT'
                  this.coupon.payment = {id: 'Efectivo'}
                  this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
                }
                this.summaryButtons.primary.disabled = true
                this.summaryButtons.secondary.visible = true
                this.summaryButtons.tertiary.visible = false
                if (this.$isGestor && this.$isTeclab) {
                    this.showSubscriptionPaymentMethod()
                }
                this.$nextTick()
                this.$trackEvent('Pagos', 'Click - Pago 1', 'Selección de aranceles')
            },
            showSubscriptionPaymentMethod() {
                if (this.filteredSubscriptions.length) {
                    if (!this.$refs.PSV.paymentMethods.includes(this.subscriptionMethod)) {
                        this.$refs.PSV.paymentMethods.push(this.subscriptionMethod)
                    }
                } else {
                    this.$refs.PSV.paymentMethods = this.$refs.PSV.paymentMethods.filter(method => method.id != 'SUS')
                }
            },
            async createReenrollmentItems(reenrollmentItems, resumen) {
                if (this[reenrollmentItems].length) {
                    try {
                        const discountItems = this[resumen].items.discounts.items.map((item, index) => {
                          return item.monto + this[resumen].items.benefits.items[index].monto + this[resumen].items.scholarships.items[index].monto
                        })
                        if (this.referedCredits || this.teclabReenrollment?.uniqueDiscount || this.ippReenrollment?.uniqueDiscount) {
                            let remainingCredits = this.referedCredits + this.teclabReenrollment?.uniqueDiscount ?? 0 + this.ippReenrollment?.uniqueDiscount ?? 0

                            this[resumen].items.subtotal.items.forEach((item, index) => {
                                if (remainingCredits) {
                                    if (item.monto - discountItems[index] >= remainingCredits) {
                                        discountItems[index] += remainingCredits
                                        remainingCredits = 0
                                    } else {
                                        discountItems[index] += item.monto - discountItems[index]
                                        remainingCredits -= item.monto - discountItems[index]
                                    }
                                }
                            })
                        }
                        const reenrollmentItemsParam = this[reenrollmentItems].map((reenrollmentItem, index) => {
                          const amount = this[resumen].items.subtotal.items?.find(subtotalItem => subtotalItem.reenrollment?.billingProduct == reenrollmentItem.product)?.monto
                          reenrollmentItem.amount = this.$encrypter(amount)
                          reenrollmentItem.discount = this.$encrypter(discountItems[index] >= amount ? amount : discountItems[index])
                          //reenrollmentItem.paid = !this.systemConfig?.contract && this.systemConfig?.dues ? false : amount <= discountItems[index]
                          reenrollmentItem.user = this.studentUserId ? this.studentUserId : null
                          return reenrollmentItem
                        })

                        const params = {}
                        if (this.systemConfig?.contract) params.isNi = true
                        const accounts = await $studentAccount.create(reenrollmentItemsParam, { params })
                        accounts.data.forEach(acc => {
                            const subtotal = this.resumen.items.subtotal.items?.find(s => s.reenrollmentId == acc.reenrollmentId)
                            if (subtotal) subtotal.id = acc.accountId
                            const subtotalAllItems = this.allItemsResumen.items.subtotal.items?.find(s => s.reenrollmentId == acc.reenrollmentId)
                            if (subtotalAllItems) subtotalAllItems.id = acc.accountId

                            const discount = this.resumen.items.discounts.items?.find(s => s.reenrollmentId == acc.reenrollmentId)
                            if (discount) discount.id = acc.accountId
                            const discountAllItems = this.allItemsResumen.items.discounts.items?.find(s => s.reenrollmentId == acc.reenrollmentId)
                            if (discountAllItems) discountAllItems.id = acc.accountId
                        })
                        if (this.adaptationInsuranceTerms.length && this[resumen].items.subtotal.items?.some(subtotalItem => subtotalItem.reenrollment.options.adaptationInsurance)) {
                            $studentAccount.create({ id: this.studentId }, {}, 'confirmAdaptationInsurance')
                        }
                        return true
                    } catch (error) {
                        this.$store.dispatch('commons/openPopUp', {
                            title: error.codeMeaning && error.codeMeaning != '' ? error.codeMeaning : this.$t('modules.portalalumno.payments.paymentsStudents.view.reenrollment.error_creating'),
                            content: null,
                            actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                            icon: { color: 'error', name: 'mdi-close' },
                            color: 'primary',
                        });
                        return false
                    }
                } else return true
            },
            async saveDiscounts(resumen) {
                const legajo = this.studentRegister;
                const discountData = {
                    studentCareerId: legajo,
                    items: this[resumen].items.discounts.items.map((item, index) => {
                        const {subtotal, benefits, scholarships} = this[resumen].items
                        return {
                            accountId: item.id,
                            discountAmmount: item.monto,
                            benefitAmmount: benefits.items[index].monto - (subtotal.items[index].monto - item.monto) <= 0 ? benefits.items[index].monto : subtotal.items[index].monto - item.monto,
                            scholarshipAmount: scholarships.items[index].monto - (subtotal.items[index].monto - item.monto - benefits.items[index].monto) <= 0 ? scholarships.items[index].monto : subtotal.items[index].monto - item.monto - benefits.items[index].monto
                        }
                    }),

                    paid: !this.resumen.total
                };

                if (this.referedCredits ||  this.teclabReenrollment?.uniqueDiscount || this.ippReenrollment?.uniqueDiscount ) {
                  let remainingCredits = (this.referedCredits ?? 0)  + (this.teclabReenrollment?.uniqueDiscount ?? this.ippReenrollment?.uniqueDiscount ?? 0)
                  this[resumen].items.subtotal.items.forEach((item, index) => {
                    const {benefitAmmount, discountAmmount, scholarshipAmount} = discountData.items[index]

                    if (remainingCredits) {
                        if (item.monto - discountAmmount - benefitAmmount - scholarshipAmount >= remainingCredits) {
                            discountData.items[index].benefitAmmount += remainingCredits
                            remainingCredits = 0
                        } else {
                            discountData.items[index].benefitAmmount += item.monto - discountAmmount - benefitAmmount - scholarshipAmount
                            remainingCredits -= item.monto - discountAmmount - benefitAmmount - scholarshipAmount
                        }
                    }
                })
            }

            discountData.items = discountData.items.map(item => {
                item.discountAmmount = this.$encrypter(item.discountAmmount)
                item.benefitAmmount = this.$encrypter(item.benefitAmmount)
                item.scholarshipAmount = this.$encrypter(item.scholarshipAmount)
                return item
            })

            const { data } = await $discount.create(discountData);
            this.resumen.paymentIntent = data.intent;
        },
        previousStep() {
            this.creditCards = []
            if (this.step > 1) {
                this.step = 1
                this.selectedMethod = { id: null }
                if (this.$isGestor && this.$isTeclab) {
                    this.resumen.items.adminExpenses.monto = 0
                }
                this.$trackEvent('Pagos', 'Cancelar', 'Step pago')
            } else {
                this.repayment.selected = false
                this.summaryButtons.tertiary.visible = true
            }

            if (!this.repayment.selected) {
                const payments = [...this.payments.filter(payment => payment.selected), ...this.paymentsNext.filter(payment => payment.selected)]
                this.onBillingChange(payments)
            }

            this.resetButtons()
        },
        definePrimaryCallback() {
            if (this.step == 1) {
                if (!this.repayment.selected) this.summaryButtons.primary.callback = () => { this.onPayClick() }
                else this.summaryButtons.primary.callback = () => { this.$refs.REP.confirm() }
            } else if (this.step == 2 && this.selectedMethod.id == 'TC1') {
                this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.TC1.saveBillingData() }
            } else if (this.step == 2 && this.selectedMethod.id == 'TC2') {
                this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.TC2.paymentCardsInfo() }
            } else if (this.step == 2 && this.selectedMethod.id == 'TD') {
                this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.TD.$refs.debitCard.saveBillingData() }
            } else if (this.step == 2 && ['FT', 'ATM'].includes(this.selectedMethod.id)) {
                this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.resumen.total ? this.$refs.FT.generateCoupon() : this.$refs.FT.oneCentReceipt() }
            } else if (this.step == 2 && this.selectedMethod.id == 'SUS') {
                this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.SUS.paymentInstallmentsSelected() }
            } else if (this.step == 2 && this.selectedMethod.id == 'CHECK') {
                this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.CHECK.redirectPay() }
            } else if (this.step == 2 && this.selectedMethod.id == 'GETNET') {
                this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.GETNET.initGetnet() }
            }
        },
        analyticsPaymentEvent() {
            const pay = this.payments?.find(payment => payment.selected &&
                (payment.billingProduct == this.$sisConstants('modules.products.matricula') ||
                    payment.billingProduct == this.$sisConstants('modules.products.examen') ||
                    payment.billingProduct == this.$sisConstants('modules.products.gasto_administrativo') ||
                    payment.billingProduct == this.$sisConstants('modules.products.nueva_tne') ||
                    payment.billingProduct == this.$sisConstants('modules.products.revalidacion_tne')))
                this.$trackEvent('Pagos', `Pago ${pay ? pay.title : 'Arancel'}`, 'Método de pago 2')

                // Google Analytics - BTN Pagar dependiendo el pago
                if(this.$isIPP){
                    if(this.isRepayment){
                        if(this.selectedMethod.id == 'TC1') this.$trackEvent('Pago', 'Clk_BtnPayCR_repac', 'Pago repactación')
                        if(this.selectedMethod.id == 'TD') this.$trackEvent('Pago', 'Clc_BtnPayDeb_repac', 'Pago repactación')
                    }else if (!this.isRepayment){
                        if(this.selectedMethod.id == 'TC1') this.$trackEvent('Pago', 'Clk_BtnPayCR_Pagos', 'Pago cuotas')
                        if(this.selectedMethod.id == 'TD') this.$trackEvent('Pago', 'Clc_BtnPayDeb_Pagos', 'Pago cuotas')
                    }
                }else if(this.$isTeclab){
                    if(this.selectedMethod.id == 'TC1') this.$trackEvent('Pago', 'Clk_BtnPayCR_Pagos', 'PagoArancel')
                    if(this.selectedMethod.id == 'TD') this.$trackEvent('Pago', 'Clc_BtnPayDeb_Pagos', 'PagoArancel')
                    if(this.selectedMethod.id == 'FT' & this.selectPayFT == 'pagofacil') this.$trackEvent('Pago', 'Clk_BtnPayPagFac_Pagos', 'PagoArancel')
                    if(this.selectedMethod.id == 'FT' & this.selectPayFT == 'rapipago') this.$trackEvent('Pago', 'Clk_BtnPayRapPag_Pagos', 'PagoArancel')
                    if(this.selectedMethod.id == 'CHECK') this.$trackEvent('Pago', 'Clk_BtnPayMerPag_Pagos', 'PagoArancel')
                }
            },
            resetButtons() {
                if (this.step == 1) {
                    this.summaryButtons.primary.disabled = !this.payments.length
                    this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
                    this.summaryButtons.primary.loading = false
                    this.summaryButtons.primary.success = false
                    this.summaryButtons.primary.error = false
                    if (this.repayment.selected) {
                        this.summaryButtons.secondary.visible = true
                        this.summaryButtons.tertiary.visible = false
                    } else {
                        this.summaryButtons.secondary.visible = false
                        this.summaryButtons.tertiary.visible = this.enableRepayment
                    }
                    this.definePrimaryCallback()
                }
            },

        onBillingChange(payments, itemsToUpdate='selected') {
                if(itemsToUpdate === 'all'){
                    this.paymentsSelected =  payments
                } else if(itemsToUpdate === 'selected'){
                    this.paymentsSelectedAll = payments
                }
                const resumen = itemsToUpdate === 'all' ? 'allItemsResumen' : 'resumen';
                const reenrollmentItems = itemsToUpdate === 'all' ? 'allReenrollmentItems' : 'reenrollmentItems'
                this.summaryButtons.primary.disabled = !payments.length
                this.summaryButtons.tertiary.visible = this.enableRepayment
                if (payments?.some(payment => payment.options.adaptationInsurance)) {
                  this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.applyAdaptationInsurance')
                } else {
                  this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
                }
                const allPayments = [...this.payments, ...this.paymentsNext]
                const all = allPayments.length === this[resumen].items.discounts.items.length == 0
                this[reenrollmentItems] = []
                this[resumen].items.subtotal.items = payments.map(payment => {
                    if (payment.reenrollmentId) {
                      if(this.systemConfig.dues && !this.systemConfig.contract) {
                        this[reenrollmentItems].push({
                          reenrollmentId: payment.reenrollmentId,
                          product: payment.billingProduct,
                          term: payment.options.term.id,
                          student: this.studentId,
                          studentCareer: this.studentRegister,
                          dueDate: payment.options.dueDate,
                          amount: payment.options.amount,
                          installment: payment.options.paymentInstallment,
                          installments: payment.options.paymentInstallments
                        })
                      } else {
                        this[reenrollmentItems].push({
                          reenrollmentId: payment.reenrollmentId,
                          product: payment.billingProduct,
                          term: payment.options.term.id,
                          student: this.studentId,
                          studentCareer: this.studentRegister,
                          dueDate: payment.options.dueDate,
                          amount: payment.options.amount,
                        })
                      }
                    }

                    return {
                        id: payment.id,
                        reenrollment: payment.id == null ? payment : null,
                        reenrollmentId: payment.reenrollmentId,
                        monto: payment.options.amount,
                        title: payment.title,
                        billingProduct: payment.id == null ? payment.billingProduct : null,
                    }
                })
                this[resumen].items.discounts.items = payments.map(payment => {
                    const scholarshipBenefitOff = payment.options.amount ? (payment.options.scholarshipAmount + payment.options.benefitAmount) / payment.options.amount * 100 : 0
                    const discountOff = payment.options.off - scholarshipBenefitOff
                    let off = payment.options.addedExtraOff ? discountOff + payment.options.addedExtraOff : discountOff

                    // if(payment.options.discounts.length) {
                    //   off = payment.options.off + (((payment.options.discounts[0].amount / payment.options.amount) * 100) - this.prepaids[0]?.percentage)
                    // }

                    if(payment.options.reenrollmentExtraOff){ off += payment.options.reenrollmentExtraOff }

                    if((this.showBanner) && all && (this.teclabReenrollment?.accumulativeDiscount)){
                      off += this.showBannerBenefitExtra?.benefitExtra
                    }

                    if(!this.teclabReenrollment?.accumulativeDiscount && !payment.options.discounts.length){
                      if(payment.options.reenrollmentExtraOff != 0){
                        const extraOff = payment.options.amount - (payment.options.amount * payment.options.reenrollmentExtraOff / 100)
                        this.aux = extraOff  - (extraOff * payment.options.off / 100)
                      }else{
                        this.aux = payment.options.amount  - (payment.options.amount * (payment.options.off - scholarshipBenefitOff ) / 100)
                      }
                      if(this.showBanner && all && (payment.options.off - scholarshipBenefitOff) > 0){
                        this.aux = (this.aux - (this.aux * this.showBannerBenefitExtra?.benefitExtra / 100))
                      }
                      off = 100 - (this.aux * 100 / payment.options.amount)
                    }
                    const amount = payment.options.amount * off / 100
                    if (payment.reenrollmentId) {
                        const reenrollment = this[reenrollmentItems]?.find(item => item.reenrollmentId == payment.reenrollmentId)
                        reenrollment.amount -= amount
                    }
                    return {
                        id: payment.id,
                        reenrollment: payment.id == null ? payment : null,
                        reenrollmentId: payment.reenrollmentId,
                        monto: payment.options.amount ? amount : 0,
                        title: `${payment.title} - ${payment.options.amount ? this.$options.filters.$roundNumber(off) : 0}% off`
                    }
                })

            this[resumen].items.benefits.items = payments.map(payment => {
                if (payment.options.adaptationInsurance) {
                    return {
                        id: payment.id,
                        monto: payment.options.amount,
                        title: `${payment.title} - 100% off`
                    }
                } else {
                    let benefitAmount = 0
                    let benefitOff = 0
                    let benefitPercentage
                    if (payment.options.amount && this.teclabReenrollment.benefit) {
                        let scholarshipOff = null
                        let discountOff = null
                        let off = null
                        let auxBenefit = null
                        if (this.teclabReenrollment?.accumulativeDiscount && this.showBannerBenefitExtra?.benefitExtra) {
                            scholarshipOff = payment.options.scholarshipAmount / payment.options.amount * 100
                            benefitOff = payment.options.benefitAmount / payment.options.amount * 100
                            discountOff = payment.options.off - scholarshipOff - benefitOff
                            off = payment.options.addedExtraOff ? discountOff + payment.options.addedExtraOff : discountOff
                            benefitAmount = benefitOff + off < 100 ? payment.options.benefitAmount : payment.options.amount * (100 - off) / 100
                        } else {
                            if (this.showBannerBenefitExtra?.benefitExtra > 0 && (this.showBanner) && all) {
                                scholarshipOff = payment.options.scholarshipAmount / payment.options.amount * 100
                                benefitOff = payment.options.benefitAmount / payment.options.amount * 100
                                this.aux = (payment.options.amount * benefitOff) / 100
                                auxBenefit = ((payment.options.amount - this.aux) * this.showBannerBenefitExtra?.benefitExtra) / 100
                                auxBenefit = auxBenefit / payment.options.amount * 100
                                discountOff = payment.options.off - scholarshipOff - benefitOff + auxBenefit
                                off = payment.options.addedExtraOff ? discountOff + payment.options.addedExtraOff : discountOff
                                benefitAmount = benefitOff + off < 100 ? payment.options.benefitAmount : payment.options.amount * (100 - off) / 100
                                benefitAmount = benefitAmount + ((payment.options.amount - this.aux) * this.showBannerBenefitExtra?.benefitExtra) / 100
                                benefitOff = benefitOff + auxBenefit
                                payment.options.addedExtraOff = auxBenefit
                            } else {
                                scholarshipOff = payment.options.scholarshipAmount / payment.options.amount * 100
                                benefitOff = payment.options.benefitAmount / payment.options.amount * 100

                                const amount = ((payment.options.amount - payment.options.scholarshipAmount) / 100) * benefitOff
                                benefitPercentage = (amount / payment.options.amount) * 100
                                discountOff = payment.options.off - scholarshipOff - benefitPercentage
                                off = payment.options.addedExtraOff ? discountOff + payment.options.addedExtraOff : discountOff
                                benefitAmount = benefitOff + off < 100 ? amount : payment.options.benefitAmount
                                payment.options.addedExtraOff = 0
                            }
                        }
                    }

                    return {
                        id: payment.id,
                        monto: benefitAmount,
                        title: `${payment.title} - ${this.$options.filters.$roundNumber(benefitPercentage || benefitOff)}% off`
                    }
                }
            })

                if (this.referedCredits && payments.length) {
                  this[resumen].items.benefits.items.push({
                    id: 'referedFriend',
                    monto: this.$options.filters.$roundNumber(this.referedCredits),
                    title: this.$t('modules.portalalumno.payments.paymentsStudents.view.benefit.type.referedFriend')
                  })
                }
                else if (this.teclabReenrollment?.uniqueDiscount && payments.length) {
                  this[resumen].items.benefits.items.push({
                    id: 'uniqueDiscount',
                    monto: this.$options.filters.$roundNumber(this.teclabReenrollment.uniqueDiscount),
                    title: this.$t('modules.portalalumno.payments.paymentsStudents.view.benefit.type.uniqueDiscount')
                  })
                }
                else if (this.ippReenrollment.uniqueDiscount && payments.length) {
                  this[resumen].items.benefits.items.push({
                    id: 'uniqueDiscount',
                    monto: this.$options.filters.$roundNumber(this.ippReenrollment.uniqueDiscount),
                    title: this.$t('modules.portalalumno.payments.paymentsStudents.view.benefit.type.uniqueDiscount')
                  })
                }
                this[resumen].items.scholarships.items = payments.map(payment => {
                  let scholarshipOff = 0
                  let benefitOff = null
                  let discountOff = null
                  let off = null
                  let scholarshipAmount = 0
                  let auxScholarship = null
                  if(payment.options.scholarshipAmount > 0){
                  if (this.teclabReenrollment.scholarship){
                  if(this.teclabReenrollment?.accumulativeDiscount && this.showBannerBenefitExtra?.benefitExtra ){
                    if((this.showBanner) && all){
                      scholarshipOff = (payment.options.scholarshipAmount / payment.options.amount * 100) + this.showBannerBenefitExtra?.benefitExtra
                    }else{
                      scholarshipOff = (payment.options.scholarshipAmount / payment.options.amount * 100)
                    }
                    benefitOff = payment.options.benefitAmount / payment.options.amount * 100
                    discountOff = payment.options.off - scholarshipOff - benefitOff
                    off = payment.options.addedExtraOff ? discountOff + payment.options.addedExtraOff : discountOff
                    scholarshipAmount = scholarshipOff + benefitOff + off < 100
                      ? payment.options.scholarshipAmount
                      : benefitOff + off < 100
                        ? payment.options.amount * (100 - (off + benefitOff)) / 100
                        : 0

                  } else if(this.showBannerBenefitExtra?.benefitExtra > 0 && (this.showBanner) && all){
                      scholarshipOff = payment.options.scholarshipAmount / payment.options.amount * 100
                      const offOld =scholarshipOff
                      this.aux = (payment.options.amount * scholarshipOff) / 100
                      auxScholarship = ((payment.options.amount - this.aux) * this.showBannerBenefitExtra?.benefitExtra) / 100
                      benefitOff = payment.options.benefitAmount / payment.options.amount * 100
                      discountOff = payment.options.off - scholarshipOff - benefitOff
                      off = payment.options.off > 100 ? payment.options.addedExtraOff ? discountOff + payment.options.addedExtraOff : discountOff : payment.options.off
                      scholarshipAmount = this.aux + auxScholarship
                      scholarshipOff = payment.options.scholarshipAmount > 0 ? (scholarshipAmount * 100) / payment.options.amount : 0
                      payment.options.addedExtraOff = scholarshipOff-offOld
                    } else {
                      scholarshipOff = (payment.options.scholarshipAmount / payment.options.amount * 100)
                      benefitOff = payment.options.benefitAmount / payment.options.amount * 100
                      discountOff = payment.options?.off - scholarshipOff - benefitOff - auxScholarship
                      off = payment.options.addedExtraOff ? discountOff + payment.options.addedExtraOff : discountOff
                      scholarshipAmount = scholarshipOff + benefitOff + off < 100
                        ? payment.options.scholarshipAmount
                        : benefitOff + off < 100
                          ? payment.options.amount * (100 - (off + benefitOff)) / 100
                          : 0

                      payment.options.addedExtraOff = 0
                    }
                  }
                }
                  return {
                    id: payment.id,
                    monto: scholarshipAmount,
                    title: `${payment.title} - ${this.$options.filters.$roundNumber(scholarshipOff)}% off`
                  }
                })

                this[resumen].items.repayments.items = payments.filter(payment => payment.repaymentAvailable && payment.selected).map(payment => ({
                    id: payment.id,
                    monto: payment.options.amount,
                    title: `${payment.options.paymentInstallment}/${payment.options.paymentInstallments}`
                }))
                this[resumen].items.repayments.monto = this[resumen].items.repayments.items.reduce((acc, curr) => {return acc += +curr.monto}, 0)
                if(this.$isMexico){
                  this[resumen].items.subtotal.monto = payments.reduce((acc, curr) => { if (curr.billingProduct !== 80) { return acc + (+curr.options.amount || 0); } return acc; }, 0);
                  this[resumen].items.discounts.monto = this[resumen].items.discounts.items.reduce((acc, curr) => {if (curr.reenrollment?.billingProduct !== 80) { return acc + (+curr.monto || 0);} return acc;}, 0);
                } else {
                  this[resumen].items.subtotal.monto = payments.reduce((acc, curr) => {return acc += +curr.options.amount}, 0)
                  this[resumen].items.discounts.monto = this[resumen].items.discounts.items.reduce((acc, curr) => {return acc += +curr.monto}, 0)
                }
                this[resumen].items.benefits.monto = this[resumen].items.benefits.items.reduce((acc, curr) => {return acc += +curr.monto}, 0)
                this[resumen].items.scholarships.monto = this[resumen].items.scholarships.items.reduce((acc, curr) => {return acc += +curr.monto}, 0)
                this[resumen].total = this[resumen].items.subtotal.monto - this[resumen].items.discounts.monto - this[resumen].items.scholarships.monto - this[resumen].items.benefits.monto
                if (this[resumen].total < 0) this[resumen].total = 0

                if (this.$isGestor && this.$isTeclab) {
                    const paymentsSelectedBillingProductIds = payments.filter(payment => payment.selected).map(payment => payment.billingProduct)
                    const paymentsNextWithoutSelectedBillingProductIds = this.paymentsNext.filter(payment => payment.selected).reduce((acc, payment) => {
                      if (![this.$sisConstants('modules.products.matricula'), this.$sisConstants('modules.products.arancel')].includes(payment.billingProduct)) {
                        acc.push(payment.billingProduct)
                      }
                      return acc
                    }, [])
                    const paymentBillingProductIds = [...new Set([...paymentsSelectedBillingProductIds, ...paymentsNextWithoutSelectedBillingProductIds])]
                    this.filteredSubscriptions = this.subscriptions.filter(subscription => {

                        const subscriptionPaymentBillingProductIds = subscription.suscriptionDetail.map(detail => detail.billingProduct.id)

                        if (paymentBillingProductIds.length !== subscriptionPaymentBillingProductIds.length) return false
                        const uniqueValues = new Set([...paymentBillingProductIds, ...subscriptionPaymentBillingProductIds])
                          for (const v of uniqueValues) {
                              const aCount = paymentBillingProductIds.filter(e => e == v).length
                              const bCount = subscriptionPaymentBillingProductIds.filter(e => e == v).length
                              if (aCount != bCount) return false
                          }
                        return true

                    })
                    this.filteredSubscriptionsTypes = [...this.subscriptionPaymentTypes.filter(type => {
                        let subscriptionTypeApply = false
                        this.filteredSubscriptions.forEach(subscription => {
                            if (type.value == subscription.suscriptionPaymentType.value) {
                                subscriptionTypeApply = true
                            }
                        })
                        return subscriptionTypeApply
                    }), {
                        meaning: 'Crédito',
                        type: 'SUSCRIPTION_PAYMENT_TYPE',
                        value: 244,
                    }, {
                        meaning: 'Débito',
                        type: 'SUSCRIPTION_PAYMENT_TYPE',
                        value: 245,
                    }]
                }
                this.$nextTick()
            },

        async nextStepChange(data = null) {
            if (this.step == 2 && this.studentData && this.studentData.studentType?.value == 7 && !this.studentData.signed) {
                // Actualizamos datos de alumno para saber si pago o no
                await this.$store.dispatch('commons/initData');
            }
            this.successMessage = data || '';
            this.step = this.step + 1;
        },
        setDue(due) {
            if (due) {
            if (Array.isArray(due)) this.creditCards = due
            else this.creditCards = [ due ]
            } else {
            this.creditCards = []
            }
        },
        updateCouponExternalInfo(payload) {
            this.coupon = { ...this.coupon, ...payload }
            }
        },
    watch: {
        selectedMethod: {
            handler() { this.definePrimaryCallback() },
            deep: true
        },
    },
};
</script>

<style lang="sass">
    @media screen and (min-width: 640px)
        .paymentStepper.v-stepper:not(.v-stepper--vertical) .v-stepper__label
            display: flex
</style>

<style scoped lang="sass">
  .redirect-message p
    font-size: 12px

  .stepper-col
    background: #ffff
    height: 100%

  .stepper
    background: transparent
    border: none
    box-shadow: none !important

  .stepper-step
    background: #ffff

  .stepper-header
    box-shadow: none
    overflow: hidden
    border: solid 1px #c1c1c1
    height: auto
    border-radius: 6px

  .v-stepper__step__step
    display: none !important


  #tabs-payment
    width: 100%
    border-radius: 0.25em

  ul
    margin: 0
    padding: 0
    list-style-type: none
    overflow: hidden
    white-space: nowrap

  li
    + li
      padding: 1em 3em 1em 3em

    background: #f1f7ff
    text-align: center
    font-size: 16px
    font-weight: bold
    color: #c7c7c7
    width: 33%
    padding: 1em 2em
    position: relative
    display: inline-block
    vertical-align: top

    &.active
      background: white
      color: var(--v-primary-base)

      + li:before
        background: white

    &.active:before
      background: #ffff

    + li:before
      content: ''
      position: absolute
      top: 0em
      bottom: 0
      border-right: solid 2px #c1c1c1
      border-top: solid 2px #c1c1c1
      pointer-events: none
      box-sizing: border-box

      /* a shadow effect can be added too
      width: 3em
      transform: rotate(30deg) skewy(30deg)
      z-index: 1

    + li:before
      left: -2em

  @media only screen and (max-width: 600px)
    li, li + li
      padding: 1em
      font-size: 12px
      width: 30%

    li:nth-child(3)
      width: 40%
    .v-stepper--alt-labels .v-stepper__step
        flex-basis: 0px !important

</style>
