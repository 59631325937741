<template>
    <v-container fluid class="superTable pa-0">
        <v-row no-gutters>
            <v-col sm="5">        
                <v-autocomplete
                    v-if="isSelectPeriod"
                    class="my-2"
                    outlined
                    :items="periods"
                    item-text="description"
                    item-value="id"
                    :label="$t('modules.sections.table.periods')"
                    v-model="periodSelected" 
                    >
                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item" ></v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.description"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.termType.meaning"></v-list-item-subtitle>
                            </v-list-item-content> 
                        </template>              
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row v-if="isSearching" no-gutters>
            <v-col sm="4">
                <v-text-field
                    v-model.trim="search"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="$t('actions.search')"
                    outlined
                    clearable
                    class="superTable__search"
                    @click:clear="clearData"
                    @keyup.enter="searchAction"
                    @keyup.esc="clearData"
                ></v-text-field>
            </v-col>
            <v-col sm="4">
                <Button
                    class="ml-4"
                    @clicked="searchAction"
                    outlined
                    depressed
                    :text="$t('modules.subjects.table.search.search')"
                ></Button>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    v-if="isExpand"
                    :show-select="false"
                    :headers="headers"
                    :items="data"
                    :search="search"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    item-key="sectionId"
                    class="superTable__table"
                    style="table-layout: fixed"
                    show-expand
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                >
                    <template #item.synchronizationCourse>
                            <v-icon  size="12" :color="synchronizationCourse ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
                    </template>
                    <template v-if="isActions" v-slot:[`item.action`]="{ item }">
                        <v-menu bottom left>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item v-if="synchronizationCourse"  @click="$emit('syncCoursesSections' , item)">
                                    <v-list-item-title>
                                        <v-icon>mdi-eye</v-icon> {{ $t('modules.canvas.syncCourses.actions.viewSections') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="!synchronizationCourse" @click="$emit('syncUpCoursePopUp', item.id)">
                                    <v-list-item-title>
                                        <v-icon>mdi-sync</v-icon> {{ $t('modules.canvas.syncCourses.actions.sync_up') }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>

                    <template   v-slot:expanded-item="{headers, item}">
                        
                        <!-- Tabla expansible ver traduccion con i18n -->
                        <td  :colspan="headers.length" >
                            <v-data-table
                                :headers="headersSections"
                                :items="item.sectionGroup"
                                hide-default-footer
                                @item-selected="$event => $emit('itemSelected', $event) "
                            >
                            <!-- :items="correction" -->
                            <template  #item.synchronization>
                                <slot :item="item" name="test"></slot>
                            </template>
                            </v-data-table>        
                        </td>
                    </template>  
                
                </v-data-table>

                <v-data-table
                    v-if="isExpand === false"
                    :show-select="false"
                    :headers="headers"
                    :items="data"
                    :search="search"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    item-key="sectionId"
                    class="superTable__table"
                    style="table-layout: fixed">
                    <template #item.synchronizationCourse = "{item}">
                            <v-icon  size="12" :color="item.lms == item.newLms ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
                    </template>

                    <template #item.isOldModality = "{item}">
                            <v-icon  size="12" :color="item.isOldModality ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
                    </template>
                    
                    <template v-if="isActions" v-slot:[`item.action`]="{ item }">
                        <v-menu bottom left>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item v-if="item.lms == item.newLms"  @click="$emit('syncCoursesSections' , item)">
                                    <v-list-item-title>
                                        <v-icon>mdi-eye</v-icon> {{ $t('modules.canvas.syncCourses.actions.viewSections') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-else @click="$emit('syncUpCoursePopUp', item.subjectTermId)">
                                    <v-list-item-title>
                                        <v-icon>mdi-sync</v-icon> {{ $t('modules.canvas.syncCourses.actions.sync_up') }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
            
        </v-row>
        <v-row v-if="isPagination" no-gutters align="center">
            <v-col sm="3">
                <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
                <v-select
                v-model="itemsPerPage"
                :items="itemsPerPageAvailable"
                :placeholder="`${itemsPerPage}`"
                outlined
                class="superTable__paginationSelect d-inline-block ml-4"
                ></v-select>
            </v-col>
            <v-col sm="6" class="text-center">
                <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
            </v-col>
            <v-col sm="3" align="right">
                <v-text-field
                    v-model="page"
                    max="pageCount"
                    outlined
                    class="superTable__paginationInput d-inline-block mr-4"
                ></v-text-field>
                <span class="caption d-inline-block">{{ $t('modules.communities.table.goToPage') }}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Button from '@/components/Button/Button';
    export default {
        name: 'SuperTable',
        components: {
            Button
        },
        props: {
            data: Array,
            headers: Array,
            periods: Array,
            permissions: { create: false },
            goToSubjects: Function,
            isSelectPeriod: Boolean,
            isSearching: Boolean,
            isActions: Boolean,
            isPagination: Boolean,
            isExpand: Boolean,
            synchronizationCourse: Boolean,
            sections: Array,
            sectionsCanvas: Array
        },
        data() {
            return {
                search: '',
                singleExpand: true,
                timeout: null,
                page: 1,
                itemsPerPage: 20,
                itemsPerPageAvailable: [20, 50, 100, 200],
                headersSections: [
                    {
                        text: this.$t('modules.canvas.syncCourses.subject.table.headers.code'),
                        value: 'id',
                    },
                    {
                        text: this.$t('modules.canvas.syncCourses.subject.table.headers.sisSectionId'),
                        value: 'sisSectionId',
                    },
                    {
                        text: this.$t('modules.canvas.syncCourses.subject.table.headers.name'),
                        value: 'name',
                    },
                    {
                        text: this.$t('modules.canvas.syncCourses.subject.table.headers.synchronization'),
                        value: 'synchronization',
                    },
                    
                ],
                expanded: [],
                periodSelected:[],
            }
        },
        methods: {
            keyup () {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.searchAction()
                    clearTimeout(this.timeout);
                },1000)
            },
            searchAction() {
                if (this.search !== null && this.search !== '') {
                    this.$emit('searchAction',{search: this.search, period: this.periodSelected} );
                }
            },
            clearData() {
                this.search = ''
                this.$emit('searchAll');
            }
        },
        watch:{
            page(val) {
                if(val > this.pageCount) this.page = this.pageCount;
                if(val <= 1) this.page = 1;
            },
        },
        computed: {
            pageCount () {
                let counter = this.data.length / this.itemsPerPage;
                if (counter < 1) counter = 1
                return Math.ceil(counter)
            },
            itemsCount () {
                const counter = {
                    totalItems: this.data.length,
                    startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) +  1,
                    endItem: this.page * this.itemsPerPage
                }
                if ( counter.endItem > this.data.length ) counter.endItem = this.data.length
                return counter
            }
        },
    };
</script>

<style lang="sass" scoped>
    .subjectsStudyPlanTable table tbody tr
        cursor: pointer

    .v-expansion-panels
        .v-expansion-panel-content
            padding-left: 10px
            padding-bottom: 0px
            padding-right: 16px
    .v-data-table ::v-deep

        .v-data-table__wrapper 
            tbody tr

            .v-data-table__expanded__content
                box-shadow: none
</style>

