<template>
    <v-container>
        <v-form id="formulario">
          <v-row>
            <v-col cols="12">
                <h5 class="primary--text">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.cardTitle') }}</h5>
            </v-col>

            <v-col class="d-flex align-center mb-8" cols="12">
                <VuePaycard
                    :has-random-backgrounds="false"
                    :is-card-number-masked="false"
                    :value-fields="valueFields"
                    :labels="labels"
                    :inputFields="{ cardNumber: 'v-card-number', cardName: 'v-card-name', cardMonth: 'v-card-month', cardYear: 'v-card-year', cardCvv: 'v-card-cvv' }"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <v-text-field
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.cardnumber')"
                    v-model="cardNumber"
                    @change="validateBin"
                    data-card-field
                    outlined
                    data-decidir="card_number"
                    v-mask="cardMask"
                    :error-messages="$v.cardNumber.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : (!validBin ? ' ' : '')"
                    @blur="$v.cardNumber.$touch()"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
                <v-text-field
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.cardexpiration')"
                    append-icon="mdi-calendar"
                    v-model="cardExpireDate"
                    data-card-field
                    v-mask="'##/##'"
                    outlined
                    :error-messages="$v.cardExpireDate.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.cardExpireDate.$touch()"
                ></v-text-field>

                <v-text-field 
                    v-show="false"
                    :value="this.cardExpireDate?.slice(0, -3)"
                    data-decidir="card_expiration_month"
                ></v-text-field>

                <v-text-field 
                    v-show="false"
                    :value="this.cardExpireDate?.slice(-2)"
                    data-decidir="card_expiration_year"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
                <v-text-field
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.cardvfcode')"
                    v-model="cardSecurityCode"
                    data-card-field
                    outlined
                    data-decidir="security_code"
                    for="CVV"
                    id="v-card-cvv"
                    v-mask="codeMask"
                    :error-messages="$v.cardSecurityCode.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : (!cardCodeValid ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.length') : '')"
                    @blur="$v.cardSecurityCode.$touch()"
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.nameoncard')"
                    v-model="cardName"
                    data-card-field
                    outlined
                    data-decidir="card_holder_name"
                    :error-messages="$v.cardName.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.cardName.$touch()"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
                <IdentificationInput
                  v-model="cardId"
                  :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.idoncard', {identificationType: this.$isTeclab ? 'DNI' : 'RUT'}) + ' *'"
                  :id-type="$isTeclab ? 'DNI' : 'RUT'"
                  :error-messages="$v.cardId.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                  @blur="$v.cardId.$touch()"
                  @validateRut="(status) => {validCardRut = status}"
              ></IdentificationInput>
            </v-col>

            <v-col cols="12" sm="6" v-show="false">
              <v-text-field 
                data-decidir="card_holder_doc_number"
                :value="this.cardId"
              ></v-text-field>
              <input type="hidden" id="deviceId" name="deviceId" v-model="deviceId" />
              <input type="hidden" value="dni"  data-decidir="card_holder_doc_type"/>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="showInstallments">
            <v-col cols="12" class="pb-0">
                <h5 class="primary--text">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.how_many_installments') }}</h5>
            </v-col>
            <v-col cols="12" md="8">
                <v-select
                    v-model="$v.selectedInstallment.$model"
                    @change="setDue"
                    label="Cantidad de cuotas"
                    :items="installments"
                    item-text="recommended_message"
                    return-object
                    :error-messages="$v.selectedInstallment.$anyError ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.selectedInstallment.$touch()"
                    outlined
                ></v-select>
            </v-col>
            <v-col cols="12" md="4" v-if="resumen_interes">
                <p v-if="resumen_interes.tea.length" class="my-0 caption">{{`TEA: ${resumen_interes.tea[1]}`}}</p>
                <p class="my-0" v-if="resumen_interes.tea.length">{{`CFT: ${resumen_interes.cft[1]}`}}</p>
            </v-col>
        </v-row>
        <!-- <v-row v-show="showInstallments || showBillingData" class="ml-1">
          <h5 class="primary--text mt-1">¿Los datos de facturacion coinciden con los del alumno?</h5>
          <v-switch class="ms-5 ma-0 pa-0" v-model="billingDataCard" @change="onChange"></v-switch>
        </v-row> -->
        
        <!-- <template v-if="$isTeclab">
          <PaymentBillingDataTeclab
            v-if="billingDataCard"
            ref="billingData"
            :isPrisma="isPrisma"
            :isCheck="billingDataCard"
            @update="(data) => { billingData = data }"
            @validate="(val) => { billingDataValid = val }"
          ></PaymentBillingDataTeclab>
        </template>
        <template v-else>
          <PaymentBillingData
            v-if="billingDataCard"
            ref="billingData"
            @update="(data) => { billingData = data }"
            @validate="(val) => { billingDataValid = val }"
          ></PaymentBillingData>
        </template> -->
        
        <template v-if="$isTeclab">
          <PaymentBillingDataTeclab
            v-if="showBillingData"
            ref="billingData"
            @update="(data) => { billingData = data }"
            @validate="(val) => { billingDataValid = val }"
          ></PaymentBillingDataTeclab>
        </template>
        <template v-else>
          <PaymentBillingData
            v-if="showBillingData"
            ref="billingData"
            @update="(data) => { billingData = data }"
            @validate="(val) => { billingDataValid = val }"
          ></PaymentBillingData>
        </template>

    </v-container>
</template>

<script>

    import IdentificationInput from '@/components/IdentificationInput/IdentificationInput'
    import { required } from 'vuelidate/lib/validators'
    import { $payments, $paymentMethod } from '../../Services';
    import { VuePaycard } from 'vue-paycard';
    import commons from '../../Mixins/commons';
    import { mapGetters } from 'vuex'
    import Configuration from '../../../../../../core/constants/configuration';
    import PaymentBillingDataTeclab from '../PaymentBillingData/PaymentBillingDataTeclab';
    import PaymentBillingData from '../PaymentBillingData/PaymentBillingData'
    

    export default {
        name: 'GatewayPrisma',
        components: {
            IdentificationInput,
            VuePaycard,
            PaymentBillingDataTeclab,
            PaymentBillingData,
        },
        props: {
            resumen: Object,
            selectedMethod: Object,
            summaryButtons: Object,
            cardIndex: {type: Number, default: null},
            pendingPayment: {type: Boolean, default: false},
            subscription: {type: Object, default: null},
            hideInstallments: {type: Boolean, default: false},
            selectedAccount: { type: Object },
        },
        mixins: [ commons ],
        data() {
            return {
              isPrisma:false,
              billingDataCard:false,
              labels: {
                  cardName: this.$t('modules.portalalumno.payments.paymentsStudents.view.creditCard.labels.cardName'),
                  cardHolder: this.$t('modules.portalalumno.payments.paymentsStudents.view.creditCard.labels.cardHolder'),
                  cardMonth: 'MM',
                  cardYear: 'YY',
                  cardExpires: this.$t('modules.portalalumno.payments.paymentsStudents.view.creditCard.labels.cardExpires'),
                  cardCvv: 'CVV'
              },
              selectedCreditCard: null,
              selectedBankId: null,
              selectedDues : true,
              billingDataValid: false,
              billingData: null,
              validBin:true,
              binNumber: null,
              cardNumber: null,
              cardExpireDate: null,
              cardExpireMonth:null,
              cardExpireYear:null,
              cardSecurityCode: null,
              cardName: null,
              cardId: null,
              validCardRut: false,
              validRut: false,
              deviceId: null,
              billing: false,
              formCompleted : {creditCard: false, resumen: false},
              selectedPaymentMethodId: null,
              selectedPaymentMethodName: null,
              installments: [],
              secondAmount: null,
              firstAmount: null,
              resumen_interes: null,
              selectedInstallment: null,
              percentageSubscription: null,
              dataPrisma:{}, 
            }
        },
        computed: {
          ...mapGetters({
            studentRegister: 'commons/getStudentActualRegister',
            studentUserData: 'commons/getStudentUserData',
            loggedUsername: 'commons/getUserName',
            mpConfig: 'commons/getMpConfig',
            studentData: 'commons/getStudentData',

          }),
          totalAmount() {
            if (this.cardIndex != null) return this.resumen.cards[this.cardIndex].amount
            else return this.resumen?.total
          },
          mpTotalAmount() {
            if (this.cardIndex != null) return parseFloat(this.resumen.cards[this.cardIndex].amount)
            else return this.resumen?.total
          },
          tasas() {
            if (this.cardIndex != null) return {
              cft: this.resumen.cards[this.cardIndex].cft,
              tea: this.resumen.cards[this.cardIndex].tea
            }
            else return {
              cft: this.resumen.cft,
              tea: this.resumen.tea
            }
          },
          cardNumberValid() {
            if (this.cardNumber == null) return true
            const value = this.cardNumber.replace(/ /gi, '')
            //LO QUE ESTA COMENTADO SE USABA ANTES , Y TODAVIA NO SE BORRA POR QUE PUEDE REHUSARSE MAS ADELANTE
            // const length = this.selectedCreditCard.settings[0].card_number.length || 16
            // return value.length == length
            return value
          },
          cardCodeValid() {
            if (this.cardSecurityCode == null) return true
            //LO QUE ESTA COMENTADO SE USABA ANTES , Y TODAVIA NO SE BORRA POR QUE PUEDE REHUSARSE MAS ADELANTE
            // const length = this.selectedCreditCard.settings[0].security_code.length || 3
            // return this.cardSecurityCode.length == length
            return this.cardSecurityCode.length == 3 || this.cardSecurityCode.length == 4
          },
          dniMask(){
            return ['#######', '########']
          },
          codeMask() {
            //LO QUE ESTA COMENTADO SE USABA ANTES , Y TODAVIA NO SE BORRA POR QUE PUEDE REHUSARSE MAS ADELANTE
            //  const length = this.selectedCreditCard.settings[0].security_code.length || 3
            // let mask = ''
            // for (let i = 0; i < length; i++) {
            //   mask += '#'
            // }
            return ['###', '####']
          },
          cardMask() {
            //LO QUE ESTA COMENTADO SE USABA ANTES , Y TODAVIA NO SE BORRA POR QUE PUEDE REHUSARSE MAS ADELANTE
            // const length = this.selectedCreditCard.settings[0].card_number.length || 16
            // switch (length) {
              const cardType = this.selectedAccount.accountKey
              switch ( cardType ){
              case 'master':
                return '#### #### #### ####'
              case 'visa':
                return '#### #### #### ####'
              case 'amex':
                return '#### ###### #####'
              case 'naranja', 'PRISMA':
                return '#### #### #### ####'
              case 'diners':
                return '#### ###### ####'
              case 'argencard':
                return '#### #### #### ####'
              case 'maestro':
                return ['#### #### #### #### ###', '###### ### ###### ###']
              case 'debvisa':
                return '#### #### #### ####'
              case 'cabal':
                return '#### #### #### ####'
              case 'tarshop':
                return '#### #### #####'
              case 'debmaster':
                return '#### #### #### ####'
              case 'debcabal':
                return '#### #### #### ####'
              case 'otro':
                return '#### #### #### ####'  
              default:
                return '######################'
            }
          },

          dataInvalid() {
            return (
              this.$v.cardName.$error || this.$v.cardId.$error ||
              this.$v.cardNumber.$error ||
              this.$v.cardSecurityCode.$error ||
              this.$v.cardExpireDate.$error ||
              !this.cardName || !this.cardId || !this.validCardRut ||
              !this.cardSecurityCode || !this.cardCodeValid ||
              // !this.cardExpireDate || !this.selectedInstallment || 
              !this.cardExpireDate || ( this.hideInstallments ? !this.hideInstallments : !this.selectedInstallment) || 
              !this.validBin || !this.billingDataValid
            )
          },

          valueFields(){
            return {
              cardName: this.cardName ? this.cardName : '',
              cardNumber: this.cardNumber ? this.cardNumber : '' ,
              cardMonth:  this.cardExpireDate ? this.addStr(this.cardExpireDate, 3, '20').slice(0,-5) : '',
              cardYear: this.cardExpireDate ? this.addStr(this.cardExpireDate, 3, '20').slice(-4) : '',
              cardCvv: this.cardSecurityCode ? this.cardSecurityCode : '',
            }
          },
          
          showInstallments(){
            return !this.$v.cardNumber.$invalid &&  !this.$v.cardName.$invalid &&  
            !this.$v.cardSecurityCode.$invalid  && !this.$v.cardExpireDate.$invalid &&  
            !this.$v.cardId.$invalid && !this.hideInstallments
            
          },

          showBillingData(){
            return this.selectedInstallment || this.hideInstallments
          },
        },
        async mounted() {
        },
        methods: {
          
          // onChange(){
          //   this.billingDataCard
          // },
        
          async validateBin (bin){
              this.binNumber = Number(bin.replace(/ /g,'').substring(0, 6))
              try {
                  if(bin && bin.length >= 6) {
                      await $paymentMethod.find('bin/validator',null, {params: {
                          bankId:this.selectedAccount?.bankId,
                          cardId:this.selectedAccount?.cardId,
                          bin: this.binNumber
                      }})
                      this.fetchDues()
                      this.validBin = true
                  }else{
                      this.validBin = false
                  }
              }catch {
                  this.$store.dispatch('commons/openPopUp', {
                      title: this.$t('alert'),
                      content: [{ value: this.$t('modules.portalalumno.payments.paymentsStudents.inputErrors.invalidBin') }],
                      actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                      icon: { color: 'warning', name: 'mdi-alert' },
                      color: 'primary',
                  });
                  this.validBin = false
              }
          },
          async getTokenPrisma(){
            const URL = `https://${(Configuration.value('env') == 'production') ? 'live':'developers'}.decidir.com/api/v2`
            const publicApiKey = this.$getAccountKey(this.selectedAccount?.account_id)
            this.prisma = new window.Decidir(URL);
            this.prisma.setPublishableKey(publicApiKey);
            this.prisma.setTimeout((Configuration.value('env') == 'production') ? 5000 : 0);//timeout de 5 segundos para produccion y 0 para desarrollo

              const data =  await this.prisma.createToken(document.querySelector('#formulario'),
                (status, data) => {
                  if (status === 200 || status === 201) { 
                    const dataPrisma = {token: data.id, bin: data.bin, device_unique_identifier: this.prisma.device_unique_identifier}
                    this.dataPrisma = dataPrisma
                    this.paymentCreate(this.dataPrisma)
                    return dataPrisma
                  }else{
                    this.dataPrisma = []
                    this.errorPopUp('¡Ups!', this.$t('modules.portalalumno.payments.paymentsStudents.errorDefault.error_processing_payment'))
                  }
                })
            
            return data
          },
          addStr(str, index, stringToAdd){
              return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
          },
          successLoading() {
            this.summaryButtons.primary.loading = false
            this.summaryButtons.primary.success = true
            setTimeout(() => {
              this.summaryButtons.primary.success = false
              this.summaryButtons.primary.disabled = true
            }, 2500)
            
          },
          errorLoading(title = null, message = null) {
            this.summaryButtons.primary.loading = false
            this.summaryButtons.primary.error = true
            this.summaryButtons.primary.error = false
            this.summaryButtons.primary.disabled = true
            if (title && message) {
              this.$store.dispatch('commons/openPopUp', {
                title,
                content: [{ value: message }],
                actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary',
              });
            }
            if (this.cardIndex) this.$emit('paymentError')  // Si paga con varias tarjetas y el error fue en otro que no sea en el primero, vuelve a iniciar
          },
          errorPopUp(title = null, message = null){
            this.summaryButtons.primary.loading = false
            this.summaryButtons.primary.error = true
            this.summaryButtons.primary.error = false
            this.summaryButtons.primary.disabled = true
            if(message!=null || message!=''){
              this.$store.dispatch('commons/openPopUp', {
                title,
                content: [{ value: message }],
                actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary',
              });
            }
          },
          async saveBillingData() {
            this.summaryButtons.primary.loading = true

            try {
              await this.$refs.billingData.save()
              await this.getTokenPrisma()
              
            } catch(error) {
              this.errorLoading('¡Ups!', this.$t('modules.portalalumno.payments.paymentsStudents.errorDefault.error_processing_payment'))
              this.summaryButtons.primary.loading = false
              this.summaryButtons.primary.error = true
              throw(error);
            }
          },

          async paymentCreate(response) {
            let desc = '';
            const conceptos = this.resumen.items.subtotal.items;

            conceptos.forEach((concepto,index) => {
              if(index === 0) desc = concepto.title;
              else desc += ` - ${concepto.title}`
            });

            const cardholderName = this.cardName;
            const array = cardholderName.split(' ');
            const firstName = array[0]; // eslint-disable-line
            const lastName = array[array.length - 1] != '' ? array[array.length - 1] : firstName;// eslint-disable-line

          if(this.cardIndex===null){
                  this.firstAmount = this.subscription ? parseFloat(this.resumen.items.adminExpenses.monto).toFixed(2) : 
                  this.$isTeclab ?  this.totalAmount.toFixed(2) : this.totalAmount.toFixed(0)
            }else{
                  if(this.resumen.cards.length>1 && (this.cardIndex===0)){
                    this.firstAmount = this.resumen.cards[0].amount
                    this.secondAmount = parseFloat(this.resumen.cards[1].amount)
                  }else{
                    this.firstAmount = parseFloat(this.resumen.cards[1].amount)
                    this.secondAmount = this.resumen.cards[0].amount;
                  }
            }
            this.firstAmount = this.$encrypter(parseFloat(this.firstAmount).toFixed(2));
            if(this.secondAmount!==null){this.secondAmount = this.$encrypter(parseFloat(this.secondAmount).toFixed(2));}
            this.percentageSubscription = parseFloat(this.resumen.items.adminExpenses.percentage).toFixed(2)
            if(this.percentageSubscription!==null){this.percentageSubscription = this.$encrypter(this.percentageSubscription)}

            let percentageSubscription = parseFloat(this.resumen.items.adminExpenses.percentage).toFixed(2)
            if (percentageSubscription && !isNaN(percentageSubscription)) percentageSubscription = this.$encrypter(percentageSubscription)
        
            const paymentData = {
                'legajo': this.studentRegister,
                'amount': this.firstAmount,
                'description': this.subscription ? 'GASTO_ADMINISTRATIVO' : desc,
                'payment_method_id': this.selectedAccount.id,
                'token': response.token,
                // 'installments': this.subscription ? this.subscription.installments : (this.selectedInstallment.installments || 1),
                'installments': this.selectedInstallment?.installments || 1,
                'payer_email': this.studentUserData.email,
                'issuer_id': 0,
                'usr_portal': this.loggedUsername,
                'discount_intent': this.resumen.paymentIntent,
                'intencion_de_pago_id': this.$paymentsIdToArray(this.resumen.items.subtotal.items),
                'user_type': this.$isGestor ? 'ADVISOR' : 'STUDENT',
                'doc_number': this.cardId,
                'doc_type': this.$isIPP ? 'RUT' : 'DNI',
                'device_id': this.deviceId,
                'firstName': firstName,
                'lastName': lastName,
                'capture': !this.pendingPayment && !this.subscription,
                'pago_una_tarjeta': this.selectedMethod.id != 'TC2',
                'credit_card_fee': this.selectedInstallment?.credit_card_fee ?? null,
                'secondAmount': this.secondAmount,
                'bin': this.binNumber,
                'accountId': this.selectedAccount.account_id,
                'city': this.billingData.city, 
                'phone': this.billingData.phone,
                'postal_code': this.billingData.zipCode,
                'state': this.billingData.state,
                'address': `${this.billingData.street}-${this.billingData.number}`,
                'device_unique_identifier': response.device_unique_identifier
            };

            if (this.subscription) {
              let percentageSubscription = parseFloat(this.resumen.items.adminExpenses.percentage).toFixed(2)
              if (percentageSubscription !== null) percentageSubscription = this.$encrypter(percentageSubscription)
              paymentData.percentage = percentageSubscription
            }

            try {
              const { data } = await $payments.create(paymentData);
              if (data && data.status !== 'approved'){
                  this.errorLoading('¡Ups!', this.errorMessagePrisma(data.status))
                  this.summaryButtons.primary.error = true
              } else if (data.status === 'approved' || data.status === 'in_process') {
                // check if payment was with Naranja (SINGLE)
                if(data.payment_type_id === 'SINGLE') {
                  this.$emit('paymentProcessed',{ cardName: this.cardName, token: this.token, data, efectivo: 'false' })
                } 
                this.$emit('changeStep', data.status);
                this.successLoading()
              } else if (data.status === 'authorized') {
                  if (this.pendingPayment) {
                      //Pago con dos tajetas
                      this.$emit('paymentProcessed', { index: this.cardIndex, paymentId: JSON.parse(data.full_response).id })
                  } else {
                      //Pago con suscripción
                      this.$emit('paymentProcessed',{ cardName: this.cardName, token: this.token, data, efectivo: 'false' })
                  }
              }
            } catch (response) {
                this.errorLoading('¡Ups!', this.$t('modules.portalalumno.payments.paymentsStudents.errorDefault.error_processing_payment'))
                this.summaryButtons.primary.error = true
                this.summaryButtons.primary.disabled = true
                throw response
            } finally {
                this.summaryButtons.primary.loading = false
            }
          },
          errorMessagePrisma(code){
            switch (code) {
              case '14':
              case '53':
              case '56': 
                return this.$t('modules.portalalumno.payments.paymentsStudents.errorsPrisma.invalid_number')
              case '47':
              case '55':
                return this.$t('modules.portalalumno.payments.paymentsStudents.errorsPrisma.security_code_error')
              case '46':
              case '49':
              case '54':
                return this.$t('modules.portalalumno.payments.paymentsStudents.errorsPrisma.expired_card')
              case '13':
              case '51':
              case '61':
              case '65': 
                return this.$t('modules.portalalumno.payments.paymentsStudents.errorsPrisma.insufficient_amount')
              case '1':
              case '2':
              case '7':
              case '76':
              case '5':   
                return this.$t('modules.portalalumno.payments.paymentsStudents.errorsPrisma.request_authorization_card');
              case '10025':
                return this.$t('modules.portalalumno.payments.paymentsStudents.errorsPrisma.cybersource_error'); 
              default:
                return this.$t('modules.portalalumno.payments.paymentsStudents.errorsPrisma.default_error'); 
            }

          },
          resetDue() {
            if (this.cardIndex != null) {
              this.resumen.cards[this.cardIndex].total = this.resumen.cards[this.cardIndex].amount
              this.resumen.cards[this.cardIndex].cft = '0,00%'
              this.resumen.cards[this.cardIndex].tea = '0,00%'
            } else {
              this.resumen.cft = '0,00%'
              this.resumen.tea = '0,00%'
            }
            this.selectedCreditCard = null
            this.selectedBankId = null
            this.selectedDues = null
            this.$emit('setDue')
          },
          setDue(value) {
                let cft = ['CFT', null]
                let tea = ['TEA', null]

                if (this.$countryConstants.payment.isCftEnabled) {
                    const tax = value.labels.find(label => label.includes('CFT') && label.includes('TEA'))?.split('|') ?? ('CFT_0,0%|TEA_0,0%').split('|')
                    cft = (tax.length) ? tax[0].split('_') : ['CFT', '0,0%']
                    tea = (tax.length) ? tax[1].split('_') : ['TEA', '0,0%']
                    this.resumen_interes = { cft, tea }
                }

                if (this.cardIndex != null) {
                    this.resumen.cards[this.cardIndex].total = value.total_amount
                    this.resumen.cards[this.cardIndex].cft = cft[1] // eslint-disable-line prefer-destructuring
                    this.resumen.cards[this.cardIndex].tea = tea[1] // eslint-disable-line prefer-destructuring
                } else {
                    this.resumen.cft = cft[1] // eslint-disable-line prefer-destructuring
                    this.resumen.tea = tea[1] // eslint-disable-line prefer-destructuring
                }
                this.$emit('setDue', {
                    installments: this.selectedInstallment.installments,
                    installment_amount: this.selectedInstallment.installment_amount,
                    total_amount: this.selectedInstallment.total_amount,
                    credit_card_fee: this.selectedInstallment?.credit_card_fee ?? null,
                    name: this.selectedAccount.name
                })
          },
          validateBlockInstallment() {
            return !this.resumen.items.subtotal.items.some(({billingProduct}) => billingProduct == 80 || billingProduct == 81 || billingProduct == 88)
          },
          async fetchDues() {
            try {
              this.loadingBank = true
              const params = {
                payment_method_id: this.selectedAccount.id,
                amount: this.subscription ? this.resumen.items.adminExpenses.monto : this.totalAmount,
                careerId: this.studentData.career.id,
                studentType: this.studentData.studentType.value,
                bin: this.binNumber,
                accountId:this.selectedAccount.account_id,
                userType: this.$isGestor ? 'ADVISOR' : 'STUDENT'
              }

              params.card_issuer = this.selectedBankId ? this.selectedBankId : 0

              const {data} = await $paymentMethod.find('installments', null, {params})
              const installments = data.installments?.payer_costs ?? data.installments?.find(installment => installment.processing_mode == 'aggregator')?.payer_costs;
              this.installments = (this.validateBlockInstallment() || this.subscription)  ? installments.slice(0, 1) : installments
            
            } finally {
              this.loadingBank = false
            }
    
          },
          
          setCustomRate(cost, rate, cft, tea) {
            cost.installment_rate = rate
            //Save previous total amount to calculate total fee
            cost.credit_card_fee = cost.total_amount
            cost.installment_amount = Math.round(((cost.installment_amount * (cost.installment_rate / 100 + 1)) + Number.EPSILON) * 100) / 100
            cost.total_amount = Math.round(((cost.installment_amount * cost.installments) + Number.EPSILON) * 100) / 100
            cost.credit_card_fee = Math.round(((cost.total_amount - cost.credit_card_fee) + Number.EPSILON) * 100) / 100
            let replacementOrder = true
            cost.recommended_message = cost.recommended_message.split('$')[0].concat(`$ ${this.formatRecommendedMessagePrice(cost.installment_amount)} ($ ${this.formatRecommendedMessagePrice(cost.total_amount)})`);
            cost.labels.forEach((label, index) => {
              if (label.includes('CFT') && label.includes('TEA')) {
                if(!replacementOrder) {
                  replacementOrder = true
                  return cost.labels[index] = `CFT_${cft}%|TEA_${tea}%`
                } else {
                  return cost.labels[index] = `CFT_${cft}%|TEA_${tea}%`
                } 
              }
            })
          },
          formatRecommendedMessagePrice(price) {
            const parts = price.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return parts.join(',');
          },
        },
        watch: {
          dataInvalid(value) {
            this.summaryButtons.primary.disabled = value
          },
        },
        validations: {
            cardNumber: {required},
            cardExpireDate: {required},
            cardSecurityCode: {required},
            cardId: {required},
            cardName: {required},
            selectedInstallment: { required },
        },
        
    }
 
</script>