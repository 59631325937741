import jwt from 'jsonwebtoken';
import redirects from '../constants/redirects';
import { createUrl } from '../helpers/uri-factory';
import { createConfig } from '../helpers/http-config';
import Configuration from '../constants/configuration';
import { Auth } from 'aws-amplify';
import { $endpoints } from '../../modules/portaladministrativo/Dashboard/services/index.js';

const uri = createUrl('oauth');
const coreUri = createUrl('core');

export default {
  install (Vue) {
    Vue.prototype.$auth = this;
  },
  async login(user, password, advisor) {
    const url = window.location.host
    return new Promise((resolve, reject) => {
      fetch(uri, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0',
        },
        body: `grant_type=password&username=${user}&password=${encodeURIComponent(password)}&url=${url}&advisor=${advisor}`,
      }).then(res => { return res.json() })
        .then(async data => {
          if (!data.error) {
            this.setStorage(data);
            resolve(data);
            setTimeout(() => { this.checkTokenExpired() }, 3 * 60 * 1000)
          } else {
            reject(data.error)
          }
        }).catch(reject)
    });
  },
  checkTokenExpiredWithoutRefresh() {
    const refreshToken = localStorage.getItem('refresh_data');
    if (!refreshToken) return true
    const timeToExpire = refreshToken.expires - Date.now();
    if (timeToExpire <= 0) return true
    return false
  },
  checkTokenExpired () {
    let refreshToken = localStorage.getItem('refresh_data');
    if (!refreshToken) return true;
    refreshToken = JSON.parse(refreshToken);
    // Chequeamos que el token todavía sea válido.
    const fiveMin = 5 * 60 * 1000;
    const timeToExpire = refreshToken.expires - Date.now();
    if (timeToExpire <= 0) return true;
    if (timeToExpire <= fiveMin) {
      this.refreshToken();
      return false;
    }
    return false;
  },
  refreshToken () {
    const refreshData = localStorage.getItem('refresh_data');
    fetch(uri, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0',
      },
      body: `grant_type=refresh_token&refresh_token=${JSON.parse(refreshData).token}`,
    }).then(data => {
      if (data.status == 200) return data.json();
      else throw new Error(String(data.status))   // Redirect to Login
    }).then(data => {
      this.setStorage(data);
      setTimeout(() => {this.refreshToken()}, 3 * 60 * 1000)
    }).catch(err => {
      this.logout();
      throw new Error(err);
    })
  },
  setStorage (token) {
    const decodedToken = jwt.decode(token.access_token);
    localStorage.setItem('user_name', JSON.stringify(decodedToken.user_name));
    localStorage.setItem('user_id', JSON.stringify(decodedToken.usuario_id));
    localStorage.setItem('roles', JSON.stringify(decodedToken.roles));
    localStorage.setItem('hasExpired', JSON.stringify(decodedToken.hasExpired))
    localStorage.setItem('access_token', JSON.stringify(token.access_token));
    localStorage.setItem('is_cognito_user', JSON.stringify(decodedToken.isCognitoUserTest));
    localStorage.setItem('access_token_cognito', JSON.stringify(decodedToken.accessTokenCognito));
    localStorage.setItem('refresh_token_cognito', JSON.stringify(decodedToken.refreshTokenCognito));
    localStorage.setItem('cognito_email_verified', JSON.stringify(decodedToken.cognitoEmailVerified));
    localStorage.setItem('cognito_phone_verified', JSON.stringify(decodedToken.cognitoPhoneNumberVerified));
    this.setRefreshData(token.refresh_token, token.expires_in); 
  },
  setRefreshData (refreshToken, expireTime) {
    const refreshData = {
      token: refreshToken,
      expires: Date.now() + (expireTime * 1000)
    };
    localStorage.setItem('refresh_data', JSON.stringify(refreshData));
  },
  checkUserLoggedIn () {
    const accessToken = localStorage.getItem('access_token');
    return !!(!this.checkTokenExpired() && accessToken);
  },
  isStudent() {
    const roles = JSON.parse(localStorage.getItem('roles'));
    return !!roles && !!roles.find(role => role === 'Alumno') && !jwt.decode(JSON.parse(localStorage.getItem('access_token'))).isAdvisor;
  },
  async logout() {
    if(localStorage.getItem('is_cognito_user') === 'true') {
      try {
        await Auth.signOut();
        this.clearStorage();
        window.location.href=`${Configuration.value('cognitoRedirectUrl')}`
      } catch (err) {
        throw new Error(err);
      }
    }else{
      this.clearStorage();
      if (Configuration.value('appBasePath')) {
        window.location.replace(Configuration.value('appBasePath') + redirects.URL_LOGOUT_REDIRECT)
      }
      else {
        window.location.replace(redirects.URL_LOGOUT_REDIRECT)
      }
    }
    
  },
  clearStorage(){
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_name');
    localStorage.removeItem('roles');
    localStorage.removeItem('alumnoSeleccionado');
    localStorage.removeItem('refresh_data');
    localStorage.removeItem('is_cognito_user');
    localStorage.removeItem('access_token_cognito');
    localStorage.removeItem('refresh_token_cognito');
    localStorage.removeItem('endpoints_doble_factor');
    localStorage.removeItem('cognito_email_verified');
    localStorage.removeItem('cognito_phone_verified');
  },
  // *********** FLUJO DE RECUPERACIÓN DE CONTRASEÑA *****
  recoverPass (username, byMail, bySms) {
    const options = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0',
      },
      body: JSON.stringify({ username, byMail, bySms })
    };
    return new Promise((resolve, reject) => {
      fetch(`${coreUri}/recover-pass?url=${window.location.hostname}`, options)
        .then(data => {
          if (data.status == 200) return (data.json());
          else reject(data)   // Redirect to Login
        }).then(data => resolve(data))
        .catch(err => { reject(new Error(err.message)) });
    });
  },
  validateCode (username, validationCode) {
    const options = createConfig({ username, validationCode }, 'POST', true);
    return new Promise((resolve, reject) => {
      fetch(`${coreUri}/validate-code`, options)
        .then(data => {
          if (data.status == 200) return (data.json());
          else reject(data)
        }).then(data => resolve(data))
        .catch(err => { reject(new Error(err.message)) });
    });
  },
  changePassword (username, validationCode, newPassword, newPasswordValidation) {
    const body = {
      username,
      userToken: validationCode,
      newPassword,
      newPasswordValidation
    };
    const options = createConfig(body, 'POST', true);
    return new Promise((resolve, reject) => {
      fetch(`${coreUri}/change-pass`, options)
        .then(data => {
          if (data.status == 200) return (data.json());
          else reject(data)   // Redirect to Login
        }).then(data => resolve(data))
        .catch(err => { reject(new Error(err.message)) });
    });
  },
  // *********** FLUJO DE RECUPERACIÓN DE USUARIO *****
  recoverEmail (cellphone) {
    const options = createConfig({ cellphone }, 'POST', true);
    return new Promise((resolve, reject) => {
      fetch(`${coreUri}/reset-email`, options)
        .then(data => {
          if (data.status == 200) return (data.json());
          else reject(data)   // Redirect to Login
        }).then(data => resolve(data))
        .catch(err => { reject(new Error(err.message)) });
    });
  },
  validateCodeForEmail (cellphone, validationCode) {
    const options = createConfig({ cellphone, validationCode }, 'POST', true);
    return new Promise((resolve, reject) => {
      fetch(`${coreUri}/validate-code-email`, options)
        .then(data => {
          if (data.status == 200) return (data.json());
          else reject(data)   // Redirect to Login
        }).then(data => resolve(data))
        .catch(err => { reject(new Error(err.message)) });
    });
  },
  resetEmail (cellphone, validationCode, newEmail) {
    const body = {
      cellphone,
      validationCode,
      email: newEmail
    };
    const options = createConfig(body, 'POST', true);
    return new Promise((resolve, reject) => {
      fetch(`${coreUri}/reset-email`, options)
        .then(data => {
          if (data.status == 200) return (data.json());
          else reject(data)   // Redirect to Login
        }).then(data => resolve(data))
        .catch(err => { reject(new Error(err.message)) });
    });
  },
  validateCognito (user, password, advisor, code) {
    const url = window.location.host
    const cognitoUrl = Configuration.value('cognitoRedirectUrl')
    return new Promise((resolve, reject) => {
      fetch(uri, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0',
        },
        body: `grant_type=password&username=${user}&password=${encodeURIComponent(password)}&url=${url}&advisor=${advisor}&code=${code}&cognitoUrl=${cognitoUrl}`,
      }).then(res => { return res.json() })
        .then(async data => {
          if (!data.error) {
            this.setStorage(data);
            resolve(data);
            if (localStorage.getItem('is_cognito_user') === 'true') {
              this.getEndpointsDobleFactor();
            }
            setTimeout(() => {this.checkTokenExpired()}, 3 * 60 * 1000)
          } else {
            reject(data.error)
          }
        }).catch(reject)
    });
  },
  validateTokenCognito(){
    const isCognitoUser = localStorage.getItem('is_cognito_user');
    const accessToken = localStorage.getItem('access_token_cognito')
    
    if(isCognitoUser === 'true' && accessToken === 'undefined' ){

      this.clearStorage();
      if (Configuration.value('appBasePath')) {
        window.location.replace(Configuration.value('appBasePath') + redirects.URL_LOGOUT_REDIRECT)
      }
      else {
        window.location.replace(redirects.URL_LOGOUT_REDIRECT)
      }
    }
  },

  async getEndpointsDobleFactor() {
    try {
      const response = await $endpoints.dobleFactor();
      localStorage.setItem('endpoints_doble_factor', JSON.stringify(response.data.body));
    } catch (error) {
      new Error(error.message);
    }
  },
}
