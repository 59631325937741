<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!(success || error)">
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard title="Seleccionada" class="mb-6">
            <v-row no-gutters class="mt-2">
              <v-col sm="7" offset-sm="1" class="d-flex flex-column align-start pb-0 pr-3" v-for="{subject} in subjects"
                :key="subject?.id">
                <span class="caption text-uppercase">{{ `${subject?.code} - ${subject?.descripcion}` }}</span>

              </v-col>
              <v-col sm="3" class="align-start pt-1 pb-3">
                <v-select outlined class="d-inline-block ml-2" placeholder="Seleccione" :items=optionsItems
                  item-text="description" v-model="$v.selectedQualifyPP.$model"
                  :error-messages="($v.selectedQualifyPP.$dirty && $v.selectedQualifyPP.$anyError) ? $t('modules.studentSubjects.ChangeAppovedPP.errorRequired') : ''"
                  @blur="$v.selectedQualifyPP.$touch()"
                  @keypress="$validateDecimalInputNumber($event, $v.selectedQualifyPP.$model)" return-object></v-select>
              </v-col>
            </v-row>
          </OutlinedCard>
          <div class="d-flex justify-end">
            <Button white :text="$t('actions.cancel')" @clicked="clearDataQualifyPP" class="mr-4"></Button>
            <Button :text="$t('actions.save')" :loading="loading" @clicked="confirmPopup"
              :disabled="$v.$invalid && $v.$dirty"></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage v-if="success" type="success" :title="$t('modules.studentSubjects.ChangeAppovedPP.successQualifyPP')"
      :actionPrimary="{
        text: 'Cerrar',
        callback: clearDataQualifyPP
      }" />
    <SuccessMessage v-if="error" type="error" :title="$t('modules.studentSubjects.ChangeAppovedPP.errorQualifyPP')"
      :actionPrimary="{
        text: 'Cerrar',
        callback:clearDataQualifyPP
      }" />
  </v-container>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import { required } from 'vuelidate/lib/validators'
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { mapGetters } from 'vuex';

export default {
  name: 'ChangeApprovedPractice',
  props: {
    subjects: Array,
    isOpen: Boolean,
    optionsItems: Array,
    loading: Boolean,
    success: Boolean,
    error: Boolean,
  },
  components: {
    OutlinedCard,
    Button,
    SuccessMessage,
  },
  data() {
    return {
      successText: {
        type: '',
        title: '',
        actionPrimary() { },
      },
      selectedQualifyPP: null
    }
  },
  computed: {
    ...mapGetters([
      'commons/getUserID'
    ]),
  },
  methods: {
    clearDataQualifyPP() {
      this.$emit('close-drawer')
      this.$emit('clearDataQualifyPP')
      this.selectedQualifyPP = null
    },
    confirmPopup() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t(`actions.warning`),
          content: [{ value: '¿Estás seguro de realizar esta acción?' }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => { this.$emit('saveQualifyPP', this.selectedQualifyPP) } },
          actionSecondary: { text: this.$t('actions.cancel'), callback() { } },
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary',
        })
      }
    },
    clearData() {
      this.$v.$reset();
    }
  },
  watch: {
    isOpen(value) {
      if (!value) this.clearData();
    }
  },
  validations() {
    return {
      selectedQualifyPP: {
        required
      }
    }
  }
}
</script>
