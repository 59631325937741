<template>
    <v-card @click="goToAddress()"  height="105" class="d-flex">
        <div>
            <v-card-title v-if="title" class="titleCard font-weight-bold primary--text body-2">
                <h3>{{title}}</h3>
            </v-card-title>
            <v-card-subtitle v-if="subtitle" style="width: 100%">
                <h4>{{subtitle}}</h4>
                <div v-if="plan">
                        {{plan}}
                    </div>
            </v-card-subtitle>
        </div>
        <v-spacer :class="[separator ? 'separator' : '']"></v-spacer>
        <div v-if="icon" class="d-flex align-center justify-center" style="width: 74px">
            <v-btn icon>
                <v-icon  >{{icon}}</v-icon>
            </v-btn>
        </div>
        <v-menu v-if="careerBook" rigth>
                <template v-slot:activator="{ on }">
                    <div class="d-flex align-center justify-center" >
                        <v-btn plain >
                            <v-icon  v-on="on" @click="$emit('requestConfirmation', {bookConfirm, book, plan, resolucion})">mdi-download</v-icon>
                        </v-btn>
                    </div>
                </template>
                
            </v-menu>
    </v-card>
</template>


<script>
import {$getInvoicesToDownload} from '../Services';
import download from 'downloadjs'

export default {
    props: {
        title: String,
        subtitle: String,
        plan: String,
        separator: Boolean,
        icon: String,
        book: Number,
        careerBook: Boolean,
        sheets: String,
        bookConfirm: Boolean,
        resolucion: String,
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        goToAddress(){
            if(this.careerBook){
                this.goToSheet(this.sheets, this.book)
            }
            else{
                this.$emit('clicked')
            }
        },
        goToSheet(id, sheetNumber){
                    this.$router.push({path: `/academic/gradebook/${id}/sheet/${sheetNumber}`});
                },
            async downloadPdf(){
                    this.loading = true
                    const data = await $getInvoicesToDownload.find(null, null, {params: {careerId: this.$route.params.id, book: this.book}});
                    download(data.data, `Libro ${this.title} ${this.plan}.pdf`, data.headers.get('content-type'))
                },
    },
}
</script>

<style scoped lang="sass">
.titleCard
    word-break: break-word
</style>