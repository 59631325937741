<template>
  <div>
    <template v-if="$v.passInputs.email.input.$anyDirty">
      <v-alert
        text
        dense
        color="error"
        :value="!$v.passInputs.email.input.required"
      >{{ $t('modules.login.recovery.password.form.validations.emailRequired') }}</v-alert>
      <v-alert
        text
        dense
        color="error"
        :value="!$v.passInputs.email.input.email"
      >{{ $t('modules.login.recovery.password.form.validations.emailInvalid') }}</v-alert>
    </template>
    <v-text-field
      class="py-2"
      v-model="passInputs.email.input"
      :label="$t('modules.login.recovery.password.form.labels.email')"
      outlined
      type="text"
      autocomplete="email"
      :success="!$v.passInputs.email.input.$invalid"
      :error="$v.passInputs.email.input.$anyError"
      :loading="$v.passInputs.email.input.$pending"
      @blur="$v.passInputs.email.input.$touch()"
    ></v-text-field>
    <div class="py-2">
      <h6 class="body-1">{{ $t('modules.login.recovery.password.form.labels.checksTitle') }}</h6>
    </div>
  </div>
</template>

<script>
  import { email, required } from 'vuelidate/lib/validators'

  export default {
    name: 'RecoveryPassForm',
    props: ['passInputs'],
    data () {
      return { }
    },
    validations: {
      passInputs: {
        email: {
          input: { required, email }
        },
        checkSMS: false,
        checkMail: true
      }
    },
    watch: {
      passInputs: {
        handler(value) {
          this.passInputs.email.valid = value.email.input && this.$v.passInputs.email.input.required && this.$v.passInputs.email.input.email
        }, deep: true
      },
    }
  };
</script>

<style scoped lang="sass">
    .v-messages
        display: none
</style>
