import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('canvas');

export class Users extends Api {
    constructor(http, config = {}) {
    super(http, url, 'users', config);
}
}
