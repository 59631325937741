const syncStudentTranslations = {
    es : {
            breadcrumbs: {
                title: 'Sincronización de Alumnos',
                title2:'Sincronización de Asignaturas',
                description: 'Administración de sincronización de alumnos',
                description2: 'Administracion de sincronización de asignaturas',
            },
            table:{
                headers:{
                    identification: 'DNI',
                    name: 'Nombre',
                    lastname: 'Apellido',
                    career: 'Carrera',
                    career_status: 'Estado de carrera',
                    term: 'Periodo',
                    synchronization: 'Sincronización',
                    edit:'',
                }
            },
            subject:{
                table:{
                    headers:{
                        code: 'Código',
                        name: 'Nombre',
                        subjectStatus: 'Estado de la asignatura',
                        termCode: 'Periodo',
                        synchronization: 'Sincronización',
                        edit:'',
                    }
                },
            },
            actions:{
                sync_up: 'Sincronizar',
                viewNotes: 'Ver notas',
                update: 'Actualizar',
                close: 'Cerrar',
                synchronizationSection: 'Ver asignatura'
            },
            message:{
                syncUpStudent: 'Estas seguro que deseas sincronizar al estudiante',
                syncUpStudentSuccess: 'Estudiante sincronizado correctamente',
                syncUpNotesSuccess: 'Notas sincronizadas correctamente',
                syncUpStudentError: 'Error al sincronizar al estudiante',
                syncUpNoteError: 'Error al sincronizar las notas',
                syncUpSubject: 'Estas seguro que deseas sincronizar la asignatura',
                errorLmsCourseNull: 'Debe sincronizar la asignatura primero en el modulo canvas course',
                syncUpSubjectSuccess: 'Asignatura sincronizada correctamente',
                syncUpSubjectError: 'Error al sincronizar la asignatura',
                syncAccept: 'Si, sincronizar',
                syncCancel: 'Cancelar',
                syncUpNotes: 'Estas seguro que deseas sincronizar las notas con las notas de canvas'
            },
            drawer:{
                title: 'Notas',
                description: 'Lista de las notas en los módulos',
                table:{
                    title: 'Actualización de notas',
                    description: 'Aquí podrás actualizar las notas del sistema con las notas de canvas.',
                    headers:{
                        modules:'Módulos',
                        examType: 'Examen',
                        notas:'Notas',
                        practicalDate: 'Fecha de finalización'
                    }
                }
            }
    },
}

export default syncStudentTranslations