import Procedures from '../Procedures'
import ProceduresInitial from '../Views/ProceduresInitial'
import Retirement from '../Views/Retirement'
import CareerFreeze from '../Views/CareerFreeze'
import CareerChange from '../Views/CareerChange/CareerChange'
import Reinstatement from '../Views/Reinstatement'
import ProcedureExamCharge from '../Views/ProcedureExamCharge'
import ProcedureHistory from '../Views/ProcedureHistory'
import CertificateDownload from '../Views/CertificateDownload'
import ProcedureTNE from '../Views/ProcedureTNE'
import RenewalStatus from '../Views/ReEnrollment/RenewalStatus'
import Renewal from '../Views/ReEnrollment/Renewal'
import reEnrollment from '../Views/ReEnrollment/reEnrollment'
import Anexo from '../Views/ReEnrollment/Anexo'
import ProcedureDocument from '../Views/ProcedureDocument.vue'
import CertificateProgram from '../Views/CertificateProgram.vue'
import ProcedureBenefit from '../Views/ProcedureBenefit.vue'
import ProcedureScholarship from '../Views/ProcedureScholarship.vue'
import ProcedureDefiniteLow from '../Views/ProcedureDefiniteLow.vue'
import ProcedureTemporaryLow from '../Views/ProcedureTemporaryLow.vue'
import RaicProcedure from '../Views/RaicProcedure'
import RefundMoney from '../Views/RefundMoney'

const ProceduresRoute =
{
  path: '/procedures',
  meta: {
    name: {
      en: 'Procedures',
      es: 'Tramites'
    }
  },
  component: Procedures,
  children: [
    {
      path: '/',
      component: ProceduresInitial,
      meta: { name: 'Index', group: 'portalalumno', path: 'procedures', permission: 'READ' }
    }, {
      path: 'retirement',
      component: Retirement,
      name: 'retirement',
      meta: { name: 'retirement', group: 'portalalumno', path: 'procedures' }
    }, {
      path: 'career-freeze',
      component: CareerFreeze,
      name: 'careerFreeze',
      meta: { name: 'careerFreeze', group: 'portalalumno', path: 'procedures', permission: 'CAREER_FREEZE' }
    }, {
      path: 'career-change',
      component: CareerChange,
      name: 'Cambio de carrera',
      meta: { name: 'careerChange', group: 'portalalumno', path: 'procedures', permission: 'CAREER_CHANGE' }
    }, {
      path: 'reinstatement',
      component: Reinstatement,
      name: 'reinstatement',
      meta: { name: 'reinstatement', group: 'portalalumno', path: 'procedures', permission: 'REINSTATEMENT' }
    }, {
      path: 'exam-charge',
      component: ProcedureExamCharge,
      name: 'procedureExamCharge',
      meta: { name: 'procedureExamCharge', group: 'portalalumno', path: 'procedures', permission: 'EXAM_CHARGE' }
    }, {
      path: 'history',
      component: ProcedureHistory,
      name: 'ProcedureHistory',
      meta: { name: 'ProcedureHistory', group: 'portalalumno', path: 'procedures', permission: 'READ' }
    }, {
      path: 'certificate/:certificateId',
      component: CertificateDownload,
      name: 'CertificateDownload',
      meta: { name: 'CertificateDownload', group: 'portalalumno', path: 'procedures', permission: 'READ' }
    }, {
      path: 'tne',
      component: ProcedureTNE,
      name: 'ProcedureTNE',
      meta: { name: 'ProcedureTNE', group: 'portalalumno', path: 'procedures', permission: 'TNE' }
    }, {
      path: 'renewalStatus',
      component: RenewalStatus,
      name: 'RenewalStatus',
      meta: { name: 'RenewalStatus', group: 'portalalumno', path: 'procedures', permission: 'REENROLLMENT' }
    }, {
      path: 'scholarshipRenewal',
      component: Renewal,
      name: 'scholarshipRenewal',
      meta: { name: 'scholarshipRenewal', group: 'portalalumno', path: 'procedures', permission: 'REENROLLMENT' }
    }, {
      path: 'benefitRenewal',
      component: Renewal,
      name: 'benefitRenewal',
      meta: { name: 'benefitRenewal', group: 'portalalumno', path: 'procedures', permission: 'REENROLLMENT' }
    }, {
      path: 'reEnrollment',
      component: reEnrollment,
      name: 'Rematrícula',
      meta: { name: 'reEnrollment', group: 'portalalumno', path: 'procedures', permission: 'REENROLLMENT' }
    }, {
      path: 'anexo',
      component: Anexo,
      name: 'anexo',
      meta: { name: 'anexo', group: 'portalalumno', path: 'procedures', permission: 'REENROLLMENT' }
    }, {
      path: 'document-obligation',
      component: ProcedureDocument,
      name: 'document-obligation',
      meta: { name: 'document-obligation', group: 'portalalumno', path: 'procedures', permission: 'DOCUMENT_OBLIGATION' }
    },
    {
      path: 'legalized-program',
      component: CertificateProgram,
      name: 'legalized-program',
      meta: { name: 'legalized-program', group: 'portalalumno', path: 'procedures', permission: 'READ' }
    },
    {
      path: 'benefit',
      component: ProcedureBenefit,
      name: 'benefit',
      meta: { name: 'benefit', group: 'portalalumno', path: 'procedures', permission: 'BENEFIT' }
    },
    {
      path: 'scholarship',
      component: ProcedureScholarship,
      name: 'scholarship',
      meta: { name: 'scholarship', group: 'portalalumno', path: 'procedures', permission: 'SCHOLARSHIP' }
    },
    {
      path: 'definitive-low',
      component: ProcedureDefiniteLow,
      name: 'definitive-low',
      meta: { name: 'definitive-low', group: 'portalalumno', path: 'procedures', permission: 'DEFINITIVE_LOW' }
    },
    {
      path: 'temporary-low',
      component: ProcedureTemporaryLow,
      name: 'temporary-low',
      meta: { name: 'temporary-low', group: 'portalalumno', path: 'procedures', permission: 'TEMPORARY_LOW' }
    },
    {
      path: 'raic-procedure',
      component: RaicProcedure,
      name: 'raicProcedure',
      meta: { name: 'raicProcedure', group: 'portalalumno', path: 'procedures', permission: 'RAIC' }
    },
    {
      path: 'refund-money',
      component: RefundMoney,
      name: 'refund-money',
      meta: { name: 'refund-money', group: 'portalalumno', path: 'procedures', permission: 'REFUND_MONEY' }
    }
  ]
}

export default ProceduresRoute
