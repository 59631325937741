<template>
	<v-container fluid class="d-flex pa-0 fullHeight">
		<AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed" />
		<v-container fluid class="scrollableContent pa-0">
			<v-container class="container-custom px-6">
				<Breadcrumbs
					:title="$t('modules.academic.documentation.breadcrumbs.title')"
					:description="$t('modules.academic.documentation.breadcrumbs.description')"
				/>
				<article v-if="loading" class="d-flex justify-center my-12">
					<v-progress-circular indeterminate size="56" color="primary" />
				</article>
				<section v-else class="mt-6">
					<v-row>
						<template v-for="(panel, index) in panels">
							<v-col cols="2,5" class="pa-0 ma-0" :key="index" v-if="panel.status != -1 && panel.description != 'Vencidos'">
									<PanelDataTable :data="panel"/>
							</v-col>
						</template>
					</v-row>
					<div class="d-flex justify-space-between">
						<span class="font-weight-bold">
							{{ panels.total.total }}
							<span class="text--secondary">
								{{ $t('modules.academic.documentation.main.delivered') }}
							</span>
						</span>
						<v-btn text color="primary" to="documentation/types">
							{{ $t('modules.academic.documentation.main.activeDocs') }}
							<v-icon small>mdi-plus</v-icon>
						</v-btn>
					</div>
					<v-col class="viewSpaces">
						<h2 class="text--secondary mb-6">
							{{ status ? status.text : '' }}
						</h2>
						<SuperTable
							@search="searchAction"
							:headers="headers"
							:items="docsStatusData"
							ref="table"
							:pageCount="asidebar.pagination.pagesAmount"
							:page="asidebar.pagination.page"
							:sortBy.sync="sort.sortBy"
							:sortDesc.sync="sort.sortDesc"
							:totalItems="totalItems"
							@searchAction="searchAction"
							@input-pagination="onInputPagination"
							@length-pagination-change="onLengthPaginationChange"
							:loading_table="loading_table"
							:statusTypes="asidebar.items[0].filters[0].items"
							:docTypes="docTypes"
							:foundStudentDocs="foundStudentDocs"
							@update="fetchStatusData"
						/>
					</v-col>
				</section>
			</v-container>
		</v-container>
	</v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import PanelDataTable from '../Components/PanelDataTable.vue';
import { $documentations } from '../Services';
import SuperTable from '../Components/SuperTable';
import AsideBar from '@/components/AsideBar/AsideBar';

export default {
	name: 'DocumentationView',
	components: {
		Breadcrumbs,
		PanelDataTable,
		SuperTable,
		AsideBar,
	},
	data() {
		return {
			loading: true,
			loading_table: false,
			amount: null,
			percentage: null,
			search: '',
			panels: {
				total: {
					status: 0,
					description: '',
					subdescription: null,
					total: 0,
					averageByDoc: 0,
					totalPerStudent: 0,
				},
			},
			procedureArray: [],
			termId: null,
			statusProcedure: null,
			showTable: false,
			identificationStudent: null,
			docsStatusData: [],
			headers: [
				{
					text: this.$t('modules.academic.documentation.table.headers.name'),
					value: 'name',
				},
				{
					text: this.$t('modules.academic.documentation.table.headers.identification'),
					value: 'identification',
				},
				{
					text: this.$t('modules.academic.documentation.table.headers.studentCareerId'),
					value: 'studentcareer',
				},
				{
					text: this.$t('modules.academic.documentation.table.headers.career'),
					value: 'career',
				},
			],
			asidebar: {
				title: this.$t('modules.academic.documentation.filters.title'),
				pagination: {
					limit: 20,
					page: 1,
					pagesAmount: 1,
				},
				items: [
					{
						name: this.$t('modules.academic.documentation.filters.status'),
						filters: [
							{
								name: this.$t('modules.academic.documentation.filters.sub1'),
								type: 'autocomplete',
								value: null,
								clearable: false,
								items: [],
							},
						],
					},
				],
			},
			sort: {
				sortBy: 'name',
				sortDesc: true,
			},
			pagination: {
				limit: 20,
				page: 1,
				pagesAmount: 1,
			},
			totalItems: null,
			docTypes: [],
			foundStudentDocs: null,
		};
	},
	computed: {
		status() {
			return this.asidebar.items[0].filters[0].items.find(el => el.value == this.asidebar.items[0].filters[0].value);
		},
	},
	async mounted() {
		await this.getPanelData();
		await this.getDocTypes();
		await this.fetchStatusData();
	},
	methods: {
		async getPanelData() {
			try {
				this.loading = true;
				const response = await $documentations.find(`status`);
				this.panels = response.data;
				Object.values(response.data).forEach(element => {
					if (element.status != -1 && element.description !== 'Vencidos')
						this.asidebar.items[0].filters[0].items.push({
							text: element.description,
							value: element.status,
						});
				});
				this.asidebar.items[0].filters[0].value = this.asidebar.items[0].filters[0].items[0].value;
			} catch (e) {
				return e;
			} finally {
				this.loading = false;
			}
		},
		async getDocTypes() {
			try {
				this.loading = true;
				const response = await $documentations.find('types');
				this.docTypes = response.data.typeSubtypeDocs;
			} catch (e) {
				return e;
			} finally {
				this.loading = false;
			}
		},
		async fetchStatusData() {
			try {
				this.loading_table = true;
				const params = {
					status: this.asidebar.items[0].filters[0].value,
					page: this.asidebar.pagination.page - 1,
					length: this.asidebar.pagination.limit,
					orderBy: this.sort.sortBy == 'studentCareerId' ? 'studentCareer' : this.sort.sortBy,
					orientation: this.sort.sortDesc ? 'desc' : 'asc',
				};
				const response = await $documentations.find('students', null, {
					params: this.search ? { dni: this.search } : { ...params },
				});
				if (this.search) {
					this.docsStatusData = [
						{
							career: response.data.studentData.career,
							identification: response.data.studentData.identification,
							name: `${response.data.studentData.name} ${response.data.studentData.lastname}`,
							studentCareerId: response.data.studentData.studentRecord,
							studentId: response.data.studentData.userId,
							userId: response.data.studentData.userId,
						},
					];
					this.foundStudentDocs = response.data;
					this.totalItems = 1;
					this.asidebar.pagination.pagesAmount = response.data?.totalElements ? response.data.totalElements : 1;
				} else {
					this.docsStatusData = response.data.content;
					this.foundStudentDocs = null;
					this.totalItems = response.data.totalElements;
					this.asidebar.pagination.pagesAmount = response.data.totalPages;
				}
			} catch (error) {
				this.docsStatusData = [];
				this.pagination.page = 1;
				this.pagination.pagesAmount = 1;
				this.asidebar.pagination.page = 1;
				this.asidebar.pagination.pagesAmount = 1;
				this.totalItems = 0;
				throw error;
			} finally {
				this.loading_table = false;
			}
		},
		changed(index, i, value) {
			this.asidebar.items[index].filters[i].value = value;
			this.fetchStatusData();
		},
		searchAction(data) {
			this.asidebar.pagination.page = 1;
			this.search = data;
			this.fetchStatusData();
		},
		onInputPagination(event) {
			this.asidebar.pagination.page = event;
			this.fetchStatusData();
		},
		onLengthPaginationChange(event) {
			this.asidebar.pagination = {
				page: 1,
				limit: event,
				pagesAmount: 1,
			};
			this.fetchStatusData();
		},
	},
	watch: {
		sort: {
			handler() {
				this.fetchStatusData();
			},
			deep: true,
		},
	},
};
</script>
