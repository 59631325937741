<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container v-if="message.show" >
            <SuccessMessage
            :type="message.type"
            :title="message.title"
            :subtitle="message.subtitle"
            :actionPrimary="message.actionPrimary"
            />      
        </v-container>

        <v-container v-else fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :title="$t('modules.subject0.table.breadcrumbs.title')"
                    :description="$t('modules.subject0.table.breadcrumbs.description')"
                ></Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            :headers="headers"
                            :data="users"
                            @search-action="searchAction"
                            @confirm-action="confirmationPopUp"
                            :permissions="$permissions.portaladministrativo.students.subject0"
                            :status="status"
                            ></SuperTable>                   
                    </v-col>
                </v-row>                            
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import SuperTable from '../Components/SuperTable/SuperTable';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage.vue';
import { $students, $studentCareer } from '../Services';

    export default {
        name: 'Subject0View',
        components: {
            Breadcrumbs,
            SuperTable,
            SuccessMessage,
        },
        data() {
            return {
                search: '',
                users: [],
                status: Boolean,
                message: {
                    type: 'success',
                    title: '',
                    subtitle: '',
                    show: false,
                    actionPrimary: {
                        text: 'Aceptar',
                        callback: ()=>{
                            this.message.show=false
                        }
                    }
                },
                headers: [
                    {
                        text: this.$t('modules.subject0.table.headers.identification'),
                        value: 'identification',
                        sortable: false
                    },{
                        text: this.$t('modules.subject0.table.headers.name'),
                        value: 'name'
                    },{
                        text: this.$t('modules.subject0.table.headers.lastname'),
                        value: 'lastname',
                    },{
                        text: this.$t('modules.subject0.table.headers.career'),
                        value: 'career',
                        sortable: false
                    },{
                        text: this.$t('modules.subject0.table.headers.career_status'),
                        value: 'careerStatus.meaning',
                        sortable: false
                    },{
                        text: this.$t('modules.subject0.table.headers.term'),
                        value: 'term',
                        sortable: false,
                        align:'center',
                    },{
                        text: this.$t('modules.subject0.table.headers.status'),
                        value: 'status',
                        sort: () => 0,
                        align: 'center'
                    },{
                        text: '',
                        value: 'action',
                        sortable: false
                    }
                ],
            };
        },
        methods: {
            searchAction(data) {
                this.search = data;
                this.getStudent();
            },
            confirmationPopUp (item) {
                
                const titulo = item.status
                    ? this.$t('modules.subject0.table.actions.takeout')
                    : this.$t('modules.subject0.table.actions.confirmation');

                    this.$store.dispatch('commons/openPopUp', {
                        title: titulo,
                        content: [{ value: `${item.identification}-${item.name} ${item.lastname}` }],
                        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.confirmAction(item.id)},
                        actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
                        icon: { color: 'warning', name: 'mdi-exclamation' },
                        color: 'primary',
                    })
                    
            },
            async confirmAction(id){
                try {
                    const currentStatus = this.users.find(user => user.id === id)?.status
                   
                    const params = {
                        status: !currentStatus
                    }
                    const {status} = await $studentCareer.update(`subject0/${id}`,null,{params})

                    if(status!=201)throw new Error(status)

                    this.message.title = params.status 
                        ? this.$t('modules.subject0.table.actions.success') 
                        : this.$t('modules.subject0.table.actions.disable');

                        this.message.show = true
                        this.message.type = 'success'
                        this.getStudent()
                    } catch (error) {
                        this.message.show = true
                        this.message.type = 'error'
                        this.message.title = this.$t('modules.subject0.table.actions.error')
                        this.getStudent()
                }
            },
            async getStudent() {
                try {
                    const student = await $students.find('studentCareersByIdentification', null, {params: { identification: this.search }})
                    this.users = [];
                    if (student.data.studentCareers.length) {
                        student.data.studentCareers.forEach(career => {
                            this.users.push({
                                id: career.studentCareer,
                                name: student.data.name,
                                lastname: student.data.lastname,
                                identification: student.data.identification,
                                career: career.careerName,
                                careerStatus: career.careerStatus,
                                term: career.termValidCode,
                                termId: career.termValidId,
                                exception: career.exception,
                                oldModality: career.oldModality,
                                allowReenrollment: career.allowReenrollment,
                                studentId: student.data.studentId,
                                status: career.certificateSubject0,
                            })
                        })
                    }
                } catch (error) {
                    this.users = [];
                }
            }
        }
    };
  </script>
  
  