<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0" v-if="!createNewBilling">
            <v-container fluid class="container-custom px-1">
                <Breadcrumbs :description="breadcrumbs.description" :title="breadcrumbs.title">
                </Breadcrumbs>
            </v-container>
            <v-container  class="container-custom px-16">
                <v-row>
                    <OutlinedCard
                        :title="$t('modules.portalalumno.pricing.billing.BillingCreate.outlineCardAlumno.title')"
                        :subtitle="$t('modules.portalalumno.pricing.billing.BillingCreate.outlineCardAlumno.subtitle')"
                    >
                    <StudentCard
                    @get-info="getInfo"
                    :infoStudentBilling="infoStudentBilling"
                    />
                    </OutlinedCard>
                </v-row>
                <v-row>
                    <OutlinedCard 
                        :title="$t('modules.portalalumno.pricing.billing.BillingCreate.outlineCardFinance.title')"
                        :subtitle="$t('modules.portalalumno.pricing.billing.BillingCreate.outlineCardFinance.subtitle')"
                    >
                    <BillingCard/>
                        
                    </OutlinedCard>
                </v-row>
                <v-row >
                    <OutlinedCard
                        title="$t('modules.portalalumno.pricing.billing.BillingCreate.outlineCardDetail.title')"
                        subtitle="$t('modules.portalalumno.pricing.billing.BillingCreate.outlineCardDetail.subtitle')"
                    >  
                        <DetailCard/>
                        <div class="total-wrapper mr-14">
                            <v-alert outlined color="#6087e1">
                            <div class="total primary--text">{{ "$t('modules.portalalumno.pricing.billing.BillingCreate.total')" }}</div>
                            <div class="total-currency primary--text">{{ 0 | $currency }}</div>
                            </v-alert>
                        </div>
                    </OutlinedCard>
                </v-row>
                 <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  
                ></Button>
              </div>
            </v-container>
        </v-container>
        <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import StudentCard from '../Components/StudentCard.vue';
import BillingCard from '../Components/BillingCard.vue';
import DetailCard from '../Components/DetailCard.vue';
import { $billingData } from '../Services/index'


    export default {
        name:'BillingCreate',
        components: {
            Breadcrumbs,
            OutlinedCard,
            Button,
            StudentCard,
            BillingCard,
            DetailCard
        },
        data() {
            return {
                breadcrumbs: {
                    title: this.$t('modules.portalalumno.pricing.billing.BillingCreate.breadcrumbs.title'),
                    description: this.$t('modules.portalalumno.pricing.billing.BillingCreate.breadcrumbs.description')
                },
                 createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                createNewBilling:false,
                successMessage: {
                    type: null,
                    title: null,
                    actionPrimary: null,
                    actionSecondary: null
                },
                infoStudentBilling: {}
            }
        },
        methods: {
            async getInfo(value){
                    try {
                        const resp = await $billingData.findById(value)
                        // const address = resp.address.split(' ')
                        this.infoStudentBilling = {
                            name: resp.student.name,
                            lastname: resp.student.lastname,
                            dni: resp.docNumber,
                            typeDni: resp.identificationType.meaning,
                            street: resp.address,
                            streetNumber: resp.addressNumber,
                            streetFloor: resp.addressFloor,
                            addressDepartment: resp.addressDepartment

                        }

                    } catch (error) {
                        this.infoStudentBilling= {}
                        throw error
                    }
            }
        },
    }
</script>

<style lang="sass" scoped>
.total-wrapper
  border-radius: 5px
  background-color: #d9e2fa
  font-family: 'DM Sans'
  font-weight: bold
  font-stretch: normal
  font-style: normal
  line-height: 1.3
  letter-spacing: -0.6px

  .total
    float: left
    font-size: 20px

  .total-currency
    float: right
    font-size: 24px
</style>