<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!createStatus">
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard :disabled="!this.canCreate" :title="$t('modules.examTitleNotes.create.labels.detail')"
            class="mb-6">
            <v-row no-gutters>
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                <span class="caption text-uppercase">{{ $t('modules.examTitleNotes.update.labels.name') }}</span>
              </v-col>
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                <span class="caption text-uppercase">{{ `${selectedExam.name} ${selectedExam.lastname}` }}</span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-6">
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                <span class="caption text-uppercase">{{ $t('modules.examTitleNotes.create.labels.act') }}</span>
              </v-col>
              <v-col sm="4" class="align-start pt-1 pb-3">
                <v-text-field v-model="act" outlined :label="$t('modules.examTitleNotes.create.labels.act')" type="text"
                  hide-details></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="regulation.value === 'PREV'" no-gutters>
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                <span class="caption text-uppercase">{{ `${$t('modules.examTitleNotes.create.labels.calificacion')} *`
                }}</span>
              </v-col>
              <v-col sm="4" class="align-start pt-1 pb-3">
                <v-text-field v-model="$v.nota.$model"
                  :error-messages="($v.nota.$dirty && $v.nota.$anyError) ? $t('modules.examTitleNotes.create.validation_errors.calificacion') : ''"
                  outlined :label="`${$t('modules.examTitleNotes.create.labels.calificacion')} *`" type="text"
                  @blur="$v.nota.$touch()"
                  @keypress="$validateDecimalInputNumber($event, $v.nota.$model, 7)"></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                <span class="caption text-uppercase">
                  {{ regulation.value === 'PREV' ?
                    `${$t('modules.examTitleNotes.create.labels.titulacion')} *` :
                    `${$t('modules.examTitleNotes.create.labels.titulacionR23')} *`
                  }}
                </span>
              </v-col>

              <v-col sm="4" class="align-start pt-1 pb-3">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="$v.fecha.$model"
                  transition="scale-transition" offset-y bottom min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :error-messages="$v.fecha.$invalid && $v.fecha.$dirty ? $t('modules.examTitleNotes.create.validation_errors.fecha') : ''"
                      v-model="$v.fecha.$model" @blur="$v.fecha.$touch()"
                      :label="regulation.value === 'PREV' ? `${$t('modules.examTitleNotes.create.labels.fecha')} *` : `${$t('modules.examTitleNotes.create.labels.fechaR23')} *` " 
                      append-icon="mdi-calendar" readonly
                      outlined v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="$v.fecha.$model" no-title color="primary" scrollable
                    :max="new Date().toISOString().substr(0, 10)">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">{{ $t('actions.cancel') }}</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save($v.fecha.$model)">{{ $t('actions.save') }}</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

          </OutlinedCard>
          <div class="d-flex justify-end">
            <Button white :text="$t('actions.cancel')" @clicked="$emit('closeDrawer')" @end="resetButtonValues"
              class="mr-4"></Button>
            <Button :loading="createButton.loading" :success="createButton.success" :error="createButton.error"
              :text="$t('actions.load')" :successText="$t('actions.created')" :errorText="'Error'"
              :disabled="$v.$invalid && $v.$anyError || !this.canCreate" @end="resetButtonValues"
              @clicked="createExam"></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage v-else :type="successMessage.type" :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary" class="mt-12" />
  </v-container>
</template>

<script>

import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $examsTitle } from '../Services';
import { mapGetters } from 'vuex'

export default {
  name: 'ExamTitlesNotesCreate',
  props: {
    insideDrawer: Boolean,
    selectedExam: Object,
    isOpen: Boolean,
    regulation: {
      type: Object,
      default() {
        return { id: null, value: 'PREV', text: 'anteriores' }
      },
      validator({ id, value, text }) {
        const isValidText = typeof text === 'string'
        const isValidId = typeof id === 'number' || id === null

        const regulationValues = ['PREV', 'R23']
        const isValidValue = typeof value === 'string' && regulationValues.includes(value)

        return isValidId && isValidText && isValidValue
      }
    }
  },
  components: {
    OutlinedCard,
    Button,
    SuccessMessage,
  },
  data() {
    return {
      menu: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      nota: null,
      fecha: null,
      act: null,
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
      },
      createStatus: false
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.students.exam_title_notes.CREATE
    },
    ...mapGetters({
      userId: 'commons/getUserID'
    })
  },
  methods: {
    async createExam() {
      try {
        this.$v.$touch()
        if (this.$v.$invalid || !this.canCreate) return

        this.createButton.loading = true;

        const payload = {
          score: parseFloat(this.nota),
          date: this.fecha,
          act: this.act,
          user: this.userId ? this.userId : null,
          regulation: this.regulation.id,
        }

        if (this.regulation.value === 'R23') {
          payload.studentCareerId = this.selectedExam.idStudentCareer
          payload.isIntermediate = this.selectedExam.isIntermediate
          this.successMessage.title = this.$t('modules.examTitleNotes.create.success_message.titulacion.title');
        }else{
          payload.studentSubjectId = this.selectedExam.id
          payload.isIntermediate = null
          this.successMessage.title = this.$t('modules.examTitleNotes.create.success_message.nota.title');
        }

        await $examsTitle.create(payload)

        this.successMessage.type = 'success';
        this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback: () => { this.$emit('closeDrawer'); } }
        this.createButton.success = true;
      } catch {
        this.successMessage.type = 'error';

        if (this.regulation.value === 'R23') {
          this.successMessage.title = this.$t('modules.examTitleNotes.create.error_message.titulacion.title');
        } else {
          this.successMessage.title = this.$t('modules.examTitleNotes.create.error_message.nota.title');
        }

        this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.createStatus = false } }
        this.createButton.error = true;
      }
      finally {
        this.createStatus = true;
        this.resetButtonValues();
      }
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    clearData() {
      this.resetButtonValues();
      this.nota = null;
      this.fecha = null;
      this.act = null;
      this.menu = false;
      this.createStatus = false;
      this.$v.$reset();
    }
  },
  validations() {
    const validation = {}

    if (this.regulation.value === 'PREV') {
      validation.nota = {
        required,
        minValue: minValue(0),
        maxValue: maxValue(7),
      }
    }

    validation.fecha = { required }
    return validation
  },
  watch: {
    isOpen(value) {
      if (!value) this.clearData()
    }
  }
}
</script>
