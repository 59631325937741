import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '../store'
import { configHttpClient } from './plugins/http-client';
import { setVueInstance } from './helpers/vue-instance';
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import { i18n } from './plugins/i18n';
import AuthService from './plugins/auth.service'
import UserService from './plugins/user.service'
import SettingsService from './plugins/settings.service'
import VueTheMask from 'vue-the-mask'
import infiniteScroll from 'vue-infinite-scroll'
import VueScrollTo from 'vue-scrollto'
import mixin from './mixins/index'
import VueApexCharts from 'vue-apexcharts'
import VueGtag from 'vue-gtag';
import VCalendar from 'v-calendar';
import VueHtmlToPaper from 'vue-html-to-paper';
import Hotjar from 'vue-hotjar'

import Configuration from './constants/configuration';

import 'roboto-fontface/css/roboto/roboto-fontface.css'

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import Amplify from 'aws-amplify';
import { I18n } from 'aws-amplify';
import { Translations } from '@aws-amplify/ui-components'
import awsconfig from './aws-exports';

I18n.putVocabularies(Translations);
I18n.setLanguage('es');

I18n.putVocabularies({
  es: {
    'Invalid session for the user, session is expired.': 'Sesión inválida para el usuario, la sesión ha expirado', 
    'Enter your middle name':'Ingrese su segundo nombre',
    'Middle Name':'Segundo nombre',
    'Enter your address':'Ingrese su dirección',
    'Address':'Dirección',
    'Phone Number *':'Número de teléfono *',
    'Enter your gender':'Ingrese su género',
    'Gender':'Género',
    'Confirm SMS Code':'Confirmar código SMS',
    'Invalid code or auth state for the user.':'Código inválido o estado de autenticación incorrecto para el usuario.',
    'Provided password cannot be used for security reasons.':'La contraseña ingresada no puede utilizarse por razones de seguridad',
    'Attempt limit exceeded, please try after some time.':'Se ha excedido el límite de intentos, por favor inténtalo de nuevo después de un tiempo.',
    'Password does not conform to policy: Password not long enough':'La contraseña no cumple con la política: La contraseña no es lo suficientemente larga',
    'Invalid verification code provided, please try again.': 'Código de verificación inválido, por favor intente de nuevo',
    'Sign In': 'Ingresar',
    'Sign Up': 'Regístrate',
    'Username *': 'Nombre de usuario *',
    'Password *': 'Contraseña *',
    'Forgot your password?': 'Olvido su contraseña?',
    'Reset password': 'Reestablecer contraseña',
    'Sign in to your account': 'Ingresa a tu cuenta',
    'Enter your username': 'Ingresa tu usuario',
    'Enter your password': 'Ingresa tu contraseña',
    'Confirm': 'Confirmar',
    'Confirm TOTP Code': 'Confirmar código TOTP',
    'Verification code': 'Código de verificación',
    'Enter code': 'Ingresa tu código',
    'Back to Sign In': 'Volver al ingreso',
    'Reset your password': 'Reestablezca su contraseña',
    'Send Code': 'Enviar código',
    'Change Password': 'Cambiar contraseña',
    'New password': 'Contraseña nueva',
    'Enter your new password': 'Ingresa tu nueva contraseña',
    'Family Name': 'Apellido',
    'Enter your family name': 'Ingresa tu apellido',
    'First Name': 'Primer nombre',
    'Enter your first name': 'Ingresa tu primer nombre',
    'Change': 'Cambiar',
    'Scan then enter verification code': 'Escanee y luego ingrese el código de verificación',
    'Enter Security Code:': 'Ingrese el código de seguridad:',
    'Verify Security Token': 'Verificar token de seguridad',
    'Incorrect username or password.': 'Usuario o contraseña incorrectos.',
    'Password reset required for the user': 'Se requiere reinicio de contraseña.',
    'Given Name': 'Nombre Asignado',
    'Enter your Given Name': 'Ingrese su nombre asignado',
    'Email': 'Correo electrónico',
    'Enter your Email': 'Ingrese su correo electrónico',
    'Full Name': 'Nombre completo',
    'Enter your full name': 'Ingrese su nombre completo'

  },
});

Amplify.configure(awsconfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];


require('typeface-dm-sans');

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(AuthService);
Vue.use(UserService);
Vue.use(SettingsService);
Vue.use(VueTheMask)
Vue.use(infiniteScroll)
Vue.use(VueScrollTo)
Vue.use(VueHtmlToPaper)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.mixin(mixin)
Vue.use(VueApexCharts)
Vue.use(VCalendar,{
  componentPrefix: 'vc',
})

String.prototype.toPascalCase = function () {
  return this.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

if (Configuration.value('gtm') && Configuration.value('gtm') != '') {
  Vue.use(VueGtag, {
    config: { id: Configuration.value('gtm') },
    appName: 'Portal',
    pageTrackerScreenviewEnabled: true
  }, router);
}

const hotjar = Configuration.value('hotjarId');
if (hotjar) {
  Vue.use(Hotjar, {
    id: hotjar,
    isProduction: true
  });
}

// This variable will hold the reference to
// document's click handler
let handleOutsideClick;
Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler } = binding.value;
      // This variable indicates if the clicked element is excluded
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target)) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick)
  }
});

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');

configHttpClient(app);
setVueInstance(app);
export default app;
