<template>
    <div>
      <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container class="container-custom px-6" fluid>
          <Breadcrumbs
            :title="$t('modules.regulation.table.breadcrumbs.title')"
            :description="$t('modules.regulation.table.breadcrumbs.description',{regulation: $route.params.regulation})"
          >
            <Button
              v-if="canCreate"
              @clicked="newChapter"
              :icon="'mdi-plus'"
              :text="$t('actions.new')"
              depressed
            ></Button>
          </Breadcrumbs>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <SuperTable
                v-if="!loading"
                :Regulation="Regulation"
                :headers="headers"
                :permissions="{ rules: {update: canUpdate, delete: canDelete}}"
                @deleteChapter="(id)=>deleteChapterPopUp(id)"
                @deleteSubChapter="(id)=>deleteSubChapterPopUp(id)"
                @updateChapter="(id, chapter)=>{updateChapter(id, chapter)}"
                @updateSubchapter="(id, subchapter)=>{updateSubchapter(id, subchapter)}"
                @newSubchapter="newSubchapter"
                @deleteChapterDescriptionPopUp="deleteChapterDescriptionPopUp"
                @deleteSubchapterDescriptionPopUp="deleteSubchapterDescriptionPopUp"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </div>
  </template>
  <script>
  import SuperTable from '../Components/SuperTable/SuperTable'
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
  import Button from '@/components/Button/Button'
  import {$rules, $chapter, $subchapter} from '../Services';

  export default {
    name: 'RegulationTable',
    components: {
      SuperTable,
      Breadcrumbs,
      Button,
    },
    data () {
      return {
        loading: true,
        clearSelected: '',
        headers: [
          {
            text: this.$t('modules.Regulation.table.headers.chapter'),
            value: 'chapter',
          },
        ],
        Regulation : [],
      }
    },
    mounted () {
      this.fetchData();
    },
    computed: {
      canCreate() {
        return this.$permissions.portaladministrativo.messaging.regulation.CREATE
      },
      canUpdate() {
        return this.$permissions.portaladministrativo.messaging.regulation.READ || this.$permissions.portaladministrativo.messaging.regulation.UPDATE
      },
      canDelete() {
        return this.$permissions.portaladministrativo.messaging.regulation.DELETE
      },
    },
    methods: {
      deleteChapterPopUp (id) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.regulation.table.messages.delete'),
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.deleteChapter(id) },
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {}
          },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        })
      },
      deleteSubChapterPopUp (id, chapterId) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.regulation.table.messages.deleteSubChapter'),
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.deleteSubChapter(id, chapterId) },
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {}
          },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        })
      },
      deleteChapterDescriptionPopUp (id, chapter) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.regulation.table.messages.deleteDescription'),
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.updateChapter(id, chapter) },
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {}
          },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        })
      },
      deleteSubchapterDescriptionPopUp(id, subchapter) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.regulation.table.messages.deleteDescription'),
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.updateSubchapter(id, subchapter) },
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {}
          },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        })
      },
      async fetchData () {
        $rules.find('chapters/'.concat(this.$route.params.regulation))
        .then( (res) => {
          this.Regulation = res.data.map(chapter => ({
            ...chapter,
            subchapter: chapter.subchapter.sort((a, b) => a.id - b.id)
          }));
          this.loading = false;
        },
        () => {
          // Manejo de errores si es necesario
        });
        // try{
        //   const {data} = await $rules.find('chapters/'.concat(this.$route.params.regulation))
        //   this.Regulation = data.map(chapter => ({
        //     ...chapter,
        //     subchapter: chapter.subchapter.sort((a, b) => a.id - b.id)
        //   }))

        // }catch(error){
        //   //empty
        // }
      },
      newChapter () {
        const aux = [...this.Regulation[this.Regulation.length-1].name.split('|')];
        aux[0] = [...aux[0].split(' ')];
        aux[0][1] = Number(aux[0][1]);
        if(this.$isTeclab) aux[0][1] += 1;
        if(this.$isIPP) aux[0][1] = 'XXXX';
        const chapterName = `${aux[0][0]} ${aux[0][1]} | Edite el nombre del capítulo`;

        const newChapterToAdd = {
          content : 'Edite el contenido del capítulo.',
          name : chapterName,
          order : null,
          ruleType: this.$route.params.regulation
        };
        $chapter.create( newChapterToAdd )
          .then(
            () => {
            this.fetchData
            this.$store.dispatch('commons/openPopUp', {
              title: this.$t('modules.regulation.table.messages.newChapterSuccess'),
              actionPrimary: { text: this.$t('actions.accept'), callback: () => this.fetchData() },
              icon: { color: 'success', name: 'mdi-check' },
              color: 'primary'
            })
          },
          (error) => {
            this.$store.dispatch('commons/openPopUp', {
              title: this.$t('actions.error'),
              content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.regulation.table.messages.editedError')}],
              actionPrimary: { text: this.$t('actions.accept'), callback() { }},
              icon: { color: 'error', name: 'mdi-close' },
              color: 'primary'
            });
        })
      },
      newSubchapter (subchapter) {
        $subchapter.create( subchapter )
          .then(
            () => {
            this.fetchData
            this.$store.dispatch('commons/openPopUp', {
              title: this.$t('modules.regulation.table.messages.editedSuccess'),
              actionPrimary: { text: this.$t('actions.accept'), callback: () => this.fetchData() },
              icon: { color: 'success', name: 'mdi-check' },
              color: 'primary'
            })
          },
          (error) => {
            this.$store.dispatch('commons/openPopUp', {
              title: this.$t('actions.error'),
              content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.regulation.table.messages.editedError')}],
              actionPrimary: { text: this.$t('actions.accept'), callback() { }},
              icon: { color: 'error', name: 'mdi-close' },
              color: 'primary'
            });
        })
      },
      updateChapter (_id, chapter) {
        $chapter.update(_id, chapter )
          .then(
            () => {
            this.fetchData
            this.$store.dispatch('commons/openPopUp', {
              title: this.$t('modules.regulation.table.messages.editedSuccess'),
              actionPrimary: { text: this.$t('actions.accept'), callback: () => this.fetchData() },
              icon: { color: 'success', name: 'mdi-check' },
              color: 'primary'
            });
          },
          (error) => {
            this.$store.dispatch('commons/openPopUp', {
              title: this.$t('actions.error'),
              content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.regulation.table.messages.editedError')}],
              actionPrimary: { text: this.$t('actions.accept'), callback() {}},
              icon: { color: 'error', name: 'mdi-close' },
              color: 'primary'
            });
        })
      },
      updateSubchapter (_id, subchapter) {
        $subchapter.update(_id, subchapter)
          .then(
            () => {
            this.fetchData
            this.$store.dispatch('commons/openPopUp', {
              title: this.$t('modules.regulation.table.messages.editedSuccess'),
              actionPrimary: { text: this.$t('actions.accept'), callback: () => this.fetchData() },
              icon: { color: 'success', name: 'mdi-check' },
              color: 'primary'
            })
          },
          (error) => {
            this.$store.dispatch('commons/openPopUp', {
              title: this.$t('actions.error'),
              content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.regulation.table.messages.editedError')}],
              actionPrimary: { text: this.$t('actions.accept'), callback() { }},
              icon: { color: 'error', name: 'mdi-close' },
              color: 'primary'
            });
        })
      },
      deleteChapter (id) {
        $chapter.delete(id)
          .then(() => {
            this.fetchData();
          })
      },
      deleteSubChapter (id) {
        $subchapter.delete(id)
          .then(() => {
            this.fetchData();
          })
      },

    }
  }
  </script>

