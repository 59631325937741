<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0" v-if="!syncStudentStatus">
            <v-container class="container-custom px-6">
                <Breadcrumbs :description="$t('modules.canvas.syncStudent.breadcrumbs.description')"
                            :title="$t('modules.canvas.syncStudent.breadcrumbs.title')">
                </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            show-expand
                            @goToSubjects="goToSubjects"
                            :headers="headers"
                            :synchronizedStudent="synchronizedStudent"
                            :data="user"
                            :isPagination="false"
                            :isSearching="true"
                            :permissions="{ create: canCreate }"
                            @searchAction="(userIdentification)=>{search(userIdentification)}"
                            @syncUpStudent="(data)=>syncUpStudentPopUp(data)"
                        ></SuperTable>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <SuccessMessage
        v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import SuperTable from '../Components/SuperTable';
import { $students, $users } from '../Services';

export default {
    name: 'SyncStudentView',
    components: {
        Breadcrumbs,
        SuperTable,
        SuccessMessage
    },
    data () {
        return {
            syncStudentStatus:false,
            user: [],
            headers: [
                {
                    text: this.$t('modules.canvas.syncStudent.table.headers.identification'),
                    value: 'identification',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncStudent.table.headers.name'),
                    value: 'name',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncStudent.table.headers.lastname'),
                    value: 'lastname',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncStudent.table.headers.career'),
                    value: 'career',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncStudent.table.headers.career_status'),
                    value: 'careerStatus.meaning',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncStudent.table.headers.synchronization'),
                    value: 'synchronizationStudent',
                    align: 'center',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncStudent.table.headers.edit'),
                    value: 'action',
                    align: 'center',
                    sortable: false
                } 
            ],
            successMessage:{},
            lmsUserId: null,
            synchronizedStudent: false
        }
    },
    methods: {
        goToSubjects(id){
            this.$router.push({path:`sync-student/sync-subjects/${id}`, query: {lmsUserId: this.lmsUserId}})
        },
        async search(userIdentification) {
            try {
                this.synchronizedStudent = false
                const {data} = await $students.find('studentCareersByIdentification', null, {params: { identification: userIdentification }})
                this.user = []
                if (data.studentCareers.length) {
                    data.studentCareers.forEach(career => {
                            this.user.push({
                                id: career.studentCareer,
                                name: data.name,
                                lastname: data.lastname,
                                identification: data.identification,
                                career: career.careerName,
                                careerStatus: career.careerStatus,
                                synchronizationStudent: ( career.careerStatus.value === 54 ) ? data.lmsUserId : false,
                                studentId: data.studentId
                            })
                    })
                }
                const res = await  $users.find(`lmsUser/${this.user[0].studentId}`, null, {params: {isStudent: true}})
                this.lmsUserId = res.data.id
                if( res.data.id != this.user[0].synchronizationStudent){
                    this.user[0].synchronizationStudent = 0
                }
            } catch (error) {
                this.user = [];
            }
        },
        syncUpStudentPopUp(data){
            this.$store.dispatch('commons/openPopUp', {
                title: this.$t('modules.canvas.syncStudent.message.syncUpStudent'),
                content: [
                    {
                        value: data.name
                    }
                ],
                actionPrimary: {
                    text: this.$t('modules.canvas.syncStudent.message.syncAccept'),
                    callback: () => this.syncUpStudent(data)
                },
                actionSecondary: {
                    text: this.$t('modules.canvas.syncStudent.message.syncCancel'),
                    callback() {}
                },
                icon: { color: 'warning', name: 'mdi-sync' },
                color: 'primary'
            })
        },
        async syncUpStudent(data){
            try {
                await $users.update(`${data.studentId}/syncStudent`)
                this.search(data.identification)
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.success'),
                    content: [{ value: this.$t('modules.canvas.syncStudent.message.syncUpStudentSuccess') }],
                    actionPrimary: { text: this.$t('actions.close'), callback () {} },
                    icon: {color: 'success', name: 'mdi-check'},
                    color: 'primary',
                })
            } catch (error) {
                this.successMessage.type = 'error';
                this.successMessage.title = this.$t('modules.canvas.syncStudent.message.syncUpStudentError');
                this.successMessage.actionSecondary = null;
                this.successMessage.actionPrimary = { 
                    text: this.$t('modules.canvas.syncStudent.actions.close'), callback: () => { 
                        this.syncStudentStatus = false
                    } 
                }
            } finally {
                this.syncStudentStatus = false;
            }
        }
    },
    computed: {
        canCreate() {
            return this.$permissions.portaladministrativo.canvas.sync_student.UPDATE
        },
    },
}
</script>