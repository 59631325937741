
export default {

    argentina: {
        canvas: {
            url: 'https://teclab.instructure.com/',
            ssoUrl: 'https://servicios.teclab.edu.ar/sso/SSOSAML.aspx',
            ssoKey: 'IlumnoCanvasUvaKey',
        },
        currency: '$',
        currency_decimals: 2,
        identificationType: 'DNI',
        minAverage: 6,
        maxAverage: 10,
        payment: {
            isCftEnabled: true
        },
        student_pays_enrollment: true,
        social_networks:{
            instagram: 'https://www.instagram.com/teclabinstituto',
            facebook:'https://www.facebook.com/teclabinstituto/',
            linkedin:'https://www.linkedin.com/company/teclabinstituto/'
        }
    },
    chile: {
        canvas: {
            url: 'https://ipp.instructure.com/',
            ssoUrl: 'https://certificados.ipp.cl/canvasSSO/SSOSaml.aspx',
            ssoKey: 'CanvasIPP',
        },
        currency: '$',
        currency_decimals: 0,
        identificationType: 'RUT',
        minAverage: 4,
        maxAverage: 7,
        payment: {
            isCftEnabled: false
        },
        student_pays_enrollment: true,
        social_networks:{
            instagram: 'https://www.instagram.com/instituto_ipp/',
            facebook:'https://www.facebook.com/institutoprofesionalipp/',
            linkedin:'https://www.linkedin.com/school/instituto-profesional-ipp/'
        }
    },
    peru: {
        canvas: {
            url: 'https://teclabperu.instructure.com/',
            ssoUrl: 'https://api.teclab.edu.pe/sso/SSOSAML.aspx',
            ssoKey: 'IlumnoCanvasUvaKey',
        },
        currency: 'S/',
        currency_decimals: 2,
        identificationType: 'DNI',
        minAverage: 13,
        maxAverage: 20,
        payment: {
            isCftEnabled: false
        },
        student_pays_enrollment: true,
        social_networks:{
            instagram: 'https://www.instagram.com/onmexinstituto',
            facebook:'https://www.facebook.com/onmexinstituto/',
            linkedin:'https://www.linkedin.com/company/onmexinstituto/'
        }
    },
    mexico: {
        canvas: {
            url: 'https://onmex.instructure.com/',
            ssoUrl: 'https://servicios.onmex.mx/sso/SSOsaml.aspx',
            ssoKey: 'IlumnoCanvasUvaKey',
        },
        currency: 'MXN$',
        currency_decimals: 2,
        identificationType: 'CURP',
        minAverage: 6,
        maxAverage: 10,
        payment: {
            isCftEnabled: false
        },
        student_pays_enrollment: false,
        social_networks:{
            instagram: 'https://www.instagram.com/onmexinstituto',
            facebook:'https://www.facebook.com/onmexinstituto/',
            linkedin:'https://www.linkedin.com/company/onmexinstituto/'
        }
    }

}