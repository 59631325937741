<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('modules.scholarships.table.search.search')"
          outlined
          clearable
          class="superTable__search"
          @click:clear="search = '';keyup()"
          @keyup="keyup"
          @keyup.esc="search = ''"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :show-select="true"
          :headers="headers"
          :items="users"
          :totalItems="totalItems"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :search="search"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
          :server-items-length="totalItems"
          must-sort
          @click:row="(val) => $emit('click:row', val.id)"
          hide-default-footer
          item-key="id"
          class="superTable__table Scholarships-supertable"
          style="table-layout: fixed"
        >
          <template v-slot:top v-if="permissions.delete">
            <div :class="['superTable__bulkActions', 'py-2',areItemsSelected ? 'show' : 'hide']">
              <v-divider></v-divider>
              <div class="py-3">
                <span
                  v-if="permissions.delete"
                  class="caption mx-4 cursor-pointer hover-7-inverted"
                  @click="itemsToDelete"
                >
                  <v-icon size="22" class="mr-2">mdi-delete</v-icon>
                  {{$t('modules.scholarships.table.search.bulkDelete')}}
                </span>
                <!--                            <span class="caption mx-4 cursor-pointer hover-7-inverted" @click="$emit('bulk-assign', selectedItems)"><v-icon size="22" class="mr-2">mdi-account-card-details-outline</v-icon>Asignar roles</span>-->
              </div>
              <v-divider></v-divider>
            </div>
          </template>

          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.discounts`]="{item}">
              <v-row v-for="type in item.discounts" v-bind:key="type.id">
                  <v-col sm="12" class="py-0">
                      {{ type.name }}
                  </v-col>
              </v-row>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.name`]="{item}">
            <div class="superTable__table__item__name">{{ item.name }}</div>
          </template>

          <template v-slot:[`item.status`]="{item}">
            <v-icon size="12" :color="(item.status) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
          </template>

          <template v-slot:[`item.action`]="{item}" v-if="permissions.read || permissions.update || permissions.delete">
            <v-menu bottom left>
              <template v-slot:activator="{ on: menu }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                      v-on="{ ...tooltip, ...menu }"
                      class="superTable__table__item__action"
                    >mdi-dots-vertical</v-icon>
                  </template>
                  <span>{{ $t('modules.scholarships.table.message.actions') }}</span>
                </v-tooltip>
              </template>
              <v-list class="pa-0">
                <v-list-item v-if="permissions.read" @click="openDetails(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-eye</v-icon>
                    {{$t('modules.scholarships.table.search.details')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="permissions.update" @click="editItem(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon>
                    {{$t('modules.scholarships.table.search.edit')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="permissions.delete" @click="deleteItem(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon>
                    {{$t('modules.scholarships.table.search.delete')}}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span
          class="caption d-inline-block"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          @change="$emit('length-pagination-change', itemsPerPage)"
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination
          :value="page"
          :length="pageCount"
          @next="$emit('next-pagination', page)" 
          @previous="$emit('previous-pagination', page)" 
          @input="$emit('input-pagination', $event)" 
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          v-model.number="goToPage"
          max="pageCount"
          outlined
          @blur="changePage"
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{$t('modules.scholarships.table.search.gotopage')}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Constants from '@/plugins/constants';

export default {
  name: 'SuperTable',
  props: {
    users: Array,
    headers: Array,
    clearSelected: String,
    permissions: {read: false, update: false, delete: false},
    sortBy: String,
    sortDesc: Boolean,
    pageCount: { type: Number, default: 1 },
    page: { type: Number, default: 1 },
    totalItems: Number,
  },
  data() {
    return {
      selectedItems: [],
      search: '',
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      markedUsers: [],
      goToPage: 1,
    };
  },
  methods: {
    getAvatar(id) {
      return `${Constants.URL_DATA_SERVER  }/users/${  id  }/avatar`;
    },
    editItem(id) {
      this.$emit('updateUser', id);
    },
    deleteItem(id) {
      this.$emit('deleteUser', id);
    },
    itemsToDelete() {
      this.$emit('bulk-delete', [...this.selectedItems]);
      this.selectedItems = [];
    },
    openDetails(id) {
      this.$emit('openDetails', id);
    },
    keyup () {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchAction()
          clearTimeout(this.timeout);
        },1000)
      },
    searchAction() {
        this.$emit('searchAction', this.search)
      },
    changePage() {
        if (this.goToPage > this.pageCount) this.goToPage = this.page;
        else this.$emit('input-pagination', this.goToPage)
      },
  },
  watch: {
    page (val) {
          if (val > this.pageCount) this.page = this.pageCount;
          if (val < 1) this.page = 1;
          if (this.page !== this.goToPage) this.goToPage = this.page;
    },
    clearSelected() {
          this.selectedItems = [];
    },
  },
  computed: {
    areItemsSelected() {
        return !!this.selectedItems.length
      },
    
      itemsCount() {
        const counter = {
          totalItems: this.totalItems,
          startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
          endItem: this.page * this.itemsPerPage
        };
        if (counter.endItem > this.totalItems) counter.endItem = this.totalItems
        return counter
      }
  }
};
</script>

<style lang="sass">
  .Scholarships-supertable
    table tr
      // cursor: pointer !important
</style>
