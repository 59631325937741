<template>
  <v-row class="p-0 ">
      <v-col cols="12 py-0 " >
          <div class="banner" :style="{height : height}" :class="[typeClass]">
              <v-row>
                <v-col cols="2">
                  <div class="d-flex flex-column justify-center iconContainer ma-3" :class="[typeClass]">
                <v-icon v-if="type === 'success'" color="success" size="20px">mdi-check-circle</v-icon>
                <v-icon v-else-if="type === 'info'" color="info" size="20px">mdi-information-outline</v-icon>
                <v-icon v-else-if="type === 'warning'" color="#FB9616" size="20px">mdi-alert-outline</v-icon>
              </div>
                </v-col>
                <v-col cols="10" class="pr-7">
                  <div class="banner__title py-3 body-2 font-weight-bold flex d-flex align-center">
                    <span v-if="title" class="flex">{{ title }}</span>
                    <slot name="action"></slot>
                  </div>
                  <div class="text-caption boards-subtitle grey--text">{{ content }}</div>
                  <div class="text-caption boards-subtitle grey--text">{{ secondContent }}</div>
                </v-col>
              </v-row>
          </div>
      </v-col>
  </v-row>
</template>

<script>
  export default {
      name: 'BannerExams',
      props: {
          type: { type: String, default: 'success' },
          title: { type: String, default: null },
          content: { type: String, default: null },
          secondContent: { type: String, default: null },
          height: { type: String, default: '106px' },
      },
      computed: {
          typeClass () {
              return `type-${this.type}`;
          }
      }
  }
</script>

<style lang="sass" scoped>
  .banner
      border-radius: 8px

      &.type-success
          background-color: rgba(59, 192, 0, 0.2)
          .banner__title
              color: #21510b

      &.type-info
          background-color: var(--v-primary-lighten5)
          .banner__title
              color: #0088cc

      &.type-warning
          background-color: #FFF1E1
          .banner__title
              color: #995200

      .iconContainer
          min-width: 30px
          width: 30px
          height: 30px
          border-radius: 50%
          &.type-success
              background-color: rgba(59, 192, 0, 0.2) !important
          &.type-info
              background-color: rgba(0, 136, 204, 0.1) !important
          &.type-warning
              background-color: rgba(251, 150, 22, 0.2) !important
</style>
