<template>
  <div>
    <v-row>
      <v-row class="pl-8 pb-10">
        <v-icon class="pr-2 noteIcon">mdi-alert-circle-outline</v-icon>
        <h5 class="pt-1 noteText" v-text="note"></h5>
      </v-row>
      <v-col cols="12" class="mx-auto">
        <FromToCard
        v-if="careerChange.career"
        :from="$isTeclab ? {title: studentCareer.description} : {title: studentCareer.description, subtitle: studentCareer.studyPlan.description}"
        :to="$isTeclab ? {title: careerChange.career.name}: {title: careerChange.career.name, subtitle: careerChange.studyPlan.description}"
        ></FromToCard>
      </v-col>
      <v-col cols="12" v-if="loading">
        <div class="d-flex justify-center align-center mt-16">
          <v-progress-circular class="mx-auto" indeterminate color="primary"></v-progress-circular>
        </div>
      </v-col>
      <template v-else>
        <v-col cols="12" class="mx-auto">
          <MultipleStatusGroups
            :items="subjects"
            :noItemsMessage="$t('modules.portalalumno.procedures.careerChangeStep2.dataSection.noItems')"
          ></MultipleStatusGroups>
        </v-col>
        <v-col cols="12" class="mx-auto d-flex flex-column mt-4">
          <h1 class="headline primary--text">{{ $t('modules.portalalumno.procedures.careerChangeStep2.dataSection.classStart') }}</h1>
          <h6 class="caption mt-2">{{ classStart }}</h6>
        </v-col>
        <v-col cols="12" class="mx-auto mt-4">
          <MultipleProductsCosts
            v-if="products.length"
            :items="products"
          ></MultipleProductsCosts>
        </v-col>
        <v-col cols="12" class="mx-auto">
          <div class="d-flex justify-end my-12">
            <Button
              white
              :text="$t('actions.return')"
              @clicked="$emit('goBack')"
              class="mr-4"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.next')"
              :successText="$t('actions.created')"
              :errorText="'Error'"
              @end="resetButtonValues"
              @clicked="nextStep"
            ></Button>
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<style lang="sass" scoped>
  .noteIcon
    font-size: 20px
    color: v-bind('$vuetify.theme.defaults.light.primary.accent4')
  .noteText
    color: v-bind('$vuetify.theme.defaults.light.primary.accent4')
    font-weight: 410
</style>

<script>
  import MultipleStatusGroups from '@/components/MultipleStatusGroups/MultipleStatusGroups'
  import MultipleProductsCosts from '@/components/MultipleProductsCosts/MultipleProductsCosts'
  import FromToCard from '@/components/FromToCard/FromToCard'
  import Button from '@/components/Button/Button'
  import {$studentProcessing, $students} from '../../services';
  import moment from 'moment'
  import { mapGetters } from 'vuex'

export default {
  name: 'SubjectsHomologation',
  props: {
    careerChange: Object,
  },
  components: {
    Button,
    MultipleStatusGroups,
    FromToCard,
    MultipleProductsCosts,
  },
  data () {
    return {
      loading: true,
      subjects: [],
      products: [],
      classStart: null,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      note: this.$t('modules.portalalumno.procedures.alert.note'),
    }
  },
  computed: {
    ...mapGetters({
      studentRegister: 'commons/getStudentActualRegister',
      studentCareer: 'commons/getStudentCareer',
      studentId: 'commons/getStudentId',
      studentUserData: 'commons/getStudentUserData',
      userName: 'commons/getUserName',
      studentUserId: 'commons/getStudentUserID',
    }),
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true

      const homologation = await $students.findById(this.studentRegister, 'equivalentsSubjects', {params: { idCareer: this.careerChange.career.id, idStudyPlan: this.careerChange.studyPlan.id }})
      this.subjects = homologation.data

      const products = await $students.findById(this.studentRegister, 'comparePrice', {params: { idCareer: this.careerChange.career.id, idStudyPlan: this.careerChange.studyPlan.id }})
      this.products = products.data.prices
      this.classStart = moment(products.data?.startDate).format('DD/MM/YYYY');

      this.loading = false
    },
    async confirmCareerChange () {
      try {
        this.createButton.loading = true
        await $studentProcessing.create({
          commentary: this.careerChange.reason,
          userUpdateId: this.studentUserId ? this.studentUserId : null,
          careers: { value: this.careerChange.career },
          processingsReasonType: { value: this.careerChange.processingsReasonType },
          origin: this.studentCareer.id,
          destination: this.careerChange.career.id,
          studentRecord: this.studentRegister,
          student: { id: this.studentId, user: {name: this.studentUserData.email} },
          processing: {key: 'CAREER_SWITCH'},
          studyPlan:{id: this.careerChange.studyPlan.id},
          userName: this.userName,
        })
        this.createButton.success = true
        this.$emit('nextStep')
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.portalalumno.procedures.careerChangeStep2.errorMessage.title'),
          actionPrimary: { text: this.$t('actions.accept'), callback () { } },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        this.createButton.error = true
        throw error
      } finally {
        this.createButton.loading = false
      }
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    nextStep () {
      const arancel = this.products.find(product => product.id == this.$sisConstants('modules.products.arancel'))
      this.careerChange.price = arancel?.newAmount
      if (this.$isTeclab) {
        this.confirmCareerChange()
      } else {
        this.$emit('nextStep')
        // this.$router.push({name: 'careerChangeStep3', params: {
        //     careerId: this.$route.params.careerId.id,
        //     careerChange: this.$route.params.careerChange,
        //     studyPlanChange: this.$route.params.studyPlanChange,
        //     procedureId: this.$route.params.procedureId,
        //     careerPrice: arancel.newAmount
        //   }
        // })
      }
    }
  },
}
</script>


