<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t(`modules.portalalumno.procedures.procedureTemporaryLow.breadcrumb.title`)"
        :description="$t(`modules.portalalumno.procedures.procedureTemporaryLow.breadcrumb.description`)"
        isDescriptionFullLength
        :callback="goBack"
      ></Breadcrumbs>
      <v-row v-if="!createNewProcedureTemporaryLowStatus">
        <v-col class="viewSpaces" cols="12">
          <v-row class="mb-4">
            <v-col sm="6" cols="6">
              <v-autocomplete
                ref="selectedCareerRecord"
                v-model="$v.selectedCareerRecord.$model"
                outlined
                :error-messages="($v.selectedCareerRecord.$dirty && $v.selectedCareerRecord.$invalid) ?
                $t('modules.portalalumno.procedures.procedureTemporaryLow.validations.careerRequired') : ''"
                :label="$t(`modules.portalalumno.procedures.procedureTemporaryLow.autocomplete.label`)"
                :loading="careersLoading"
                :items="careers"
                item-text="career.description"
                item-value="idStudentCareer"
                @blur="$v.selectedCareerRecord.$touch()"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <OutlinedCard
              title=""
              subtitle=""
              class="mb-6">
              <v-row class="mb-6">
                <v-col sm="12" class="py-0">
                  <v-card-text class=" body-1 pa-0 primary--text font-weight-bold">
                    {{ $t(`modules.portalalumno.procedures.procedureTemporaryLow.OutlineCard.title`) }}
                  </v-card-text>
                  <v-card-text class=" body-2 pa-0 gray--text ">
                    {{ $t(`modules.portalalumno.procedures.procedureTemporaryLow.OutlineCard.subtitle`) }}
                  </v-card-text>
                </v-col>
                <v-col sm="4" cols="4">
                  <v-autocomplete
                    ref="selectedProcessingReason"
                    v-model="$v.selectedProcessingReason.$model"
                    outlined
                    :error-messages="($v.selectedProcessingReason.$dirty && $v.selectedProcessingReason.$invalid) ?
                    $t('modules.portalalumno.procedures.procedureTemporaryLow.validations.processingReasonRequired') : ''"
                    :label="$t(`modules.portalalumno.procedures.procedureTemporaryLow.OutlineCard.autocompleteMotivo.label`)"
                    :loading="processingReasonsLoading"
                    :items="processingReasons"
                    item-text="meaning"
                    @blur="$v.selectedProcessingReason.$touch()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="10" class=" pe-12">
                  <v-textarea
                    ref="observation"
                    v-model="$v.observation.$model"
                    :error-messages="($v.observation.$dirty && $v.observation.$invalid) ?
                    $t('modules.portalalumno.procedures.procedureTemporaryLow.validations.observationRequired') : ''"
                    :label="$t(`modules.portalalumno.procedures.procedureTemporaryLow.OutlineCard.textarea.label`)"
                    :placeholder="$t(`modules.portalalumno.procedures.procedureTemporaryLow.OutlineCard.textarea.placeholder`)"
                    name="input-7-4"
                    class="mt-6"
                    outlined
                    @blur="$v.observation.$touch()"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
              </v-row>
              <v-row class="mb-6">
                <v-col cols="12 " class="mb-0 py-0">
                  <v-row class="mb-0">
                    <v-col sm="12" class="py-0 mb-0">
                      <v-card-text class=" body-1 pa-0 primary--text font-weight-bold">
                        {{ $t(`modules.portalalumno.procedures.procedureTemporaryLow.OutlineCard.contacto.title`) }}
                      </v-card-text>
                    </v-col>
                    <v-col cols="4" sm="4" class="mb-0">
                      <TelephoneInput
                        ref="contactPhone"
                        :value.sync="$v.contactPhone.$model"
                        :placeholder="$t(`modules.portalalumno.procedures.procedureTemporaryLow.OutlineCard.contacto.inputContacto`)"
                        :required="$v.contactPhone.$dirty && $v.contactPhone.$invalid"
                        @blur="$v.contactPhone.$touch()"
                        @validate="isValidPhone"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="mt-0 py-0">
                  <v-row class="mt-0">
                    <v-col sm="4" cols="4" class="py-0">
                      <v-menu
                        ref="time"
                        v-model="timeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="$t(`modules.portalalumno.procedures.procedureTemporaryLow.OutlineCard.contacto.inputTime.title`)"
                            :error-messages="($v.selectedTime.$dirty && $v.selectedTime.$invalid) ?
                            $t('modules.portalalumno.procedures.procedureTemporaryLow.validations.timeRequired') : ''"
                            @blur="$v.selectedTime.$touch()"
                            append-icon="mdi-calendar-clock"
                            class="no-message mt-2"
                            v-model="selectedTime"
                            v-on="on"
                            readonly
                            outlined
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="timeMenu"
                          :value="selectedTime"
                          @click:hour="selectHour"
                          class="no-message"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </OutlinedCard>
          </v-row>
        </v-col>
        <v-col cols="11" class="margin-bot">
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="cancel"
              class="mr-4"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.create')"
              :successText="$t('actions.created')"
              :errorText="$t('actions.error')"
              @clicked=" createProcedureTemporaryLow"
            ></Button>
          </div>
        </v-col>
      </v-row>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :subtitle="successMessage.subtitle"
        :actionPrimary="successMessage.actionPrimary"
      />
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {$students, $types, $studentProcessing} from '../services';
import {required} from 'vuelidate/lib/validators';
import {mapGetters} from 'vuex';

export default {
  name: 'ProcedureTemporaryLow',
  components: {
    Breadcrumbs,
    OutlinedCard,
    TelephoneInput,
    Button,
    SuccessMessage
  },
  data() {
    return {
      createNewProcedureTemporaryLowStatus: false,
      careers: [],
      careersLoading: false,
      selectedCareerRecord: null,
      processingReasons: [],
      processingReasonsLoading: false,
      selectedProcessingReason: null,
      observation: null,
      contactPhone: '',
      telephoneValid: true,
      timeMenu:false,
      selectedTime: null,
      hola:'',
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null
      },
    };
  },
  validations: {
    selectedCareerRecord: {required},
    selectedProcessingReason: {required},
    observation: {required},
    contactPhone: {
      required,
      valid() {
        return this.telephoneValid;
      }
    },
    selectedTime: {required}
  },
  computed: {
    ...mapGetters({
      studentData: 'commons/getStudentData',
      userName: 'commons/getUserName',
      studentUserId: 'commons/getStudentUserID',
    }),
  },
  mounted() {
    this.getCareers();
    this.getProcessingReasons();
  },
  methods: {
    async getCareers() {
      this.careersLoading = true;
      try {
        const {data} = await $students.filter([54], {}, `careers/${this.studentData.student.id}`);
        this.careers = data;
      } catch (err) {
        this.careers = [];
        throw err;
      } finally {
        this.careersLoading = false;
      }
    },
    async getProcessingReasons() {
      try {
        this.processingReasonsLoading = true;
        const {data} = await $types.find(null, null, {params: {type: 'TEMPORAL_UNSUBSCRIBE_PROCESSING'}});
        this.processingReasons = data;
      } catch (err) {
        this.processingReasons = [];
        throw err;
      } finally {
        this.processingReasonsLoading = false;
      }
    },
    isValidPhone(phoneObject) {
      this.telephoneValid = phoneObject.isValid;
      this.$refs.contactPhone.handleInput(this.contactPhone, phoneObject);
    },
    async createProcedureTemporaryLow() {
      this.createButton.loading = true
      if (this.$v.$invalid) {
        await this.$v.$touch();
        this.$refs.contactPhone.handleBlur()
        Object.keys(this.$v).some(input => {
          if (input.includes('$')) return false;
          if (this.$v[input].$error) {
            this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
            return true;
          }
        });
        this.createButton.error = true;
        this.createButton.loading = false;
      } else {
        try {
          if(this.$isTeclab) {
            this.$trackEvent('Tramites', 'Clk_BtnEnvBajTr_Tram', 'BajaTrans');
          }
          const newTemporaryLow = {
            reason: this.selectedProcessingReason,
            studentId: this.studentData.student.id,
            studentCareerId: this.selectedCareerRecord,
            userName: this.userName,
            processingKey: 'TEMPORARY_DEREGISTRATION',
            comments: this.observation,
            telephoneContact: this.contactPhone,
            contactTime: this.selectedTime,
            user: this.studentUserId ? this.studentUserId : null
          }
          await $studentProcessing.create(newTemporaryLow, {}, 'temporary-low')
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.portalalumno.procedures.procedureTemporaryLow.successMessage.title');
          this.successMessage.subtitle = this.$t('modules.portalalumno.procedures.procedureTemporaryLow.successMessage.subtitle');
          this.successMessage.actionPrimary = {
            text: this.$t('actions.close'), callback: () => {
              this.$router.push('/procedures');
            }
          };
          this.createNewProcedureTemporaryLowStatus = true;
          this.createButton.success = true;
          this.createButton.loading = false;
          this.clearData();
        } catch (err) {
          this.successMessage.type = 'error';
          this.successMessage.title = this.$t('modules.portalalumno.procedures.procedureTemporaryLow.errorMessage.title');
          this.successMessage.subtitle = null;
          this.successMessage.actionPrimary = {
            text: this.$t('actions.return'), callback: () => {
              this.createNewProcedureTemporaryLowStatus = false;
            }
          };
          this.successMessage.actionSecondary = null;
          this.createNewProcedureTemporaryLowStatus = true;
          this.createButton.loading = false;
          this.createButton.error = true;
          throw err;
        }
      }
    },
    goBack() {
      this.$trackEvent('Tramites', 'Cancelar', 'Retiro');
      this.$router.push('/procedures');
    },
    clearData() {
      this.resetButtonValues();
      this.selectedCareerRecord = null;
      this.selectedProcessingReason = null;
      this.observation = null;
      this.contactPhone = '';
      this.selectedTime = null;
      this.$v.$reset();
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    selectHour (val) {
      this.selectedTime = val < 10 ? `0${val}:00`: `${val}:00`;      
      this.timeMenu = false
    },
    cancel() {
      if(this.$isTeclab) {
          this.$trackEvent('Tramites', 'Clk_BtnBackBajTr_Tram', 'BajaTrans');
        }
      this.$router.replace('/procedures');
    },
  },
  
};
</script>


