<template>
  <v-container class="pa-0 pb-8 StudentPreRegistration" fluid>
    <v-container class="container-custom" v-if="!updatePreStatus">
      <Breadcrumbs
        :title="$t('modules.preRegistration.create.breadcrumbs.title')"
        :description="$t('modules.preRegistration.create.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row>
        <v-col cols="8">
          <v-expansion-panels multiple :value="[0, 1, 2, 3]" class="mx-5 px-5">
            <!-- Personal info -->
            <v-expansion-panel class="no-shadow">
              <v-expansion-panel-header>
                <h2 class="primary--text">{{ $t("modules.preRegistration.form.personalInfo") }}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="align-center">
                  <v-col sm="5">
                    <v-text-field
                      :error-messages="$v.studentDataPrereg.email.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :label="`${$t('modules.preRegistration.form.email')} *`"
                      outlined
                      v-model="$v.studentDataPrereg.email.$model"
                      @blur="$v.studentDataPrereg.email.$touch()"
                    />
                  </v-col>
                  <v-btn
                    class="mb-2"
                    text
                    @click="onEmailSearchClick"
                    :loading="emailSearchButton.loading"
                    disabled
                  >
                    <v-icon v-if="!emailSearchButton.wasSearched">mdi-magnify</v-icon>
                    <v-icon v-if="emailSearchButton.wasSearched && emailSearchButton.wasFounded" class="success--text">mdi-check</v-icon>
                    <v-icon v-if="emailSearchButton.wasSearched && !emailSearchButton.wasFounded" class="error--text">mdi-close</v-icon>
                  </v-btn>
                </v-row>
                <v-row>
                  <v-col sm="3">
                    <v-select
                      @change="changeIdentificationInputKey()"
                      :error-messages="$v.studentDataPrereg.identificationType.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :items="identificationTypes"
                      item-text="meaning"
                      :label="`${$t('modules.preRegistration.form.docType')} *`"
                      outlined
                      v-model="$v.studentDataPrereg.identificationType.$model"
                      @blur="$v.studentDataPrereg.identificationType.$touch()"
                    />
                  </v-col>
                  <v-col sm="3">
                    <IdentificationInput
                      :value="$v.studentDataPrereg.identification.$model"
                      v-model="$v.studentDataPrereg.identification.$model"
                      :idType="identificationType"
                      :error-messages="$v.studentDataPrereg.identification.$dirty && $v.studentDataPrereg.identification.$invalid ? $t('modules.users.create.validation_errors.personalForm.identification') : ''"
                      @validateRut="(status) => {this.validRut = status;}"
                      @blur="$v.studentDataPrereg.identification.$touch()"
                      :label="$t('modules.users.create.personalForm.identificationNumber') + ' *'"
                      :key="identificationInputKey"
                      @input="(inpValue) => {this.studentDataPrereg.identification = inpValue}"
                    ></IdentificationInput>
                  </v-col>
                  <v-col sm="3">
                    <v-text-field
                      v-model="studentDataPrereg.serialNumber"
                      :label="$t('modules.preRegistration.form.docSerial')"
                      @input="studentDataPrereg.serialNumber = $event.toUpperCase()"
                      outlined
                      maxlength="10"
                      @keypress="$validateAlphanumeric($event, $v.studentDataPrereg.lastname.$model,10, false, false)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      :error="$v.studentDataPrereg.name.$error"
                      :error-messages="$v.studentDataPrereg.name.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :label="`${$t('modules.preRegistration.form.name')} *`"
                      outlined
                      v-model="$v.studentDataPrereg.name.$model"
                      @input="$v.studentDataPrereg.name.$model = $event.toUpperCase()"
                      @blur="$v.studentDataPrereg.name.$touch()"
                      @keypress="$validateAlphanumeric($event, $v.studentDataPrereg.lastname.$model,40, true, true)"
                    />
                  </v-col>
                  <v-col sm="6">
                    <v-text-field
                      :error="$v.studentDataPrereg.lastname.$error"
                      :error-messages="$v.studentDataPrereg.lastname.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :label="`${$t('modules.preRegistration.form.lastName')} *`"
                      outlined
                      v-model="$v.studentDataPrereg.lastname.$model"
                      @input="$v.studentDataPrereg.lastname.$model = $event.toUpperCase()"
                      @blur="$v.studentDataPrereg.lastname.$touch()"
                      @keypress="$validateAlphanumeric($event, $v.studentDataPrereg.lastname.$model,40, true, true)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-select
                      :error="$v.studentDataPrereg.maritalStatus.$error"
                      :error-messages="$v.studentDataPrereg.maritalStatus.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :label="`${$t('modules.preRegistration.form.maritalStatus')} *`"
                      outlined
                      :items="maritalStatus"
                      item-text="meaning"
                      return-object
                      v-model="$v.studentDataPrereg.maritalStatus.$model"
                      @blur="$v.studentDataPrereg.maritalStatus.$touch()"
                    />
                  </v-col>
                  <v-col sm="3">
                    <v-select
                      :error="$v.studentDataPrereg.genderId.$error"
                      :error-messages="$v.studentDataPrereg.genderId.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :label="`${$t('modules.preRegistration.form.gender')} *`"
                      outlined
                      :items="genderTypes"
                      item-text="meaning"
                      v-model="$v.studentDataPrereg.genderId.$model"
                      @blur="$v.studentDataPrereg.genderId.$touch()"
                    />
                  </v-col>
                  <v-col sm="3">
                    <v-menu
                      ref="birthdateMenu"
                      v-model="birthdateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="$v.studentDataPrereg.birthDate.$model"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatDateToCurrent($v.studentDataPrereg.birthDate.$model)"
                          :label="`${$t('modules.preRegistration.form.birthDate')} *`"
                          readonly
                          v-on="on"
                          outlined
                          :error="$v.studentDataPrereg.birthDate.$error"
                          :error-messages="$v.studentDataPrereg.birthDate.$error ? $t('modules.preRegistration.errors.required') : ''"
                          @blur="$v.studentDataPrereg.birthDate.$touch()"
                          append-icon="mdi-calendar"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="$v.studentDataPrereg.birthDate.$model"
                        no-title
                        scrollable
                        :max="nowDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="birthdateMenu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.birthdateMenu.save($v.studentDataPrereg.birthDate.$model)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6">
                     <AutocompleteInput
                      :value.sync="studentDataPrereg.birthPlace"
                      :errorMessages="$t('modules.preRegistration.errors.required')"
                      :label="$t('modules.preRegistration.form.birthPlace') + ' *'"
                      disable-buttons
                      @save="$emit('saveLocation')"
                    ></AutocompleteInput>
                  </v-col>
                  <v-col sm="6">
                    <v-select
                      :error="$v.studentDataPrereg.nationality.$error"
                      :error-messages="$v.studentDataPrereg.nationality.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :label="`${$t('modules.preRegistration.form.nationality')} *`"
                      outlined
                      :items="nationalities"
                      item-text="demonym"
                      item-value="id"
                      v-model="$v.studentDataPrereg.nationality.$model"
                      @blur="$v.studentDataPrereg.nationality.$touch()"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6">
                    <AutocompleteInput
                      :value.sync="$v.studentDataPrereg.address.cityWithState.$model"
                      :errorMessages="$t('modules.preRegistration.errors.required')"
                      :label="$t('modules.users.create.contactForm.city') + ' *'"
                      disable-buttons
                      @save="$emit('saveLocation')"
                    ></AutocompleteInput>
                  </v-col>
                  <v-col sm="3">
                    <v-text-field
                      :label="$t('modules.preRegistration.form.zipCode')"
                      outlined
                      v-model.number="studentDataPrereg.address.zipCode"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      :error="$v.studentDataPrereg.address.street.$error"
                      :error-messages="$v.studentDataPrereg.address.street.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :label="`${$t('modules.preRegistration.form.street')} *`"
                      outlined
                      v-model="$v.studentDataPrereg.address.street.$model"
                      @blur="$v.studentDataPrereg.address.street.$touch()"
                    />
                  </v-col>
                  <v-col sm="2">
                    <v-text-field
                      :error="$v.studentDataPrereg.address.number.$error"
                      :error-messages="$v.studentDataPrereg.address.number.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :label="`${$t('modules.preRegistration.form.number')} *`"
                      outlined
                      type="text"
                      v-mask="'#####'"
                      v-model.number="$v.studentDataPrereg.address.number.$model"
                      @blur="$v.studentDataPrereg.address.number.$touch()"
                    />
                  </v-col>
                  <v-col sm="2">
                    <v-text-field
                      :label="$t('modules.preRegistration.form.floor')"
                      outlined
                      type="text"
                      v-mask="'###'"
                      v-model.number="studentDataPrereg.address.floor"
                    />
                  </v-col>
                  <v-col sm="2">
                    <v-text-field
                      :label="$t('modules.preRegistration.form.department')"
                      outlined
                      v-model="studentDataPrereg.address.apartment"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6" class="columns textfieldwidth">
                    <TelephoneInput
                      :value.sync="$v.studentDataPrereg.cellphone.$model"
                      :error="$v.studentDataPrereg.cellphone.$error"
                      :error_messages="$v.studentDataPrereg.cellphone.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :placeholder="`${$t('modules.preRegistration.form.cellphone')} *`"
                      @blur="$v.studentDataPrereg.cellphone.$touch()"
                    />
                  </v-col>
                  <v-col sm="6" class="columns textfieldwidth">
                    <TelephoneInput
                      :value.sync="studentDataPrereg.phone"
                      :placeholder="$t('modules.preRegistration.form.phone')"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Previous Studies -->
            <v-expansion-panel class="no-shadow">
              <v-expansion-panel-header>
                <h2 class="primary--text">{{$t('modules.preRegistration.form.previousStudies')}}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="d-flex">
                  <v-col sm="6">
                    <AutocompleteInput
                      :value.sync="$v.institutionCityWithState.$model"
                      :errorMessages="$t('modules.preRegistration.errors.required')"
                      :label="$t('modules.preRegistration.form.institutionCity') + ' *'"
                      :disable-buttons="true"
                      @save="loadInstitutions"
                    >
                    </AutocompleteInput>
                  </v-col>
                  <v-col sm="6">
                    <v-autocomplete
                      :error-messages="$v.studentDataPrereg.institution.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :items="institutions"
                      :label="$t('modules.preRegistration.form.institution')"
                      :loading="loadingInstitution"
                      v-model="$v.studentDataPrereg.institution.$model"
                      item-text="name"
                      item-value="id"
                      outlined
                      @blur="$v.studentDataPrereg.institution.$touch()"
                    />
                  </v-col>
                  <v-col sm="6">
                    <v-select
                      :error-messages="$v.studentDataPrereg.levelType.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :items="levelTypes"
                      :label="$t('modules.preRegistration.form.educationalLevel')"
                      v-model="$v.studentDataPrereg.levelType.$model"
                      item-text="meaning"
                      item-value="value"
                      outlined
                      @blur="$v.studentDataPrereg.levelType.$touch()"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Academic data -->
            <v-expansion-panel class="no-shadow">
              <v-expansion-panel-header>
                <h2 class="primary--text">{{ $t("modules.preRegistration.form.preinscription") }}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="d-flex">
                  <v-col sm="12" class="py-0 d-none">
                    <v-switch
                      v-model="goodAverage"
                      @change="fetchPrices"
                      :label="$t('modules.preRegistration.form.good_average')"
                      color="primary"
                      class="ma-0 pa-0 switch"
                    ></v-switch>
                  </v-col>
                  <v-col sm="12" class="py-0">
                    <div class="d-block">
                      <strong>{{ $t("modules.preRegistration.form.career") }}:</strong>
                      <span>{{ selectedCareer ? selectedCareer.description : '' }}</span>
                    </div>
                    <div class="my-3 d-block">
                      <strong>{{ $t("modules.preRegistration.form.studyPlan") }}:</strong>
                      <span>{{ selectedStudyPlan ? selectedStudyPlan.description : '' }}</span>
                    </div>
                  </v-col>
                  <v-col sm="6">
                    <!-- <v-autocomplete
                      v-model="$v.selectedCeo.$model"
                      :items="ceos"
                      item-value="id"
                      item-text="description"
                      :disabled="rolCeo"
                      return-object
                      :label="`${$t('modules.preRegistration.form.serviceCenter')} *`"
                      :error="$v.selectedCeo.$error"
                      :error-messages="$v.selectedCeo.$error ? $t('modules.preRegistration.errors.required') : ''"
                      class="pb-2"
                      outlined
                      @change="onCeoChange"
                    ></v-autocomplete> -->
                    
                    <!-- :TODO - Fix zonas, descomentar bloque al subir cambios -->
                    <v-autocomplete 
                      :items="statesList"
                      item-text="name"
                      item-value="id"
                      :label="$t('modules.preRegistration.selectEntryOptionItems.province')"
                      outlined
                      v-model.trim="$v.selectState.$model"
                      @blur="$v.selectState.$touch()"
                      @change="loadLocality"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="6">
                    <v-autocomplete
                      v-model="$v.selectedLocality.$model"
                      :items="filteredLocalities"
                      :loading="loadingSearchLocalities"
                      item-value="id"
                      item-text="name"
                      :label="`${$t('modules.preRegistration.form.locality')} *`"
                      :error="$v.selectedLocality.$error"
                      :error-messages="$v.selectedLocality.$error ? $t('modules.preRegistration.errors.required') : ''"
                      class="pb-2"
                      outlined
                      @change="onLocalityChange"
                    />
                  </v-col>
                  <v-col sm="6">
                    <v-select
                      :error="$v.selectedTerm.$error"
                      :error-messages="$v.selectedTerm.$error ? $t('modules.preRegistration.errors.required') : ''"
                      :label="`${$t('modules.preRegistration.form.term')} *`"
                      outlined
                      :no-data-text="$t('modules.preRegistration.selectsEmpty.terms')"
                      :items="terms"
                      item-value="id"
                      return-object
                      v-model="selectedTerm"
                      @blur="$v.selectedTerm.$touch()"
                      @change="fetchPrices"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="no-shadow" v-if="showPayments">
              <v-expansion-panel-header>
                <h2 class="primary--text">{{ $t("modules.preRegistration.form.payment") }}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <h2 class="primary--text">{{ $t("modules.preRegistration.form.listPrice") }}</h2>
                <v-row>
                  <v-col cols="6">
                    <SpecialCheck :label="'Matrícula'" :price="prices.matricula" />
                  </v-col>
                  <v-col cols="6">
                    <SpecialCheck :label="'Arancel'" :price="prices.arancel" />
                  </v-col>
                </v-row>

                <div class="section-grey pa-4 my-4" v-if="discount && discount.details.length">
                  <h3 class="primary--text">{{ $t("modules.preRegistration.form.typeOfDiscount") }}</h3>
                  <span class="ml-5">{{ discount.description }}</span>
                  <v-row>
                    <v-col cols="6" v-for="detail in discount.details" :key="detail.id">
                      <v-card outlined class="py-3 px-3 no-border">
                        <v-row justify="space-between" no-gutters>
                          <div class="text--lighten-4 text-center">
                            <small class="text-uppercase font-weight-bold">{{ detail.name }}</small>
                            <h3 class="success--text">{{ detail.amount ? `- ${$options.filters.$currency(detail.amount)}` : `-${detail.percentage}%` }}</h3>
                          </div>
                          <div class="mt-3">
                            <h3 class="primary--text">{{ billingProductWithDiscount(detail) }}</h3>
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-sheet color="#fadcb3" class="info-container text-left py-3" >
                    {{ $t("modules.preRegistration.form.validPromotion") }}
                    {{ discount.to }}
                  </v-sheet>
                </div>

                <div class="section-grey pa-4 my-4" v-if="benefits.length">
                  <h3 class="primary--text">{{ $t("modules.preRegistration.form.benefits") }}</h3>
                  <v-row>
                    <v-col cols="8">
                      <v-select
                        :label="$t('modules.preRegistration.form.benefits')"
                        :items="benefits"
                        item-text="description"
                        item-value="id"
                        return-object
                        clearable
                        v-model="selectedBenefit"
                        @change="onBenefitChange"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        :label="$t('modules.preRegistration.form.benefits')"
                        :disabled="!selectedBenefit"
                        :items="benefitProducts"
                        v-model="selectedBenefitProduct"
                        return-object
                        @change="onBenefitProductChange"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>

                <div class="section-grey pa-4 my-4" v-if="scholarships.length">
                  <h3 class="primary--text">{{ $t("modules.preRegistration.form.scholarships") }}</h3>
                  <v-row>
                    <v-col cols="8">
                      <v-select
                        :label="$t('modules.preRegistration.form.scholarships')"
                        :items="scholarships"
                        item-text="description"
                        item-value="id"
                        return-object
                        clearable
                        v-model="selectedScholarship"
                        @change="onScholarshipsChange"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        :label="$t('modules.preRegistration.form.scholarships')"
                        :disabled="!selectedScholarship"
                        :items="scholarshipsProducts"
                        v-model="selectedScholarshipProduct"
                        @change="onScholarshipProductChange"
                        return-object
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="no-shadow" v-if="showPayments">
              <v-expansion-panel-header>
                <h2 class="primary--text">{{ $t("modules.preRegistration.form.finance") }}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-sheet color="#5ca1ff" class="info-container white--text py-2">
                  <v-row>
                    <v-col class="pa-0 text-center align-self-center" cols="2">
                      <img class="img-coin" :src="require(`@/assets/icons/coins.png`)" />
                    </v-col>
                    <v-col class="pl-0 text-left align-self-center" cols="10">
                      {{ $t("modules.preRegistration.form.bonificationWarning1") }}
                      <b>
                      {{ $t("modules.preRegistration.form.bonificationWarning2") }}
                      </b>
                      {{ $t("modules.preRegistration.form.bonificationWarning3") }}
                    </v-col>
                  </v-row>
                </v-sheet>

                <div class="section-grey pa-4 my-4">
                  <v-row>
                    <v-col sm="6" class="pt-0 d-flex justify-center align-center">
                      <div>{{ creditCardDiscount }}% extra por pago total de cuotas</div>
                    </v-col>
                    <v-col sm="2" class="pt-0 d-flex justify-center align-center">
                      <v-switch
                        color="primary"
                        class="ma-0 pa-0 switch"
                        v-model="extraOff"
                        @change="enabledExtraOff"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  <MercadoPagoInstallments
                    ref="mp"
                    :total="resumen.total"
                  ></MercadoPagoInstallments>
                  <!-- @setDue="setDue"
                    @resetDue="resetDue" -->

                  <v-row v-for="item in payments" :key="item.id">
                    <v-col cols="12">
                      <v-card outlined class="py-3 px-3">
                        <v-row
                          justify="space-between"
                          class="payment-container"
                          no-gutters
                        >
                          <v-col class="d-flex justify-start align-center text-center">
                            <h3 class="black--text">{{ item.title }}</h3>
                          </v-col>
                          <v-col
                            class="text-right"
                            :class="{ 'd-none': !showPrepaid && prepaid.expiration }"
                            v-for="(prepaid, index) in getPaymentPrepaids(item.options)"
                            :key="`${item.id}-${index}`"
                          >
                            <div class="mx-2 d-flex flex-column align-end justify-center fill-height" :class="{ 'border-row': item.options.length > 1 && showPrepaid }">
                              <small v-if="prepaid.off > 0 && !prepaid.isTotal" class="font-weight-bold grey--text">{{ prepaid.expiration }}</small>
                              <h3 :class="prepaid.off > 0 || !showPrepaid ? 'primary--text' : 'black--text'">
                                {{ getExtraOffPrice(prepaid.amount - (prepaid.off * prepaid.amount) / 100) / (prepaid.isTotal ? totalInstallment : 1) | $currency }}
                              </h3>
                              <small v-if="prepaid.off > 0" class="font-weight-bold success--text">{{ getExtraOffPrice(null, prepaid.off) }}% Off</small>
                              <small v-else-if="prices.arancel.discount > 0" class="font-weight-bold success--text">{{ getExtraOffPrice(null, prices.arancel.discount)}}% Off</small>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col sm="8" class="d-flex justify-center align-center">
                      <div class="d-flex justify-center align-center">
                        <div>{{ $t("modules.preRegistration.create.countDues") }}</div>
                      </div>
                    </v-col>
                    <v-col sm="2" class="d-flex justify-center align-center">
                      <div class="d-flex justify-center align-center text-center text-muted">
                        <v-select
                          class="combo-days"
                          :items="installmentsOptions"
                          :label="$t('modules.preRegistration.create.dues')"
                          outlined
                          v-model="totalInstallment"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <!-- <v-tabs v-model="tab" centered background-color="#f5f6f9" class="mt-5">
                    <v-tab v-for="item in payMethodLabels" :key="item">{{ item }}</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab"  class="section-grey pa-4">
                  <v-tab-item>
                    <MercadoPagoInstallments
                      ref="mp"
                      :total="resumen.total * (1 - creditCardDiscount / 100)"
                      @setDue="setDue"
                      @resetDue="resetDue"
                    ></MercadoPagoInstallments>

                    <v-row v-if="resumen.due">
                      <v-col cols="12">
                        <v-card outlined class="py-4 px-3">
                          <v-row justify="space-between" no-gutters>
                            <v-col class="text-left pl-3" cols="6">
                              <h3 class="black--text">{{ resumen.due.installments }} Cuotas de</h3>
                              <small class="font-weight-bold success--text">{{ creditCardDiscount }} % OFF</small>
                            </v-col>
                            <v-col class="text--lighten-4 text-right align-self-center pr-3" cols="6">
                              <h3 class="black--text">{{ resumen.due.total_amount / resumen.due.installments | $currency }}</h3>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-sheet color="#b3dbff" class="info-container text-left primary--text py-2">
                      <v-row>
                        <v-col class="pa-0 text-center align-self-center" cols=2>
                          <img class="img-scroll" :src="require(`@/assets/icons/scroll.png`)">
                        </v-col>
                        <v-col class="pl-0 text-left align-self-center" cols=10>  
                          <b>{{ $t('modules.preRegistration.form.detailsWarning') }}</b>
                        </v-col>
                      </v-row>
                    </v-sheet>

                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col sm="6" class="d-flex justify-center align-center">
                        <div class="d-flex justify-center align-center">
                          <div>{{$t('modules.preRegistration.create.countDues')}}</div>
                        </div>
                      </v-col>
                      <v-col sm="2" class="d-flex justify-center align-center">
                        <div class="d-flex justify-center align-center text-center text-muted">
                          <v-select
                            class="combo-days"
                            :items="installmentsOptions"
                            :label="$t('modules.preRegistration.create.dues')"
                            outlined
                            @change="generatePayments"
                            v-model="installment"
                          />
                        </div>
                      </v-col>
                    </v-row>

                    <v-row v-for="item in payments" :key="item.id">
                      <v-col cols="12">
                        <v-card outlined class="py-3 px-3">
                          <v-row justify="space-between" class="payment-container" no-gutters>
                            <v-col class="d-flex justify-start align-center text-center">
                              <h3 class="black--text">{{ item.title }}</h3>
                            </v-col>
                            <v-col class="text-right" :class="{'d-none': !showPrepaid && prepaid.expiration}" v-for="(prepaid, index) in getPaymentPrepaids(item.options)" :key="`${item.id}-${index}`">
                              <div class="mx-2 d-flex flex-column align-end justify-center fill-height" :class="{'border-row': item.options.length > 1 && showPrepaid}">
                                <small v-if="(prepaid.off > 0)" class="font-weight-bold grey--text">{{ prepaid.expiration }}</small>
                                <h3 :class="(prepaid.off > 0 || !showPrepaid) ? 'primary--text' : 'black--text'">{{ prepaid.amount - (prepaid.off * prepaid.amount / 100) | $currency }}</h3>
                                <small v-if="(prepaid.off > 0)" class="font-weight-bold success--text">{{ prepaid.off }}% Off</small>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>

                  </v-tab-item>
                </v-tabs-items> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="d-flex justify-end mb-5">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.push('/preregistration')"
              @end="resetButtonValues()"
              class="mr-4"
            ></Button>

            <Button
              :loading="updateButton.loading"
              :success="updateButton.success"
              :error="updateButton.error"
              :text="$t('modules.preRegistration.update.preregister')"
              :successText="$t('modules.preRegistration.create.preregistered')"
              :errorText="'Error'"
              :disabled="!canCreatePreregister"
              @clicked="updatePreregister"
            ></Button>
          </div>
        </v-col>

        <v-col cols="4" v-if="showPayments">
          <Summary :resumen="resumen" :extraOff="extraOff" />
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
    ></SuccessMessage>
  </v-container>
</template>

<script>
import {
  $types,
  $preRegister,
  $nationalities,
  $terms,
  $countries,
  // $scholarships,
  $billingProps,
  $benefits,
  $discounts,
  $prepaids, $institutions,
  $citiesByState   // :TODO - Fix zonas, descomentar linea al subir cambios
  // $contact
} from '../services';
import { required, email, helpers, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex'
import moment from 'moment'
import Button from '@/components/Button/Button'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import IdentificationInput from '@/components/IdentificationInput/IdentificationInput'
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput'
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput'
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import MercadoPagoInstallments from '@/components/Payments/MercadoPagoInstallments'
import Summary from '../Components/Summary/Summary'
import SpecialCheck from '../Components/SpecialCheck/SpecialCheck'
import Configuration from '../../../../core/constants/configuration';

const STUDENT_TYPE = 7

export default {
  name: 'StudentPreRegistrationUpdate',
  components: {
    Button,
    Breadcrumbs,
    AutocompleteInput,
    TelephoneInput,
    SuccessMessage,
    Summary,
    IdentificationInput,
    SpecialCheck,
    MercadoPagoInstallments
  },
  data() {
    return {
      identificationType: '',
      identificationInputKey: 1,
      nowDate: moment().format('YYYY-MM-DD'),
      goodAverage: false,
      extraOff: false,
      creditCardDiscount: 5,
      validRut: true,
      installmentsOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      matriculaID: 0,
      arancelID: 0,
      payments: [],
      originalPrices: null,
      prices: {
        matricula: { selected: true, amount: null, discount: null },
        arancel: { selected: true, amount: null, discount: null }
      },
      payMethodLabels: ['Tarjeta de Crédito', 'Pago mensual'],
      successMessage: {
        type: 'success',
        title: '',
        subtitle: this.$t('modules.preRegistration.create.message.successCreationSubtitle'),
        actionPrimary: {
          text: this.$t('modules.periods.create.success_message.actions.primary_text'),
          callback: () => { this.$router.push('/preregistration') }
        },
        actionSecondary: {
          text: this.$t('modules.periods.create.success_message.actions.secondary_text'),
          callback: () => { this.updatePreStatus = false }
        }
      },
      scholarships: [],
      scholarshipsProducts: [],
      selectedScholarship: null,
      selectedScholarshipProduct: null,
      benefitProducts: [],
      selectedBenefit: null,
      selectedBenefitProduct: null,
      selectedTerm: null,
      benefits: [],
      existPrices: false,
      totalInstallment: 1,
      installment: 6,
      discount: null,
      prepaids: [],
      studentDataPrereg: {
        email: null,
        name: null,
        lastname: null,
        birthDate: null,
        maritalStatus: null,
        genderId: null,
        identificationType: 17,
        identification: null,
        serialNumber: null,
        phone: null,
        cellphone: null,
        nationality: null,
        birthPlace: null,
        address: {
          street: null,
          number: null,
          floor: null,
          apartment: null,
          zipCode: null,
          cityWithState: null
        },
        institution: null,
        levelType: null,
      },
      originalInstitution: null,
      institutionCityWithState: null,
      institutions: [],
      loadingInstitution: false,
      levelTypes: [],
      selectedCareer: null,
      selectedLocality: null,    // :TODO - Fix zonas, descomentar linea al subir cambios
      originalSelectedLocality: null,    // :TODO - Fix zonas, descomentar linea al subir cambios
      selectedStudyPlan: null,
      updatePreStatus: false,
      emailSearchButton: {
        loading: false,
        wasSearched: false,
        wasFounded: false
      },
      identificationTypes: [],
      genderTypes: [],
      maritalStatus: [],
      careers: [],
      /*
      :TODO - Fix zonas, descomentar bloque al subir cambios
      */
      filteredLocalities: [],
      loadingSearchLocalities: false,
      statesList: [],
      selectState: null,
      birthdateMenu: false,
      updateButton: {
        loading: false,
        success: false,
        error: false
      },
      countries: [],
      nationalities: [],
      terms: [],
      studyPlans: [],
      resumen: {
        items: [
          { title: 'Subtotal', items: [], monto: 0 },
          { title: 'Descuentos', items: [], monto: 0 }
        ],
        due: null,
        total: 0,
        recharge: 0,
        tea: '0,00%',
        cft: '0,00%',
        creditCard: false
      }
    };
  },
  validations: {
    selectedTerm: { required },
    selectedCareer: { required },
    selectedLocality: { required },    // :TODO - Fix zonas, descomentar linea al subir cambios
    selectState: { required },    // :TODO - Fix zonas, descomentar linea al subir cambios
    selectedStudyPlan: { required },
    institutionCityWithState: { required },
    studentDataPrereg: {
      email: { required, email },
      name: {
        required,
        maxLength: maxLength(60),
        alphabetical: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i)
      },
      lastname: {
        required,
        maxLength: maxLength(60),
        alphabetical: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i)
      },
      birthDate: { required },
      maritalStatus: { required },
      genderId: { required },
      identificationType: { required },
      identification: {
        required,
        valid() {
          return this.validRut;
        }
      },
      cellphone: { required },
      address: {
        street: { required },
        number: { required },
        cityWithState: { required }
      },
      nationality: { required },
      institution: { required },
      levelType: { required },
    },
   formValid: ['selectedTerm', 'selectedCareer', 'selectedLocality', 'selectState', 'selectedStudyPlan', 'studentDataPrereg']    // :TODO - Fix zonas, descomentar linea al subir cambios
  },
  mounted() {
    if (!this.canUpdate) this.$router.push('preregistration');
    else this.initialize();
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.admission.pre_registration.UPDATE;
    },
    showPrepaid() {
      return true;
    },
    showPayments() {
      return (this.selectedCareer && this.selectedLocality && this.selectedStudyPlan && this.selectedTerm && this.existPrices);    // :TODO - Fix zonas, descomentar linea al subir cambios
    },
    canCreatePreregister() {
      return (
        !this.$v.formValid.$invalid &&
        (this.installment >= 1 || this.resumen.due)
      );
    },
    ...mapGetters({
      configurationValues: 'settings/getAllCurrentValues',
      configurationOptions: 'settings/getAllOptions',
      userId: 'commons/getUserID',
    }),
    /*
    :TODO - Fix zonas, descomentar bloque al subir cambios
    */
   selectStateErrors() {
      const errors = [];
      if (!this.$v.selectState.$anyError) return errors;
      if (!this.$v.selectState.required)
        errors.push(this.$t('modules.preRegistration.errors.cityRequired'));
      return errors;
    },
    isIPP() {
      return Configuration.value('url') == 'ipp.cl';
    }
  },
  methods: {
    changeIdentificationInputKey() {
      const identification = this.identificationTypes.filter(e => e.value == this.studentDataPrereg.identificationType);
      this.identificationType = identification[0].meaning;
      this.identificationInputKey += 1;
    },
    /*
    :TODO - Fix zonas, descomentar bloque al subir cambios
    */
   async loadLocality() {
      this.loadingSearchLocalities = true;
      const cities = await $citiesByState.find(null, null, {
        params: { stateId: this.selectState, sellers: true }
      });
      const newCities = cities.data
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map(el => {
          if (el.seller) {
            const newseller = { status: el.seller, message: 'No disponible' };
            const newElem = { ...el, seller: newseller };
            return newElem;
          } else {
            return { ...el, seller: undefined };
          }
        });
      this.filteredLocalities = newCities;
      if (this.originalSelectedLocality)
        this.selectedLocality = this.originalSelectedLocality;
      this.originalSelectedLocality = null;
      this.loadingSearchLocalities = false;
    },
    async loadInstitutions() {
      this.loadinginstitution = true
      try {
        const params = {
          city: this.institutionCityWithState?.idCity || null,
          region: this.institutionCityWithState?.idRegion || null,
          orientation: 'asc',
          orderBy: 'name',
          length: 500
        }
        const {data} = await $institutions.find(null, null, { params })
        this.institutions = data.content
        if (this.originalInstitution) {
          this.institution = this.originalInstitution;
          this.originalInstitution = null;
        }
      } catch (err) {
        this.institutions = []
        throw err
      } finally {
        this.loadinginstitution = false
      }
    },
    getExtraOffPrice(amount = null, percentage = null) {
      if (this.extraOff) {
        if (amount === 0) return amount;
        if (percentage === 100) return 100;
        return amount
          ? amount * (1 - this.creditCardDiscount / 100)
          : (percentage +=
              ((100 - percentage) * this.creditCardDiscount) / 100);
      } else {
        return amount ? amount : percentage;
      }
    },
    enabledExtraOff() {
      this.resetMpPaymentAmount();
    },
    getPaymentPrepaids(options) {
      return this.showPrepaid
        ? options
        : options.filter(option => option.expiration == null);
    },
    priceChanged() {
      this.resumen.items[0].items = [];
      this.resumen.items[0].monto = 0;
      this.resumen.items[1].items = [];
      this.resumen.items[1].monto = 0;

      // Agrego matricula
      this.resumen.items[0].monto = this.prices.matricula.amount;
      this.resumen.items[0].items.push({
        monto: this.prices.matricula.amount,
        title: this.prices.matricula.title,
        creditCard: false
      });

      // Agrego descuento matricula
      let amount = 0;
      let percentage = 0;
      let discount = this.discount
        ? this.discount.details.find(
            detail => detail.billingProductId == this.matriculaID
          )
        : null;
      if (discount) {
        percentage = discount.percentage
          ? discount.percentage
          : (discount.amount * 100) / this.prices.matricula.amount;
        amount = discount.amount
          ? discount.amount
          : (this.prices.matricula.amount * discount.percentage) / 100;
      }

      let benefit =
        this.selectedBenefitProduct &&
        this.selectedBenefitProduct.billingProductId == this.matriculaID;
      if (benefit) {
        if (amount >= this.prices.matricula.amount) {
          percentage = 100;
          amount = this.prices.matricula.amount; // eslint-disable-line prefer-destructuring
        } else {
          percentage += this.selectedBenefitProduct.percentage
            ? ((100 - percentage) * this.selectedBenefitProduct.percentage) /
              100
            : (this.selectedBenefitProduct.amount * 100) /
              (this.prices.matricula.amount - amount);
          amount += this.selectedBenefitProduct.amount
            ? this.selectedBenefitProduct.amount
            : ((this.prices.matricula.amount - amount) *
                this.selectedBenefitProduct.percentage) /
              100;
        }
      }

      let scholarship =
        this.selectedScholarshipProduct &&
        this.selectedScholarshipProduct.billingProductId == this.matriculaID;
      if (scholarship) {
        if (amount >= this.prices.matricula.amount) {
          percentage = 100;
          amount = this.prices.matricula.amount; // eslint-disable-line prefer-destructuring
        } else {
          percentage += this.selectedScholarshipProduct.percentage
            ? ((100 - percentage) *
                this.selectedScholarshipProduct.percentage) /
              100
            : (this.selectedScholarshipProduct.amount * 100) /
              (this.prices.matricula.amount - amount);
          amount += this.selectedScholarshipProduct.amount
            ? this.selectedScholarshipProduct.amount
            : ((this.prices.matricula.amount - amount) *
                this.selectedScholarshipProduct.percentage) /
              100;
        }
      }

      this.prices.matricula.discount = percentage;
      // this.resumen.items[1].monto = amount
      this.resumen.items[1].monto = this.extraOff
        ? amount +
          ((this.prices.matricula.amount - amount) * this.creditCardDiscount) /
            100
        : amount;
      this.resumen.items[1].items.push({
        monto: this.extraOff
          ? amount +
            ((this.prices.matricula.amount - amount) *
              this.creditCardDiscount) /
              100
          : amount,
        title: `${this.prices.matricula.title} - ${this.getExtraOffPrice(
          null,
          percentage
        )}% off`,
        creditCard: false
      });

      // Agrego arancel
      this.resumen.items[0].monto += this.prices.arancel.amount;
      this.resumen.items[0].items.push({
        monto: this.prices.arancel.amount,
        title: this.prices.arancel.title,
        creditCard: false
      });

      // Agrego descuento arancel
      amount = 0;
      percentage = 0;
      discount = this.discount
        ? this.discount.details.find(
            detail => detail.billingProductId == this.arancelID
          )
        : null;
      if (discount) {
        percentage = discount.percentage
          ? discount.percentage
          : (discount.amount * 100) / this.prices.arancel.amount;
        amount = discount.amount
          ? discount.amount
          : (this.prices.arancel.amount * discount.percentage) / 100;
      }

      benefit =
        this.selectedBenefitProduct &&
        this.selectedBenefitProduct.billingProductId == this.arancelID;
      if (benefit) {
        if (amount >= this.prices.arancel.amount) {
          percentage = 100;
          amount = this.prices.arancel.amount; // eslint-disable-line prefer-destructuring
        } else {
          percentage += this.selectedBenefitProduct.percentage
            ? ((100 - percentage) * this.selectedBenefitProduct.percentage) /
              100
            : (this.selectedBenefitProduct.amount * 100) /
              (this.prices.arancel.amount - amount);
          amount += this.selectedBenefitProduct.amount
            ? this.selectedBenefitProduct.amount
            : ((this.prices.arancel.amount - amount) *
                this.selectedBenefitProduct.percentage) /
              100;
        }
      }

      scholarship =
        this.selectedScholarshipProduct &&
        this.selectedScholarshipProduct.billingProductId == this.arancelID;
      if (scholarship) {
        if (amount >= this.prices.arancel.amount) {
          percentage = 100;
          amount = this.prices.arancel.amount; // eslint-disable-line prefer-destructuring
        } else {
          percentage += this.selectedScholarshipProduct.percentage
            ? ((100 - percentage) *
                this.selectedScholarshipProduct.percentage) /
              100
            : (this.selectedScholarshipProduct.amount * 100) /
              (this.prices.arancel.amount - amount);
          amount += this.selectedScholarshipProduct.amount
            ? this.selectedScholarshipProduct.amount
            : ((this.prices.arancel.amount - amount) *
                this.selectedScholarshipProduct.percentage) /
              100;
        }
      }

      this.prices.arancel.discount = percentage;
      // this.resumen.items[1].monto += amount
      this.resumen.items[1].monto += this.extraOff
        ? amount +
          ((this.prices.arancel.amount - amount) * this.creditCardDiscount) /
            100
        : amount;
      this.resumen.items[1].items.push({
        monto: this.extraOff
          ? amount +
            ((this.prices.arancel.amount - amount) * this.creditCardDiscount) /
              100
          : amount,
        title: `${this.prices.arancel.title} - ${this.getExtraOffPrice(
          null,
          percentage
        )}% off`,
        creditCard: false
      });

      // Total temporal sin recargo o descuento de tarjeta
      this.resumen.total =
        this.resumen.items[0].monto - this.resumen.items[1].monto;

      if (this.resumen.creditCard) {
        // Recargo por tarjeta
        this.resumen.items[0].monto += this.resumen.recharge;
        this.resumen.items[0].items.push({
          monto: this.resumen.recharge,
          title: 'Recargo por tarjeta',
          creditCard: true
        });

        // Descuento por tarjeta
        this.resumen.items[1].monto += this.resumen.due
          ? this.resumen.total - this.resumen.due.total_amount
          : 0;
        this.resumen.items[1].items.push({
          monto: this.resumen.due
            ? this.resumen.total - this.resumen.due.total_amount
            : 0,
          title: `Descuento por tarjeta - ${this.creditCardDiscount}% off`,
          creditCard: true
        });

        this.resumen.total =
          this.resumen.items[0].monto - this.resumen.items[1].monto;
      }
    },
    billingProductWithDiscount(detail) {
      let price = null;
      if (detail.amount) {
        price = detail.amount;
      } else {
        price =
          detail.id === this.matriculaID
            ? this.prices.matricula.amount
            : this.prices.arancel.amount;
        price = price * (1 - detail.percentage / 100);
      }
      return this.$options.filters.$currency(price);
    },
    async initialize() {
      this.matriculaID = this.$sisConstants('modules.products.matricula');
      this.arancelID = this.$sisConstants('modules.products.arancel');

      const requests = [
        $types.find(null, null, { params: { type: 'IDENTIFICATION_TYPE' } }),
        $types.find(null, null, { params: { type: 'GENDER_TYPE' } }),
        $nationalities.find(),
        $countries.find(),
        $terms.find('current-sales'),
        $types.find(null, null, { params: { type: 'MARITAL_STATUS' } }),
        $benefits.find('date', null, null),
        $types.find(null, null, { params: { type: 'STUDENT_LEVEL_TYPE' } }),
        $countries.find(null, null, { params: { name: Configuration.value('country') || 'chile' } })    // :TODO - Fix zonas, descomentar linea al subir cambios
        // $scholarships.find('date', null,  null),
      ];
      const response = await Promise.all(requests);
      this.identificationTypes = response[0].data;
      this.genderTypes = response[1].data;
      this.nationalities = response[2].data;
      this.countries = response[3].data;
      this.terms = response[4].data.content.map(term => ({
        id: term.id,
        startMonth: new Date(term.classStartDate).getUTCMonth() + 1,
        text: `(${term.code}) ${moment(
          term.classStartDate,
          'YYYY-MM-DD'
        ).format('YYYY - MM/DD')}`
      }));
      this.maritalStatus = response[5].data;
      this.benefits = response[6].data;
      this.levelTypes = response[7].data;
      this.statesList = response[8].data[0].states.sort((a, b) => a.name < b.name ? -1 : 1);   // :TODO - Fix zonas, descomentar linea al subir cambios
      // this.scholarships = response[6].data;

      // const userRoles = this.$store.getters['commons/getUserRoles']
      // this.rolCeo = (userRoles.find(rol => {return rol.rolType.meaning == 'Ceo'}) !== undefined)

      // if (this.rolCeo) {
      //     this.ceos = this.$store.getters['commons/getUserCeos']
      //     if (this.ceos && this.ceos.length) {
      //         const fetchedCareers = await $ceos.find(`${this.ceos[0].id}/careers`, null, null)
      //         fetchedCareers.data.sort((a, b) => (a.description > b.description) ? 1 : -1)
      //         this.careers = fetchedCareers.data
      //         this.selectedCeo = this.ceos[0]    // eslint-disable-line prefer-destructuring
      //     }
      // } else {
      //     const careers = await $careers.find(null, null, { params: { page: 0, length: 500 } })
      //     careers.data.content.sort((a, b) => (a.description > b.description) ? 1 : -1)
      //     this.careers = careers.data.content
      // }

      const studentDataPrereg = await $preRegister.findById(this.$route.params.id);
      const { data } = studentDataPrereg;
      this.institutionCityWithState = {
        cityState: data.student.institution ? data.student.institution.city || data.student.institution.region : null,
        idCity: data.student.institution?.cityId || null,
        idRegion: data.student.institution?.regionId || null
      };
      await this.loadInstitutions();

      if (data.student && data.student.user && data.student.user.userData) {
        this.studentDataPrereg.email = data.student.user.userData.email;
        this.studentDataPrereg.name = data.student.user.userData.name;
        this.studentDataPrereg.lastname = data.student.user.userData.lastname;
        this.studentDataPrereg.birthDate = data.student.user.userData.birthDate;
        this.studentDataPrereg.maritalStatus = data.student.user.userData.maritalStatus;
        this.studentDataPrereg.genderId = data.student.user.userData.gender.value;
        this.studentDataPrereg.identificationType = data.student.user.userData.identificationType.value;
        this.studentDataPrereg.identification = data.student.user.userData.identification;
        this.studentDataPrereg.serialNumber = data.student.user.userData.serialNumber;
        this.studentDataPrereg.phone = data.student.user.userData.phone;
        this.studentDataPrereg.cellphone = data.student.user.userData.cellphone;
        this.studentDataPrereg.nationality = data.student.user.userData.nationality.id;
        this.studentDataPrereg.birthPlace = data.student.user.userData.birthPlace;
        this.identificationType = data.student.user.userData.identificationType.meaning;
        this.changeIdentificationInputKey();
        if (data.student.user.userData.address) {
          this.studentDataPrereg.address.street = data.student.user.userData.address.street;
          this.studentDataPrereg.address.number = data.student.user.userData.address.number;
          this.studentDataPrereg.address.floor = data.student.user.userData.address.floor;
          this.studentDataPrereg.address.apartment = data.student.user.userData.address.apartment;
          this.studentDataPrereg.address.zipCode = data.student.user.userData.address.zipCode;
          this.studentDataPrereg.address.cityWithState = data.student.user.userData.address.cityWithState;
        }
        this.originalInstitution = data.student.institution?.id || null;
        this.studentDataPrereg.institution = data.student.institution?.id || null;
        this.studentDataPrereg.levelType = data.student.level?.value || null;
      }

      if (data.career) {
        this.careers = data.career;
        this.selectedCareer = data.career;
        this.goodAverage = !!data.career.good_average
        const degree = data.degree ? data.degree.description : '';
        this.studyPlans = [{ id: data.career.studyPlan.id, description: `(${data.career.studyPlan.description}) ${degree}` }];
        this.selectedStudyPlan = { id: data.career.studyPlan.id, description: `(${data.career.studyPlan.description}) ${degree}` };
      }
      this.selectedTerm = {
        id: data.term.id,
        startMonth: new Date(data.term.classStartDate).getUTCMonth() + 1,
        text: `(${data.term.code}) ${moment(data.term.classStartDate, 'YYYY-MM-DD').format('YYYY - MM/DD')}`
      };
      /*
      :TODO - Fix zonas, descomentar bloque al subir cambios
      */
      this.originalSelectedLocality = data.city.id;
      this.selectState = data.city ? data.city.stateId : data.region;
      await this.loadLocality();

      const arancel = data.studentPreregistation.find(price => price.billingProduct.id == this.arancelID);

      // if (arancel.scholarship) {
      //   const scholarship = this.scholarships.find(scholarship => scholarship.id == arancel.scholarship.id)
      //   const detail = scholarship.details.find(detail => detail.id == arancel.scholarship.scholarshipDetail.id)
      //   this.selectedScholarship = scholarship
      //   this.selectedScholarshipProduct = {
      //     scholarshipId: arancel.scholarship.id,
      //     billingProductId: this.arancelID,
      //     text: `${arancel.billingProduct.name.trim()} - ${detail.percentage ? `${detail.percentage} %` : this.$options.filters.$currency(detail.amount) }`,
      //     name: arancel.billingProduct.name.trim(),
      //     value: detail.id,
      //     percentage: detail.percentage ? detail.percentage : null,
      //     amount: detail.amount ? detail.amount : null
      //   }
      // }
      if (arancel.benefit) {
        const benefit = this.benefits.find(benefit => benefit.id == arancel.benefit.id);
        const detail = benefit.details.find(detail => detail.id == arancel.benefit.benefitDetail.idDetail);
        this.selectedBenefit = benefit;
        this.benefitProducts = benefit.details.map(detail => ({
          benefitId: benefit.id,
          billingProductId: detail.billingProduct.id,
          text: `${detail.billingProduct.name.trim()} - ${detail.percentage ? `${detail.percentage} %` : this.$options.filters.$currency(detail.amount)}`,
          name: detail.billingProduct.name.trim(),
          value: detail.id,
          percentage: detail.percentage ? detail.percentage : null,
          amount: detail.amount ? detail.amount : null
        }));

        this.selectedBenefitProduct = {
          benefitId: benefit.id,
          billingProductId: this.arancelID,
          text: `${arancel.billingProduct.name.trim()} - ${detail.percentage ? `${detail.percentage} %` : this.$options.filters.$currency(detail.amount)}`,
          name: arancel.billingProduct.name.trim(),
          value: detail.id,
          percentage: detail.percentage ? detail.percentage : null,
          amount: detail.amount ? detail.amount : null
        };
      }

      const matricula = data.studentPreregistation.find(price => price.billingProduct.id == this.matriculaID);
      if (matricula.benefit) {
        const benefit = this.benefits.find(benefit => benefit.id == matricula.benefit.id);
        const detail = benefit.details.find(detail => detail.id == matricula.benefit.benefitDetail.idDetail);
        this.selectedBenefit = benefit;
        this.benefitProducts = benefit.details.map(detail => ({
          benefitId: benefit.id,
          billingProductId: detail.billingProduct.id,
          text: `${detail.billingProduct.name.trim()} - ${detail.percentage ? `${detail.percentage} %` : this.$options.filters.$currency(detail.amount)}`,
          name: detail.billingProduct.name.trim(),
          value: detail.id,
          percentage: detail.percentage ? detail.percentage : null,
          amount: detail.amount ? detail.amount : null
        }));

        this.selectedBenefitProduct = {
          benefitId: benefit.id,
          billingProductId: this.matriculaID,
          text: `${matricula.billingProduct.name.trim()} - ${detail.percentage ? `${detail.percentage} %` : this.$options.filters.$currency(detail.amount)}`,
          name: matricula.billingProduct.name.trim(),
          value: detail.id,
          percentage: detail.percentage ? detail.percentage : null,
          amount: detail.amount ? detail.amount : null
        };
      }

      this.originalPrices = data.studentPreregistation;
      this.fetchPrices();

      // this.postulation = {
      //   ceo: data.ceo.description,
      //   career: data.career.description,
      //   studyPlan: data.career.studyPlan.description,
      //   term: `${data.term.code} ${moment(data.term.classStartDate, 'YYYY-MM-DD').format('YYYY - MM/DD')}`
      // }

      // this.newRegistration.email = data.student.user.name;
      // this.newRegistration.name = data.student.user.userData.name;
      // this.newRegistration.lastname = data.student.user.userData.lastname;
      // this.newRegistration.birthDate = data.student.user.userData.birthDate;
      // this.newRegistration.maritalStatus.value = data.student.user.userData.maritalStatus?.value;
      // this.newRegistration.genderId = data.student.user.userData.gender.value;
      // this.newRegistration.identificationType = data.student.user.userData.identificationType.value;
      // this.newRegistration.identification = data.student.user.userData.identification;
      // this.newRegistration.serialNumber = data.student.user.userData.serialNumber;
      // this.newRegistration.phone = data.student.user.userData.phone;
      // this.newRegistration.cellphone = data.student.user.userData.cellphone;
      // this.newRegistration.careerId = data.career.id;
      // this.newRegistration.ceoId = data.ceo.id;
      // this.newRegistration.address.street = data.student.user.userData.address.street;
      // this.newRegistration.address.number = data.student.user.userData.address.number;
      // this.newRegistration.address.floor = data.student.user.userData.address.floor;
      // this.newRegistration.address.apartment = data.student.user.userData.address.apartment;
      // this.newRegistration.address.zipCode = data.student.user.userData.address.zipCode;
      // this.newRegistration.address.cityWithState = data.student.user.userData.address.cityWithState;
      // this.newRegistration.termId = data.term.id;
      // this.newRegistration.nationality = data.student.user.userData.nationality.id;
      // this.newRegistration.birthPlace = data.student.user.userData.birthPlace.id;
      // this.newRegistration.studyPlanId = data.career.studyPlan.id;
    },
    formatDateToCurrent(date) {
      return date ?
      moment(date).format( 'DD/MM/YYYY' )
      // moment(date).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) 
      : null;
    },
    // resetDue() {
    //   this.resumen.recharge = 0
    //   this.resumen.due = null
    //   this.priceChanged()
    // },
    // setDue(data) {
    //   this.resumen.recharge = (data.resumen.total > this.resumen.total) ? data.resumen.total - this.resumen.total : 0
    //   this.resumen.cft = data.resumen.cft
    //   this.resumen.tea = data.resumen.tea
    //   this.resumen.due = data.due
    //   this.resumen.due.total_amount = this.resumen.due.total_amount.toFixed(0)
    //   this.priceChanged()
    // },
    async resetMpPaymentAmount() {
      await this.priceChanged();
      this.generatePayments();
      if (this.$refs.mp) this.$refs.mp.changeAmount();
    },
    onBenefitChange(benefit) {
      if (benefit) {
        this.selectedScholarship = null;
        this.selectedScholarshipProduct = null;
        this.scholarshipProduct = null;
        this.benefitProducts = benefit.details.map(detail => ({
          benefitId: benefit.id,
          billingProductId: detail.billingProduct.id,
          text: `${detail.billingProduct.name.trim()} - ${detail.percentage ? `${detail.percentage} %` : this.$options.filters.$currency(detail.amount)}`,
          name: detail.billingProduct.name.trim(),
          value: detail.id,
          percentage: detail.percentage ? detail.percentage : null,
          amount: detail.amount ? detail.amount : null
        }));
      } else {
        this.selectedBenefit = null;
        this.benefitProducts = [];
      }
      this.selectedBenefitProduct = null;
      this.resetMpPaymentAmount();
    },
    onBenefitProductChange() {
      this.resetMpPaymentAmount();
    },
    onScholarshipsChange(scholarship) {
      if (scholarship) {
        this.selectedBenefit = null;
        this.selectedBenefitProduct = null;
        this.benefitProducts = [];
        this.scholarshipsProducts = scholarship.scholarshipProduct.map(
          product => ({
            scholarshipId: scholarship.id,
            billingProductId: product.billingProduct.id,
            text: `${product.billingProduct.name.trim()} - ${product.percentage ? `${product.percentage} %` : this.$options.filters.$currency(product.amount)}`,
            name: product.billingProduct.name.trim(),
            value: product.id,
            percentage: product.percentage ? product.percentage : null,
            amount: product.amount ? product.amount : null
          })
        );
      } else {
        this.selectedScholarship = null;
        this.scholarshipsProducts = [];
      }
      this.selectedScholarshipProduct = null;
      this.resetMpPaymentAmount();
    },
    onScholarshipProductChange() {
      this.resetMpPaymentAmount();
    },
    async onCareerChange(career) {
      this.studyPlans = career
        ? career.studyPlans.map(plan => ({ id: plan.studyPlan.id, description: `(${plan.studyPlan.description}) ${plan.degreesFinal.description}` }))
        : [];

      this.selectedStudyPlan = null;
      this.$v.selectedStudyPlan.$reset();

      this.selectedLocality = null;    // :TODO - Fix zonas, descomentar linea al subir cambios
      this.$v.selectedLocality.$reset();    // :TODO - Fix zonas, descomentar linea al subir cambios
      this.fetchPrices();
    },
    async fetchPrices() {
      this.existPrices = false;
      if (this.selectedCareer && this.selectedLocality && this.selectedTerm) {    // :TODO - Fix zonas, descomentar linea al subir cambios
        try {
          if (this.originalPrices) {
            // Si hay precios traidos de back los cargo, sino los busco
            this.originalPrices.forEach(price => {
              if (price.billingProduct.id == this.matriculaID) {
                this.prices.matricula = {
                  preregister_id: price.id,
                  id: price.billingProduct.id,
                  amount: price.amount,
                  title: price.billingProduct.name.trim(),
                  selected: true
                };
              } else if (price.billingProduct.id == this.arancelID) {
                this.prices.arancel = {
                  preregister_id: price.id,
                  id: price.billingProduct.id,
                  amount: price.amount,
                  title: price.billingProduct.name.trim(),
                  selected: true
                };
              }
            });
          } else {
            const prices = await $billingProps.findPriceByCarrerAndCity(`/products-with-price?careerId=${this.selectedCareer.id}&cityId=${this.selectedLocality}&studentType=${STUDENT_TYPE}`);   // :TODO - Fix zonas, descomentar linea al subir cambios
            prices.data.forEach(price => {
              if (price.id == this.matriculaID) {
                this.prices.matricula = {
                  id: price.id,
                  amount: price.amount,
                  title: price.name.trim(),
                  selected: true
                };
              } else if (price.id == this.arancelID) {
                this.prices.arancel = {
                  id: price.id,
                  amount: price.amount,
                  title: price.name.trim(),
                  selected: true
                };
              }
            });
          }
          const prepaids = await $prepaids.find(null, null, {params: { termId: this.selectedTerm.id, careerId: this.selectedCareer.id, cityId: this.selectedLocality, studentType: STUDENT_TYPE, average: this.goodAverage }});   // :TODO - Fix zonas, descomentar linea al subir cambios
          this.prepaids = prepaids.data;
          this.prepaids.sort((a, b) => (a.dayFrom > b.dayFrom ? 1 : -1));

          try {
            const discount = await $discounts.find('date', null, {params: { cityId: this.selectedLocality, idCareer: this.selectedCareer.id, studentType: STUDENT_TYPE }});   // :TODO - Fix zonas, descomentar linea al subir cambios

            if (discount.data.length) {
              // eslint-disable-next-line prefer-destructuring
              this.discount = discount.data[0]
              this.discount.to = moment().format('DD-MM-YYYY');

              this.discount.details = this.discount.details.map(detail => ({
                billingProductId: detail.billingProduct.id,
                name: detail.billingProduct.name.trim(),
                amount: detail.bianualAmount ? detail.bianualAmount : (detail.bimesterAmount ? detail.bimesterAmount : null),
                percentage: detail.bianualPercentage ? detail.bianualPercentage : (detail.bimesterPercentage ? detail.bimesterPercentage : null)
              }));

              this.discount.details.sort((a, b) => (a.name > b.name ? 1 : -1));
            }
          } catch (err) {
            this.discount = null;
          } finally {
            this.existPrices = true;
          }
        } catch (error) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('alert'),
            content: [{ value: this.$t('modules.preRegistration.popup.withoutPrice') }],
            actionPrimary: { text: this.$t('actions.accept'), callback() {} },
            icon: { color: 'warning', name: 'mdi-alert' },
            color: 'primary'
          });
          this.existPrices = false;
          throw error;
        }
      }
      this.resetMpPaymentAmount();
    },
    
    onLocalityChange() {   // :TODO - Fix zonas, descomentar linea al subir cambios
      this.fetchPrices();
    },
    generatePayments() {
      const generatedPayments = [
        {
          id: 1,
          billingProductId: this.matriculaID,
          title: this.prices.matricula.title,
          options: [
            {
              isTotal: false,
              expiration: null,
              off: this.prices.matricula.discount,
              amount: this.prices.matricula.amount
            }
          ]
        }
      ];

      const matriculaNeto = (this.prices.matricula.amount * (1 - this.prices.matricula.discount / 100)).toFixed(0);
      const arancelNeto = (this.prices.arancel.amount * (1 - this.prices.arancel.discount / 100)).toFixed(0);

      for (let index = 0; index < this.installment; index++) {
        const prepaidOptions = [];
        this.prepaids.forEach(prepaid => {
          prepaidOptions.push({
            isTotal: false,
            expiration: `Hasta el ${prepaid.dayTo}/${this.selectedTerm.startMonth + index}`,
            off: prepaid.percentage,
            amount: arancelNeto / this.installment
          });
        });

        generatedPayments.push({
          id: index + 2,
          billingProductId: this.arancelID,
          title: `${this.prices.arancel.title} cuota ${index + 1}/${this.installment}`,
          options: [
            ...prepaidOptions,
            {
              isTotal: false,
              expiration: null,
              off: 0,
              amount: arancelNeto / this.installment
            }
          ]
        });
      }

      const prepaidOptions = [];
      this.prepaids.forEach(prepaid => {
        prepaidOptions.push({
          isTotal: true,
          expiration: `Hasta el ${prepaid.dayTo}/${this.selectedTerm.startMonth}`,
          off: prepaid.percentage,
          amount: parseInt(matriculaNeto) + parseInt(arancelNeto)
        });
      });

      generatedPayments.push({
        id: this.installment + 2,
        billingProductId: null,
        title: 'Total',
        options: [
          ...prepaidOptions,
          {
            isTotal: true,
            expiration: null,
            off: 0,
            amount: parseInt(matriculaNeto) + parseInt(arancelNeto)
          }
        ]
      });

      this.payments = generatedPayments;
    },
    async onEmailSearchClick() {
      // if (!this.emailSearchButton.wasSearched) {
      //   this.emailSearchButton.loading = true;
      //   this.emailSearchButton.wasSearched = true;
      //   try {
      //     const hubspot = await $contact.find(null, null, {params: {email: this.studentDataPrereg.email}})
      //     this.studentDataPrereg.name = hubspot.data.firstName.toUpperCase().trim()
      //     this.studentDataPrereg.cellphone = hubspot.data.phone.replace(/ /g, '')
      //     const gender = this.genderTypes.find(gender => gender.meaning == hubspot.data.gender)
      //     this.studentDataPrereg.genderId = (gender) ? gender.id : null
      //     this.studentDataPrereg.birthDate = (hubspot.data.dateOfBirth && hubspot.data.dateOfBirth != '') ? hubspot.data.dateOfBirth.substr(0, 10) : null
      //     const career = this.careers.find(career => career.id == hubspot.data.careerId)
      //     this.selectedCareer = (career) ? career : null
      //     await this.onCareerChange(career)
      //     if (!this.rolCeo) {
      //       const ceo = this.ceos.find(ceo => ceo.id == hubspot.data.ceoId)
      //       this.selectedCeo = (ceo) ? ceo : null
      //       this.onCeoChange()
      //     }
      //     this.emailSearchButton.wasFounded = true;
      //     this.emailSearchButton.loading = false;
      //   } catch (err) {
      //     this.emailSearchButton.loading = false;
      //     this.emailSearchButton.wasFounded = false;
      //     this.emailSearchButton.wasSearched = true;
      //     setTimeout(() => { this.emailSearchButton.wasSearched = false }, 2000)
      //     throw err
      //   }
      // } else if (this.emailSearchButton.wasSearched) {
      //   this.emailSearchButton.wasSearched = false;
      // }
    },
    async updatePreregister() {
      if (!this.canUpdate) return false;

      this.updateButton.loading = true;
      const studentDataPrereg = JSON.parse(JSON.stringify(this.studentDataPrereg));
      studentDataPrereg.identification = studentDataPrereg.identification.toUpperCase();
      studentDataPrereg.address.cityId = this.studentDataPrereg.address.cityWithState && this.studentDataPrereg.address.cityWithState.idCity ? this.studentDataPrereg.address.cityWithState.idCity : this.studentDataPrereg.address.cityWithState.idRegion;
      studentDataPrereg.termId = this.selectedTerm.id;
      studentDataPrereg.city = this.selectedLocality;    // :TODO - Fix zonas, descomentar linea al subir cambios
      studentDataPrereg.careerId = this.selectedCareer.id;
      studentDataPrereg.studyPlanId = this.selectedStudyPlan.id;
      studentDataPrereg.good_average = this.goodAverage;
      studentDataPrereg.userId = this.userId
      studentDataPrereg.preregistation = [
        {
          id: this.prices.matricula.preregister_id,
          installment: 1,
          installmentAmount: this.prices.matricula.amount * (1 - this.prices.matricula.discount / 100),
          billingProduct: { id: this.matriculaID },
          amount: this.prices.matricula.amount,
          discount: this.discount && this.discount.details.find(detail => detail.billingProductId == this.matriculaID) ? { id: this.discount.id } : null,
          benefit: this.selectedBenefitProduct && this.selectedBenefitProduct.billingProductId == this.matriculaID ? { id: this.selectedBenefitProduct.benefitId } : null,
          scholarship: this.selectedScholarshipProduct && this.selectedScholarshipProduct.billingProductId == this.matriculaID ? { id: this.selectedScholarshipProduct.scholarshipId } : null
        }, {
          id: this.prices.arancel.preregister_id,
          installment: this.installment,
          installmentAmount: (this.prices.arancel.amount * (1 - this.prices.arancel.discount / 100)) / this.installment,
          billingProduct: { id: this.arancelID },
          amount: this.prices.arancel.amount,
          discount: this.discount && this.discount.details.find(detail => detail.billingProductId == this.arancelID) ? { id: this.discount.id } : null,
          benefit: this.selectedBenefitProduct && this.selectedBenefitProduct.billingProductId == this.arancelID ? { id: this.selectedBenefitProduct.benefitId } : null,
          scholarship: this.selectedScholarshipProduct && this.selectedScholarshipProduct.billingProductId == this.arancelID ? { id: this.selectedScholarshipProduct.scholarshipId } : null
        }
      ];

      try {
        await $preRegister.update(this.$route.params.id, studentDataPrereg, {}, 'student');
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.preRegistration.update.message.success');
        this.successMessage.actionPrimary = {text: this.$t('actions.accept'), callback: () => { this.$router.push('/preregistration') }};
      } catch (err) {
        this.successMessage.type = 'error';
        this.successMessage.title = err.codeMeaning && err.codeMeaning != '' ? err.codeMeaning : this.$t('modules.preRegistration.update.message.error');
        this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => {this.updatePreStatus = false} };
      } finally {
        this.updatePreStatus = true;
        this.resetButtonValues();
      }
    },
    resetButtonValues() {
      this.updateButton.loading = false;
      this.updateButton.success = false;
      this.updateButton.error = false;
    }
  }
};
</script>

<style lang="sass" scoped>
.banner
    background-color: #f6da63
    border-radius: 8px

    .text
        flex: 1
        color: #910012
        font-size: 14px
        font-weight: 600
        align-self: center

.inputpadding
    padding-bottom: 0px
    padding-top: 0px

.numero
    width: 73px
    padding-right: 0px
    margin-right: 0px
.depto
    width: 49px
    padding-right: 0px
    margin-right: 0px

.inputw 
    width: 162px

.hidden-block
    visibility: hidden

.no-border
    border: none !important

.payment-container
    min-height: 62px

.border-row
    border-left: 1px solid rgba(0, 0, 0, 0.12)
    height: 100%

.main-amount
    margin-left: 60px


.text-line-through
    text-decoration: line-through !important

.section-grey
    background: #f5f6f9

.img-coin
    width: 70%

.img-scroll
    width: 70%

.switch
    .v-input__slot
        margin-top: 12px !important
        margin-bottom: 0 !important

.StudentPreRegistration
    .v-expansion-panels
        .v-expansion-panel
            margin-bottom: 1em

            &:not(:first-child)::after
                border-top: none !important

            &.no-shadow:before
                box-shadow: none !important

            .v-expansion-panel-header
                border-bottom: 1px solid lightgrey
                min-height: auto
                max-heihgt: auto
                padding: 0.7em 0
                border-radius: 0
            
            .v-expansion-panel-content
                .v-expansion-panel-content__wrap
                    padding: 1.5em 0

                    .benefit-label
                        font-size: 14px
                        text-transform: uppercase
                    
                    .info-container
                        background: #f1f7ff
                        width: 100%
                        padding: 20px 15px

                        &.with-shadow
                            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16)
</style>