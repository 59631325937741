<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.admission.preregistration.create.breadcrumbs.title')"
        :description="$t('modules.admission.preregistration.create.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <div :class="showEmailSummary ? 'd-none' : 'd-flex'">
            <div class="col-8 pl-0 pt-0">
              <v-stepper class="mb-3">
                <v-stepper-header class="stepper-header-height">
                  <v-stepper-step
                    step="1"
                    class="py-4"
                    :complete="!!hubspotId"
                    editable
                    edit-icon="$complete"
                    :color="hubspotId ? 'success' : 'primary'"
                    @click="scrollToSection('leadInfo')"
                  >
                    {{ $t('modules.admission.preregistration.create.stepper.first') }}
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    step="2"
                    class="py-4"
                    :complete="!!selectedBenefit && !!selectedScholarship"
                    editable
                    edit-icon="$complete"
                    :color="selectedBenefit && selectedScholarship ? 'success' : 'primary'"
                    @click="scrollToSection('priceSimulator')"
                  >
                    {{ $t('modules.admission.preregistration.create.stepper.second') }}
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    step="3"
                    class="py-4"
                    :complete="!!dues"
                    editable
                    edit-icon="$complete"
                    :color="!!dues ? 'success' : 'primary'"
                    @click="scrollToSection('paymentMethod')"
                  >
                    {{ $t('modules.admission.preregistration.create.stepper.third') }}
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    step="4"
                    class="py-4"
                    :complete="validStudentData"
                    editable
                    edit-icon="$complete"
                    :color="validStudentData ? 'success' : 'primary'"
                    @click="scrollToSection('preregistrationInfo')"
                  >
                    {{ $t('modules.admission.preregistration.create.stepper.fourth') }}
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
              <div ref="preregistrationScroll" class="preregistration-scroll">
                <LeadInfo
                  ref="leadInfo"
                  :careers="careers"
                  :canCreate="canCreate"
                  @nextStep="enablePriceSimulator"
                  @emailChange="(changedEmail) => { this.email = changedEmail }"
                  @nameChange="(changedName) => { this.name = changedName }"
                  @studyPlanChange="(changedStudyPlan) => { this.studyPlan = changedStudyPlan }"
                  @telephoneChange="(changedTelephone) => { this.telephone = changedTelephone }"
                  @tokenChanged="(changedToken) => { this.benefitToken = changedToken }"
                ></LeadInfo>
                <PriceSimulator
                  ref="priceSimulator"
                  :show="!!prices.length"
                  :prices="prices"
                  :discounts="discounts"
                  :benefits="benefits"
                  :student-token="studentToken"
                  :scholarships="scholarships"
                  :total="total"
                  @averageChange="averageChange"
                  @enabledExtraOff="setTotalDisounts"
                  @selectedPrices="(changedPrice) => { this.selectedPrices = changedPrice }"
                  @setBenefit="(changedBenefit) => { this.selectedBenefit = changedBenefit }"
                  @setReferredFriend="(changedReferredFriend) => { this.referredFriend = changedReferredFriend}"
                  @setScholarship="(changedScholarship) => { this.selectedScholarship = changedScholarship }"
                ></PriceSimulator>
                <!-- <PaymentMethod
                  @enabledExtraOff="setTotalDisounts"
                  ref="paymentMethod"
                  :show="!!prices.length"
                  :total="total"
                  :prepaids="prepaids"
                  @setDue="(changedDue) => { this.dues = changedDue }"
                  @resetDue="() => { this.dues = null }"
                  :careerId="selectedCareer ? selectedCareer.id : null"
                  :studentType="studentType"
                ></PaymentMethod> -->
                <PreregistrationInfo
                  ref="preregistrationInfo"
                  :email="email"
                  :name="name"
                  :careers="careers"
                  :career="selectedCareer"
                  :studyPlan="studyPlan"
                  :city="city"
                  :telephone="telephone"
                  :saleTerms="saleTerms"
                  @setStudentData="(changedStudentData) => { this.preregStudentData =  changedStudentData}"
                  @careerChange="(changedCareer) => { enablePriceSimulator(changedCareer, this.city, this.hubspotId) }"
                  @validationError="createPreregistrationError"
                  @validationSuccess="createPreregistration"
                ></PreregistrationInfo>
                <PaymentMethod
                  ref="paymentMethod"
                  :show="!!prices.length"
                  :total="total"
                  :prepaids="prepaids"
                  @setDue="(changedDue) => { this.dues = changedDue }"
                  @resetDue="() => { this.dues = null }"
                  :careerId="selectedCareer ? selectedCareer.id : null"
                  :studentType="studentType"
                ></PaymentMethod>
                <SimulatePrice
                  ref="simulatePrice"
                  :show="!!prices.length"
                  :payments="payments"
                  :prepaids="prepaids"
                  :dues="dues"
                  :total="total"
                  :discounts="discounts"
                  :benefits="benefits"
                  :scholarships="scholarships"
                  :extraBenefit="extraBenefitPercentage"
                  @enabledExtraOff="enabledExtraOff"
                ></SimulatePrice>
                <div class="d-flex justify-end pa-3">
                  <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="$router.replace('/admission/preregistration')"
                    @end="resetButtonValues"
                    class="mr-4"
                  ></Button>
                  <Button
                    :loading="createButton.loading"
                    :success="createButton.success"
                    :error="createButton.error"
                    :text="isCreated ? $t('actions.create_another') : $t('actions.create')"
                    :successText="$t('actions.created')"
                    :errorText="$t('actions.error')"
                    :disabled="!canCreate"
                    @end="resetButtonValues"
                    @clicked="isCreated ? clearData() : validatePreregistration()"
                  ></Button>
                </div>
              </div>
            </div>
            <Summary
              class="col-4"
              :prepaids="prepaids"
              :dues="dues"
              :total="total"
              :is-created="isCreated"
              :payment-link-button="paymentLinkButton"
              @enabledExtraOff="enabledExtraOff"
              @showEmailSummary="() => { this.showEmailSummary = true }"
              @sendPaymentLink="sendPaymentLink"
            ></Summary>
          </div>
          <EmailSummary
            v-if="showEmailSummary"
            :prepaids="prepaids"
            :dues="dues"
            :total="total"
            :city="city"
            :prereg-student-data="preregStudentData"
            @showForm="showForm"
          ></EmailSummary>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import SimulatePrice from '@/components/SimulatePrice/SimulatePrice';
import LeadInfo from '../Components/LeadInfo';
import PriceSimulator from '../Components/PriceSimulator';
import PaymentMethod from '../Components/PaymentMethod';
import PreregistrationInfo from '../Components/PreregistrationInfo';
import Summary from '../Components/Summary';
import EmailSummary from '../Components/EmailSummary';
import { $preRegister, $billingProps, $discounts, $benefits, $scholarships,
        $terms, $studyPlans, $pagoAutogestionado, $careers, $studentToken, $prepaids} from '../Services';
import moment from 'moment';
import {mapGetters} from 'vuex';


export default {
  name: 'PreregistrationCreate',
  components: {
    Breadcrumbs,
    Button,
    LeadInfo,
    PriceSimulator,
    PaymentMethod,
    PreregistrationInfo,
    Summary,
    EmailSummary,
    SimulatePrice
  },
  data() {
    return {
      careerId: null,
      isCreated: false,
      preregStudentData: null,
      validStudentData: false,
      loading: false,
      saleTerms: [],
      hubspotId: null,
      studentType: 7,
      referredFriendId: 10,
      careers: [],
      selectedCareer: null,
      studyPlan: null,
      city: null,
      telephone: null,
      email: null,
      name: null,
      average: false,
      prices: [],
      selectedPrices: [],
      discounts: [],
      benefits: [],
      benefitToken: null,
      studentToken: null,
      selectedBenefit: null,
      selectedScholarship: null,
      referredFriend: null,
      scholarships: [],
      prepaids: [],
      extraBenefitPercentage: 0,
      dues: null,
      arancelAmount: null,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      showEmailSummary: false,
      paymentLinkButton: {
        loading: false,
        success: false,
        error: false
      },
      payments:[],
    };
  },
  computed: {
    ceoId() {
      if (this.$isTeclab) return 369
      else if (this.$isIPP) return 546
      else return null
    },
    canCreate() {
      return this.$permissions.portaladministrativo.admission.pre_registration.CREATE;
    },
    ...mapGetters({
      userId: 'commons/getUserID',
      userData: 'commons/getUserData'
    }),
    isBianualArancel() {
      return this.total.prices.filter(price => price.id == this.$sisConstants('modules.products.arancel')).length > 1
    },
    total() {
      const total = {
        prices: [],
        savings: [],
        cost: 0,
        saving: 0,
      }
      if (this.selectedPrices.length) {
        this.selectedPrices.forEach(price => {
          total.prices.push({
            key: price.key,
            id: price.id,
            name: price.label,
            amount: price.amount,
            accumulativeDiscount: price.accumulativeDiscount
          })
          total.savings.push({
            key: `Saving ${price.key}`,
            id: price.id,
            name: price.label,
            percentage: 0,
            amount: 0,
            discountId: null,
            benefitId: null,
            scholarshipId: null,
            details: [],
            accumulativeDiscount: price.accumulativeDiscount
          })
          total.cost += parseFloat(price.amount)
        })
      }
      if (this.discounts.length) {
        this.discounts.forEach(discount => {
          const discountId = discount.id
          discount.details.forEach(discount => {
            const [discountProduct] = total.savings.filter(saving => saving.id == discount.billingProduct.id)
            if (discountProduct) {
              const productPrice = total.prices.reduce((acc, price) => {
                if(price.id == discount.billingProduct.id) {
                  acc += price.amount
                }
                return acc
              }, 0)
              if (discountProduct.id == this.$sisConstants('modules.products.matricula') || total.prices.filter(price => price.id == this.$sisConstants('modules.products.arancel')).length > 1) {
                discount.amount = discount.bianualAmount? parseFloat(discount.bianualAmount) : null 
                discount.percentage = discount.bianualPercentage ? parseFloat(discount.bianualPercentage) : null
              } else {
                discount.amount = discount.bimesterAmount ? parseFloat( discount.bimesterAmount) : null
                discount.percentage = discount.bimesterPercentage ? parseFloat(discount.bimesterPercentage) : null
              }
              const rawAmount = discount.amount || productPrice * discount.percentage / 100
              const weightedAmount = discount.amount || ((productPrice - discountProduct.amount) * discount.percentage) / 100
              discountProduct.amount += parseFloat(this.$isTeclab ? rawAmount : weightedAmount)
              discountProduct.percentage += parseFloat(discount.percentage || 0)
              discountProduct.discountId = discountId
              discountProduct.details.push({
                name: `${this.$t('modules.admission.preregistration.create.summary.discount')} - ${discountProduct.name}`,
                type: 'DISCOUNT',
                amount: this.$isTeclab ? rawAmount : weightedAmount,
                percentage: discount.percentage
              })
              total.saving -= parseFloat(this.$isTeclab ? rawAmount : weightedAmount)
            }
          })
        })
      }
      if (this.selectedBenefit) {
        if (this.selectedBenefit.details) {
          const [{percentage: benefitPercentage, amount: benefitAmount, billingProduct}] = this.selectedBenefit.details
          const [benefitProduct] = total.savings.filter(saving => saving.id == billingProduct.id)
          if (benefitProduct) {
            const productPrice = total.prices.reduce((acc, price) => {
              if(price.id == billingProduct.id) {
                acc += price.amount
              }
              return acc
            }, 0)
            const rawAmount = benefitAmount || benefitPercentage >= 100 ? ((productPrice - benefitProduct.amount) * benefitPercentage) / 100 : productPrice * benefitPercentage / 100
            const weightedAmount = benefitAmount || ((productPrice - benefitProduct.amount) * benefitPercentage) / 100
            benefitProduct.percentage += parseFloat(benefitPercentage || 0)
            benefitProduct.amount += parseFloat(this.$isTeclab ? rawAmount : weightedAmount)
            benefitProduct.benefitId = this.selectedBenefit.id
            benefitProduct.details.push({
              name: `${this.selectedBenefit.description} - ${benefitProduct.name}`,
              type: 'BENEFIT',
              percentage: benefitPercentage,
              amount: this.$isTeclab ? rawAmount : weightedAmount
            })
            total.saving -= parseFloat(this.$isTeclab ? rawAmount : weightedAmount)
          }
        }
      }
      if (this.referredFriend) {
        if (total.savings.length != total.prices.length) total.savings.pop()
        if (total.prices.length) {
          total.savings.push({
            key: `${this.referredFriend.code}${this.referredFriend.id}`,
            name: this.referredFriend.type.meaning,
            percentage: null,
            amount: this.referredFriend.benefitReferredAmount,
            benefitId: this.referredFriend.id,
            referredFriend: true
          })
          total.saving -= parseFloat(this.referredFriend.benefitReferredAmount)
        }
      }
      if (this.selectedScholarship) {
        const scholarshipId = this.selectedScholarship.id
        this.selectedScholarship.scholarshipProduct.forEach(scholarship => {
          const [scholarshipProduct] = total.savings.filter(saving => saving.id == scholarship.billingProduct.id)
          if (scholarshipProduct) {
            const productPrice = total.prices.reduce((acc, price) => {
              if(price.id == scholarship.billingProduct.id) {
                acc += price.amount
              }
              return acc
            }, 0)
            //TODO: add raw and weighted amount
            scholarship.amount = scholarship.originalAmount || (productPrice * scholarship.percentage) / 100
            scholarship.percentage = scholarship.originalPercentage || ((scholarship.amount * 100) / productPrice).toFixed(2)
            scholarshipProduct.amount += parseFloat(scholarship.amount)
            scholarshipProduct.percentage += parseFloat(scholarship.percentage)
            scholarshipProduct.scholarshipId = scholarshipId
            scholarshipProduct.details.push({
              name: `${this.selectedScholarship.description} - ${scholarshipProduct.name}`,
              type: 'SCHOLARSHIP',
              percentage: scholarship.percentage,
              amount: scholarship.amount
            })
            total.saving -= parseFloat(scholarship.amount)
          }
        })
      }
      total.savings.map(saving => {
        const [price] = total.prices.filter(price => price.id == saving.id)
        if (saving.percentage > 100) {
          const priceAmount = (saving.id == this.$sisConstants('modules.products.arancel') && total.prices.filter(price => price.id == this.$sisConstants('modules.products.arancel')).length > 1) ? price.amount * 2 : price.amount
          saving.percentage = 100
          total.saving += saving.amount - priceAmount
          saving.amount = priceAmount
        }
        return saving
      })
      return total
    }
  },
  async mounted() {
    this.getSaleTerms()
    await this.getCareers()
  },
  methods: {
    async getBenefitExtra(){
      const {data} = await $benefits.find('extra', null, null)
      this.extraBenefitPercentage = data.length?? data 
    },

    setTotalDisounts(){
      const paymentsWhitDiscounts = this.payments.map((payment) => {
        return{...payment, options: payment.options.map((prepaid) =>{
          return{...prepaid, off: this.total.savings.find((item) => item.id == payment.billingProductId).percentage}})
        }
      })
      this.payments = paymentsWhitDiscounts
      if (this.payments.some((item) => item.accumulativeDiscount == false)) this.enabledExtraOff(false)
    },
    enabledExtraOff(extraOff){
      let payments = []
      let prepaids = []

      if(this.payments.some((item) => item.accumulativeDiscount == true)){
        if(extraOff){
          payments = this.payments.map((payment) => {
            return {...payment , options: payment.options.map((prepaid) => {
              return {...prepaid, off: prepaid.off + this.extraBenefitPercentage}}) }
          })
          prepaids = this.prepaids.map((prepaid) => {return {...prepaid, percentage: prepaid.percentage + this.extraBenefitPercentage}})
        }else{
          payments = this.payments.map((payment) => {
            return {...payment , options: payment.options.map((prepaid) => {
              return {...prepaid, off: prepaid.off - this.extraBenefitPercentage}}) }
          })
          prepaids = this.prepaids.map((prepaid) => {return {...prepaid, percentage: prepaid.percentage - this.extraBenefitPercentage}})
        }
      }else{
        if(extraOff){
          payments = this.payments.map((payment) => {
            return {...payment , options: payment.options.map((prepaid) => {
              const disc = payment.amount - (payment.amount * prepaid.off / 100)
              const pr = disc - (disc * prepaid.percentage/ 100)
              const totalOffWithExtraBenefit = pr - (pr * this.extraBenefitPercentage / 100)
              return {...prepaid, totalOff: this.$options.filters.$roundNumber(100 - (totalOffWithExtraBenefit * 100 / payment.amount))}})}
          })
          prepaids = payments[1].options
        }else{
          payments = this.payments.map((payment) => {
            return {...payment , options: payment.options.map((prepaid) => {
              const disc = payment.amount - (payment.amount * prepaid.off / 100)
              const totalOff = disc - (disc * prepaid.percentage/ 100)
              return {...prepaid, totalOff: this.$options.filters.$roundNumber(100 - (totalOff * 100 / payment.amount))}})}
          })
          prepaids = payments[1].options
        }
       
      }

      this.payments = payments
      this.prepaids = prepaids

    },

    scrollToSection(refSection) {
      const container = this.$refs.preregistrationScroll
      container.scrollTop = this.$refs[refSection].$el.offsetTop - container.offsetTop
    },
    async getSaleTerms() {
      try {
        const {data} = await $terms.find('current-sales')
        this.saleTerms = data.content.sort((a, b) => (a.classStartDate > b.classStartDate) ? 1 : -1)
      } catch (err) {
        this.saleTerms = []
        throw err
      }
    },
    async getCareers() {
      try {
        const {data} = await $careers.find(null, null, { params: { page: 0, length: 500, status: true } })
        this.careers = data.content
      } catch (err) {
        this.careers = []
        throw err
      }
    },
    async getPrepaids() {
      try {
        // with cityId
        /*const {data: prepaids} = await $prepaids.find(null, null, { params: { termId: this.preregStudentData.selectedSaleTerm.id, careerId: this.selectedCareer.id, cityId: this.city.idCity || this.city.idRegion, studentType: this.studentType, average: this.average }})*/
        // with ceoId
        const params = {
          termId: this.preregStudentData.selectedSaleTerm.id, 
          careerId: this.selectedCareer.id, 
          cityId: this.city.idCity, 
          studentType: 7, 
          average: false
        }
        const {data} = await $prepaids.find(null, null, {params})
        
        this.prepaids = data.map((pr) => {return{...pr, percentage: Number(pr.percentage)}}).sort((a, b) => (a.dayFrom > b.dayFrom) ? 1 : -1)
        this.prepaids.push({percentage: 0})
      } catch (err) {
        this.prepaids = []
        throw err
      }
    },
    async fetchPrices() {
      await this.getBenefitExtra()
      await this.getPrepaids()
      if (this.selectedCareer && this.city && !this.loading) {
        this.loading = true
        try {
          const {data: prices} = await $billingProps.findPriceByCarrerAndCity(`/products-with-price?careerId=${this.selectedCareer.id}&cityId=${this.city.idCity || this.city.idRegion}&studentType=${this.studentType}`)
          
          // const {data} = await $productsWithPricePeriods.find(null, null, {params})
          // if (data.products.some(product => product.idProduct !== 80) || data.prepaids.length > 0) {
          //     this.productWithPricePrepaids = data.prepaids
          //     this.productWithPricePrepaids.sort((a, b) => (a.dayFrom > b.dayFrom) ? 1 : -1)
          //   }
          const mes = moment().month() + 1
          const pr = this.prepaids.map((item) => { 
            return { ...item,
              isTotal: true,
              expiration: item.dayTo ? `Hasta el ${item.dayTo}/${mes}`: '',
              off: item.percentage,
              percentage: item.percentage
            }
          })
          prices.forEach((item, index) => {
            this.payments.push({...item, id: index,billingProductId: item.id, title: `${(index > 0 ? `${item.name} ${index}` : item.name )}`, options: pr})
          })
          this.prices = prices.map((price, index) => {
            return {
              id: price.id,
              key: `${price.name}${index}`,
              label: price.name,
              amount: price.amount,
              selected: true,
              accumulativeDiscount: price.accumulativeDiscount
            }
          })
          this.selectedPrices = this.prices

          if (this.$isIPP) {
            this.getPrepaids()
          }

          try {
            const {data: discounts} = await $discounts.find('date', null, {
              params: {
                cityId: this.city.idCity || this.city.idRegion,
                idCareer: this.selectedCareer.id,
                studentType: this.studentType
              }
            });
            // with ceoId
            // const {data: discounts} = await $discounts.find('date', null, {params: { idCeo: (!this.$isTeclab ? 546 : 1), idCareer: this.selectedCareer.id, studentType: this.studentType }});
            this.discounts = discounts.map(discount => {
              discount.details = discount.details.reduce((acc, product) => {
                const discountPrice = prices.filter(price => price.id == product.billingProduct.id)
                if (discountPrice.length) {
                  product.amount = null
                  product.percentage = null
                  acc.push(product)
                }
                return acc
              }, [])
              discount.to = moment().locale('es').format('D [de] MMMM [del] YYYY')
              discount.details.sort((a, b) => (a.name > b.name) ? 1 : -1)
              return discount
            })
            if(this.discounts.length){
              let payments = []
              if(this.isBianualArancel && this.discounts[0].details[0].bianualPercentage){
                payments = this.payments.map((item) => {
                  if(item.billingProductId == this.discounts[0].details[0].billingProduct.id){
                    return {...item , options: item.options.map((pr) => {return {...pr, off: pr.off + parseFloat(this.discounts[0].details[0].bianualPercentage)}})}
                  }else{
                    return {...item}
                  }
                })
              }
              if(this.isBianualArancel && this.discounts[0].details[0].bianualAmount){
                payments = this.payments.map((item) => {
                  if(item.billingProductId == this.discounts[0].details[0].billingProduct.id){
                    return {...item , amount:  item.amount - parseFloat(this.discounts[0].details[0].bianualAmount) }
                  }else{
                    return {...item}
                  }
                })
              }
              this.payments = payments
            }
          } catch {
            this.discounts = []
          }
          try {
            const {data: benefits} = await $benefits.find('date')
            if (this.benefitToken) {
              try {
                const {data: dataToken} = await $studentToken.find(null, null, {params: {token: this.benefitToken}})
                this.studentToken = dataToken
              } catch {
                this.studentToken = null
              }
            } else {
              this.studentToken = null
            }
            this.benefits = benefits.filter(benefit => {
              if (benefit.details) {
                benefit.details = benefit.details.reduce((acc, product) => {
                  const benefitPrice = prices.filter(price => price.id == product.billingProduct.id)
                  if (benefitPrice.length) acc.push(product)
                  return acc
                }, [])
                return !!benefit.details.length
              } else {
                if (this.studentToken) {
                  if (benefits.find(benefit => benefit.id == this.studentToken.id && benefit.type.value == this.referredFriendId)) {
                    return true
                  }
                }
                return false
              }
            })
            // if(this.benefits.length){
            //   let payments = []
            //   if(this.isBianualArancel && this.benefits[0].details[0].bianualPercentage){
            //     payments = this.payments.map((item) => {
            //       if(item.billingProductId == this.discounts[0].details[0].billingProduct.id){
            //         return {...item , options: item.options.map((pr) => {return {...pr, off: pr.off + parseFloat(this.discounts[0].details[0].bianualPercentage)}})}
            //       }else{
            //         return {...item}
            //       }
            //     })
            //   }
            //   if(this.isBianualArancel && this.discounts[0].details[0].bianualAmount){
            //     payments = this.payments.map((item) => {
            //       if(item.billingProductId == this.discounts[0].details[0].billingProduct.id){
            //         return {...item , amount:  item.amount - parseFloat(this.discounts[0].details[0].bianualAmount) }
            //       }else{
            //         return {...item}
            //       }
            //     })
            //   }
            //   this.payments = payments
            // }
          } catch {
            this.benefits = []
            this.benefitToken = null
          }
          try {
            const {data: scholarships} = await $scholarships.find(null, null, { params: { page: 0, length: 500 , preRegistration: true} })
            this.scholarships = scholarships.content.filter(scholarship => {
              scholarship.scholarshipProduct = scholarship.scholarshipProduct.reduce((acc, product) => {
                const priceAmount = prices.reduce((acc, price) => {
                  if(price.id == product.billingProduct.id) {
                    acc += price.amount
                  }
                  return acc
                }, 0)
                if (priceAmount) {
                  product.originalAmount = product.amount
                  product.originalPercentage = product.percentage
                  acc.push(product)
                }
                return acc
              }, [])
              return !!scholarship.scholarshipProduct.length
            })
          } catch {
            this.scholarships = []
          }

        } catch (error) {
          this.prices = []
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('alert'),
            content: [{ value: this.$t('modules.admission.preregistration.create.errorMessage.withoutPrice') }],
            actionPrimary: { text: this.$t('actions.accept'), callback() {} },
            icon: { color: 'warning', name: 'mdi-alert' },
            color: 'primary',
          });
          throw error
        } finally {
          this.loading = false
          this.setTotalDisounts()
        }
      }
    },
    enablePriceSimulator(career, city, hubspotId) {
      this.hubspotId = hubspotId
      this.selectedCareer = career
      this.city = city
      this.prices = []
      this.selectedPrices = []
      if (career && city && hubspotId) this.fetchPrices()
    },
    averageChange(average) {
      this.average = average
      this.getPrepaids()
    },
    showForm() {
      this.showEmailSummary = false
      this.$el.scrollIntoView();
    },
    async sendPaymentLink() {
      try {
        this.paymentLinkButton.loading = true
        const { name, lastname } = this.userData;
        const body = {
          email: this.preregStudentData.email,
          name: this.preregStudentData.name,
          identification: this.preregStudentData.identification,
          adviser: this.$options.filters.$capitalize(`${lastname}, ${name}`)
        }
        await $pagoAutogestionado.create(body)
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.create.successMessage.paymentLink'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {}
          },
          icon: {color: 'success', name: 'mdi-check'},
          color: 'primary',
        });
        this.paymentLinkButton.success = true
      } catch (err) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.create.errorMessage.paymentLink'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {}
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        this.paymentLinkButton.error = true
        throw err
      } finally {
        this.paymentLinkButton.loading = false
        this.paymentLinkButton.error = false
        this.paymentLinkButton.success = false
      }
    },
    validatePreregistration() {
      this.createButton.loading = true
      this.$refs.preregistrationInfo.validateStudentData()
    },
    async createPreregistration() {
      this.validStudentData = true
      try {
        if (this.isBianualArancel) {
          const firstArancel = this.total.savings.find(saving => saving.id == this.$sisConstants('modules.products.arancel') && saving.amount)
          const secondArancel = this.total.savings.find(saving => saving.id == this.$sisConstants('modules.products.arancel') && !saving.amount)
           if (firstArancel) {
            secondArancel.discountId = firstArancel.discountId
            secondArancel.benefitId = firstArancel.benefitId
            secondArancel.scholarshipId = firstArancel.scholarshipId
            secondArancel.details = firstArancel.details
            this.arancelAmount = firstArancel.amount
          }
        }
        const {preregStudentData} = this
        if (this.$isTeclab) {
          try {
            const {data} = await $studyPlans.find(null, null, { params: { careerId: preregStudentData.selectedCareer.id, filterByStatus: true } })
            preregStudentData.selectedStudyPlan = {
              value: data.content.pop().id
            }
          } catch {
            preregStudentData.selectedStudyPlan = null
          }
        }
        const newPreregistration = {
          hubspotId: this.hubspotId === true ? null : this.hubspotId,
          name: preregStudentData.name,
          lastname: preregStudentData.lastname,
          birthDate: preregStudentData.birthDate,
          birthPlace: preregStudentData.birthPlace,
          nationality: preregStudentData.selectedNationality,
          maritalStatus: preregStudentData.selectedMaritalStatus,
          genderId: preregStudentData.selectedGender,
          identificationType: preregStudentData.selectedIdentificationType,
          identification: preregStudentData.identification.toLowerCase(),
          serialNumber: preregStudentData.serialNumber,
          email: preregStudentData.email.toLowerCase(),
          cellphone: preregStudentData.telephone,
          phone: null,
          address: {
            street: preregStudentData.address,
            number: preregStudentData.number,
            floor: preregStudentData.floor,
            apartment: preregStudentData.apartment,
            zipCode: preregStudentData.zipCode,
            cityWithState: preregStudentData.city,
            cityId: preregStudentData.city.idCity,
          },
          institution: preregStudentData.selectedInstitution,
          levelType: preregStudentData.selectedStudentLevel,
          userId: this.userId,
          termId: preregStudentData.selectedSaleTerm.id,
          cityId: this.city?.idCity || null,
          ceoId: this.ceoId,
          careerId: preregStudentData.selectedCareer.id,
          studyPlanId: preregStudentData.selectedStudyPlan?.value || null,
          good_average: this.average,
          benefitToken: this.benefitToken,
          preregistation: this.total.savings.length ? this.total.savings.map(saving => {
            if (saving?.referredFriend) {
              return {
                installment: null,
                installmentAmount: null,
                billingProduct: null,
                amount: null,
                discount: null,
                benefit: {
                  id: saving.benefitId,
                  referred: true,
                  community: null,
                  credits: saving.amount
                },
                scholarship: null,
              }
            } else {
              const [price] = this.total.prices.filter(price => price.id == saving.id)
              const savingAmount = saving.id == this.$sisConstants('modules.products.arancel') && this.isBianualArancel ? (this.arancelAmount / 2) : saving.amount
              const amount = price.amount - savingAmount
              return {
                installment: this.dues ? (this.dues?.value || this.dues.due.installments) : 1,
                installmentAmount: this.dues ? parseFloat((amount / (this.dues?.value || this.dues.due.installments)).toFixed(2)) : parseFloat(amount.toFixed(2)),
                billingProduct:
                  {
                    id: saving.id
                  },
                amount: price.amount,
                discount: saving.discountId ? {id: saving.discountId} : null,
                benefit: saving.benefitId ? {
                  id: saving.benefitId,
                  referred: false,
                  community: {id: this.selectedBenefit?.community} || null,
                  credits: saving.details.find(detail => detail.type == 'BENEFIT')?.amount / (saving.id == this.$sisConstants('modules.products.arancel') &&  this.isBianualArancel ? 2 : 1) || 0
                } : null,
                scholarship: saving.scholarshipId ? {id: saving.scholarshipId} : null,
              }
            }
          }) : null
        }
        await $preRegister.create(newPreregistration);
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.create.successMessage.preregistration'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {}
          },
          icon: { color: 'success', name: 'mdi-check' },
          color: 'primary',
        });
        this.createButton.success = true;
        this.createButton.loading = false;
        this.isCreated = true;
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.create.errorMessage.preregistration'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {}
          },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        this.createButton.loading = false;
        this.createButton.error = true;
        throw error;
      }
    },
    createPreregistrationError() {
      this.createButton.error = true;
      this.createButton.loading = false;
    },
    clearData() {
      this.$router.go(0)
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
  }
};
</script>

<style lang="sass" scoped>

.stepper-header-height
  height: auto

.preregistration-scroll
  overflow-y: auto
  overflow: -moz-scrollbars-none
  -ms-overflow-style: none
  height: 100vh
  scroll-behavior: smooth

.preregistration-scroll::-webkit-scrollbar
  width: 0 !important


</style>
