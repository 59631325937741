<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <!-- <AsideBar :items="asidebar.items" :title="asidebar.title" ></AsideBar> -->
      <v-container>
        <BreadcrumbsVue :title="$t('modules.pricing.billing.billingTable.breadcrumbs.title')">
        <div class="d-flex justify-end">
            <Button
                class="mr-4"
                :icon="'mdi-download'"
                :text="$t('actions.load')"
                @clicked="tmpDrawerOpen"
                depressed
                outlined
            ></Button>
        </div>
        </BreadcrumbsVue>
        <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
                <SuperTableVue   
                :permissions="{create: canCreate, update: canUpdate, delete: canDelete}"
                :headers="headers"
                :data="billings"
                @updateData="generateNote"
                @searchAction="searchAction"
                @bulk-delete="bulkGenerate"
                @deleteData="deletePopUp"
                @cancelNote="openPopup"
                @clientResend="clientResend"
                @receiptResend="receiptResend"
                :bulkTitle="bulkTitle"
                :page-count="pagination.pagesAmount"
                :page="pagination.page"
                />

                <ReasonCancelPopup
                    :modalOpen="paymentPopupOpen"
                    @close="() => {this.paymentPopupOpen = false}"
                    :selectedNoteCredit="selectedNC" 
                    @fetchReceipts="() => {this.fetchReceits(this.search)}"  
                />
            </v-col>
        </v-row>
        <TemporaryRightDrawer
            @tmpDrawerOpen="tmpDrawerOpen"
            :open="tmpDrawer.open"
            :title="tmpDrawer.title"
            :description="tmpDrawer.description"
        >
          <MassImportReceiptSap
            @close="tmpDrawer.open = false"
          ></MassImportReceiptSap>
        </TemporaryRightDrawer>
      </v-container>
    </v-container>
</template>

<script>
import SuperTableVue from '../Components/SuperTable/SuperTable'
import BreadcrumbsVue from '@/components/Breadcrumbs/Breadcrumbs'
import ReasonCancelPopup from '../Components/ReasonCancelPopup/ReasonCancelPopup';
import { $receipt } from '../Services'
import Button from '@/components/Button/Button';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import MassImportReceiptSap from '../Components/MassImportReceiptSap/MassImportReceiptSap';

    export default {
        name: 'BillingTable',
        components:{
            SuperTableVue,
            BreadcrumbsVue,
            TemporaryRightDrawer,
            MassImportReceiptSap,
            Button,
            ReasonCancelPopup,
        },
        data() {
            return {
            tmpDrawer: {
                open: false,
                title: this.$t('modules.pricing.billing.billingTable.massImportDrawer.breadcrumbs.title'),
                description: this.$t('modules.pricing.billing.billingTable.massImportDrawer.breadcrumbs.description')
            },
            billings: [],
              headers : [
                    {
                        text: this.$t('modules.pricing.billing.billingTable.headers.tipo'),
                        value: 'receiptType'
                    }, 
                    {
                        text: this.$t('modules.pricing.billing.billingTable.headers.number'),
                        value: 'receiptNumber'
                    },   
                    {
                        text: this.$t('modules.pricing.billing.billingTable.headers.dni'),
                        value: 'identification'
                    }, 
                    {
                        text: this.$t('modules.pricing.billing.billingTable.headers.date'),
                        value: 'receiptDetail.date'
                    }, 
                    {
                        text: this.$t('modules.pricing.billing.billingTable.headers.amount'),
                        value: 'amount'
                    },
                    {
                        text: '',
                        value: 'action',
                        sortable: false,
                    },
              ],
            search: '',
            pagination: {
                limit: 20,
                page: 1,
                pagesAmount: 1
            },
            page: 1,
            paymentPopupOpen: false,
            selectedNC: null
            
        }
      },
      computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.prices.billing.CREATE
            },
            canUpdate() {
                return this.$permissions.portaladministrativo.prices.billing.UPDATE
            },
            canDelete() {
                return this.$permissions.portaladministrativo.prices.billing.DELETE
            },
            bulkTitle(){
                return 'Generar Comprobantes'
            },
            isReceipt(value){
                return !value.toLowerCase().includes('recibo')
            },
            isMex(){
                return this.$isMexico
            }
        },
        mounted(){
            this.headerFetch()
        },
        methods: {
            headerFetch(){
                const headerMex = {
                    text: this.$isMexico? 'Estado' : 'NLL',
                    value: this.$isMexico ? 'receiptStatus': 'concept' 
                }
                this.headers.splice(2,0,headerMex);
            }, 
            tmpDrawerOpen(){
                this.tmpDrawer.open = !this.tmpDrawer.open;
            },
            generateNote(billing) {
                this.$router.push( { name: 'billing-generate',  params:{id:billing.receiptNumber ,listReceipts:[billing], identification:this.search,generic: billing.generic}  }) 
            },
            async clientResend(item) {
                try {
                    await $receipt.findById(`${item.receiptId}/client-resend`)
                     this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.pricing.billing.billingTable.errorMessage.successSentClient'),
                        actionPrimary: {
                            text: this.$t('actions.close'), callback() {}
                        },
                        icon: {color: 'success', name: 'mdi-check'},
                        color: 'primary',
                    });
                    this.fetchReceits(this.search)
                } catch (error) {
                     this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.pricing.billing.billingTable.errorMessage.errorSentClient'),
                        actionPrimary: {
                            text: this.$t('actions.close'), callback() {}
                        },
                        icon: {color: 'error', name: 'mdi-close'},
                        color: 'primary',
                    });         
                }
            },
            openPopup(item) {
                this.selectedNC = item
                this.paymentPopupOpen = true
            },
            async receiptResend(item) {
                try {
                    await $receipt.findById(`${item.receiptId}/resend`)
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.pricing.billing.billingTable.errorMessage.successSentReceipt'),
                        actionPrimary: {
                            text: this.$t('actions.close'), callback() {}
                        },
                        icon: {color: 'success', name: 'mdi-check'},
                        color: 'primary',
                    });  
                    this.fetchReceits(this.search)
                } catch (error) {
                     this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.pricing.billing.billingTable.errorMessage.errorSentReceipt'),
                        actionPrimary: {
                            text: this.$t('actions.close'), callback() {}
                        },
                        icon: {color: 'error', name: 'mdi-close'},
                        color: 'primary',
                    });         
                }
            },
            async fetchReceits( value = '' ){
                try {
                    const dic = {
                        203:'Pendiente de envio', 
                        204:'Esperando CAE', 
                        205:'Generada',
                        206:'Error', 
                        360:'Enviado', 
                        440:'Cancelada'
                    }
                    const resp = await $receipt.find(`payment-history?identification=${value}`) 
                    const map = resp.data.receipts.map( receip => {
                        return {
                            ...receip,
                            identification: value,
                            receiptStatus: dic[receip.receiptStatus]
                        }
                    })
                    this.billings = map
                    this.paymentPopupOpen = false
                } catch  {
                    this.billings = []
                }
            },
            searchAction(value){
                this.search = value
                if(value != '') this.fetchReceits(value)
            },
            bulkGenerate(value){
                const list = value.filter( item => item.receiptType.toLowerCase().includes('recibo'))
                  this.$router.push( { name: 'billing-generate',  params:{ listReceipts:list, identification:this.search}  }) 
            },
            deletePopUp(item) {
                const receipt = this.billings.find(receipt => receipt.receiptId == item.receiptId);
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    content: [{value: receipt.concept}],
                    actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteReceipt(item)},
                    actionSecondary: {
                    text: this.$t('actions.cancel'), callback() {
                    }
                    },
                    icon: {color: 'error', name: 'mdi-delete'},
                    color: 'primary',
                });
            },
            
            async deleteReceipt(receipt){
                try {
                    await $receipt.delete(`receipts?id=${receipt.receiptId}&receiptType=${receipt.receiptType}`)
                    this.fetchReceits(this.search)
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.pricing.billing.billingTable.errorMessage.deleteReceipt'),
                    actionPrimary: {
                        text: this.$t('actions.close'), callback() {}
                    },
                    icon: {color: 'error', name: 'mdi-close'},
                    color: 'primary',
                    });         
                }
            },
            
        },
    }
    
</script>

<style lang="scss" scoped>

</style>