<template>
    <v-container fluid class="superTable pa-0">
        <v-row no-gutters>
            <v-col sm="4">
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="$t('actions.search')"
                    outlined
                    clearable
                    class="superTable__search"
                    @keyup="keyup"
                    @keyup.esc="search = ''"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    :show-select="false"
                    :headers="headers"
                    :items="data"
                    :search="search"
                    hide-default-footer
                    item-key="id"
                    class="superTable__table subjectsStudyPlanTable"
                    style="table-layout: fixed"
                >
                <template v-slot:[`item.action`]="{ item }">
            <v-menu bottom left v-if="item.currentPlanCode !== item.updatedPlanCode">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                </template>
                    <v-list class="pa-0">
                        <v-list-item  @click="changeStudyPlan(item.legajo)">
                            <v-list-item-title>
                                <v-icon>mdi-pencil</v-icon> {{ $t('modules.changeStudyPlan.table.actions.changeStudyPlan')}}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </v-menu>
                </template>
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'SuperTable',
        props: {
            data: Array,
            headers: Array,
            permissions: {
                create: false,
            },
        },
        data() {
            return {
                search: '',
                timeout: null,
                page: 1,
                itemsPerPage: 20,
                itemsPerPageAvailable: [20, 50, 100, 200],
            }
        },
        methods: {
            keyup () {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.searchAction()
                    clearTimeout(this.timeout);
                },1000)
            },
            searchAction() {
                this.$emit('searchAction', this.search)
            },
            changeStudyPlan(legajo){
                this.$emit('changeStudyPlan', legajo)
            },
        },
    };
</script>

<style lang="sass">
    .subjectsStudyPlanTable table tbody tr
        cursor: pointer
</style>