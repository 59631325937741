<template>
    <div   >
        <v-card class="modal">
            
            <v-card>
                <v-btn small class="btn-close px-6 mx-4"  @click="$emit('close')">
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
                <template>
                    <slot></slot>
                </template>

            </v-card>
            
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props:{
        loading: Boolean
    }

}
</script>


<style lang="sass" scoped>
.btn-close 
    float: right
    background-color: rgba(0, 0, 0, 0.5) 
    position: absolute
    top: 10px
    right: 10px
    cursor: pointer
.modal
    margin: auto
    padding: 20px
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(0, 0, 0, 0.5)
    z-index: 10
    text-align: center
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)
    border-radius: 10px
</style>