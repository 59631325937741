const periodsTranslations = {
  es_PE: {
      list: {
          closePracticalSubject: 'Cerrar cursos prácticos',
          closePracticalBianual: 'Cerrar cursos semestrales'
      },
      create: {
          cards: {
              unsuscribe: 'Desinscripción a cursos',
          }
      },
      update: {
          cards: {
              unsuscribe: 'Desinscripción a cursos',
          }
      },
      closePracticalSubject: {
          title: 'Estás por cerrar los cursos prácticos del periodo {periodo}',
          error_message: {
              title: 'No se pudieron cerras los cursos practicas del periodo {periodo}'
          }
      },
      closeBianualSubject: {
          title: 'Estás por cerrar los cursos semestrales del periodo {periodo}',
          error_message: {
              title: 'No se pudieron cerras los cursos semestrales del periodo {periodo}'
          }
      }
  },
  es_MX: {
    list: {
        closePracticalSubject: 'Cerrar materias prácticas',
        closePracticalBianual: 'Cerrar materias semestrales'
    },
    create: {
        cards: {
            unsuscribe: 'Desinscripción a materias',
        }
    },
    update: {
        cards: {
            unsuscribe: 'Desinscripción a materias',
        }
    },
    closePracticalSubject: {
        title: 'Estás por cerrar las materias prácticas del periodo {periodo}',
        error_message: {
            title: 'No se pudieron cerras las materias practicas del periodo {periodo}'
        }
    },
    closeBianualSubject: {
        title: 'Estás por cerrar las materias semestrales del periodo {periodo}',
        error_message: {
            title: 'No se pudieron cerras las materias semestrales del periodo {periodo}'
        }
    }
},  
  es: {
    loading: false,
    clearSelected: '',
    list: {
      breadcrumbs: {
        title: 'Períodos',
        description: 'Administración de períodos.',
        button: {
          name: 'Nuevo',
          icon: 'mdi-plus',
          color: 'primary'
        }
        },
      error_message: {
        delete: 'No se puede eliminar el período',
        bulk_delete: 'Los períodos seleccionados no pudieron eliminarse',
      },
      no_filter: 'Sin filtro',
      search: 'Buscar',
      code: 'Código',
      name: 'Nombre',
      status: 'Estado',
      start: 'Inicia',
      end: 'Finaliza',
      edit: 'Editar',
      closePracticalSubject: 'Cerrar materias practicas',
      closePracticalBianual: 'Cerrar materias semestrales',
      period: 'Periodo',
      delete: 'Eliminar',
      bulkDelete: 'Eliminar período',
      page: 'Ir a la página',
    },
    create: {
      transferList: {
        firstTitle: 'Disponibles',
        secondTitle: 'Seleccionados'
      },
      warning : {
        no_selected_warning : 'Debes seleccionar al menos una documentación',
        no_selected_product : 'Debes seleccionar al menos un producto',
        no_add_product : 'Debes agregar al menos un producto',
      },
      errors: {
        codeError: 'El código ingresado es incorrecto, intente nuevamente',
        required: 'El campo es obligatorio',
        datePair: 'Fecha inválida',
        minLength: 'Debe contener al menos 6 caracteres',
        codeUnique: 'Código ya existente',
        requiredTransferList: 'Debes seleccionar al menos una carrera',
      },
      buttons: {
        create: 'Crear',
        cancel: 'Cancelar',
        back: 'Volver',
      },
      success_message: {
        title: 'Período creado correctamente',
        actions: {
          primary_text: 'Cerrar',
          secondary_text: 'Crear otro',
        },
      },
      warning_popup: {
        title: 'Estás creando un período deshabilitado',
        content: '',
      },
      breadcrumbs: {
        title: 'Nuevo período',
        description: 'Crea un nuevo período',
        button: {
          name: 'Nuevo',
          icon: 'mdi-plus',
          color: 'primary',
          cancel: 'Cancelar',
          create: 'Crear',
        }
        },
        cards: {
          switch: {
            enable: 'Habilitado',
            disable: 'Deshabilitado'
          },
          title: 'Datos del período',
          main_subtitle: 'Agrega la información básica del período y su código, junto con una breve descripción que permita tener datos relevantes del mismo.',
          name: 'Nombre',
          code: 'Código',
          date: 'Fecha de cursado',
          raic:'Multa Raic',
          preSale: 'Pre venta',
          from: 'Desde',
          to: 'Hasta',
          enable: 'Habilitaciones',
          subtitle: 'Selecciona las habilitaciones que tendrá el período y determina el inicio/finalización de cada una. Ten en cuenta que el "fin" del período de venta siempre será 15 días después del comienzo del cursado de clases.',
          incomingsale: 'Venta Ingresante',
          resale: 'Venta Rematriculados',
          application: 'Aplicación de beca',
          refund: 'Reembolso sin motivo',
          refund2: 'Reembolso fuerza mayor',
          refund3: 'Devolución rematriculados',
          unsuscribe: 'Desinscripción a materias',
          suscribe: 'Inscripción a materias',
          pases: 'Cambios',
          cathedra: 'Secciones',
          modality: 'Modalidad',
          subtitle2: 'Selecciona las modalidades que se asociarán al período.',
          distance: 'A distancia',
          onsite: 'Prescencial',
          semi: 'Semiprescencial',
          product: 'Producto de facturación',
          subtitle3: 'Esta sección le permitirá asociar productos de facturación al período y estrán habilitados durante el ciclo del mismo.',
          period:'Periodo',
          subtitle4: 'Selecciona un periodo.',
        },
      error_message: {
        title: 'El período no pudo crearse',
        exists: 'Ya existe un período con esa modalidad y fecha',
      },
    },
    update: {
      warning : {
        no_selected_warning : 'Debes seleccionar al menos una documentación',
        no_selected_product : 'Debes seleccionar al menos un producto',
        no_add_product : 'Debes agregar al menos un producto',
      },
      errors: {
        invalidCognitoCode: 'El código es inválido, ingréselo nuevamente',
        invalidCode: 'El código es inválido',
        required: 'El campo es obligatorio',
        datePair: 'Fecha inválida',
        maxLength: 'El código debe ser de 6 caracteres',
        minLength: 'Debe contener al menos 6 caracteres',
        codeUnique: 'Código ya existente',
        requiredTransferList: 'Debes seleccionar al menos una carrera',
      },
      warning_popup: {
        title: 'Estás modificando un período deshabilitado',
        content: '',
      },
      buttons: {
        create: 'Modificar',
        cancel: 'Cancelar',
        back: 'Volver',
      },
      success_message: {
        title: 'Período modificado correctamente',
        actions: {
          primary_text: 'Cerrar',
        },
      },
      breadcrumbs: {
        title: 'Editar un período',
        description: 'Modifica el período seleccionado',
        button: {
          name: 'Nuevo',
          icon: 'mdi-plus',
          color: 'primary',
          cancel: 'Cancelar',
          create: 'Crear',
        }
      },
      cards: {
        switch: {
          enable: 'Habilitado',
          disable: 'Deshabilitado'
        },
        title: 'Datos del período',
        main_subtitle: 'Agrega la información básica del período y su código, junto con una breve descripción que le permita tener datos relevantes del mismo.',
        name: 'Nombre',
        code: 'Código',
        date: 'Fecha de cursado',
        from: 'Desde',
        to: 'Hasta',
        enable: 'Habilitaciones',
        subtitle: 'Selecciona las habilitaciones que tendrá el período y determine el inicio/finalización de cada una.',
        incomingsale: 'Venta Ingresante',
        resale: 'Venta Rematriculado',
        application: 'Aplicación de beca',
        refund: 'Reembolso sin motivo',
        refund2: 'Reembolso fuerza mayor',
        refund3: 'Devolución rematriculados',
        unsuscribe: 'Desinscripción a materias',
        suscribe: 'Inscripción a materias',
        pases: 'Cambios',
        cathedra: 'Secciones',
        modality: 'Modalidad',
        subtitle2: 'Selecciona las modalidades que se asociarán al período.',
        distance: 'A distancia',
        onsite: 'Presencial',
        semi: 'Semipresencial',
        product: 'Producto de facturación',
        subtitle3: 'Esta sección permitirá asociar productos de facturación al período y estarán habilitados durante el ciclo del mismo.',
        period:'Periodo',
      },
      error_message: {
        title: 'El período no pudo modificarse',
        exists: 'Ya existe un período con esa modalidad y fecha',
      },
    },
    closePracticalSubject: {
      title: 'Estás por cerrar las materias practicas del periodo {periodo}',
      error_message: {
        title: 'No se pudieron cerras las materias practicas del periodo {periodo}'
      }
    },
    closeBianualSubject: {
      title: 'Estás por cerrar las materias semestrales del periodo {periodo}',
      error_message: {
        title: 'No se pudieron cerras las materias semestrales del periodo {periodo}'
      }
    }
  },
  en: {

  }
};

export default periodsTranslations
