export default {
    es: {
        invalid_rut: 'Ingrese un RUT válido',
        invalid_provisional_rut: 'Ingrese un RUT Provisorio válido',
        invalid_Passport: 'Ingrese un Pasaporte válido',
    },
    en: {
        invalid_rut: 'Enter a valid RUT',
        invalid_provisional_rut: 'Enter a valid provisional RUT',
        invalid_Passport: 'Enter a valid Passport',
    }
};
