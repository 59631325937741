<template>
  <div>
    <VContainer fluid class="d-flex pa-0 fullHeight">
      <VContainer class="container-custom px-6">
        <Breadcrumbs
          :description="$t('modules.careers.table.breadcrumbs.description')"
          :title="$t('modules.careers.table.breadcrumbs.title')"
        >
          <Button v-if="canCreate" @clicked="newCareers" icon="mdi-plus" :text="$t('actions.new_female')" depressed></Button>
        </Breadcrumbs>
        <VRow justify="center">
          <VCol sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :items="careers"
              :clear-selected="clearSelected"
              :permissions="{update: canUpdate, delete: canDelete}"
              @bulk-delete="(careers)=>{bulkDeletePopUp(careers)}"
              @delete="(id)=>deleteCareersPopUp(id)"
              @update="(id)=>{updateCareer(id)}"
            />
          </VCol>
        </VRow>
      </VContainer>
    </VContainer>
  </div>
</template>
<script>
import SuperTable from '../Components/SuperTable/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { $careers } from '../Services';
import { mapGetters } from 'vuex'

export default {
  name: 'CareersTable',
  components: {
    Breadcrumbs,
    SuperTable,
    Button
  },
  data() {
    return {
      careers: [],
      clearSelected: '',
      headers: [
        {
          text: this.$t('modules.careers.table.headers.id'),
          value: 'id'
        },
        {
          text: this.$t('modules.careers.table.headers.code'),
          value: 'code'
        },
        {
          text: this.$t('modules.careers.table.headers.name'),
          value: 'name'
        },
        {
          text: this.$t('modules.careers.table.headers.type'),
          value: 'type'
        },
        {
          text: this.$t('modules.careers.table.headers.status'),
          value: 'status'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    };
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.careers.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.careers.READ || this.$permissions.portaladministrativo.academic.careers.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.academic.careers.DELETE
    },
    ...mapGetters({
      userId: 'commons/getUserID'
    })
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    changed(parent, filter, value) {
      this.asidebar[parent].filters[filter].value = value;
    },
    deleteCareersPopUp(id) {
      const career = this.careers.find(career => career.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.careers.table.message.title'),
        content: [
          {
            value: career.name
          }
        ],
        actionPrimary: {
          text: this.$t('modules.careers.table.message.deleteAction'),
          callback: () => this.deleteCareers(id)
        },
        actionSecondary: {
          text: this.$t('modules.careers.table.message.cancelAction'),
          callback() {}
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary'
      });
    },
    async fetchData() {
      try {
        this.loading = true;
        const careers = await $careers.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc', excludeStudyPlan: true } });
        this.careers = careers.data.content.map(careers => {
          return {
            id: careers.id,
            code: careers.code,
            name: careers.description,
            status: careers.status,
            type: careers.careerType.meaning
          };
        });
      } catch {
        this.careers = []
      }
    },
    bulkDeletePopUp(careers) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.careers.table.message.title'),
        content: careers.map(career => ({
          value: career.name
        })),
        actionPrimary: {
          text: this.$t('modules.careers.table.message.deleteAction'),
          callback: () => this.bulkDelete(careers)
        },
        actionSecondary: {
          text: this.$t('modules.careers.table.message.cancelAction'),
          callback() {}
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary'
      });
    },
    bulkDelete(careers) {
      const itemsForDelete = careers.map(career => {
        return { id: career.id };
      });
      try {
        $careers.bulkDelete(itemsForDelete);
      } finally {
        this.fetchData();
      }
    },
    newCareers() {
      if (this.canCreate) {
        this.$router.params = {};
        this.$router.push('/careers/create');
      }
    },
    updateCareer(id) {
      if (this.canUpdate) {
        this.$router.params = {};
        this.$router.push(`careers/update/${id}`);
      }
    },
    async deleteCareers(idCareer) {
      try {
        await $careers.delete(`${idCareer}?userId=${this.userId}`);
        this.careers = this.careers.filter(career => career.idCareer !== idCareer);
        this.fetchData()
      } catch (error) {
        this.careers = []
        throw error;
      }
    }
  }
};
</script>
