const examEditorTranslations = {
    es_PE: {
        table:{
            headers:{
                subject: 'Curso',
            }
        }
    },
    es_MX: {
        table:{
            headers:{
                subject: 'Materia',
            }
        }
    },
    es: {
        breadcrumbs:{
            title: 'Editar Exámenes',
            description: 'Aquí puedes modificar la nota y el estado de un examen',
        },
        table:{
            headers:{
                student: 'Estudiante',
                career: 'Carrera',
                date: 'Fecha',
                subject: 'Materia',
                status: 'Estado',
                score: 'Calificación',
            }
        }
    },
    en: {

    },
}

export default examEditorTranslations