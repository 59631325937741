<template>
  <v-container
    fluid
    class="fullHeightViewport backgroundLogin pa-0 "
    :style="`
      background-image: url(${appPath});
      background-size: 66vw 100vh;
      background-position:  right  center ;`"
  >
    <v-container class="container-custom pa-0">
      <v-row no-gutters>
        <v-col cols="10" md="4" sm="6" class="mx-auto mx-sm-0">
          <router-view :logoPath="logoPath"></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Constants from '@/plugins/constants'
import { mapGetters } from 'vuex';
export default {
  name: 'login',
  components: {
  },
  data () {
    return {
      url_data_server: Constants.URL_DATA_SERVER,
    }
  },
  computed: {
    ...mapGetters({
      appPath: 'commons/getApplicationImageUrl',
      logoPath: 'commons/getLogoImageUrl',
    }),
  },
  methods:{
    backgroundPosition(){
      if (this.$country === 'argentina' || !this.$country === 'chile') {
        document.documentElement.setAttribute('data-activar-media-query', '1')
      }
    }
  },
}
</script>

<style lang="sass" scoped>

    :root
      --activar-media-query: 0

    .loginFormCard
        position: relative
        max-width: 400px
        margin: 0 auto
        display: flex
        flex-direction: column
        justify-content: center
        .recovery-btn
            margin-top: 0
            position: relative
            z-index: 2
            h6
                font-weight: 300
                line-height: 1
                text-transform: unset
        &__logo
            position: relative
            top: -3rem
            left: 0
        .v-alert
            line-height: 1.2
        .v-input
            &.error--text + .recovery-btn
                margin-top: -12px
            input
                -webkit-box-shadow: 0 0 0px 1000px #fff inset !important
                &:hover, &:focus, &:active
                    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important
        .v-input--checkbox
            .v-input__slot
                margin-bottom: 0 !important
            .v-messages
                display: none

        .v-text-field
            .v-input__slot
                input
                    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important
                    &:hover, &:focus, &:active
                        -webkit-box-shadow: 0 0 0px 1000px #fff inset !important
    .container-text
        position: absolute
        top: 18%
        right: 0
        word-break: break-word
        width: 40vw
        & p
            text-align: left
            font-size: 1.1rem
            font-weight: 400
            color: #fff
            line-height: 1.3rem
            & span:first-child
                font-size: 2rem
                font-weight: 700
            span
              font-size: 1.2rem
              font-weight: 500
              line-height: 3rem
    @media (min-width: 768px)
        :root[data-activar-media-query="1"]
            .backgroundLogin
                background-position:  right -50%  center !important
    @media (max-width: 768px)
        .backgroundLogin
            background-image: unset !important
            background-image: none !important
        .loginFormCard
            justify-content: flex-start
            &__logo
                top: 0 !important
        .container-text
            display: none
      </style>