<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
import Configuration from './constants/configuration';
import { loadScript } from 'vue-plugin-load-script';

export default {
    name: 'App',
    async created(){
        if (this.$isPortalAlumno) {
            await loadScript('https://cdn.agentbot.net/core/' + Configuration.value('aivo') + '.js');// eslint-disable-line
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                if(window.$aivo){
                    window.$aivo.ready(function() {
                        if (window.location.pathname == '/login'){
                            window.$aivo.launcher.hide()
                            const webChannel= Configuration.value('aivo')
                            window.$aivo.registerTokenForExternalZendesk(webChannel)
                        }
                    })
                }
                clearTimeout(this.timeout);
            },1000)
        }
    },
};
</script>

<style lang="sass">
    html
        overflow-y: hidden !important

    ::-webkit-scrollbar
        // Width of vertical scroll bar
        width: 8px
        // Height of horizontal scroll bar
        height: 10px
    ::-webkit-scrollbar-thumb
        border-radius: 8px
        background: #BDBDBD
    ::-webkit-scrollbar-track
        background: #F0F0F0

    @mixin hover($initialState)
        @for $i from 0 to 9
            @if $initialState == 'inverted'
                .hover-#{$i}-#{$initialState}
                    transition: opacity 300ms ease
                    opacity: $i*0.1
                    &:hover
                        opacity: 1


            @if $initialState == 'default'
                .hover-#{$i}-#{$initialState}
                    transition: opacity 300ms ease
                    opacity: 1
                    &:hover
                        opacity: $i*0.1

    @include hover('default')
    @include hover('inverted')

    .row
        margin-top: 0px !important
        margin-bottom: 0px !important
    
    .cursor-pointer
        cursor: pointer

    .cursor-grab
        /* fallback if grab cursor is unsupported */
        cursor: move
        cursor: grab
        cursor: -moz-grab
        cursor: -webkit-grab

    /* (Optional) Apply a "closed-hand" cursor during drag operation. */
    .cursor-grab:active
        cursor: grabbing
        cursor: -moz-grabbing
        cursor: -webkit-grabbing

    .noselect
        -webkit-touch-callout: none // iOS Safari
        -webkit-user-select: none // Safari
        -khtml-user-select: none // Konqueror HTML
        -moz-user-select: none // Firefox
        -ms-user-select: none // Internet Explorer/Edge
        user-select: none // Non-prefixed version, currently supported by Chrome and Opera

    // Navigation Drawer
    .v-overlay
        height: 100vh !important
        border-radius: 0 !important
        z-index: 6 !important

    .fullWidth
        width: auto
        min-width: 100%

    .fullHeight
        height: auto
        min-height: 100%

    .fullHeightViewport
        min-height: 100vh
        .v-main__wrap
            height: inherit
            & > div, & > div > div
              height: inherit

    .scrollableContent
        overflow-y: auto
        height: calc(100vh - 64px)

    .viewSpaces
        padding-top: 20px !important
        padding-bottom: 20px !important
        padding-left: 10px !important
        padding-right: 10px !important

    .v-text-field
        .v-input__slot
            min-height: 44px !important
            fieldset
                background: #FFFFFF
            .v-label
                top: 13px !important
                &--active
                    top: 18px !important
            .v-input__append-inner, .v-input__prepend-inner
                margin-top: 10px !important
                align-self: center
                z-index: 1
                .v-input__icon
                    margin-top: -10px !important
            .v-select__selections
                padding: 0 !important

        &.v-input--dense
            .v-input__slot
                min-height: 40px !important

    .v-input
        .v-text-field__details
            margin-bottom: -2px !important
        &.v-text-field:not(.error--text)
            .v-input__slot
                margin-top: 0 !important
            .v-text-field__details
                display: none
        &.no-message
            display: inline-flex
            width: inherit
            .v-text-field__details
                opacity: 0 !important
                min-height: 0 !important
                margin: 0 !important
                padding: 0 !important
                display: none
            .v-messages
                min-height: 0 !important
                display: none
    .v-btn
        text-transform: unset !important
    @media screen and (min-width: 1264px)
        .container-custom
            max-width: 1088px !important

    // Prevent text overflow
    .v-data-table-header th
        white-space: nowrap !important
    .v-data-table td
        word-break: keep-all

    @media screen and (max-width: 1024px)
        .margin-bot
            margin-bottom: 110px !important
</style>
