<template>
    <v-container fluid class="pa-0 scrollableContent">
        <v-container class="container-custom px-6">
        <Breadcrumbs
            :title="$t('modules.academic.gradebook.breadcrumbs.title')"
            :description="$t('modules.academic.gradebook.breadcrumbs.subtitle')"
            :callback="goBack"
            >
        </Breadcrumbs>
            <v-row no-gutters class="row pl-6 pb-6 pt-4 mt-4">
                    <v-col sm="4">
                        <v-text-field
                            prepend-inner-icon="mdi-magnify"
                            :placeholder="$t('modules.academic.gradebook.table.searchInput')"
                            outlined
                            clearable
                            class="superTable__search"
                            v-model="search"
                            @click:clear="clearAction"
                        ></v-text-field>
                    </v-col>
                    <v-col sm="4">
                        <Button
                            class="ml-4"
                            @clicked="searchAction"
                            outlined
                            depressed
                            :text="$t('actions.search')"
                        ></Button>
                    </v-col>
                </v-row>
                <div class="ml-4 pl-4" v-if="!superTableView">
                    <span class="text-h6 pa-2">{{cardsBooks.length}}</span>
                    <span class="text--secondary">{{$t('modules.academic.gradebook.career')}}</span>
                </div>
                <v-row v-if="!superTableView">
                        <v-col sm="4" v-for="(cardBook, index) in cardsBooks" :key="index" >
                            <CardBook
                                :title="cardBook.carrera"
                                :subtitle="`${cardBook.cantidad_libros} ` + $t('modules.academic.gradebook.card.books')"
                                :separator="false"
                                :icon="'mdi-chevron-right'"
                                @clicked="goToBook(cardBook.id)"
                                :careerBooks="careerBooks"
                            ></CardBook>
                        </v-col>
                </v-row>   
                <v-row  v-else>
                    <SuperTable
                        class="ml-6"
                        :headers="headers"
                        ref="tabla"
                        :items="sheets"
                        :pageCount="pagination.pagesAmount"
                        :page="pagination.page"
                        :totalItems="totalSheets"
                        @input-pagination="onInputPagination"
                        @length-pagination-change="onLengthPaginationChange"
                    />
                </v-row>
        </v-container>
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import CardBook from '../Components/CardBook';
import {$countGradeBooks, $getStudentsByBookAndCareer} from '../Services';
import SuperTable from '../Components/SuperTable';

    export default {
        name: 'CareersView',
        components: { 
            Breadcrumbs,
            Button,
            CardBook,
            SuperTable,
        },
        data() {
            return {
                search: '',
                sheets: [],
                totalSheets: 0,
                cardsBooks: [],
                carrera: '',
                superTableView: false,
                headers: [
                    { text: this.$t('modules.academic.gradebook.headers.nameStudent'), value: 'nombreAlumno' },
                    { text: this.$t('modules.academic.gradebook.headers.documentation'), value: 'dniAlumno' },
                    { text: this.$t('modules.academic.gradebook.headers.sheet'), value: 'folio' },
                    { text: this.$t('modules.academic.gradebook.headers.career'), value: 'carrera' },
                    { text: this.$t('modules.academic.gradebook.headers.legajo'), value: 'legajo' },
                    { text: this.$t('modules.academic.gradebook.headers.status'), value: 'status' },
                ],
                pagination: {
                        limit: 20,
                        page: 1,
                        pagesAmount: 1
                    },
                items: [],
                careerBooks: false,
            }
        },
        mounted() {
            this.fetchData()
        },
        methods: {
        async fetchData() {
            try{
                    this.loading = true
                    const respuesta = await $countGradeBooks.find(null, null, null) ;
                    this.cardsBooks = respuesta.data;
            }catch(e){
                this.cardsBooks = [];
                return e;
            } finally{
                this.loading = false;
            }
        },
        async fetchStudent(){
            try{
                const params = {
                    page:  this.pagination.page ?  this.pagination.page - 1 : 1,
                    length: this.pagination.limit,
                }
                const filterParams = {
                    book: null,
                    careerId: null,
                    search: this?.search ?? ''
                }
                this.loading = true
                const respuesta = await $getStudentsByBookAndCareer.filter(filterParams, {params});
                this.sheets = respuesta.data.content;
                this.totalSheets = respuesta.data.totalElements;
                this.career = respuesta.data.content[0]?.carrera
                this.pagination.pagesAmount = respuesta.data.totalPages;
            }
            catch(e){
                this.sheets = [];
                return e;
            } finally{
                this.loading = false;
            }
        },
        searchAction() {
            this.fetchStudent();
            this.superTableView = true
        },
		clearAction() {
            this.search = ''
            this.superTableView = false            
		},
        goToBook(id){
            this.$router.push(`gradebook/career-books/${id}`);
        },
        onInputPagination(event) {
                    this.pagination.page = event;
                    this.fetchStudent();
                },
        onLengthPaginationChange(event) {
            this.pagination = {
                page: 1,
                limit: event,
                pagesAmount: 1
            };
            this.fetchStudent();
            },
        goBack(){
            if(this.superTableView){
                this.superTableView = false;
                this.search = ''
                this.fetchData()
            }
            else{
                this.$router.push('/');
            }
        },
    }
    };
</script>