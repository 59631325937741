<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs :title="$t('modules.studentSubjects.enrollment.breadcrumbs.title')"
          :description="$t('modules.studentSubjects.enrollment.breadcrumbs.description')"></Breadcrumbs>
        <v-row>
          <v-col sm="12" class="d-flex align-center justify-center py-10" v-if="!plan.length">
            <v-progress-circular block indeterminate color="primary"></v-progress-circular>
          </v-col>
          <v-col cols="12" v-else>
            <div class="my-6">

              <v-row class="mb-12" no-gutters>
                <v-col cols="12">
                  <v-row>
                    <v-col sm="2" offset-sm="1" class="d-flex align-start py-0">
                      <span class="body-1 primary--text font-weight-bold">{{
                        $t('modules.studentSubjects.enrollment.labels.rut') }}</span>
                    </v-col>
                    <v-col sm="6" class="d-flex align-start py-0">
                      <span class="body-1 text-uppercase">{{ student.rut }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="2" offset-sm="1" class="d-flex align-start pb-0 pt-1">
                      <span class="body-1 primary--text font-weight-bold">{{
                        $t('modules.studentSubjects.enrollment.labels.student') }}</span>
                    </v-col>
                    <v-col sm="6" class="d-flex align-start pb-0 pt-1">
                      <span class="body-1 text-uppercase">{{ student.name }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="2" offset-sm="1" class="d-flex align-start pb-0 pt-1">
                      <span class="body-1 primary--text font-weight-bold">{{
                        $t('modules.studentSubjects.enrollment.labels.career') }}</span>
                    </v-col>
                    <v-col sm="6" class="d-flex align-start pb-0 pt-1">
                      <span class="body-1 text-uppercase">{{ student.career }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="2" offset-sm="1" class="d-flex align-start pb-0 pt-1">
                      <span class="body-1 primary--text font-weight-bold">{{
                        $t('modules.studentSubjects.enrollment.labels.studyPlan') }}</span>
                    </v-col>
                    <v-col sm="6" class="d-flex align-start pb-0 pt-1">
                      <span class="body-1 text-uppercase">{{ student.studyPlan }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="2" offset-sm="1" class="d-flex align-start pb-0 pt-1">
                      <span class="body-1 primary--text font-weight-bold">{{
                        $t('modules.studentSubjects.enrollment.labels.term') }}</span>
                    </v-col>
                    <v-col sm="6" class="d-flex align-start pb-0 pt-1">
                      <span class="body-1 text-uppercase">{{ student.term }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-12 mb-4" no-gutters>
                <v-col justify="center" class="d-flex" cols="12">
                  <div class="px-2">
                    <v-card color="#f9fafc" class="caption states-card text-center py-2 px-6" flat>
                      <span class="text-no-wrap text-uppercase grey--text text--darken-2">No cursado</span>
                    </v-card>
                  </div>
                  <div class="px-2">
                    <v-card color="#717EFF" class="caption states-card text-center py-2 px-6" flat>
                      <span class="text-no-wrap text-uppercase white--text">En curso</span>
                    </v-card>
                  </div>
                  <div class="px-2">
                    <v-card color="#D6FFD6" class="caption states-card text-center py-2 px-6" flat>
                      <span class="text-no-wrap text-uppercase grey--text text--darken-2">Aprobada</span>
                    </v-card>
                  </div>
                  <div class="px-2">
                    <v-card color="#52D87E" class="caption states-card text-center py-2 px-6" flat>
                      <span class="text-no-wrap text-uppercase white--text">Habilitada</span>
                    </v-card>
                  </div>
                  <div class="px-2">
                    <v-card color="#F4BADA" class="caption states-card text-center py-2 px-6" flat>
                      <span class="text-no-wrap text-uppercase pink--text text--darken-4">Reprobada</span>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mb-4" no-gutters :key="`year-${index}`" v-for="(semesters, index) in plan">
                <v-col cols="12">
                  <v-row>
                    <v-col sm="12" class="pb-0">
                      <v-card flat class="text-center primary lighten-1 py-2">
                        <span class="headline font-weight-bold primary--text">{{ toOrdinal(index) }} Año</span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-center" sm="6" :key="`semester-${semesterIndex}`"
                      v-for="(semester, semesterIndex) in semesters" @click="$v.$touch()">
                      <span class="title primary--text">{{ toOrdinal(((index * 2) + semesterIndex)) }} semestre</span>
                      <v-row>
                        <v-col class="pr-1 pb-0">
                          <v-card flat class="text-center primary lighten-4 py-2">
                            <span class="subtitle-1 text-uppercase font-weight-medium white--text">Bimestre A</span>
                          </v-card>
                          <SubjectItem :item="asignature" @open="selectedSubject = asignature"
                            v-for="(asignature, index) in semester.firstBimester" :key="`fb-${index}`"></SubjectItem>
                        </v-col>
                        <v-col class="pl-1 pb-0">
                          <v-card flat class="text-center primary lighten-4 py-2">
                            <span class="subtitle-1 text-uppercase font-weight-medium white--text">Bimestre B</span>
                          </v-card>
                          <SubjectItem :item="asignature" @open="selectedSubject = asignature"
                            v-for="(asignature, index) in semester.secondBimester" :key="`sb-${index}`"></SubjectItem>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="py-0">
                          <SubjectItem :item="asignature" @open="selectedSubject = asignature"
                            v-for="(asignature, index) in semester.semesterAsignatures" :key="`sa-${index}`">
                          </SubjectItem>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div class="d-flex justify-end my-12">
                <Button white :text="$t('actions.cancel')" @clicked="$router.go(-1)" class="mx-2"></Button>

                <Button :loading="enrollButton.loading" :success="enrollButton.success" :error="enrollButton.error"
                  :text="'Inscribir'"
                  :disabled="unenrollButton.loading || homologarButton.loading || convalidarButton.loading"
                  :successText="$t('actions.success')" :errorText="$t('actions.error')"
                  @clicked="checkSubjectsSelected('enroll')" @end="resetButtons" class="mx-2"></Button>

                <Button :loading="unenrollButton.loading" :success="unenrollButton.success" :error="unenrollButton.error"
                  :text="'Desinscribir'"
                  :disabled="enrollButton.loading || homologarButton.loading || convalidarButton.loading"
                  :successText="$t('actions.success')" :errorText="$t('actions.error')"
                  @clicked="checkSubjectsSelected('unenroll')" @end="resetButtons" class="mx-2"></Button>

                <Button :loading="homologarButton.loading" :success="homologarButton.success"
                  :error="homologarButton.error" :text="'Homologar'"
                  :disabled="enrollButton.loading || unenrollButton.loading || convalidarButton.loading"
                  :successText="$t('actions.success')" :errorText="$t('actions.error')"
                  @clicked="checkSubjectsSelected('homologar')" @end="resetButtons" class="mx-2"></Button>

                <Button :loading="convalidarButton.loading" :success="convalidarButton.success"
                  :error="convalidarButton.error" :text="'Convalidar'"
                  :disabled="enrollButton.loading || unenrollButton.loading || homologarButton.loading"
                  :successText="$t('actions.success')" :errorText="$t('actions.error')"
                  @clicked="checkSubjectsSelected('convalidar')" @end="resetButtons" class="ml-2"></Button>

                <Button v-if="student.academicRegulation == 'R23'" :text="$t('modules.studentSubjects.qualify.options.approved')"
                  :disabled="$v.$dirty && $v.selectedSubjects?.$invalid" @clicked="checkSubjectsSelected('qualify')"
                  class="ml-2" />
              </div>
            </div>

            <TemporaryRightDrawer :open="tmpDrawer.open" @tmpDrawerOpen="tmpDrawerOpen" :title="tmpDrawer.title"
              :description="tmpDrawer.description">
              <SelectPeriodPopUp v-if="selectPeriod" :periods="periods" @setSelectedPeriod="setSelectedPeriod"
                :open="selectPeriod" @close="selectPeriod = false" />
              <HomologarSubjects :isOpen="tmpDrawer.open" :subjects="homologarSubjects" :periodSelected="periodSelected"
                @close-drawer="tmpDrawer.open = false" @save="fetchData"></HomologarSubjects>
            </TemporaryRightDrawer>

            <TemporaryRightDrawer :open="qualify.drawer.open" @tmpDrawerOpen="clearDataQualifyPP" :title="qualify.title"
              :description="qualify.description">

              <ChangeApprovedPractice @saveQualifyPP="sendQualifyingPP" @clearDataQualifyPP="clearDataQualifyPP"
                :isOpen="qualify.drawer.open" :subjects="selectedSubjects" :optionsItems="qualify.options"
                :loading="qualify.loading" :success="qualify.success" :error="qualify.error"
                @close-drawer="clearDataQualifyPP"></ChangeApprovedPractice>
            </TemporaryRightDrawer>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import SubjectItem from '../Components/SubjectItem/SubjectItem';
import HomologarSubjects from './HomologarSubjects';
import ChangeApprovedPractice from './ChangeApprovedPractice';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import SelectPeriodPopUp from '../Components/SelectPeriodPopUp';
import { $students, $materias } from '../Services';
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex';

export default {
  name: 'StudentSubjectsCreate',
  components: {
    Breadcrumbs,
    SelectPeriodPopUp,
    Button,
    SubjectItem,
    TemporaryRightDrawer,
    HomologarSubjects,
    ChangeApprovedPractice,
  },
  data() {
    return {
      tmpDrawer: {
        open: false,
        title: 'Asignaturas a homologar',
        description: 'Ingrese la nota de las asignaturas a homologar'
      },
      selectedSubjects: [],
      selectPeriod: false,
      periods: null,
      callback: null,
      qualify: {
        value: '',
        options: [
          { id: 84, description: this.$t('modules.studentSubjects.qualify.options.approved') },
          { id: 87, description: this.$t('modules.studentSubjects.qualify.options.repproved') },
        ],
        loading: false,
        error: false,
        success: false,
        drawer: {
          open: false
        },
        title: 'Estado de aprobación de la materia',
        description: 'Ingrese el estado de la práctica'
      },
      periodSelected: null,
      student: {
        rut: null,
        name: null,
        career: null,
        studyPlan: null,
        term: null,
        academicRegulation: null
      },
      plan: [],
      currentTerm: null,
      homologarSubjects: [],
      enrollButton: {
        loading: false,
        error: false,
        success: false,
      },
      unenrollButton: {
        loading: false,
        error: false,
        success: false,
      },
      homologarButton: {
        loading: false,
        error: false,
        success: false,
      },
      convalidarButton: {
        loading: false,
        error: false,
        success: false,
      },
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.students.subjects.CREATE
    },
    userId() {
      return this['commons/getUserID']
    },
    ...mapGetters([
      'commons/getUserID',
    ]),
  },
  mounted() {
    this.fetchData()
    this.getPeriods()
  },
  methods: {
    clearDataQualifyPP() {
      if (!(this.qualify.success || this.qualify.error)) {
        this.qualify.drawer.open = false
        return
      }
      this.plan
        .forEach(year => {
          return year
            .forEach(semester => {
              return Object.values(semester)
                .forEach(bimester => {
                  return bimester
                    .forEach(subject => subject.selected = false)
                })
            })
        })
      setTimeout(() => {
          this.qualify.success = false
          this.qualify.error = false
          this.selectedSubjects = []
        },1000)
      this.qualify.drawer.open = false
    },
    async getPeriods() {
      let cantTerm = 5
      if(this.$isIPP) cantTerm = 32
      const data = await $students.find('term/current-or-previous', null, { params: { cantTerm } });
      this.periods = data.data;
    },
    checkSelectPeriod(callback) {
      this.selectPeriod = true
      this.callback = callback
    },
    setSelectedPeriod(value) {
      this.periodSelected = value
      this.selectPeriod = false
      this.callback()
    },
    tmpDrawerOpen() {
      this.tmpDrawer.open = !this.tmpDrawer.open
    },
    resetButtons() {
      this.enrollButton.loading = false
      this.enrollButton.success = false
      this.enrollButton.error = false

      this.unenrollButton.loading = false
      this.unenrollButton.success = false
      this.unenrollButton.error = false

      this.homologarButton.loading = false
      this.homologarButton.success = false
      this.homologarButton.error = false

      this.convalidarButton.loading = false
      this.convalidarButton.success = false
      this.convalidarButton.error = false
    },
    checkSubjectsSelected(action) {
      const selectedSubjects = []
      let selectedEnroll = 0
      let selectedUnenroll = 0
      let selectedNoHomologar = 0
      let selectedNoConvalidar = 0
      this.plan.forEach(year => {
        year.forEach(semester => {
          const bimesterArray = ['firstBimester', 'secondBimester', 'semesterAsignatures']
          bimesterArray.forEach(bimester => {
            semester[bimester].forEach(subject => {
              if (subject.selected) {
                if (subject.canEnroll) selectedEnroll++
                else if (subject.canUnenroll) selectedUnenroll++
                if (!subject.canHomologar) selectedNoHomologar++
                if (!subject.canConvalidar) selectedNoConvalidar++
                selectedSubjects.push(subject)
              }
            })
          })
        })
      })
      if (!selectedSubjects.length) {
        this.alertPopup('warning', 'No hay asignaturas seleccionadas')
      } else if (
        (action == 'enroll' && (!selectedEnroll || selectedUnenroll)) ||
        (action == 'unenroll' && (selectedEnroll || !selectedUnenroll))
      ) {
        this.alertPopup('warning', 'Las asignaturas seleccionadas no corresponden para la acción solicitada')
      } else if ((action == 'homologar' && selectedNoHomologar) || (action == 'convalidar' && selectedNoConvalidar)) {
        this.alertPopup('warning', 'Las asignaturas seleccionadas no corresponden para la acción solicitada')
      } else if (action == 'enroll') this.checkSelectPeriod(() => { this.enroll(selectedSubjects) })
      else if (action == 'unenroll') this.confirmPopup(() => { this.unenroll(selectedSubjects) })
      else if (action == 'homologar') this.checkSelectPeriod(() => { this.homologar(selectedSubjects) });
      else if (action == 'convalidar') this.checkSelectPeriod(() => { this.convalidar(selectedSubjects) });
      else if (action == 'qualify') this.qualifySubjectsPP(selectedSubjects);
    },
    async enroll(selectedSubjects) {
      this.enrollButton.loading = true

      const subjects = selectedSubjects.map(sub => {
        return {
          matId: sub.electiveSelected ? sub.electiveSelected.id : sub.subject.id,
          turnoCursadoId: this.periodSelected.id,
          elecId: sub.electiveSelected ? sub.subject.id : null,
        }
      })
      const data = { leg: this.$route.params.id, items: subjects, user: this.userId ? this.userId : null }

      try {
        await $materias.create(data, null, 'inscripcion')
        this.enrollButton.success = true
        this.alertPopup('success', 'Asignatura inscrita correctamente')
      } catch {
        this.enrollButton.error = true
        this.alertPopup('error', 'Ocurrió un error al inscribir la asignatura')
      } finally {
        this.enrollButton.loading = false
        this.fetchData()
      }
    },
    async unenroll(selectedSubjects) {
      this.unenrollButton.loading = true

      const subjects = selectedSubjects.map(sub => {
        return {
          subjectId: (!sub.electives) ? sub.subject.id : sub.electives.find(elective => elective.selected == true).id,
          termId: sub.termId,
        }
      })
      const data = { leg: this.$route.params.id, subjects, user: this.userId }

      try {
        await $materias.create(data, null, 'desinscripcion')
        this.unenrollButton.success = true
        this.alertPopup('success', 'Asignatura anulada correctamente')
      } catch {
        this.unenrollButton.error = true
        this.alertPopup('error', 'Ocurrió un error al desinscribir la asignatura')
      } finally {
        this.unenrollButton.loading = false
        this.fetchData()
      }
    },
    homologar(selectedSubjects) {
      const subjects = selectedSubjects.map(sub => {
        return {
          idSubject: sub.electiveSelected ? sub.electiveSelected.id : sub.subject.id,
          code: sub.subject.code,
          descripcion: sub.subject.descripcion,
          score: null,
          electiveSelected: sub.electiveSelected,
          idElectiveSubject: sub.electiveSelected ? sub.subject.id : null,
        }
      })
      this.homologarSubjects = subjects
      this.tmpDrawer.open = true
    },
    async convalidar(selectedSubjects) {
      this.convalidarButton.loading = true
      const subjects = selectedSubjects.map(sub => {
        return {
          idSubject: sub.electiveSelected ? sub.electiveSelected.id : sub.subject.id,
          idElectiveSubject: sub.electiveSelected ? sub.subject.id : null,
          score: null
        }
      })
      const data = { studentCareer: this.$route.params.id, termId: this.periodSelected.id, subjects, user: this.userId };
      try {
        await $materias.create(data, null, 'homologacion')
        this.convalidarButton.success = true
        this.alertPopup('success', 'Asignatura convalidada correctamente')
        this.fetchData()
      } catch {
        this.convalidarButton.error = true
        this.alertPopup('error', 'Ocurrió un error al convalidar la asignatura')
      } finally {
        this.convalidarButton.loading = false
      }
    },
    qualifySubjectsPP() {
      if (this.$v.$invalid) return
      this.qualify.drawer.open = true
    },
    async sendQualifyingPP(qualify) {
      const { id: qualification } = qualify
      const [{ subject, termId }] = this.selectedSubjects
      const data = {
        leg: this.$route.params.id,
        userId: this.userId,
        qualification,
        subjects: [
          {
            subjectId: subject.id,
            termId
          }
        ]
      }
      try {
        this.qualify.loading = true
        await $materias.create(data, null, 'qualification')
        this.qualify.success = true;
        this.$v.$reset()
        this.fetchData()
      } catch (error) {
        this.qualify.error = true;
      }
      finally {
        this.qualify.loading = false
      }
    },
    confirmPopup(callback) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t(`actions.warning`),
        content: [{ value: '¿Estás seguro de realizar esta acción?' }],
        actionPrimary: { text: this.$t('actions.accept'), callback() { callback() } },
        actionSecondary: { text: this.$t('actions.cancel'), callback() { } },
        icon: { color: 'warning', name: 'mdi-eye-off-outline' },
        color: 'primary',
      })
    },
    alertPopup(type, message) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t(`actions.${type}`),
        content: [{ value: message }],
        actionPrimary: { text: this.$t('actions.accept'), callback() { } },
        icon: { color: type, name: (type == 'success') ? 'mdi-check' : ((type == 'error') ? 'mdi-close' : 'mdi-eye-off-outline') },
        color: 'primary',
      })
    },
    toOrdinal(number) {
      const first = this.$t('modules.careers.ordinal');
      const second = this.$t('modules.careers.ordinal_2');

      const units = (number + 1) % 10;
      let decimals = (number + 1) / 10;
      decimals = Math.floor(decimals);

      if (decimals === 0) return first[units - 1].toPascalCase()
      if (units === 0 && decimals != 0) return second[decimals - 1].toPascalCase()

      return `${second[decimals - 1].toPascalCase()} ${first[units - 1]}`
    },
    async fetchData() {
      try {
        this.tmpDrawer.open = false
        const studyPlan = await $students.findById(this.$route.params.id, 'study-plan');
        const { data } = studyPlan

        this.currentTerm = data.currentTerm
        this.student.rut = data.identification
        this.student.name = `${data.name} ${data.lastName}`
        this.student.career = data.degree
        this.student.studyPlan = data.description
        this.student.term = data.term
        this.student.academicRegulation = data.academicRegulation

        const yearFormat = []
        let semesters = []
        let semesterAmount = 0

        data.subjects.map(subject => {
          subject.canUnenroll = subject.subjectStatus && subject.subjectStatus.value == 86
          subject.canEnroll = !subject.subjectStatus || subject.subjectStatus.value == 87
          subject.canHomologar = !subject.subjectStatus || subject.subjectStatus.value == 87 || subject.subjectStatus.value == 86
          subject.canConvalidar = !subject.subjectStatus || subject.subjectStatus.value == 87 || subject.subjectStatus.value == 86;
          subject.electiveSelected = null
          return subject
        })
        data.subjects.forEach(subject => {
          if (semesterAmount < subject.semester) semesterAmount = subject.semester
        });

        await this.orderSubjects(data.subjects)

        for (let i = 0; i < semesterAmount; ++i) {
          semesters[i] = this.subjects.filter(subject => subject.semester === i + 1).map(subject => {
            subject.selected = false
            return subject
          })
        }

        semesters = semesters.map(subjects => ({
          firstBimester: subjects.filter(subject => subject.termType.value == 52 && subject.subject.duration.value == 49).sort((a, b) => a.order - b.order),
          secondBimester: subjects.filter(subject => subject.termType.value == 53 && subject.subject.duration.value == 49).sort((a, b) => a.order - b.order),
          semesterAsignatures: subjects.filter(subject => subject.subject.duration.value == 50).sort((a, b) => a.order - b.order)
        }))

        for (let i = 0; i < semesters.length; i = i + 2) {
          if (semesters[i + 1]) yearFormat.push([semesters[i], semesters[i + 1]])
          else yearFormat.push([semesters[i]])
        }

        this.plan = yearFormat

      } catch (error) {
        this.plan = [];
        throw error
      }
    },
    orderSubjects(subjects) {
      if (Array.isArray(subjects)) this.subjects = subjects.sort((subjA, subjB) => {
        if (subjA.semester < subjB.semester) return -1
        else if (subjA.semester > subjB.semester) return 1
        else {
          if (subjA.termType.value < subjB.termType.value) return -1
          if (subjA.termType.value > subjB.termType.value) return 1
          else {
            if (subjA.order < subjB.order) return -1
            if (subjA.order > subjB.order) return 1
            else return 0
          }
        }
      })
    },
  },
  watch: {
    plan: {
      handler(plan) {
        this.selectedSubjects = plan
          .map(year => {
            return year
              .map(semester => {
                return Object.values(semester)
                  .map(bimester => {
                    return bimester
                      .filter(subject => subject.selected === true)
                  })
              })
          })
          .flat(3)
      },
      deep: true
    }
  },
  validations() {
    const STATUS = {
      SUBJECTS:{
        IN_PROGRESS:86
      }
    }
    function isStudying({ subjectStatus }){
      return subjectStatus?.value == STATUS.SUBJECTS.IN_PROGRESS ?? false
    }
    function isPP({ subject }) {
      return subject.isPP ?? false
    }
    if (this.student.academicRegulation === 'R23') {
      return {
        selectedSubjects: {
          required,
          maxLength: maxLength(1),
          $each: {
            isPP,
            isStudying
          }
        }
      }
    }
    return {}
  }
}
</script>
