<template>
    <v-dialog :value="open" @input="closeDialog" max-width="660px">
    <v-card v-if="true" style="padding:40px;">
        <div class="alertPopup mx-auto mb-5">
            <div class="circle text-center mx-auto">
            <v-icon color="white" size="60" class="icon">mdi-alert</v-icon>
            </div>
        </div>
        <h1 class="primary--text headline text-center" style="font-size:22px;color:rgba(0, 0, 0, 0.6); margin-bottom:20px">{{this.$t('modules.changeStudyPlan.table.actions.message.confirmation_change_plan')}}</h1>
        <div >
            <v-autocomplete
            v-model="studyPlanSelected"
            item-text="description"
            item-value="id"
            return-object
            label='Plan de estudio'
            outlined
            :items="arrayStudyPlan"
            @blur="$v.studyPlanSelected.$touch()"
            :error-messages="$v.studyPlanSelected.$anyError ? $t('modules.changeStudyPlan.table.error.required') : ''"
            >
            </v-autocomplete>
        </div>
        <v-row class="justify-center pt-8">
            <Button
                class="mx-8"
                :text="$t('actions.close')"
                white
                @clicked="closeDialog"
            ></Button>
            <Button
                class="mx-8"
                :text="$t('actions.confirm')"
                style="background-color:#106ca7;border-radius:38px"
                :loading="createButton.loading"
                :success="createButton.success"
                :error="createButton.error"
                :successText="$t('actions.created')"
                :errorText="'Error'"
                @end="resetButtonValues"
                @clicked="saveSelectionStudyPlan"
                ></Button>
            </v-row>
    </v-card>
    <SuccessMessage 
        v-else
        class="white px-10"
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :fullWidth="true"
        >
        <h2 class="text-center font-weight-regular">{{successMessage.text}}</h2>
    </SuccessMessage>
    </v-dialog>
</template>

<script>
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {required } from 'vuelidate/lib/validators'

export default {
    name: 'RequestStudyPlanPopUp',
    props: {
        open: { type: Boolean, default: false },
        studyPlan: {type: Array},
        successMessage: {type: Object},
        response: {type: Boolean, default: false},
        createButton: {type:Object},
        arrayStudyPlan: {type: Array},
    },
    components: {
        Button,
        SuccessMessage
    },
    data(){
        return {
            studyPlanSelected: null,
        }
    },
    methods: {
        closeDialog () {
            if (this.open) {
                this.$emit('close');
                this.$v.$reset()
            }
        },
        saveSelectionStudyPlan(){
            if (this.$v.$invalid) {
                this.createButton.error = true;
                this.$v.$touch()
            } else {
                this.$emit('requestStudyPlan', this.studyPlanSelected)
                this.resetButtonValues()
            }
        },
        resetButtonValues () {
            this.createButton.success = false;
            this.createButton.error = false;
        },
    },
    validations: {
        studyPlanSelected: { required }
    }
}
</script>

<style lang="sass" scoped>
.headline
    font-size: 1.5rem !important
    font-weight: 400
    line-height: 2rem
    letter-spacing: normal !important
    font-family: "Roboto", sans-serif !important

.alertPopup
    width: 160px

.circle
    border-radius: 50% !important
    background-color: #ffff9e

.icon
    background-color: #fb8c00
    margin: 35px
    padding: 15px
    position: relative
    border-radius: 50%
</style>