import api from '@/api';

const { $student, $canvas, $academic } = api;

export const { $students, $canvasSyncStudent } = $student

export const { $canvasStudent, $users } = $canvas

export const { $teachers, $terms } = $academic

