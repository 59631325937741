<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight" v-if="!selectedInstallments">
      <v-container fluid class="px-0">
        <div class="px-md-10">
          <div class="d-flex">
            <v-btn icon @click="$emit('goBackPayment')"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <h4 class="d-flex align-center primary--text ml-2">{{ $t('modules.portalalumno.payments.paymentsStudents.view.subscription.payment', { org: 'Teclab' }) }}</h4>
          </div>
          <v-row>
            <v-col class="col-6">
              <v-card
              v-for="item in filteredSubscriptions"
              :key="item.installments"
              @click="selectSubscription(item)"
              class="bank-card d-inline-block align-center justify-center pa-6 primary--text"
              style="margin-right: 10px;"
            >
              <v-card-title>
                {{ `${item.installments} ${$t('modules.portalalumno.payments.paymentsStudents.view.subscription.installments')}` }}
              </v-card-title>
            </v-card>
            </v-col>
          </v-row>
          <v-data-table
            v-if="selectedSubscription"
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-filtering
            disable-pagination
            disable-sort
          ></v-data-table>
        </div>
      </v-container>
    </v-container>

  </div>
</template>

<script>
import moment from 'moment'
import {$payments} from '../Services';
import {mapGetters} from 'vuex';

export default {
  name: 'PaymentsSubscriptionView',
  props: {
    subscriptions: Array,
    subscriptionPaymentTypes: Array,
    selectedMethod: Object,
    coupon: Object,
    resumen: Object,
    summaryButtons: Object,
    reenrollment: { type: Object, default: null },
    unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } }
  },
  data() {
    return {
      selectedInstallments: false,
      selectedSubscription: null,
      adminExpenses: null,
      filteredSubscriptions: null,
      headers: [
        { text: this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.description'), value: 'description' },
        { text: this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.expiration'), value: 'expiration' },
        { text: this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.amount'), value: 'amount' }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters({
      loggedUserId: 'commons/getUserID'
    }),
  },
  watch: {
    selectedSubscription(value) {
      this.summaryButtons.primary.disabled = !value
      const totalWithoutAdminExpenses = this.resumen.items.subtotal.monto - this.resumen.items.discounts.monto - this.resumen.items.scholarships.monto - this.resumen.items.benefits.monto
      this.adminExpenses = ((totalWithoutAdminExpenses * (this.selectedSubscription?.adminExpenses || 0)) / 100).toFixed(2)
      this.resumen.items.adminExpenses.monto = this.adminExpenses
      this.resumen.items.adminExpenses.percentage = this.selectedSubscription?.adminExpenses
      this.items = []
      for (let due = 0; due <= this.selectedSubscription?.installments; due++) {
        this.items.push({
          description: due ? `${this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.installment')} N°${due}` : this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.adminExpenses'),
          expiration: due ? moment(this.selectedSubscription.payDay, 'DD').add(due, 'months').format('D/M/YYYY') : moment().format('D/M/YYYY'),
          amount: due ? (totalWithoutAdminExpenses / this.selectedSubscription.installments).toFixed(2) : this.adminExpenses
        })
      }
      this.resumen.total = totalWithoutAdminExpenses + parseFloat(this.resumen.items.adminExpenses.monto)
    },
  },
  mounted() {
    this.selectedSubscriptionPaymentType();
  },
  
  methods: {
    selectedSubscriptionPaymentType() {
      this.selectedSubscription = null
      let filteredDues = this.subscriptions.filter(subscription => subscription.suscriptionPaymentType.value == 243)
      if (this.resumen.items.subtotal.items.filter(price => price.title.toUpperCase().includes('ARANCEL')).length < 2) {
        filteredDues = filteredDues.length ? [filteredDues.shift()] : []
      }
      this.filteredSubscriptions = filteredDues
    },

    selectSubscription(item) {
      this.selectedSubscription = item;
    },
    isSelected(item) {
      return this.selectedSubscription === item;
    },

    paymentInstallmentsSelected() {
      if (!this.selectedInstallments) {
        this.selectedInstallments = true
        this.summaryButtons.primary.disabled = true
        this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentLink')
        this.createSubscription(this.resumen)
      } else {
        if (this.selectedSubscriptionPaymentType.meaning.toUpperCase() == 'EFECTIVO') {
          this.$refs.subscriptionCash.generateCoupon()
        } else this.$refs.subscriptionCard.saveBillingData()
      }
    },
    goBackToSubscriptionPaymentMethod() {
      this.selectedInstallments = false
      this.summaryButtons.primary.disabled = false
      this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
      this.$emit('setDue')
    },
    async createSubscription() {
      const alumnoSeleccionado = JSON.parse(localStorage.getItem('alumnoSeleccionado'))
      const suscripcionData = {
        studentId: alumnoSeleccionado.student.id,
        email: alumnoSeleccionado.student.user.userData.email,
        transaction_amount: ((this.resumen.total - this.resumen.items.adminExpenses.monto) / this.selectedSubscription.installments).toFixed(2),
        userType: 'ADVISOR',
        legajo: alumnoSeleccionado.academicRegister,
        userId: this.loggedUserId,
        intencion_de_pago_id: this.resumen.paymentIntent,
        setup_fee: this.resumen.items.adminExpenses.monto,
        repetitions: this.selectedSubscription.installments,
        first_debit_date: moment(this.selectedSubscription.payDay, 'DD').add(1, 'months'),
        description: 'GASTO_ADMINISTRATIVO',
        efectivo: this.selectedSubscriptionPaymentType.meaning.toUpperCase() === 'EFECTIVO',
        termId: this.reenrollment?.products.reduce(function (a, b) { return a.dueDate < b.dueDate ? a : b }).idTerm || null,
        idsPending: this.unselectedReenrollmentsAccountIds
      }
      
      try {
        await $payments.create(suscripcionData, {}, 'subscription');
        this.$emit('changeStep', 'suscriptionOK');
      } catch {
        this.summaryButtons.primary.loading = false
        this.summaryButtons.primary.error = true
        this.$emit('changeStep', 'suscriptionError');
        this.summaryButtons.primary.error = false
      }
    },
    setDue(due) {
      this.$emit('setDue', due)
    }
  }
}
</script>

<style scoped>

</style>
