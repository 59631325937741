import Students from '../Students.vue'
import StudentsView from '../Views/StudentsView.vue';
const StudentsRoute =
{
  path: '/students',
  meta: {
    name: {
      en: 'Students',
      es: 'Estudiantes',
    }
  },
  component: Students,
  children: [
    {
      path: '/',
      component: StudentsView,
      meta: { name: 'Index', group: 'comisiones', path: 'students', permission: 'READ' }
    }
  ]
};
export default StudentsRoute
