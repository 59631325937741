<template>
    <v-container fluid class="superTable">
      <v-row>
        <v-col sm="12">
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :page="page"
            :items-per-page="itemsPerPage"
            :totalItems="totalItems"
            sort-by="date"
            sort-desc
            hide-default-footer
            item-key="id"
            class="superTable__table"
            style="table-layout: fixed"
          >
          <template v-slot:item.download="{ item }">
            <v-icon
              v-if="item.status.value == 283"
              small
              @click="$emit('downloadBook', item)"
            >
              mdi-download
            </v-icon>
          </template>
  
            <template v-slot:[`item.name`]="{item}">
              <div class="superTable__table__item__name">
                <v-avatar size="32" class="mr-4">
                  <img :src="require(`@/assets/icons/pdf.svg`)">
                </v-avatar>
                <div>{{ item.name }}</div>
              </div>
            </template>
  
            <template v-slot:[`item.date`]="{item}">
              <div class="superTable__table__item__name">
                <div>{{ item.date }}</div>
              </div>
            </template>
  
            <template v-slot:[`item.status`]="{item}">
              <div class="d-flex">
                <v-icon v-if="item.status.value == 282" color="orange" size="20px">mdi-clock-outline</v-icon>
                <v-icon v-else-if="item.status.value == 283" color="green" size="20px">mdi-check-circle</v-icon>
                <v-icon v-else-if="item.status.value == 284" color="red" size="20px">mdi-close-circle</v-icon>
                <span class="ml-1">{{ item.status.meaning }}</span>
              </div>
            </template>
  
          </v-data-table>
          <v-divider class="mt-0 mb-8"></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters align="center">
        <v-col sm="3">
          <span
            class="caption d-inline-block"
          >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
          <v-select
            v-model="itemsPerPage"
            :items="itemsPerPageAvailable"
            :placeholder="itemsPerPage.toString()"
            outlined
            class="superTable__paginationSelect d-inline-block ml-4"
          ></v-select>
        </v-col>
        <v-col sm="6" class="text-center">
          <v-pagination
            v-model="page"
            :length="pageCount"
            class="superTable__pagination"
          ></v-pagination>
        </v-col>
        <v-col sm="3" align="right">
          <v-text-field
            :value="page"
            ref="directPage"
            :max="pageCount"
            outlined
            @change="directPageInput($event)"
            class="superTable__paginationInput d-inline-block mr-4"
          ></v-text-field>
          <span class="caption d-inline-block">{{$t('modules.academic.gradebook.action.goToPage')}}</span>

        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  
  export default {
    name: 'SuperTable',
    props: {
      headers: Array,
      items: Array
    },
    data() {
      return {
        search: '',
        page: 1,
        itemsPerPage: 5,
        itemsPerPageAvailable: [5, 20, 50]
      };
    },
    methods: {
      directPageInput(event) {
        let newPage = +event;
  
        if(newPage > +this.pageCount) newPage = +this.pageCount;
        else if(newPage < 1 || +this.pageCount == 1) newPage = 1;
  
        this.page = newPage;
      }
    },
    watch: {
      page(val) {
        if (val > this.pageCount) this.page = this.pageCount;
        if (val <= 1) this.page = 1;
      }
    },
    computed: {
      totalItems() {
        return this.items.length
      },
      itemsCount() {
        const counter = {
          totalItems: this.totalItems,
          startItem: this.page * this.itemsPerPage - this.itemsPerPage + 1,
          endItem: this.page * this.itemsPerPage
        };
        if (counter.endItem > this.totalItems) {
          counter.endItem = this.totalItems;
        }
        return counter;
      },
      pageCount() {
        let counter = this.items.length / this.itemsPerPage;
        if (counter < 1) {
          counter = 1;
        }
        return Math.ceil(counter);
      }
    },
  };
  </script>
  