<template>
    <div>
        <v-container fluid class="d-flex pa-0 fullHeight">
            <v-container fluid class="scrollableContent pa-0">
                <v-container class="container-custom px-6">
                    <Breadcrumbs :description="breadcrumbs.description" :title="breadcrumbs.title">
                        <Button
                            v-if="canCreate"
                            @clicked="newSection"
                            :icon="breadcrumbs.button.icon"
                            :text="$t('modules.sections.table.new_section')"
                            depressed
                        ></Button>
                    </Breadcrumbs>
                    <v-row justify="center">
                        <v-col sm="12" class="viewSpaces">
                            <SuperTable
                                :periods="periods"
                                :headers="headers"
                                :sections="sections"
                                :clear-selected="clearSelected"
                                :page.sync="page"
                                :itemsPerPage.sync="itemsPerPage"
                                :totalElements="totalItems"
                                :deleteTitle="$t('modules.sections.table.bulk_delete')"
                                :permissions="{update: canUpdate, delete: canDelete}"
                                @bulk-delete="bulkDeletePopUp"
                                @deleteUser="deleteSectionPopUp"
                                @updateUser="updateSection"
                                @search="searchMethod"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
        </v-container>
    </div>
</template>

<script>
    import SuperTable from '../Components/SuperTable/SuperTable'
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
    import Button from '@/components/Button/Button';
    import { $sections, $terms } from '../Services';
    import { mapGetters } from 'vuex'
    export default {
        name: 'SectionsTable',
        components: {
            SuperTable,
            Breadcrumbs,
            Button
        },
        data() {
            return {
                periods:[],
                page: 1,
                itemsPerPage: 20,
                totalItems: null,
                paramSearch: null,
                paramPeriod: null,
                clearSelected: null,
                breadcrumbs: {
                    title: this.$t('modules.sections.table.breadcrumbs.title'),
                    description: this.$t('modules.sections.table.breadcrumbs.description'),
                    button: {
                        name: 'Nuevo',
                        icon: 'mdi-plus',
                        color: 'primary'
                    }
                },
                sections: [],
                asidebar: {
                    title: 'Refinar búsqueda',
                    items: [
                        {
                            name: 'Nombre',
                            filters: [
                                {
                                    name: 'Nombre',
                                    type: 'text',
                                    header: 'description',
                                    value: '',
                                },
                            ]
                        },
                        {
                            name: 'Tipo',
                            filters: [
                                {
                                    name: 'Final',
                                    type: 'check',
                                    value: true,
                                    dataFiltered: 123
                                },
                            ]
                        },
                    ]
                },
                
                headers: [
                    {
                        text: this.$t('modules.sections.table.headers.name'),
                        value: 'description',
                    },
                    {
                        text: this.$t('modules.sections.table.headers.subjects'),
                        value: 'subject_name',
                    },
                    {
                        text: this.$t('modules.sections.table.headers.teacher'),
                        value: 'teacher',
                    },
                    {
                        text: this.$t('modules.sections.table.headers.term'),
                        value: 'term_name',
                    },
                    /*{
                        text: this.$t('modules.sections.table.headers.lms_type'),
                        value: 'lms_name',
                    },*/
                    {
                        text: this.$t('modules.sections.table.headers.size'),
                        value: 'size',
                    },
                    {
                        text: this.$t('modules.sections.table.headers.registered'),
                        value: 'countStudent',
                    },
                    {
                        text: this.$t('modules.sections.table.headers.status'),
                        value: 'status',
                    },
                    {
                        text: this.$t('modules.sections.table.headers.reglamento'),
                        value: 'oldModality',
                    },
                    {
                        text: '',
                        value: 'action'
                    }
                ],
            }
        },
        async mounted() {
            await this.fetchData(
                {
                    init: true,
                    page:this.page,
                    length:this.itemsPerPage,
                }
            );            
        },
        computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.academic.sections.CREATE
            },
            canUpdate() {
                return this.$permissions.portaladministrativo.academic.sections.READ || this.$permissions.portaladministrativo.academic.sections.UPDATE
            },
            canDelete() {
                return this.$permissions.portaladministrativo.academic.sections.DELETE
            },
            ...mapGetters({
                userId: 'commons/getUserID'
            })
        },
        methods: {
            changed(parent, filter, value) {
                this.asidebar.items[parent].filters[filter].value = value
            },
            deleteSectionPopUp(id){
                const section = this.sections.find(s => s.id == id);
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    content: [{value: section.description}],
                    actionPrimary: {text: this.$t('actions.accept'), callback: () => this.deleteSection(id)},
                    actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
                    icon: {color: 'error', name: 'mdi-delete'},
                    color: 'primary',
                })
            },
            async fetchData (params) {
                this.clearSelected = new Date().getMilliseconds().toString();
                try {
                    if(params.init) await this.getPeriodsData();
                    const sections = await $sections.find(null, null, { params: { 
                            page: params?.page !== null ? params.page-1 : null, 
                            length: params?.length !== null ? params.length : null,
                            term: params?.term !== null ? params.term : null,
                            search: params?.search !== null ? params.search : null,
                        } 
                    });
                    this.sections = sections.data.content.map(section => {
                        section.teacher = null;
                        if(section.assignedTeachers && section.assignedTeachers[0].user !== null){
                            section.teacher = `${section.assignedTeachers[0].user.userData.lastname} ${
                                                section.assignedTeachers[0].user.userData.name}`
                        }
                        section.description = section.name;
                        if (section.subject) section.subject_name = section.subject.description;
                        if (section.term) section.term_name = section.term.description;
                        section.lms_name = section.lmsType.meaning;
                        return section;
                    });
                    this.totalItems = sections?.data?.totalElements;
                } catch(error) {
                    this.sections = []
                     throw error
                }
            },
            async getPeriodsData() {
                try {
                    const params = {page: 0, length: 20, orderBy: 'classStartDate', orientation: 'desc'}
                    const periodsData = await $terms.find(null,null,{params});
                    const cursado = [{ divider: true } , { header: 'Cursado' }, ...periodsData.data.content?.filter(e => e.termType.meaning == 'Cursado')]
                    const onBording = [{ divider: true } , { header: 'On Boarding' }, ...periodsData.data.content?.filter(e => e.termType.meaning == 'On Boarding')]
                    const nivelacion = [{ divider: true } , { header: 'Nivelacion'},...periodsData.data.content?.filter(e => e.termType.meaning == 'Nivelacion')]
                    const practica = [{ divider: true } , { header: 'Prácticas Profesionales'},...periodsData.data.content?.filter(e => e.termType.meaning == 'Practicas Profesionales')]
                    this.periods = [ ...cursado, ...onBording, ...nivelacion, ...practica]
                } catch (error) {
                    this.periods = []
                    throw error
                }
            },
            bulkDeletePopUp(sections) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    content: sections.map(section => ({
                        value: section.description
                    })),
                    actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkDelete(sections)},
                    actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
                    icon: {color: 'error', name: 'mdi-delete'},
                    color: 'primary',
                })
            },
            async bulkDelete (sections) {
                const itemsForDelete = sections.map((section) => {
                    return { id: section.id }
                });

                try {
                    await $sections.bulkDelete(itemsForDelete)
                    itemsForDelete.forEach(item => {
                        this.sections = this.sections.filter(section => section.id !== item.id);
                    });
                    this.fetchData(
                    {
                        page: 1,
                        length: this.itemsPerPage,
                        term: this.paramPeriod,
                        search: this.paramSearch,
                    }
                )

                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: 'Error',
                        content: [{value: this.$t('modules.sections.table.bulk_delete_error')}],
                        actionPrimary: {text: this.$t('actions.accept'), callback() {}},
                        icon: {color: 'error', name: 'mdi-close'},
                        color: 'primary',
                    });
                    throw error;
                }
            },
            newSection() {
                if (this.canCreate) this.$router.push('/sections/create')
            },
            updateSection(id) {
                if (this.canUpdate) this.$router.push(`/sections/update/${id}`)
            },
            async deleteSection (id) {
                try {
                    await $sections.delete(`${id}?userId=${this.userId}`);
                    this.sections = this.sections.filter(section => section.id !== id);
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: 'Error',
                        content: [{value: this.$t('modules.sections.table.delete_error')}],
                        actionPrimary: {text: this.$t('actions.accept'), callback() {}},
                        icon: {color: 'error', name: 'mdi-close'},
                        color: 'primary',
                    });
                    throw error
                }
            },
            searchMethod(params){
                this.paramSearch = params.search;
                this.paramPeriod = params.period;
                this.fetchData(
                    {
                        page: 1,
                        length: this.itemsPerPage,
                        term: params.period,
                        search: params.search,
                    }
                )
            },
        },
        watch:{
            'page': function(val) {
                this.fetchData(
                    {
                        page: val,
                        length: this.itemsPerPage,
                        term: this.paramPeriod,
                        search: this.paramSearch,
                    }
                )
            },
            'itemsPerPage': function(val) {
                this.fetchData(
                    {
                        page: 1,
                        length: val,
                        term: this.paramPeriod,
                        search: this.paramSearch,
                    }
                )
            }
        }
    }
</script>
