
<template>
  <amplify-form 
    :logoPath="logoPath"
    :data="data"></amplify-form>   
  
</template>
  
  <script>
  import AmplifyForm from '../Components/AmplifyForm/AmplifyForm.vue';
  
  export default {
    components: {
      AmplifyForm
    },
    props: {
      loading: Boolean,
      errorAuth: Boolean,
      serverError: Boolean,
      logoPath: String,
      data: Object
    },
  }
  </script>
  