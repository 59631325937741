const loginApplicationTranslations = {
  es_CL: {
    signIn: {
      welcomeMessage: '¡Hola! Qué bueno tenerte por aquí.',
      subMessage: 'Ingresa tu RUT y contraseña para acceder.',
      items: {
        email: 'RUT'
      },
      recovery: {
        password: {
          'description': 'Ingresa tu correo electrónico y te enviaremos un  código que te permitira restablecer la contraseña.',
        },
      },
      titleLogin:'<p class="m-0"><span class=""> Aprovecha tu tiempo,</span> <br> <span>Accede desde cualquier dispositivo</span> <br>Todo tu material de estudio en un solo lugar las 24 horas,<br> para que elijas cúando y dónde estudiar.</p>',
    },
  },
  es_PE: {
      signIn: {
          subMessage: 'Ingresa tu DNI y contraseña',
          titleLogin:'<p class="m-0"><span class=""> Aprovecha tu tiempo,</span> <br> <span>Accede desde cualquier dispositivo</span> <br> Accede a todos los contenidos las 24 horas, desde cualquier<br> dispositivo móvil y realiza tus exámenes cerca de tu casa</p>',
      },
      recovery: {
          password: {
              'description': 'Ingresa tu correo electrónico y te enviaremos un  código que te permitirá restablecer la contraseña.',
          },
          user: {
              'description': 'Ingresa tu número de teléfono y te enviaremos un código de recuperación de la cuenta.',
          }
      },
      recovered: {
          'password': {
              'title': 'Crear nueva Contraseña',
              'changeError': 'Lo sentimos hubo un problema con el servidor! Intente nuevamente!',
              'form': {
                  'labels': {
                      'newPass': 'Nueva contraseña',
                      'repeatPass': 'Repetir contraseña',
                  },
                  'validations': {
                      'passLength': 'Mínimo de 8 caracteres',
                      'minRequired': 'La contraseña debe contener al menos una mayúscula, minúscula, carácter especiales y un número.',
                  }
              }
          },
          'user': {
              'title': 'Tu cuenta recuperada es:',
              'authError': 'Error de autenticación. Se ha ingresado un  usuario y/o contraseña incorrecto.',
          }
      }
  },  
  es_MX: {
    signIn: {
      welcomeMessage: 'Que bueno verte :)',
      subMessage: 'Ingresa tu CURP y contraseña',
      serverError: 'Error en el servidor. Intenta nuevamente más tarde.',
      authError: 'Error de autenticación. Has ingresado usuario y/o contraseña incorrectos.',
      items: {
        email: 'CURP',
        password: 'Contraseña',
        advisor: 'Soy Asesor'
      },
      titleLogin:'',
      errors: {
        invalidEmail: 'El correo electrónico es inválido',
        requiredEmail: 'Debes ingresar tus datos',
        requiredPassword: 'Debes ingresar tu contraseña'
      },
    },
    recovery: {
        password: {
          'title': 'Restablecer Contraseña',
          'description': 'Ingresá tu correo electrónico y te enviaremos un código que te permitirá continuar.',
            'form': {
              'labels': {
                'email': 'Correo Electrónico',
                'checksTitle': 'Desafío de seguridad',
                'checkSMS': 'SMS',
                'checkMail': 'Correo electrónico'
              },
              'validations': {
                'emailRequired': 'El email es requerido!',
                'emailInvalid': 'El email es invalido!',
                'emailNonExistent': 'El correo ingresado no existe!'
              }
            }
        },
        user: {
            'description': 'Ingresa tu número de teléfono y te enviaremos un código de recuperación de la cuenta.',
        }
    },
    recovered: {
        'password': {
            'title': 'Crear nueva Contraseña',
            'changeError': 'Lo sentimos hubo un problema con el servidor! Intente nuevamente!',
            'description': 'La contraseña debe contener al manes 8 caracteres, una letra mayúscula y al menos un número',
            'form': {
                'labels': {
                    'newPass': 'Nueva contraseña',
                    'repeatPass': 'Repetir contraseña',
                },
                'validations': {
                    'passLength': 'Mínimo de 8 caracteres',
                    'minRequired': 'La contraseña debe contener al menos una mayúscula, minúscula, carácter especiales y un número.',
                }
            }
        },
        'user': {
            'title': 'Tu cuenta recuperada es:',
            'authError': 'Error de autenticación. Se ha ingresado un  usuario y/o contraseña incorrecto.',
        }
    }
},
  es: {
    signIn: {
      welcomeMessage: '¡Hola! Qué bueno tenerte por aquí.',
      subMessage: 'Ingresá tu DNI y contraseña para acceder.',
      serverError: 'Error en el servidor. Intenta nuevamente más tarde.',
      authError: 'Error de autenticación. Has ingresado usuario y/o contraseña incorrectos.',
      items: {
        email: 'DNI',
        password: 'Contraseña',
        advisor: 'Soy Asesor'
      },
      titleLogin:'<p class="m-0"><span class=""> Aprovechá tu tiempo,</span> <br> <span>Accedé desde cualquier dispositivo</span> <br> Accedé a todos los contenidos las 24 horas, desde cualquier<br> dispositivo móvil y rendí cerca de tu casa</p>',
      errors: {
        invalidEmail: 'El correo electrónico es inválido',
        requiredEmail: 'Debes ingresar tus datos',
        requiredPassword: 'Debes ingresar tu contraseña'
      },
    },
    recovery: {
      password: {
        'title': 'Restablecer Contraseña',
        'description': 'Ingresá tu correo electrónico y te enviaremos un  código que te permitira restablecer la contraseña.',
        'form': {
          'labels': {
            'email': 'Correo Electrónico',
            'checksTitle': 'Recibir código por:',
            'checkSMS': 'SMS',
            'checkMail': 'Correo electrónico'
          },
          'validations': {
            'emailRequired': 'El email es requerido!',
            'emailInvalid': 'El email es invalido!',
            'emailNonExistent': 'El correo ingresado no existe!'
          }
        }
      },
      user: {
        'title': 'Recuperar Cuenta',
        'description': 'Ingresá tu número de teléfono y te enviaremos un código de recuperación de la cuenta.',
        'detail': 'Te enviaremos un SMS al número que nos proporciones, el cual puede tener costos adicionales según tu compañía telefónica.',
        'form': {
          'labels': {
            'phone': 'Teléfono'
          },
          'validations': {
            'phoneRequired': 'El teléfono es requerido!',
            'phoneInvalid': 'El teléfono es invalido!'
          }
        }
      }
    },
    securityCode: {
      'resendCode': 'No recibí el código',
      'password': {
        'title': 'Restablecer Contraseña',
        'description': 'Te enviamos un código de restablecimiento a:',
        'form': {
          'title': 'Ingresa el código recibido',
          'labels': {
            'code': 'Ingresa el código'
          },
          'validations': {
            'codeInvalid': 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!',
            'codeRequired': 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!',
            'codeLength': 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!'
          }
        }
      },
      'user': {
        'title': 'Recuperar Cuenta',
        'description': 'Te enviamos un código de restablecimiento a:',
        'form': {
          'title': 'Ingresa el código recibido',
          'labels': {
            'code': 'Código'
          },
          'validations': {
            'codeInvalid': 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!',
            'codeRequired': 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!',
            'codeLength': 'El codigo es invalido, ingresa nuevamente o solicita un nuevo codigo!'
          }
        }
      }
    },
    recovered: {
      'password': {
        'title': 'Crear nueva Contraseña',
        'changeError': 'Lo sentimos hubo un problema con el servidor! Intente nuevamente!',
        'form': {
          'labels': {
            'newPass': 'Nueva contraseña',
            'repeatPass': 'Repetir contraseña',
          },
          'validations': {
            'passRequired': 'La contraseña es requerida',
            'passLength': 'Mínimo de 8 caracteres',
            'passEqual': 'Las contraseñas deben ser iguales',
            'minRequired': 'La contraseña debe contener al manes 8 caracteres, una letra mayúscula y al menos un número.',
            'passwordMax': 'Máximo 20 caracteres',
            'number':'Numero',
            'special_character':'Carácter especial',
            'capital_letter':'Mayúscula'

          }
        },
        'securityProgressBar': {
          'label': '',
          'categories': {
            'down': 'Bajo',
            'middle': 'Medio',
            'high': 'Alto'
          }
        }
      },
      'user': {
        'title': 'Tu cuenta recuperada es:',
        'authError': 'Error de autenticación. Se ha ingresado un  usuario y/o constraseña incorrecto.',
        'switchAccount': 'Usar otra cuenta',
        'form': {
          'title': 'A continuación, ingresa tu contraseña',
          'labels': {
            'password': 'Contraseña'
          }
        }
      }
    }
  },


  en: {
    signIn: {
      welcomeMessage: 'Start session',
      subMessage: 'Enter RUT or E-mail and Password',
      serverError: 'Server error. Please try again later.',
      authError: 'Authentication error. Incorrect User or Password',
      items: {
        email: 'RUT or E-Mail',
        password: 'Password',
      },
      errors: {
        invalidEmail: 'E-mail is invalid',
        requiredEmail: 'User is required',
        requiredPassword: 'Password is required'
      }
    }
  }
};


export default loginApplicationTranslations
