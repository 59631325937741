<template>
    <OutlinedCard :title="$t('modules.users.update.sectionContact.title')" class="mb-6">
        <v-container fluid>
            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionContact.fields.email') + ' *' }}</span>
                </v-col>
                <v-col sm="4" class="align-start pt-1 pb-3">
                    <v-text-field
                        ref="email"
                        v-model="userData.email.$model"
                        :error-messages="(userData.email.$dirty && userData.email.$anyError) ? $t('modules.users.update.sectionContact.validation_errors.email') : ''"
                        outlined
                        type="text"
                        :label="$t('modules.users.update.sectionContact.fields.email')"
                        @blur="userData.email.$touch()"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionContact.fields.password') }}</span>
                </v-col>
                <v-col sm="8" class="align-start pt-1 pb-3">
                    <Button
                        :disabled="disableWhitenPasswordButton"
                        :disableWhitenPasswordButton="disableWhitenPasswordButton"
                        class="mb-5"
                        outlined
                        white
                        :loading="resetButton.loading"
                        :success="resetButton.success"
                        :error="resetButton.error"
                        :text="$t('modules.users.update.sectionContact.fields.resetPassword')"
                        :successText="$t('modules.users.update.sectionContact.fields.resetedPassword')"
                        errorText="Error"
                        @clicked="warningChangePassword"
                        @end="resetButtonValues"
                    ></Button>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionContact.fields.cellphone') + ' *' }}</span>
                </v-col>
                <v-col sm="4" class="align-start pt-1 pb-3 mb-4">
                    <TelephoneInput
                        ref="cellphone"
                        :value.sync="userData.cellphone.$model"
                        :country.sync="userData.celCountry"
                        required
                        :placeholder="$t('modules.users.update.sectionContact.fields.cellphone')"
                        :error="userData.cellphone.$dirty && userData.cellphone.$invalid"
                        @blur="userData.cellphone.$touch()">
                    </TelephoneInput>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionContact.fields.phone') }}</span>
                </v-col>
                <v-col sm="4" class="align-start pt-1 pb-3 mb-4">
                    <TelephoneInput
                        :value.sync="userData.$model.phone"
                        :country.sync="userData.celCountry"
                        :placeholder="$t('modules.users.update.sectionContact.fields.phone')">
                    </TelephoneInput>
                </v-col>
            </v-row>

            <template v-if="userData.address.$model">
            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionContact.fields.addressName') + ' *' }}</span>
                </v-col>
                <v-col sm="4" class="align-start pt-1 pb-3">
                    <v-text-field
                        ref="street"
                        v-model="userData.address.street.$model"
                        :error-messages="(userData.address.street.$dirty && userData.address.street.$anyError) ? $t('modules.users.update.sectionContact.validation_errors.addressName') : ''"
                        outlined
                        type="text"
                        :label="$t('modules.users.update.sectionContact.fields.addressName')"
                        @blur="userData.address.street.$touch()"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionContact.fields.addressNumber') }}</span>
                </v-col>
                <v-col sm="2" class="align-start pt-1 pb-3">
                    <v-text-field
                        v-model="userData.address.$model.number"
                        outlined
                        type="text"
                        @keypress="$validateIntegerInputNumber($event, userData.address.$model.number, null, 6)"
                        :label="$t('modules.users.update.sectionContact.fields.addressNumber')"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionContact.fields.addressFloor') }}</span>
                </v-col>
                <v-col sm="2" class="align-start pt-1 pb-3">
                    <v-text-field
                        v-model="userData.address.$model.floor"
                        outlined
                        type="text"
                        @keypress="$validateIntegerInputNumber($event, userData.address.$model.floor, 200)"
                        :label="$t('modules.users.update.sectionContact.fields.addressFloor')"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionContact.fields.addressApartment') }}</span>
                </v-col>
                <v-col sm="2" class="align-start pt-1 pb-3">
                    <v-text-field
                        v-model="userData.address.$model.apartment"
                        @keydown="limitInput($event, 3)"
                        outlined
                        type="text"
                        :label="$t('modules.users.update.sectionContact.fields.addressApartment')"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionContact.fields.addressZipCode') }}</span>
                </v-col>
                <v-col sm="2" class="align-start pt-1 pb-3">
                    <v-text-field
                        v-model="userData.address.$model.zipCode"
                        @keydown="limitInput($event, 10)"
                        outlined
                        type="text"
                        :label="$t('modules.users.update.sectionContact.fields.addressZipCode')"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionContact.fields.city') + ' *' }}</span>
                </v-col>
                <v-col sm="4" class="align-start pt-1 pb-3">
                    <AutocompleteInput
                        ref="cityWithState"
                        :value.sync="userData.address.cityWithState.$model"
                        :required="userData.address.cityWithState.$invalid"
                        :errorMessages="$t('modules.users.create.validation_errors.contactForm.city')"
                        :label="`${$t('modules.users.create.contactForm.city')} *`"
                        disable-buttons
                        @save="(val)=>{this.userData.address.cityWithState.$model = val}">
                    </AutocompleteInput>
                </v-col>
            </v-row>
            </template>
        </v-container>
    </OutlinedCard>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
import { $users } from '../../services';

export default {
    name: 'UserEditContactForm',
    props: {
        userData: Object,
        disableWhitenPasswordButton: Boolean,
        isCognitoUser: {type: Boolean, default:false}
    },
    components: {
        OutlinedCard,
        Button,
        TelephoneInput,
        AutocompleteInput,
    },
    data () {
        return {
            title: this.$t('modules.users.update.sectionContact.title'),
            resetButton: {
                loading: false,
                success: false,
                error: false
            },
            menu: false,
        }
    },
    methods: {
        limitInput(event, length) {
            if ((event.keyCode != 8) && (event.keyCode != 9) && (event.target.value.length >= length)) event.preventDefault();
        },
        warningChangePassword(){
            this.$store.dispatch('commons/openPopUp', {
                title: this.$t('modules.users.update.sectionContact.fields.resetPasswordWarning'),
                content: '',
                actionPrimary: {text: this.$t('actions.accept'), callback: () => this.resetPassword()},
                actionSecondary: {text: this.$t('actions.cancel'), callback () {}},
                icon: {color: 'warning', name: 'mdi-alert'},
                color: 'primary',
            })
        },
        async resetPassword() {
            try {
                this.resetButton.loading = true;
                const newPass = await $users.updatePassword(this.$route.params.id, 'new-password');

                this.resetButton.success = true

                if(!this.isCognitoUser){
                this.$store.dispatch('commons/openPopUp', {
                   
                    title: this.$t('modules.users.details.changePassword.success', {newPass: newPass.data.newPassword}),
                    content: '',
                    actionPrimary: {text: this.$t('actions.accept'), callback() {}},
                    icon: { color: 'success', name: 'mdi-check' },
                    color: 'primary',
                });
                }else{
                    this.$store.dispatch('commons/openPopUp', {
                   
                   title: this.$t('modules.users.details.changePassword.cognitoSuccess'),
                   content: '',
                   actionPrimary: {text: this.$t('actions.accept'), callback() {}},
                   icon: { color: 'success', name: 'mdi-check' },
                   color: 'primary',
               });
                }
            } catch {
                this.resetButton.error = true
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.users.details.changePassword.error'),
                    content: '',
                    actionPrimary: {text: this.$t('actions.accept'), callback() {}},
                    icon: { color: 'error', name: 'mdi-close' },
                    color: 'primary',
                });
            } finally {
                this.resetButton.loading = false
            }
        },
        resetButtonValues () {
            this.resetButton.loading = false;
            this.resetButton.success = false;
            this.resetButton.error = false;
        },
    }
}
</script>
