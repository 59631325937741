const moduleControlTranslations = {
    es: {
        table: {
            breadcrumbs: {
                title: 'Control de módulos',
                description: 'Análisis de estado',
            },
            headers: {
                name: 'Nombre',
                description: 'Descripción',
                status:'Estado'
            },
            asidebar:{
                title: 'Módulos',
                academic: 'Académica',
                pricing: 'Administrador de Precios',
                student: 'Estudiantes',
                qSystem: 'Exámenes',
                canvas: 'Canvas',
                commission: 'Comisiones',
                payment: 'Contratos',
            },
            drawer: {
                filter:{
                    label: 'Periodo',
                    placeholder: 'Seleccione un Periodo'
                }
            }
        },
    },
    en: {
    },
};

export default moduleControlTranslations;