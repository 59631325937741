<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t(`modules.portalalumno.procedures.procedureDefiniteLow.breadcrumb.title`)"
        :description="$t(`modules.portalalumno.procedures.procedureDefiniteLow.breadcrumb.description`)"
        isDescriptionFullLength
        :callback="goBack"
      ></Breadcrumbs>
      <v-row v-if="!createNewProcedureDefiniteLowStatus">
        <v-col class="viewSpaces" cols="12">
          <v-row class="mb-4">
            <v-col sm="4" cols="4">
              <v-autocomplete
                ref="selectedCareerRecord"
                v-model="$v.selectedCareerRecord.$model"
                outlined
                :error-messages="($v.selectedCareerRecord.$dirty && $v.selectedCareerRecord.$invalid) ?
                $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.careerRequired') : ''"
                :label="$t(`modules.portalalumno.procedures.procedureDefiniteLow.autocomplete.label`)"
                :loading="careersLoading"
                :items="careers"
                item-text="career.description"
                item-value="idStudentCareer"
                @blur="$v.selectedCareerRecord.$touch()"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <OutlinedCard
              title=""
              subtitle=""
              class="mb-6">
              <v-row class="mb-6">
                <v-col sm="12" class="py-0">
                  <v-card-text class=" body-1 pa-0 primary--text font-weight-bold">
                    {{ $t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.title`) }}
                  </v-card-text>
                  <v-card-text class=" body-2 pa-0 gray--text ">
                    {{ $t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.subtitle`) }}
                  </v-card-text>
                </v-col>
                <v-col sm="4" cols="4">
                  <v-autocomplete
                    ref="selectedProcessingReason"
                    v-model="$v.selectedProcessingReason.$model"
                    outlined
                    :error-messages="($v.selectedProcessingReason.$dirty && $v.selectedProcessingReason.$invalid) ?
                    $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.processingReasonRequired') : ''"
                    :label="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.autocompleteMotivo.label`)"
                    :loading="processingReasonsLoading"
                    :items="processingReasons"
                    item-text="meaning"
                    @blur="$v.selectedProcessingReason.$touch()"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="isRefundable && !isNI" cols="10">
                  <v-alert
                    text
                    prominent
                    type="info"
                    icon="mdi-information-outline"
                  >
                    <strong>
                      {{ $t('modules.portalalumno.procedures.procedureDefiniteLow.alert') }}
                    </strong>
                  </v-alert>
                </v-col>
                <v-col cols="10">
                  <v-textarea
                    ref="observation"
                    v-model="$v.observation.$model"
                    :error-messages="($v.observation.$dirty && $v.observation.$invalid) ?
                    $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.observationRequired') : ''"
                    :label="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.textarea.label`)"
                    :placeholder="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.textarea.placeholder`)"
                    name="input-7-4"
                    outlined
                    @blur="$v.observation.$touch()"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <template v-if="isRefundable && isNI">
                <v-row class="mt-6">
                  <v-col cols="10" sm="10">
                    <OutlinedCard
                      :title="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.title`)"
                      :subtitle="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.subtitle`)"
                      class="mb-6"
                      :shadow="false"
                    >
                      <v-row>
                        <v-col>
                          <v-switch
                            v-model="isHolder"
                            color="primary"
                            class="pt-0"
                            :label="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.switch`)"
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row v-if="!isHolder">
                        <v-col cols="12" class="pe-12">
                          <v-textarea
                            ref="observationNotOwnAccount"
                            v-model="$v.observationNotOwnAccount.$model"
                            :error-messages="($v.observationNotOwnAccount.$dirty && $v.observationNotOwnAccount.$invalid) ?
                            $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.observationRequired') : ''"
                            :label="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.textarea.label`)"
                            :placeholder="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.textarea.placeholderNotOwnAccount`)"
                            name="input-7-4"
                            outlined
                            @blur="$v.observationNotOwnAccount.$touch()"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" sm="4">
                          <v-text-field
                            ref="accountHolder"
                            v-model="$v.accountHolder.$model"
                            outlined
                            v-mask="'##-########-#'"
                            :error-messages="($v.accountHolder.$dirty && $v.accountHolder.$invalid) ?
                            ((!$v.accountHolder.required) ? $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.accountHolderRequired') :
                            $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.accountHolderFormat')) : ''"
                            :label="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.inputCuit.label`)"
                            :placeholder="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.inputCuit.placeholder`)"
                            @blur="$v.accountHolder.$touch()"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" sm="6">
                          <v-text-field
                            ref="accountCBU"
                            v-model="$v.accountCBU.$model"
                            outlined
                            v-mask="'######################'"
                            :error-messages="($v.accountCBU.$dirty && $v.accountCBU.$invalid) ?
                            ((!$v.accountCBU.required) ? $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.accountCBURequired') :
                            $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.accountCBUFormat')) : ''"
                            :label="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.inputCBU.label`)"
                            :placeholder="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.inputCBU.placeholder`)"
                            @blur="$v.accountCBU.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="5" sm="5">
                          <v-text-field
                            ref="bank"
                            v-model="$v.bank.$model"
                            outlined
                            :error-messages="($v.bank.$dirty && $v.bank.$invalid) ?
                            ((!$v.bank.required) ? $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.bankRequired') :
                            $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.bankMaxLength')) : ''"
                            :label="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.inputBanco.label`)"
                            :placeholder="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.inputBanco.placeholder`)"
                            @blur="$v.bank.$touch()"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </OutlinedCard>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </template>
              <v-row class="mt-6">
                <v-col cols="12">
                  <div>
                    <h4 class="title font-weight-bold">{{ $t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.dropfile.title`) }}</h4>
                    <h4 class="body-2">{{ $t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.dropfile.${isRefundable && isNI ? 'subtitleRefund' : 'subtitlePending'}`) }}</h4>
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center" class="mb-6">
                <v-col cols="10">
                  <DropFiles
                    ref="documentation"
                    extension=".jpg, .png, .pdf"
                    :type-file="['image/*', 'application/pdf']"
                    limitSizeBytes="25000000"
                    multiple
                    :required="$v.documentation.$anyError"
                    :value="documentation"
                    :docTypes="isRefundable ? (isNI ? [{ id: 8, name: 'Constancia CBU' }, { id: 6, name: 'Reembolso' }] : [{ id: 7, name: 'Reembolso Fuerza Mayor' }]) : [{ id: 12, name: 'Soporte Baja' }]"
                    :truncate-text-length="80"
                    @input="(files) => { this.documentation = files }"
                  />
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="mb-6 mt-6">
                <v-col cols="12 " class="mb-0 py-0">
                  <v-row class="mb-0">
                    <v-col sm="12" class="py-0 mb-0">
                      <v-card-text class=" body-1 pa-0 primary--text font-weight-bold">
                        {{ $t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.seccionContacto.title`) }}
                      </v-card-text>
                    </v-col>
                    <v-col cols="4" sm="4" class="mb-0">
                      <TelephoneInput
                        ref="contactPhone"
                        :value.sync="$v.contactPhone.$model"
                        :placeholder="$t(`modules.portalalumno.procedures.procedureDefiniteLow.OutlineCard.outlineCardDatosBancarios.seccionContacto.inputContacto`)"
                        :required="$v.contactPhone.$dirty && $v.contactPhone.$invalid"
                        @blur="$v.contactPhone.$touch()"
                        @validate="isValidPhone"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="mt-0 py-0">
                  <v-row class="mt-0">
                    <v-col sm="4" cols="4" class="py-0">
                      <v-menu
                        ref="time"
                        v-model="timeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="$t(`modules.portalalumno.procedures.procedureTemporaryLow.OutlineCard.contacto.inputTime.title`)"
                            :error-messages="($v.selectedTime.$dirty && $v.selectedTime.$invalid) ?
                            $t('modules.portalalumno.procedures.procedureDefiniteLow.validations.timeRequired') : ''"
                            @blur="$v.selectedTime.$touch()"
                            append-icon="mdi-calendar-clock"
                            class="no-message mt-2"
                            v-model="selectedTime"
                            v-on="on"
                            readonly
                            outlined
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="timeMenu"
                          :value="selectedTime"
                          @click:hour="selectHour"
                          class="no-message"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </OutlinedCard>
          </v-row>
        </v-col>
        <v-col cols="11" class="margin-bot">
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="cancel"
              class="mr-4"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.create')"
              :successText="$t('actions.created')"
              :errorText="$t('actions.error')"
              @clicked="validateProcedureDefiniteLow"
            ></Button>
          </div>
        </v-col>
      </v-row>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :subtitle="successMessage.subtitle"
        :actionPrimary="successMessage.actionPrimary"
      />
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import DropFiles from '@/components/DropFiles/DropFiles';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {$students, $studentProcessing, $types} from '../services';
import {helpers, maxLength, required, requiredIf} from 'vuelidate/lib/validators';
import {mapGetters} from 'vuex';

export default {
  name: 'ProcedureDefiniteLow',
  components: {
    Breadcrumbs,
    OutlinedCard,
    DropFiles,
    TelephoneInput,
    Button,
    SuccessMessage
  },
  data() {
    return {
      createNewProcedureDefiniteLowStatus: false,
      procedureId: 0,
      documentUploadIndex: 0,
      careers: [],
      careersLoading: false,
      selectedCareerRecord: null,
      processingReasons: [],
      processingReasonsLoading: false,
      selectedProcessingReason: null,
      observation: null,
      isHolder: true,
      observationNotOwnAccount: null,
      accountHolder: null,
      accountCBU: null,
      bank: null,
      documentation: null,
      requiredDocuments: [{ id: 5, name: 'Recibo de sueldo' }],
      contactPhone: '',
      telephoneValid: true,
      timeMenu:false,
      selectedTime: null,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        subtitle: null,
        actionPrimary: null
      }
    }
  },
  validations: {
    selectedCareerRecord: { required },
    selectedProcessingReason: { required },
    observation: { required },
    observationNotOwnAccount: { required: requiredIf(function () { return !this.isHolder }) },
    accountHolder: {
      required: requiredIf(function () { return this.isRefundable && this.isNI }),
      accountHolderFormat: helpers.regex('accountHolderFormat', /^\d{2}-\d{8}-\d$/i),
    },
    accountCBU: {
      required: requiredIf(function () { return this.isRefundable && this.isNI }),
      accountCBUFormat: helpers.regex('accountCBUFormat', /^\d{22}$/i),
    },
    bank: {
      required: requiredIf(function () { return this.isRefundable && this.isNI }),
      maxLength: maxLength(30)
    },
    documentation: { required },
    contactPhone: {
      required,
      valid() {
        return this.telephoneValid;
      }
    },
    selectedTime: {required}
  },
  computed: {
    isRefundable() {
      return this.$route.params.procedureId == 'REFUND'
    },
    isNI() {
      return this.studentData.studentType.meaning.toUpperCase() == 'INGRESANTE'
    },
    ...mapGetters({
      studentData: 'commons/getStudentData',
      userName: 'commons/getUserName',
      studentUserId: 'commons/getStudentUserID'
    }),
  },
  mounted() {
    this.getCareers();
    this.getProcessingReasons();
  },
  methods: {
    async getCareers() {
      this.careersLoading = true;
      try {
        const {data} = await $students.filter([54], {}, `careers/${this.studentData.student.id}`);
        this.careers = data;
      } catch (err) {
        this.careers = [];
        throw err;
      } finally {
        this.careersLoading = false;
      }
    },
    async getProcessingReasons() {
      try {
        this.processingReasonsLoading = true;
        const {data} = await $types.find(null, null, {params: {type: this.isNI ? 'DEFINITIVE_NI_UNSUBSCRIBE_PROCESSING' : 'DEFINITIVE_RI_UNSUBSCRIBE_PROCESSING'}});
        this.processingReasons = data;
      } catch (err) {
        this.processingReasons = [];
        throw err;
      } finally {
        this.processingReasonsLoading = false;
      }
    },
    isValidPhone(phoneObject) {
      this.telephoneValid = phoneObject.isValid;
      this.$refs.contactPhone.handleInput(this.contactPhone, phoneObject);
    },
    goBack() {
      this.$trackEvent('Tramites', 'Cancelar', 'Retiro');
      this.$router.push('/procedures');
    },
    async validateProcedureDefiniteLow() {
      this.createButton.loading = true;
      if (this.$v.$invalid) {
        await this.$v.$touch();
        this.$refs.contactPhone.handleBlur()
        Object.keys(this.$v).some(input => {
          if (input.includes('$')) return false;
          if (this.$v[input].$error) {
            this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
            return true;
          }
        });
        this.createButton.error = true;
        this.createButton.loading = false;
      } else {
        this.createProcedureDefiniteLow(this.documentUploadIndex);
      }
    },
    async createProcedureDefiniteLow(index) {
      try {
        if (index != this.documentation.length) {
          const formData = new FormData();
          const newDefiniteLow = {
            reason: this.selectedProcessingReason,
            user: this.studentUserId ? this.studentUserId : null,
            studentId: this.studentData.student.id,
            studentCareerId: this.selectedCareerRecord,
            userName: this.userName,
            processingKey: 'PERMANENT_DEREGISTRATION',
            comments: this.observation,
            telephoneContact: this.contactPhone,
            contactTime: this.selectedTime,
            cuil: this.accountHolder,
            cbu: this.accountCBU,
            bank: this.bank,
            justificationNotOwnAccount: this.observationNotOwnAccount,
            studentProcessingId: this.procedureId,
          };
          formData.append('model', JSON.stringify(newDefiniteLow));
          formData.append('documentation', this.documentation[index].file);
          formData.append('idDocumentation', this.documentation[index].docType.id);
          formData.append('partial', '1');
          const response = await $studentProcessing.formData(formData, 'definitive-low');
          if (response.ok) {
            if (!this.procedureId) {
              this.procedureId = (await response.json()).processingId;
            }
            this.createProcedureDefiniteLow(++this.documentUploadIndex);
          } else {
            throw 'Error'
          }
        } else {
          if(this.$isTeclab) {
            this.$trackEvent('Tramites', 'Clk_BtnEnvBajDf_Tram', 'BajaDef');
          }
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.portalalumno.procedures.procedureDefiniteLow.successMessage.title');
          this.successMessage.subtitle = this.$t('modules.portalalumno.procedures.procedureDefiniteLow.successMessage.subtitle');
          this.successMessage.actionPrimary = {
            text: this.$t('actions.close'), callback: () => {
              this.$router.push('/procedures');
            }
          };
          this.createNewProcedureDefiniteLowStatus = true;
          this.createButton.success = true;
          this.createButton.loading = false;
        }
      } catch (err) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.portalalumno.procedures.procedureDefiniteLow.errorMessage.uploadDocument');
        this.successMessage.subtitle = null;
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.createNewProcedureDefiniteLowStatus = false;
          }
        };
        this.successMessage.actionSecondary = null;
        this.createNewProcedureDefiniteLowStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        throw err;
      }
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    selectHour (val) {
      this.selectedTime = val < 10 ? `0${val}:00`: `${val}:00`;      
      this.timeMenu = false
    },
    cancel() {
      if(this.$isTeclab) {
        this.$trackEvent('Tramites', 'Clk_BtnBackBajDf_Tram', 'BajaDef');
      }
      this.$router.replace('/procedures');
    },
  },
};
</script>


