<template>
    <v-container fluid class="pa-0 scrollableContent">
        <v-container class="container-custom px-6">
            <Breadcrumbs :title="$t('modules.academic.gradebook.breadcrumbs.title')"
                :description="$t('modules.academic.gradebook.breadcrumbs.subtitle')">
                <Button class="px-2" @clicked="downloadPdf" depressed
                    :text="$t('modules.academic.gradebook.action.export')"></Button>
            </Breadcrumbs>
            <v-card outlined rounded v-if="student" class="mt-4">
                <span class="d-flex justify-left primary--text text-h6 mx-4 mt-4">{{
                    `${$t('modules.academic.gradebook.sheet')}
                                    ${student.libroMatriz.folio}` }}</span>
                <span class="d-flex justify-left mx-4">{{ `${$t('modules.academic.gradebook.bookSheet')}
                                    ${student.libroMatriz.libro}` }}</span>

                <span class="statusText mx-2">
                    <div class>{{ $t('modules.academic.gradebook.studentStatus') }}</div>
                    <v-chip :class="setColor" class="mx-2 white--text">{{ student.carrera.status }}</v-chip>
                </span>

                <span class="d-flex justify-left black--text text-h6 px-4"> {{ student.alumno.apellido }}
                    {{ student.alumno.nombres }} </span>
                <span class="d-flex justify-left pb-4 px-4 mb-4 black--text text-h6">{{
                    `${$t('modules.academic.gradebook.documentation')}
                                    ${student.alumno.nroDoc}` }} </span>
                <span class="d-flex justify-left px-4 py-2">{{ `${$t('modules.academic.gradebook.paragraph1')}
                                    ${student.alumno.apellido} ${student.alumno.nombres}, ${$t('modules.academic.gradebook.paragraph2')}
                                    ${student.alumno.localidadDeNacimiento.descripcion ? student.alumno.localidadDeNacimiento.descripcion :
                        ''}, ${student.alumno.localidadDeNacimiento.provincia.descripcion ?
                            student.alumno.localidadDeNacimiento.provincia.descripcion : ''}
                                    ${$t('modules.academic.gradebook.paragraph3')} ${student.alumno.fechaNac}
                                    ${$t('modules.academic.gradebook.paragraph4')} ${student.alumno.nroDoc}
                                    ${$t('modules.academic.gradebook.paragraph5')}` }}</span>
                <span class="d-flex justify-left black--text px-4 pb-2">{{ `${$t('modules.academic.gradebook.paragraph6')}
                                    ${student.carrera.descripcion}, ${$t('modules.academic.gradebook.paragraph7')}
                                    ${student.carrera.plan.resolucion}, ${$t('modules.academic.gradebook.paragraph8')}
                                    ${dateInitial.from}` }}</span>
                <span class="d-flex justify-left black--text px-4 pb-4">{{ `${$t('modules.academic.gradebook.paragraph9')}
                                    ${student.id}` }}</span>
                <StudentTable :headers="headers" :items="subjectsPerYear" :studentCareerId="student.id"
                    :states="statePerYear" :student="student"></StudentTable>
            </v-card>
        </v-container>
    </v-container>
</template>
<style scoped lang="sass">
    .statusText
        font-family: Roboto, Bold
        font-size: 14px
        color:#727272
        justify-content: right
        display: flex
        align-items: center

</style>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import StudentTable from '../Components/StudentTable';
import { $getStudentSheet, $reports } from '../Services';
import moment from 'moment';
import download from 'downloadjs'
export default {
    name: 'StudentSheet',
    components: {
        Breadcrumbs,
        StudentTable,
        Button
    },
    data() {
        return {
            student: null,
            subjectsPerYear: [],
            dateInitial: {},
            search: null,
            completed: null,
            statePerYear: [],
            average: [],
            headers: [
                { text: this.$t('modules.academic.gradebook.headers.subject'), value: 'descripcion' },
                { text: this.$t('modules.academic.gradebook.headers.inNumber'), value: 'examFinales.notaDefinitiva' },
                { text: this.$t('modules.academic.gradebook.headers.inWord'), value: 'examFinales.notaDefinitivaEnLetras' },
                { text: this.$t('modules.academic.gradebook.headers.book'), value: 'libro' },
                { text: this.$t('modules.academic.gradebook.headers.sheet'), value: 'folio' },
                { text: this.$t('modules.academic.gradebook.headers.date'), value: 'examFinales.examen.fecha' },
            ],
        }
    },
    computed: {
        setColor() {
            let color = null
            switch (this.student.carrera.status) {
                case 'Cursando':
                    color = 'green'
                    break;
                case 'Baja':
                    color = 'red'
                    break
            }
            return color
        }
    },
    methods: {
        async downloadPdf() {
            const { headers, data } = await $reports.academic.find('downloadSingleInvoice', null, { params: { careerId: this.$route.params.id, book: this.$route.params.book, studentCareerId: this.student.id, resolution: this.student.carrera.plan.resolucion } })
            download(data, `Alumno ${this.student.alumno.apellido} ${this.student.alumno.nombres}.pdf`, headers.get('content-type'))
        },
        async fetchData() {
            try {
                this.loading = true
                const params = {
                    careerId: this.$route.params.id,
                    book: this.$route.params.book,
                    invoice: this.$route.params.invoice
                }
                const { data } = await $getStudentSheet.find(null, null, { params });
                this.student = data.pop();
                this.student.alumno.fechaNac = moment(this.student.alumno.fechaNac).format('DD-MM-YYYY')
                this.subjectsPerYear = this.student.carrera.materias.reduce((acc, subject) => {
                    const year = Math.floor((subject.cuatrimestre + 1) / 2) - 1
                    if (acc?.[year]) {
                        acc[year].push(subject)
                    }
                    else {
                        acc[year] = [subject]
                    }
                    return acc
                }, [])
                const auxOne = {
                    materias: this.subjectsPerYear[0].map(materia => {
                        return { ...materia, libro: this.student.libroMatriz.libro, folio: this.student.libroMatriz.folio, }
                    }), statusOne: this.student.carrera.statusPrimerAno, comentario: this.student.libroMatriz.primerObservacion
                }
                if (this.subjectsPerYear[1]) {
                    const auxTwo = {
                        materias: this.subjectsPerYear[1],
                        statusTwo: this.student.carrera.statusSegundoAno, comentario: this.student.libroMatriz.segundaObservacion
                    }
                    this.subjectsPerYear = [auxOne, auxTwo]
                    this.subjectsPerYear.forEach((aux) => {
                        this.statePerYear.push(aux.statusOne ? 'Completo' : 'Incompleto' && aux.statusTwo ? 'Completo' : 'Incompleto')
                    })
                }
                else {
                    this.subjectsPerYear = [auxOne]
                    this.subjectsPerYear.forEach((aux) => {
                        this.statePerYear.push(aux.statusOne ? 'Completo' : 'Incompleto')
                    })
                }
                this.dateInitial.from = moment(this.student.fechaIngreso).format('YYYY')

                this.subjectsPerYear
                    .forEach(({ materias }) => {
                        materias.forEach(({ examFinales }) => {
                            const { fecha } = examFinales.examen
                            examFinales.examen.fecha = moment(fecha).format('DD-MM-YYYY')
                        })
                    })
            }
            catch (e) {
                this.student = null;
                return e;
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.fetchData();
    },
}
</script>