import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('commission');

export class SalesBySeller extends Api {
    constructor(http, config = {}) {
        super(http, url, 'sales-by-seller', config);
    }
}
