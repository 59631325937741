import ChangeStudyPlan from '../ChangeStudyPlan.vue'
import ChangeStudyPlanTable from '../Views/ChangeStudyPlanTable.vue'


const ChangeStudyPlanRoute =
{
    path: '/study-plan',
    meta: {
        name: {
        en: 'Change of study plan',
        es: 'Cambio de plan de estudio'
        }
    },
    component: ChangeStudyPlan,
    children: [
        {
        path: '/',
        component: ChangeStudyPlanTable,
        meta: { name: 'Index', group: 'portaladministrativo.students', path: 'change_study_plan'}
        }
    ]
    };

export default ChangeStudyPlanRoute