import { render, staticRenderFns } from "./BirthdayDatePicker.vue?vue&type=template&id=64eb5008&"
import script from "./BirthdayDatePicker.vue?vue&type=script&lang=js&"
export * from "./BirthdayDatePicker.vue?vue&type=script&lang=js&"
import style0 from "./BirthdayDatePicker.vue?vue&type=style&index=0&id=64eb5008&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports