<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :title="$t('modules.statusconnection.table.breadcrumbs.title')"
                    :description="$t('modules.statusconnection.table.breadcrumbs.description')"
                >
                </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            :headers="headers"
                            :data="services"
                            @check="getCheck"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
    import api from '@/api';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
    import SuperTable from '../Components/SuperTable/SuperTable';

    export default {
        components: { 
            Breadcrumbs,
            SuperTable,
        },
        data(){
            return{
                services: [],
                headers: [
                    { text: this.$t('modules.statusconnection.table.headers.status'), value: 'status', align: 'center', sortable: false },
                    { text: this.$t('modules.statusconnection.table.headers.name'), value: 'service', align: 'center' },
                    { text: this.$t('modules.statusconnection.table.headers.version'), value: 'version', align: 'center' },
                    { text: '', value: 'action', sortable: false },
                ],
            }
        },
        mounted(){
           this.getService();
        },
        methods:{
            getService(){
                this.services = Object.entries(api).filter(service => !!service[1].$checkStatus)
                                    .map(service => {
                                        return {
                                            id: service[0],
                                            status: null,
                                            service: this.$t(`modules.statusconnection.table.service.${service[0]}.title`),
                                            name: '',
                                            version: '',
                                        }
                                    })

                this.services.forEach(service => {
                    this.getCheck(service)
                })
            },
            async getCheck(service){
                try {
                    service.status = null
                    const { data, status } = await api[service.id].$checkStatus.find()
                    service.name = data.name
                    service.version = data.version
                    if (status === 200) {
                        service.status = true
                    }
                } catch {
                    service.status = false
                }
            },
        }
   }
</script>