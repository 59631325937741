<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs :title="`${this.$route.query.code} - ${this.$route.query.description}`">
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <ProgramTable :headers="headers" :sections="sections" :clear-selected="clearSelected" :show-select="false"
              :page.sync="page" :totalElements="totalItems" @createProgram="createProgram" @updateProgram="updateProgram"
              @uploadStatement="uploadStatement" />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import ProgramTable from '../Components/ProgramsTable'
import { $statements } from '../Services/index'

export default {
  name: 'Programs',
  components: {
    ProgramTable,
    Breadcrumbs,
  },
  data() {
    return {
      sections: [],
      page: 1,
      headers: [{ text: '', value: 'id' }, { text: '', value: 'code' }, { text: '', value: 'description' }],
      totalItems: 0,
      paramSearch: null,
      paramPeriod: null,
      clearSelected: null,
    }
  },
  mounted() {
    this.getPrograms();
  },
  methods: {
    async getPrograms() {
      try {
        const params = { page: 0, length: 500, orderBy: 'description', orientation: 'asc' }
        const { data } = await $statements.find(`programs/${this.$route.params.subject_id}`, null, { params })
        this.totalItems = data?.totalElements;
        this.sections = data?.content
      } catch {
        this.programs = []
      }
    },
    createProgram() {
      if (!this.canCreate) return
      this.$router.push(`${this.$route.params.subject_id}/create`)
    },
    updateProgram(id, code, description) {
      if (!this.canUpdate) return
      this.$router.push({ path: `update/${id}`, query: { code, description } })
    },
    uploadStatement(id) {
      if (!this.canUpdate) return
      const query = {
        code: this.$route.query.code,
        description: this.$route.query.description
      }
      this.$router.push({ path: `/exams/statements/contents/${id}`, query })
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.exams.statements.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.exams.statements.UPDATE
    },
  },
}
</script>
