const studyPlanTranslations = {
    es_CL: {
      studyPlanView: {
        subtitle:  'Haz click en la asignatura para ver sus prerrequisito.',
        requirement: 'Prerrequisitos',
        requirement_of: 'Es prerrequisito de',
        requirement_view: 'Ver prerrequisitos',
        no_requirement: 'Esta asignatura no tiene prerrequisitos',
        no_requirement_of: 'Esta asignatura no es prerrequisito de ninguna otra',
        period:'Semestre {periodType}',
        subjectsStates: {
          reproved: 'REPROBADA',
          available: 'HABILITADA'
        }
      }
    },
    es_PE: {
        studyPlanView: {
            description: 'Acá podrás visualizar los cursos que tienes que cursar durante tu carrera.',
            subtitle: 'Haz click en el curso para ver sus prerrequisitos.',
            requirement: 'Prerrequisito',
            requirement_of: 'Es prerrequisito de',
            requirement_view: 'Ver prerrequisitos',
            no_requirement: 'Este curso no tiene prerrequisitos',
            no_requirement_of: 'Este curso no es prerrequisito de ningún otro',
        }
    },
    es_MX: {
      studyPlanView: {
          description: 'Aquí podrás visualizar las materias que tienes que cursar durante tu carrera.',
          subtitle: 'Haz click en la materia para ver sus prerrequisitos.',
          requirement: 'Prerrequisito',
          requirement_of: 'Es prerrequisito de',
          requirement_view: 'Ver prerrequisitos',
          no_requirement: 'Esta materia no tiene prerrequisitos',
          no_requirement_of: 'Esta materia no es prerrequisito de ningún otro',
      }
  },
    es: {
      studyPlanView: {
        title: 'Plan de estudios',
        description:  'Acá podrás visualizar las materias que tienes que cursar durante tu carrera.',
        subtitle:  'Haz click en la materia para ver sus correlativas.',
        bimesterA: 'Bimestre A',
        bimesterB: 'Bimestre B',
        requirement: 'Correlativas',
        requirement_of: 'Es correlativa de',
        requirement_view: 'Ver correlativas',
        no_requirement: 'Esta materia no tiene correlativas',
        no_requirement_of: 'Esta materia no es correlativa de ninguna otra',
        period:'Cuatrimestre {periodType}',
        semestersHeaders: {
          first: 'Primer',
          second: 'Segundo',
          third: 'Tercer',
          fourth: 'Cuarto',
          fifth: 'Quinto',
          sixth: 'Sexto',
          seventh: 'Séptimo',
          eighth: 'Octavo',
          ninth: 'Noveno',
          tenth: 'Decimo',
          eleventh: 'undécimo',
          twelfth: 'duodécimo',
          thirteenth: 'decimotercero',
          fourteenth: 'decimocuarto',
          fifteenth: 'decimoquinto',
          sixteenth: 'decimosexto'
        },
        ordinal : ['primer', 'segundo', 'tercer', 'cuarto', 'quinto', 'sexto', 'septimo', 'octavo', 'noveno'],
        ordinal_2: ['decimo', 'vigesimo', 'trigesimo'],
        subjectsStates: {
          aproved: 'APROBADA',
          reproved: 'DESAPROBADA',
          studying: 'EN CURSO',
          noStudying: 'NO CURSADO',
          available: 'REGULAR',
        }
      }
    },
    en: {
      view: {
        //
      }
    }
  };
  
  
  export default studyPlanTranslations