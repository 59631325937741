<template>
    <section class="overflow-y-visible">
        <article v-if="!enableSuccessMessage">
            <v-card outlined class=" ma-4 pa-4" v-if="!billingOnly">
                <v-row>
                    <v-col>
                        <v-text-field 
                            v-if="!billingOnly"
                            cols="6"
                            outlined
                            ref="identifier"
                            :error-messages="$v.identifier.$invalid && $v.identifier.$dirty ? $t('modules.prices.create.section_zone.items.price_error') : ''"
                            :min="0"
                            type="number"
                            :label="$t('modules.exceptions.payments.bankTransferPanel.voucherNumber')"
                            v-model.number="$v.identifier.$model"
                            @blur="$v.identifier.$touch()"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                :disabled="billingOnly"
                                v-model="$v.date.$model"
                                :label="$t('modules.exceptions.payments.bankTransferPanel.dateTransfer')"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                ref="date"
                                :error-messages="$v.date.$invalid && $v.date.$dirty ? $t('modules.prices.create.section_zone.items.price_error') : ''"
                                @blur="$v.date.$touch()"
                                />
                            </template>
                            <v-date-picker
                                :disabled="billingOnly"
                                v-model="$v.date.$model"
                                @input="menu = false"
                                :max="new Date().toISOString().substr(0, 10)"
                                @blur="$v.date.$touch()"
                            />
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row class="mx-10">
                    <v-col class="d-flex align-center font-weight-bold">{{ $t('modules.exceptions.payments.bankTransferPanel.id') }}</v-col>
                    <v-col class="d-flex align-center font-weight-bold">{{ $t('modules.exceptions.payments.bankTransferPanel.description') }}</v-col>
                    <v-col class="d-flex align-center font-weight-bold">{{ $t('modules.exceptions.payments.bankTransferPanel.amount') }}</v-col>
                    <v-col class="d-flex align-center font-weight-bold">{{ $t('modules.exceptions.payments.bankTransferPanel.breakDown') }}</v-col>
                </v-row>
                <v-row v-for="(payment, index) in $v.paymentsInput.$each.$iter" :key="index" class="mx-10">
                    <v-col class="d-flex align-center"> {{ payments[index].id }} </v-col>
                    <v-col class="d-flex align-center"> {{ payments[index].accountDescription }} </v-col>
                    <v-col class="d-flex align-center"> {{ payments[index].paymentAmount | $currency }} </v-col>
                    <v-col class="d-flex align-center">
                        <v-text-field 
                            outlined
                            :error-messages="payment.$invalid && payment.$dirty ? $t('modules.prices.create.section_zone.items.price_error') : ''"
                            :min="0"
                            ref="paymentsInput"
                            @keypress="$validateDecimalInputNumber($event, paymentsInput[index], payments[index].paymentAmount)"
                            v-model.number="paymentsInput[index]"
                            :label="`${payments[index].paymentAmount}`"
                            @blur="payment.$touch()">
                          <template v-slot:prepend>
                            <span>{{ $countryConstants.currency }}</span>
                          </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <div class="total-wrapper">
                    <v-row outlined color="#6087e1" class="mx-10">
                        <v-col cols="6" class="d-flex align-center total primary--text">Total</v-col>
                        <v-col cols="3" class="d-flex align-center total-currency primary--text">{{ detailsTotalPrices | $currency }}</v-col>
                        <v-col cols="3" class="d-flex align-center total-currency primary--text">{{ inputTotalPrices | $currency }}</v-col>
                    </v-row>
                </div>
            </v-card>
            <!-- elimine el la validacion de si no es mexico en el v-card -->
            <v-card outlined class=" ma-4 mt-14 mb-8 pb-6 pt-0">
                <v-col>
                    <template v-if="$isTeclab">
                        <PaymentBillingDataTeclab
                            ref="billingData"
                            @update="(data) => { billingData = data }"
                            @validate="(val) => { billingDataValid = val }"
                            :react="updateData"
                        ></PaymentBillingDataTeclab>
                    </template>
                    <template v-else>
                        <PaymentBillingData
                            ref="billingData"
                            @update="(data) => { billingData = data }"
                            @validate="(val) => { billingDataValid = val }"
                        ></PaymentBillingData>
                    </template>
                </v-col>
            </v-card>
            <div class="d-flex justify-end mr-16 mb-10">
                <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="closeDrawer"
                    class="mr-4"
                ></Button>
                <Button
                    :loading="createButton.loading"
                    :success="createButton.success"
                    :error="createButton.error"
                    :text="$t('actions.create')"
                    :successText="$t('actions.created')"
                    :errorText="$t('actions.error')"
                    :disabled="!billingDataValid"
                    @clicked="saveBillingData"
                ></Button>
            </div>
        </article>
        <SuccessMessage
            v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
        />
    </section>
</template>
<script>
import Button from '@/components/Button/Button';
import { $transfer, $process } from '../Services';
import { required } from 'vuelidate/lib/validators';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import PaymentBillingData from '../Components/PaymentBillingData/PaymentBillingData';
import PaymentBillingDataTeclab from '../Components/PaymentBillingData/PaymentBillingDataTeclab';
import { mapGetters } from 'vuex';
//import moment from 'moment/moment';
    export default {
        name:'BankTransferPanel',
        components: {
            Button,
            SuccessMessage,
            PaymentBillingDataTeclab,
            PaymentBillingData,
        },
        props: {
            payments: Array,
            hideInstallments: {type: Boolean, default: false},
            billingOnly: Boolean,
            userId: String,
        },
        inject: ['studentId'],
        data() {
            return {
                createButton: {
                    loading: false,
                    success: false,
                    error: false,
                },
                updateData:true,
                paymentsInput: [],
                date: null,
                menu: false,
                identifier: null,
                successMessage: {
                    type: 'success',
                    title: '',
                    actionPrimary: { text: '', callback() {} },
                },
                enableSuccessMessage: false,
                billingDataValid: false,
                billingData: null,
            }
        },
        computed: {
            detailsTotalPrices() {
                let total = 0;
                this.payments.forEach(payment => {
                    total += payment.paymentAmount;
                });
                return Number.parseFloat(total);
            },
            inputTotalPrices() {
                let total = 0;
                this.paymentsInput.forEach(payment => {
                    total += payment;
                });
                return Number.parseFloat(total);
            },
            
            ...mapGetters({
                user:'commons/getUserName',
                
            }) 
        },
        methods: {
            closeDrawer(){
                this.$emit('close')
            },
            initialize() {
                this.reset();
                // this.setBillingDate();
                for( let i = 0; i < this.payments.length; i++ ) {
                    this.paymentsInput.push('');
                }
                this.$v.$reset();
                this.updateData = !this.updateData
            },
            async createTransfer(){
                try {
                    // this.createButton.loading = true;
                    // await this.$v.$touch();
                    // if (this.$v.$invalid || this.$v.$error) return;
                    const accounts = this.paymentsInput.map((payment, index) => {
                        return {
                            accountId: this.payments[index].id,
                            amount: payment,
                            originalAmount:this.payments[index].paymentAmount
                        }
                    });
                    const body = {
                        accounts, 
                        studentCareerId:this.$route.params.studentRecord,
                        user:this.user,
                        identifier:this.identifier,
                        amount:this.inputTotalPrices,
                        date:this.date,
                    }
                    await $transfer.create(body);
                    this.createButton.success = true;
                    this.enableSuccessMessage = true;
                    this.successMessage.type= 'success';
                    this.successMessage.title= this.$t('modules.exceptions.payments.bankTransferPanel.successMessage');
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.resetButtonValues()} };
                }catch (error) {
                    this.enableSuccessMessage = true;
                    this.successMessage.type= 'error';
                    this.successMessage.title= this.$t('modules.exceptions.payments.bankTransferPanel.errorMessage');
                    this.successMessage.actionPrimary= { text: this.$t('actions.return'), callback: () => {
                    this.enableSuccessMessage = false;
                    }};
                    this.createButton.error = true;
                    throw error;
                } finally {
                    this.createButton.loading = false;
                    this.createButton.error = false;
                    this.createButton.success = false;
                }
            },
            async createBill(){
                try {
                    this.createButton.loading = true;
                    // await this.$v.$touch();
                    // if (this.$v.$invalid || this.$v.$error) return;
                    
                    // //CHECK ENTRE INTENT ID DE LOS ELEMENTOS SELECCIONADOS. Si lugo hago que no se muestren los checkboxex de los items que no tengan el mismo intent id esta linea ya no es necesaria.
                    // //logic to do that: variable aux, cuando hago click en el checkbox se complete con el intent id, v-show en checkboxes si el intent id es igual a esa variable. Tambien validar por esa nueva prop si es que es un item ya facturado o no.
                    // console.log(this.payments)
                    // for(let i=0; i<this.payments.length-1; i++) {
                    //     if(this.payments[i].intent != this.payments[i+1].intent) {
                    //         console.log('ERROR dif intent')
                    //         throw new Error('Los items seleccionados no corresponden al mismo intento de pago');
                    //     }
                    // }
                    const body = {
                        //luego de hacer la verificación usar el intent Id del primero del array. todavia no trae los intentId del backend
                        intentId: this.payments[0].intent,
                        accountId: null,
                        studentId: this.studentId,
                        generic: false
                    }
                    await $process.create(body)
                    this.createButton.success = true;
                    this.enableSuccessMessage = true;
                    this.successMessage.type= 'success';
                    this.successMessage.title= this.$t('modules.exceptions.payments.bankTransferPanel.successBillingMessage');
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.resetButtonValues()} };
                }catch (error) {
                    this.enableSuccessMessage = true;
                    this.successMessage.type= 'error';
                    this.successMessage.title= this.$t('modules.exceptions.payments.bankTransferPanel.errorBillingMessage');
                    this.successMessage.actionPrimary= { text: this.$t('actions.return'), callback: () => {
                    this.enableSuccessMessage = false;
                    }};
                    this.createButton.error = true;
                    throw error;
                } finally {
                    this.createButton.loading = false;
                    this.createButton.error = false;
                    this.createButton.success = false;
                }
            },
            reset(){
                this.createButton.loading = false;
                this.createButton.success = false;
                this.createButton.error = false;
                this.paymentsInput = [];
                this.date = null;
                this.menu = false;
                this.identifier = null;
                this.$v.$reset();
            },
            resetButtonValues () {
                this.$emit('update');
                this.$emit('close');
                this.enableSuccessMessage = false;
            },
         
            errorLoading(title = null, message = null) {
                this.createButton.loading = false
                this.createButton.error = true
                this.createButton.error = false
                if (title && message) {
                this.$store.dispatch('commons/openPopUp', {
                    title,
                    content: [{ value: message }],
                    actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                    icon: { color: 'error', name: 'mdi-close' },
                    color: 'primary',
                });
                }
            },
            async saveBillingData() {
                this.createButton.loading=true
                //Le asigno un valor para que pase la validación porque no se va a usar.
                if(this.billingOnly) {
                    this.identifier = 10000
                    this.payments.forEach(e => {this.paymentsInput.push(e.paymentAmount)})
                }
                if (this.$v.$invalid && !this.billingOnly) {
                    this.$v.$touch();
                    Object.keys(this.$v).some(input => {
                        if (input.includes('$')) return false;
                        
                        if (this.$v[input].$error) {
                           
                            this.$refs[input][0]
                                ? this.$refs[input][0].$el.scrollIntoView({block: 'center', behavior: 'smooth'})
                                : this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
                            return true
                        }
                    })
                    this.createButton.error = true;
                    this.createButton.loading = false;
                }else{
                    try {
                        if(!this.$isMexico){
                            if(await this.$refs.billingData.save()){
                                if(!this.billingOnly) {
                                    this.createTransfer();
                                } else {
                                    this.createBill();
                                }
                            }else{
                                throw this.$t('modules.exceptions.payments.bankTransferPanel.errorLoadingMessage')
                            }
                        } else{
                            if(this.billingOnly) {
                                if(await this.$refs.billingData.save()){
                                    this.createBill();
                                }      
                            } else {
                                this.createTransfer();
                            }
                        }
                    } catch(error) {
                        this.errorLoading(this.$t('modules.exceptions.payments.bankTransferPanel.errorLoadingTittle'), error)
                        this.createButton.loading = false
                        this.createButton.error = true
                    }
                }
            },
            // setBillingDate() {
            //     //console.log(this.payments[0].paymentDate)
            //     if(this.payments[0].paymentDate != null && this.billingOnly) {
            //         this.date = moment(this.payments[0].paymentDate).format('YYYY-MM-DD');
            //     }
            // }
        },
        watch: {
            payments(value){
                if (value) this.initialize();
            },
            
        },
        validations: { 
            date: { required },
            identifier: { required },
            paymentsInput: { 
                required, 
                $each: { required } 
            },
        },
    }
</script>
<style lang="sass" scoped>
.total-wrapper
  border-radius: 5px
  background-color: #d9e2fa
  font-family: 'DM Sans'
  font-weight: bold
  font-stretch: normal
  font-style: normal
  line-height: 1.3
  letter-spacing: -0.6px
  .total
    float: left
    font-size: 20px
  .total-currency
    float: right
    font-size: 24px
</style>