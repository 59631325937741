<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <div class="settings-container">
      <div class="title primary--text px-6 pt-4">{{ $t('modules.modulecontrol.table.asidebar.title') }} </div>
      <AsideBar :categories="categories" @click="getStatusTable"></AsideBar>
    </div>
    <v-container fluid class="container-custom scrollableContent pa-0 ">
      <v-row no-gutters justify="center">
        <v-col sm="10">
          <Breadcrumbs :title="$t('modules.modulecontrol.table.breadcrumbs.title')"
            :description="$t('modules.modulecontrol.table.breadcrumbs.description')" class="mt-2">
          </Breadcrumbs>
        </v-col>
      </v-row>
      <v-row justify="center" class="mx-8">
        <v-col sm="12" class="viewSpaces">
          <ModulesTable :headers="headers" :items="validations" @click="itemSelected" />
        </v-col>
      </v-row>

      <TemporaryRightDrawer
        :open="open"
        :title="name"
        :description="description"
        @tmpDrawerOpen="close"
      >
        <SuperTable
          :name="name"
          :period="currentPeriod"
          :periods="periods"
          :items="correction"
          :loading="isLoading"
          :pagination="pagination"
          @changePage="changePage"
          @changePeriod="changePeriod"
          @changeItemsPerPage="changeItemsPerPage" />
      </TemporaryRightDrawer>
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import AsideBar from '../Components/AsideBar/AsideBar';
import ModulesTable from '../Components/ModulesTable/ModulesTable.vue'
import SuperTable from '../Components/SuperTable/SuperTable';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import api from '@/api';

const { $academic } = api
const { $terms } = $academic

export default {
  name: 'ModuleControlIndex',
  components: {
    Breadcrumbs,
    AsideBar,
    ModulesTable,
    TemporaryRightDrawer,
    SuperTable,
  },
  data() {
    return {
      name: '',
      description: '',
      service: '',
      correction: [],
      services: [],
      periods:[],
      currentPeriod:null,
      validations: [],
      categories: [],
      permissions: [],
      headers: [
        { text: this.$t('modules.modulecontrol.table.headers.name'), value: 'name', sortable: false },
        { text: this.$t('modules.modulecontrol.table.headers.description'), value: 'description', sortable: false },
        { text: this.$t('modules.modulecontrol.table.headers.status'), align: 'center', value: 'status', sortable: false },
        { text: '', align: 'center', value: 'correction' }
      ],
      modules: [],
      open: false,
      isLoading: false,
      pagination: {
        page: 1,
        total: 1,
        items: 10,
        availables: [ 10, 15, 20 ]
      }
    }
  },
  methods: {
    getService() {
      Object.entries(api).forEach(service => {
        if (service[1].$systemValidation) {
          this.fetchModule(service);
        }
      })
    },
    async fetchModule(service) {
      if (service[0] == '$core') {
        const { data } = await service[1].$systemValidation.find();
        // Se toma la información del servicio de core, se la ordena, luego se ve que permisos posee segun el rol, y luego se formatea para poder utilizarla en el asidebar
        data.sort((a, b) => {
          return a.service.localeCompare(b.service) || a.group.localeCompare(b.group);
        });

        this.categories = data.reduce((acc, item) => {
          if (acc[acc.length - 1]?.title != item.service) {
            acc.push({
              title: item.service,
              groups: [{
                group: item.group,
                validations: [{
                  name: item.name,
                  permission: item.permission,
                  description: item.description,
                  url: item.url,
                  optionalUrl: item.optionalUrl,
                  status: 'PENDING',
                  correction: []
                }]
              }]
            })
          } else {
            if (acc[acc.length - 1]?.groups[acc[acc.length - 1]?.groups.length - 1]?.group != item.group) {
              acc[acc.length - 1]?.groups.push({
                group: item.group,
                validations: [{
                  name: item.name,
                  permission: item.permission,
                  description: item.description,
                  url: item.url,
                  optionalUrl: item.optionalUrl,
                  status: 'PENDING',
                  correction: []
                }]
              })
            } else {
              acc[acc.length - 1]?.groups[acc[acc.length - 1]?.groups.length - 1]?.validations.push({
                name: item.name,
                permission: item.permission,
                description: item.description,
                url: item.url,
                optionalUrl: item.optionalUrl,
                status: 'PENDING',
                correction: []
              })
            }
          }
          return acc
        }, [])
      } else {
        // Se carga el array de service con todos los servicios que no sean core, para luego poder hacer la llamada a los sub endpoints para la informacion de las tablas en el metodo getStatusTable
        this.services.push(service)
      }
    },
    // Método para cargar el estado de las validaciones
    async getStatusTable(info) {
      [this.validations, this.service] = info;

      const services = this.services
        .filter( ([name]) => name === `$${this.service}`)
        .flat()

      const validations = this.validations
        .filter(({ status }) => status === 'PENDING')

      const [,modules] = services
      validations
        .map(async (validation) => {
          try {
            const { data } = await modules.$systemValidation.find(validation.url)
            validation.status = data.message
          } catch (error) {
            validation.status = 'ERROR'
            throw error
          }
        })
    },
    // Método para llamar al endpoint de optionalUrl que trae los datos que hay que corregir
    async getDataCorrectionTable(validationName) {
      const service = this.services.filter(([name]) => name === `$${this.service}`).flat()
      const [,modules] = service
      const validation = this.validations.find(validation => validationName === validation.name)

      if (!validation.optionalUrl) return
      try {
        await this.fetchDataCorrectionTable(modules, validation)
      } catch (error) {
        this.close()
        throw error
      }
    },
    async fetchDataCorrectionTable(service, validation) {
      try {
        let params = { page: this.page, length: this.pagination.items, orientation: 'desc'}
        if(this.currentPeriod) params = {...params, termId : this.currentPeriod}

        this.isLoading = true
        const { data, status } = await service.$systemValidation.find(validation.optionalUrl, null, { params })
        if (!data.content && [200].includes(status)) throw ''
        this.correction = data.content;
        this.pagination.total = data.totalPages
      } catch (error) {
        this.correction = []
        throw error
      }
      finally {
        this.isLoading = false
      }
    },
    // Método para ver los permisos del roll
    availableValidations() {
      Object.entries(this.$permissions.portaladministrativo.moduleControl).forEach(([permission, status]) => { if (status && permission != 'NAME' && permission != 'STATUS' && permission != 'READ') this.permissions.push(permission) })
    },
    itemSelected({ name, description, optionalUrl }) {
      if (!optionalUrl) return
      this.name = name
      this.description = description
      this.open = true
      this.getDataCorrectionTable(name)
    },
    changePage(page) {
      this.pagination.page = page
      this.getDataCorrectionTable(this.name)
    },
    changeItemsPerPage(number) {
      this.pagination.page = 1
      this.pagination.items = number
      this.getDataCorrectionTable(this.name)
    },
    close() {
      this.correction = []
      this.pagination.page = 1
      this.pagination.total = 1
      this.pagination.items = 10
      this.currentPeriod = null
      this.open = false
    },
    async getPeriods() {
      try {
        const { data } = await $terms.find(null, null, { params: { page: 0, length: 20, orderBy: 'classStartDate', orientation: 'desc' }})
        const cursadoData = data.content?.filter(e => e.termType.meaning == 'Cursado')
        const cursado = cursadoData.length ? [ { header: 'Cursado' }, ...cursadoData ] : []

        const onboardingData = data.content?.filter(e => e.termType.meaning == 'On Boarding')
        const onboarding = onboardingData.length ? [ { divider: true }, { header: 'On Boarding' }, ...onboardingData ] : []

        const nivelacionData = data.content?.filter(e => e.termType.meaning == 'Nivelación')
        const nivelacion = nivelacionData.length ? [ { divider: true }, { header: 'Nivelación' }, ...nivelacionData ] : []

        const practicaData = data.content?.filter(e => e.termType.meaning == 'Practicas Profesionales')
        const practica = practicaData.length ? [ { divider: true }, { header: 'Prácticas Profesionales' }, ...practicaData ] : []

        this.periods = [ ...cursado, ...onboarding, ...nivelacion, ...practica ]
      } catch (error) {
        this.periods = []
        throw error
      }
    },
    changePeriod (period){
      this.currentPeriod = period
      this.pagination.page = 1
      this.getDataCorrectionTable(this.name)
    }
  },
  computed: {
    page() {
      return this.pagination.page - 1
    },
  },
  mounted() {
    this.getService();
    this.availableValidations()
    this.getPeriods()
  }
}
</script>

<style lang="sass" scoped>
    .v-list-item--link
        margin-left: 10px

    .settings-container
        background: #ebebeb
        width: 300px
</style>
