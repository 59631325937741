<template>
  <v-container fluid>
    <v-row>
      <v-col sm="8" md="6" v-if="validationsWithTerms.includes(name)">
        <v-autocomplete
          class="ml-2"
          :label="$t('modules.modulecontrol.table.drawer.filter.label')"
          :placeholder="$t('modules.modulecontrol.table.drawer.filter.placeholder')"
          :value="period"
          item-text="description"
          item-value="id"
          :items="periods"
          @change="changePeriod"
          outlined
          clearable
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.description }}</v-list-item-title>
              <v-list-item-subtitle>{{ data.item.termType.meaning }}</v-list-item-subtitle>
            </v-list-item-content> 
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md="12">
        <v-data-table
          :headers="headersCorrection"
          :items="items"
          :items-per-page="pagination.items"
          item-key="name"
          hide-default-footer
          style="table-layout: fixed"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="3" md="2">
        <v-select
          class="ml-2"
          :items="pagination.availables"
          :placeholder="pagination.items.toString()"
          @change="$emit('changeItemsPerPage', $event)"
          outlined
        />
      </v-col>
      <v-col md="9">
        <v-pagination
          :value="pagination.page"
          :length="pagination.total"
          @input="$emit('changePage', $event)"
          circle
          total-visible="5"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SuperTable',
  props: {
    items: { type: Array },
    pagination:{
      type:Object,
      default:()=>({
        page: 1,
        total: 1,
        items: 10,
        availables:[ 10, 15, 20 ]
      }),
      validator:({page,total,items,availables})=>
        Array.isArray(availables)&&
        [page,total,items].every(prop=>typeof prop === 'number')
    },
    period: { type:Number || null },
    periods: { type: Array },
    name: { type: String }
  },
  methods:{
    changePeriod(value){
      this.$emit('changePeriod', value)
    }
  },
  computed: {
    validationsWithTerms() {
      return [
        'Materias sin seccion',
        'Materias sin cerrar',
        'Estudiante sin LMS'
      ]
    },
    headersCorrection(){
      return this.items.length
        ? Object.keys(this.items?.[0]).map(item =>({ text : item, value : item , sortable: false }))
        : [] ;
    },
  },
}
</script>
