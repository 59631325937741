const subject0Translations = {
    es_CL: {
        table: {
            headers: {
                identification: 'RUT',
            },
        }
    },
    es: {
        table: {
            breadcrumbs: {
                title: 'Materia 0',
                description: 'Gestión de Certificado'
            },
            headers: {
                identification: 'DNI',
                name: 'Nombre',
                lastname: 'Apellido',
                career: 'Carrera',
                career_status: 'Estado de carrera',
                term: 'Periodo',
                status: 'Materia 0',
            },
            actions: {
                confirmation: 'Confirma habilitar certificado para:',
                takeout: 'Confirma deshabilitar certificado para:',
                popUpHabilitar: 'Habilitar certificado',
                popUpDeshabilitar: 'Deshabilitar certificado',
                success: 'Se habilitó el certificado correctamente',
                disable: 'Se deshabilitó el certificado correctamente',
                error: 'Ocurrió un error al asignar el certificado',
            },
        }
    },
    en: {

    }
}

export default subject0Translations