import { render, staticRenderFns } from "./StudentPreRegistrationCreate.vue?vue&type=template&id=18ff163a&scoped=true&"
import script from "./StudentPreRegistrationCreate.vue?vue&type=script&lang=js&"
export * from "./StudentPreRegistrationCreate.vue?vue&type=script&lang=js&"
import style0 from "./StudentPreRegistrationCreate.vue?vue&type=style&index=0&id=18ff163a&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18ff163a",
  null
  
)

export default component.exports