const sistemaQTranslations = {
    es: {
      errors: {
        required: 'Campo requerido',
        minLength: 'Debe seleccionar al menos uno',
        hourRequired: 'Selecciona una hora',
        hourRepeated: 'La hora seleccionada ya se encuentra cargada',
        hourInvalid: 'Selecciona una hora valida',
        proctoringRequired: 'Selecciona un proctoring',
        proctoringRepeated: 'Proctoring repetido',
        quantityMinValue: 'valor invalido',
        quantityEnable: 'cupo minimo en este proctoring %{valor}',
        datePair: 'Fecha inválida',
        dateRequired: 'Ingresa una fecha'
      },
      create: {
        cards: {
          switch: {
            enable: 'Habilitado',
            disable: 'Deshabilitado'
          }
        },
        warning_anulation: {
          title: '¿Estás seguro que desea anular la mesa?',
          content: 'Si anulas la mesa los estudiantes no visualizarán la misma para inscribirse.'
        },
        breadcrumbs: {
          title: 'Nueva mesa de examen',
          description: 'Crea una nueva mesa de examen',
          text: 'Nuevo Examen,'
        },
        warning_popup: {
          title: 'Estas creando una mesa de examen deshabilitada',
          content: '',
        },
        ceoSelectLabel: 'Centro de servicio',
        proctoring: 'Tipo de proctoring',
        calendarbreadcrumbs: {
          title: 'Generación de exámenes',
          description: 'Administración de exámenes',
          button: {
            name: 'Nuevo examen',
            icon: 'mdi-plus',
            color: 'primary'
          }
        },
        calendar: {
          selectView: 'Visualizar',
          day: 'Día',
          month: 'Mes',
          week: 'Semana',
          references: 'Referencias',
          lab: 'Laboratorio',
          notebook: 'Notebook',
          name: 'Mesa de examen',
          disabled: 'Deshabilitada'
        },
        months: {
          1: 'Enero',
          2: 'Febrero',
          3: 'Marzo',
          4: 'Abril',
          5: 'Mayo',
          6: 'Junio',
          7: 'Julio',
          8: 'Agosto',
          9: 'Septiembre',
          10: 'Octubre',
          11: 'Noviembre',
          12: 'Diciembre',
        },
        card: {
          title: 'Datos de la mesa',
          subtitle: 'Agrega la información de la nueva mesa de examen. Ten en cuenta que podrás seleccionar un rango de fechas en los cuales estará disponible.',
          date: 'Fecha',
          from: 'Desde',
          to: 'Hasta',
          time: 'Hora de inicio',
          type: 'Tipo de mesa',
          lab: 'Laboratorio',
          notebook: 'Notebook',
          switch: 'Habilitada',
          proctoring: {
            klarway: 'Klarway',
            sumadi: 'Sumadi'
          }
        },
        success_popup: {
          title: 'La mesa de examen fue creada correctamente'
        },
        success_message: {
          title: 'La mesa de examen fue creada correctamente',
          actions: {
            primary_text: 'Cerrar',
            secondary_text: 'Crear otra',
            back: 'Volver',
          },
        },
        error_message: {
          anulation_title: 'La mesa de examen no se pudo anular correctamente'
        },
        successAnulation_popup: {
          title: 'La mesa de examen fue anulada'
        },
        aside: {
          title: 'Nueva mesa de examen',
          subtitle: 'Crea una nueva mesa de examen',
          },
        button: {
          cancel: 'Cancelar',
          create: 'Crear',
          void: 'Anular',
          void_all: 'Anular todas',
          save: 'Guardar',
          accept: 'Aceptar'
        },
        detail: {
          title: 'Mesa seleccionada: ',
          subtitle: 'Estudiantes inscritos',
          subtitleDetail: 'No hay estudiantes inscritos en esta mesa',
          datefrom: 'Fecha desde',
          dateto: 'Fecha hasta',
          start: 'Hora de inicio',
          type: 'Tipo de Mesa',
          lab: 'Laboratorio',
          notebook: 'Notebook',
          from: '10/11/2019',
          to: '15/11/2019',
          hs: '15:00 hs',
          phone: 'Teléfono: ',
          ceo: 'CSE:'
        },
        modal: {
          title: 'Selecciona justificación',
          subtitle: 'Selecciona el motivo por el cual desea anular la mesa de examen seleccionada',
          justify: {
            placeholder: 'Selecciona una justificación'
          }
        },
      },
      update: {
        warning_popup: {
          title: 'Estás deshabilitando una mesa de examen',
          content: '',
        },
        success_message: {
          title: 'La mesa de examen fue modificada correctamente',
          actions: {
            primary_text: 'Cerrar',
            secondary_text: 'Crear otra',
            back: 'Volver',
          },
        },
        error_message: {
          title: 'Hubo un problema al intentar modificar la mesa de examen'
        },
        success_popup: {
          title: 'La mesa de examen fue modificada correctamente'
        },
        cardTitle: 'Datos de la mesa',
        to: 'Hora final',
        buttons: {
          cancel: 'Cancelar',
          create: 'Crear',
          void: 'Anular',
          void_all: 'Anular todas',
          save: 'Guardar',
          accept: 'Aceptar'
        },
      }
    },
    en: {



    }
  };


  export default sistemaQTranslations
