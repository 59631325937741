<template>
    <MultipleFormGroup
    :items="receipts.$each.$iter"
    :length="receipts.$model.length"
    @removeGroup="removeGroup"
    @addGroup="addGroup"
    :canAddGroup="originalReceipsLength > receipts.$model.length"
    >
        <template  v-slot:default="{item, index}">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-select 
                            :label="$t('modules.pricing.billing.detailCard.labelConcept')"
                            :items="types"
                            item-text="description"
                            outlined
                            item-value="description"
                            v-model="item.description.$model"
                            class="no-message"
                            :error-messages="(item.description.$dirty && item.description.$invalid) ?
                            ((!item.description.required) ? $t('modules.messaging.banners.validations.buttonTextRequired') :'') : ''"
                            @blur="item.price.$touch()"
                        ></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            :label="$t('modules.pricing.billing.detailCard.labelPrice')"
                            outlined
                            type="text"
                            prepend-inner-icon="mdi-currency-usd"
                            v-model.trim="item.price.$model"
                            class="no-message"
                            :error-messages="(item.price.$dirty && item.price.$invalid) ?
                            ((!item.price.required) ? $t('modules.messaging.banners.validations.buttonTextRequired') :'') : ''"
                            @keypress="$validateDecimalInputNumber($event, $event.target.value, null, 6, -300000)"
                            @blur="[item.price.$touch(),formatPrice($event.target.value, index)]"
                        ></v-text-field>
                    </v-col>
                </v-row>          
            </v-container>
        </template>
    </MultipleFormGroup>
</template>

<script>
import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup.vue'

export default {
    name: 'DetailCard',
    components:{
        MultipleFormGroup
    },
    props:{
        billingProducts: {type: Array}, 
        receipts: {type: Object},
        types: {type: Array},
        removedReceipts: {Array},
        originalReceipsLength: {Number}
    },
    data() {
        return {
            
        }
    },
    mounted(){
    },
    methods: {
        formatPrice(e, index) {
            this.receipts.$each.$iter[index].price.$model = e
            this.receipts.$each.$iter[index].price.$touch()
        },
        addGroup() {
            const mockup = 
                {
                description:'',
                price:'',
                code: 0,
                };
                this.$emit('addGroup', mockup);  
        },
        removeGroup(index) {
            this.$emit('removeGroup', index)
        },
    },
    watch: {
        
    }
}
</script>

<style lang="sass" scoped>

</style>