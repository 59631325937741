<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed"></AsideBar>
      <v-container fluid class="scrollableContent pa-0">
        <v-container class="container-custom px-6">
          <Breadcrumbs :description="breadcrumbs.description" :title="breadcrumbs.title">
            <Button
              v-if="canCreate"
              @clicked="newBenefit"
              :icon="breadcrumbs.button.icon"
              :text="breadcrumbs.button.name"
              depressed
            ></Button>
          </Breadcrumbs>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <SuperTable
                :headers="headers"
                :users="filteredBenefits"
                :clear-selected="clearSelected"
                :page-count="pagination.pagesAmount"
                :page="pagination.page"
                :total-items="totalItems"
                @searchAction="searchAction"
                :sortBy.sync="sort.sortBy"
                :sortDesc.sync="sort.sortDesc"
                @input-pagination="onInputPagination"
                @length-pagination-change="onLengthPaginationChange"
                :permissions="{ update: canUpdate, delete: canDelete }"
                :deleteTitle="$t('modules.benefits.table.bulk_delete')"
                @bulk-delete="(benefits)=>{bulkDeletePopUp(benefits)}"
                @deleteUser="(id)=>{deleteBenefitPopUp(id)}"
                @updateUser="(id)=>{updateBenefit(id)}"
                @openDetails="(id)=>{openDetails(id)}"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import AsideBar from '@/components/AsideBar/AsideBar'
import Button from '@/components/Button/Button';
import { $benefits, $types } from '../Services';

export default {
  name: 'BenefitsTable',
  components: {
    SuperTable,
    Breadcrumbs,
    AsideBar,
    Button
  },
  data () {
    return {
      clearSelected: '',
      breadcrumbs: {
        title: this.$t('modules.benefits.table.breadcrumbs.title'),
        description: this.$t('modules.benefits.table.breadcrumbs.description'),
        button: {
          name: 'Nuevo',
          icon: 'mdi-plus',
          color: 'primary'
        }
      },
      filteredBenefits: [],
      benefits: [],
      asidebar: {
        title: 'Refinar búsqueda',
        items: [
          {
            name: this.$t('modules.benefits.table.headers.benefit'),
            filters:null
          }, {
            name: this.$t('modules.benefits.table.headers.status'),
            filters: [
              {
                id: 1,
                name: this.$t('modules.benefits.table.filters.active'),
                type: 'check',
                value: true,
              }, {
                id: 0,
                name: this.$t('modules.benefits.table.filters.inactive'),
                type: 'check',
                value: true,
              }
            ]
          },
        ]
      },
      headers: [
        
        {
          text: this.$t('modules.benefits.table.headers.code'),
          value: 'code',
        },
        {
          text: this.$t('modules.benefits.table.headers.name'),
          value: 'description',
        },
        {
          text: this.$t('modules.benefits.table.headers.benefit'),
          value: 'benefit.meaning',
          sortable: false,

        },
        {
          text: this.$t('modules.benefits.table.headers.status'),
          value: 'status',
        },
        {
          text: this.$t('modules.benefits.table.headers.product'),
          value: 'productType',
          sortable: false,

        },
        {
          text: this.$t('modules.benefits.table.headers.discount'),
          value: 'percentage',
          sortable: false,

        },
        {
          text: '',
          value: 'action'
        }
      ],
      totalItems: null,
      pagination: {
          limit: 20,
          page: 1,
          pageAmount: 1
      },
      sort: {
          sortBy: 'code',
          sortDesc: false,
      },
      loading:false,
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.benefits.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.benefits.READ || this.$permissions.portaladministrativo.prices.benefits.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.benefits.DELETE
    },
  },
  async mounted () {
    await this.getBenefits();
    this.fetchData();
  },
  methods: {
    async getBenefits() {
        const fetchedBenefit = await $types.find(null, null, { params: { type: 'BENEFIT_TYPE' } })
        this.asidebar.items[0].filters = fetchedBenefit.data.map(item => {
          return {
            id: item.value,
            name: item.meaning,
            type: 'check',
            value: true,
          }
        });
      },

    async changed () {
      const filtered = []
      this.benefits.forEach(benefit => {
        let found = false
        this.asidebar.items[0].filters.forEach(type => {
          this.asidebar.items[1].filters.forEach(status => {
            if (type.value && status.value) {
              if ((benefit.benefit.value === type.id) && (benefit.status == status.id)) {
                filtered.push(benefit)
                found = true
                return true
              }
            }
          })
          if (found) return true
        })
      })
  
      this.filteredBenefits = filtered
      this.pagination.page=1
      this.fetchData();
    },
    deleteBenefitPopUp (id) {
      const benefit = this.benefits.find(benefit => benefit.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{ value: benefit.description }],
        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.deleteBenefit(id) },
        actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      })
    },
    async fetchData () {
      this.clearSelected = new Date().getMilliseconds().toString();
  
      this.loading=true;
     
      const params = {
          id: this.$route.params.id,
          page: this.pagination.page == 0 ? this.pagination.page=0 : this.pagination.page - 1 ,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc',
          search: this.search,
      }

      try {
        
        const filterParams = {status:[],type: []}

        if(this.asidebar.items[1].filters[0].name === 'Activo' && this.asidebar.items[1].filters[0].value){
          filterParams.status.push(true)
        }
        if(this.asidebar.items[1].filters[1].name === 'Inactivo' && this.asidebar.items[1].filters[1].value){
          filterParams.status.push(false)
        }
        this.asidebar.items[0].filters.forEach(item =>{
          if(item.value === true){
            filterParams.type.push(item.id)
          }
        })
       
        const benefits = await $benefits.filter(filterParams, { params })
        this.benefits =  benefits.data.content.map(benefit => {
          let productType = [];
          let percentage = [];
          if (benefit.details?.length) {
            benefit.details.map(detail => {
              percentage = [...percentage,{
                id: detail.id,
                amount: (detail.amount !== null) ? true : false,
                percentage: (detail.amount !== null) ? detail.amount : detail.percentage
              }];
              productType = [...productType , { id: 1, name: detail.billingProduct}];
            });
          } else {
            percentage = [{
              amount: true,
              percentage: benefit.benefitReferrerAmount,
            }, {
              amount: true,
              percentage: benefit.benefitReferredAmount,
            }];
            productType = [{ id: 1, name: '-' }];
          }
    
          return {
            id: benefit.id,
            code: benefit.code,
            description: benefit.description,
            benefit: benefit.type,
            status: benefit.status,
            productType,
            percentage
          }
  
        });

        this.filteredBenefits = this.benefits
        this.totalItems = benefits.data.totalElements
        this.pagination.pagesAmount = benefits.data.totalPages
      } catch (error) {
        this.benefits = []
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        this.totalItems = 0;
        throw error;
      }finally{
        this.loading=false;
      }
      
    },
    bulkDeletePopUp (benefits) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: benefits.map(benefit => ({
          value: benefit.description
        })),
        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.bulkDelete(benefits) },
        actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      })
    },
    async bulkDelete (benefits) {
      const itemsForDelete = benefits.map((benefit) => {
        return { id: benefit.id }
      });

      try {
        await $benefits.bulkDelete(itemsForDelete)
        this.clearSelected = new Date().getMilliseconds().toString()
        itemsForDelete.forEach(item => {
          this.benefits = this.benefits.filter(benefit => benefit.id !== item.id);
          this.filteredBenefits = this.filteredBenefits.filter(benefit => benefit.id !== item.id);
        });
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{ value: this.$t('modules.benefits.table.bulk_delete_error') }],
          actionPrimary: { text: this.$t('actions.accept'), callback () { } },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        throw error;
      }
    },
    newBenefit () {
      if (this.canCreate) this.$router.push('/benefits/create')
    },
    updateBenefit (id) {
      if (this.canUpdate) this.$router.push(`/benefits/update/${id}`)
    },
    async deleteBenefit (id) {
      try {
        await $benefits.delete(id);
        this.benefits = this.benefits.filter(benefit => benefit.id !== id);
        this.filteredBenefits = this.filteredBenefits.filter(benefit => benefit.id !== id);
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{ value: this.$t('modules.benefits.table.delete_error') }],
          actionPrimary: { text: this.$t('actions.accept'), callback () { } },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        throw error
      }
    },
    openDetails(id) {
     this.$router.push(`benefits/details/${id}`);
    },
    onInputPagination(event) {
        this.pagination.page = event;
        this.fetchData();
    },
    onLengthPaginationChange(event) {
        this.pagination = {
            page: 1,
            limit: event,
            pagesAmount: 1
        };
        this.fetchData();
    },
    searchAction(data) {
        this.pagination.page = 1;
        this.search = data;
        this.fetchData();
    },
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
}
</script>
