
const sections = {
    title: 'Secciones',
    description: 'Este reporte muestra todas las secciones activas, con cantidad de inscriptos a ellas, su cupo máximo y los datos del tutor, profesor y corrector de cada una.',
    detail: [
        { label: 'Archivo', value: 'Reporte genereteSectionsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estado de la asignatura debe ser "En curso" o la cantidad de inscriptos debe ser 0.' },
        { label: 'Criterios', value: 'Muestra las secciones para el período seleccionado.' },
    ],
    example: {
        header: [
            'NOMBRE',
            'PERIODO',
            'CUPO',
            'ESTADO',
            'R19',
            'INSCRIPTOS',
            'NOMBRE PROFESOR',
            'APELLIDO PROFESOR',
            'RUT PROFESOR',
            'MAIL PROFESOR',
            'NOMBRE TUTOR',
            'APELLIDO TUTOR',
            'RUT TUTOR',
            'MAIL TUTOR',
            'NOMBRE CORRECTOR',
            'APELLIDO CORRECTOR',
            'RUT CORRECTOR',
            'MAIL CORRECTOR',
        ],
        data: [
            [
                '0121P46A-TALLER DE LENGUA DE SEÑAS 1',
                '2B/2022',
                '140',
                'true',
                'false',
                '90',
                'PAOLA ANDREA',
                'HIDALGO BARRERA',
                '14546133',
                'paola.hidalgo.barrera@gmail.com',
                'GERARD CAMILO',
                'ARANCIBIA QUIROZ',
                '17053734',
                'gerard.arancibia@ipp.cl',
                'PAOLA ANDREA',
                'HIDALGO BARRERA',
                '14546133',
                'paola.hidalgo.barrera@gmail.com',
            ],
            [
                '0250P64A-CAMPAÑAS PUBLICITARIAS 1',
                '2B/2022',
                '140',
                'true',
                'false',
                '10',
                'EDISON',
                'CASTILLO AEDO',
                '12462906',
                'edison.castillo@ipp.cl',
                'CATHERINE ANDREA',
                'MACHTING TORO',
                '12884623',
                'catherine.machting@ipp.cl',
                'EDISON',
                'CASTILLO AEDO',
                '12462906',
                'edison.castillo@ipp.cl',
            ],
        ],
    },
}

const mesh = {
    title: 'Malla',
    description: 'Este reporte muestra todas las materias de todas las carreras con el plan de estudio, el semestre, las horas y los créditos',
    detail: [
        { label: 'Archivo', value: 'Reporte genereteMeshReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Muestra todas las carreras, materias y planes de estudio creados.' },
        { label: 'Criterios', value: 'Los semestres van del 1 al 17 y los semestres C se muestran como X.' },
    ],
    example: {
        header: [
            'Código Carrera',
            'Nombre Carrera',
            'Código Plan',
            'Plan de Estudio',
            'Estado Plan',
            'Código Materia',
            'Nombre Materia',
            'Orden Asignatura',
            'Nivel Asignatura',
            'Semestre Asignatura',
            'Período Asignatura',
            'Horas Asignatura',
            'Créditos Asignatura',
        ],
        data: [
            [
                'TSCP',
                'Cloud Administration',
                '2021',
                'PLAN 2021',
                'Habilitado',
                'ESP95',
                'BASE DE DATOS',
                '5',
                '6',
                '3',
                'B',
                '120',
                '120',
            ],
            [
                'TSMD',
                'Marketing Digital',
                '2022',
                'PLAN 2022',
                'Habilitado',
                'ESP121',
                'ADMINISTRACIÓN DE PUBLICIDAD DIGITAL',
                '4',
                '3',
                '2',
                'A',
                '120',
                '120',
            ],
        ],
    },
}

// documentations[this.reports.service][this.reports.url]


export default { sections, mesh };
