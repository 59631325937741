<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0" v-if="!syncCoursesStatus">
            <v-container class="container-custom px-6">
                <Breadcrumbs :description="$t('modules.canvas.syncCourses.breadcrumbs.description')"
                            :title="$t('modules.canvas.syncCourses.breadcrumbs.title')">
                </Breadcrumbs>

                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            :headers="headers"
                            :data="sections"
                            :sections="sections"
                            :sectionsCanvas="sectionsCanvas"
                            :periods="periods"
                            :isPagination="false"
                            :isSearching="false"
                            :isActions="false"
                            :isSelectPeriod="false"
                            :isExpand="true"
                            @searchAction="(userIdentification)=>{search(userIdentification)}"
                            @syncCoursesSections="(id)=>{syncCoursesSections(id)}"
                            >
                            <template #test ="{item}" > 
                                <div v-if="item.sectionGroup">
                                    <span  v-for="(record, index) in item.sectionGroup" :key="index">
                                        <v-switch v-model="switchSectionGroup" @change="()=>syncUpCoursePopUp(item, record.id)" ></v-switch>
                                    </span>
                                </div>
    
                            </template>
                        </SuperTable>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <SuccessMessage
            v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import SuperTable from '../Components/SuperTable';
import { $terms, $subjects, $course, $sectionsCanvas, $courses } from '../Services';

export default {
    name: 'SyncCoursesView',
    components: {
        Breadcrumbs,
        SuperTable,
        SuccessMessage
    },
    data () {
        return {
            switchSectionGroup: false,
            switchDisabled: false,
            syncCoursesStatus:false,
            course: [],
            sections: [],
            sectionsCanvas:[],
            periods:[],
            headers: [
                {
                    text: this.$t('modules.canvas.syncCourses.subject.table.headers.code'),
                    value: 'sectionId',
                },
                {
                    text: this.$t('modules.canvas.syncCourses.subject.table.headers.name'),
                    value: 'section',
                },
                {
                    text: this.$t('modules.canvas.syncCourses.subject.table.headers.termCode'),
                    value: 'term',
                    sortable: false
                },
                
            ],
            successMessage:{},
        }
    },
    mounted(){
        this.getPeriodsData()
        this.search(this.$route.query.search, this.$route.query.period)
    },
    methods: {
        async getPeriodsData() {
            try {
                const params = {page: 0, length: 20, orderBy: 'classStartDate', orientation: 'desc'}
                const periodsData = await $terms.find(null,null,{params});
                const cursado = [{ divider: true } , { header: 'Cursado' }, ...periodsData.data.content?.filter(e => e.termType.meaning == 'Cursado')]
                const onBording = [{ divider: true } , { header: 'On Boarding' }, ...periodsData.data.content?.filter(e => e.termType.meaning == 'On Boarding')]
                const nivelacion = [{ divider: true } , { header: 'Nivelacion'},...periodsData.data.content?.filter(e => e.termType.meaning == 'Nivelacion')]
                const practica = [{ divider: true } , { header: 'Prácticas Profesionales'},...periodsData.data.content?.filter(e => e.termType.meaning == 'Practicas Profesionales')]
                this.periods = [ ...cursado, ...onBording, ...nivelacion, ...practica]
            } catch (error) {
                this.periods = []
                throw error
            }
        },
        syncUpCoursePopUp(item, sectionGroupId){
            this.$store.dispatch('commons/openPopUp', {
                title: this.$t('modules.canvas.syncCourses.message.syncUpCouse'),
                content: [
                    {
                        value: item.name
                    }
                ],
                actionPrimary: {
                    text: this.$t('modules.canvas.syncCourses.message.syncAccept'),
                    callback: () => this.syncUpCourseSection({sectionId: item.sectionSyncId, newLms:sectionGroupId})
                },
                actionSecondary: {
                    text: this.$t('modules.canvas.syncCourses.message.syncCancel'),
                    
                    callback:() => {
                        this.switchSectionGroup= false
                    }
                },
                icon: { color: 'warning', name: 'mdi-sync' },
                color: 'primary'
            })
        },
        async search(search, period) {
            try {
                const {data} = await $subjects.find(`term/${period}`, null, {params: { search }})
                this.course = data
                await this.getSectionsCourse(this.$route.query.courseId, period)
            } catch (error) {
                this.course = [];
            }
        },
        async getSectionsCourse(courseId, period){
            try {
                
                const {data} = await $course.find(`${courseId}/sections`, null, {params: {notSync: true} })
                const sectionAcademic = data.map(sectionAcademic => {
                    return {...sectionAcademic, sectionId: `${sectionAcademic?.sectionId}-${period}`, sectionSyncId: `${sectionAcademic?.sectionId}` }
                })
                await this.getCanvasSections(sectionAcademic)
                this.sections = sectionAcademic.map(section =>{
                    const sectionGroup = this.sectionsCanvas.filter(sectionCanvas => {
                            return section.sectionId.split('-')[0] === sectionCanvas.sisSectionId.split('-')[0]
                        })
                    return {...section, sectionGroup}
                })
            } catch (error) {
                this.course = [];
            }
        },
        async getCanvasSections(sections){
            try {
                const {data} = await $sectionsCanvas.find('', null, {params: { course: sections[0].subjectTermId}})
                this.sectionsCanvas = data
            } catch (error) {
                this.course = [];
            }
        },
        async syncUpCourseSection({sectionId, newLms}){
            const body = {
                newLms
            }
            try {
                await $courses.update(`sections/${sectionId}`, body, null)
                this.search(this.$route.query.search, this.$route.query.period)
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.success'),
                    content: [{ value: this.$t('modules.canvas.syncCourses.message.syncUpCoursesSectionSuccess') }],
                    actionPrimary: { text: this.$t('actions.close'), callback () {} },
                    icon: {color: 'success', name: 'mdi-check'},
                    color: 'primary',
                })
                }catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = this.$t('modules.canvas.syncCourses.message.syncUpCoursesSectionError');
                    this.successMessage.actionSecondary = null;
                    this.successMessage.actionPrimary = { 
                        text: this.$t('modules.canvas.syncCourses.actions.close'), callback: () => { 
                            this.syncCoursesStatus = false
                        } 
                    }
            } finally {
                this.syncCoursesStatus = false;
                this.switchSectionGroup = false
            }
        },
    },
    computed: {
        canUpdate() {
            return this.$permissions.portaladministrativo.canvas.sync_courses.UPDATE
        },
    },
}
</script>