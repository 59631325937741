<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.repayment.table.breadcrumbs.title')"
          :description="$t('modules.repayment.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newRepayment"
            :icon="'mdi-plus'"
            :text="$t('actions.new')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :users="repayments"
              :clear-selected="clearSelected"
              :permissions="{update: canUpdate, delete: canDelete}"
              @bulk-delete="(repayments)=>{bulkDeletePopUp(repayments)}"
              @deleteUser="(id)=>deleteRepaymentPopUp(id)"
              @updateUser="(id)=>{updateRepayment(id)}"/>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
  import SuperTable from '../components/SuperTable/SuperTable'
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
  import Button from '@/components/Button/Button'
  import { $repayments } from '../Services';
  export default {
    name: 'RepaymentTable',
    components: {
      SuperTable,
      Breadcrumbs,
      Button
    },
    data() {
      return {
        clearSelected: '',
        repayments: [],
        headers: [
          {
            text: this.$t('modules.repayment.table.headers.description'),
            value: 'description',
          },{
            text: this.$t('modules.repayment.table.headers.installments'),
            value: 'installments',
            align: 'center',
            sortable: false
          },{
            text: this.$t('modules.repayment.table.headers.status'),
            value: 'status',
            align: 'center',
            sortable: false
          },{
            text: this.$t('modules.repayment.table.headers.basicPay'),
            value: 'basicPay',
            align: 'center',
            sortable: false
          },{
            text: this.$t('modules.repayment.table.headers.discountPercentage'),
            value: 'discountPercentage',
            align: 'center',
            sortable: false
          },{
            text: '',
            value: 'action'
          }
        ]
      }
    },
    mounted() {
      this.fetchData();
    },
    computed: {
      canCreate() {
        return this.$permissions.portaladministrativo.prices.repayments.CREATE
      },
      canUpdate() {
        return this.$permissions.portaladministrativo.prices.repayments.READ || this.$permissions.portaladministrativo.prices.repayments.UPDATE
      },
      canDelete() {
        return this.$permissions.portaladministrativo.prices.repayments.DELETE
      },
    },
    methods: {
      changed(parent, filter, value) {
        this.asidebar.items[parent].filters[filter].value = value
      },
      async deleteRepaymentPopUp (idRepayment){
        const repayment = await $repayments.findById(idRepayment);
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.confirmation_delete'),
          content: [{value: repayment.name}],
          actionPrimary: {text: this.$t('actions.accept'), callback: () => this.deleteRepayment(idRepayment)},
          actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
          icon: {color: 'error', name: 'mdi-delete'},
          color: 'primary',
        })
      },
      async fetchData() {
        this.clearSelected = new Date().getMilliseconds().toString();
        const repayments = await $repayments.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } });
        if (repayments) {
          this.repayments = repayments.data.content.map(repayment => {
            const formattedRepayment = {
              ...repayment,
              basicPay: `${repayment.basicPay}%`,
              discountPercentage: `${repayment.discountPercentage}%`,
            };
            return formattedRepayment;
          });
        }
      },
      bulkDeletePopUp(repayments) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.confirmation_delete'),
          content: repayments.map(repayment => ({
            value: repayment.name
          })),
          actionPrimary: {
            text: this.$t('actions.accept'),
            callback: () => this.bulkDelete(repayments)
          },
          actionSecondary: {
            text: this.$t('actions.cancel'),
            callback() {}
          },
          icon: {
            color: 'error',
            name: 'mdi-delete'
          },
          color: 'primary',
        })
      },
      async bulkDelete(repayments) {
        const itemsForDelete = repayments.map((repayment) => {
          return {id: repayment.id}
        })
        try {
          const deleteRepayment = await $repayments.bulkDelete(itemsForDelete)
          if (deleteRepayment) {
            this.fetchData()
          }
        } catch (err) {
          this.$store.dispatch('commons/openPopUp', {
            title: 'No se pueden eliminar los repactacíon seleccionada debido a que tiene datos relacionados.',
            actionPrimary: {text: this.$t('actions.accept'), callback() {}},
            icon: { color: 'error', name: 'mdi-close' },
            color: 'primary',
          })
        }
      },
      newRepayment() {
        if (this.canCreate) this.$router.push('/repayments/create')
      },
      updateRepayment(id) {
        if (this.canUpdate) this.$router.push(`repayments/update/${id}`)
      },
      async deleteRepayment(idRepayment) {
        try {
          await $repayments.delete(idRepayment)
          this.fetchData()
        } catch (err) {
          this.$store.dispatch('commons/openPopUp', {
            title: 'No se puede eliminar la repactacíon seleccionada debido a que tiene datos relacionados.',
            actionPrimary: {text: this.$t('actions.accept'), callback() {}},
            icon: {color: 'error', name: 'mdi-close'},
            color: 'primary',
          })
        }
      }
    }
  }
</script>
