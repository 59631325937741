
<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
      <AsideBar
          :title="$t('modules.comisiones.students.asideBar.title')"
          :items="asidebar.items"
          @changed="changed"
      ></AsideBar>
      <v-container fluid class="scrollableContent pa-0">
          <v-container class="container-custom px-6">
              <Breadcrumbs
                  :title="$t('modules.comisiones.students.table.breadcrumbs.title')"
                  :description="$t('modules.comisiones.students.table.breadcrumbs.description')"
              />
              <v-row>
                  <v-col sm="5" class="pt-5 pb-0">
                      <v-autocomplete
                          v-model="selectedTerm"
                          :items="periods"
                          :label="$t('modules.comisiones.students.table.headers.periods')"
                          class="my-2"
                          item-text="description"
                          item-value="id"
                          outlined
                          @change="getStudents"
                      ></v-autocomplete>
                  </v-col>
                  <v-col sm="12" class="viewSpaces">
                      <SuperTable
                          :headers="headers"
                          :students="students"
                          :page.sync="pagination.page"
                          :search.sync="body.search"
                          :totalElements="pagination.totalItems"
                          :itemsPerPage.sync="pagination.itemsPerPage"
                          @fetchData="getStudents"
                      />
                  </v-col>
              </v-row>
          </v-container>
      </v-container>
  </v-container>
</template>
<script>
import SuperTable from '../Components/SuperTable/SuperTable'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import AsideBar from '@/components/AsideBar/AsideBar';
import { $terms, $salesBySeller, $sellers, $careers } from '../Services';
import { mapGetters } from 'vuex'
import moment from 'moment'
  export default {
      name: 'StudentsView',
      components: {
          SuperTable,
          Breadcrumbs,
          AsideBar,
      },
      data() {
          return {
              periods: [],
              students: [],
              selectedTerm: null,
              pagination: {
                  page: 1,
                  totalItems: null,
                  itemsPerPage: 20,
              },
              body: {
                  termId: null,
                  search: null,
                  sellerId: null,
                  careerId: null,
                  isNI: true,
                  paid: null,
              },
              asidebar: {
                  items: [
                      {
                          id: 'sellerId',
                          name: this.$t('modules.comisiones.students.asideBar.agent'),
                          filters: [
                              {
                                  name: 'Agente',
                                  clearable: true,
                                  items: [],
                                  value: null,
                                  type: 'autocomplete',
                                  header: 'description',
                              }
                          ]
                      }, {
                          id: 'careerId',
                          name: this.$t('modules.comisiones.students.asideBar.career'),
                          filters: [
                              {
                                  name: 'Carrera',
                                  clearable: true,
                                  items: [],
                                  value: null,
                                  type: 'autocomplete',
                                  header: 'description',
                              }
                          ]
                      },
                      {
                          id: 'isNI',
                          name: this.$t('modules.comisiones.students.asideBar.type'),
                          filters: [
                              {
                                  type: 'radio',
                                  value: true,
                                  radios: [
                                      {name: 'NI', value: true },
                                      {name: 'RI', value: false},
                                  ],
                              }
                          ]
                      },
                      {
                          id: 'paid',
                          name: this.$t('modules.comisiones.students.asideBar.state'),
                          filters: [
                              {name: 'Pagó', type: 'check', value: true, items: [], dataFiltered: '' },
                              {name: 'No pagó', type: 'check', value: true, items: [], dataFiltered: '' }
                          ]
                      },
                  ]
              },
              headers: [
                  { text: this.$t('modules.comisiones.students.table.headers.identification'), value: 'studentDni' },
                  { text: this.$t('modules.comisiones.students.table.headers.name'), value: 'completeName' },
                  { text: this.$t('modules.comisiones.students.table.headers.phone'), value: 'phone' },
                  { text: this.$t('modules.comisiones.students.table.headers.email'), value: 'email' },
                  { text: this.$t('modules.comisiones.students.table.headers.career'), value: 'careerName' },
                  { text: this.$t('modules.comisiones.students.table.headers.periods'), value: 'lastPaidTerm' },
                  { text: 'Pagó', value: 'paid' }
              ],
          }
      },
      computed: {
          ...mapGetters({
              userRoles: 'commons/getUserRoles',
              loggedUser: 'commons/getUserID'
          }),
          isAdmin(){
              return !!this.userRoles.find(rol => {return rol.rolType.meaning == 'Administrador'});
          },
      },
      async mounted() {
          await this.getSellersCode()
          await this.getPeriods()
          this.getCareersCode()
          this.getStudents()
      },
      methods: {
          changed(index, i, value) {
              const selectedItem = this.asidebar.items[index];
              this.body[selectedItem.id] = value;
              if(selectedItem.id == 'paid'){
                  const paidStatus = this.paidStatus();
                  this.body[selectedItem.id] = paidStatus;
              }
              this.getStudents();
          },
          async getStudents() {
              // Validacion para evitar que se busquen datos general sin un agente seleccionado en caso de no ser admin
              const seller = this.asidebar.items.find(item => item.id == 'seller')
              if (!this.isAdmin && !seller.filters[0].value) return false
              try {
                  const bodyFilter = {
                      ...this.body,
                      userId: this.loggedUser,
                      termId: this.selectedTerm,
                  }
                  const params = {
                      page: this.pagination.page - 1,
                      length: this.pagination.itemsPerPage,
                  }
                  const { data } = await $salesBySeller.filter(bodyFilter, {params});
                  this.students = data.content
                  this.pagination.totalItems = data.totalElements
              } catch (error) {
                  this.pagination.page = 1
                  this.pagination.pagesAmount = 1
                  this.pagination.totalItems = 0
                  throw error;
              }
          },
          async getPeriods() {
              try {
                  const params = { page: 0, length: 20, orderBy: 'classStartDate', orientation: 'desc', termType: 193 }
                  const { data } = await $terms.find(null, null, { params });
                  const today = moment().format('YYYY-MM-DD')
                  let currentPeriod = data.content.find(term => term.saleDate <= today && term.saleDateTo >= today)
                  if(!currentPeriod){
                    currentPeriod = data.content.find(term => term.saleDateFrom <= today && term.saleDateTo >= today)
                  }
                  this.selectedTerm = currentPeriod.id
                  this.periods = data.content
              } catch (error) {
                  this.periods = []
                  this.selectedTerm = null
                  throw error
              }
          },
          searchMethod(searchBody){
              this.paramPeriod = searchBody.period
              this.search = searchBody.search
              this.getStudents();
          },
          async getSellersCode() {
              const asidebar = this.asidebar.items.find(item => item.id == 'sellerId')
              try {
                  const response = await $sellers.find('users', null, { params: { user: this.loggedUser, status: true }})
                  asidebar.filters[0].items = response.data.map(seller => { return { text: `${seller.sellerCode} - ${seller.sellerName}`, value: seller.id }})
              } catch (error) {
                  asidebar.filters[0].items = [];
                  throw error;
              }
          },
          async getCareersCode() {
              const careerFilter = this.asidebar.items.find(item => item.id == 'careerId')
              try {
              const response = (await $careers.find(null, null, { params: { page: 0, length: 500 } })).data;
              careerFilter.filters[0].items = response.content.map(career => ({ text: `${career.description}`, value: career.id }))
              } catch (error) {
              this.asidebar.items[0].filters[0].items = [];
              throw error;
              }
          },
          paidStatus() {
              const paidFilter = this.asidebar.items.find(item => item.id === 'paid');
              const paidActive = paidFilter.filters.find(filter => filter.name === 'Pagó').value;
              const noPaidActive = paidFilter.filters.find(filter => filter.name === 'No pagó').value;
              return (paidActive && noPaidActive) ? null : (paidActive ? 'paid' : 'notPaid');
          }
      },
  }
</script>
