/* eslint-disable no-useless-catch */
<template>
  <v-dialog v-model="showDialog" persistent transition="dialog-top-transition" max-width="600">
    <template v-slot>
      <v-card>

        <v-container class="container-custom ">
          <v-row class="justify-end">
            <v-btn icon light @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-row class="px-12">
            <p :style="messageStyle">{{ message }}</p>
            <v-col cols="12">
              <v-text-field @input="onCodeInput" v-model="code" required ref="code" :error-messages="errorMessages"
                @blur="$v.code.$touch()" outlined
                :label="$t('modules.messaging.template.newArea.labelCode')"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end px-12 mb-4">
            <Button 
            white :text="$t('actions.cancel')" 
            @clicked="closeDialog" 
            class="mr-4"></Button>
            <Button 
            :loading="loading" 
            :error="error"
            :errorText="'Error'"
              :text= "textButton" 
              @clicked="submit"
              ></Button>
          </v-row>
        </v-container>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Button from '@/components/Button/Button';
//import PeriodsUpdate from '../Views/PeriodsUpdate.vue';
import {
  required,
  maxLength,
  minLength
} from 'vuelidate/lib/validators';


export default {
  name: 'TwoFactorValidation',
  components: {
    Button,
  },
  props: {
    isCodeValid: { type: Boolean, default: true },
    loading: {type:Boolean, default:false},
    error: {type:Boolean, default:false},
    textButton: String
  },
  data() {
    return {
      showDialog: false,
      code: '',
      createButton: {
        error: false,
      },
      message: 'Ingrese el código de doble factor:',
      messageStyle: { color:'grey', fontWeight:'bold'}
    }
  },
  computed: {
    errorMessages() {
      if (this.isCodeValid === false) {
        return this.$t('modules.academic.periods.update.errors.invalidCognitoCode');
      }
      if (this.$v.code.$error) {
        if (!this.$v.code.required) {
          return this.$t('modules.academic.periods.update.errors.required');
        } else if (!this.$v.code.maxLength || !this.$v.code.minLength) {
          return this.$t('modules.academic.periods.update.errors.maxLength');
        }

      }
      return '';
    }
  },
  methods: {
    onCodeInput() {
      this.$v.$touch();
      this.emitClearCodeValid();
    },
    emitClearCodeValid() {
      this.$emit('clearCodeValid');
    },
    closeDialog() {
      this.showDialog = false;
      this.$v.$reset();
      this.code = '';
      this.errorText = null;
      this.emitClearCodeValid();

    },
    openDialog() {
      this.showDialog = true;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.validateCognitoCode();
      }
    },
    async validateCognitoCode() {
      this.$v.$touch();
      this.$emit('codeVerification', this.code);
    },
  },
  validations: {

    code: {
      required,
      maxLength: maxLength(6),
      minLength: minLength(6)
    },
  }

}
</script>

<style lang="scss" scoped></style>