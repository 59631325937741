<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.changeStudyPlan.table.breadcrumbs.title')"
          :description="$t('modules.changeStudyPlan.table.breadcrumbs.description')"
        >
        <Button
          @clicked="tmpDrawerOpen"
          icon='mdi-download'
          :text="$t('modules.changeStudyPlan.table.actions.massiveChange')"
        ></Button>
        </Breadcrumbs>
              <v-row justify="center">
                  <v-col sm="12" class="viewSpaces">
                      <SuperTable
                          :headers="headers"
                          :data="studyPlan"
                          @searchAction="searchAction"
                          :permissions="{read: canRead}"
                          @changeStudyPlan="(legajo)=>changeStudyPlanPopUp(legajo)"
                      ></SuperTable>
                  </v-col>
              </v-row>
              <TemporaryRightDrawer
                v-if="openedDrawer"
                @tmpDrawerOpen="tmpDrawerOpen"
                :open="tmpDrawer.open"
                :title="tmpDrawer.title"
                :description="tmpDrawer.description"
                >
                    <MassImportWorkData
                        @close="tmpDrawer.open = false"
                        @newCreate="tmpDrawer.open"
                        insideDrawer
                    ></MassImportWorkData>
                </TemporaryRightDrawer>
                <RequestStudyPlanPopUp 
                  :open="openModal" 
                  @close="closeModal" 
                  @requestStudyPlan="requestStudyPlan"
                  :arrayStudyPlan="arrayStudyPlan"
                  :studyPlan="studyPlan" 
                  :response="response"
                  :successMessage="successMessage"
                  :createButton="createButton"
              />
          </v-container>
      </v-container>
  </v-container>
</template>

<script>
  import SuperTable from '../Components/SuperTable';
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import {$studentCareer} from '../Services';
  import Button from '@/components/Button/Button';
  import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
  import MassImportWorkData from '../Components/MassImportWorkData.vue';
  import RequestStudyPlanPopUp from '../Components/RequestStudyPlanPopUp.vue'


  
  export default {
    name: 'ChangeStudyPlanTable',
    components: {
      SuperTable,
      Breadcrumbs,
      TemporaryRightDrawer,
      Button,
      MassImportWorkData,
      RequestStudyPlanPopUp
    },
      data () {
          return {
              legajo: null,
              search: '18439357',
              studyPlan: [],
              arrayStudyPlan: null,
              openedDrawer: true,
              openModal: false,
              response: false,
              successMessage: {
                    actionPrimary: null,
                    title: null,
                    type: null,
                    text: null,
                },
                createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
              tmpDrawer: {
                    open: false,
                    title: this.$t('modules.changeStudyPlan.table.openDrawer.title'),
                    description: this.$t('modules.changeStudyPlan.table.openDrawer.subtitle')
              },
              headers: [
                  {
                    text: this.$t('modules.changeStudyPlan.table.headers.name'),
                    value: 'name'
                  }, {
                    text: this.$t('modules.changeStudyPlan.table.headers.lastName'),
                    value: 'lastName'
                  },
                  {
                    text: this.$t('modules.changeStudyPlan.table.headers.identification'),
                    value: 'identification'
                  },{
                    text: this.$t('modules.changeStudyPlan.table.headers.career'),
                    value: 'career',
                  }, {
                    text: this.$t('modules.changeStudyPlan.table.headers.studyPlan'),
                    value: 'currentPlanCode',
                    align: 'center',
                  }, {
                    text: this.$t('modules.changeStudyPlan.table.headers.legajo'),
                    align: 'center',
                    value: 'legajo'
                  },{
                    text: this.$t('modules.changeStudyPlan.table.headers.statusCareer'),
                    align: 'center',
                    value: 'careerStatus'
                  }, {
                    text: '',
                    value: 'action',
                  },
                ],
          }
      },
      methods: {
          tmpDrawerOpen() {
            this.tmpDrawer.open = !this.tmpDrawer.open;
          },
          searchAction(data) {
              const dataTrimed = data.trim()
              if(dataTrimed.length > 3){
                this.search = dataTrimed
                this.getStudent();
              }
          },
          async getStudent() {
            try {
              if (this.search != null) {
                const data = await $studentCareer.find(this.search, null, null )
                this.studyPlan = data.data.map((item) => {
                  return {
                    name: item.name,
                    lastName: item.lastName,
                    identification: item.identification,
                    legajo: item.id,
                    career: item.career,
                    careerStatus: item.status.meaning,
                    currentPlanCode: item.currentPlanCode,
                    studyPlans: item.studyPlans
                  }
                })
                this.arrayStudyPlan = this.studyPlan[0].studyPlans
              }
            } catch (error) {
              this.studyPlan = []
            }
          },
          closeModal(){
                this.openModal = false
                this.response = false
          },
          changeStudyPlanPopUp(legajo) {
                if (!this.loading) {
                    if(legajo){
                        this.openModal = true
                        this.legajo = legajo
                    } else {
                        this.openModal = true
                        }
                    }
            },
            async requestStudyPlan(selectedStudyPlan) {
                if(!selectedStudyPlan) {
                    this.response = true
                    this.createButton.loading = false
                    this.createButton.error = true
                  return
                }
                this.createButton.loading = true
                try {
                    await $studentCareer.update(this.legajo, null, {params: {code:selectedStudyPlan}} )                                      
                    await this.getStudent()
                    setTimeout(() => {
                      this.closeModal()
                    }, 2000)
                    this.loading = true
                    this.createButton.loading = false
                    this.createButton.success = true
                    this.response = true
                } catch (error) {
                    this.response = true
                    this.createButton.loading = false
                    this.createButton.error = true
                    
                } finally {
                    this.loading = false
                }
            },
      },
    computed: {
      canRead() {
                return this.$permissions.portaladministrativo.students.change_study_plan.READ
            },
    }
  }
</script>