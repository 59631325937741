const IntermediateDegree = {
    es: {
        breadcrumbs: {
            title: 'Certificados Intermedios',
            description: 'Gestión de certificados Intermedios'
        },
        certificados:{
            iniciados:'Inicio de Tramites',
            generados:'Generados',
            en_legalizacion:'En Legalización',
            legalizados:'Legalizados',
            enviados_a_ceo:'Enviados a Ceo'
        },
        table: {
            headers: {
                student: 'Alumno',
                numero: 'Numero',
                legajo:'Legajo',
                curp: 'CURP',
                carrera: 'Carrera',
                estado: 'Estado'
            }
        },
    },
    es_CL: {
        breadcrumbs: {
            title: 'Certificados Intermedios',
            description: 'Gestión de certificados Intermedios'
        },
        certificados:{
            iniciados:'Inicio de Tramites',
            generados:'Generados',
            en_legalizacion:'En Legalización',
            legalizados:'Legalizados',
            enviados_a_ceo:'Enviados a Ceo'
        },
        table: {
            headers: {
                student: 'Alumno',
                numero: 'Numero',
                legajo:'Legajo',
                dni: 'DNI',
                carrera: 'Carrera',
                estado: 'Estado'
            }
        }
    }
};

export default IntermediateDegree
