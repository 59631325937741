<template>
  <div class="loginFormCard fullHeightViewport">
    <div class="py-6 d-flex flex-column">
      <div class="d-flex justify-start">
        <v-img
          max-height="50px"
          max-width="150px"
          class="loginFormCard__logo"
          contain
          :src="logoPath"
        ></v-img>
      </div>
      <div class="text-left mt-12">
        <h1 class="secondary--text">{{ $t('modules.login.signIn.welcomeMessage') }}</h1>
        <p class="body-1 mb-0">{{ $t('modules.login.signIn.subMessage') }}</p>
      </div>
    </div>
    <v-alert text dense color="error" class="ma-0" :value="errorAuth">{{ $t('modules.login.signIn.authError') }}</v-alert>
    <v-alert text dense color="error" class="ma-0" :value="serverError">{{ $t('modules.login.signIn.serverError') }}</v-alert>
    <form @keyup.enter="logIn">
      <v-text-field
        class="mt-6"
        v-model="$v.email.$model"
        :error-messages="emailErrors"
        :label="$t('modules.login.signIn.items.email')"
        outlined
        required
        :placeholder="!autofilled ? ' ' : ''"
        minlength="8"
        maxlength="50"
        type="text"
        autocomplete="email"
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
      ></v-text-field>
      <v-text-field
        v-model="$v.pass.$model"
        :error-messages="passErrors"
        :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
        :type="hidePass ? 'text' : 'password'"
        :label="$t('modules.login.signIn.items.password')"
        outlined
        required
        autocomplete="current-password"
        :placeholder="!autofilled ? ' ' : ''"
        @click:append="hidePass = !hidePass"
        @input="$v.pass.$touch()"
        @blur="$v.pass.$touch()"
        class="mt-6"
      ></v-text-field>
      <div class="d-flex justify-end recovery-btn">
        <h6 class="caption primary--text cursor-pointer" @click="recoveryPass">{{ $t('actions.forgotPass') }}</h6>
      </div>
      <div :class="['d-flex mt-6', !showCheckBox ? 'justify-space-between' : 'justify-end' ]">
        <v-checkbox
          class="mt-0"
          v-if="!showCheckBox"
          v-model="advisor"
          :label="`${$t('modules.login.signIn.items.advisor')}`"
        />
        <div class="ml-auto mt-12">
          <Button
            @clicked="logIn"
            primary
            :loading="loading"
            rounded
            large
            :text="$isTeclab ?  $t('actions.next') : $t('actions.enter')"
            :error="errorAuth"
            :errorText="$t('actions.error')"
            :disabled="!isLoginFormValid"
          ></Button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Button from '@/components/Button/Button'
import Constants from '@/plugins/constants'

export default {
  name: 'SignInForm',
  components:{
    Button
  },
  props: {
    loading: Boolean,
    errorAuth: Boolean,
    serverError: Boolean,
    logoPath: String,
  },
  data () {
    return {
      email: '',
      pass: '',
      hidePass: false,
      autofilled: false,
      url_data_server: Constants.URL_DATA_SERVER,
      showCheckBox: Constants.ADVISOR,
      isPortalAdmin: Constants.ADVISOR,
      advisor: false,
      }
  },
  methods: {
    logIn () {
      this.$v.$touch();
      if (this.email === '' || this.pass === '') return false;
      this.$trackEvent('Login', 'Clk_BtnNext_Login', 'Login_Sesión');
      const data = { email: this.email.toLowerCase(), pass: this.pass, advisor: this.isPortalAdmin ? true : this.advisor};
      this.$emit('logIn', data)
    },
    clear () {
      this.$v.$reset();
      this.email = '';
      this.pass = '';
      this.$store.dispatch('login/clearErrorLogin')
    },
    recoveryUser () {
      this.$router.push('/login/recovery-user').catch(err => { throw err; })
    },
    recoveryPass () {
      this.$trackEvent('Login', 'Clk_UrlRecCont_Login', 'Login_Sesión');
      this.$router.push('/login/recovery-password')
    }
  },

  // mixins: [validationMixin],

  validations: {
    email: { required },
    pass: { required },
  },

  computed: {
    isLoginFormValid() {
      return !this.$v.$anyError && this.email !== '' && this.pass !== '';
    },
    emailErrors () {
      const errors = [];
      if (!this.$v.email.$anyError ) return errors;
      if(!this.$v.email.required) errors.push(this.$t('modules.login.signIn.errors.requiredEmail'));
      // if(!this.$v.email.email) errors.push(this.$t('modules.login.signIn.errors.invalidEmail'));
      return errors
    },
    passErrors () {
      const errors = [];
      const requiredPasswordError = this.$t('modules.login.signIn.errors.requiredPassword')
      if (!this.$v.pass.$anyError) return errors;
      !this.$v.pass.required && errors.push(requiredPasswordError);
      return errors
    },
  },
  watch: {
    email() {
      this.autofilled = true
    }
  },
}
</script>
