import transferlistTranslations from './TransferList/Translations/index'
import appBarTranslations from './AppBar/Translations/index'
import alertPopupTranslations from './AlertPopup/Translations/index'
import changePasswordAlertTranslations from './ChangePasswordPopUp/Translations/index'
import ValidateEmailPhonePopUpTranslations from './ValidateEmailPhonePopUp/Translations/index.js'
import TelephoneInputTranslations from './TelephoneInput/Translations/index'
import AutocompleteInputTranslations from './AutocompleteInput/Translations/index.js'
import helpMenuTranslations from './HelpMenu/Translations/index.js'
import emptyStateTranslations from './EmptyState/Translations/index.js'
import notFoundStateTranslations from './NotFoundState/Translations/index.js'
import identificationInputTranslations from './IdentificationInput/Translations/index.js'
import fromToCardTranslations from './FromToCard/Translations/index.js'
import multipleStatusGroupsTranslations from './MultipleStatusGroups/Translations/index.js'
import multipleProductsCostsTranslations from './MultipleProductsCosts/Translations'
import paymentsTranslations from './Payments/Translations'
import fileUploaderTranslations from './FileUploader/Translations/index.js'
import breadcrumbs from './Breadcrumbs/Translations/index.js'
import accessBlockedTranslations from './AccessBlocked/Translations/index'


const componentsTranslations = {
  es_CL: {
    appBar: { ...appBarTranslations.es_CL },
  },
  es_PE: {
      autocompleteInput: { ...AutocompleteInputTranslations.es_PE },
      transferlistTranslations: { ...transferlistTranslations.es_PE },
      identificationInput: { ...identificationInputTranslations.es_PE },
      fromToCard: { ...fromToCardTranslations.es_PE },
      multipleStatusGroups: { ...multipleStatusGroupsTranslations.es_PE },
      fileUploader: { ...fileUploaderTranslations.es_PE },
  },
  es_MX: {
    appBar: { ...appBarTranslations.es_MX },
    autocompleteInput: { ...AutocompleteInputTranslations.es_MX },
    transferlistTranslations: { ...transferlistTranslations.es_MX },
    identificationInput: { ...identificationInputTranslations.es_MX },
    fromToCard: { ...fromToCardTranslations.es_MX },
    payments: {...paymentsTranslations.es_MX},
    multipleStatusGroups: { ...multipleStatusGroupsTranslations.es_MX },
    fileUploader: { ...fileUploaderTranslations.es_MX },
},
  es: {
    breadcrumbs:{...breadcrumbs.es},
    autocompleteInput: { ...AutocompleteInputTranslations.es },
    transferlistTranslations: { ...transferlistTranslations.es },
    appBar: { ...appBarTranslations.es },
    alertPopup: { ...alertPopupTranslations.es },
    changePasswordAlert: { ...changePasswordAlertTranslations.es },
    validateEmailPhoneAlert: { ...ValidateEmailPhonePopUpTranslations.es },
    telephoneInput: { ...TelephoneInputTranslations.es },
    helpMenu: { ...helpMenuTranslations.es },
    emptyState: { ...emptyStateTranslations.es },
    notFoundState: { ...notFoundStateTranslations.es },
    identificationInput: { ...identificationInputTranslations.es },
    fromToCard: { ...fromToCardTranslations.es },
    multipleStatusGroups: { ...multipleStatusGroupsTranslations.es },
    multipleProductsCosts: {...multipleProductsCostsTranslations.es},
    payments: {...paymentsTranslations.es},
    fileUploader: { ...fileUploaderTranslations.es },
    accessBlocked: { ...accessBlockedTranslations.es },
  },
  en: {
    breadcrumbs:{...breadcrumbs.en},
    transferlistTranslations: { ...transferlistTranslations.en },
    autocompleteInput: { ...AutocompleteInputTranslations.en },
    appBar: { ...appBarTranslations.en },
    alertPopup: { ...alertPopupTranslations.en },
    changePasswordAlert: { ...changePasswordAlertTranslations.en },
    ValidateEmailPhoneAlert: { ...ValidateEmailPhonePopUpTranslations.en },
    telephoneInput: { ...TelephoneInputTranslations.en },
    helpMenu: { ...helpMenuTranslations.en },
    emptyState: { ...emptyStateTranslations.en },
    notFoundState: { ...notFoundStateTranslations.en },
    identificationInput: { ...identificationInputTranslations.en },
    fromToCard: { ...fromToCardTranslations.en },
    multipleStatusGroups: { ...multipleStatusGroupsTranslations.en },
    multipleProductsCosts: {...multipleProductsCostsTranslations.en},
    payments: {...paymentsTranslations.es},
    fileUploader: { ...fileUploaderTranslations.en },
    accessBlocked: { ...accessBlockedTranslations.en },
  }
};

export default componentsTranslations;
