<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight" v-if="!selectedCards">
      <v-container fluid class="px-0">
        <div class="px-md-10">
          <div class="d-flex">
            <v-btn icon @click="$emit('goBackPayment')"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <h4 class="d-flex align-center primary--text ml-2">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.creditcardMultiple') }}</h4>
          </div>

          <p class="body-2">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.creditcardMultipleDescription') }}</p>

          <v-container>
            <v-row v-for="(card, index) in resumen.cards" :key="index">
              <v-col cols="12" sm="4" lg="3">
                <span class="d-block subtitle-2 mt-2">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.creditCard') }} {{ index + 1 }}</span>
              </v-col>
              <v-col cols="12" sm="5" md="6" lg="4">
                <v-text-field
                  class="input-amount"
                  type="number"
                  min="0"
                  v-model.number="card.amount"
                  :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.amount')"
                  @keypress="$validateDecimalInputNumber($event, card.amount, total)"
                  @keyup="adjustValue(index)"
                  outlined
                >
                  <template v-slot:prepend-inner>
                      <span>{{ $countryConstants.currency }}</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-container>
    </v-container>
    <div v-if="selectedCards">
      <div v-for="(card, index) in resumen.cards" :key="index" :id="index">
        <PaymentsSingleCardView
          v-if="index == activeCardIndex"
          :ref="`single-card-${index}`"
          :resumen="resumen"
          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
          :cardIndex="index"
          :summaryButtons="summaryButtons"
          :selectedMethod="selectedMethod"
          :pendingPayment="(index + 1) != resumen.cards.length"
          @paymentError="paymentError"
          @paymentProcessed="paymentProcessed"
          @cancelPaymentMethod="onCancelPaymentClick"
          @changeStep="nextStepChange"
          @goBackPayment="goBackToAmounts"
          @setDue="setDue"
        ></PaymentsSingleCardView>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
  .input-amount ::v-deep .v-input__prepend-inner
    margin-top: 3px !important
    margin-right: 5px
</style>

<script>
import PaymentsSingleCardView from './PaymentsSingleCardView';
import { $payments } from '../Services';

export default {
  props: {
    resumen: Object,
    summaryButtons: Object,
    selectedMethod: Object,
    unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } }
  },
  components: {
    PaymentsSingleCardView,
  },
  data() {
    return {
      selectedCards: false,
      creditCards: [],
      activeCardIndex: 0,
      total: 0,
    };
  },
  computed: {
    canContinue() {
      let subTotal = 0
      let empty = false

      this.resumen.cards.forEach(card => {
        if (card.amount) subTotal += this.$isTeclab? parseFloat(card.amount) :  card.amount
        else empty = true
      })

      return !empty && subTotal == this.total
    },
  },
  mounted() {
    this.total = this.$$isTeclab ? this.resumen.total.toFixed(2) : this.resumen.total.toFixed(0)
  },
  methods: {
    paymentProcessed(response) {
      this.resumen.cards[response.index].paymentId = response.paymentId
      if (++this.activeCardIndex == this.resumen.cards.length - 1) this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentLink')
      else this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
    },
    paymentError() {
      if (this.activeCardIndex) {
        this.resumen.cards.forEach(async card => {
          if (card.paymentId) {
            await $payments.updatePayment(card.paymentId, 'cancel', {accountId: card.accountId})
            card.paymentId = null
          }
        })

        this.activeCardIndex = 0
      }
    },
    goBackToAmounts() {
      this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
      this.summaryButtons.primary.disabled = !this.canContinue
      this.selectedCards = false
      this.$emit('setDue')
    },
    paymentCardsInfo() {
      if (this.selectedCards) {
        this.$refs[`single-card-${this.activeCardIndex}`][0].saveBillingData()
      } else {
        // Acaba de elegir el monto de cada tarjeta
        this.selectedCards = true;
        this.summaryButtons.primary.disabled = true
      }
    },
    nextStepChange() {
      // Entra aca cuando genero el segundo pago correctamente
      // Proceso con exito todos los pagos pendientes y emito changeStep
      this.resumen.cards.forEach(async card => {
        if (card.paymentId){
          try {
              const { data } =  await $payments.updatePayment(card.paymentId, 'capture', {accountId: card.accountId});
              // approved, in_process
              if (data && data.status === 'rejected' && this.isCodeError(data.status_detail)){
                  this.errorLoading('¡Error en primer pago!', this.errorMessage(data.status_detail))
                  this.summaryButtons.primary.error = true
                  this.summaryButtons.primary.disabled = true
              } else if (data.status === 'approved' || data.status === 'in_process') {
                  this.$emit('changeStep', data.status);
                  this.successLoading()
              } 
          } catch (response) {
              this.errorLoading('¡Ups!', 'Se genero un error en el proceso de pago. Por favor, vuelva a intentarlo')
              this.summaryButtons.primary.error = true
              throw response
          } finally {
            this.summaryButtons.primary.loading = false
          }
        }
      })
    },
    successLoading() {
        this.summaryButtons.primary.loading = false
        this.summaryButtons.primary.success = true
        setTimeout(() => {
            this.summaryButtons.primary.success = false
            this.summaryButtons.primary.disabled = true
        }, 2500)
    },
    onCancelPaymentClick() {
      this.$emit('cancelPaymentMethod')
    },
    adjustValue(index) {
      let { total } = this
      this.resumen.cards.forEach((card, i) => {
        if (i == index && card.amount) total -= this.$isTeclab ? parseFloat(card.amount).toFixed(2) :  card.amount
      });
      if (index == 0) this.resumen.cards[1].amount = this.$isTeclab ? parseFloat(total).toFixed(2) : total
      else this.resumen.cards[0].amount = this.$isTeclab ? parseFloat(total).toFixed(2) : total
    },
    setDue(due) {
      this.creditCards?.[this.activeCardIndex] ? this.creditCards[this.activeCardIndex] = due : this.creditCards.push(due)
      this.$emit('setDue', ...this.creditCards)
    },
    isCodeError(data){
      const codes = [
        'cc_rejected_bad_filled_card_number',
        'cc_rejected_bad_filled_date',
        'cc_rejected_bad_filled_other',
        'cc_rejected_bad_filled_security_code',
        'cc_rejected_blacklist',
        'cc_rejected_call_for_authorize',
        'cc_rejected_card_disabled',
        'cc_rejected_card_error',
        'cc_rejected_duplicated_payment',
        'cc_rejected_high_risk',
        'cc_rejected_insufficient_amount',
        'cc_rejected_invalid_installments',
        'cc_rejected_max_attempts',
        'cc_rejected_other_reason'
      ]
      return codes.includes(data);
    },
    errorMessage(error){
      const codes = {
        cc_rejected_bad_filled_card_number: this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_bad_filled_card_number') ,
        cc_rejected_bad_filled_date: this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_bad_filled_date'),
        cc_rejected_bad_filled_other:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_bad_filled_other'),
        cc_rejected_bad_filled_security_code: this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_bad_filled_security_code'),
        cc_rejected_blacklist:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_blacklist'),
        cc_rejected_call_for_authorize:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_call_for_authorize'),
        cc_rejected_card_disabled:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_card_disabled'),
        cc_rejected_card_error:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_card_error'),
        cc_rejected_duplicated_payment:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_duplicated_payment'),
        cc_rejected_high_risk:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_high_risk'),
        cc_rejected_insufficient_amount:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_insufficient_amount'),
        cc_rejected_invalid_installments:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_invalid_installments'),
        cc_rejected_max_attempts:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_max_attempts'),
        cc_rejected_other_reason:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_other_reason')
      }
      return codes[error]
    },
    errorLoading(title = null, message = null) {
      this.summaryButtons.primary.loading = false
      this.summaryButtons.primary.error = true
      if (title && message) {
          this.$store.dispatch('commons/openPopUp', {
              title,
              content: [{ value: message }],
              actionPrimary: { text: this.$t('actions.accept'), callback: () => {this.$router.go()} },
              icon: { color: 'error', name: 'mdi-close' },
              color: 'primary',
          });
      }
    },
  },
  watch: {
    canContinue(value) {
      this.summaryButtons.primary.disabled = !value
    }
  }
};
</script>
