const syncTeacherTranslations = {
    es : {
            breadcrumbs: {
                title: 'Sincronización de Profesores',
                description: 'Administración de sincronización de profesores',
            },
            table:{
                headers:{
                    identification: 'DNI',
                    nameAndLastname: 'Nombre y Apellido',
                    career_status: 'Estado de carrera',
                    term: 'Periodo',
                    synchronization: 'Sincronización',
                    edit:'',
                }
            },
            subject:{
                table:{
                    headers:{
                        code: 'Código',
                        name: 'Nombre',
                        subjectStatus: 'Estado de la asignatura',
                        termCode: 'Periodo',
                        synchronization: 'Sincronización',
                        edit:'',
                    }
                },
            },
            actions:{
                sync_up: 'Sincronizar',
                update: 'Actualizar',
                close: 'Cerrar',
                synchronizationSection: 'Sincronizar Sección'
            },
            message:{
                syncUpTeacherSuccess: 'Profesor sincronizado correctamente',
                syncUpTeacher: 'Estas seguro que deseas sincronizar al profesor',
                syncUpSection: 'Estas seguro que deseas sincronizar la sección con canvas',
                syncUpSectionSuccess: 'Sección sincronizada correctamente',
                syncUpSectionError: 'Error al sincronizar al Sección',
                syncUpSubject: 'Estas seguro que deseas sincronizar la asignatura',
                syncUpSubjectSuccess: 'Asignatura sincronizada correctamente',
                syncUpSubjectError: 'Error al sincronizar la asignatura',
                syncUpTeacherError: 'Error al sincronizar el profesor',
                syncAccept: 'Si, sincronizar',
                syncCancel: 'Cancelar'
            },
            drawer:{
                title: 'Asignaturas',
                description: 'Lista de las asignaturas en los módulos',
                table:{
                    title: 'Actualización de asignaturas',
                    description: 'Aquí podrás actualizar las asignaturas del sistema con las asignaturas de canvas.',
                    headers:{
                        section:'Seccion',
                        canvas:'Canvas',
                        sis:'SIS',
                        subject: 'Asignaturas',
                        term: 'Periodo',
                    }
                }
            }
    },
}

export default syncTeacherTranslations