
const changeStudyPlanTranslations = {
    es: {
        table: {
            error: {
                required: 'El campo es obligatorio'
            },
            breadcrumbs: {
                title: 'Cambio de plan',
                description: 'Gestión de cambio de plan'
            },
            headers: {
                legajo: 'Legajo',
                name: 'Nombre',
                lastName: 'Apellido',
                identification: 'DNI',
                career: 'Carrera',
                studyPlan: 'Plan de estudio',
                statusCareer: 'Estado de carrera',
            },
            actions: {
                changeStudyPlan: 'Cambiar de plan',
                massiveChange: 'Cambio de plan masivo',
                message: {
                    confirmation_change_plan: 'Elegir plan de estudio al que quiera cambiarse '
                }
            },
            openDrawer: {
                title: 'Carga de Excel',
                subtitle:'Cambio masivo de planes de estudio'
            },
            massImportDrawer: {
                breadcrumbs: {
                    title: 'Carga masiva',
                    description: 'Cargar datos laborales a diversos estudiantes'
                },
                title: 'Cargue el listado de estudiantes',
                messages: {
                    noFiles: 'No se detectaron archivos para enviar',
                    sendConfirmation: '¿Desea enviar el siguiente archivo? No se podrá revertir',
                    pendingFiles: 'Hay un archivo pendiente. ¿Desea salir?',
                    success: 'Se cargaron correctamente los datos',
                    warning: 'Verifique que se haya procesado todo correctamente',
                    errorOffline:'Problemas al intentar enviar el archivo',
                    errorAll: ' Todos los legajos son invalidos',
                    errorAny: 'Hay información invalida.',
                    createAgain: 'Volver a cargar'
                    },
                loading: 'Espere un momento su archivo se está cargando..'
                }
        },

        },
        es_CL: {
            table: {
                headers: {
                    legajo: 'Legajo',
                    name: 'Nombre',
                    lastName: 'Apellido',
                    identification: 'RUT',
                },
            },
        }
    };

    export default changeStudyPlanTranslations
