const referredCompaniesTranslations = {
  es: {
    table: {
      breadcrumbs: {
        title: 'Empresas referidas',
        description:'Alta, baja y modifición de empresas referidas',
      },
      headers: {
        name: 'Razón social',
        contactName: 'Contacto',
        email: 'Email',
        cellphone: 'Celular',
        cuit: 'CUIT',
        position: 'Cargo',
        paymentMethod: 'Método de Pago',
        status: 'Estado'
      },
      actions: {
        errorDelete: 'Error al eliminar',
      },
      messages: {
        deleteError: 'No se pudo eliminar la regla de suscripción seleccionado',
      }
    },
    create: {
      breadcrumbs: {
        title: 'Nueva empresa referida',
        description:'Crea una nueva empresa referida',
      },
      codeSection: {
        title: 'Datos de la empresa',
        subtitle: 'Agrega la información básica de la nueva empresa. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          name: 'Razón social',
          cuit: 'CUIT',
          contactName: 'Contacto',
          email: 'Email',
          position: 'Cargo',
          cellphone: 'Teléfono',
        },
      },
      billingProductSection: {
        title: 'Producto de facturación',
        subtitle: 'Selecciona en esta sección los productos de facturación donde esta regla de suscripción puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      successMessage: {
        title: 'Regla de suscripción creada correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al crear la regla de suscripción',
      }
    },
    update: {
      breadcrumbs: {
        title: 'Editar empresa referida',
        description:'Modifica una empresa referida',
      },
      codeSection: {
        title: 'Datos de la empresa',
        subtitle: 'Edita la información básica de la empresa. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          name: 'Razón social',
          cuit: 'CUIT',
          contactName: 'Contacto',
          email: 'Email',
          position: 'Cargo',
          cellphone: 'Teléfono',
        },
      },
      billingProductSection: {
        title: 'Producto de facturación',
        subtitle: 'Selecciona en esta sección los productos de facturación donde esta regla de suscripción puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      successMessage: {
        title: 'Regla de suscripción modificada correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al modificar la regla de suscripción',
      }
    },
    validations: {
      nameRequired: 'Ingrese la razón social',
      nameAlphabeticWithSpaces: 'Solo alfabéticos',
      nameMaxLength: 'Máximo 60 caracteres',
      cuitRequired: 'Ingrese el CUIT',
      cuitFormat: 'Formato de CUIT inválido',
      cuitUnique: 'CUIT ya existente',
      contactNameRequired: 'Ingrese el nombre',
      contactNameAlphabeticWithSpaces: 'Solo alfabéticos',
      contactNameMaxLength: 'Máximo 60 caracteres',
      emailRequired: 'Ingrese el email',
      emailFormat: 'Formato de email inválido',
      positionRequired: 'Ingrese el cargo',
      positionAlphabeticWithSpaces: 'Solo alfabéticos',
      positionMaxLength: 'Máximo 60 caracteres',
    },
  },
  es_MX: {
    table: {
      breadcrumbs: {
        title: 'Empresas referidas',
        description:'Alta, baja y modificación de empresas referidas',
      },
      headers: {
        name: 'Razón social',
        contactName: 'Contacto',
        email: 'Email',
        cellphone: 'Celular',
        cuit: 'RFC',
        position: 'Cargo',
        paymentMethod: 'Método de Pago',
        status: 'Estado'
      },
    }
  },
  en: {

  }
};

export default referredCompaniesTranslations
