<template>
  <OutlinedCard
    v-if="!show"
    :title="$t('modules.admission.preregistration.create.paymentMethod.title')"
    disabled
    class="pb-4 mb-6"
  >
  </OutlinedCard>
  <OutlinedCard
    v-else
    :title="$t('modules.admission.preregistration.create.paymentMethod.title')"
    class="mb-6"
  >
    <v-row>
      <v-col>
        <v-radio-group
          v-model="selectedPaymentMethod"
          mandatory
          row
          hide-details
          class="mt-0 pt-0"
          @change="resetDue"
        >
          <v-radio
            class="pr-8"
            :label="$t('modules.admission.preregistration.create.paymentMethod.type.credit')"
            value="credit"
          ></v-radio>
          <v-radio
            v-if="!$isTeclab"
            class="pr-8"
            :label="$t('modules.admission.preregistration.create.paymentMethod.type.prepaid')"
            value="prepaid"
          ></v-radio>
          <v-radio
            v-if="$isTeclab"
            class="pr-8"
            :label="$t('modules.admission.preregistration.create.paymentMethod.type.cash')"
            value="cash"
          ></v-radio>
          <v-radio
            v-if="isSubscriptionOptionVisible"
            class="pr-8"
            :label="$t('modules.admission.preregistration.create.paymentMethod.type.subscription')"
            value="subscription"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="selectedPaymentMethod == 'credit'">
      <MercadoPagoInstallments
        ref="mp"
        :total="total.cost + total.saving"
        @setDue="setDueMP"
        @resetDue="resetDue"
        :careerId="careerId"
        :studentType="studentType"
      ></MercadoPagoInstallments>
    </v-row>
    <v-row v-else-if="selectedPaymentMethod == 'prepaid'">
      <v-col class="col-6">
        <v-select
          :error="$v.selectedPrepaidDues.$error"
          :error-messages="$v.selectedPrepaidDues.$error ? $t('modules.admission.preregistration.validations.prepaidDuesRequired') : ''"
          :label="$t('modules.admission.preregistration.create.paymentMethod.labels.prepaidDues')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.dues')"
          outlined
          :items="prepaidDues"
          v-model="$v.selectedPrepaidDues.$model"
          return-object
          @blur="$v.selectedPrepaidDues.$touch()"
          @change="$emit('setDue', $v.selectedPrepaidDues.$model)"
        />
      </v-col>
    </v-row>
    <v-row v-else-if="selectedPaymentMethod == 'cash'">
      <v-col class="col-7">
        <SpecialCheck
          :item="{
            label: $t('modules.admission.preregistration.create.paymentMethod.labels.cash'),
            amount: total.cost + total.saving,
            selected: true
          }"
          :has-checkbox="false"
        ></SpecialCheck>
      </v-col>
    </v-row>
    <v-row v-else-if="selectedPaymentMethod == 'subscription'">
      <v-col class="col-6">
        <v-select
          :error="$v.selectedSubscriptionPaymentType.$error"
          :error-messages="$v.selectedSubscriptionPaymentType.$error ? $t('modules.admission.preregistration.validations.subscriptionPaymentMethodRequired') : ''"
          :label="$t('modules.admission.preregistration.create.paymentMethod.labels.subscriptionPaymentMethod')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.subscriptionPaymentMethod')"
          outlined
          :items="subscriptionPaymentTypes"
          item-text="meaning"
          v-model="$v.selectedSubscriptionPaymentType.$model"
          @blur="$v.selectedSubscriptionPaymentType.$touch()"
        />
      </v-col>
      <v-col class="col-6">
        <v-select
          :error="$v.selectedSubscriptionDues.$error"
          :error-messages="$v.selectedSubscriptionDues.$error ? $t('modules.admission.preregistration.validations.subscriptionDuesRequired') : ''"
          :label="$t('modules.admission.preregistration.create.paymentMethod.labels.subscriptionDues')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.subscriptionDues')"
          outlined
          :items="filteredSubscriptionDues"
          v-model="$v.selectedSubscriptionDues.$model"
          return-object
          @blur="$v.selectedSubscriptionDues.$touch()"
          @change="$emit('setDue', $v.selectedSubscriptionDues.$model)"
        />
      </v-col>
    </v-row>
    <template v-if="!$isTeclab">
      <v-row>
        <v-col class="col-8">
          <v-alert
            class="mb-0"
            text
            color="success"
          >
            {{ $t('modules.admission.preregistration.create.paymentMethod.alerts.totalDues', {percentage: '5'}) }}
          </v-alert>
        </v-col>
        <v-col class="col-4 my-auto">
          <v-switch
            class="mt-0"
            :label="$t('modules.admission.preregistration.create.paymentMethod.labels.totalDues', {percentage: '5'})"
          >
          </v-switch>
        </v-col>
      </v-row>
      <v-row v-if="prepaids.length && (duesMP || selectedPrepaidDues)">
        <v-col class="col-12">
          <span class="text-h6">
            {{ $t('modules.admission.preregistration.create.paymentMethod.subtitle.duesPrepaid', {dues: duesMP ? duesMP.due.installments : selectedPrepaidDues.value}) }}
          </span>
        </v-col>
        <v-col class="col-12">
          <v-sheet
            outlined
            rounded
            class="d-flex justify-center"
          >
            <div v-for="(prepaid, index) in prepaids" :key="`paymentMethod-${prepaid.dayTo}-${prepaid.id}`">
              <v-divider v-if="index != 0" vertical ></v-divider>
              <div class="d-flex align-center justify-center" >
                <div class="col-6 text-body-2">
                  {{ $t('modules.admission.preregistration.create.paymentMethod.labels.prepaidDayRange', {dayFrom: prepaid.dayFrom, dayTo: prepaid.dayTo}) }}
                  <v-alert
                    class="mb-0 pa-1 text-center text-body-2"
                    text
                    color="success"
                  >
                    {{ `${prepaid.percentage}% Off` }}
                  </v-alert>
                </div>
                <v-chip label color="primary" class="align-self-center">{{ getPrepaidDiscountAmount(prepaid.percentage) | $currency }}</v-chip>
              </div>
            </div>
          </v-sheet>
        </v-col>
        <v-col class="col-12">
          <span class="text-h6">
            {{ $t('modules.admission.preregistration.create.paymentMethod.subtitle.prepaidTotal') }}
          </span>
        </v-col>
        <v-col class="col-12">
          <v-sheet
            outlined
            rounded
            color="#EBF3FF"
            class="d-flex"
          >
            <div v-for="(prepaid, index) in prepaids" :key="`paymentMethod-${prepaid.dayFrom}_${prepaid.id}`">
              <v-divider v-if="index != 0" vertical class="my-2" ></v-divider>
              <div class="d-flex flex-column align-center mx-auto py-4" >
                <span class="primary--text text-h6">{{ getPrepaidDiscountAmount(prepaid.percentage) * (duesMP ? duesMP.due.installments : selectedPrepaidDues.value) | $currency }}</span>
                <span>{{ $t('modules.admission.preregistration.create.paymentMethod.labels.prepaidDayRange', {dayFrom: prepaid.dayFrom, dayTo: prepaid.dayTo}) }}</span>
              </div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </template>
  </OutlinedCard>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import MercadoPagoInstallments from '@/components/Payments/MercadoPagoInstallments';
import SpecialCheck from '../Components/SpecialCheck';
import { required } from 'vuelidate/lib/validators'
import { $types, $suscriptions } from '../Services'

export default {
  name: 'PaymentMethod',
  components: {
    OutlinedCard,
    MercadoPagoInstallments,
    SpecialCheck
  },
  props: {
    show: { type: Boolean, default: false },
    total: Object,
    prepaids: Array,
    careerId: {type: Number, default: null},
    studentType: {type: Number, default: null}

  },
  data() {
    return {
      selectedPaymentMethod: null,
      creditCards: [],
      duesMP: null,
      selectedCreditCard: null,
      banks: [],
      selectedBank: null,
      duesAmount: 6,
      selectedPrepaidDues: null,
      subscriptionPaymentTypes: [],
      selectedSubscriptionPaymentType: null,
      subscriptions: [],
      selectedSubscriptionDues: null
    }
  },
  validations: {
    selectedPaymentMethod: { required },
    selectedCreditCard: { required },
    selectedBank: { required },
    selectedPrepaidDues: { required },
    selectedSubscriptionPaymentType: { required },
    selectedSubscriptionDues: { required }
  },
  computed: {
    prepaidDues() {
      const dues = []
      for (let due = 1; due <= this.duesAmount; due++) {
        const dueAmount = (this.total.cost + this.total.saving) / due
        dues.push({
          value: due,
          amount: dueAmount,
          text: this.$t('modules.admission.preregistration.create.paymentMethod.labels.installmentsOf', {installments: due, installmentAmount: this.$options.filters.$currency(dueAmount)})
        })
      }
      return dues
    },
    isSubscriptionOptionVisible() {
      return this.$isTeclab && this.subscriptions.length > 0
    },
    subscriptionDues() {
      const paymentBillingProductIds = [...new Set(this.total.prices.map(price => price.id))]
      return this.subscriptions.reduce((acc, subscription) => {
        let addSubscription = true
        const subscriptionPaymentBillingProductIds = subscription.suscriptionDetail.map(detail => detail.billingProduct.id)
        if (paymentBillingProductIds.length !== subscriptionPaymentBillingProductIds.length) addSubscription = false
        const uniqueValues = new Set([...paymentBillingProductIds, ...subscriptionPaymentBillingProductIds])
        for (const v of uniqueValues) {
          const aCount = paymentBillingProductIds.filter(e => e == v).length
          const bCount = subscriptionPaymentBillingProductIds.filter(e => e == v).length
          if (aCount != bCount) addSubscription = false
        }
        if (addSubscription) {
          const dueAmount = (this.total.cost + this.total.saving) / subscription.installments
          acc.push({
            value: subscription.installments,
            amount: dueAmount,
            text: this.$t('modules.admission.preregistration.create.paymentMethod.labels.installmentsOf', {installments: subscription.installments, installmentAmount: this.$options.filters.$currency(dueAmount)}),
            adminExpenses: (subscription.adminExpenses * (this.total.cost + this.total.saving)) / 100,
            type: subscription.suscriptionPaymentType
          })
        }
        return acc
      }, [])
    },
    filteredSubscriptionDues() {
      let filteredDues = this.subscriptionDues.filter(due => due.type.value == this.selectedSubscriptionPaymentType)
      if (this.total.prices.filter(price => price.name.toUpperCase().includes('ARANCEL')).length < 2) {
        filteredDues = filteredDues.length ? [filteredDues.shift()] : []
      }
      return filteredDues
    }
  },
  watch: {
    total: {
      handler() {
        if (!this.filteredSubscriptionDues.some(subscription => subscription.value == this.selectedSubscriptionDues?.value)) {
          this.selectedSubscriptionDues = null
          this.$emit('setDue', this.selectedSubscriptionDues)
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.$isTeclab) {
      this.getSubscriptionPaymentTypes()
      this.getSubscriptions()
    }
  },
  methods: {
    async getSubscriptionPaymentTypes() {
      try {
        const {data} = await $types.find(null, null, { params: { type: 'SUSCRIPTION_PAYMENT_TYPE' } })
        this.subscriptionPaymentTypes = data
      } catch (err) {
        this.subscriptionPaymentTypes = []
        throw err
      }
    },
    async getSubscriptions() {
      try {
        const {data} = await $suscriptions.find()
        this.subscriptions = data
      } catch (err) {
        this.subscriptions = []
      }
    },
    getPrepaidDiscountAmount(percentage) {
      return ((100 - percentage) * (this.duesMP ? this.duesMP.due.installment_amount : this.selectedPrepaidDues.amount)) / 100
    },
    setDueMP(objectMP) {
      this.duesMP = objectMP
      this.$emit('setDue', this.duesMP)
    },
    resetDue() {
      this.duesMP = null
      this.selectedPrepaidDues = null
      this.selectedSubscriptionDues = null
      this.$emit('resetDue')
    }
  }
};
</script>

<style lang="sass" scoped>

  .special-check-container
    height: 46px
    display: flex
    cursor: pointer
    flex-flow: row nowrap
    justify-content: space-between
    align-content: center
    border-radius: 5px
    padding: 10px 10px
    border: 1px solid #d3d3d3 !important

</style>
