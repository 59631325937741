<template>
  <div>
    <template v-if="$v.passCode.text.$anyError && (this.passCode.text.length == 6)">
      <v-alert
        text
        dense
        color="error"
        :value="!this.$v.passCode.text.valid && !this.$v.passCode.text.$pending "
      >{{ $t('modules.login.securityCode.password.form.validations.codeInvalid') }}</v-alert>
    </template>
    <v-otp-input
      v-model="$v.passCode.text.$model"
      :label="$t('modules.login.securityCode.password.form.labels.code')"
      outlined
      type="text"
      maxlength="6"
      :loading="$v.passCode.text.$pending"
      :error="$v.passCode.text.$anyError && (this.passCode.text.length == 6)"
      @blur="$v.passCode.text.$touch"
    ></v-otp-input>
  </div>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'
export default {
  name: 'SecurityCodePassForm',
  props: {
    passCode: Object,
    redirectPath: String
  },
  validations: {
    passCode: {
      text: {
        required,
        maxLength: maxLength(6),
        minLength: minLength(6),
        valid (value) {
          return new Promise((resolve) => {
            if (value.length !== 6) resolve(false);
            else {
              const {username} = this['login/getRecoveryData'];
              this.$auth.validateCode(username, value)
                .then(() => {
                  this.$store.dispatch('login/setValidationCode', value).then(() => {
                    this.$router.push(this.redirectPath).then(() => { resolve(true) });
                  })
                })
                .catch(() => {
                  resolve(false)
                })
            }
          })

        }
      }
    }
  },
  computed: {
    ...mapGetters(['login/getRecoveryData']),
    passCodeErrors () {
      const errors = [];
      if(!this.$v.passCode.text.$anyError && this.passCode.text.length < 6  ) return errors;
      if (!this.$v.passCode.text.required) errors.push(this.$t('modules.login.securityCode.password.form.validations.codeRequired'));
      if (!this.$v.passCode.text.minLength) errors.push(this.$t('modules.login.securityCode.password.form.validations.codeLength'));
      return errors;
    }
  }
};
</script>