import SyncCourses from '../Views/SyncCoursesView';
import SyncCoursesSections from '../Views/SyncCoursesSections';
// import SyncSubjects from '../Views/SyncSubjectsView';

const SyncCoursesRoute = [
    {
        path: '',
        component: SyncCourses,
        meta: { name: 'sync_courses', group: 'portaladministrativo.canvas', path: 'sync_courses', permission: 'READ' }
    },
    {
        path: 'courses_sections/:id',
        component: SyncCoursesSections,
        meta: { name: 'Courses_Sections', group: 'portaladministrativo.canvas', path: 'sync_courses', permission: 'READ' }
    },
    // {
    //     path: 'sync-subjects/:id',
    //     component: SyncSubjects,
    //     meta: { name: 'sync_subjects', group: 'portaladministrativo.canvas', path: 'sync_student', permission: 'READ' }
    // }
]

export default SyncCoursesRoute