
import CareersView from '../Views/CareersView'
import CareersBooks from '../Views/CareersBooks'
import Sheet from '../Views/Sheet'
import StudentSheet from '../Views/StudentSheet'

const GradebookRoute = [
    {
        path: '',
        component: CareersView,
        meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'gradebook', permission: 'READ' }
    },
    {
        path: 'career-books/:id',
        component: CareersBooks,
        meta : { name: 'CareersBooks', group: 'portaladministrativo.academic', path: 'gradebook', permission: 'READ'}
    },
    {
        path: ':id/sheet/:sheetNumber',
        component: Sheet,
        meta : { name: 'Sheet', group: 'portaladministrativo.academic', path: 'gradebook', permission: 'READ'}
    },
    {
        path: ':id/student-sheet/:book/invoice/:invoice',
        component: StudentSheet,
        meta : { name: 'StudentSheet', group: 'portaladministrativo.academic', path: 'gradebook', permission: 'READ'}
    },
]

export default GradebookRoute