<template>
  <div>
      <div class="d-flex" v-if="showTitle">
        <h4 class="headline font-weight-bold mb-4 px-0 pt-4 fileTitle-left">
          <v-icon class="paperclip">mdi-paperclip</v-icon>
          {{ title }}
        </h4>
      </div>
      <div
        :class="{'dashed':showDashed}"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        v-if="!file"
      >
        <v-file-input
          :name="`fields[${customId}][]`"
          @change="onChange"
          :accept="extension"
          class=""
          hide-input
          :id="customId"
          prepend-icon
          ref="file"
        ></v-file-input>
        <h2 class="text-center">
          Arrastra archivo para subirlos <br /><br />o
        </h2>
        <Button class="my-5" text="SELECCIONAR ARCHIVO" @clicked="onPickFile" :size="size">
        </Button>
        <h5 class="text-center">Tamaño maximo de archivo: {{ limitSize }}</h5>
      </div>
      <div class="file d-flex justify-center align-center" v-cloak v-else>
        <h2>{{ this.file.name.substr(0, 35) }}</h2>
        <button
          class="ml-2"
          type="button"
          @click="remove()"
          title="Remove file"
        >
          <v-icon>mdi-close</v-icon>
        </button>
      </div>
      <v-alert
        class="mb-0 mt-4"
        text
        type="error"
        v-if="required || limitSizeError || typeFileError"
      >{{ errorMessage }}
      </v-alert>
  </div>
</template>

<script>
import Button from '@/components/Button/Button';
export default {
  name: 'DropFiles',
  components: {
    Button
  },
  props: {
    title: { type: String },
    extension: { type: String },
    typeFile: { type: String },
    limitSizeBytes: { type: String },
    showTitle:{type:Boolean, default:true},
    showDashed:{type:Boolean, default:true},
    size: { type: String, default: 'lg' },
    // fileInput: File,
    fileInput: {type: [File, String]},
    required: { type: Boolean, default: false },
    clearValidation: { type: Boolean, default: false }
  },
  data() {
    return {
      customId: `assetsFieldHandle${(new Date()).getTime()}`,
      file: typeof this.fileInput === 'string' ? null : this.fileInput,
      limitSizeError: false,
      typeFileError: false
    }
  },
  computed: {
    limitSize() {
      if (this.limitSizeBytes.length > 6) {
        return `${this.limitSizeBytes.substr(0,1)} MB`
      } else {
        return `${this.limitSizeBytes.substr(0,3)} KB`
      }
    },
    errorMessage() {
      if (this.limitSizeError) {
        return 'Tamaño máximo excedido'
      }
      if (this.typeFileError) {
        return 'Tipo de archivo inválido'
      }
      if (this.required) {
        return 'Debe seleccionar un archivo'
      }
      return ''
    }
  },
  watch: {
    clearValidation(val) {
      if (val) {
        this.limitSizeError = false
        this.typeFileError = false
        this.$emit('update:clearValidation', false)
      }
    }
  },
  methods: {
    onPickFile() {
      const subir = document.querySelector(`#${this.customId}`);
      subir.click();
    },
    onChange(e) {
      if (this.limitSizeBytes >= e.size) {
        this.limitSizeError = false
        if (e.type.match(this.typeFile)) {
          this.typeFileError = false
          this.file = e;
          this.$emit('update:fileInput', this.file)
        } else {
          this.typeFileError = true
        }
      } else {
        this.limitSizeError = true
      }
    },
    remove() {
      this.file = null;
      this.$emit('remove')
    },
    //
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('color-hover')) {
        // event.currentTarget.classList.remove('grey');
        event.currentTarget.classList.add('color-hover');
      }
    },
    dragleave(event) {
      // event.currentTarget.classList.add('grey');
      event.currentTarget.classList.remove('color-hover');
    },
    drop(event) {
      event.preventDefault();
      const [file] = event.dataTransfer.files
      this.$refs.file.files = file
      this.onChange(file);
      event.currentTarget.classList.remove('color-hover');
    }
  }
};
</script>

<style lang="sass" scoped>
[v-cloak]
  display: none

.dashed
  width: 100%
  height: 200px
  border: #BCC4CB dashed 2px

.paperclip
  background-color: blue
  border-radius: 50%
  color: #fff
  padding: 7px
  transform: rotate(220deg)

.fileTitle-left
  position: relative
  left: -30px
  bottom: -5px

.color-hover
  background-color: rgba(61,72,56,.08)
</style>
