<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="filterChanged"></AsideBar>
      <v-container fluid class="scrollableContent pa-0">
        <v-container class="container-custom px-6">
          <Breadcrumbs :title="$t(`modules.examTitleNotes.table.breadcrumbs.${$isIPP ? 'titleIPP' : 'title'}`)"
            :description="$t(`modules.examTitleNotes.table.breadcrumbs.${$isIPP ? 'descriptionIPP' : 'description'}`)">
          </Breadcrumbs>
          <v-row v-if="$isIPP" no-gutters>
            <v-col sm="5">
              <v-select :disabled="loading" class="my-2" return-object outlined item-text="text" :items="regulations"
                v-model="regulation" :label="$t('modules.examTitleNotes.table.regulations.select')">
                ></v-select>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <SuperTable ref="superTable" :headers="headers" :data="users" :regulation="regulation"
                :pageCount="pagination.pagesAmount" :page="pagination.currentPage" :sortBy.sync="sort.sortBy"
                :sortDesc.sync="sort.sortDesc" :totalItems="totalItems"
                :permissions="{ update: canUpdate, create: canCreate }" @inputPagination="onInputPagination"
                @lengthPaginationChange="onLengthPaginationChange" @searchAction="searchAction"
                @createOpen="tmpDrawerCreateOpen" @updateOpen="tmpDrawerUpdateOpen">
              </SuperTable>
            </v-col>
          </v-row>

          <TemporaryRightDrawer v-if="canCreate" @tmpDrawerOpen="tmpDrawerCreateOpen" :open="tmpDrawerCreate.open"
            :title="tmpDrawerCreate.title" :description="tmpDrawerCreate.description">
            <ExamTitlesNotesCreate :regulation="regulation" :isOpen="tmpDrawerCreate.open"
              @closeDrawer="onCloseCreateDrawer" :selectedExam="selectedExam"></ExamTitlesNotesCreate>
          </TemporaryRightDrawer>

          <TemporaryRightDrawer v-if="canUpdate" @tmpDrawerOpen="tmpDrawerUpdateOpen" :open="tmpDrawerUpdate.open"
            :title="tmpDrawerUpdate.title" :description="tmpDrawerUpdate.description">
            <ExamTitlesNotesUpdate :regulation="regulation" :isOpen="tmpDrawerUpdate.open"
              @closeDrawer="onCloseUpdateDrawer" :selectedExam="selectedExam"></ExamTitlesNotesUpdate>
          </TemporaryRightDrawer>

        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable';
import ExamTitlesNotesCreate from '../Views/ExamTitlesNotesCreate';
import ExamTitlesNotesUpdate from '../Views/ExamTitlesNotesUpdate';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import AsideBar from '@/components/AsideBar/AsideBar';
import { $examsTitle, $titulations } from '../Services';

const ASIDEBAR = {
  NOTES: {
    ID: 0
  },
  FROM_DATE: {
    ID: 1
  },
  TO_DATE: {
    ID: 2
  }
}

const FILTERS = {
  NO_NOTE: {
    ID: 0
  },
  WITH_NOTE: {
    ID: 1
  }
}

const REGULATIONS = {
  PREV: 'PREV',
  R23: 'R23'
}

export default {
  components: {
    SuperTable,
    Breadcrumbs,
    TemporaryRightDrawer,
    ExamTitlesNotesCreate,
    ExamTitlesNotesUpdate,
    AsideBar
  },
  data() {
    return {
      search: '',
      users: [],
      loading: false,
      regulation: { id: null, value: 'PREV', text: 'Anteriores a R23' },
      regulations: [
        { id: 433, value: 'R23', text: 'R23' },
        { id: null, value: 'PREV', text: 'Anteriores a R23' }
      ],
      totalItems: 0,
      selectedExam: {},
      tmpDrawerCreate: {
        open: false,
        title: '',
        description: ''
      },
      tmpDrawerUpdate: {
        open: false,
        title: '',
        description: ''
      },
      pagination: {
        limit: 20,
        page: 1,
        currentPage: 1,
        pagesAmount: 1
      },
      headers: [
        {
          text: this.$t('modules.examTitleNotes.table.headers.name'),
          value: 'name'
        }, {
          text: this.$t('modules.examTitleNotes.table.headers.lastname'),
          value: 'lastname',
        }, {
          text: this.$t('modules.examTitleNotes.table.headers.identification'),
          value: 'identification',
          sortable: false
        }, {
          text: this.$t('modules.examTitleNotes.table.headers.career_id'),
          value: 'careerCode',
          sortable: false
        }, {
          text: this.$t('modules.examTitleNotes.table.headers.career'),
          value: 'carrear',
          sortable: false
        }, {
          text: '',
          value: 'date',
          sortable: false,
          align: 'center',
        }, {
          text: this.$t('modules.examTitleNotes.table.headers.act'),
          value: 'act',
          align: 'center',
          sortable: false
        }, {
          text: '',
          value: 'grade',
          align: 'center',
          sortable: false
        }, {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      notesItems: {
        id: 0,
        name: this.$t('modules.examTitleNotes.table.asidebar.notas'),
        filters: [
          {
            id: 0,
            name: this.$t('modules.examTitleNotes.table.asidebar.alumnos_sin_nota'),
            type: 'check',
            value: true,
            dataFiltered: 0
          }, {
            id: 1,
            name: this.$t('modules.examTitleNotes.table.asidebar.alumnos_con_nota'),
            type: 'check',
            value: true,
            dataFiltered: 0
          },
        ]
      },
      asidebar: {
        title: 'Refinar búsqueda',
        items: [
          {
            id: 1,
            name: this.$t('modules.examTitleNotes.table.asidebar.desde'),
            filters: [
              {
                type: 'date',
                name: this.$t('modules.examTitleNotes.table.asidebar.desde'),
                value: null,
                isCalendarOpen: false,
              },
            ]
          }, {
            id: 2,
            name: this.$t('modules.examTitleNotes.table.asidebar.hasta'),
            filters: [
              {
                type: 'date',
                name: this.$t('modules.examTitleNotes.table.asidebar.hasta'),
                value: null,
                isCalendarOpen: false,
              },
            ]
          },
        ]
      },
      sort: {
        sortBy: 'name',
        sortDesc: false,
      },
    }
  },
  methods: {
    tmpDrawerCreateOpen(item = null) {
      if (item) this.selectedExam = item;
      this.tmpDrawerCreate.open = !this.tmpDrawerCreate.open;
      if (!this.tmpDrawerCreate.open) this.fetchData();
    },
    onCloseCreateDrawer() {
      this.tmpDrawerCreate.open = false;
      this.fetchData()
    },
    tmpDrawerUpdateOpen(item = null) {
      if (item) this.selectedExam = item
      this.tmpDrawerUpdate.open = !this.tmpDrawerUpdate.open;
      if (!this.tmpDrawerUpdate.open) this.fetchData();
    },
    onCloseUpdateDrawer() {
      this.tmpDrawerUpdate.open = false;
      this.fetchData()
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.pagination.currentPage = event;
      this.fetchData();
    },
    clearPagination() {
      this.pagination.currentPage = 1
      this.pagination.page = 1
      this.pagination.pagesAmount = 1
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        currentPage: 1,
        pagesAmount: 1
      };
      this.fetchData();
    },
    searchAction(data) {
      this.search = data;
      this.fetchData();
    },
    filterChanged(parent, filter, value) {
      this.asidebar.items[parent].filters[filter].value = value;
      this.clearPagination()
      this.fetchData();
    },
    async fetchData() {
      this.loading = true
      const params = {
        page: this.pagination.page - 1,
        length: this.pagination.limit
      }
      const score = this.asidebar.items
        .find((item) => item.id === ASIDEBAR.NOTES.ID);

      const from = this.asidebar.items
        .find((item) => item.id === ASIDEBAR.FROM_DATE.ID);

      const to = this.asidebar.items
        .find((item) =>item.id === ASIDEBAR.TO_DATE.ID);
      try {
        let reqBody = {}
        let users = {}
        if (this.regulation.value === REGULATIONS.PREV) {
          reqBody = {
            from: from.filters[0].value ? from.filters[0].value : null,
            to: to?.filters[0]?.value ? to.filters[0].value : null,
            noScore: score.filters[0].value,
            score: score.filters[1].value,
            search: this.search
          }
          users = await $examsTitle.filterExams(reqBody, { params });
        } else {
          reqBody = {
            from: from.filters[0].value ? from.filters[0].value : null,
            to: to?.filters[0]?.value ? to?.filters[0].value : null,
            noScore: false,
            score: false,
            search: this.search,
          }
          users = await $titulations.filterExams(reqBody, { params });
        }

        this.totalItems = users.data.totalElements;
        this.pagination.pagesAmount = users.data.totalPages;

        this.asidebar.items
          .forEach(item => {
            if (item.id !== ASIDEBAR.NOTES.ID) return
            item.filters
              .forEach(filter => filter.dataFiltered = 0)
          })

        this.users = users.data.content.map((item, index) => {
          this.examCounter(item)
          if (this.regulation.value === REGULATIONS.PREV) {
            return {
              table_id: item.exam ? item.exam.id : index,
              id: item.idStudentSubject,
              idStudentCareer: item.idStudentCareer,
              name: item.studentName,
              lastname: item.studentLastname,
              careerCode: item.careerCode,
              identification: item.studentIdentification,
              carrear: item.careerName,
              date: item.exam ? item.exam.date : '-',
              act: item.exam?.act,
              grade: item.exam ? item.exam.score : null,
              id_exam: item.exam ? item.exam.id : null,
              description: item.exam ? item.exam.description : null,
            }
          } else {
            return {
              table_id: null,
              id: item.idStudentSubject,
              idStudentCareer: item.studentCareerId,
              name: item.name,
              lastname: item.lastName,
              careerCode: item.careerCode,
              identification: item.rut,
              carrear: item.description,
              date: item.date ?? '-',
              act: item.act ?? '-',
              grade: null,
              id_exam: null,
              description: null,
              actGraduationId: item.actGraduationId,
              isIntermediate: item.isIntermediate
            }
          }
        });
      } catch (error) {
        this.users = [];
        this.pagination.pagesAmount = 0
      } finally {
        this.loading = false
      }
    },
    changeHeaders(keyValue, text) {
      this.headers
        .forEach(header => {
          if (header.value != keyValue) return
          header.text = text
        })
    },
    examCounter(item) {
      if (item.exam && item.exam.score) {
        this.asidebar.items
          .forEach(item => {
            if (item.id !== ASIDEBAR.NOTES.ID) return
            item.filters
              .forEach(filter => {
                if (filter.id === FILTERS.WITH_NOTE.ID) filter.dataFiltered++
              })
          })
      }
      else {
        this.asidebar.items
          .forEach(item => {
            if (item.id !== ASIDEBAR.NOTES.ID) return
            item.filters
              .forEach(filter => {
                if (filter.id === FILTERS.NO_NOTE.ID) filter.dataFiltered++
              })
          })
      }
    }
  },
  mounted() {
    const title = this.$isIPP ? 'titleIPP' : 'title'
    const description = this.$isIPP ? 'descriptionIPP' : 'description'

    this.tmpDrawerCreate.title = this.$t(`modules.examTitleNotes.create.breadcrumbs.${title}`)
    this.tmpDrawerCreate.description = this.$t(`modules.examTitleNotes.create.breadcrumbs.${description}`)
    this.tmpDrawerUpdate.title = this.$t(`modules.examTitleNotes.update.breadcrumbs.${title}`)
    this.tmpDrawerUpdate.description = this.$t(`modules.examTitleNotes.update.breadcrumbs.${description}`)
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.students.exam_title_notes.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.students.exam_title_notes.UPDATE
    },
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    },
    regulation: {
      handler({ value }) {
        const item = this.asidebar.items
          .some((item) => item.id === ASIDEBAR.NOTES.ID)

        if (value === REGULATIONS.PREV && !item) {
          this.asidebar.items = [this.notesItems, ...this.asidebar.items]

          this.headers.forEach(header => {
            if (header.value !== 'date') return
            header.text = this.$t('modules.examTitleNotes.table.headers.date')
          })
        } else {
          const items = this.asidebar.items
            .filter((item) => item.id !== this.notesItems.id)
          this.asidebar.items = items

          this.headers.forEach(header => {
            if (header.value !== 'date') return
            header.text = this.$t('modules.examTitleNotes.table.headers.dateIPP')
          })
        }
        this.clearPagination()
        this.fetchData();
      },
      immediate: true
    },
    asidebar: {
      handler({ items }) {
        const score = items
          .find(item => item.id === ASIDEBAR.NOTES.ID)

        if (!score) {
          this.changeHeaders('grade', '')
          return
        }

        score.filters
          .forEach(filter => {
            if (filter.id !== FILTERS.WITH_NOTE.ID) return

            if (!filter.value) {
              this.changeHeaders('grade', '')
              return
            }

            this.changeHeaders('grade', this.$t('modules.examTitleNotes.table.headers.grade'))
          })
      },
      deep: true,
    }
  }
}
</script>
