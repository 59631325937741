<template>
  <OutlinedCard
    :title="$t('modules.admission.preregistration.create.leadInfo.title')"
    class="mb-6"
  >
    <v-row>
      <v-col class="col-12">
        <v-text-field
          :error-messages="($v.leadEmail.$dirty && $v.leadEmail.$invalid) ?
                  ((!$v.leadEmail.required) ? $t('modules.admission.preregistration.validations.leadEmailRequired') :
                  $t('modules.admission.preregistration.validations.leadEmailFormat')) :
                  ''"
          :label="$t('modules.admission.preregistration.create.leadInfo.labels.leadEmail')"
          outlined
          v-model="$v.leadEmail.$model"
          @blur="$v.leadEmail.$touch()"
          @keydown.enter.prevent="onEmailSearchClick"
        >
          <template v-slot:append>
            <div class="mt-n2">
              <v-progress-circular
                v-if="leadEmailLoading"
                size="24"
                indeterminate
              ></v-progress-circular>
              <v-icon
                v-else
                @click="onEmailSearchClick"
              >
                mdi-magnify
              </v-icon>
            </div>
          </template>
        </v-text-field>
        <v-alert
          v-if="!hubspotId"
          class="text-body-2 mt-3 mb-0"
          text
          color="#94A21E"
        >
          {{ $t('modules.admission.preregistration.validations.warnings.leadEmailNotFound') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <v-text-field
          :error-messages="($v.name.$dirty && $v.name.$invalid) ?
                  ((!$v.name.required) ? $t('modules.admission.preregistration.validations.nameRequired') :
                  (!$v.name.alphabeticWithSpaces) ? $t('modules.admission.preregistration.validations.nameAlphabeticWithSpaces') :
                  $t('modules.admission.preregistration.validations.nameMaxLength')) :
                  ''"
          @blur="$v.name.$touch()"
          @keypress="$validateAlphanumeric($event, $v.name.$model,60, true, true)"
          v-model="$v.name.$model"
          :label="$t('modules.admission.preregistration.create.leadInfo.labels.name')"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col class="col-6">
        <TelephoneInput
          :value.sync="$v.telephone.$model"
          :placeholder="$t('modules.admission.preregistration.create.leadInfo.labels.telephone')"
          :required="$v.telephone.$dirty && $v.telephone.$invalid"
          ref="telephone"
          @blur="$v.telephone.$touch()"
          @validate="isValidPhone"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="!$isTeclab ? 6 : 12">
        <v-autocomplete
          :error="$v.selectedCareer.$error"
          :error-messages="$v.selectedCareer.$error ? $t('modules.admission.preregistration.validations.careerRequired') : ''"
          :label="$t('modules.admission.preregistration.create.leadInfo.labels.career')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.career')"
          outlined
          :items="careers"
          item-text="description"
          v-model="$v.selectedCareer.$model"
          return-object
          @change="$emit('nextStep', $v.selectedCareer.$model, $v.city.$model, hubspotId)"
          @blur="$v.selectedCareer.$touch()"
        />
      </v-col>
      <v-col v-if="!$isTeclab" class="col-6">
        <v-select
          :error="$v.selectedStudyPlan.$error"
          :error-messages="$v.selectedStudyPlan.$error ? $t('modules.admission.preregistration.validations.studyPlanRequired') : ''"
          :label="$t('modules.admission.preregistration.create.leadInfo.labels.studyPlan')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.studyPlan')"
          outlined
          :items="studyPlans"
          v-model="$v.selectedStudyPlan.$model"
          return-object
          @change="$emit('studyPlanChange', $v.selectedStudyPlan.$model)"
          @blur="$v.selectedStudyPlan.$touch()"
        />
      </v-col>
    </v-row>
    <v-row v-if="validationCity">
      <v-col class="col-12">
        <AutocompleteInput
          :value.sync="$v.city.$model"
          :required="$v.city.$invalid"
          :error-messages="$t('modules.admission.preregistration.validations.cityRequired')"
          :label="$t('modules.admission.preregistration.create.leadInfo.labels.city')"
          disable-buttons
          ref="leadCity"
        ></AutocompleteInput>
      </v-col>
    </v-row>
    <div class="d-flex justify-end pa-3">
      <Button
        v-if="!hubspotId"
        :loading="leadButton.loading"
        :success="leadButton.success"
        :error="leadButton.error"
        :text="$t('actions.save')"
        :successText="$t('actions.saved')"
        :errorText="$t('actions.error')"
        :disabled="!canCreate"
        @end="resetButtonValues"
        @clicked="createLead"
      ></Button>
    </div>
  </OutlinedCard>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
import Button from '@/components/Button/Button';
import { required, requiredIf, email, maxLength, helpers } from 'vuelidate/lib/validators';
import { $contact, $cities } from '#/portaladministrativo/StudentPreRegistration/services';

export default {
  name: 'LeadInfo',
  components: {
    OutlinedCard,
    TelephoneInput,
    AutocompleteInput,
    Button
  },
  props: {
    careers: { type: Array, default: () =>[] },
    canCreate: { type: Boolean, default: false }
  },
  data() {
    return {
      leadEmail: null,
      validationCity: true,
      leadEmailLoading: false,
      hubspotId: true,
      selectedCareer: null,
      selectedStudyPlan: null,
      name: null,
      city: null,
      telephone: '',
      telephoneValid: true,
      stepOnce: true,
      leadButton: {
        loading: false,
        success: false,
        error: false
      },
      baseValidation: {
        leadEmail: {
          required,
          email
        },
        name: {
          required: requiredIf('isHubspotRequired'),
          maxLength: maxLength(60),
          alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
        },
        selectedCareer: {
          required: requiredIf('isHubspotRequired')
        },
        city: {
          required: requiredIf('isHubspotRequired')
        },
        telephone: {
          required: requiredIf('isHubspotRequired'),
          valid() {
            return this.telephoneValid
          }
        }
      }
    }
  },
  validations() {
    if (this.$isTeclab) {
      return {
        ...this.baseValidation
      }
    } else {
      return {
        ...this.baseValidation,
        selectedStudyPlan: {
          required: requiredIf('isHubspotRequired')
        }
      }
    }
  },
  computed: {
    isHubspotRequired() {
      return !this.hubspotId
    },
    studyPlans() {
      return this.selectedCareer
        ? this.selectedCareer.studyPlans.map(plan => (
          {
            text: `(${plan.studyPlan.description}) ${plan.degreesFinal.description}`,
            value: plan.studyPlan.id,
            hubspotReference: plan.hubspotReference
          }
        ))
        : [];
    }
  },
  watch: {
    /*$v: {
      handler(value) {
        if (this.initialValidation && this.hubspotId && !value.$invalid) {
          this.$emit('nextStep', this.selectedCareer, this.city, this.hubspotId)
        } else {
          this.$emit('previousStep')
        }
      },
      deep: true
    },*/
    name(value) {
      this.$emit('nameChange', value)
    },
    leadEmail(value) {
      this.$emit('emailChange', value)
    },
    telephone(value) {
      this.$emit('telephoneChange', value)
    },
    city(value) {
      this.$emit('nextStep', this.selectedCareer, value, this.hubspotId)
    }
  },
  methods: {
    isValidPhone(phoneObject) {
      this.telephoneValid = phoneObject.isValid
      this.$refs.telephone.handleInput(this.telephone, phoneObject)
    },
    async onEmailSearchClick() {
      this.hubspotId = true
      this.clearFields()
      this.$v.leadEmail.$touch()
      if (!this.leadEmailLoading && !this.$v.leadEmail.$anyError) {
        this.leadEmailLoading = true;
        try {
          const {data} = await $contact.find(null, null, {params: {email: this.leadEmail}})
          this.hubspotId = data.id || null
          data?.token ? this.$emit('tokenChanged', data.token) : this.$emit('tokenChanged', null)
          this.name = data.firstName.trim()
          this.telephone = data.phone.replace(/ /g, '')
          if (this.telephone.charAt(0) != '+') {
            this.telephone = '+'.concat(this.telephone)
          }

          this.$nextTick(function() {
            this.$refs.telephone.$refs.v_tel_input.onInput()
          })

          if (data.localidadId) {
            try {
              const {data: city} = await $cities.findById(data.localidadId)
              this.city = {
                cityState: city.name,
                idCity: data.localidadId
              }
            } catch {
              this.city = null
            }
          }

          const career = this.careers.find(career => career.id == data.careerId)
          this.selectedCareer = career || null
        } catch (err) {
          this.hubspotId = null
          if(this.validationCity){
          this.$store.dispatch('commons/openPopUp', {
             title: this.$t('modules.admission.preregistration.create.warning'),
             actionPrimary: {
               text: this.$t('actions.close'), callback() {}
             },
             icon: { color: 'warning', name: 'mdi-alert' },
             color: 'primary',
           });
          }
          this.clearFields()
        } finally {
          this.$emit('nextStep', this.selectedCareer, this.city, this.hubspotId)
          this.leadEmailLoading = false
        }
      }
    },
    async createLead() {
      try {
        this.leadButton.loading = true;
        if (this.$v.$invalid) {
          await this.$v.$touch();
          if(this.validationCity){
            this.$refs.leadCity.triggerOuterValidation()
          }
          this.$refs.telephone.handleBlur()
          this.leadButton.error = true;
          this.leadButton.loading = false;
        } else {
          const newLead = {
            email: this.leadEmail,
            firstname: this.name,
            carreraId: this.selectedCareer.id,
            localidadId: this.city.idCity || this.city.idRegion,
            telefono: this.telephone
          }
          const {data} = await $contact.create(newLead, {}, 'create')
          this.hubspotId = data.vid
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.admission.preregistration.create.successMessage.lead'),
            /*content: this.total.prices.map(preregistration => ({value: preregistration.name})),*/
            actionPrimary: {
              text: this.$t('actions.close'), callback() {}
            },
            icon: { color: 'success', name: 'mdi-check' },
            color: 'primary',
          });
          this.leadButton.success = true;
        }
      } catch (err) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.create.errorMessage.lead'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {}
          },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        this.leadButton.error = true;
        throw err
      } finally {
        this.leadButton.loading = false;
        this.$emit('nextStep', this.selectedCareer, this.city, this.hubspotId)
      }
    },
    clearFields() {
      this.$v.$reset()
      if(this.validationCity){
        this.$refs.leadCity.$v.$reset()
      }
      this.$refs.telephone.cancelInput()
      this.selectedCareer = null
      this.selectedStudyPlan = null
      this.name = null
      this.city = null
      this.telephone = ''
    },
    resetButtonValues() {
      this.leadButton.loading = false;
      this.leadButton.success = false;
      this.leadButton.error = false;
    },
  }

}
</script>
