<template>
  <div fluid @click="$emit('paymentSelected', payment)">
    <v-row class="payment-single">
      <v-col class="d-flex align-center" cols="2" sm="1">
        <img
            class="payment-logo"
            v-if="payment?.thumbnail"
            :alt="payment?.payment_type_id"
            :src="payment.thumbnail"
        >
        <v-icon v-else>{{ payment.icon || 'mdi-cash-usd-outline' }}</v-icon>
      </v-col>
      <v-col v-if= "payment.id === 'CHECK'" class="d-flex align-center" cols="2" sm="9">
        <img width="auto" height="40" :src="require('@/assets/mercadopagologo.png')" />
      </v-col>
      <v-col v-if= "payment.id === 'GETNET'" class="d-flex align-center" cols="2" sm="9">
        <img width="auto" height="25"  :src="require('@/assets/santanderlogo.png')" />
      </v-col>
      <v-col v-if= "payment.id != 'CHECK' && payment.id != 'GETNET'" class="description d-flex flex-column justify-center" cols="8" sm="9">
        <span>{{ payment.name }}</span>
        <span class="subdescription" :class="{ 'green--text': payment.color == 'green' }" v-if="payment.name === 'PayCash'">{{ $t('modules.portalalumno.payments.paymentsStudents.view.payCash.places') }}</span>
      </v-col>
      <v-col class="arrow-container d-flex align-center justify-end text-right" cols="2">
        <v-icon>mdi-chevron-right</v-icon>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    payment: Object
  },
};
</script>

<style scoped  lang="sass">
  .payment-logo
    max-width: 70px
  .description
    span
      font-family: 'DM Sans'
      font-size: 16px
      line-height: 1.2
      font-weight: bold
      letter-spacing: -0.48px
      color: #212121
      margin-bottom: 0
      margin-left: 20px
      
      &.subdescription
        -webkit-text-stroke: 1px rgba(0, 0, 0, 0)
        font-family: 'DM Sans'
        font-size: 12px
        line-height: 1.2
        font-weight: bold
        margin-bottom: 0
        color: #666666

  .arrow-container
    font-family: 'DM Sans'
    font-size: 20px
    font-weight: bold
    letter-spacing: -0.6px
    color: #212121

  .payment-single
    height: 90px
    border-radius: 5px
    box-shadow: 0 2px 2px 0 rgba(1, 33, 105, 0)
    border: solid 1px #d9d9d9
    background-color: white
    margin-bottom: 10px !important

    &:hover
      box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)
      border: solid 1px #0036b1
      cursor: pointer

</style>