import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('core');

export class Endpoints extends Api {
  constructor(http, config = {}) {
    super(http, url, 'endpoints', config);
  }

  async dobleFactor(parameters, config = {}) {
    config = { ...this._config, ...config }
    const uri = this._uriConstructor(null, 'dobleFactor', null)
    return this._http.get(uri, parameters, config)
  }
}
