import api from '@/api';

const { $student, $canvas, $academic } = api;

export const { $students, $canvasSyncStudent } = $student

export const { $terms, $subjects, $course} = $academic

export const { $canvasStudent, $canva, $courses, $sectionsCanvas } = $canvas

