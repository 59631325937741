<template>
  <v-container fluid class="d-flex pa-0 white fullHeightViewport" :class="{'flex-column': $isPortalAlumno}">
    <AppBar v-if="$isPortalAlumno" :drawerOpen="drawerOpen" @toggle-nav="drawerOpen = !drawerOpen"></AppBar>
    <NavigationDrawer v-else :drawerOpen="drawerOpen"></NavigationDrawer>

    <v-container fluid class="d-flex pa-0 ma-0" :class="{'flex-column': !$isPortalAlumno}" style="height: 100%;">
      <MiniNavigationDrawer v-if="$isPortalAlumno" :drawerOpen="drawerOpen" @close="drawerOpen = $event"></MiniNavigationDrawer>
      <AppBar v-else :drawerOpen="drawerOpen" @toggle-nav="drawerOpen = !drawerOpen"></AppBar>
      <v-main class="scrollableContent" :style="$isPortalAlumno && !$isMobile ? 'max-width: calc(100% - 110px) !important' : ''">
        <router-view v-if="initDataLoaded"></router-view>
      </v-main>
    </v-container>
    <AlertPopup></AlertPopup>
    <ChangePasswordPopUp></ChangePasswordPopUp>
    <ValidateEmailPhonePopUp></ValidateEmailPhonePopUp>
  </v-container>
</template>

<script>
import NavigationDrawer from '@/components/NavigationDrawer/NavigationDrawer';
import MiniNavigationDrawer from '@/components/NavigationDrawer/MiniNavigationDrawer';
import AppBar from '@/components/AppBar/AppBar';
import AlertPopup from '@/components/AlertPopup/AlertPopup';
import ChangePasswordPopUp from '@/components/ChangePasswordPopUp/ChangePasswordPopUp';
import ValidateEmailPhonePopUp from '@/components/ValidateEmailPhonePopUp/ValidateEmailPhonePopUp.vue';
import AuthService from '@/plugins/auth.service';
import { mapGetters } from 'vuex'

export default {
  components: {
    AlertPopup,
    NavigationDrawer,
    MiniNavigationDrawer,
    AppBar,
    ChangePasswordPopUp,
    ValidateEmailPhonePopUp
  },
  data() {
    return {
      initDataLoaded: false,
      drawerOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      academicRegister: 'commons/getStudentActualRegister',
      studentUserData: 'commons/getStudentUserData',
      studentCareer: 'commons/getStudentCareer',
    })
  },
  async mounted() {
    await this.$store.dispatch('commons/initData');

    this.initDataLoaded = true;
    this.checkToken();
    if (this.$isPortalAlumno && !this.$isGestor) this.initBot()
    if ((this.$isPortalAlumno && this.$isGestor) || !this.$isPortalAlumno)  this.closeBot()
  },
  methods: {
    checkToken() {
      if (AuthService.checkTokenExpiredWithoutRefresh()) this.$router.replace('/logout');
      setTimeout(this.checkToken, 1000 * 60 * 3);
    },
    initBot() {
      if (window.$aivo && window.$aivo.launcher) {
        window.$aivo.launcher.show();
        this.setBotData();

        window.$aivo.chat.onCloseWindow(() => {
          this.setBotData();
        });
      }
    },
    closeBot() {
      if (window.$aivo || window.$aivo.launcher) {
        window.$aivo.launcher.remove()
      }
    },
    setBotData() {
      if (window.$aivo && window.$aivo.user) {
        window.$aivo.user.new();
        window.$aivo.user.set('dni', this.studentUserData.username);
        window.$aivo.user.set('contact_name', `${this.studentUserData.lastname} ${this.studentUserData.name}`);
        window.$aivo.user.set('legajo', this.academicRegister);
        window.$aivo.user.set('carrera', this.studentCareer.description);
        window.$aivo.user.set('mail', this.studentUserData.email);
        window.$aivo.user.set('name', `${this.studentUserData.lastname} ${this.studentUserData.name}`);
      }
    },
  },
};
</script>
