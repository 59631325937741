<template>
    <div class="banner">
        <img class="banner__image" :src="banner.image">
        <div class="banner__content">
            <div class="banner__content__text">
                <div class="banner__content__text__title" v-html="banner.text.title"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'OnboardingCentralTerceryBanner',
        props: {
            banner: Object
        },
        computed: {
          ...mapGetters({
            canvasId: 'commons/getCanvasId',
            studentData: 'commons/getStudentData',
          }),
        },
        methods: {
            buttonClick(button) {
                this.$trackEvent(button.analytics.category, button.analytics.action, button.analytics.label)
                this.$redirectToCanvas(this.canvasId, this.studentData.student.user.name)
            }
        }
    };
</script>

<style lang="sass" scoped>
    .banner
        min-height: 45px
        width: 100%
        background-color: #E87800
        border: 1px solid #D4D4D4
        border-radius: 5px
        position: relative
        display: flex
        margin-top: 15px
        &__image
            position: absolute
            right: 0
            bottom: 0
            height: 100%
            max-height: 65px
        &__content
            display: flex
            width: 100%
            align-items: center
            justify-content: space-between
            &__text
                margin-left: 40px
                &__title
                    font-size: medium
                    font-weight: bold
                    color: #DFEEF1

</style>