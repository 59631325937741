<template>
    <v-container fluid class="superTable pa-0">
        <v-row no-gutters>
            <v-col sm="4" class="mt-4 mb-2">
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="$t('actions.search')"
                    outlined
                    clearable
                    class="superTable__search"
                    @keyup.enter="searchAction"
                    @keyup.esc="search = ''"
                    @click:clear="clearAction"
                ></v-text-field>
            </v-col>
            <v-col sm="4" class="mt-4 mb-2">
                <Button
                    class="ml-4"
                    @clicked="searchAction"
                    outlined
                    depressed
                    :text="$t('actions.search')"
                ></Button>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    :headers="headers"
                    :items="data"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    :totalItems="totalItems"
                    hide-default-footer
                    item-key="id"
                    class="superTable__table examExceptionTable"
                    style="table-layout: fixed"
                    @click:row="clickRow"
                >
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
        <v-row no-gutters align="center">
            <v-col sm="3">
                <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
                <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageAvailable"
                    :placeholder="itemsPerPage.toString()"
                    @change="$emit('items-per-page-change', itemsPerPage)"
                    outlined
                    class="superTable__paginationSelect d-inline-block ml-4"
                ></v-select>
            </v-col>
            <v-col sm="6" class="text-center">
                <v-pagination
                    :value="page"
                    :length="pageCount"
                    @next="$emit('next-pagination', page)"
                    @previous="$emit('previous-pagination', page)"
                    @input="$emit('input-pagination', $event)"
                    class="superTable__pagination"
                ></v-pagination>
            </v-col>
            <v-col sm="3" align="right">
                <v-text-field
                    v-model.number="goToPage"
                    max="pageCount"
                    outlined
                    @blur="changePage"
                    class="superTable__paginationInput d-inline-block mr-4"
                ></v-text-field>
                <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="sass" scoped>
    .v-expansion-panels
        .v-expansion-panel-content
            padding-left: 10px
            padding-bottom: 0px
            padding-right: 16px
</style>

<script>
    import Button from '@/components/Button/Button';

    export default {
        name: 'SuperTable',
        components: {
            Button,
        },
        props: {
            data: Array,
            headers: Array,
            permissions: {update: false, delete: false},
            pageCount: { type: Number, default: 1 },
            page: { type: Number, default: 1 },
            totalItems: { type: Number, default: 0 },
        },
        data () {
            return {
                search: '',
                goToPage: 1,
                itemsPerPage: 20,
                itemsPerPageAvailable: [20, 50, 100, 200],
            }
        },
        methods: {
            clearAction() {
              this.search = ''
              this.searchAction()
            },
            editItem (id) {
                this.$emit('update', id);
            },
            deleteItem (id) {
                this.$emit('deleteUser', id);
            },
            clickRow(row){
                this.$router.push({path: `exam-exception/create/${row.legajo}`, params: { student: `${row.name} ${row.lastname}`, career: row.career } })
            },
            searchAction(){
                this.$emit('searchAction',this.search ? this.search.toLowerCase().trim() : this.search);
            },
            changePage() {
                if (this.goToPage > this.pageCount) this.goToPage = this.page;
                else this.$emit('input-pagination', this.goToPage)
            }
        },
        watch: {
            search(newSearch, oldSearch) {
                if (newSearch === '' & newSearch !== oldSearch) {
                    this.searchAction();
                }
            },
            page(val) {
                if (val > this.pageCount) this.page = this.pageCount
                if (val <= 1) this.page = 1
                if (this.page !== this.goToPage) this.goToPage = this.page;
            },
        },
        computed: {
            itemsCount () {
                const counter = {
                    totalItems: this.totalItems,
                    startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
                    endItem: this.page * this.itemsPerPage
                };
                if (counter.endItem > this.totalItems) {
                    counter.endItem = this.totalItems
                }
                return counter
            }
        },
    };
</script>

<style lang="sass">
    .examExceptionTable table tbody tr
        cursor: pointer
</style>
