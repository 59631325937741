
const repaymentTranslations = {
    es: {
        enabled: 'Habilitado',
        disabled: 'Deshabilitado',
        inputErrors: {
            required: 'Campo obligatorio',
            minLengthOne: 'Selecciona al menos uno',
            alreadyExists: 'Ya existe un producto con este código'
        },
        table: {
            breadcrumbs: {
                title: 'Configuración de repactación',
                description: 'Administración de configuración de repactación'
            },
            headers: {
                basicPay: 'Porcentaje obligatorio de pago',
                description: 'Descripción',
                status: 'Estado',
                discountPercentage: 'Descuento',
                installments: 'Cuotas'
            },
            bulk_delete: 'Eliminar repactaciónes',
        },
        validation_errors: {
            billing_props: 'Ingresa un valor',
            billing_props_required: 'Selecciona al menos una habilitación',
        },
        sections: {
            infoTitle: 'Datos',
            enabledTitle: 'Habilitaciones',
            relatedProduct: 'Repactacíones relacionadas'
        },
        create: {
            alert_message: {
                title: 'Estás creando una repactación deshabilitado',
                actions: {
                  primary_text: 'Aceptar',
                  secondary_text: 'Cancelar'
                }
            },
            breadcrumbs: {
                title: 'Nueva repactación',
                description: 'Crea una nueva repactación'
            },
            title: 'Datos',
            subtitle: 'Define una descripción de la repactación',
            labels: {
                basicPay: 'Porcentaje obligatorio de pago',
                description: 'Descripción',
                status: 'Estado',
                discountPercentage: 'Descuento',
                installments: 'Cuotas'
            },
            success_message: {
                title: 'Producto creado correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otro',
                },
            },
            error_message: {
                title: 'Se produjo un error al crear la repactación',
                actions: {
                    primary_text: 'Cerrar',
                    go_back_text: 'Volver'
                }
            },
        },
        update: {
            breadcrumbs: {
                title: 'Editar repactación',
                description: 'Modifica la repactación seleccionada'
            },
            labels: {
                basicPay: 'Porcentaje obligatorio de pago',
                description: 'Descripción',
                status: 'Estado',
                discountPercentage: 'Descuento',
                installments: 'Cuotas'
            },
            success_message: {
                title: '',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otro',
                },
            },
            error_message: {
                title: 'Se produjo un error al modificar la repactación',
                actions: {
                    primary_text: 'Cerrar',
                    go_back_text: 'Volver'
                }
            },
            section_description: {
                title: 'Datos',
                subtitle: 'Define un nombre e ingresa un código para el producto de facturación seleccionado',
                switchLabel: 'Habilitado'
            },
            alert_message: {
                title: 'Estás deshabilitando un producto',
                actions: {
                  primary_text: 'Aceptar',
                  secondary_text: 'Cancelar'
                }
            },
        },
        detail: {

        }
    },
    en: {

    }
};

export default repaymentTranslations