<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col sm="12">        
                <v-autocomplete
                    class="my-2"
                    outlined
                    :items="periods"
                    item-text="description"
                    :label="$t('modules.sections.table.periods')"
                    v-model="periodSelected" 
                    @change="getTerm(periodSelected, $event)"
                    return-object
                    >
                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" ></v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.description"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.termType.meaning"></v-list-item-subtitle>
                            </v-list-item-content> 
                        </template>              
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row v-if="!syncSectionStatus">
        <v-col cols="12">
            <v-card outlined class="py-8 px-10">
            <h3>{{ $t('modules.canvas.syncTeacher.drawer.table.title') }}</h3><br>
            <p>{{ $t('modules.canvas.syncTeacher.drawer.table.description') }}</p>
            <SuperTable
                :headers="headers"
                :data="sectionsCanvas"
                :isPagination="false"
                :isSearching="false"
                @syncUpSection="(sectionsCanvas)=>syncUpSectionPopUp(sectionsCanvas)"
            ></SuperTable>
            </v-card>
        </v-col>
        </v-row>
        <SuccessMessage
            v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
            />
    </v-container>
    </template>

    <script>
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
    import SuperTable from '../Components/SuperTable';
    import { $users } from '../Services';


    export default {
    name: 'SyncSubjectsView',
    components: {
        SuperTable,
        SuccessMessage
    },
    props: {
        periods: Array,
        user: Array,
        userCanvas: Array,
        subjectsCanvas: Array,
        sectionsCanvas: Array,
    },
    data() {
        return {
            syncSectionStatus:false,
            headers: [
                {
                    text: this.$t('modules.canvas.syncTeacher.drawer.table.headers.section'),
                    sortable: false,
                    value: 'sectionName'
                },
                {
                    text: this.$t('modules.canvas.syncTeacher.drawer.table.headers.term'),
                    sortable: false,
                    value: 'termDescription',
                    align: 'center',
                },
                {
                    text: this.$t('modules.canvas.syncTeacher.table.headers.synchronization'),
                    value: 'synchronizationSection',
                    align: 'center',
                    sortable: false
                },
                {
                    text: this.$t('modules.canvas.syncTeacher.table.headers.edit'),
                    value: 'actionSyncSection',
                    align: 'center',
                    sortable: false
                } 
                ],
            successMessage:{},
            periodSelected:[],
            lmsTermId: null,
            sisTermId: null
        }
    },
    methods: {
        getTerm(periodSelected){
            this.lmsTermId = periodSelected.lmsTermId
            this.sisTermId = periodSelected.id
            this.$emit('selectedPeriod',  this.lmsTermId, this.sisTermId  )
        },
        syncUpSectionPopUp(sectionsCanvas){
            this.$store.dispatch('commons/openPopUp', {
                title: this.$t('modules.canvas.syncTeacher.message.syncUpSection'),
                content: [
                    {
                        value: sectionsCanvas.sectionName
                    }
                ],
                actionPrimary: {
                    text: this.$t('modules.canvas.syncStudent.message.syncAccept'),
                    callback: () => this.syncSection(sectionsCanvas)
                },
                actionSecondary: {
                    text: this.$t('modules.canvas.syncStudent.message.syncCancel'),
                    callback() {}
                },
                icon: { color: 'warning', name: 'mdi-sync' },
                color: 'primary'
            })
        },
        async syncSection(sectionsCanvas){
            const bodySync = {
                teacherSectionId: sectionsCanvas.sectionId,
                lmsId: sectionsCanvas.idCanvas
            }
            try {
                await $users.update(`teacher/section`, bodySync)
                this.successMessage.type = 'success';
                this.successMessage.title = this.$t('modules.canvas.syncTeacher.message.syncUpSectionSuccess');
                this.successMessage.actionPrimary = { text: this.$t('modules.canvas.syncStudent.actions.close'), callback: () => { this.$emit('closeDrawer') 
                this.syncSectionStatus = false}  }
                this.successMessage.actionSecondary = null;
                this.getTerm(this.periodSelected)
            } catch (error) {
            this.successMessage.type = 'error';
                this.successMessage.title = this.$t('modules.canvas.syncTeacher.message.syncUpSectionError');
                this.successMessage.actionSecondary = null;
                this.successMessage.actionPrimary = { text: this.$t('modules.canvas.syncStudent.actions.close'), callback: () => { this.$emit('closeDrawer') 
                this.syncSectionStatus = false } }
            } finally {
            this.syncSectionStatus = true;
            }
        },
    },
};
</script>
