
import academicChargeTranslations from './AcademicCharge/main.i18n.js'
import paymentsTranslations from './Payments/main.i18n.js'
import appsTranslations from './Apps/Translations'
import examsTranslations from './Exams/Translations'
import practiceTranslations from './ProfessionalizingPractice/Translations'
import dashboardTranslations from './Dashboard/Translations'
import proceduresTranslations from './Procedures/Translations'
import reglamentoTranslations from './Reglamento/Translations'
import studyPlanTranslations from './AcademicCharge/StudyPlan/Translations'
import profileTranslations from './Profile/Translations'
import admissionTranslations from './Admission/Translations'
import communicationsTranslations from './Communications/Translations'

const portalAlumnoTranslations = {
    es_MX: {
        academic_charge: { ...academicChargeTranslations.es_MX },
        apps: { ...appsTranslations.es_MX },
        dashboard: { ...dashboardTranslations.es_MX },
        exams: { ...examsTranslations.es_MX },
        payments: { ...paymentsTranslations.es_MX },
        procedures: { ...proceduresTranslations.es_MX },
        profile: { ...profileTranslations.es_MX },
        reglamento: { ...reglamentoTranslations.es_MX },
        study_plan: { ...studyPlanTranslations.es_MX },
        admission: { ...admissionTranslations.es_MX },
        communications: { ...communicationsTranslations.es_MX },
        practice: { ...practiceTranslations.es_MX}
    },
    es_CL: {
        academic_charge: { ...academicChargeTranslations.es_CL },
        apps: { ...appsTranslations.es_CL },
        dashboard: { ...dashboardTranslations.es_CL },
        exams: { ...examsTranslations.es_CL },
        payments: { ...paymentsTranslations.es_CL },
        procedures: { ...proceduresTranslations.es_CL },
        profile: { ...profileTranslations.es_CL },
        reglamento: { ...reglamentoTranslations.es_CL },
        study_plan: { ...studyPlanTranslations.es_CL },
        admission: { ...admissionTranslations.es_CL },
        communications: { ...communicationsTranslations.es_CL },
        practice: { ...practiceTranslations.es_CL}
    },
    es_PE: {
        academic_charge: { ...academicChargeTranslations.es_PE },
        apps: { ...appsTranslations.es_PE },
        dashboard: { ...dashboardTranslations.es_PE },
        exams: { ...examsTranslations.es_PE },
        payments: { ...paymentsTranslations.es_PE },
        procedures: { ...proceduresTranslations.es_PE },
        profile: { ...profileTranslations.es_PE },
        study_plan: { ...studyPlanTranslations.es_PE },
        admission: { ...admissionTranslations.es_PE },
        practice: { ...practiceTranslations.es_PE}
    },
    es: {
        academic_charge: { ...academicChargeTranslations.es },
        apps: { ...appsTranslations.es },
        dashboard: { ...dashboardTranslations.es },
        exams: { ...examsTranslations.es },
        payments: { ...paymentsTranslations.es },
        procedures: { ...proceduresTranslations.es },
        profile: { ...profileTranslations.es },
        reglamento: { ...reglamentoTranslations.es },
        study_plan: { ...studyPlanTranslations.es },
        admission: { ...admissionTranslations.es },
        communications: { ...communicationsTranslations.es },
        practice: { ...practiceTranslations.es}
    },
    en: {
        academic_charge: { ...academicChargeTranslations.en },
        apps: { ...appsTranslations.en },
        dashboard: { ...dashboardTranslations.en },
        exams: { ...examsTranslations.en },
        payments: { ...paymentsTranslations.en },
        procedures: { ...proceduresTranslations.en },
        profile: { ...profileTranslations.en},
        reglamento: { ...reglamentoTranslations.en },
        study_plan: { ...studyPlanTranslations.en },
        admission: { ...admissionTranslations.en },
        communications: { ...communicationsTranslations.en },
    }
}

export default portalAlumnoTranslations
