<template>
    <div class="scrollableContent">
        <v-container class="d-flex flex-row pa-0 fullHeight scrollableContent" fluid>
            <!-- <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed"></AsideBar> -->
            <v-container class="container container-custom px-6">
                <Breadcrumbs :description="$t('modules.preRegistration.table.breadcrumbs.subtitle')" :title="$t('modules.preRegistration.table.breadcrumbs.title')">
                    <Button
                        v-if="canCreate"
                        @clicked="create"
                        :icon="breadcrumbs.button.icon"
                        :text="breadcrumbs.button.name"
                        depressed
                    ></Button>
                </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12">
                        <SuperTable
                            :headers="headers"
                            :data="preRegistrations"
                            :pageCount="asidebar.pagination.pagesAmount"
                            :page="asidebar.pagination.currentPage"
                            :totalItems="totalItems"
                            :itemsPerPage="asidebar.pagination.limit"
                            :permissions="{read: canRead, update: canUpdate, delete: canDelete}"
                            @searchAction="onSearchAction($event)"
                            @itemsPerPageChange="onItemsPerPageChange"
                            @inputPagination="onInputPagination"
                            @delete="(id) => deletePreRegistrationPopUp(id)"
                            @update="(id) => {update(id)}"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
// import AsideBar from '@/components/AsideBar/AsideBar'
import Button from '@/components/Button/Button';
import { $preRegister } from '../services';
import { mapGetters } from 'vuex';
import moment from 'moment'

    export default {
        name: 'SubjectsTable',
        components:{
            SuperTable,
            Breadcrumbs,
            // AsideBar,
            Button
        },
        data () {
            return {
                preRegistrations: [],
                totalItems: 0,
                search: null,
                asidebar: {
                    title: 'Refinar búsqueda',
                    pagination: {
                        limit: 20,
                        page: 1,
                        currentPage: 1,
                        pagesAmount: 1,
                    },
                    items: [
                        {
                            name: this.$t('modules.preRegistration.table.asidebar.firstFilter.name'),
                            field: '-',
                            filters: []
                        },{
                            name: this.$t('modules.preRegistration.table.asidebar.secondFilter.name'),
                            field: '-',
                            filters: []
                        },{
                            name: this.$t('modules.preRegistration.table.asidebar.thridFilter.name'),
                            field: '-',
                            filters: [
                                {
                                    type: 'check',
                                    field: 'modalityType',
                                    name: this.$t('modules.preRegistration.table.asidebar.thridFilter.inputs.firstInput.name'),
                                    filterValue: 'Anulado',
                                    value: true,
                                    dataFiltered: ''
                                },{
                                    type: 'check',
                                    field: 'modalityType',
                                    name: this.$t('modules.preRegistration.table.asidebar.thridFilter.inputs.secondInput.name'),
                                    filterValue: 'Anulado',
                                    value: true,
                                    dataFiltered: ''
                                },{
                                    type: 'check',
                                    field: 'modalityType',
                                    name: this.$t('modules.preRegistration.table.asidebar.thridFilter.inputs.thirdInput.name'),
                                    filterValue: 'Anulado',
                                    value: true,
                                    dataFiltered: ''
                                }
                            ]
                        },{
                            name: this.$t('modules.preRegistration.table.asidebar.fourthFilter.name'),
                            field: '-',
                            filters: []
                        },
                    ]
                },
                breadcrumbs: {
                    title: this.$t('modules.preRegistration.table.breadcrumbs.title'),
                    description: this.$t('modules.preRegistration.table.breadcrumbs.subtitle'),
                    button: {
                        name: this.$t('actions.new'),
                        icon: 'mdi-plus',
                        color: 'primary'
                    }
                },
                headers: [
                    {
                        text: this.$t('modules.preRegistration.table.headers.name'),
                        value: 'name',
                    },{
                        text: this.$t('modules.preRegistration.table.headers.RUT'),
                        value: 'identification'
                    },{
                        text: this.$t('modules.preRegistration.table.headers.career'),
                        value: 'career'
                    },{
                        // :TODO - Fix zonas, reemplazar bloque con cometario debajo al subir cambios
                        // text: this.$t('modules.preRegistration.table.headers.ceo'),
                        // text:value: 'ceo'
                         text: this.$t('modules.preRegistration.table.headers.city'),
                         value: 'city'
                    },{
                        text: this.$t('modules.preRegistration.table.headers.term'),
                        value: 'term'
                    },{
                        text: this.$t('modules.preRegistration.table.headers.status'),
                        value: 'status'
                    },{
                        text: '',
                        value: 'action',
                        sortable: false
                    }
                ],
            }
        },
        computed: {
            ...mapGetters({
                configurationValues: 'settings/getAllCurrentValues',
                configurationOptions: 'settings/getAllOptions',
                userId: 'commons/getUserID',
            }),
            canRead() {
                return this.$permissions.portaladministrativo.admission.pre_registration.READ
            },
            canCreate() {
                return this.$permissions.portaladministrativo.admission.pre_registration.CREATE
            },
            canUpdate() {
                return this.$permissions.portaladministrativo.admission.pre_registration.UPDATE
            },
            canDelete() {
                return this.$permissions.portaladministrativo.admission.pre_registration.DELETE
            },
        },
        mounted () {
            this.fetchData()
        },
        methods: {
            onItemsPerPageChange (event) {
                this.asidebar.pagination.limit = event
                this.fetchData()
            },
            onSearchAction (query) {
                this.search = query
                this.fetchData()
            },
            async onInputPagination (event) { // event param
                this.asidebar.pagination.page = event
                if (!this.preRegistrations[(event - 1) * this.asidebar.pagination.limit]) {
                    const fetchedRegistrations = await $preRegister.find(null, null, {params: {page: this.asidebar.pagination.page-1, length: this.asidebar.pagination.limit}})
                    fetchedRegistrations.data.content.forEach((element, index) => {
                        this.preRegistrations[index + (event - 1) * this.asidebar.pagination.limit] = this.formatRegistrations(element)
                    })
                    this.preRegistrations = Array.from(this.preRegistrations)
                }
                this.asidebar.pagination.currentPage = event
            },
            deletePreRegistrationPopUp(id){
                this.$store.dispatch('commons/openPopUp', {
                    title:  this.$t('modules.preRegistration.table.messages.askDelete'),
                    content: [{ value: id }],
                    actionPrimary: {text: this.$t('actions.accept'), callback: () => this.deletePreRegitration(id)},
                    actionSecondary: { text: this.$t('actions.cancel'), callback () {} },
                    icon: {color: 'error', name: 'mdi-delete'},
                    color: 'primary',
                })
            },
            formatRegistrations (preRegistrations) {
                if (Array.isArray(preRegistrations)) {
                    if (preRegistrations.length) {
                        return preRegistrations.map(preRegistration => {
                            return {
                                id: preRegistration.academicRegister,
                                identification: preRegistration.identificacion,
                                name: preRegistration.name,
                                career: preRegistration.career,
                                city: preRegistration.city,   // :TODO - Fix zonas, descomentar linea al subir cambios
                                term: this.configurationValues.dateFormat ? moment(preRegistration.startDate).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) : moment(preRegistration.startDate).format('YYYY-MM-DD'),
                                status: preRegistration.studentCareerStatus.meaning
                            }
                        });
                    } else {
                        return []
                    }
                } else {
                    return {
                        id: preRegistrations.academicRegister,
                        identification: preRegistrations.identificacion,
                        name: preRegistrations.name,
                        career: preRegistrations.career,
                        city: preRegistrations.city,   // :TODO - Fix zonas, descomentar linea al subir cambios
                        term: this.configurationValues.dateFormat ? moment(preRegistrations.startDate).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) : moment(preRegistrations.startDate).format('YYYY-MM-DD'),
                        status: preRegistrations.studentCareerStatus.meaning
                    }
                }
            },
            async fetchData() {
                this.clearSelected = new Date().getMilliseconds().toString();
                const params = { page: this.asidebar.pagination.page-1, length: this.asidebar.pagination.limit }
                if (this.search) params.identification = this.search

                const fetchedRegister = await $preRegister.find(null, null, { params })
                this.preRegistrations = this.formatRegistrations(fetchedRegister.data.content)
                this.asidebar.pagination.pagesAmount = fetchedRegister.data.totalPages
                this.totalItems = fetchedRegister.data.totalElements
            },
            create() {
                if (this.canCreate) this.$router.push('preregistration/create')
            },
            update(id) {
                if (this.canUpdate) this.$router.push(`preregistration/update/${id}`)
            },
            async deletePreRegitration (id) {
                try {
                    await $preRegister.update(id, {value: 56, user: this.userId})
                    this.fetchData()
                    this.showMessage('success', this.$t('modules.preRegistration.table.messages.success'))
                } catch (error) {
                    this.showMessage('error', error.codeMeaning)
                }
            },
            showMessage(type, message) {
                const icon = type == 'error' ? 'mdi-delete' :'mdi-check';
                this.$store.dispatch('commons/openPopUp', {
                    title: message,
                    content: '',
                    actionPrimary: {text: this.$t('actions.accept'), callback() {} },
                    actionSecondary: null,
                    icon: {color: type, name: icon},
                    color: 'primary',
                });
            }
        }
    }
</script>

<style scoped>

</style>
