<template>
    <v-container fluid class="superTable pa-0">
        <v-row no-gutters>
            <v-col sm="4">
                <v-text-field prepend-inner-icon="mdi-magnify" :placeholder="$t('actions.search')" outlined clearable
                    class="superTable__search" v-model="tableFilter" @click:clear="clearAction" @keyup.enter="searchAction"
                    @keyup.delete="clearAction"></v-text-field>
                    <div v-if="searchError" class="error--text">Debes buscar por CURP</div>
            </v-col>
            <v-col sm="4">
                <Button class="ml-4" @clicked="searchAction" outlined depressed :text="$t('actions.search')"></Button>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table :items="items" :headers="headers" :page.sync="page" :items-per-page="itemsPerPage"
                    hide-default-footer item-key="id" class="superTable__table" style="table-layout: fixed"
                    :loading="loading" :server-items-length="totalItems" :sort-by="sortBy" :sort-desc="sortDesc"
                    @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
                    @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)">
                    <template v-slot:[`item.action`]="{ item }">
                        <v-menu bottom left v-if="statusID === 249">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item @click="goToCertificateData(item, 'Rechazado')">
                                    <v-list-item-title>
                                        <v-icon>mdi-close-circle</v-icon> Rechazado
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="goToCertificateData(item, 'Observado')">
                                    <v-list-item-title>
                                        <v-icon>mdi-eye-check-outline</v-icon> Observado
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="goToCertificateData(item, 'Legalizado')">
                                    <v-list-item-title>
                                        <v-icon>mdi-checkbox-marked-circle-outline</v-icon> Legalizado
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu bottom left v-if="statusID === 247">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item @click="goToCertificateData(item, 'Generado')">
                                    <v-list-item-title>
                                        <v-icon>mdi-checkbox-marked-circle-outline</v-icon> Generado
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu bottom left v-if="statusID === 248">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item @click="goToCertificateData(item, 'EnLegalizacion')">
                                    <v-list-item-title>
                                        <v-icon>mdi-checkbox-marked-circle-outline</v-icon> En legalización
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                         <v-menu bottom left v-if="statusID === 252">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item @click="goToCertificateData(item, 'EnviadoACeo')">
                                    <v-list-item-title>
                                        <v-icon>mdi-checkbox-marked-circle-outline</v-icon> Enviar a Ceo
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu bottom left v-if="statusID === 253">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item @click="goToCertificateData(item, 'EntregaFinal')">
                                    <v-list-item-title>
                                        <v-icon>mdi-checkbox-marked-circle-outline</v-icon> Entregado
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
        <v-row no-gutters align="center">
            <v-col sm="3">
                <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' +
                    itemsCount.totalItems }}</span>
                <v-select v-model="itemsPerPage" :items="itemsPerPageAvailable" :placeholder="itemsPerPage.toString()"
                    outlined class="superTable__paginationSelect d-inline-block ml-4"
                    @change="$emit('length-pagination-change', itemsPerPage)" />
            </v-col>
            <v-col sm="6" class="text-center">
                <v-pagination :value="page" :length="pageCount" @next="$emit('next-pagination', page)"
                    @previous="$emit('previous-pagination', page)" @input="$emit('input-pagination', $event)"
                    class="superTable__pagination" />
            </v-col>
            <v-col sm="3" align="right">
                <v-text-field max="pageCount" outlined class="superTable__paginationInput d-inline-block mr-4"
                    v-model.number="goToPage" @blur="changePage" />
                <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="sass" scoped>
.v-expansion-panels
    .v-expansion-panel-content
        padding-left: 10px
        padding-bottom: 0px
        padding-right: 16px
</style>

<script>
import Button from '@/components/Button/Button';


const statusCodes = {
  Rechazado: 250,
  Observado: 251,
  EntregaFinal: 254,
  Generado: 248,
  EnLegalizacion: 249,
  Legalizado: 252,
  EnviadoACeo: 253
};


export default {
    name: 'SuperTable',
    components: {
        Button
    },
    props: {
        items: Array,
        headers: Array,
        loading: Boolean,
        sortBy: String,
        sortDesc: Boolean,
        pageCount: { type: Number, default: 1 },
        page: { type: Number, default: 1 },
        totalItems: Number,
        statusID: Number,
        searchError: Boolean

    },
    data() {
        return {
            itemsPerPage: 20,
            itemsPerPageAvailable: [20, 50, 100, 200],
            tableFilter: null,
            searchStudent: null,
            goToPage: 1,
        }
    },
    methods: {
        goToCertificateData(item,estado) {
            const newStatus = statusCodes[estado]
            this.$emit('update', { item, newStatus })
        },
        searchAction() {
            const body = {
                search: this.tableFilter ? this.tableFilter : '',
                period: null,
                status: null,
            }
            this.$emit('search', body)
        },
        clearAction() {
            const body = {
                search: '',
                period: null,
                status: null,
            }
            this.$emit('search', body)
        },
        changePage() {
            if (this.goToPage > this.pageCount) this.goToPage = this.page;
            else this.$emit('input-pagination', this.goToPage);
        },

    },
    watch: {
        page(val) {
            if (val > this.pageCount) this.page = this.pageCount;
            if (val < 1) this.page = 1;
            if (this.page !== this.goToPage) this.goToPage = this.page;
        },
    },
    computed: {
        itemsCount() {
            const counter = {
                totalItems: this.totalItems,
                startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
                endItem: this.page * this.itemsPerPage
            };
            if (counter.endItem > this.totalItems) counter.endItem = this.totalItems
            return counter
        }
    },
};
</script>
