<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom">
            <Breadcrumbs
                :title="$t('modules.portalalumno.reglamento.breadcrumbs.title')"
                :description="$t('modules.portalalumno.reglamento.breadcrumbs.description')"
                :callback="() => { this.backHome() }"
            ></Breadcrumbs>
            <v-row justify="center">

                <v-col sm="12" md="5" lg="8" class="order-last order-sm-first margin-bot">
                    <div v-show="!selectedCapitulo">
                        <v-card-title class=" py-0 justify-center">
                            <h4 class="text-center">
                                {{ $t('modules.portalalumno.reglamento.label.shortCut') }}
                            </h4>
                        </v-card-title>
                        <v-row max-height="1000px" justify="center" justify-lg="start" class="mt-8">
                            <div v-for="card in cards" :key="card.id">
                                <Reglamento-card :card="card" @sendToEntry="sendToEntry"></Reglamento-card>
                            </div>
                        </v-row>
                    </div>

                    <div v-if="selectedCapitulo">
                        <v-card-title class= "py-0 justify-center lime darken-1">
                            <h4 class="text-center title">{{ selectedCapitulo.name }}</h4>
                        </v-card-title>
                        <v-row class="">
                              <v-container v-for="capitulo in capituloNews" :key="capitulo.id">
                                  <h5 :id="`sub-${capitulo.id}`">{{ capitulo.namet }}</h5>
                                  <div v-html="capitulo.filteredContent"></div>
                              </v-container>
                        </v-row>
                        <v-row class="mt-8">
                            <v-container v-for="subcapitulo in selectedCapitulo.subchapter"  :key="subcapitulo.id">
                                <h5 :id="`sub-${subcapitulo.id}`">{{ subcapitulo.name}}</h5>
                                <div v-html="subcapitulo.content"></div>
                            </v-container>
                        </v-row>
                    </div>
                </v-col>

                <v-col sm="12" md="7" lg="4" id="indices">
                    <v-row  class="mx-auto " justify="center">
                        <v-card width="100%">
                            <v-card-title class="py-0 white--text primary lighten-1 justify-center text-uppercase">
                                <h4 class="title"> {{ $t('modules.portalalumno.reglamento.label.indices') }} </h4>
                            </v-card-title>
                            <v-card-actions class="justify-center">
                                <v-combobox
                                    v-model="searchArt"
                                    @change="selectCapitulo($event)"
                                    item-text="name"
                                    return-object
                                    :items="autocompleteItems"
                                    :label="$t('modules.portalalumno.reglamento.label.search')"
                                    prepend-inner-icon="mdi-magnify"
                                    :search-input.sync="searchText"
                                    :filter="filterCapitulosByText"
                                    outlined
                                    auto-select-first
                                    :menu-props="{maxWidth: '338'}"
                                    clearable
                                ></v-combobox>
                            </v-card-actions>
                            <v-card-title class="py-0 white--text primary darken-1 justify-space-between">
                                <h4 class="title"> {{ $t('modules.portalalumno.reglamento.label.shortCut') }} </h4>
                                <v-icon class="white--text" @click="backHome">mdi-home</v-icon>
                            </v-card-title>

                            <v-list style="max-height: 600px " class="overflow-y-auto">
                                <v-list-group v-for="capitulo  in capituloNews" :key="capitulo.id" v-model="capitulo.active" @click="selectCapitulo(capitulo)">
                                    <template v-slot:activator>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ capitulo.name }}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <v-list-item v-for="subcapitulo  in capitulo.subchapter"  :key="subcapitulo.id" @click="selectCapitulo(capitulo, subcapitulo.id)">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ subcapitulo.name }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-group>
                            </v-list>
                        </v-card>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>


<script>
    import ReglamentoCard from '../Components/ReglamentoCard.vue'
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import Constants from '@/plugins/constants'
    import { $rules} from '../Services';
    import { mapGetters } from 'vuex';
    export default {
        components: {
            Breadcrumbs,
            ReglamentoCard
        },
        name: 'ReglamentoView',
        data() {
            return {
                autocompleteItems: [],
                capituloNews: [],
                selectedEntryId: null,
                searchArt: '',
                searchText: null,
                cards: [],
                selectedCapitulo: null,
            }
        },
        computed: {
            token(){
                return this.$isTeclab ? '9e705928b960e3c338102ab3233e1e' : '9b840d8b42fa7c01c06df4e95913d0'
            },
            uriBase() {
                return `https://${Constants.DOMAIN}`
            },
            // preFix() {
            //   const regulationType = this.$route.path.split('/').pop()
            //   return regulationType != 'reglamento' ? regulationType : ''
            // },
            path() {
              const regulationType = this.$route.path.split('/').pop()
              return regulationType != 'reglamento' ? regulationType : ''
            },
            ...mapGetters({
                rulesImage: 'commons/getRulesImage'
            }),
        },
        methods: {
            backHome() {
                this.selectedCapitulo = null
                this.selectedEntryId = null
                this.searchText = ''
                this.autocompleteItems = this.capituloNews
            },
            filterCapitulosByText(item, queryText, itemText) {
                const query = queryText.toLocaleLowerCase()
                return itemText.toLocaleLowerCase().indexOf(query) > -1 ||
                    !!item.subchapter.find(subcapitulo => subcapitulo.filteredContent.indexOf(query) > -1)
            },
            trackEventIPP(id) {
              switch(id) {
                case 'Disposición General':
                  this.$trackEvent('Reglamento', 'Clk_DispGen_regl', 'AccesosRapi')
                  break;
                case 'Validación de Identidad':
                  this.$trackEvent('Reglamento', 'Clk_ValIden_regl', 'AccesosRapi')
                  break;
                case 'Verificación de Identidad':
                  this.$trackEvent('Reglamento', 'Clk_VerIdent_regl', 'AccesosRapi')
                  break;
                case 'Requerimiento para la rendición remota':
                  this.$trackEvent('Reglamento', 'ClktRendRemot_regl', 'AccesosRapi')
                  break;
                case 'Equipo computacional':
                  this.$trackEvent('Reglamento', 'Clk_Equip_regl', 'AccesosRapi')
                  break;
                case 'Cámara Web':
                  this.$trackEvent('Reglamento', 'Clk_CamWeb_regl', 'AccesosRapi')
                  break;
                case 'Prohibido el uso de celulares':
                  this.$trackEvent('Reglamento', 'Clk_UsoCel_regl', 'AccesosRapi')
                  break;
                case 'Causales de anulación':
                  this.$trackEvent('Reglamento', 'Clk_CausAnul_regl', 'AccesosRapi')
                  break;
                case 'Anulaciones':
                  this.$trackEvent('Reglamento', 'Clk_Anula_regl', 'AccesosRapi')
                  break;
                case 'Sanciones':
                  this.$trackEvent('Reglamento', 'Clk_Sancion_regl', 'AccesosRapi')
                  break;
                }
            },
            sendToEntry(id, title) {
                if(this.$isIPP) {
                  this.trackEventIPP(title);
                }
                this.capituloNews.forEach(capitulo => {
                    if (capitulo.id == id) {
                        this.selectCapitulo(capitulo)
                    } else {
                        capitulo.subchapter.forEach(subcapitulo => {
                            if (subcapitulo.id == id) this.selectCapitulo(capitulo, subcapitulo.id)
                        });
                    }
                });

                this.trackEvent(id, title);
            },
            trackEvent(id, title) {

                switch(id) {
                    //Disposiciones generales
                    case '5a29785e08b16doc9404':
                        this.$trackEvent('Reglamento', 'Clk_ValIden_regl', 'AccesosRapi');
                        break;
            //sobre teclab
                    case '5a26be2657376doc23271':
                        this.$trackEvent('Reglamento', 'Clk_SobTeclab_regl', 'AccesosRapi');
                        break;
            //ingreso y administracioin
                    case '5a26cbe078d9bdoc2264':
                        this.$trackEvent('Reglamento', 'Clk_Ingreso_regl', 'AccesosRapi');
                        break;
            //INSCRIPCION A MATERIAS
                    case '5a2970d0e994edoc14116':
                        this.$trackEvent('Reglamento', 'Clk_InscMat_regl', 'AccesosRapi');
                        break;
            //Cursado de materias - Practica profesionalizante
                    case '5a297115a973adoc31079':
                        if(title == 'Cursado de materias') {
                            this.$trackEvent('Reglamento', 'Clk_CursMat_regl', 'AccesosRapi');
                        } else if (title == 'Práctica Profesionalizante') {
                            this.$trackEvent('Reglamento', 'Clk_PracProf_regl', 'AccesosRapi');
                        }
                        break;
            //Examenes
                    case '5a2972022dd2ddoc17667':
                        this.$trackEvent('Reglamento', 'Clk_Exam_regl', 'AccesosRapi');
                        break;
            //Cambios
                    case '5a2972dd7437cdoc10797':
                        this.$trackEvent('Reglamento', 'Clk_Cambios_regl', 'AccesosRapi');
                        break;
            //Creditos Teclab y Becas
                    case '5a2973021e293doc29191':
                        this.$trackEvent('Reglamento', 'Clk_CredBecas_regl', 'AccesosRapi');
                        break;
                }
            },
            async getFetchCapitulo() {
                try {//editar aca para que le pegue a las url vieja si es dif de general
                    if(this.path){
                        //Revisar para unificar con argentina y chile antes de unificar países
                        const {data} = await $rules.find('chapters/'.concat(this.path))
                        this.capituloNews = data.map( e => {
                            e.active = false
                            e.filteredContent = this.stripHTML(e.content).toLowerCase();
                            e.subchapter.map(subcapitulo => {
                                subcapitulo.filteredContent = subcapitulo.content.replace(/<[^>]+>/g, '').toLowerCase()
                                return subcapitulo
                            })
                            e.subchapter.sort((a, b) => {
                              const numeroA = parseInt(a.name.replace(/\D/g, ''), 10);
                              const numeroB = parseInt(b.name.replace(/\D/g, ''), 10);
                              return numeroA - numeroB;
                          });
                          return e;
                      });
                }
                    this.autocompleteItems = this.capituloNews
                } catch (error) {
                    this.capituloNews = []
                    this.autocompleteItems = []
                    throw error
                }
            },
            async getFetchCard () {
                //Revisar para unificar con argentina y chile antes de unificar países
                try {
                    if(this.path){
                //Revisar para unificar con argentina y chile antes de unificar países
                        const {data} = await $rules.find('cards/'.concat(this.path))
                        this.cards = data.map(e => {
                            return {
                                img: this.rulesImage + e.image,
                                title: e.title,
                                action: e.action,
                                href: e.href,
                                order: e.order
                            }
                        }).sort((a, b) => a.order - b.order)
                    }
                } catch (error) {
                    this.cards = []
                    throw error
                }
            },
            selectCapitulo(capitulo, subcapitulo_id = null) {
                this.selectedCapitulo = capitulo
                this.selectedEntryId = subcapitulo_id
                setTimeout(() => {
                  const art = document.getElementById(`sub-${this.selectedEntryId}`);
                art.scrollIntoView({block: 'start', behavior: 'smooth'})}, 300)
            },
            stripHTML(html) {
                const div = document.createElement('div');
                div.innerHTML = html;
                return div.textContent || div.innerText || '';
            },
        },
        async mounted () {
            await this.getFetchCard();
            await this.getFetchCapitulo();
        },
        watch:{
            path(value, old){
                if(old !== value){
                    this.getFetchCapitulo()
                    this.getFetchCard()
                }
            }
        }
    }
</script>

<style lang="sass" scoped>
    .bg
        background-color: #ecedf0
    .v-menu__content
        background-color: red !important

</style>
