import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';
import AuthService from '../../plugins/auth.service'


const url = createUrl('core');

export class Users extends Api {
  constructor(http, config = {}) {
    super(http, url, 'users', config);
  }

  /**
   * @param  {string} id
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<resource>}
   */
  async updatePassword (id, apiMethod = null, config = {}, instantce={}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(id, apiMethod);

    if (!AuthService.checkUserLoggedIn()) {
      throw new Error('Not logged in');
    }

    return this._http.post(uri, instantce , config);
  }

  /**
   * @param  {string} apiMethod=null - api method
   * @param  {object} filter=null - filter object
   * @param  {object} parameters=null - parameters object
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<resource>}
   */
  async findFiltered(apiMethod = null, filter = null, parameters, config = {}) {
    config = { ...this._config, ...config };
    const uri = this._uriConstructor(null, apiMethod, filter);
    return this._http.post(uri, parameters, config);
  }

  async byRolCeo(parameters, config = {}) {
    config = { ...this._config, ...config }
    const uri = this._uriConstructor(null, 'by-rol', null)
    return this._http.get(uri, parameters, config)
  }

  async sendCognitoVerificationCode(parameters, config = {}) {
    config = { ...this._config, ...config }
    const uri = this._uriConstructor(null, 'generateCognitoCode', null)
    return this._http.get(uri, parameters, config)
  }

  async verifyCognitoCode(parameters, config = {}) {
    config = { ...this._config, ...config }
    const uri = this._uriConstructor(null, 'verificationCognitoCode', null)
    return this._http.get(uri, parameters, config)
  }

  async checkUserType(username, config = {}) {
    config = { ...this._config, ...config}
    const uri = `${url}/users/validateIsCognitoUser?userName=${username}`;
    return this._http.get(uri, config)
  }
}
