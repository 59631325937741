import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('pricing');

export class Repayments extends Api {
  constructor(http, config = {}) {
    super(http, url, 'repayments', config);
  }
  

}
