<template>
    <div class="banner" :style="`background-color: ${banner.backgroundColor}`">
        <div class="banner__content">
            <img class="banner__content__image" :src="banner.image">
            <div class="banner__content__text">
                <div class="banner__content__text__caption" v-if="banner.text.caption" v-html="banner.text.caption"></div>
                <div class="banner__content__text__title" v-html="banner.text.title" style="color: var(--v-primary-lighten5)"></div>
                <div class="banner__content__text__comment mb-3" v-if="banner.text.comment" v-html="banner.text.comment" style="color: var(--v-primary-lighten5)"></div>
            </div>
        </div>
        <div class="banner__buttons">
            <v-btn
                v-for="(button, index) in banner.buttons"
                :key="index"
                rounded
                :small="button.size === 'sm'"
                :large="button.size === 'lg'"
                :x-large="button.size === 'x-lg'"
                :depressed="button.outline"
                :style="`color: ${button.textColor}`"
                :class="`banner__buttons__${button.outline ? 'outline' : 'solid'}`"
                :color="button.color"
                @click="buttonClick(button)"
            >
                <v-icon class="pr-2" v-if="button.icon">{{ button.icon }}</v-icon>
                {{ button.text }}
            </v-btn>
        </div>
        <v-dialog attach=".layout-grid__column--right" content-class="banner-video" v-model="videoDialog">
            <iframe
                v-if="videoDialog"
                type="text/html" width="640" height="360"
                iv_load_policy="3" modestbranding="1" showinfo="0"
                :src="`${videoSrc}?rel=0`"
                frameborder="0"
                allowfullscreen
            ></iframe>
            <v-btn icon dark class="close-dialog" @click="videoDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: 'OnboardingSidePrimaryBanner',
        props: {
            banner: Object
        },
        data() {
            return {
                videoDialog: false,
                videoSrc: null
            }
        },
        methods: {
            buttonClick(button) {
                if(button.action.includes('youtube') || button.action.includes('.mp4')) {
                    this.videoDialog = true
                    this.videoSrc = button.action
                } else {
                    window.open(button.action)
                }
                this.$trackEvent(button.analytics.category, button.analytics.action, button.analytics.label)
            }
        }
    };
</script>

<style lang="sass" scoped>

.banner
  display: flex
  flex-direction: column
  border-radius: 5px
  padding: 16px 0 16px 20px
  margin-bottom: 24px
  &__content
    position: relative
    min-height: 137px
    &__image
      position: absolute
      right: 0
      bottom: 90px
      height: 50%
      max-height: 230px
    &__text
      width: 50%
      position: relative
      &__caption
        font-size: medium
        font-weight: bold
      &__title
        font-size: large
        font-weight: bold
      &__comment
        font-size: small
        padding-top: 8px
  &__buttons
    padding-right: 16px
    &__outline
      margin-top: 8px
      width: 100%
      font-weight: bold
    &__solid
      margin-top: 8px
      width: 100%

.close-dialog
    position: absolute
    top: 10px
    right: 10px

</style>

<style lang="sass">
    .layout-grid__column--right
        > .v-dialog__content
            > .banner-video
                position: relative
                width: 640px
                height: 360px
                max-height: 100% !important
                line-height: 0 !important
</style>