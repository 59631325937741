import SyncTeacher from '../Views/SyncTeacherView';

const SyncTeacherRoute = [
    {
        path: '',
        component: SyncTeacher,
        meta: { name: 'sync_teacher', group: 'portaladministrativo.canvas', path: 'sync_teacher', permission: 'READ' }
    },
]

export default SyncTeacherRoute