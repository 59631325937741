<template>
    <v-container fluid class="d-flex pa-0 ">
        <AsideBar :items="asidebar.items"  style="height: 1600px;" :title="asidebar.title"  @changed="changed"></AsideBar>
        <v-container fluid class=" pa-0">
            <v-dialog v-model="modal" width="fit-content" scrollable>
                <ConfirmModal :is-error="isError"  :is-success="isSuccess"  @confirm="updateCertificateStatus" :selectedNewStatus="selectedNewStatus" :selected-student="selectedStudent" @close="closeConfirmModal" />
            </v-dialog>
            <v-container class="container-custom px-6">
                <Breadcrumbs :title="$t('modules.academic.intermediateDegree.breadcrumbs.title')"
                    :description="$t('modules.academic.intermediateDegree.breadcrumbs.description')">
                </Breadcrumbs>
                <v-row>
                    <v-row>
                        <v-col sm="3" class="px-0">
                            <PanelDataTable :data_text="$t('modules.academic.intermediateDegree.certificados.iniciados')"
                                :data_procedures="certificates_initiated" />
                        </v-col>
                        <v-col sm="3" class="px-0">
                            <PanelDataTable :data_text="$t('modules.academic.intermediateDegree.certificados.generados')"
                                :data_procedures="certificates_generated" />
                        </v-col>
                        <v-col sm="3" class="px-0">
                            <PanelDataTable
                                :data_text="$t('modules.academic.intermediateDegree.certificados.en_legalizacion')"
                                :data_procedures="certificates_InLegalization" />
                        </v-col>
                        <v-col sm="3" class="px-0">
                            <PanelDataTable :data_text="$t('modules.academic.intermediateDegree.certificados.legalizados')"
                                :data_procedures="certificates_Legalized" />
                        </v-col>
                        <v-col sm="3" class="px-0">
                            <PanelDataTable
                                :data_text="$t('modules.academic.intermediateDegree.certificados.enviados_a_ceo')"
                                :data_procedures="certificates_sent_to_ceo" />
                        </v-col>
                    </v-row>
                </v-row>
                <v-col class="viewSpaces">
                    <SuperTable @search="searchAction" :search-error="searchError" :headers="headers" @update="(id) => { openConfirmModal(id) }"
                        :items="certificates" :loading="loading" :page-count="pagination.pagesAmount"
                        :page="pagination.page"
                        :totalItems="totalItems" :statusID="statusID" @input-pagination="onInputPagination"
                        @length-pagination-change="onLengthPaginationChange" />
                </v-col>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import AsideBar from '@/components/AsideBar/AsideBar';
import PanelDataTable from '../Components/PanelDataTable.vue';
import SuperTable from '../Components/SuperTable';
import { $certificates } from '../services';
import ConfirmModal from '../Components/ConfirmModal'

export default {
    name: 'IntermediateDegreeView',
    components: {
        Breadcrumbs,
        PanelDataTable,
        SuperTable,
        AsideBar,
        ConfirmModal
    },
    data() {
        return {
            asidebar: {
                title: this.$t('modules.academic.professionalizingPractice.filters.title'),
                items: [
                    {
                        name: this.$t('modules.academic.professionalizingPractice.filters.condition'),
                        filters: [
                            {
                                name: this.$t('modules.academic.professionalizingPractice.filters.sub2'),
                                type: 'select',
                                value: null,
                                clearable: false,
                                items: [],
                            },
                        ]
                    }
                ]
            },
            headers: [
                {
                    text: this.$t('modules.academic.intermediateDegree.table.headers.student'),
                    value: 'nombreAlumno'
                },
                {
                    text: this.$t('modules.academic.intermediateDegree.table.headers.numero'),
                    value: 'numeracion'
                },
                {
                    text: this.$t('modules.academic.intermediateDegree.table.headers.legajo'),
                    value: 'legajo'
                },
                {
                    text: this.$t('modules.academic.intermediateDegree.table.headers.carrera'),
                    value: 'nombreCarrera'
                },
                {
                    text: this.$t('modules.academic.intermediateDegree.table.headers.curp'),
                    value: 'nroDoc'
                },
                {
                    text: this.$t('modules.academic.intermediateDegree.table.headers.estado'),
                    value: 'estadoCertificado'
                },
                {
                    text: '',
                    sortable: false,
                    value: 'action'
                }
            ],
            pagination: {
                limit: 20,
                page: 1,
                pagesAmount: 1,
            },
            selectedStudent:null,
            selectedNewStatus:null,
            isSuccess: false,
            isError: false,
            searchError:false,
            modal:false,
            certificates: [],
            certificates_initiated: {
                cantidad: 0,
                porcentaje: 0,
                dias: 0
            },
            certificates_generated: {
                cantidad: 0,
                porcentaje: 0,
                dias: 0
            },
            certificates_InLegalization: {
                cantidad: 0,
                porcentaje: 0,
                dias: 0
            },
            certificates_Legalized: {
                cantidad: 0,
                porcentaje: 0,
                dias: 0
            },
            certificates_sent_to_ceo: {
                cantidad: 0,
                porcentaje: 0,
                dias: 0
            },
            STATUS: [
                {
                    value: null,
                    meaning: 'Todos'
                },
                {
                    value: 247,
                    meaning: 'Inicio'
                },
                {
                    value: 248,
                    meaning: 'Generados'
                },

                {
                    value: 249,
                    meaning: 'En Legalización'
                },
                {
                    value: 252,
                    meaning: 'Legalizados'
                },
                {
                    value: 253,
                    meaning: 'Enviados a Ceo'
                }
            ],
            statusID: null,
            loading: false,
            totalItems: null,
            errorMessage: null
        }
    },
    async mounted() {
        await this.getFilterStatus()
        await this.getCertificates()
        await this.getCategoryCertificates()
    },
    methods: {
        async getFilterStatus() {
            try {
                this.STATUS.forEach(term => {
                    this.asidebar.items[0].filters[0].items.push({ text: term.meaning, value: term.value })
                });
                this.asidebar.items[0].filters[0].value = this.asidebar.items[1].filters[0].items[0].value;
            } catch (error) {
                return error
            }
        },
        searchAction(data) {
            if(!data.search || this.statusID){
                this.asidebar.items[0].filters[0].value = null
            }
            else if(!/^\d{8,}$/.test(data.search)){
                this.searchError = true
                setTimeout(() => {
                    this.searchError = false
                },'3000')
                return
            }
            this.getCertificates(data);
        },
        getTotalItems() {
            const statusMapping = {
                247: this.certificates_initiated.cantidad,
                248: this.certificates_generated.cantidad,
                249: this.certificates_InLegalization.cantidad,
                252: this.certificates_Legalized.cantidad,
                253: this.certificates_sent_to_ceo.cantidad
            }
            return statusMapping[this.statusID]
        },
        async getCategoryCertificates() {
            try {
                const { data } = await $certificates.find('/certificadosIntermedioEnTramite')
                this.certificates_initiated.cantidad = data.totalIniciados
                this.certificates_initiated.porcentaje = data.porcIniciados.toFixed(0)
                this.certificates_initiated.dias = data.promedioDiasIniciado
                this.certificates_generated.cantidad = data.totalGenerados
                this.certificates_generated.porcentaje = data.porcGenerados.toFixed(0)
                this.certificates_generated.dias = data.promedioDiasGenerado
                this.certificates_InLegalization.cantidad = data.totalEnLegalizacion
                this.certificates_InLegalization.porcentaje = data.porcEnLegalizacion.toFixed(0)
                this.certificates_InLegalization.dias = data.promedioDiasEnLegalizacion
                this.certificates_Legalized.cantidad = data.totalLegalizados
                this.certificates_Legalized.porcentaje = data.porcLegalizados.toFixed(0)
                this.certificates_Legalized.dias = data.promedioDiasLegalizado
                this.certificates_sent_to_ceo.cantidad = data.totalEnviados
                this.certificates_sent_to_ceo.porcentaje = data.porcEnviados.toFixed(0)
                this.certificates_sent_to_ceo.dias = data.promedioDiasEnviados
            } catch (error) {
                this.errorMessage = error.message
            }
        },
        async getCertificates(body) {
            let urlFetch = 'datosAlumnosCertificados'
            let params = {
                status: null,
                page: this.pagination.page - 1,
                pageLength: this.pagination.limit,
            }
            this.loading = true;
            if (body && body.status) {
                this.statusID = body.status
                params = { ...params, status: body.status }
            }
            else if (body && body.search) {
                params = ''
                urlFetch = `buscarAlumnoByDni?dni=${body.search}&page=0&pageLength=10&_=1699294863680`;
            }
            try {
                const { data } = await $certificates.find(urlFetch, null, { params })
                if(!data.alumnosCertificado) {
                     this.certificates =  []
                    this.loading = false
                    return
                }
                this.certificates = data.alumnosCertificado
                this.totalItems = this.getTotalItems() ? this.getTotalItems()  : data.totalAlumnos
                this.pagination.pagesAmount = data.totalPages
                this.loading = false

            } catch (error) {
                this.errorMessage = error.message
            }
        },
        changed() {
            const body = {
                search: '',
                status: this.asidebar.items[0].filters[0].value ? this.asidebar.items[0].filters[0].value : null,
            }
            if (!this.asidebar.items[0].filters[0].value ) {
                this.statusID = null
            }
            this.pagination.page = 1
            this.getCertificates(body)
        },
        async updateCertificateStatus({ item, newStatus }) {
            try {
                const data = {
                    estadoAnterior: this.statusID,
                    estadoNuevo: newStatus,
                    listLegajos: [item.legajo]
                }
                const urlPostStatus = 'cambiarEstadoCertificado'
                await $certificates.create(data, null, urlPostStatus)
                this.isSuccess = true
                const body = {
                    search: '',
                    status: this.statusID
                }
                this.getCertificates(body)
                setTimeout(() => {
                    this.modal = false
                    this.isSuccess = false
                },'3000')
            } catch (error) {
                this.isError = true
                this.errorMessage = error.message
                setTimeout(() => {
                    this.modal = false
                    this.isError = false
                },'3000')
            }
        },
        onInputPagination(event) {
            const body = {
                search: '',
                status: this.statusID
            }
            this.pagination.page = event;
            this.getCertificates(body);
        },
        onLengthPaginationChange(event) {
            const body = {
                search: '',
                status: this.statusID
            }
            this.pagination = {
                page: 1,
                limit: event,
                pagesAmount: 1,
            };
            this.getCertificates(body);
        },
        openConfirmModal(id) {
            this.selectedStudent =  id.item
            this.selectedNewStatus = id.newStatus
            this.modal = true
        },
        closeConfirmModal() {
            this.modal = false;
        }
    }
};
</script>
