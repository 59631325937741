const multipleStatusGroupsTranslations = {
    es_MX: {
        asignatura: 'Materia'
    },
    es_PE: {
        asignatura: 'Curso',
    },
    es: {
        asignatura: 'Asignatura',
        periodo_aprobacion: 'Periodo de aprobación',
        calificacion: 'Calificación',
        homologada: 'Homologada',
    },
    en: {

    }
}

export default multipleStatusGroupsTranslations