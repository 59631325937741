<template>
    <div class="in-progress-subjects__content d-flex flex-column justify-space-around pb-5 pt-2">
        <v-progress-circular v-if="loading" indeterminate color="#ccc" class="mx-auto"></v-progress-circular>
        <div v-else-if="!subjects.length" class="text-center grey--text text--darken-2">
            <v-icon class="mr-3">mdi-inbox</v-icon>
            <span>{{ $t('modules.portalalumno.dashboard.subjectsTab.inProgress.noSubjects') }}</span>
        </div>
        <template v-else>
            <div class="in-progress-subjects__content-grid">
                <div class="in-progress-subjects__content-grid-headers table-sizing">
                    <div></div>
                    <div>{{ $t('modules.portalalumno.dashboard.subjectsTab.inProgress.selfAssessments') }}</div>
                    <div class="text-center">{{ $t('modules.portalalumno.dashboard.subjectsTab.inProgress.regular') }}</div>
                </div>
                <div class="in-progress-subjects__content-grid-row table-sizing" v-for="(subject, idx) in subjects" :key="`subject-${idx}`">
                    <div class="in-progress-subjects__content-grid-row__subject-info px-4">
                        <div class="in-progress-subjects__content-grid-row__subject-info__title">{{ subject.name }}</div>
                        <div class="in-progress-subjects__content-grid-row__subject-info__year">{{ subject.year }}</div>
                    </div>
                    <div class="in-progress-subjects__content-grid-row__evaluations">
                        <template v-for="(evaluation, evIdx) in subject.evaluations">
                            <div :class="['in-progress-subjects__content-grid-row__evaluations__circle', `circle-${evaluation.status}`]" :key="`evaluation-${idx}-${evIdx}`">
                                <v-icon class="icon" v-if="evaluation.isIcon">{{ evaluation.icon }}</v-icon>  
                                <span class="icon" v-else>{{ evIdx + 1 }}</span>
                            </div>
                            <div v-if="evIdx < subject.evaluations.length - 1" class="in-progress-subjects__content-grid-row__evaluations__divider" :key="`divider-${idx}-${evIdx}`">
                                <v-icon>mdi-play</v-icon>
                            </div>  
                        </template>
                    </div>
                    <div class="in-progress-subjects__content-grid-row__regularity justify-center">
                        <div :class="['in-progress-subjects__content-grid-row__evaluations__circle', `circle-${subject.regularity.status}`]">
                            <v-icon class="icon">{{ subject.regularity.icon }}</v-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="in-progress-subjects__content-references d-flex justify-space-around justify-sm-end pt-5 px-5">
                <div class="reference d-flex align-center reference-green">
                    <div class="circle d-flex justify-center mr-2">
                        <v-icon class="icon" color="white" size="9">mdi-check</v-icon>
                    </div>
                    <span class="caption" style="font-size: 10px !important">{{ $t('modules.portalalumno.dashboard.subjectsTab.inProgress.approved') }}</span>
                </div>
                <div class="reference d-flex align-center ml-3 reference-red">
                    <div class="circle d-flex justify-center mr-2">
                        <v-icon class="icon" color="white" size="9">mdi-close</v-icon>
                    </div>
                    <span class="caption" style="font-size: 10px !important">{{ $t('modules.portalalumno.dashboard.subjectsTab.inProgress.disapproved') }}</span>
                </div>
                <div class="reference d-flex align-center ml-3 reference-orange">
                    <div class="circle mr-2"></div>
                    <span class="caption" style="font-size: 10px !important">{{ $t('modules.portalalumno.dashboard.subjectsTab.inProgress.pending') }}</span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { $materias } from '../Services';
    import { mapGetters } from 'vuex';

    export default {
        name: 'InProgressSubjects',
        data() {
            return {
                loading: false,
            }
        },
        computed: {
            subjects() {
                if (this.cacheSubjects) return this.cacheSubjects.map(subject => {
                    const practicals = ['notaUno','notaDos','notaTres','notaCuatro']
                    const statusList = [
                        { isIcon: true, status: 'fail', icon: 'mdi-close' },
                        { isIcon: true, status: 'approve', icon: 'mdi-check' },
                        { isIcon: false, status: 'pending', icon: null },
                        { isIcon: true, status: 'empty', icon: 'mdi-help' }
                    ]
                    let regularity = null
                    const evaluations = practicals.map(practical => {
                        const state = statusList[subject[practical]]
                        if (state.status == 'pending') regularity = statusList[3]   // eslint-disable-line prefer-destructuring
                        else if (state.status == 'fail' && (!regularity || regularity.status != 'empty')) regularity = state
                        else if (state.status == 'approve' && !regularity) regularity = state
                        return state
                    })

                    return {
                        name: subject.descripcion,
                        year: this.$options.filters.$capitalize(subject.anio),
                        evaluations,
                        regularity,
                    }
                })
                else return []
            },
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                cacheSubjects: 'commons/getStudentInProcessSubjects',
            }),
        },
        mounted(){
            if (!this.cacheSubjects) this.getData()
        },
        methods: {
            async getData(){
                try {
                    this.loading = true;
                    const response = await $materias.find('enCurso', null, {params: { leg: this.studentRegister }})
                    await this.$store.dispatch('commons/setStudentInProgressSubjects', response.data.enCursoDTO);
                    this.$trackEvent('Home', 'Clk_AsigCurDhsh_Home', 'Dashboard')
                } catch(err) {
                    this.$store.dispatch('commons/setStudentInProgressSubjects', []);
                    throw err
                } finally {
                    this.loading = false;
                }
            }
        },
    }
</script>

<style lang="sass" scoped>

  $light-blue: #4586de
  $color-gray: #6f7f74
  $color-orange: #ed8b00

  .table-sizing
    div:nth-child(1)
      width: 51%
    div:nth-child(2)
      width: 39%
    div:nth-child(3)
      width: 10%

  .circle
    &-fail
      background: #ff5252
      .icon
        color: #fff
    &-pending
      background: $color-orange
    &-approve
      background: #3bc000
      .icon
        color: #fff
    &-empty
      border: 2px dotted $color-gray
      .icon
        color: $color-gray

  .in-progress-subjects
    &__content
        width: 100%
        min-height: 250px
        &-references
          width: 100%
          .reference
            &-red
              .circle
                background: #ff5252
            &-green
              .circle
                background: #3bc000
            &-orange
              .circle
                background: $color-orange
            .circle
              width: 15px
              height: 15px
              border-radius: 100%

        &-grid
          flex: 1
          max-height: 224px
          width: 100%
          overflow-y: scroll
          &-headers
            display: flex
            font-size: 10px
            color: $color-gray
          &-row
            display: flex
            border-bottom: 1px solid #ddd
            padding: .4em 0
            &__subject-info
              &__title
                color: var(--v-primary-base)
                font-weight: bold
                font-size: 14px
                min-width: 100%
              &__year
                margin-top: .5em
                color: $color-gray
                font-size: 10px
            &__evaluations
              display: flex
              justify-content: flex-start
              align-items: center
              div
                margin: 0 2px
              &__divider
                color: $color-gray
                font-size: 14px
                display: flex
                width: 25px !important
              &__circle
                width: 30px !important
                height: 30px
                border-radius: 100%
                display: flex
                justify-content: center
                align-items: center
                font-size: 16px
                color: #fff

                .icon
                  width: 30px !important
                  text-align: center



            &__regularity
              display: flex
              align-items: center
  @media only screen and (max-width: 600px)
    .table-sizing
      div:nth-child(1)
        width: 100%
      div:nth-child(2)
        width: 70%
      div:nth-child(3)
        width: 10%
    .in-progress-subjects
      &__content
        padding: 10px
        &-references
          .reference
            magin-left: auto
        &-grid
          &-headers
            flex-wrap: wrap
            justify-content: space-around
          &-row
            flex-wrap: wrap
            justify-content: space-around
            &__subject-info
              &__year
                margin-top: .2em
            &__evaluations
              margin: .75em 0
              div
                margin: 0 1px
            &__regularity
              margin: .75em 0

</style>
