<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <template>
        <v-container class="container-custom">
          <Breadcrumbs
            :title="$t('modules.repayment.update.breadcrumbs.title')"
            :description="$t('modules.repayment.update.breadcrumbs.description')"
          ></Breadcrumbs>
        </v-container>
      </template>
      <v-container class="container-custom px-8">
        <template v-if="!updateRepaymentStatus">
          <v-row justify="center">
            <v-col class="py-0 pl-7" sm="12">
              <OutlineCard
                :title="$t('modules.repayment.sections.infoTitle')"
                :subtitle="$t('modules.repayment.update.section_description.subtitle')"
                :switchLabel="(status) ? $t('modules.discounts.enabled') : $t('modules.discounts.disabled')"
                :switchValue="status"
                @switchChange="(val) => {updateSwitch(val); this.$v.$touch()}"
                switcher
                class="mb-6"
              >
                <v-row>
                  <v-col sm="3" class="col-sm-3 col">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('modules.repayment.update.labels.description') }}</span>
                  </v-col>
                  <v-col sm="6" class="col-sm-6 col">
                    <v-text-field
                      :error-messages="$v.description.$error ? $t('modules.repayment.inputErrors.required') : ''"
                      @blur="$v.description.$touch()"
                      v-model="$v.description.$model"
                      :label="`${$t('modules.repayment.update.labels.description')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="3" class="col-sm-3 col">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('modules.repayment.update.labels.basicPay') }}</span>
                  </v-col>
                  <v-col sm="6" class="col-sm-6 col">
                    <v-text-field
                      :error-messages="$v.basicPay.$error ? $t('modules.repayment.inputErrors.required') : ''"
                      @blur="$v.basicPay.$touch()"
                      v-model="$v.basicPay.$model"
                      :label="`${$t('modules.repayment.update.labels.basicPay')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="3" class="col-sm-3 col">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('modules.repayment.update.labels.installments') }}</span>
                  </v-col>
                  <v-col sm="6" class="col-sm-6 col">
                    <v-text-field
                      :error-messages="$v.installments.$error ? $t('modules.repayment.inputErrors.required') : ''"
                      @blur="$v.installments.$touch()"
                      v-model="$v.installments.$model"
                      :label="`${$t('modules.repayment.update.labels.installments')} *`"
                      v-mask="'##'"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="3" class="col-sm-3 col">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('modules.repayment.update.labels.discountPercentage') }}</span>
                  </v-col>
                  <v-col sm="6" class="col-sm-6 col">
                    <v-text-field
                      :error-messages="$v.discountPercentage.$error ? $t('modules.repayment.inputErrors.required') : ''"
                      @blur="$v.discountPercentage.$touch()"
                      v-model="$v.discountPercentage.$model"
                      v-mask="'##'"
                      :label="`${$t('modules.repayment.update.labels.discountPercentage')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

              </OutlineCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/repayments')"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>
                <Button
                  :loading="saveButton.loading"
                  :success="saveButton.success"
                  :error="saveButton.error"
                  :text="$t('actions.save')"
                  :successText="$t('actions.saved')"
                  :errorText="'Error'"
                  :disabled="!canUpdate"
                  @end="resetButtonValues()"
                  @clicked="validStatus"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
        <SuccessMessage
          v-else
          :type="successMessage.type"
          :title="successMessage.title"
          :actionPrimary="successMessage.actionPrimary"
        />
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { required} from 'vuelidate/lib/validators';
import { $repayments } from '../Services';

export default {
  name: 'RepaymentUpdate',
  components: {
    Breadcrumbs,
    OutlineCard,
    Button,
    SuccessMessage
  },
  data() {
    return {
      updateRepaymentStatus: false,
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {},
      status: true,
      basicPay: null,
      description: null,
      discountPercentage: null,
      installments: null,
    };
  },
  validations: {
    description: {
        required
    },
    basicPay: {
        required
    },
    installments: {
        required
    },
    discountPercentage: {},
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.repayments.UPDATE && this.$v.$anyDirty && !this.$v.$anyError;
    },
  },
  methods: {
    onBlur(vuelidateLink) {
      vuelidateLink.$touch();
    },
    updateSwitch(val) {
      this.status = val;
    },
    validStatus() {
      if (!this.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.repayment.update.alert_message.title'),
          content: '',
          actionPrimary: {
            text: this.$t(
              'modules.repayment.update.alert_message.actions.primary_text'
            ),
            callback: () => {
              this.updateRepayment();
            }
          },
          actionSecondary: {
            text: this.$t(
              'modules.repayment.update.alert_message.actions.secondary_text'
            ),
            callback() {}
          },
          icon: {
            color: 'warning',
            name: 'mdi-eye-off-outline'
          },
          color: 'primary'
        });
      } else {
        this.updateRepayment();
      }
    },
    isDisabled(prop) {
      let status = false;
      if (!prop.status) {
        prop.value = '';
        status = true;
      } else {
        status = false;
      }
      return status;
    },
    async updateRepayment() {
      if (!this.canUpdate) return false
      this.saveButton.loading = true;
      try {
        await $repayments.update(this.$route.params.id, {
        basicPay: this.basicPay,
        description: this.description,
        discountPercentage: this.discountPercentage,
        status: this.status,
        installments: this.installments
        });
        this.successMessage.type = 'success';
        this.successMessage.title = `Repactacion ${this.description} editada`;
        this.successMessage.actionPrimary = {
          text: this.$t(
            'modules.repayment.update.success_message.actions.primary_text'
          ),
          callback: () => {
            this.$router.push('/repayments');
          }
        };
      } catch (err) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t(
          'modules.repayment.update.error_message.title'
        );
        this.successMessage.actionPrimary = {
          text: this.$t(
            'modules.repayment.create.error_message.actions.go_back_text'
          ),
          callback: () => {
            this.updateRepaymentStatus = false;
          }
        };
      }
      this.updateRepaymentStatus = true;
      this.saveButton.loading = false;
      this.saveButton.success = true;
    },
    resetButtonValues() {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
    onCheckHabClick(event) {
      if (!event) this.$v.repaymentProps.$touch();
    },

    async fetchRepayment() {
        const repaymentInfo = await $repayments.findById(this.$route.params.id);
        this.basicPay = repaymentInfo.data.basicPay;
        this.description = repaymentInfo.data.description;
        this.discountPercentage = repaymentInfo.data.discountPercentage;
        this.status = repaymentInfo.data.status;
        this.installments = repaymentInfo.data.installments;
        if (repaymentInfo.data.repaymentsRelated) {
            this.repaymentsRelated.forEach(rel => {
              repaymentInfo.data.repaymentsRelated.forEach(item => {
                if (rel.id == item.parent) {
                this.selectedProducts.push(rel.id);
                }
            });
            });
        }
        if (repaymentInfo.data.repaymentProps) {
            this.repaymentProps.forEach(prop => {
            repaymentInfo.data.repaymentProps.forEach(item => {
                if (prop.id == item.billingProps.id) {
                prop.status = true;
                prop.value = item.count;
                }
            });
            });
        }
    }
  },
  created() {
    this.fetchRepayment();
  }
};
</script>
