<template>
  <div :class="['AsideBar', (close) ? 'closed' : '']">
    <v-btn @click="closeToggle" class="fab-button" color="white" elevation="0" fab>
      <v-icon>{{ (close) ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
    </v-btn>
    <div class="AsideBar__content">
      <div class="title font-weight-bold primary--text px-4 py-6">{{ title }}</div>
      <v-expansion-panels class="main-panels md-0 px-0 pt-0 pb-0" multiple>
        <v-expansion-panel :key="'expansion'+index" class="px-0 ma-0" v-for="(item, index) in items">
          <v-expansion-panel-header class="px-4 py-0">
            <span class="subtitle-2">{{ item.name }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <template v-for="(filter, i) in item.filters">
              <template v-if="filter.type === 'check' && !close">
                <div :key="'filter'+i" class="d-flex justify-space-between">
                  <div class="d-flex">
                    <v-checkbox
                      :disabled="filter.disabled"
                      :label="filter.name || filter.description"
                      @change="() => changed(index, i, filter.value)"
                      class="mt-0 ml-0 AsideBar-checkbox"
                      color="primary"
                      v-model="filter.value"
                    ></v-checkbox>
                  </div>
                  <span class="caption">{{ filter.dataFiltered }}</span>
                </div>
              </template>
              <template v-if="filter.type === 'date' && !close">
                <div :key="'filter'+i" class="d-flex justify-space-between">
                  <div class="d-flex">
                    <v-menu
                      :close-on-content-click="false"
                      :ref="`menu-${index}-${i}`"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                      :return-value.sync="filter.value"
                      v-model="filter.isCalendarOpen"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          append-icon="mdi-calendar"
                          :label="filter.name"
                          outlined
                          readonly
                          v-model="filter.value"
                          v-on="on"
                          clearable
                          @click:clear="changed(index, i, null, `menu-${index}-${i}`)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        scrollable
                        v-model="filter.value"
                        :min="filter.minDate"
                        :max="filter.maxDate"
                        @input="changed(index, i, filter.value,  `menu-${index}-${i}`)"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </template>
              <template v-if="filter.type === 'dateRange' && !close">
                <div :key="'filter'+i" class="d-flex justify-space-between">
                  <div class="d-flex">
                    <v-menu
                      :close-on-content-click="false"
                      :return-value.sync="filter.value"
                      min-width="290px"
                      offset-y
                      ref="dateFromMenu"
                      transition="scale-transition"
                      v-model="dateFromMenu"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          append-icon="mdi-calendar"
                          label="Desde"
                          outlined
                          readonly
                          v-model="filter.value"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        @input="changed(index, i, filter.value)"
                        no-title
                        range
                        scrollable
                        v-model="filter.value"
                      >
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text>Cancel</v-btn>
                        <v-btn @click="changed(index, i, filter.value)" color="primary" text>OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </template>
              <template v-if="filter.type === 'radio' && !close">
                <div :key="'filter'+i">
                  <div class="d-flex justify-space-between align-center">
                    <span class="subtitle-1">{{ filter.name }}</span>
                    <span class="caption">{{ filter.dataFiltered }}</span>
                  </div>
                  <v-radio-group
                    @change="() => changed(index, i, filter.value)"
                    class="mt-0"
                    v-model="filter.value"
                  >
                    <v-radio
                      :key="'radio'+i"
                      :label="radio.name"
                      :value="radio.value"
                      color="primary"
                      v-for="(radio, i) in filter.radios"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </template>
              <template v-if="filter.type === 'range' && !close">
                <div :key="'filter'+i">
                  <div class="d-flex justify-space-between align-center">
                    <span class="subtitle-1">{{ filter.name }}</span>
                    <span class="caption">{{ filter.dataFiltered }}</span>
                  </div>
                  <div class="d-flex flex-column text-center" style="width: 100%">
                    <v-slider
                      :max="filter.max"
                      :min="filter.min"
                      @change="() => changed(index, i, filter.value)"
                      color="primary"
                      hide-details
                      thumb-label
                      v-model="filter.value"
                    ></v-slider>
                  </div>
                </div>
              </template>
              <template v-if="filter.type === 'text' && !close">
                <div :key="'filter'+i" class="d-flex justify-space-between">
                  <div class="d-flex">
                    <v-text-field
                      :label="filter.name"
                      @input="() => changed(index, i, filter.value)"
                      class="mt-0 ml-0"
                      clearable
                      outlined
                      v-model="filter.value"
                    ></v-text-field>
                  </div>
                </div>
              </template>
              <template v-if="filter.type === 'select' && !close">
                <div :key="'filter'+i" class="d-flex justify-space-between">
                  <div class="d-flex">
                    <v-select
                      :items="filter.items"
                      :label="filter.name"
                      @change="() => changed(index, i, filter.value)"
                      class="no-message"
                      outlined
                      v-model="filter.value"
                    ></v-select>
                  </div>
                </div>
              </template>
              <template v-if="filter.type === 'autocomplete' && !close">
                <div :key="'filter'+i" class="d-flex justify-space-between">
                  <div class="d-flex">
                    <v-autocomplete
                      v-model="filter.value"
                      :items="filter.items"
                      :label="filter.name"
                      @change="() => changed(index, i, filter.value)"
                      class="no-message"
                      :clearable="filter.clearable"
                      :multiple="filter.multiple"
                      outlined
                    >
                      <template v-slot:item="data">
                        <v-list-item-action v-if="filter.multiple">
                          <v-checkbox
                            color="primary"
                            :value="data.attrs.inputValue"
                            readonly
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                          <v-list-item-subtitle v-if="data.item.description">{{ data.item.description }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
              </template>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<style scoped lang="sass">
  .AsideBar
    width: 25%
    position: relative
    transition: width 0.5s ease-in
    background: #ebebeb
    min-height: calc( 100vh - 64px )
    &__content
      height: 100%
      overflow-y: auto
      animation-fill-mode: forwards
      animation-name: smoothShow
      animation-duration: 1s
      animation-timing-function: ease-in

    &.closed
      width: 32px !important
      .AsideBar__content
        animation-fill-mode: forwards
        animation-name: smoothHide
        animation-duration: 1s
        animation-timing-function: ease-in

    .fab-button
      border: 1px solid #ddd !important
      position: absolute
      z-index: 2
      top: 0
      right: 0
      transform: translateX(50%) translateY(50%)
      width: 28px
      min-width: unset
      min-height: unset
      height: 28px
      .v-icon
        color: var(--v-primary-base)
      &:hover:before
        opacity: 0.06
      &:focus:before
        opacity: 0
      &:focus:hover:before
        opacity: 0.06

    .v-expansion
      &-panel
        min-height: 50px
        background-color: transparent !important
        &:before
          box-shadow: unset
        &:after
          border: 0
        &-header
          min-height: 50px
        & .subtitle-1
          cursor: pointer
          &-container
            margin-top: 5px !important
        & .caption
          margin-top: 7px !important

  @keyframes smoothHide
    0%
      opacity: 1
      display: block
    15%
      opacity: 0
    30%
      display: none
    100%
      display: none
      opacity: 0

  @keyframes smoothShow
    0%
      opacity: 0
      display: none
    50%
      opacity: 0
    75%
      display: block
    100%
      display: block
      opacity: 1
</style>

<script>
  export default {
    name: 'AsideBar',
    props: {
      title: String,
      items: Array
    },
    data() {
      return {
        close: false,
      }
    },
    methods: {
      changed(parent, filter, value, ref = null) {
        if (this.items[parent].filters.some(item => item.type == 'check')) {
          const filtered = this.items[parent].filters.filter(item => item.value==true);

          if(filtered.length == 1) filtered[0].disabled = true;
          else filtered.forEach(item => item.disabled = false);
        }
        this.$emit('changed', parent, filter, value);
        if (ref) this.$refs[ref][0].save(value)
      },
      closeToggle() {
        this.close = !this.close
      }
    }
  }
</script>
