<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t(`modules.portalalumno.procedures.procedureScholarship.breadcrumb.title`)"
        :description="$t(`modules.portalalumno.procedures.procedureScholarship.breadcrumb.description`)"
        isDescriptionFullLength
        :callback="goBack"
      ></Breadcrumbs>
      <v-row v-if="!createNewProcedureScholarshipStatus">
        <v-col class="viewSpaces" cols="12">
          <OutlinedCard
            title=""
            subtitle=""
            class="mb-6"
          >
            <v-row class="mb-6">
              <v-col sm="12" class="py-0">
                <v-card-text class=" body-1 pa-0 primary--text font-weight-bold">
                  {{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.title`) }}
                </v-card-text>
                <v-card-text class=" body-2 pa-0 gray--text ">
                  {{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.subtitle`) }}
                </v-card-text>
              </v-col>
              <v-col sm="3" cols="4">
                <v-autocomplete
                  ref="selectedScholarship"
                  v-model="$v.selectedScholarship.$model"
                  outlined
                  :error-messages="($v.selectedScholarship.$dirty && $v.selectedScholarship.$invalid) ?
                  $t('modules.portalalumno.procedures.procedureScholarship.validations.scholarshipRequired') : ''"
                  :label="$t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.autocompleteTipo.label`)"
                  :placeholder="$t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.autocompleteTipo.placeholder`)"
                  :loading="scholarshipsLoading"
                  :items="scholarships"
                  item-text="description"
                  return-object
                  @blur="$v.selectedScholarship.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="10">
                <p class=" body-2 pa-0 gray--text ">
                  {{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.textBeca_1`) }}</p><br>
                <p class=" body-2 pa-0 gray--text ">
                  {{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.textBeca_2`) }}</p>
              </v-col>
              <v-col cols="10">
                <v-alert class="mb-0 mt-4 text-left overflow-auto" outlined type="info">
                  {{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.formBeca.title`) }}
                  <a  class="ms-16 ps-16" :href="getDownloadLink()" target="_blank">
                    <v-icon medium color="blue" class="me-3">mdi-cloud-download-outline</v-icon>
                    {{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.formBeca.descarga`) }}
                  </a>
                </v-alert>
              </v-col>
            </v-row>
            <v-row class="mb-6">
              <v-col sm="12" class="py-0">
                <v-card-text class=" body-1 pa-0 primary--text font-weight-bold">
                  {{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.motivo.title`) }}
                </v-card-text>
                <v-card-text class=" body-2 pa-0 gray--text ">
                  {{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.motivo.subtitle`) }}
                </v-card-text>
              </v-col>
              <v-col sm="3" cols="4">
                <v-autocomplete
                  ref="selectedProcessingReason"
                  v-model="$v.selectedProcessingReason.$model"
                  outlined
                  :error-messages="($v.selectedProcessingReason.$dirty && $v.selectedProcessingReason.$invalid) ?
                  $t('modules.portalalumno.procedures.procedureScholarship.validations.processingReasonRequired') : ''"
                  :label="$t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.motivo.inputMotivo.label`)"
                  :placeholder="$t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.motivo.inputMotivo.placeholder`)"
                  :loading="processingReasonsLoading"
                  :items="processingReasons"
                  item-text="meaning"
                  return-object
                  @blur="$v.selectedProcessingReason.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="10">
                <v-textarea
                  ref="observation"
                  v-model="$v.observation.$model"
                  :error-messages="($v.observation.$dirty && $v.observation.$invalid) ?
                  $t('modules.portalalumno.procedures.procedureScholarship.validations.observationRequired') : ''"
                  :label="$t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.motivo.textarea.label`)"
                  :placeholder="$t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.motivo.textarea.placeholder`)"
                  name="input-7-4"
                  class="mt-6"
                  outlined
                  @blur="$v.observation.$touch()"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <div>
                  <h4 class="title font-weight-bold">{{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.dropfile.title`) }}</h4>
                  <h4 class="body-2">{{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.dropfile.subtitle`) }}</h4>
                </div>
              </v-col>
            </v-row>
            <v-row justify="center" style="overflow:hidden">
              <v-col cols="10">
                <DropFiles
                  ref="documentation"
                  extension=".jpg, .png, .pdf"
                  :type-file="['image/*', 'application/pdf']"
                  limitSizeBytes="25000000"
                  multiple
                  :required="$v.documentation.$anyError"
                  :value="documentation"
                  :docTypes="requiredDocuments"
                  :truncate-text-length="80"
                  @input="(files) => { this.documentation = files }"
                />
              </v-col>
            </v-row>
          </OutlinedCard>
        </v-col>
        <v-col cols="11">
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.replace('/procedures')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.create')"
              :successText="$t('actions.created')"
              :errorText="$t('actions.error')"
              :disabled="!canCreate"
              @end="resetButtonValues"
              @clicked="validateProcedureScholarship"
            ></Button>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="">
            <h5>{{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.information.title`) }}</h5>
            <Button
              class="mt-4 "
              :text="$t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.information.buttonText`)"
              @clicked="$router.push('/reglamento')"
              outlined
              size="sm"
            ></Button>
          </div>
        </v-col>
      </v-row>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :subtitle="successMessage.subtitle"
        :actionPrimary="successMessage.actionPrimary"
      />
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import DropFiles from '@/components/DropFiles/DropFiles';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {required} from 'vuelidate/lib/validators';
import {$types, $scholarships, $studentProcessing} from '../services';
import {mapGetters} from 'vuex';

export default {
  name: 'ProcedureScholarship',
  components: {
    Breadcrumbs,
    OutlinedCard,
    DropFiles,
    Button,
    SuccessMessage
  },
  data() {
    return {
      createNewProcedureScholarshipStatus: false,
      procedureId: 0,
      documentUploadIndex: 0,
      scholarships: [],
      scholarshipsLoading: false,
      selectedScholarship: null,
      processingReasons: [],
      processingReasonsLoading: false,
      selectedProcessingReason: null,
      observation: null,
      documentation: null,
      requiredDocuments: [],
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        subtitle: null,
        actionPrimary: null
      },
    };
  },
  validations: {
    selectedScholarship: {required},
    selectedProcessingReason: {required},
    observation: {required},
    documentation: {required}
  },
  computed: {
    canCreate() {
      return this.$permissions.portalalumno.procedures.SCHOLARSHIP;
    },
    ...mapGetters({
      studentRecord: 'commons/getStudentActualRegister',
      studentData: 'commons/getStudentData',
      userName: 'commons/getUserName',
      studentUserId: 'commons/getStudentUserID'
    }),
  },
  watch: {
    async selectedScholarship(scholarship) {
      try {
        const {data} = await $scholarships.findById(scholarship.id, null, {
          params: {
            docDetails: true,
            studentDetails: false
          }
        });
        this.requiredDocuments = data.scholarshipDocs.map(scholarshipDoc => ({...scholarshipDoc.documentation}));
      } catch (err) {
        this.requiredDocuments = [];
        throw err;
      }
    }
  },
  mounted() {
    this.getScholarships();
    this.getProcessingReasons();
  },
  methods: {
    async getScholarships() {
      try {
        this.scholarshipsLoading = true;
        const {data} = await $scholarships.find(null, null, { params: { page: 0, length: 500, status: true }
        });
        this.scholarships = data.content;
      } catch (err) {
        this.scholarships = [];
        throw err;
      } finally {
        this.scholarshipsLoading = false;
      }
    },
    async getProcessingReasons() {
      try {
        this.processingReasonsLoading = true;
        const {data} = await $types.find(null, null, {params: {type: 'PROCESSINGS_REASONS'}});
        this.processingReasons = data;
      } catch (err) {
        this.processingReasons = [];
        throw err;
      } finally {
        this.processingReasonsLoading = false;
      }
    },
    goBack() {
      this.$trackEvent('Tramites', 'Cancelar', 'Retiro');
      this.$router.push('/procedures');
    },
    validateProcedureScholarship() {
      this.createButton.loading = true;
      if (this.documentation.some(document => !document.docType)) {
        this.$v.$touch();
        this.$refs.documentation.$el.scrollIntoView({block: 'center', behavior: 'smooth'});
        this.$refs.documentation.triggerOuterValidation();
        this.createButton.error = true;
        this.createButton.loading = false;
      } else if (this.$v.$invalid) {
        this.$v.$touch();
        Object.keys(this.$v).some(input => {
          if (input.includes('$')) return false;
          if (this.$v[input].$error) {
            this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
            return true;
          }
        });
        this.createButton.error = true;
        this.createButton.loading = false;
      } else {
        this.createProcedureScholarship(this.documentUploadIndex);
      }
    },
    async createProcedureScholarship(index) {
      try {
        if (index != this.documentation.length) {
          const formData = new FormData();
          const newProcedureScholarship = {
            scholarship: {id: this.selectedScholarship.id},
            student: {
              id: this.studentData.student.id,
              user: {
                id: this.studentData.student.user.id,
                name: this.studentData.userData.name
              }
            },
            user: this.studentUserId ? this.studentUserId : null,
            studentRecord: this.studentRecord,
            processingsReasonType: this.selectedProcessingReason,
            commentary: this.observation,
            processingId: this.procedureId,
            userName: this.userName,
          };
          formData.append('model', JSON.stringify(newProcedureScholarship));
          formData.append('documentation', this.documentation[index].file);
          formData.append('idDocumentation', this.documentation[index].docType.id);
          formData.append('partial', '1');
          const response = await $studentProcessing.formData(formData, 'uploadDocumentation');
          if (response.ok) {
            if (!this.procedureId) {
              this.procedureId = (await response.json()).processingId;
            }
            this.createProcedureScholarship(++this.documentUploadIndex);
          } else {
            throw 'Error'
          }
        } else {
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.portalalumno.procedures.procedureScholarship.successMessage.title');
          this.successMessage.subtitle = this.$t('modules.portalalumno.procedures.procedureScholarship.successMessage.subtitle');
          this.successMessage.actionPrimary = {
            text: this.$t('actions.close'), callback: () => {
              this.$router.push('/procedures');
            }
          };
          this.createNewProcedureScholarshipStatus = true;
          this.createButton.success = true;
          this.createButton.loading = false;
        }
      } catch (err) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.portalalumno.procedures.procedureScholarship.errorMessage.uploadDocument');
        this.successMessage.subtitle = null;
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.createNewProcedureScholarshipStatus = false;
          }
        };
        this.createNewProcedureScholarshipStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        throw err;
      }
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    getDownloadLink() {
      const country = this.$country.charAt(0).toUpperCase() + this.$country.slice(1)
      return require(`@/assets/documents/BecaInclusion${country}.pdf`);
  }
  },
};
</script>

<style lang="scss" scoped>

</style>
