// import api from '@/api';

// const { $academic } = api;
// export const { $countGradeBooks, $studentCareerDetails, $getInvoicesToDownload, $getStudentsByBookAndCareer, $getStudentSheet, $updateObservation} = $academic;

import api from '@/api';

const { $report } = api;
const { $academic } = $report;
const $st = api.$academic

export const { $countGradeBooks, $studentCareerDetails, $getInvoicesToDownload, $getStudentsByBookAndCareer, $getStudentSheet, $updateObservation} = $st;
export const $reports = {academic: $academic }
