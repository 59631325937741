

import { $careers } from '../Services';
import { required, numeric } from 'vuelidate/lib/validators';
export default {
  data(){return {
    tmpDrawer: {
      open: false,
      title: this.$t('modules.careers.study_plan.drawer.create.title'),
      description: this.$t('modules.careers.study_plan.drawer.create.description')
    },
    headers: [
      { text: this.$t('modules.careers.create.table.headers.status'), value: 'status' },
      { text: this.$t('modules.careers.create.table.headers.name'), value: 'studyPlan.description', align: 'center' },
      { text: this.$t('modules.careers.create.table.headers.quarter'), value: 'quarterCount', align: 'center' },
      { text: this.$t('modules.careers.create.table.headers.subjects'), value: 'subjectCount', align: 'center' },
      { text: this.$t('modules.careers.create.table.headers.studyPlanHours'), value: 'studyPlanHours', align: 'center' },
      { text: this.$t('modules.careers.create.table.headers.duplicateCarousel'), value: 'studyPlan.duplicateCarousel', align: 'center' },
      { text: '', value: 'action' }
    ],
    successMessage: {
      title: '¡Precios creados con éxito!',
      actionPrimary: { text: 'Aceptar', callback: () => {this.$router.push('/prices')} }
    },
    checkingCode: false,
  }},
  computed:{
    canCreate() {
      return this.$permissions.portaladministrativo.academic.careers.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.careers.UPDATE
    },
    canCreateCareer() {
      // return this.$v.formValid.anyDirty && this.$v.formValid.anyError
      return this.canCreate && !this.$v.formValid.$invalid
    },
    changeStatuslabel() {
      return this.newCareers.status
        ? this.$t('modules.careers.create.sectionData.labelEnable')
        : this.$t('modules.careers.create.sectionData.labelDisable');
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.newCareers.description.$anyError) return errors;

      if (!this.$v.newCareers.description.required) errors.push(this.$t('modules.careers.create.validations.descriptionRequired'));
      return errors;
    },
    careerTypeErrors() {
      const errors = [];
      if (!this.$v.newCareers.careerType.$anyError) return errors;

      if (!this.$v.newCareers.careerType.required) errors.push(this.$t('modules.careers.create.validations.careerTypeRequired'));
      return errors;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.newCareers.code.$anyError) return errors;
      if (!this.$v.newCareers.code.required) errors.push(this.$t('modules.careers.create.validations.codeRequired'));

      if (!this.$v.newCareers.code.checkIfExists && this.$v.newCareers.code.$model.length >= 3) errors.push(this.$t('modules.careers.create.validations.codeExisting'));
      return errors;
    },
    legalDescriptionErrors() {
      const errors = [];
      if (!this.$v.newCareers.legalDescription.$anyError) return errors;

      if (!this.$v.newCareers.legalDescription.required) errors.push(this.$t('modules.careers.create.validations.legalDescriptionRequired'));
      return errors;
    },

    bookErrors() {
      const errors = [];
      if (!this.$v.newCareers.book.book.$anyError) return errors;

      if (!this.$v.newCareers.book.book.required) errors.push(this.$t('modules.careers.create.validations.bookRequired'));
      return errors;
    },
    invoiceErrors() {
      const errors = [];
      if (!this.$v.newCareers.book.invoice.$anyError) return errors;

      if (!this.$v.newCareers.book.invoice.required) errors.push(this.$t('modules.careers.create.validations.invoiceRequired'));
      return errors;
    },
    creditsErrors() {
      const errors = [];
      if (!this.$v.newCareers.credits.$anyError) return errors;

      if (!this.$v.newCareers.credits.required) errors.push(this.$t('modules.careers.create.validations.creditsRequired'));
      return errors;
    },
  },
  validations: {
    newCareers: {
      legalDescription: {required},
      fistYearCertified: {},
      status: {required},
      description: {required},
      code: {
        required,
        async checkIfExists(value){
          try{
            if(this.originalCode === value) return true;
            this.checkingCode = true;
            const res = await $careers.checkIfExists(value);
            return !res.data.exists
          } catch {
            return false;
          } finally {
            this.checkingCode = false;
          }
        }
      },
      credits: {required},
      careerType: {required},
      book: {
        book: {numeric},
        invoice: {numeric}
      }
    },
    studyPlansTmp: {required},
    formValid: ['newCareers', 'studyPlansTmp']
  },

}