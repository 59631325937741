<template>
    <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
        <v-col v-for="subjectsYear, index in items" :key="index" sm="12">
                <v-row no-gutters class="pt-4">
                    <v-col class="d-flex justify-center align-center" col="6">
                        <span class="d-flex justify-left black--text subtitle-2">{{ $t('modules.academic.gradebook.grade') }}</span>
                    </v-col>              
                    <v-col class="d-flex justify-center align-center" col="6">
                        <span class="d-flex justify-left black--text subtitle-2">{{ $t('modules.academic.gradebook.paragraph10') }}</span>
                    </v-col>
                </v-row>
                <v-row no-gutters class="pb-4">
                    <v-col class="d-flex justify-center align-center" col="6">
                        <h6 class="d-flex justify-left primary--text title-2" style="font-size: 20px">{{states[index]}}</h6>
                    </v-col>              
                    <v-col class="d-flex justify-center align-center" col="6">
                        <h6 class="d-flex justify-left primary--text title-2" style="font-size: 20px">{{getAverage(index)}}</h6>
                    </v-col>
                </v-row>
            <v-sheet color="primary" height="50" class="d-flex align-center justify-center">
              <h2 class="white--text">{{$t(`modules.academic.gradebook.numberYear.${index + 1}`)}} {{$t(`modules.academic.gradebook.year`)}}</h2>
            </v-sheet>
            <v-data-table
                :headers="headers"
                :items="getSubjects(subjectsYear.materias)"
                hide-default-footer
                item-key="id"
                class="superTable__table"
                style="table-layout: fixed"
            >
        </v-data-table>
        <v-textarea
              v-model="subjectsYear.comentario"
              outlined
              name="input-7-4"
              :disabled="!addObservation"
              :label="$t('modules.academic.gradebook.observations')"
              ></v-textarea>
            <Button
              :text="$t('actions.edit')"
              class="d-flex justify-star py-4 pl-4"
              @clicked="addObservation = true"
              v-if="!addObservation"
            />
            <div class="d-flex justify-star align-center" v-else>
              <Button
                :text="$t('actions.cancel')"
                class="d-flex justify-star py-4 pl-4"
                white
                @clicked="addObservation = false"
              />
              <Button
                primary
                :text="$t('actions.save')"
                class="d-flex justify-end ml-3 py-4 pl-4"
                @clicked="updateObservacion(index + 1)"
              />
            </div>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Button from '@/components/Button/Button';
import {$updateObservation} from '../Services';

export default {
    name: 'StudentTable',
    components:{
      Button,
    },
    props: {
    headers: Array,
    items: Array,
    states: Array,
    studentCareerId: String,
    student: Object,
  },
  data () {
    return {
      addObservation: false,
      observations: Array(this.items.length).fill(''),
    }
  },
  methods: {
    async updateObservacion(index){
      try {
        const params = {
          id: this.studentCareerId,
          index 
        }
        const res = await $updateObservation.update(null, this.items[index - 1].comentario, {params});
        this.addObservation = false
        return res;
      }catch(err){
        return err;
      }
    },
    getAverage(index) {
      let average = 0;
      let count = 0;
      this.items[index].materias?.forEach((element) => {
              if(element.examFinales){
                average += element.examFinales?.notaDefinitiva && element.examFinales?.notaDefinitiva != '-' ? element.examFinales?.notaDefinitiva : 0 ;
                count ++;
              }
      });
      return (count == 0 ? 0 : average / count).toFixed(1)
    },
    getSubjects(subjects){
      const elements = [];
      subjects.forEach((element) => {
        element.examFinales.notaDefinitiva = element.examFinales.notaDefinitiva ? element.examFinales.notaDefinitiva : '-';
        elements.push(element)
      })
      return elements;
    }
  },
}
</script>