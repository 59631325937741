<template>
    <v-text-field
        v-model="inpValue"
        :value="inpValue"
        @input="updateValue"
        outlined
        :loading="loading"
        type="text"
        v-mask="mask"
        autocomplete="off"
        :disabled="disabled"
        @keyup="keyup"
        @blur="blur"
        :label="label"
        :error-messages="(!validRut && dirty && value != '' && isRUT && this.idType == 'RUT Provisorio') ? $t('components.identificationInput.invalid_provisional_rut') :
        (!validRut && dirty && value != '' && isRUT && this.idType == 'Pasaporte') ? $t('components.identificationInput.invalid_Passport') :
        (!validRut && dirty && value != '' && isRUT && this.idType == 'RUT') ? $t('components.identificationInput.invalid_rut') : errorMessages"
    ></v-text-field>
</template>
<script>
    export default {
        name: 'IdentificationInput',
        props: {
            value: String,
            label: String,
            errorMessages: String,
            loading: Boolean,
            idType: { type: String, default: 'RUT'},
            disabled: { type: Boolean, default: false },
        },
        data() {
            return {
                timeout: null,
                dirty: false,
                validRut: true,
                inpValue: this.value
            }
        },
        methods: {
            validateInput(idType) {
                let isValid = false;
                const regex = /^(?!0{5,8})(\d{1,8})-[\dK]$/;
                const regex2 = /^(?!0{5,9})(\d{1,9})-[\dK]$/;
                const regex3 = /^[a-zA-Z1-9]{1}[a-zA-Z0-9]{1,20}$/;

                switch(idType) {
                    case 'RUT':
                        isValid = regex.test(this.value);
                        break;
                    case 'RUT Provisorio':
                        isValid = regex2.test(this.value);
                        break;
                    default: 
                        isValid = regex3.test(this.value);
                }
                return isValid;
            },
            blur(event) {
                this.validateRut(event.target.value)
                this.$emit('blur')
            },
            updateValue(value) {
                this.$emit('input', value)
            },
            keyup(event) {
                this.validateRut(event.target.value)
            },
            validateRut(rut, dirty = true) {
                if (rut == null || rut == undefined || rut == '') return false

                if (this.isRUT) {
                    if(this.idType == 'RUT' || this.idType == 'RUT Provisorio') {
                        let valor = rut.replace('.', '')
                        valor = valor.replace('-', '')
                        const cuerpo = valor.slice(0, -1)
                        const dv = valor.slice(-1).toUpperCase()

                        let suma = 0
                        let multiplo = 2

                        for (let i = cuerpo.length - 1; i >= 0; i--) {
                            suma += (multiplo * cuerpo.charAt(i));
                            (multiplo < 7) ? multiplo++ : multiplo = 2
                        }

                        let dvEsperado = 11 - (suma % 11)

                        if (dvEsperado == 10) dvEsperado = 'K'
                        else if (dvEsperado == 11) dvEsperado = 0

                        if(dvEsperado == dv && this.validateInput(this.idType) == true) {
                            this.validRut = true;
                        } else {
                            this.validRut = false;
                        }
                    } else {
                        this.validRut = this.validateInput(this.idType);
                    }
                } else {
                    this.validRut = true
                }

                if (dirty) this.dirty = true
                this.$emit('validateRut', this.validRut)
                this.initTimer()
            },
            initTimer() {
                this.$emit('preTimer')
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    if (this.validRut) this.$emit('posTimer')
                    clearTimeout(this.timeout)
                }, 1000)
            },
        },
        computed: {
            mask() {
            const idMask = {
            'argentina' : {
                mask: '###########'
            },
            'chile' : {
                mask: ['#####-X','######-X','#######-X', '########-X']
            },
            'peru' : {
                mask: '###########'
            },
            'mexico' : {
                mask: {
                    mask: 'XXXXXXXXXXXXXXXXXX',
                    tokens: { 'X': { pattern: /[a-zA-Z\u00F1\u00D10-9]/ }, '#': { pattern: /[0-9]/ } },
                }
                
            },
            }
            return idMask[this.$country].mask
            },
            isRUT(){
                return this.$isIPP ? true : false
            },
        },
        watch: {
            idType() {
                this.validateRut(this.value, false);
                this.updateValue();
            }
        }
    }
</script>