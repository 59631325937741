import Regulation from '../Regulation';
import RegulationTable from '../Views/RegulationTable';
import RegulationCardsView from '../Views/RelulationCardsView';

const RegulationRoute =
{
  path: '/messaging/regulation',
  meta: {
    name: {
      en: 'regulation',
      es: 'regulation',
      }
    },
  component: Regulation,
  children: [
    {
      path: '',
      component: RegulationCardsView,
      //meta: { name: 'Index', group: 'portaladministrativo', path: 'regulation', permission: 'READ' }
      meta: { name: 'RegulationCardsView', group: 'portaladministrativo', path: 'regulation'}
    },
    {
      path: ':regulation',
      component: RegulationTable,
      //meta: { name: 'Index', group: 'portaladministrativo', path: 'regulation', permission: 'READ' }
      meta: { name: 'RegulationTable', group: 'portaladministrativo', path: 'regulation'}
    }
  ]
};

export default RegulationRoute
