import Repayment from '../Repayments'
import RepaymentCreate from '../views/RepaymentCreate';
import RepaymentTable from '../views/RepaymentTable';
import RepaymentUpdate from '../views/RepaymentUpdate';

const RepaymentRoute =
    {
        path: '/repayments',
        meta: {
            name: {
                en: 'Repayments',
                es: 'Repactacíon',
            }
        },
        component: Repayment,
        children: [
            {
                path: '/',
                component: RepaymentTable,
                meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'repayments', permission: 'READ' }
            }, {
                path: 'create',
                component: RepaymentCreate,
                meta: {name: 'RepaymentCreate', group: 'portaladministrativo.prices', path: 'repayments', permission: 'CREATE'}
            }, {
                path: 'update/:id',
                component: RepaymentUpdate,
                meta: {name: 'RepaymentUpdate', group: 'portaladministrativo.prices', path: 'repayments', permission: 'READ'}
            },
        ]
    };

export default RepaymentRoute
