<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.portalalumno.procedures.procedureHistory.title')"
        :description="$t('modules.portalalumno.procedures.procedureHistory.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" cols="12">
          <SimpleTable
            :headers="headers"
            :items="procedures"
            :pageCount="pagination.pagesAmount"
            :page="pagination.currentPage"
            :totalItems="procedures.length"
            :status="status"
            :items-per-page="pagination.itemsPerPage"
            @clickRow="clickRow"
            @lengthPaginationChange="changePagesAmount"
          />
        </v-col>
      </v-row>
    </v-container>
    <TemporaryRightDrawer
      @tmpDrawerOpen="openDrawer"
      :open="procedureDetailDrawer.open"
      :title="procedureDetailDrawer.title"
      :description="procedureDetailDrawer.description"
      :width="75"
    >
      <ProcedureHistoryDetails :procedureId="procedureDetailDrawer.id"></ProcedureHistoryDetails>
    </TemporaryRightDrawer>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
  import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
  import ProcedureHistoryDetails from './ProcedureHistoryDetails'
  import SimpleTable from '../Components/SimpleTable/SimpleTable';
  import moment from 'moment'
  import { $studentProcessing } from '../services';
  import { mapGetters } from 'vuex'

export default {
  name: 'ProcedureHistory',
  components: {
    Breadcrumbs,
    SimpleTable,
    TemporaryRightDrawer,
    ProcedureHistoryDetails
  },
  data () {
    return {
      pagination: {
        limit: 20,
        page: 1,
        currentPage: 1,
        itemsPerPage: 20,
        pagesAmount: 1,
      },
      headers: [
        { value: 'id', text: this.$t('modules.portalalumno.procedures.procedureHistory.table.headers.number'), align: 'center' },
        { value: 'title', text: this.$t('modules.portalalumno.procedures.procedureHistory.table.headers.name') },
        { value: 'date', text: this.$t('modules.portalalumno.procedures.procedureHistory.table.headers.date'), align: 'center' },
        { value: 'status', text: this.$t('modules.portalalumno.procedures.procedureHistory.table.headers.status'), align: 'center' }
      ],
      status: [
        { value: 'success', text: this.$t('modules.portalalumno.procedures.procedureHistory.table.status.aproved') },
        { value: 'orange', text: this.$t('modules.portalalumno.procedures.procedureHistory.table.status.observed') },
        { value: 'error', text: this.$t('modules.portalalumno.procedures.procedureHistory.table.status.rejected') },
        { value: '#419bf9', text: this.$t('modules.portalalumno.procedures.procedureHistory.table.status.init') }
      ],
      procedures: [],
      procedureDetailDrawer: {
        open: false,
        title: '',
        description: '',
        id: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      studentId: 'commons/getStudentId',
    }),
  },
  mounted(){
    this.fetchProcedures();
  },
  methods: {
    openDrawer() {
      this.procedureDetailDrawer.open = !this.procedureDetailDrawer.open
      if (!this.procedureDetailDrawer.open) this.procedureDetailDrawer.id = null
    },
    changePagesAmount(value){
      this.pagination.itemsPerPage = value;
      this.pagination.pagesAmount = Math.ceil(this.procedures.length / value)
    },
    async fetchProcedures () {
      const response = await $studentProcessing.find(null, null, {params: { idStudent: this.studentId, page: 0, length: 100 }})

      response.data.content.forEach(procedure => {
        if (procedure.processingCategory.id !== 3){
          this.procedures.push({
            name: procedure.processingCategory.name,
            processingCategory: procedure.processingCategory.id,
            id: procedure.id,
            title: procedure.description,
            date: moment(procedure.created, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            status: procedure.processingStatus.meaning === this.$t('modules.portalalumno.procedures.procedureHistory.processingStatus.pendiente') ? this.$t('modules.portalalumno.procedures.procedureHistory.processingStatus.iniciado')  : procedure.processingStatus.meaning
          });
        }
      });
    },
    clickRow($event) {
      const item = $event
      if(item.processingCategory === 11 && item.status === 'Denegado') {
            this.$router.push({name: 'DocumentationUpload', params: {dataDocumentation: item }})
          }else{
            this.procedureDetailDrawer.title = item.title
            this.procedureDetailDrawer.description = `Tramite № ${item.id} - ${item.description}`
            this.procedureDetailDrawer.open = true
            this.procedureDetailDrawer.id = item.id
          }
    },
  }
}
</script>