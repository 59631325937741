<template>
    <v-container fluid class="superTable pa-0">
      <v-row no-gutters class="justify-space-between">
        <v-col sm="4" class="mt-4 mb-2 d-flex">
          <v-text-field ref="keyword" prepend-inner-icon="mdi-magnify" :placeholder="$t('actions.search')" outlined
            @click="opened = []" @click:clear="clear" clearable class="superTable__search"></v-text-field>
        </v-col>
        <v-col sm="4" class="mt-4 mb-2">
          <Button @clicked="search" class="ml-4" outlined depressed :text="$t('actions.search')"></Button>
        </v-col>
        <v-col sm="4" class="mt-4 mb-2">
          <div class="d-flex justify-end">
            <Button class="ml-4" @clicked="createProgram" depressed
              :text="$t('modules.exams_group.statements.subjectsTable.newProgram')"></Button>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-14 mb-15">
        <v-col sm="12">
          <v-data-table :headers="headers" :items="sections" :page.sync="page" :itemsPerPage.sync="itemsPerPage"
            :search="keyword" hide-default-footer hide-default-header :show-select="false">
            <template #body="{ items }">
              <v-expansion-panels multiple :value="opened" flat v-if="items?.length ?? false">
                <v-expansion-panel v-for="(item, index) in items" :key="index" readonly>
                  <v-expansion-panel-header hide-actions @click="openPanel(index)">
                    <template #default="{ open }">
                      <v-row no-gutters justify="end" class="subtitle-2 font-weight-regular color-secondary">
                        <v-col cols="auto" align-self="center">
                          <v-icon color="gray">
                            {{ open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                          </v-icon>
                        </v-col>
                        <v-spacer />
                        <v-col cols="2" align-self="center">
                          {{ $t('modules.exams_group.statements.subjectsTable.state') }}:
                          <v-icon :color="item.status ? '#44B448' : '#ED8B00'" size="9">
                            mdi-circle
                          </v-icon>
                        </v-col>
                        <v-col cols="3" align-self="center">
                          {{ $t('modules.exams_group.statements.subjectsTable.code') }}:
                          <span class="subtitle-2 font-weight-bold color-primary">{{ item.code }}</span>
                        </v-col>
                        <v-col cols="3" align-self="center">
                          {{ $t('modules.exams_group.statements.subjectsTable.name') }}: <span
                            class="subtitle-2 font-weight-bold color-primary">
                            {{ item.description }}
                          </span>
                        </v-col>
                        <v-col cols="3" align-self="center">
                          {{ $t('modules.exams_group.statements.subjectsTable.updated') }}:
                          <span class="subtitle-2 font-weight-bold color-primary">{{ item.updatedAt }}</span>
                        </v-col>
                        <v-col cols="auto" align-self="center" class="d-flex flex-row-reverse">
                          <v-menu offset-y>
                            <template #activator="{ on }">
                              <v-icon color="gray" v-on="on">
                                mdi-dots-vertical
                              </v-icon>
                            </template>
                            <v-list>
                              <v-list-item @click="updateProgram(item.id, item.code, item.description, item.status)">
                                <v-list-item-title>
                                  <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="uploadStatement(item.id)">
                                <v-list-item-title>
                                  <v-icon>mdi-upload</v-icon> {{ $t('modules.exams_group.statements.actions.statements')
                                  }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-divider v-if="opened.includes(index)" />
                  <Transition>
                    <v-expansion-panel-content>
                      <v-row justify="space-around" dense>
                        <v-col cols="5">
                          <h3 class="mb-2 subtitle-1 font-weight-medium color-primary">
                            {{ $t('modules.exams_group.statements.subjectsTable.questionsFinal.title') }}
                          </h3>
                          <v-simple-table dense>
                            <template #default>
                              <thead>
                                <tr class="text-center color-secondary">
                                  <th class="subtitle-2 font-weight-regular">
                                    <span class="color-primary">
                                      {{ $t('modules.exams_group.statements.subjectsTable.questions.level') }}
                                    </span>
                                  </th>
                                  <th class="subtitle-2 font-weight-regular">
                                    {{ $t('modules.exams_group.statements.subjectsTable.questions.theoretical') }}
                                  </th>
                                  <th class="subtitle-2 font-weight-regular">
                                    {{ $t('modules.exams_group.statements.subjectsTable.questions.practice') }}
                                  </th>
                                  <th class="subtitle-2 font-weight-regular">
                                    {{ $t('modules.exams_group.statements.subjectsTable.questions.total') }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="text-center font-weight-medium color-secondary">
                                <tr>
                                  <td class="color-primary">1</td>
                                  <td>{{ item.countStatementsFinal?.difficultyOne?.theoretical }}</td>
                                  <td>{{ item.countStatementsFinal?.difficultyOne?.practical }}</td>
                                  <td>{{ item.countStatementsFinal?.difficultyOne?.total }}</td>
                                </tr>
                                <tr>
                                  <td class="color-primary">3</td>
                                  <td>{{ item.countStatementsFinal?.difficultyThree?.theoretical }}</td>
                                  <td>{{ item.countStatementsFinal?.difficultyThree?.practical }}</td>
                                  <td>{{ item.countStatementsFinal?.difficultyThree?.total }}</td>
                                </tr>
                                <tr>
                                  <td class="color-primary">5</td>
                                  <td>{{ item.countStatementsFinal?.difficultyFive?.theoretical }}</td>
                                  <td>{{ item.countStatementsFinal?.difficultyFive?.practical }}</td>
                                  <td>{{ item.countStatementsFinal?.difficultyFive?.total }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                        <v-col cols="5">
                          <h3 class="mb-2 subtitle-1 font-weight-medium color-primary">
                            {{ $t('modules.exams_group.statements.subjectsTable.questionsPractice.title') }}
                          </h3>
                          <v-simple-table dense>
                            <template #default>
                              <thead>
                                <tr class="text-left color-secondary">
                                  <th class="subtitle-2 font-weight-regular">
                                    <span class="color-primary">
                                      {{ $t('modules.exams_group.statements.subjectsTable.questions.level') }}
                                    </span>
                                  </th>
                                  <th class="subtitle-2 font-weight-regular">
                                    {{ $t('modules.exams_group.statements.subjectsTable.questions.theoretical') }}
                                  </th>
                                  <th class="subtitle-2 font-weight-regular">
                                    {{ $t('modules.exams_group.statements.subjectsTable.questions.practice') }}
                                  </th>
                                  <th class="subtitle-2 font-weight-regular">
                                    {{ $t('modules.exams_group.statements.subjectsTable.questions.total') }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="text-center font-weight-medium color-secondary">
                                <tr>
                                  <td class="color-primary">1</td>
                                  <td>{{ item.countStatementsPractice?.difficultyOne?.theoretical }}</td>
                                  <td>{{ item.countStatementsPractice?.difficultyOne?.practical }}</td>
                                  <td>{{ item.countStatementsPractice?.difficultyOne?.total }}</td>
                                </tr>
                                <tr>
                                  <td class="color-primary">3</td>
                                  <td>{{ item.countStatementsPractice?.difficultyThree?.theoretical }}</td>
                                  <td>{{ item.countStatementsPractice?.difficultyThree?.practical }}</td>
                                  <td>{{ item.countStatementsPractice?.difficultyThree?.total }}</td>
                                </tr>
                                <tr>
                                  <td class="color-primary">5</td>
                                  <td>{{ item.countStatementsPractice?.difficultyFive?.theoretical }}</td>
                                  <td>{{ item.countStatementsPractice?.difficultyFive?.practical }}</td>
                                  <td>{{ item.countStatementsPractice?.difficultyFive?.total }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </Transition>
                  <!-- <div class="d-flex justify-end align-center ">
                    <v-icon color="#ED8B00" size="16">
                      mdi-alert
                    </v-icon><span class="ml-1 body-2 color-secondary">{{
                      $t('modules.exams_group.statements.subjectsTable.alert') }}</span>
                  </div> -->
                </v-expansion-panel>
              </v-expansion-panels>
              <template v-else>
                <v-divider />
                <span class="d-flex justify-center subtitle-1 color-secondary mt-4 mb-8">{{
                  $t('modules.exams_group.statements.subjectsTable.search.notResult') }}</span>
                <v-divider />
              </template>
            </template>
  
          </v-data-table>
        </v-col>
      </v-row>
      <v-row no-gutters align="center">
        <v-col sm="3">
          <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' +
            itemsCount.totalItems }}</span>
          <v-select v-model="itemsPerPage" :items="itemsPerPageAvailable" :placeholder="itemsPerPage.toString()" outlined
            class="superTable__paginationSelect d-inline-block ml-4"></v-select>
        </v-col>
        <v-col sm="6" class="text-center">
          <v-pagination v-model="page" :length="totalPages" class="superTable__pagination" />
        </v-col>
        <v-col sm="3" align="right">
          <v-text-field :value="page" @input="setPage" outlined class="superTable__paginationInput d-inline-block mr-4" />
          <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import Button from '@/components/Button/Button'
  
  export default {
    name: 'SuperTable',
    components: {
      Button
    },
    props: {
      sections: Array,
      headers: Array,
      totalElements: Number,
    },
    data() {
      return {
        selectedItems: [],
        keyword: '',
        page: 1,
        itemsPerPage: 20,
        itemsPerPageAvailable: [20, 50, 100, 200],
        opened: [],
      }
    },
    methods: {
      openPanel(index) {
        if (this.opened.includes(index)) {
          this.opened = this.opened.filter(element => element !== index)
        } else {
          this.opened.push(index)
        }
      },
      clear() {
        this.keyword = ''
        this.opened = []
      },
      search() {
        this.keyword = this.$refs.keyword.$refs.input.value
      },
      createProgram() {
        this.$emit('createProgram')
      },
      updateProgram(id, code, description, status) {
        this.$emit('updateProgram', id, code, description, status);
      },
      uploadStatement(id) {
        this.$emit('uploadStatement', id);
      },
      setPage(value) {
        if (!value) return
        const page = parseInt(value)
        if (this.isInRange(page)) this.page = page
      },
      isInRange(page) {
        return page >= 1 && page <= this.totalPages
      }
    },
    computed: {
      totalPages() {
        const totalPages = Math.ceil(this.totalElements / this.itemsPerPage);
        return totalPages > 1 ? totalPages : 1
      },
      itemsCount() {
        const counter = {
          totalItems: this.totalElements,
          startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
          endItem: this.page * this.itemsPerPage
        };
  
        if (counter.endItem > this.totalElements) {
          counter.endItem = this.totalElements
        }
  
        return counter
      }
    }
  };
  </script>
  <style scoped>
  .color-primary {
    color: #00236f;
  }
  
  .color-secondary {
    color: #727272;
  }
  
  :deep(.v-expansion-panels) {
    gap: 24px;
  }
  
  :deep(.v-expansion-panels:not(.v-expansion-panels--tile) > .v-expansion-panel--next-active .v-expansion-panel-header:not(.v-expansion-panel-header--active)) {
    border-radius: 8px;
  }
  
  :deep(.v-expansion-panels:not(.v-expansion-panels--tile) > .v-expansion-panel--next-active .v-expansion-panel-header, .v-expansion-panel-header--active) {
    border-radius: 8px 8px 0 0
  }
  
  
  :deep(.v-expansion-panel-header:not(.v-expansion-panel-header--active)) {
    border: solid 1px #e5e5e5;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  :deep(.v-expansion-panel-header:is(.v-expansion-panel-header--active)) {
    border-top: solid 1px #e5e5e5;
    border-left: solid 1px #e5e5e5;
    border-right: solid 1px #e5e5e5;
    border-radius: 8px 8px 0 0;
  }
  
  :deep(.v-expansion-panel-header:hover:not(.v-expansion-panel-header--active)) {
    box-shadow: 0px 5px 8px #0000000d;
  }
  
  :deep(.v-expansion-panel-content) {
    padding-top: 32px;
    box-shadow: 0px 5px 8px #0000000d;
    border-bottom: solid 1px #e5e5e5;
    border-left: solid 1px #e5e5e5;
    border-right: solid 1px #e5e5e5;
    border-radius: 0 0 8px 8px;
    margin-bottom: 10px;
  }
  
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.3s ease;
  }
  
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  </style>
  