<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0" v-if="!syncCoursesStatus">
            <v-container class="container-custom px-6">
                <Breadcrumbs :description="$t('modules.canvas.syncCourses.breadcrumbs.description')"
                            :title="$t('modules.canvas.syncCourses.breadcrumbs.title')">
                </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            :isExpand="false"
                            :headers="headers"
                            :data="course"
                            :periods="periods"
                            :isPagination="false"
                            :isSearching="true"
                            :isActions="true"
                            :isSelectPeriod="true"
                            :synchronizationCourse="synchronizationCourse"
                            @searchAction="search"
                            @syncUpCoursePopUp="(id)=>syncUpCoursePopUp(id)"
                            @syncCoursesSections="syncCoursesSections"
                            ></SuperTable>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import SuperTable from '../Components/SuperTable';
import { $terms, $subjects, $canva, $courses } from '../Services';

export default {
    name: 'SyncCoursesView',
    components: {
        Breadcrumbs,
        SuperTable,
        SuccessMessage
    },
    data () {
        return {
            syncCoursesStatus:false,
            synchronizationCourse: false,
            course: [],
            periods:[],
            headers: [
                {
                    text: this.$t('modules.canvas.syncCourses.subject.table.headers.code'),
                    value: 'code',
                },
                {
                    text: this.$t('modules.canvas.syncCourses.subject.table.headers.name'),
                    value: 'description',
                },
                {
                    text: this.$t('modules.canvas.syncCourses.subject.table.headers.termCode'),
                    value: 'term',
                    sortable: false
                },
                {
                    text: this.$t('modules.canvas.syncCourses.subject.table.headers.modality'),
                    value: 'isOldModality',
                    sortable: false,
                    align: 'center'
                },
                {
                    text: this.$t('modules.canvas.syncCourses.subject.table.headers.synchronization'),
                    value: 'synchronizationCourse',
                    align: 'center',
                    sortable: false
                },{
                    text: this.$t('modules.canvas.syncCourses.subject.table.headers.edit'),
                    value: 'action',
                    align: 'center',
                    sortable: false
                } 
            ],
            successMessage:{},
        }
    },
    async mounted(){
        await this.getPeriodsData()
    },
    methods: {
        async getPeriodsData() {
            try {
                const params = {page: 0, length: 20, orderBy: 'classStartDate', orientation: 'desc'}
                const periodsData = await $terms.find(null,null,{params});
                const cursado = [{ divider: true } , { header: 'Cursado' }, ...periodsData.data.content?.filter(e => e.termType.meaning == 'Cursado')]
                const onBording = [{ divider: true } , { header: 'On Boarding' }, ...periodsData.data.content?.filter(e => e.termType.meaning == 'On Boarding')]
                const nivelacion = [{ divider: true } , { header: 'Nivelacion'},...periodsData.data.content?.filter(e => e.termType.meaning == 'Nivelacion')]
                const practica = [{ divider: true } , { header: 'Prácticas Profesionales'},...periodsData.data.content?.filter(e => e.termType.meaning == 'Practicas Profesionales')]
                this.periods = [ ...cursado, ...onBording, ...nivelacion, ...practica]
            } catch (error) {
                this.periods = []
                throw error
            }
        },
        syncUpCoursePopUp(id){
            const course = this.course.find(course => course.subjectTermId == id)
            this.$store.dispatch('commons/openPopUp', {
                title: this.$t('modules.canvas.syncCourses.message.syncUpCouse'),
                content: [
                    {
                        value: course.description
                    }
                ],
                actionPrimary: {
                    text: this.$t('modules.canvas.syncCourses.message.syncAccept'),
                    
                    
                    callback: () => this.syncUpCourse(course)
                },
                actionSecondary: {
                    text: this.$t('modules.canvas.syncCourses.message.syncCancel'),
                    callback() {}
                },
                icon: { color: 'warning', name: 'mdi-sync' },
                color: 'primary'
            })
        },
        async syncUpCourse(course){
            const body = {
                subjectId: course.id,
                termId: this.periodSelectedId
            }
            const bodySync = {
                newLms: course.newLms,
            }
            try {
                await $courses.update(`lms/${course.courseId}`, bodySync, null)
                this.search({search: course.code, period: body.termId})
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('Exito'),
                    content: [{ value: this.$t('modules.canvas.syncCourses.message.syncUpCourseSuccess') }],
                    actionPrimary: { text: this.$t('actions.close'), callback() {} },
                    icon: {color: 'success', name: 'mdi-check'},
                    color: 'primary',
                })
                }catch (error) {
                this.successMessage.type = 'error';
                this.successMessage.title = this.$t('modules.canvas.syncCourses.message.syncUpCoursesError');
                this.successMessage.actionSecondary = null;
                this.successMessage.actionPrimary = { 
                    text: this.$t('modules.canvas.syncCourses.actions.close'), callback: () => { 
                        this.syncCoursesStatus = false
                    } 
                }
            } finally {
                this.syncCoursesStatus = false;
            }
        },
        syncCoursesSections(item){
            this.$router.push({path:`sync-courses/courses_sections/${item.courseId}`, query: {period: this.periodSelectedId, search: item.code, courseId: item.courseId}})
        },
        async search({search, period}) {
            try {
                this.periodSelectedId = period
                const {data} = await $subjects.find(`term/${period}`, null, {params: { search }})
                this.course = data
                this.canvasMls(this.course[0].courseId)
            } catch (error) {
                this.course = [];
            }
        },
        async canvasMls(courseId){
            try{
                const {data} = await $canva.find(`courses/${courseId}`, null, null)
                this.course = this.course.map(tmpCourse => {
                    let tmpIdCanvas 
                    if(tmpCourse.isOldModality){
                        [tmpIdCanvas] = data.filter(canvasCourse => canvasCourse?.sisCourseId.includes('R19') && canvasCourse?.name.includes('R.19'))
                    }
                    else{
                        
                        [tmpIdCanvas] = data.filter(canvasCourse => !(canvasCourse?.sisCourseId.includes('R19') && canvasCourse?.name.includes('R.19')))
                    }
                    return {...tmpCourse, isSync: tmpIdCanvas.id == tmpIdCanvas.lms, newLms: tmpIdCanvas.id}
                })
            }catch(error){
                return error
            }
            
        },
    },
    computed: {
        canUpdate() {
            return this.$permissions.portaladministrativo.canvas.sync_courses.UPDATE
        },
    },
}
</script>