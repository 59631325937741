<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t(`modules.portalalumno.procedures.procedureBenefit.breadcrumb.title`)"
        :description="$t(`modules.portalalumno.procedures.procedureBenefit.breadcrumb.description`)"
        isDescriptionFullLength
        :callback="goBack"
      ></Breadcrumbs>
      <v-row v-if="!createNewProcedureBenefitStatus">
        <v-col class="viewSpaces" cols="12">
          <OutlinedCard
            class="mb-6">
            <v-row class="mb-6">
              <v-col sm="12" class="py-0">
                <v-card-text class=" body-1 pa-0 primary--text font-weight-bold">
                  {{ $t(`modules.portalalumno.procedures.procedureBenefit.OutlineCard.title`) }}
                </v-card-text>
              </v-col>
              <v-col sm="10">
                <v-autocomplete
                  ref="selectedCommunity"
                  v-model="$v.selectedCommunity.$model"
                  outlined
                  :error-messages="($v.selectedCommunity.$dirty && $v.selectedCommunity.$invalid) ?
                  $t('modules.portalalumno.procedures.procedureBenefit.validations.communityRequired') : ''"
                  :label="$t(`modules.portalalumno.procedures.procedureBenefit.OutlineCard.autocompleteEmpresa.label`)"
                  item-text="name"
                  return-object
                  :items="communities"
                  :loading="communitiesLoading"
                  @blur="$v.selectedCommunity.$touch()"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-6">
              <v-col cols="10">
                <div>
                  <h4 class="title font-weight-bold">{{ $t(`modules.portalalumno.procedures.procedureBenefit.OutlineCard.dropfile.title`) }}</h4>
                  <h4 class="body-2">{{ $t(`modules.portalalumno.procedures.procedureBenefit.OutlineCard.dropfile.subtitle`) }}</h4>
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <DropFiles
                  ref="documentation"
                  extension=".jpg, .png, .pdf"
                  :type-file="['image/*', 'application/pdf']"
                  limitSizeBytes="25000000"
                  multiple
                  :required="$v.documentation.$anyError"
                  :value="documentation"
                  :docTypes="requiredDocuments"
                  :truncate-text-length="80"
                  @input="(files) => { this.documentation = files }"
                />
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col sm="10" class="py-0">
                <v-card-text class=" body-1 pa-0 primary--text font-weight-bold">
                  {{ $t(`modules.portalalumno.procedures.procedureBenefit.OutlineCard.seccionPropietario.subtitle`) }}
                </v-card-text>
                <v-card-text class=" body-2 pa-0 gray--text ">
                  {{ $t(`modules.portalalumno.procedures.procedureBenefit.OutlineCard.seccionPropietario.subtitle`) }}
                </v-card-text>
              </v-col>
              <v-col sm="6" class="ms-6">
                <v-radio-group
                  ref="documentOwner"
                  v-model="$v.documentOwner.$model"
                  :error-messages="($v.documentOwner.$dirty && $v.documentOwner.$invalid) ?
                  $t('modules.portalalumno.procedures.procedureBenefit.validations.documentOwnerRequired') : ''"
                  class="mt-0"
                >
                  <v-radio
                    v-for="documentOwner in documentOwnerTypes"
                    :key="`documentOwner-${documentOwner.value}`"
                    :label="documentOwner.meaning"
                    :value="documentOwner.value"
                    color="primary"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </OutlinedCard>
        </v-col>
        <v-col cols="11">
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.replace('/procedures')"
              @end="resetButtonValues"
              class="mr-4 margin-bot"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.create')"
              :successText="$t('actions.created')"
              :errorText="$t('actions.error')"
              :disabled="!canCreate"
              @end="resetButtonValues"
              @clicked="validateProcedureBenefit"
            ></Button>
          </div>
        </v-col>
      </v-row>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :subtitle="successMessage.subtitle"
        :actionPrimary="successMessage.actionPrimary"
      />
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import DropFiles from '@/components/DropFiles/DropFiles';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {required} from 'vuelidate/lib/validators';
import {$types, $communities, $studentProcessing, $benefits} from '../services';
import {mapGetters} from 'vuex';

export default {
  name: 'ProcedureBenefit',
  components: {
    Breadcrumbs,
    OutlinedCard,
    DropFiles,
    Button,
    SuccessMessage
  },
  data() {
    return {
      createNewProcedureBenefitStatus: false,
      procedureId: 0,
      documentUploadIndex: 0,
      communities: [],
      communitiesLoading: false,
      selectedCommunity: null,
      documentOwnerTypes: [],
      documentOwner: null,
      documentation: null,
      requiredDocuments: [],
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        subtitle: null,
        actionPrimary: null
      },
    };
  },
  validations: {
    selectedCommunity: {required},
    documentation: {required},
    documentOwner: {required}
  },
  computed: {
    canCreate() {
      return this.$permissions.portalalumno.procedures.BENEFIT;
    },
    ...mapGetters({
      studentRecord: 'commons/getStudentActualRegister',
      studentData: 'commons/getStudentData',
      userName: 'commons/getUserName',
      studentUserId: 'commons/getStudentUserID'
    }),
  },
  watch: {
    async selectedCommunity(community) {
      try {
        const {data} = await $benefits.findById(community.benefit.id, 'documentation');
        this.requiredDocuments = data.documentation;
      } catch (err) {
        this.requiredDocuments = [];
        throw err;
      }
    }
  },
  mounted() {
    this.getCommunities();
    this.getDocumentOwnerTypes();
  },
  methods: {
    async getCommunities() {
      this.communitiesLoading = true;
      try {
        const {data} = await $communities.find(null, null, { params: {page: 0, length: 500 , status: true } });
        this.communities = data.content;
      } catch (err) {
        this.communities = [];
        throw err;
      } finally {
        this.communitiesLoading = false;
      }
    },
    async getDocumentOwnerTypes() {
      try {
        const {data} = await $types.find(null, null, {params: {type: 'DOCUMENT_OWNER'}});
        this.documentOwnerTypes = data;
      } catch (err) {
        this.documentOwnerTypes = [];
        throw err;
      }
    },
    goBack() {
      this.$trackEvent('Tramites', 'Cancelar', 'Retiro');
      this.$router.push('/procedures');
    },
    validateProcedureBenefit() {
      this.createButton.loading = true;
      if (this.$v.$invalid) {
        this.$v.$touch();
        Object.keys(this.$v).some(input => {
          if (input.includes('$')) return false;
          if (this.$v[input].$error) {
            this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
            return true;
          }
        });
        this.createButton.error = true;
        this.createButton.loading = false;
      } else {
        this.createProcedureBenefit(this.documentUploadIndex);
      }
    },
    async createProcedureBenefit(index) {
      try {
        if (index != this.documentation.length) {
          const formData = new FormData();
          const newProcedureBenefit = {
            benefit: {id: this.selectedCommunity.benefit.id},
            student: {
              id: this.studentData.student.id,
              user: {
                id: this.studentData.student.user.id,
                name: this.studentData.userData.name
              }
            },
            user: this.studentUserId ? this.studentUserId : null,
            studentRecord: this.studentRecord,
            communityId: this.selectedCommunity.id,
            ownerType: this.documentOwner,
            processingId: this.procedureId,
            userName: this.userName,
          };
          formData.append('model', JSON.stringify(newProcedureBenefit));
          formData.append('documentation', this.documentation[index].file);
          formData.append('idDocumentation', this.documentation[index].docType.id);
          formData.append('partial', '1');

          const response = await $studentProcessing.formData(formData, 'uploadDocumentation');
          if (response.ok) {
            if (!this.procedureId) {
              this.procedureId = (await response.json()).processingId;
            }
            this.createProcedureBenefit(++this.documentUploadIndex);
          } else {
            throw 'Error'
          }
        } else {
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t(`modules.portalalumno.procedures.procedureBenefit.successMessage.title`);
          this.successMessage.subtitle = this.$t(`modules.portalalumno.procedures.procedureBenefit.successMessage.subtitle`);
          this.successMessage.actionPrimary = {
            text: this.$t('actions.close'), callback: () => {
              this.$router.push('/procedures');
            }
          };
          this.createNewProcedureBenefitStatus = true;
          this.createButton.success = true;
          this.createButton.loading = false;
        }
      } catch (err) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.portalalumno.procedures.procedureBenefit.errorMessage.uploadDocument');
        this.successMessage.subtitle = null;
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.createNewProcedureBenefitStatus = false;
          }
        };
        this.createNewProcedureBenefitStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        throw err;
      }
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
