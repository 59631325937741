import preregistrationTranslations from './Preregistration/Translations';

const admissionTranslations = {
    es_MX: {
      preregistration: { ...preregistrationTranslations.es_MX },
    },
    es_PE: {
        preregistration: { ...preregistrationTranslations.es_PE },
    },
    es: {
    preregistration: { ...preregistrationTranslations.es },
  },
  en: {
    preregistration: { ...preregistrationTranslations.en },
  }
};

export default admissionTranslations
