<template>
  <div class="multipleFormGroup">
    <template >
      <div v-for="(item, i) in items"
        :key="i"
        :class="['multipleFormGroup__form-group', Number(i) != arrLength ? 'mb-6' : '', (styleless) ? 'styleless' : '']"
      >

        <div class="multipleFormGroup__form-group__content">
          <slot v-bind:item="item" v-bind:index="i"></slot>
        </div>

        <div
          :class="['multipleFormGroup__form-group__actions', (Number(i)+1 != arrLength) || !canAddGroup? 'simpleAction' : '']"
        >

          <v-tooltip top v-if="!(Number(i) == 0 && Number(i)+1 == arrLength)">
            <template v-slot:activator="{ on }">
              <div
                class="multipleFormGroup__form-group__actions__remove cursor-pointer"
                @click="() => removeGroup(i)"
                v-on="on"
              >-</div>
            </template>
            <span>{{ tooltipsText.removeGroup }}</span>
          </v-tooltip> 
          
          <v-tooltip top v-if="(Number(i)+1 == arrLength) && canAddGroup">
            <template v-slot:activator="{ on }">
              <div
                @click="() => addGroup(item)"
                class="multipleFormGroup__form-group__actions__add cursor-pointer"
                v-on="on"
              >+</div>
            </template>
            <span>{{ tooltipsText.addGroup }}</span>
          </v-tooltip> 

        </div>
      
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MultipleFormGroup',
  props: {
    items: [Array, Object], //Permite objetos para utilizar la propiedad $iter de vuelidate
    canAddGroup: {
      type: Boolean,
      default: true
    },
    styleless: Boolean,
    length: { //Pasar length si se pasa un objeto como items
      type: [Number, undefined],
      default: undefined
    },
    tooltipsText: {
      type: Object,
      default() {
        return {
          addGroup: this.$t('actions.add'),
          removeGroup: this.$t('actions.delete')
        }
      }
    }
  },
  computed: {
    arrLength () {
      let returnValue = 123;
      if (this.length == undefined && Array.isArray(this.items))
        returnValue = this.items.length;
      else if (typeof this.length === 'number')
        returnValue = this.length;
      else
        returnValue = 0;
      return returnValue;
    }
  },
  methods: {
    addGroup (item) {
      let copied = null
      if (Array.isArray(item)) {
        copied = [...item]
      } else {
        copied = {...item}
      }
      this.$emit('addGroup', copied)
      // this.$emit('addGroup', JSON.parse(JSON.stringify(item)))
    },
    removeGroup (index) {
      this.$emit('removeGroup', index)
    }
  }
}
</script>

<style scoped lang="sass">

.multipleFormGroup
  &__form-group
    position: relative
    background-color: #F6F6F6
    border-radius: 5px
    border: 1px solid #CECECE
    padding: 1rem
    width: calc(100% - 56px)
    &.styleless
      padding-top: 0 !important
      padding-bottom: 0 !important
      padding-left: 0 !important
      background-color: white !important
      border: none !important
    &__content
      width: calc(100% - 16px)
    &__actions
      position: absolute
      right: -20px
      top: 50%
      transform: translate(50%, -50%)
      display: flex
      &.simpleAction
        right: 0
      &__remove, &__add
        height: 32px
        width: 32px
        border-radius: 50%
        border: 1px solid var(--v-primary-base)
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16)
        font-size: 24px
        color: var(--v-primary-base)
        background-color: #FFFFFF
        display: flex
        justify-content: center
        align-items: center
        transition: all 300ms ease
        &:hover, &:active
          background-color: var(--v-primary-base)
          color: #FFFFFF
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3)
      &__add
        margin-left: 0.5rem
</style>
