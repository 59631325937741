<template>
  <v-container class="pa-0" fluid>
    <div class="mb-4">
      <div class="body-1 mb-4"> {{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subtitle') }}</div>
      <div class="d-flex flex-wrap">
        <v-card
            :color="item.colorDec"
            :key="`semester-${index}`"
            class="caption py-1 px-3 mr-2 mb-2"
            flat
            v-for="(item, index) in states"
        >
          <span :class="item.colorText" class="text-no-wrap text-uppercase ">{{ item.state }}</span>
        </v-card>
      </div>
    </div>
    <div class="study-plan-mobile">
      <div :class="['study-plan-mobile__semester']" :key="`semester-${indexS}`" v-for="indexS in semesters">
        <div class="flex-grow-1">
          <div
              v-if="yearRows.includes(indexS)"
              class="study-plan-mobile__year__header mt-4 mb-2 py-1 text-center primary"
              :key="`year-header-${indexS}`">
            <span class="subtitle-1 font-weight-medium white--text">{{toOrdinal(yearRows.findIndex(year => year === indexS))}} Año</span>
          </div>
          <div :class="['study-plan-mobile__semester__header lighten-4 mt-4 mb-2 py-1 text-center primary']">
            <span class="subtitle-1 font-weight-medium white--text">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.period',{periodType:indexS}) }}</span>
          </div>

          <div class="d-flex">
            <div class="study-plan-mobile__bimesters-headers" v-if="!isExamenTitulo(indexS)">
              <h6 class="study-plan-mobile__bimesters-title caption primary--text">
                {{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.bimesterA') }}
              </h6>
              <h6 class="study-plan-mobile__bimesters-title caption primary--text">
                {{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.bimesterB') }}
              </h6>
            </div>
            <div class="flex-grow-1">
              <template v-if="!isExamenTitulo(indexS)">
                <v-tooltip :key="item.title" top v-for="(item, index) in studyPlans.bimestreA[indexS-1]">
                  <template v-slot:activator="{ on }">
                    <v-card
                        :class="['study-plan-mobile__semester__bimester__subject' , 'subject-card lighten-4 mb-2', index === studyPlans.bimestreA[indexS-1].length -1 ? 'mb-4' : '']"
                        :color="cardColor(item.state)" @click="dialog = true; subjectClicked(index, item)"
                        flat outlined
                        v-on="on">
                      <div :class="textColor(item.state)" class="text-truncated pa-2">{{ item.title }}</div>
                    </v-card>
                  </template>
                  <span>{{$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement_view')}}</span>
                </v-tooltip>

                <v-tooltip :key="item.title" top v-for="(item, index) in studyPlans.bimestreB[indexS-1]">
                  <template v-slot:activator="{ on }">
                    <v-card
                        :class="['study-plan-mobile__semester__bimester__subject' , 'subject-card lighten-4 mb-2']"
                        :color="cardColor(item.state)" @click="dialog = true; subjectClicked(index, item)"
                        flat outlined
                        v-on="on">
                      <div :class="textColor(item.state)" class="text-truncated pa-2">{{ item.title }}</div>
                    </v-card>
                  </template>
                  <span>{{$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement_view')}}</span>
                </v-tooltip>
              </template>

              <template v-else>
                <div :class="['study-plan-mobile__semester__bimester__subject-semestral']"
                     :key="`bimester-1-${indexS}`">
                  <v-tooltip :key="item.title" top v-for="(item, index) in studyPlans.bimestreA[indexS-1]">
                    <template v-slot:activator="{ on }">
                      <v-card :class="['subject-card lighten-4']" :color="cardColor(item.state)"
                              @click="dialog = true; subjectClicked(index, item)"
                              flat outlined
                              v-on="on">
                        <div :class="textColor(item.state)" class="text-truncated pa-2">{{ item.title }}</div>
                      </v-card>
                    </template>
                    <span>{{$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement_view')}}</span>
                  </v-tooltip>
                </div>
              </template>

              <template v-if="studyPlans.semestrales[indexS-1].length > 0">
                <div :class="['study-plan-mobile__semester__bimester__subject-semestral']" :key="`bimester-1-${indexS}`">
                  <v-tooltip :key="`${index}-${item.title}`" top v-for="(item, index) in studyPlans.semestrales[indexS-1]">
                    <template v-slot:activator="{ on }">
                      <v-card :class="['subject-card lighten-4']" :color="cardColor(item.state)" @click="dialog = true; subjectClicked(index, item)" flat outlined v-on="on">
                        <div :class="textColor(item.state)" class="text-truncated pa-2">
                          {{item.title}}
                        </div>
                      </v-card>
                    </template>
                    <span>{{$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement_view')}}</span>
                  </v-tooltip>
                </div>
              </template>
            </div>
          </div>
        </div>

      </div>
    </div>
    <v-dialog :width="'90%'" v-model="dialog">
      <v-card class="study-plan-mobile__dialog-card">
        <v-card-title class="primary white--text d-flex justify-space-between" primary-title>
          <div class="title">{{ subjectTitle }}</div>
          <v-btn @click="dialog = false" dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-6">
          <v-row>
            <v-col cols="12">
              <h3 class="prerequisites-title my-0">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement') }}:</h3>
            </v-col>
            <v-col cols="12" class="body-1">
              <div class="pl-3 mb-2" v-if="!preRequisitesFrom.length">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.no_requirement')}}</div>
              <div v-else :key="`subject-from-${index}`" class="pl-3 mb-2 text-no-wrap" v-for="(item, index) in preRequisitesFrom">{{ `- ${item}` }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" class="mx-auto">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 class="prerequisites-title my-0">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement_of') }}:</h3>
            </v-col>
            <v-col cols="12" class="body-1">
              <div class="pl-3 mb-2" v-if="!preRequisitesOf.length">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.no_requirement_of')}}</div>
              <div v-else :key="`subject-Of-${index}`" class="pl-3 mb-2 text-no-wrap" v-for="(item, index) in preRequisitesOf">{{ `- ${item}` }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { $setting } from '#/portalalumno/AcademicCharge/StudyPlan/Services';
  export default {
    name: 'StudyPlanComponent',
    props: {
      rawStudyPlans: Array
    },
    data() {
      return {
        academicSettings: {
          CANT_SEMESTRES_ANUALES: 2
        },
        dialog: false,
        semesters: 0,
        subjectsLastSemesters: [],
        subjectTitle: '',
        preRequisitesOf: '',
        preRequisitesFrom: '',
        states: [
          {
            state: this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subjectsStates.noStudying'),
            color: 'blue-grey lighten-4',
            colorDec: '#f9fafc',
            colorText: 'grey--text text--darken-2'
          }, {
            state: this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subjectsStates.studying'),
            color: '#616DDC',
            colorDec: '#717EFF',
            colorText: 'white--text'
          }, {
            state: this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subjectsStates.aproved'),
            color: '#C4E9C4',
            colorDec: '#D6FFD6',
            colorText: 'grey--text text--darken-2'
          }, {
            state: this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subjectsStates.available'),
            color: 'lighten-4',
            colorDec: '#52D87E',
            colorText: 'white--text'
          }, {
            state: this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subjectsStates.reproved'),
            color: '#F699CC',
            colorDec: '#F4BADA',
            colorText: 'pink--text text--darken-4'
          }
        ],
        studyPlans: { // Defino 9 semestres por defecto para no tener que generarlos dinamicamente
          bimestreA: [[], [], [], [], [], [], [], [], []],
          bimestreB: [[], [], [], [], [], [], [], [], []],
          semestrales: [[], [], [], [], [], [], [], [], []]
        },
      }
    },
    computed: {
      years() {
        return Math.ceil(this.semesters / this.academicSettings.CANT_SEMESTRES_ANUALES)
      },
      yearRows() {
        const yearsRows = []
        let rowStart = 1
        for (let i = 1; i <= this.years; i++) {
          yearsRows.push(rowStart)
          rowStart = rowStart + this.academicSettings.CANT_SEMESTRES_ANUALES  > this.semesters ? this.semesters : rowStart + this.academicSettings.CANT_SEMESTRES_ANUALES
        }
        return yearsRows
      }
    },
    async created() {
      await this.getAcademicSettings()
    },
    methods: {
      // Data parcer
      isExamenTitulo(index) {
        const graduationExamNames = ['EXAMEN DE TÍTULO' || 'EXAMEN DE TITULO']

        const semesterSubjects = [
            ...this.studyPlans.bimestreA[index - 1],
            ...this.studyPlans.bimestreB[index - 1],
            ...this.studyPlans.semestrales[index - 1]
        ]

        if (semesterSubjects.length === 1 && this.semesters === index) {
            const [uniqueSubjectInLastSemester] = semesterSubjects
            return graduationExamNames.includes(uniqueSubjectInLastSemester?.title.toUpperCase())
        }

        return false;
      },
      stateParcer(status) {
        if (status.toUpperCase() == 'LIBRE') return this.$isTeclab ? 'Desaprobada' :  'Reprobada'
        else if (status.toUpperCase() == 'REGULAR') return this.$isTeclab ? 'Regular' : 'Habilitada'
        else return status
      },
      subjectsParcer(studyPlans) {
        studyPlans.forEach(subject => {
          const subjectParsed = {
            title: subject.subject.descripcion,
            state: !subject.subjectStatus ? 'No cursado' : this.stateParcer(subject.subjectStatus.meaning),
            semester: subject.semester,
            bimester: subject.subject.duration.meaning === 'Semestral' ? 'C' : subject.termType.meaning,
            preRequisitesOf: subject.preRequisites ? subject.preRequisites : [],
            preRequisitesFrom: subject.preRequisitesFrom ? subject.preRequisitesFrom : []
          };

          if (this.semesters < subjectParsed.semester)
            this.semesters = subjectParsed.semester;

          switch (subjectParsed.bimester) {
            case 'A':
              this.studyPlans.bimestreA[subjectParsed.semester - 1].push(subjectParsed);
              break;
            case 'B':
              this.studyPlans.bimestreB[subjectParsed.semester - 1].push(subjectParsed);
              break;
            case 'C':
              this.studyPlans.semestrales[subjectParsed.semester - 1].push(subjectParsed);
              break
          }

        });
        this.$emit('lodingState', true);
      },

      subjectClicked(index, item) {
        this.subjectTitle = item.title;
        this.preRequisitesOf = item.preRequisitesOf
        this.preRequisitesFrom = item.preRequisitesFrom
        if (item.title == 'PRÁCTICA LABORAL') this.$trackEvent('Carga académica', 'Click - Práctica laboral', 'Plan de estudio')
        else if (item.title == 'TRABAJO DE TÍTULO') this.$trackEvent('Carga académica', 'Click - Trabajo de título', 'Plan de estudio')
        else if (item.title == 'EXAMEN DE TÍTULO') this.$trackEvent('Carga académica', 'Click - Examen de título', 'Plan de estudio')
      },
      cardColor(status) {
        for (let i = 0; i < this.states.length; i++) {
          if (status.toLowerCase() === this.stateParcer(this.states[i].state).toLowerCase()) {
            return this.states[i].colorDec
          }
        }
      },
      textColor(status) {
        for (let i = 0; i < this.states.length; i++) {
          if (status.toLowerCase() === this.stateParcer(this.states[i].state).toLowerCase()) {
            return this.states[i].colorText
          }
        }
      },
      countSubjectsLastSemesters() {
        for (let i = 0; i < this.studyPlans.length; i++) {
          if (this.studyPlans[i].semester === this.semesters) {
            this.subjectsLastSemesters.push(this.studyPlans[i])
          }
        }
      },
      showSemestralSubject(index) {
        return this.studyPlans.semestrales[index - 1].length > 0
      },
      async getAcademicSettings() {
        const {data} = await $setting.find('CANT_SEMESTRES_ANUALES')
        this.academicSettings.CANT_SEMESTRES_ANUALES = data?.value
      },
      toOrdinal (number){
        const first = this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.ordinal');
        const second = this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.ordinal_2');
  
        const units = (number+1) % 10;
        let decimals = (number+1) / 10;
        decimals = Math.floor(decimals);
  
        if(decimals === 0)
          return first[units-1].toPascalCase()
  
        if(units === 0 && decimals !== 0)
          return second[decimals-1].toPascalCase()
  
        return `${second[decimals-1].toPascalCase()  } ${  first[units-1]}`
      },
    }
  };
</script>

<style lang="sass" scoped>
  .study-plan-mobile
    flex-direction: column
    &__bimesters
      &-headers
        display: flex
        flex-direction: column
        justify-content: space-around
        padding: 0 0.5rem 0 0

      &-title
        text-align: center
        writing-mode: vertical-lr
        transform: rotate(180deg)
        margin-top: 10px
        margin-bottom: 15px

    &__semester
      &__header
        border-radius: 5px
        height: unset
    &__year
      &__header
        border-radius: 5px
        height: unset    

      &__bimester
        &__subject
          &-text
            font-size: 0.625rem !important
            line-height: 1.3

          &-semestral
            width: 100%

            & > div
              display: block
              text-align: left

    &__dialog-card
      .title
        word-break: break-word
        width: 80%
        line-height: 1.25
      .v-card__text
        overflow: auto
        max-height: 400px
      .prerequisites-title
        font-family: "DM Sans", "Roboto" !important
        letter-spacing: 0.0125em !important
        font-weight: 700 !important
        font-size: 1.25rem !important

</style>
