<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!createNewDiscountStatus">
        <template v-if="!insideDrawer">
          <Breadcrumbs
            :title="$t('modules.discounts.create.breadcrumbs.title')"
            :description="$t('modules.discounts.create.breadcrumbs.description')"
          ></Breadcrumbs>
        </template>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlineCard
                :title="$t('modules.discounts.create.section_name.title')"
                :subtitle="$t('modules.discounts.create.section_name.subtitle')"
                :switchLabel="(status) ? $t('modules.discounts.enabled') : $t('modules.discounts.disabled')"
                :switchValue="status"
                @switchChange="() => {this.status = !this.status; this.$v.status.$touch()}"
                switcher
                class="mb-6"
              >
                <v-row>
                  <v-col sm="6" class="py-0">
                    <v-text-field
                      :error-messages="($v.description.$dirty && $v.description.$invalid) ? $t('modules.discounts.create.validation_errors.name') : ''"
                      @blur="$v.description.$touch()"
                      v-model="$v.description.$model"
                      :label="`${$t('modules.discounts.create.labels.name')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" class="py-0">
                    <v-text-field
                      :error-messages="($v.code.$anyDirty && $v.code.$anyError) ? ((!$v.code.required) ? $t('modules.discounts.create.validation_errors.code') : $t('modules.discounts.create.validation_errors.code_unique')) : ''"
                      @blur="validateCode"
                      :loading="loadingCode"
                      v-model="$v.code.$model"
                      :label="`${$t('modules.discounts.create.labels.code')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </OutlineCard>
              <OutlineCard
                :title="$t('modules.discounts.create.section_career.title')"
                :subtitle="$t('modules.discounts.create.section_career.subtitle')"
                class="mb-6"
              >
                <TransferList
                  v-if="filteredCareers.length || $v.selectedCareers.$model.length"
                  :addedItems="$v.selectedCareers.$model"
                  areEquals="id"
                  :availableItems="filteredCareers"
                  @addItems="(arr)=> {this.$v.selectedCareers.$model = arr}"
                  @removeItems="(arr)=>{this.filteredCareers = arr}"
                  :firstListTitle="$t('modules.discounts.create.section_career.transferList.firstTitle')"
                  :secondListTitle="$t('modules.discounts.create.section_career.transferList.secondTitle')"
                ></TransferList>
                <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-if="$v.selectedCareers.$dirty && $v.selectedCareers.$anyError"
                >{{ $t('modules.discounts.create.section_career.no_selected_warning') }}</v-alert>
              </OutlineCard>
              <OutlineCard :title="$t('modules.discounts.create.section_student.title')" class="mb-6">
                <div class="d-flex">
                  <v-checkbox
                    v-for="type in studentTypes"
                    :key="type.value"
                    v-model="$v.selectedStudentTypes.$model"
                    color="primary"
                    :label="type.meaning"
                    :value="type"
                    class="mr-8 mt-0 no-message"
                  ></v-checkbox>
                </div>
                <v-alert
                  text
                  type="warning"
                  class="mt-2"
                  v-if="$v.selectedStudentTypes.$anyDirty && $v.selectedStudentTypes.$anyError"
                >{{ $t('modules.discounts.create.section_student.error') }}</v-alert>
              </OutlineCard>
              <OutlineCard :title="$t('modules.discounts.create.section_zone.title')" class="mb-6">
                <MultipleFormGroup
                  :items="$v.groups.$each.$iter"
                  :length="$v.groups.$model.length"
                  @removeGroup="removeGroup"
                  @addGroup="addGroup"
                >
                  <template v-slot:default="{item, index}">
                    <v-container fluid>
                      <v-row>
                        <v-col sm="3">
                          <v-select
                            :error-messages="(item.product.$dirty && item.product.$invalid) ? 'Error' : ''"
                            :label="`${$t('modules.discounts.create.section_zone.items.product')} *`"
                            :items="billingProducts"
                            item-text="name"
                            @blur="item.product.$touch()"
                            outlined
                            return-object
                            class="no-message"
                            v-model="item.product.$model"
                          ></v-select>
                        </v-col>
                        <v-col sm="6">
                          <v-select
                            :error-messages="(item.zone.$dirty && item.zone.$invalid) ? 'Error' : ''"
                            :label="`${$t('modules.discounts.create.section_zone.items.zone')} *`"
                            :items="zones"
                            item-text="zoneDescription"
                            outlined
                            return-object
                            class="no-message"
                            v-model="item.zone.$model"
                            @blur="item.zone.$touch()"
                          ></v-select>
                        </v-col>
                        <v-col sm="3">
                          <v-menu
                            :ref="`${index}-menu_from`"
                            v-model="item.$model.menu_from"
                            :close-on-content-click="false"
                            :return-value.sync="item.from.$model"
                            transition="scale-transition"
                            offset-y
                            bottom
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :error-messages="item.from.$invalid && item.from.$dirty ? 'Error' : ''"
                                v-model="item.from.$model"
                                :label="`${$t('modules.discounts.create.section_zone.items.from')} *`"
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                v-on="on"
                                class="no-message"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.from.$model"
                              no-title
                              color="primary"
                              scrollable
                              :min="new Date().toISOString().substr(0, 10)"
                              :max="item.to.$model"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="item.$model.menu_from = false">Cancelar</v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs[`${index}-menu_from`].save(item.from.$model)"
                              >OK</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="3" class="pb-0">
                          <v-select
                            :error-messages="(item.discountType.$dirty && item.discountType.$invalid) ? 'Error' : ''"
                            :label="`${$t('modules.discounts.create.section_zone.items.discountType')} *`"
                            :items="$t('modules.discounts.create.section_zone.discountTypes')"
                            outlined
                            class="no-message"
                            return-object
                            v-model.number="item.discountType.$model"
                            @blur="item.discountType.$touch()"
                          ></v-select>
                        </v-col>
                        <v-col sm="3" class="pb-0">
                          <v-text-field
                            :error-messages="(item.semester.$invalid && item.semester.$dirty) ? 'Error' : ''"
                            :label="`${$t('modules.discounts.create.section_zone.items.semester')} *`"
                            :input="item.semester"
                            :prepend-inner-icon="(item.discountType.$model.value === 1) ? 'mdi-currency-usd' : ((item.discountType.$model.value === 2) ? 'mdi-percent' : '')"
                            type="number"
                            :min="0"
                            :max="(item.discountType.$model.value === 1) ? null : 100"
                            @keypress="$validateDecimalInputNumber($event, item.semester.$model, (item.discountType.$model.value === 1) ? null : 100)"
                            outlined
                            v-model.number="item.semester.$model"
                            @blur="item.semester.$touch()"
                            class="no-message"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="3" class="pb-0">
                          <v-text-field
                            v-if="systemConfig?.halfTicket"
                            :error-messages="(item.bimester.$invalid && item.bimester.$dirty) ? 'Error' : ''"
                            :label="`${$t('modules.discounts.create.section_zone.items.bimester')}`"
                            :input="item.bimester"
                            :prepend-inner-icon="(item.discountType.$model.value === 1) ? 'mdi-currency-usd' : ((item.discountType.$model.value === 2) ? 'mdi-percent' : '')"
                            type="number"
                            outlined
                            :min="0"
                            :max="(item.discountType.$model.value === 1) ? null : 100"
                            @keypress="$validateDecimalInputNumber($event, item.bimester.$model, (item.discountType.$model.value === 1) ? null : 100)"
                            class="no-message"
                            v-model.number="item.bimester.$model"
                            @blur="item.bimester.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="3" class="pb-0">
                          <v-menu
                            :ref="`${index}-menu_to`"
                            v-model="item.$model.menu_to"
                            :close-on-content-click="false"
                            :return-value.sync="item.to.$model"
                            transition="scale-transition"
                            offset-y
                            bottom
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :error-messages="item.to.$invalid && item.to.$dirty ? 'Error' : ''"
                                v-model="item.to.$model"
                                :label="`${$t('modules.discounts.create.section_zone.items.to')} *`"
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                v-on="on"
                                class="no-message"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.to.$model"
                              no-title
                              color="primary"
                              scrollable
                              :min="item.from.$model"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="item.$model.menu_to = false">Cancelar</v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs[`${index}-menu_to`].save(item.to.$model)"
                              >OK</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </MultipleFormGroup>
                <v-divider class="mb-4"></v-divider>
                <div class="d-flex justify-end" v-if="canCreateZones">
                  <Button
                    class="mt-4"
                    outlined
                    depressed
                    :text="$t('modules.discounts.create.section_zone.button')"
                    @clicked="tmpDrawerOpen"
                  ></Button>
                </div>
              </OutlineCard>
              <OutlineCard :title="$t('modules.discounts.create.section_restriction.title')" class="mb-6">
                <v-radio-group v-model="limited" class="no-message ma-0">
                  <v-radio
                    :label="$t('modules.discounts.create.section_restriction.items.radioFirst')"
                    color="primary"
                    :value="0"
                  ></v-radio>
                  <div class="d-flex">
                    <v-radio
                      :label="$t('modules.discounts.create.section_restriction.items.radioSecond')"
                      color="primary"
                      :value="1"
                    ></v-radio>
                    <v-text-field
                      :error-messages="($v.limit.$invalid && $v.limit.$dirty) ? $t('modules.discounts.create.validation_errors.limit') : ''"
                      outlined
                      v-model.number="$v.limit.$model"
                      type="number"
                      min="0"
                      max="999"
                      placeholder="0"
                      @blur="$v.limit.$touch()"
                      :disabled="!limited"
                      @keypress="$validateIntegerInputNumber($event, $v.limit.$model, null, 3)"
                      ></v-text-field>
                  </div>
                </v-radio-group>
              </OutlineCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/discounts')"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  :disabled="!canCreate || $v.formValid.$anyError || $v.formValid.$invalid"
                  @end="resetButtonValues()"
                  @clicked="createDiscountPopUp"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
      <TemporaryRightDrawer
        v-if="canCreateZones && !insideDrawer"
        @tmpDrawerOpen="tmpDrawerOpen"
        :open="tmpDrawer.open"
        :title="tmpDrawer.title"
        :description="tmpDrawer.description"
      >
        <ZonesCreate
          @closeDrawer="onCloseDrawer"
          :insideDrawer="true"
        ></ZonesCreate>
      </TemporaryRightDrawer>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import ZonesCreate from '../../Zones/views/ZonesCreate'
import { required, minValue, decimal, requiredIf } from 'vuelidate/lib/validators';
import { $discounts } from '../Services';
import commons from '../mixins/commons';
import { mapGetters } from 'vuex';


const arrayNotEmpty = (array) => {
  return array.length
};
export default {
  name: 'DiscountsCreate',
  mixins: [commons],
  components: {
    Breadcrumbs,
    OutlineCard,
    TransferList,
    MultipleFormGroup,
    Button,
    SuccessMessage,
    TemporaryRightDrawer,
    ZonesCreate
  },
  props: {
    systemConfig: Object,
    insideDrawer: { type: Boolean, default: false }
  },
  data () {
    return {
      createNewDiscountStatus: false,
      loadingCode: false,
      codeUnique: null,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      tmpDrawer: {
          open: false,
          title: this.$t('modules.discounts.create.section_zone.drawer.title'),
          description: this.$t('modules.discounts.create.section_zone.drawer.description')
      },
      successMessage: {
        type: 'success',
        title: this.$t('modules.discounts.create.success_message.title'),
        actionPrimary: { text: this.$t('modules.discounts.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/discounts') } },
        actionSecondary: { text: this.$t('modules.discounts.create.success_message.actions.secondary_text'), callback: () => { this.createNewDiscountStatus = false } }
      },
      limited: 0,
      limit: null,
      groups: [
        {
          product: {},
          zone: {},
          discountType: { value: 1 },
          semester: '',
          bimester: '',
          menu_from: false,
          menu_to: false,
          from: new Date().toISOString().substr(0, 10),
          to: null,
        }
      ],

      // SELECTED DATA
      description: null,
      code: null,
      status: true,
      selectedStudentTypes: [],
      selectedCareers: [],
      selectedBillingProduct: [],
      selectedZone: [],
    }
  },
  validations: {
    status: { required },
    description: { required },
    code: {
      required,
      unique() {
        return this.codeUnique !== false;
      },
    },
    selectedCareers: { arrayNotEmpty },
    selectedStudentTypes: { arrayNotEmpty },
    limit: {
      decimal,
      minValue: minValue(0),
      required: requiredIf(function () {
        return this.limited
      })
    },
    groups: {
      $each:
      {
        product: { required },
        zone: { required },
        discountType: { required },
        from: { required },
        to: { required },
        semester: {
          required,
          decimal,
          minValue: minValue(0),
        },
        bimester: {
          decimal,
          minValue: minValue(0),
        },
      }
    },
    formValid: ['status', 'description', 'code', 'selectedCareers', 'selectedStudentTypes', 'limit', 'groups']
  },
  computed: {
    canCreate () {
      return this.$permissions.portaladministrativo.prices.discounts.CREATE
    },
    canCreateZones () {
      return this.$permissions.portaladministrativo.prices.zones.CREATE
    },
    ...mapGetters({userId: 'commons/getUserID'})
  },
  methods: {
    async validateCode() {
        this.$v.code.$touch();
        if (this.$v.code.$model) {
            this.codeUnique = null;
            this.loadingCode = true;
            try {
                const response = await $discounts.find('exists', null, {params: {code: this.$v.code.$model}});
                this.codeUnique = !response.data.exists;
                this.loadingCode = false;
            } catch (error) {
                this.codeUnique = null;
                throw error
            } finally {
                this.$v.code.$touch();
            }
        }
    },
    tmpDrawerOpen () {
      this.tmpDrawer.open = !this.tmpDrawer.open;
      if (!this.tmpDrawer.open) this.fetchZones();
    },
    removeGroup (index) {
      this.groups.splice(index, 1)
    },
    addGroup () {
      const mockup = {
        product: {},
        zone: {},
        discountType: { value: 1 },
        semester: '',
        bimester: '',
        menu_from: false,
        menu_to: false,
        from: new Date().toISOString().substr(0, 10),
        to: null,
      };
      this.groups.push({
        ...mockup
      });
    },
    createDiscountPopUp() {
      if (!this.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('disabledMessage') }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.createDiscount() },
          actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.createDiscount();
      }
    },
    async createDiscount () {
      if (!this.canCreate) return false
      this.createButton.loading = true;

      const details = [];
      this.groups.forEach(group => {
        const discountAmount = (group.discountType.value == 1) ? true : false;
        details.push({
          billingProduct: { id: group.product.id },
          zone: { id: group.zone.id },
          bianualPercentage: (discountAmount) ? null : Number(group.semester),
          bimesterPercentage: (discountAmount) ? null : Number(group.bimester),
          bianualAmount: (discountAmount) ? Number(group.semester) : null,
          bimesterAmount: (discountAmount) ? Number(group.bimester) : null,
          from: group.from,
          to: group.to,
        });
      });

      const studentTypes = [];
      this.selectedStudentTypes.forEach(studentType => {
        studentTypes.push({ value: studentType.value });
      });

      const careers = [];
      this.selectedCareers.forEach(career => {
        careers.push({ id: career.id });
      });

      const newDiscount = {
        description: this.description,
        code: this.code,
        status: this.status,
        limit: (this.limited) ? this.limit : null,
        studentType: studentTypes,
        careers,
        details,
        userId: this.userId,
      };

      try {
        await $discounts.create(newDiscount);
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.discounts.create.success_message.title');
        if (!this.insideDrawer) {
          this.successMessage.actionPrimary = { text: this.$t('modules.discounts.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/discounts') } };
          this.successMessage.actionSecondary = { text: this.$t('modules.discounts.create.success_message.actions.secondary_text'), callback: () => { this.createNewDiscountStatus = false } };
        } else {
          this.successMessage.actionPrimary = { text: this.$t('modules.discounts.create.success_message.actions.primary_text'), callback: () => { this.$emit('closeDrawer'); } }
          this.successMessage.actionSecondary = null;
        }

        this.createNewDiscountStatus = true;
        this.createButton.success = true;
        this.clearData();
      } catch(error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.discounts.create.error_message.title');
        this.successMessage.actionPrimary = { text: this.$t('modules.discounts.create.success_message.actions.back'), callback: () => { this.createNewDiscountStatus = false; }}
        this.successMessage.actionSecondary = null;

        this.createNewDiscountStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
      }
    },
    clearData () {
      this.resetButtonValues();
      this.$v.$reset();
      this.description = null;
      this.loadingCode = false;
      this.codeUnique = null;
      this.code = null;
      this.status = true;
      this.selectedStudentTypes = [];
      this.selectedCareers = [];
      this.selectedBillingProduct = [];
      this.selectedZone = [];
      this.limited = 0;
      this.limit = null;
      this.groups = [];
      this.addGroup();
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
  },
  mounted () {
    this.fetchData()
  },
}
</script>
